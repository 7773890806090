String.prototype.colorHex = function () {
    // RGB颜色值的正则
    var reg = /^(rgb|RGB)/;
    var color = this;
    if (reg.test(color)) {
        var strHex = "#";
        // 把RGB的3个数值变成数组
        var colorArr = color.replace(/(?:\(|\)|rgb|RGB)*/g, "").split(",");
        // 转成16进制
        for (var i = 0; i < colorArr.length; i++) {
            var hex = Number(colorArr[i]).toString(16);
            if (hex === "0") {
                hex += hex;
            }
            strHex += hex;
        }
        return strHex;
    } else {
        return String(color);
    }
};

Array.prototype.select = function (func) {
    var result = new Array();

    this.forEach(function (value) {
        if (func) {
            result.push(func(value));
        }
    });

    return result;
};

Array.prototype.remove = function (val) {
    var index = this.indexOf(val);
    if (index > -1) {
        this.splice(index, 1);
    }
};

Array.prototype.getValues = function () {
    return [...this];
};

Map.prototype.getValues = function () {
    return [...this.values()];
};

Map.prototype.getKeys = function () {
    return [...this.keys()];
};

Array.prototype.orderBy = function (f) {
    return this.sort((a, b) => {
        return f(a) - f(b);
    });
};

Array.prototype.orderByDesc = function (f) {
    return this.sort((a, b) => {
        return f(b) - f(a);
    });
};
