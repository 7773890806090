import { ReportPointBase, captureGraphImages, initGraph } from "./reportBase";
import { nextTick } from "vue";
import { alertSuccessMsg, i18nGlobal } from "@common/js/utils";
import $ from "jquery";
import axios from "@common/js/axios";
import { TrendGraph } from "@core/graphs/js/Dh-graph-trend";
import _ from 'lodash'
import { axiosAuth } from "@common/js/auth";

const VibrationId = 130; // 振动特征值
const TemperatureId = 132; // 温度特征值
export class ReportTemplateTwo extends ReportPointBase {
    constructor(state, props) {
        super(state, props)
    }
    getTrendHistoryPoints(points) {
        let res = [];
        points.forEach((point) => {
            res.push({
                PointId: point.PointId,
                EigenTypes: [point.EigenId],
            });
        });
        return res;
    }
    setReportEntityPath(points, node, imagePath) {
        if (points.length > 0) {
            const eigenId = points[0].EigenId;
            const current = this.reportEntity.Devices.find(t => t.DeviceId === node.Id);
            if (eigenId === VibrationId || eigenId === TemperatureId) {
                current.historyPathDictionary[points[0].EigenName] = imagePath;
            } else {
                current.eigenPathDictionary[points[0].EigenName] = imagePath;
            }
        }
    }
    async createTrendGraph(graphContainer, node, points, title) {
        const graph = new TrendGraph();
        initGraph(graph, title, this.state.graphId);
        const datas = await this.getTrendHistoryData(points);
        const { startTime, endTime } = this.getBaseInfo();
        if (datas) {
            graph.SetData(datas, startTime, endTime);
        } else {
            graph.ClearData();
        }
        const imagePath = await captureGraphImages(graphContainer);
        this.setReportEntityPath(points, node, imagePath);
        graph.Clear();
        graphContainer.empty();
    }
    getFaults(nodeId) {
        return axiosAuth({
            method: "get",
            url: `Devices/${nodeId}/Faults`,
            auth: "Summarize_Browse"
        }).then((res) => {
            if(res.IsSuccess) {
                res.Result.forEach(item => {
                    const param = JSON.parse(item.Param);
                    const advice = param.find(t => t.scheme);
                    if(advice) {
                        const current = this.reportEntity.Devices.find(c => c.DeviceId === nodeId);
                        if(current) {
                            current.faultAdviceDictionary[item.Id] = advice.value;
                        }
                    }
                })
                return true;
            }
        });
    }
    exportReport() {
        nextTick(async () => {
            const graphContainer = $(`#${this.state.graphId}`);
            const devicePoints = this.getCheckDevicePoints();
            this.reportEntity.Devices = [];
            for (let nodeId of devicePoints.keys()) {
                let vibration = [], temperature = [], others = [];
                const node = this.state.devices.find((r) => r.Id == nodeId);
                const points = devicePoints.get(nodeId);
                points.forEach(item => {
                    item.ValueList.forEach(child => {
                        if (child.Id === VibrationId) {
                            vibration.push({ PointId: item.PointId, EigenId: child.Id, EigenName: child.Name, Node: node });
                        } else if (child.Id === TemperatureId) {
                            temperature.push({ PointId: item.PointId, EigenId: child.Id, EigenName: child.Name, Node: node });
                        } else {
                            others.push({ PointId: item.PointId, EigenId: child.Id, EigenName: child.Name, Node: node });
                        }
                    })
                })

                this.reportEntity.Devices.push({ PointIds: points.map(item => item.PointId), DeviceId: node.Id, historyPathDictionary: {}, eigenPathDictionary: {}, faultAdviceDictionary: {} });
                await this.createTrendGraph(graphContainer, node, vibration, i18nGlobal('mainLayout.report.vibration'));
                await this.createTrendGraph(graphContainer, node, temperature, i18nGlobal('mainLayout.report.temperature'));
                const uniqEigens = _.uniqBy(others, 'EigenId')
                for (let i = 0; i < uniqEigens.length; i++) {
                    const item = uniqEigens[i];
                    const p = others.filter(c => c.EigenId === item.EigenId);
                    if (p.length > 0) {
                        await this.createTrendGraph(graphContainer, node, p, p[0].EigenName + i18nGlobal('trendGraph.title'));
                    }
                }
                await this.getFaults(nodeId);
            }

            const { startTime, endTime, title } = this.getBaseInfo();
            this.reportEntity.startTime = startTime;
            this.reportEntity.endTime = endTime;
            this.reportEntity.name = title;

            axios({
                method: "post",
                url: "report/dataAnalysis/export-template2",
                data: this.reportEntity,
            }).then((res) => {
                if(res.IsSuccess) {
                    alertSuccessMsg(i18nGlobal("common.exportSuccess"));
                }
            });
            this.saveParam();
        });

    }
    
}