
import axios from "@common/js/axios";
import store from "@common/js/store";
export class PhmWebStorage {
    constructor() {
        this.db = null;
        this.databaseName = "phm_" + window.location.port;
        this.version = 1;
        this.enable = false;
        this.tableName = 'layouts';
        this.records = new Map();

        this.initialize();
    }
    initialize() {
        const myIndexedDB = window.indexedDB || window.webkitIndexedDB || window.mozIndexedDB || window.msIndexedDB
        if (!myIndexedDB) {
            return console.log('您的浏览器不支持IndexedDB')
        }

        const request = myIndexedDB.open(this.databaseName, this.version);
        request.onerror = (event) => {
            this.enable = false;
            console.log(event);
            console.log('数据库打开报错');
        };
        request.onsuccess = () => {
            this.db = request.result;
            this.enable = true;
        }
        request.onupgradeneeded = (event) => { this.initializeTables(event) };
    }
    initializeTables(event) {
        this.db = event.target.result;
        if (!this.db.objectStoreNames.contains(this.tableName)) {
            this.db.createObjectStore(this.tableName, { keyPath: 'id' });
        }
    }
    async updateLayout(id, plotType, data) {
        const key = id + "_" + plotType + "_" + this.getUserName();
        return new Promise((resolve) => {
            if (!this.enable) {
                return resolve(false);;
            }
            this.getLayout(id, plotType, false).then(d => {
                const objectStore = this.db.transaction([this.tableName], 'readwrite').objectStore(this.tableName);
                let request = null;
                if (d) {
                    request = objectStore.put({ id: key, data: data });
                } else {
                    request = objectStore.add({ id: key, data: data });
                }
                request.onerror = () => resolve(false);
                request.onsuccess = () => resolve(true);
                this.records.set(key, data.Time);
            });
        });
    }
    async getLayout(id, plotType, filter = true) {
        const key = id + "_" + plotType + "_" + this.getUserName();
        return new Promise((resolve) => {
            if (!this.enable) {
                return resolve(false);;
            }
            const objectStore = this.db.transaction([this.tableName]).objectStore(this.tableName);
            const request = objectStore.get(key);
            request.onerror = () => { resolve(false) };
            request.onsuccess = () => {
                if (request.result) {
                    if (filter == false) resolve(request.result.data);
                    else {
                        if (!this.records.has(key) || this.records.get(key) > new Date(request.result.data.Time)) {
                            resolve(null);
                        } else {
                            resolve(request.result.data);
                        }
                    }
                } else {
                    resolve(null);
                }
            }
        });
    }
    async removeLayout(id, plotType) {
        const key = id + "_" + plotType + "_" + this.getUserName();
        return new Promise((resolve) => {
            if (!this.enable) {
                return resolve(false);;
            }
            const objectStore = this.db.transaction([this.tableName], 'readwrite').objectStore(this.tableName);
            const request = objectStore.delete(key);
            request.onerror = () => { resolve(false) };
            request.onsuccess = () => { resolve(true) };
        });
    }
    async getRecords() {
        this.records.clear();
        const res = await axios({
            method: "get",
            url: `/Component/layout/record`,
        });
        if (res.IsSuccess && res.Result?.length > 0) {
            res.Result.forEach(record => {
                this.records.set(`${record.Key}_${record.PlotType}_${record.UserName}`, new Date(record.UpdateTime));
            })
        }
    }
    updateRecord(data) {
        if (data.Records?.length > 0) {
            data.Records.forEach(record => {
                record.PlotTypes.forEach(plotType => {
                    this.records.set(`${record.Key}_${plotType}_${record.UserName}`, new Date(record.UpdateTime));
                })
            });
        }
    }
    getUserName() {
        return store.state.graphSetting.UseUserLayout ? store.state.userInfo.UserName : '';
    }
    IsEnable() {
        return this.enable;
    }
}


export class PhmWebStorageContext {
    static _instance = null;
    static GetInstance() {
        if (PhmWebStorageContext._instance == null) {
            PhmWebStorageContext._instance = new PhmWebStorage();
        }
        return PhmWebStorageContext._instance;
    }
}