import { i18nGlobal } from "@common/js/utils";
import { SingleCursorPlugin } from "./Dh-plugin-single-cursor";
import { GetTHD } from "../algorithm/harmonic-wave";

export class HarmonicCursorPlugin extends SingleCursorPlugin {
    constructor(mode) {
        super(mode);
        this.Title = i18nGlobal('core.harmonicCursor');
        this.HarmonicCursorNum = 3;
    }
    SetHarmonicCursorNum(val) {
        this.HarmonicCursorNum = val;
        this.OnSizeChanged();
    }
    UpdatePosition(data) {
        let rect = this.Owner.Information.ShapeContainerRect;
        if (this.Cursor == null) return;
        this.Cursor.ClearPoints();
        if (data && data != null) {
            let container = this.LegendWnd.find(".cursor-container");
            container.empty();
            container.append(`<span class="cursor-row">${i18nGlobal('dataInput.fundamentalFrequency')}:` + data.XText + "</span>");
            const harmonicDistortionDegreeMap = GetTHD(data.X, this.HarmonicCursorNum, this.Owner.ShapeOperator.Data);

            Array.isArray(data.Items) && data.Items.forEach(d => {
                container.append('<span class="cursor-row" style="color:' + d.Color + `">${i18nGlobal('core.harmonicDistortionDegree')}:` + Number(harmonicDistortionDegreeMap[d.Tag.Source.PointId]).toFixed(2) + "%</span>");
                this.Cursor.DrawPoint(d.OffsetY + rect.Top);
            })
            for (let i = 0; i < this.HarmonicCursorNum; i++) {
                let harmonicCursorData = this.Owner.CoordinateSystem.GetPositionByIndex(data.XIndex * (i + 2));
                const CROSS_SIZE = 8; // 谐光标叉点的大小
                Array.isArray(harmonicCursorData.Items) && harmonicCursorData.Items.forEach(harmonicCursorItem => {
                    let { line1, line2 } = this.Cursor.AddCross(harmonicCursorData.OffsetX - this.CursorData.OffsetX + this.Cursor.LineStartPos.X + CROSS_SIZE / 2, harmonicCursorItem.OffsetY + rect.Top, CROSS_SIZE, CROSS_SIZE, harmonicCursorItem.Color);
                    this.Cursor.Points.push(line1);
                    this.Cursor.Points.push(line2);
                    this.Cursor.DOM.append(line1);
                    this.Cursor.DOM.append(line2);
                })
            }
            this.CursorData = data;
            this.NotifyPositionChanged();
            if (!isNaN(this.CursorData.OffsetX) && isFinite(this.CursorData.OffsetX)) {
                if (this.CursorData.OffsetX >= 0 && Math.floor(this.CursorData.OffsetX) <= rect.Width) {
                    this.Cursor.DOM.removeClass("hide");
                    this.Cursor.DOM.attr("transform", "translate(" + (this.CursorData.OffsetX - 6) + ",0)");
                } else {
                    this.Cursor.DOM.addClass("hide");
                }
            }
        } else {
            let container = this.LegendWnd.find(".cursor-container");
            container.empty();
            this.Cursor.DOM.attr("transform", "translate(-6,0)");
        }
    }
}
