// @generated by protobuf-ts 2.9.1
// @generated from protobuf file "protos/alarmpointseigensdatas.proto" (package "alarm", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { HistoryPointDataReply } from "./historypointdatareply";
import { AlarmPointEigensRequest } from "./alarmpointeigens";
/**
 * @generated from protobuf message alarm.QueryAlarmPointsEigensDatasRequest
 */
export interface QueryAlarmPointsEigensDatasRequest {
    /**
     * @generated from protobuf field: string EventId = 1 [json_name = "EventId"];
     */
    eventId: string;
    /**
     * @generated from protobuf field: repeated alarm.AlarmPointEigensRequest PointEigens = 2 [json_name = "PointEigens"];
     */
    pointEigens: AlarmPointEigensRequest[];
}
/**
 * @generated from protobuf message alarm.QueryAlarmPointsEigensDatasReply
 */
export interface QueryAlarmPointsEigensDatasReply {
    /**
     * @generated from protobuf field: repeated storage.HistoryPointDataReply Points = 1 [json_name = "Points"];
     */
    points: HistoryPointDataReply[];
}
// @generated message type with reflection information, may provide speed optimized methods
class QueryAlarmPointsEigensDatasRequest$Type extends MessageType<QueryAlarmPointsEigensDatasRequest> {
    constructor() {
        super("alarm.QueryAlarmPointsEigensDatasRequest", [
            { no: 1, name: "EventId", kind: "scalar", jsonName: "EventId", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "PointEigens", kind: "message", jsonName: "PointEigens", repeat: 1 /*RepeatType.PACKED*/, T: () => AlarmPointEigensRequest }
        ]);
    }
    create(value?: PartialMessage<QueryAlarmPointsEigensDatasRequest>): QueryAlarmPointsEigensDatasRequest {
        const message = { eventId: "", pointEigens: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<QueryAlarmPointsEigensDatasRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: QueryAlarmPointsEigensDatasRequest): QueryAlarmPointsEigensDatasRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string EventId = 1 [json_name = "EventId"];*/ 1:
                    message.eventId = reader.string();
                    break;
                case /* repeated alarm.AlarmPointEigensRequest PointEigens = 2 [json_name = "PointEigens"];*/ 2:
                    message.pointEigens.push(AlarmPointEigensRequest.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: QueryAlarmPointsEigensDatasRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string EventId = 1 [json_name = "EventId"]; */
        if (message.eventId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.eventId);
        /* repeated alarm.AlarmPointEigensRequest PointEigens = 2 [json_name = "PointEigens"]; */
        for (let i = 0; i < message.pointEigens.length; i++)
            AlarmPointEigensRequest.internalBinaryWrite(message.pointEigens[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message alarm.QueryAlarmPointsEigensDatasRequest
 */
export const QueryAlarmPointsEigensDatasRequest = new QueryAlarmPointsEigensDatasRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class QueryAlarmPointsEigensDatasReply$Type extends MessageType<QueryAlarmPointsEigensDatasReply> {
    constructor() {
        super("alarm.QueryAlarmPointsEigensDatasReply", [
            { no: 1, name: "Points", kind: "message", jsonName: "Points", repeat: 1 /*RepeatType.PACKED*/, T: () => HistoryPointDataReply }
        ]);
    }
    create(value?: PartialMessage<QueryAlarmPointsEigensDatasReply>): QueryAlarmPointsEigensDatasReply {
        const message = { points: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<QueryAlarmPointsEigensDatasReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: QueryAlarmPointsEigensDatasReply): QueryAlarmPointsEigensDatasReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated storage.HistoryPointDataReply Points = 1 [json_name = "Points"];*/ 1:
                    message.points.push(HistoryPointDataReply.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: QueryAlarmPointsEigensDatasReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated storage.HistoryPointDataReply Points = 1 [json_name = "Points"]; */
        for (let i = 0; i < message.points.length; i++)
            HistoryPointDataReply.internalBinaryWrite(message.points[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message alarm.QueryAlarmPointsEigensDatasReply
 */
export const QueryAlarmPointsEigensDatasReply = new QueryAlarmPointsEigensDatasReply$Type();
