<template>
	<el-container class="outContainer">
		<el-header :class="getCardClass"
			:style="{ backgroundColor: primaryColor, height: state.headerHeight + 'px' }" v-show="!shrink">
			<headerVue ref="headerRef"></headerVue>
		</el-header>
		<el-container v-if="showPlatForm">
			<platFormContainer @setTab="setTab"></platFormContainer>
		</el-container>
		<el-container v-show="!showPlatForm">
			<el-aside width="260px" v-show="!shrink" @mouseover="state.hover = true" @mouseleave="state.hover = false">
				<asideVue ref="asideRef" @nodeClick="hidePopover"></asideVue>
			</el-aside>
			<el-main>
				<mainContentVue ref="contentRef" @treeChange="treeChange"></mainContentVue>
			</el-main>
			<div class="nav-collspan" :class="{ hide: !shrink && state.hover == false }" @mouseover="state.hover = true"
				@mouseleave="state.hover = false" v-if="state.showExpand" :style="{ left: shrink ? '0px' : '240px' }">
				<svgIcon @click="expand" class="expand" v-if="shrink" :color="primaryColor" width="20px" height="20px"
					name="expand" :title="$t('dataProcessUnit.expand')" :hover="false"> </svgIcon>
				<svgIcon @click="collspanLeft" class="collspan" v-else :color="primaryColor" width="20px" height="20px"
					name="collspan" :title="$t('dataProcessUnit.collspan')" :hover="false"></svgIcon>
			</div>
		</el-container>
		<msgList v-if="state.msgs.length > 0" :data="state.msgs" @remove="removeMsg"></msgList>
	</el-container>
</template>

<script setup>
import headerVue from "./header.vue";
import asideVue from "./aside.vue";
import mainContentVue from "./mainContent.vue";
import { ref, reactive, nextTick, computed, onMounted, onBeforeUnmount, onBeforeMount, watch } from "vue";
import { useStore } from "vuex";
import platFormContainer from "@views/platformSetting/platFormContainer.vue";
import { SignalRInstance, ISignalRHandler } from "@common/js/signalRClient";
import { ClientRealContext } from "@core/js/clientRealContext";
import { setPhmTheme, setPhmPrimaryColor, i18nGlobal, guid, Debounce, fixedThemes } from "@common/js/utils";
import { ThemeResource } from "@components/js/utils/model";
import axios from "@common/js/axios";
import { ParentGuid } from "@common/js/enum";
import svgIcon from "@common/components/svgIcon.vue";
import msgList from "@common/components/msgList.vue";
import { LabelPointAlarmClinetInstance } from "@components/js/utils/labelPointAlarmClinet";
import { DotImageResourceContext } from "@core/graphs/shapes/Dh-shape-dots";
import { ContractTypes } from "@common/js/enum";
import { axiosAuth } from "@common/js/auth";

const asideRef = ref(null);
const headerRef = ref(null);
const contentRef = ref(null);

let signarlCache = {};
const store = useStore();
const showPlatForm = computed(() => store.state.showPlatForm);
const primaryColor = computed(() => store.state.primaryColor);
const shrink = computed(() => store.state.shrink);
const state = reactive({
	headerHeight: 30,
	showExpand: true,
	hover: false,
	msgs: [],
});

const getCardClass = computed(() => {
	const headerTheme = window.Config.uiConfig.headerTheme;
	const cardColor = store.state.customColor.CardColor?.toLowerCase()
	if (headerTheme === 2) return 'card';
	if (headerTheme === 0 && store.state.customColor.GraphTheme && cardColor === '#020c34') return 'card';
	return 'theme';
})

const collspanLeft = () => {
	store.commit("setShrink", { shrink: true });
};

const expand = () => {
	store.commit("setShrink", { shrink: false });
};

const hidePopover = () => {
	headerRef.value?.hidePopover();
};

const removeMsg = (data) => {
	const index = state.msgs.findIndex(t => t.id === data.id);
	state.msgs.splice(index, 1);
}

// 右侧按钮操作后通知树进行更新
const treeChange = (obj) => {
	asideRef.value.updateTree(obj);
};

const setTab = () => {
	contentRef.value?.turnToPage();
};

const disposeResource = () => {
	store.commit("setCurrentDep", { currentDep: {} });
	signarlCache.MessagePush?.Dispose();
	LabelPointAlarmClinetInstance.Clear();
	ClientRealContext.Clear();
	SignalRInstance.Clear();
	DotImageResourceContext.Clear();
	window.removeEventListener("beforeunload", beforeUnMount);
	window.removeEventListener("unload", unload);
	window.MachineTree = null;
	window.Header = null;
	window.MainContent = null;
	window.onNavigateChanged = null;
	window.onUnitRemoved = null;
	window.onRefresh = null;
	window.threedModels = null;
	window.onExpandBtnChanged = null;
};

const setPrimaryColor = (color) => {
	store.commit("setPrimaryColor", { primaryColor: color });
};

const setTheme = (theme) => {
	const data = { ...theme };
	delete data.primary;
	store.commit("setThemeColor", { theme: data });
};

const getAlarmConfig = () => {
	return axiosAuth({
		method: "get",
		url: "AlarmConfig",
		auth: 'AlarmConfigSett_Browse'
	}).then((res) => {
		if (res.IsSuccess) {
			store.commit('setAlarmConfig', { alarmConfig: res.Result });
		}
	});
};

const getAlarmRelateInfo = () => {
	axiosAuth({
		method: "get",
		url: "Alarm/GetAlarmInfoRelated",
		auth: "AlarmInfo_Browse"
	}).then((res) => {
		if (res.IsSuccess) {
			store.commit('setAlarmRelateInfo', { alarmRelateInfo: res.Result });
		}
	})
}

const getThemes = () => {
	// no permisson control
	axios({
		method: "get",
		url: `/Theme/curtheme/${store.state.userInfo.UserId}`,
	}).then((res) => {
		if (res.IsSuccess) updateTheme(res.Result);
	});
};

const updateTheme = (theme) => {
	if (theme) {
		if (theme.Id === ParentGuid) {
			setPrimaryColor(fixedThemes[0].ThemeColor);
			setTheme({ ...fixedThemes[0], GraphTheme: theme.GraphTheme });
		} else if (theme.Id === "00000000-0000-0000-0000-000000000001") {
			setPrimaryColor(fixedThemes[1].ThemeColor);
			setTheme({ ...fixedThemes[1], GraphTheme: theme.GraphTheme });
		} else {
			setPrimaryColor(theme.ThemeColor);
			setTheme(theme);
		}
	} else {
		setPrimaryColor(fixedThemes[0].ThemeColor);
		setTheme({ ...fixedThemes[0], GraphTheme: false });
	}
	nextTick(() => {
		setPhmPrimaryColor(store.state.primaryColor, store);
		setPhmTheme(store.state.customColor, store);
	});
}

class MessagePushSignalRHandler extends ISignalRHandler {
	constructor() {
		super();
		this.SupportContentType = [ContractTypes.MessagePush];
		this.Debounce = new Debounce();
	}

	ReceiveData(data) {
		if (data.ContentType == ContractTypes.MessagePush) {
			if (data.Content != null) {
				let datas = JSON.parse(data.Content[0].Data);
				state.msgs.push({
					text: datas.Message,
					type: 'warning',
					id: guid()
				})
			}
		}
	}
}

const initGraphDots = async () => {
	const dotResource = DotImageResourceContext.GetInstance();
	for (const key in ThemeResource) {
		for (const color of ThemeResource[key].Colors()) {
			await dotResource.get(color);
		}
	}
}

const beforeUnMount = (e) => {
	if (Config.showCloseTip) {
		e.returnValue = 'close'
	} else {
		disposeResource();
	}
}

const unload = () => {
	if (Config.showCloseTip) {
		disposeResource();
	}
}

onBeforeMount(() => {
	if (store.state.customColor.ThemeColor) {
		updateTheme(store.state.customColor);
	}
});

onMounted(() => {
	state.headerHeight = store.state.licenseInfo?.TitleHeight ?? 30;
	window.MachineTree = asideRef.value;
	window.Header = headerRef.value;
	window.MainContent = contentRef.value;
	window.onNavigateChanged = new Map();
	window.onUnitRemoved = new Map();
	window.onRefresh = new Map();
	window.threedModels = new Map();
	state.showExpand = window.showExpandBtn == null ? true : window.showExpandBtn;
	window.onExpandBtnChanged = (v) => {
		state.showExpand = v;
	}
	store.dispatch("getEigens");
	store.dispatch("getAllDepartmentType");
	store.dispatch("getAllDeviceLevels");
	store.dispatch("getDepartDeviceConfig");
	store.dispatch("getTree");
	document.oncontextmenu = function (e) {
		return false;
	};
	getThemes();
	getAlarmConfig();
	getAlarmRelateInfo();
	initGraphDots();
	SignalRInstance.initial();
	ClientRealContext.initial();
	LabelPointAlarmClinetInstance.initial();
	nextTick(() => {
		const signalr = SignalRInstance.GetInstance();
		signarlCache.MessagePush = new MessagePushSignalRHandler();
		signalr?.Add(signarlCache.MessagePush);
	});
	window.addEventListener('beforeunload', beforeUnMount)
	window.addEventListener("unload", unload);
});

</script>

<style scoped lang="less">
@import "@styles/variable.less";

.outContainer {
	height: 100%;
	position: relative;

	.el-header {
		&.card {
			background-color: var(--phm-theme-card) !important;
		}

		&.theme {
			background-color: var(--phm-primary-color) !important;
		}
	}

	.el-container {
		flex: 1;
		overflow: auto;

		// height: auto;
		height: calc(100% - 30px);

		.el-aside {
			background-color: var(--phm-theme-card);
			border-right: 1px solid var(--phm-theme-border);
		}

		.el-main {
			padding: 0;
			background-color: var(--phm-theme-background);
			display: flex;
			flex-direction: column;
			max-height: 100%;
			overflow: hidden;
		}
	}
}

.nav-collspan {
	position: absolute;
	top: 114px;
	width: 20px;
	height: 60px;
	z-index: 999;

	.expand,
	.collspan {
		display: none;
	}

	&:hover {

		.expand,
		.collspan {
			display: block;
		}
	}
}</style>
