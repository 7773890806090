// @generated by protobuf-ts 2.9.1
// @generated from protobuf file "protos/component.proto" (package "component", syntax proto3)
// tslint:disable
import { HistoryHealthDatasRequest } from "./historyhealthdatas";
import { GetTorvibGraphDatasRequest } from "./torvibgraphdatas";
import { GetParamTwoPointFirstDatasRequest } from "./paramtwopointfirstdatas";
import { GetParamTwoPointNearestDatasRequest } from "./paramtwopointnearestdatas";
import { GetParamTwoPointDatasRequest } from "./paramtwopointdatas";
import { GetGraphFirstDatasRequest } from "./graphfirstdatas";
import { GetGraphNearestDatasRequest } from "./graphnearestdatas";
import { GetGraphDatasRequest } from "./graphdatas";
import { GetTwiceDifferentialGraphDataRequest } from "./twicedifferentialgraphdata";
import { GetEnvelopSpecGraphDataRequest } from "./envelopspecgraphdata";
import { GetEnvelopGraphDataRequest } from "./envelopgraphdata";
import { GetOnceDifferentialGraphDataRequest } from "./oncedifferentialgraphdata";
import { GetTwiceIntegralGraphDataRequest } from "./twiceintegralgraphdata";
import { GetOnceIntegralGraphDataRequest } from "./onceIntegralgraphdata";
import { GetFIRGraphDataRequest } from "./firgraphdata";
import { Reply } from "./reply";
import { GetFrGraphDataRequest } from "./frgraphdata";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
/**
 * @generated ServiceType for protobuf service component.Component
 */
export const Component = new ServiceType("component.Component", [
    { name: "GetFrGraphData", options: {}, I: GetFrGraphDataRequest, O: Reply },
    { name: "GetFIRGraphData", options: {}, I: GetFIRGraphDataRequest, O: Reply },
    { name: "GetOnceIntegralGraphData", options: {}, I: GetOnceIntegralGraphDataRequest, O: Reply },
    { name: "GetTwiceIntegralGraphData", options: {}, I: GetTwiceIntegralGraphDataRequest, O: Reply },
    { name: "GetOnceDifferentialGraphData", options: {}, I: GetOnceDifferentialGraphDataRequest, O: Reply },
    { name: "GetEnvelopGraphData", options: {}, I: GetEnvelopGraphDataRequest, O: Reply },
    { name: "GetEnvelopSpecGraphData", options: {}, I: GetEnvelopSpecGraphDataRequest, O: Reply },
    { name: "GetTwiceDifferentialGraphData", options: {}, I: GetTwiceDifferentialGraphDataRequest, O: Reply },
    { name: "QueryWholePeriodDatas", options: {}, I: GetGraphDatasRequest, O: Reply },
    { name: "QueryLatestSpectralDatas", options: {}, I: GetGraphDatasRequest, O: Reply },
    { name: "QueryBodePlotsDatas", options: {}, I: GetGraphDatasRequest, O: Reply },
    { name: "QueryPolarDatas", options: {}, I: GetGraphDatasRequest, O: Reply },
    { name: "QueryWholePeriodAlarmNearestDatas", options: {}, I: GetGraphNearestDatasRequest, O: Reply },
    { name: "QueryWholePeriodAlarmPreDatas", options: {}, I: GetGraphNearestDatasRequest, O: Reply },
    { name: "QueryWholePeriodAlarmNextDatas", options: {}, I: GetGraphNearestDatasRequest, O: Reply },
    { name: "QueryBodeAlarmDatas", options: {}, I: GetGraphNearestDatasRequest, O: Reply },
    { name: "QueryPolarAlarmDatas", options: {}, I: GetGraphNearestDatasRequest, O: Reply },
    { name: "QueryWholePeriodFirstData", options: {}, I: GetGraphFirstDatasRequest, O: Reply },
    { name: "QueryAxisPositionDatas", options: {}, I: GetParamTwoPointDatasRequest, O: Reply },
    { name: "QueryAxisTrailDatas", options: {}, I: GetParamTwoPointDatasRequest, O: Reply },
    { name: "QueryAxisPosAlarmDatas", options: {}, I: GetParamTwoPointNearestDatasRequest, O: Reply },
    { name: "QueryAxisTrailAlarmNearestDatas", options: {}, I: GetParamTwoPointNearestDatasRequest, O: Reply },
    { name: "QueryAxisTrailAlarmPreDatas", options: {}, I: GetParamTwoPointNearestDatasRequest, O: Reply },
    { name: "QueryAxisTrailAlarmNextDatas", options: {}, I: GetParamTwoPointNearestDatasRequest, O: Reply },
    { name: "QueryAxisTrailFirstData", options: {}, I: GetParamTwoPointFirstDatasRequest, O: Reply },
    { name: "QueryTorvibDatas", options: {}, I: GetTorvibGraphDatasRequest, O: Reply },
    { name: "QueryHistoryHealthDatasAsync", options: {}, I: HistoryHealthDatasRequest, O: Reply }
]);
