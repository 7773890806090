// @generated by protobuf-ts 2.9.1
// @generated from protobuf file "protos/graphdatas.proto" (package "component", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { HistoryPointDataReply } from "./historypointdatareply";
import { GraphParamRequest } from "./graphparamrequest";
import { GetDatasRequest } from "./getdatas";
/**
 * @generated from protobuf message component.GetGraphDatasRequest
 */
export interface GetGraphDatasRequest {
    /**
     * @generated from protobuf field: storage.GetDatasRequest Conndition = 1 [json_name = "Conndition"];
     */
    conndition?: GetDatasRequest;
    /**
     * @generated from protobuf field: component.GraphParamRequest GraphParam = 2 [json_name = "GraphParam"];
     */
    graphParam?: GraphParamRequest;
    /**
     * @generated from protobuf field: bool IncludeAlarm = 3 [json_name = "IncludeAlarm"];
     */
    includeAlarm: boolean;
}
/**
 * @generated from protobuf message component.GetGraphDatasReply
 */
export interface GetGraphDatasReply {
    /**
     * @generated from protobuf field: repeated storage.HistoryPointDataReply Points = 1 [json_name = "Points"];
     */
    points: HistoryPointDataReply[];
}
// @generated message type with reflection information, may provide speed optimized methods
class GetGraphDatasRequest$Type extends MessageType<GetGraphDatasRequest> {
    constructor() {
        super("component.GetGraphDatasRequest", [
            { no: 1, name: "Conndition", kind: "message", jsonName: "Conndition", T: () => GetDatasRequest },
            { no: 2, name: "GraphParam", kind: "message", jsonName: "GraphParam", T: () => GraphParamRequest },
            { no: 3, name: "IncludeAlarm", kind: "scalar", jsonName: "IncludeAlarm", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<GetGraphDatasRequest>): GetGraphDatasRequest {
        const message = { includeAlarm: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetGraphDatasRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetGraphDatasRequest): GetGraphDatasRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* storage.GetDatasRequest Conndition = 1 [json_name = "Conndition"];*/ 1:
                    message.conndition = GetDatasRequest.internalBinaryRead(reader, reader.uint32(), options, message.conndition);
                    break;
                case /* component.GraphParamRequest GraphParam = 2 [json_name = "GraphParam"];*/ 2:
                    message.graphParam = GraphParamRequest.internalBinaryRead(reader, reader.uint32(), options, message.graphParam);
                    break;
                case /* bool IncludeAlarm = 3 [json_name = "IncludeAlarm"];*/ 3:
                    message.includeAlarm = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetGraphDatasRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* storage.GetDatasRequest Conndition = 1 [json_name = "Conndition"]; */
        if (message.conndition)
            GetDatasRequest.internalBinaryWrite(message.conndition, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* component.GraphParamRequest GraphParam = 2 [json_name = "GraphParam"]; */
        if (message.graphParam)
            GraphParamRequest.internalBinaryWrite(message.graphParam, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* bool IncludeAlarm = 3 [json_name = "IncludeAlarm"]; */
        if (message.includeAlarm !== false)
            writer.tag(3, WireType.Varint).bool(message.includeAlarm);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message component.GetGraphDatasRequest
 */
export const GetGraphDatasRequest = new GetGraphDatasRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetGraphDatasReply$Type extends MessageType<GetGraphDatasReply> {
    constructor() {
        super("component.GetGraphDatasReply", [
            { no: 1, name: "Points", kind: "message", jsonName: "Points", repeat: 1 /*RepeatType.PACKED*/, T: () => HistoryPointDataReply }
        ]);
    }
    create(value?: PartialMessage<GetGraphDatasReply>): GetGraphDatasReply {
        const message = { points: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetGraphDatasReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetGraphDatasReply): GetGraphDatasReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated storage.HistoryPointDataReply Points = 1 [json_name = "Points"];*/ 1:
                    message.points.push(HistoryPointDataReply.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetGraphDatasReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated storage.HistoryPointDataReply Points = 1 [json_name = "Points"]; */
        for (let i = 0; i < message.points.length; i++)
            HistoryPointDataReply.internalBinaryWrite(message.points[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message component.GetGraphDatasReply
 */
export const GetGraphDatasReply = new GetGraphDatasReply$Type();
