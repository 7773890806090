import { DataParserContext } from "@core/js/dataParserContext";
import { PluginMode, ScaleType } from "@core/graphs/webcharts/Dh-webchart-models";
import { fullScreenLoading, getDeepClone, i18nGlobal, alertErrorMsg } from "@common/js/utils";
import html2canvas from "html2canvas";
import { Canvg } from "canvg";
import axios from "@common/js/axios";
import { nextTick } from "vue";
import $ from "jquery";
import { EnumQueryDataType } from "@components/js/utils/model";
import { GrpcApiContext } from "@grpc/grpcApi";
import store from "@common/js/store";

export const initGraph = (graph, title, graphId) => {
    const option = {
        Id: graphId,
        Axis: {
            Title: title,
            AxisScaleType: ScaleType.Auto,
            ShowDot: true,
            Theme: 1,
        },
    };
    const plugins = [
        { Type: PluginMode.YAxisZoomOut },
        { Type: PluginMode.YAxisZoomIn },
        { Type: PluginMode.XAxisZoomOut },
        { Type: PluginMode.XAxisZoomIn },
        { Type: PluginMode.SingleCursor },
        { Type: PluginMode.Statistics },
    ];
    graph.Init(option, plugins);
    const array = [];
    plugins
        .filter((r) => r.Type >= 100)
        .map((item) => {
            array.push(item.Type);
        });
    graph.GetPluginManager().OpenPlugin(array);
    graph.GetPluginManager().OpenPlugin([PluginMode.SingleCursor]);
    graph.OnSizeChanged();
}

export const captureGraphImages = async (graphContainer) => {
    let svgMaps = new Array();
    for (let svg of graphContainer.find("svg")) {
        const svgHtml = svg.outerHTML.trim();
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        let v = await Canvg.from(ctx, svgHtml, {
            ignoreAnimation: false,
            log: true,
        });
        v.start();
        $(svg).after($(canvas));
        $(svg).addClass("hide");
        svgMaps.push({
            svg: $(svg),
            canvas: $(canvas),
        });
    }
    return new Promise(function (resolve, reject) {
        const opts = {
            scale: 1,
            height: graphContainer.height(),
            windowHeight: graphContainer.width(),
        };
        html2canvas(graphContainer[0], opts).then(async function (canvas) {
            let myImage = canvas.toDataURL("image/png");
            svgMaps.map((item) => {
                item.canvas.remove();
                item.svg.removeClass("hide");
            });
            svgMaps = [];
            const res = await axios({
                method: "post",
                url: `workstate/getImagePath`,
                data: {
                    ImageSource: myImage.replace(/^data:image\/(png|jpg|jpeg);base64,/, ""),
                },
            });
            if (res?.Result != null) {
                resolve(res.Result);
            } else {
                reject(new Error(i18nGlobal("mainLayout.report.FailedToSaveMap")));
            }
        });
    });
}
export class ReportBase {
    constructor(state, props) {
        this.state = state;
        this.props = props;
        this.reportEntity = {};
    }
    sureExcute() { }
    saveParam() { }
    getBaseInfo() {
        return {
            startTime: this.props.startTime,
            endTime: this.props.endTime,
            title: this.props.title,
            templateType: this.props.templateType,
            enitiyId: this.props.enitiyId
        }
    }
    closeModal() {
        this.props.reportDialog = false;
    }
}

export class ReportPointBase extends ReportBase {
    constructor(state, props) {
        super(state, props)
    }
    getCheckPoints() {
        let datas = this.getCheckDevicePoints();
        const result = [];
        for (let data of datas.values()) {
            result.push(...data);
        }
        return result;
    }
    getCheckDevicePoints() {
        let datas = new Map();
        for (let device of this.state.devices.filter((r) => r.isSelect)) {
            datas.set(device.Id, []);
            if (device.points) {
                for (let point of device.points.filter((r) => r.isSelect)) {
                    var eigens = new Array();
                    for (let eigen of point.Eigens) {
                        eigens.push({
                            Id: eigen.Id,
                            Name: eigen.Name,
                        });
                    }
                    datas.get(device.Id).push({
                        PointId: point.Id,
                        DeviceId: device.Id,
                        Name: point.Name,
                        ValueList: eigens,
                    });
                }
            } else {
                let temp = this.state.datas.filter((r) => r.DeviceId == device.Id);
                for (let p of temp) {
                    datas.get(device.Id).push(getDeepClone(p));
                }
            }
        }
        return datas;
    }
    sureExcute() {
        if (this.getCheckPoints().length === 0) {
            return alertErrorMsg(i18nGlobal("pointSetting.selectPoint"));
        }
        this.props.currentStep = 2;
        this.props.clickModel = false;
        nextTick(() => {
            this.exportReport();
        })
    }
    async getTrendHistoryData(points) {
        const { startTime, endTime } = this.getBaseInfo();
        const condition = {
            StartTime: startTime,
            EndTime: endTime,
            DataType: EnumQueryDataType.Eigen,
            Points: [],
        };
        condition.Points = this.getTrendHistoryPoints(points);
        const loading = fullScreenLoading(i18nGlobal("common.query"));
        const res = await GrpcApiContext.GetInstance().getDatas(condition).finally(() => {
            loading.close();
        });
        if (res?.length > 0) return res;
        return null;
    }
    parseDatas(datas) {
        const dataRet = [];
        datas.filter(item => item.Datas.Datas).forEach(data => {
            const temp = {
                PointId: data.PointId,
                AlgorithmType: data.DspType,
                RotateSpeed: data.RotateSpeed,
                SampleTime: Number(data.SampleTime),
                CursorInfo: data.CursorInfo,
                Eigens: [],
                AlarmParams: data.AlarmParams,
                BlockSize: data.BlockSize,
                SampleFreq: data.SampleFreq,
                DataOffset: 0,
                Param: data.Param
            }
            if (data.EigenType) {
                temp.Eigens.push({
                    EigenType: data.EigenType.key,
                    EigenName: data.EigenType.value,
                    Unit: data.EigenType.unit
                });
                temp.EigenType = data.EigenType;
            }
            temp.Datas = DataParserContext.GetInstance().get(0).GetDatas(data.Datas, data);
            dataRet.push(temp);
        });
        return dataRet;
    }
    getSaveParam() {
        const { title, startTime, endTime, templateType, enitiyId } = this.getBaseInfo();
        const condition = {
            Id: enitiyId,
            StartTime: startTime,
            EndTime: endTime,
            Name: title,
            PlotType: 1,
            DeviceId: store.state.currentDep.Id,
            ReportPoints: this.getCheckPoints(),
            TemplateType: templateType
        };
        return condition;
    }
    saveParam() {
        const condition = this.getSaveParam();
        axios({
            method: "put",
            url: "report/dataAnalysis",
            data: condition,
        }).then((res) => { }).finally(() => {
            this.props.clickModel = true;
            this.closeModal()
        });
    }

}
