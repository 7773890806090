// @generated by protobuf-ts 2.9.1
// @generated from protobuf file "protos/historypointdatasreply.proto" (package "storage", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { PointParamReply } from "./basereply";
import { DspParamReply } from "./basereply";
import { HistoryPointDataReply } from "./historypointdatareply";
import { HistoryDataReply } from "./basereply";
import { CursorInfoReply } from "./basereply";
import { DataParamReply } from "./basereply";
import { EigenInfoReply } from "./basereply";
import { DoubleValue } from "../google/protobuf/wrappers";
/**
 * @generated from protobuf message storage.HistoryPointDatasReply
 */
export interface HistoryPointDatasReply {
    /**
     * @generated from protobuf field: int32 PointId = 1 [json_name = "PointId"];
     */
    pointId: number;
    /**
     * @generated from protobuf field: google.protobuf.DoubleValue RotateSpeed = 2 [json_name = "RotateSpeed"];
     */
    rotateSpeed?: DoubleValue;
    /**
     * @generated from protobuf field: storage.EigenInfoReply EigenType = 3 [json_name = "EigenType"];
     */
    eigenType?: EigenInfoReply;
    /**
     * @generated from protobuf field: storage.DataParamReply Param = 4 [json_name = "Param"];
     */
    param?: DataParamReply;
    /**
     * @generated from protobuf field: storage.CursorInfoReply CursorInfo = 5 [json_name = "CursorInfo"];
     */
    cursorInfo?: CursorInfoReply;
    /**
     * @generated from protobuf field: repeated storage.HistoryDataReply PointDatas = 6 [json_name = "PointDatas"];
     */
    pointDatas: HistoryDataReply[];
    /**
     * @generated from protobuf field: int32 DataCount = 7 [json_name = "DataCount"];
     */
    dataCount: number;
    /**
     * @generated from protobuf field: storage.HistoryPointDataReply FFTDatas = 8 [json_name = "FFTDatas"];
     */
    fFTDatas?: HistoryPointDataReply;
    /**
     * @generated from protobuf field: int32 DspType = 9 [json_name = "DspType"];
     */
    dspType: number;
    /**
     * @generated from protobuf field: float Freq = 10 [json_name = "Freq"];
     */
    freq: number;
    /**
     * @generated from protobuf field: int32 SampleType = 11 [json_name = "SampleType"];
     */
    sampleType: number;
    /**
     * @generated from protobuf field: storage.DspParamReply DspParam = 12 [json_name = "DspParam"];
     */
    dspParam?: DspParamReply;
    /**
     * @generated from protobuf field: int32 BlockSize = 13 [json_name = "BlockSize"];
     */
    blockSize: number;
    /**
     * @generated from protobuf field: storage.PointParamReply PointParam = 14 [json_name = "PointParam"];
     */
    pointParam?: PointParamReply;
}
// @generated message type with reflection information, may provide speed optimized methods
class HistoryPointDatasReply$Type extends MessageType<HistoryPointDatasReply> {
    constructor() {
        super("storage.HistoryPointDatasReply", [
            { no: 1, name: "PointId", kind: "scalar", jsonName: "PointId", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "RotateSpeed", kind: "message", jsonName: "RotateSpeed", T: () => DoubleValue },
            { no: 3, name: "EigenType", kind: "message", jsonName: "EigenType", T: () => EigenInfoReply },
            { no: 4, name: "Param", kind: "message", jsonName: "Param", T: () => DataParamReply },
            { no: 5, name: "CursorInfo", kind: "message", jsonName: "CursorInfo", T: () => CursorInfoReply },
            { no: 6, name: "PointDatas", kind: "message", jsonName: "PointDatas", repeat: 1 /*RepeatType.PACKED*/, T: () => HistoryDataReply },
            { no: 7, name: "DataCount", kind: "scalar", jsonName: "DataCount", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "FFTDatas", kind: "message", jsonName: "FFTDatas", T: () => HistoryPointDataReply },
            { no: 9, name: "DspType", kind: "scalar", jsonName: "DspType", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "Freq", kind: "scalar", jsonName: "Freq", T: 2 /*ScalarType.FLOAT*/ },
            { no: 11, name: "SampleType", kind: "scalar", jsonName: "SampleType", T: 5 /*ScalarType.INT32*/ },
            { no: 12, name: "DspParam", kind: "message", jsonName: "DspParam", T: () => DspParamReply },
            { no: 13, name: "BlockSize", kind: "scalar", jsonName: "BlockSize", T: 5 /*ScalarType.INT32*/ },
            { no: 14, name: "PointParam", kind: "message", jsonName: "PointParam", T: () => PointParamReply }
        ]);
    }
    create(value?: PartialMessage<HistoryPointDatasReply>): HistoryPointDatasReply {
        const message = { pointId: 0, pointDatas: [], dataCount: 0, dspType: 0, freq: 0, sampleType: 0, blockSize: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<HistoryPointDatasReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: HistoryPointDatasReply): HistoryPointDatasReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 PointId = 1 [json_name = "PointId"];*/ 1:
                    message.pointId = reader.int32();
                    break;
                case /* google.protobuf.DoubleValue RotateSpeed = 2 [json_name = "RotateSpeed"];*/ 2:
                    message.rotateSpeed = DoubleValue.internalBinaryRead(reader, reader.uint32(), options, message.rotateSpeed);
                    break;
                case /* storage.EigenInfoReply EigenType = 3 [json_name = "EigenType"];*/ 3:
                    message.eigenType = EigenInfoReply.internalBinaryRead(reader, reader.uint32(), options, message.eigenType);
                    break;
                case /* storage.DataParamReply Param = 4 [json_name = "Param"];*/ 4:
                    message.param = DataParamReply.internalBinaryRead(reader, reader.uint32(), options, message.param);
                    break;
                case /* storage.CursorInfoReply CursorInfo = 5 [json_name = "CursorInfo"];*/ 5:
                    message.cursorInfo = CursorInfoReply.internalBinaryRead(reader, reader.uint32(), options, message.cursorInfo);
                    break;
                case /* repeated storage.HistoryDataReply PointDatas = 6 [json_name = "PointDatas"];*/ 6:
                    message.pointDatas.push(HistoryDataReply.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 DataCount = 7 [json_name = "DataCount"];*/ 7:
                    message.dataCount = reader.int32();
                    break;
                case /* storage.HistoryPointDataReply FFTDatas = 8 [json_name = "FFTDatas"];*/ 8:
                    message.fFTDatas = HistoryPointDataReply.internalBinaryRead(reader, reader.uint32(), options, message.fFTDatas);
                    break;
                case /* int32 DspType = 9 [json_name = "DspType"];*/ 9:
                    message.dspType = reader.int32();
                    break;
                case /* float Freq = 10 [json_name = "Freq"];*/ 10:
                    message.freq = reader.float();
                    break;
                case /* int32 SampleType = 11 [json_name = "SampleType"];*/ 11:
                    message.sampleType = reader.int32();
                    break;
                case /* storage.DspParamReply DspParam = 12 [json_name = "DspParam"];*/ 12:
                    message.dspParam = DspParamReply.internalBinaryRead(reader, reader.uint32(), options, message.dspParam);
                    break;
                case /* int32 BlockSize = 13 [json_name = "BlockSize"];*/ 13:
                    message.blockSize = reader.int32();
                    break;
                case /* storage.PointParamReply PointParam = 14 [json_name = "PointParam"];*/ 14:
                    message.pointParam = PointParamReply.internalBinaryRead(reader, reader.uint32(), options, message.pointParam);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: HistoryPointDatasReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 PointId = 1 [json_name = "PointId"]; */
        if (message.pointId !== 0)
            writer.tag(1, WireType.Varint).int32(message.pointId);
        /* google.protobuf.DoubleValue RotateSpeed = 2 [json_name = "RotateSpeed"]; */
        if (message.rotateSpeed)
            DoubleValue.internalBinaryWrite(message.rotateSpeed, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* storage.EigenInfoReply EigenType = 3 [json_name = "EigenType"]; */
        if (message.eigenType)
            EigenInfoReply.internalBinaryWrite(message.eigenType, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* storage.DataParamReply Param = 4 [json_name = "Param"]; */
        if (message.param)
            DataParamReply.internalBinaryWrite(message.param, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* storage.CursorInfoReply CursorInfo = 5 [json_name = "CursorInfo"]; */
        if (message.cursorInfo)
            CursorInfoReply.internalBinaryWrite(message.cursorInfo, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* repeated storage.HistoryDataReply PointDatas = 6 [json_name = "PointDatas"]; */
        for (let i = 0; i < message.pointDatas.length; i++)
            HistoryDataReply.internalBinaryWrite(message.pointDatas[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* int32 DataCount = 7 [json_name = "DataCount"]; */
        if (message.dataCount !== 0)
            writer.tag(7, WireType.Varint).int32(message.dataCount);
        /* storage.HistoryPointDataReply FFTDatas = 8 [json_name = "FFTDatas"]; */
        if (message.fFTDatas)
            HistoryPointDataReply.internalBinaryWrite(message.fFTDatas, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* int32 DspType = 9 [json_name = "DspType"]; */
        if (message.dspType !== 0)
            writer.tag(9, WireType.Varint).int32(message.dspType);
        /* float Freq = 10 [json_name = "Freq"]; */
        if (message.freq !== 0)
            writer.tag(10, WireType.Bit32).float(message.freq);
        /* int32 SampleType = 11 [json_name = "SampleType"]; */
        if (message.sampleType !== 0)
            writer.tag(11, WireType.Varint).int32(message.sampleType);
        /* storage.DspParamReply DspParam = 12 [json_name = "DspParam"]; */
        if (message.dspParam)
            DspParamReply.internalBinaryWrite(message.dspParam, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* int32 BlockSize = 13 [json_name = "BlockSize"]; */
        if (message.blockSize !== 0)
            writer.tag(13, WireType.Varint).int32(message.blockSize);
        /* storage.PointParamReply PointParam = 14 [json_name = "PointParam"]; */
        if (message.pointParam)
            PointParamReply.internalBinaryWrite(message.pointParam, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message storage.HistoryPointDatasReply
 */
export const HistoryPointDatasReply = new HistoryPointDatasReply$Type();
