import DrawApi from "./Dh-webchart-drawApis";
import $ from "jquery";
import { guid } from "@common/js/utils";
//图表控件基本元素
export class ChartElement {
	//构造函数
	constructor() {
		//图表上下文，能用来获取图表相关的所有信息
		this.ChartContext = null;
		this.Id = guid();
	}
	//测量
	OnMeasure() { }
	//呈现
	OnRender() { }
	//图表发生改变
	OnChartChanged() {
		this.OnMeasure();
		this.OnRender();
	}
	//清除
	OnClear() { }
}

/**图形基本元素 */
export class ShapeElement extends ChartElement {
	//构造函数
	constructor() {
		super();
		this.Data = new Object();
	}
	OnClear() { }
	SetOption() { }
}

export class CommonShape extends ShapeElement {
	constructor() {
		super();
		this.drawApi = new DrawApi();
	}
	/**确认容器已经创建 */
	EnsureContainerCreated() {
		const container = this.ChartContext.PluginContainer;
		let element = document.getElementById(this.Id);
		if (element == null) {
			element = document.createElement("canvas");
			element.setAttribute("id", this.Id);
			element.setAttribute("style", "position:absolute;left:" + this.ChartContext.CoordinateSystem.CanvasLeft + "px;z-index:1;pointer-events:none;");
			container.before(element);
		}
		const dpr = window.devicePixelRatio;
		const { width, height } = this.GetContainerRect();
		element.width = width * dpr;
		element.height = height * dpr;
		element.style.width = width + "px";
		element.style.height = height + "px";
		this.drawApi.SetContext(this.Id);
		this.drawApi.DrawContext.scale(dpr, dpr);
		this.EnsureContainerCliped();
		return element;
	}
	//确认容器已经剪裁
	EnsureContainerCliped() {
		const rect = this.ChartContext.Information.ShapeContainerRect;
		this.drawApi.ClipRect(rect.Left, rect.Top, rect.Width, rect.Height);
	}
	/**清除 */
	OnClear() {
		if (this.drawApi.DrawContext) {
			this.drawApi.Dispose();
		}
		this.drawApi = null;
	}
	GetContainerRect() {
		const container = $("#" + this.ChartContext.ChartContainer.id);
		return {
			width: container.width(),
			height: container.height()
		}
	}
}
