// @generated by protobuf-ts 2.9.1
// @generated from protobuf file "protos/nearestrotatespeed.proto" (package "storage", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message storage.GetNearestRotateSpeedRequest
 */
export interface GetNearestRotateSpeedRequest {
    /**
     * @generated from protobuf field: string Time = 1 [json_name = "Time"];
     */
    time: string;
    /**
     * @generated from protobuf field: repeated int32 PointIds = 2 [json_name = "PointIds"];
     */
    pointIds: number[];
}
/**
 * @generated from protobuf message storage.GetNearestRotateSpeedReply
 */
export interface GetNearestRotateSpeedReply {
    /**
     * @generated from protobuf field: map<int32, double> Result = 1 [json_name = "Result"];
     */
    result: {
        [key: number]: number;
    };
}
// @generated message type with reflection information, may provide speed optimized methods
class GetNearestRotateSpeedRequest$Type extends MessageType<GetNearestRotateSpeedRequest> {
    constructor() {
        super("storage.GetNearestRotateSpeedRequest", [
            { no: 1, name: "Time", kind: "scalar", jsonName: "Time", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "PointIds", kind: "scalar", jsonName: "PointIds", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<GetNearestRotateSpeedRequest>): GetNearestRotateSpeedRequest {
        const message = { time: "", pointIds: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetNearestRotateSpeedRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetNearestRotateSpeedRequest): GetNearestRotateSpeedRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string Time = 1 [json_name = "Time"];*/ 1:
                    message.time = reader.string();
                    break;
                case /* repeated int32 PointIds = 2 [json_name = "PointIds"];*/ 2:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.pointIds.push(reader.int32());
                    else
                        message.pointIds.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetNearestRotateSpeedRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string Time = 1 [json_name = "Time"]; */
        if (message.time !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.time);
        /* repeated int32 PointIds = 2 [json_name = "PointIds"]; */
        if (message.pointIds.length) {
            writer.tag(2, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.pointIds.length; i++)
                writer.int32(message.pointIds[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message storage.GetNearestRotateSpeedRequest
 */
export const GetNearestRotateSpeedRequest = new GetNearestRotateSpeedRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetNearestRotateSpeedReply$Type extends MessageType<GetNearestRotateSpeedReply> {
    constructor() {
        super("storage.GetNearestRotateSpeedReply", [
            { no: 1, name: "Result", kind: "map", jsonName: "Result", K: 5 /*ScalarType.INT32*/, V: { kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ } }
        ]);
    }
    create(value?: PartialMessage<GetNearestRotateSpeedReply>): GetNearestRotateSpeedReply {
        const message = { result: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetNearestRotateSpeedReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetNearestRotateSpeedReply): GetNearestRotateSpeedReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<int32, double> Result = 1 [json_name = "Result"];*/ 1:
                    this.binaryReadMap1(message.result, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: GetNearestRotateSpeedReply["result"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof GetNearestRotateSpeedReply["result"] | undefined, val: GetNearestRotateSpeedReply["result"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.int32();
                    break;
                case 2:
                    val = reader.double();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field storage.GetNearestRotateSpeedReply.Result");
            }
        }
        map[key ?? 0] = val ?? 0;
    }
    internalBinaryWrite(message: GetNearestRotateSpeedReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<int32, double> Result = 1 [json_name = "Result"]; */
        for (let k of Object.keys(message.result))
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.Varint).int32(parseInt(k)).tag(2, WireType.Bit64).double(message.result[k as any]).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message storage.GetNearestRotateSpeedReply
 */
export const GetNearestRotateSpeedReply = new GetNearestRotateSpeedReply$Type();
