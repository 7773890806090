import { ContractTypes } from "@common/js/enum";
import { Debounce, getDeepClone } from "@common/js/utils";
import axios from "@common/js/axios";
import { DataRealContext } from "./dataRealContext";
import _, { map } from "lodash";
import { GrpcApiContext } from "@grpc/grpcApi";

export class BlockDataRealContext extends DataRealContext {
    constructor() {
        super();
        this.graphRegisterPoints = new Map();
        this.registerPointCollection = new Map();
        this.pointChangeGraphs = new Map();
        this.canTrigger = true;
        this.stopTrigger = false;
        this.debounce = new Debounce();
        this.client = GrpcApiContext.GetInstance();
    }
    getContractType() {
        return ContractTypes.Block;
    }
    async registerPoints(requestId, registerPoints) {
        const points = new Map();
        if (registerPoints) {
            for (const registerPoint of registerPoints) {
                const point = await this.pointCacheContext.get(registerPoint.PointId) || registerPoint;
                points.set(registerPoint.PointId, {
                    PointId: registerPoint.PointId,
                    Name: point.Name,
                    Unit: point.UnitStr,
                    FullValue: point.FullValue,
                    AlgorithmType: point.SignalType,
                    SampleType: point.SampleMode
                });
            }
        }
        this.graphRegisterPoints.set(requestId, points);
        this.pointChangeGraphs.set(requestId, _.cloneDeep(points));
        this.updateRegisterPointCollection();
        return true;
    }
    unRegisterPoints(requestId) {
        if (this.graphRegisterPoints.has(requestId)) {
            this.graphRegisterPoints.delete(requestId);
            this.updateRegisterPointCollection();
        }
        if (this.pointChangeGraphs.has(requestId)) {
            this.pointChangeGraphs.delete(requestId);
        }
    }
    updateRegisterPointCollection() {
        const registerPointCollection = new Map();
        this.graphRegisterPoints.forEach((registerGraph) => {
            registerGraph.forEach((registerPoint) => {
                registerPointCollection.set(registerPoint.PointId, registerPoint);
            });
        });
        this.registerPointCollection = registerPointCollection;
        this.sendRegisterPointsToClinet();
    }
    sendRegisterPointsToClinet() {
        if (this.getConnectionIds().length == 0) return;
        this.debounce.run(() => {
            const registerGraphPoints = this.updateHistoryChangeGraphs();
            const connectionIds = this.getConnectionIds();
            if (connectionIds.length == 0) return;
            axios({
                method: "post",
                url: "datamanagement/register-block-data",
                data: {
                    ConnectionIds: connectionIds,
                    PointIds: [...this.registerPointCollection.keys()],
                },
            }).then((res) => {
                if (res.IsSuccess) {
                    const pointIds = []
                    registerGraphPoints.forEach((point) => {
                        point.forEach((p) => {
                            pointIds.push(p.PointId);
                        });
                    });
                    this.processRegisterData([...this.registerPointCollection.values()], registerGraphPoints);
                    this.queryLatestWaveData(pointIds);
                }
            });
        }, 100);
    }
    queryLatestWaveData(pointIds) {
        if(pointIds.length === 0) return;
        const condition = {
            PointIds: pointIds,
        }
        this.client.queryLatestWaveData(condition)
            .then((res) => {
                if(res) {
                    this.handleDatas(res);
                }
            });
    }
    updateHistoryChangeGraphs() {
        const registerGraphPoints = new Map();
        for (const requestId of this.pointChangeGraphs.keys()) {
            registerGraphPoints.set(requestId, this.pointChangeGraphs.get(requestId));
            this.pointChangeGraphs.delete(requestId);
        }
        return registerGraphPoints;
    }
    sendUnRegisterPointsToClient() {
        axios({
            method: "delete",
            url: `DataManage/`,
            data: {
                ids: this.getConnectionIds()
            },
        }).then(() => { });
    }
    processRegisterData(pointInfos, registerGraphPoints) {
        let datas = pointInfos.map(pointInfo => {
            return {
                PointId: pointInfo.PointId,
                AlgorithmType: pointInfo.AlgorithmType,
                Param: getDeepClone(pointInfo)
            }
        });
        if (datas.length > 0) {
            this.processRegisterDataReceived(datas, registerGraphPoints);
        }
        datas = null;
    }
    processRegisterDataReceived(datas, registerGraphPoints) {
        this.processWaveData(datas);
        for (const requestId of this.dataReceiveds.keys()) {
            const handler = this.dataReceiveds.get(requestId);
            if (!registerGraphPoints.has(requestId)) continue;
            const graphRegisterPoints = registerGraphPoints.get(requestId);
            if (graphRegisterPoints == null) continue;
            let graphDatas = [];
            for (const data of datas) {
                if (graphRegisterPoints.has(data.PointId)) {
                    graphDatas.push(this.getCloneData(data));
                }
            }
            handler(graphDatas);
            graphDatas = null;
        }
        registerGraphPoints.clear();
    }
    processWaveData(datas) {
        for (const data of datas) {
            if (data.Datas?.length == 2) {
                data.Datas = this.dataParser.get(data.Datas[0]).GetDatas(data.Datas[1], data);
                data.AlgorithmType = data.DspType;
            }
        }
    }
    onDataReceived(datas) {
        this.processWaveData(datas);
        this.handleDatas(datas);
    }
    handleDatas(datas) {
        this.dataReceiveds.forEach((handler, requestId) => {
            if (!this.graphRegisterPoints.has(requestId)) return;
            const graphRegisterPoints = this.graphRegisterPoints.get(requestId);
            let graphDatas = [];
            for (const data of datas) {
                if (graphRegisterPoints.has(data.PointId)) {
                    graphDatas.push(this.getCloneData(data));
                }
            }
            if (graphDatas.length > 0) {
                handler(graphDatas);
            }
            graphDatas = null;
        });
    }
    getCloneData(data) {
        const dataTemp = {
            PointId: data.PointId,
            SampleTime: data.SampleTime,
            SampleFreq: data.SampleFreq,
            AlgorithmType: data.AlgorithmType,
            Param: null,
            Datas: data.Datas,
            BlockSize: data.BlockSize,
            Speed: data.RotateSpeed,
            RotateSpeed: data.RotateSpeed,
            DataOffset: data.DataOffset,
        };
        // 无关联转速测点且模拟转速时
        if (data.DspParam?.length === 2 && !data.RotateSpeed) {
            if (data.DspParam[1].UseSpeed) {
                dataTemp.RotateSpeed = data.DspParam[1].RoteSpeed
                dataTemp.IsUseSpeed = true;
            }
        }
        if (data.Param) {
            dataTemp.Param = getDeepClone(data.Param);
        }
        return dataTemp;
    }
}