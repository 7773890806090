export class draggable {
	constructor(option) {
		this.element = option.element;
		this.containment = option.containment || "parent";
		this.drag = option.drag || null;
		this.owner = option.owner;
		this.padding = option.padding || {
			Left: 0,
			Top: 0,
			Right: 0,
			Bottom: 0,
		};
		this.initDragEvents();
	}
	initDragEvents() {
		let parent = this.element.parent();
		this.element.on("mousedown", (ex) => {
			ex.preventDefault();
			ex.stopPropagation();
			let disx = ex.pageX - this.element[0].offsetLeft;
			let disy = ex.pageY - this.element[0].offsetTop;
			if (this.owner) this.owner.moved = true;
			document.onmousemove = (e) => {
				e.preventDefault();
				e.stopPropagation();
				let left = e.pageX - disx;
				let top = e.pageY - disy;
				if (left < this.padding.Left) left = this.padding.Left;
				if (left > parent.width() - this.element.width() - this.padding.Right) left = parent.width() - this.element.width() - this.padding.Right;
				if (top < this.padding.Top) top = this.padding.Top;
				if (parent.height() - this.element.height() - this.padding.Bottom < 0) top = this.padding.Top;
				else if (top > parent.height() - this.element.height() - this.padding.Bottom) top = parent.height() - this.element.height() - this.padding.Bottom;
				left = (left / parent.width()) * 100;
				top = (top / parent.height()) * 100;
				this.element[0].style.left = left + "%";
				this.element[0].style.top = top + "%";
			};
			document.onmouseup = (e) => {
				e.preventDefault();
				e.stopPropagation();
				document.onmousemove = document.onmouseup = null;
			};
		});

		this.element.on("touchstart", (ex) => {
			ex.preventDefault();
			ex.stopPropagation();
			let disx = ex.touches[0].pageX - this.element[0].offsetLeft;
			let disy = ex.touches[0].pageY - this.element[0].offsetTop;
			if (this.owner) this.owner.moved = true;
			document.ontouchmove = (e) => {
				e.stopPropagation();
				let left = e.touches[0].pageX - disx;
				let top = e.touches[0].pageY - disy;
				if (left < this.padding.Left) left = this.padding.Left;
				if (left > parent.width() - this.element.width() - this.padding.Right) left = parent.width() - this.element.width() - this.padding.Right;
				if (top < this.padding.Top) top = this.padding.Top;
				if (parent.height() - this.element.height() - this.padding.Bottom < 0) top = this.padding.Top;
				else if (top > parent.height() - this.element.height() - this.padding.Bottom) top = parent.height() - this.element.height() - this.padding.Bottom;
				left = (left / parent.width()) * 100;
				top = (top / parent.height()) * 100;
				this.element[0].style.left = left + "%";
				this.element[0].style.top = top + "%";
			};
			document.ontouchend = (e) => {
				e.preventDefault();
				e.stopPropagation();
				document.ontouchmove = document.ontouchend = null;
			};
		});
	}
}
