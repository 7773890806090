// @generated by protobuf-ts 2.9.1
// @generated from protobuf file "protos/data.proto" (package "storage", syntax proto3)
// tslint:disable
import { QueryNavDiluteDatasRequest } from "./dilutedatas";
import { QueryHistoryDiluteDatasRequest } from "./dilutedatas";
import { GetNearestRotateSpeedRequest } from "./nearestrotatespeed";
import { CalculateWaveDataRequest } from "./calculatewavedata";
import { GetFirstWaveDataRequest } from "./firstwavedata";
import { QueryLatestWaveDataRequest } from "./latestwavedata";
import { QueryLatestEigenDatasRequest } from "./latesteigendatas";
import { GetDatasRequest } from "./getdatas";
import { Reply } from "./reply";
import { GetRangeWaveDatasRequest } from "./rangewavedatas";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
/**
 * @generated ServiceType for protobuf service storage.Data
 */
export const Data = new ServiceType("storage.Data", [
    { name: "GetRangeWaveDatas", options: {}, I: GetRangeWaveDatasRequest, O: Reply },
    { name: "GetDatas", options: {}, I: GetDatasRequest, O: Reply },
    { name: "GetEigenDatas", options: {}, I: GetDatasRequest, O: Reply },
    { name: "GetEigenDatasByLowerlimit", options: {}, I: GetDatasRequest, O: Reply },
    { name: "QueryLatestEigenDatas", options: {}, I: QueryLatestEigenDatasRequest, O: Reply },
    { name: "QueryLatestWaveData", options: {}, I: QueryLatestWaveDataRequest, O: Reply },
    { name: "GetFirstWaveData", options: {}, I: GetFirstWaveDataRequest, O: Reply },
    { name: "GetLastWaveData", options: {}, I: GetFirstWaveDataRequest, O: Reply },
    { name: "CalculateWaveData", options: {}, I: CalculateWaveDataRequest, O: Reply },
    { name: "QueryDatasWithLimit", options: {}, I: CalculateWaveDataRequest, O: Reply },
    { name: "GetNearestEigenData", options: {}, I: GetDatasRequest, O: Reply },
    { name: "QueryNearestEigenData", options: {}, I: GetDatasRequest, O: Reply },
    { name: "GetNearestRotateSpeed", options: {}, I: GetNearestRotateSpeedRequest, O: Reply },
    { name: "QueryHistoryDiluteDatas", options: {}, I: QueryHistoryDiluteDatasRequest, O: Reply },
    { name: "QueryNavDiluteDatas", options: {}, I: QueryNavDiluteDatasRequest, O: Reply }
]);
