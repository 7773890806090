import { DataSeries, GraphType, ScaleType } from "@core/graphs/webcharts/Dh-webchart-models";
import { SpectralAnalysisChart } from "@core/graphs/charts/Dh-analysischart-spectral";
import _ from "lodash";
import { GraphBase } from "./Dh-graph-base";
import { i18nGlobal } from "@common/js/utils";

export class SpectralGraph extends GraphBase {
    constructor() {
        super();
        this.GraphType = GraphType.Spectrum;
        this.xUnit = "Hz";
    }
    CreateGraph() {
        this.Graph = new SpectralAnalysisChart();
        this.Graph.Init(this.Option, this.Plugins);
        this.Graph.OnChartChanged();
    }
    ProcessData(series, data) {
        if (this.Option.Axis.YAxisLogType == 3) {
            series.Points = new Array(data.Datas.Points.length);
            const yReferenceValue = this.Option.Axis.YReferenceValue;
            for (let i = 0; i < data.Datas.Points.length; i++) {
                const dp = data.Datas.Points[i];
                if (dp.Y > 0) {
                    series.Points[i] = { X: dp.X, Y: data.coef * Math.log10(dp.Y / yReferenceValue), SourceY: dp.Y };
                } else {
                    series.Points[i] = { X: dp.X, Y: 0, SourceY: 0 };
                }
            }
        } else {
            series.Points = data.Datas.Points;
            for (let i = 0; i < series.Points.length; i++) {
                series.Points[i].SourceY = series.Points[i].Y;
            }
        }
        series.coef = data.coef;
        series.XMin = series.Points[0].X;
        series.XMax = series.Points[series.Points.length - 1].X;
    }
    Update(option) {
        if (this.Graph == null) return;
        const datas = this.Datas.getValues();
        const temp = this.getOrderDataSeries(datas);
        this.SetLineColor(temp);
        if (this.Option) {
            option.ChartId = this.Option.ChartId;
        }
        this.Option = option;
        this.Graph.Update(option, false);
        if (this.Option.Axis.YAxisLogType == 3) this.processDbData(datas);
        else {
            this.restoreData(datas);
        }
        this.RefreshData();
    }
    processDbData(datas) {
        const yReferenceValue = this.Option.Axis.YReferenceValue;
        for (const data of datas) {
            for (let i = 0; i < data.Points.length; i++) {
                const dp = data.Points[i];
                if (dp.SourceY > 0) {
                    data.Points[i].Y = data.coef * Math.log10(dp.SourceY / yReferenceValue);
                } else {
                    data.Points[i].Y = 0;
                }
            }
        }
    }
    ProcessYData(series, fullValue) {
        const maxmin = this.GetMaxMinValue(series.Points);
        if (this.Option.Axis.AxisScaleType == ScaleType.Auto && series.Points.length > 0) {
            series.YMax = maxmin.YMax;
            series.YMin = maxmin.YMin;
        } else {
            series.YMax = fullValue;
            series.YMin = maxmin.YMin;
        }
        series.FullValue = fullValue;
    }
    restoreData(datas) {
        for (const data of datas) {
            for (let i = 0; i < data.Points.length; i++) {
                data.Points[i].Y = data.Points[i].SourceY;
            }
        }
    }
    UpdateDataSeries(datas, forceUpdate = true) {
        let dataMaps = new Map();
        for (let p of datas) {
            if (p.Param) {
                let ds = new DataSeries();
                if (!forceUpdate && this.Datas.has(p.PointId)) {
                    ds = this.Datas.get(p.PointId);
                }
                ds.PointId = p.PointId;
                ds.PointType = this.PointType;
                ds.XUnit = this.xUnit;
                ds.YUnit = p.Param.Unit;
                ds.Tag = p.Param;
                ds.Legend = p.Param.Name;
                ds.FullValue = p.Param.FullValue;
                dataMaps.set(p.PointId, ds);
                if (this.Option.RegisterPoints) {
                    let point = this.Option.RegisterPoints.find((r) => r.PointId == p.PointId);
                    if (point && point.Param) {
                        let param = JSON.parse(point.Param);
                        if (param.FreqUnit == 1) {
                            ds.XUnit = i18nGlobal('core.order');
                        }
                    }
                }
                if (this.Option.Signal?.FreqUnit == 1) {
                    ds.XUnit = i18nGlobal('core.order');
                }
                if (p.Param.SampleType == 1) {
                    ds.XUnit = i18nGlobal('core.order');
                }
            }
        }
        if (dataMaps.size > 0 || forceUpdate) {
            this.Datas = null;
            this.Datas = dataMaps;
        }
    }
}
