// @generated by protobuf-ts 2.9.1
// @generated from protobuf file "protos/dilutedatas.proto" (package "storage", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { DatasReply } from "./basereply";
import { PointParamReply } from "./basereply";
import { DataParamReply } from "./basereply";
import { DspParamReply } from "./basereply";
/**
 * @generated from protobuf message storage.QueryHistoryDiluteDatasRequest
 */
export interface QueryHistoryDiluteDatasRequest {
    /**
     * @generated from protobuf field: string BeginTime = 1 [json_name = "BeginTime"];
     */
    beginTime: string;
    /**
     * @generated from protobuf field: string EndTime = 2 [json_name = "EndTime"];
     */
    endTime: string;
    /**
     * @generated from protobuf field: repeated int32 PointIds = 3 [json_name = "PointIds"];
     */
    pointIds: number[];
}
/**
 * @generated from protobuf message storage.QueryHistoryDiluteDatasReply
 */
export interface QueryHistoryDiluteDatasReply {
    /**
     * @generated from protobuf field: repeated storage.PointHistoryDataReply Points = 1 [json_name = "Points"];
     */
    points: PointHistoryDataReply[];
}
/**
 * @generated from protobuf message storage.QueryNavDiluteDatasRequest
 */
export interface QueryNavDiluteDatasRequest {
    /**
     * @generated from protobuf field: int32 PointId = 1 [json_name = "PointId"];
     */
    pointId: number;
}
/**
 * @generated from protobuf message storage.QueryNavDiluteDatasReply
 */
export interface QueryNavDiluteDatasReply {
    /**
     * @generated from protobuf field: repeated storage.PointHistoryDataReply Points = 1 [json_name = "Points"];
     */
    points: PointHistoryDataReply[];
}
/**
 * @generated from protobuf message storage.PointHistoryDataReply
 */
export interface PointHistoryDataReply {
    /**
     * @generated from protobuf field: int32 PointId = 1 [json_name = "PointId"];
     */
    pointId: number;
    /**
     * @generated from protobuf field: int64 StartTime = 2 [json_name = "StartTime"];
     */
    startTime: bigint;
    /**
     * @generated from protobuf field: int32 DiluteLevel = 3 [json_name = "DiluteLevel"];
     */
    diluteLevel: number;
    /**
     * @generated from protobuf field: int32 DspType = 4 [json_name = "DspType"];
     */
    dspType: number;
    /**
     * @generated from protobuf field: storage.DspParamReply DspParam = 5 [json_name = "DspParam"];
     */
    dspParam?: DspParamReply;
    /**
     * @generated from protobuf field: storage.DataParamReply DataParam = 6 [json_name = "DataParam"];
     */
    dataParam?: DataParamReply;
    /**
     * @generated from protobuf field: storage.PointParamReply PointParam = 7 [json_name = "PointParam"];
     */
    pointParam?: PointParamReply;
    /**
     * @generated from protobuf field: repeated storage.DilutionDataSegmentReply DiluteDataSegments = 8 [json_name = "DiluteDataSegments"];
     */
    diluteDataSegments: DilutionDataSegmentReply[];
}
/**
 * @generated from protobuf message storage.DilutionDataSegmentReply
 */
export interface DilutionDataSegmentReply {
    /**
     * @generated from protobuf field: int64 SampleTime = 1 [json_name = "SampleTime"];
     */
    sampleTime: bigint;
    /**
     * @generated from protobuf field: bool IsContinous = 2 [json_name = "IsContinous"];
     */
    isContinous: boolean;
    /**
     * @generated from protobuf field: float SampleFreq = 3 [json_name = "SampleFreq"];
     */
    sampleFreq: number;
    /**
     * @generated from protobuf field: storage.DatasReply Datas = 4 [json_name = "Datas"];
     */
    datas?: DatasReply;
}
// @generated message type with reflection information, may provide speed optimized methods
class QueryHistoryDiluteDatasRequest$Type extends MessageType<QueryHistoryDiluteDatasRequest> {
    constructor() {
        super("storage.QueryHistoryDiluteDatasRequest", [
            { no: 1, name: "BeginTime", kind: "scalar", jsonName: "BeginTime", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "EndTime", kind: "scalar", jsonName: "EndTime", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "PointIds", kind: "scalar", jsonName: "PointIds", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<QueryHistoryDiluteDatasRequest>): QueryHistoryDiluteDatasRequest {
        const message = { beginTime: "", endTime: "", pointIds: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<QueryHistoryDiluteDatasRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: QueryHistoryDiluteDatasRequest): QueryHistoryDiluteDatasRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string BeginTime = 1 [json_name = "BeginTime"];*/ 1:
                    message.beginTime = reader.string();
                    break;
                case /* string EndTime = 2 [json_name = "EndTime"];*/ 2:
                    message.endTime = reader.string();
                    break;
                case /* repeated int32 PointIds = 3 [json_name = "PointIds"];*/ 3:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.pointIds.push(reader.int32());
                    else
                        message.pointIds.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: QueryHistoryDiluteDatasRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string BeginTime = 1 [json_name = "BeginTime"]; */
        if (message.beginTime !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.beginTime);
        /* string EndTime = 2 [json_name = "EndTime"]; */
        if (message.endTime !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.endTime);
        /* repeated int32 PointIds = 3 [json_name = "PointIds"]; */
        if (message.pointIds.length) {
            writer.tag(3, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.pointIds.length; i++)
                writer.int32(message.pointIds[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message storage.QueryHistoryDiluteDatasRequest
 */
export const QueryHistoryDiluteDatasRequest = new QueryHistoryDiluteDatasRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class QueryHistoryDiluteDatasReply$Type extends MessageType<QueryHistoryDiluteDatasReply> {
    constructor() {
        super("storage.QueryHistoryDiluteDatasReply", [
            { no: 1, name: "Points", kind: "message", jsonName: "Points", repeat: 1 /*RepeatType.PACKED*/, T: () => PointHistoryDataReply }
        ]);
    }
    create(value?: PartialMessage<QueryHistoryDiluteDatasReply>): QueryHistoryDiluteDatasReply {
        const message = { points: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<QueryHistoryDiluteDatasReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: QueryHistoryDiluteDatasReply): QueryHistoryDiluteDatasReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated storage.PointHistoryDataReply Points = 1 [json_name = "Points"];*/ 1:
                    message.points.push(PointHistoryDataReply.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: QueryHistoryDiluteDatasReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated storage.PointHistoryDataReply Points = 1 [json_name = "Points"]; */
        for (let i = 0; i < message.points.length; i++)
            PointHistoryDataReply.internalBinaryWrite(message.points[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message storage.QueryHistoryDiluteDatasReply
 */
export const QueryHistoryDiluteDatasReply = new QueryHistoryDiluteDatasReply$Type();
// @generated message type with reflection information, may provide speed optimized methods
class QueryNavDiluteDatasRequest$Type extends MessageType<QueryNavDiluteDatasRequest> {
    constructor() {
        super("storage.QueryNavDiluteDatasRequest", [
            { no: 1, name: "PointId", kind: "scalar", jsonName: "PointId", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<QueryNavDiluteDatasRequest>): QueryNavDiluteDatasRequest {
        const message = { pointId: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<QueryNavDiluteDatasRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: QueryNavDiluteDatasRequest): QueryNavDiluteDatasRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 PointId = 1 [json_name = "PointId"];*/ 1:
                    message.pointId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: QueryNavDiluteDatasRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 PointId = 1 [json_name = "PointId"]; */
        if (message.pointId !== 0)
            writer.tag(1, WireType.Varint).int32(message.pointId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message storage.QueryNavDiluteDatasRequest
 */
export const QueryNavDiluteDatasRequest = new QueryNavDiluteDatasRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class QueryNavDiluteDatasReply$Type extends MessageType<QueryNavDiluteDatasReply> {
    constructor() {
        super("storage.QueryNavDiluteDatasReply", [
            { no: 1, name: "Points", kind: "message", jsonName: "Points", repeat: 1 /*RepeatType.PACKED*/, T: () => PointHistoryDataReply }
        ]);
    }
    create(value?: PartialMessage<QueryNavDiluteDatasReply>): QueryNavDiluteDatasReply {
        const message = { points: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<QueryNavDiluteDatasReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: QueryNavDiluteDatasReply): QueryNavDiluteDatasReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated storage.PointHistoryDataReply Points = 1 [json_name = "Points"];*/ 1:
                    message.points.push(PointHistoryDataReply.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: QueryNavDiluteDatasReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated storage.PointHistoryDataReply Points = 1 [json_name = "Points"]; */
        for (let i = 0; i < message.points.length; i++)
            PointHistoryDataReply.internalBinaryWrite(message.points[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message storage.QueryNavDiluteDatasReply
 */
export const QueryNavDiluteDatasReply = new QueryNavDiluteDatasReply$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PointHistoryDataReply$Type extends MessageType<PointHistoryDataReply> {
    constructor() {
        super("storage.PointHistoryDataReply", [
            { no: 1, name: "PointId", kind: "scalar", jsonName: "PointId", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "StartTime", kind: "scalar", jsonName: "StartTime", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 3, name: "DiluteLevel", kind: "scalar", jsonName: "DiluteLevel", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "DspType", kind: "scalar", jsonName: "DspType", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "DspParam", kind: "message", jsonName: "DspParam", T: () => DspParamReply },
            { no: 6, name: "DataParam", kind: "message", jsonName: "DataParam", T: () => DataParamReply },
            { no: 7, name: "PointParam", kind: "message", jsonName: "PointParam", T: () => PointParamReply },
            { no: 8, name: "DiluteDataSegments", kind: "message", jsonName: "DiluteDataSegments", repeat: 1 /*RepeatType.PACKED*/, T: () => DilutionDataSegmentReply }
        ]);
    }
    create(value?: PartialMessage<PointHistoryDataReply>): PointHistoryDataReply {
        const message = { pointId: 0, startTime: 0n, diluteLevel: 0, dspType: 0, diluteDataSegments: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PointHistoryDataReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PointHistoryDataReply): PointHistoryDataReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 PointId = 1 [json_name = "PointId"];*/ 1:
                    message.pointId = reader.int32();
                    break;
                case /* int64 StartTime = 2 [json_name = "StartTime"];*/ 2:
                    message.startTime = reader.int64().toBigInt();
                    break;
                case /* int32 DiluteLevel = 3 [json_name = "DiluteLevel"];*/ 3:
                    message.diluteLevel = reader.int32();
                    break;
                case /* int32 DspType = 4 [json_name = "DspType"];*/ 4:
                    message.dspType = reader.int32();
                    break;
                case /* storage.DspParamReply DspParam = 5 [json_name = "DspParam"];*/ 5:
                    message.dspParam = DspParamReply.internalBinaryRead(reader, reader.uint32(), options, message.dspParam);
                    break;
                case /* storage.DataParamReply DataParam = 6 [json_name = "DataParam"];*/ 6:
                    message.dataParam = DataParamReply.internalBinaryRead(reader, reader.uint32(), options, message.dataParam);
                    break;
                case /* storage.PointParamReply PointParam = 7 [json_name = "PointParam"];*/ 7:
                    message.pointParam = PointParamReply.internalBinaryRead(reader, reader.uint32(), options, message.pointParam);
                    break;
                case /* repeated storage.DilutionDataSegmentReply DiluteDataSegments = 8 [json_name = "DiluteDataSegments"];*/ 8:
                    message.diluteDataSegments.push(DilutionDataSegmentReply.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PointHistoryDataReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 PointId = 1 [json_name = "PointId"]; */
        if (message.pointId !== 0)
            writer.tag(1, WireType.Varint).int32(message.pointId);
        /* int64 StartTime = 2 [json_name = "StartTime"]; */
        if (message.startTime !== 0n)
            writer.tag(2, WireType.Varint).int64(message.startTime);
        /* int32 DiluteLevel = 3 [json_name = "DiluteLevel"]; */
        if (message.diluteLevel !== 0)
            writer.tag(3, WireType.Varint).int32(message.diluteLevel);
        /* int32 DspType = 4 [json_name = "DspType"]; */
        if (message.dspType !== 0)
            writer.tag(4, WireType.Varint).int32(message.dspType);
        /* storage.DspParamReply DspParam = 5 [json_name = "DspParam"]; */
        if (message.dspParam)
            DspParamReply.internalBinaryWrite(message.dspParam, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* storage.DataParamReply DataParam = 6 [json_name = "DataParam"]; */
        if (message.dataParam)
            DataParamReply.internalBinaryWrite(message.dataParam, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* storage.PointParamReply PointParam = 7 [json_name = "PointParam"]; */
        if (message.pointParam)
            PointParamReply.internalBinaryWrite(message.pointParam, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* repeated storage.DilutionDataSegmentReply DiluteDataSegments = 8 [json_name = "DiluteDataSegments"]; */
        for (let i = 0; i < message.diluteDataSegments.length; i++)
            DilutionDataSegmentReply.internalBinaryWrite(message.diluteDataSegments[i], writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message storage.PointHistoryDataReply
 */
export const PointHistoryDataReply = new PointHistoryDataReply$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DilutionDataSegmentReply$Type extends MessageType<DilutionDataSegmentReply> {
    constructor() {
        super("storage.DilutionDataSegmentReply", [
            { no: 1, name: "SampleTime", kind: "scalar", jsonName: "SampleTime", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "IsContinous", kind: "scalar", jsonName: "IsContinous", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "SampleFreq", kind: "scalar", jsonName: "SampleFreq", T: 2 /*ScalarType.FLOAT*/ },
            { no: 4, name: "Datas", kind: "message", jsonName: "Datas", T: () => DatasReply }
        ]);
    }
    create(value?: PartialMessage<DilutionDataSegmentReply>): DilutionDataSegmentReply {
        const message = { sampleTime: 0n, isContinous: false, sampleFreq: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DilutionDataSegmentReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DilutionDataSegmentReply): DilutionDataSegmentReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 SampleTime = 1 [json_name = "SampleTime"];*/ 1:
                    message.sampleTime = reader.int64().toBigInt();
                    break;
                case /* bool IsContinous = 2 [json_name = "IsContinous"];*/ 2:
                    message.isContinous = reader.bool();
                    break;
                case /* float SampleFreq = 3 [json_name = "SampleFreq"];*/ 3:
                    message.sampleFreq = reader.float();
                    break;
                case /* storage.DatasReply Datas = 4 [json_name = "Datas"];*/ 4:
                    message.datas = DatasReply.internalBinaryRead(reader, reader.uint32(), options, message.datas);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DilutionDataSegmentReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 SampleTime = 1 [json_name = "SampleTime"]; */
        if (message.sampleTime !== 0n)
            writer.tag(1, WireType.Varint).int64(message.sampleTime);
        /* bool IsContinous = 2 [json_name = "IsContinous"]; */
        if (message.isContinous !== false)
            writer.tag(2, WireType.Varint).bool(message.isContinous);
        /* float SampleFreq = 3 [json_name = "SampleFreq"]; */
        if (message.sampleFreq !== 0)
            writer.tag(3, WireType.Bit32).float(message.sampleFreq);
        /* storage.DatasReply Datas = 4 [json_name = "Datas"]; */
        if (message.datas)
            DatasReply.internalBinaryWrite(message.datas, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message storage.DilutionDataSegmentReply
 */
export const DilutionDataSegmentReply = new DilutionDataSegmentReply$Type();
