import dayjs from "dayjs";
import { ruleCheck } from "@common/js/rules";
import { DataPointShape, DataPoint, DataSeries, GraphType, YAxisType, XAxisType, XAxisShowType } from "@core/graphs/webcharts/Dh-webchart-models";
import store from "@common/js/store";
import { HTrendAnalysisChart, TrendAnalysisChart } from "@core/graphs/charts/Dh-analysischart-trend";
import _ from "lodash";
import { GraphBase } from "./Dh-graph-base";

export class TrendGraph extends GraphBase {
    constructor() {
        super();
        this.GraphType = GraphType.Trend;
        this.TimeLength = 60 * 10 * 1000; //s
        this.PointType = DataPointShape.Arc;
        this.LastEndPoint = null;
        this.XMin = null;
        this.XMax = null;
        this.LatestTimeMap = new Map();
    }
    Init(option, plugins) {
        if (option.Axis.TimeLength) this.TimeLength = option.Axis.TimeLength;
        super.Init(option, plugins);
    }
    CreateGraph() {
        this.Graph = new TrendAnalysisChart();
        this.Graph.Init(this.Option, this.Plugins);
        this.Graph.OnChartChanged();
    }
    UpdateDataSeries(datas, forceUpdate = true) {
        const dataMaps = new Map();
        for (const data of datas) {
            if (data.Param == null) continue;
            data.Eigens.forEach(eigen => {
                const id = `${data.PointId}_${eigen.EigenType}`;
                const ds = !forceUpdate && this.Datas.has(id) ? this.Datas.get(id) : new DataSeries();
                ds.PointId = id;
                ds.PointType = this.PointType;
                ds.XUnit = this.xUnit;
                ds.YUnit = eigen.Unit;
                if (data.Param.IsObserver == true) {
                    ds.YUnit = "";
                    ds.YAxisType = YAxisType.Text;
                    let idx = this.getMaxValue(dataMaps) + 1;
                    for (let item of JSON.parse(data.Param.Extensions)) {
                        ds.YAxisItems.push({
                            ...item,
                            Value: idx,
                        });
                        idx++;
                    }
                }
                ds.Tag = _.cloneDeep(data.Param);
                ds.Tag.PointId = id;
                ds.Legend = eigen.EigenName ? `${data.Param.Name}_${eigen.EigenName}` : data.Param.Name;
                ds.FullValue = data.Param.FullValue;
                dataMaps.set(ds.PointId, ds);
            });
        }
        if (dataMaps.size > 0) {
            this.Datas = null;
            this.Datas = dataMaps;
        }
    }
    GetDilutionInterval() {
        return this.TimeLength / 1000;
    }
    AppendAheadData(datas, time) {
        for (const data of datas) {
            let id = `${data.PointId}_${data.EigenType.Key}`;
            if (!this.Datas.has(id) || data.Datas.DataCount == 0) continue;
            const series = this.Datas.get(id);
            const points = series.Points;
            series.Points = new Array();
            let time = null;
            const interval = this.GetDilutionInterval();
            for (let y = 0; y < data.Datas.DataCount; y++) {
                const p = data.Datas.Points[y];
                if (time != null && p.X - time < interval) continue;
                if (series.YAxisType == YAxisType.Text) {
                    const item = series.YAxisItems.find((r) => r.Id == parseInt(p.Y));
                    if (item) {
                        series.Points.push(new DataPoint(p.X, item.Value));
                    }
                } else {
                    const dataPoint = new DataPoint(p.X, p.Y);
                    if (p.AlarmLevel) dataPoint.AlarmLevel = p.AlarmLevel;
                    if (data.AlarmParams != null && data.AlarmParams[y] != null) {
                        dataPoint.AlarmLevel = data.AlarmParams[y];
                    }
                    series.Points.push(dataPoint);
                }
                time = p.X;
            }
            let last = series.Points.length > 0 ? series.Points[series.Points.length - 1].X : 0;
            points.forEach(point => {
                if (point.X > last && point.X - last.X > interval) {
                    last = point.X;
                    series.Points.push(point);
                }
            });
        }
        this.XMin = null;
        this.ProcessData(this.Datas, time);
        this.SetIntervalRefresh(0);
    }
    AppendData(datas) {
        this.UpdateDataSeries(datas, false);
        datas = datas.filter(data => this.FilterDatas(data));
        const interval = this.GetDilutionInterval();
        if (datas.length == 0) {
            this.SetIntervalRefresh();
            return;
        }
        for (const dt of datas) {
            for (const eigen of dt.Eigens) {
                const id = `${dt.PointId}_${eigen.EigenType}`;
                if (!this.Datas.has(id)) {
                    continue;
                }
                if (this.LatestTimeMap.has(id) && dt.SampleTime / this.xcofe - this.LatestTimeMap.get(id) < interval) {
                    continue;
                }
                const series = this.Datas.get(id);
                if (series.YAxisType == YAxisType.Text) {
                    const item = series.YAxisItems.find((r) => r.Id == parseInt(eigen.Value));
                    if (item) {
                        series.Points.push(new DataPoint(dt.SampleTime / this.xcofe, item.Value));
                    }
                } else {
                    const dp = new DataPoint(dt.SampleTime / this.xcofe, eigen.Value);
                    if (dt.AlarmParams != null && dt.AlarmParams[eigen.EigenType]) {
                        dp.AlarmLevel = dt.AlarmParams[eigen.EigenType];
                    }
                    series.Points.push(dp);
                }
                series.FullValue = ruleCheck.IsNullOrSpaceStr(series.FullValue) ? 1000 : parseFloat(series.FullValue);
            }
        }
        this.ProcessData(this.Datas);
        this.SetIntervalRefresh();
    }
    FilterDatas(data) {
        return data.SampleTime != null && data.Eigens?.length > 0;
    }
    UpdateTimeLength(length) {
        this.TimeLength = length;
        this.ProcessData(this.Datas);
        this.SetIntervalRefresh(0);
    }
    GetTimeRange(dataMap) {
        let xmax = null, xmin = null;
        for (const series of dataMap.values()) {
            if (series.Points.length > 0) {
                let min = series.Points[0].X;
                let max = series.Points[series.Points.length - 1].X;
                if (xmin == null || xmin > min) xmin = min;
                if (xmax == null || xmax < max) xmax = max;
                this.LatestTimeMap.set(series.PointId, max);
            }
        }
        return { xmax, xmin };
    }
    GetQueryTimeRange() {
        let { xmax, xmin } = this.GetTimeRange(this.Datas);
        if (xmax == null) {
            xmax = Number(dayjs());
        }
        xmin = xmax - this.TimeLength;
        return { xmax, xmin };
    }
    ProcessData(dataMap, time = null) {
        const { xmax, xmin } = this.GetTimeRange(dataMap);
        if (this.XMin == null) this.XMin = xmin;
        this.XMax = xmax;
        if (this.Option.Axis.ShowMode == true) {
            if (time) {
                const deadTime = Number(dayjs(time));
                if (deadTime < xmax) {
                    this.XMax = xmax;
                } else {
                    this.XMax = deadTime;
                }
            }
            this.XMin = this.XMax - this.TimeLength;
        } else {
            if (this.XMax - this.XMin >= this.TimeLength) this.XMin = this.XMax - this.TimeLength;
            else {
                this.XMax = this.XMin + this.TimeLength;
            }
        }
        dataMap.forEach(series => {
            series.XMin = this.XMin;
            series.XMax = this.XMax;
            let index = series.Points.findIndex(p => p.X >= series.XMin);
            if (index < 0) series.Points = series.Points.length > 0 ? [series.Points[series.Points.length - 1]] : [];
            else if (index > 0) {
                series.Points.splice(0, index - 1);
            }
            this.ProcessYData(series, series.FullValue);
        });
    }
    ProcessYData(series, fullValue) {
        if (series.YAxisType == YAxisType.Text) {
            if (series.YAxisItems.length > 0) {
                series.YMax = series.YAxisItems[0].Value;
                series.YMin = series.YAxisItems[0].Value;
                for (let item of series.YAxisItems) {
                    if (item.Value < series.YMin) series.YMin = item.Value;
                    if (item.Value > series.YMax) series.YMax = item.Value;
                }
            }
        } else {
            super.ProcessYData(series, fullValue);
        }
    }
    SetData(datas, startTime, endTime) {
        this.UpdateDataSeries(datas);
        for (const data of datas) {
            if (data.EigenType == null) continue;
            let id = `${data.PointId}_${data.EigenType.Key}`;
            if (!this.Datas.has(id)) {
                continue;
            }
            const series = this.Datas.get(id);
            series.Points = new Array();
            for (let y = 0; y < data.Datas.DataCount; y++) {
                const p = data.Datas.Points[y];
                if (series.YAxisType == YAxisType.Text) {
                    const item = series.YAxisItems.find((r) => r.Id == parseInt(p.y));
                    if (item) {
                        series.Points.push(new DataPoint(p.X, item.Value));
                    }
                } else {
                    if (p.Flag > 100 && !p.AlarmLevel) {
                        //升级前版本产生的报警，历史数据模式下做兼容
                        if (p.Flag == 101) p.AlarmLevel = 8;
                        if (p.Flag == 102) p.AlarmLevel = 16;
                    }
                    if (data.AlarmParams != null && data.AlarmParams[y] != null) {
                        p.AlarmLevel = data.AlarmParams[y];
                    }
                    series.Points.push(p);
                }
            }
            series.XMin = startTime ? Number(dayjs(startTime)) : series.Points[0].X;
            series.XMax = endTime ? Number(dayjs(endTime)) : series.Points[series.Points.length - 1].X;
            this.checkCrossDay(series.XMin, series.XMax);
            series.FullValue = ruleCheck.IsNullOrSpaceStr(series.FullValue) ? 1000 : parseFloat(series.FullValue);
            this.ProcessYData(series, series.FullValue);
        }
        let temp = this.getOrderDataSeries(this.Datas.getValues());
        this.SetLineColor(temp);
        this.Graph?.SetData(temp);
        temp = null;
    }
    SetIntervalRefresh(interval = null) {
        let sender = this;
        this.debounce.run(() => {
            let dataArray = this.Datas.getValues();
            let serieses = this.getOrderDataSeries(dataArray);
            sender.SetLineColor(serieses);
            if (!sender.Graph) return;
            let coord = sender.Graph.WebChart.Information.CoordinateInfo;
            for (const ser of serieses) {
                if (ser.Points.length > 0) {
                    let lastX = ser.Points[ser.Points.length - 1].X;
                    if (lastX < ser.XMax && lastX > coord.XMax) {
                        if (sender.LastEndPoint == null) sender.LastEndPoint = ser.Points[ser.Points.length - 1];
                        let delta = lastX - sender.LastEndPoint.X;
                        ser.XMax = ser.XMax + delta;
                        ser.XMin = ser.XMin + delta;
                    } else {
                        sender.LastEndPoint = null;
                    }
                }
            }
            sender.Graph?.SetData(serieses);
            serieses = null;
            dataArray = null;
        }, interval || store.state.graphSetting.DataIntervalMillsec);
    }
    getOrderDataSeries(serieses) {
        let registerPoints = this.Option.RegisterPoints;
        let result = [];
        if (registerPoints?.length > 0) {
            for (let rp of registerPoints) {
                for (let eigen of JSON.parse(rp.ValueList)) {
                    let id = `${rp.PointId}_${eigen.Id}`;
                    let series = serieses.find((r) => r.PointId == id);
                    if (series) {
                        result.push(series);
                    }
                }
            }
            if (result.length > 0 && result[0].YAxisType == YAxisType.Text) {
                return result.filter((r) => r.YAxisType == YAxisType.Text);
            }
            return result.filter((r) => r.YAxisType == YAxisType.Number);
        } else {
            return serieses;
        }
    }
    UpdateXCoordinate(start, end) {
        this.checkCrossDay(start, end);
        this.Graph?.UpdateXCoordinate(start, end);
    }
    checkCrossDay(start, end) {
        const startDate = dayjs(start);
        const endDate = dayjs(end);
        const axis = this.Option.Axis;
        if (!(axis.XAxisType == XAxisType.Absolute && axis.XAxisShowType == XAxisShowType.ShowDate) &&
            (startDate.year() != endDate.year() || startDate.month() != endDate.month() || startDate.day() != endDate.day())) {
            axis.XAxisType = XAxisType.Absolute;
            axis.XAxisShowType = XAxisShowType.ShowDate;
        }
    }
    ClearData(start, end) {
        super.ClearData(start, end);
        this.XMin = null;
        this.XMax = null;
    }
}

export class HTrendGraph extends TrendGraph {
    constructor() {
        super();
    }
    CreateGraph() {
        this.Graph = new HTrendAnalysisChart();
        this.Graph.Init(this.Option, this.Plugins);
        this.Graph.OnChartChanged();
    }
}