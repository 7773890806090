import { KeyListenManager, ElementBase } from "@core/graphs/plugins/DH-plugin-base";
import $ from "jquery";
import { numberRound, ruleCheck } from "@common/js/rules";
import { draggable } from "@core/graphs/webcharts/Dh-webchart-draggable";
import { guid, i18nGlobal } from "@common/js/utils";
import DrawApi from "@core/graphs/webcharts/Dh-webchart-drawApis";
import { ThemeResource } from "@components/js/utils/model";
import { CursorElement, CursorPluginBase } from "@core/graphs/plugins/Dh-plugin-cursor-base";
import { PluginMode } from "../webcharts/Dh-webchart-models";

//光标
class CrossCurosrEmelemt extends ElementBase {
    constructor(owner, linecolor, startPos) {
        super();
        this.parent = owner;
        this.IsMouseDown = false;
        this.Width = 16;
        this.StartPos = startPos;
        this.DOM = null;
        this.Color = linecolor !== undefined ? linecolor : "red";
        this.Stroke = 2;
        this.Height = 16;
        this.Init();
    }
    Init() {
        const g = document.createElementNS("http://www.w3.org/2000/svg", "g");
        const line1 = document.createElementNS("http://www.w3.org/2000/svg", "line");
        const line2 = document.createElementNS("http://www.w3.org/2000/svg", "line");
        $(g).attr("transform", "translate(1,0)").attr("candrag", "true").css("width", "9px").css("height", "100%").css("cursor", "move").attr("isSelected", "false").attr("cursor-color", this.Color);
        $(line1)
            .attr("stroke", this.Color)
            .attr("stroke-width", this.Stroke)
            .attr("x1", this.StartPos.X - this.Width / 2)
            .attr("y1", this.StartPos.Y)
            .attr("x2", this.StartPos.X + this.Width / 2)
            .attr("y2", this.StartPos.Y);
        $(line2)
            .attr("stroke", this.Color)
            .attr("stroke-width", this.Stroke)
            .attr("x1", this.StartPos.X)
            .attr("y1", this.StartPos.Y - this.Height / 2)
            .attr("x2", this.StartPos.X)
            .attr("y2", this.StartPos.Y + this.Height / 2);
        $(g).append(line1).append(line2);
        $(g).append(this.AddRect(this.StartPos.X - this.Width / 2, this.StartPos.Y - this.Height / 2, this.Width, this.Height, "rgba(0,0,0,0)"));
        this.DOM = $(g);
        this.RegisterEvent();
        $(this.parent.Owner.PluginContainer).append(this.DOM);
    }
    Remove() {
        $(this.DOM).off("mousedown");
        $(this.DOM).off("mouseup");
        this.DOM.remove();
    }
    DrawPoint(y) {
        const rect = this.parent.Owner.Information.ShapeContainerRect;
        const theme = this.parent.Owner.Option.Axis.Theme;
        const coordSystem = this.parent.Owner.CoordinateSystem;
        if (y - 3 > rect.Top && y - 3 <= rect.Height + rect.Top && coordSystem.DrawPoint) {
            const point = this.AddRect(this.StartPos.X + 2, y - 3, 6, 6, theme == 1 ? "black" : "red");
            this.Points.push(point);
            this.DOM.append(point);
        }
    }
    RegisterEvent() {
        if (this.parent.IsEnabled) {
            $(this.DOM).off("mousedown");
            $(this.DOM).on("mousedown", (e) => {
                this.OnMouseDown(e);
            });
            $(this.DOM).off("mouseup");
            $(this.DOM).on("mouseup", (e) => {
                this.OnMouseUp(e);
            });
        }
    }
    OnMouseUp(e) {
        const plugins = $("div .cursor-window");
        $.each(plugins, function (i, item) {
            $(item).css("pointer-events", "auto");
        });
        this.IsMouseDown = false;
    }
    OnMouseDown(e) {
        if (e.which != 1) return;
        if (!this.IsSelected()) {
            this.Select();
        }
        e.stopPropagation();
        this.IsMouseDown = true;

        KeyListenManager.getListenManager();
        window.KeyManager.CurrentElement = this.parent;
        const plugins = $("div .cursor-window");
        $.each(plugins, function (i, item) {
            $(item).css("pointer-events", "none");
        });
    }
    Select() {
        this.UnSelect();
        this.DOM.attr("isSelected", "true");
    }
    UnSelect() {
        const groups = $(this.DOM[0].parentNode).children("g");
        $.each(groups, function (i, g) {
            if ($(g).attr("isSelected")) {
                $(g).attr("isSelected", "false");
            }
        });
    }
    IsSelected() {
        return this.DOM.attr("isSelected") == "true";
    }
}

export class SingleCursorPlugin extends CursorPluginBase {
    constructor(mode) {
        super(mode);
        this.Cursor = null;
        this.CursorData = null;
        this.SelectedItem = null;
        this.CacheIds = []; // 缓存id
        this.nameTables = new Set();
        this.valuesTables = new Set();
        this.Title = i18nGlobal('core.singleCursor');
    }
    CreatePlugin() {
        this.LegendWnd = $(this.CreateLegendWnd());
        $(this.Owner.ChartContainer).append(this.LegendWnd);
        if (this.IsEnabled) {
            new draggable({
                element: this.LegendWnd,
                containment: "parent",
            });
        }
        this.InitSvgEvent();
        this.InitRadioEvent();
    }
    CreateLegendWnd() {
        const padding = this.Owner.Information.Padding;
        const rect = this.Owner.Information.ShapeContainerRect;
        return `<div class="cursor-window none-select" style="pointer-events:auto;position: absolute;left:${(rect.Left + 15)}px;top:${padding.Top}px">
                    <table>
                        <tbody>
                            <tr>
                                <th style="white-space: nowrap;">
                                    <div class="cursor-array"></div>
                                    <span class="cursor-title">${this.Title}</span>
                                </th>
                                <th style="white-space: nowrap;" class="cursor-xvalue">
                                </th>
                            </tr>
                            <tr>
                                <td>
                                    <div class="cursor-container"></div>
                                </td>
                                <td>
                                    <div class="data-container"></div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>`;
    }
    InitRadioEvent() {
        const plugin = this.GetStatisticsPlugin();
        plugin.RegisterItemChangeEvent(this.Id, (selectedItem) => {
            this.SelectedItem = selectedItem;
        });
        this.LegendWnd.on('click', '.cussor_radio_wrapper', function () {
            const id = $(this).data('id');
            if (plugin) {
                plugin.RadioClickCallBack(id);
            }
        })
    }
    GetStatisticsPlugin() {
        return this.Owner.PluginManager.Plugins.get(PluginMode.Statistics)
    }
    UnRegisteStatisticsEvent() {
        const plugin = this.GetStatisticsPlugin();
        plugin?.UnRegisterItemChangeEvent(this.Id);
    }
    OnRender() {
        if (!this.Owner.Option.isHistory && this.Owner.Option.Axis.CursorFollow) {
            this.UpdateLatest();
        } else {
            if (this.Entity.RefreshCursor == true) {
                if (this.CursorData != null && this.CursorData.X) {
                    const coordInfo = this.Owner.Information.CoordinateInfo;
                    if (coordInfo.XMin > this.CursorData.X) {
                        return this.SetPosition(0);
                    }
                }
            }
            if (this.CursorData != null && this.CursorData.XIndex) {
                this.SetPosition(this.CursorData.XIndex);
            } else {
                this.SetPosition(0);
            }
        }
    }
    UpdateLatest() {
        let x = this.Owner.CoordinateSystem.GetLastestPoint();
        this.CursorData = x;
        this.UpdatePosition(this.CursorData);
    }
    OnSizeChanged() {
        super.OnSizeChanged();
        let rect = this.Owner.Information.ShapeContainerRect;
        if (this.Cursor != null) {
            this.Cursor.Remove();
        }
        this.Cursor = new CursorElement(this, rect.Height, this.Owner.CoordinateSystem.AxisColor, "1", { X: rect.Left, Y: rect.Top });
        $(this.Owner.PluginContainer).append(this.Cursor.DOM);
    }
    OnMouseDown(e) {
        if (!super.OnMouseDown(e)) return false;
        if (this.Cursor?.DOM == null) return false;
        this.IsMouseDown = true;
        let offsetx = this.getMousePostion(e);
        let data = this.Owner.CoordinateSystem.GetPositionData(offsetx);
        if (data) this.WacthCursorChanged(() => { this.UpdatePosition(data); })
    }
    OnMouseUp(e) {
        if (!super.OnMouseUp(e)) return false;
        if (this.Cursor && this.Cursor.IsMouseDown) {
            let offsetx = this.getMousePostion(e);
            this.IsNotify = true;
            let data = this.Owner.CoordinateSystem.GetPositionData(offsetx);
            if (data) this.WacthCursorChanged(() => { this.UpdatePosition(data); })
            this.Cursor.IsMouseDown = false;
        } else {
            this.IsNotify = true;
            let offsetx = this.getMousePostion(e);
            let data = this.Owner.CoordinateSystem.GetPositionData(offsetx);
            if (data) this.WacthCursorChanged(() => { this.UpdatePosition(data); })
        }
        this.IsMouseDown = false;
    }
    OnMouseMove(e) {
        if (!super.OnMouseMove(e)) return false;
        if (this.Cursor && this.Cursor.IsMouseDown && e.which == 1) {
            this.IsNotify = false;
            let offsetx = this.getMousePostion(e);
            let data = this.Owner.CoordinateSystem.GetPositionData(offsetx);
            if (data) this.WacthCursorChanged(() => { this.UpdatePosition(data); })
            return false;
        }
        return true;
    }
    KeyLeftPress() {
        if (!this.IsEnabled) return;
        if (this.CursorData != null) {
            this.IsNotify = false;
            let index = this.CursorData.XIndex > 0 ? this.CursorData.XIndex - 1 : this.CursorData.XIndex;
            const currentX = this.CursorData.X;
            this.WacthCursorChanged(() => {
                const nextX = this.SetPosition(index);
                if (nextX && currentX === nextX) {
                    // 点一样再执行一次
                    this.SetPosition(index > 1 ? index - 1 : 0);
                }
            })
        }
    }
    KeyRightPress() {
        if (!this.IsEnabled) return;
        if (this.CursorData != null) {
            this.IsNotify = false;
            let index = this.CursorData.XIndex + 1;
            const currentX = this.CursorData.X;
            this.WacthCursorChanged(() => {
                const nextX = this.SetPosition(index);
                if (nextX && currentX === nextX) {
                    this.SetPosition(index + 1);
                }
            });
        }
    }
    RefreshRelatedData() {
        this.IsNotify = true;
        this.NotifyPositionChanged();
    }
    NotifyPositionChanged() {
        if (this.Entity.onPositionChanged && this.IsNotify == true && this.CursorData) {
            this.Entity.onPositionChanged(this.CursorData);
        }
        if (this.Entity.onCursorPosChanged && this.CursorData && this.IsCursorChanged) {
            this.Entity.onCursorPosChanged(this.CursorData);
        }
    }
    GetCursorDatas(x, showName) {
        if (!showName) return x;
        if (x == null) x = { Items: [] };
        const data = this.Owner.ShapeOperator.Data;
        let arr = [];
        if (Array.isArray(data)) {
            data.forEach(item => {
                const xData = x.Items.find(t => t.Tag.Source.PointId === item.PointId);
                if (xData) {
                    xData.YUnit = this.GetDataUnit(item);
                    arr.push(xData)
                } else {
                    arr.push({
                        OffsetY: 0,
                        Color: item.LineColor,
                        Text: item.Legend,
                        Tag: {
                            Point: null,
                            Source: item.Tag
                        },
                        Y: null,
                        Unit: this.GetDataUnit(item)
                    })
                }
            })
        }
        x.Items = arr;
        return x;
    }
    GetDataUnit(data) {
        const showUnit = this.Owner.CoordinateSystem.ShowUnit;
        const yAxisLogType = this.Owner.CoordinateSystem.YAxisLogType;
        const isShowUnit = showUnit && !ruleCheck.IsNullOrSpaceStr(data.YUnit);
        let unit = "";
        if (isShowUnit) {
            if (yAxisLogType === 3) {
                unit = "[dB]";
            } else {
                unit = "[" + data.YUnit + "]";
            }
        }
        return unit;
    }
    UpdatePosition(cussorData) {
        if (this.Cursor == null) return;
        const cursorPointName = this.Owner?.Option?.Axis?.ShowCursorPointName;
        this.Cursor.ClearPoints();
        let rect = this.Owner.Information.ShapeContainerRect;
        const data = this.GetCursorDatas(cussorData, cursorPointName);
        if (data && data != null) {
            this.UpdateDataName(data, cursorPointName);
            this.UpdateDataValue(data, cursorPointName);
            this.CursorData = data;
            this.NotifyPositionChanged();
            if (!isNaN(this.CursorData.OffsetX) && isFinite(this.CursorData.OffsetX)) {
                if (this.CursorData.OffsetX >= 0 && Math.floor(this.CursorData.OffsetX) <= rect.Width) {
                    this.Cursor.DOM.removeClass("hide");
                    this.Cursor.DOM.attr("transform", "translate(" + (this.CursorData.OffsetX - 6) + ",0)");
                } else {
                    this.Cursor.DOM.addClass("hide");
                }
            }
        } else {
            let container = this.LegendWnd.find(".cursor-container");
            container.empty();
            this.Cursor.DOM.attr("transform", "translate(-6,0)");
        }
    }
    UpdateDataName(data, showPointName) {
        const container = this.LegendWnd.find(".cursor-container");
        const $td = container.parent();
        if ($td.hasClass('hidden')) $td.removeClass('hidden');
        if (!showPointName) {
            $td.addClass('hidden');
            return;
        }
        const xValue = this.LegendWnd.find('.cursor-xvalue');
        xValue.removeClass('hidden');

        xValue.text(`X: ${data.XText ?? ''}`);

        const items = new Set(data.Items.map(d => { return `name_${d.Tag.Source.PointId}`; }));
        const minus = [...this.nameTables.keys()].filter(x => !items.has(x));
        minus.forEach(key => {
            this.nameTables.delete(key);
            container.find("#" + key).remove();
        })

        data.Items.forEach((d, index) => {
            this.UpdateDataNameRow(container, d, index);
        });
    }
    UpdateDataNameRow(container, data, index) {
        if (!data.Tag.Source.PointId) return;
        const key = `name_${data.Tag.Source.PointId}`;
        if (this.nameTables.has(key)) {
            const $rowSpan = container.find("#" + key);
            $rowSpan.css('color', data.Color);
            $rowSpan.find(".radio-big").css('background-color', data.Color);
            $rowSpan.find(".radio-small").css('background-color', data.Tag.Source.PointId === this.SelectedItem ? '#fff' : data.Color);
        } else {
            const rowSpan = `<span id="${key}" class="cursor-row" style="color: ${data.Color};display:flex;margin-left:4px;font-size:12px">
                                    <span class='cussor_radio_wrapper'" data-id='${data.Tag.Source.PointId}'>
                                        <span class="radio-big" style="background-color:${data.Color}"></span>
                                        <span class="radio-small" style="background-color:${data.Tag.Source.PointId === this.SelectedItem ? '#fff' : data.Color}"></span>
                                    </span>
                                    <span class='cursor_text'> ${data.Text}</span>
                                </span>`
            const brotherRowSpan = container.find(".cursor-row")[index - 1];
            if (brotherRowSpan) {
                $(brotherRowSpan).after($(rowSpan));
            } else {
                container.prepend(rowSpan);
            }
            this.nameTables.add(key);
        }
    }
    UpdateDataValue(data, showPointName) {
        const rect = this.Owner.Information.ShapeContainerRect;
        const container = this.LegendWnd.find(".data-container");
        container.empty();

        if (!showPointName) {
            const xValue = this.LegendWnd.find('.cursor-xvalue');
            xValue.addClass('hidden');
            container.append(`<span class="cursor-row">X:${data.XText}</span>`);
        }

        data.Items.forEach(d => {
            if (showPointName) {
                const unit = d.Y ? d.YUnit : '';
                container.append(
                    `<span class="cursor-row" style="color: ${d.Color};display:flex;margin-left:4px">
                            Y: ${d.Y ?? '-'}${unit}
                        </span>`
                )
            } else {
                container.append(`<span class="cursor-row" style="color:${d.Color}">Y:${d.Y}</span>`);
            }
            if (d.OffsetY !== null) this.Cursor.DrawPoint(d.OffsetY + rect.Top);
        })
    }
    GetPosition() {
        let sender = this;
        let result = {
            PluginType: sender.Entity.Type,
            Position: this.CursorData == null ? [] : [this.CursorData],
        };
        return result;
    }
    GetCursorPosition() {
        return this.CursorData == null ? 0 : this.CursorData.XIndex;
    }
    GetZoomPosition() {
        if (this.CursorData) {
            return this.CursorData.X;
        } else {
            let info = this.Owner.Information;
            return (info.CoordinateInfo.XMin + info.CoordinateInfo.XMax) / 2;
        }
    }
    SetPosition(pos) {
        let data = this.Owner.ShapeOperator.Data;
        if (data.length > 0) {
            let x = this.Owner.CoordinateSystem.GetPositionByIndex(pos);
            if (x) {
                this.CursorData = x;
                this.UpdatePosition(this.CursorData);
                return this.CursorData.X;
            } else {
                this.CursorData = {
                    XIndex: pos,
                };
                this.UpdatePosition(null);
            }
        } else {
            this.CursorData = {
                XIndex: pos,
            };
            this.UpdatePosition(null);
        }
    }
    SetPositionByX(pos) {
        this.IsNotify = false;
        let data = this.Owner.ShapeOperator.Data;
        if (pos.length == 1 && data.length > 0) {
            let x = this.Owner.CoordinateSystem.GetPositionByX(pos[0]);
            if (x) {
                this.CursorData = x;
                this.UpdatePosition(this.CursorData);
            }
        }
        this.IsNotify = true;
    }
    Close() {
        super.Close();
        this.UnRegisteStatisticsEvent();
        this.LegendWnd?.off('click');
        if (this.Cursor != null) {
            this.Cursor.Remove();
            this.Cursor = null;
        }
        this.LegendWnd?.remove();
    }
    ClearData() {
        //this.CursorData = null;
    }
}

export class SeparateSingleCursorPlugin extends CursorPluginBase {
    constructor(mode) {
        super(mode);
        this.Cursors = new Map();
        this.CursorDatas = new Map();
        this.ChartMouseDown = new Map();
        this.Title = i18nGlobal('core.singleCursor');
        this.IsSizeChanged = true;
    }
    OnRender() {
        let charts = this.Owner.Information.GetCharts();
        let cursors = [...this.Cursors.keys()];
        if (cursors.length != charts.length) this.IsSizeChanged = true;
        const temp = new Map();
        const checkedTemp = new Map();
        for (const c of cursors) {
            if (!charts.has(c) || this.IsSizeChanged) {
                const cursor = this.Cursors.get(c);
                checkedTemp.set(c, cursor.IsSelected());
                this.Cursors.get(c).Remove();
                this.Cursors.delete(c);
                temp.set(c, cursor.IsMouseDown);
            }
        }
        for (const key of charts.keys()) {
            let chart = charts.get(key);
            let chartRect = chart.ShapeContainerRect;
            if (!this.Cursors.has(this) && chartRect) {
                let cursor = new CursorElement(this, chartRect.Height, this.Owner.CoordinateSystem.AxisColor, "1", { X: chartRect.Left, Y: chartRect.Top });
                cursor.IsMouseDown = temp.get(key);
                $(this.Owner.PluginContainer).append(cursor.DOM);
                if (checkedTemp.has(key) && checkedTemp.get(key) == true) {
                    cursor.Select();
                }
                this.Cursors.set(key, cursor);
            }
        }
        this.IsSizeChanged = false;
        this.RefreshCursorPosition();
    }
    RefreshCursorPosition() {
        let sender = this;
        let array = new Array();
        if (sender.CursorDatas != null && sender.CursorDatas.size > 0) {
            for (const key of sender.Cursors.keys()) {
                if (this.CursorDatas.has(key)) {
                    let cd = sender.CursorDatas.get(key);
                    if (cd.XIndex) {
                        array.push({ Key: key, Value: cd.XIndex });
                    } else {
                        array.push({ Key: key, Value: 0 });
                    }
                } else {
                    array.push({ Key: key, Value: 0 });
                }
            }
        } else {
            let cursorKeys = sender.Cursors.keys();
            for (const key of cursorKeys) {
                array.push({ Key: key, Value: 0 });
            }
        }
        sender.SetPosition(array);
    }
    OnMouseDown(e) {
        if (!super.OnMouseDown(e)) return false;
        let charts = this.Owner.Information.GetCharts();
        for (const key of charts.keys()) {
            const chart = charts.get(key);
            const rect = chart.ShapeContainerRect;
            if (rect.Left <= e.offsetX && rect.Left + rect.Width >= e.offsetX && rect.Top <= e.offsetY && rect.Top + rect.Height >= e.offsetY) {
                this.ChartMouseDown.set(key, true);
            } else {
                this.ChartMouseDown.set(key, false);
            }
        }
    }
    OnMouseUp(e) {
        if (!super.OnMouseUp(e)) return false;
        if (e.which != 1) return false;
        let temp = new Map();
        let charts = this.Owner.Information.GetCharts();
        for (const key of this.Cursors.keys()) {
            let cursor = this.Cursors.get(key);
            if (cursor.IsMouseDown || this.ChartMouseDown.get(key) == true) {
                let offsetx = this.getMousePostion(e, charts.get(key));
                let data = this.Owner.CoordinateSystem.GetPositionData(offsetx, key);
                if (data) temp.set(key, data);
            } else {
                temp.set(key, this.CursorDatas.get(key));
            }
            if (cursor.IsMouseDown) {
                cursor.IsMouseDown = false;
            }
            this.ChartMouseDown.set(key, false);
        }
        this.UpdatePosition(temp);
        return true;
    }
    OnSizeChanged() {
        super.OnSizeChanged();
        this.IsSizeChanged = true;
    }
    OnMouseMove(e) {
        if (!super.OnMouseMove(e)) return false;
        if (e.which !== 1) return false;
        if (this.Cursors.size == 0) return false;

        let sender = this;
        sender.IsNotify = false;
        let charts = sender.Owner.Information.GetCharts();
        let temp = new Map();
        for (const key of sender.Cursors.keys()) {
            let cursor = sender.Cursors.get(key);
            if (cursor.IsMouseDown) {
                let offsetx = sender.getMousePostion(e, charts.get(key));
                let data = sender.Owner.CoordinateSystem.GetPositionData(offsetx, key);
                if (data) temp.set(key, data);
            } else {
                if (!sender.CursorDatas.has(key)) {
                    const d = sender.Owner.CoordinateSystem.GetPositionByIndex(0, key);
                    if (d) {
                        temp.set(key, d);
                    }
                } else {
                    temp.set(key, sender.CursorDatas.get(key));
                }
            }
        }
        sender.UpdatePosition(temp);
        return true;
    }
    SetPosition(pos) {
        let sender = this;
        sender.CursorDatas = new Map();
        for (const p of pos) {
            let data = sender.Owner.CoordinateSystem.GetPositionByIndex(p.Value, p.Key);
            if (data) {
                sender.CursorDatas.set(p.Key, data);
            }
        }
        sender.UpdatePosition(sender.CursorDatas);
    }
    getMousePostion(e, chart) {
        let rect = chart.ShapeContainerRect;
        let c_x = e.offsetX;
        let offsetx = c_x - rect.Left;
        if (offsetx + 4 > rect.Width) {
            offsetx = rect.Width;
        }
        if (offsetx < 0) {
            offsetx = 0;
        }
        return offsetx;
    }
    UpdatePosition(datas) {
        for (let cursor of this.Cursors.values()) {
            cursor.ClearPoints();
        }
        let container = this.LegendWnd.find(".cursor-container");
        if (datas.size > 0) {
            container.empty();
            for (const k of datas.keys()) {
                let data = datas.get(k);
                if (data) {
                    let rows = "X" + ":" + data.XText + "&emsp;" + "Y" + ":" + data.Y;
                    container.append('<span class="cursor-row" style="color:' + data.Color + '">' + rows + "</span>");
                }
            }

            this.CursorDatas = datas;
            this.NotifyPositionChanged();
            for (const key of this.Cursors.keys()) {
                let data = this.CursorDatas.get(key);
                let chart = this.Owner.Information.GetCharts().get(key);
                let cursor = this.Cursors.get(key);
                if (!isNaN(data.OffsetX) && isFinite(data.OffsetX)) {
                    cursor.DOM.attr("transform", "translate(" + (data.OffsetX - 6) + ",0)");
                }
                if (data.OffsetX < 0 || data.OffsetX > chart.ShapeContainerRect.Width) {
                    cursor.DOM.addClass("hide");
                } else {
                    cursor.DOM.removeClass("hide");
                }
            }
        } else {
            container.empty();
            for (const cursor of this.Cursors.values()) {
                cursor.DOM.attr("transform", "translate(-6,0)");
            }
        }
    }
    NotifyPositionChanged() {
        if (this.Entity.onPositionChanged && this.IsNotify == true && this.CursorData) {
            let e = {
                Items: this.CursorDatas,
            };
            this.Entity.onPositionChanged(e);
        }
    }
    GetPosition() {
        let sender = this;
        let array = new Array();
        if (sender.CursorDatas != null && sender.CursorDatas.size > 0 && sender.Cursors != null) {
            let cursorKeys = sender.CursorDatas.keys();
            $.each(cursorKeys, function () {
                if (sender.CursorDatas.has(this)) {
                    let cd = sender.CursorDatas.get(this);
                    if (cd.XIndex) {
                        array.push({ Key: this, Value: cd.XIndex });
                    } else {
                        array.push({ Key: this, Value: 0 });
                    }
                } else {
                    array.push({ Key: this, Value: 0 });
                }
            });
        } else {
            let cursorKeys = sender.Cursors.keys();
            $.each(cursorKeys, function () {
                array.push({ Key: this, Value: 0 });
            });
        }
        let result = {
            PluginType: sender.Entity.Type,
            Position: array,
        };
        return result;
    }
    GetZoomPosition() {
        let array = new Array();
        if (this.CursorDatas != null && this.CursorDatas.size > 0 && this.Cursors != null) {
            for (const key of this.CursorDatas.keys()) {
                if (this.CursorDatas.has(key)) {
                    let cd = this.CursorDatas.get(key);
                    if (cd.X) {
                        array.push({ Key: key, Value: cd.X });
                    } else {
                        array.push({ Key: key, Value: 0 });
                    }
                } else {
                    array.push({ Key: key, Value: 0 });
                }
            }
        } else {
            for (const key of this.Cursors.keys()) {
                array.push({ Key: key, Value: 0 });
            }
        }
        return array;
    }
    RefreshRelatedData() {
        this.IsNotify = true;
        this.NotifyPositionChanged();
    }
    Close() {
        super.Close();
        for (let cursor of this.Cursors.values()) {
            cursor.Remove();
        }
        this.Cursors.clear();
        if (this.LegendWnd != null) {
            this.LegendWnd.remove();
        }
    }
    ClearData() {
        //this.CursorData = new Hash();
    }
    KeyLeftPress() {
        if (!this.IsEnabled) return;
        let array = new Array();
        for (let key of this.CursorDatas.keys()) {
            let cursor = this.Cursors.get(key);
            let data = this.CursorDatas.get(key);
            if (cursor.IsSelected()) {
                let index = data.XIndex > 0 ? data.XIndex - 1 : data.XIndex;
                array.push({ Key: key, Value: index });
            } else {
                array.push({ Key: key, Value: data.XIndex });
            }
        }
        this.SetPosition(array);
    }
    KeyRightPress() {
        if (!this.IsEnabled) return;
        let array = new Array();
        for (let key of this.CursorDatas.keys()) {
            let cursor = this.Cursors.get(key);
            let data = this.CursorDatas.get(key);
            if (cursor.IsSelected()) {
                let index = data.XIndex + 1;
                array.push({ Key: key, Value: index });
            } else {
                array.push({ Key: key, Value: data.XIndex });
            }
        }
        this.SetPosition(array);
    }
}

export class TrendSingleCursorPlugin extends SingleCursorPlugin {
    constructor(mode) {
        super(mode);
        this.sizeChanged = false;
    }
    OnSizeChanged() {
        this.sizeChanged = true;
        super.OnSizeChanged();
    }
    OnRender() {
        if (!this.Owner.Option.isHistory && this.Owner.Option.Axis.CursorFollow) {
            this.UpdateLatest();
        } else {
            if (this.CursorData != null) {
                let x = this.Owner.CoordinateSystem.GetPositionByX(this.CursorData.X);
                let hasChange = x == null || x.X != this.CursorData.X;
                if (hasChange == false) this.IsNotify = false;
                this.CursorData = x;
                this.UpdatePosition(this.CursorData);
                this.IsNotify = true;
            } else {
                this.SetPosition(0);
            }
        }
        this.sizeChanged = false;
    }
    UpdateLatest() {
        let x = this.Owner.CoordinateSystem.GetLastestPoint();
        this.CursorData = x;
        this.UpdatePosition(this.CursorData);
    }
}

export class WaterfallSingleCursorPlugin extends SingleCursorPlugin {
    constructor(mode) {
        super(mode);
        this.Id = guid();
        this.drawApi = new DrawApi();
        this.selectTime = null;
        this.offsetY = null;
    }

    CreatePlugin() {
        let rect = this.Owner.Information.ChartContainerRect;
        let table =
            '<div class="cursor-window none-select" style="pointer-events:auto;position: absolute;left:' +
            (rect.Left + 5) +
            "px;top:" +
            rect.Top +
            'px"><table>' +
            "<tbody>" +
            "<tr>" +
            '<th style="white-space: nowrap;">' +
            '<div class="cursor-array"></div>' +
            '<span class="cursor-title">' +
            this.Title +
            "</span>" +
            "</th>" +
            "</tr>" +
            "<tr>" +
            "<td>" +
            '<div class="cursor-container">' +
            "</div>" +
            "</td>" +
            "</tr>" +
            "</tbody>" +
            "</table></div>";
        this.LegendWnd = $(table);
        $(this.Owner.ChartContainer).append(this.LegendWnd);
        if (this.IsEnabled) {
            new draggable({
                element: this.LegendWnd,
                containment: "parent",
            });
        }
        this.InitSvgEvent();
    }
    OnMouseDown(e) {
        if (e.which != 1) return false;
        if (window.event.ctrlKey) return false;
        if (!this.IsEnabled) return false;
        KeyListenManager.getListenManager();
        window.KeyManager.CurrentElement = this;
        //let rect = this.Owner.Information.ShapeContainerRect;
        //if (e.offsetY < rect.Top || e.offsetY > rect.Top + rect.Height) return false;
        //if (e.offsetX < rect.Left - 4 || e.offsetX > rect.Left + rect.Width + 4) return false;
        $('body').off('mouseup', this.onMouseUpHandler);
        $('body').on('mouseup', this.onMouseUpHandler);
        return true;
    }
    CreatePlugin() {
        super.CreatePlugin();
        this.EnsureContainerCreated();
    }
    EnsureContainerCreated() {
        const container = this.Owner.PluginContainer;
        let element = document.getElementById(this.Id);
        if (element == null) {
            element = document.createElement("canvas");
            element.setAttribute("id", this.Id);
            element.setAttribute("style", "position:absolute;left:0;");
            container.before(element);
        }
        element.width = $("#" + this.Owner.ChartContainer.id).width();
        element.height = $("#" + this.Owner.ChartContainer.id).height();
        this.EnsureContainerCliped();
        return element;
    }
    EnsureContainerCliped() {
        const rect = this.Owner.Information.ChartContainerRect;
        this.drawApi.SetContext(this.Id);
        this.drawApi.ClipRect(rect.Left, rect.Top, rect.Width, rect.Height);
    }
    OnSizeChanged() { }
    OnRender() {
        this.EnsureContainerCreated();
        super.OnRender();
    }
    getMousePostion(e) {
        let rect = this.Owner.Information.ShapeContainerRect;
        let verInfo = this.Owner.Information.VertexInfo;
        let c_x = e.offsetX;
        let offsetx = c_x - rect.Left - 4 - (verInfo.FrontLeftBottom.Y - e.offsetY) / Math.tan((verInfo.XZAngle * Math.PI) / 180);
        if (offsetx + 4 > rect.Width) {
            offsetx = rect.Width;
        }
        if (offsetx < 0) {
            offsetx = 0;
        }
        this.offsetY = e.offsetY;
        this.selectTime = null;
        return offsetx;
    }
    UpdatePosition(data) {
        this.updateCursorLine(data);
    }
    updateCursorLine(data) {
        this.drawApi.SetContext(this.Id);
        this.drawApi.ClearAll();
        const vInfo = this.Owner.Information.VertexInfo;
        const theme = this.Owner.Option.Axis.Theme;
        if (data?.Items.length > 0) {
            this.drawApi.Save();
            this.drawApi.ClipPath([vInfo.FrontLeftTop, vInfo.FrontLeftBottom, vInfo.FrontRightBottom, vInfo.BackRightBottom, vInfo.BackRightTop, vInfo.BackLeftTop, vInfo.FrontLeftTop]);
            this.drawApi.BeginPath();
            let selectIndex = 0;
            for (let i = 0; i < data.Items.length; i++) {
                let point = data.Items[i];
                if (this.selectTime == null) {
                    if (Math.abs(point.OffsetY - this.offsetY) < Math.abs(data.Items[selectIndex].OffsetY - this.offsetY)) {
                        selectIndex = i;
                    }
                } else {
                    if (point.Z == this.selectTime) {
                        selectIndex = i;
                    }
                }
                this.drawApi.LineTo(point.OffsetX, point.OffsetY);
            }
            this.drawApi.Stroke(ThemeResource[theme].DotColor(), 1);
            this.drawApi.Restore();
            if (this.selectTime == null) {
                this.selectTime = data.Items[selectIndex].Z;
            }

            this.drawSelectLine(selectIndex);
            const currentRect = this.Owner.Information.CurrentRect;
            const { OffsetX, OffsetY } = data.Items[selectIndex];
            if (
                currentRect.Left <= OffsetX &&
                OffsetX <= currentRect.Left + currentRect.Width + 4 &&
                vInfo.FrontLeftBottom.Y + 4 >= OffsetY &&
                OffsetY >= vInfo.BackLeftBottom.Y &&
                OffsetY >= currentRect.Top &&
                OffsetY <= currentRect.Top + currentRect.Height + 4
            ) {
                this.drawApi.FillSquare(OffsetX, OffsetY, 3, ThemeResource[theme].DotColor());
            }
            this.updateInformation(data, data.Items[selectIndex]);
        }
    }
    drawSelectLine(idx) {
        this.drawApi.Save();
        let theme = this.Owner.Option.Axis.Theme;
        let data = this.Owner.ShapeOperator.Data;
        let pointData = data[idx];
        let info = this.Owner.Information;
        let temp = info.CurrentIndex;
        info.CurrentIndex = idx;
        let dataArray = this.Owner.CoordinateSystem.GetPoints(pointData);
        let rect = info.CurrentRect;
        this.drawApi.ClipRect(rect.Left, rect.Top, rect.Width, rect.Height);
        this.drawApi.Stroke("transparent", data.LineWidth);
        let first = dataArray[0];
        this.drawApi.BeginPath();
        this.drawApi.MoveTo(first.X, first.Y);
        for (let d of dataArray) {
            this.drawApi.LineTo(d.X, d.Y);
        }
        this.drawApi.Stroke(ThemeResource[theme].DotColor(), 1, data.LineWidth);
        this.drawApi.Restore();
        info.CurrentIndex = temp;
    }
    updateInformation(data, point) {
        if (data && data != null) {
            let container = this.LegendWnd.find(".cursor-container");
            container.empty();
            container.append('<span class="cursor-row">X:' + data.XText + "</span>");
            container.append('<span class="cursor-row" style="color:' + point.Color + '">Y:' + point.Y + "</span>");
            container.append('<span class="cursor-row" style="color:' + point.Color + '">Z:' + this.Owner.CoordinateSystem.getXText(point.Z) + "</span>");
            this.CursorData = data;
            this.NotifyPositionChanged();
        } else {
            let container = this.LegendWnd.find(".cursor-container");
            container.empty();
        }
    }
    Close() {
        if (this.drawApi.DrawContext) this.drawApi.Dispose();
        super.Close();
    }
}

export class TrailSingleCursorPlugin extends CursorPluginBase {
    constructor(mode) {
        super(mode);
        this.Cursors = new Map();
        this.CursorDatas = null;
        this.Title = i18nGlobal('core.singleCursor');
        this.isResize = false;
    }
    CreatePlugin() {
        super.CreatePlugin();
        if (this.Owner.ShapeOperator.Data.length > 0) {
            let ids = [];
            for (let data of this.Owner.ShapeOperator.Data) {
                ids.push(data.PointId);
            }
            this.createCursor(ids);
        }
    }
    createCursor(ids) {
        const rect = this.Owner.Information.ShapeContainerRect;
        for (let cursor of this.Cursors.values()) {
            cursor.Remove();
            this.Cursors.delete(cursor);
        }
        for (let id of ids) {
            this.Cursors.set(id, new CrossCurosrEmelemt(this, this.Owner.CoordinateSystem.AxisColor, { X: rect.Left, Y: rect.Top }));
        }
    }
    OnSizeChanged() {
        super.OnSizeChanged();
        this.isResize = true;
        let ids = this.Cursors.getKeys();
        for (let key of ids) {
            let cursor = this.Cursors.get(key);
            cursor.Remove();
            this.Cursors.delete(cursor);
        }
        this.createCursor(ids);
    }

    OnRender() {
        if (this.CursorData != null && this.CursorDatas.length > 0 && this.isResize) {
            let idxs = [];
            for (let d of this.CursorDatas) {
                idxs.push({
                    Index: d.Index,
                    Id: d.Id,
                });
            }
            this.CursorDatas = this.Owner.CoordinateSystem.GetPositionByIndex(idxs);
        } else {
            this.CursorDatas = this.Owner.CoordinateSystem.GetLastestPoint();
        }
        this.UpdatePosition(this.CursorDatas);
        this.isResize = false;
    }
    OnMouseDown(e) {
        if (!super.OnMouseDown(e)) return false;
        this.IsMouseDown = true;
        const { offsetx, offsety } = this.getMousePostion(e);
        const datas = new Array();
        for (let key of this.Cursors.keys()) {
            const data = this.Owner.CoordinateSystem.GetPosition(key, offsetx, offsety);
            if (data) {
                datas.push(data);
            }
        }
        this.CursorDatas = datas;
        this.UpdatePosition(this.CursorDatas);
    }
    OnMouseMove(e) {
        if (!super.OnMouseMove(e)) return false;
        if (this.CursorDatas == null) return;
        if (!this.IsMouseDown) return;
        let datas = [];
        const { offsetx, offsety } = this.getMousePostion(e);
        for (let key of this.Cursors.keys()) {
            let cursor = this.Cursors.get(key);
            let data = this.CursorDatas.find((r) => r.Id == key);
            if (cursor.IsMouseDown && e.which == 1) {
                data = this.Owner.CoordinateSystem.GetPosition(key, offsetx, offsety);
            }
            if (data) {
                datas.push(data);
            }
        }
        this.CursorDatas = datas;
        this.UpdatePosition(this.CursorDatas);
    }
    KeyLeftPress() {
        if (!this.IsEnabled) return;
        if (this.CursorDatas.length > 0) {
            let datas = [];
            this.CheckForCusor();
            for (let key of this.Cursors.keys()) {
                let data = this.CursorDatas.find((r) => r.Id == key);
                let cursor = this.Cursors.get(key);
                if (data == null) continue;
                if (cursor.IsSelected()) {
                    let idxs = [
                        {
                            Index: data.Index > 0 ? data.Index - 1 : data.Index,
                            Id: data.Id,
                        },
                    ];
                    let temp = this.Owner.CoordinateSystem.GetPositionByIndex(idxs);
                    datas.push(...temp);
                } else {
                    datas.push(data);
                }
            }
            this.CursorDatas = datas;
            this.UpdatePosition(this.CursorDatas);
        }
    }
    KeyRightPress() {
        if (!this.IsEnabled) return;
        if (this.CursorDatas.length > 0) {
            let datas = [];
            this.CheckForCusor();
            for (let key of this.Cursors.keys()) {
                let cursor = this.Cursors.get(key);
                let data = this.CursorDatas.find((r) => r.Id == key);
                if (data == null) continue;
                if (cursor.IsSelected()) {
                    let idxs = [
                        {
                            Index: data.Index + 1,
                            Id: data.Id,
                        },
                    ];
                    let temp = this.Owner.CoordinateSystem.GetPositionByIndex(idxs);
                    datas.push(...temp);
                } else {
                    datas.push(data);
                }
            }
            this.CursorDatas = datas;
            this.UpdatePosition(this.CursorDatas);
        }
    }
    CheckForCusor() {
        let isSelected = false;
        for (let cursor of this.Cursors.values()) {
            if (cursor.IsSelected()) {
                isSelected = true;
            }
        }
        if (!isSelected) {
            this.Cursors.getValues()[0].Select();
        }
    }
    getMousePostion(e) {
        const rect = this.Owner.Information.ShapeContainerRect;
        const c_x = e.offsetX;
        const c_y = e.offsetY;
        let offsetx = c_x - rect.Left;
        let offsety = c_y - rect.Top;
        if (offsetx > rect.Width) {
            offsetx = rect.Width;
        }
        if (offsety > rect.Height) {
            offsety = rect.Height;
        }
        if (offsetx < 0) {
            offsetx = 0;
        }
        if (offsety < 0) {
            offsety = 0;
        }
        return {
            offsetx,
            offsety,
        };
    }
    UpdatePosition(data) {
        const rect = this.Owner.Information.ShapeContainerRect;
        let coordSystem = this.Owner.CoordinateSystem;
        const container = this.LegendWnd.find(".cursor-container");
        container.html("");
        if (data && data.length > 0) {
            for (let d of data) {
                container.append(
                    `<span class="cursor-row" style="color:${d.Color}">X:${numberRound(d.Point.X, coordSystem.XDecimalPrecision)} Y:${numberRound(d.Point.Y, coordSystem.YDecimalPrecision)}</span>`
                );
                let cursor = this.Cursors.get(d.Id);
                if (cursor.DOM && !isNaN(d.X) && isFinite(d.X) && !isNaN(d.Y) && isFinite(d.Y)) {
                    if (d.X >= 0 && d.X <= rect.Width && d.Y >= 0 && d.Y <= rect.Height) {
                        cursor.DOM.removeClass("hide");
                        cursor.DOM.attr("transform", `translate(${d.X},${d.Y})`);
                    } else {
                        cursor.DOM.addClass("hide");
                    }
                }
            }
        } else {
            for (let cursor of this.Cursors.values()) {
                if (cursor.DOM) cursor.DOM.addClass("hide");
            }
        }
    }
    Close() {
        super.Close();
        for (let cursor of this.Cursors.values()) {
            cursor.Remove();
        }
        if (this.LegendWnd != null) {
            this.LegendWnd.remove();
        }
        this.Cursors = null;
        this.CursorDatas = null;
    }
    ClearData() {
        this.CursorDatas = null;
    }
    GetCursorPosition() {
        return {};
    }
    GetZoomPosition() {
        if (this.CursorDatas?.length > 0) {
            return this.CursorDatas[0].Point.X;
        } else {
            let info = this.Owner.Information;
            return (info.CoordinateInfo.XMin + info.CoordinateInfo.XMax) / 2;
        }
    }
}

export class PolarSingleCursorPlugin extends TrailSingleCursorPlugin {
    constructor(mode) {
        super(mode);
    }
    createCursor(ids) {
        const rect = this.Owner.Information.ShapeContainerRect;
        for (let cursor of this.Cursors.values()) {
            cursor.Remove();
            this.Cursors.delete(cursor);
        }
        for (let id of ids) {
            this.Cursors.set(id, new CrossCurosrEmelemt(this, this.Owner.CoordinateSystem.AxisColor, { X: rect.PolarLeft, Y: rect.Top }));
        }
    }
    getMousePostion(e) {
        const rect = this.Owner.Information.ShapeContainerRect;
        const c_x = e.offsetX;
        const c_y = e.offsetY;
        let offsetx = c_x - rect.PolarLeft;
        let offsety = c_y - rect.Top;
        if (offsetx > rect.PolarWidth) {
            offsetx = rect.PolarWidth;
        }
        if (offsety > rect.Height) {
            offsety = rect.Height;
        }
        if (offsetx < 0) {
            offsetx = 0;
        }
        if (offsety < 0) {
            offsety = 0;
        }
        return {
            offsetx,
            offsety,
        };
    }
    UpdatePosition(data) {
        const rect = this.Owner.Information.ShapeContainerRect;
        let coordSystem = this.Owner.CoordinateSystem;
        const container = this.LegendWnd.find(".cursor-container");
        container.empty();
        if (data && data.length > 0) {
            for (let d of data) {
                container.append(`<span class="cursor-row" style="color:${d.Color}">${i18nGlobal('core.amplitude')}:
                    ${numberRound(d.Point.Amplitude, coordSystem.XDecimalPrecision)}  ${i18nGlobal('core.phase')}:
                    ${numberRound(d.Point.Phase, coordSystem.YDecimalPrecision)}  ${i18nGlobal('core.rotateSpeed')}:
                    ${numberRound(d.Point.Speed, coordSystem.YDecimalPrecision)}</span>`);
                let cursor = this.Cursors.get(d.Id);
                if (cursor.DOM && !isNaN(d.X) && isFinite(d.X) && !isNaN(d.Y) && isFinite(d.Y)) {
                    if (d.X >= 0 && d.X <= rect.PolarWidth && d.Y >= 0 && d.Y <= rect.Height) {
                        cursor.DOM.removeClass("hide");
                        cursor.DOM.attr("transform", `translate(${d.X},${d.Y})`);
                    } else {
                        cursor.DOM.addClass("hide");
                    }
                }
            }
        } else {
            for (let cursor of this.Cursors.values()) {
                if (cursor.DOM) cursor.DOM.addClass("hide");
            }
        }
    }
}
