// @generated by protobuf-ts 2.9.1
// @generated from protobuf file "protos/reply.proto" (package "storage", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Any } from "../google/protobuf/any";
/**
 * @generated from protobuf message storage.Reply
 */
export interface Reply {
    /**
     * @generated from protobuf field: bool IsSuccess = 1 [json_name = "IsSuccess"];
     */
    isSuccess: boolean;
    /**
     * @generated from protobuf field: string ErrorMessage = 2 [json_name = "ErrorMessage"];
     */
    errorMessage: string;
    /**
     * @generated from protobuf field: google.protobuf.Any Content = 3 [json_name = "Content"];
     */
    content?: Any;
}
// @generated message type with reflection information, may provide speed optimized methods
class Reply$Type extends MessageType<Reply> {
    constructor() {
        super("storage.Reply", [
            { no: 1, name: "IsSuccess", kind: "scalar", jsonName: "IsSuccess", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "ErrorMessage", kind: "scalar", jsonName: "ErrorMessage", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "Content", kind: "message", jsonName: "Content", T: () => Any }
        ]);
    }
    create(value?: PartialMessage<Reply>): Reply {
        const message = { isSuccess: false, errorMessage: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Reply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Reply): Reply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool IsSuccess = 1 [json_name = "IsSuccess"];*/ 1:
                    message.isSuccess = reader.bool();
                    break;
                case /* string ErrorMessage = 2 [json_name = "ErrorMessage"];*/ 2:
                    message.errorMessage = reader.string();
                    break;
                case /* google.protobuf.Any Content = 3 [json_name = "Content"];*/ 3:
                    message.content = Any.internalBinaryRead(reader, reader.uint32(), options, message.content);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Reply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool IsSuccess = 1 [json_name = "IsSuccess"]; */
        if (message.isSuccess !== false)
            writer.tag(1, WireType.Varint).bool(message.isSuccess);
        /* string ErrorMessage = 2 [json_name = "ErrorMessage"]; */
        if (message.errorMessage !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.errorMessage);
        /* google.protobuf.Any Content = 3 [json_name = "Content"]; */
        if (message.content)
            Any.internalBinaryWrite(message.content, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message storage.Reply
 */
export const Reply = new Reply$Type();
