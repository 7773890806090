import { guid } from "@common/js/utils";
import { CustomCoordinateSystem } from "@core/graphs/coords/dh-coordsystem-time";
import { AnalysisChart } from "./Dh-analysischart-base";
import { EnumXDataType } from "@core/js/dataParserContext";
import { CustomShapeOperator } from "../shapes/Dh-shape-line";


export class CustomAnalysisChart extends AnalysisChart {
    constructor() {
        super();
    }
    GetCoordinateSystem() {
        return new CustomCoordinateSystem(guid());
    }
    SetData(datas) {
        let filters = this.ProcessDatas(datas);
        super.SetData(filters);
        filters = null;
    }
    ProcessDatas(datas) {
        let dataType = null;
        const filters = [];
        for (let data of datas) {
            if (dataType == null || data.XDataType == dataType) {
                dataType = data.XDataType;
                filters.push(data);
            }
        }
        if (dataType != null) {
            this.WebChart.CoordinateSystem.XDataType = dataType;
        }
        return filters;
    }
    GetShapeOperator() {
        return new CustomShapeOperator();
    }
    UpdateXCoordinate(start, end) {
        this.WebChart.CoordinateSystem.XDataType = EnumXDataType.Time;
        super.UpdateXCoordinate(start, end);
    }
}