<template>
	<el-dialog class="copyDialog" v-model="showDialog" :title="$t('copyRight.title')" :close-on-click-modal="false"
		width="600px" @close="closeDialog">
		<div class="versionContent" style="padding-bottom: 20px">
			<div class="name">PHM</div>
			<div class="title">Prognostics and Health Management</div>
			<div class="title versionItem">{{ $t('copyRight.edition') }}{{ state.version }}</div>

			<div class="title">{{ $t('copyRight.authorizedVersion') }}{{ state.info.LicenseVersionStr }}</div>

			<div class="title" v-if="state.info.TimeLimit">{{ $t('copyRight.authorizationTime') }}{{ state.info.Time }}{{
				$t('copyRight.day') }}</div>
			<div class="title" v-else>{{ $t('copyRight.authorizationTime') }}{{ $t('copyRight.unlimited') }}</div>

			<div class="title versionItem" v-if="state.info.TimeLimit">{{ $t('copyRight.availableDuration') }}{{
				state.info.remaiTime }}{{ $t('copyRight.day') }}</div>
			<div class="title versionItem" v-else>{{ $t('copyRight.availableDuration') }}{{ $t('copyRight.unlimited') }}
			</div>

			<div class="title">
				{{ $t('copyRight.timeRange') }} <span>{{ $t('copyRight.companyName') }}</span>
			</div>
			<div class="title versionItem">{{ $t('copyRight.allRightsReserved') }}</div>
			<div class="title">{{ $t('copyRight.address') }}</div>
			<div class="title">{{ $t('copyRight.tel') }}400-6565-228</div>
			<div class="title">{{ $t('copyRight.email') }}dhc@dhtest.com</div>
			<div class="title versionItem">{{ $t('copyRight.website') }}Http://www.dhtest.com</div>
			<div class="sencondTitle">{{ $t('copyRight.waringmsg1') }}</div>
			<div class="sencondTitle">{{ $t('copyRight.waringmsg2') }}</div>
		</div>
	</el-dialog>
</template>

<script setup>
import { watch, ref, reactive, onBeforeUnmount } from "vue";
import axios from "@common/js/axios";

const emit = defineEmits(["cancel"]);
const props = defineProps({
	dialog: {
		default: false,
		type: Boolean,
	},
	copyright: {
		default: "",
		type: String,
	},
	version: {
		default: "",
		type: String,
	},
});
const showDialog = ref(false);
const state = reactive({
	info: {
		OnlinePointNum: 0,
		PatrolPointNum: 0,
		TimeLimit: false,
		OnlinePointNumLimit: false,
		PatrolPointNumLimit: false,
		LicenseVersionStr: "",
		Time: 0,
		Status: 1,
		remaiTime: 0,
	},
	version: "",
});

const closeDialog = () => {
	emit("cancel");
};

const getLicenseInfo = () => {
	// no permisson control	
	axios({
		method: "get",
		url: `License/GetLicenseModel`,
	}).then((res) => {
		if (res.IsSuccess) {
			if (res.Result) {
				loadPermission(res.Result);
			}
		}
	});
};

const getVersion = () => {
	// no permisson control	
	axios({
		method: "get",
		url: "Product/ProductInfo",
	}).then((res) => {
		if (res.IsSuccess) {
			state.version = res.Result.Version;
		}
	});
};

const loadPermission = (data) => {
	for (const key in state.info) {
		state.info[key] = data[key];
	}
	state.info.remaiTime = Math.floor((+new Date(data.DeadlineTime) - +new Date(data.LastCheckTime)) / (1000 * 60 * 60 * 24));
};

const dialogWatch = watch(
	() => props.dialog,
	(val) => {
		showDialog.value = val;
		if (val) {
			getVersion();
			getLicenseInfo();
		}
	},
	{
		immediate: true,
	}
);

onBeforeUnmount(() => {
	dialogWatch();
});
</script>

<style scoped lang="less">
.el-dialog {
	background-color: #fff;
}
</style>
