<template>
    <el-dialog v-model="state.dialog" top="6vh" width="800px" :title="$t('browse.synchronous')" append-to-body>
        <div class="main" v-if="state.showTree">
            <el-tree-v2 ref="treeRef" :props="defaultProps" node-key="Sid" :default-checked-keys="state.checkedKey"
                :default-expanded-keys="state.expandKey" show-checkbox :empty-text="$t('common.noContent')"
                :data="state.tree" :height="600">
                <template #default="{ node, data }">
                    <span class="custom-tree-label">{{ node.label }}</span>
                </template>
            </el-tree-v2>
        </div>
        <template #footer>
            <span class="dialog-footer">
                <el-button type="primary" @click="submitForm">{{ $t('common.ok') }}</el-button>
                <el-button @click="state.dialog = false">{{ $t('common.cancel') }}</el-button>
            </span>
        </template>
    </el-dialog>
</template>

<script setup>
import { reactive, ref } from "vue";
import axios from "@common/js/axios";
import { alertSuccessMsg, fullScreenLoading, i18nGlobal } from "@common/js/utils";
import _ from 'lodash';

const emit = defineEmits(["submit"]);
const treeRef = ref(null);
const defaultProps = {
    children: "Children",
    label: "DeptName",
    value: 'Sid',
};


const state = reactive({
    checkedKey: [],
    expandKey: [],
    tree: [],
    postData: [],
    showTree: false,
    dialog: false
});

const syncSuccess = () => {
    alertSuccessMsg(i18nGlobal('mainLayout.mainContent.syncSuccess'))
    state.dialog = false;
}

const getPostData = (data) => {
    const parent = treeRef.value.getNode(data.ParentId)?.data;
    if(parent) {
        const hasData = state.postData.find(t => t.Sid === parent.Sid);
        if(parent.IsExsit && !hasData) {
            parent.Children = [];
            parent.IsTop = true;
            state.postData.push(parent);
        } else if(!parent.IsExsit && !hasData) {
            parent.Children = [];
            state.postData.push(parent);
            getPostData(parent);
        }
    } else {
        data.IsTop = true;
    }
}

const submitForm = () => {
    state.postData = [];
    const checkedItem = treeRef.value.getCheckedNodes().filter(item => !item.IsExsit);
    checkedItem.forEach(item => {
        state.postData.push(item)
        getPostData(item);
    })
    const orderData = state.postData.filter(item => item.IsTop);
    let ParentId = _.uniq(orderData.map(item => item.Sid))

    let data = [];
    ParentId.forEach(item => {
        let arr = [state.postData.find(t => t.Sid === item)];
        arr[0].Children = setPostDepartment(state.postData, item)
        data = data.concat(arr)
    })
    if (data.length === 0) {
        return syncSuccess()
    }
    const loading = fullScreenLoading(i18nGlobal('platformSetting.settingCenter.alarm.requesting'));
    axios({
        method: "post",
        url: `JuhuaTree/organization`,
        data: data,
    }).then((res) => {
        if (res.IsSuccess) {
            syncSuccess()
        }
    }).finally(() => {
        loading.close();
    })
};

const setPostDepartment = (data, Id) => {
    let tree = []
    let temp
    for (let i = 0; i < data.length; i++) {
        if (data[i].ParentId === Id && !data[i].IsExsit) {
            let obj = data[i]
            temp = setPostDepartment(data, data[i].Sid)
            if (temp.length > 0) {
                obj.Children = temp
            }
            tree.push(obj)
        }
    }
    tree = tree.sort((a, b) => a.Index - b.Index);
    return tree;
}

const getCheckedKey = (data, level) => {
    for (let i = 0; i < data.length; i++) {
        data[i].Level = level;
        data[i].Index = i;
        if (data[i].IsExsit && data[i].Children.length === 0) {
            state.checkedKey.push(data[i].Sid);
        }
        if (data[i].IsExsit) {
            state.expandKey.push(data[i].Sid);
        }
        if (data[i].Children && data[i].Children.length > 0) {
            getCheckedKey(data[i].Children, (level + 1));
        }
    }
}


const getTree = () => {
    state.showTree = false;
    axios({
        method: "get",
        url: `/JuhuaTree/organization/${window.Config.project.props.appId}`,
    }).then((res) => {
        if (res.IsSuccess) {
            state.expandKey = [];
            state.checkedKey = [];
            getCheckedKey(res.Result, 0);
            state.showTree = true;
            state.tree = res.Result;
        }
    });
};

const showDialog = () => {
    state.dialog = true;
    getTree();
}

defineExpose({
    showDialog
})
</script>

<style scoped lang="less">
.custom-tree-node {
    display: flex;
    align-items: center;

    .custom-tree-label {
        margin-left: 0px;
    }
}
</style>
