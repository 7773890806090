<template>
	<div class="dh-calendar" @click="setFocus" ref="calendarRef">
		<div class="time-bar">
			<el-slider v-model="state.time" :format-tooltip="formatTimeBar" @click="setCurrentTime"
				:disabled="!state.enableCalendar" size="small"></el-slider>
			<div class="text">
				<span class="start">00:00:00</span>
				<div class="center">
					<el-time-picker v-if="state.showTimePicker" @visible-change="setCenterText" v-model="state.currentTime"
						placeholder="Arbitrary time" ref="timeRef" size="small" :clearable="false" :editable="false" />
					<span class="time" @dblclick="setTimePicker">{{ selectedTime }}</span>
				</div>

				<span class="end">23:59:59</span>
			</div>
		</div>
		<slot name="header">
			<div class="calendar-header">
				<el-icon :size="20" @click="handlePre">
					<arrow-left />
				</el-icon>
				<div class="header-title" @click="changeSet" @dblclick="getOpenInfo">
					<div class="day" v-if="state.showDays">
						{{ state.selectedYear }}-{{ state.selectedMonth < 9 ? "0" + (state.selectedMonth + 1) :
							state.selectedMonth + 1 }}-{{ state.selectedDay < 10 ? "0" + state.selectedDay :
		state.selectedDay }}</div>
							<div class="month" v-if="state.showMonth">{{ state.selectedYear }}</div>
							<div class="year" v-if="state.showYear">{{ state.calendarYearData[0] }} - {{
								state.calendarYearData[state.calendarYearData.length - 1] }}</div>
					</div>
					<el-icon :size="20" @click="handleNext">
						<arrow-right />
					</el-icon>
				</div>
		</slot>

		<div class="calendar-main">
			<div class="calendar-week" v-for="(item, index) in state.calendarHeader" v-if="state.showDays" :key="index">
				{{ item }}
			</div>
			<div class="calendar-main-wrapper" v-if="state.showDays">
				<div v-for="(item, index) in state.calendarDayData[state.selectedMonth]" @contextmenu="setOpen(item)"
					@dblclick="getDetailAlarm(item)"
					:class="`calendar-main-item main-days ${item.type === 'pre' || item.type === 'next' || !state.enableCalendar ? 'main-item-not' : ''} ${item.content === state.selectedDay && item.type !== 'pre' && item.type !== 'next' ? 'active' : ''}`"
					:style="{ background: item.isOpen ? '' : store.state.alarmInfo.find(t => t.Level === item.alarmLevel)?.Color, color: (item.alarmLevel === NormalLevel && !item.isOpen) ? '#fff' : '' }"
					:key="item.type + item.content + `${index}`" @click="setDay(item)">
					{{ item.content }}
				</div>
			</div>
			<div class="calendar-main-wrapper" v-if="state.showMonth">
				<div v-for="(item, index) in state.calendarMonthData"
					:class="`calendar-main-item main-month ${state.selectedMonth === index ? 'active' : ''}`" :key="item"
					@click="setMonth(index)">
					{{ item }}
				</div>
			</div>
			<div class="calendar-main-wrapper" v-if="state.showYear">
				<div v-for="(item, index) in state.calendarYearData"
					:class="`calendar-main-item main-years ${state.selectedYear === item ? 'active' : ''}`" :key="item"
					@click="setYear(item)">
					{{ item }}
				</div>
			</div>
		</div>
	</div>

	<el-dialog v-model="state.calendarDialog" width="1200px" top="4vh" :title="$t('calendar.openTitle')" append-to-body>
		<div class="calendarWrapper">
			<el-config-provider :locale="isCN ? CN : English">
				<el-calendar ref="calendar" v-model="state.dialog.date">
					<template #dateCell="{ data }">
						<div class="alarmDay" :class="{ no_alarm: state.alarmDateMap[Number(data.day.split('-')[2])] === undefined}"
							:style="data.type === 'current-month' ? state.alarmDateMap[Number(data.day.split('-')[2])] : {}">
							<span class="date">{{ Number(data.day.split("-")[2]) }}</span>
							<el-icon :color="store.state.primaryColor" @click="setInnerOpen(data, true)"
								v-if="data.type === 'current-month' && state.dialog.openDay.includes(Number(data.day.split('-')[2]))">
								<Check />
							</el-icon>
							<el-icon :color="store.state.customColor.TextColor" @click="setInnerOpen(data, false)"
								v-if="data.type === 'current-month' && state.dialog.closeDay.includes(Number(data.day.split('-')[2]))">
								<Close />
							</el-icon>
						</div>
					</template>
					<template #header="{ date }">
						<div class="calendarHead">
							<el-icon :size="20" @click="selectDate('prev-month')">
								<arrow-left />
							</el-icon>
							<span>{{ date }}</span>
							<el-icon :size="20" @click="selectDate('next-month')">
								<arrow-right />
							</el-icon>
						</div>
					</template>
				</el-calendar>
			</el-config-provider>
		</div>
		<template #footer>
			<span class="dialog-footer">
				<el-button @click="state.calendarDialog = false">{{ $t('common.cancel') }}</el-button>
			</span>
		</template>
	</el-dialog>

	<component-property-dialog :title="$t('calendar.title')" width="1200px" customClass="body-margin"
		:showProperty="state.showProperty" @close="state.showProperty = false" height="auto" top="10vh"
		@sure="state.showProperty = false">
		<el-table :data="state.tableData" ref="pointTabelRef" max-height="600">
			<el-table-column prop="Path" :label="$t('calendar.path')" width="280" show-overflow-tooltip />
			<el-table-column prop="AlarmLevelStr" :label="$t('calendar.alarmLevleStr')" width="120" />
			<el-table-column prop="AlarmTypeStr" :label="$t('calendar.alarmTypeStr')" width="120" />
			<el-table-column prop="AlarmModelStr" :label="$t('calendar.alarmModelStr')" width="120" />
			<el-table-column prop="AlarmStartTime" :label="$t('common.startTime')" width="200" />
			<el-table-column prop="AlarmEndTime" :label="$t('common.endTime')" width="200" />
			<el-table-column prop="ConfirmDegreeStr" :label="$t('calendar.confirmDegreeStr')" width="120" />
		</el-table>
		<paginationVue :pageInfo="state.pageInfo" @changePage="changePage"></paginationVue>
	</component-property-dialog>
</template>

<script>
export default { name: "DhCalendar" };
</script>
<script setup>
import { computed, reactive, ref, nextTick, onUnmounted, onMounted, watch } from "vue";
import { ArrowLeft, ArrowRight, Check, Close } from "@element-plus/icons-vue";
import { useStore } from "vuex";
import { KeyListenManager } from "@core/graphs/plugins/DH-plugin-base";
import { ClickOutside } from "element-plus";
import componentPropertyDialog from "@components/properties/common/componentPropertyDialog.vue";
import axios from "@common/js/axios";
import { ParentGuid, NormalLevel } from "@common/js/enum";
import _ from "lodash";
import paginationVue from "@common/components/pagination.vue";
import dayjs from "dayjs";
import AlarmCalendarComponent from "@components/template/browse/alarmCalendarComponent.vue";
import { getHexOpacityColor, i18nGlobal, translateAlarmStr, concatAlarmPath } from "@common/js/utils";
import { CN, English } from "@common/js/en.js";

const vClickOutside = ClickOutside;
const store = useStore();
const alarmInfo = computed(() => store.state.alarmInfo);
const isCN = computed(() => {
	return store.state.lang === 'CN'
})
const today = dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss")
// const primaryColor = computed(() => store.state.primaryColor);
const emit = defineEmits(["change"]);
const props = defineProps({
	defaultDate: {
		type: String,
		default: function () {
			const date = new Date(+new Date());
			const Y = date.getFullYear();
			const M = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
			const D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
			return `${Y}-${M}-${D}`;
		},
	},
});
const timeRef = ref(null);
const calendarRef = ref(null);
const calendar = ref(null);
const state = reactive({
	showProperty: false,
	tableData: [],
	selectedYear: 1949,
	selectedMonth: 10,
	selectedDay: 10,
	time: 0,
	originTime: 0,
	currentTime: "",
	enableCalendar: false,
	delayTimer: null,
	timer: null,
	currentAlarm: {},
	watchTime: null,
	showDays: true,
	showMonth: false,
	showYear: false,
	showTimePicker: false,
	isCurrentChange: false,
	calendarDialog: false,
	calendarDayData: [],
	calendarHeader: [
		i18nGlobal('calendar.Sun'),
		i18nGlobal('calendar.Mon'),
		i18nGlobal('calendar.Tue'),
		i18nGlobal('calendar.Wed'),
		i18nGlobal('calendar.Tur'),
		i18nGlobal('calendar.Fri'),
		i18nGlobal('calendar.Sat')
	],
	calendarMonthData: [
		i18nGlobal('calendar.JAN'),
		i18nGlobal('calendar.FEB'),
		i18nGlobal('calendar.MAR'),
		i18nGlobal('calendar.APR'),
		i18nGlobal('calendar.MAY'),
		i18nGlobal('calendar.JUN'),
		i18nGlobal('calendar.JUL'),
		i18nGlobal('calendar.AUG'),
		i18nGlobal('calendar.SEP'),
		i18nGlobal('calendar.OCT'),
		i18nGlobal('calendar.NOV'),
		i18nGlobal('calendar.DEC'),
	],
	calendarYearData: [],
	pageInfo: {
		total: 0,
		PageIndex: 1,
		PageSize: 10,
	},
	dialog: {
		errday: [],
		date: "",
		highAlarmDay: [],
		highestAlarmDay: [],
		openDay: [],
		closeDay: []
	},
	colorMap: {},
	alarmDateMap: {}
});

const getWarningDate = (isFirst) => {
	const month = state.selectedMonth < 9 ? "0" + (state.selectedMonth + 1) : state.selectedMonth + 1;
	const dayArr = state.calendarDayData[state.selectedMonth];
	const endIndex = _.findLastIndex(dayArr, (e) => {
		return e.type === "normal";
	});
	return axios({
		method: "post",
		url: "AlarmStatistic/GetAlarmCalander",
		data: {
			NodeId: ParentGuid,
			startTime: isFirst ? state.selectedYear + "-" + month + "-01" + " 00:00:00" : dayjs(new Date()).format("YYYY-MM-DD") + " 00:00:00",
			endTime: isFirst ? state.selectedYear + "-" + month + "-" + dayArr[endIndex].content + " 23:59:59" : dayjs(new Date()).format("YYYY-MM-DD") + " 23:59:59",
		},
	}).then((result) => {
		if (result.IsSuccess) {
			result.Result.forEach((item) => {
				const data = dayArr.find((child) => child.content === item.Day && child.type === "normal");
				if (data) {
					data.alarmLevel = item.AlarmLevel;
					data.isOpen = item.IsOpen;
				}
			});
			setAlarmDateMap(result.Result)
		}
		return result.Result;
	});
};

const selectDate = (val) => {
	calendar.value?.selectDate(val);
}

const getCalendarIsOpen = () => {
	const date = dayjs(state.dialog.date).format("YYYY-MM-DD")
	let lastDay = new Date(date.split("-")[0], date.split("-")[1], 0).getDate();
	lastDay = lastDay < 9 ? "0" + lastDay : lastDay;
	state.dialog.openDay = [];
	state.dialog.closeDay = [];
	axios({
		method: "post",
		url: "AlarmStatistic/GetAlarmCalander",
		data: {
			NodeId: ParentGuid,
			startTime: dayjs(state.dialog.date).format("YYYY-MM") + "-01" + " 00:00:00",
			endTime: dayjs(state.dialog.date).format("YYYY-MM") + '-' + lastDay + " 23:59:59",
		},
	}).then((result) => {
		if (result.IsSuccess) {
			// const errday = result.Result.filter(t => t.AlarmLevel === 16 || t.AlarmLevel === 8);
			// state.dialog.highAlarmDay = result.Result.filter(t => t.AlarmLevel === 8).map(t => t.Day);
			// state.dialog.highestAlarmDay = result.Result.filter(t => t.AlarmLevel === 16).map(t => t.Day);
			// state.dialog.openDay = errday.filter(t => !t.IsOpen).map(t => t.Day);
			// state.dialog.closeDay = errday.filter(t => t.IsOpen).map(t => t.Day);
			const errday = result.Result.filter(t => t.AlarmLevel !== NormalLevel);
			state.dialog.errday = errday;
			// state.dialog.highAlarmDay = result.Result.filter(t => t.AlarmLevel === 8).map(t => t.Day);
			// state.dialog.highestAlarmDay = result.Result.filter(t => t.AlarmLevel === 16).map(t => t.Day);
			state.dialog.openDay = errday.filter(t => !t.isOpen).map(t => t.Day);
			state.dialog.closeDay = errday.filter(t => t.isOpen).map(t => t.Day);
			setAlarmDateMap(result.Result)
		}
	});
}

const getOpenInfo = () => {
	state.dialog.date = new Date(selectedDate.value);
	const data = state.calendarDayData[state.selectedMonth];
	const errday = data.filter(t => t.alarmLevel !== NormalLevel)
	state.dialog.openDay = errday.filter(t => t.isOpen === false).map(t => t.content);
	state.dialog.closeDay = errday.filter(t => t.isOpen).map(t => t.content);
	state.calendarDialog = true;
}

const getOpenDate = (type) => {
	let Y = state.selectedYear;
	let month = state.selectedMonth;
	if (type === 'pre') month = month - 1;
	if (type === 'next') {
		if (month === 12) {
			month = 1;
			Y = Y + 1;
		} else {
			month = month + 1;
		}
	}
	const M = month < 9 ? "0" + (month + 1) : month + 1;
	return `${Y}-${M}-`;
}

const setInnerOpen = async (data, isOpen) => {
	axios({
		method: "post",
		url: "/AlarmStatistic/AlarmFilterDate",
		data: {
			date: data.day,
			isOpen: isOpen
		},
	}).then(async (res) => {
		if (res.IsSuccess) {
			store.commit('setAlarmOpenStatus', { alarmOpenStatus: !store.state.alarmOpenStatus })
			const result = await getWarningDate(true);
			const errday = result.filter(t => t.AlarmLevel !== NormalLevel);
			state.dialog.errday = errday;
			state.dialog.openDay = errday.filter(t => !t.IsOpen).map(t => t.Day);
			state.dialog.closeDay = errday.filter(t => t.IsOpen).map(t => t.Day);
		}
	});
}

const setOpen = (data) => {
	let ym = getOpenDate(data.type);
	let day = data.content < 10 ? '0' + data.content : data.content;
	axios({
		method: "post",
		url: "/AlarmStatistic/AlarmFilterDate",
		data: {
			date: ym + day,
			isOpen: !data.isOpen
		},
	}).then((res) => {
		if (res.IsSuccess) {
			store.commit('setAlarmOpenStatus', { alarmOpenStatus: !store.state.alarmOpenStatus })
			getWarningDate(true);
		}
	});
}

const getDetailAlarm = (data) => {
	if (!data.alarmLevel || data.isOpen) return;
	state.showProperty = true;
	state.currentAlarm = data;
	getTableData(data, true);
};

const getTableData = (data, isFirst) => {
	const month = state.selectedMonth < 9 ? "0" + (state.selectedMonth + 1) : state.selectedMonth + 1;
	const day = data.content < 9 ? "0" + data.content : data.content;
	const endTime = dayjs(state.selectedYear + "-" + month + "-" + day + " 00:00:00")
		.add(1, "day")
		.format("YYYY-MM-DD HH:mm:ss");
	if (isFirst) {
		state.pageInfo.PageIndex = 1;
	}
	axios({
		method: "post",
		url: "Alarm/QueryAlarmInfo",
		data: {
			Path: "0001",
			startTime: state.selectedYear + "-" + month + "-" + day + " 00:00:00",
			endTime: endTime,
			AlarmLevel: 0,
			AlarmType: 0,
			ContainHistory: true,
			PageIndex: state.pageInfo.PageIndex,
			PageSize: state.pageInfo.PageSize,
		},
	}).then((res) => {
		if (res.IsSuccess) {
			res.Result.Data.forEach(item => {
				item.Path = concatAlarmPath(item, store.state.alarmConfig.DisplayContents, store.state.alarmConfig.DisplayDepartmentTypes);
			})
			state.tableData = res.Result.Data;
			state.pageInfo.total = res.Result.TotalRows;
		}
	});
};

const changePage = (pageInfo) => {
	state.pageInfo = pageInfo;
	getTableData(state.currentAlarm);
};

const getFormatDate = (timestamp) => {
	const date = new Date(timestamp);
	const Y = date.getFullYear();
	const M = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
	const D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
	return `${Y}-${M}-${D}`;
};

const getFormatTime = (time) => {
	const date = new Date(time);
	const H = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
	const M = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
	const S = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
	return `${H}:${M}:${S}`;
};

const getAllDaysForYear = (year) => {
	/**
	 * monthData 每月数据 用于最后输出
	 * daysInMonth 每个月的天数
	 * specialDaysInMonth 每个月第一天和最后一天的星期
	 */
	const daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

	// 对闰年二月天数特殊处理
	if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
		daysInMonth[1] = 29;
	}
	const monthData = new Array(12).fill(null);

	const specialDaysInMonth = monthData.slice(0).map((m, i) => {
		return [new Date(year, i, 1).getDay(), new Date(year, i, daysInMonth[i]).getDay()];
	});

	return monthData.map((m, i, data) => {
		const month = [];
		const pre = preDaysCreator(daysInMonth[i === 0 ? 11 : i - 1], specialDaysInMonth[i][0]);
		const normal = normalDaysCreator(daysInMonth[i]);
		const next = nextDaysCreator(specialDaysInMonth[i][1], pre.length + normal.length);
		return month.concat(pre, normal, next);
	});
};

const preDaysCreator = (preLastDay, firstDay, monthData) => {
	const preDays = [];
	for (; firstDay > 0; firstDay--) {
		let obj = {
			content: preLastDay--,
			type: "pre",
		};

		preDays.splice(0, 0, obj);
	}
	return preDays;
};

const nextDaysCreator = (lastDay, long) => {
	const nextDays = [];
	const start = long > 35 ? 6 : 13;
	// if(normal > )
	const count = start - lastDay;
	for (let i = 0; i < count; i++) {
		let obj = {
			content: i + 1,
			type: "next",
		};
		nextDays.push(obj);
	}
	return nextDays;
};

const normalDaysCreator = (days) => {
	const normalDays = [];
	for (let i = 0; i < days; i++) {
		let obj = {
			content: i + 1,
			type: "normal",
		};

		normalDays.push(obj);
	}
	return normalDays;
};

const isFirstMonth = computed(() => {
	return state.selectedMonth === 0;
});

const isLastMonth = computed(() => {
	return state.selectedMonth === 11;
});

const selectedDate = computed(() => {
	const Y = state.selectedYear;
	const M = state.selectedMonth < 9 ? "0" + (state.selectedMonth + 1) : state.selectedMonth + 1;
	const D = state.selectedDay < 10 ? "0" + state.selectedDay : state.selectedDay;
	return `${Y}-${M}-${D}`;
});

const selectedTime = computed(() => {
	const start = +new Date(selectedDate.value + " 00:00:00");
	const end = +new Date(selectedDate.value + " 23:59:59");
	const timestamp = (end - start) / 100;
	const time = new Date(state.time * timestamp + start);
	// return  getFormatTime(time)
	return state.enableCalendar ? getFormatTime(time) : getFormatTime(+new Date());
});

const init = (d) => {
	let dateTime = d ? d : getFormatDate(+new Date()) + " " + getFormatTime(+new Date());
	// let dateTime = d ? d : '2022-08-31' + ' ' + '23:59:50';
	const arr = dateTime.split(" ");
	const date = arr[0].split("-");
	state.selectedYear = Number(date[0]);
	state.selectedMonth = Number(date[1]) - 1;
	state.selectedDay = Number(date[2]);
	state.calendarDayData = getAllDaysForYear(Number(date[0]));
	const start = +new Date(arr[0] + " 00:00:00");
	const end = +new Date(arr[0] + " 23:59:59");
	state.time = ((+new Date(dateTime) - start) / (end - start)) * 100;
	setTimer();
	getWarningDate(true);
};

const changeSet = () => {
	if (!state.enableCalendar) return;
	if (state.showDays) {
		state.showMonth = true;
		state.showDays = false;
	} else if (state.showMonth) {
		const arr = [];
		for (let index = 0; index < 12; index++) {
			arr.push(state.selectedYear + index);
		}
		state.calendarYearData = arr;
		state.showYear = true;
		state.showMonth = false;
	}
};

const handlePre = (date) => {
	if (!state.enableCalendar) return;
	if (state.showDays) {
		if (isFirstMonth.value) {
			state.selectedYear = state.selectedYear - 1;
			state.calendarDayData = getAllDaysForYear(Number(state.selectedYear));
		}
		state.selectedDay = isNaN(date) ? 1 : date;
		state.selectedMonth = isFirstMonth.value ? 11 : state.selectedMonth - 1;
	} else if (state.showMonth) {
		state.selectedYear = state.selectedYear - 1;
		state.selectedMonth = 0;
	} else {
		const first = state.calendarYearData[0];
		const arr = [];
		for (let index = 0; index < 12; index++) {
			arr.unshift(first - 1 - index);
		}
		state.calendarYearData = arr;
	}
};

const handleNext = (date) => {
	if (!state.enableCalendar) return;
	if (state.showDays) {
		if (isLastMonth.value) {
			state.selectedYear = state.selectedYear + 1;
			state.calendarDayData = getAllDaysForYear(Number(state.selectedYear));
		}
		state.selectedDay = isNaN(date) ? 1 : date;
		state.selectedMonth = isLastMonth.value ? 0 : state.selectedMonth + 1;
	} else if (state.showMonth) {
		state.selectedYear = state.selectedYear + 1;
		state.selectedMonth = 0;
	} else {
		const last = state.calendarYearData[state.calendarYearData.length - 1];
		const arr = [];
		for (let index = 0; index < 12; index++) {
			arr.push(last + 1 + index);
		}
		state.calendarYearData = arr;
	}
};

const setDay = (data) => {
	if (!state.enableCalendar) return;
	if (data.type === "pre") {
		handlePre(data.content);
	} else if (data.type === "next") {
		handleNext(data.content);
	} else {
		state.selectedDay = data.content;
	}
};

const setMonth = (month) => {
	state.showMonth = false;
	state.showDays = true;
	state.selectedMonth = month;
};

const setYear = (year) => {
	state.showYear = false;
	state.showDays = true;
	state.selectedYear = year;
};

const formatTimeBar = () => {
	return selectedTime.value;
};

const setBarEnable = (status) => {
	state.enableCalendar = status;
	setTimer();
};

const setTimer = () => {
	if (!state.enableCalendar && !state.timer) {
		const oneday = 24 * 60 * 60;
		state.timer = setInterval(() => {
			if (state.time < 100) {
				state.time += 100 / oneday;
			} else {
				goToNextDay();
			}
		}, 1000);
	} else {
		if (state.timer) {
			clearInterval(state.timer);
			state.timer = null;
		}
	}
};

const goToNextDay = () => {
	const days = state.calendarDayData[state.selectedMonth].filter((item) => item.type === "normal");
	if (state.selectedDay < days[days.length - 1].content) {
		state.selectedDay = state.selectedDay + 1;
	} else {
		if (isLastMonth.value) {
			state.selectedYear = state.selectedYear + 1;
			state.calendarDayData = getAllDaysForYear(Number(state.selectedYear));
		}
		state.selectedMonth = isLastMonth.value ? 0 : state.selectedMonth + 1;
		state.selectedDay = 1;
	}
	state.time = 0;
};

const keyupTime = (time) => {
	if (state.enableCalendar) {
		state.time += time;
		setCurrentTime();
	}
};

const setTimePicker = () => {
	if (state.enableCalendar) {
		const start = +new Date(selectedDate.value + " 00:00:00");
		const end = +new Date(selectedDate.value + " 23:59:59");
		const timestamp = (end - start) / 100;
		const time = new Date(state.time * timestamp + start);
		state.currentTime = time;
		state.showTimePicker = true;
		nextTick(() => {
			timeRef.value.focus();
		});
	}
};

const setCenterText = (val) => {
	if (!val) {
		const start = +new Date(selectedDate.value + " 00:00:00");
		const end = +new Date(selectedDate.value + " 23:59:59");
		state.time = ((+new Date(state.selectedDate + " " + state.currentTime) - start) / (end - start)) * 100;
		state.showTimePicker = false;
		setCurrentTime();
	}
};

const getTimeBarStatus = () => {
	return state.enableCalendar;
};

const getDateTime = () => {
	return selectedDate.value + " " + selectedTime.value;
};

const getTime = () => {
	return selectedTime.value;
};

const getDate = () => {
	return selectedDate.value;
};

const setDate = (date) => {
	init(date);
};

const setCurrentTime = (val) => {
	if (state.time === state.originTime) return;
	if (state.enableCalendar) {
		state.isCurrentChange = true;
		store.commit("setCurrentTime", { currentTime: getDateTime() });
		store.commit("setHistoryTimeChange", { historyTimeChange: !store.state.historyTimeChange });
		nextTick(() => {
			state.isCurrentChange = false;
			state.originTime = state.time;
		});
	}
};

watch(
	() => store.state.currentTime,
	(val) => {
		if (state.enableCalendar && !state.isCurrentChange) {
			setDate(val);
		}
	}
);

watch(
	() => store.state.enableCalendar,
	(val) => {
		state.enableCalendar = val;
		init();
		if (state.enableCalendar) {
			state.originTime = state.time;
		}
	},
	{
		immediate: true,
	}
);

const calendarWatch = watch(
	() => store.state.alarmOpenStatus,
	() => {
		nextTick(() => {
			getWarningDate(true);
		})
	}
);

const setFocus = () => {
	KeyListenManager.getListenManager();
	window.KeyManager.CurrentElement = calendarRef.value;
	calendarRef.value.KeyLeftPress = KeyLeftPress;
	calendarRef.value.KeyRightPress = KeyRightPress;
};

const KeyLeftPress = () => {
	keyupTime(-0.001);
};
const KeyRightPress = () => {
	keyupTime(0.001);
};

const setColorMap = () => {
	alarmInfo.value.forEach(item => {
		state.colorMap[item.Level] = {
			background: getHexOpacityColor(item.Color, 0.1),
			color: item.Color
		}
	})
}

const setAlarmDateMap = (data) => {
	state.alarmDateMap = {};
	data.forEach(item => {
		if (!item.IsOpen && state.colorMap[item.AlarmLevel]) {
			state.alarmDateMap[item.Day] = {
				IsOpen: item.IsOpen,
				background: state.colorMap[item.AlarmLevel].background,
				color: state.colorMap[item.AlarmLevel].color,
			}
		}

	})
}

const alarmWatch = watch(() => store.state.alarmInfo, () => {
	nextTick(() => {
		setColorMap();
	})
}, { deep: true })


watch(
	() => state.dialog.date,
	(newVal, oldVal) => {
		if (new Date(newVal).getMonth() !== new Date(oldVal).getMonth() && oldVal) {
			nextTick(() => {
				getCalendarIsOpen();
			})
		}
	}
);

watch(
	() => store.state.warningStatus,
	() => {
		const day = new Date().getDate();
		const dayArr = state.calendarDayData[state.selectedMonth];
		const current = dayArr.find((item) => item.type === "normal" && item.content === day);
		if (current.alarmLevel !== 16 && state.selectedMonth === new Date().getMonth()) {
			getWarningDate();
		}
	}
);

watch([() => state.selectedYear, () => state.selectedMonth], () => {
	getWarningDate(true);
});

onMounted(() => {
	setColorMap()
});

onUnmounted(() => {
	if (state.timer) {
		clearInterval(state.timer);
		state.timer = null;
	}
	alarmWatch()
	calendarWatch()
});

defineExpose({
	setBarEnable,
	getTimeBarStatus,
	getDate,
	getTime,
	getDateTime,
	getWarningDate,
	setDate,
});
</script>

<style scoped lang="less">
@import "@styles/variable.less";

.dh-calendar {
	width: 250px;
	box-shadow: 0 2px 0px 0 rgba(0, 0, 0, 0.1);
	padding: 12px;
	box-sizing: border-box;
	background-color: var(--phm-theme-card);

	.time-bar {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 10px;

		:deep(.el-slider) {
			width: 220px;

			.el-slider__button {
				width: 15px;
				height: 15px;
			}

			.el-slider__runway.is-disabled {
				.el-slider__bar {
					background-color: var(--el-color-primary);
				}
			}
		}

		.text {
			display: flex;
			justify-content: space-between;
			width: 230px;
			margin-top: -4px;

			span {
				color: var(--phm-theme-tip);
				font-size: 12px;
				user-select: none;
			}

			.center {
				width: 100px;
				position: relative;

				.time {
					font-size: 14px;
					color: var(--phm-primary-color);
					width: 100%;
					text-align: center;
					position: absolute;
					top: 0;
					left: 0;
					z-index: 1;
				}

				:deep(.el-input.el-date-editor) {
					position: absolute;
					width: 100px !important;
					opacity: 0;
					bottom: 6px;
					left: 0;
					z-index: 2;

					.el-input__prefix-inner {
						display: none;
					}
				}
			}
		}
	}

	.calendar-header {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.el-icon {
			cursor: pointer;
			width: 24px;
			height: 24px;
			line-height: 28px;

			&:hover {
				background-color: var(--phm-primary-light-color);

				path {
					fill: var(--phm-primary-color);
				}
			}
		}

		.header-title {
			line-height: 24px;
			padding: 4px;
			cursor: pointer;
			color: var(--phm-theme-mainText);
			user-select: none;

			&:hover {
				background-color: var(--phm-primary-light-color);
			}
		}

		:deep(.el-icon) {
			svg {
				path {
					fill: var(--phm-primary-color);
				}
			}
		}
	}

	.calendar-main {
		width: 100%;
		display: flex;
		justify-content: space-around;
		flex-wrap: wrap;
		margin-top: 10px;

		.calendar-main-wrapper {
			width: 100%;
			display: flex;
			justify-content: space-around;
			flex-wrap: wrap;
		}

		.calendar-week {
			width: calc(100% / 7);
			height: 40px;
			margin-bottom: 14px;
			border-radius: 2px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 12px;
			color: #7f8fa4;
			background-color: var(--phm-theme-card);
			flex-shrink: 0;
			user-select: none;
		}

		.calendar-main-item {
			box-sizing: border-box;
			border-radius: 2px;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #666666;
			background-color: var(--phm-theme-card);
			flex-shrink: 0;
			box-shadow: 0;
			position: relative;
			user-select: none;
			cursor: pointer;

			&:hover {
				background-color: var(--phm-primary-light-color);
			}

			&.main-item-not {
				// background-color: #edf2f5;
				color: var(--phm-theme-tip);
			}

			&.active {
				background-color: var(--phm-primary-color);
				color: var(--phm-theme-card);
			}
		}

		.main-days {
			width: calc(100% / 7);
			height: 30px;
			font-size: 12px;
			color: var(--phm-theme-mainText);
		}

		.main-month {
			width: calc(100% / 4);
			height: 66px;
			font-size: 14px;
			margin-bottom: 12px;
		}

		.main-years {
			width: calc(100% / 4);
			height: 66px;
			font-size: 14px;
			margin-bottom: 12px;
		}
	}
}

:deep(.el-calendar) {
	// --el-calendar-cell-width: 30px;
	margin: 20px 0;
	width: 100%;

	.el-calendar__header {
		display: flex;
		justify-content: center;
		padding: 4px;
		border: none;

		.calendarHead {
			display: flex;
			align-items: center;
			color: var(--phm-theme-title);

			.el-icon {
				cursor: pointer;

				&:hover {
					background-color: var(--phm-primary-light-color);

					path {
						fill: var(--phm-primary-color);
					}
				}
			}

			span {
				margin: 0 8px;
			}
		}
	}

	.el-calendar__body {
		padding: 0 20px;

		.el-calendar-day {
			padding: 0;

			&:hover {
				background-color: var(--phm-primary-light-color);
			}
		}

		.el-calendar-table td.is-selected {
			background-color: var(--phm-theme-card);
		}

		.el-calendar-table td.is-today {
			.alarmDay.no_alarm {
				background-color: var(--phm-primary-light-color);
			}

			// &.is-selected {
			//     background-color: var(--phm-light-3);
			// }

			.lunarText {
				color: var(--phm-theme-card) !important;
			}
		}

		td {
			border-color: var(--phm-theme-border);

			&.prev {
				color: var(--phm-theme-tip);

				.alarmDay {
					color: var(--phm-theme-tip);
				}

				.lunarText {
					color: var(--phm-theme-tip) !important;
				}
			}

			&.next {
				color: var(--phm-theme-tip);

				.alarmDay {
					color: var(--phm-theme-tip);
				}

				.lunarText {
					color: var(--phm-theme-tip) !important;
				}
			}

			.alarmDay {
				display: flex;
				width: 100%;
				height: 100%;
				padding: 8px;
				align-items: center;
				color: var(--phm-theme-mainText);

				&.highest {
					background-color: rgba(255, 0, 16, 0.1);
					color: #ff0010;
				}

				&.high {
					background-color: rgba(245, 148, 53, 0.1);
					color: #f59435;
				}

				.date {
					width: 20px;
				}

				.el-icon {
					margin-left: auto;
					margin-bottom: auto;
				}

				.lunarText {
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
					margin-left: 8px;
					font-size: 12px;
					color: #666;
					display: flex;
					height: 24px;
					align-items: center;
					min-width: 40px;
				}
			}
		}

		th {
			padding: 8px 0;
			color: var(--phm-theme-title);
		}
	}
}
</style>
