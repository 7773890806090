// @generated by protobuf-ts 2.9.1
// @generated from protobuf file "protos/fault.proto" (package "device", syntax proto3)
// tslint:disable
import { Reply } from "./reply";
import { GetTypicalDataRequest } from "./typicaldata";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
/**
 * @generated ServiceType for protobuf service device.GrpcFault
 */
export const GrpcFault = new ServiceType("device.GrpcFault", [
    { name: "GetTypicalData", options: {}, I: GetTypicalDataRequest, O: Reply }
]);
