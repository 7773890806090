import { createRouter, createWebHashHistory } from "vue-router";
import mainLayout from "@views/mainLayout/container.vue";
import store from "@common/js/store";
import axios from "@common/js/axios";
import { getGraphSetting, getTokens, getUserAuth, setTokens } from "@common/js/auth.js";
import { ElMessageBox } from 'element-plus';
import { elFullScreenLoading, i18nGlobal } from "@common/js/utils";
import { LoginSuccessEnum } from "@common/js/enum.js";

const routes = [
	{
		path: "/",
		name: "login",
		component: () => import("@apps/login/index.vue"),
	},
	{
		path: "/404",
		name: "notFound",
		component: () => import("@apps/errorPage/notFound.vue"),
	},
	{
		path: "/401",
		name: "noPermission",
		component: () => import("@apps/errorPage/noPermission.vue"),
	},
	{
		path: "/mainPage",
		name: "mainPage",
		component: () => import("@apps/login/mainPage.vue"),
		redirect: {
			name: "homeIndex",
		},
		children: [{
			path: "/app/tree",
			name: "appTree",
			component: () => import("@apps/common/appTree.vue"),
		},
		{
			path: "/home/index",
			name: "homeIndex",
			component: () => import("@apps/home/index.vue"),
		},
		{
			path: "/devices/index",
			name: "devicesIndex",
			component: () => import("@apps/devices/index.vue"),
		},
		{
			path: "/devices/workSetList",
			name: "workSetList",
			component: () => import("@apps/devices/workSetList.vue"),
		},
		{
			path: "/devices/workSetDetail",
			name: "workSetDetail",
			component: () => import("@apps/devices/workSetDetail.vue"),
		},
		{
			path: "/devices/graphSummary",
			name: "graphSummary",
			component: () => import("@apps/devices/graphSummary.vue"),
		},
		{
			path: "/devices/record",
			name: "record",
			component: () => import("@apps/devices/recordDetail.vue"),
		},
		{
			path: "/devices/3d",
			name: "graphSummary3d",
			component: () => import("@apps/devices/graphSummary3d.vue"),
		},
		{
			path: "/points/index",
			name: "pointsIndex",
			component: () => import("@apps/points/index.vue"),
		},
		{
			path: "/points/detail",
			name: "pointsDetail",
			component: () => import("@apps/points/pointDetail.vue"),
		},
		{
			path: "/message/index",
			name: "messageIndex",
			component: () => import("@apps/message/index.vue"),
		},
		{
			path: "/message/alarm",
			name: "messageAlarm",
			component: () => import("@apps/message/alarmDetail.vue"),
		},
		// {
		// 	path: "/devices/index",
		// 	name: "devicesIndex",
		// 	component: () => import("@apps/devices/index.vue"),
		// },
		// {
		// 	path: "/devices/index",
		// 	name: "devicesIndex",
		// 	component: () => import("@apps/devices/index.vue"),
		// },
		// {
		// 	path: "/devices/index",
		// 	name: "devicesIndex",
		// 	component: () => import("@apps/devices/index.vue"),
		// },
		{
			path: "/mine/index",
			name: "mineIndex",
			component: () => import("@apps/mine/index.vue"),
		},
		{
			path: "/mine/about",
			name: "mineAbout",
			component: () => import("@apps/mine/about.vue"),
		},
		{
			path: "/mine/instrument",
			name: "mineInstrument",
			component: () => import("@apps/mine/instrumentList.vue"),
		},
		{
			path: "/mine/captureCard",
			name: "mineCaptureCard",
			component: () => import("@apps/mine/captureCardList.vue"),
		},
		{
			path: "/mine/channel",
			name: "mineChannel",
			component: () => import("@apps/mine/channelList.vue"),
		},
		]
	},
	{
		path: "/:pathMatch(.*)*",
		redirect: "/404",
	},
];

const router = createRouter({
	history: createWebHashHistory(),
	routes,
});

const autoLogin = async (param) => {
	const loading = elFullScreenLoading('加载中')
	const res = await axios({
		method: 'post',
		url: 'Login/Login',
		data: param,
	});
	setTimeout(() => {
		loading.close()
	}, 500);
	const result = res.Result;
	if (result && result.LoginResult && result.LoginResult === LoginSuccessEnum.Success) {

		const auth = await getUserAuth(param.UserName);
		if (auth.ResourceAuth.length === 0 || Object.keys(auth.MachineTreeAuth).length === 0) {
			ElMessageBox.alert(i18nGlobal('login.unauthorizedTip'), i18nGlobal('common.tip'), {
				confirmButtonText: i18nGlobal('common.ok'),
				showClose: false,
				callback: () => {
					var userAgent = navigator.userAgent;
					if (userAgent.indexOf("Firefox") != -1 || userAgent.indexOf("Chrome") != -1) {
						window.location.href = "about:blank";
						window.close();
					} else {
						window.opener = null;
						window.open("", "_self");
						window.close();
					}
				},
			})
			return;
		}
		setTokens(param.UserName, result);
		store.commit('setUserInfo', { userInfo: result });
		await getGraphSetting();
	}
}

router.beforeEach(async (to, from, next) => {
	let UserName = localStorage.getItem('userName');
	if (to.name !== "login") {
		if (getTokens().token) {
			next();
		} else {
			next({ name: "login" });
		}
	} else if (to.name === 'login') {
		const token = getTokens().token;
		if (token && UserName) {
			await autoLogin({
				UserName: UserName,
				Token: token,
				UseToken: true
			})
			next({ name: 'mainPage' });
		} else {
			next();
		}
	} else {
		next();
	}
});

export default router
