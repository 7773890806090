import axios from "@common/js/axios";
import { SignalRInstance } from "@common/js/signalRClient";
import { ISignalRHandler } from "@common/js/signalRClient";
import { ContractTypes } from "@common/js/enum";
import { Debounce } from "@common/js/utils";

class LabelPointAlarmClinet {
    constructor() {
        this.handlerMap = new Map(); //标签回调方法
        this.registeredPointMap = new Map(); //标签上注册的测点
        this.pointAlarmLevelMap = new Map();
        this.signalrHandler = null;
    }

    Initial() {
        this.RegisterSignalR();
    }

    RegisterSignalR() {
        this.signalrHandler = new LabelAlarmSignalRHandler();
        this.signalrHandler.dataCallback = () => this.SyncAlarm();
        SignalRInstance.GetInstance()?.Add(this.signalrHandler);
    }

    SyncAlarm() {
        if (this.registeredPointMap.size === 0 || this.handlerMap.size === 0) return;
        const pointIds = this.GetRegisteredPoints();
        this.FetchAlarmLevel(pointIds);
    }

    GetAlarmLevel(pointId) {
        return this.pointAlarmLevelMap.get(pointId);
    }

    GetRegisteredPoints() {
        let points = [];
        this.registeredPointMap.forEach((value) => {
            points.push(...value);
        });
        points = Array.from(new Set(points)); //去重
        return points;
    }

    FetchAlarmLevel(ids = []) {
        if (ids?.length > 0) {
            axios({
                method: "post",
                url: "StatusStatistics/GetPointLabelAlarms",
                data: { ids: ids },
            }).then(res => {
                if (res.IsSuccess && res.Result.PointAlarmLevelDic) {
                    ids.forEach(pointId => {
                        this.pointAlarmLevelMap.set(Number(pointId), res.Result.PointAlarmLevelDic[pointId]);
                    });
                }
            }).finally(() => {
                this.InformAlarm();
            });
        }
    }

    InformAlarm() {
        this.handlerMap.forEach(value => value(this.pointAlarmLevelMap));
    }

    RegisterPoints(requestId, pointIdList = []) {
        const registeredPoints = this.registeredPointMap.get(requestId);
        const registeringPoints = pointIdList?.map(point => point.PointId);
        if (Array.isArray(registeredPoints) && Array.isArray(registeringPoints)) {
            this.registeredPointMap.set(requestId, registeringPoints);
            const newRegisterPoints = registeringPoints.filter(pointId => !registeredPoints.includes(pointId));
            newRegisterPoints?.length > 0 ? this.FetchAlarmLevel(newRegisterPoints) : this.InformAlarm();
        }
    }

    Register(requestId, handler) {
        this.handlerMap.set(requestId, handler);
        this.registeredPointMap.set(requestId, []);
    }

    UnRegister(requestId) {
        this.handlerMap.delete(requestId);
        this.registeredPointMap.delete(requestId);
    }

    Clear() {
        this.signalrHandler?.Dispose();
        this.handlerMap = null;
        this.pointAlarmLevelMap = null;
        this.registeredPointMap = null;
    }
}

export class LabelAlarmSignalRHandler extends ISignalRHandler {
    constructor() {
        super();
        this.SupportContentType = [ContractTypes.AlarmInfoUpdate];
        this.Debounce = new Debounce();
        this.dataCallback = null;
    }

    ReceiveData(data) {
        if (data.ContentType == ContractTypes.AlarmInfoUpdate) {
            if (data.Content != null) {
                this.Debounce.run(() => {
                    if (typeof this.dataCallback == 'function')
                        this.dataCallback(data);
                }, 300);
            }
        }
    }
}

export class LabelPointAlarmClinetInstance {
    static _instance = null;
    static GetInstance() {
        return LabelPointAlarmClinetInstance._instance;
    }
    static initial() {
        if (LabelPointAlarmClinetInstance._instance == null) {
            LabelPointAlarmClinetInstance._instance = new LabelPointAlarmClinet();
            LabelPointAlarmClinetInstance._instance.Initial();
        }
    }
    static Clear() {
        LabelPointAlarmClinetInstance._instance.Clear();
        LabelPointAlarmClinetInstance._instance = null;
    }
}