import $ from "jquery";
import { DoubleCursorPlugin } from "./Dh-plugin-double-cursor";
import { i18nGlobal } from "@common/js/utils";

export class RectCursorPlugin extends DoubleCursorPlugin {
    constructor(mode) {
        super(mode);
        this.Block = 0;
        this.StartIndex = null;
        this.EnIndex = null;
        this.Title = i18nGlobal('core.rectCursor');
        this.isRect = false;
    }
    OnRender() {
        let pos = new Array();
        if (this.CursorDatas.length > 0) {
            for (let i = 0; i < this.CursorDatas.length; i++) {
                if (i > 0) {
                    let last = this.CursorDatas[i - 1];
                    let now = this.CursorDatas[i];
                    if (last.XIndex - now.XIndex > this.MaxEndDiff) {
                        now.XIndex = last.XIndex + this.MaxEndDiff;
                    }
                }
                pos.push(this.CursorDatas[i].XIndex);
            }
        } else {
            const { startIndex, endIndex } = this.Owner.Option;
            if(startIndex !== null) {
                pos = [startIndex,endIndex]
            } else {
                for (let i = 0; i < this.CursorCount; i++) {
                    pos.push(0 + i * this.MaxEndDiff);
                }
            }
        }
        this.SetPosition(pos);
    }
    OnMouseMove(e) {
        let sender = this;
        if(sender.StartIndex === null) return;
        let rect = this.Owner.Information.ShapeContainerRect;
        this.IsNotify = false;
        if (this.IsMouseDown) {
            //区域拖动
            if (this.StartDatas.length == 0) return false;
            let delta = e.offsetX - rect.Left - this.StartPos;
            $.each(this.StartDatas, function (i, sd) {
                let offsetx = sd + delta;
                if (offsetx < 0) delta = -sd;
                if (offsetx > rect.Width) delta = rect.Width - sd;
            });

            $.each(this.StartDatas, function (i, sd, arr) {
                let offsetx = sd + delta;
                offsetx = offsetx < 0 ? 0 : offsetx;
                offsetx = offsetx > rect.Width ? rect.Width : offsetx;
                const CoordinateSystem = sender.Owner.CoordinateSystem;
                let data = CoordinateSystem.GetPositionData(offsetx);
                if (i === 0) {
                    const start = data.XIndex - sender.StartIndex;
                    const fnum = Math.abs(start) % sender.Block;
                    if (fnum !== 0) return;
                    if (fnum !== 0) {
                        let resNum = sender.Block - fnum;
                        if (sender.Block / resNum > 0.6) {
                            let x = start > 0 ? data.XIndex + resNum : data.XIndex - resNum;
                            data = CoordinateSystem.GetPositionByIndex(x);
                        } else {
                            return;
                        }
                    }
                }
                if (i === 1) {
                    const end = data.XIndex - sender.EnIndex;
                    const fnum = Math.abs(end) % sender.Block;
                    if (fnum !== 0) return;
                    if (fnum !== 0) {
                        let resNum = sender.Block - fnum;
                        if (sender.Block / resNum > 0.6) {
                            let x = end > 0 ? data.XIndex + resNum : data.XIndex - resNum;
                            data = CoordinateSystem.GetPositionByIndex(x);
                        } else {
                            return;
                        }
                    }
                }
                if (data) sender.CursorDatas[i] = data;
            });
            this.isRect = true;
            this.UpdatePosition(sender.CursorDatas, true);
        } else {
            $.each(this.Cursors, function (i, cursor) {
                if (cursor.IsMouseDown && sender.Block === 1) {
                    sender.ProcessCursorMove(e, 0, sender);
                    sender.ProcessCursorMove(e, 1, sender);
                }
            });
            if (sender.Block === 1 && this.Cursors.find((t) => t.IsMouseDown)) {
                this.isRect = true;
                this.UpdatePosition(sender.CursorDatas, true);
            }
        }
        return true;
    }
    OnTouchMove(e) {
        let sender = this;
        if(sender.StartIndex === null) return;
        const boundingClientRect = e.currentTarget.getBoundingClientRect();
        const offsetX = e.touches[0].pageX - boundingClientRect.left;
        const offsetY = e.touches[0].pageY - boundingClientRect.top;
        let rect = this.Owner.Information.ShapeContainerRect;
        this.IsNotify = false;
        if (this.IsMouseDown) {
            //区域拖动
            if (this.StartDatas.length == 0) return false;
            let delta = offsetX - rect.Left - this.StartPos;
            $.each(this.StartDatas, function (i, sd) {
                let offsetx = sd + delta;
                if (offsetx < 0) delta = -sd;
                if (offsetx > rect.Width) delta = rect.Width - sd;
            });

            $.each(this.StartDatas, function (i, sd, arr) {
                let offsetx = sd + delta;
                offsetx = offsetx < 0 ? 0 : offsetx;
                offsetx = offsetx > rect.Width ? rect.Width : offsetx;
                const CoordinateSystem = sender.Owner.CoordinateSystem;
                let data = CoordinateSystem.GetPositionData(offsetx);
                if (i === 0) {
                    const start = data.XIndex - sender.StartIndex;
                    const fnum = Math.abs(start) % sender.Block;
                    if (fnum !== 0) return;
                    if (fnum !== 0) {
                        let resNum = sender.Block - fnum;
                        if (sender.Block / resNum > 0.6) {
                            let x = start > 0 ? data.XIndex + resNum : data.XIndex - resNum;
                            data = CoordinateSystem.GetPositionByIndex(x);
                        } else {
                            return;
                        }
                    }
                }
                if (i === 1) {
                    const end = data.XIndex - sender.EnIndex;
                    const fnum = Math.abs(end) % sender.Block;
                    if (fnum !== 0) return;
                    if (fnum !== 0) {
                        let resNum = sender.Block - fnum;
                        if (sender.Block / resNum > 0.6) {
                            let x = end > 0 ? data.XIndex + resNum : data.XIndex - resNum;
                            data = CoordinateSystem.GetPositionByIndex(x);
                        } else {
                            return;
                        }
                    }
                }
                if (data) sender.CursorDatas[i] = data;
            });
            this.isRect = true;
            this.UpdatePosition(sender.CursorDatas, true);
        } else {
            $.each(this.Cursors, function (i, cursor) {
                if (cursor.IsMouseDown && sender.Block === 1) {
                    sender.ProcessCursorMove(e, 0, sender);
                    sender.ProcessCursorMove(e, 1, sender);
                }
            });
            if (sender.Block === 1 && this.Cursors.find((t) => t.IsMouseDown)) {
                this.isRect = true;
                this.UpdatePosition(sender.CursorDatas, true);
            }
        }
        return true;
    }
    OnTouchStart(e) {
        if (!this.IsEnabled) return false;
        const boundingClientRect = e.currentTarget.getBoundingClientRect();
        const offsetX = e.touches[0].pageX - boundingClientRect.left;
        const offsetY = e.touches[0].pageY - boundingClientRect.top;
        let rect = this.Owner.Information.ShapeContainerRect;
        if (offsetY < rect.Top || offsetY > rect.Top + rect.Height) return false;
        if (offsetX < rect.Left - 4 || offsetX > rect.Left + rect.Width + 4) return false;
        $('body').off('touchend', this.onMouseUpHandler);
        $('body').on('touchend', this.onMouseUpHandler);
        if (this.Cursors.length == 0) return false;
        if (!this.CursorDatas || this.CursorDatas.length == 0) return false;
        let start = this.CursorDatas[0].OffsetX;
        let end = this.CursorDatas[this.Cursors.length - 1].OffsetX;
        for (let i = 0; i < this.Cursors.length; i++) {
            if (start > this.CursorDatas[i].OffsetX) start = this.CursorDatas[i].OffsetX;
            if (end < this.CursorDatas[i].OffsetX) end = this.CursorDatas[i].OffsetX;
        }
        let xc = offsetX - rect.Left;
        if (xc >= start - 5 && xc <= end + 5) {
            this.IsMouseDown = true;
            this.StartPos = xc;
            this.StartDatas = new Array();
            for (let j = 0; j < this.CursorDatas.length; j++) {
                this.StartDatas.push(this.CursorDatas[j].OffsetX);
            }
        }
        return true;
    }
    RefreshRelatedData() {
        this.IsNotify = true;
        this.NotifyPositionChanged(this.isRect);
        this.isRect = false;
    }
    NotifyPositionChanged(isRect) {
        if(this.StartIndex === null) {
            const { startIndex, endIndex } = this.Owner.Option;
            if(startIndex !== null) {
                isRect = true;
                this.SetBlock(startIndex,endIndex);
            }
        }
        if (this.Entity.onPositionChanged && this.IsNotify == true && this.CursorDatas.length > 0 && this.StartIndex !== null) {
            let e = {
                Cursors: new Array(),
            };
            $.each(this.CursorDatas, function (i, cd) {
                e.Cursors.push(cd);
            });
            this.Entity.onPositionChanged(e, isRect, this);
        }
    }
    SetBlock(StartIndex, EnIndex) {
        this.StartIndex = StartIndex;
        this.EnIndex = EnIndex;
        this.Block = EnIndex - StartIndex + 1;
    }
    KeyLeftPress() { }
    KeyRightPress() { }
}
