<template>
	<div class="warning" id="showWarning" v-loading="state.loading">
		<div class="title" v-if="props.showTitle">{{ $t("headerSetting.warning.title") }}</div>
		<div class="content">
			<el-form ref="warnnigRef" class="flex platSearchForm" :inline="true">
				<el-form-item :label="$t('headerSetting.warning.path')">
					<el-tree-select v-model="state.searchForm.Path" :data="state.tree" @change="getTableData(true)"
						:teleported="false" default-expand-all :filter-node-method="filterNodeMethod" node-key="Id"
						check-strictly filterable clearable ref="treeRef" :props="{
		label: 'Name',
		children: 'children',
		value: 'Path',
		disabled: 'disabled',
	}">
					</el-tree-select>
				</el-form-item>
				<el-form-item :label="$t('headerSetting.warning.alarmType')">
					<el-select v-model="state.searchForm.AlarmType" :placeholder="$t('common.selectToolTip')"
						@change="getTableData(true)" :teleported="false">
						<el-option v-for="item in state.AlarmTypes" :key="item.Key" :label="item.Value"
							:value="item.Key">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item v-if="!isBatteryAlarm" :label="$t('headerSetting.warning.alarmClass')">
					<el-select v-model="state.searchForm.AlarmModel" :placeholder="$t('common.selectToolTip')"
						@change="getTableData(true)" :teleported="false">
						<el-option v-for="item in state.AlarmModels" :key="item.Key" :label="item.Value"
							:value="item.Key">
						</el-option>
					</el-select>
				</el-form-item>

				<el-form-item :label="$t('headerSetting.warning.alarmLevel')">
					<el-select v-model="state.searchForm.AlarmLevel" :placeholder="$t('common.selectToolTip')"
						@change="getTableData(true)" :teleported="false">
						<el-option :key="0" :label="$t('common.all')" :value="0"></el-option>
						<el-option v-for="item in alarmLeves" :key="item.Level" :label="item.Name" :value="item.Level">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item :label="$t('headerSetting.warning.severity')" v-show="state.searchForm.ContainHistory">
					<el-select v-model="state.searchForm.AlarmConfirmDegree" :placeholder="$t('common.selectToolTip')"
						@change="getTableData(true)" :teleported="false">
						<el-option v-for="item in state.ConfirmDegree" :key="item.Key" :label="item.Value"
							:value="item.Key"> </el-option>
					</el-select>
				</el-form-item>
				<el-form-item :label="$t('headerSetting.warning.alarmClassify')"
					v-show="state.searchForm.ContainHistory">
					<el-select v-model="state.searchForm.AlarmConfirmType" :placeholder="$t('common.selectToolTip')"
						@change="getTableData(true)" :teleported="false">
						<el-option v-for="item in state.ConfirmTypes" :key="item.Key" :label="item.Value"
							:value="item.Key">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item :label="$t('common.startTime')">
					<el-date-picker v-model="state.searchForm.StartTime" type="datetime" class="marRight10"
						:placeholder="$t('common.selectStartTimePlaceholder')" :teleported="false"
						format="YYYY-MM-DD HH:mm:ss" value-format="YYYY-MM-DD HH:mm:ss" :clearable="true" />
					<el-date-picker v-model="state.searchForm.EndTime" type="datetime" class="marRight10"
						:placeholder="$t('common.selectEndTimePlaceholder')" :teleported="false"
						format="YYYY-MM-DD HH:mm:ss" value-format="YYYY-MM-DD HH:mm:ss" :clearable="true" />
					<!-- <el-date-picker v-model="state.searchForm.StartTime" class="marRight10" type="datetime" :teleported="false"
                        :placeholder="$t('common.selectStartTimePlaceholder')" format="YYYY-MM-DD hh:mm:ss" value-format="YYYY-MM-DD hh:mm:ss" />
                    <el-date-picker v-model="state.searchForm.EndTime" class="marRight10" type="datetime" :teleported="false"
                        :placeholder="$t('common.selectEndTimePlaceholder')" format="YYYY-MM-DD hh:mm:ss" value-format="YYYY-MM-DD hh:mm:ss" /> -->
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getTableData(true)" v-auth="'AlarmInfo_Browse'">{{
		$t("common.query")
	}}</el-button>
					<el-button type="primary" @click="reset" v-auth="'AlarmInfo_Browse'">{{ $t("common.reset")
						}}</el-button>
				</el-form-item>
				<el-form-item>
					<el-checkbox v-model="state.searchForm.ContainHistory" :label="$t('headerSetting.warning.allAlarm')"
						@change="getTableData(true)" />
				</el-form-item>
				<el-form-item>
					<el-button type="primary" :disabled="state.selection.length === 0" @click="confirm(state.selection)"
						v-auth="'AlarmInfo_Edit'">{{ $t("common.confirm") }}</el-button>
					<!-- <el-button type="primary" @click="confirm([], 'all')" :disabled="state.tableData.length === 0">全部{{$t('common.confirm')}}</el-button> -->
					<el-button type="primary" :disabled="state.tableData.length === 0" @click="exportToExcel"
						v-auth="'AlarmInfo_Export'">{{ $t("common.export") }}</el-button>
					<el-button type="primary" v-if="!isBatteryAlarm" @click="setAllAlarmSilent"
						v-auth="'AlarmInfo_Edit'">
						{{ state.isAllAlarmSilent ? $t("headerSetting.warning.unmute") :
		$t("headerSetting.warning.muteAll")
						}}
					</el-button>
				</el-form-item>
				<!-- <div class="fullRow">
                    <el-form-item :label="$t('component.templateBrowse.severity')" v-show="state.searchForm.ContainHistory">
                        <el-select v-model="state.searchForm.AlarmConfirmDegree" :placeholder="$t('common.selectToolTip')"
                            @change="getTableData(true)" :teleported="false">
                            <el-option v-for="item in state.ConfirmDegree" :key="item.Key" :label="item.Value"
                                :value="item.Key">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="$t('component.templateBrowse.alarmClassification')" v-show="state.searchForm.ContainHistory">
                        <el-select v-model="state.searchForm.AlarmConfirmType" :placeholder="$t('common.selectToolTip')"
                            @change="getTableData(true)" :teleported="false">
                            <el-option v-for="item in state.ConfirmTypes" :key="item.Key" :label="item.Value"
                                :value="item.Key">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div> -->
			</el-form>

			<el-table :data="state.tableData" style="width: 100%" :empty-text="$t('common.noContent')"
				v-if="state.searchForm.ContainHistory" @selection-change="handleSelectionChange" max-height="600">
				<el-table-column type="selection" width="55" :selectable="selectable" />
				<el-table-column prop="Path" :label="$t('headerSetting.warning.devicePath')" width="440"
					show-overflow-tooltip>
					<template #default="scope">
						<span @click.stop="setAlarmNode(scope.row)" class="deviceText">{{ scope.row.Path }}</span>
					</template>
				</el-table-column>
				<el-table-column prop="AlarmTypeStr" :label="$t('headerSetting.warning.alarmType')" width="120"
					show-overflow-tooltip />
				<el-table-column prop="AlarmLevelStr" :label="$t('headerSetting.warning.alarmLevel')" width="120"
					show-overflow-tooltip />
				<el-table-column prop="AlarmStartTime" :label="$t('common.startTime')" width="220"
					show-overflow-tooltip />
				<el-table-column prop="AlarmEndTime" :label="$t('common.endTime')" width="220" />
				<el-table-column :label="$t('headerSetting.warning.confirmInfo')" width="300">
					<template #default="scope">
						<div class="confirmInfo" v-if="scope.row.IsConfirmed">
							<div class="text">{{ scope.row.ConfirmDegreeStr }}</div>
							<phmTag :type="scope.row.ConfirmType === 1 ? 'danger' : 'success'">{{
		scope.row.ConfirmType === 1 ? $t("headerSetting.warning.real") :
			$t("headerSetting.warning.empty")
	}}</phmTag>
							<el-button type="primary" link @click="getConfirmDetail(scope.row)">{{ $t("common.detail")
								}}</el-button>
						</div>
						<span v-else>-</span>
					</template>
				</el-table-column>
				<el-table-column :label="$t('common.operation')" min-width="220" fixed="right">
					<template #default="scope">
						<el-button type="primary" link @click.stop="getDetail(scope.row)">{{
		$t("headerSetting.warning.viewDetail") }}</el-button>
						<el-button type="primary" link @click.stop="confirm([scope.row])"
							v-if="!scope.row.IsConfirmed && scope.row.AlarmEndTime" v-auth="'AlarmInfo_Edit'">{{
		$t("common.confirm") }}
						</el-button>
						<el-button type="primary" link @click="saveCase(scope.row)"
							v-if="scope.row.IsConfirmed && !scope.row.IsAddedFaultCase" v-auth="'Fault_Case_Edit'">
							{{ $t("faultCaseLibrary.caseLibrary") }}
						</el-button>
						<svgIcon v-if="!scope.row.AlarmEndTime && scope.row.IsSilent" width="16px" height="16px"
							:color="store.state.primaryColor" class="silientIcon" name="sound_off"
							:title="$t('headerSetting.warning.changeUnmute')" @click="changeAlarmSilent(scope.row)"
							v-auth="'AlarmInfo_Edit'">
						</svgIcon>
						<svgIcon v-if="!scope.row.AlarmEndTime && !scope.row.IsSilent" width="16px" height="16px"
							:color="store.state.primaryColor" class="silientIcon" name="sound_on"
							:title="$t('headerSetting.warning.changeUnmute')" @click="changeAlarmSilent(scope.row)"
							v-auth="'AlarmInfo_Edit'">
						</svgIcon>
					</template>
				</el-table-column>
			</el-table>

			<el-table :data="state.tableData" style="width: 100%" :empty-text="$t('common.noContent')" v-else
				@selection-change="handleSelectionChange" max-height="600">
				<el-table-column type="selection" width="55" :selectable="selectable" />
				<el-table-column prop="Path" :label="$t('headerSetting.warning.devicePath')" width="440">
					<template #default="scope">
						<div @click.stop="setAlarmNode(scope.row)" class="deviceText" v-title="scope.row.Path">{{
		scope.row.Path }}</div>
					</template>
				</el-table-column>
				<el-table-column prop="AlarmTypeStr" :label="$t('headerSetting.warning.alarmType')" width="140"
					show-overflow-tooltip />
				<el-table-column prop="AlarmLevelStr" :label="$t('headerSetting.warning.alarmLevel')" width="140"
					show-overflow-tooltip />
				<el-table-column prop="AlarmStartTime" :label="$t('common.startTime')" width="220"
					show-overflow-tooltip />
				<!-- <el-table-column prop="AlarmStartTime" label="开始gfg" width="220" show-overflow-tooltip v-if="state.searchForm.ContainHistory"/> -->

				<el-table-column prop="AlarmEndTime" :label="$t('common.endTime')" width="220" />
				<el-table-column :label="$t('common.operation')" min-width="180" fixed="right">
					<template #default="scope">
						<el-button type="primary" link @click="getDetail(scope.row)">{{
		$t("headerSetting.warning.viewDetail") }}</el-button>
						<el-button type="primary" link @click="confirm([scope.row])"
							v-if="!scope.row.IsConfirmed && scope.row.AlarmEndTime" v-auth="'AlarmInfo_Edit'">{{
		$t("common.confirm") }}
						</el-button>
						<el-button type="primary" link @click="saveCase(scope.row)"
							v-if="scope.row.IsConfirmed && !scope.row.IsAddedFaultCase" v-auth="'Fault_Case_Edit'">
							{{ $t("faultCaseLibrary.caseLibrary") }}
						</el-button>
						<svgIcon v-if="!scope.row.AlarmEndTime && scope.row.IsSilent" width="16px" height="16px"
							:color="store.state.primaryColor" class="silientIcon" name="sound_off"
							:title="$t('headerSetting.warning.changeUnmute')" @click="changeAlarmSilent(scope.row)"
							v-auth="'AlarmInfo_Edit'">
						</svgIcon>
						<svgIcon v-if="!scope.row.AlarmEndTime && !scope.row.IsSilent" width="16px" height="16px"
							:color="store.state.primaryColor" class="silientIcon" name="sound_on"
							:title="$t('headerSetting.warning.changeUnmute')" @click="changeAlarmSilent(scope.row)"
							v-auth="'AlarmInfo_Edit'">
						</svgIcon>
					</template>
				</el-table-column>
			</el-table>
			<paginationVue :pageInfo="state.pageInfo" @changePage="changePage"></paginationVue>

			<el-dialog v-model="state.innerDialog" width="800px" :title="$t('headerSetting.warning.confirmInfo')"
				@close="resetFields">
				<el-form label-width="120px" ref="innnerFormRef" class="platForm" :model="state.confirmForm"
					:rules="state.confirmRules">
					<el-form-item :label="$t('headerSetting.warning.alarmType')" prop="ConfirmType">
						<el-select v-model="state.confirmForm.ConfirmType" :placeholder="$t('common.selectToolTip')">
							<el-option v-for="item in state.ConfirmTypes.slice(1, 3)" :key="item.Key"
								:label="item.Value" :value="item.Key"> </el-option>
						</el-select>
					</el-form-item>
					<el-form-item :label="$t('headerSetting.warning.severity')" prop="ConfirmDegree">
						<el-select v-model="state.confirmForm.ConfirmDegree" :placeholder="$t('common.selectToolTip')">
							<el-option v-for="item in state.ConfirmDegree.slice(1, 3)" :key="item.Key"
								:label="item.Value" :value="item.Key"> </el-option>
						</el-select>
					</el-form-item>
					<el-form-item :label="$t('headerSetting.warning.confirmDetail')" prop="ConfirmDetail"
						class="fullRow">
						<el-input v-model="state.confirmForm.ConfirmDetail" :rows="4" :maxlength="200" show-word-limit
							type="textarea" :placeholder="$t('common.enterToolTip')" />
					</el-form-item>
				</el-form>
				<template #footer>
					<span class="dialog-footer">
						<el-button type="primary" @click="confirmAlarm">{{ $t("common.confirm") }}</el-button>
						<el-button @click="state.innerDialog = false">{{ $t("common.cancel") }}</el-button>
					</span>
				</template>
			</el-dialog>
			<warnningDialog ref="warningDialogRef" :currentRadio="state.currentRadio" @change="setcurrentRadio">
			</warnningDialog>
		</div>
	</div>
</template>

<script setup>
import { reactive, ref, onMounted, nextTick, computed } from "vue";
import paginationVue from "@common/components/pagination.vue";
import axios from "@common/js/axios";
import { genneralValidate } from "@common/js/rules";
import { alertErrorMsg, alertSuccessMsg, alertConfirm, i18nGlobal, setAuthTree, setDepartment, concatAlarmPath, translateAlarmStr } from "@common/js/utils";
import { MachineTreeNodeTypeEnum, ParentGuid, NormalLevel } from "@common/js/enum";
import dayjs from "dayjs";
import { ElMessageBox } from "element-plus";
import phmTag from "@common/components/phmTag.vue";
import svgIcon from "@common/components/svgIcon.vue";
import { useStore } from "vuex";
import warnningDialog from './warnningDialog.vue'

/** 巨化项目，使用特定字符串过滤故障报警类型 */
const BATTERY_ALARM_TEXT = '电池电量低';

const emit = defineEmits(["setCount", "onChangeAlarmSilent"]);
const props = defineProps({
	isBatteryAlarm: {
		type: Boolean,
		default: false,
	},
	showTitle: {
		type: Boolean,
		default: true,
	},
	path: {
		type: String,
		default: "",
	},
	time: {
		type: Array,
		default: [],
	},
});

const warningDialogRef = ref(null);
const warnnigRef = ref(null);
const innnerFormRef = ref(null);
const state = reactive({
	innerDialog: false,
	searchForm: {
		Path: "",
		StartTime: "",
		EndTime: "",
		AlarmLevel: "",
		AlarmType: "",
		AlarmConfirmDegree: "",
		AlarmConfirmType: "",
		AlarmModel: "",
		ContainHistory: false,
	},
	confirmForm: {
		ConfirmType: "",
		ConfirmDegree: "",
		ConfirmDetail: "",
	},
	confirmRules: {
		ConfirmDegree: genneralValidate(i18nGlobal("common.selectToolTip"), "change"),
		ConfirmType: genneralValidate(i18nGlobal("common.selectToolTip"), "change"),
	},
	loading: false,
	selection: [],
	tableData: [],
	AlarmTypes: [],
	ConfirmDegree: [],
	ConfirmTypes: [],
	confirmData: [],
	AlarmEvents: [],
	AlarmModels: [],
	tree: [],
	originTree: [],
	pageInfo: {
		total: 0,
		PageIndex: 1,
		PageSize: 10,
	},
	isAllAlarmSilent: false,
	currentRadio: i18nGlobal("headerSetting.warning.alarmDynamics"),
	userAlarmPathConfig: {},
});
const store = useStore();
const alarmLeves = computed(() => {
	return store.state.alarmInfo.filter(item => item.Level !== NormalLevel)
});

const lazyLoading = (time) => {
	let isDispose = false;
	const ret = {
		close: () => {
			isDispose = true;
			state.loading = false;
		}
	}
	setTimeout(() => {
		if (isDispose) return;
		state.loading = true;
	}, time);
	return ret;
}

const getTableData = (isFirst) => {
	const loading = lazyLoading(500);
	if (isFirst) {
		state.pageInfo.PageIndex = 1;
	}
	if (state.searchForm.StartTime && state.searchForm.EndTime) {
		const isBefore = dayjs(state.searchForm.StartTime).isBefore(dayjs(state.searchForm.EndTime));
		if (!isBefore) {
			loading.close();
			return alertErrorMsg(i18nGlobal("common.startTimeLessThanEndTime"));
		}
	}
	axios({
		method: "post",
		url: "Alarm/QueryAlarmInfo",
		data: {
			...state.searchForm,
			KeyWords: props.isBatteryAlarm ? BATTERY_ALARM_TEXT : '',
			PageSize: state.pageInfo.PageSize,
			PageIndex: state.pageInfo.PageIndex,
		},
	}).then((res) => {
		if (res.IsSuccess) {
			const data = setFullPath(res.Result.Data);
			state.tableData = data;
			state.pageInfo.total = res.Result.TotalRows;
			if (data.length > 0 && !props.isBatteryAlarm) getIsAllSilent();
		}
	}).finally(() => {
		loading.close();
	});
};

const getIsAllSilent = async () => {
	// no permisson control
	return axios({
		method: "get",
		url: "Alarm/all-alarm-issilent",
	}).then((res) => {
		if (res.IsSuccess) {
			state.isAllAlarmSilent = res.Result;
		}
	});
}

const setFullPath = (data) => {
	let displayContents = [], displayDepartmentTypes = [];
	if (state.userAlarmPathConfig?.DisplayPaths) {
		displayContents = state.userAlarmPathConfig.DisplayPaths;
		displayDepartmentTypes = state.userAlarmPathConfig.DisplayDepartmentTypes;
	}
	data.forEach((item) => {
		let path = concatAlarmPath(item, displayContents, displayDepartmentTypes);
		item.Path = path;
	});

	return data;
};

const changePage = (pageInfo) => {
	state.pageInfo = pageInfo;
	getTableData();
};

const handleSelectionChange = (selection) => {
	state.selection = selection;
};

const confirm = (data, type) => {
	if (data.every((item) => !item.AlarmEndTime || item.IsConfirmed)) return alertErrorMsg(i18nGlobal("headerSetting.warning.confirmTip"));
	state.confirmRequestType = type;
	state.confirmData = data;
	state.confirmForm.ConfirmType = state.ConfirmTypes[1]?.Key;
	state.confirmForm.ConfirmDegree = state.ConfirmDegree[1]?.Key;
	state.innerDialog = true;
};

const exportToExcel = () => {
	axios({
		method: "post",
		url: "Alarm/ExportToExcel",
		data: {
			...state.searchForm,
			KeyWords: props.isBatteryAlarm ? BATTERY_ALARM_TEXT : '',
			alarmIds: state.selection.map((t) => t.Id),
		},
	}).then((res) => {
		if (res.IsSuccess) {
			alertSuccessMsg(i18nGlobal("common.exportWarningSuccess"));
		}
	});
};

const saveCase = (row) => {
	alertConfirm(() => {
		axios({
			method: "post",
			url: `/FaultCase/fault-case/${row.Id}`,
		}).then((res) => {
			if (res.IsSuccess) {
				row.IsAddedFaultCase = !row.IsAddedFaultCase;
				alertSuccessMsg(i18nGlobal('common.joinSuccess'));
			}
		});
	}, {
		msg: i18nGlobal('faultCaseLibrary.joinCase'),
		title: i18nGlobal('common.tip'),
	});
}

const changeAlarmSilent = (row) => {
	axios({
		method: "put",
		url: `Alarm/SetAlarmSilent/${row.Id}/IsSilent/${!row.IsSilent}`,
	}).then((res) => {
		if (res.IsSuccess) {
			getTableData();
			emit("onChangeAlarmSilent");
		}
	});
};

const setAllAlarmSilent = async () => {
	return axios({
		method: "put",
		url: `Alarm/SetAllAlarmSilent/${!state.isAllAlarmSilent}`,
	}).then((res) => {
		if (res.IsSuccess) {
			getTableData();
			emit("onChangeAlarmSilent");
			state.isAllAlarmSilent = !state.isAllAlarmSilent;
		}
	});
};

const confirmAlarm = (loading) => {
	innnerFormRef.value?.validate((valid) => {
		if (!valid) return;
		const ConfirmInfos = [];
		let data = state.confirmData.filter((item) => item.AlarmEndTime && !item.IsConfirmed);
		data.forEach((item) => {
			ConfirmInfos.push({ ...state.confirmForm, AlarmInfoId: item.Id });
		});
		axios({
			method: "post",
			url: "Alarm/ConfirmAlarmInfo",
			data: {
				AlarmConfirmDtos: ConfirmInfos,
			},
		}).then((res) => {
			if (res.IsSuccess) {
				alertSuccessMsg(i18nGlobal("common.confirmSuccess"));
				emit("setCount");
				getTableData();
			}
		}).finally(() => {
			state.innerDialog = false;
		});
	});
};

const setcurrentRadio = ({ currentRadio }) => {
	state.currentRadio = currentRadio;
}

const getDetail = (row) => {
	warningDialogRef.value?.openDialog(row, 'Alarm/GetAlarmInfoDetail', 'AlarmInfo_Browse')
};

const getConfirmDetail = (row) => {
	ElMessageBox.alert(
		`<div class='confirmDetailText'>
            <div class='item'>
                <div class='text'>${i18nGlobal("headerSetting.warning.alarmClassify")}:</div>
                <div class='value'>${row.ConfirmTypeStr}</div>
            </div>
            <div class='item'>
                <div class='text'>${i18nGlobal("headerSetting.warning.severity")}:</div>
                <div class='value'>${row.ConfirmDegreeStr}</div>
            </div>
            <div class='item'>
                <div class='text'>${i18nGlobal("headerSetting.warning.confirmDetail")}:</div>
                <div class='value'>${row.ConfirmDetail ? row.ConfirmDetail : "-"}</div>
            </div>
        </div>`,
		i18nGlobal("headerSetting.warning.confirmInfo"),
		{
			dangerouslyUseHTMLString: true,
			confirmButtonText: i18nGlobal("common.confirm"),
			closeOnClickModal: true,
		}
	);
};

const reset = () => {
	state.searchForm.AlarmType = state.AlarmTypes[0]?.Key;
	state.searchForm.AlarmLevel = 0;
	state.searchForm.AlarmModel = state.AlarmModels[0]?.Key;
	state.searchForm.AlarmConfirmType = state.ConfirmTypes[0]?.Key;
	state.searchForm.AlarmConfirmDegree = state.ConfirmDegree[0]?.Key;
	state.searchForm.StartTime = "";
	state.searchForm.EndTime = "";
	state.searchForm.Path = "";
	getTableData(true);
};

const selectable = (row) => {
	return true;
	return !row.IsConfirmed && row.AlarmEndTime;
};

const filterNodeMethod = (value, data) => data.Name.includes(value);

const getTree = async () => {
	// no permisson control
	return axios({
		method: "get",
		url: "/Tree/tree",
	}).then(async (res) => {
		if (res.IsSuccess) {
			state.originTree = res.Result.sort((a, b) => a.DisplayIndex - b.DisplayIndex);
			res.Result = setAuthTree(res.Result.sort((a, b) => a.DisplayIndex - b.DisplayIndex));
			state.tree = setDepartment(res.Result, ParentGuid);
		}
	});
};

const clearData = () => {
	state.pageInfo.PageIndex = 1;
	state.pageInfo.total = 0;
	state.tableData = [];
};

const getAlarmRelated = () => {
	const relateInfo = store.state.alarmRelateInfo;
	state.AlarmTypes = relateInfo.AlarmTypes;
	state.ConfirmDegree = relateInfo.ConfirmDegree;
	state.ConfirmTypes = relateInfo.ConfirmTypes;
	state.AlarmModels = relateInfo.AlarmModels;
}

const getCurrentWorkGroupPath = async () => {
	if (store.state.userAlarmPathConfig?.DisplayPaths) {
		return store.state.userAlarmPathConfig;
	} else {
		await axios({
			method: "get",
			url: "AlarmConfig/display-path/by-user",
		}).then(res => {
			if (res.IsSuccess) {
				store.commit('setUserAlarmPathConfig', { userAlarmPathConfig: res.Result ?? {} })
			}
		})
		return store.state.userAlarmPathConfig;
	}
}

const getOptions = async () => {
	// const loading = fullScreenLoading('加载中');
	state.loading = true;
	getTree();
	getAlarmRelated();
	state.searchForm.AlarmType = state.AlarmTypes[0]?.Key;
	state.searchForm.AlarmLevel = 0;
	state.searchForm.AlarmModel = props.isBatteryAlarm ? 2 : state.AlarmModels[0]?.Key;
	state.searchForm.AlarmConfirmType = state.ConfirmTypes[0]?.Key;
	state.searchForm.AlarmConfirmDegree = state.ConfirmDegree[0]?.Key;
	if (props.time.length > 0) {
		state.searchForm.StartTime = props.time[0];
		state.searchForm.EndTime = props.time[1];
	}
	if (props.path) {
		state.searchForm.Path = props.path;
	}
	await getCurrentWorkGroupPath().then(res => state.userAlarmPathConfig = res)
	getTableData(true);
};

const resetFields = () => {
	innnerFormRef.value?.resetFields();
};

const setAlarmNode = (row) => {
	const device = state.originTree.find((t) => t.Path === row.DevicePath);
	let path = row.DevicePath,
		currentNode = state.originTree.find((t) => t.Path === path),
		ids = [];
	path = path.slice(0, -4);
	ids.unshift(currentNode.Id);
	while (currentNode && path.length >= 8) {
		currentNode = state.originTree.find((t) => t.Path === path);
		ids.unshift(currentNode.Id);
		path = path.slice(0, -4);
	}
	if (path.length > 8) {
		return alertErrorMsg(i18nGlobal("headerSetting.warning.searchDeviceTip"));
	}
	if (ids.length > 0 && device?.Id) {
		window.MachineTree.findAlarmNode(device.Id, ids);
	}
};

onMounted(() => { });

defineExpose({
	getOptions,
	clearData,
});
</script>

<style scoped lang="less">
.warning {

	// min-width: 1000px;
	.title {
		color: var(--phm-theme-title);
		font-weight: bold;
		height: 40px;
		line-height: 40px;
		border-bottom: 1px solid var(--phm-theme-border);
		padding-left: 20px;
	}

	.content {
		padding: 20px;
	}
}

.el-checkbox {
	margin-left: 0;
}

.chartWrapper {
	.item {
		width: 100%;
		height: 300px;
	}
}

.confirmInfo {
	display: flex;
	align-items: center;

	.phmTag {
		margin: 0 8px;
	}
}

.silientIcon {
	margin: 0 0 -3px 16px;
	cursor: pointer;
}
</style>
