// @generated by protobuf-ts 2.9.1
// @generated from protobuf file "protos/firstwavedata.proto" (package "storage", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { HistoryPointDataReply } from "./historypointdatareply";
/**
 * @generated from protobuf message storage.GetFirstWaveDataRequest
 */
export interface GetFirstWaveDataRequest {
    /**
     * @generated from protobuf field: string StartTime = 1 [json_name = "StartTime"];
     */
    startTime: string;
    /**
     * @generated from protobuf field: string EndTime = 2 [json_name = "EndTime"];
     */
    endTime: string;
    /**
     * @generated from protobuf field: repeated int32 Points = 3 [json_name = "Points"];
     */
    points: number[];
    /**
     * @generated from protobuf field: bool IsFirst = 4 [json_name = "IsFirst"];
     */
    isFirst: boolean;
    /**
     * @generated from protobuf field: bool IsExternal = 5 [json_name = "IsExternal"];
     */
    isExternal: boolean;
}
/**
 * @generated from protobuf message storage.GetFirstWaveDataReply
 */
export interface GetFirstWaveDataReply {
    /**
     * @generated from protobuf field: repeated storage.HistoryPointDataReply Points = 1 [json_name = "Points"];
     */
    points: HistoryPointDataReply[];
}
/**
 * @generated from protobuf message storage.GetLatestWaveDataRequest
 */
export interface GetLatestWaveDataRequest {
    /**
     * @generated from protobuf field: repeated int32 PointIds = 1 [json_name = "PointIds"];
     */
    pointIds: number[];
}
// @generated message type with reflection information, may provide speed optimized methods
class GetFirstWaveDataRequest$Type extends MessageType<GetFirstWaveDataRequest> {
    constructor() {
        super("storage.GetFirstWaveDataRequest", [
            { no: 1, name: "StartTime", kind: "scalar", jsonName: "StartTime", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "EndTime", kind: "scalar", jsonName: "EndTime", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "Points", kind: "scalar", jsonName: "Points", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "IsFirst", kind: "scalar", jsonName: "IsFirst", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "IsExternal", kind: "scalar", jsonName: "IsExternal", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<GetFirstWaveDataRequest>): GetFirstWaveDataRequest {
        const message = { startTime: "", endTime: "", points: [], isFirst: false, isExternal: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetFirstWaveDataRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetFirstWaveDataRequest): GetFirstWaveDataRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string StartTime = 1 [json_name = "StartTime"];*/ 1:
                    message.startTime = reader.string();
                    break;
                case /* string EndTime = 2 [json_name = "EndTime"];*/ 2:
                    message.endTime = reader.string();
                    break;
                case /* repeated int32 Points = 3 [json_name = "Points"];*/ 3:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.points.push(reader.int32());
                    else
                        message.points.push(reader.int32());
                    break;
                case /* bool IsFirst = 4 [json_name = "IsFirst"];*/ 4:
                    message.isFirst = reader.bool();
                    break;
                case /* bool IsExternal = 5 [json_name = "IsExternal"];*/ 5:
                    message.isExternal = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetFirstWaveDataRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string StartTime = 1 [json_name = "StartTime"]; */
        if (message.startTime !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.startTime);
        /* string EndTime = 2 [json_name = "EndTime"]; */
        if (message.endTime !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.endTime);
        /* repeated int32 Points = 3 [json_name = "Points"]; */
        if (message.points.length) {
            writer.tag(3, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.points.length; i++)
                writer.int32(message.points[i]);
            writer.join();
        }
        /* bool IsFirst = 4 [json_name = "IsFirst"]; */
        if (message.isFirst !== false)
            writer.tag(4, WireType.Varint).bool(message.isFirst);
        /* bool IsExternal = 5 [json_name = "IsExternal"]; */
        if (message.isExternal !== false)
            writer.tag(5, WireType.Varint).bool(message.isExternal);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message storage.GetFirstWaveDataRequest
 */
export const GetFirstWaveDataRequest = new GetFirstWaveDataRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetFirstWaveDataReply$Type extends MessageType<GetFirstWaveDataReply> {
    constructor() {
        super("storage.GetFirstWaveDataReply", [
            { no: 1, name: "Points", kind: "message", jsonName: "Points", repeat: 1 /*RepeatType.PACKED*/, T: () => HistoryPointDataReply }
        ]);
    }
    create(value?: PartialMessage<GetFirstWaveDataReply>): GetFirstWaveDataReply {
        const message = { points: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetFirstWaveDataReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetFirstWaveDataReply): GetFirstWaveDataReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated storage.HistoryPointDataReply Points = 1 [json_name = "Points"];*/ 1:
                    message.points.push(HistoryPointDataReply.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetFirstWaveDataReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated storage.HistoryPointDataReply Points = 1 [json_name = "Points"]; */
        for (let i = 0; i < message.points.length; i++)
            HistoryPointDataReply.internalBinaryWrite(message.points[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message storage.GetFirstWaveDataReply
 */
export const GetFirstWaveDataReply = new GetFirstWaveDataReply$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetLatestWaveDataRequest$Type extends MessageType<GetLatestWaveDataRequest> {
    constructor() {
        super("storage.GetLatestWaveDataRequest", [
            { no: 1, name: "PointIds", kind: "scalar", jsonName: "PointIds", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<GetLatestWaveDataRequest>): GetLatestWaveDataRequest {
        const message = { pointIds: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetLatestWaveDataRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetLatestWaveDataRequest): GetLatestWaveDataRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated int32 PointIds = 1 [json_name = "PointIds"];*/ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.pointIds.push(reader.int32());
                    else
                        message.pointIds.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetLatestWaveDataRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated int32 PointIds = 1 [json_name = "PointIds"]; */
        if (message.pointIds.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.pointIds.length; i++)
                writer.int32(message.pointIds[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message storage.GetLatestWaveDataRequest
 */
export const GetLatestWaveDataRequest = new GetLatestWaveDataRequest$Type();
