import { VertexInfoClass, YAxisType } from "./Dh-webchart-models";
//坐标信息
export class CoordinateInfo {
	constructor(xmax, xmin, ymax, ymin, owner) {
		this.XMax = xmax;
		this.XMin = xmin;
		this.YMax = ymax;
		this.YMin = ymin;
		this.YMax_Min = null;
		this.YMin_Max = null;
		this.Owner = owner;

		//最初的坐标，没有经过取整计算过的
		this.OriginXMax = this.XMax;
		this.OriginXMin = this.XMin;
		this.OriginYMax = this.YMax;
		this.OriginYMin = this.YMin;
		this.YRate = 1;

		this.AutoChangeYAxis = true;
		this.IsEnlarge = false;
		this.XMinRate = 0;
		this.XMaxRate = 1;
	}
	ResetXAxisRate() {
		this.XMinRate = 0;
		this.XMaxRate = 1;
	}
	UpdateInfo(xmax, xmin, ymax, ymin) {
		if (!this.IsEnlarge && this.AutoChangeYAxis) {
			if (ymax == ymin) {
				ymax = ymax + 100;
				ymin = ymin - 100;
			}
			if (this.YMax_Min != null) {
				if (ymax < this.YMax_Min) ymax = this.YMax_Min > 0 ? this.YMax_Min * 1.2 : this.YMax_Min * 0.8;
			}
			if (this.YMin_Max != null) {
				if (ymin > this.YMin_Max) ymin = this.YMin_Max > 0 ? this.YMin_Max * 0.8 : this.YMin_Max * 1.2;
			}
			this.OriginYMax = ymax;
			this.OriginYMin = ymin;
			let yaxis = this.GetYZoomValue(ymax, ymin, this.YRate);
			this.YMax = yaxis.Max;
			this.YMin = yaxis.Min;
		}

		this.OriginXMax = xmax;
		this.OriginXMin = xmin;
		this.XMax = xmin + (xmax - xmin) * this.XMaxRate;
		this.XMin = xmin + (xmax - xmin) * this.XMinRate;
		if (this.XMax == this.XMin) {
			this.XMax = this.XMin + 100;
		}
	}
	XZoomIn(pos) {
		let result = this.GetXZoomValue(this.XMax, this.XMin, -1, pos);
		this.XMax = result.Max;
		this.XMin = result.Min;
		this.XMinRate = result.MinRate;
		this.XMaxRate = result.MaxRate;
	}
	XZoomOut(pos) {
		let result = this.GetXZoomValue(this.XMax, this.XMin, 0.5, pos);
		this.XMax = result.Max;
		this.XMin = result.Min;
		this.XMinRate = result.MinRate;
		this.XMaxRate = result.MaxRate;
	}
	YZoomIn() {
		if (Math.log(this.YRate) > 8) return;
		let result = this.GetYZoomValue(this.YMax, this.YMin, 2);
		this.YMax = result.Max;
		this.YMin = result.Min;
		this.YRate = this.YRate * 2;
	}
	YZoomOut() {
		if (Math.log(1 / this.YRate) > 8) return;
		let result = this.GetYZoomValue(this.YMax, this.YMin, 1 / 2);
		this.YMax = result.Max;
		this.YMin = result.Min;
		this.YRate = this.YRate / 2;
	}
	GetYZoomValue(max, min, rate) {
		rate = rate == 0 ? 1 : rate;
		if (rate > 1) {
			for (let i = 0; i < Math.log2(rate); i++) {
				let diff = (max - min) / 2;
				max = max + diff;
				min = min - diff;
			}
		} else if (rate < 1) {
			rate = 1 / rate;
			for (let i = 0; i < Math.log2(rate); i++) {
				let diff = (max - min) / 4;
				max = max - diff;
				min = min + diff;
			}
		}
		return { Max: max, Min: min };
	}
	GetXZoomValue(max, min, rate, pos) {
		let currentX = (max + min) / 2;
		if (pos != null && !isNaN(pos)) {
			currentX = pos;
		} else {
			let cursor = this.Owner.Owner.PluginManager.GetRadioPlugin();
			if (cursor) {
				let cursorPos = cursor.GetZoomPosition();
				if (cursorPos != null && !isNaN(cursorPos)) currentX = cursorPos;
			}
		}
		let xmin = min + Math.abs(currentX - min) * rate;
		let xmax = max - Math.abs(max - currentX) * rate;
		if (xmin < this.OriginXMin) xmin = this.OriginXMin;
		if (xmax > this.OriginXMax) xmax = this.OriginXMax;
		if (xmax - xmin <= 0.0001) {
			xmax = max;
			xmin = min;
		}
		return {
			Min: xmin,
			Max: xmax,
			MinRate: (xmin - this.OriginXMin) / (this.OriginXMax - this.OriginXMin),
			MaxRate: (xmax - this.OriginXMin) / (this.OriginXMax - this.OriginXMin),
		};
	}
}

export class TrendCoordinateInfo extends CoordinateInfo {
	constructor(xmax, xmin, ymax, ymin, owner) {
		super(xmax, xmin, ymax, ymin, owner);
	}
	UpdateInfo(xmax, xmin, ymax, ymin) {
		if (!this.Owner.Owner.Option.isHistory) {
			super.UpdateInfo(xmax, xmin, ymax, ymin);
		} else {
			this.XMaxRate = 1;
			this.XMinRate = 0;
			super.UpdateInfo(xmax, xmin, ymax, ymin);
		}
	}
	GetXZoomValue(max, min, rate) {
		console.log(this)
		if (!this.Owner.Owner.Option.isHistory) {
			let currentX = (max + min) / 2;
			let selectId = this.Owner.Owner.CoordinateSystem.GetSelectItem();
			if (selectId) {
				let seletedPoint = this.Owner.Owner.ShapeOperator.Data.find((r) => r.PointId == selectId);
				if (seletedPoint?.Points?.length > 0) {
					currentX = seletedPoint.Points[seletedPoint.Points.length - 1].X;
				}
			}
			let xmin = 0;
			let xmax = 0;
			if (max < currentX || currentX < min) {
				let delta = (max - min) / 2;
				xmin = min + delta * rate;
				xmax = max - delta * rate;
			} else {
				xmin = min + (currentX - min) * rate;
				xmax = max - (max - currentX) * rate;
			}
			if (xmax > this.OriginXMax) xmax = this.OriginXMax;

			if (!this.Owner.Owner.Option.Axis.InfiniteZoom) {
				/** 最大缩放60天 */
				if ((xmax - xmin) / 1000 / 60 / 60 / 24 > 60) {
					xmin = min;
					xmax = max;
				}
				/** 最小缩放1秒 */
				if (xmax - xmin <= 1000) {
					xmax = max;
					xmin = min;
				}
			}

			return {
				Min: xmin,
				Max: xmax,
				MinRate: (xmin - this.OriginXMin) / (this.OriginXMax - this.OriginXMin),
				MaxRate: (xmax - this.OriginXMin) / (this.OriginXMax - this.OriginXMin),
			};
		} else {
			return this.GetHistoryXZoomValue(max, min, rate);
		}
	}
	GetHistoryXZoomValue(max, min, rate) {
		let currentX = (max + min) / 2;
		let cursor = this.Owner.Owner.PluginManager.GetRadioPlugin();
		if (cursor && cursor.GetZoomPosition()) {
			currentX = cursor.GetZoomPosition();
		} else {
			//获取最新的点
			let selectId = this.Owner.Owner.CoordinateSystem.GetSelectItem();
			if (selectId) {
				let seletedPoint = this.Owner.Owner.ShapeOperator.Data.find((r) => r.PointId == selectId);
				if (seletedPoint?.Points?.length > 0) {
					currentX = seletedPoint.Points[seletedPoint.Points.length - 1].X;
				}
			}
		}
		let xmin = 0;
		let xmax = 0;
		if (max < currentX || currentX < min) {
			let delta = (max - min) / 2;
			xmin = min + delta * rate;
			xmax = max - delta * rate;
		} else {
			xmin = min + (currentX - min) * rate;
			xmax = max - (max - currentX) * rate;
		}
		if ((xmax - xmin) / 1000 / 60 / 60 / 24 / 365 > 32) {
			xmin = min;
			xmax = max;
		}
		if (xmax - xmin <= 0.00000000001) {
			xmax = max;
			xmin = min;
		}
		return {
			Min: xmin,
			Max: xmax,
			MinRate: (xmin - this.OriginXMin) / (this.OriginXMax - this.OriginXMin),
			MaxRate: (xmax - this.OriginXMin) / (this.OriginXMax - this.OriginXMin),
		};
	}
}

export class CorrelationCoordinateInfo extends CoordinateInfo {
	constructor(xmax, xmin, ymax, ymin, owner) {
		super(xmax, xmin, ymax, ymin, owner);
	}
	UpdateInfo(xmax, xmin, ymax, ymin) {
		if (this.AutoChangeYAxis) {
			if (ymax == ymin) {
				ymax = ymax + 100;
				ymin = ymin - 100;
			}
			if (this.YMax_Min != null) {
				if (ymax < this.YMax_Min) ymax = this.YMax_Min > 0 ? this.YMax_Min * 1.2 : this.YMax_Min * 0.8;
			}
			if (this.YMin_Max != null) {
				if (ymin > this.YMin_Max) ymin = this.YMin_Max > 0 ? this.YMin_Max * 0.8 : this.YMin_Max * 1.2;
			}
			this.OriginYMax = ymax;
			this.OriginYMin = ymin;
			var yaxis = this.GetYZoomValue(ymax, ymin, this.YRate);
			this.YMax = yaxis.Max;
			this.YMin = yaxis.Min;
		}
		if (this.AutoChangeXAxis) {
			if (xmax == xmin) {
				xmax = xmax + 100;
			}
			this.OriginXMax = xmax;
			this.OriginXMin = xmin;
			var abs = xmax > Math.abs(xmin) ? xmax : Math.abs(xmin);
			var xaxis = this.GetXZoomValue(abs, -abs, this.XRate);
			this.XMax = xaxis.Max;
			this.XMin = xaxis.Min;
		}
	}
	GetXZoomValue(max, min, rate) {
		if (rate > 1) {
			for (var i = 0; i < Math.log2(rate); i++) {
				var diff = (max - min) / 2;
				max = max + diff;
				min = min - diff;
			}
		} else if (rate < 1) {
			rate = 1 / rate;
			for (var i = 0; i < Math.log2(rate); i++) {
				var diff = (max - min) / 4;
				max = max - diff;
				min = min + diff;
			}
		}
		return { Max: max, Min: min };
	}
}

export class SpectrumCoordinateInfo extends CoordinateInfo {
	constructor(xmax, xmin, ymax, ymin, owner) {
		super(xmax, xmin, ymax, ymin, owner);
		this.XAxisLogType = 1;
		this.YAxisLogType = 1;
		this.YReferenceValue = 1;
	}
	GetYZoomValue(max, min, rate) {
		if (rate > 1) {
			for (let i = 0; i < Math.log2(rate); i++) {
				let diff = max - min;
				max = max + diff;
			}
		} else if (rate < 1) {
			rate = 1 / rate;
			for (let i = 0; i < Math.log2(rate); i++) {
				let diff = (max - min) / 2;
				max = max - diff;
			}
		}
		return { Max: max, Min: min };
	}
}

export class ChartInformationBase {
	constructor(padding) {
		this.ChartContainerRect = null; //图谱大小
		this.ShapeContainerRect = null; //数据显示区域大小
		this.YAxisItems = new Map();
		this.YAxisType = YAxisType.Number;
		this.Owner = null;
		this.Padding = padding
			? padding
			: {
				Left: 5,
				Top: 30,
				Right: 30,
				Bottom: 5,
			}; //坐标轴预设间距-- 坐标轴间距=坐标轴预设间距+文字长度
	}
	XZoomIn() { }
	XZoomOut() { }
	YZoomIn() { }
	YZoomOut() { }
	UpdateCoordinateInfo(xmax, xmin, ymax, ymin) { }
	SetYMaxMin(ymax_min) { }
	SetYMinMax(ymin_max) { }
	getEndPoint() { }
}

export class ChartInformation extends ChartInformationBase {
	constructor(padding) {
		super(padding);
		this.Left = 0;
		this.Top = 0;
		this.CoordinateInfo = null; //坐标信息
		this.UnitTexts = {
			YText: "",
			XText: "",
		};
	}
	XZoomIn(pos) {
		this.CoordinateInfo.XZoomIn(pos);
	}
	XZoomOut(pos) {
		this.CoordinateInfo.XZoomOut(pos);
	}
	YZoomIn() {
		this.CoordinateInfo.YZoomIn();
	}
	YZoomOut() {
		this.CoordinateInfo.YZoomOut();
	}
	UpdateCoordinateInfo(xmax, xmin, ymax, ymin) {
		if (xmax == undefined || xmax == null) xmax = this.CoordinateInfo.OriginXMax;
		if (xmin == undefined || xmin == null) xmin = this.CoordinateInfo.OriginXMin;
		if (ymax == undefined || ymax == null) ymax = this.CoordinateInfo.OriginYMax;
		if (ymin == undefined || ymin == null) ymin = this.CoordinateInfo.OriginYMin;
		this.CoordinateInfo.UpdateInfo(xmax, xmin, ymax, ymin);
	}
	SetYMaxMin(ymax_min) {
		this.CoordinateInfo.YMax_Min = ymax_min;
	}
	SetYMinMax(ymin_max) {
		this.CoordinateInfo.YMin_Max = ymin_max;
	}
	GetCharts() {
		let result = new Map();
		result.set(0, this);
		return result;
	}
	getEndPoint() {
		return {
			XMin: this.CoordinateInfo.XMin,
			XMax: this.CoordinateInfo.XMax,
		};
	}
}

export class MultiChartInformation {
	constructor(padding) {
		this.ChartContainerRect = null; //图谱大小
		this.ShapeContainerRect = null; //数据显示区域大小
		this.Owner = null;
		this.CoordinateInfo = null; //坐标信息
		this.Padding = padding
			? padding
			: {
				Left: 5,
				Top: 30,
				Right: 30,
				Bottom: 5,
			};
		this.ChartList = new Map();
		this.YRate = 1;
	}
	YZoomIn() {
		this.YRate = this.YRate * 2;
		for (const chart of this.ChartList.values()) {
			chart.YZoomIn();
		}
	}
	YZoomOut() {
		this.YRate = this.YRate / 2;
		for (const chart of this.ChartList.values()) {
			chart.YZoomOut();
		}
	}
}

export class GraphInformation {
	constructor(padding) {
		this.ChartContainerRect = null; //图谱大小
		this.ShapeContainerRect = null; //数据显示区域大小
		this.Owner = null;
		this.Padding = padding
			? padding
			: {
				Left: 5,
				Top: 30,
				Right: 30,
				Bottom: 5,
			};
		this.UnitTexts = {
			YText: "",
			XText: "",
		};
		this.ChartList = new Map(); //分开显示信息
		this.Chart = null; //合并显示信息
	}
	XZoomIn() {
		if (this.Chart != null) {
			this.Chart.XZoomIn();
		}
		for (const chart of this.ChartList.values()) {
			chart.XZoomIn();
		}
	}
	XZoomOut() {
		if (this.Chart != null) {
			this.Chart.XZoomOut();
		}
		for (const chart of this.ChartList.values()) {
			chart.XZoomOut();
		}
	}
	YZoomIn() {
		if (this.Chart != null) {
			this.Chart.YZoomIn();
		}
		for (const chart of this.ChartList.values()) {
			chart.YZoomIn();
		}
	}
	YZoomOut() {
		if (this.Chart != null) {
			this.Chart.YZoomOut();
		}
		for (const chart of this.ChartList.values()) {
			chart.YZoomOut();
		}
	}
	GetCharts() {
		return this.ChartList;
	}
	getEndPoint() {
		return {
			XMin: 0,
			XMax: 1,
		};
	}
}

export class VertexChartInfomation extends ChartInformation {
	constructor(padding) {
		super(padding);
		this.VertexInfo = new VertexInfoClass();
		this.ZUint = null; //每个Z占用的px数
		this.CurrentIndex = 0; //当前频谱index
		this.CurrentRect = null;
	}
}

export class SqureCoordinateInfo extends CoordinateInfo {
	constructor(xmax, xmin, ymax, ymin, owner) {
		super(xmax, xmin, ymax, ymin, owner);
	}
	GetXZoomValue(max, min, rate) {
		let currentX = (max + min) / 2;
		let xmin = min + Math.abs(currentX - min) * rate;
		let xmax = max - Math.abs(max - currentX) * rate;
		if (xmax - xmin <= 0.0001) {
			xmax = max;
			xmin = min;
		}
		return {
			Min: xmin,
			Max: xmax,
			MinRate: (xmin - this.OriginXMin) / (this.OriginXMax - this.OriginXMin),
			MaxRate: (xmax - this.OriginXMin) / (this.OriginXMax - this.OriginXMin),
		};
	}
}
