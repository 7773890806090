// @generated by protobuf-ts 2.9.1
// @generated from protobuf file "protos/component.proto" (package "component", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { Component } from "./component";
import type { HistoryHealthDatasRequest } from "./historyhealthdatas";
import type { GetTorvibGraphDatasRequest } from "./torvibgraphdatas";
import type { GetParamTwoPointFirstDatasRequest } from "./paramtwopointfirstdatas";
import type { GetParamTwoPointNearestDatasRequest } from "./paramtwopointnearestdatas";
import type { GetParamTwoPointDatasRequest } from "./paramtwopointdatas";
import type { GetGraphFirstDatasRequest } from "./graphfirstdatas";
import type { GetGraphNearestDatasRequest } from "./graphnearestdatas";
import type { GetGraphDatasRequest } from "./graphdatas";
import type { GetTwiceDifferentialGraphDataRequest } from "./twicedifferentialgraphdata";
import type { GetEnvelopSpecGraphDataRequest } from "./envelopspecgraphdata";
import type { GetEnvelopGraphDataRequest } from "./envelopgraphdata";
import type { GetOnceDifferentialGraphDataRequest } from "./oncedifferentialgraphdata";
import type { GetTwiceIntegralGraphDataRequest } from "./twiceintegralgraphdata";
import type { GetOnceIntegralGraphDataRequest } from "./onceIntegralgraphdata";
import type { GetFIRGraphDataRequest } from "./firgraphdata";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { Reply } from "./reply";
import type { GetFrGraphDataRequest } from "./frgraphdata";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service component.Component
 */
export interface IComponentClient {
    /**
     * @generated from protobuf rpc: GetFrGraphData(component.GetFrGraphDataRequest) returns (storage.Reply);
     */
    getFrGraphData(input: GetFrGraphDataRequest, options?: RpcOptions): UnaryCall<GetFrGraphDataRequest, Reply>;
    /**
     * @generated from protobuf rpc: GetFIRGraphData(component.GetFIRGraphDataRequest) returns (storage.Reply);
     */
    getFIRGraphData(input: GetFIRGraphDataRequest, options?: RpcOptions): UnaryCall<GetFIRGraphDataRequest, Reply>;
    /**
     * @generated from protobuf rpc: GetOnceIntegralGraphData(component.GetOnceIntegralGraphDataRequest) returns (storage.Reply);
     */
    getOnceIntegralGraphData(input: GetOnceIntegralGraphDataRequest, options?: RpcOptions): UnaryCall<GetOnceIntegralGraphDataRequest, Reply>;
    /**
     * @generated from protobuf rpc: GetTwiceIntegralGraphData(component.GetTwiceIntegralGraphDataRequest) returns (storage.Reply);
     */
    getTwiceIntegralGraphData(input: GetTwiceIntegralGraphDataRequest, options?: RpcOptions): UnaryCall<GetTwiceIntegralGraphDataRequest, Reply>;
    /**
     * @generated from protobuf rpc: GetOnceDifferentialGraphData(component.GetOnceDifferentialGraphDataRequest) returns (storage.Reply);
     */
    getOnceDifferentialGraphData(input: GetOnceDifferentialGraphDataRequest, options?: RpcOptions): UnaryCall<GetOnceDifferentialGraphDataRequest, Reply>;
    /**
     * @generated from protobuf rpc: GetEnvelopGraphData(component.GetEnvelopGraphDataRequest) returns (storage.Reply);
     */
    getEnvelopGraphData(input: GetEnvelopGraphDataRequest, options?: RpcOptions): UnaryCall<GetEnvelopGraphDataRequest, Reply>;
    /**
     * @generated from protobuf rpc: GetEnvelopSpecGraphData(component.GetEnvelopSpecGraphDataRequest) returns (storage.Reply);
     */
    getEnvelopSpecGraphData(input: GetEnvelopSpecGraphDataRequest, options?: RpcOptions): UnaryCall<GetEnvelopSpecGraphDataRequest, Reply>;
    /**
     * @generated from protobuf rpc: GetTwiceDifferentialGraphData(component.GetTwiceDifferentialGraphDataRequest) returns (storage.Reply);
     */
    getTwiceDifferentialGraphData(input: GetTwiceDifferentialGraphDataRequest, options?: RpcOptions): UnaryCall<GetTwiceDifferentialGraphDataRequest, Reply>;
    /**
     * @generated from protobuf rpc: QueryWholePeriodDatas(component.GetGraphDatasRequest) returns (storage.Reply);
     */
    queryWholePeriodDatas(input: GetGraphDatasRequest, options?: RpcOptions): UnaryCall<GetGraphDatasRequest, Reply>;
    /**
     * @generated from protobuf rpc: QueryLatestSpectralDatas(component.GetGraphDatasRequest) returns (storage.Reply);
     */
    queryLatestSpectralDatas(input: GetGraphDatasRequest, options?: RpcOptions): UnaryCall<GetGraphDatasRequest, Reply>;
    /**
     * @generated from protobuf rpc: QueryBodePlotsDatas(component.GetGraphDatasRequest) returns (storage.Reply);
     */
    queryBodePlotsDatas(input: GetGraphDatasRequest, options?: RpcOptions): UnaryCall<GetGraphDatasRequest, Reply>;
    /**
     * @generated from protobuf rpc: QueryPolarDatas(component.GetGraphDatasRequest) returns (storage.Reply);
     */
    queryPolarDatas(input: GetGraphDatasRequest, options?: RpcOptions): UnaryCall<GetGraphDatasRequest, Reply>;
    /**
     * @generated from protobuf rpc: QueryWholePeriodAlarmNearestDatas(component.GetGraphNearestDatasRequest) returns (storage.Reply);
     */
    queryWholePeriodAlarmNearestDatas(input: GetGraphNearestDatasRequest, options?: RpcOptions): UnaryCall<GetGraphNearestDatasRequest, Reply>;
    /**
     * @generated from protobuf rpc: QueryWholePeriodAlarmPreDatas(component.GetGraphNearestDatasRequest) returns (storage.Reply);
     */
    queryWholePeriodAlarmPreDatas(input: GetGraphNearestDatasRequest, options?: RpcOptions): UnaryCall<GetGraphNearestDatasRequest, Reply>;
    /**
     * @generated from protobuf rpc: QueryWholePeriodAlarmNextDatas(component.GetGraphNearestDatasRequest) returns (storage.Reply);
     */
    queryWholePeriodAlarmNextDatas(input: GetGraphNearestDatasRequest, options?: RpcOptions): UnaryCall<GetGraphNearestDatasRequest, Reply>;
    /**
     * @generated from protobuf rpc: QueryBodeAlarmDatas(component.GetGraphNearestDatasRequest) returns (storage.Reply);
     */
    queryBodeAlarmDatas(input: GetGraphNearestDatasRequest, options?: RpcOptions): UnaryCall<GetGraphNearestDatasRequest, Reply>;
    /**
     * @generated from protobuf rpc: QueryPolarAlarmDatas(component.GetGraphNearestDatasRequest) returns (storage.Reply);
     */
    queryPolarAlarmDatas(input: GetGraphNearestDatasRequest, options?: RpcOptions): UnaryCall<GetGraphNearestDatasRequest, Reply>;
    /**
     * @generated from protobuf rpc: QueryWholePeriodFirstData(component.GetGraphFirstDatasRequest) returns (storage.Reply);
     */
    queryWholePeriodFirstData(input: GetGraphFirstDatasRequest, options?: RpcOptions): UnaryCall<GetGraphFirstDatasRequest, Reply>;
    /**
     * @generated from protobuf rpc: QueryAxisPositionDatas(component.GetParamTwoPointDatasRequest) returns (storage.Reply);
     */
    queryAxisPositionDatas(input: GetParamTwoPointDatasRequest, options?: RpcOptions): UnaryCall<GetParamTwoPointDatasRequest, Reply>;
    /**
     * @generated from protobuf rpc: QueryAxisTrailDatas(component.GetParamTwoPointDatasRequest) returns (storage.Reply);
     */
    queryAxisTrailDatas(input: GetParamTwoPointDatasRequest, options?: RpcOptions): UnaryCall<GetParamTwoPointDatasRequest, Reply>;
    /**
     * @generated from protobuf rpc: QueryAxisPosAlarmDatas(component.GetParamTwoPointNearestDatasRequest) returns (storage.Reply);
     */
    queryAxisPosAlarmDatas(input: GetParamTwoPointNearestDatasRequest, options?: RpcOptions): UnaryCall<GetParamTwoPointNearestDatasRequest, Reply>;
    /**
     * @generated from protobuf rpc: QueryAxisTrailAlarmNearestDatas(component.GetParamTwoPointNearestDatasRequest) returns (storage.Reply);
     */
    queryAxisTrailAlarmNearestDatas(input: GetParamTwoPointNearestDatasRequest, options?: RpcOptions): UnaryCall<GetParamTwoPointNearestDatasRequest, Reply>;
    /**
     * @generated from protobuf rpc: QueryAxisTrailAlarmPreDatas(component.GetParamTwoPointNearestDatasRequest) returns (storage.Reply);
     */
    queryAxisTrailAlarmPreDatas(input: GetParamTwoPointNearestDatasRequest, options?: RpcOptions): UnaryCall<GetParamTwoPointNearestDatasRequest, Reply>;
    /**
     * @generated from protobuf rpc: QueryAxisTrailAlarmNextDatas(component.GetParamTwoPointNearestDatasRequest) returns (storage.Reply);
     */
    queryAxisTrailAlarmNextDatas(input: GetParamTwoPointNearestDatasRequest, options?: RpcOptions): UnaryCall<GetParamTwoPointNearestDatasRequest, Reply>;
    /**
     * @generated from protobuf rpc: QueryAxisTrailFirstData(component.GetParamTwoPointFirstDatasRequest) returns (storage.Reply);
     */
    queryAxisTrailFirstData(input: GetParamTwoPointFirstDatasRequest, options?: RpcOptions): UnaryCall<GetParamTwoPointFirstDatasRequest, Reply>;
    /**
     * @generated from protobuf rpc: QueryTorvibDatas(component.GetTorvibGraphDatasRequest) returns (storage.Reply);
     */
    queryTorvibDatas(input: GetTorvibGraphDatasRequest, options?: RpcOptions): UnaryCall<GetTorvibGraphDatasRequest, Reply>;
    /**
     * @generated from protobuf rpc: QueryHistoryHealthDatasAsync(component.HistoryHealthDatasRequest) returns (storage.Reply);
     */
    queryHistoryHealthDatasAsync(input: HistoryHealthDatasRequest, options?: RpcOptions): UnaryCall<HistoryHealthDatasRequest, Reply>;
}
/**
 * @generated from protobuf service component.Component
 */
export class ComponentClient implements IComponentClient, ServiceInfo {
    typeName = Component.typeName;
    methods = Component.methods;
    options = Component.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: GetFrGraphData(component.GetFrGraphDataRequest) returns (storage.Reply);
     */
    getFrGraphData(input: GetFrGraphDataRequest, options?: RpcOptions): UnaryCall<GetFrGraphDataRequest, Reply> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetFrGraphDataRequest, Reply>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetFIRGraphData(component.GetFIRGraphDataRequest) returns (storage.Reply);
     */
    getFIRGraphData(input: GetFIRGraphDataRequest, options?: RpcOptions): UnaryCall<GetFIRGraphDataRequest, Reply> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetFIRGraphDataRequest, Reply>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetOnceIntegralGraphData(component.GetOnceIntegralGraphDataRequest) returns (storage.Reply);
     */
    getOnceIntegralGraphData(input: GetOnceIntegralGraphDataRequest, options?: RpcOptions): UnaryCall<GetOnceIntegralGraphDataRequest, Reply> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetOnceIntegralGraphDataRequest, Reply>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetTwiceIntegralGraphData(component.GetTwiceIntegralGraphDataRequest) returns (storage.Reply);
     */
    getTwiceIntegralGraphData(input: GetTwiceIntegralGraphDataRequest, options?: RpcOptions): UnaryCall<GetTwiceIntegralGraphDataRequest, Reply> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetTwiceIntegralGraphDataRequest, Reply>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetOnceDifferentialGraphData(component.GetOnceDifferentialGraphDataRequest) returns (storage.Reply);
     */
    getOnceDifferentialGraphData(input: GetOnceDifferentialGraphDataRequest, options?: RpcOptions): UnaryCall<GetOnceDifferentialGraphDataRequest, Reply> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetOnceDifferentialGraphDataRequest, Reply>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetEnvelopGraphData(component.GetEnvelopGraphDataRequest) returns (storage.Reply);
     */
    getEnvelopGraphData(input: GetEnvelopGraphDataRequest, options?: RpcOptions): UnaryCall<GetEnvelopGraphDataRequest, Reply> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetEnvelopGraphDataRequest, Reply>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetEnvelopSpecGraphData(component.GetEnvelopSpecGraphDataRequest) returns (storage.Reply);
     */
    getEnvelopSpecGraphData(input: GetEnvelopSpecGraphDataRequest, options?: RpcOptions): UnaryCall<GetEnvelopSpecGraphDataRequest, Reply> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetEnvelopSpecGraphDataRequest, Reply>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetTwiceDifferentialGraphData(component.GetTwiceDifferentialGraphDataRequest) returns (storage.Reply);
     */
    getTwiceDifferentialGraphData(input: GetTwiceDifferentialGraphDataRequest, options?: RpcOptions): UnaryCall<GetTwiceDifferentialGraphDataRequest, Reply> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetTwiceDifferentialGraphDataRequest, Reply>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: QueryWholePeriodDatas(component.GetGraphDatasRequest) returns (storage.Reply);
     */
    queryWholePeriodDatas(input: GetGraphDatasRequest, options?: RpcOptions): UnaryCall<GetGraphDatasRequest, Reply> {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetGraphDatasRequest, Reply>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: QueryLatestSpectralDatas(component.GetGraphDatasRequest) returns (storage.Reply);
     */
    queryLatestSpectralDatas(input: GetGraphDatasRequest, options?: RpcOptions): UnaryCall<GetGraphDatasRequest, Reply> {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetGraphDatasRequest, Reply>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: QueryBodePlotsDatas(component.GetGraphDatasRequest) returns (storage.Reply);
     */
    queryBodePlotsDatas(input: GetGraphDatasRequest, options?: RpcOptions): UnaryCall<GetGraphDatasRequest, Reply> {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetGraphDatasRequest, Reply>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: QueryPolarDatas(component.GetGraphDatasRequest) returns (storage.Reply);
     */
    queryPolarDatas(input: GetGraphDatasRequest, options?: RpcOptions): UnaryCall<GetGraphDatasRequest, Reply> {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetGraphDatasRequest, Reply>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: QueryWholePeriodAlarmNearestDatas(component.GetGraphNearestDatasRequest) returns (storage.Reply);
     */
    queryWholePeriodAlarmNearestDatas(input: GetGraphNearestDatasRequest, options?: RpcOptions): UnaryCall<GetGraphNearestDatasRequest, Reply> {
        const method = this.methods[12], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetGraphNearestDatasRequest, Reply>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: QueryWholePeriodAlarmPreDatas(component.GetGraphNearestDatasRequest) returns (storage.Reply);
     */
    queryWholePeriodAlarmPreDatas(input: GetGraphNearestDatasRequest, options?: RpcOptions): UnaryCall<GetGraphNearestDatasRequest, Reply> {
        const method = this.methods[13], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetGraphNearestDatasRequest, Reply>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: QueryWholePeriodAlarmNextDatas(component.GetGraphNearestDatasRequest) returns (storage.Reply);
     */
    queryWholePeriodAlarmNextDatas(input: GetGraphNearestDatasRequest, options?: RpcOptions): UnaryCall<GetGraphNearestDatasRequest, Reply> {
        const method = this.methods[14], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetGraphNearestDatasRequest, Reply>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: QueryBodeAlarmDatas(component.GetGraphNearestDatasRequest) returns (storage.Reply);
     */
    queryBodeAlarmDatas(input: GetGraphNearestDatasRequest, options?: RpcOptions): UnaryCall<GetGraphNearestDatasRequest, Reply> {
        const method = this.methods[15], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetGraphNearestDatasRequest, Reply>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: QueryPolarAlarmDatas(component.GetGraphNearestDatasRequest) returns (storage.Reply);
     */
    queryPolarAlarmDatas(input: GetGraphNearestDatasRequest, options?: RpcOptions): UnaryCall<GetGraphNearestDatasRequest, Reply> {
        const method = this.methods[16], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetGraphNearestDatasRequest, Reply>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: QueryWholePeriodFirstData(component.GetGraphFirstDatasRequest) returns (storage.Reply);
     */
    queryWholePeriodFirstData(input: GetGraphFirstDatasRequest, options?: RpcOptions): UnaryCall<GetGraphFirstDatasRequest, Reply> {
        const method = this.methods[17], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetGraphFirstDatasRequest, Reply>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: QueryAxisPositionDatas(component.GetParamTwoPointDatasRequest) returns (storage.Reply);
     */
    queryAxisPositionDatas(input: GetParamTwoPointDatasRequest, options?: RpcOptions): UnaryCall<GetParamTwoPointDatasRequest, Reply> {
        const method = this.methods[18], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetParamTwoPointDatasRequest, Reply>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: QueryAxisTrailDatas(component.GetParamTwoPointDatasRequest) returns (storage.Reply);
     */
    queryAxisTrailDatas(input: GetParamTwoPointDatasRequest, options?: RpcOptions): UnaryCall<GetParamTwoPointDatasRequest, Reply> {
        const method = this.methods[19], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetParamTwoPointDatasRequest, Reply>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: QueryAxisPosAlarmDatas(component.GetParamTwoPointNearestDatasRequest) returns (storage.Reply);
     */
    queryAxisPosAlarmDatas(input: GetParamTwoPointNearestDatasRequest, options?: RpcOptions): UnaryCall<GetParamTwoPointNearestDatasRequest, Reply> {
        const method = this.methods[20], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetParamTwoPointNearestDatasRequest, Reply>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: QueryAxisTrailAlarmNearestDatas(component.GetParamTwoPointNearestDatasRequest) returns (storage.Reply);
     */
    queryAxisTrailAlarmNearestDatas(input: GetParamTwoPointNearestDatasRequest, options?: RpcOptions): UnaryCall<GetParamTwoPointNearestDatasRequest, Reply> {
        const method = this.methods[21], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetParamTwoPointNearestDatasRequest, Reply>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: QueryAxisTrailAlarmPreDatas(component.GetParamTwoPointNearestDatasRequest) returns (storage.Reply);
     */
    queryAxisTrailAlarmPreDatas(input: GetParamTwoPointNearestDatasRequest, options?: RpcOptions): UnaryCall<GetParamTwoPointNearestDatasRequest, Reply> {
        const method = this.methods[22], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetParamTwoPointNearestDatasRequest, Reply>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: QueryAxisTrailAlarmNextDatas(component.GetParamTwoPointNearestDatasRequest) returns (storage.Reply);
     */
    queryAxisTrailAlarmNextDatas(input: GetParamTwoPointNearestDatasRequest, options?: RpcOptions): UnaryCall<GetParamTwoPointNearestDatasRequest, Reply> {
        const method = this.methods[23], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetParamTwoPointNearestDatasRequest, Reply>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: QueryAxisTrailFirstData(component.GetParamTwoPointFirstDatasRequest) returns (storage.Reply);
     */
    queryAxisTrailFirstData(input: GetParamTwoPointFirstDatasRequest, options?: RpcOptions): UnaryCall<GetParamTwoPointFirstDatasRequest, Reply> {
        const method = this.methods[24], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetParamTwoPointFirstDatasRequest, Reply>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: QueryTorvibDatas(component.GetTorvibGraphDatasRequest) returns (storage.Reply);
     */
    queryTorvibDatas(input: GetTorvibGraphDatasRequest, options?: RpcOptions): UnaryCall<GetTorvibGraphDatasRequest, Reply> {
        const method = this.methods[25], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetTorvibGraphDatasRequest, Reply>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: QueryHistoryHealthDatasAsync(component.HistoryHealthDatasRequest) returns (storage.Reply);
     */
    queryHistoryHealthDatasAsync(input: HistoryHealthDatasRequest, options?: RpcOptions): UnaryCall<HistoryHealthDatasRequest, Reply> {
        const method = this.methods[26], opt = this._transport.mergeOptions(options);
        return stackIntercept<HistoryHealthDatasRequest, Reply>("unary", this._transport, method, opt, input);
    }
}
