import $ from "jquery";
import { PluginMode } from "@core/graphs/webcharts/Dh-webchart-models";
import { CursorElement } from "@core/graphs/plugins/Dh-plugin-cursor-base";
import { DoubleCursorPlugin } from "./Dh-plugin-double-cursor";

export class TorvibDoubleCursorPlugin extends DoubleCursorPlugin {
    constructor(mode) {
        super(mode);
        this.maxData = null;
        this.hasInteraction = false; //是否有交互的标识
    }
    GetMaxData(data) {
        if (data?.length !== 2) return;
        const startIndex = data[0].XIndex < data[1].XIndex ? data[0].XIndex : data[1].XIndex;
        const endIndex = data[0].XIndex < data[1].XIndex ? data[1].XIndex : data[0].XIndex;

        let serieses = this.Owner.ShapeOperator.Data;
        let legend = this.Owner.PluginManager.GetPlugin(PluginMode.Statistics);
        let seriesData = null;
        $.each(serieses, function (i, series) {
            if (series.PointId == legend.SelectedItem) {
                seriesData = series;
            }
        });

        if (!seriesData?.Points?.length > 0) return;

        let maxData = seriesData.Points[startIndex];
        for (let i = startIndex; i < endIndex; i++) {
            if (seriesData.Points[i].Y >= maxData.Y) {
                maxData = seriesData.Points[i];
            }
        }

        return maxData;
    }
    UpdatePosition(data) {
        this.maxData = this.GetMaxData(data);
        if (this.hasInteraction) {
            typeof this.Entity.onCursorPositionChange === 'function' && this.Entity.onCursorPositionChange(this.maxData);
            this.hasInteraction = false;
        }
        super.UpdatePosition(data);
    }
    KeyLeftPress() {
        this.hasInteraction = true;
        super.KeyLeftPress();
    }
    KeyRightPress() {
        this.hasInteraction = true;
        super.KeyRightPress();
    }
    OnMouseMove(e) {
        if (this.IsMouseDown || this.Cursors?.some(cursor => cursor.IsMouseDown)) {
            this.hasInteraction = true;
        }
        return super.OnMouseMove(e);
    }
}
