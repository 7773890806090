import { Point, LineMode } from "@core/graphs/webcharts/Dh-webchart-models";
import { TableCoordinateSystem } from "@core/graphs/coords/Dh-coordsystem-base";

export class SpectrumTableCoordinateSystem extends TableCoordinateSystem {
    constructor(id) {
        super(id);
        this.XAxisLogType = 1;
        this.YAxisLogType = 1;
        this.YReferenceValue = 1;
        this.YReferenceValueType = 6;
        this.XMinLog = 0;
        this.XMaxLog = 0;
        this.XMinLogInt = 0;
        this.XDiff = 1;
        this.YMinLog = 0;
        this.YMaxLog = 0;
        this.YMinLogInt = 0;
        this.YDiff = 1;
        this.XRulePositions = new Array();
        this.YRulePositions = new Array();
        this.ShowXUnit = true;
    }
    DrawXAxisText() {
        if (this.XAxisLogType == 2) {
            this.DrawLogXAxisText();
        } else {
            super.DrawXAxisText();
        }
    }
    DrawYAxisText() {
        if (this.YAxisLogType == 2) {
            this.DrawLogYAxisText();
        } else {
            super.DrawYAxisText();
        }
    }
    DrawXCrossLine() {
        if (this.XAxisLogType == 2) {
            this.DrawLogXCrossLine();
        } else {
            super.DrawXCrossLine();
        }
    }
    DrawLogXAxisText() {
        const info = this.Owner.Information;
        const rect = info.ShapeContainerRect;
        const columns = this.Columns;
        if (this.ShowXText) {
            //绘制x轴方向文字
            const xTexts = this.XTexts;
            let textSize;
            if (xTexts?.length > 0) {
                let maxTextWidth = Math.max(
                    this.CeilingSize(this.GetXTextSize(xTexts[0])).Width,
                    this.CeilingSize(this.GetXTextSize(xTexts[xTexts.length - 1])).Width
                );
                let bestCol = Math.floor((rect.Width - maxTextWidth - 60) / maxTextWidth);
                bestCol = bestCol < 1 ? 1 : bestCol;
                let step = Math.ceil(columns / bestCol) == 0 ? 1 : Math.ceil(columns / bestCol);
                step = step > 1 ? step + (step % 2) : 1;
                let temp = 0;
                textSize = this.CeilingSize(this.GetXTextSize(xTexts[0]));
                temp = this.GetXOffsetColumn(0, columns);
                let firstLeft = rect.Left - textSize.Width / 2 + temp < 0 ? 5 : rect.Left - textSize.Width / 2 + temp;
                this.DrawXText(xTexts[0], firstLeft, rect.Height + rect.Top + textSize.Height / 2 + this.RuleLength, this.XAxisTextColor, this.XAxisTextStyle);
                for (let r = step; r < columns; r = r + step) {
                    textSize = this.CeilingSize(this.GetXTextSize(xTexts[r]));
                    temp = this.GetXOffsetColumn(r, columns);
                    if (columns - r >= step) {
                        this.DrawXText(
                            xTexts[r],
                            this.XRulePositions[r - 1] - textSize.Width / 2 + temp,
                            rect.Height + rect.Top + textSize.Height / 2 + this.RuleLength,
                            this.XAxisTextColor,
                            this.XAxisTextStyle
                        );
                    }
                }
                textSize = this.CeilingSize(this.GetXTextSize(xTexts[columns]));
                temp = this.GetXOffsetColumn(columns, columns);
                // 最后一段间隔判断, 间隔不够将右移最后一个刻度文字位置
                const lastStepWidth = rect.Left + rect.Width - textSize.Width - temp - (this.XRulePositions[columns - step - 1] - textSize.Width / 2 + temp);
                const lastTextWidth = this.GetXTextSize(xTexts[xTexts.length - 1]).Width;
                if (lastStepWidth <= lastTextWidth) temp -= (lastTextWidth - lastStepWidth + 5);
                this.DrawXText(
                    xTexts[columns],
                    rect.Left + rect.Width - textSize.Width - temp,
                    rect.Height + rect.Top + textSize.Height / 2 + this.RuleLength,
                    this.XAxisTextColor,
                    this.XAxisTextStyle
                );
            }
        }
    }
    DrawLogYAxisText() {
        const info = this.Owner.Information;
        const rect = info.ShapeContainerRect;
        const rows = this.Rows;
        const positions = new Array();
        if (this.ShowYText) {
            //绘制y轴方向文字
            const yTexts = this.YTexts;
            let textSize;
            for (let i = 0; i < yTexts.length; i++) {
                var position = {
                    Size: this.CeilingSize(this.GetTextSize(yTexts[i], this.YAxisTextStyle)),
                };
                if (i == 0) {
                    position.Pos = rect.Top + rect.Height;
                } else if (i == rows) {
                    position.Pos = rect.Top + position.Size.Width / 2;
                } else {
                    position.Pos = this.YRulePositions[i - 1] + position.Size.Width / 2;
                }
                positions.push(position);
            }
            let maxTextWidth = Math.max(this.CeilingSize(this.GetTextSize(yTexts[0])).Width, this.CeilingSize(this.GetTextSize(yTexts[yTexts.length - 1])).Width);
            let bestRow = Math.floor((rect.Height - 60) / maxTextWidth);
            bestRow = bestRow < 1 ? 1 : bestRow;
            let step = Math.ceil(rows / bestRow) == 0 ? 1 : Math.ceil(rows / bestRow);
            step = step > 1 ? step + (step % 2) : 1;
            for (let r = 0; r <= rows; r = r + step) {
                if (r != 0 && r != rows) {
                    if (rows - r >= step && positions[r].Pos < positions[r - 1].Pos - positions[r - 1].Size.Width) {
                        if (r == rows - 1) {
                            if (positions[r].Pos - positions[r].Size.Width < positions[r + 1].Pos) continue;
                        }
                        this.DrawYText(yTexts[r], rect.Left - positions[r].Size.Height, positions[r].Pos, this.YAxisTextColor, this.YAxisTextStyle);
                    }
                }
            }

            textSize = this.CeilingSize(this.GetTextSize(yTexts[0], this.YAxisTextStyle));
            this.DrawYText(yTexts[rows], rect.Left - textSize.Height, rect.Top + textSize.Width / 2, this.YAxisTextColor, this.YAxisTextStyle);
            textSize = this.CeilingSize(this.GetTextSize(yTexts[rows], this.YAxisTextStyle));
            this.DrawYText(yTexts[0], rect.Left - textSize.Height, rect.Top + rect.Height, this.YAxisTextColor, this.YAxisTextStyle);
        }
    }
    DrawLogXCrossLine() {
        const info = this.Owner.Information;
        const rect = info.ShapeContainerRect;
        if (this.ShowVerCross) {
            this.drawApi.BeginPath();
            if (this.VerCrossStyle == LineMode.Dash) {
                this.drawApi.SetLineDash([5]);
            }
            this.drawApi.SetLineDash([10, 10]);
            const minLogInt = Math.floor(this.XMinLog);
            const end = Math.pow(10, this.XMaxLog);
            let base = Math.pow(10, minLogInt);
            for (let i = 0; i < this.Columns; i++) {
                base = Math.pow(10, minLogInt + i);
                for (let j = 2; j <= 10; j++) {
                    let tmp = base * j;
                    if (tmp > base && tmp < end) {
                        var x = this.GetXPos(tmp);
                        this.drawApi.VerticalLeftMoveTo(x, rect.Top);
                        this.drawApi.VerticalLeftLineTo(x, rect.Top + rect.Height);
                    }
                }
            }
            this.drawApi.Stroke(this.VerCrossColor, 1);
        }
    }
    GetXPos(x) {
        const info = this.Owner.Information;
        const rect = info.ShapeContainerRect;
        if (this.XAxisLogType == 2) {
            if (x == 0) return rect.Left;
            const columnUnit = rect.Width / (this.XMaxLog - this.XMinLogInt);
            return rect.Left + (Math.log10(x) - this.XMinLogInt) * columnUnit;
        } else {
            return rect.Left + (x - info.CoordinateInfo.XMin) / info.XUnit;
        }
    }
    GetYPos(y) {
        const info = this.Owner.Information;
        const rect = info.ShapeContainerRect;
        if (this.YAxisLogType == 2) {
            if (y == 0) return rect.Top + rect.Height;
            const rowUnit = rect.Height / (this.YMaxLog - this.YMinLogInt);
            let logOffsetY = rect.Top + rect.Height - (Math.log10(y) - this.YMinLogInt) * rowUnit;
            return logOffsetY > rect.Top + rect.Height ? rect.Top + rect.Height : logOffsetY;
        } else {
            return rect.Top + rect.Height - (y - info.CoordinateInfo.YMin) / info.YUnit;
        }
    }
    GetQueryAction(p) {
        const info = this.Owner.Information;
        const rect = info.ShapeContainerRect;
        if (this.XAxisLogType == 2) {
            if (p.X == 0) return 0;
            const columnUnit = rect.Width / (this.XMaxLog - this.XMinLogInt);
            return (Math.log10(p.X) - this.XMinLogInt) * columnUnit;
        } else {
            return super.GetQueryAction(p);
        }
    }
    GetPoints(series) {
        if (series?.Points != null) {
            const result = new Array();
            for (let i = 0; i < series.Points.length; i++) {
                const d = series.Points[i];
                const x = this.GetXPos(d.X);
                const y = this.GetYPos(d.Y);
                result.push(new Point(x, y));
            }
            return result;
        }
    }
    GetOffsetX(x) {
        if (this.XAxisLogType == 2) {
            return this.GetLogOffsetX(x);
        } else {
            return super.GetOffsetX(x);
        }
    }
    GetLogOffsetX(x) {
        const info = this.Owner.Information;
        const shapeRect = info.ShapeContainerRect;
        const columnUnit = shapeRect.Width / (this.XMaxLog - this.XMinLogInt);
        if (x == 0)
            return 0;
        return (Math.log10(x) - this.XMinLogInt) * columnUnit;
    }
    DrawYCrossLine() {
        if (this.YAxisLogType == 2) {
            this.DrawLogYCrossLine();
        } else {
            super.DrawYCrossLine();
        }
    }
    DrawLogYCrossLine() {
        const info = this.Owner.Information;
        const rect = info.ShapeContainerRect;
        if (this.ShowHorCross) {
            this.drawApi.BeginPath();
            if (this.HorCrossStyle == LineMode.Dash) {
                this.drawApi.SetLineDash([5]);
            }
            this.drawApi.SetLineDash([10, 10]);
            const minLogInt = Math.floor(this.YMinLog);
            let base = Math.pow(10, minLogInt);
            const start = Math.pow(10, this.YMinLog);
            const end = Math.pow(10, this.YMaxLog);
            for (let i = 0; i < this.Rows; i++) {
                base = Math.pow(10, minLogInt + i);
                for (let j = 2; j <= 10; j++) {
                    let tmp = base * j;
                    if (tmp > start && tmp < end) {
                        const y = this.GetYPos(tmp);
                        this.drawApi.HorizontalTopMoveTo(rect.Left, y);
                        this.drawApi.HorizontalTopLineTo(rect.Left + rect.Width, y);
                    }
                }
            }
            this.drawApi.Stroke(this.VerCrossColor, 1);
        }
    }
    GetYScaleText(max, min, num) {
        if (this.YAxisLogType == 2) {
            return this.GetLogYScaleText(max, min, num);
        } else {
            return super.GetYScaleText(max, min, num);
        }
    }
    GetLogYScaleText(max, min, num) {
        const result = {
            Min: this.FormatYText(Math.pow(max, 10)),
            Max: this.FormatYText(Math.pow(min, 10)),
            YTexts: [this.FormatYText(Math.pow(max, 10)), this.FormatYText(Math.pow(min, 10))],
        };
        if (num <= 1 || max < min) return result;
        let step = num - 1;
        result.YTexts = new Array();

        for (let i = 0; i <= num; i++) {
            result.YTexts.push(this.FormatYText(Math.pow(min + i * step, 10)));
        }
        return result;
    }
    DrawYRule() {
        if (this.YAxisLogType == 2) {
            this.DrawLogYRule();
        } else {
            super.DrawYRule();
        }
    }
    DrawLogYRule() {
        const info = this.Owner.Information;
        this.YRulePositions = new Array();
        const rect = info.ShapeContainerRect;
        if (this.ShowYRule) {
            const minLogInt = Math.floor(this.YMinLog);
            let base = Math.pow(10, minLogInt);
            const start = Math.pow(10, this.YMinLog);
            const end = Math.pow(10, this.YMaxLog);
            for (let i = 0; i < this.Rows; i++) {
                base = Math.pow(10, minLogInt + i);
                let tmp = base * 10;
                if (tmp > start && tmp < end) {
                    var y = this.GetYPos(tmp);
                    this.YRulePositions.push(y);
                    this.drawApi.HorizontalTopMoveTo(rect.Left - this.RuleLength, y);
                    this.drawApi.HorizontalTopLineTo(rect.Left, y);
                }
            }
            this.YRulePositions.push(rect.Top);
            this.drawApi.HorizontalTopMoveTo(rect.Left - this.RuleLength, rect.Top);
            this.drawApi.HorizontalTopLineTo(rect.Left, rect.Top);
        }
    }
    DrawXRule() {
        if (this.XAxisLogType == 2) {
            this.DrawLogXRule();
        } else {
            super.DrawXRule();
        }
    }
    DrawLogXRule() {
        const info = this.Owner.Information;
        this.XRulePositions = new Array();
        const rect = info.ShapeContainerRect;
        if (this.ShowXRule) {
            let base = Math.pow(10, this.XMinLogInt);
            let start = Math.pow(10, this.XMinLog);
            let end = Math.pow(10, this.XMaxLog);
            for (let i = 0; i < this.Columns; i++) {
                base = Math.pow(10, this.XMinLogInt + i);
                let tmp = base * 10;
                if (tmp > start && tmp < end) {
                    var x = this.GetXPos(tmp);
                    this.XRulePositions.push(x);
                    this.drawApi.VerticalLeftMoveTo(x, rect.Height + rect.Top);
                    this.drawApi.VerticalLeftLineTo(x, rect.Height + rect.Top + this.RuleLength);
                }
            }
            this.XRulePositions.push(rect.Left + rect.Width);
            this.drawApi.VerticalLeftMoveTo(rect.Left + rect.Width, rect.Height + rect.Top);
            this.drawApi.VerticalLeftLineTo(rect.Left + rect.Width, rect.Height + rect.Top + this.RuleLength);
        }
    }
    MeasureRows() {
        if (this.YAxisLogType == 2) {
            this.MeasureLogRows();
        } else {
            super.MeasureRows();
        }
    }
    MeasureLogRows() {
        const info = this.Owner.Information;
        const max = info.CoordinateInfo.YMax == 0 ? 1e3 : info.CoordinateInfo.YMax;
        this.YMaxLog = Math.log10(max);
        const maxLogInt = Math.floor(Math.log10(max));
        let min = info.CoordinateInfo.YMin
        if (info.CoordinateInfo.YMin <= 1e-1) {
            if (max >= 1e-1) {
                min = 1e-1;
            } else if (info.CoordinateInfo.YMin == 0) {
                min = max / 100
            }
        }
        this.YMinLog = Math.log10(min);
        this.YMinLogInt = Math.floor(Math.log10(min));
        this.Rows = maxLogInt - this.YMinLogInt;
        if (maxLogInt != this.YMaxLog) {
            this.Rows++;
        }
        this.YTexts = new Array();
        this.YTexts.push(this.FormatYText(0));
        for (let u = 1; u < this.Rows; u++) {
            this.YTexts.push(this.FormatYText(Math.pow(10, this.YMinLogInt + u)));
        }
        this.YTexts.push(this.FormatYText(max));
    }
    GetFitNumber(number) {
        const fitNumbers = [2, 3, 4, 5];
        for (var i = fitNumbers.length - 1; i >= 0; i--) {
            if (number % fitNumbers[i] == 0) return fitNumbers[i];
        }
    }
    MeasureColumns() {
        if (this.XAxisLogType == 2) {
            this.MeasureLogColumns();
        } else {
            super.MeasureColumns();
        }
    }
    MeasureLogColumns() {
        const info = this.Owner.Information;
        const data = this.Owner.ShapeOperator.Data;
        let min = info.CoordinateInfo.XMin <= 1E-1 ? 1E-1 : info.CoordinateInfo.XMin;
        if (data && data.length > 0 && data[0].Points && data[0].Points.length >= 1) {
            min = data[0].Points[1].X;
        }
        let max = info.CoordinateInfo.XMax == 0 ? 1E3 : info.CoordinateInfo.XMax;
        this.XMaxLog = Math.log10(max);
        let maxLogInt = Math.floor(Math.log10(max));
        this.XMinLog = Math.log10(min);
        this.XMinLogInt = Math.floor(Math.log10(min));
        /** Math.log10(min)为整数时，应取小于其的整数，否则会导致x=0和xmin两个坐标重合 */
        this.XMinLog === this.XMinLogInt && (this.XMinLogInt -= 1);
        this.Columns = maxLogInt - this.XMinLogInt;
        if (maxLogInt != this.XMaxLog) {
            this.Columns++;
        }
        this.XTexts = new Array();
        this.XTexts.push(0);
        for (let u = 1; u < this.Columns; u++) {
            this.XTexts.push(this.FormatXText(Math.pow(10, this.XMinLogInt + u * 1)));
        }
        this.XTexts.push(this.FormatXText(max));
    }
    GetYValue(yoffset) {
        if (this.YAxisLogType == 2) {
            return this.GetLogYValue(yoffset);
        } else {
            return super.GetYValue(yoffset);
        }
    }
    GetLogYValue(yoffset) {
        const info = this.Owner.Information;
        const rect = info.ShapeContainerRect;
        const rowUnit = rect.Height / (this.YMaxLog - this.YMinLog);
        return Math.pow((rect.Top + rect.Height - yoffset) / rowUnit + this.YMinLog, 10);
    }
    GetXValue(xoffset) {
        if (this.XAxisLogType == 2) {
            return this.GetLogXValue(xoffset);
        } else {
            return super.GetXValue(xoffset);
        }
    }
    GetLogXValue(xoffset) {
        const info = this.Owner.Information;
        const rect = info.ShapeContainerRect;
        const columnUnit = rect.Width / (this.XMaxLog - this.XMinLog);
        return Math.pow((xoffset - rect.Left) / columnUnit + this.XMinLog, 10);
    }
    GetYOffset(yvalue) {
        let info = this.Owner.Information;
        let rect = info.ShapeContainerRect;
        return this.GetYPos(yvalue) - rect.Top;
    }
    GetXOffset(xvalue) {
        return this.GetXPos(xvalue);
    }
}
