import { getDeepClone } from "@common/js/utils";
import axios from "@common/js/axios";
import { BlockDataRealContext } from "./blockDataRealContext";
import { EnumQueryDataType } from "@components/js/utils/model";
import { ContractTypes } from "@common/js/enum";
import _ from "lodash";
import { ChartRealType, ClientRealContext } from "./clientRealContext";
import { GrpcApiContext } from "@grpc/grpcApi";
import dayjs from "dayjs";

export class EigenDataRealContext extends BlockDataRealContext {
    constructor() {
        super();
    }
    getContractType() {
        return ContractTypes.Eigen;
    }
    async registerPoints(requestId, registerPoints) {
        const points = new Map();
        if (registerPoints) {
            for (const registerPoint of registerPoints) {
                const point = await this.pointCacheContext.get(registerPoint.PointId) || registerPoint;
                const valueList = JSON.parse(registerPoint.ValueList);
                const eigenList = point.EigenTypes;
                let eigens = new Map();
                if (points.has(registerPoint.PointId)) eigens = points.get(registerPoint.PointId).Eigens;
                valueList.forEach(item => {
                    const e = eigenList.find(t => t.Key == item.Id);
                    if (e) {
                        eigens.set(item.Id, {
                            Id: item.Id,
                            Name: e.Value,
                            Unit: e.Unit || point.UnitStr
                        });
                    }
                });
                points.set(registerPoint.PointId, {
                    PointId: registerPoint.PointId,
                    Name: point.Name,
                    Unit: point.UnitStr,
                    FullValue: point.FullValue,
                    AlgorithmType: point.SignalType,
                    IsPatrol: point.IsPatrol,
                    Eigens: eigens,
                });
            }
        }
        this.graphRegisterPoints.set(requestId, points);
        this.pointChangeGraphs.set(requestId, _.cloneDeep(points));
        this.updateRegisterPointCollection();
        return true;
    }
    updateRegisterPointCollection() {
        const registerPointCollection = new Map();
        this.graphRegisterPoints.forEach((registerGraph) => {
            registerGraph.forEach((registerPoint) => {
                if (!registerPointCollection.has(registerPoint.PointId)) {
                    registerPointCollection.set(registerPoint.PointId, {
                        Id: registerPoint.PointId,
                        Name: registerPoint.Name,
                        IsPatrol: registerPoint.IsPatrol,
                        FullValue: registerPoint.FullValue,
                        Unit: registerPoint.Unit,
                        AlgorithmType: registerPoint.SignalType,
                        Eigens: new Array(),
                    });
                }
                const point = registerPointCollection.get(registerPoint.PointId);
                for (const eigen of registerPoint.Eigens.values()) {
                    if (point.Eigens.findIndex((r) => r.Id == eigen.Id) < 0) {
                        point.Eigens.push(eigen);
                    }
                }
            });
        });
        this.registerPointCollection = registerPointCollection;
        this.sendRegisterPointsToClinet();
    }
    sendRegisterPointsToClinet() {
        if (this.getConnectionIds().length == 0) return;
        this.debounce.run(() => {
            const registerGraphPoints = this.updateHistoryChangeGraphs();
            const registerPointInfos = this.getRegisterPoints();
            this.mergeRegisterPoints(ClientRealContext.GetInstance(ChartRealType.TrendEigen).getRegisterPoints(), registerPointInfos);
            this.getLatestDatas(registerGraphPoints);
            const connectionIds = this.getConnectionIds();
            if (connectionIds.length == 0) return;
            axios({
                method: "post",
                url: "datamanagement/register-eigen-data",
                data: {
                    ConnectionIds: connectionIds,
                    Points: registerPointInfos
                }
            });
        }, 100);
    }
    getRegisterPoints() {
        return this.registerPointCollection.getValues().map((point) => {
            return {
                PointId: point.Id,
                EigenTypes: point.Eigens.map(e => e.Id)
            };
        });
    }
    mergeRegisterPoints(source, target) {
        source.forEach(point => {
            const t = target.find(p => p.PointId == point.PointId);
            if (t) {
                point.EigenTypes.forEach(eigen => {
                    if (t.EigenTypes.findIndex(e => e == eigen) < 0) {
                        t.EigenTypes.push(eigen);
                    }
                });
            } else {
                target.push(point);
            }
        });
    }
    processRegisterData(_) { }
    processWaveData(_) { }
    getCloneData(data) {
        const dataTemp = {
            PointId: data.PointId,
            SampleTime: data.SampleTime,
            SampleFreq: data.SampleFreq,
            Param: null,
            Eigens: [],
        };
        if (data.Param) {
            dataTemp.Param = getDeepClone(data.Param);
        }
        if (data.Eigens) {
            dataTemp.Eigens = data.Eigens;
        }
        return dataTemp;
    }
    getChangePoints(registerGraphPoints) {
        const pointInfos = new Map();
        registerGraphPoints.forEach(graph => {
            graph.forEach(point => {
                if (!pointInfos.has(point.PointId)) {
                    pointInfos.set(point.PointId, _.cloneDeep(point));
                } else {
                    point.Eigens.forEach(eigen => {
                        if (!pointInfos.get(point.PointId).Eigens.has(eigen)) {
                            pointInfos.get(point.PointId).Eigens.set(eigen.Id, eigen);
                        }
                    })
                }
            })
        });
        return [...pointInfos.values()];
    }
    async getLatestDatas(registerGraphPoints) {
        const pointInfos = this.getChangePoints(registerGraphPoints);
        if (pointInfos.length == 0) return;
        const registerPointInfos = pointInfos.map((point) => {
            return {
                PointId: point.PointId,
                EigenTypes: [...point.Eigens.keys()]
            };
        });
        const alarmRules = await this.getAlarmRules(registerPointInfos);
        const datas = await this.queryEigenData(registerPointInfos);
        const observerParams = await this.getObserverParams(pointInfos.filter(p => p.IsPatrol).map(p => p.PointId));
        const temp = pointInfos.map(pointInfo => {
            const data = datas.find(d => d.PointId == pointInfo.PointId);
            const point = {
                PointId: pointInfo.PointId,
                Param: getDeepClone(pointInfo),
                Eigens: [...pointInfo.Eigens.values()].map((eigen) => {
                    return {
                        EigenType: eigen.Id,
                        Value: data?.EignData.find(e => e.EigenType == eigen.Id)?.Value || 0,
                        Unit: eigen.Unit,
                        EigenName: eigen.Name,
                    }
                }),
                SampleTime: Number(new Date()) * 10000
            }
            point.Param.AlarmRuler = alarmRules.find(a => a.EntityId == pointInfo.PointId);
            if (pointInfo.IsPatrol) {
                point.Param.Extensions = observerParams.find(p => p.Id == pointInfo.PointId)?.Extensions;
                point.Param.IsObserver = point.Param.Extensions != null;
            }
            return point;
        });
        if (temp.length > 0) {
            this.processRegisterDataReceived(temp, registerGraphPoints);
        }
    }
    async queryEigenData(registerPointInfos) {
        const res = await axios({
            method: "post",
            url: "datamanagement/query-latest-eigen-data",
            data: {
                points: registerPointInfos
            }
        });
        if (res.IsSuccess && res.Result?.length > 0) {
            return res.Result;
        }
        return [];
    }
    async getAlarmRules(registerPointInfos) {
        const alarmRules = new Array();
        const alarmRuleRes = await axios({
            method: "post",
            url: `Alarm/GetPointAmplitudeAlarmRulers`,
            data: registerPointInfos.map(p => p.PointId),
        });
        if (alarmRuleRes.IsSuccess && alarmRuleRes.Result?.length > 0) {
            alarmRules.push(...alarmRuleRes.Result);
        }
        return alarmRules;
    }
    async getObserverParams(ids) {
        const observerParams = new Array();
        if (ids.length == 0) return observerParams;
        const res = await axios({
            method: "post",
            url: "point/observer-param",
            data: {
                PointIds: ids
            }
        });
        if (res.IsSuccess && res.Result?.length > 0) {
            res.Result.forEach(p => {
                observerParams.push(p);
            });
        }
        return observerParams;
    }
}

export class TrendEigenDataRealContext extends EigenDataRealContext {
    constructor() {
        super();
        this.aheadDataCallback = new Map();
        this.changeDatas = new Map();
        this.client = GrpcApiContext.GetInstance();
    }
    registerPoints(requestId, registerPoints, changeData) {
        if (changeData) this.changeDatas.set(requestId, changeData);
        return super.registerPoints(requestId, registerPoints);
    }
    registerDataCalback(requestId, realCallback, aheadCallback) {
        super.registerDataCalback(requestId, realCallback);
        this.aheadDataCallback.set(requestId, aheadCallback);
    }
    removeDataCalback(requestId) {
        this.aheadDataCallback.delete(requestId);
        super.removeDataCalback(requestId);
    }
    sendRegisterPointsToClinet() {
        if (this.getConnectionIds().length == 0) return;
        this.debounce.run(() => {
            const registerGraphPoints = this.updateHistoryChangeGraphs();
            const registerPointInfos = this.getRegisterPoints();
            this.mergeRegisterPoints(ClientRealContext.GetInstance(ChartRealType.Eigen).getRegisterPoints(), registerPointInfos);
            const connectionIds = this.getConnectionIds();
            if (connectionIds.length == 0) return;
            axios({
                method: "post",
                url: "datamanagement/register-eigen-data",
                data: {
                    ConnectionIds: connectionIds,
                    Points: registerPointInfos
                }
            }).then(async (res) => {
                if (res.IsSuccess) {
                    await this.processRegisterData([...this.registerPointCollection.values()], registerGraphPoints);
                    this.getAheadData();
                }
            });
        }, 100);
    }
    async processRegisterData(pointInfos, registerGraphPoints) {
        const observerParams = await this.getObserverParams(pointInfos.filter(p => p.IsPatrol).map(p => p.Id));
        let datas = pointInfos.map(pointInfo => {
            const point = {
                PointId: pointInfo.Id,
                Param: getDeepClone(pointInfo),
                Eigens: pointInfo.Eigens.map((eigen) => {
                    return {
                        EigenType: eigen.Id,
                        Unit: eigen.Unit,
                        EigenName: eigen.Name,
                    }
                })
            }
            if (pointInfo.IsPatrol) {
                point.Param.Extensions = observerParams.find(p => p.Id == pointInfo.Id)?.Extensions;
                point.Param.IsObserver = point.Param.Extensions != null;
            }
            return point;
        });
        if (datas.length > 0) {
            this.processRegisterDataReceived(datas, registerGraphPoints);
        }
        datas = null;
    }
    getAheadData() {
        if (this.changeDatas.size == 0) return;
        const changeDatas = _.cloneDeep(this.changeDatas);
        this.changeDatas.clear();
        changeDatas.forEach((graph, requestId) => {
            if (graph.datas?.length > 0 && this.aheadDataCallback.has(requestId)) {
                const condition = {
                    StartTime: graph.startTime,
                    Points: graph.datas.map(point => {
                        return {
                            PointId: point.PointId,
                            EigenTypes: JSON.parse(point.ValueList).map((e) => e.Id)
                        }
                    }),
                }
                this.client.queryLatestEigenDatas(condition)
                    .then((res) => {
                        this.aheadDataCallback.get(requestId)?.(res);
                    });
            }
        });
    }
    getCloneData(data) {
        const dataTemp = super.getCloneData(data);
        dataTemp.AlarmParams = data.AlarmInfo;
        return dataTemp;
    }
}
