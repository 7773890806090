// @generated by protobuf-ts 2.9.1
// @generated from protobuf file "protos/torvibgraphdatas.proto" (package "component", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { DataParamReply } from "./basereply";
import { DspParamReply } from "./basereply";
import { PointParamReply } from "./basereply";
import { DatasReply } from "./basereply";
import { GetPointDataRequest } from "./getdatas";
/**
 * @generated from protobuf message component.GetTorvibGraphDatasRequest
 */
export interface GetTorvibGraphDatasRequest {
    /**
     * @generated from protobuf field: string Time = 1 [json_name = "Time"];
     */
    time: string;
    /**
     * @generated from protobuf field: bool IsExternal = 2 [json_name = "IsExternal"];
     */
    isExternal: boolean;
    /**
     * @generated from protobuf field: repeated storage.GetPointDataRequest Points = 3 [json_name = "Points"];
     */
    points: GetPointDataRequest[];
    /**
     * @generated from protobuf field: float ODSFrequency = 4 [json_name = "ODSFrequency"];
     */
    oDSFrequency: number;
    /**
     * @generated from protobuf field: string AlgorithmParam = 5 [json_name = "AlgorithmParam"];
     */
    algorithmParam: string;
}
/**
 * @generated from protobuf message component.TorvibDataReply
 */
export interface TorvibDataReply {
    /**
     * @generated from protobuf field: int64 SampleTime = 1 [json_name = "SampleTime"];
     */
    sampleTime: bigint;
    /**
     * @generated from protobuf field: float SampleFreq = 2 [json_name = "SampleFreq"];
     */
    sampleFreq: number;
    /**
     * @generated from protobuf field: float Threshold = 3 [json_name = "Threshold"];
     */
    threshold: number;
    /**
     * @generated from protobuf field: storage.DatasReply Datas = 4 [json_name = "Datas"];
     */
    datas?: DatasReply;
}
/**
 * @generated from protobuf message component.TorvibFFTDataReply
 */
export interface TorvibFFTDataReply {
    /**
     * @generated from protobuf field: int32 SampleType = 1 [json_name = "SampleType"];
     */
    sampleType: number;
    /**
     * @generated from protobuf field: int32 DspType = 2 [json_name = "DspType"];
     */
    dspType: number;
    /**
     * @generated from protobuf field: storage.PointParamReply PointParam = 3 [json_name = "PointParam"];
     */
    pointParam?: PointParamReply;
    /**
     * @generated from protobuf field: storage.DspParamReply DspParam = 4 [json_name = "DspParam"];
     */
    dspParam?: DspParamReply;
    /**
     * @generated from protobuf field: storage.DatasReply Datas = 5 [json_name = "Datas"];
     */
    datas?: DatasReply;
}
/**
 * @generated from protobuf message component.TorvibHistoryDataReply
 */
export interface TorvibHistoryDataReply {
    /**
     * @generated from protobuf field: int32 PointId = 1 [json_name = "PointId"];
     */
    pointId: number;
    /**
     * @generated from protobuf field: component.TorvibDataReply Datas = 2 [json_name = "Datas"];
     */
    datas?: TorvibDataReply;
    /**
     * @generated from protobuf field: component.TorvibFFTDataReply FFTDatas = 3 [json_name = "FFTDatas"];
     */
    fFTDatas?: TorvibFFTDataReply;
    /**
     * @generated from protobuf field: float ODSFrequency = 4 [json_name = "ODSFrequency"];
     */
    oDSFrequency: number;
    /**
     * @generated from protobuf field: float ODSValue = 5 [json_name = "ODSValue"];
     */
    oDSValue: number;
    /**
     * @generated from protobuf field: storage.DataParamReply Param = 6 [json_name = "Param"];
     */
    param?: DataParamReply;
}
/**
 * @generated from protobuf message component.GetTorvibDatasReply
 */
export interface GetTorvibDatasReply {
    /**
     * @generated from protobuf field: repeated component.TorvibHistoryDataReply Datas = 1 [json_name = "Datas"];
     */
    datas: TorvibHistoryDataReply[];
}
// @generated message type with reflection information, may provide speed optimized methods
class GetTorvibGraphDatasRequest$Type extends MessageType<GetTorvibGraphDatasRequest> {
    constructor() {
        super("component.GetTorvibGraphDatasRequest", [
            { no: 1, name: "Time", kind: "scalar", jsonName: "Time", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "IsExternal", kind: "scalar", jsonName: "IsExternal", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "Points", kind: "message", jsonName: "Points", repeat: 1 /*RepeatType.PACKED*/, T: () => GetPointDataRequest },
            { no: 4, name: "ODSFrequency", kind: "scalar", jsonName: "ODSFrequency", T: 2 /*ScalarType.FLOAT*/ },
            { no: 5, name: "AlgorithmParam", kind: "scalar", jsonName: "AlgorithmParam", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetTorvibGraphDatasRequest>): GetTorvibGraphDatasRequest {
        const message = { time: "", isExternal: false, points: [], oDSFrequency: 0, algorithmParam: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetTorvibGraphDatasRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTorvibGraphDatasRequest): GetTorvibGraphDatasRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string Time = 1 [json_name = "Time"];*/ 1:
                    message.time = reader.string();
                    break;
                case /* bool IsExternal = 2 [json_name = "IsExternal"];*/ 2:
                    message.isExternal = reader.bool();
                    break;
                case /* repeated storage.GetPointDataRequest Points = 3 [json_name = "Points"];*/ 3:
                    message.points.push(GetPointDataRequest.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* float ODSFrequency = 4 [json_name = "ODSFrequency"];*/ 4:
                    message.oDSFrequency = reader.float();
                    break;
                case /* string AlgorithmParam = 5 [json_name = "AlgorithmParam"];*/ 5:
                    message.algorithmParam = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTorvibGraphDatasRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string Time = 1 [json_name = "Time"]; */
        if (message.time !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.time);
        /* bool IsExternal = 2 [json_name = "IsExternal"]; */
        if (message.isExternal !== false)
            writer.tag(2, WireType.Varint).bool(message.isExternal);
        /* repeated storage.GetPointDataRequest Points = 3 [json_name = "Points"]; */
        for (let i = 0; i < message.points.length; i++)
            GetPointDataRequest.internalBinaryWrite(message.points[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* float ODSFrequency = 4 [json_name = "ODSFrequency"]; */
        if (message.oDSFrequency !== 0)
            writer.tag(4, WireType.Bit32).float(message.oDSFrequency);
        /* string AlgorithmParam = 5 [json_name = "AlgorithmParam"]; */
        if (message.algorithmParam !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.algorithmParam);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message component.GetTorvibGraphDatasRequest
 */
export const GetTorvibGraphDatasRequest = new GetTorvibGraphDatasRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TorvibDataReply$Type extends MessageType<TorvibDataReply> {
    constructor() {
        super("component.TorvibDataReply", [
            { no: 1, name: "SampleTime", kind: "scalar", jsonName: "SampleTime", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "SampleFreq", kind: "scalar", jsonName: "SampleFreq", T: 2 /*ScalarType.FLOAT*/ },
            { no: 3, name: "Threshold", kind: "scalar", jsonName: "Threshold", T: 2 /*ScalarType.FLOAT*/ },
            { no: 4, name: "Datas", kind: "message", jsonName: "Datas", T: () => DatasReply }
        ]);
    }
    create(value?: PartialMessage<TorvibDataReply>): TorvibDataReply {
        const message = { sampleTime: 0n, sampleFreq: 0, threshold: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TorvibDataReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TorvibDataReply): TorvibDataReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 SampleTime = 1 [json_name = "SampleTime"];*/ 1:
                    message.sampleTime = reader.int64().toBigInt();
                    break;
                case /* float SampleFreq = 2 [json_name = "SampleFreq"];*/ 2:
                    message.sampleFreq = reader.float();
                    break;
                case /* float Threshold = 3 [json_name = "Threshold"];*/ 3:
                    message.threshold = reader.float();
                    break;
                case /* storage.DatasReply Datas = 4 [json_name = "Datas"];*/ 4:
                    message.datas = DatasReply.internalBinaryRead(reader, reader.uint32(), options, message.datas);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TorvibDataReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 SampleTime = 1 [json_name = "SampleTime"]; */
        if (message.sampleTime !== 0n)
            writer.tag(1, WireType.Varint).int64(message.sampleTime);
        /* float SampleFreq = 2 [json_name = "SampleFreq"]; */
        if (message.sampleFreq !== 0)
            writer.tag(2, WireType.Bit32).float(message.sampleFreq);
        /* float Threshold = 3 [json_name = "Threshold"]; */
        if (message.threshold !== 0)
            writer.tag(3, WireType.Bit32).float(message.threshold);
        /* storage.DatasReply Datas = 4 [json_name = "Datas"]; */
        if (message.datas)
            DatasReply.internalBinaryWrite(message.datas, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message component.TorvibDataReply
 */
export const TorvibDataReply = new TorvibDataReply$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TorvibFFTDataReply$Type extends MessageType<TorvibFFTDataReply> {
    constructor() {
        super("component.TorvibFFTDataReply", [
            { no: 1, name: "SampleType", kind: "scalar", jsonName: "SampleType", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "DspType", kind: "scalar", jsonName: "DspType", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "PointParam", kind: "message", jsonName: "PointParam", T: () => PointParamReply },
            { no: 4, name: "DspParam", kind: "message", jsonName: "DspParam", T: () => DspParamReply },
            { no: 5, name: "Datas", kind: "message", jsonName: "Datas", T: () => DatasReply }
        ]);
    }
    create(value?: PartialMessage<TorvibFFTDataReply>): TorvibFFTDataReply {
        const message = { sampleType: 0, dspType: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TorvibFFTDataReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TorvibFFTDataReply): TorvibFFTDataReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 SampleType = 1 [json_name = "SampleType"];*/ 1:
                    message.sampleType = reader.int32();
                    break;
                case /* int32 DspType = 2 [json_name = "DspType"];*/ 2:
                    message.dspType = reader.int32();
                    break;
                case /* storage.PointParamReply PointParam = 3 [json_name = "PointParam"];*/ 3:
                    message.pointParam = PointParamReply.internalBinaryRead(reader, reader.uint32(), options, message.pointParam);
                    break;
                case /* storage.DspParamReply DspParam = 4 [json_name = "DspParam"];*/ 4:
                    message.dspParam = DspParamReply.internalBinaryRead(reader, reader.uint32(), options, message.dspParam);
                    break;
                case /* storage.DatasReply Datas = 5 [json_name = "Datas"];*/ 5:
                    message.datas = DatasReply.internalBinaryRead(reader, reader.uint32(), options, message.datas);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TorvibFFTDataReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 SampleType = 1 [json_name = "SampleType"]; */
        if (message.sampleType !== 0)
            writer.tag(1, WireType.Varint).int32(message.sampleType);
        /* int32 DspType = 2 [json_name = "DspType"]; */
        if (message.dspType !== 0)
            writer.tag(2, WireType.Varint).int32(message.dspType);
        /* storage.PointParamReply PointParam = 3 [json_name = "PointParam"]; */
        if (message.pointParam)
            PointParamReply.internalBinaryWrite(message.pointParam, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* storage.DspParamReply DspParam = 4 [json_name = "DspParam"]; */
        if (message.dspParam)
            DspParamReply.internalBinaryWrite(message.dspParam, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* storage.DatasReply Datas = 5 [json_name = "Datas"]; */
        if (message.datas)
            DatasReply.internalBinaryWrite(message.datas, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message component.TorvibFFTDataReply
 */
export const TorvibFFTDataReply = new TorvibFFTDataReply$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TorvibHistoryDataReply$Type extends MessageType<TorvibHistoryDataReply> {
    constructor() {
        super("component.TorvibHistoryDataReply", [
            { no: 1, name: "PointId", kind: "scalar", jsonName: "PointId", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "Datas", kind: "message", jsonName: "Datas", T: () => TorvibDataReply },
            { no: 3, name: "FFTDatas", kind: "message", jsonName: "FFTDatas", T: () => TorvibFFTDataReply },
            { no: 4, name: "ODSFrequency", kind: "scalar", jsonName: "ODSFrequency", T: 2 /*ScalarType.FLOAT*/ },
            { no: 5, name: "ODSValue", kind: "scalar", jsonName: "ODSValue", T: 2 /*ScalarType.FLOAT*/ },
            { no: 6, name: "Param", kind: "message", jsonName: "Param", T: () => DataParamReply }
        ]);
    }
    create(value?: PartialMessage<TorvibHistoryDataReply>): TorvibHistoryDataReply {
        const message = { pointId: 0, oDSFrequency: 0, oDSValue: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TorvibHistoryDataReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TorvibHistoryDataReply): TorvibHistoryDataReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 PointId = 1 [json_name = "PointId"];*/ 1:
                    message.pointId = reader.int32();
                    break;
                case /* component.TorvibDataReply Datas = 2 [json_name = "Datas"];*/ 2:
                    message.datas = TorvibDataReply.internalBinaryRead(reader, reader.uint32(), options, message.datas);
                    break;
                case /* component.TorvibFFTDataReply FFTDatas = 3 [json_name = "FFTDatas"];*/ 3:
                    message.fFTDatas = TorvibFFTDataReply.internalBinaryRead(reader, reader.uint32(), options, message.fFTDatas);
                    break;
                case /* float ODSFrequency = 4 [json_name = "ODSFrequency"];*/ 4:
                    message.oDSFrequency = reader.float();
                    break;
                case /* float ODSValue = 5 [json_name = "ODSValue"];*/ 5:
                    message.oDSValue = reader.float();
                    break;
                case /* storage.DataParamReply Param = 6 [json_name = "Param"];*/ 6:
                    message.param = DataParamReply.internalBinaryRead(reader, reader.uint32(), options, message.param);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TorvibHistoryDataReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 PointId = 1 [json_name = "PointId"]; */
        if (message.pointId !== 0)
            writer.tag(1, WireType.Varint).int32(message.pointId);
        /* component.TorvibDataReply Datas = 2 [json_name = "Datas"]; */
        if (message.datas)
            TorvibDataReply.internalBinaryWrite(message.datas, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* component.TorvibFFTDataReply FFTDatas = 3 [json_name = "FFTDatas"]; */
        if (message.fFTDatas)
            TorvibFFTDataReply.internalBinaryWrite(message.fFTDatas, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* float ODSFrequency = 4 [json_name = "ODSFrequency"]; */
        if (message.oDSFrequency !== 0)
            writer.tag(4, WireType.Bit32).float(message.oDSFrequency);
        /* float ODSValue = 5 [json_name = "ODSValue"]; */
        if (message.oDSValue !== 0)
            writer.tag(5, WireType.Bit32).float(message.oDSValue);
        /* storage.DataParamReply Param = 6 [json_name = "Param"]; */
        if (message.param)
            DataParamReply.internalBinaryWrite(message.param, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message component.TorvibHistoryDataReply
 */
export const TorvibHistoryDataReply = new TorvibHistoryDataReply$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTorvibDatasReply$Type extends MessageType<GetTorvibDatasReply> {
    constructor() {
        super("component.GetTorvibDatasReply", [
            { no: 1, name: "Datas", kind: "message", jsonName: "Datas", repeat: 1 /*RepeatType.PACKED*/, T: () => TorvibHistoryDataReply }
        ]);
    }
    create(value?: PartialMessage<GetTorvibDatasReply>): GetTorvibDatasReply {
        const message = { datas: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetTorvibDatasReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTorvibDatasReply): GetTorvibDatasReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated component.TorvibHistoryDataReply Datas = 1 [json_name = "Datas"];*/ 1:
                    message.datas.push(TorvibHistoryDataReply.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTorvibDatasReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated component.TorvibHistoryDataReply Datas = 1 [json_name = "Datas"]; */
        for (let i = 0; i < message.datas.length; i++)
            TorvibHistoryDataReply.internalBinaryWrite(message.datas[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message component.GetTorvibDatasReply
 */
export const GetTorvibDatasReply = new GetTorvibDatasReply$Type();
