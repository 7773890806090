<template>
	<div class="task" id="showExport">
		<div class="title">{{ $t('headerSetting.exportTask.title') }}</div>
		<el-radio-group v-model="state.radio" @change="getTableData(true)">
			<el-radio-button :label="0">{{ $t('headerSetting.exportTask.subTitle') }}</el-radio-button>
			<el-radio-button :label="1" v-if="isAdmin">{{ $t('headerSetting.exportTask.backupTask') }}</el-radio-button>
		</el-radio-group>
		<div class="content">
			<el-form class="flex platSearchForm" :inline="true">
				<el-form-item
					:label="isBackUp ? $t('headerSetting.exportTask.backupTime') : $t('headerSetting.exportTask.exportTime')">
					<!-- <el-date-picker v-model="state.searchForm.StartTime" class="marRight10" type="datetime"
                        :teleported="false" :placeholder="$t('common.selectStartTimePlaceholder')" format="YYYY-MM-DD hh:mm:ss"
                        value-format="YYYY-MM-DD hh:mm:ss" />
                    <el-date-picker v-model="state.searchForm.EndTime" class="marRight10" type="datetime"
                        :teleported="false" :placeholder="$t('common.selectEndTimePlaceholder')" format="YYYY-MM-DD hh:mm:ss"
                        value-format="YYYY-MM-DD hh:mm:ss" /> -->
					<el-date-picker v-model="state.searchForm.StartTime" type="datetime" class="marRight10"
						:placeholder="$t('common.selectStartTimePlaceholder')" :teleported="false"
						format="YYYY-MM-DD HH:mm:ss" value-format="YYYY-MM-DD HH:mm:ss" :clearable="false" />
					<el-date-picker v-model="state.searchForm.EndTime" type="datetime" class="marRight10"
						:placeholder="$t('common.selectEndTimePlaceholder')" :teleported="false"
						format="YYYY-MM-DD HH:mm:ss" value-format="YYYY-MM-DD HH:mm:ss" :clearable="false" />
				</el-form-item>
				<el-form-item>
					<el-input v-model="state.searchForm.SearchText" :placeholder="$t('common.searchContentPlaceholder')"
						class="searchInput" @keyup.enter.prevent="getTableData(true)"> </el-input>
					<el-button type="primary" @click="getTableData(true)">{{ $t('common.query') }}</el-button>
					<el-button type="primary" @click="reset">{{ $t('common.reset') }}</el-button>
				</el-form-item>

				<el-form-item class="flexRight">
					<el-button type="primary" :disabled="state.selection.length === 0" @click="download(state.selection)"
						v-auth="'SystemTask_Download'">{{ $t('common.download') }} </el-button>
					<el-button type="primary" :disabled="state.selection.length === 0" @click="retry(state.selection)"
						v-auth="'SystemTask_Edit'"> {{ $t('headerSetting.exportTask.retry') }} </el-button>
					<el-button type="primary" :disabled="state.selection.length === 0" @click="removeTask(state.selection)"
						v-auth="'SystemTask_Delete'">{{ $t('common.delete') }} </el-button>
				</el-form-item>
			</el-form>
			<el-table :data="state.tableData" style="width: 100%" :empty-text="$t('common.noContent')"
				@selection-change="handleSelectionChange" max-height="600">
				<el-table-column type="selection" width="55" />
				<el-table-column prop="Title"
					:label="isBackUp ? $t('headerSetting.exportTask.backupContent') : $t('headerSetting.exportTask.exportContent')"
					width="300" show-overflow-tooltip />

				<el-table-column prop="ExecuteStatus"
					:label="isBackUp ? $t('headerSetting.exportTask.backupStatus') : $t('headerSetting.exportTask.exportStatus')"
					width="100">
					<template #default="scope">{{ isBackUp ? backupStatusMap[scope.row.ExecuteStatus] :
						statusMap[scope.row.ExecuteStatus] }}</template>
				</el-table-column>
				<el-table-column prop="CreateTime"
					:label="isBackUp ? $t('headerSetting.exportTask.backupTime') : $t('headerSetting.exportTask.exportTime')"
					width="200" />
				<el-table-column prop="ExecuteProgress"
					:label="isBackUp ? $t('headerSetting.exportTask.backupProgress') : $t('headerSetting.exportTask.exportProgress')"
					width="360">
					<template #default="scope">
						<el-progress :stroke-width="12" :percentage="scope.row.ExecuteProgress * 100"
							:status="ProcessStatusMap[scope.row.ExecuteStatus]" :format="formatProgress" />
					</template>
				</el-table-column>
				<el-table-column prop="address" :label="$t('common.operation')" min-width="160" fixed="right">
					<template #default="scope">
						<el-button type="primary" link @click="download([scope.row])"
							v-if="scope.row.ExecuteStatus === ExportStatusEnum.Exported" v-auth="'SystemTask_Download'">
							{{ $t('common.download') }}</el-button>
						<el-button type="primary" link @click="retry([scope.row])"
							v-if="scope.row.ExecuteStatus === ExportStatusEnum.Failed" v-auth="'SystemTask_Edit'">
							{{ $t('headerSetting.exportTask.retry') }}</el-button>
						<el-button type="primary" link @click="removeTask([scope.row])" v-auth="'SystemTask_Delete'">{{
							$t('common.delete') }}
						</el-button>
					</template>
				</el-table-column>
			</el-table>
			<paginationVue :pageInfo="state.pageInfo" @changePage="changePage"></paginationVue>
		</div>
		<teleport to="#app">
			<div v-if="state.showLoading" class="loadingText">
				<span class="text">{{ $t('headerSetting.exportTask.obtainingFiles') }}</span>
				<span class="percent">{{ state.percent }}%</span>

				<!-- <el-progress :text-inside="true" :stroke-width="26" class="percent" :percentage="percent" /> -->
			</div>
		</teleport>
	</div>
</template>

<script setup>
import paginationVue from "@common/components/pagination.vue";
import axios from "@common/js/axios";
import { onMounted, nextTick, reactive, ref, computed, onBeforeUnmount } from "vue";
import { alertErrorMsg, alertSuccessMsg, fullScreenLoading, i18nGlobal } from "@common/js/utils";
import { ContractTypes, ExportStatusEnum } from "@common/js/enum";
import dayjs from "dayjs";
import { SignalRInstance, ISignalRHandler } from "@common/js/signalRClient";
import { useStore } from "vuex";
import { axiosAuth } from "@common/js/auth";

const store = useStore();
const emit = defineEmits(["setCount"]);

const statusMap = {
	[ExportStatusEnum.NoExport]: i18nGlobal('headerSetting.exportTask.unexecuted'),
	[ExportStatusEnum.Exporting]: i18nGlobal('common.exporting'),
	[ExportStatusEnum.Exported]: i18nGlobal('headerSetting.exportTask.completed'),
	[ExportStatusEnum.Failed]: i18nGlobal('headerSetting.exportTask.exportFailed'),
};

const backupStatusMap = {
	[ExportStatusEnum.NoExport]: i18nGlobal('headerSetting.exportTask.unexecuted'),
	[ExportStatusEnum.Exporting]: i18nGlobal('headerSetting.exportTask.backuping'),
	[ExportStatusEnum.Exported]: i18nGlobal('headerSetting.exportTask.completed'),
	[ExportStatusEnum.Failed]: i18nGlobal('headerSetting.exportTask.backupFailed'),
};

const ProcessStatusMap = {
	[ExportStatusEnum.NoExport]: "",
	[ExportStatusEnum.Exporting]: "",
	[ExportStatusEnum.Exported]: "success",
	[ExportStatusEnum.Failed]: "exception",
};

const isBackUp = computed(() => {
	return state.radio === 1
});

const signalrCache = {};
const state = reactive({
	selection: [],
	tableData: [],
	showLoading: false,
	percent: 0.0,
	totalSize: 0,
	radio: 0,
	searchForm: {
		StartTime: "",
		EndTime: "",
		SearchText: "",
	},
	pageInfo: {
		total: 0,
		PageIndex: 1,
		PageSize: 10,
	},
});

const isAdmin = computed(() => {
	return store.state.userInfo.UserId == 'b942b1d8-2946-4ac7-8ae8-5ba51d1c175b';
});

const getTableData = (isFirst) => {
	state.loading = true;
	if (isFirst) {
		state.pageInfo.PageIndex = 1;
	}
	if (state.searchForm.StartTime && state.searchForm.EndTime) {
		const isBefore = dayjs(state.searchForm.StartTime).isBefore(dayjs(state.searchForm.EndTime));
		if (!isBefore) {
			state.loading = false;
			return alertErrorMsg(i18nGlobal('common.startTimeLessThanEndTime'));
		}
	}
	axiosAuth({
		method: "get",
		url: "SystemTask/search",
		auth: "SystemTask_Browse",
		params: {
			PageSize: state.pageInfo.PageSize,
			PageIndex: state.pageInfo.PageIndex,
			TaskType: state.radio,
			...state.searchForm,
		},
	}).then((res) => {
		if (res.IsSuccess) {
			state.tableData = res.Result.Data;
			state.pageInfo.total = res.Result.TotalRows;
		}
	}).finally(() => {
		state.loading = false;
	});
};

const clearData = () => {
	state.pageInfo.PageIndex = 1;
	// reset();
	state.tableData = [];
};

const changePage = (pageInfo) => {
	state.pageInfo = pageInfo;
	getTableData();
};

const handleSelectionChange = (selection) => {
	state.selection = selection;
};

const reset = () => {
	state.searchForm.StartTime = "";
	state.searchForm.EndTime = "";
	state.searchForm.SearchText = "";
	getTableData(true);
};

const removeTask = (selection) => {
	const ids = selection.map((item) => item.Id);
	axios({
		method: "delete",
		url: "SystemTask",
		data: ids,
	}).then((res) => {
		if (res.IsSuccess) {
			alertSuccessMsg(i18nGlobal('common.deleteSuccess'));
			emit("setCount");
			getTableData();
		}
	});
};

const retry = (selection) => {
	const ids = selection.filter((item) => item.ExecuteStatus === ExportStatusEnum.Failed).map((item) => item.Id);
	if (ids.length === 0) return alertErrorMsg(i18nGlobal('headerSetting.exportTask.noRetryTask'));
	axios({
		method: "post",
		url: "ExportTask/retry",
		data: ids,
	}).then((res) => {
		if (res.IsSuccess) {
			// alertSuccessMsg('删除成功');
			// getTableData();
		}
	});
};

const getPercent = (percent) => {
	const arr = percent.split(".");
	if (arr[1] && arr[1].length > 2) {
		if ((arr[0].length === 1)) return percent.slice(0, 4);
		if ((arr[0].length === 2)) return percent.slice(0, 5);
		if ((arr[0].length === 3)) return percent.slice(0, 6);
	}
	return percent;
};

const download = (selection) => {
	const loading = fullScreenLoading(i18nGlobal('headerSetting.exportTask.obtainingFiles'));
	const ids = selection.map((item) => item.Id);
	axios({
		method: "post",
		url: `ExportTask/download`,
		data: ids,
	}).then((res) => {
		if (res.IsSuccess) {
			res.Result.forEach((item) => {
				let arr = item.Url.split("filePath=");
				arr[1] = encodeURIComponent(arr[1]);
				state.showLoading = true;
				axios({
					method: "post",
					url: `${arr[0] + "filePath=" + arr[1]}`,
					responseType: "blob",
					onDownloadProgress: (evt) => {
						// 对原生进度事件的处理
						state.totalSize = evt.total / 1024 / 1024 / 1024;
						const percent = String((evt.loaded / evt.total).toFixed(4) * 100);
						state.percent = getPercent(percent);
					},
				}).then((childRes) => {
					const anchor = document.createElement("a");
					const url = window.URL || window.webkitURL;
					anchor.href = url.createObjectURL(childRes);
					anchor.download = item.Filename;
					document.body.append(anchor);
					anchor.click();
					setTimeout(() => {
						document.body.removeChild(anchor);
						url.revokeObjectURL(anchor.href);
					});
				}).finally(() => {
					setTimeout(() => {
						state.showLoading = false;
					}, 500);
				});
			});
		}
	}).finally(() => {
		loading.close();
	});
};

const formatProgress = (percentage) => {
	return Number(percentage).toFixed(2) + "%";
};

const setDefaultRadio = (radio = 0) => {
	state.radio = radio;
}

class ExportProcessSignalRHandler extends ISignalRHandler {
	constructor() {
		super();
		this.SupportContentType = [ContractTypes.ExportTaskProgressChanged];
	}

	ReceiveData(data) {
		if (data.ContentType == ContractTypes.ExportTaskProgressChanged) {
			if (data.Content != null) {
				const { Id, ExecuteProgress, ExecuteStatus } = data.Content[0];
				const cur = state.tableData.find((item) => item.Id === Id);
				if (cur) {
					cur.ExecuteProgress = ExecuteProgress.toFixed(4);
					cur.ExecuteStatus = ExecuteStatus;
				}
			}
		}
	}
}

onMounted(() => {
	setDefaultRadio();
	getTableData();
	nextTick(() => {
		const signalr = SignalRInstance.GetInstance();
		signalrCache.exprotSignalr = new ExportProcessSignalRHandler();
		signalr?.Add(signalrCache.exprotSignalr);
	});
});

onBeforeUnmount(() => {
	signalrCache.exprotSignalr?.Dispose();
})

defineExpose({
	setDefaultRadio,
	getTableData,
	clearData,
});
</script>

<style scoped lang="less">
.task {
	.title {
		color: var(--phm-theme-title);
		font-weight: bold;
		height: 40px;
		line-height: 40px;
		border-bottom: 1px solid var(--phm-theme-border);
		padding-left: 20px;
	}

	.el-radio-group {
		margin: 10px 0 0 20px;
	}

	.content {
		padding: 20px;
	}
}
</style>
