// 普通验证
import { alertErrorMsg, i18nString } from "@common/js/utils";

export const genneralValidate = (msg, trigger = 'blur', args) => {
	const obj = {
		required: true,
		message: msg,
		trigger: trigger,
	}
	return args !== undefined ? [obj, {
		type: args.type,
		message: args.msg
	}] : [obj];
}

// 长度验证
export const limitLengthValidate = (args, msg) => {
	const obj = {
		required: true,
		message: msg,
		trigger: 'blur',
	};
	const range = {
		message: args.msg,
		trigger: 'blur',
	}
	if (args.min !== undefined) range.min = args.min;
	if (args.max !== undefined) range.max = args.max;
	return args.required ? [obj, range] : [range]
}

// 验证数字范围
export const checkRangeNumber = (min, max, name, digit) => {
	return (rule, value, callback) => {
		const regex = /^[0-9]+.?[0-9]*/;
		if (value === '') {
			callback(new Error(i18nString({ CN: `请输入${name}`, EN: `please input ${name}` })))
		} else if (!ruleCheck.IsNum(value)) {
			callback(new Error(i18nString({ CN: `${name}必须为数字`, EN: `${name}should be a number` })))
		} else if (value > max || value < min) {
			callback(new Error(i18nString({ CN: `${name}必须在${min}到${max}之间`, EN: `${name}'s range is ${min} to ${max}` })))
		} else {
			if (digit !== undefined && !ruleCheck.IsNumByRound(value, digit)) {
				callback(new Error(i18nString({ CN: `${name}最多${digit}位小数`, EN: `${name} only has ${digit} decimal width` })))
			} else {
				callback()
			}
		}

	}
}
// 验证正整数数字范围
export const checkIntRangeNumber = (min, max, name) => {
	return (rule, value, callback) => {
		if (value === '') {
			callback(new Error(i18nString({ CN: `请输入${name}`, EN: `please input ${name}` })))
		} else if (!ruleCheck.IsInteger(value)) {
			callback(new Error(i18nString({ CN: `${name}必须为整数`, EN: `${name}should be an integer` })))
		} else if (value > max || value < min) {
			callback(new Error(i18nString({ CN: `${name}必须在${min}到${max}之间的整数`, EN: `${name} should be ranged from ${min} to ${max}` })))
		} else {
			callback()
		}
	}
}

export const checkIntRangeNumberx = (value, min, max, name) => {
	if (value === '') {
		alertErrorMsg(i18nString({ CN: `请输入${name}`, EN: `please input ${name}` }));
		return false;
	} else if (!ruleCheck.IsInteger(value)) {
		alertErrorMsg(i18nString({ CN: `${name}必须为整数`, EN: `${name}should be an integer` }));
		return false;
	} else if (value > max || value < min) {
		alertErrorMsg(i18nString({ CN: `${name}必须在${min}到${max}之间的整数`, EN: `${name} should be ranged from ${min} to ${max}` }));
		return false;
	}
	return true;
}


// 检查特殊
export const checkRegex = (name, type, args) => {
	const regMap = {
		user: /^[A-Za-z0-9_]{2,18}$/,
		IP: /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/,
		email: /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/,
		intZero: /(^[0-9]+\d*$)|(^\d+\.\d+$)/,
		intNoZero: /^[1-9]\d*$/,
		int: /^-?\d+$/,
		password: /[^\u4e00-\u9fa5]{6,20}/,
		specialCharacters: /[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/im,
		nullSpecialCharacters: /[`~!@#$%^&*()_\-+=<>?:"{}|\/'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、‘’、]/im,
		positiveNum: /^\d+(\.\d+)?$/, // 正数
		phone: /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/,
	}
	return (rule, value, callback) => {
		if (value === '') {
			if (args && args.required === false) {
				callback()
			} else {
				callback(new Error(`请输入${name}`))
			}
		} else if (!regMap[type].test(value)) {
			if (type === 'int') {
				callback(new Error((args && args.msg) || `请输入正整数`))
			} else {
				callback(new Error((args && args.msg) || `请输入正确的${name}`))
			}
		} else {
			callback();
		}

	}
}

// 检查用户名
export const checkNotRegex = (name, type, args) => {
	const regMap = {
		specialCharacters: /[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/im,
		nullSpecialCharacters: /[`~!@#$%^&*()_\-+=<>?:"{}|\/'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、‘’、]/im
	}
	return (rule, value, callback) => {
		if (value === '') {
			if (args && args.required === false) {
				callback()
			} else {
				callback(new Error(`请输入${name}`))
			}
		} else if (regMap[type].test(value)) {
			callback(new Error((args && args.msg) || `请输入正确的${name}`))
		} else {
			callback();
		}

	}
}

export const ruleCheck = {
	// 是数值
	IsNumeric(obj) {
		return !Array.isArray(obj) && (obj - parseFloat(obj) + 1) >= 0;
	},
	IsNum(str) {
		if (!ruleCheck.IsNumeric(str)) {
			return false;
		}

		const float = parseFloat(str);
		const result = (float <= Number.MAX_SAFE_INTEGER && float >= Number.MIN_SAFE_INTEGER);

		return result;
	},
	// 是几位小数内的数值
	// decimalDigits：小数位数
	IsNumByRound(str, decimalDigits) {
		const regStr = '/^-?\\d+\.?\\d{0,' + decimalDigits + '}$/';
		const re = eval(regStr);//转成正则
		return re.test(str);
	},
	// 大于0的数值
	IsGreaterZero(str) {
		const reg = /^(0+[1-9]+)$|(^[1-9]+\d*$)|(^\d+\.\d+$)/;
		return reg.test(str);
	},
	// 是整数
	IsInteger(str) {
		const reg = /^-?\d+$/;
		return reg.test(str);
	},
	// 大于等于0的数值
	IsNotLessThanZero(str) {
		const reg = /(^[0-9]+\d*$)|(^\d+\.\d+$)/;
		return reg.test(str);
	},
	// 大于某个数值
	IsLess(str, min) {
		if (!ruleCheck.IsNum(str)) {
			return false;
		}

		if (typeof (min) != "number") {
			return false;
		}

		return parseFloat(str) > min;
	},
	// 数值在某个区间内,inclusive为true或空时：<=X<=，为false时：<X<
	IsBetweenNum(str, min, max, inclusive) {
		if (!ruleCheck.IsNum(str)) {
			return false;
		}
		const value = parseFloat(str);
		if (inclusive == null || inclusive == true) {
			if (min != null && value < min) { return false; }
			if (max != null && value > max) { return false; }
		} else {
			if (min != null && value <= min) { return false; }
			if (max != null && value >= max) { return false; }
		}
		return true;
	},
	// 判断是否为空串
	IsNullOrSpaceStr(str) {
		if (typeof str === 'string') {
			return str.trim() === "";
		}
		return str == undefined || str == null;
	},
	// 判断字符串是否超长,true:超长,false:未超长，字符串为空.
	IsStringOverlength(str, minlength, maxlength) {
		if (ruleCheck.IsNullOrSpaceStr(str))
			return false;

		if (str.length < minlength || str.length > maxlength) {
			return true;
		} else {
			return false;
		}
	},
	// 判断字符串是否为联系电话，true:是联系电话
	IsStringPhone(str) {
		const reg = /^1\d{10}$|^(0\d{2,3}-?|(0\d{2,3}))?[1-9]\d{4,7}(-\d{1,8})?$/;
		return reg.test(str);
	},
	// 判断时间字符串格式是否正确
	IsStringDate(str) {
		if (new Date(str) == "Invalid Date") {
			return true;
		} else {
			const reg1 = /^\d{4}-\d{2}-\d{2}$/,
				reg2 = /^\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}$/,
				reg3 = /^\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}:\d{2}$/;
			if (reg1.test(str) || reg2.test(str) || reg3.test(str)) {
				return false;
			}
			else {
				return true;
			}

		}
	},
	// 判断字符串是否为邮箱
	IsStringEmail(str) {
		const reg = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;
		return reg.test(str);
	},
	// 用户名校验
	IsUserName(str) {
		const reg = /^[A-Za-z0-9_]{2,18}$/;
		return reg.test(str);
	},
	// 判断字符串是否为IP地址
	IsIPAddress(str) {
		const reg = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/;
		return reg.test(str);
	},
};

export const numberRound = (value, precision) => {
	if (isNaN(value) || !isFinite(value)) return value;
	let arr = (value).toString().split('.');
	if (precision == 0) {
		return parseFloat(value).toFixed(0).toString();
	}
	if (arr.length == 1) {
		let suffix = '.' + '0'.repeat(precision);
		return arr.concat([suffix]).join('');
	} else if (arr.length == 2) {
		if (arr[1].length >= precision) {
			return parseFloat(value).toFixed(precision).toString();
		} else {
			let str = '.' + arr[1] + '0'.repeat(precision - arr[1].length);
			return arr[0] + str;
		}
	} else {
		return '';
	}
}

export const formatFloat = (d) => {
	if (d == null || isNaN(d)) {
		return "";
	}

	let m = d.toString();

	let s = "000000000000";
	let i = m.indexOf(s);
	if (i > 0) {
		return parseFloat(m.substring(0, i));
	}

	let q = "999999999999";
	let t = m.indexOf(q);
	if (t > 0) {
		return parseFloat(d.toPrecision(t));
	}

	return d;
}

export const strongPasswordCheck = (val) => {
	const checkreg = /^(?=.{8})(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)(?=.*?[*?!&￥$%^#,./@";:><\[\]}{\-=+_\\|》《。，、？'‘“”~ `]).*$/
	return checkreg.test(val)
}
