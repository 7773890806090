<template>
	<div v-if="props.step == 1" class="selector">
		<div class="l1">
			<div class="header">
				<div class="spliter"></div>
				<label style="float: left">{{ $t("mainLayout.report.deviceList") }}</label>
				<el-checkbox v-model="state.deviceCheckedAll" :indeterminate="deviceIndeterminate" class="checkAll"
					:label="$t('common.checkAll')" @change="handleCheckAllDeviceChanged">
				</el-checkbox>
			</div>
			<div class="border">
				<div class="row" v-for="d in state.devices"
					:class="{ active: d.isActive }">
					<el-checkbox class="checkbox" @click.stop v-model="d.isSelect" :indeterminate="d.Indeterminate"
						@change="handleCheckDeviceChanged(d)" />
					<span class="text">{{ d.Name }}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { reactive, computed } from "vue";
import axios from "@common/js/axios";
import store from "@common/js/store";
import { guid, i18nGlobal } from "@common/js/utils";

const props = defineProps({
	step: {
		type: Number,
		default: 1
	},
	datas: {
		type: Array,
		default: []
	}
});

const state = reactive({
	devices: new Array(),
	deviceCheckedAll: false,
	pointCheckedAll: false,
	nodes: [],
	enitiyId: null,
	title: i18nGlobal("mainLayout.report.monitorReport"),
	graphId: guid(),
});

const deviceIndeterminate = computed(() => {
	let checked = state.devices.filter((r) => r.isSelect);
	state.deviceCheckedAll = checked.length == state.devices.length && checked.length > 0;
	return checked.length < state.devices.length && checked.length > 0;
});

const handleCheckAllDeviceChanged = async (value) => {
	for (let device of state.devices) {
		device.isSelect = value;
	}
};

const handleCheckDeviceChanged = (device) => {
	let status = device.isSelect;
	device.isSelect = status;
};

const getTree = async (currentNode) => {
	let res = await axios.get(`Tree/${currentNode.Path}/type/3,4`);
	if (res.IsSuccess) {
		state.devices = [];
		for (let node of res.Result.orderBy((p) => p.Path)) {
			node.isSelect = props.datas.find((r) => r.DeviceId == node.Id) != null;
			node.isActive = false;
			node.Indeterminate = false;
			state.devices.push(node);
		}
	}
};

const validate = (step) => {
	return true;
}

const getCheckedDeviceIds = () => state.devices.filter(e => e.isSelect).map(e => e.Id);

const init = (parentState) => {
	getTree(store.state.currentDep);
}

defineExpose({
	init,
	validate,
	getCheckedDeviceIds
})
</script>

<style scoped lang="less">
.selector {
	float: left;
	width: 100%;
	margin-bottom: 20px;

	.l1 {
		width: 100%;
		text-align: center;
		padding: 2px;
		overflow: auto;
		float: left;

		.checkAll {
			float: right;
			margin-right: 3px;
			margin-top: 4px;
		}

		.border {
			border: 1px solid var(--phm-theme-border);
			height: 530px;
			padding-top: 5px;

			.row {
				width: 94%;
				float: left;
				padding-left: 20px;
				height: 40px;
				line-height: 40px;
				margin-left: 3%;
				border-radius: 5px;

				label {
					float: left;
				}
			}
		}

		.text {
			float: left;
			line-height: 40px;
			margin-left: 10px;
		}

		.checkbox {
			margin-top: 4px;
		}
	}
}
.graph-container {
	float: left;
	width: 100%;
	height: 450px;
	margin-bottom: 20px;
}
</style>
