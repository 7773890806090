<template>
	<div class="header" :style="{ height: state.headerHeight + 'px' }">
		<div v-if="!state.logoHref" class="leftInfo">
			<div class="logo">
				<img id="titleLogoImg" :src="state.titleUrl" :class="{ visible: state.showImg }" />
			</div>
			<div class="title" :style="{ lineHeight: state.headerHeight + 'px', color: titleColor }" :class="{ margin0: !state.showImg }">
				{{ systemName }}
			</div>
		</div>
		<a v-else class="leftInfo" :href="state.logoHref">
			<div class="logo">
				<img id="titleLogoImg" :src="state.titleUrl" :class="{ visible: state.showImg }" />
			</div>
			<div class="title" :style="{ lineHeight: state.headerHeight + 'px' }" :class="{ margin0: !state.showImg }">
				{{ systemName }}
			</div>
		</a>
		<div class="copyright" @click="getCopyRight"></div>
		<audio v-if="soundAlarmSrc" id="alarmAudio" :src="soundAlarmSrc" autoplay="pl" loop playsinline></audio>
		<div class="rightIcon">
			<ul>
				<li :title="store.state.uiMode === UiModeEnum.EditStatus ? $t('mainLayout.header.showStatus') :  $t('mainLayout.header.editStatus')" class="noPopover" @click="setShowGraphMenu">
					<svgIcon :color="getHeaderColor" :name="store.state.uiMode === UiModeEnum.EditStatus ? 'screen' : 'isEdited'" :hover="false">
					</svgIcon>
				</li>


				<li class="line" :style="{ borderColor: lineColor }" v-auth="'AlarmInfo_Browse'"></li>

				<el-popover placement="bottom" :width="1600" :visible="state.showWarning" :hide-after="0" :offset="0"
					popper-class="noPaddingPopper" transition="" @show="showWarning" @hide="hideWarning">
					<template #reference>
						<li :title="$t('mainLayout.header.alarmInfo')" @click="state.showWarning = true"
							v-auth="'AlarmInfo_Browse'">
							<el-badge :value="state.warningCount" class="item">
								<svgIcon :color="getHeaderColor" name="realalarm" :hover="false"></svgIcon>
							</el-badge>
						</li>
					</template>
					<div id="showWarning_wrapper">
						<warnningVue ref="warningRef" @setCount="getAlarmCount" @onChangeAlarmSilent="getSoundAlarmLevel">
						</warnningVue>
					</div>
				</el-popover>

				<el-popover placement="bottom" :width="1500" :visible="state.showSystemEvent" trigger="click"
					:hide-after="0" :offset="0" popper-class="noPaddingPopper" @show="showSystemEvent" transition=""
					@hide="hideSystemEvent">
					<template #reference>
						<li :title="$t('mainLayout.header.systemEvent')" @click="state.showSystemEvent = true"
							v-auth="'SystemEvent_Browse'">
							<el-badge :value="state.eventNum" class="item">
								<svgIcon :color="getHeaderColor" name="systemEvent" :hover="false"></svgIcon>
							</el-badge>
						</li>
					</template>
					<div id="showSystemEvent_wrapper">
						<systemEventVue ref="systemEventRef" @setCount="getSystemEventCount"></systemEventVue>
					</div>
				</el-popover>
				<el-popover placement="bottom" :width="1250" :visible="state.showExport" trigger="click" :hide-after="0"
					:offset="0" popper-class="noPaddingPopper" transition="" @show="showExport" @hide="hideExport">
					<template #reference>
						<li :title="$t('mainLayout.header.systemTask')" @click="state.showExport = true"
							v-auth="'SystemTask_Browse'">
							<el-badge :value="state.exportNum" class="item">
								<svgIcon :color="getHeaderColor" name="export_task" :hover="false">
								</svgIcon>
							</el-badge>
						</li>
					</template>
					<div id="showExport_wrapper">
						<exportTaskVue ref="exportRef" @setCount="getExportNum"></exportTaskVue>
					</div>
				</el-popover>
				<!-- <el-popover placement="bottom" :width="800" :visible="state.showSystemStatus" trigger="click"
					:hide-after="0" :offset="0" popper-class="noPaddingPopper systemStatusPopper" transition=""
					@show="showSystemStatus" :show-arrow="false">
					<template #reference>
						<li :title="$t('mainLayout.header.systemStatus')" style="margin-top:17px"
							@click="state.showSystemStatus = true" v-auth="'SystemTask_Browse'">
							<svgIcon :color="getHeaderColor" name="systemStatus" width="18px" height="18px" :hover="false"></svgIcon>
						</li>
					</template>
					<systemStatusVue v-if="state.showSystemStatus" ref="systemStatusRef" @close="closeSystemStatus"
						@dragStart="onDragStart" @dragStop="onDragStop" @draging="onDragging"></systemStatusVue>
				</el-popover> -->
				<li class="line" :style="{ borderColor: lineColor }" v-auths="'SystemEvent_Browse,SystemTask_Browse'"></li>
				<li class="userInfo">
					<el-dropdown size="middle">
						<span class="el-dropdown-link">
							<svgIcon :color="getHeaderColor" class="userIcon" name="user" :hover="false"></svgIcon>
							<span class="userName" :style="{ color: getHeaderColor }">{{ userName }}</span>
						</span>
						<template #dropdown>
							<el-dropdown-menu>
								<el-dropdown-item v-if="!state.externalLogin" @click="state.changePwdDialog = true">{{
									$t('mainLayout.header.changePwd')
								}}</el-dropdown-item>
								<el-dropdown-item @click="getPlatForm">{{ $t('mainLayout.header.platformSetting')
								}}</el-dropdown-item>
								<el-dropdown-item @click="handleLogOut">{{ $t('mainLayout.header.signOut')
								}}</el-dropdown-item>
							</el-dropdown-menu>
						</template>
					</el-dropdown>
				</li>
			</ul>
		</div>
	</div>
	<changePwdVue v-if="state.changePwdDialog" :dialog="state.changePwdDialog" @cancel="state.changePwdDialog = false"
		@submit="changePassword"> </changePwdVue>
	<copyright :dialog="state.copyRightDialog" :version="state.version" @cancel="state.copyRightDialog = false">
	</copyright>
	<warnningVue v-if="state.realTimeDialog" :dialog="state.realTimeDialog" @cancel="state.realTimeDialog = false"
		@setCount="getAlarmCount" @onChangeAlarmSilent="getSoundAlarmLevel"></warnningVue>
</template>

<script setup>
import { reactive, onMounted, computed, onBeforeUnmount, nextTick, ref } from "vue";
import { useRouter } from "vue-router";
import svgIcon from "@common/components/svgIcon.vue";
import changePwdVue from "@views/headerSetting/changePwd.vue";
import warnningVue from "@views/headerSetting/warnning.vue";
import copyright from "@common/components/copyright.vue";
import exportTaskVue from "@views/headerSetting/exportTask.vue";
import systemEventVue from "@views/headerSetting/systemEvent.vue";
import { SignalRInstance, ISignalRHandler } from "@common/js/signalRClient";
import axios from "@common/js/axios";
import { useStore } from "vuex";
import { fullScreenLoading, getHexOpacityColor, getOffsetByBody, mixColor, getSourceUrl, i18nGlobal, Debounce } from "@common/js/utils";
import { ContractTypes, SoundAlarmLevelEnum, UiModeEnum } from "@common/js/enum";
import $ from "jquery";
import { getTokens } from "@common/js/auth";
import { ruleCheck } from "@common/js/rules";
import { axiosAuth } from "@common/js/auth";

const store = useStore();
const state = reactive({
	logoImg: "",
	logoHref: window.Config.logoHref,
	changePwdDialog: false,
	historyWarnnigDialog: false,
	realTimeDialog: false,
	exportDialog: false,
	systemEventDialog: false,
	copyRightDialog: false,
	showWarning: false,
	showSystemEvent: false,
	showExport: false,
	showSystemStatus: false,
	warningCount: 0,
	soundAlarmLevel: SoundAlarmLevelEnum.Safe,
	historyCount: 0,
	exportNum: 0,
	eventNum: 0,
	signalr: null,
	activePopover: null,
	headerHeight: 30,
	copyInfo: {
		clickCount: 0,
		timer: null,
		lastTime: new Date().getTime(),
	},
	version: "2.0.3",
	externalLogin: false,
});

const signarlCache = {};
const headTheme = window.Config.uiConfig.headerTheme;
const warningRef = ref(null);
const systemEventRef = ref(null);
const exportRef = ref(null);
const systemStatusRef = ref(null);
const dragObject = {};
const getPlatForm = () => {
	store.commit("setShowPlatForm", { showPlatForm: true });
};

const getHeaderColor = computed(() => {
	const headerTheme = window.Config.uiConfig.headerTheme;
	const { CardColor, } = store.state.customColor;
	const primaryColor = store.state.primaryColor;
	if (headerTheme === 2) return primaryColor;
	if (headerTheme === 1) return '#fff';
	if (headerTheme === 0 && store.state.customColor.GraphTheme && store.state.customColor.CardColor?.toLowerCase() === '#020c34') return primaryColor;
	return CardColor;
})

const titleColor = computed(() => {
	const headerTheme = window.Config.uiConfig.headerTheme;
	if(headerTheme === 0 || headerTheme === 1) return '#fff';
	return store.state.primaryColor;
})

const lineColor = computed(() => {
	const headerTheme = window.Config.uiConfig.headerTheme;
	if(headerTheme === 2 || headerTheme === 0) return store.state.customColor.TooltipColor;
	if(headerTheme === 1) return '#fff';
	return '#fff';
})

const soundAlarmSrc = computed(() => {
	return store.state.soundAlarmSrc;
})
const hasSystemTaskPermission = computed(() => {
	return store.state.userAuth.ResourceAuth.includes('SystemTask_Browse')
})

const hasSystemEventPermission = computed(() => {
	return store.state.userAuth.ResourceAuth.includes('SystemEvent_Browse')
})

const userName = computed(() => {
	return store.state.userInfo.UserName;
});

const getSvgColor = () => {
	const themeMap = {
		1: '#fff',
		2: store.state.primaryColor
	}
	return themeMap[window.Config.uiConfig.headerTheme];
}

const systemName = computed(() => {
	if (store.state.licenseInfo?.SystemName) {
		return store.state.licenseInfo?.SystemName.replace("\n", "");
	}
	return i18nGlobal('mainLayout.header.systemName');
});

const changePassword = () => {
	state.changePwdDialog = false;
	handleLogOut();
};

const isChildOf = (child, parent) => {
	var parentNode;
	if (child && parent) {
		parentNode = child.parentNode;
		while (parentNode) {
			if (parent === parentNode) {
				return true;
			}
			parentNode = parentNode.parentNode;
		}
	}
	return false;
};

// 手动关闭popover
const setPopoverHide = (e) => {
	if (state[state.activePopover]) {
		const parent = document.getElementById(state.activePopover + '_wrapper');
		// dialog外部点击， cell 点击月份年
		let valid = false;
		const arr = ["el-overlay-dialog", "cell", "el-overlay-message-box"];
		arr.forEach((item) => {
			if (e.target.classList.contains(item)) valid = true;
		});
		const { offsetLeft, offsetTop } = getOffsetByBody(parent);
		//判断是否在区域内点击
		if (!valid && e.clientY > offsetTop && e.clientY < offsetTop + parent.clientHeight && e.clientX > offsetLeft && e.clientX < offsetLeft + parent.clientWidth) {
			valid = true;
		}
		const res = isChildOf(e.target, parent) || valid;
		if (!res) {
			state[state.activePopover] = false;
		}
	}
};

const hidePopover = () => {
	if (state.showWarning) {
		state.showWarning = false;
	}
	if (state.showSystemEvent) {
		state.showSystemEvent = false;
	}
	if (state.showExport) {
		state.showExport = false;
	}
};

const showWarning = () => {
	state.activePopover = "showWarning";
	state.showSystemEvent = false;
	state.showExport = false;
	warningRef.value?.getOptions();
	nextTick(() => {
		document.addEventListener("click", setPopoverHide);
	});
};

const hideWarning = () => {
	warningRef.value?.clearData();
	if (!state.showWarning && !state.showSystemEvent && !state.showExport) {
		document.removeEventListener("click", setPopoverHide);
	}
};

const showSystemEvent = () => {
	state.activePopover = "showSystemEvent";
	state.showWarning = false;
	state.showExport = false;
	systemEventRef.value.getOptions();
	nextTick(() => {
		document.addEventListener("click", setPopoverHide);
	});
};

const hideSystemEvent = () => {
	systemEventRef.value.clearData();
	if (!state.showWarning && !state.showSystemEvent && !state.showExport) {
		document.removeEventListener("click", setPopoverHide);
	}
};

const showExport = () => {
	state.activePopover = "showExport";
	state.showSystemEvent = false;
	state.showWarning = false;
	exportRef.value.getTableData();
	nextTick(() => {
		document.addEventListener("click", setPopoverHide);
	});
};

const showSystemStatus = () => {
	document.removeEventListener("click", setPopoverHide);
	state.activePopover = "";
	state.showSystemEvent = false;
	state.showWarning = false;
	state.showExport = false;
	systemStatusRef.value.getTableData();
	if (dragObject.dragDom == null) {
		dragObject.dragDom = $(".systemStatusPopper");
		dragObject.dragDom[0].addEventListener("focusout", onFocusout);
		dragObject.dragDom[0].addEventListener("focusin", onFocusin);
	}
	dragObject.dragDom.removeClass("hide");
	updatePoperPos();
}

const onFocusout = (e) => {
	e?.stopPropagation();
	e?.preventDefault();
	setTimeout(() => {
		updatePoperPos();
	});
	return false;
}

const onFocusin = (e) => {
	e.stopPropagation();
	e.preventDefault();
	updatePoperPos();
	return false;
}

const updatePoperPos = () => {
	dragObject.dragDom.css("left", dragObject.left + 'px');
	dragObject.dragDom.css("top", dragObject.top + 'px');
	dragObject.dragDom.css("width", dragObject.width + 'px');
	dragObject.dragDom.css("height", dragObject.height + 'px');
}

const closeSystemStatus = () => {
	state.showSystemStatus = false;
	dragObject.dragDom.addClass("hide");
}
const hideExport = () => {
	exportRef.value.clearData();
	if (!state.showWarning && !state.showSystemEvent && !state.showExport) {
		document.removeEventListener("click", setPopoverHide);
	}
};

const setShowGraphMenu = () => {
	store.commit('setUiMode', { uiMode: store.state.uiMode === UiModeEnum.EditStatus ? UiModeEnum.ShowStatus : UiModeEnum.EditStatus })
}

const getAlarmCount = () => {
	axiosAuth({
		method: "get",
		url: "Alarm/GetAlarmInfoCount",
		auth: "AlarmInfo_Browse"
	}).then((res) => {
		if (res.IsSuccess) {
			state.warningCount = res.Result;
			store.commit("setWarningCount", { warningCount: state.warningCount });
		}
	});
};

const onDragStart = (e) => {
	dragObject.startPos = e;
	dragObject.sourceLeft = parseFloat(dragObject.dragDom.css("left").replace('px', ''));
	dragObject.sourceTop = parseFloat(dragObject.dragDom.css("top").replace('px', ''));
	dragObject.width = parseFloat(dragObject.dragDom.css("width").replace('px', ''));
	dragObject.height = parseFloat(dragObject.dragDom.css("height").replace('px', ''));
}

const onDragStop = () => {
	dragObject.left = dragObject.dragDom.css("left").replace('px', '');
	dragObject.top = dragObject.dragDom.css("top").replace('px', '');
	dragObject.width = parseFloat(dragObject.dragDom.css("width").replace('px', ''));
	dragObject.height = parseFloat(dragObject.dragDom.css("height").replace('px', ''));
}

const onDragging = (e) => {
	let delatX = e.clientX - dragObject.startPos.clientX;
	let delatY = e.clientY - dragObject.startPos.clientY;
	let left = dragObject.sourceLeft + delatX;
	let top = dragObject.sourceTop + delatY;
	const body = $("body");
	if (left < 0) left = 0;
	if (left + dragObject.width >= body.width()) left = body.width() - dragObject.width;
	if (top <= 0) top = 0;
	if (top + dragObject.height >= body.height()) top = body.height() - dragObject.height;
	dragObject.dragDom.css("left", left + 'px');
	dragObject.dragDom.css("top", top + 'px');
}

const getExportNum = () => {
	if (!hasSystemTaskPermission.value) return;
	axiosAuth({
		method: "get",
		url: "SystemTask/count",
		auth: "SystemTask_Browse"
	}).then((res) => {
		if (res.IsSuccess) {
			state.exportNum = res.Result;
		}
	});
};

const getSystemEventCount = () => {
	if (!hasSystemEventPermission.value) return;
	axiosAuth({
		method: "get",
		url: "Event/count",
		auth: "SystemEvent_Browse"
	}).then((res) => {
		if (res.IsSuccess) {
			state.eventNum = res.Result;
		}
	});
};

const router = useRouter();
const handleLogOut = () => {
	try {
		const { UserName } = store.state.userInfo;
		const tokens = getTokens();
		if (ruleCheck.IsNullOrSpaceStr(tokens.token)) {
			router.push("/");
			window.location.reload();
		}
		if (!state.externalLogin) {
			router.push("/");
		}
		setTimeout(() => {
			axios({
				method: "post",
				url: "Login/LogOut",
				data: {
					userName: UserName,
					refreshToken: tokens.refreshToken,
					token: tokens.token,
				},
			}).then((res) => {
				clearLoginMsg();
				if (state.externalLogin) {
					window.location.href = Config.externaLogOutUrl;
				}
			});
		});
	} catch {
		clearLoginMsg();
		router.push("/");
		window.location.reload();
	}
};

const clearLoginMsg = () => {
	if (!ruleCheck.IsNullOrSpaceStr(store.state?.userInfo?.UserName)) {
		store.commit("reset", {});
	}
}

const loadPrimaryColor = () => {
	const pre = "--el-color-primary";
	const customPre = "--phm-primary-color";
	const customLightPre = "--phm-primary-light-color";

	// 白色混合色
	const mixWhite = "#ffffff";
	// 黑色混合色
	const mixBlack = "#000000";
	const node = document.documentElement;

	const color = store.state.primaryColor;
	const CustomColor = getHexOpacityColor(color, 0.15);

	node.style.setProperty(pre, color);
	node.style.setProperty(customPre, color);
	node.style.setProperty(customLightPre, CustomColor);

	for (let i = 1; i < 10; i += 1) {
		node.style.setProperty(`${pre}-light-${i}`, mixColor(color, mixWhite, i * 0.1));
	}
	for (let i = 1; i < 3; i += 1) {
		node.style.setProperty(`--el-color-primary-dark-${i}`, mixColor(color, mixBlack, i * 0.1));
	}
};

const loadTheme = (theme) => {
	const backPre = "--phm-theme-background";
	const cardPre = "--phm-theme-card";
	const titlePre = "--phm-theme-title";
	const mainTextPre = "--phm-theme-mainText";
	const tipPre = "--phm-theme-tip";
	const borderPre = "--phm-theme-border";

	const { background, card, border, title, mainText, tip } = store.state.customColor;
	const node = document.documentElement;
	node.style.setProperty(backPre, background);
	node.style.setProperty(cardPre, card);
	node.style.setProperty(titlePre, title);
	node.style.setProperty(mainTextPre, mainText);
	node.style.setProperty(tipPre, tip);
	node.style.setProperty(borderPre, border);
};

class SystemEventSignalRHandler extends ISignalRHandler {
	constructor() {
		super();
		this.SupportContentType = [ContractTypes.SystemEventInformation];
	}

	ReceiveData(data) {
		if (data.ContentType == ContractTypes.SystemEventInformation) {
			if (data.Content != null && data.Content.length > 0) {
				var msg = JSON.parse(data.Content[0].Data);
				if (msg.EventNum === undefined) {
					state.eventNum++;
				} else {
					state.eventNum = msg.EventNum;
				}
			}
		}
	}
}

class WarningSignalRHandler extends ISignalRHandler {
	constructor() {
		super();
		this.SupportContentType = [ContractTypes.AlarmInfoUpdate];
		this.Debounce = new Debounce();
	}

	ReceiveData(data) {
		if (data.ContentType == ContractTypes.AlarmInfoUpdate) {
			if (data.Content != null) {
				this.Debounce.run(() => {
					// state.warningCount = Number(data.Content[0].Data);
					getSoundAlarmLevel();
					sendAlarmReport();
					getAlarmCount();
					// store.commit("setWarningCount", { warningCount: state.warningCount });
					store.commit("setWarningStatus", { warningStatus: !store.state.warningStatus });
				}, 2000)
			}
		}
	}
}

class ExportSignalRHandler extends ISignalRHandler {
	constructor() {
		super();
		this.SupportContentType = [ContractTypes.ExportTaskNumChanged];
	}

	ReceiveData(data) {
		if (data.ContentType == ContractTypes.ExportTaskNumChanged) {
			if (data.Content != null) {
				//state.exportNum = Number(data.Content[0].SystemTaskNum);
				getExportNum();
			}
		}
	}
}

const sendAlarmReport = () => {
	if (window.Notification) {
		if (Notification.permission == "granted") {
			popNotice();
		} else if (Notification.permission != "denied") {
			Notification.requestPermission(function (permission) {
				popNotice();
			});
		}
	}
};

/** 浏览器限制，页面必须在用户交互之后才会自动播放音视频，因此设置定时器重复尝试播放音频 */
const tryPlayAudio = () => {
	const audioDom = document.getElementById('alarmAudio');
	if (audioDom) {
		audioDom.play().catch(() => {
			setTimeout(() => {
				tryPlayAudio();
			}, 500)
		});
	}
}

const getSoundAlarmLevel = () => {
	// no permisson control
	axios({
		method: "get",
		url: "Alarm/GetSoundAlarmLevel",
	}).then((res) => {
		if (res.IsSuccess) {
			state.soundAlarmLevel = res.Result;
			if (res.Result == 1) {
				store.commit("setAlarmSrc", { soundAlarmSrc: '' });
			} else {
				// no permisson control
				axios({
					method: "get",
					url: `AlarmConfig/AlarmAudioPathByLevel/${res.Result}`,
				}).then((response) => {
					if (response.IsSuccess) {
						const audioPathPrefix = getSourceUrl();
						store.commit("setAlarmSrc", { soundAlarmSrc: audioPathPrefix + response.Result });
						tryPlayAudio();
					}
				});
			}
		}
	});
};

const popNotice = () => {
	if (Notification.permission == "granted") {
		const str = i18nGlobal('mainLayout.header.hello') + "：";
		var notification = new Notification(str, {
			body: i18nGlobal('mainLayout.header.alarmUpdateTip'),
		});
		setTimeout(() => {
			notification.close();
		}, 10 * 1000);
		notification.onclick = function () {
			notification.close();
		};
	}
};

const showExportTasks = (radio = 0) => {
	state.showExport = true;
	exportRef.value?.setDefaultRadio(radio);
};

const setTitle = () => {
	const isJuhua = window.Config.project.props.projectCode === 'juhua';
	if (store.state.licenseInfo?.TitleImageBase64Str?.Base64Str) {
		let base = "data:image/png;base64," + store.state.licenseInfo?.TitleImageBase64Str?.Base64Str;
		if(isJuhua) {
			base = new URL(`../../assets/img/juhua_logo.png`, import.meta.url).href
		}
		state.titleUrl = base;
		state.headerHeight = store.state.licenseInfo?.TitleHeight ?? 30;
		nextTick(() => {
			const height = $("#titleLogoImg").height();
			const width = $("#titleLogoImg").width();
			const percent = 26 / height;
			$("#titleLogoImg").width(width * percent);
			$("#titleLogoImg").height(height * percent);
			state.showImg = true;
		});
	} else {
		state.showImg = false;
	}
};

const getVersion = () => {
	// no permisson control
	axios({
		method: "get",
		url: "Product/ProductInfo",
	}).then((res) => {
		if (res.IsSuccess) {
			state.version = "V" + res.Result.Version;
		}
	});
};

onMounted(() => {
	state.externalLogin = Config.externalLogin ?? false;
	const loading = fullScreenLoading(i18nGlobal('mainLayout.header.loadTheme'));
	loadTheme();
	loadPrimaryColor();
	state.primaryColor = store.state.primaryColor;
	loading.close();
	getAlarmCount();
	getSoundAlarmLevel();
	getExportNum();
	getSystemEventCount();
	getVersion();
	nextTick(() => {
		const signalr = SignalRInstance.GetInstance();
		signarlCache.systemEvent = new SystemEventSignalRHandler();
		signarlCache.warning = new WarningSignalRHandler();
		signarlCache.export = new ExportSignalRHandler();
		signalr?.Add(signarlCache.systemEvent);
		signalr?.Add(signarlCache.warning);
		signalr?.Add(signarlCache.export);
	});
	setTitle();
});

const getCopyRight = () => {
	const waitTime = 200;
	let currentTime = new Date().getTime();
	state.copyInfo.clickCount = currentTime - state.copyInfo.lastTime < waitTime ? state.copyInfo.clickCount + 1 : 1;
	state.copyInfo.lastTime = new Date().getTime();
	clearTimeout(state.copyInfo.timer);
	state.copyInfo.timer = setTimeout(() => {
		// state.count = 1;
		if (state.copyInfo.clickCount > 3) {
			state.copyRightDialog = true;
		}
	}, waitTime + 10);
};

onBeforeUnmount(() => {
	signarlCache.systemEvent?.Dispose();
	signarlCache.warning?.Dispose();
	signarlCache.export?.Dispose();

	if (dragObject.dragDom) {
		dragObject.dragDom[0].removeEventListener("focusout", onFocusout);
		dragObject.dragDom[0].removeEventListener("focusin", onFocusin)
		dragObject.dragDom = null;
	}
});

defineExpose({
	hidePopover,
	showExportTasks,
});
</script>

<style scoped lang="less">
@import "@styles/variable.less";

#titleLogoImg {
	height: 28px;
	width: 140px!important;
	&.headTheme2 {
		height: 28px;
		width: 140px!important;
	}
}
.header {
	display: flex;
	align-items: center;
	justify-content: space-between;

	.copyright {
		width: 40px;
		height: 30px;
	}

	.leftInfo {
		display: flex;

		.logo {
			display: flex;
			align-items: center;

			img {
				visibility: hidden;

				&.visible {
					visibility: visible;
				}
			}
		}

		.title {
			display: inline-block;
			font-size: 16px;
			margin-left: 20px;
			color: var(--phm-theme-title);
			white-space: nowrap;
			margin-right: 10px;

			&.margin0 {
				margin-left: 0;
			}
			&.headTheme2 {
				color: var(--phm-theme-title);
			}
		}
	}

	.rightIcon {
		ul {
			display: flex;

			li {
				margin-right: 30px;
				cursor: pointer;
				display: flex;
				&.noPopover {
					align-items: center;
					margin-right: 15px;
				}

				// &.export {
				//     margin-right: 16px;

				//     svg {
				//         margin-top: 16px;
				//     }
				// }

				&.line {
					margin-right: 16px;
					border-right: 1px solid var(--phm-theme-border);
					margin-top: 20px;
					height: 14px;
				}

				&.userInfo {
					display: flex;
					align-items: center;
					white-space: nowrap;
				}

				&.color {
					position: relative;
					margin-right: 22px;

					.el-color-picker {
						position: absolute;
					}

					svg {
						position: absolute;
						top: 6px;
						left: 6px;
					}
				}

				.el-badge.item {
					margin-top: 10px;

					:deep(sup) {
						min-width: 30px;
						height: 20px;
					}
				}

				:deep(.el-badge__content.el-badge__content--danger.is-fixed) {
					transform: translateY(-35%) translateX(110%) scale(0.8);
					padding: 0 4px;
					top: 8px;
					right: calc(1px + var(--el-badge-size) / 1.5);
				}

				&:last-child {
					margin-right: 0;
					margin-left: 0px;
				}

				.userIcon {
					margin-top: 4px;
				}

				.userName {
					font-size: 13px;
					color: #fff;
					display: inline-block;
					margin-left: 2px;
					max-width: 100px;
					height: 20px;
					line-height: 20px;
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
					&.headTheme2 {
						color: var(--phm-primary-color);
					}
				}
			}
		}
	}
}

:deep(.right-icon) {
	vertical-align: sub;
	margin-left: 20px;
}

.submenu {
	li {
		height: 30px;
		line-height: 30px;
		cursor: pointer;
		user-select: none;
		vertical-align: bottom;

		&:hover {
			color: @primaryColor;
		}
	}
}
</style>

<style>
.systemStatusPopper {
	resize: both;
	overflow: hidden;
}
</style>
