import $ from "jquery";
import { PluginMode, ScaleType } from "@core/graphs/webcharts/Dh-webchart-models";
import { SvgButtonBase, PluginBase } from "./DH-plugin-base";

export const XAxisLeftType = {
	Shape: 0,
	Chart: 1,
};

export class ZoomPluginBase extends SvgButtonBase {
	constructor() {
		super();
	}
}

export class YAxisZoomOutPlugin extends ZoomPluginBase {
	constructor() {
		super();
		this.Width = 15;
		this.Height = 15;
	}
	GetSvgIcon() {
		let g = document.createElementNS("http://www.w3.org/2000/svg", "g");
		$(g).css("width", this.Width).css("height", this.Height).css("cursor", "auto");
		$(g).append(this.AddPath("M1.5,5c0.98-2.349,3.297-4,6-4s5.02,1.651,6,4h1.064c-1.031-2.911-3.8-5-7.064-5 S1.466,2.089,0.436,5H1.5z", "1"));
		$(g).append(this.AddPath("M13.5,10c-0.98,2.349-3.297,4-6,4s-5.02-1.651-6-4H0.436c1.031,2.911,3.8,5,7.064,5 s6.034-2.089,7.064-5H13.5z", "1"));
		$(g).append(this.AddRect(7, 4, 1, 7));
		$(g).append(this.AddRect(4, 7, 7, 1));
		return $(g);
	}
	OnSizeChanged() {
		let info = this.Owner.Information;
		let rect = info.ShapeContainerRect;
		let leftPadding = this.Entity.PaddingLeft != null ? this.Entity.PaddingLeft : 0;
		this.Left = rect.Left + leftPadding;
		this.Top = info.Padding.Top - this.Height - this.Padding;
		this.Translate = "translate(" + this.Left + "," + this.Top + ")";
		this.Dom.attr("transform", this.Translate);
	}
	OnClick() {
		if (!this.IsEnabled) return;
		this.Owner.Information.YZoomOut();
		if (this.Entity.onClick) {
			this.Entity.onClick(this);
		} else {
			this.Owner.OnMeasure();
			this.Owner.OnRender();
		}
	}
}

export class YAxisZoomInPlugin extends ZoomPluginBase {
	constructor() {
		super();
		this.Width = 15;
		this.Height = 15;
	}
	GetSvgIcon() {
		let g = document.createElementNS("http://www.w3.org/2000/svg", "g");
		$(g).css("width", this.Width).css("height", this.Height).css("cursor", "auto");
		$(g).append(this.AddPath("M1.5,5c0.98-2.349,3.297-4,6-4s5.02,1.651,6,4h1.064c-1.031-2.911-3.8-5-7.064-5 S1.466,2.089,0.436,5H1.5z", "1"));
		$(g).append(this.AddPath("M13.5,10c-0.98,2.349-3.297,4-6,4s-5.02-1.651-6-4H0.436c1.031,2.911,3.8,5,7.064,5 s6.034-2.089,7.064-5H13.5z", "1"));
		$(g).append(this.AddRect(4, 7, 7, 1));
		return $(g);
	}
	OnSizeChanged() {
		let info = this.Owner.Information;
		let rect = info.ShapeContainerRect;
		let leftPadding = this.Entity.PaddingLeft != null ? this.Entity.PaddingLeft : 0;
		this.Left = rect.Left + this.Height + 10 + leftPadding;
		this.Top = info.Padding.Top - this.Height - this.Padding;
		this.Translate = "translate(" + this.Left + "," + this.Top + ")";
		this.Dom.attr("transform", this.Translate);
	}
	OnClick() {
		if (!this.IsEnabled) return;
		this.Owner.Information.YZoomIn();
		if (this.Entity.onClick) {
			this.Entity.onClick(this);
		} else {
			this.Owner.OnMeasure();
			this.Owner.OnRender();
		}
	}
}

export class XAxisZoomOutPlugin extends ZoomPluginBase {
	constructor() {
		super();
		this.Width = 15;
		this.Height = 15;
	}
	Init(owner, entity) {
		super.Init(owner, entity);
		if (entity.Padding) {
			this.Padding = entity.Padding;
		}
	}
	GetSvgIcon() {
		let g = document.createElementNS("http://www.w3.org/2000/svg", "g");
		$(g).css("width", this.Width).css("height", this.Height).css("cursor", "auto");
		$(g).append(
			this.AddPath(
				"M-3.4999899864196777,9.812490336596966 C-2.5199899864196778,7.463490336596966 -0.20298998641967805,5.812490336596966 2.5000100135803223,5.812490336596966 S7.520010013580322,7.463490336596966 8.500010013580322,9.812490336596966 L9.564010013580322,9.812490336596966 C8.533010013580322,6.901490336596966 5.7640100135803225,4.812490336596966 2.5000100135803223,4.812490336596966 S-3.5339899864196775,6.901490336596966 -4.563989986419678,9.812490336596966 L-3.4999899864196777,9.812490336596966 z",
				"1",
				"rotate(-89.71548461914062 2.5000100135803236,7.312490463256837)"
			)
		);
		$(g).append(
			this.AddPath(
				"M18.56248950958252,4.812510646879673 C17.58248950958252,7.161510646879673 15.265489509582519,8.812510646879673 12.56248950958252,8.812510646879673 S7.54248950958252,7.161510646879673 6.5624895095825195,4.812510646879673 L5.4984895095825195,4.812510646879673 C6.52948950958252,7.723510646879673 9.29848950958252,9.812510646879673 12.56248950958252,9.812510646879673 S18.59648950958252,7.723510646879673 19.62648950958252,4.812510646879673 L18.56248950958252,4.812510646879673 z",
				"1",
				"rotate(-90.10647583007812 12.56248950958252,7.312509536743165)"
			)
		);
		$(g).append(this.AddRect(7, 4, 1, 7));
		$(g).append(this.AddRect(4, 7, 7, 1));
		return $(g);
	}
	OnSizeChanged() {
		let info = this.Owner.Information;
		let rect = info.ShapeContainerRect;
		if (this.Entity.LeftMode == XAxisLeftType.Chart) {
			this.Left = info.ChartContainerRect.Width - info.Padding.Right + this.Padding;
		} else {
			this.Left = rect.Left + rect.Width + this.Padding;
		}
		this.Top = rect.Top + rect.Height - this.Height;
		this.Translate = "translate(" + this.Left + "," + this.Top + ")";
		this.Dom.attr("transform", this.Translate);
	}
	OnClick() {
		if (!this.IsEnabled) return;
		this.Owner.Information.XZoomOut();
		if (this.Entity.onClick) {
			this.Entity.onClick(this);
		} else {
			this.Owner.OnMeasure();
			this.Owner.OnRender();
		}
	}
}

export class XAxisZoomInPlugin extends ZoomPluginBase {
	constructor() {
		super();
		this.Width = 15;
		this.Height = 15;
	}
	Init(owner, entity) {
		super.Init(owner, entity);
		if (entity.Padding) {
			this.Padding = entity.Padding;
		}
	}
	GetSvgIcon() {
		let g = document.createElementNS("http://www.w3.org/2000/svg", "g");
		$(g).css("width", this.Width).css("height", this.Height).css("cursor", "auto");
		$(g).append(
			this.AddPath(
				"M-3.4999899864196777,9.812490336596966 C-2.5199899864196778,7.463490336596966 -0.20298998641967805,5.812490336596966 2.5000100135803223,5.812490336596966 S7.520010013580322,7.463490336596966 8.500010013580322,9.812490336596966 L9.564010013580322,9.812490336596966 C8.533010013580322,6.901490336596966 5.7640100135803225,4.812490336596966 2.5000100135803223,4.812490336596966 S-3.5339899864196775,6.901490336596966 -4.563989986419678,9.812490336596966 L-3.4999899864196777,9.812490336596966 z",
				"1",
				"rotate(-89.71548461914062 2.5000100135803236,7.312490463256837)"
			)
		);
		$(g).append(
			this.AddPath(
				"M18.56248950958252,4.812510646879673 C17.58248950958252,7.161510646879673 15.265489509582519,8.812510646879673 12.56248950958252,8.812510646879673 S7.54248950958252,7.161510646879673 6.5624895095825195,4.812510646879673 L5.4984895095825195,4.812510646879673 C6.52948950958252,7.723510646879673 9.29848950958252,9.812510646879673 12.56248950958252,9.812510646879673 S18.59648950958252,7.723510646879673 19.62648950958252,4.812510646879673 L18.56248950958252,4.812510646879673 z",
				"1",
				"rotate(-90.10647583007812 12.56248950958252,7.312509536743165)"
			)
		);
		$(g).append(this.AddRect(4, 7, 7, 1));
		return $(g);
	}
	OnSizeChanged() {
		let info = this.Owner.Information;
		let rect = info.ShapeContainerRect;
		if (this.Entity.LeftMode == XAxisLeftType.Chart) {
			this.Left = info.ChartContainerRect.Width - info.Padding.Right + this.Padding;
		} else {
			this.Left = rect.Left + rect.Width + this.Padding;
		}
		this.Top = rect.Top + rect.Height - this.Height * 2 - 10;
		this.Translate = "translate(" + this.Left + "," + this.Top + ")";
		this.Dom.attr("transform", this.Translate);
	}
	OnClick() {
		if (!this.IsEnabled) return;
		this.Owner.Information.XZoomIn();
		if (this.Entity.onClick) {
			this.Entity.onClick(this);
		} else {
			this.Owner.OnMeasure();
			this.Owner.OnRender();
		}
	}
}

export class EnlargePlugin extends PluginBase {
	constructor() {
		super();
		this.CanEnlarge = false;
		this.RectDom = null;
		this.StartPos = null;
		this.EndPos = null;
	}
	Init(owner, entity) {
		super.Init(owner, entity);
		this.Owner.OnMouseDown.delete(this.Id);
		this.Owner.OnMouseUp.delete(this.Id);
		this.Owner.OnMouseMove.delete(this.Id);
		let sender = this;
		this.Owner.OnMouseDown.set(this.Id, function (e) {
			sender.OnMouseDown(e);
		});
		this.Owner.OnMouseUp.set(this.Id, function (e) {
			sender.OnMouseUp(e);
		});
		this.Owner.OnMouseMove.set(this.Id, function (e) {
			sender.OnMouseMove(e);
		});
	}
	OnMouseDown(e) {
		if (e.which != 1) return false;
		if (!window.event.ctrlKey) return false;
		e.preventDefault();
		// e.stopPropagation();
		let rect = this.Owner.Information.ShapeContainerRect;
		if (e.offsetX < rect.Left || e.offsetX > rect.Left + rect.Width || e.offsetY < rect.Top || e.offsetY > rect.Top + rect.Height) return false;
		this.CanEnlarge = true;
		this.StartPos = {
			X: e.offsetX,
			Y: e.offsetY,
		};
		this.RectDom = $(document.createElementNS("http://www.w3.org/2000/svg", "rect"));
		this.RectDom.attr("x", e.offsetX).attr("y", e.offsetY).attr("width", 0).attr("height", 0).css("fill", "#468fcc80");
		$(this.Owner.PluginContainer).append(this.RectDom);
		return true;
	}
	OnMouseUp(e) {
		if (!this.IsEnabled) return false;
		if (!this.CanEnlarge) return false;
		if (this.RectDom == null) return false;
		e.preventDefault();
		e.stopPropagation();
		this.RectDom.remove();
		this.CanEnlarge = false;
		this.RectDom = null;
		try {
			let rect = this.Owner.Information.ShapeContainerRect;
			let x1 = this.Owner.CoordinateSystem.GetXValue(this.StartPos.X - rect.Left);
			let x2 = this.Owner.CoordinateSystem.GetXValue(this.EndPos.X - rect.Left);
			let y1 = this.Owner.CoordinateSystem.GetYValue(this.StartPos.Y - rect.Top);
			let y2 = this.Owner.CoordinateSystem.GetYValue(this.EndPos.Y - rect.Top);
			let coord = this.Owner.Information.CoordinateInfo;
			coord.XMax = x1 < x2 ? x2 : x1;
			coord.XMin = x1 < x2 ? x1 : x2;
			if (this.Owner.Option.Axis.AxisScaleType != ScaleType.Auto) {
				coord.YMax = y1 < y2 ? y2 : y1;
				coord.YMin = y1 < y2 ? y1 : y2;
				coord.YRate = 1;
			}
			coord.IsEnlarge = true;
			coord.XMinRate = (coord.XMin - coord.OriginXMin) / (coord.OriginXMax - coord.OriginXMin);
			coord.XMaxRate = (coord.XMax - coord.OriginXMin) / (coord.OriginXMax - coord.OriginXMin);
			if (this.Entity.onClick) {
				this.Entity.onClick(this);
			} else {
				this.Owner.OnMeasure();
				this.Owner.OnRender();
			}
			this.StartPos = null;
			this.EndPos = null;
		} catch { }
		return true;
	}
	OnMouseMove(e) {
		if (!this.IsEnabled) return false;
		if (!this.CanEnlarge) return false;
		if (this.RectDom == null) return false;
		e.preventDefault();
		e.stopPropagation();
		let rect = this.Owner.Information.ShapeContainerRect;
		let x = e.offsetX;
		let y = e.offsetY;
		if (x < rect.Left) x = rect.Left;
		if (x > rect.Left + rect.Width) x = rect.Left + rect.Width;
		if (y < rect.Top) y = rect.Top;
		if (y > rect.Top + rect.Height) y = rect.Top + rect.Height;
		let widht = x - this.StartPos.X;
		let height = y - this.StartPos.Y;
		if (x < this.StartPos.X) {
			this.RectDom.attr("x", x);
		}
		if (y < this.StartPos.Y) {
			this.RectDom.attr("y", y);
		}
		this.EndPos = {
			X: x,
			Y: y,
		};
		this.RectDom.attr("width", Math.abs(widht));
		this.RectDom.attr("height", Math.abs(height));
		return true;
	}
	Close() {
		super.Close();
		this.Owner.OnMouseDown.delete(this.Id);
		this.Owner.OnMouseUp.delete(this.Id);
		this.Owner.OnMouseMove.delete(this.Id);
	}
}

export class AxisEndPlugin extends SvgButtonBase {
	constructor() {
		super();
		this.Width = 4;
		this.Height = 20;
		this.Left = 4;
		this.Top = 4;
	}
	CreatePlugin() {
		this.MeasureSize();
		super.CreatePlugin();
	}
	GetSvgIcon() {
		let g = document.createElementNS("http://www.w3.org/2000/svg", "g");
		$(g).css("width", this.Width).css("height", this.Height).css("fill", "red");
		return $(g);
	}
	OnSizeChanged() {
		this.MeasureSize();
		this.Translate = "translate(" + this.Left + "," + this.Top + ")";
		this.Dom.css("width", this.Width).css("height", this.Height);
		this.Dom.attr("transform", this.Translate);
		this.Dom.css("cursor", "pointer");
	}
	MeasureSize() {
		let info = this.Owner.Information;
		let rect = info.ShapeContainerRect;
		let coordSys = this.Owner.CoordinateSystem;
		let endText = coordSys.XTexts[coordSys.XTexts.length - 1];
		let textSize = coordSys.GetXTextSize(endText);
		this.Width = textSize.Width;
		let temp = coordSys.GetXOffsetColumn(2, 2);
		this.Left = rect.Left + rect.Width - textSize.Width / 2 + temp;
		this.Top = rect.Height + rect.Top + textSize.Height / 2;
	}
	OnDblClick() {
		let pluginManager = this.Owner.PluginManager;
		pluginManager.GetPlugin(PluginMode.SureButton).SetVisible(true);
		pluginManager.GetPlugin(PluginMode.CancelButton).SetVisible(true);
		if (this.Entity.OnDblClick) {
			this.Entity.OnDblClick();
		}
	}
}

export class SureButtonPlugin extends SvgButtonBase {
	constructor() {
		super();
		this.Width = 15;
		this.Height = 15;
	}
	CreatePlugin() {
		super.CreatePlugin();
		this.SetVisible(false);
	}
	GetSvgIcon() {
		let g = document.createElementNS("http://www.w3.org/2000/svg", "g");
		$(g).css("width", this.Width).css("height", this.Height);
		$(g).append(
			this.AddPath(
				"M7.5,1.1c0.9,0,1.7,0.2,2.5,0.5c0.8,0.3,1.5,0.8,2,1.4c0.6,0.6,1.1,1.3,1.4,2c0.3,0.8,0.5,1.6,0.5,2.5s-0.2,1.7-0.5,2.5  c-0.3,0.8-0.8,1.5-1.4,2c-0.6,0.6-1.3,1.1-2,1.4c-0.8,0.3-1.6,0.5-2.5,0.5S5.8,13.8,5,13.4c-0.8-0.3-1.5-0.8-2-1.4  c-0.6-0.6-1.1-1.3-1.4-2C1.2,9.2,1.1,8.4,1.1,7.5S1.2,5.8,1.6,5c0.3-0.8,0.8-1.5,1.4-2c0.6-0.6,1.3-1.1,2-1.4  C5.8,1.2,6.6,1.1,7.5,1.1 M7.5,0C3.4,0,0,3.4,0,7.5S3.4,15,7.5,15S15,11.6,15,7.5S11.6,0,7.5,0z",
				"1"
			)
		);
		$(g).append(
			this.AddPath(
				"M6.2,10.5c-0.3,0-0.6-0.1-0.8-0.3L3.3,8c-0.2-0.2-0.2-0.5,0-0.7C3.5,7,3.8,7,4,7.2l2.2,2.2c0,0,0,0,0.1,0s0,0,0.1,0L11,4.7  c0.2-0.2,0.5-0.2,0.7,0c0.2,0.2,0.2,0.5,0,0.7L7,10.1C6.8,10.4,6.5,10.5,6.2,10.5z",
				"1"
			)
		);
		return $(g);
	}
	OnSizeChanged() {
		let info = this.Owner.Information;
		let rect = info.ShapeContainerRect;
		this.Left = info.ChartContainerRect.Width - info.Padding.Right - 50;
		this.Top = rect.Top + rect.Height - (this.Height / 2) * 3;
		this.Translate = "translate(" + this.Left + "," + this.Top + ")";
		this.Dom.attr("transform", this.Translate);
	}
	SetVisible(b) {
		this.Dom.css("display", b ? "block" : "none");
	}
	OnClick() {
		this.SetVisible(false);
		let pluginManager = this.Owner.PluginManager;
		pluginManager.GetPlugin(PluginMode.CancelButton).SetVisible(false);
		if (this.Entity.onClick) {
			this.Entity.onClick();
		}
	}
}

export class CancelButtonPlugin extends SureButtonPlugin {
	constructor() {
		super();
	}
	GetSvgIcon() {
		let g = document.createElementNS("http://www.w3.org/2000/svg", "g");
		$(g).css("width", this.Width).css("height", this.Height);
		$(g).append(
			this.AddPath(
				"M7.5,1.1c0.9,0,1.7,0.2,2.5,0.5c0.8,0.3,1.5,0.8,2,1.4c0.6,0.6,1.1,1.3,1.4,2c0.3,0.8,0.5,1.6,0.5,2.5s-0.2,1.7-0.5,2.5  c-0.3,0.8-0.8,1.5-1.4,2c-0.6,0.6-1.3,1.1-2,1.4c-0.8,0.3-1.6,0.5-2.5,0.5S5.8,13.8,5,13.4c-0.8-0.3-1.5-0.8-2-1.4  c-0.6-0.6-1.1-1.3-1.4-2C1.2,9.2,1.1,8.4,1.1,7.5S1.2,5.8,1.6,5c0.3-0.8,0.8-1.5,1.4-2c0.6-0.6,1.3-1.1,2-1.4  C5.8,1.2,6.6,1.1,7.5,1.1 M7.5,0C3.4,0,0,3.4,0,7.5S3.4,15,7.5,15S15,11.6,15,7.5S11.6,0,7.5,0z",
				"1"
			)
		);
		$(g).append(this.AddPath("M5.1,10.4c-0.1,0-0.3-0.1-0.4-0.2c-0.2-0.2-0.2-0.5,0-0.7l4.7-4.7c0.2-0.2,0.5-0.2,0.7,0c0.2,0.2,0.2,0.5,0,0.7l-4.7,4.7  C5.4,10.3,5.3,10.4,5.1,10.4z", "1"));
		$(g).append(this.AddPath("M9.9,10.4c-0.1,0-0.3-0.1-0.4-0.2L4.8,5.5C4.6,5.3,4.6,5,4.8,4.8c0.2-0.2,0.5-0.2,0.7,0l4.7,4.7c0.2,0.2,0.2,0.5,0,0.7  C10.1,10.3,10,10.4,9.9,10.4L9.9,10.4z", "1"));
		return $(g);
	}
	OnSizeChanged() {
		let info = this.Owner.Information;
		let rect = info.ShapeContainerRect;
		this.Left = info.ChartContainerRect.Width - info.Padding.Right - 20;
		this.Top = rect.Top + rect.Height - (this.Height / 2) * 3;
		this.Translate = "translate(" + this.Left + "," + this.Top + ")";
		this.Dom.attr("transform", this.Translate);
	}
	OnClick() {
		this.SetVisible(false);
		let pluginManager = this.Owner.PluginManager;
		pluginManager.GetPlugin(PluginMode.SureButton).SetVisible(false);
		if (this.Entity.onClick) {
			this.Entity.onClick();
		}
	}
}
