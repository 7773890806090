import $ from "jquery";
import { PluginMode } from "@core/graphs/webcharts/Dh-webchart-models";
import { CursorElement, CursorPluginBase } from "@core/graphs/plugins/Dh-plugin-cursor-base";
import { i18nGlobal } from "@common/js/utils";

const getMaxPointY = (point) => {
    let Y = point.Y;
    /** 记录仪 */
    if (typeof point.Y1 == "number") {
        Y = Y > point.Y1 ? Y : point.Y1;
    }
    return Y;
}

const getMinPointY = (point) => {
    let Y = point.Y;
    /** 记录仪 */
    if (typeof point.Y1 == "number") {
        Y = Y < point.Y1 ? Y : point.Y1;
    }
    return Y;
}

export class PeakValueCursorPlugin extends CursorPluginBase {
    constructor() {
        super();
        this.Cursor = null;
        this.Title = i18nGlobal('core.peakValueCursor');
        this.CursorData = null;
    }
    OnRender() {
        let serieses = this.Owner.ShapeOperator.Data;
        let legend = this.Owner.PluginManager.GetPlugin(PluginMode.Statistics);
        let seriesData = null;
        $.each(serieses, function (i, series) {
            if (series.PointId == legend.SelectedItem) {
                seriesData = series;
            }
        });
        if (seriesData == null || seriesData.Points.length == 0) {
            let container = this.LegendWnd.find(".cursor-container");
            container.empty();
            return;
        }
        let maxPoint = seriesData.Points[0];
        for (let k = 0; k < seriesData.Points.length; k++) {
            let Y = getMaxPointY(seriesData.Points[k]);
            let maxPointY = getMaxPointY(maxPoint);
            if (Y >= maxPointY) {
                maxPoint = seriesData.Points[k];
            }
        }
        let info = this.Owner.Information;
        //let offsetx = (maxPoint.X - info.CoordinateInfo.XMin) / info.XUnit;

        let offsetx = this.Owner.CoordinateSystem.GetXOffset(maxPoint.X) - info.ShapeContainerRect.Left;
        offsetx > info.ShapeContainerRect.Width && (offsetx = info.ShapeContainerRect.Width);

        let data = this.Owner.CoordinateSystem.GetPositionData(offsetx);
        if (data) this.UpdatePosition(data);
    }
    OnSizeChanged() {
        super.OnSizeChanged();
        let rect = this.Owner.Information.ShapeContainerRect;
        if (this.Cursor != null) {
            this.Cursor.Remove();
        }
        this.Cursor = new CursorElement(this, rect.Height, this.Owner.CoordinateSystem.AxisColor, "1", { X: rect.Left, Y: rect.Top });
        $(this.Owner.PluginContainer).append(this.Cursor.DOM);
    }
    OnMouseDown(e) { }
    OnMouseMove(e) { }
    KeyLeftPress() { }
    KeyRightPress() { }
    UpdatePosition(data) {
        let sender = this;
        let rect = this.Owner.Information.ShapeContainerRect;
        if (this.Cursor == null) return;
        this.Cursor.ClearPoints();
        let container = this.LegendWnd.find(".cursor-container");
        if (data) {
            container.empty();
            container.append('<span class="cursor-row">X:' + data.XText + "</span>");
            $.each(data.Items, function (i, d) {
                container.append('<span class="cursor-row" style="color:' + d.Color + '">Y:' + d.Y + "</span>");
                sender.Cursor.DrawPoint(d.OffsetY + rect.Top);
            });
            if (!isNaN(data.OffsetX) && isFinite(data.OffsetX)) {
                if (data.OffsetX >= 0 && Math.floor(data.OffsetX) <= rect.Width) {
                    this.Cursor.DOM.removeClass("hide");
                    this.Cursor.DOM.attr("transform", "translate(" + (data.OffsetX - 6) + ",0)");
                } else {
                    this.Cursor.DOM.addClass("hide");
                }
            }
        } else {
            container.empty();
            this.Cursor.DOM.attr("transform", "translate(-6,0)");
        }
        this.CursorData = data;
    }
    GetPosition() {
        let result = {
            PluginType: this.Entity.Type,
            Position: this.CursorData == null ? [] : [this.CursorData],
        };
        return result;
    }
    GetZoomPosition() {
        if (this.CursorData) {
            return this.CursorData.X;
        } else {
            let info = this.Owner.Information;
            return (info.CoordinateInfo.XMin + info.CoordinateInfo.XMax) / 2;
        }
    }
    SetPosition() {
        this.OnRender();
    }
    Close() {
        super.Close();
        if (this.Cursor != null) {
            this.Cursor.Remove();
            this.Cursor = null;
        }
        if (this.LegendWnd != null) {
            this.LegendWnd.remove();
        }
    }
    ClearData() { }
}

export class ValleyValueCursorPlugin extends PeakValueCursorPlugin {
    constructor(mode) {
        super(mode);
        this.Title = i18nGlobal('core.valleyValueCursor');
    }
    OnRender() {
        let serieses = this.Owner.ShapeOperator.Data;
        let legend = this.Owner.PluginManager.GetPlugin(PluginMode.Statistics);
        let seriesData = null;
        $.each(serieses, function (i, series) {
            if (series.PointId == legend.SelectedItem) {
                seriesData = series;
            }
        });
        if (seriesData == null || seriesData.Points.length == 0) {
            let container = this.LegendWnd.find(".cursor-container");
            container.empty();
            return;
        }
        let minPoint = seriesData.Points[0];
        for (let k = 0; k < seriesData.Points.length; k++) {
            let Y = getMinPointY(seriesData.Points[k]);
            let minPointY = getMinPointY(minPoint);
            if (Y <= minPointY) {
                minPoint = seriesData.Points[k];
            }
        }
        let info = this.Owner.Information;
        let offsetx = this.Owner.CoordinateSystem.GetXOffset(minPoint.X) - info.ShapeContainerRect.Left;
        //let offsetx = (minPoint.X - info.CoordinateInfo.XMin) / info.XUnit;
        offsetx > info.ShapeContainerRect.Width && (offsetx = info.ShapeContainerRect.Width);
        let data = this.Owner.CoordinateSystem.GetPositionData(offsetx);
        if (data) this.UpdatePosition(data);
    }
}
