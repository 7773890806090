import { ContractTypes } from "@common/js/enum";
import { TrendEigenDataRealContext } from "./eigenDataRealContext";
import axios from "@common/js/axios";

export class BlockEigenDataRealContext extends TrendEigenDataRealContext {
    constructor() {
        super();
    }
    getContractType() {
        return ContractTypes.BlockEigen;
    }
    sendRegisterPointsToClinet() {
        if (this.getConnectionIds().length == 0) return;
        this.debounce.run(() => {
            const registerGraphPoints = this.updateHistoryChangeGraphs();
            const registerPointInfos = this.getRegisterPoints();
            const connectionIds = this.getConnectionIds();
            if (connectionIds.length == 0) return;
            axios({
                method: "post",
                url: "datamanagement/register-eigen-block-data",
                data: {
                    ConnectionIds: connectionIds,
                    Points: registerPointInfos
                }
            }).then(async (res) => {
                if (res.IsSuccess) {
                    await this.processRegisterData([...this.registerPointCollection.values()], registerGraphPoints);
                    this.getAheadData();
                }
            });
        }, 100);
    }
    processWaveData(datas) {
        for (const data of datas) {
            if (data.Datas?.length == 2) {
                data.Datas = this.dataParser.get(data.Datas[0]).GetDatas(data.Datas[1], data);
                data.AlgorithmType = data.DspType;
            }
        }
    }
    getCloneData(data) {
        const dataTemp = super.getCloneData(data);
        dataTemp.Eigens = data.Eigens;
        dataTemp.Datas = data.Datas;
        return dataTemp;
    }
}