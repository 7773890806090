<template>
	<div class="aside">
		<div class="top">
			<el-input v-model="state.keyWord" :placeholder="$t('common.searchContentPlaceholder')" @keyup.enter="searchTree"
				clearable>
				<template #append>
					<el-button :icon="Search" @click="searchTree" />
				</template>
			</el-input>
			<el-tree :expand-on-click-node="false" element-loading-svg-view-box="-10, -10, 50, 50" id="aside_tree"
				element-loading-background="rgba(0, 0, 0, 0.8)" :props="{
					children: 'children',
					label: 'Name',
					isLeaf: 'IsLeaf'
				}" :empty-text="$t('common.noContent')" @node-click="nodeClick" node-key="Id" :load="getTreeNodeById"
				:lazy="state.lazyNode" @node-expand="setExpandKey" @node-collapse="removeExpandKey"
				@node-contextmenu="nodeContextmenu" :default-expanded-keys="state.defaultExpandedKeys" ref="treeRef"
				:filter-node-method="filterNode" :data="state.treeData" :highlight-current="true">
				<template #default="{ node, data }">
					<div class="treeNode" @dblclick="expandNode(node)">
						<el-icon class="customIcon" :size="24"
							:color="node.isLeaf ? 'transparent' : getSvgColor(node, data)" @click="expandNode(node)"
							v-show="!node.expanded">
							<CaretRight />
						</el-icon>
						<el-icon class="customIcon" :size="24"
							:color="node.isLeaf ? 'transparent' : getSvgColor(node, data)" @click="expandNode(node)"
							v-show="node.expanded">
							<CaretBottom />
						</el-icon>
						<svgIcon class="icon" :color="getSvgColor(node, data)" :hover="false" width="14px" height="13px"
							:name="getSvgIcon(data)">
						</svgIcon>
						<!-- <el-tooltip v-if="data.showTitle" effect="dark" :content="data.Name" placement="bottom-start">
							<div class="name">{{ data.Name }}</div>
						</el-tooltip>
						 <div class="name" v-else>{{ data.Name }}</div> -->
						<div class="name" v-title="data.Name" :style="{ color: getTextColor(node, data) }">{{ data.Name }}
						</div>
					</div>
				</template>
			</el-tree>
		</div>
		<calendar></calendar>
	</div>
	<vue3-menus ref="menuRef" :open="state.menuIsOpen" :event="state.menuEventVal" :menus="state.menus" hasIcon
		:min-width="150" :zIndex="10000">
		<template #icon="{ menu }">{{ menu.icon }}</template>
		<template #label="{ menu }">{{ menu.label }}</template>
	</vue3-menus>

	<el-dialog v-model="state.sortDialog" :draggable="true"
		:title="state.contextmenuNode?.data?.NodeType === MachineTreeNodeTypeEnum.Area ? $t('mainLayout.aside.unitSort') : $t('mainLayout.aside.sort')"
		width="700px" append-to-body>
		<div class="sortWrapper">
			<ul>
				<draggable v-model="state.sortList" group="tabs" item-key="Id" @end="onDragEnd" animation="200">
					<template #item="{ element, index }">
						<li @mouseenter="element.active = true" @mouseleave="element.active = false">
							<div class="left">
								<svgIcon class="icon" :color="element.active ? primaryColor : textColor" :hover="false"
									width="16px" height="16px" :name="TreeMap[element.NodeType]"> </svgIcon>
								<span class="name"> {{ element.Name }} </span>
							</div>
							<div class="btns" v-if="element.active">
								<svgIcon class="icon" :title="$t('mainLayout.aside.topping')" :color="primaryColor"
									:hover="false" width="16px" height="16px" name="moveTop"
									@click="move(element, index, 'top')"> </svgIcon>
								<svgIcon class="icon" :title="$t('mainLayout.aside.moveUp')" :color="primaryColor"
									:hover="false" width="16px" height="16px" name="moveUp"
									@click="move(element, index, 'up')"> </svgIcon>
								<svgIcon class="icon" :title="$t('mainLayout.aside.moveDown')" :color="primaryColor"
									:hover="false" width="16px" height="16px" name="moveDown"
									@click="move(element, index, 'down')"> </svgIcon>
								<svgIcon class="icon" :title="$t('mainLayout.aside.bottoming')" :color="primaryColor"
									:hover="false" width="16px" height="16px" name="moveBottom"
									@click="move(element, index, 'bottom')"> </svgIcon>
							</div>
						</li>
					</template>
				</draggable>
				<el-empty :description="$t('common.noContent')" v-if="state.sortList.length === 0" />
			</ul>
		</div>
		<template #footer>
			<span class="dialog-footer">
				<el-button @click="state.sortDialog = false">{{ $t('common.cancel') }}</el-button>
				<el-button type="primary" @click="submitSort">{{ $t('common.ok') }}</el-button>
			</span>
		</template>
	</el-dialog>
	<SynchronousDialog ref="synchronousRef"></SynchronousDialog>
</template>

<script setup>
import { computed, nextTick, onBeforeUnmount, onMounted, reactive, ref, watch } from "vue";
import svgIcon from "@common/components/svgIcon.vue";
import axios from "@common/js/axios";
import { Search, CaretRight, CaretBottom } from "@element-plus/icons-vue";
import { alertConfirm, alertSuccessMsg, Debounce } from "@common/js/utils";
import { ContractTypes, MachineTreeNodeTypeEnum, OrgEnum, ParentGuid, TreeOperateEnum, NormalLevel } from "@common/js/enum";
import calendar from "@common/components/calendar.vue";
import { useStore } from "vuex";
import _ from "lodash";
import { fullScreenLoading, getTextSize, setOrgs, i18nGlobal, i18nString } from "@common/js/utils";
import { ClickOutside } from "element-plus";
import draggable from "vuedraggable";
import { SignalRInstance, ISignalRHandler } from "@common/js/signalRClient";
import { ruleCheck } from "@common/js/rules";
import $ from 'jquery'
import { PhmWebStorageContext } from "@components/js/utils/indexDb";
import { Vue3Menus } from "@components/js/utils/vue3-menus";
import SynchronousDialog from "./synchronousDialog.vue";

const emit = defineEmits(["nodeClick"]);

const vClickOutside = ClickOutside;
const state = reactive({
	itemLoading: false,
	treeData: [],
	preNode: null,
	defaultExpandedKeys: [],
	expandKey: "",
	defaultProps: {
		children: "children",
		label: "Name",
		isLeaf: "IsLeaf",
	},
	iconMap: {
		1: "unit",
		2: "depart",
	},
	contextmenuNode: {},
	sortList: [],
	sortDialog: false,
	hasSortAuth: false,
	rootNode: null,
	rootResolve: null,
	isFirstRequest: true,
	isAdd: false,
	isSort: false,
	lazyNode: true,
	keyWord: "",
	isFirst: true,
	node: null,
	resolve: null,
	alarmNodeIds: [],
	alarmLevelMap: {},
	menus: [],
	menuIsOpen: false,
	menuEventVal: {}
});

const store = useStore();
const debounce = new Debounce();
const treeRef = ref(null);
const menuRef = ref(null);
const synchronousRef = ref(null);
const currentDep = computed(() => store.state.currentDep);
const primaryColor = computed(() => store.state.primaryColor);
const alarmInfo = computed(() => store.state.alarmInfo);
const textColor = computed(() => store.state.customColor.TextColor);
const isOpenOffilneSetting = computed(() => store.state.isDeviceOfflineStatus);
const TreeMap = {
	[MachineTreeNodeTypeEnum.Area]: "treeUnit",
	[MachineTreeNodeTypeEnum.Depart]: "treeDepart",
	[MachineTreeNodeTypeEnum.Device]: "treeDevice",
	[MachineTreeNodeTypeEnum.Scene]: "treeScene",
};
const signalrCache = {};

const DeviceStatusEnum = {
	None: 0,
	Online: 1,
	Offline: 2
}

const getSvgIcon = (data) => {
	if (data.NodeType !== MachineTreeNodeTypeEnum.Device) {
		return TreeMap[data.NodeType]
	} else {
		if (data.StartStopStatus === 0) return 'treeDevice';
		if (data.StartStopStatus === 1) return 'stopDevice'
		if (data.StartStopStatus === 2) return 'startDevice'
	}
}

const getOpenOffilneSettingColor = (data) => {
	if (data.Status === NormalLevel) {
		if (data.DeviceStatus === DeviceStatusEnum.Offline) {
			return store.state.customColor.TooltipColor;
		} else {
			return primaryColor.value
		}
	} else {
		return alarmInfo.value.find(t => t.Level === data.Status)?.Color || primaryColor.value;
	}
}

const getSvgColor = (node, data) => {
	if (isOpenOffilneSetting.value) {
		return getOpenOffilneSettingColor(data);
	} else {
		if (data.Status === NormalLevel) {
			if (node.isCurrent) {
				return primaryColor.value;
			} else {
				return store.state.customColor.TextColor
			}
		} else {
			return alarmInfo.value.find(t => t.Level === data.Status)?.Color || textColor.value;
		}
	}
}

const getTextColor = (node, data) => {
	if (isOpenOffilneSetting.value) {
		return getOpenOffilneSettingColor(data);
	} else {
		return node.isCurrent ? primaryColor.value : store.state.customColor.TextColor;
	}
}

const nodeClick = (data, node, e) => {
	state.menuIsOpen = false;
	emit("nodeClick"); // 隐藏头部popover
	store.commit("setCurrentDep", { currentDep: data });
	store.commit("setLastDep", { lastDep: null });
};

const setActiveNode = (data, node, e) => {
	// 请求列表返回字段为path，新增返回为Path
	store.commit("setCurrentDep", { currentDep: data });
};

const getRootNode = (node, resolve) => {
	// no permisson control
	axios({
		method: "get",
		url: `/Tree/authtree/nodes`,
	}).then((res) => {
		if (res.IsSuccess) {
			res.Result.forEach((item) => {
				item.showTitle = getTextSize("14px", item.Name).width > 160;
			});
			resolve(res.Result);
			getNodeCallBack(node,res);
		}
	});
}

const getOtherNode = (node, resolve, parentId) => {
	// no permisson control
	axios({
		method: "get",
		url: `/Tree/tree/${parentId}`,
	}).then((res) => {
		if (res.IsSuccess) {
			res.Result.forEach((item) => {
				item.showTitle = getTextSize("14px", item.Name).width > 160;
			});
			res.Result = res.Result.filter((item) => item.IsVisible);
			resolve(res.Result);
			getNodeCallBack(node,res)
		}
	});
}

const getNodeCallBack = (node, res) => {
	if (!ruleCheck.IsNullOrSpaceStr(window.targetDep)) {
		changeDep(window.targetDep);
		state.isFirstRequest = false;
	} else {
		if (node.level === 0 && state.isFirstRequest) {
			setActiveNode(res.Result[0]);
			const firstNode = treeRef.value.getNode(res.Result[0].Id);
			treeRef.value.setCurrentNode(res.Result[0]);
			firstNode.expand();
			state.isFirstRequest = false;
		}
		if (state.isSort) {
			treeRef.value.setCurrentNode(store.state.currentDep);
			state.isSort = false;
		}
		if (state.isAdd) {
			const last = res.Result[res.Result.length - 1];
			setActiveNode(last);
			treeRef.value.setCurrentNode(last);
			state.isAdd = false;
		}
	}
}

const getTreeNodeById = (node, resolve) => {
	if (state.lazyNode) {
		let parentId = null;
		if (node.level === 0) {
			parentId = ParentGuid;
			state.rootNode = node;
			state.rootResolve = resolve;
			getRootNode(node, resolve);
		} else {
			parentId = node.data.Id;
			getOtherNode(node, resolve, parentId)
		}
	} else {
		const data = findChildrenById(state.treeData, node.data.Id);
		resolve(data);
	}
};

const getNode = async (id) => {
	// no permisson control
	const res = await axios({
		method: "get",
		url: `/Tree/tree/node/${id}`,
	});
	return res.Result;
};

const getNodeByPath = async (path) => {
	// no permisson control
	const res = await axios({
		method: "get",
		url: `/Tree/tree/node/path/${path}`,
	});
	return res.Result;
};

const expandNode = (node) => {
	if (!node.isLeaf) {
		if (!node.expanded) {
			node.expand();
		} else {
			node.collapse();
		}
	}
};

const findChildrenById = (data, Id) => {
	let res = [];
	for (let index = 0; index < data.length; index++) {
		const element = data[index];
		if (element.Id == Id) {
			res = element.children || [];
			break;
		}
		if (element.children && element.children.length > 0) {
			const result = findChildrenById(element.children, Id);
			if (result && result.length > 0) {
				return result;
			}
		}
	}
	return res;
};

const searchTree = () => {
	const childNodes = treeRef.value.root.childNodes;
	childNodes.splice(0, childNodes.length);
	state.treeData = [];
	if (state.keyWord.trim() === "") {
		state.expandAll = false;
		state.isFirstRequest = true;
		state.lazyNode = true;
		getTreeNodeById(state.rootNode, state.rootResolve);
	} else {
		state.lazyNode = false;
		nextTick(() => {
			axios({
				method: "post",
				url: `/Tree/tree/search/${state.keyWord}`,
			}).then((res) => {
				if (res.IsSuccess) {
					state.treeData = setOrgs(res.Result, ParentGuid);
					nextTick(() => {
						expandAll(state.treeData);
					});
				}
			});
		});
	}
};

const expandAll = (data) => {
	data.forEach((item) => {
		const node = treeRef.value.getNode(item.Id);
		node.expand();
		if (item.children && item.children.length > 0) {
			expandAll(item.children);
		}
	});
};

const setOrgTree = async (path) => {
	const loading = fullScreenLoading();
	const data = await store.dispatch("getOrgs");
	if (data.length > 0 && path) {
		findActiveNode(path, data);
	} else if (data.length > 0 && !path) {
		data[0].active = true;
		state.preNode = data[0];
		store.commit("setCurrentDep", { currentDep: data[0] });
	} else {
		store.commit("setCurrentDep", { currentDep: {} });
	}
	store.commit("setDepTree", { depTree: data });
	state.treeData = data;
	if (state.expandKey) {
		state.defaultExpandedKeys.push(state.expandKey);
		state.expandKey = "";
	}
	loading.close();
};

const nodeContextmenu = (e, data, node) => {
	if (!state.hasSortAuth) return;
	state.menuIsOpen = false;
	nextTick(async () => {
		state.contextmenuNode = node;
		if (data.NodeType === MachineTreeNodeTypeEnum.Area) {
			state.menus = [{
				label: i18nGlobal("mainLayout.aside.unitSort"),
				icon: "",
				isChecked: false,
				click: () => {
					showSortDialog('unit')
				}
			}, {
				label: i18nGlobal("mainLayout.aside.sort"),
				icon: "",
				isChecked: false,
				click: () => {
					showSortDialog('depart')
				}
			}]

			// 巨化定制需求
			if (window.Config.project.props.projectCode === 'juhua') {
				state.menus.push({
					label: i18nGlobal("browse.synchronous"),
					icon: "",
					isChecked: false,
					click: () => {
						synchronousRef.value.showDialog()
					}
				})
			}
			state.menuEventVal = e;
			state.menuIsOpen = true;
		}
		if (data.NodeType === MachineTreeNodeTypeEnum.Depart) {
			nextTick(() => {
				state.menus = [{
					label: i18nGlobal("mainLayout.aside.sort"),
					icon: "",
					isChecked: false,
					click: () => {
						showSortDialog('depart')
					}
				}]
				state.menuEventVal = e;
				state.menuIsOpen = true;
			})
		}
	})

};

const getChildNodes = () => {
	// no permisson control
	axios({
		method: "get",
		url: `/Tree/tree/${state.contextmenuNode.data.Id}`,
	}).then((res) => {
		if (res.IsSuccess) {
			state.sortList = res.Result;
		}
	});
};

const showSortDialog = (type) => {
	state.sortType = type;
	if (type === "unit") {
		const node = treeRef.value.getNode(state.contextmenuNode.data.Id);
		const data = node.parent.childNodes.map((item) => item.data);
		state.sortList = data;
	} else {
		getChildNodes();
	}
	state.sortDialog = true;
};

const move = (data, index, type) => {
	data.active = false;
	if (type === "top") {
		if (index === 0) return;
		state.sortList.splice(index, 1);
		state.sortList.unshift(data);
	} else if (type === "up") {
		if (index === 0) return;
		const clone = _.cloneDeep(state.sortList[index - 1]);
		state.sortList[index - 1] = data;
		state.sortList[index] = clone;
	} else if (type === "down") {
		if (index === state.sortList.length - 1) return;
		const clone = _.cloneDeep(state.sortList[index + 1]);
		state.sortList[index + 1] = data;
		state.sortList[index] = clone;
	} else {
		if (index === state.sortList.length - 1) return;
		state.sortList.splice(index, 1);
		state.sortList.push(data);
	}
};

const onDragEnd = () => { };

const submitSort = async () => {
	let departs = [],
		devices = [];
	state.sortList.forEach((item, index) => {
		if (item.NodeType === MachineTreeNodeTypeEnum.Area || item.NodeType === MachineTreeNodeTypeEnum.Depart) {
			departs.push({
				displayIndex: index,
				id: item.Id,
			});
		} else {
			devices.push({
				displayIndex: index,
				id: item.Id,
			});
		}
	});
	if (departs.length > 0) {
		await sortDepart(departs);
	}
	if (devices.length > 0) {
		await sortDevices(devices);
	}
	if (departs.length > 0 || devices.length > 0) {
		if (state.sortType === "unit") {
			state.rootNode.childNodes = [];
			state.isFirstRequest = true;
			getTreeNodeById(state.rootNode, state.rootResolve);
			alertSuccessMsg(i18nGlobal('common.sortSuccess'));
		} else {
			state.contextmenuNode.childNodes = [];
			state.contextmenuNode.loaded = false;
			state.contextmenuNode.expanded = false;
			state.isSort = true;
			state.contextmenuNode.expand();
			alertSuccessMsg(i18nGlobal('common.sortSuccess'));
		}
	}
	state.sortDialog = false;
};

const sortDepart = (data) => {
	return axios({
		method: "put",
		url: `Department/displayindex`,
		data: data,
	}).then((res) => {
		if (res.IsSuccess) {
		}
	});
};
const sortDevices = (data) => {
	return axios({
		method: "put",
		url: `Device/displayindex`,
		data: data,
	}).then((res) => {
		if (res.IsSuccess) {
		}
	});
};

const setNewNodeAuth = (path, id) => {
	const cloneAuth = _.cloneDeep(store.state.userAuth);
	const MachineTreeAuth = cloneAuth.MachineTreeAuth;
	let parentPath = path.slice(0, path.length - 4);
	if (MachineTreeAuth[parentPath]) {
		MachineTreeAuth[path] = MachineTreeAuth[parentPath];
		MachineTreeAuth[path].DepartmentId = id;
		MachineTreeAuth[path].Path = path;
	}
	store.commit("setUserAuth", { userAuth: cloneAuth });
}

const addUnit = () => {
	const loading = fullScreenLoading();
	axios({
		method: "post",
		url: "/Department/Department",
		data: {
			Name: "",
			ParentId: ParentGuid,
			Location: "",
			MonitoringPurpose: "",
			Description: "",
			Param: "",
			NodeType: MachineTreeNodeTypeEnum.Area,
			ParentType: MachineTreeNodeTypeEnum.Area,
		},
	}).then((res) => {
		if (res.IsSuccess) {
			setNewNodeAuth(res.Result.Path, res.Result.Id)
			res.Result.IsLeaf = true;

			// 重新请求
			// const childNodes = state.rootNode.childNodes;
			// childNodes.splice(0, childNodes.length);
			// state.isFirstRequest = true;
			// getTreeNodeById(state.rootNode, state.rootResolve);

			// 去重添加
			// treeRef.value.root.childNodes = _.uniqBy(treeRef.value.root.childNodes, 'Id');
			treeRef.value.append(res.Result, treeRef.value.root);
			const newNode = treeRef.value.getNode(res.Result.Id);
			setActiveNode(newNode.data);
			treeRef.value.setCurrentNode(newNode.data);
		}
		loading.close();
	}).catch(() => {
		loading.close();
	});
};

const addDepart = () => {
	const loading = fullScreenLoading();
	const node = treeRef.value.getNode(currentDep.value.Id);
	axios({
		method: "post",
		url: "/Department/Department",
		data: {
			Name: "",
			ParentId: currentDep.value.Id,
			Location: "",
			MonitoringPurpose: "",
			Description: "",
			Param: "",
			NodeType: MachineTreeNodeTypeEnum.Depart,
			ParentType: currentDep.value.NodeType,
		},
	}).then((res) => {
		if (res.IsSuccess) {
			setNewNodeAuth(res.Result.Path, res.Result.Id)
			res.Result.IsLeaf = false;
			node.isLeaf = false;
			node.loaded = false;
			state.isAdd = true;
			node.expand();
			// treeRef.value.append(res.Result, node);
		}
		loading.close();
	}).catch(() => {
		loading.close();
	});
};

const addDevice = () => {
	const loading = fullScreenLoading();
	const node = treeRef.value.getNode(currentDep.value.Id);
	axios({
		method: "post",
		url: "/Device/device",
		data: {
			Name: "",
			Vendor: "", // 生产厂家
			Model: "", // 设备型号
			DeviceType: "", // 设备类型
			DepartmentId: currentDep.value.Id,
			NodeType: MachineTreeNodeTypeEnum.Device,
			Param: "",
			DepartmentType: currentDep.value.NodeType,
		},
	}).then((res) => {
		if (res.IsSuccess) {
			setNewNodeAuth(res.Result.Path, res.Result.Id)
			res.Result.IsLeaf = false;
			node.isLeaf = false;
			node.loaded = false;
			state.isAdd = true;
			node.expand();
			// store.commit('appendDepTree', { parentPath: currentDep.value.path, data: res.Result });
		}
		loading.close();
	}).catch(() => {
		loading.close();
	});
};

const addScene = () => {
	const loading = fullScreenLoading();
	const node = treeRef.value.getNode(currentDep.value.Id);
	axios({
		method: "post",
		url: "/Device/device",
		data: {
			Name: "",
			Vendor: "", // 生产厂家
			Model: "", // 设备型号
			DeviceType: "", // 设备类型
			DepartmentId: currentDep.value.Id,
			NodeType: MachineTreeNodeTypeEnum.Scene,
			Param: "",
			DepartmentType: currentDep.value.NodeType,
		},
	}).then((res) => {
		if (res.IsSuccess) {
			setNewNodeAuth(res.Result.Path, res.Result.Id)
			res.Result.IsLeaf = false;
			node.isLeaf = false;
			node.loaded = false;
			state.isAdd = true;
			node.expand();
		}
		loading.close();
	}).catch(() => {
		loading.close();
	});
};

// 删除节点
const removeTreeNode = (urlText) => {
	const node = treeRef.value.getNode(currentDep.value.Id);
	axios({
		method: "delete",
		url: urlText,
		data: {
			ids: [currentDep.value.Id],
		},
	}).then((res) => {
		if (res.IsSuccess) {
			// if (node.parent?.level >= 1) {
			//     setActiveNode(node.parent.data);
			//     treeRef.value.setCurrentNode(node.parent.data);
			// } else if (node.previousSibling || node.nextSibling) {
			//     const actvieData = node.previousSibling?.data || node.nextSibling?.data;
			//     setActiveNode(actvieData);
			//     if (actvieData) {
			//         treeRef.value.setCurrentNode(actvieData);
			//     }
			// } else {
			//     const parentNode = treeRef.value.getNode(node.data.ParentId);
			//     if (parentNode) {
			//         if (parentNode.childNodes.length > 0) {
			//             treeRef.value.setCurrentNode(parentNode.childNodes[0].data);
			//             setActiveNode(parentNode.childNodes[0].data);

			//         } else {
			//             treeRef.value.setCurrentNode(parentNode.data)
			//             setActiveNode(parentNode.data);

			//         }
			//     }
			// }
			// treeRef.value.remove(node);
			alertSuccessMsg(i18nGlobal('common.deleteSuccess'));
		}
	});
};

// 添加节点
const findActiveNode = (path, tree) => {
	for (let index = 0; index < tree.length; index++) {
		const element = tree[index];
		if (element.path === path) {
			element.active = true;
			state.preNode = element;
			store.commit("setCurrentDep", { currentDep: element });
			return;
		}
		if (element.children && element.children.length > 0) {
			findActiveNode(path, element.children);
		}
	}
};

const scrollToCurrentNode = (key) => {
	const dom = $('#aside_tree').find(`.el-tree-node[data-key="${key}"]`)
	const tree = document.getElementById('aside_tree');
	const scrollHeight = tree.scrollHeight;
	const clientHeight = tree.clientHeight;
	if (clientHeight < scrollHeight) {
		tree.scrollTop = 0;
		nextTick(() => {
			tree.scrollTop = dom[0].offsetTop;
		})
	}
}

const findAlarmNode = (Id, alarmNodeIds) => {
	state.alarmNodeIds = alarmNodeIds;
	const node = treeRef.value.getNode(Id);
	if (node) {
		treeRef.value.setCurrentNode(node.data);
		setActiveNode(node.data);
		setTimeout(() => {
			scrollToCurrentNode(node.key)
		}, 300)
	} else if (state.alarmNodeIds.length > 0) {
		const nodeId = state.alarmNodeIds[0];
		waitForAlarmNodeExpand(Id, nodeId);
	}
}

const waitForAlarmNodeExpand = (Id, nodeId) => {
	const node = treeRef.value.getNode(Id);
	if (node) {
		treeRef.value.setCurrentNode(node.data);
		setActiveNode(node.data);
		setTimeout(() => {
			scrollToCurrentNode(node.key)
		}, 300)
		return;
	} else {
		const node2 = treeRef.value.getNode(nodeId);
		if (node2) {
			state.alarmNodeIds.shift();
			node2.expand()
			setTimeout(() => {
				waitForAlarmNodeExpand(Id, state.alarmNodeIds[0]);
			}, 30);
		} else {
			setTimeout(() => {
				waitForAlarmNodeExpand(Id, nodeId);
			}, 30);
		}
	}
};

const setCurrentNode = (Id, parentId) => {
	const node = treeRef.value.getNode(Id);
	if (node) {
		treeRef.value.setCurrentNode(node.data);
		setActiveNode(node.data);
	} else if (parentId) {
		const parentNode = treeRef.value.getNode(parentId);
		parentNode?.expand();
		waitForNodeExpand(Id);
	}
};

const waitForNodeExpand = (Id) => {
	const node = treeRef.value.getNode(Id);
	if (node) {
		treeRef.value.setCurrentNode(node.data);
		setActiveNode(node.data);
		return;
	} else {
		setTimeout(() => {
			waitForNodeExpand(Id);
		}, 30);
	}
};

const getNodeById = (id) => {
	return treeRef.value.getNode(id);
};

// 树结构改变
const updateTree = async ({ hanlder, orgType }) => {
	if (hanlder === "add") {
		state.lazyNode = true;
		nextTick(() => {
			switch (orgType) {
				case OrgEnum.Unit:
					addUnit();
					break;
				case OrgEnum.Depart:
					addDepart();
					break;
				case OrgEnum.Device:
					addDevice();
					break;
				default:
					addScene();
					break;
			}
		});
	} else if (hanlder === "refresh") {
		setOrgTree();
	} else {
		// 删除
		let nameText = "",
			urlText = "",
			msg = "";
		const nameMap = {
			[MachineTreeNodeTypeEnum.Area]: {
				name: i18nGlobal('common.unit'),
				url: `Department/Department/${currentDep.value.Id}`,
			},
			[MachineTreeNodeTypeEnum.Depart]: {
				name: i18nGlobal('common.depart'),
				url: `Department/Department/${currentDep.value.Id}`,
			},
			[MachineTreeNodeTypeEnum.Device]: {
				name: i18nGlobal('common.device'),
				url: "Device/device",
			},
			[MachineTreeNodeTypeEnum.Scene]: {
				name: i18nGlobal('common.scene'),
				url: "Device/device",
			},
		};

		nameText = nameMap[currentDep.value.NodeType].name;
		urlText = `${nameMap[currentDep.value.NodeType].url}`;

		// const node = treeRef.value.getNode(currentDep.value.Id);
		// const isOnly = node.parent.childNodes.length === 1;
		// if (currentDep.value.NodeType === MachineTreeNodeTypeEnum.Area && isOnly) {
		//     return alertErrorMsg('至少需要存在一个单位');
		// }
		const { NodeType } = currentDep.value;
		if (NodeType === MachineTreeNodeTypeEnum.Area || NodeType === MachineTreeNodeTypeEnum.Depart) {
			i18nString
			msg = i18nString({
				CN: `删除当前${nameText}会同时删除该目录下的子部门、设备和场景，是否继续删除?`,
				EN: `Deleting the current ${nameText} will also delete the subdepartments, devices, and scenes in the directory, and whether to continue to delete them?`
			})
		} else {
			msg = i18nString({
				CN: `是否删除${nameText}【${currentDep.value.Name}】?`,
				EN: `Whether to delete ${nameText}【${currentDep.value.Name}】`
			})
		}
		alertConfirm(() => removeTreeNode(urlText), {
			msg: msg,
			title: i18nGlobal('common.delete'),
		});
	}
};

const setExpandKey = (data) => {
	state.defaultExpandedKeys.push(data.path);
};

const removeExpandKey = (data) => {
	const index = state.defaultExpandedKeys.findIndex((item) => item === data.path);
	state.defaultExpandedKeys.splice(index, 1);
};

const filterNode = (value, data) => {
	if (!value) return true;
	return data.Name.indexOf(value) !== -1;
};


class TreeAlarmSignalRHandler extends ISignalRHandler {
	constructor() {
		super();
		this.SupportContentType = [ContractTypes.TreeAlarms];
	}

	ReceiveData(data) {
		if (data.ContentType == ContractTypes.TreeAlarms) {
			if (data.Content != null) {
				let alarm = JSON.parse(data.Content[0].Data);
				const node = treeRef.value.getNode(alarm.Id);
				node.data.Status = alarm.Status;
				debounce.run(() => {
					getAlarmLevelsMap()
				}, 2000);
			}
		}
	}
}

const signalrAdd = (nodeData) => {
	store.commit('updateOriginTree', { data: _.cloneDeep(nodeData), type: 'add' })
	nodeData.IsLeaf = true;
	setNewNodeAuth(nodeData.Path, nodeData.Id)
	setTimeout(() => {
		const currentNode = treeRef.value.getNode(nodeData.Id);
		if (!currentNode) {
			const parentId = nodeData.ParentId || nodeData.DepartmentId;
			if (parentId === ParentGuid) {
				treeRef.value.append(nodeData, treeRef.value.root);
			} else {
				const node = treeRef.value.getNode(parentId);
				if (node) {
					if (node.isLeaf) {
						node.isLeaf = false;
						node.loaded = false;
						node.expand();
					} else {
						treeRef.value.append(nodeData, node);
					}
				}
			}
		}
	}, 1000);
};

const signalrRemove = (nodeData) => {
	store.commit('updateOriginTree', { data: _.cloneDeep(nodeData), type: 'remove' })
	const node = treeRef.value.getNode(nodeData.Id);
	if (node) {
		if (store.state.currentDep.Id === nodeData.Id) {
			if (node.parent?.level >= 1) {
				setActiveNode(node.parent.data);
				treeRef.value.setCurrentNode(node.parent.data);
			} else if (node.previousSibling || node.nextSibling) {
				const actvieData = node.previousSibling?.data || node.nextSibling?.data;
				setActiveNode(actvieData);
				if (actvieData) {
					treeRef.value.setCurrentNode(actvieData);
				}
			} else {
				const parentNode = treeRef.value.getNode(node.data.ParentId);
				if (parentNode) {
					if (parentNode.childNodes.length > 0) {
						treeRef.value.setCurrentNode(parentNode.childNodes[0].data);
						setActiveNode(parentNode.childNodes[0].data);
					} else {
						treeRef.value.setCurrentNode(parentNode.data);
						setActiveNode(parentNode.data);
					}
				}
			}
		}
		treeRef.value.remove(nodeData);
	}
};

const getAlarmLevelsMap = () => {
	// no permisson control
	axios({
		method: "get",
		url: "/Tree/authtree",
	}).then((res) => {
		if (res.IsSuccess) {
			state.alarmLevelMap = res.Result;
		}
	})
}

class TreeChangeSignalRHandler extends ISignalRHandler {
	constructor() {
		super();
		this.SupportContentType = [ContractTypes.TreeUpdate];
	}

	ReceiveData(data) {
		if (data.ContentType == ContractTypes.TreeUpdate) {
			if (data.Content != null) {
				let nodeDatas = JSON.parse(data.Content[0].Data);
				if (Array.isArray(nodeDatas)) {
					for (let i = 0; i < nodeDatas.length; i++) {
						const item = nodeDatas[i];
						this.UpdateTree(item)
					}
				} else {
					this.UpdateTree(nodeDatas)
				}
			}
		}
	}

	UpdateTree(nodeData) {
		if (nodeData.LastOperate === TreeOperateEnum.Add) {
			// 新增
			signalrAdd(nodeData);
		} else if (nodeData.LastOperate === TreeOperateEnum.Edit) {
			const node = treeRef.value.getNode(nodeData.Id);
			const ParentId = nodeData.ParentId || nodeData.DepartmentId;
			if (node.data.ParentId === ParentId) {
				store.commit('updateOriginTree', { data: _.cloneDeep(nodeData), type: 'update' })
				node.data.Name = nodeData.Name;
				node.data.DepartmentType = nodeData.DepartmentType;
				if (nodeData.DeviceGrade) {
					node.data.DeviceGrade = nodeData.DeviceGrade;
				}
			} else {
				const newNode = treeRef.value.getNode(ParentId);
				if (newNode) {
					signalrRemove(nodeData);
					setTimeout(() => {
						signalrAdd(nodeData);
					}, 300);
				}
			}
		} else {
			// 删除
			signalrRemove(nodeData);
		}
	}
}

class StartStopSignalRHandler extends ISignalRHandler {
	constructor() {
		super();
		this.SupportContentType = [ContractTypes.StartStopUpdate];
	}

	ReceiveData(data) {
		if (data.ContentType == ContractTypes.StartStopUpdate) {
			if (data.Content != null) {
				let nodeData = data.Content;
				nodeData.forEach(item => {
					const node = treeRef.value.getNode(item.DeviceId);
					node.data.StartStopStatus = item.StartStopStatus
				})
			}
		}
	}
}

class DeviceStatusChangeSignalRHandler extends ISignalRHandler {
	constructor() {
		super();
		this.SupportContentType = [ContractTypes.DeviceStatus];
	}

	ReceiveData(data) {
		if (data.ContentType == ContractTypes.DeviceStatus) {
			let nodeData = JSON.parse(data.Content[0].Data);
			const online = nodeData.OnlineDeviceId;
			const offLine = nodeData.OfflineDeviceId;

			for (let i = 0; i < online.length; i++) {
				const item = online[i];
				nextTick(() => {
					const node = treeRef.value.getNode(item);
					node.data.DeviceStatus = DeviceStatusEnum.Online;
				})
			}

			for (let i = 0; i < offLine.length; i++) {
				const item = offLine[i];
				nextTick(() => {
					const node = treeRef.value.getNode(item);
					node.data.DeviceStatus = DeviceStatusEnum.Offline;
				})
			}
			store.commit('setDeviceOnlineStatus', { deviceOnlineStatus: !store.state.deviceOnlineStatus })
		}
	}
}

const expandNodeByPath = (path, i = 2) => {
	if (i * 4 >= path.length) return;
	const node = Object.values(treeRef.value.store.nodesMap).find(node => node.data.Path == path.substring(0, 4 * i));
	if (node) {
		treeRef.value.getNode(node.data.Id)?.expand();
		expandNodeByPath(path, i + 1);
	} else {
		setTimeout(() => {
			expandNodeByPath(path, i);
		}, 30);
	}
}

const changeDep = (path) => {
	expandNodeByPath(path);
	if (!ruleCheck.IsNullOrSpaceStr(path)) {
		getNodeByPath(path).then((node) => {
			if (node != null) {
				store.commit("setCurrentDep", { currentDep: node });

				setTimeout(() => {
					treeRef.value.setCurrentNode(node);
					scrollToCurrentNode(treeRef.value.getNode(node.Id).key);
				}, path.length * 40)
			}
		});
		window.targetDep = null;
	}
}

const updateComponentLayouts = async () => {
	await PhmWebStorageContext.GetInstance().getRecords();
}

onMounted(async () => {
	await updateComponentLayouts();
	getAlarmLevelsMap()
	const ResourceAuth = store.state.userAuth.ResourceAuth;
	state.hasSortAuth = ResourceAuth.includes("Edit");
	window.onTargetDepChanged = changeDep;
	nextTick(() => {
		const signalr = SignalRInstance.GetInstance();
		signalrCache.treeAlarm = new TreeAlarmSignalRHandler();
		signalrCache.treeChange = new TreeChangeSignalRHandler();
		signalrCache.startStop = new StartStopSignalRHandler();
		signalrCache.deviceStatus = new DeviceStatusChangeSignalRHandler();
		signalr?.Add(signalrCache.treeAlarm);
		signalr?.Add(signalrCache.treeChange);
		signalr?.Add(signalrCache.startStop);
		signalr?.Add(signalrCache.deviceStatus);
	});
});

onBeforeUnmount(() => {
	signalrCache.treeAlarm?.Dispose();
	signalrCache.treeChange?.Dispose();
	signalrCache.startStop?.Dispose();
	signalrCache.deviceStatus?.Dispose();
	window.onTargetDepChanged = null;
});

defineExpose({
	updateTree,
	getNodeById,
	setCurrentNode,
	findAlarmNode
});
</script>

<style scoped lang="less">
@import "@styles/variable.less";

.sortWrapper {
	border: 1px solid var(--phm-theme-border);
	padding: 20px;

	ul {
		max-height: 600px;
		overflow: auto;

		li {
			display: flex;
			align-items: center;
			height: 30px;
			padding: 4px;
			border-radius: 2px;
			justify-content: space-between;
			color: var(--phm-theme-mainText);

			.left {
				display: flex;
				align-items: center;

				.name {
					margin-left: 8px;
				}
			}

			.btns {
				display: flex;

				svg {
					margin-left: 8px;
					cursor: pointer;
				}
			}

			&:hover {
				color: var(--phm-primary-color);
				background-color: var(--phm-primary-light-color);
			}
		}
	}
}

.aside {
	padding: 15px 6px 0;
	box-sizing: border-box;
	height: 100%;
	// position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	position: relative;

	.top {
		height: calc(100% - 360px);
	}

	.search {
		margin-top: 8px;
	}

	:deep(.el-tree) {
		background-color: var(--phm-primary-theme-card);
		margin-top: 20px;
		// max-height: 480px;
		height: calc(100% - 52px);
		width: 100%;
		overflow: auto;

		&::-webkit-scrollbar-thumb {
			background-color: transparent;
		}

		&::-webkit-scrollbar-thumb {
			background-color: transparent;
		}

		&::-webkit-scrollbar-track {
			background-color: transparent;
		}
		&::-webkit-scrollbar-corner {
			background-color: var(--phm-theme-card);
		}

		&:hover {

			&::-webkit-scrollbar-thumb {
				background: var(--phm-theme-tip);
			}

			&::-webkit-scrollbar-track {
				background: var(--phm-theme-border);
			}


		}

		.el-tree-node {
			color: var(--phm-theme-mainText);

			.el-tree-node__content {
				height: 30px;
				display: inline-flex;
				align-items: center;
				min-width: 100%;
				padding-right: 8px;

				.el-tree-node__expand-icon {
					display: none;
				}
			}

			.el-tree-node__children {
				overflow-x: inherit !important;
				overflow-y: clip !important;
			}

			.treeNode {
				display: flex;
				width: 100%;
				height: 100%;
				align-items: center;
				font-size: 14px;
				// overflow: hidden;

				.name {
					height: 100%;
					line-height: 30px;
					margin-left: 8px;
					// width: 160px;
					// overflow: hidden;
					// text-overflow: ellipsis;
					// white-space: nowrap;
					user-select: none;
				}

				.customIcon {
					padding: 6px;
				}
			}

			.el-tree-node__expand-icon {
				&.is-leaf {
					color: transparent !important;

					svg path {
						fill: transparent !important;
					}
				}
			}

			&.normal>.el-tree-node__content {
				svg {
					path {
						fill: #666;
					}
				}

				use {
					fill: #666 !important;
				}
			}

			&.is-current>.el-tree-node__content {
				background-color: var(--phm-primary-light-color);
				color: var(--phm-primary-color);
			}
		}

		// .icon {
		//     margin: 0 10px;
		// }

		// .name {
		//     color: #606266;

		//     &:hover {
		//         text-decoration: underline;
		//     }

		//     &.active {
		//         color: @
		//     }
		// }

		.txt {
			vertical-align: top;
		}
	}

	.dh-calendar {
		// position: absolute;
		bottom: 10px;
		left: 4px;
	}
}

.loading {
	animation: loading 1s linear infinite;
	margin-right: 8px;
}

@keyframes loading {
	0% {
		-webkit-transform: rotate(0deg);
	}

	25% {
		-webkit-transform: rotate(90deg);
	}

	50% {
		-webkit-transform: rotate(180deg);
	}

	75% {
		-webkit-transform: rotate(270deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
	}
}
</style>
