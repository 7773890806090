import { createI18n } from 'vue-i18n'
import store from './store'
import CN from '../../lang/cn';
import EN from '../../lang/en';
import CommonCN from '../../lang/common_cn';
import CommonEN from '../../lang/common_en';
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import en from 'element-plus/lib/locale/lang/en'
import _ from 'lodash';

const getCN = () => {
    return _.merge(CN, zhCn, CommonCN);
}
const getEN = () => {
    return _.merge(EN, en, CommonEN);
}


const messages = {
    CN: getCN(),
    EN: getEN()
}

const i18n = createI18n({
    legacy: false,
    globalInjection: true,
    locale: store.state.lang,
    messages
})


export default i18n