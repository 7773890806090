import dayjs from "dayjs";
import { guid } from "@common/js/utils";
import { ChartInformation, TrendCoordinateInfo } from "@core/graphs/webcharts/Dh-webchart-coordinates";
import { YAxisType } from "@core/graphs/webcharts/Dh-webchart-models";
import { TimeXCoordinateSystem } from "@core/graphs/coords/Dh-coordsystem-time";
import { AnalysisChart } from "./Dh-analysischart-base";
import { HLineShapeOperator } from "../shapes/Dh-shape-line";

export class TrendAnalysisChart extends AnalysisChart {
    constructor() {
        super();
    }
    GetCoordinateSystem() {
        return new TimeXCoordinateSystem(guid());
    }
    GetInformation() {
        let info = new ChartInformation();
        let xmin = Number(dayjs());
        let xmax = Number(dayjs().add(600, "second"));
        info.CoordinateInfo = new TrendCoordinateInfo(xmax, xmin, 1000, -1000, info);
        info.Padding.Top = 30;
        return info;
    }
    SetData(datas) {
        let isTextYAxis = datas.length > 0 && datas[0].YAxisType == YAxisType.Text;
        this.WebChart.Information.YAxisType = isTextYAxis ? YAxisType.Text : YAxisType.Number;
        if (isTextYAxis) {
            this.WebChart.Information.YAxisItems.clear();
            for (const data of datas) {
                this.WebChart.Information.YAxisItems.set(data.PointId, data.YAxisItems);
            }
            const range = this.getCoordinateRange(datas);
            this.WebChart.Information.UpdateCoordinateInfo(range.xmax, range.xmin, range.ymax, range.ymin);
            this.WebChart.Information.UnitTexts.XText = "";
            this.WebChart.SetData(datas);
        } else {
            super.SetData(datas);
        }
    }
}

export class HTrendAnalysisChart extends TrendAnalysisChart {
    constructor() {
        super();
    }
    GetShapeOperator() {
        return new HLineShapeOperator();
    }
}