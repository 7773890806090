import $ from "jquery";
import { PluginBase } from "./DH-plugin-base";
import { draggable } from "@core/graphs/webcharts/Dh-webchart-draggable";
import { ruleCheck } from "@common/js/rules";
import { i18nGlobal } from "@common/js/utils";

export class LegendPlugin extends PluginBase {
	constructor() {
		super();
		this.LegendWnd = null;
		this.SelectedItem = null;
		this.Right = null;
		this.Top = null;
	}
	CreatePlugin() {
		let rect = this.Owner.Information.ShapeContainerRect;
		this.Right = 20;
		this.Top = rect.Top;
		var table =
			'<div class="cursor-window none-select" style="pointer-events:auto;min-width: 80px;position: absolute;display:table;left:calc(100% - 20px);top:' +
			this.Top +
			'px;overflow: hidden;"><table class="cursor-container">' +
			"</table></div>";
		this.LegendWnd = $(table);
		$(this.Owner.ChartContainer).append(this.LegendWnd);
		if (this.IsEnabled) {
			new draggable({
				element: this.LegendWnd,
				containment: "parent",
				drag(e, ui) {
					// sender.Right = sender.Owner.Information.ShapeContainerRect.Width - ui.offset.left - ui.helper.width();
					// sender.Top = ui.helper.css("top").replace("px");
				},
			});
		}
	}
	OnSizeChanged() {
		var chart = this.Owner.Information.ChartContainerRect;
		var padding = this.Owner.Information.Padding;
		var left = chart.Width - this.LegendWnd.width() - padding.Right;
		var top = this.LegendWnd.css("top").replace("px", "");
		var cssLeft = this.LegendWnd.css("left").replace("px", "");
		var limitTop = chart.Height - this.LegendWnd.height();
		if (cssLeft < left) left = cssLeft;
		if (left < 0) left = 0;
		if (top < 0) top = 0;
		if (top > limitTop) top = limitTop;
		let parent = this.LegendWnd.parent();
		this.LegendWnd.css("left", (left / parent.width()) * 100 + "%");
		this.LegendWnd.css("top", (top / parent.height()) * 100 + "%");
	}
	OnRender() {
		let sender = this;
		let datas = sender.Owner.ShapeOperator.Data;
		let showUnit = sender.Owner.CoordinateSystem.ShowUnit;
		let rows = "";
		let columns = "";
		if (datas != null && datas.length > 0) {
			$.each(datas, function (i, data) {
				let column = "";
				showUnit = showUnit & !ruleCheck.IsNullOrSpaceStr(data.YUnit);
				let unit = showUnit ? "[" + data.YUnit + "]" : "";
				rows +=
					"<tr>" +
					'<td style="display:flex;white-space: nowrap;">' +
					'<a data-id="' +
					data.PointId +
					'" style="cursor:pointer;"><span class="radio-big" style="background-color:' +
					data.LineColor +
					'"></span>' +
					'<span class="radio-small" style="background-color:' +
					data.LineColor +
					'"></span></a>' +
					'<span class="legend-text" style="color:' +
					data.LineColor +
					'">' +
					data.Legend +
					unit +
					"</span>" +
					"</td>" +
					column +
					"</tr>";
			});
			if (sender.SelectedItem == null) sender.SelectedItem = datas[0].PointId;
		}
		let content = "<tbody>" + "<tr>" + "<th>" + '<div class="cursor-array"></div>' + `<span class="cursor-title">${i18nGlobal('core.curveInfo')}</span>` + "</th>" + columns + "</tr>" + rows + "</tbody>";
		sender.LegendWnd.find(".cursor-container").empty().append(content);
		sender.LegendWnd.find(".radio-small").removeClass("radio-selected");
		sender.LegendWnd.find('a[data-id="' + sender.SelectedItem + '"]')
			.find(".radio-small")
			.addClass("radio-selected");
		if (sender.IsEnabled) {
			sender.LegendWnd.find("a").click(function (e) {
				let id = $(this).attr("data-id");
				if (sender.SelectedItem != id) {
					sender.SelectedItem = id;
					sender.LegendWnd.find(".radio-small").removeClass("radio-selected");
					$(this).find(".radio-small").addClass("radio-selected");
					sender.Owner.CoordinateSystem.OnRender();
				}
			});
		}
	}
	Close() {
		this.LegendWnd.remove();
		this.LegendWnd = null;
	}
}
