import { ContractTypes } from "@common/js/enum";
import * as signalR from "@microsoft/signalr";
import * as msgpack from "@microsoft/signalr-protocol-msgpack";
import store from "@common/js/store";
import axios from "@common/js/axios";
import { PhmWebStorageContext } from "@components/js/utils/indexDb";

export class ISignalRHandler {
	constructor() {
		this.SignalR = null;
		this.SupportContentType = []; // 协议类型
	}

	Dispose() {
		if (this.SignalR) {
			this.SignalR.Remove(this);
			this.Abort();
		}
	}

	// 第一次连接成功时
	OnConnected() { }
	// SignalR断线时触发
	OnDisconnected() { }
	// SignalR重连时
	OnReconnected() { }
	// SignalR有数据接收到时,data对应具体的JsonMsg对象
	ReceiveData(data) { }
	// 是否支持某种消息类型的处理
	IsSupport(contentType) {
		return this.SupportContentType.includes(contentType);
	}
	// 功能退出时
	Abort() { }
}

class SignalrClient {
	constructor(host) {
		this.HandlerList = [];
		this.Host = host;
		this.SignalrHubId = "";
		this.Hub = new signalR.HubConnectionBuilder()
			.withUrl(host)
			.withAutomaticReconnect(this.getReconnectTimes())
			.withHubProtocol(new msgpack.MessagePackHubProtocol())
			.build();
		this.Hub.serverTimeoutInMilliseconds = 180000;
		this.Hub.keepAliveIntervalInMillisecods = 5000;
		this.onReceivedData = null;
		this.onReconnected = null;
		this.onDisconnected = null;
		this.onConnected = null;
	}
	getReconnectTimes() {
		const array = [0, 3000, 5000, 10000, 15000, 30000, 45000, 60000, 75000, 90000, 120000];
		const seeds = [2 * 60 * 1000, 3 * 60 * 1000, 4 * 60 * 1000];
		seeds.forEach(seed => {
			for (let i = 0; i < 720; i++) {
				array.push(seed);
			}
		});
		return array;
	}
	initial() {
		const sender = this;
		if (this.Hub == null) {
			return;
		}
		this.Hub.on("ReceiveData", function (data) {
			for (let handler of sender.HandlerList) {
				if (handler.IsSupport(data.ContentType)) {
					handler.ReceiveData(data);
				}
			}
			sender.onReceivedData?.(data);
		});

		this.Hub.onreconnecting((error) => {
			sender.ConnectionState = signalR.HubConnectionState.Reconnecting;
			console.log(error);
		});
		this.Hub.onreconnected(function () {
			console.log(" reconnected state:" + sender.Hub.state);
			if (sender.ConnectionState === signalR.HubConnectionState.Connected) {
				return;
			}
			sender.ConnectionState = signalR.HubConnectionState.Connected;

			let loginHandlers = sender.HandlerList.filter((r) => r.IsSupport(ContractTypes.LoginResult));
			for (let handler of loginHandlers) {
				handler.OnReconnected();
			}

			setTimeout(function () {
				let otherHandlers = sender.HandlerList.filter((r) => !r.IsSupport(ContractTypes.LoginResult));
				for (let handler of otherHandlers) {
					handler.OnReconnected();
				}

				sender.onReconnected?.();
			}, 1000);
		});

		this.Hub.onclose(function () {
			console.log("signalr connection colsed");
			sender.ConnectionState = signalR.HubConnectionState.Disconnected;
			// 断连后，通知各个消息处理器
			for (let handler of sender.HandlerList) {
				handler.OnDisconnected();
			}
			sender.onDisconnected?.();
		});
	}
	Start() {
		this.Hub.start().then(() => {
			this.SignalrHubId = this.Hub.connection.connectionId;
			this.ConnectionState = signalR.HubConnectionState.Connected;
			console.log("signalR start success!");
			for (let handler of this.HandlerList) {
				handler.OnConnected();
			}
			this.onConnected?.();
		}).catch((ex) => {
			console.log("error:" + ex);
			if (this.ConnectionState !== signalR.HubConnectionState.Connected) {
				setTimeout(() => this.Start(), 5000);
			}
		});
	}
	Stop() {
		let array = [...this.HandlerList];
		for (let handler of array) {
			handler.Dispose();
		}
		this.Hub.stop();
	}
	resetConnection(sender) {
		sender.Hub.connection.stop();
		sender.serverStart();
	}
	// 添加消息处理器
	Add(handler) {
		this.HandlerList.push(handler);
		handler.SignalR = this;
	}
	// 移除某个数据处理器
	Remove(handler) {
		var index = this.HandlerList.indexOf(handler);
		if (index >= 0) {
			this.HandlerList.splice(index, 1);
		}
	}
	// 发送数据
	SendData(data) {
		if (this.Hub.state === signalR.HubConnectionState.Connected) {
			this.Hub.invoke("ReceiveData", data).catch(function (err) {
				return console.error(err.toString());
			});
		}
	}
}

class SignalRLoginHandler extends ISignalRHandler {
	constructor() {
		super();
		this.Type = "Login";
		this.SupportContentType = [ContractTypes.LoginResult];
	}
	ReceiveData(data) {
		if (data.Content[0].IsAuthenticate === true) {
			this.SignalR.LoginSuccess = true;
		}
	}
	//第一次连接成功时
	OnConnected() {
		const user = store.state.userInfo;
		const msg = {
			User: user.UserName,
			Token: user.Token
		}
		this.SignalR.SendData({
			ContentType: ContractTypes.Login,
			Content: [{
				Data: JSON.stringify(msg)
			}]
		});
	}
	OnReconnected() {
		this.OnConnected();
	}
}


class ComponentLayoutSignalRHandler extends ISignalRHandler {
	constructor() {
		super();
		this.SupportContentType = [ContractTypes.ComponentLayoutChanged];
	}
	ReceiveData(data) {
		if (data.ContentType == ContractTypes.ComponentLayoutChanged) {
			if (data.Content?.length > 0) {
				for (const temp of data.Content) {
					const records = JSON.parse(temp.Data);
					PhmWebStorageContext.GetInstance().updateRecord(records);
				}
			}
		}
	}
}

class DatacenterConnectedHandler extends ISignalRHandler {
	constructor() {
		super();
		this.SupportContentType = [ContractTypes.DataCenterConnected];
	}
	ReceiveData(data) {
		if (data.Content[0].SignalRAddress) {
			const array = data.Content[0].SignalRAddress;
			const instance = SignalRInstance.GetInstance();
			let hasAdd = false;
			for (const url of array) {
				if (instance.CreateClient(url)) {
					hasAdd = true;
				}
			}
			if (hasAdd) {
				instance.OnConnected();
			}
		}
	}
}

class SignalRClientManage {
	constructor() {
		this.handlerList = [];
		this.clientMap = new Map();
	}
	async initial() {
		const urls = await getSignalrUrls();
		urls.forEach(url => {
			this.CreateClient(url);
		});
	}
	// 添加消息处理器
	Add(handler) {
		this.handlerList.push(handler);
		handler.SignalR = this;
	}
	// 移除某个数据处理器
	Remove(handler) {
		const index = this.handlerList.indexOf(handler);
		if (index >= 0) {
			this.handlerList.splice(index, 1);
		}
	}
	CreateClient(url) {
		if (this.clientMap.has(url)) return false;
		const client = new SignalrClient(url);
		client.onReceivedData = (data) => {
			for (const handler of this.handlerList) {
				if (handler.IsSupport(data.ContentType)) {
					handler.ReceiveData(data);
				}
			}
		}
		client.onReconnected = () => {
			for (const handler of this.handlerList) {
				handler.OnReconnected();
			}
		}
		client.onDisconnected = () => {
			for (const handler of this.handlerList) {
				handler.OnDisconnected();
			}
		}
		client.onConnected = () => {
			for (const handler of this.handlerList) {
				handler.OnConnected();
			}
		}
		client.Add(new SignalRLoginHandler());
		client.Add(new ComponentLayoutSignalRHandler());
		client.Add(new DatacenterConnectedHandler());
		client.initial();
		client.Start();
		this.clientMap.set(url, client);
		return true;
	}
	GetConnectorIds() {
		const ids = [];
		for (const client of this.clientMap.values()) {
			if (client?.Hub?.connection?.connectionId != null) {
				ids.push(client.Hub.connection.connectionId);
			}
		}
		return ids;
	}
	GetApiConnectorId() {
		const url = Config.signalRUrl;
		if (this.clientMap.has(url)) {
			return this.clientMap.get(url).Hub.connection.connectionId;
		}
	}
	Clear() {
		const array = [...this.handlerList];
		for (const handler of array) {
			handler.Dispose();
		}
		for (const client of this.clientMap.values()) {
			client.Stop();
		}
	}
}

export class SignalRInstance {
	static _instance = null;
	static GetInstance() {
		return SignalRInstance._instance;
	}
	static initial() {
		if (SignalRInstance._instance == null) {
			SignalRInstance._instance = new SignalRClientManage();
			const instance = SignalRInstance._instance;
			instance.initial();
		}
	}
	static Clear() {
		SignalRInstance._instance.Clear();
		SignalRInstance._instance = null;
	}
}

export const getSignalrUrls = async () => {
	const urls = [Config.signalRUrl];
	// no permisson control
	const res = await axios({
		method: "get",
		url: `DataManage/signalr-url`,
	}).catch(() => { });
	if (res?.IsSuccess && res.Result) {
		urls.push(...res.Result);
	}
	return urls;
}
