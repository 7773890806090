import { ElementBase, PluginBase } from "@core/graphs/plugins/DH-plugin-base";
import $ from "jquery";
import { ruleCheck } from "@common/js/rules";
import { AlarmTypeModelEnum, AlarmTypeEnum } from "@common/js/enum";
import { i18nGlobal } from "@common/js/utils";
export class HorCursorElement extends ElementBase {
    constructor(owner, width, linecolor, stroke, startPos, name, Enable) {
        super();
        this.parent = owner;
        this.IsMouseDown = false;
        this.Width = width;
        this.Height = 11;
        this.StartPos = startPos;
        this.Name = name;
        this.Enable = Enable;
        this.DOM = null;
        this.Color = linecolor !== undefined ? linecolor : "black";
        this.Stroke = stroke != undefined ? stroke : 1;
        this.StopPropagation = true;
        this.StartPos.X = parseInt(startPos.X);
        this.VDom = null;
        this.Init();
    }
    Init() {
        let g = document.createElementNS("http://www.w3.org/2000/svg", "g");
        let line = document.createElementNS("http://www.w3.org/2000/svg", "line");
        let path = document.createElementNS("http://www.w3.org/2000/svg", "path");
        $(g)
            .attr("transform", "translate(0,-6)")
            .attr("candrag", "true")
            .css("height", "9px")
            .css("width", "100%")
            .css("cursor", "n-resize")
            .attr("isSelected", "false")
            .attr("cursor-color", this.Color)
            .attr("aria-title", "");
        $(line)
            .attr("stroke", this.Color)
            .attr("stroke-width", this.Stroke)
            .attr("stroke-dasharray", "1 1")
            .attr("x1", this.StartPos.X)
            .attr("y1", this.StartPos.Y + 6)
            .attr("x2", this.StartPos.X + this.Width)
            .attr("y2", this.StartPos.Y + 6);
        $(path)
            .attr("fill", "rgba(0,0,0,0)")
            .attr(
                "d",
                "M " +
                this.StartPos.X +
                " " +
                this.StartPos.Y +
                " L " +
                (this.StartPos.X + this.Width) +
                " " +
                this.StartPos.Y +
                " L " +
                (this.StartPos.X + this.Width) +
                " " +
                (this.StartPos.Y + this.Height) +
                " L " +
                this.StartPos.X +
                " " +
                (this.StartPos.Y + this.Height) +
                " L " +
                this.StartPos.X +
                " " +
                this.StartPos.Y +
                ""
            )
            .attr("cursor-rect", "true");
        if (!ruleCheck.IsNullOrSpaceStr(this.Name)) {
            let name = document.createElementNS("http://www.w3.org/2000/svg", "text");
            $(name).text(this.Name);
            //$(name).attr("fill", this.Color).attr("transform", "translate(5,-20) rotate(270,50,50)").attr("x", this.StartPos.X + 10).attr("y", this.StartPos.Y + 12).addClass("none-select").css("font-family", "monospace");
            $(name)
                .attr("fill", this.Color)
                .attr("x", this.StartPos.X + 50)
                .attr("y", this.StartPos.Y)
                .addClass("none-select")
                .css("font-family", "monospace");
            $(g).append(name);
        }
        let value = document.createElementNS("http://www.w3.org/2000/svg", "text");
        $(value).text("");
        $(value)
            .attr("fill", this.Color)
            .attr("x", this.StartPos.X + this.Width)
            .attr("y", this.StartPos.Y)
            .addClass("none-select")
            .css("font-family", "monospace");
        $(g).append(line).append(path).append(value);
        this.VDOM = $(value);
        this.DOM = $(g);
    }
    SetValue(value) {
        if (this.VDOM != null && !ruleCheck.IsNullOrSpaceStr(value)) {
            let width = value.length * 7;
            this.VDOM.text(value);
            this.VDOM.attr("x", this.StartPos.X + this.Width - width - 100).attr("y", this.StartPos.Y);
        } else {
            this.VDOM.text("");
        }
    }
    Remove() {
        if (this.VDOM != null) {
            this.VDOM.remove();
        }
        if (this.DOM) {
            this.DOM.remove();
        }
    }
}

export class AlarmCursorPlugin extends PluginBase {
    constructor(mode) {
        super(mode);
        this.CursorDatas = new Array();
        this.MaxEndDiff = 1000000;
        this.StartPos = null; //鼠标按下位置
        this.StartDatas = new Array(); //鼠标按下时数据信息
        this.CursorCount = 0;
        this.Cursors = new Array();
        this.AlarmType = 0;
        this.AlarmInfo = [];
    }
    OnSizeChanged() {
        let rect = this.Owner.Information.ShapeContainerRect;
        $.each(this.Cursors, function (i, cursor) {
            cursor.Remove();
        });
        this.Cursors = new Array();
        this.AlarmInfo.forEach(item => {
            this.Cursors.push(new HorCursorElement(this, rect.Width, item.Color, "1", { X: rect.Left, Y: rect.Top }, 'L' + item.Level + this.GetAlarmName(), item.Enable));
            if (this.AlarmType === AlarmTypeEnum.InnerWindow || this.AlarmType === AlarmTypeEnum.OuterWindow) {
                this.Cursors.push(new HorCursorElement(this, rect.Width, item.Color, "1", { X: rect.Left, Y: rect.Top }, 'L' + item.Level + this.GetAlarmName(), item.Enable));
            }
        })

        for (let cursor of this.Cursors) {
            $(this.Owner.PluginContainer).append(cursor.DOM);
        }
        this.Cursors.forEach(item => {
            if (!item.Enable) {
                item.DOM.css("display", "none");
            }
        })
        this.OnRender();
    }
    GetAlarmName() {
        let array = {
            0: "",
            1: i18nGlobal('core.overLimit'),
            2: i18nGlobal('core.belowLimit'),
            3: i18nGlobal('core.inWindow'),
            4: i18nGlobal('core.outWindow'),
        };
        return array[this.AlarmType];
    }
    OnRender() {
        let pos = new Array();
        if (this.CursorDatas.length > 0) {
            for (let i = 0; i < this.CursorDatas.length; i++) {
                pos.push(this.CursorDatas[i].Y);
            }
        } else {
            for (let i = 0; i < this.CursorCount; i++) {
                pos.push(0);
            }
        }
        this.SetPosition(pos);
    }
    UpdatePosition(data) {
        let sender = this;
        let rect = this.Owner.Information.ShapeContainerRect;
        if (this.Owner.Option && this.Owner.Option.Axis.ShowAlarmLine == false) {
            $.each(this.Cursors, function () {
                this.DOM.addClass("hidden");
            });
            return;
        }
        if (data?.length > 0) {
            for (let i = 0; i < this.Cursors.length; i++) {
                let cursor = this.Cursors[i];
                let d = sender.CursorDatas[i];
                cursor.DOM.removeClass("hidden");
                if (d.OffsetY < 0 || d.OffsetY > rect.Height) {
                    cursor.DOM.addClass("hidden");
                }
                cursor.SetValue(d.Y.toFixed(2));
                if (!isNaN(d.OffsetY) && isFinite(d.OffsetY)) {
                    cursor.DOM.attr("transform", "translate(0," + (d.OffsetY - 6) + ")");
                }
            }
            this.CursorDatas = data;
        } else {
            for (let cursor of this.Cursors) {
                cursor.DOM.attr("transform", "translate(0," + (rect.Height - 6) + ")");
                cursor.SetValue("");
            }
        }
    }
    Close() {
        super.Close();
        $.each(this.Cursors, function (i, cd) {
            cd.Remove();
        });
    }
    SetPosition(pos) {
        let sender = this;
        sender.CursorDatas = new Array();
        $.each(pos, function (i, p) {
            let offset = sender.Owner.CoordinateSystem.GetYOffset(p);
            sender.CursorDatas.push({
                OffsetY: offset,
                Y: p,
            });
        });
        this.UpdatePosition(sender.CursorDatas);
    }
    getMousePostion(e) {
        let rect = this.Owner.Information.ShapeContainerRect;
        let c_y = e.offsetY;
        let offset_y = c_y - rect.Top;
        if (offset_y + 6 > rect.Height) {
            offset_y = rect.Height;
        }
        if (offset_y < 0) {
            offset_y = 0;
        }
        return offset_y;
    }
    SetAlarmInfo(arr) {
        let pos = [];
        const Amplitude = arr.find((item) => item.AlarmCategory === AlarmTypeModelEnum.Amplitude && item.IsEnabled);
        if (Amplitude && Amplitude.Levels) {
            this.AlarmType = Amplitude.AmplitudeAlarmType;
            this.AlarmInfo = Amplitude.Levels;
            if (this.AlarmType === AlarmTypeEnum.MoreLimit || this.AlarmType === AlarmTypeEnum.LessLimit) {
                pos = Amplitude.Levels.map(item => item.Threshold)
            } else {
                let arr = [];
                Amplitude.Levels.forEach(item => {
                    arr.push(item.LowerLimit);
                    arr.push(item.UpperLimit);
                })
                pos = arr;
            }
            this.CursorCount = pos.length;
            this.SetPosition(pos);
            this.OnSizeChanged()
        } else {
            this.AlarmInfo = [];
        }
    }
    Reset(alarmType) {
        this.AlarmType = alarmType;
    }
}
