import $ from "jquery";
import { draggable } from "@core/graphs/webcharts/Dh-webchart-draggable";
import { PluginBase } from "./DH-plugin-base";
import { ruleCheck } from "@common/js/rules";
import { PluginMode, StatisticInfo, StatisticInfoDic } from "@core/graphs/webcharts/Dh-webchart-models";
import { i18nGlobal } from "@common/js/utils";
export class StatisticsPlugin extends PluginBase {
	constructor() {
		super();
		this.StatisticsWnd = null;
		this.SelectedItem = null;
		this.Right = null;
		this.Top = null;
		this.ValueType = [];
		this.rowMap = new Map();
		this.columnMap = new Map();
		this.onItemChangedEvent = new Map();
	}
	CreatePlugin() {
		const padding = this.Owner.Information.Padding;
		this.Right = 20;
		this.Top = padding.Top;
		const table = `<div class="cursor-window none-select statistics-wnd" style="top:${this.Top}px;">
                        <table class="cursor-container">
                            <tbody>
                                <tr>
                                    <td><div class="cursor-array"></div></td>
                                    <td><span class="cursor-title">${i18nGlobal('core.curveInfo')}</span></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>`;
		this.StatisticsWnd = $(table);
		$(this.Owner.ChartContainer).append(this.StatisticsWnd);
		this.StatisticsWnd.on("dblclick", function (e) {
			e.preventDefault();
			e.stopPropagation();
		});
		if (this.IsEnabled) {
			new draggable({
				element: this.StatisticsWnd,
				owner: this,
				containment: "parent",
			});
		}
	}
	OnSizeChanged() {
		this.InitSvgEvent();
	}
	UpdatePosition() {
		const chart = this.Owner.Information.ChartContainerRect;
		const padding = this.Owner.Information.Padding;
		const wndWidth = this.StatisticsWnd.width() < 80 ? 80 : this.StatisticsWnd.width();
		const orginLeft = chart.Width - wndWidth - padding.Right;
		let left = orginLeft;
		let top = this.StatisticsWnd.css("top").replace("px", "");
		let cssLeft = this.StatisticsWnd.css("left").replace("px", "");
		let limitTop = chart.Height - this.StatisticsWnd.height();
		if (limitTop < 0) limitTop = 0;
		if (cssLeft < left) left = cssLeft;
		if (left < 0) left = 0;
		if (top < 0) top = 0;
		if (top > limitTop) top = limitTop;
		const parent = this.StatisticsWnd.parent();
		if (this.moved != true) left = orginLeft;
		this.StatisticsWnd.css("left", (left / parent.width()) * 100 + "%");
		this.StatisticsWnd.css("top", (top / parent.height()) * 100 + "%");
	}
	OnRender() {
		let datas = this.pretreatMentData();
		const showLegend = this.Owner.CoordinateSystem.ShowLegend;
		const showUnit = this.Owner.CoordinateSystem.ShowUnit;
		const yAxisLogType = this.Owner.CoordinateSystem.YAxisLogType;
		if (showLegend) {
			this.StatisticsWnd.removeClass("hide");
		} else {
			this.StatisticsWnd.addClass("hide");
		}
		for (let i = 0; i < datas.length; i++) {
			const data = datas[i];
			let isShowUnit = showUnit && !ruleCheck.IsNullOrSpaceStr(data.YUnit);
			let unit = "";
			if (isShowUnit) {
				if (yAxisLogType === 3) {
					unit = "[dB]";
				} else {
					unit = "[" + data.YUnit + "]";
				}
			}
			// let unit = isShowUnit ? '[' + data.YUnit + ']' : '';
			const rowIndex = i + 1;
			this.createRow(data.PointId, rowIndex, data.Legend, unit, data.LineColor);
			const valueTypes = this.ValueType.filter(p => this.isSupportValueType(p));
			for (let j = 0; j < valueTypes.length; j++) {
				const type = valueTypes[j];
				const key = data.PointId + "_" + type;
				const colIndex = j + 2;
				this.createColumnHeader(type, colIndex, StatisticInfoDic[type]);
				this.updateColumn(data.PointId, key, colIndex, this.GetStatisc(type, data), data.LineColor);
			}
		}
		if (datas.length > 0) {
			if (this.SelectedItem == null) {
				this.SelectedItem = datas[0].PointId;
			} else if (!datas.find((r) => r.PointId == this.SelectedItem)) {
				this.SelectedItem = datas[0].PointId;
			}
			this.StatisticsWnd.find(".radio-small").removeClass("radio-selected");
			if (this.rowMap.has(this.SelectedItem)) {
				this.rowMap.get(this.SelectedItem).dom.find(".radio-small").addClass("radio-selected");
			}
			this.NoticeCussor(this.SelectedItem);
		}
		this.UpdatePosition();
		datas = null;
	}
	isSupportValueType(type) {
		return true;
	}
	pretreatMentData() {
		let datas = [];
		if (this.Owner.ShapeOperator.Data.length > 0) {
			for (let data of this.Owner.ShapeOperator.Data) {
				if (datas.findIndex((r) => r.PointId == data.PointId) < 0) {
					datas.push(data);
				}
			}
		}
		let rows = [...this.rowMap.keys()];
		for (let data of datas) {
			let index = rows.findIndex((r) => r == data.PointId);
			if (index >= 0) {
				rows.splice(index, 1);
			}
		}
		let columns = [...this.columnMap.keys()];
		const valueTypes = this.ValueType.filter(p => this.isSupportValueType(p));
		for (let type of valueTypes) {
			let index = columns.findIndex((r) => r == type);
			if (index >= 0) {
				columns.splice(index, 1);
			}
		}

		for (let key of rows) {
			let row = this.rowMap.get(key);
			row.columns.clear();
			row.dom.find("a").off("click");
			row.dom.remove();
			this.rowMap.delete(key);
		}

		for (let key of columns) {
			this.columnMap.get(key).remove();
			this.columnMap.delete(key);
			for (let pid of this.rowMap.keys()) {
				let temp = pid + "_" + key;
				let columnsTemp = this.rowMap.get(pid).columns;
				columnsTemp.get(temp).remove();
				columnsTemp.delete(temp);
			}
		}
		return datas;
	}
	createColumnHeader(id, colIndex, name) {
		if (this.columnMap.has(id)) {
			return;
		}
		const $html = $(`<td><span class="cursor-title">${name}</span></td>`);
		let td = this.StatisticsWnd.find("tbody tr:first td")[colIndex - 1];
		$(td).after($html);
		this.columnMap.set(id, $html);
	}
	updateColumn(rowId, colId, colIndex, value, color) {
		let row = this.rowMap.get(rowId);
		if (row.columns.has(colId)) {
			let temp = row.columns.get(colId).find("span");
			temp.css("color", color);
			temp.html(value);
		} else {
			const $html = $(`<td style="white-space: nowrap;">
                                <span class="legend-text" style="color:${color};">${value}</span>
                            </td>`);
			let td = row.dom.find("td")[colIndex - 1];
			$(td).after($html);
			row.columns.set(colId, $html);
		}
	}
	createRow(id, rowIndex, value, unit, color) {
		if (this.rowMap.has(id)) {
			this.rowMap.get(id).dom.find(".legend-text").css("color", color);
			this.rowMap.get(id).dom.find(".radio-big").css("background-color", color);
			this.rowMap.get(id).dom.find(".radio-small").css("background-color", color);
			this.rowMap.get(id).dom.find(".legend-unit").css("color", color);
			this.rowMap.get(id).dom.find(".legend-text").html(value);
			this.rowMap.get(id).dom.find(".legend-unit").html(unit);
		} else {
			const $html = $(`<tr>
                            <td>
                                <a style="cursor:pointer;">
                                    <span class="radio-big" style="background-color:${color}"></span>
                                    <span class="radio-small" style="background-color:${color}"></span>
                                </a>
                            </td>
                            <td style="white-space: nowrap;display:flex;">
                                <span class="legend-text" style="color:${color}">${value}</span>
                                <span class="legend-unit" style="color:${color}">${unit}</span>
                            </td>
                        </tr>`);
			let tr = this.StatisticsWnd.find("tbody tr")[rowIndex - 1];
			$(tr).after($html);
			this.rowMap.set(id, {
				dom: $html,
				columns: new Map(),
			});
			if (this.IsEnabled) {
				$html.find("a").on("click", () => {
					this.RadioClickCallBack(id, $html)
				});
			}
		}
	}
	// $html
	RadioClickCallBack(id) {
		if (this.SelectedItem != id) {
			this.SelectedItem = id;
			const html = this.rowMap.get(id).dom;
			this.StatisticsWnd.find(".radio-small").removeClass("radio-selected");
			html.find(".radio-small").addClass("radio-selected");
			// 切换测点先置空转速值
			if (this.Owner?.Option?.RotateSpeed) {
				this.Owner.Option.RotateSpeed = null;
			}
			this.Owner.OnRender();
		}
	}
	NoticeCussor(selectedItem) {
		this.onItemChangedEvent.forEach((fun) => {
			fun(selectedItem)
		})
	}
	RegisterItemChangeEvent(requestId, callback) {
		this.onItemChangedEvent?.set(requestId, callback);
	}
	UnRegisterItemChangeEvent(requestId) {
		this.onItemChangedEvent?.delete(requestId)
	}
	GetStatisc(type, cdata) {
		const sender = this;
		const dotNum = sender.Owner.CoordinateSystem.YDecimalPrecision;
		let datas = cdata.Points;
		// 双光标只取之间的值
		const doubleCursor = sender.Owner.PluginManager.Plugins.get(PluginMode.DoubleCursor);
		if (doubleCursor && doubleCursor.CursorDatas.length > 0) {
			let x0 = sender.Owner.CoordinateSystem.GetPositionByIndex(doubleCursor.CursorDatas[0].XIndex)?.X;
			let x1 = sender.Owner.CoordinateSystem.GetPositionByIndex(doubleCursor.CursorDatas[1].XIndex)?.X;
			if (x0 !== undefined && x1 !== undefined) {
				let start = x0 > x1 ? x1 : x0;
				let end = x0 < x1 ? x1 : x0;
				datas = cdata.Points.filter((item) => item.X >= start && item.X <= end);
			}
		}
		switch (parseInt(type)) {
			case StatisticInfo.Max:
				return this.GetMaxValue(datas, dotNum);
			case StatisticInfo.Min:
				return this.GetMinValue(datas, dotNum);
			case StatisticInfo.Avg:
				return this.GetAvageValue(datas, dotNum);
			case StatisticInfo.RMS:
				return this.GetRMSValue(datas, dotNum);
			case StatisticInfo.Skewness:
				return this.GetSkewnessValue(datas, dotNum);
			case StatisticInfo.Kurtosis:
				return this.GetKurtosisValue(datas, dotNum);
			case StatisticInfo.SD:
				return this.GetSDValue(datas, dotNum);
			case StatisticInfo.PTP:
				return this.GetPTPValue(datas, dotNum);
			case StatisticInfo.Slope:
				return this.GetSlopeValue(datas, dotNum);
			case StatisticInfo.Deviation:
				return this.GetDeviationValue(datas, dotNum);
			case StatisticInfo.APTP:
				return this.GetAPTPValue(datas, dotNum);
			case StatisticInfo.PeakList:
				return this.GetPeakListValue(datas, dotNum);
		}
		return 0;
	}
	GetMaxValue(data, dotNum) {
		let result = null;
		$.each(data, function (i, point) {
			const Y = parseFloat(point.Y);
			if (result === null) result = Y;
			if (result < Y) {
				result = Y;
			}
		});
		return data.length ? result.toFixed(dotNum) : Number(0).toFixed(dotNum);
	}
	GetMinValue(data, dotNum) {
		let result = null;
		$.each(data, function (i, point) {
			const Y = parseFloat(point.Y);
			if (result === null) result = Y;
			if (result > Y) {
				result = Y;
			}
		});
		return data.length ? result.toFixed(dotNum) : Number(0).toFixed(dotNum);
	}
	GetAvageValue(data, dotNum) {
		let result = 0;
		$.each(data, function (i, point) {
			result += parseFloat(point.Y);
		});
		return data.length ? (result / data.length).toFixed(dotNum) : Number(0).toFixed(dotNum);
	}
	GetRMSValue(data, dotNum) {
		let squares = data.map((val) => parseFloat(val.Y) * parseFloat(val.Y));
		let sum = squares.length > 0 ? squares.reduce((acum, val) => acum + val) : 0;
		let mean = data.length ? sum / data.length : 0;
		return Math.sqrt(mean).toFixed(dotNum);
	}
	// 偏度值
	GetSkewnessValue(data, dotNum) {
		const avage = this.GetAvageValue(data, dotNum);
		let result = 0;
		const squaresMap = data.map((val) => {
			return {
				three: Math.pow(parseFloat(val.Y) - avage, 3),
				two: Math.pow(parseFloat(val.Y) - avage, 2),
			};
		});
		const threeSum = squaresMap.length > 0 ? squaresMap.reduce((acum, val) => acum + val.three, 0) : 0;
		const twoSum = squaresMap.length > 0 ? squaresMap.reduce((acum, val) => acum + val.two, 0) : 0;
		const n = data.length;
		const numerator = data.length ? threeSum / n : 0;
		const denominator = data.length ? Math.pow(twoSum / n, 1.5) : 0;
		result = numerator / denominator;
		return isNaN(result) ? Number(0).toFixed(dotNum) : result.toFixed(dotNum);
	}
	GetSDValue(data, dotNum) {
		let x = this.GetAvageValue(data, dotNum);
		let total = 0;
		for (let point of data) {
			total += Math.pow(point.Y - x, 2);
		}

		return data.length ? Math.sqrt(total / data.length).toFixed(dotNum) : Number(0).toFixed(dotNum);
	}
	GetKurtosisValue(data, dotNum) {
		const avage = this.GetAvageValue(data, dotNum);
		let result = 0;
		const squaresMap = data.map((val) => {
			return {
				four: Math.pow(parseFloat(val.Y) - avage, 4),
				two: Math.pow(parseFloat(val.Y) - avage, 2),
			};
		});
		const fourSum = squaresMap.length > 0 ? squaresMap.reduce((acum, val) => acum + val.four, 0) : 0;
		const twoSum = squaresMap.length > 0 ? squaresMap.reduce((acum, val) => acum + val.two, 0) : 0;
		const n = data.length;
		const numerator = data.length ? fourSum / n : 0;
		const denominator = data.length ? Math.pow(twoSum / n, 2) : 0;
		// result = (numerator / denominator) - 3;
		result = numerator / denominator;

		return isNaN(result) ? Number(0).toFixed(dotNum) : result.toFixed(dotNum);
	}
	GetPTPValue(data, dotNum) {
		let max = -1000000;
		$.each(data, function (i, point) {
			if (max < parseFloat(point.Y)) {
				max = parseFloat(point.Y);
			}
		});
		let min = 1000000;
		$.each(data, function (i, point) {
			if (min > parseFloat(point.Y)) {
				min = parseFloat(point.Y);
			}
		});
		return data.length ? (max - min).toFixed(dotNum) : Number(0).toFixed(dotNum);
	}
	GetSlopeValue(data, dotNum) {
		let result = data.Points.length > 0 ? data.Points[0].Y : 0;
		return result.toFixed(dotNum);
	}
	GetDeviationValue(data, dotNum) {
		let result = data.Points.length > 0 ? data.Points[0].Y : 0;
		return result.toFixed(dotNum);
	}
	GetAPTPValue(data, dotNum) {
		let result = data.Points.length > 0 ? data.Points[0].Y : 0;
		return result.toFixed(dotNum);
	}
	GetPeakListValue(data, dotNum) {
		let result = data.Points.length > 0 ? data.Points[0].Y : 0;
		return result.toFixed(dotNum);
	}
	InitSvgEvent() { }
	Close() {
		for (let row of this.rowMap.values()) {
			row.columns.clear();
			row.dom.find("a").off("click");
		}
		this.rowMap.clear();
		this.columnMap.clear();
		this.StatisticsWnd.off("dblclick");
		this.StatisticsWnd.remove();
		this.StatisticsWnd = null;
	}
}

export class HistogramStatisticsPlugin extends StatisticsPlugin {
	constructor() {
		super();
	}
	isSupportValueType(type) {
		if (type == 1 || type == 2 || type == 8) return true;
		return false;
	}
	GetMaxValue(data, dotNum) {
		let result = Number.NEGATIVE_INFINITY;
		$.each(data, function (i, barDataPoint) {
			if (result < parseFloat(barDataPoint.Value) && parseFloat(barDataPoint.Value) != 0) {
				result = parseFloat(barDataPoint.Value);
			}
		});
		return result === Number.NEGATIVE_INFINITY ? Number(0).toFixed(dotNum) : result.toFixed(dotNum);
	}
	GetMinValue(data, dotNum) {
		let result = Number.POSITIVE_INFINITY;
		$.each(data, function (i, barDataPoint) {
			if (result > parseFloat(barDataPoint.Value) && parseFloat(barDataPoint.Value) != 0) {
				result = parseFloat(barDataPoint.Value);
			}
		});
		return result === Number.POSITIVE_INFINITY ? Number(0).toFixed(dotNum) : result.toFixed(dotNum);
	}
	GetPTPValue(data, dotNum) {
		let max = Number.NEGATIVE_INFINITY;
		$.each(data, function (i, barDataPoint) {
			if (max < parseFloat(barDataPoint.Value) && parseFloat(barDataPoint.Value) != 0) {
				max = parseFloat(barDataPoint.Value);
			}
		});
		let min = Number.POSITIVE_INFINITY;
		$.each(data, function (i, barDataPoint) {
			if (min > parseFloat(barDataPoint.Value) && parseFloat(barDataPoint.Value) != 0) {
				min = parseFloat(barDataPoint.Value);
			}
		});
		return max - min === Number.NEGATIVE_INFINITY ? Number(0).toFixed(dotNum) : (max - min).toFixed(dotNum);
	}
	createRow(id, rowIndex, value, unit, color) {
		if (this.rowMap.has(id)) {
			this.rowMap.get(id).dom.find(".legend-text").css("color", color);
			this.rowMap.get(id).dom.find(".legend-unit").css("color", color);
			this.rowMap.get(id).dom.find(".legend-text").html(value);
			this.rowMap.get(id).dom.find(".legend-unit").html(unit);
		} else {
			const $html = $(`<tr>
                            <td></td>
                            <td style="white-space: nowrap;display:flex;">
                                <span class="legend-text" style="color:${color}">${value}</span>
                                <span class="legend-unit" style="color:${color}">${unit}</span>
                            </td>
                        </tr>`);
			let tr = this.StatisticsWnd.find("tbody tr")[rowIndex - 1];
			$(tr).after($html);
			this.rowMap.set(id, {
				dom: $html,
				columns: new Map(),
			});
		}
	}
}
