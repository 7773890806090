// @generated by protobuf-ts 2.9.1
// @generated from protobuf file "protos/latesteigendatas.proto" (package "storage", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message storage.QueryLatestEigenDatasRequest
 */
export interface QueryLatestEigenDatasRequest {
    /**
     * @generated from protobuf field: string StartTime = 1 [json_name = "StartTime"];
     */
    startTime: string;
    /**
     * @generated from protobuf field: repeated storage.PointEigenRequest Points = 2 [json_name = "Points"];
     */
    points: PointEigenRequest[];
}
/**
 * @generated from protobuf message storage.PointEigenRequest
 */
export interface PointEigenRequest {
    /**
     * @generated from protobuf field: int32 PointId = 1 [json_name = "PointId"];
     */
    pointId: number;
    /**
     * @generated from protobuf field: repeated int32 EigenTypes = 2 [json_name = "EigenTypes"];
     */
    eigenTypes: number[];
}
// @generated message type with reflection information, may provide speed optimized methods
class QueryLatestEigenDatasRequest$Type extends MessageType<QueryLatestEigenDatasRequest> {
    constructor() {
        super("storage.QueryLatestEigenDatasRequest", [
            { no: 1, name: "StartTime", kind: "scalar", jsonName: "StartTime", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "Points", kind: "message", jsonName: "Points", repeat: 1 /*RepeatType.PACKED*/, T: () => PointEigenRequest }
        ]);
    }
    create(value?: PartialMessage<QueryLatestEigenDatasRequest>): QueryLatestEigenDatasRequest {
        const message = { startTime: "", points: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<QueryLatestEigenDatasRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: QueryLatestEigenDatasRequest): QueryLatestEigenDatasRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string StartTime = 1 [json_name = "StartTime"];*/ 1:
                    message.startTime = reader.string();
                    break;
                case /* repeated storage.PointEigenRequest Points = 2 [json_name = "Points"];*/ 2:
                    message.points.push(PointEigenRequest.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: QueryLatestEigenDatasRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string StartTime = 1 [json_name = "StartTime"]; */
        if (message.startTime !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.startTime);
        /* repeated storage.PointEigenRequest Points = 2 [json_name = "Points"]; */
        for (let i = 0; i < message.points.length; i++)
            PointEigenRequest.internalBinaryWrite(message.points[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message storage.QueryLatestEigenDatasRequest
 */
export const QueryLatestEigenDatasRequest = new QueryLatestEigenDatasRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PointEigenRequest$Type extends MessageType<PointEigenRequest> {
    constructor() {
        super("storage.PointEigenRequest", [
            { no: 1, name: "PointId", kind: "scalar", jsonName: "PointId", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "EigenTypes", kind: "scalar", jsonName: "EigenTypes", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<PointEigenRequest>): PointEigenRequest {
        const message = { pointId: 0, eigenTypes: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PointEigenRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PointEigenRequest): PointEigenRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 PointId = 1 [json_name = "PointId"];*/ 1:
                    message.pointId = reader.int32();
                    break;
                case /* repeated int32 EigenTypes = 2 [json_name = "EigenTypes"];*/ 2:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.eigenTypes.push(reader.int32());
                    else
                        message.eigenTypes.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PointEigenRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 PointId = 1 [json_name = "PointId"]; */
        if (message.pointId !== 0)
            writer.tag(1, WireType.Varint).int32(message.pointId);
        /* repeated int32 EigenTypes = 2 [json_name = "EigenTypes"]; */
        if (message.eigenTypes.length) {
            writer.tag(2, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.eigenTypes.length; i++)
                writer.int32(message.eigenTypes[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message storage.PointEigenRequest
 */
export const PointEigenRequest = new PointEigenRequest$Type();
