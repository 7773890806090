import $ from "jquery";
import DrawApi from "@core/graphs/webcharts/Dh-webchart-drawApis";
import { Point, Rect, LineMode, PluginMode } from "@core/graphs/webcharts/Dh-webchart-models";
import { numberRound, ruleCheck } from "@common/js/rules";
import { add, bignumber, multiply, round } from "mathjs";
import store from "@common/js/store";

/**坐标轴信息 */
export class CoordinateSystemOperation {
    constructor(id) {
        this.Id = id;
        this.Owner = null;
        this.FontSize = 12;
        this.FontStyle = this.FontSize + "px Lucida Sans Unicode";
        this.FontColor = "#606060";
        this.UnitTextStyle = "12px 微软雅黑";
        this.UnitTextColor = "#606060";
        this.HorCrossColor = "#d1d1d1"; //横网格线颜色
        this.VerCrossColor = "#d1d1d1"; //纵网格线颜色
        this.XAxisTextStyle = " normal 11px Arial";
        this.XAxisTextColor = "black";
        this.YAxisTextStyle = " normal 11px Arial";
        this.YAxisTextColor = "#333333";
        this.TitleStyle = " normal 14px Arial";
        this.TitleColor = "#333";
        this.HeaderStyle = " normal 13px Arial";
        this.HeaderColor = "#333";
        this.RuleColor = "#666666";
        this.AxisColor = "#666666";
        this.ShowTitle = true;
        this.ShowXAxis = true;
        this.ShowYAxis = true;
        this.ShowYRule = true;
        this.ShowXRule = true;
        this.ShowYText = true;
        this.ShowXText = true;
        this.ShowHorCross = true;
        this.ShowVerCross = true;
        this.HorCrossStyle = LineMode.Solid;
        this.VerCrossStyle = LineMode.Solid;
        this.ShowXUnit = false;
        this.ShowUnit = true;
        this.Unit = ""; //单位
        this.Title = ""; //标题
        this.Header = ""; //头
        this.XDecimalPrecision = 3;
        this.YDecimalPrecision = 3;
        this.GridSelfAdaption = true; //网格是否自适应
        this.XGridNum = 4;
        this.YGridNum = 4;
        this.CanvasLeft = 0;
        this.ShowLegend = true;
        this.drawApi = new DrawApi();
        this.DrawPoint = true;
        this.isApp = store.state.browseType === 'app';
    }
    /**设置属性 */
    SetOption(option) {
        for (const k in this) {
            for (const p in option) {
                if (k == p) {
                    this[k] = option[p];
                }
            }
        }
    }
    /**测量 */
    OnMeasure() { }
    /**绘制 */
    OnRender() { }
    /**确认容器已经创建 */
    EnsureContainerCreated() {
        const container = this.Owner.PluginContainer;
        let element = document.getElementById(this.Id);

        if (element == null) {
            element = document.createElement("canvas");
            element.setAttribute("id", this.Id);
            element.setAttribute("style", "position:absolute;left:" + this.CanvasLeft + "px;pointer-events:none;");
            container.before(element);
        }

        const dpr = window.devicePixelRatio;
        element.width = $("#" + this.Owner.ChartContainer.id).width() * dpr;
        element.height = $("#" + this.Owner.ChartContainer.id).height() * dpr;
        element.style.width = element.width / dpr + "px";
        element.style.height = element.height / dpr + "px";

        this.drawApi.SetContext(this.Id);
        this.drawApi.DrawContext.scale(dpr, dpr);

    }
    /**获取文本大小 */
    GetTextSize(text, style) {
        let st = this.FontStyle;
        if (style != null) {
            st = style;
        }
        return this.drawApi.MeasureText(text, st, st);
    }
    /**近似尺寸 */
    CeilingSize(size) {
        return {
            Width: Math.ceil(size.Width),
            Height: Math.ceil(size.Height),
        };
    }
    /**测量X方向默认文字尺寸 */
    GetXDemoSize() {
        return this.ShowXText ? this.GetTextSize("Test", this.XAxisTextStyle) : { Width: 0, Height: 0 };
    }
    /**测量Y方向默认文字尺寸 */
    GetYDemoSize() {
        return this.ShowYText ? this.GetTextSize("Test", this.YAxisTextStyle) : { Width: 0, Height: 0 };
    }
    //获取X方向文字尺寸
    GetXTextSize(text) {
        return this.GetTextSize(text, this.XAxisTextStyle);
    }
    /**获取选中的测点Id */
    GetSelectItem() {
        const statistics = this.Owner.PluginManager.GetPlugin(PluginMode.Statistics);
        if (statistics?.SelectedItem != null) {
            return statistics.SelectedItem;
        } else if (this.Owner.ShapeOperator.Data.length > 0) {
            return this.Owner.ShapeOperator.Data[0].PointId;
        }
        return null;
    }
    /**获取选中的测点单位 */
    GetSelectItemUnit() {
        const id = this.GetSelectItem();
        if (this.Owner.ShapeOperator.Data && id) {
            const p = this.Owner.ShapeOperator.Data.find((r) => r.PointId == id);
            if (p) {
                return p.XUnit;
            }
        }
        return "";
    }
    /**清除 */
    Clear() {
        if (this.drawApi.DrawContext) {
            this.drawApi.Dispose();
        }
        for (const p in this) {
            this[p] = null;
        }
    }
}

export class StandardCoordinateSystem extends CoordinateSystemOperation {
    constructor(id) {
        super(id);
        this.DesignWidth = 120;
        this.DesignHeight = 100;
        this.DesignXRule = 11;
        this.DesignYRule = 6;
        this.RuleWidth = 1;
        this.RuleLength = 8;
    }
    MeasureGrid() {
        const info = this.Owner.Information;

        const yUnitTextSize = this.GetTextSize("Test", this.UnitTextStyle);
        const yScaleTextSize = this.GetYDemoSize();
        const xScaleTextSize = this.GetXDemoSize();

        info.ShapeContainerRect = new Rect();
        info.ShapeContainerRect.Left = info.Padding.Left + (this.ShowYAxis ? yUnitTextSize.Height : 0) + (this.ShowYRule ? this.RuleLength + yScaleTextSize.Height : 0);
        info.ShapeContainerRect.Top = info.Padding.Top;
        info.ShapeContainerRect.Width = info.ChartContainerRect.Width - info.ShapeContainerRect.Left - info.Padding.Right;
        info.ShapeContainerRect.Height = info.ChartContainerRect.Height - info.Padding.Top - info.Padding.Bottom - (this.ShowXRule ? this.RuleLength + xScaleTextSize.Height : 0);
    }
    IsHideTitle() {
        /** 图谱宽度过窄导致标题被遮盖时，不绘制标题 */
        const chartWidth = this.Owner.Information.ChartContainerRect.Width;
        const toolbarWidth = ($(`#${this.Owner.Option.Id}`).siblings('.toolbar').width() || 74) + 10; //toolbar绝对定位有10px的右偏移
        const titleWidth = this.GetTextSize(this.Title, this.TitleStyle).Width;
        return (chartWidth / 2 - toolbarWidth) < (titleWidth / 2);
    }
    DrawTitle() {
        const info = this.Owner.Information;
        const rect = this.Owner.Information.ChartContainerRect;
        const chartWidth = rect.Width;
        if (!ruleCheck.IsNullOrSpaceStr(this.Title) && this.ShowTitle) {
            let titleSize = this.GetTextSize(this.Title, this.TitleStyle);
            if (titleSize.Height < info.Padding.Top && !this.IsHideTitle()) {
                let x = (chartWidth - titleSize.Width) / 2;
                let y = 30 - titleSize.Height / 2;
                this.drawApi.FillText(this.Title, x, y - 2, this.TitleColor, this.TitleStyle);
            }
        }
    }
    DrawHeader() {
        const info = this.Owner.Information;
        if (!ruleCheck.IsNullOrSpaceStr(this.Header)) {
            const headerSize = this.GetTextSize(this.Header, this.HeaderStyle);
            if (headerSize.Height < info.Padding.Top) {
                const x = info.ShapeContainerRect.Left + 50;
                const y = 30 - headerSize.Height / 2;
                this.drawApi.FillText(this.Header, x, y - 2, this.HeaderColor, this.HeaderStyle);
            }
        }
    }
    GetPoints() { }
    DrawYText(s, x, y, fontColor, fontStyle) {
        if (s != null) this.drawApi.FillVerticalText(s, x, y, fontColor, fontStyle);
    }
    DrawXText(s, x, y, fontColor, fontStyle) {
        this.drawApi.FillText(s, x, y + 4, fontColor, fontStyle);
    }
    FormatXText(text) {
        return round(bignumber(text), this.XDecimalPrecision);
    }
    FormatYText(text) {
        return numberRound(text, this.YDecimalPrecision);
    }
    GetYScaleText(max, min, num) {
        const result = {
            Min: this.FormatYText(max),
            Max: this.FormatYText(min),
            YTexts: [this.FormatYText(max), this.FormatYText(min)],
        };
        if (num > 1 && max >= min) {
            const step = (max - min) / num;
            result.YTexts = new Array();

            for (let i = 0; i <= num; i++) {
                result.YTexts.push(this.FormatYText(max - i * step));
            }
        }
        return result;
    }
    GetYValue(yoffset) {
        const info = this.Owner.Information;
        const shapeRect = info.ShapeContainerRect;
        const ymax = info.CoordinateInfo.YMax;
        const ymin = info.CoordinateInfo.YMin;
        const yunit = (ymax - ymin) / info.ShapeContainerRect.Height;
        return (shapeRect.Height - yoffset) * yunit + ymin;
    }
    GetXValue(xoffset) {
        const info = this.Owner.Information;
        const shapeRect = info.ShapeContainerRect;
        const xmax = info.CoordinateInfo.XMax;
        const xmin = info.CoordinateInfo.XMin;
        const xunit = (xmax - xmin) / shapeRect.Width;
        return xoffset * xunit + xmin;
    }
    GetYOffset(yvalue) {
        const info = this.Owner.Information;
        const shapeRect = info.ShapeContainerRect;
        const ymax = info.CoordinateInfo.YMax;
        const ymin = info.CoordinateInfo.YMin;
        const yunit = (ymax - ymin) / info.ShapeContainerRect.Height;
        return shapeRect.Height - (yvalue - ymin) / yunit;
    }
    GetXOffset(xvalue) {
        const info = this.Owner.Information;
        const shapeRect = info.ShapeContainerRect;
        const xmin = info.CoordinateInfo.XMin;
        return shapeRect.Left + (xvalue - xmin) / info.XUnit;
    }
}

export class TableCoordinateSystem extends StandardCoordinateSystem {
    constructor(id) {
        super(id);
        this.Rows = 0;
        this.Columns = 0;
        this.XDiff = 0;
        this.YDiff = 0;
        this.XTexts = null;
        this.YTexts = null;
    }
    OnMeasure() {
        this.EnsureContainerCreated();
        this.MeasureGrid();
        this.MeasureRows();
        this.MeasureColumns();
    }
    MeasureRows() {
        const info = this.Owner.Information;
        //计算Y方向所有坐标并记录下值
        this.YTexts = this.GridSelfAdaption
            ? this.GetYScaleText(info.CoordinateInfo.YMax, info.CoordinateInfo.YMin, Math.floor(info.ShapeContainerRect.Height / this.DesignHeight)).YTexts
            : this.GetYScaleText(info.CoordinateInfo.YMax, info.CoordinateInfo.YMin, this.YGridNum).YTexts;
        this.Rows = this.YTexts != null && this.YTexts.length > 0 ? this.YTexts.length - 1 : 1;
        if (this.Rows <= 0) this.Rows = 1;
        info.YUnit = (info.CoordinateInfo.YMax - info.CoordinateInfo.YMin) / info.ShapeContainerRect.Height;
    }
    MeasureColumns() {
        const info = this.Owner.Information;
        this.Columns = this.GridSelfAdaption ? Math.floor(info.ShapeContainerRect.Width / this.DesignWidth) : this.XGridNum;
        if (this.Columns <= 0) this.Columns = 1;
        this.XDiff = (info.CoordinateInfo.XMax - info.CoordinateInfo.XMin) / this.Columns;
        //计算X方向所有坐标并记录下值
        this.XTexts = new Array();
        for (let u = 0; u < this.Columns + 1; u++) {
            let coord = add(bignumber(info.CoordinateInfo.XMin), multiply(bignumber(this.XDiff), u)).toString();
            this.XTexts.push(this.FormatXText(coord));
        }
        info.XUnit = (info.CoordinateInfo.XMax - info.CoordinateInfo.XMin) / info.ShapeContainerRect.Width;
    }
    OnRender() {
        this.EnsureContainerCreated();
        this.DrawTitle();
        this.DrawHeader();
        this.DrawRule();
        this.DrawAxis();
        this.DrawOuterLines();
        this.DrawCrossLines();
        this.DrawAxisText();
        this.DrawUnit();
    }
    DrawRule() {
        this.drawApi.BeginPath();
        this.DrawYRule();
        this.DrawXRule();
        this.drawApi.Stroke(this.RuleColor, 1);
    }
    DrawXRule() {
        const info = this.Owner.Information;
        const rect = info.ShapeContainerRect;
        const xRule = this.DesignXRule;
        const columnUnit = info.ShapeContainerRect.Width / (this.Columns * (xRule - 1));
        if (this.ShowXRule) {
            for (let c = 0; c < this.Columns; c++) {
                for (let i = 1; i < xRule; i++) {
                    const m = i + (xRule - 1) * c;
                    if (m != 0 && m != (xRule - 1) * this.Columns) {
                        if (i > xRule - 2) {
                            this.drawApi.VerticalLeftMoveTo(info.ShapeContainerRect.Left + columnUnit * m, rect.Height + rect.Top);
                            this.drawApi.VerticalLeftLineTo(info.ShapeContainerRect.Left + columnUnit * m, rect.Height + rect.Top + this.RuleLength);
                        } else {
                            this.drawApi.VerticalLeftMoveTo(info.ShapeContainerRect.Left + columnUnit * m, rect.Height + rect.Top);
                            this.drawApi.VerticalLeftLineTo(info.ShapeContainerRect.Left + columnUnit * m, rect.Height + rect.Top + this.RuleLength / 2);
                        }
                    }
                }
            }
            this.drawApi.VerticalLeftMoveTo(info.ShapeContainerRect.Left + info.ShapeContainerRect.Width, rect.Height + rect.Top);
            this.drawApi.VerticalLeftLineTo(info.ShapeContainerRect.Left + info.ShapeContainerRect.Width, rect.Height + rect.Top + this.RuleLength);
        }
    }
    DrawYRule() {
        const info = this.Owner.Information;
        const rect = info.ShapeContainerRect;
        const yRule = this.DesignYRule;
        const rowUnit = info.ShapeContainerRect.Height / (this.Rows * (yRule - 1));
        if (this.ShowYRule) {
            for (let r = 0; r < this.Rows; r++) {
                for (let i = 1; i < yRule; i++) {
                    const m = i + (yRule - 1) * r;
                    if (m != (yRule - 1) * this.Rows) {
                        if (i > yRule - 2) {
                            this.drawApi.HorizontalTopMoveTo(rect.Left, rect.Top + rowUnit * m);
                            this.drawApi.HorizontalTopLineTo(rect.Left - this.RuleLength, rect.Top + rowUnit * m);
                        } else {
                            this.drawApi.HorizontalTopMoveTo(rect.Left, rect.Top + rowUnit * m);
                            this.drawApi.HorizontalTopLineTo(rect.Left - this.RuleLength / 2, rect.Top + rowUnit * m);
                        }
                    }
                }
            }
            this.drawApi.HorizontalTopMoveTo(rect.Left, rect.Top);
            this.drawApi.HorizontalTopLineTo(rect.Left - this.RuleLength, rect.Top);
        }
    }
    DrawAxis() {
        const info = this.Owner.Information;
        const rect = info.ShapeContainerRect;
        this.drawApi.BeginPath();
        if (this.ShowXAxis) {
            this.drawApi.HorizontalBottomMoveTo(rect.Left - this.RuleLength, rect.Top + rect.Height);
            this.drawApi.HorizontalBottomLineTo(rect.Left + rect.Width, rect.Top + rect.Height);
        }

        if (this.ShowYAxis) {
            this.drawApi.VerticalLeftMoveTo(rect.Left, rect.Top);
            this.drawApi.VerticalLeftLineTo(rect.Left, rect.Top + rect.Height + this.RuleLength);
        }
        this.drawApi.Stroke(this.AxisColor, 1);
    }
    DrawOuterLines() {
        const info = this.Owner.Information;
        const rect = info.ShapeContainerRect;
        if (this.ShowXAxis) {
            this.drawApi.BeginPath();
            this.drawApi.HorizontalTopMoveTo(rect.Left, rect.Top);
            this.drawApi.HorizontalTopLineTo(rect.Left + rect.Width, rect.Top);
            this.drawApi.Stroke(this.HorCrossColor, 1);
        }

        if (this.ShowYAxis) {
            this.drawApi.BeginPath();
            this.drawApi.VerticalLeftMoveTo(rect.Left + rect.Width, rect.Top);
            this.drawApi.VerticalLeftLineTo(rect.Left + rect.Width, rect.Top + rect.Height);
            this.drawApi.Stroke(this.VerCrossColor, 1);
        }
    }
    DrawCrossLines() {
        this.DrawXCrossLine();
        this.DrawYCrossLine();
    }
    DrawXCrossLine() {
        const info = this.Owner.Information;
        const rect = info.ShapeContainerRect;
        const columnUnit = rect.Width / this.Columns;

        if (this.ShowVerCross) {
            this.drawApi.BeginPath();
            if (this.VerCrossStyle == LineMode.Dash) {
                this.drawApi.SetLineDash([5]);
            }
            for (let r = 1; r < this.Columns; r++) {
                this.drawApi.VerticalLeftMoveTo(rect.Left + columnUnit * r, rect.Top);
                this.drawApi.VerticalLeftLineTo(rect.Left + columnUnit * r, rect.Top + rect.Height);
            }
            this.drawApi.Stroke(this.VerCrossColor, 1);
        }
    }
    DrawYCrossLine() {
        const info = this.Owner.Information;
        const rect = info.ShapeContainerRect;
        const rowUnit = rect.Height / this.Rows;

        if (this.ShowHorCross) {
            this.drawApi.BeginPath();
            if (this.HorCrossStyle == LineMode.Dash) {
                this.drawApi.SetLineDash([5]);
            }
            for (let r = 1; r < this.Rows; r++) {
                this.drawApi.HorizontalTopMoveTo(rect.Left, rowUnit * r + rect.Top);
                this.drawApi.HorizontalTopLineTo(rect.Left + rect.Width, rowUnit * r + rect.Top);
            }
            this.drawApi.Stroke(this.HorCrossColor, 1);
        }
    }
    DrawAxisText() {
        this.DrawXAxisText();
        this.DrawYAxisText();
    }
    DrawXAxisText() {
        const info = this.Owner.Information;
        const rect = info.ShapeContainerRect;
        const columns = this.Columns;
        const columnUnit = rect.Width / columns;
        if (this.ShowXText) {
            //绘制x轴方向文字
            const xTexts = this.XTexts;
            let textSize;
            if (xTexts?.length > 0) {
                const maxTextWidth = Math.max(this.CeilingSize(this.GetXTextSize(xTexts[0])).Width, this.CeilingSize(this.GetXTextSize(xTexts[xTexts.length - 1])).Width);
                let bestCol = Math.floor((rect.Width - maxTextWidth - 60) / maxTextWidth);
                bestCol = bestCol < 1 ? 1 : bestCol;
                let step = Math.ceil(columns / bestCol) == 0 ? 1 : Math.ceil(columns / bestCol);
                step = step > 1 ? step + (step % 2) : 1;
                textSize = this.CeilingSize(this.GetXTextSize(xTexts[0]));
                let temp = this.GetXOffsetColumn(0, columns);
                const firstLeft = rect.Left - textSize.Width / 2 + temp < 0 ? 5 : rect.Left - textSize.Width / 2 + temp;
                this.DrawXText(xTexts[0], firstLeft, rect.Height + rect.Top + textSize.Height / 2 + this.RuleLength, this.XAxisTextColor, this.XAxisTextStyle);
                for (let r = step; r < columns; r = r + step) {
                    textSize = this.CeilingSize(this.GetXTextSize(xTexts[r]));
                    temp = this.GetXOffsetColumn(r, columns);
                    if (columns - r >= step) {
                        this.DrawXText(
                            xTexts[r],
                            rect.Left + r * columnUnit - textSize.Width / 2 + temp,
                            rect.Height + rect.Top + textSize.Height / 2 + this.RuleLength,
                            this.XAxisTextColor,
                            this.XAxisTextStyle
                        );
                    }
                }
                textSize = this.CeilingSize(this.GetXTextSize(xTexts[columns]));
                //temp = this.GetXOffsetColumn(columns, columns);

                const left = textSize.Width / 2 > info.Padding.Right ? rect.Left + rect.Width + info.Padding.Right - textSize.Width : rect.Left + rect.Width - textSize.Width / 2;
                this.DrawXText(
                    xTexts[columns],
                    left,
                    rect.Height + rect.Top + textSize.Height / 2 + this.RuleLength,
                    this.XAxisTextColor,
                    this.XAxisTextStyle
                );
            }
        }
    }
    DrawYAxisText() {
        const info = this.Owner.Information;
        const rect = info.ShapeContainerRect;
        const rows = this.Rows;
        const rowUnit = rect.Height / rows;
        if (this.ShowYText) {
            //绘制y轴方向文字
            const yTexts = this.YTexts;
            let textSize;
            let maxTextWidth = Math.max(this.CeilingSize(this.GetTextSize(yTexts[0])).Width, this.CeilingSize(this.GetTextSize(yTexts[yTexts.length - 1])).Width);
            let bestRow = Math.floor((rect.Height - 60) / maxTextWidth);
            bestRow = bestRow < 1 ? 1 : bestRow;
            let step = Math.ceil(rows / bestRow) == 0 ? 1 : Math.ceil(rows / bestRow);
            step = step > 1 ? step + (step % 2) : 1;
            for (let r = 0; r <= rows; r = r + step) {
                if (r != 0 && r != rows) {
                    textSize = this.CeilingSize(this.GetTextSize(yTexts[r], this.YAxisTextStyle));
                    if (rows - r >= step) {
                        this.DrawYText(yTexts[r], rect.Left - textSize.Height, r * rowUnit + rect.Top + textSize.Width / 2, this.YAxisTextColor, this.YAxisTextStyle);
                    }
                }
            }

            textSize = this.CeilingSize(this.GetTextSize(yTexts[0], this.YAxisTextStyle));
            this.DrawYText(yTexts[0], rect.Left - textSize.Height, rect.Top + textSize.Width, this.YAxisTextColor, this.YAxisTextStyle);

            textSize = this.CeilingSize(this.GetTextSize(yTexts[rows], this.YAxisTextStyle));
            this.DrawYText(yTexts[rows], rect.Left - textSize.Height, rect.Top + rect.Height, this.YAxisTextColor, this.YAxisTextStyle);
        }
    }
    DrawUnit() {
        const info = this.Owner.Information;
        const rect = info.ShapeContainerRect;
        const xUnit = this.GetSelectItemUnit();
        if (this.ShowXAxis && !ruleCheck.IsNullOrSpaceStr(xUnit) && this.ShowXUnit) {
            const xzi = this.Owner.PluginManager.GetPlugin(PluginMode.XAxisZoomIn);
            const xunitStr = "[" + xUnit + "]";
            const xunitSize = this.CeilingSize(this.GetTextSize(xunitStr, this.UnitTextStyle));
            if (xzi != null) {
                this.drawApi.FillVerticalText(xunitStr, xzi.Left + xunitSize.Height - 1, xzi.Top - xzi.Height + 8, this.UnitTextColor, this.UnitTextStyle);
            } else {
                this.drawApi.FillVerticalText(xunitStr, rect.Left + rect.Width + xunitSize.Height - 1, rect.Top + rect.Height, this.UnitTextColor, this.UnitTextStyle);
            }
        }
    }
    GetXOffsetColumn() {
        return 0;
    }
    GetPoints(series) {
        const info = this.Owner.Information;
        const shapeRect = info.ShapeContainerRect;
        if (series != null && series.Points != null) {
            const result = new Array();
            for (let i = 0; i < series.Points.length; i++) {
                const d = series.Points[i];
                const x = shapeRect.Left + (d.X - info.CoordinateInfo.XMin) / info.XUnit;
                const y = shapeRect.Top + shapeRect.Height - (d.Y - info.CoordinateInfo.YMin) / info.YUnit;
                const point = new Point(x, y);
                if (d.AlarmLevel) point.AlarmLevel = d.AlarmLevel;
                result.push(point);
            }
            return result;
        }
    }
    /**获取离X最近的 */
    GetPositionData(offsetx) {
        const info = this.Owner.Information;
        const shapeRect = info.ShapeContainerRect;
        const data = this.Owner.ShapeOperator.Data;
        if (data?.length > 0 && offsetx >= 0 && offsetx <= shapeRect.Width) {
            const pointId = this.GetSelectItem();
            const point = data.find((r) => pointId != null && r.PointId == pointId);
            if (point?.Points?.length > 0) {
                let index = this.BinarySearch(point.Points, offsetx, p => { return this.GetQueryAction(p) });
                return this.GetPositionByIndex(index);
            }
        }
        return null;
    }
    GetQueryAction(p) {
        return (p.X - this.Owner.Information.CoordinateInfo.XMin) / this.Owner.Information.XUnit;
    }
    GetPositionByX(x) {
        const data = this.Owner.ShapeOperator.Data;
        if (data?.length > 0) {
            const pointId = this.GetSelectItem();
            const point = data.find((r) => pointId != null && r.PointId == pointId);
            if (point?.Points?.length > 0) {
                let index = this.BinarySearch(point.Points, x, p => { return p.X });
                return this.GetPositionByIndex(index);
            }
        }
        return null;
    }
    BinarySearch(points, target, func) {
        let left = 0;
        let right = points.length - 1;
        let delta = 10000000;
        let index = 0;
        if (target == null) return 0;
        let count = 0;
        while (left <= right) {
            if (count > points.length) return 0;
            let mid = parseInt((right + left) / 2);
            let value = func(points[mid]);
            if (isNaN(value)) return mid;
            let c = Math.abs(value - target);
            if (c == 0) {
                return this.GetScreenPointIndex(points, mid);
            } else if (value < target) {
                left = mid + 1;
            } else if (value > target) {
                right = mid - 1;
            }
            if (c < delta) {
                delta = c;
                index = mid;
            }
            if (left == right) {
                c = Math.abs(func(points[left]) - target);
                if (c < delta) {
                    return this.GetScreenPointIndex(points, left);
                } else {
                    return this.GetScreenPointIndex(points, index);
                }
            }
            count++;
        }

        return this.GetScreenPointIndex(points, index);
    }
    GetPositionByIndex(index) {
        const data = this.Owner.ShapeOperator.Data;
        if (data?.length > 0) {
            const pointId = this.GetSelectItem();
            const point = data.find((r) => pointId != null && r.PointId == pointId);
            if (point?.Points?.length > 0) {
                index = this.GetScreenPointIndex(point.Points, index);
                const checkedPoint = point.Points[index];

                let checkedPointOffsetX = this.GetOffsetX(checkedPoint.X);
                const result = {
                    Items: new Array(),
                    OffsetX: checkedPointOffsetX,
                    X: checkedPoint.X,
                    XText: this.FormatXText(checkedPoint.X),
                    XIndex: index
                };
                const cursorPixels = store.state.graphSetting.CursorPixels ?? 10;

                for (const dataPoint of data) {
                    let targetPoint = null;
                    if (dataPoint.Points?.length > 0 && dataPoint.Points[0].X <= checkedPoint.X && dataPoint.Points[dataPoint.Points.length - 1].X >= checkedPoint.X) { //当光标打在当前曲线上时，才进行取点的操作
                        if (dataPoint.Points.length > index && dataPoint.Points[index].X == checkedPoint.X) {
                            targetPoint = dataPoint.Points[index];
                        } else {
                            let newIndex = this.BinarySearch(dataPoint.Points, checkedPoint.X, (p) => {
                                return p.X;
                            });
                            if (newIndex >= 0 && newIndex < dataPoint.Points.length) {
                                let dataPointOffsetX = this.GetOffsetX(dataPoint.Points[newIndex].X);
                                Math.abs(checkedPointOffsetX - dataPointOffsetX) < cursorPixels && (targetPoint = dataPoint.Points[newIndex]);
                            }
                        }
                    }
                    if (dataPoint.Tag && dataPoint.PointId && !dataPoint.Tag.PointId) {
                        dataPoint.Tag.PointId = dataPoint.PointId;
                    }
                    if (targetPoint) {
                        result.Items.push({
                            OffsetY: this.GetYOffset(targetPoint.Y), //shapeRect.Height - (point.Y - ymin) / yunit,
                            Color: dataPoint.LineColor,
                            Text: dataPoint.Legend,
                            Tag: { Point: targetPoint, Source: dataPoint.Tag },
                            Y: this.GetYDescription(targetPoint),
                        });
                    }
                }
                return result;
            }
        }
        return null;
    }
    GetYDescription(point) {
        return this.FormatYText(point.Y);
    }
    GetOffsetX(x) {
        const info = this.Owner.Information;
        return (x - info.CoordinateInfo.XMin) / info.XUnit;
    }
    GetOffsetY(y) {
        const info = this.Owner.Information;
        const shapeRect = info.ShapeContainerRect;
        const ymax = info.CoordinateInfo.YMax;
        const ymin = info.CoordinateInfo.YMin;
        const yunit = (ymax - ymin) / info.ShapeContainerRect.Height;
        return shapeRect.Height - (y - ymin) / yunit;
    }
    GetLastestPoint() {
        const datas = this.Owner.ShapeOperator.Data;
        if (datas?.length > 0) {
            const pointId = this.GetSelectItem();
            const point = datas.find((r) => pointId != null && r.PointId == pointId);
            if (!(point?.Points?.length > 0)) return null;
            const checkedPoint = point.Points[point.Points.length - 1];
            const result = {
                Items: new Array(),
                OffsetX: this.GetOffsetX(checkedPoint.X),
                X: checkedPoint.X,
                XText: this.FormatXText(checkedPoint.X),
                XIndex: point.Points.length - 1
            };
            datas.filter(data => data.Points?.length > 0).forEach(data => {
                const targetPoint = data.Points[data.Points.length - 1];
                result.Items.push({
                    OffsetY: this.GetYOffset(targetPoint.Y), //shapeRect.Height - (point.Y - ymin) / yunit,
                    Color: data.LineColor,
                    Text: data.Legend,
                    Tag: { Point: targetPoint, Source: data.Tag },
                    Y: this.GetYDescription(targetPoint),
                });
            });
            return result;
        }
        return null;
    }
    GetScreenPointIndex(points, index) {
        const info = this.Owner.Information;
        let idx = index;
        if (idx < 0) idx = 0;
        if (idx >= points.length) idx = points.length - 1;
        let dataPoint = points[idx];
        if (points.length === 0) return idx;
        let x = (dataPoint.X - info.CoordinateInfo.XMin) / info.XUnit;

        while (x < 0) {
            if (idx + 1 <= points.length - 1) {
                dataPoint = points[++idx];
                x = (dataPoint.X - info.CoordinateInfo.XMin) / info.XUnit;
            }
            if (idx + 1 >= points.length) {
                break;
            }
        }

        while (x - info.ShapeContainerRect.Width > 0.000001) {
            if (idx - 1 >= 0) {
                dataPoint = points[--idx];
                x = (dataPoint.X - info.CoordinateInfo.XMin) / info.XUnit;
            } else {
                break;
            }
        }
        return idx;
    }
    GetHarmonicCursorData(start, count) {
        const chartData = this.Owner.ShapeOperator.Data;
        const data = this.GetPositionData(start);
        const fillcolor = "rgba(0,0,0,0)";

        if (data?.Items?.length > 0) {
            const result = {
                OffsetX: data.OffsetX,
                TimesFrequencyData: [this.GetHarmonicData(data, "", fillcolor)]
            };
            if (chartData?.length > 0) {
                const first = chartData[0];
                if (first.Points != null && result.TimesFrequencyData.length > 0) {
                    for (let loop = 1; loop < count; loop++) {
                        const index = data.XIndex + loop;
                        if (index < first.Points.length) {
                            const iData = this.GetPositionByIndex(index);
                            if (iData?.Items?.length > 0) {
                                result.TimesFrequencyData.push(this.GetHarmonicData(iData, (loop + 1).toString(), fillcolor));
                            }
                        }
                    }
                }
            }
            return result;
        }
    }
    GetHarmonicData(data, name, fillcolor) {
        return {
            Name: name,//空心点名称（一般是序号1、2、3……）
            Points: data.Items.map(item => {
                return {
                    OffsetX: data.OffsetX,
                    OffsetY: item.OffsetY,
                    ValueX: data.X,
                    ValueY: item.Y,
                    Fill: fillcolor,
                    IndexX: data.XIndex,
                    Tag: item.Tag,
                }
            })
        };
    }
    GetYTextSize(text) {
        return this.GetTextSize(text, this.YAxisTextStyle);
    }
    FormatXSpanText(timeSpan) {
        return timeSpan.toFixed(this.XDecimalPrecision);
    }
}

export class SquareCoordinateSystem extends TableCoordinateSystem {
    constructor(id) {
        super(id);
        this.XTitle = null;
        this.YTitle = null;
        this.XTitleTextStyle = "normal 11px Arial";
        this.XTitleTextColor = "black";
        this.YTitleTextStyle = "normal 11px Arial";
        this.YTitleTextColor = "black";
        this.ShowYTitle = true;
        this.ShowXTitle = true;
        this.DesignWidth = 60;
        this.DesignHeight = 60;
    }
    MeasureGrid() {
        const info = this.Owner.Information;

        const xTitleTextSize = this.GetTextSize("Test", this.XTitleTextStyle);
        const yTitleTextSize = this.GetTextSize("Test", this.YTitleTextStyle);
        const yScaleTextSize = this.GetYDemoSize();
        const xScaleTextSize = this.GetXDemoSize();

        if (this.isApp) {
            const width = info.ChartContainerRect.Width - info.Padding.Left - info.Padding.Right;
            const height = info.ChartContainerRect.Height - info.Padding.Top - info.Padding.Bottom;
            info.ShapeContainerRect = new Rect();
            info.ShapeContainerRect.Left = 40;
            info.ShapeContainerRect.Top = 40;
            info.ShapeContainerRect.Width = width - 40;
            info.ShapeContainerRect.Height = height - 40;
        } else {
            const tempLeft = (this.ShowYTitle ? yTitleTextSize.Height : 0) + (this.ShowYRule ? this.RuleLength + yScaleTextSize.Height : 0);
            const tempBottom = (this.ShowXRule ? this.RuleLength + xScaleTextSize.Height : 0) + (this.ShowXTitle ? xTitleTextSize.Height : 0);
            const width = info.ChartContainerRect.Width - info.Padding.Left - tempLeft - info.Padding.Right;
            const height = info.ChartContainerRect.Height - info.Padding.Top - info.Padding.Bottom - tempBottom;
            const length = Math.min(width, height);

            info.ShapeContainerRect = new Rect();
            info.ShapeContainerRect.Left = info.Padding.Left + tempLeft + (width - length) / 2;
            info.ShapeContainerRect.Top = info.Padding.Top + (height - length) / 2;
            info.ShapeContainerRect.Width = length;
            info.ShapeContainerRect.Height = length;
        }

    }
    DrawUnit() {
        const info = this.Owner.Information;
        const rect = info.ShapeContainerRect;
        if (this.ShowXTitle && !ruleCheck.IsNullOrSpaceStr(this.XTitle)) {
            const xSize = this.GetTextSize(this.XTitle, this.XTitleTextStyle);
            const xScaleTextSize = this.GetXDemoSize();
            const left = (info.ChartContainerRect.Width - xSize.Width) / 2;
            this.DrawXText(this.XTitle, left, rect.Height + rect.Top + xSize.Height / 2 + this.RuleLength + xScaleTextSize.Height, this.XTitleTextColor, this.XTitleTextStyle);
        }
        if (this.ShowYTitle && !ruleCheck.IsNullOrSpaceStr(this.YTitle)) {
            const ySize = this.GetTextSize(this.YTitle, this.YTitleTextStyle);
            const yScaleTextSize = this.GetYDemoSize();
            const left = rect.Left - (this.ShowYRule ? this.RuleLength + yScaleTextSize.Height : 0) - 4;
            this.drawApi.FillVerticalText(this.YTitle, left, (info.ChartContainerRect.Height + ySize.Width) / 2, this.YTitleTextColor, this.YTitleTextStyle);
        }
    }
    GetPosition(id, x, y) {
        const info = this.Owner.Information;
        const shapeRect = info.ShapeContainerRect;
        const data = this.Owner.ShapeOperator.Data;
        if (data?.length > 0) {
            const line = data.find((r) => r.PointId == id);
            if (line?.Points?.length > 0) {
                let delta = null;
                const result = {
                    X: null,
                    Y: null,
                    Point: null,
                    Index: 0,
                    Color: line.LineColor,
                    Id: line.PointId,
                };
                for (let i = 0; i < line.Points.length; i++) {
                    let p = line.Points[i];
                    var x1 = (p.X - info.CoordinateInfo.XMin) / info.XUnit;
                    var y1 = shapeRect.Height - (p.Y - info.CoordinateInfo.YMin) / info.YUnit;
                    let temp = Math.pow(x - x1, 2) + Math.pow(y - y1, 2);
                    if (delta == null || delta > temp) {
                        delta = temp;
                        result.Point = p;
                        result.X = x1;
                        result.Y = y1;
                        result.Index = i;
                    }
                }
                return result;
            }
        }
        return null;
    }
    GetLastestPoint() {
        const info = this.Owner.Information;
        const shapeRect = info.ShapeContainerRect;
        const data = this.Owner.ShapeOperator.Data;
        if (data?.length > 0) {
            const result = new Array();
            for (let line of data) {
                if (line == null || line.Points.length == 0) continue;
                let point = line.Points[line.Points.length - 1];
                const x1 = (point.X - info.CoordinateInfo.XMin) / info.XUnit;
                const y1 = shapeRect.Height - (point.Y - info.CoordinateInfo.YMin) / info.YUnit;
                result.push({
                    X: x1,
                    Y: y1,
                    Point: point,
                    Index: line.Points.length - 1,
                    Color: line.LineColor,
                    Id: line.PointId,
                });
            }
            return result;
        }
        return null;
    }
    GetPositionByIndex(idxs) {
        const info = this.Owner.Information;
        const shapeRect = info.ShapeContainerRect;
        const data = this.Owner.ShapeOperator.Data;
        if (data?.length > 0) {
            const result = new Array();
            for (const idx of idxs) {
                let line = data.find((r) => r.PointId == idx.Id);
                if (line == null || line.Points.length == 0) continue;
                let index = idx.Index;
                if (index >= line.Points.length) index = line.Points.length - 1;
                let point = line.Points[index];
                const x1 = (point.X - info.CoordinateInfo.XMin) / info.XUnit;
                const y1 = shapeRect.Height - (point.Y - info.CoordinateInfo.YMin) / info.YUnit;
                result.push({
                    X: x1,
                    Y: y1,
                    Point: point,
                    Index: index,
                    Color: line.LineColor,
                    Id: line.PointId,
                });
            }
            return result;
        }
        return null;
    }
}
