const recordGraphComponent = () => import("@components/template/graphs/recordGraphComponent.vue");
const trendGraphComponent = () => import("@components/template/graphs/trendGraphComponent.vue");
const waveGraphComponent = () => import("@components/template/graphs/waveGraphComponent.vue");
const spectrumGraphComponent = () => import("@components/template/graphs/spectrumGraphComponent.vue");
const waterfallGraphComponent = () => import("@components/template/graphs/waterfallGraphComponent.vue");
const barGraphComponent = () => import("@components/template/graphs/barGraphComponent.vue");
const dashBoardGraphComponent = () => import("@components/template/graphs/dashBoardGraphComponent.vue");
const numberTableGraphComponent = () => import("@components/template/graphs/numberTableGraphComponent.vue");
const imageComponent = () => import("@components/template/browse/imageComponent.vue");
const mapComponent = () => import("@components/template/browse/mapComponent.vue");
const runHealthComponent = () => import("@components/template/browse/runHealthComponent.vue");
const pieChartComponent = () => import("@components/template/browse/pieChartComponent.vue");
const countStaticsComponent = () => import("@components/template/browse/countStaticsComponent.vue");
const lineChartComponent = () => import("@components/template/browse/lineChartComponent.vue");
const panelGraphComponent = () => import("@components/template/graphs/panelGraphComponent.vue");
const tableComponent = () => import("@components/template/graphs/tableComponent.vue");

const axisPositionGraphComponent = () => import("@components/template/graphs/axisPositionGraphComponent.vue");
const axisTrailGraphComponent = () => import("@components/template/graphs/axisTrailGraphComponent.vue");
const bodePlotsGraphComponent = () => import("@components/template/graphs/bodePlotsGraphComponent.vue");
const wholePeriodGraphComponent = () => import("@components/template/graphs/wholePeriodGraphComponent.vue");
const polarGraphComponent = () => import("@components/template/graphs/polarGraphComponent.vue");
const cascadeGraphComponent = () => import("@components/template/graphs/cascadeGraphComponent.vue");
const navigateGraphComponent = () => import("@components/template/graphs/navigateGraphComponent.vue");
const torvibMonitoringComponent = () => import("@components/template/graphs/torvibMonitoringComponent.vue");
const torvibRotateGraphComponent = () => import("@components/template/graphs/torvibRotateGraphComponent.vue");

const threeDComponent = () => import("@components/template/browse/threeDComponent.vue");
const iirAlgorithmComponent = () => import("@components/template/algorithms/iirAlgorithmComponent.vue");
const firAlgorithmComponent = () => import("@components/template/algorithms/firAlgorithmComponent.vue");
const alarmStatusComponent = () => import("@components/template/browse/alarmStatusComponent.vue");
const alarmRecordComponent = () => import("@components/template/browse/alarmRecordComponent.vue");
const titleGraphComponent = () => import("@components/template/browse/titleGraphComponent.vue");
const valveStatusComponent = () => import("@components/template/browse/valveStatusComponent.vue");
const timingComponent = () => import("@components/template/browse/timingComponent.vue");
const valveStatusPanelComponent = () => import("@components/template/browse/valveStatusPanelComponent.vue");
const scopeOfMonitoringComponent = () => import("@components/template/browse/scopeOfMonitoringComponent.vue");


// const titleGraphComponent = () => import("@components/template/browse/titleGraphComponent.vue");

const alarmCalendarComponent = () => import("@components/template/browse/alarmCalendarComponent.vue");
const videoComponent = () => import("@components/template/browse/videoComponent.vue");
const healthStatusComponent = () => import("@components/template/browse/healthStatusComponent.vue");
const statusStatisticsComponent = () => import("@components/template/browse/statusStatisticsComponent.vue");
const trendStatisComponent = () => import("@components/template/browse/trendStatisComponent.vue");
const reportComponent = () => import("@components/template/browse/reportComponent.vue");
const histogramComponent = () => import("@components/template/browse/histogramComponent.vue");
const fftAlgorithmComponent = () => import("@components/template/algorithms/fftAlgorithmComponent.vue");
const cepstrumAlgorithmComponent = () => import("@components/template/algorithms/cepstrumAlgorithmComponent.vue");
const freqIntegralAlgorithmComponent = () => import("@components/template/algorithms/freqIntegralAlgorithmComponent.vue");
const crossPowerSpectrumDensAlgorithmComponent = () => import("@components/template/algorithms/crossPowerSpectrumDensAlgorithmComponent.vue");
const integrayAlgorithmComponent = () => import("@components/template/algorithms/integrayAlgorithmComponent.vue");
const twiceIntegrayAlgorithmComponent = () => import("@components/template/algorithms/twiceIntegrayAlgorithmComponent.vue");
const onceDifferentialAlgorithmGraphComponent = () => import("@components/template/algorithms/onceDifferentialAlgorithmGraphComponent.vue");
const twiceDifferentialAlgorithmGraphComponent = () => import("@components/template/algorithms/twiceDifferentialAlgorithmGraphComponent.vue");
const rosetteAlgorithmGraphComponent = () => import("@components/template/algorithms/rosetteAlgorithmGraphComponent.vue");
const virtualComputeAlgorithmComponent = () => import("@components/template/algorithms/virtualComputeAlgorithmComponent.vue");
const orderAlgorithmComponent = () => import("@components/template/algorithms/orderAlgorithmComponent.vue");
const cableForceAlgorithmComponent = () => import("@components/template/algorithms/cableForceAlgorithmComponent.vue");
const envelopeSpectrumAlgorithmComponent = () => import("@components/template/algorithms/envelopeSpectrumAlgorithmComponent.vue");
const envelopeAlgorithmComponent = () => import("@components/template/algorithms/envelopeAlgorithmComponent.vue");
const amplitudeAnalysisAlgorithmComponent = () => import("@components/template/algorithms/amplitudeAnalysisAlgorithmComponent.vue");
const correlationAlgorithmComponent = () => import("@components/template/algorithms/correlationAlgorithmComponent.vue");
const statisticAnalysisComponent = () => import("@components/template/algorithms/statisticAnalysisComponent.vue");
const trendForecastComponent = () => import("@components/template/algorithms/trendForecastComponent.vue");
const rainflowAnalysisAlgorithmComponent = () => import("@components/template/algorithms/rainflowAnalysisAlgorithmComponent.vue");
const eigenFilterAlgorithmComponent = () => import("@components/template/algorithms/eigenFilterAlgorithmComponent.vue");
const torqueAlgorithmComponent = () => import("@components/template/algorithms/torqueAlgorithmComponent.vue");
const shaftPowerAlgorithmComponent = () => import("@components/template/algorithms/shaftPowerAlgorithmComponent.vue");
const residualStressAlgorithmComponent = () => import("@components/template/algorithms/residualStressAlgorithmComponent.vue");

export {
	recordGraphComponent,
	trendGraphComponent,
	waveGraphComponent,
	barGraphComponent,
	dashBoardGraphComponent,
	numberTableGraphComponent,
	imageComponent,
	mapComponent,
	runHealthComponent,
	countStaticsComponent,
	pieChartComponent,
	lineChartComponent,
	panelGraphComponent,
	threeDComponent,
	iirAlgorithmComponent,
	firAlgorithmComponent,
	alarmStatusComponent,
	alarmRecordComponent,
	alarmCalendarComponent,
	videoComponent,
	healthStatusComponent,
	statusStatisticsComponent,
	reportComponent,
	histogramComponent,
	fftAlgorithmComponent,
	cepstrumAlgorithmComponent,
	freqIntegralAlgorithmComponent,
	crossPowerSpectrumDensAlgorithmComponent,
	integrayAlgorithmComponent,
	twiceIntegrayAlgorithmComponent,
	onceDifferentialAlgorithmGraphComponent,
	twiceDifferentialAlgorithmGraphComponent,
	virtualComputeAlgorithmComponent,
	rosetteAlgorithmGraphComponent,
	spectrumGraphComponent,
	waterfallGraphComponent,
	axisPositionGraphComponent,
	axisTrailGraphComponent,
	bodePlotsGraphComponent,
	wholePeriodGraphComponent,
	polarGraphComponent,
	cascadeGraphComponent,
	navigateGraphComponent,
	torvibMonitoringComponent,
	torvibRotateGraphComponent,
	orderAlgorithmComponent,
	cableForceAlgorithmComponent,
	envelopeAlgorithmComponent,
	envelopeSpectrumAlgorithmComponent,
	amplitudeAnalysisAlgorithmComponent,
	trendStatisComponent,
	correlationAlgorithmComponent,
	statisticAnalysisComponent,
	trendForecastComponent,
	rainflowAnalysisAlgorithmComponent,
	eigenFilterAlgorithmComponent,
	torqueAlgorithmComponent,
	shaftPowerAlgorithmComponent,
	residualStressAlgorithmComponent,
	titleGraphComponent,
	valveStatusComponent,
	timingComponent,
	tableComponent,
	valveStatusPanelComponent,
	scopeOfMonitoringComponent
};
