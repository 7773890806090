import { ReportPointBase, captureGraphImages, initGraph } from "./reportBase";
import { nextTick } from "vue";
import { alertSuccessMsg, fullScreenLoading, i18nGlobal } from "@common/js/utils";
import $ from "jquery";
import axios from "@common/js/axios";
import { TrendGraph } from "@core/graphs/js/Dh-graph-trend";
import { CustomGraph } from "@core/graphs/js/Dh-graph-custom";
import { SpectralGraph } from "@core/graphs/js/Dh-graph-spectral";
import { axiosAuth } from "@common/js/auth";

export class ReportTemplateOne extends ReportPointBase {
    constructor(state, props) {
        super(state, props)
    }
    async getFaultAlarmInfos(id) {
        const res = await axiosAuth({
            method: "get",
            url: `alarm/GetEquipmentLatestFaultAlarmInfo/${id}`,
            auth: "AlarmInfo_Browse"
        });
        if (res.Result) {
            return res.Result;
        }
        return [];
    }
    async getFaultAlarmDatas(alarmInfo) {
        const condition = {
            Time: alarmInfo.AlarmStartTime,
            PointIds: [],
        };
        alarmInfo.FaultModelAlarmEvents.map((item) => {
            condition.PointIds.push(item.PointId);
        });
        const loading = fullScreenLoading(i18nGlobal("common.inQuery"));
        const res = await axios({
            method: "post",
            url: `report/dataAnalysis/getDatas`,
            data: condition,
        }).finally(() => {
            loading.close();
        });
        return res.Result;
    }
    getTrendHistoryPoints(points) {
        let res = [];
        points.filter((p) => p.ValueList.length > 0).map((point) => {
            res.push({
                PointId: point.PointId,
                EigenTypes: [point.ValueList[0].Id],
            });
        });
        return res;
    }
    getFaultGraph(type) {
        let graph = null, name = '', title = ''
        switch (type) {
            case 'wave':
                graph = new CustomGraph();
                title = i18nGlobal('component.title.waveGraph');
                name = i18nGlobal("mainLayout.report.timeDomain") + "：";
                break;
            case 'fft':
                graph = new SpectralGraph();
                title = i18nGlobal('component.title.fftGraph')
                name = i18nGlobal("mainLayout.report.fftTitle");
                break;
            case 'order':
                graph = new SpectralGraph();
                title = i18nGlobal('component.title.orderAlgorithm')
                name = i18nGlobal("component.title.orderAlgorithm") + "：";
                break;
        }
        return {
            graph,
            title,
            name
        }
    }
    async createFaultGraph(graphContainer, datas, type) {
        const { graph, name, title } = this.getFaultGraph(type)
        initGraph(graph, title, this.state.graphId);
        if (datas?.length > 0) {
            graph.SetData(datas);
        }
        const imagePath = await captureGraphImages(graphContainer);
        graph.Clear();
        graphContainer.empty();
        return {
            Name: name,
            Path: imagePath,
        };
    }
    async createTrendGraph(graphContainer) {
        const graph = new TrendGraph();
        initGraph(graph, i18nGlobal('component.title.trendGraph'), this.state.graphId);
        this.reportEntity.SubmachineDatas = [];
        const devicePoints = this.getCheckDevicePoints();
        for (let nodeId of devicePoints.keys()) {
            const node = this.state.devices.find((r) => r.Id == nodeId);
            const points = devicePoints.get(nodeId);
            const datas = await this.getTrendHistoryData(points);
            const { startTime, endTime } = this.getBaseInfo();
            if (datas) {
                graph.SetData(datas, startTime, endTime);
            } else {
                graph.ClearData();
            }
            const imagePath = await captureGraphImages(graphContainer);
            this.reportEntity.SubmachineDatas.push({ Name: node.Name, Path: imagePath, Id: node.Id });
        }
        graph.Clear();
        graphContainer.empty();
    }
    exportReport() {
        nextTick(async () => {
            const graphContainer = $(`#${this.state.graphId}`);
            await this.createTrendGraph(graphContainer);
            this.reportEntity.PointDatas = [];
            for (let device of this.state.devices.filter((r) => r.isSelect)) {
                const alarmInfos = await this.getFaultAlarmInfos(device.Id);
                for (let alarmInfo of alarmInfos) {
                    const dataInfo = await this.getFaultAlarmDatas(alarmInfo);
                    if (dataInfo) {
                        const faultData = {
                            Name: alarmInfo.Path,
                            GraphtDatas: [],
                        };
                        faultData.GraphtDatas.push(await this.createFaultGraph(graphContainer, this.parseDatas(dataInfo.WaveData), 'wave'));
                        faultData.GraphtDatas.push(await this.createFaultGraph(graphContainer, this.parseDatas(dataInfo.FFTData), 'fft'));
                        faultData.GraphtDatas.push(await this.createFaultGraph(graphContainer, this.parseDatas(dataInfo.OrderData), 'order'));
                        this.reportEntity.PointDatas.push(faultData);
                    }
                }
            }
            this.reportEntity.ReportTemplate = this.getSaveParam();
            axios({
                method: "post",
                url: "report/dataAnalysis/export",
                data: this.reportEntity,
            }).then((res) => {
                if(res.IsSuccess) {
                    alertSuccessMsg(i18nGlobal("common.exportSuccess"));
                }
            });
            this.saveParam();
        });
    }
}