// @generated by protobuf-ts 2.9.1
// @generated from protobuf file "protos/alarmdataswithlimit.proto" (package "alarm", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { HistoryPointDataReply } from "./historypointdatareply";
import { PointAlgorithmParamRequest } from "./pointalgorithmparam";
/**
 * @generated from protobuf message alarm.QueryAlarmDatasWithLimitRequest
 */
export interface QueryAlarmDatasWithLimitRequest {
    /**
     * @generated from protobuf field: string EventId = 1 [json_name = "EventId"];
     */
    eventId: string;
    /**
     * @generated from protobuf field: int32 Limit = 2 [json_name = "Limit"];
     */
    limit: number;
    /**
     * @generated from protobuf field: string Time = 3 [json_name = "Time"];
     */
    time: string;
    /**
     * @generated from protobuf field: repeated alarm.PointAlgorithmParamRequest Points = 4 [json_name = "Points"];
     */
    points: PointAlgorithmParamRequest[];
}
/**
 * @generated from protobuf message alarm.QueryAlarmDatasWithLimitReply
 */
export interface QueryAlarmDatasWithLimitReply {
    /**
     * @generated from protobuf field: repeated storage.HistoryPointDataReply Points = 1 [json_name = "Points"];
     */
    points: HistoryPointDataReply[];
}
// @generated message type with reflection information, may provide speed optimized methods
class QueryAlarmDatasWithLimitRequest$Type extends MessageType<QueryAlarmDatasWithLimitRequest> {
    constructor() {
        super("alarm.QueryAlarmDatasWithLimitRequest", [
            { no: 1, name: "EventId", kind: "scalar", jsonName: "EventId", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "Limit", kind: "scalar", jsonName: "Limit", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "Time", kind: "scalar", jsonName: "Time", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "Points", kind: "message", jsonName: "Points", repeat: 1 /*RepeatType.PACKED*/, T: () => PointAlgorithmParamRequest }
        ]);
    }
    create(value?: PartialMessage<QueryAlarmDatasWithLimitRequest>): QueryAlarmDatasWithLimitRequest {
        const message = { eventId: "", limit: 0, time: "", points: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<QueryAlarmDatasWithLimitRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: QueryAlarmDatasWithLimitRequest): QueryAlarmDatasWithLimitRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string EventId = 1 [json_name = "EventId"];*/ 1:
                    message.eventId = reader.string();
                    break;
                case /* int32 Limit = 2 [json_name = "Limit"];*/ 2:
                    message.limit = reader.int32();
                    break;
                case /* string Time = 3 [json_name = "Time"];*/ 3:
                    message.time = reader.string();
                    break;
                case /* repeated alarm.PointAlgorithmParamRequest Points = 4 [json_name = "Points"];*/ 4:
                    message.points.push(PointAlgorithmParamRequest.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: QueryAlarmDatasWithLimitRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string EventId = 1 [json_name = "EventId"]; */
        if (message.eventId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.eventId);
        /* int32 Limit = 2 [json_name = "Limit"]; */
        if (message.limit !== 0)
            writer.tag(2, WireType.Varint).int32(message.limit);
        /* string Time = 3 [json_name = "Time"]; */
        if (message.time !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.time);
        /* repeated alarm.PointAlgorithmParamRequest Points = 4 [json_name = "Points"]; */
        for (let i = 0; i < message.points.length; i++)
            PointAlgorithmParamRequest.internalBinaryWrite(message.points[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message alarm.QueryAlarmDatasWithLimitRequest
 */
export const QueryAlarmDatasWithLimitRequest = new QueryAlarmDatasWithLimitRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class QueryAlarmDatasWithLimitReply$Type extends MessageType<QueryAlarmDatasWithLimitReply> {
    constructor() {
        super("alarm.QueryAlarmDatasWithLimitReply", [
            { no: 1, name: "Points", kind: "message", jsonName: "Points", repeat: 1 /*RepeatType.PACKED*/, T: () => HistoryPointDataReply }
        ]);
    }
    create(value?: PartialMessage<QueryAlarmDatasWithLimitReply>): QueryAlarmDatasWithLimitReply {
        const message = { points: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<QueryAlarmDatasWithLimitReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: QueryAlarmDatasWithLimitReply): QueryAlarmDatasWithLimitReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated storage.HistoryPointDataReply Points = 1 [json_name = "Points"];*/ 1:
                    message.points.push(HistoryPointDataReply.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: QueryAlarmDatasWithLimitReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated storage.HistoryPointDataReply Points = 1 [json_name = "Points"]; */
        for (let i = 0; i < message.points.length; i++)
            HistoryPointDataReply.internalBinaryWrite(message.points[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message alarm.QueryAlarmDatasWithLimitReply
 */
export const QueryAlarmDatasWithLimitReply = new QueryAlarmDatasWithLimitReply$Type();
