import dayjs from "dayjs";
import { NavigateAnalysisChart } from "@core/graphs/charts/Dh-analysischart-navigate";
import _ from "lodash";
import { TrendGraph } from "./Dh-graph-trend";

export class NavigateGraph extends TrendGraph {
    constructor() {
        super();
    }
    CreateGraph() {
        this.Graph = new NavigateAnalysisChart();
        this.Graph.Init(this.Option, this.Plugins);
        this.Graph.OnChartChanged();
    }
    CreateGraphContainer() {
        return `<div id="${this.Option.ChartId}" data-minwidth="40" data-minheight="30" style="position:absolute;overflow: hidden;"></div>`;
    }
    SetTime(startTime, endTime) {
        const info = this.Graph.WebChart.Information.CoordinateInfo;
        this.Graph.WebChart.Information.UpdateCoordinateInfo(Number(dayjs(endTime)), Number(dayjs(startTime)), info.YMax, info.YMin);
        this.Graph.WebChart.OnMeasure();
        this.Graph.WebChart.OnRender();
    }
}
