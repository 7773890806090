// @generated by protobuf-ts 2.9.1
// @generated from protobuf file "protos/historypointdatareply.proto" (package "storage", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { PointParamReply } from "./basereply";
import { DspParamReply } from "./basereply";
import { CursorInfoReply } from "./basereply";
import { Any } from "../google/protobuf/any";
import { DatasReply } from "./basereply";
import { DataParamReply } from "./basereply";
import { EigenDataReply } from "./basereply";
import { EigenInfoReply } from "./basereply";
import { DoubleValue } from "../google/protobuf/wrappers";
/**
 * @generated from protobuf message storage.HistoryPointDataReply
 */
export interface HistoryPointDataReply {
    /**
     * @generated from protobuf field: int32 PointId = 1 [json_name = "PointId"];
     */
    pointId: number;
    /**
     * @generated from protobuf field: int64 SampleTime = 2 [json_name = "SampleTime"];
     */
    sampleTime: bigint;
    /**
     * @generated from protobuf field: google.protobuf.DoubleValue RotateSpeed = 3 [json_name = "RotateSpeed"];
     */
    rotateSpeed?: DoubleValue;
    /**
     * @generated from protobuf field: storage.EigenInfoReply EigenType = 4 [json_name = "EigenType"];
     */
    eigenType?: EigenInfoReply;
    /**
     * @generated from protobuf field: repeated storage.EigenDataReply Eigens = 5 [json_name = "Eigens"];
     */
    eigens: EigenDataReply[];
    /**
     * @generated from protobuf field: map<int32, int32> AlarmParams = 6 [json_name = "AlarmParams"];
     */
    alarmParams: {
        [key: number]: number;
    };
    /**
     * @generated from protobuf field: storage.DataParamReply Param = 7 [json_name = "Param"];
     */
    param?: DataParamReply;
    /**
     * @generated from protobuf field: storage.DatasReply Datas = 8 [json_name = "Datas"];
     */
    datas?: DatasReply;
    /**
     * @generated from protobuf field: google.protobuf.Any FFTDatas = 9 [json_name = "FFTDatas"];
     */
    fFTDatas?: Any;
    /**
     * @generated from protobuf field: int32 DspType = 10 [json_name = "DspType"];
     */
    dspType: number;
    /**
     * @generated from protobuf field: storage.CursorInfoReply CursorInfo = 11 [json_name = "CursorInfo"];
     */
    cursorInfo?: CursorInfoReply;
    /**
     * @generated from protobuf field: float Freq = 12 [json_name = "Freq"];
     */
    freq: number;
    /**
     * @generated from protobuf field: int32 SampleType = 13 [json_name = "SampleType"];
     */
    sampleType: number;
    /**
     * @generated from protobuf field: int32 BlockSize = 14 [json_name = "BlockSize"];
     */
    blockSize: number;
    /**
     * @generated from protobuf field: storage.DspParamReply DspParam = 15 [json_name = "DspParam"];
     */
    dspParam?: DspParamReply;
    /**
     * @generated from protobuf field: storage.PointParamReply PointParam = 16 [json_name = "PointParam"];
     */
    pointParam?: PointParamReply;
}
// @generated message type with reflection information, may provide speed optimized methods
class HistoryPointDataReply$Type extends MessageType<HistoryPointDataReply> {
    constructor() {
        super("storage.HistoryPointDataReply", [
            { no: 1, name: "PointId", kind: "scalar", jsonName: "PointId", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "SampleTime", kind: "scalar", jsonName: "SampleTime", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 3, name: "RotateSpeed", kind: "message", jsonName: "RotateSpeed", T: () => DoubleValue },
            { no: 4, name: "EigenType", kind: "message", jsonName: "EigenType", T: () => EigenInfoReply },
            { no: 5, name: "Eigens", kind: "message", jsonName: "Eigens", repeat: 1 /*RepeatType.PACKED*/, T: () => EigenDataReply },
            { no: 6, name: "AlarmParams", kind: "map", jsonName: "AlarmParams", K: 5 /*ScalarType.INT32*/, V: { kind: "scalar", T: 5 /*ScalarType.INT32*/ } },
            { no: 7, name: "Param", kind: "message", jsonName: "Param", T: () => DataParamReply },
            { no: 8, name: "Datas", kind: "message", jsonName: "Datas", T: () => DatasReply },
            { no: 9, name: "FFTDatas", kind: "message", jsonName: "FFTDatas", T: () => Any },
            { no: 10, name: "DspType", kind: "scalar", jsonName: "DspType", T: 5 /*ScalarType.INT32*/ },
            { no: 11, name: "CursorInfo", kind: "message", jsonName: "CursorInfo", T: () => CursorInfoReply },
            { no: 12, name: "Freq", kind: "scalar", jsonName: "Freq", T: 2 /*ScalarType.FLOAT*/ },
            { no: 13, name: "SampleType", kind: "scalar", jsonName: "SampleType", T: 5 /*ScalarType.INT32*/ },
            { no: 14, name: "BlockSize", kind: "scalar", jsonName: "BlockSize", T: 5 /*ScalarType.INT32*/ },
            { no: 15, name: "DspParam", kind: "message", jsonName: "DspParam", T: () => DspParamReply },
            { no: 16, name: "PointParam", kind: "message", jsonName: "PointParam", T: () => PointParamReply }
        ]);
    }
    create(value?: PartialMessage<HistoryPointDataReply>): HistoryPointDataReply {
        const message = { pointId: 0, sampleTime: 0n, eigens: [], alarmParams: {}, dspType: 0, freq: 0, sampleType: 0, blockSize: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<HistoryPointDataReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: HistoryPointDataReply): HistoryPointDataReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 PointId = 1 [json_name = "PointId"];*/ 1:
                    message.pointId = reader.int32();
                    break;
                case /* int64 SampleTime = 2 [json_name = "SampleTime"];*/ 2:
                    message.sampleTime = reader.int64().toBigInt();
                    break;
                case /* google.protobuf.DoubleValue RotateSpeed = 3 [json_name = "RotateSpeed"];*/ 3:
                    message.rotateSpeed = DoubleValue.internalBinaryRead(reader, reader.uint32(), options, message.rotateSpeed);
                    break;
                case /* storage.EigenInfoReply EigenType = 4 [json_name = "EigenType"];*/ 4:
                    message.eigenType = EigenInfoReply.internalBinaryRead(reader, reader.uint32(), options, message.eigenType);
                    break;
                case /* repeated storage.EigenDataReply Eigens = 5 [json_name = "Eigens"];*/ 5:
                    message.eigens.push(EigenDataReply.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* map<int32, int32> AlarmParams = 6 [json_name = "AlarmParams"];*/ 6:
                    this.binaryReadMap6(message.alarmParams, reader, options);
                    break;
                case /* storage.DataParamReply Param = 7 [json_name = "Param"];*/ 7:
                    message.param = DataParamReply.internalBinaryRead(reader, reader.uint32(), options, message.param);
                    break;
                case /* storage.DatasReply Datas = 8 [json_name = "Datas"];*/ 8:
                    message.datas = DatasReply.internalBinaryRead(reader, reader.uint32(), options, message.datas);
                    break;
                case /* google.protobuf.Any FFTDatas = 9 [json_name = "FFTDatas"];*/ 9:
                    message.fFTDatas = Any.internalBinaryRead(reader, reader.uint32(), options, message.fFTDatas);
                    break;
                case /* int32 DspType = 10 [json_name = "DspType"];*/ 10:
                    message.dspType = reader.int32();
                    break;
                case /* storage.CursorInfoReply CursorInfo = 11 [json_name = "CursorInfo"];*/ 11:
                    message.cursorInfo = CursorInfoReply.internalBinaryRead(reader, reader.uint32(), options, message.cursorInfo);
                    break;
                case /* float Freq = 12 [json_name = "Freq"];*/ 12:
                    message.freq = reader.float();
                    break;
                case /* int32 SampleType = 13 [json_name = "SampleType"];*/ 13:
                    message.sampleType = reader.int32();
                    break;
                case /* int32 BlockSize = 14 [json_name = "BlockSize"];*/ 14:
                    message.blockSize = reader.int32();
                    break;
                case /* storage.DspParamReply DspParam = 15 [json_name = "DspParam"];*/ 15:
                    message.dspParam = DspParamReply.internalBinaryRead(reader, reader.uint32(), options, message.dspParam);
                    break;
                case /* storage.PointParamReply PointParam = 16 [json_name = "PointParam"];*/ 16:
                    message.pointParam = PointParamReply.internalBinaryRead(reader, reader.uint32(), options, message.pointParam);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap6(map: HistoryPointDataReply["alarmParams"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof HistoryPointDataReply["alarmParams"] | undefined, val: HistoryPointDataReply["alarmParams"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.int32();
                    break;
                case 2:
                    val = reader.int32();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field storage.HistoryPointDataReply.AlarmParams");
            }
        }
        map[key ?? 0] = val ?? 0;
    }
    internalBinaryWrite(message: HistoryPointDataReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 PointId = 1 [json_name = "PointId"]; */
        if (message.pointId !== 0)
            writer.tag(1, WireType.Varint).int32(message.pointId);
        /* int64 SampleTime = 2 [json_name = "SampleTime"]; */
        if (message.sampleTime !== 0n)
            writer.tag(2, WireType.Varint).int64(message.sampleTime);
        /* google.protobuf.DoubleValue RotateSpeed = 3 [json_name = "RotateSpeed"]; */
        if (message.rotateSpeed)
            DoubleValue.internalBinaryWrite(message.rotateSpeed, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* storage.EigenInfoReply EigenType = 4 [json_name = "EigenType"]; */
        if (message.eigenType)
            EigenInfoReply.internalBinaryWrite(message.eigenType, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* repeated storage.EigenDataReply Eigens = 5 [json_name = "Eigens"]; */
        for (let i = 0; i < message.eigens.length; i++)
            EigenDataReply.internalBinaryWrite(message.eigens[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* map<int32, int32> AlarmParams = 6 [json_name = "AlarmParams"]; */
        for (let k of Object.keys(message.alarmParams))
            writer.tag(6, WireType.LengthDelimited).fork().tag(1, WireType.Varint).int32(parseInt(k)).tag(2, WireType.Varint).int32(message.alarmParams[k as any]).join();
        /* storage.DataParamReply Param = 7 [json_name = "Param"]; */
        if (message.param)
            DataParamReply.internalBinaryWrite(message.param, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* storage.DatasReply Datas = 8 [json_name = "Datas"]; */
        if (message.datas)
            DatasReply.internalBinaryWrite(message.datas, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Any FFTDatas = 9 [json_name = "FFTDatas"]; */
        if (message.fFTDatas)
            Any.internalBinaryWrite(message.fFTDatas, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* int32 DspType = 10 [json_name = "DspType"]; */
        if (message.dspType !== 0)
            writer.tag(10, WireType.Varint).int32(message.dspType);
        /* storage.CursorInfoReply CursorInfo = 11 [json_name = "CursorInfo"]; */
        if (message.cursorInfo)
            CursorInfoReply.internalBinaryWrite(message.cursorInfo, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* float Freq = 12 [json_name = "Freq"]; */
        if (message.freq !== 0)
            writer.tag(12, WireType.Bit32).float(message.freq);
        /* int32 SampleType = 13 [json_name = "SampleType"]; */
        if (message.sampleType !== 0)
            writer.tag(13, WireType.Varint).int32(message.sampleType);
        /* int32 BlockSize = 14 [json_name = "BlockSize"]; */
        if (message.blockSize !== 0)
            writer.tag(14, WireType.Varint).int32(message.blockSize);
        /* storage.DspParamReply DspParam = 15 [json_name = "DspParam"]; */
        if (message.dspParam)
            DspParamReply.internalBinaryWrite(message.dspParam, writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        /* storage.PointParamReply PointParam = 16 [json_name = "PointParam"]; */
        if (message.pointParam)
            PointParamReply.internalBinaryWrite(message.pointParam, writer.tag(16, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message storage.HistoryPointDataReply
 */
export const HistoryPointDataReply = new HistoryPointDataReply$Type();
