import { guid, i18nGlobal } from "@common/js/utils";

export const DataPointShape = {
	/** 无形状 */
	None: 0,
	/** 圆形 */
	Arc: 1,
	/** 正方形 */
	Square: 2,
	/** 三角形 */
	Triangle: 3,
};

export const ChartMode = {
	Normal: 0,
	Performance: 1,
};

export class DataPoint {
	constructor(x, y) {
		this.X = x;
		this.Y = y;
	}
}

export class PolarDataPoint extends DataPoint {
	constructor(x, y, data) {
		super(x, y, data);
		this.Amplitude = data?.Amplitude;
		this.Phase = data?.Phase;
		this.Speed = data?.Speed;
	}
}

export class BarDataPoint {
	constructor(start, end, value) {
		this.Start = start;
		this.End = end;
		this.Value = value;
	}
}

export class RectDataPoint {
	constructor(x1, x2, y1, y2, value) {
		this.X1 = x1;
		this.X2 = x2;
		this.Y1 = y1;
		this.Y2 = y2;
		this.Value = value;
	}
}

export class DataSeries {
	constructor() {
		this.Id = guid();
		this.Tag = null;
		this.Points = new Array();
		this.PointId = null;
		this.Legend = null;
		this.LineColor = null;
		this.LineWidth = 1;
		this.PointType = DataPointShape.None;
		this.ChartMode = ChartMode.Normal;
		this.PointSize = 6;
		this.XMin = null;
		this.XMax = null;
		this.XUnit = null;
		this.YUnit = null;
		this.FullValue = null;
		this.YAxisType = YAxisType.Number;
		this.LowFrequency = false;
		this.XLength = 0;
		this.YAxisItems = [];
	}
}

export class Point {
	constructor(x, y) {
		this.X = x;
		this.Y = y;
	}
}

export class BarPoint {
	constructor(x, y, width, height, color) {
		this.X = x;
		this.Y = y;
		this.Width = width;
		this.Height = Math.abs(height) < 1 ? 1 : height;
		this.Color = color;
	}
}

export class BandwidthPoint {
	constructor(x, y, width, height, color, level, id, dash, rect) {
		this.X = x;
		this.Y = y;
		this.Width = width;
		this.Height = height;
		this.color = color;
		this.Level = level;
		this.Id = id;
		this.dash = dash;
		this.rect = rect;
	}
}

export class Rect {
	constructor(left, top, width, height, color) {
		this.Left = left;
		this.Top = top;
		this.Width = width;
		this.Height = height;
		this.Color = color;
	}
	update(left, top, width, height, color) {
		if (left) this.Left = left;
		if (top) this.Top = top;
		if (width) this.Width = width;
		if (height) this.Height = height;
		if (color) this.Color = color;
	}
}

export class VertexInfoClass {
	constructor() {
		//立方体8个顶点 瀑布图里用到7个 另一个不画出来
		/**正面 左下角 */
		this.FrontLeftBottom = new DataPoint();
		/**正面 左上角 */
		this.FrontLeftTop = new DataPoint();
		/** 正面 右下角 */
		this.FrontRightBottom = new DataPoint();
		/**背面  左下角 */
		this.BackLeftBottom = new DataPoint();
		/**背面 左上角 */
		this.BackLeftTop = new DataPoint();
		/**背面 右下角 */
		this.BackRightBottom = new DataPoint();
		/**背面 右上角 */
		this.BackRightTop = new DataPoint();

		this.Width = 0;
		this.Height = 0;
		this.Depth = 0;
		/**Z轴与X轴夹角 */
		this.XZAngle = 30;
		/**Y:Z */
		this.YZProportion = 2 / 3;
	}
}

export class Size {
	constructor(width, height) {
		this.Width = width;
		this.Height = height;
	}
}

export const XAxisShowType = {
	/**按时间显示 */
	ShowTime: 1,
	/**按日期时间显示 */
	ShowDate: 2,
	/**按时分秒显示 */
	ShowMS: 3,
	/**按秒显示 */
	ShowS: 4,
};

export class ChartUnit {
	constructor(xtext, ytext) {
		this.XText = xtext;
		this.YText = ytext;
	}
}

export const ZoomType = {
	/**放大 */
	Out: 1,
	/**缩小 */
	In: 2,
};

export const ScaleType = {
	/**自动刻度 */
	Auto: 0,
	/**量程 */
	FullValue: 1,
};

export const StatisticInfo = {
	/**最大值 */
	Max: 1,
	/**最小值 */
	Min: 2,
	/**平均值 */
	Avg: 3,
	/**均方根 */
	RMS: 4,
	/**偏度 */
	Skewness: 5,
	/**峰度 */
	Kurtosis: 6,
	/**标准差 */
	SD: 7,
	/**峰峰值 */
	PTP: 8,
	/**斜率 */
	Slope: 9,
	/**偏差值 */
	Deviation: 10,
	/**相邻峰峰值 */
	APTP: 11,
	/**峰值列表 */
	PeakList: 12,
};

export const StatisticInfoDic = {
	1: i18nGlobal("core.max"),
	2: i18nGlobal("core.min"),
	3: i18nGlobal("core.avage"),
	4: i18nGlobal("core.RMS"),
	5: i18nGlobal("core.skewness"),
	6: i18nGlobal("core.kurtosis"),
	7: i18nGlobal("core.SD"),
	8: i18nGlobal("core.PTP"),
	9: i18nGlobal("core.slope"),
	10: i18nGlobal("core.deviation"),
	11: i18nGlobal("core.APTP"),
	12: i18nGlobal("core.peakList"),
};

export const LineMode = {
	Solid: 1,
	Dash: 2,
};

export const EnumDataRefreshMode = {
	AccumulateRefresh: 1,
	AllRefresh: 2,
};

export const GraphType = {
	/**无图谱 */
	None: 0,
	/**趋势图 */
	Trend: 1,
	/**棒图 */
	Bar: 2,
	/**波形频谱图 */
	WaveSpectrum: 3,
	/**瀑布图 */
	WaterFall: 4,
	/**阶次谱图 */
	OrderAnalysis: 5,
	/**轴心轨迹图 */
	AxisTrail: 6,
	/**轴心位置图 */
	AxisPosition: 7,
	/**伯德图 */
	BodePlots: 8,
	/**极坐标图 */
	PolarCoordinate: 9,
	/**索力图 */
	CableGraph: 10,
	/**阶次图 */
	RankGraph: 11,
	/**全频图 */
	FullBandGraph: 12,
	/**波形图 */
	Wave: 13,
	/**频谱图 */
	Spectrum: 14,
	/**导航图 */
	Navigate: 15,
	/**正方形图 */
	Square: 16,
	/**数字表 */
	NumberTable: 17,
	/**仪表盘 */
	DashBoard: 18,
	/**级联图 */
	Cascade: 19,
	CustomGraph: 20,
	/**包络波形 */
	EnvelopeWaveGraph: 21,
	/**包络谱 */
	EnvelopeSpectrumGraph: 22,
};

export const PluginMode = {
	/* Radio插件 /
		/**无 */
	None: 0,
	/**单光标 */
	SingleCursor: 1,
	/**双光标 */
	DoubleCursor: 2,
	/**关联双光标 */
	RelatedDoubleCursor: 3,
	/**谐波光标 */
	HarmonicCursor: 4,
	/**边带光标 */
	SidebandCursor: 5,
	/**导航图单光标 */
	NavigateSingleCursor: 6,
	/**导航双光标 */
	NavigateDoubleCursor: 7,
	/**导航带光标 */
	NavigateHarmonicCursor: 8,
	/**三光标 */
	ThreeCursor: 9,
	/**四光标 */
	FourCursor: 10,
	/**峰值光标 */
	PeakValueCursor: 11,
	/**谷值光标 */
	ValleyValueCursor: 12,
	/**分开显示单光标 */
	SeparateSingleCursor: 13,
	/**标记光标 */
	FlagCursor: 14,
	/**极坐标单光标 */
	PolarSingleCursor: 15,
	/**边频光标 */
	SideFrequencyCursor: 16,
	/**导航光标 */
	NavigateCursor: 17,
	/**趋势图单光标 */
	TrendSingleCursor: 18,
	/**瀑布图单光标 */
	WaterfallSingleCursor: 19,
	/**轨迹图单光标 */
	TrailSingleCursor: 20,
	/**区域光标 */
	RectCursor: 21,
	/**双导航光标 */
	NavigateTrendDoubleCursor: 22,
	/**特征频率光标 */
	CharacteristicFrequencyCursor: 23,
	/**记录仪导航图光标 */
	NavigateRecorderCursor: 24,
	/**谐(倍频)光标 */
	HarmonicCursor: 25,
	/**扭振监测频率光标 */
	TorvibDoubleCursor: 26,

	/* Noraml插件 /
	/**y轴放大 */
	YAxisZoomOut: 100,
	/**y轴缩小 */
	YAxisZoomIn: 101,
	/**x轴放大 */
	XAxisZoomOut: 102,
	/**x轴缩小 */
	XAxisZoomIn: 103,
	/**图例 */
	Legend: 104,
	/**报警线 */
	AlarmLine: 105,
	/**报警光标 */
	AlarmCursor: 106,
	/**信号处理按钮 */
	SignalProcess: 107,
	/**统计信息 */
	Statistics: 108,
	/**自动刻度切换 */
	AutoScale: 109,
	/**频带报警插件 */
	FreqBandAlarm: 110,
	/**局部放大插件 */
	Enlarge: 111,
	/**坐标轴结束时间按钮插件 */
	AxisEnd: 112,
	/**确认按钮 */
	SureButton: 113,
	/**取消按钮 */
	CancelButton: 114,
	/**趋势图开始结束时间 */
	TimeRange: 115,
	/**Y轴范围设置插件 */
	YAxisRange: 116,
	/**幅值分析多图谱scale */
	MultiCoordAutoScale: 117,
	/**柱状图统计信息 */
	HistogramStatistics: 118,
	/** 输入框与按钮 */
	InputButton: 119,
};

export const BarTypeEnum = {
	Normal: 1,
	ThreeD: 2,
};

export const ComponentCategory = {
	TrendGraph: 0,
	TimeDomain: 1,
	Spectral: 2,
	Common: 3,
	Amplitude: 4,
	BarGraph: 5,
	NumberTableGraph: 6,
	DashBoard: 7,
	PointBrowse: 8,
	Image: 9,
	ThreeD: 10,
	Title: 11,
	AlarmStatus: 12,
	AlarmRecord: 13,
	Map: 14,
	Video: 15,
	HelathStatus: 16,
	AlarmRecordNew: 17,
	Histogram: 18,
	Line: 19,
	Pie: 20,
	CountStatics: 21,
	Rainflow: 23, //雨流分析
	AmpAnalysis: 24, //幅值分析
	Cable: 25,
	RunHealth: 26,
	Panel: 27,
	Record: 28,
	Timing: 29,
	ValveStatus: 30,
	TitleGraph: 31,
	graphicalDisplay: 32,
	Table: 33,
	ValveStatusPanel: 34,
	ScopeOfMonitoring: 35
};

export const XAxisType = {
	Absolute: 0,
	Relative: 1,
};

export class CoordInfo {
	constructor() {
		this.IsEnlarge = false;
		this.XMinRate = 0;
		this.XMaxRate = 1;
		this.YMax = 1000;
		this.YMin = -1000;
		this.YAxisRate = 1;
		this.TimeLength = 20000;
	}
}

export const YAxisType = {
	/**数字坐标 */
	Number: 0,
	/**文字坐标 */
	Text: 1,
};

export class DiluteData {
	constructor(pointId, points, color, drawType) {
		this.DrawType = drawType;
		this.LineColor = color;
		this.PointId = pointId;
		this.Points = [...points];
	}
}


export class TimeLineDuilteData {
	constructor(data) {
		this.DrawType = data.DrawType;
		this.LineColor = data.LineColor;
		this.PointId = data.PointId;
		this.Points = new Array();
		this.TimeInterval = data.TimeInterval;
		this.LastPoint = null;
		this.SampleFreq = data.SampleFreq;
		this.Points.push(...data.Points);
	}
}

export class TimeLineData {
	constructor(data) {
		this.Points = new Array();
		this.Legend = data.Legend;
		this.PointId = data.PointId;
		this.LineColor = data.LineColor;
		this.Level = 0;
		this.YUnit = data.YUnit;
		this.XUnit = data.XUnit;
	}
}


export const VSHADER_SOURCE = `
    attribute vec4 a_Position;
    uniform float xunit;
    uniform float yunit;
	uniform float pointSize;
    void main() {
        float x = 2.0 * xunit * a_Position.x - 1.0;
        float y = 2.0 * yunit * a_Position.y - 1.0;
        gl_Position = vec4(x, y , a_Position.z, 1);
        gl_PointSize = pointSize;
    }`;

export const FSHADER_SOURCE = `
    precision highp float;
    uniform vec4 u_FragColor;
    uniform float type;
    void main() {
        if(type > 0.0){
            float dist = distance(gl_PointCoord, vec2(0.5, 0.5));
            if(dist > 0.5) {
                discard;
            }else {
                gl_FragColor = u_FragColor;
            }
        }else{
            gl_FragColor = u_FragColor;
        }
    }`;

export const FreqType = {
	fb: 1,
	fco: 2,
	fi: 3,
	fo: 4,
};

export class CircularQueue {
	constructor(capacity) {
		this.front = 0;
		this.rear = 0;
		this.capacity = parseInt(capacity);
		this.data = new Array(this.capacity);
		this.full = false;
	}
	push(...items) {
		items.forEach(item => {
			this.data[this.rear] = item;
			if (this.isFull()) {
				this.front = (this.front + 1) % this.capacity;
			}
			this.rear = (this.rear + 1) % this.capacity;
			this.full = this.front == this.rear;
		})

	}
	shift() {
		if (!this.isEmpty()) {
			const item = this.data[this.front];
			this.front = (this.front + 1) % this.capacity;
			this.full = false;
			return item;
		}
	}
	getIndex(index) {
		if (index < 0 || index >= this.getLength()) {
			return null;
		}

		return this.data[(this.front + index) % this.capacity];
	}
	getFront() {
		if (this.isEmpty())
			return null;
		return this.data[this.front];
	}
	getRear() {
		if (this.isEmpty())
			return null;
		return this.data[this.rear - 1];
	}
	getLength() {
		if (!this.isFull()) {
			if (this.rear >= this.front) {
				return this.rear - this.front;
			} else {
				return this.capacity + this.rear - this.front;
			}
		}
		return this.capacity;
	}
	isEmpty() {
		return this.rear === this.front && !this.full;
	}
	isFull() {
		return this.full;
	}
	empty() {
		this.front = 0;
		this.rear = 0;
		this.full = false;
	}
	clear() {
		this.front = 0;
		this.rear = 0;
		this.data = null;
		this.full = false;
	}
}