<!-- 修改密码页面 -->
<template>
	<el-dialog v-model="showDialog" :title="$t('headerSetting.changePwd.title')" width="600px" @close="closeDialog">
		<el-form :model="state.loginForm" :rules="state.rules" ref="formRef" :label-width="lang === 'CN' ? 100 : 170" @keyup.enter="submitForm">
			<el-form-item :label="$t('headerSetting.changePwd.oldPwd')" prop="OldPassword">
				<el-input type="password" v-model="state.loginForm.OldPassword" :placeholder="$t('headerSetting.changePwd.oldPwdPlaceholder')" />
			</el-form-item>
			<el-form-item :label="$t('headerSetting.changePwd.newPwd')" prop="NewPassword">
				<el-input type="password" v-model="state.loginForm.NewPassword" :placeholder="$t('headerSetting.changePwd.newPwdPlaceholder')" />
			</el-form-item>
			<el-form-item :label="$t('headerSetting.changePwd.retypePwd')" prop="NewPassword2">
				<el-input type="password" v-model="state.loginForm.NewPassword2" :placeholder="$t('headerSetting.changePwd.retypePwdPlaceholder')" />
			</el-form-item>
		</el-form>
		<template #footer>
			<span class="dialog-footer">
				<el-button type="primary" @click="submitForm">{{$t('common.ok')}}</el-button>
				<el-button @click="cancel">{{$t('common.cancel')}}</el-button>
			</span>
		</template>
	</el-dialog>
</template>

<script setup>
import { reactive, ref, computed, onMounted } from "vue";
import { strongPasswordCheck } from "@common/js/rules";
import axios from "@common/js/axios";
import { alertSuccessMsg, i18nGlobal } from "@common/js/utils";
import { useDialog } from "@hooks/platformSetting";
import { useStore } from "vuex";
import SparkMD5 from "spark-md5";

const emit = defineEmits(["cancel", "submit"]);
const props = defineProps({
	dialog: {
		default: false,
		type: Boolean,
	},
});
const store = useStore();
const lang = computed(() => {
	return store.state.lang
})
const formRef = ref(null);

const checkStrongPass = (rule, value, callback) => {
	if (strongPasswordCheck(value)) {
		callback()
	} else {
		callback(new Error(i18nGlobal("common.strongPasswordRule")));
	}
}

const checkWeakPass = (rule, value, callback) => {
	const regex = /[^\u4e00-\u9fa5]{6,20}/;
	if (!regex.test(value)) {
		callback(new Error(i18nGlobal('headerSetting.changePwd.notContainCnToolTip')));
	} else {
		callback();
	}
};

const checkOldPass = (rule, value, callback) => {
	if (value === "") {
		callback(new Error(i18nGlobal('common.passwordRequired')));
	} else {
		callback();
	}
};

const checkNewPass = (rule, value, callback) => {
	if (value === "") {
		callback(new Error(i18nGlobal("common.passwordRequired")));
	} else if (value === state.loginForm.OldPassword) {
		callback(new Error(i18nGlobal('headerSetting.changePwd.samePwdToolTip')));
	} else if (!state.strongPassOption.IsStrongPassword) {
		checkWeakPass(rule, value, callback);
	} else {
		checkStrongPass(rule, value, callback);
	}
};

// 验证重复密
const checkRePass = (rule, value, callback) => {
	if (value !== state.loginForm.NewPassword) {
		callback(new Error(i18nGlobal('headerSetting.changePwd.differentPwdToolTip')));
	} else {
		callback();
	}
};

const state = reactive({
	loginForm: {
		OldPassword: "",
		NewPassword: "",
		NewPassword2: "",
	},
	rules: {
		OldPassword: [{ validator: checkOldPass, trigger: "blur", required: true }],
		NewPassword: [{ validator: checkNewPass, trigger: "blur", required: true }],
		NewPassword2: [{ validator: checkRePass, trigger: "blur", required: true }],
	},
	strongPassOption: {
		IsStrongPassword: false,
  		DefaultPassword: ""
	}
});

const { closeDialog, cancel, showDialog } = useDialog(formRef, emit);

const rememberPwd = (password) => {
	// alertWarningMsg('请注意账户安全');
	const storage = localStorage.getItem("phmLogin");
	const { UserName } = store.state.userInfo;
	if (storage) {
		const arr = JSON.parse(storage);
		const current = arr.find((item) => item.userName === UserName);
		if (current) {
			current.time = +new Date();
			current.password = password;
		} else {
			arr.push({
				time: +new Date(),
				userName: UserName,
				password: password,
			});
		}
		localStorage.setItem("phmLogin", JSON.stringify(arr));
	} else {
		localStorage.setItem(
			"phmLogin",
			JSON.stringify([
				{
					time: +new Date(),
					userName: UserName,
					password: password,
				},
			])
		);
	}
};

const submitForm = async () => {
	formRef.value.validate(async (valid) => {
		if (!valid) return false;
		const { UserId } = store.state.userInfo;
		const OldPassword = SparkMD5.hash(state.loginForm.OldPassword);
		const NewPassword = SparkMD5.hash(state.loginForm.NewPassword);

		axios({
			method: "put",
			url: `User/ChangePassword/${UserId}`,
			data: {
				OldPassword,
				NewPassword,
			},
		}).then((res) => {
			if (res.IsSuccess) {
				rememberPwd(NewPassword);
				alertSuccessMsg(i18nGlobal('common.modifiedSuccess'));
				emit("cancel");
				emit("submit");
			}
		});
	});
};

const getStrongPassword = async () => {
	// no permisson control
	return axios({
		method: "get",
		url: "Login/strong-password-info",
	}).then((res) => {
		if (res.IsSuccess) {
			state.strongPassOption = res.Result;
		}
	});
}

onMounted(() => {
	getStrongPassword();
})
</script>

<style scoped lang="less">
.el-form {
	margin-top: 10px;
}
</style>
