const CommonCN = {
    common: {
        enterToolTip: '请输入',
        selectToolTip: '请选择',
        confirm: '确认',
        ok: '确定',
        close: '关闭',
        cancel: '取消',
        save: '保存',
        clear: '清空',
        add: '添加',
        add1: '新增',
        query: '查询',
        reset: '重置',
        delete: '删除',
        edit: '编辑',
        modify: '修改',
        download: '下载',
        export: '导出',
        preview: '预览',
        upload: '上传',
        enable: '启用',
        disable: '禁用',
        checkAll: '全选',
        tip: '提示',
        uploadText: '将文件拖拽至此处或点击上传',
        operation: '操作',
        search: '搜索',
        confirmAll: '全部确认',
        inQuery: '查询中',
        uploading: '上传中',
        exporting: '导出中',
        loading: '加载中',
        deleting: '删除中',
        duringSelfInspection: '自检中',
        inBalance: '平衡中',
        zeroing: '清零中',
        downloading: '下载中',
        importing: '导入中',
        modifiedSuccess: '修改成功',
        applying: '应用中',
        editSuccess: '编辑成功',
        addSuccess: '添加成功',
        deleteSuccess: '删除成功',
        moveSuccess: '移动成功',
        uploadSuccess: '上传成功',
        exportSuccess: '导出成功',
        exportWarningSuccess: '导出创建成功,请前往系统任务中查看',
        importSucess: '导入成功',
        sortSuccess: '排序成功',
        confirmSuccess: '确认成功',
        saveSuccess: '保存成功',
        enableSuccess: '启用成功',
        joinSuccess: '加入成功',
        disableSuccess: '禁用成功',
        copySucess: '复制成功',
        setSuccess: '设置成功',
        submitSuccess: '提交成功',
        balancedSuccess: '平衡成功',
        zeroingSuccess: '清零成功',
        connectSuccess: '连接成功',
        noContent: '暂无数据',
        startTime: '开始时间',
        endTime: '结束时间',
        selectStartTimePlaceholder: '请选择开始时间',
        selectEndTimePlaceholder: '请选择结束时间',
        searchContentPlaceholder: '请输入搜索内容',
        startTimeLessThanEndTime: '开始时间必须小于结束时间',
        startTimeLessThanEndTime1: '开始时间必须小于结束时间',
        all: '所有',
        normal: '正常',
        warning: '高报',
        danger: '高高报',
        detail: '详情',
        description: '描述信息',
        descriptionPlaceholder: '请输入描述信息',
        baseInfo: '基本信息',
        baseInfoTip: '请填写基本信息',
        custom: '自定义',
        name: '名称',
        namePlaceholder: '请输入名称',
        graph: '图谱',
        sort: '序号',
        unit: '单位',
        depart: '部门',
        device: '设备',
        scene: '场景',
        aDay: '日',
        day: '天',
        hour: '小时',
        month: '月',
        year: '年',
        week: '周',
        Monday: '周一',
        Tuesday: '周二',
        Wednesday: '周三',
        Thursday: '周四',
        Friday: '周五',
        Saturday: '周六',
        Sunday: '周日',
        confirmTips: '这些记录将被永远地删掉，请确认',
        deleteAllGraph: '确认删除所有图谱吗?',
        pointName: '测点名称',
        amplitudeAlarm: '幅值报警',
        mutaionAlarm: '突变报警',
        selfLearningAlarm: '自学习报警',
        trendPredictionAlarm: '趋势预测报警',
        bandEnergyAlarm: '频带能量报警',
        yes: '是',
        no: '否',
        alarmLevel: '报警等级',
        signal: '信号',
        role: '角色',
        user: '用户',
        workgroup: '工作组',
        path: '路径',
        instrument: '仪器',
        collectionInstrument: '采集仪器',
        method: '方式',
        start: '开始',
        stop: '停止',
        close: '关闭',
        open: '开启',
        fault: '故障',
        deleteConfirm: '删除确认',
        title: '标题',
        all: '全部',
        caculate: '计算',
        settingConfig: '配置中心',
        emailConfig: '邮件配置',
        msgConfig: '短信配置',
        applyAll: '应用全部',
        on: '启用',
        off: '禁用',
        MondayShort: '周一',
        TuesdayShort: '周二',
        WednesdayShort: '周三',
        ThursdayShort: '周四',
        FridayShort: '周五',
        SaturdayShort: '周六',
        SundayShort: '周日',
        oneKey: "一键",
        block: "块",
        switchMode: "切换模式",
        positiveInteger: "正整数",
        naturalInteger: "自然数",
        points: "点数",
        viewChannel: "查看通道",
        mustUpdateExcel: "上传文件必须为excel文件",
        all: "全部",
        eigenType: "特征值类型",
        eigenTypePlaceholder: "请选择特征值",
        enableEigen: "启用特征值",
        enableAlarm: "启用报警",
        connect: "连接",
        disconnect: "断开连接",
        deleteConfig: "清除配置",
        exportConfig: "导出配置",
        pointFilter: "测点筛选",
        pointList: "测点列表",
        pointPath: "测点路径",
        configList: "配置列表",
        externalCode: "外部编码",
        eigen: "特征值",
        alarm: "报警",
        port: "端口",
        portPlaceholder: "请输入端口号",
        slaveId: "从属ID",
        slaveIdPlaceholder: "请输入从属ID",
        sensor: "传感器",
        signalType: "信号类型",
        observerCategory: "观察类别",
        observerItem: "观察项",
        taskRecord: "任务记录",
        holiday: "节假日",
        note: "备注",
        notePlaceholder: "请输入备注信息",
        isInternal: "是否系统内置",
        isRemovable: "是否可删除",
        isEnabled: "是否启用",
        vendor: "厂商",
        model: "型号",
        hour: "小时",
        minute: "分钟",
        second: "秒",
        online: "在线",
        offline: "离线",
        connected: "已连接",
        disconnected: "已断开连接",
        unit: "单位",
        department: "部门",
        isVisible: "是否显示",
        baseInfo: "基本信息",
        deviceTree: "设备树",
        platformSetting: "平台配置",
        authorizationSetting: "权限设置",
        browse: "浏览",
        departmentManagement: "部门管理",
        export: "导出",
        import: "导入",
        use: "使用",
        faultSetting: "故障配置",
        pointSetting: "测点配置",
        userManagement: "用户管理",
        setting: "设置",
        status: "状态",
        enabled: "已启用",
        disable: "禁用",
        disabled: "已禁用",
        onekeyReuse: "一键复用",
        onekeyEnable: "一键启用",
        onekeyDisable: "一键禁用",
        useRole: "所属角色",
        account: "账号",
        oldPassword: "旧密码",
        newPassword: "新密码",
        password: "密码",
        confirmPassword: "确认密码",
        underWorkGroup: "所属工作组",
        underUnit: "所属单位",
        underDepartment: "所属部门",
        passwordRequired: "请输入密码",
        passwordRule: "密码不能包含中文，长度应为6-20个字符",
        strongPasswordRule: "密码应为8字符以上，且包含大写字母、小写字母、数字与特殊字符",
        twoPasswordNotSame: "两次密码不一致！",
        accountRule: "账号仅支持字母、数字、下划线，长度应为2-18个字符",
        baseInfoRequired: "请填写完基本信息",
        copyrightInfo: "版权信息",
        version: "版本",
        lisenceVersion: "授权版本",
        lisenceDuration: "授权时间",
        unlimited: "无限制",
        themeSetting: "主题设置",
        dark: "深色",
        light: "浅色",
        apply: "应用",
        ipAddress: "IP地址",
        ipAddressPlaceholder: "请输入IP地址",
        noEditAuth: "暂无编辑权限",
        onekeyIncrement: "一键自增",
        yes: "是",
        no: "否",
        batchAdd: '批量添加',
        noRecord: '暂无记录',
        samplingFrequency: '采样频率',
        signalTypeTooltip: "请选择信号类型",
        numberTip: "请输入数字",
        version: '版本号',
        abnormal: '异常',
        patrolPlanTip: '请选择巡检计划',
        greaterThan: '大于',
        equal: '等于',
        lessThan: '小于',
        none: '无',
        batch: '批次',
        chinese: '中文',
        code: '编码'
    },
    // 涉及后台翻译，禁止修改
    important: {
        setting: '设置',
        config: '配置',
        faultSetting: '故障设置',
        pointSetting: '测点配置',
        platformSetting: '平台配置',
        treeSetting: '设备树配置',
        browseText: '浏览（查找）',
        use: '使用',
        browse: '浏览',
        export: '导出',
        permissions: '权限设置',
        departManage: '部门管理',
        sampleError: '仪器正在采样'
    }
}

export default CommonCN