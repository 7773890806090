import { createRouter, createWebHashHistory } from "vue-router";
import mainLayout from "@views/mainLayout/container.vue";
import store from "@common/js/store";
import axios from "@common/js/axios";
import { LoginSuccessEnum } from "@common/js/enum.js";
import { axiosAuth, getAlarmLevel, getGraphSetting, getTokens, getUserAuth, setTokens } from "@common/js/auth.js";
import { ElMessageBox } from 'element-plus';
import { i18nGlobal } from "@common/js/utils";

const routes = [
	{
		path: "/license",
		name: "license",
		component: () => import("@views/login/license.vue"),
	},
	{
		path: "/404",
		name: "notFound",
		component: () => import("@views/errorPage/notFound.vue"),
	},
	{
		path: "/401",
		name: "noPermission",
		component: () => import("@views/errorPage/noPermission.vue"),
	},
	{
		path: "/mainPage",
		name: "mainPage",
		component: mainLayout,
		redirect: {
			name: "summary",
		},
		children: [
			{
				path: "/summary",
				name: "summary",
				component: () => import("@views/summary/index.vue"),
			},
			{
				path: "/depState",
				name: "depState",
				component: () => import("@views/depState/index.vue"),
			},
			{
				path: "/faultSetting",
				name: "faultSetting",
				component: () => import("@views/faultSetting/index.vue"),
			},
			{
				path: "/maintainPlan",
				name: "maintainPlan",
				component: () => import("@views/maintainPlan/index.vue"),
			},
			{
				path: "/pointSetting",
				name: "pointSetting",
				component: () => import("@views/pointSetting/index.vue"),
			},
			{
				path: "/workState",
				name: "workState",
				component: () => import("@views/workState/index.vue"),
			},
			{
				path: "/pointData",
				name: "pointData",
				component: () => import("@views/pointData/index.vue"),
			},
			{
				path: "/patrol/plan",
				name: "patrolPlan",
				component: () => import("@views/patrol/plan/patrolPlan.vue"),
			},
			{
				path: "/patrol/task",
				name: "patrolTask",
				component: () => import("@views/patrol/index.vue"),
			},
			{
				path: "/:pathMatch(.*)*",
				redirect: "/404",
			},
		],
	},
	{
		path: "/changePassword",
		name: "changePassword",
		component: () => import("@views/login/changePassword.vue"),
	},
];

if (!window.Config.project.enable) {
	routes.push({
		path: "/",
		name: "login",
		component: () => import("@views/login/template/normal.vue"),
	});
} else {
	routes.push({
		path: "/",
		name: "login",
		component: () => import(`@views/login/template/${window.Config.project.props.projectCode}.vue`),
	});
}

if (!window.Config.project.dungouji) {
	routes[3].children.unshift({
		path: "/fileLibrary",
		name: "fileLibrary",
		component: () => import("@views/fileLibrary/index.vue"),
	})
} else {
	routes[3].children.unshift({
		path: "/fileLibraryVertical",
		name: "fileLibraryVertical",
		component: () => import("@views/fileLibraryVertical/index.vue"),
	})
}

const router = createRouter({
	history: createWebHashHistory(),
	routes,
});

router.beforeEach(async (to, from, next) => {
	await setThirdInterface(to);
	let { UserName } = store.state.userInfo;
	if (to.name !== "login" && to.name !== "license" && to.name !== "changePassword") {
		if (getTokens(UserName).token) {
			next();
		} else {
			next({ name: "login" });
		}
	} else {
		next();
	}
});

const getDepByPointId = async (pointId) => {
	const res = await axiosAuth({
		method: 'get',
		url: `Point/${pointId}/device`,
		auth: "Point_Browse"
	})

	return res?.Result;
}

const setThirdInterface = async (to) => {
	if (to.query.appType == "0" && to.query.token && to.query.appId) {
		await juhuaAutoLogin({ Token: to.query.token, AppId: to.query.appId });
		return;
	}
	if (to.query.password && window.Config.usePassword == false) {
		return;
	}
	if (to.query.username && (to.query.token || to.query.password)) {
		if (to.query.token) {
			await autoLogin({
				UserName: to.query.username,
				Token: to.query.token,
				UseToken: true
			});
		} else if (to.query.password) {
			await autoLogin({
				UserName: to.query.username,
				Password: to.query.password,
			});
		}
		if (to.query.path) {
			window.targetDep = to.query.path;
			window.onTargetDepChanged?.(to.query.path);
		}
		if (to.query.isShrink != undefined) {
			store.commit("setShrink", { shrink: true });
			window.hideFaultTab = true;
		}
		if (to.query.showExpand != undefined) {
			window.showExpandBtn = to.query.showExpand == "true";
			window.onExpandBtnChanged?.(window.showExpandBtn);
		}
		if (to.query.faultId != undefined && to.query.faultId != '') {
			window.targetFault = to.query.faultId;
			window.onTargetFaultChanged?.(to.query.faultId);
		}
		/** 跳转到指定测点所在的设备 */
		if (to.query.pointId != undefined && to.query.pointId != '') {
			getDepByPointId(to.query.pointId).then((depData) => {
				if (!depData) return;
				window.targetDep = depData.Path;
				window.onTargetDepChanged?.(window.targetDep);
			})
		}
		window.customPath = true;
	}
};

const juhuaAutoLogin = async (data) => {
	const res = await axios({
		method: 'post',
		url: 'JuhuaLogin/Login',
		data: data,
	});
	await setLoginInfo(res.Result);
}

const autoLogin = async (param) => {
	const res = await axios({
		method: 'post',
		url: 'Login/Login',
		data: param,
	});
	await setLoginInfo(res.Result);
}

const setLoginInfo = async (loginResult) => {
	if (loginResult && loginResult.LoginResult && loginResult.LoginResult === LoginSuccessEnum.Success) {

		const auth = await getUserAuth(loginResult.UserName);
		if (auth.ResourceAuth.length === 0 || Object.keys(auth.MachineTreeAuth).length === 0) {
			ElMessageBox.alert(i18nGlobal('login.unauthorizedTip'), i18nGlobal('common.tip'), {
				confirmButtonText: i18nGlobal('common.ok'),
				showClose: false,
				callback: () => {
					const userAgent = navigator.userAgent;
					if (userAgent.indexOf("Firefox") != -1 || userAgent.indexOf("Chrome") != -1) {
						window.location.href = "about:blank";
						window.close();
					} else {
						window.opener = null;
						window.open("", "_self");
						window.close();
					}
				},
			})
			return;
		}
		setTokens(loginResult.UserName, loginResult);
		store.commit('setUserInfo', { userInfo: loginResult });
		await getGraphSetting();
		await getAlarmLevel();
	}
}

export default router
