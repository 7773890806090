// @generated by protobuf-ts 2.9.1
// @generated from protobuf file "protos/nearestpointseigendatas.proto" (package "alarm", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { HistoryPointDataReply } from "./historypointdatareply";
import { AlarmPointEigensRequest } from "./alarmpointeigens";
/**
 * @generated from protobuf message alarm.QueryNearestPointsEigenDatasRequest
 */
export interface QueryNearestPointsEigenDatasRequest {
    /**
     * @generated from protobuf field: string EventId = 1 [json_name = "EventId"];
     */
    eventId: string;
    /**
     * @generated from protobuf field: repeated alarm.AlarmPointEigensRequest Points = 2 [json_name = "Points"];
     */
    points: AlarmPointEigensRequest[];
    /**
     * @generated from protobuf field: string DateTime = 3 [json_name = "DateTime"];
     */
    dateTime: string;
}
/**
 * @generated from protobuf message alarm.QueryNearestPointsEigenDatasReply
 */
export interface QueryNearestPointsEigenDatasReply {
    /**
     * @generated from protobuf field: repeated storage.HistoryPointDataReply Points = 1 [json_name = "Points"];
     */
    points: HistoryPointDataReply[];
}
// @generated message type with reflection information, may provide speed optimized methods
class QueryNearestPointsEigenDatasRequest$Type extends MessageType<QueryNearestPointsEigenDatasRequest> {
    constructor() {
        super("alarm.QueryNearestPointsEigenDatasRequest", [
            { no: 1, name: "EventId", kind: "scalar", jsonName: "EventId", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "Points", kind: "message", jsonName: "Points", repeat: 1 /*RepeatType.PACKED*/, T: () => AlarmPointEigensRequest },
            { no: 3, name: "DateTime", kind: "scalar", jsonName: "DateTime", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<QueryNearestPointsEigenDatasRequest>): QueryNearestPointsEigenDatasRequest {
        const message = { eventId: "", points: [], dateTime: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<QueryNearestPointsEigenDatasRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: QueryNearestPointsEigenDatasRequest): QueryNearestPointsEigenDatasRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string EventId = 1 [json_name = "EventId"];*/ 1:
                    message.eventId = reader.string();
                    break;
                case /* repeated alarm.AlarmPointEigensRequest Points = 2 [json_name = "Points"];*/ 2:
                    message.points.push(AlarmPointEigensRequest.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string DateTime = 3 [json_name = "DateTime"];*/ 3:
                    message.dateTime = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: QueryNearestPointsEigenDatasRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string EventId = 1 [json_name = "EventId"]; */
        if (message.eventId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.eventId);
        /* repeated alarm.AlarmPointEigensRequest Points = 2 [json_name = "Points"]; */
        for (let i = 0; i < message.points.length; i++)
            AlarmPointEigensRequest.internalBinaryWrite(message.points[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string DateTime = 3 [json_name = "DateTime"]; */
        if (message.dateTime !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.dateTime);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message alarm.QueryNearestPointsEigenDatasRequest
 */
export const QueryNearestPointsEigenDatasRequest = new QueryNearestPointsEigenDatasRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class QueryNearestPointsEigenDatasReply$Type extends MessageType<QueryNearestPointsEigenDatasReply> {
    constructor() {
        super("alarm.QueryNearestPointsEigenDatasReply", [
            { no: 1, name: "Points", kind: "message", jsonName: "Points", repeat: 1 /*RepeatType.PACKED*/, T: () => HistoryPointDataReply }
        ]);
    }
    create(value?: PartialMessage<QueryNearestPointsEigenDatasReply>): QueryNearestPointsEigenDatasReply {
        const message = { points: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<QueryNearestPointsEigenDatasReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: QueryNearestPointsEigenDatasReply): QueryNearestPointsEigenDatasReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated storage.HistoryPointDataReply Points = 1 [json_name = "Points"];*/ 1:
                    message.points.push(HistoryPointDataReply.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: QueryNearestPointsEigenDatasReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated storage.HistoryPointDataReply Points = 1 [json_name = "Points"]; */
        for (let i = 0; i < message.points.length; i++)
            HistoryPointDataReply.internalBinaryWrite(message.points[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message alarm.QueryNearestPointsEigenDatasReply
 */
export const QueryNearestPointsEigenDatasReply = new QueryNearestPointsEigenDatasReply$Type();
