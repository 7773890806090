// http/index.js
import axios from "axios";
import { ElMessageBox } from "element-plus";
import { alertErrorMsg, i18nGlobal, removeElLoading } from "@common/js/utils";
import store from "@common/js/store";
import { getTokens, setTokens } from "@common/js/auth";

//创建axios的一个实例
const instance = axios.create({
	baseURL: Config.url, //接口统一域名
	// baseURL: '/api',
	timeout: 0, // 设置超时
	withCredentials: true,
});

const showMsg = (status) => {
	let message = "";
	switch (status) {
		case 400:
			message = i18nGlobal('commonJs.code400');
			break;
		case 403:
			message = i18nGlobal('commonJs.code403');
			return;
		case 404:
			message = i18nGlobal('commonJs.code404');
			break;
		case 405:
			message = i18nGlobal('commonJs.code405');
			break;
		case 406:
			message = i18nGlobal('commonJs.code406');
			break;
		case 408:
			message = i18nGlobal('commonJs.code408');
			break;
		case 500:
			message = i18nGlobal('commonJs.code500');
			break;
		case 501:
			message = i18nGlobal('commonJs.code501');
			break;
		case 502:
			message = i18nGlobal('commonJs.code502');
			break;
		case 503:
			message = i18nGlobal('commonJs.code503');
			break;
		case 504:
			message = i18nGlobal('commonJs.code504');
			break;
		case 505:
			message = i18nGlobal('commonJs.code505');
			break;
		default:
			message = i18nGlobal('commonJs.codeDefault');
	}
	alertErrorMsg(message);
};

//请求拦截器
instance.interceptors.request.use(
	(config) => {
		let headers = {}
		const { UserName } = store.state.userInfo;
		config.headers['Accept-Language'] = store.state.lang === 'CN' ? 'zh-Hans' : 'EN';
		if (UserName) {
			const tokens = getTokens();
			headers.Authorization = "Bearer " + tokens.accessToken;
			headers.UserToken = tokens.token;
			headers["Content-Type"] = "application/json; charset=utf-8";
			if ((config.url.includes("Upload") && !config.url.includes("PatrolUpload")) || config.url.includes("DownloadFile") || config.url.includes("upload") || config.url.includes("Component/model") || config.url.includes("importCheck")) {
				headers["Content-Type"] = "multipart/form-data";
			}
		}
		if (store.state.currentDep?.Path && store.state.browseType === 'pc') {
			headers.Path = store.state.currentDep.Path;
		} else {
			headers.Path = '0001';
		}
		config.headers = { ...headers, ...config.headers };
		Promise.resolve(null)
		return config;
	},
	(error) => {
		Promise.reject(error);
	}
);

// 刷新token
const refreshToken = () => {
	return instance({
		method: "post",
		url: `Authority/RefreshToken?refreshToken=${getTokens().refreshToken}`,
	}).then((res) => {
		if (res.IsSuccess) {
			const result = res.Result;
			return result;
		}
	});
};

// 是否正在刷新的标记
let isRefreshing = false;
let isTimeOut = false;
// 重试队列，每一项将是一个待执行的函数形式
let requests = [];

//响应拦截器
instance.interceptors.response.use(
	(response) => {
		if (!response.data.IsSuccess) {
			const loginCode = ["ms:000026", "ms:000024", 24];
			if (loginCode.includes(response.data.ErrorCode) && !isTimeOut) {
				isTimeOut = true;
				let msg = "";
				msg = response.data.Message;
				const { UserName } = store.state.userInfo;
				ElMessageBox.alert(i18nGlobal('commonJs.tokenLoseTip'), i18nGlobal('common.tip'), {
					confirmButtonText: i18nGlobal('common.confirm'),
					showClose: false,
					callback: () => {
						localStorage.removeItem('userName');
						store.commit("reset", {});
						window.location.href = "/";
						isTimeOut = false;
					},
				});
			} else if (response.data.Message || response.data.Detail) {
				alertErrorMsg(response.data.Message || response.data.Detail);
			}
		}

		if (response.data.Result && response.data.Result.errors) {
			let msg = "";
			const error = response.data.Result.errors;
			for (const key in error) {
				const element = error[key];
				msg += element;
			}
			alertErrorMsg(msg);
		}
		return response.data;
	},
	(error) => {
		const response = error.response;
		if (response) {
			const config = response.config;
			const status = error.response.status;
			if (status === 401) {
				if (!getTokens().refreshToken) {
					ElMessageBox.alert(i18nGlobal('commonJs.tokenLoseTip'), i18nGlobal('common.tip'), {
						confirmButtonText: i18nGlobal('common.confirm'),
						showClose: false,
						callback: () => {
							localStorage.removeItem('userName');
							store.commit("reset", {});
							window.location.href = "/";
						},
					});
					return Promise.reject(error);
				}
				if (!isRefreshing) {
					requests.push((config) => {
						instance(config);
					});
					isRefreshing = true;
					return refreshToken().then((res) => {
						const { UserName } = store.state.userInfo;
						const { AccessToken } = res;
						const originTokens = getTokens();
						setTokens(UserName, { ...res, Token: originTokens.token });
						config.headers["Authorization"] = "Bearer " + AccessToken;
						// 已经刷新了token，将所有队列中的请求进行重试

						requests.forEach((cb) => cb(res, config));
						requests = [];
						removeElLoading();
						return instance(config);
					}).catch((res) => {
						console.error("refreshtoken error =>", res);
					}).finally(() => {
						isRefreshing = false;
					});
				} else {
					return new Promise((resolve) => {
						requests.push((config) => instance(config));
						resolve(instance(config));
					});
				}
			} else if (status === 403) {
				const { UserName } = store.state.userInfo;
				localStorage.removeItem('userName');
				store.commit("reset", {});
				window.location.href = "/";
				return Promise.reject(error);
			} else {
				if (config.method != 'get' && config.showError != false) showMsg(status);
				return Promise.reject(error);
			}
		} else {
			// alertErrorMsg('请求错误');
		}
	}
);

export default instance;
