import { PlotTypeEnum } from "@common/js/enum";
import store from "@common/js/store";
import { binarySearch, Debounce, elFullScreenDelayLoading, guid, i18nGlobal } from "@common/js/utils";
import { NavigateGraph } from "@core/graphs/js/Dh-graph-navigate";
import { PluginMode, ScaleType, XAxisShowType } from "@core/graphs/webcharts/Dh-webchart-models";
import { PointCacheContext } from "@core/js/clientRealContext";
import { EnumXDataType } from "@core/js/dataParserContext";
import dayjs from "dayjs";
import { nextTick, reactive, ref } from "vue";
import { EnumQueryDataType, ThemeResource } from "./model";

export class NavigateOperator {
    constructor(owner) {
        this.owner = owner;
        this.debounce = new Debounce();
    }

    /**布局操作 */
    initializeParam(operator) {
        operator.state.Position = {
            width: "400px",
            height: "200px",
        };
    }
    onComponentMounted(operator, props) {
        operator.state.Position = {
            width: props.componentOption.width + "px",
            height: props.componentOption.height + "px",
        };
    }
    updateComponentSize(rect, operator) {
        const that = this.owner;
        operator.state.Position.width = rect.width + "px";
        operator.state.Position.height = rect.height + "px";
        that.updateSize(rect);
    }
    setParam() { }
    setTheme() { }
    close() { }
}


export class TimeRangeNavigateOperator extends NavigateOperator {
    constructor(owner) {
        super(owner);
        this.orginRect = { width: 400, height: 200 };
        this.initialize();
    }
    initialize() {
        const that = this.owner;
        this.navigateGraphHeight = 32;
        this.navigateGraphTop = 26;
        that.showTimeSelector = ref(true);
    }
    setParam(param) {
        const that = this.owner;
        that.showTimeSelector.value = param.showTimeSelector ?? true;
    }
    /**布局操作 */
    initializeParam(operator) {
        super.initializeParam(operator);

        operator.state.graphPos = {
            width: "400px",
            height: "200px",
        }
        operator.state.navigatePos = {
            width: "400px",
            height: this.navigateGraphHeight + "px",
            marginTop: this.navigateGraphTop + "px",
        }
        this.orginRect = { width: 400, height: 200 + this.navigateGraphHeight + this.navigateGraphTop };

        this.initializeEvents(operator);
    }
    initializeEvents(operator) {
        const that = this.owner;
        that.onGraphChanged = () => {
            this.updateComponentSize(this.orginRect, operator);
        };
    }
    updateComponentSize(rect, operator) {
        const that = this.owner;
        const showNavigateGraph = that.isHistory.value && that.showTimeSelector.value && store.state.browseType === 'pc';
        operator.state.graphPos.width = rect.width + "px";
        operator.state.graphPos.height = showNavigateGraph ? rect.height - this.navigateGraphHeight - this.navigateGraphTop + "px" : rect.height + "px";
        operator.state.navigatePos.width = rect.width + "px";
        operator.state.navigatePos.height = showNavigateGraph ? this.navigateGraphHeight + "px" : "0px";
        operator.state.navigatePos.marginTop = showNavigateGraph ? this.navigateGraphTop + "px" : "0px";
        this.orginRect.width = rect.width;
        this.orginRect.height = rect.height;
        that.updateSize({ width: rect.width, height: showNavigateGraph ? rect.height - this.navigateGraphHeight - this.navigateGraphTop : rect.height });
    }
    onComponentMounted(operator, props) {
        const that = this.owner;
        const { width, height } = props.componentOption;
        const showNavigateGraph = that.isHistory.value && that.showTimeSelector.value;
        operator.state.graphPos = {
            width: width + "px",
            height: showNavigateGraph ? height - this.navigateGraphHeight - this.navigateGraphTop + "px" : height + "px",
        };
        operator.state.navigatePos = {
            width: width + "px",
            height: showNavigateGraph ? this.navigateGraphHeight + "px" : "0px",
            marginTop: showNavigateGraph ? this.navigateGraphTop + "px" : "0px",
        };
        this.orginRect.width = width;
        this.orginRect.height = height;
    }
}

export class GraphNavigateOperator extends TimeRangeNavigateOperator {
    constructor(owner) {
        super(owner);
        this.debounce = new Debounce();
        this.doubleNaviDebounce = new Debounce();
        this.initialize();
    }
    initialize() {
        const that = this.owner;
        this.navigateGraphHeight = 30;
        this.navigateGraphTop = 28;
        that.showTimeSelector = ref(false);
        that.navigateOption = this.createNavigateGraphOption();
        that.navigateGraph = null;
        that.toolbarOption.showNavigate = true;
        that.toolbarOption.showExport = false;
        that.tooltipChanged = null;
        that.showNavigate = true;
    }
    createNavigateGraphOption() {
        const that = this.owner;
        return reactive({
            Id: guid(),
            Axis: {
                ShowDot: store.state.graphSetting.ShowDot,
                AxisScaleType: ScaleType.Auto,
                XAxisShowType: XAxisShowType.ShowDate,
                ShowEndPoint: true,
                Theme: that.theme,
            },
            point: null,
            startTime: null,
            endTime: null,
            cursorType: PluginMode.NavigateSingleCursor,
            rangeStartTime: null,
            rangeEndTime: null,
        });
    }
    getNavigateGraph() {
        return new NavigateGraph();
    }
    async createNavigateGraph() {
        const that = this.owner;
        this.setTheme(that.theme);
        that.navigateGraph = this.getNavigateGraph();
        that.navigateGraph.Init(that.navigateOption, this.createNaviageGraphPlugin());
        await this.customProcessNavigateGraph();
        that.updateOption(that.option);
        const pluginManager = that.navigateGraph.GetPluginManager();
        pluginManager.OpenPlugin([that.navigateOption.cursorType]);
        this.getNavigateDatas((data) => {
            if (data?.length > 0) {
                let navigateCursor = pluginManager.GetRadioPlugin();
                if (navigateCursor) {
                    if (that.navigateOption.cursorType == PluginMode.NavigateSingleCursor) {
                        navigateCursor.SetPosition(data[0].Datas.Points.length - 1);
                    }
                }
            }
        });
        nextTick(() => {
            that.navigateGraph.EnsureGraphSizes();
            that.navigateGraph.OnSizeChanged();
        });
    }
    async customProcessNavigateGraph() {
        const that = this.owner;
        const currentTime = dayjs();
        that.navigateOption.startTime = currentTime.add(-1, "hour").format("YYYY-MM-DD HH:mm:ss");
        that.navigateOption.endTime = currentTime.format("YYYY-MM-DD HH:mm:ss");
        if (that.navigateOption.point == null) {
            that.navigateOption.point = that.option.RegisterPoints.length > 0 ? that.option.RegisterPoints[0] : null;
        }
    }
    createNaviageGraphPlugin() {
        const that = this.owner;
        return [
            {
                Type: PluginMode.NavigateSingleCursor,
                onPositionChanged: (data) => {
                    this.debounce.run(() => {
                        if (data && data.X && that.navigateOption.cursorType == PluginMode.NavigateSingleCursor) {
                            let time = dayjs(data.X).format("YYYY-MM-DD HH:mm:ss.SSS");
                            let isAlarm = data.Items?.length > 0 && data.Items[0].Tag?.Point?.AlarmLevel > 1;
                            if (isAlarm) {
                                that.queryPointAlarmDataEx(time);
                            } else {
                                that.getHistoryData(time);
                            }
                        }
                    }, 300);
                },
                onDataChange: (data) => {
                    that.tooltipChanged?.(data);
                },
            },
            {
                Type: PluginMode.NavigateDoubleCursor,
                onPositionChanged: (data) => {
                    this.doubleNaviDebounce.runAwait((action) => {
                        if (data && data.Cursors?.length > 0 && that.navigateOption.cursorType == PluginMode.NavigateDoubleCursor) {
                            that.graph?.ClearZoom();
                            that.getRangeHistoryData(data.Cursors[0].XText, data.Cursors[1].XText, action);
                        }
                    }, 300);
                },
                onDataChange: (data) => {
                    that.tooltipChanged?.(data);
                },
            },
        ];
    }
    async getNavigateDatas(callback) {
        const that = this.owner;
        const conditon = {
            StartTime: that.navigateOption.startTime,
            EndTime: that.navigateOption.endTime,
            DataType: EnumQueryDataType.Eigen,
            IsExternal: that.layoutOperator.plotType === PlotTypeEnum.Analysis,
            Points: await this.getNavigatePointEigens(),
        };
        const cursor = that.navigateGraph.GetPluginManager().GetRadioPlugin();
        const position = cursor.GetPosition().Position;
        const loading = elFullScreenDelayLoading(i18nGlobal('common.inQuery'));
        that.dataClient.getDatas(conditon)
            .then((res) => {
                this.updateNavigateDatas(res, position, conditon);
                callback?.(res);
            }).finally(() => {
                loading.close();
            });
    }
    async getNavigatePointEigens() {
        const points = [];
        const that = this.owner;
        if (that.navigateOption.point) {
            const point = await PointCacheContext.GetInstance().get(that.navigateOption.point.PointId);
            if (point != null) {
                if (point.UseNavigateEigen) {
                    points.push({ PointId: point.Id, EigenTypes: [9000] });
                } else if (point.EigenTypes.length > 0) {
                    points.push({ PointId: point.Id, EigenTypes: [point.EigenTypes[0].Key] });
                }
            }
        }
        return points;
    }
    updateNavigateDatas(res, position, condition) {
        const that = this.owner;
        if (res?.length > 0) {
            for (const data of res) {
                if (position?.length > 0) {
                    for (const cursor of position) {
                        const name = data.Param.Name + "_" + data.EigenType.Value;
                        if (cursor.Items) {
                            const item = cursor.Items.find((r) => r.Text == name);
                            if (item) {
                                const xcofe = data.Datas.XDataType == EnumXDataType.Time ? 10000 : 1;
                                const sourceX = item.Tag.Point.X * xcofe;
                                const p = { X: sourceX, Y: item.Tag.Point.Y };
                                let index = binarySearch(data.Datas.Points, data.Datas.DataCount, sourceX, (p) => { return p.X; });
                                if (data.Datas.DataCount > 0) {
                                    if (data.Datas.Points[index].X < sourceX) {
                                        data.Datas.Points.splice(index + 1, 0, p);
                                    } else if (data.Datas.Points[index].X > sourceX) {
                                        data.Datas.Points.splice(index, 0, p);
                                    }
                                }
                            }
                        }
                    }
                }
            }
            that.navigateGraph?.SetData(res, condition.StartTime, condition.EndTime);
        } else {
            that.navigateGraph.ClearData();
        }
    }
    changeNavigateGraphCursor(cursor) {
        const that = this.owner;
        const pluginManager = that.navigateGraph.GetPluginManager();
        const currPos = pluginManager.GetPlugin(that.navigateOption.cursorType)?.GetPosition()?.Position;
        pluginManager.ClosePlugin([that.navigateOption.cursorType]);
        that.navigateOption.cursorType = cursor;
        pluginManager.OpenPlugin([cursor]);
        if (cursor == PluginMode.NavigateDoubleCursor) {
            const doubleCursor = pluginManager.GetPlugin(cursor);
            const data = doubleCursor.Owner.ShapeOperator.Data;
            if (data.length == 0 || data[0].Points.length == 0) return;
            const len = data[0].Points.length;
            let pos;
            if (currPos && currPos.length == 1 && currPos[0] && currPos[0].Items?.length > 0) {
                let index = binarySearch(data[0].Points, len, currPos[0].Items[0].Tag.Point.X, (p) => { return p.X; });
                if (index == 0) pos = [0, len < 3 ? len - 1 : 2];
                else if (index == len - 1) pos = [len > 3 ? len - 3 : 0, len - 1];
                else pos = [index - 1, index + 1];
            } else {
                pos = [0, len < 3 ? len - 1 : 2];
            }
            doubleCursor.SetPosition(pos);
        }
    }
    setTheme(theme) {
        const that = this.owner;
        if (that.navigateOption == null) return;
        that.navigateOption.Axis.XAxisTextColor = ThemeResource[theme].AxisTextColor();
        that.navigateOption.Axis.YAxisTextColor = ThemeResource[theme].AxisTextColor();
        that.navigateOption.Axis.RuleColor = ThemeResource[theme].LineColor();
        that.navigateOption.Axis.AxisColor = ThemeResource[theme].LineColor();
        that.navigateOption.Axis.HorCrossColor = ThemeResource[theme].CrossColor();
        that.navigateOption.Axis.VerCrossColor = ThemeResource[theme].CrossColor();
        that.navigateOption.Axis.VerCrossStyle = ThemeResource[theme].CrossStyle();
        that.navigateOption.Axis.HorCrossStyle = ThemeResource[theme].CrossStyle();
        that.navigateOption.Axis.TitleColor = ThemeResource[theme].TitleColor();
        that.navigateOption.Axis.UnitTextColor = ThemeResource[theme].LineColor();
        that.navigateGraph?.Update(that.navigateOption);
    }
    updateNavigateSize(rect) {
        const that = this.owner;
        that.navigateGraph?.EnsureGraphSizes(rect);
        that.navigateGraph?.OnSizeChanged();
    }
    closeNavigateGraph() {
        const that = this.owner;
        that.navigateGraph?.Clear();
        that.navigateGraph = null;
    }
    close() {
        this.closeNavigateGraph();
    }
    /**布局操作 */
    initializeEvents(operator) {
        const that = this.owner;
        that.onGraphChanged = () => {
            this.updateComponentSize(this.orginRect, operator);
        };
        that.onComponentChangeNav = () => {
            this.changeNavigateStatus(operator);
        };
    }
    updateComponentSize(rect, operator) {
        const that = this.owner;
        const showNavigateGraph = that.toolbarOption.showNavigate && that.toolbarOption.navigateExpand;
        operator.state.graphPos.width = rect.width + "px";
        operator.state.graphPos.height = showNavigateGraph ? rect.height - this.navigateGraphHeight - this.navigateGraphTop + "px" : rect.height + "px";
        operator.state.navigatePos.width = rect.width + "px";
        operator.state.navigatePos.height = showNavigateGraph ? this.navigateGraphHeight + "px" : "0px";
        operator.state.navigatePos.marginTop = showNavigateGraph ? this.navigateGraphTop + "px" : "0px";
        this.orginRect.width = rect.width;
        this.orginRect.height = rect.height;
        that.updateSize({ width: rect.width, height: showNavigateGraph ? rect.height - this.navigateGraphHeight - this.navigateGraphTop : rect.height });
        if (showNavigateGraph) {
            this.updateNavigateSize({ width: rect.width, height: this.navigateGraphHeight });
        }
    }
    changeNavigateStatus(operator) {
        const that = this.owner;
        nextTick(() => {
            if (that.toolbarOption.navigateExpand) {
                this.createNavigateGraph();
            } else {
                this.closeNavigateGraph();
            }
            this.updateComponentSize(this.orginRect, operator);
        });
    }
    onComponentMounted(operator, props) {
        const that = this.owner;
        const { width, height } = props.componentOption;
        const { showNavigate, navigateExpand } = that.toolbarOption;
        const showNavigateGraph = showNavigate && navigateExpand;
        operator.state.graphPos = {
            width: width + "px",
            height: showNavigateGraph ? height - this.navigateGraphHeight - this.navigateGraphTop + "px" : height + "px",
        };
        operator.state.navigatePos = {
            width: width + "px",
            height: showNavigateGraph ? this.navigateGraphHeight + "px" : "0px",
            marginTop: showNavigateGraph ? this.navigateGraphTop + "px" : "0px",
        };
        this.orginRect.width = width;
        this.orginRect.height = height;
    }
}