const CommonEN = {
    common: {
        "enterToolTip": "Please enter",
        "selectToolTip": "Please select",
        "confirm": "Confirm",
        "ok": "Ok",
        "close": 'Close',
        "cancel": "Cancel",
        "save": "Save",
        "clear": "Clear",
        "add": "Add",
        "add1": "Add",
        "query": "Query",
        "reset": "Reset",
        "delete": "Delete",
        "edit": "Edit",
        "modify": "Modify",
        "download": "Download",
        "export": "Export",
        "preview": "Preview",
        "upload": "Upload",
        "enable": "Enable",
        "disable": "Disable",
        "checkAll": "Check all",
        "tip": "Tip",
        "uploadText": "Drag and drop files here or click upload",
        "operation": "Operation",
        "search": "Search",
        "confirmAll": "Confirm all",
        "inQuery": "In query",
        "uploading": "Uploading",
        "exporting": "Exporting",
        "loading": "Loading",
        "deleting": "Deleting",
        "duringSelfInspection": "During self inspection",
        "inBalance": "In balance",
        "zeroing": "Zeroing",
        "downloading": "Downloading",
        "importing": "Importing",
        "applying": 'Applying',
        "modifiedSuccess": "Successfully modified",
        "editSuccess": "Successfully edited",
        "addSuccess": "Successfully added",
        "deleteSuccess": "Successfully deleted",
        "moveSuccess": "Successfully moved",
        "uploadSuccess": "Successfully uploaded",
        "exportSuccess": "Successfully exported",
        "exportWarningSuccess": 'The export is successful, please go to System Tasks to download',
        "importSucess": "Successfully imported",
        "sortSuccess": "Successfully sorted",
        "confirmSuccess": "Confirmed successfully",
        "saveSuccess": "Successfully saved",
        "enableSuccess": "Successfully enable",
        "joinSuccess": 'Successfully joined',
        "disableSuccess": "Successfully disable",
        "copySucess": "Successfully copied",
        "setSuccess": "Successfully set",
        "submitSuccess": "Successfully submited",
        "balancedSuccess": "Successfully balanced",
        "zeroingSuccess": "Zeroing successful",
        "connectSuccess": "Successfully connected",
        "noContent": "No data",
        "startTime": "Start time",
        "endTime": "End time",
        "selectStartTimePlaceholder": "Please select a start time",
        "selectEndTimePlaceholder": "Please select a end time",
        "searchContentPlaceholder": "Please enter search content",
        "startTimeLessThanEndTime": "The start time must be less than the end time",
        "startTimeLessThanEndTime1": 'The start time must be less than the end time',
        "all": "All",
        "normal": "Normal",
        "warning": "Warning",
        "danger": "Critical",
        "detail": "Detail",
        "description": "Description",
        "descriptionPlaceholder": "Please enter descriptive information",
        "baseInfo": "Base information",
        "baseInfoTip": "Please enter the base information",
        "custom": "Custom",
        "name": "Name",
        "namePlaceholder": "Please enter the name",
        "graph": "Graph",
        "sort": "Sort",
        "unit": "Unit",
        "depart": "Depart",
        "device": "Device",
        "scene": "Scene",
        "aDay": 'Day',
        "day": "Day",
        "hour": "Hour",
        "month": "Month",
        "year": "Year",
        "week": "Week",
        "Monday": "Monday",
        "Tuesday": "Tuesday",
        "Wednesday": "Wednesday",
        "Thursday": "Thursday",
        "Friday": "Friday",
        "Saturday": "Saturday",
        "Sunday": "Sunday",
        "confirmTips": "These records will be permanently deleted, please confirm",
        "deleteAllGraph": "Are you sure to delete all graphs?",
        "pointName": "Point name",
        "amplitudeAlarm": "Amplitude alarm",
        "mutaionAlarm": "Mutaion alarm",
        "selfLearningAlarm": "Self learning alarm",
        "trendPredictionAlarm": "Trend prediction alarm",
        "bandEnergyAlarm": "Band energy alarm",
        "yes": "Yes",
        "no": "No",
        "alarmLevel": "Alarm level",
        "signal": "Signal",
        "role": "Role",
        "user": "User",
        "workgroup": "Workgroup",
        "path": "Path",
        "instrument": "Instrument",
        "collectionInstrument": "Collection instrument",
        "method": "Method",
        "start": "Start",
        "stop": "Stop",
        "close": 'Close',
        "open": 'Open',
        "fault": "Fault",
        "deleteConfirm": "Delete confirm",
        "title": "Title",
        "caculate": "Caculate",
        "settingConfig": "Setting config",
        "emailConfig": "Email Config",
        "msgConfig": "Message Config",
        "applyAll": "Apply all",
        "on": "ON",
        "off": "OFF",
        "MondayShort": "Mon",
        "TuesdayShort": "Tues",
        "WednesdayShort": "Wed",
        "ThursdayShort": "Thur",
        "FridayShort": "Fri",
        "SaturdayShort": "Sat",
        "SundayShort": "Sun",
        "oneKey": "One-key ",
        "block": "Block",
        "switchMode": "Switch mode",
        "positiveInteger": "Positive integer",
        "naturalInteger": "Natural integer",
        "points": "Point number",
        "viewChannel": "View channel",
        "mustUpdateExcel": "Upload file must be Excel",
        "all": "All",
        "eigenType": "Eigen type",
        "eigenTypePlaceholder": "Please select the eigen type",
        "enableEigen": "Enable eigen",
        "enableAlarm": "Enable alarm",
        "connect": "Connect",
        "disconnect": "Disconnect",
        "deleteConfig": "Clear",
        "exportConfig": "Export configuration",
        "pointFilter": "Filter points",
        "pointList": "Point list",
        "pointPath": "Point path",
        "configList": "Configuration list",
        "externalCode": "External code",
        "eigen": "Eigen",
        "alarm": "Alarm",
        "port": "Port",
        "portPlaceholder": "Please input the port",
        "slaveId": "Salve ID",
        "slaveIdPlaceholder": "Please input the salve's ID",
        "sensor": "Sensor",
        "signalType": "Signal Type",
        "observerCategory": "Observer Category",
        "observerItem": "Observer Item",
        "taskRecord": "Task Record",
        "holiday": "Vacation",
        "note": "Note",
        "notePlaceholder": "Please input the note",
        "isInternal": "Is it internal",
        "isRemovable": "Is it removable",
        "isEnabled": "Is it enable",
        "vendor": "Vendor",
        "model": "Model",
        "hour": "Hour",
        "minute": "Minute",
        "second": "Second",
        "online": "Online",
        "offline": "Offline",
        "connected": "Connected",
        "disconnected": "Disconnected",
        "unit": "Unit",
        "department": "Department",
        "isVisible": "Is it visible",
        "baseInfo": "Base Information",
        "deviceTree": "Device tree",
        "platformSetting": "Platform settings",
        "authorizationSetting": "Authorization settings",
        "browse": "Browse",
        "departmentManagement": "Department Management",
        "export": "Export",
        "import": "Import",
        "use": "Use",
        "faultSetting": "Fault settings",
        "pointSetting": "Point settings",
        "userManagement": "User Manangement",
        "setting": "Setting",
        "status": "Status",
        "enabled": "Enabled",
        "disable": "Disable",
        "disabled": "Disabled",
        "onekeyReuse": "One-key reuse",
        "onekeyEnable": "One-key enable",
        "onekeyDisable": "One-key disable",
        "useRole": "Belongs to role",
        "account": "Account",
        "oldPassword": "Old password",
        "newPassword": "New password",
        "password": "Password",
        "confirmPassword": "Confirm password",
        "underWorkGroup": "Belongs to work group",
        "underUnit": "Belongs to unit",
        "underDepartment": "Belongs to department",
        "passwordRequired": "Please enter the password",
        "passwordRule": "Chinese character is illegal in password; the length should be 6 - 20",
        "strongPasswordRule": "The password must contain at least 8 characters and contain uppercase letters, lowercase letters, digits, and special characters",
        "twoPasswordNotSame": "Password is not the same",
        "accountRule": "An account only contains alphabet, number or underline; the length should be 2 - 18",
        "baseInfoRequired": "Please finish each essential part in base info",
        "copyrightInfo": "Copyright Information",
        "version": "Version",
        "lisenceVersion": "Lisence version",
        "lisenceDuration": "Lisence duration",
        "unlimited": "Unlimited",
        "themeSetting": "Theme Settings",
        "dark": "Dark",
        "light": "Light",
        "apply": "Apply",
        "ipAddress": "IP address",
        "ipAddressPlaceholder": "Please input the IP address",
        "noEditAuth": "No edit authorization yet",
        "onekeyIncrement": "One-key increment",
        "yes": "Yes",
        "no": "No",
        "batchAdd": "Batch Add",
        "noRecord": "There are currently no records available",
        "samplingFrequency": "Sampling Frequency",
        "signalTypeTooltip": "Please select signal type",
        "numberTip": "Please enter a number",
        "version": "Version",
        "abnormal": "Abnormal",
        "patrolPlanTip": 'Please select an inspection schedule',
        "greaterThan": 'Greater than',
        "equal": 'equal',
        "lessThan": 'Less than',
        "none": 'none',
        "batch": 'batch',
        "chinese": 'chinese',
        "code": 'code'
    },
    important: {
        setting: 'Setting',
        config: 'Configuration',
        faultSetting: 'Fault Configuration',
        pointSetting: 'Point Configuration',
        platformSetting: 'Platform Configuration',
        treeSetting: 'Device Tree Configuration',
        tree: 'Device Tree',
        browseText: 'Browse（Search）',
        use: 'Use',
        browse: 'Search',
        export: 'Export',
        permissions: 'Auth Setting',
        departManage: 'Department Management',
        sampleError: 'Driver Error: Instrument is sampling'
    }
}

export default CommonEN
