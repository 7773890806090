// @generated by protobuf-ts 2.9.1
// @generated from protobuf file "protos/fault.proto" (package "device", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { GrpcFault } from "./fault";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { Reply } from "./reply";
import type { GetTypicalDataRequest } from "./typicaldata";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * The greeting service definition.
 *
 * @generated from protobuf service device.GrpcFault
 */
export interface IGrpcFaultClient {
    /**
     * Sends a greeting
     *
     * @generated from protobuf rpc: GetTypicalData(device.GetTypicalDataRequest) returns (storage.Reply);
     */
    getTypicalData(input: GetTypicalDataRequest, options?: RpcOptions): UnaryCall<GetTypicalDataRequest, Reply>;
}
/**
 * The greeting service definition.
 *
 * @generated from protobuf service device.GrpcFault
 */
export class GrpcFaultClient implements IGrpcFaultClient, ServiceInfo {
    typeName = GrpcFault.typeName;
    methods = GrpcFault.methods;
    options = GrpcFault.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * Sends a greeting
     *
     * @generated from protobuf rpc: GetTypicalData(device.GetTypicalDataRequest) returns (storage.Reply);
     */
    getTypicalData(input: GetTypicalDataRequest, options?: RpcOptions): UnaryCall<GetTypicalDataRequest, Reply> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetTypicalDataRequest, Reply>("unary", this._transport, method, opt, input);
    }
}
