<template>
	<div class="content" v-if="state.hasPemisson">
		<div class="nav" v-show="!shrink && store.state.uiMode === UiModeEnum.EditStatus">
			<el-tabs v-model="state.activeName" @tab-click="handleTabClick" type="card"
				@keydown.native.capture.stop.prevent>
				<el-tab-pane v-for="item in state.tab" :name="item.name">
					<template #label>
						<svgIcon :hover="false" :name="item.params.icon"></svgIcon>
						<div class="tabContent">{{ item.name }}</div>
					</template>
				</el-tab-pane>
			</el-tabs>
			<div class="btnGroups">
				<div v-for="(item, index) in state.btnGroups" class="btnItem">
					<el-button type="primary" link :ref="el => btnRef[index] = el"
						@click="handleBtnClick(item.handler, item.type, index)"> {{ item.name }}</el-button>
				</div>
			</div>
		</div>

		<el-dialog v-model="state.dialog" :title="$t('mainLayout.mainContent.importFualtLib')" width="900px" top="5vh"
			@close="state.form.keyWords = ''" class="faultDialog" @open="searchLibrary">
			<el-form ref="formRef" :inline="true" class="flex platSearchForm" @submit.native.prevent>
				<el-form-item>
					<el-input v-model="state.form.keyWords" :placeholder="$t('common.searchContentPlaceholder')"
						class="searchInput" clearable @keyup.enter.prevent="searchLibrary"> </el-input>
					<el-button type="primary" @click="searchLibrary">{{ $t('common.query') }}</el-button>
				</el-form-item>
			</el-form>
			<vxe-table border ref="xTableRef" height="300" :data="state.tableData" :radio-config="{ highlight: false }"
				:empty-text="$t('common.noContent')" show-overflow>
				<vxe-column type="radio" width="60"></vxe-column>
				<vxe-column field="Vendor" :title="$t('mainLayout.mainContent.manufacturer')"></vxe-column>
				<vxe-column field="Model" :title="$t('mainLayout.mainContent.equipmentModel')"></vxe-column>
				<vxe-column field="DeviceType" :title="$t('mainLayout.mainContent.equipmentType')"></vxe-column>
				<vxe-column field="FaultDescription" :title="$t('mainLayout.mainContent.faultDescription')"></vxe-column>
			</vxe-table>
			<template #footer>
				<span class="dialog-footer">
					<el-button type="primary" @click="importLibrary">{{ $t('common.ok') }}</el-button>
					<el-button @click="state.dialog = false">{{ $t('common.cancel') }}</el-button>
				</span>
			</template>
		</el-dialog>

		<report :show-dialog="state.showExport" @close="onReportClose"></report>
		<div class="view" :style="{ padding: shrink ? '0px' : '10px' }">
			<router-view></router-view>
		</div>
	</div>
	<div class="noPemission" v-else>
		<svgIcon width="460px" height="500px" name="401" :allowColor="false"></svgIcon>
		<div class="right">
			<div class="num">401</div>
			<div class="msg">{{ $t('mainLayout.mainContent.noPermission') }}!</div>
			<div class="detail">{{ $t('mainLayout.mainContent.noPermissionTip') }}</div>
		</div>
	</div>
</template>

<script setup>
import { computed, reactive, ref, onMounted, watch, onBeforeUnmount, nextTick } from "vue";
import axios from "@common/js/axios";
import { useRoute, useRouter } from "vue-router";
import svgIcon from "@common/components/svgIcon.vue";
import { alertConfirm, alertErrorMsg, alertSuccessMsg, fullScreenLoading, i18nGlobal, checkHasAllPermission } from "@common/js/utils";
import { MachineTreeNodeTypeEnum, UiModeEnum } from "@common/js/enum";
import { useStore } from "vuex";
import report from "./analystReport/base/reportBase.vue";
import { axiosAuth } from "@common/js/auth";

const xTableRef = ref(null);
const btnRef = ref([]);
const store = useStore();
const emit = defineEmits(["treeChange"]);
const router = useRouter();
const currentDep = computed(() => store.state.currentDep);
const shrink = computed(() => store.state.shrink);

const formRef = ref(null);
const route = useRoute();
const state = reactive({
	tab: [],
	activeName: i18nGlobal('mainLayout.mainContent.summary'),
	btnGroups: [],
	dialog: false,
	showExport: false,
	hasPemisson: true,
	form: {
		keyWords: "",
	},
	selectRow: null,
	tableData: [],
});
const topTabs = [
	{
		name: i18nGlobal('mainLayout.mainContent.summary'),
		route: "/summary",
		params: {
			icon: "summary",
		},
		auth: ["Summarize_Browse", "ImgDisplayNode"],
	},
	{
		name: i18nGlobal('mainLayout.mainContent.statusDisplay'),
		route: "/depState",
		params: {
			icon: "status",
		},
		auth: "State_Browse",
	},
	{
		name: i18nGlobal('mainLayout.mainContent.fileLib'),
		route: window.Config.project.dungouji ? "/fileLibraryVertical" : "/fileLibrary",
		params: {
			icon: "knowlege",
		},
		auth: "Doc_Browse",
	},
	{
		name: i18nGlobal('mainLayout.mainContent.pointSetting'),
		route: "/pointSetting",
		params: {
			icon: "pointsetting",
		},
	},
	{
		name: i18nGlobal('mainLayout.mainContent.pointData'),
		route: "/pointData",
		params: {
			icon: "pointdata",
		},
		// auth: 'PointDataNode'
		auth: "PointData_Browse",
	},
	{
		name: i18nGlobal('mainLayout.mainContent.workStatus'),
		route: "/faultSetting",
		params: {
			icon: "faultsetting",
		},
		auth: "Fault_Browse",
	},
	{
		name: i18nGlobal('mainLayout.mainContent.spotInspection'),
		route: "/patrol/task",
		params: {
			icon: "partolplan",
		},
		auth: "Patrol_Browse",
	},
];

const handleTabClick = (data) => {
	const { props } = data;
	const path = state.tab.find((item) => item.name === props.name).route;
	router.push({ path: path });
};

const gotoPath = (path) => {
	state.activeName = state.tab.find((item) => item.route === path).name;
	router.push({ path: path });
};

// tab导航栏改变显示
const changeTab = (NodeType) => {
	let tab = [];
	switch (NodeType) {
		case MachineTreeNodeTypeEnum.Area:
			tab = [topTabs[0], topTabs[1], topTabs[2], topTabs[6]];
			break;
		case MachineTreeNodeTypeEnum.Depart:
			tab = [topTabs[0], topTabs[1], topTabs[2], topTabs[6]];
			break;
		case MachineTreeNodeTypeEnum.Device:
			tab = [topTabs[0], topTabs[3], topTabs[4], topTabs[5]];
			break;
		case MachineTreeNodeTypeEnum.Scene:
			tab = [topTabs[0], topTabs[3], topTabs[4], topTabs[5]];
			break;
		default:
			tab = [];
			break;
	}
	const auth = store.state.userAuth.ResourceAuth;
	if (!auth.includes("Patrol_Point_Browse") && !auth.includes("Point_Browse")) {
		const index = tab.findIndex((item) => item.name === i18nGlobal('mainLayout.mainContent.pointSetting'));
		if (index >= 0) {
			tab.splice(index, 1);
		}
	}
	state.tab = tab.filter((item) => {
		if(!item.auth) return true;
		if(Array.isArray(item.auth)) {
			return item.auth.some(c => auth.includes(c));
		} else {
			return auth.includes(item.auth)
		}
	});
};

// 右侧按钮改变显示
const changeBtns = (NodeType, AreaType) => {
	const btns = [
		{
			name: i18nGlobal('mainLayout.mainContent.addUnit'),
			type: "unit",
			active: false,
			handler: "add",
		},
		{
			name: i18nGlobal('mainLayout.mainContent.addDepart'),
			type: "depart",
			active: false,
			handler: "add",
		},
		{
			name: i18nGlobal('mainLayout.mainContent.addDevice'),
			type: "device",
			active: false,
			handler: "add",
		},
		{
			name: i18nGlobal('mainLayout.mainContent.addScence'),
			type: "scence",
			active: false,
			handler: "add",
		},
		{
			name: i18nGlobal('common.delete'),
			type: "delete",
			active: false,
			handler: "remove",
		},
		{
			name: i18nGlobal('mainLayout.mainContent.importLibrary'),
			type: "import",
			active: false,
			handler: "importLibrary",
		},
		{
			name: i18nGlobal('mainLayout.mainContent.saveFaultLib'),
			type: "synchro",
			active: false,
			handler: "saveLibrary",
		},
		{
			name: i18nGlobal('mainLayout.mainContent.exportReport'),
			type: "export",
			active: false,
			handler: "exportReport",
		},
	];
	let showBtns = [];
	switch (NodeType) {
		case MachineTreeNodeTypeEnum.Area:
			showBtns = [btns[0], btns[1], btns[2], btns[3], btns[7], btns[4]];
			break;
		case MachineTreeNodeTypeEnum.Depart:
			showBtns = [btns[0], btns[1], btns[2], btns[3], btns[7], btns[4]];
			break;
		case MachineTreeNodeTypeEnum.Device:
			showBtns = [btns[5], btns[6], btns[4]];
			break;
		case MachineTreeNodeTypeEnum.Scene:
			showBtns = [btns[4]];
			break;
		default:
			showBtns = [btns[0]];
			break;
	}
	state.btnGroups = setBtnAuth(showBtns);
};

const setBtnAuth = (btns) => {
	const ResourceAuth = store.state.userAuth.ResourceAuth;
	// 筛选出添加
	const deleteAdd = () => {
		btns = btns.filter((item) => item.handler !== "add");
	};

	// 筛选出删除
	const deleteRemove = () => {
		const index = btns.findIndex((item) => item.handler === "remove");
		if (index >= 0) btns.splice(index, 1);
	};

	// 筛选出编辑
	const deleteEdit = () => {
		btns = btns.filter((item) => item.handler !== "importLibrary" && item.handler !== "saveLibrary" && item.handler !== "exportReport");
	};

	if (!ResourceAuth.includes("Add")) {
		deleteAdd();
	}
	if (!ResourceAuth.includes("Delete")) {
		deleteRemove();
	}
	if (!ResourceAuth.includes("Edit")) {
		deleteEdit();
	}

	if (!ResourceAuth.includes("FaultLib_Import")) {
		btns = btns.filter((item) => item.handler !== "importLibrary");
	}

	if (!ResourceAuth.includes("FaultLib_Save")) {
		btns = btns.filter((item) => item.handler !== "saveLibrary");
	}
	if (!ResourceAuth.includes("Export")) {
		btns = btns.filter((item) => item.handler !== "exportReport");
	}
	// 能找到Id则按对应权限，否则根据Path依次向上遍历
	btns = setTreeNodePermission(btns);
	return btns;
};

const setTreeNodePermission = (btns) => {
	const MachineTreeAuth = store.state.userAuth.MachineTreeAuth;
	const { Path } = currentDep.value;

	const deleteAdd = () => {
		btns = btns.filter((item) => item.handler !== "add");
	};

	// 筛选出删除
	const deleteRemove = () => {
		const index = btns.findIndex((item) => item.handler === "remove");
		if (index >= 0) btns.splice(index, 1);
	};

	const deleteFaults = () => {
		btns = btns.filter((item) => item.handler !== "importLibrary");
		btns = btns.filter((item) => item.handler !== "saveLibrary");
	};

	const deleteReport = () => {
		btns = btns.filter((item) => item.handler !== "exportReport");
	};

	const hasAllPermission = checkHasAllPermission(MachineTreeAuth);
	if (hasAllPermission) {
		state.hasPemisson = true;
		return btns;
	}
	if (MachineTreeAuth[Path]) {
		if (!MachineTreeAuth[Path].Remove) {
			deleteRemove();
		}

		if (!MachineTreeAuth[Path].Add) {
			deleteAdd();
		}
		if (!MachineTreeAuth[Path].Edit) {
			deleteFaults();
			deleteReport();
		}
		state.hasPemisson = Boolean(MachineTreeAuth[Path].Browse);
	} else {
		deleteAdd();
		deleteRemove();
		deleteFaults();
		deleteReport();
		state.hasPemisson = false;
	}

	// 如果没有总公司权限，则不能添加单位
	// 如果有总公司权限，但没有添加权限，也不能添加单位
	const ParentCompanyPath = '0001';
	if(MachineTreeAuth[ParentCompanyPath]) {
		if(!MachineTreeAuth[ParentCompanyPath].Add) {
			btns = btns.filter((item) => item.type !== "unit");
		}
	} else {
		btns = btns.filter((item) => item.type !== "unit");
	}
	return btns;
};

// 保存故障库
const saveLibrary = (id) => {
	axios({
		method: "post",
		url: "FaultLibary/add",
		data: {
			DeviceId: id,
		},
	}).then((res) => {
		if (res.IsSuccess) {
			alertSuccessMsg(i18nGlobal('mainLayout.mainContent.syncSuccess'));
		}
	});
};

// 搜索故障库
const searchLibrary = async () => {
	const res = await axiosAuth({
		method: "get",
		url: `FaultLibary/get?keyword=${state.form.keyWords}`,
		auth: "FaultLib_Browse"
	});
	if (res.IsSuccess) {
		state.tableData = res.Result;
		const data = xTableRef.value.getRadioRecord();
		if (data == null && state.tableData.length > 0) {
			xTableRef.value.setRadioRow(state.tableData[0]);
		}
	}
};

const onReportClose = () => {
	state.showExport = false;
};

const importLibrary = async () => {
	const data = xTableRef.value.getRadioRecord();
	if (data == null) {
		alertErrorMsg(i18nGlobal('mainLayout.mainContent.chooseFaultLibToolTip'));
		return;
	}

	const loading = fullScreenLoading();
	const res = await axios({
		method: "post",
		url: `FaultLibary/import`,
		data: {
			FaultLibaryId: data.Id,
			DeviceId: currentDep.value.Id,
		},
	});
	loading.close();
	state.dialog = false;
	if (res.IsSuccess && res.Result == true) {
		for (let handler of window.onRefresh.values()) {
			handler();
		}
	}
};

const handleBtnClick = (hanlder, orgType, index) => {
	switch (hanlder) {
		case "saveLibrary":
			alertConfirm(() => saveLibrary(currentDep.value.Id), { msg: i18nGlobal('mainLayout.mainContent.syncFaultLibToolTip') });
			break;
		case "importLibrary":
			state.dialog = true;
			break;
		case "exportReport":
			state.showExport = true;
			break;
		default:
			emit("treeChange", {
				hanlder,
				orgType,
			});
			break;
	}
	nextTick(() => {
		btnRef.value[index].ref.blur()
	})
};

const turnToPage = () => {
	state.activeName = i18nGlobal('mainLayout.mainContent.summary');
};

onMounted(() => {
	if (window.customPath == true) {
		window.customPath = false;
	} else {
		const indexPage = Config.indexPage || "summary";
		const path = `/${indexPage}`;
		const hasAuth = state.tab.find((r) => r.route === path);
		if (hasAuth) {
			router.push({ path: path });
		}
	}
	if (store.state.isShrink == true) {
		store.commit("setShrink", { shrink: Config.isShrink || false });
	}
});

const depWatch = watch(
	() => currentDep.value,
	(newDep, oldDep) => {
		if (newDep && newDep.Id) {
			const { NodeType } = newDep;
			// 不同单位设备切换相同页面
			if (NodeType) {
				if (route.path === "/summary") {
					changeBtns(NodeType);
				} else {
					state.btnGroups = [];
					setTreeNodePermission([]);
				}
				const arr = [MachineTreeNodeTypeEnum.Area, MachineTreeNodeTypeEnum.Depart];
				if (oldDep && oldDep.NodeType === NodeType) {
					return;
				} else if (oldDep && arr.includes(oldDep.NodeType) && arr.includes(NodeType)) {
					return;
				}
			}
			changeTab(NodeType);
			if (state.tab.length > 0) {
				const activeName = state.tab.find((item) => item.route === route.path)?.name;
				if (activeName) {
					state.activeName = state.tab.find((item) => item.route === route.path)?.name;
					router.push({ path: `${route.path}` });
				} else {
					state.activeName = state.tab[0].name;
					router.push({ path: `${state.tab[0].route}` });
				}
			} else {
				//state.hasPemisson = false;
				router.push({ path: "/summary" });
			}
		}
	},
	{ immediate: true, deep: true }
);

const activeWatch = watch(
	() => state.activeName,
	(newVal, oldVal) => {
		if (newVal === i18nGlobal('mainLayout.mainContent.summary')) {
			if (currentDep.value.Id) {
				changeBtns(currentDep.value.NodeType, currentDep.value.srcdata?.AreaType);
			}
		} else {
			state.btnGroups = [];
		}
	},
	{ immediate: true }
);

const pathWatch = watch(
	() => route.path,
	(path, oldVal) => {
		const activeTab = topTabs.find((item) => item.route === path);
		if (activeTab) {
			state.activeName = activeTab.name;
		}
	}
);

onBeforeUnmount(() => {
	depWatch();
	activeWatch();
	pathWatch();
});

defineExpose({
	turnToPage,
	gotoPath,
});
</script>

<style scoped lang="less">
@import "@styles/variable.less";

.noPemission {
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	.right {
		height: 500px;
		margin-left: 20px;
		display: flex;
		flex-direction: column;
		justify-content: center;

		.num {
			font-size: 100px;
			color: #333;
			font-weight: bold;
		}

		.msg {
			font-size: 36px;
			color: #333;
			font-weight: bold;
			margin-bottom: 10px;
		}

		.detail {
			font-size: 20px;
			color: #666;
			margin-bottom: 20px;
		}
	}
}

.content {
	height: 100%;
	display: flex;
	flex-direction: column;

	.nav {
		// min-width: 1000px;
		background-color: var(--phm-theme-card);
		display: flex;
		justify-content: space-between;
		padding: 10px 0 0 15px;
		height: 50px;
		border-bottom: 1px solid var(--phm-theme-border);

		.el-tabs {
			max-width: 1000px;
			user-select: none;

			svg {
				vertical-align: sub;
			}

			:deep(.el-tabs__header) {
				margin-bottom: 0 !important;
			}

			:deep(.el-tabs__nav) {
				border: none;

				.el-tabs__item {
					border: 1px solid transparent;

					use {
						fill: var(--phm-theme-mainText);
					}

					.tabContent {
						color: var(--phm-theme-mainText);
					}

					&:hover {
						.tabContent {
							color: var(--phm-primary-color);
						}

						use {
							fill: var(--phm-primary-color);
						}
					}

					&.is-active {
						background-color: var(--phm-theme-background);
						border: 1px solid var(--phm-theme-border);
						border-bottom: none;
						border-top-left-radius: 6px;
						border-top-right-radius: 6px;

						use {
							fill: var(--phm-primary-color);
						}

						.tabContent {
							color: var(--phm-primary-color);
						}
					}
				}
			}

			.tabContent {
				display: inline-block;
				height: 100%;
				margin-left: 10px;
			}
		}

		.btnGroups {
			display: flex;
			align-items: center;
			padding-right: 15px;

			.btnItem {
				display: flex;
				align-items: center;
				margin-right: 10px;
			}

			.btn {
				font-size: 14px;
				margin-right: 20px;
				cursor: pointer;
				line-height: 2.8;

				&:hover {
					color: var(--phm-primary-color);

					use {
						fill: var(--phm-primary-color);
					}
				}

				svg {
					vertical-align: sub;
				}
			}
		}
	}

	.view {
		padding: 10px;
		box-sizing: border-box;
		flex: 1;
		overflow: auto;
	}
}

.faultDialog {
	.el-form {
		margin-top: 10px;

		.el-form-item {
			margin-right: 0;
			width: 100%;

			:deep(.el-form-item__content) {
				display: flex;
				width: 100%;

				.el-input {
					flex: 1;
				}
			}

		}
	}
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}

:deep(.el-dropdown) {
	.el-dropdown-link {
		color: var(--phm-primary-color);
		margin: 0 2px;
		cursor: pointer;

		&:hover {
			color: var(--phm-light-6);
		}
	}
}
</style>
