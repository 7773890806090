// @generated by protobuf-ts 2.9.1
// @generated from protobuf file "protos/basereply.proto" (package "storage", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { DoubleValue } from "../google/protobuf/wrappers";
import { Timestamp } from "../google/protobuf/timestamp";
/**
 * @generated from protobuf message storage.EigenInfoReply
 */
export interface EigenInfoReply {
    /**
     * @generated from protobuf field: int32 Key = 1 [json_name = "Key"];
     */
    key: number;
    /**
     * @generated from protobuf field: string Value = 2 [json_name = "Value"];
     */
    value: string;
    /**
     * @generated from protobuf field: string Unit = 3 [json_name = "Unit"];
     */
    unit: string;
}
/**
 * @generated from protobuf message storage.DataParamReply
 */
export interface DataParamReply {
    /**
     * @generated from protobuf field: string Name = 1 [json_name = "Name"];
     */
    name: string;
    /**
     * @generated from protobuf field: double FullValue = 2 [json_name = "FullValue"];
     */
    fullValue: number;
    /**
     * @generated from protobuf field: string Unit = 3 [json_name = "Unit"];
     */
    unit: string;
    /**
     * @generated from protobuf field: bool IsObserver = 4 [json_name = "IsObserver"];
     */
    isObserver: boolean;
    /**
     * @generated from protobuf field: string Extensions = 5 [json_name = "Extensions"];
     */
    extensions: string;
    /**
     * @generated from protobuf field: storage.AlarmRulerReply AlarmRuler = 6 [json_name = "AlarmRuler"];
     */
    alarmRuler?: AlarmRulerReply;
}
/**
 * @generated from protobuf message storage.AlarmRulerReply
 */
export interface AlarmRulerReply {
    /**
     * @generated from protobuf field: string Id = 1 [json_name = "Id"];
     */
    id: string;
    /**
     * @generated from protobuf field: bool HasSendToDc = 2 [json_name = "HasSendToDc"];
     */
    hasSendToDc: boolean;
    /**
     * @generated from protobuf field: bool IsHistory = 3 [json_name = "IsHistory"];
     */
    isHistory: boolean;
    /**
     * @generated from protobuf field: int32 EntityId = 4 [json_name = "EntityId"];
     */
    entityId: number;
    /**
     * @generated from protobuf field: int32 Type = 5 [json_name = "Type"];
     */
    type: number;
    /**
     * @generated from protobuf oneof: Inner
     */
    inner: {
        oneofKind: "amplitudeAlarmRuler";
        /**
         * @generated from protobuf field: storage.AmplitudeAlarmRulerReply AmplitudeAlarmRuler = 6 [json_name = "AmplitudeAlarmRuler"];
         */
        amplitudeAlarmRuler: AmplitudeAlarmRulerReply;
    } | {
        oneofKind: "bandEnergyAlarmRuler";
        /**
         * @generated from protobuf field: storage.BandEnergyAlarmRulerReply BandEnergyAlarmRuler = 7 [json_name = "BandEnergyAlarmRuler"];
         */
        bandEnergyAlarmRuler: BandEnergyAlarmRulerReply;
    } | {
        oneofKind: "dispersedAlarmRuler";
        /**
         * @generated from protobuf field: storage.DispersedAlarmRulerReply DispersedAlarmRuler = 8 [json_name = "DispersedAlarmRuler"];
         */
        dispersedAlarmRuler: DispersedAlarmRulerReply;
    } | {
        oneofKind: "mutationAlarmRuler";
        /**
         * @generated from protobuf field: storage.MutationAlarmRulerReply MutationAlarmRuler = 9 [json_name = "MutationAlarmRuler"];
         */
        mutationAlarmRuler: MutationAlarmRulerReply;
    } | {
        oneofKind: "selfLearningAlarmRuler";
        /**
         * @generated from protobuf field: storage.SelfLearningAlarmRulerReply SelfLearningAlarmRuler = 10 [json_name = "SelfLearningAlarmRuler"];
         */
        selfLearningAlarmRuler: SelfLearningAlarmRulerReply;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message storage.AmplitudeAlarmRulerReply
 */
export interface AmplitudeAlarmRulerReply {
    /**
     * @generated from protobuf field: int32 EigenType = 1 [json_name = "EigenType"];
     */
    eigenType: number;
    /**
     * @generated from protobuf field: int32 AmplitudeAlarmType = 2 [json_name = "AmplitudeAlarmType"];
     */
    amplitudeAlarmType: number;
    /**
     * @generated from protobuf field: int32 Flag = 3 [json_name = "Flag"];
     */
    flag: number;
    /**
     * @generated from protobuf field: double Warning1 = 4 [json_name = "Warning1"];
     */
    warning1: number;
    /**
     * @generated from protobuf field: double Warning2 = 5 [json_name = "Warning2"];
     */
    warning2: number;
    /**
     * @generated from protobuf field: double Danger1 = 6 [json_name = "Danger1"];
     */
    danger1: number;
    /**
     * @generated from protobuf field: double Danger2 = 7 [json_name = "Danger2"];
     */
    danger2: number;
}
/**
 * @generated from protobuf message storage.BandEnergyAlarmRulerReply
 */
export interface BandEnergyAlarmRulerReply {
    /**
     * @generated from protobuf field: string Details = 1 [json_name = "Details"];
     */
    details: string;
    /**
     * @generated from protobuf field: int32 OperateType = 2 [json_name = "OperateType"];
     */
    operateType: number;
    /**
     * @generated from protobuf field: int32 ParamType = 3 [json_name = "ParamType"];
     */
    paramType: number;
    /**
     * @generated from protobuf field: string SpectrumParam = 4 [json_name = "SpectrumParam"];
     */
    spectrumParam: string;
}
/**
 * @generated from protobuf message storage.DispersedAlarmRulerReply
 */
export interface DispersedAlarmRulerReply {
    /**
     * @generated from protobuf field: string CategoryName = 1 [json_name = "CategoryName"];
     */
    categoryName: string;
    /**
     * @generated from protobuf field: string DispersedItems = 2 [json_name = "DispersedItems"];
     */
    dispersedItems: string;
}
/**
 * @generated from protobuf message storage.MutationAlarmRulerReply
 */
export interface MutationAlarmRulerReply {
    /**
     * @generated from protobuf field: int32 EigenType = 1 [json_name = "EigenType"];
     */
    eigenType: number;
    /**
     * @generated from protobuf field: int32 Flag = 2 [json_name = "Flag"];
     */
    flag: number;
    /**
     * @generated from protobuf field: double Alarm = 3 [json_name = "Alarm"];
     */
    alarm: number;
    /**
     * @generated from protobuf field: double Danger = 4 [json_name = "Danger"];
     */
    danger: number;
    /**
     * @generated from protobuf field: double MutationTrigger = 5 [json_name = "MutationTrigger"];
     */
    mutationTrigger: number;
}
/**
 * @generated from protobuf message storage.SelfLearningAlarmRulerReply
 */
export interface SelfLearningAlarmRulerReply {
    /**
     * @generated from protobuf field: string RelateId = 1 [json_name = "RelateId"];
     */
    relateId: string;
    /**
     * @generated from protobuf field: string StartTime = 2 [json_name = "StartTime"];
     */
    startTime: string;
    /**
     * @generated from protobuf field: string EndTime = 3 [json_name = "EndTime"];
     */
    endTime: string;
    /**
     * @generated from protobuf field: double Sigma = 4 [json_name = "Sigma"];
     */
    sigma: number;
    /**
     * @generated from protobuf field: double WeightLimit = 5 [json_name = "WeightLimit"];
     */
    weightLimit: number;
}
/**
 * @generated from protobuf message storage.CursorInfoReply
 */
export interface CursorInfoReply {
    /**
     * @generated from protobuf field: int32 BlockNumber = 1 [json_name = "BlockNumber"];
     */
    blockNumber: number;
    /**
     * @generated from protobuf field: int32 FirstNumber = 2 [json_name = "FirstNumber"];
     */
    firstNumber: number;
    /**
     * @generated from protobuf field: int32 LastNumber = 3 [json_name = "LastNumber"];
     */
    lastNumber: number;
    /**
     * @generated from protobuf field: int32 BlockSize = 4 [json_name = "BlockSize"];
     */
    blockSize: number;
}
/**
 * @generated from protobuf message storage.HistoryDataReply
 */
export interface HistoryDataReply {
    /**
     * @generated from protobuf field: int64 SampleTime = 1 [json_name = "SampleTime"];
     */
    sampleTime: bigint;
    /**
     * @generated from protobuf field: repeated storage.EigenDataReply Eigens = 2 [json_name = "Eigens"];
     */
    eigens: EigenDataReply[];
    /**
     * @generated from protobuf field: storage.DatasReply Datas = 3 [json_name = "Datas"];
     */
    datas?: DatasReply;
}
/**
 * @generated from protobuf message storage.EigenDataReply
 */
export interface EigenDataReply {
    /**
     * @generated from protobuf field: int32 EigenType = 1 [json_name = "EigenType"];
     */
    eigenType: number;
    /**
     * @generated from protobuf field: float Value = 2 [json_name = "Value"];
     */
    value: number;
}
/**
 * @generated from protobuf message storage.BatchPointWaveDataReply
 */
export interface BatchPointWaveDataReply {
    /**
     * @generated from protobuf field: int32 PointId = 1 [json_name = "PointId"];
     */
    pointId: number;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp SampleTime = 2 [json_name = "SampleTime"];
     */
    sampleTime?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.DoubleValue RotateSpeed = 3 [json_name = "RotateSpeed"];
     */
    rotateSpeed?: DoubleValue;
    /**
     * @generated from protobuf field: int32 WaveCount = 4 [json_name = "WaveCount"];
     */
    waveCount: number;
    /**
     * @generated from protobuf field: bytes WaveData = 5 [json_name = "WaveData"];
     */
    waveData: Uint8Array;
}
/**
 * @generated from protobuf message storage.DatasReply
 */
export interface DatasReply {
    /**
     * @generated from protobuf field: int32 DataCount = 1 [json_name = "DataCount"];
     */
    dataCount: number;
    /**
     * @generated from protobuf oneof: Inner
     */
    inner: {
        oneofKind: "yDatas";
        /**
         * @generated from protobuf field: storage.YDatasReply YDatas = 2 [json_name = "YDatas"];
         */
        yDatas: YDatasReply;
    } | {
        oneofKind: "barDatas";
        /**
         * @generated from protobuf field: storage.BarDatasReply BarDatas = 3 [json_name = "BarDatas"];
         */
        barDatas: BarDatasReply;
    } | {
        oneofKind: "trendDatas";
        /**
         * @generated from protobuf field: storage.TrendDatasReply TrendDatas = 4 [json_name = "TrendDatas"];
         */
        trendDatas: TrendDatasReply;
    } | {
        oneofKind: "rainFlowDatas";
        /**
         * @generated from protobuf field: storage.RainFlowDatasReply RainFlowDatas = 5 [json_name = "RainFlowDatas"];
         */
        rainFlowDatas: RainFlowDatasReply;
    } | {
        oneofKind: "xYDatas";
        /**
         * @generated from protobuf field: storage.XYDatasReply XYDatas = 6 [json_name = "XYDatas"];
         */
        xYDatas: XYDatasReply;
    } | {
        oneofKind: "extensionEigenDatas";
        /**
         * @generated from protobuf field: storage.ExtensionEigenDatasReply ExtensionEigenDatas = 7 [json_name = "ExtensionEigenDatas"];
         */
        extensionEigenDatas: ExtensionEigenDatasReply;
    } | {
        oneofKind: "diluteDatas";
        /**
         * @generated from protobuf field: storage.DiluteDatasReply DiluteDatas = 8 [json_name = "DiluteDatas"];
         */
        diluteDatas: DiluteDatasReply;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message storage.DiluteDatasReply
 */
export interface DiluteDatasReply {
    /**
     * @generated from protobuf field: repeated storage.DiluteDataReply Datas = 1 [json_name = "Datas"];
     */
    datas: DiluteDataReply[];
}
/**
 * @generated from protobuf message storage.DiluteDataReply
 */
export interface DiluteDataReply {
    /**
     * @generated from protobuf field: int64 Time = 1 [json_name = "Time"];
     */
    time: bigint;
    /**
     * @generated from protobuf field: float MinValue = 2 [json_name = "MinValue"];
     */
    minValue: number;
    /**
     * @generated from protobuf field: float MaxValue = 3 [json_name = "MaxValue"];
     */
    maxValue: number;
}
/**
 * @generated from protobuf message storage.YDatasReply
 */
export interface YDatasReply {
    /**
     * @generated from protobuf field: repeated float Datas = 1 [json_name = "Datas"];
     */
    datas: number[];
}
/**
 * @generated from protobuf message storage.BarDatasReply
 */
export interface BarDatasReply {
    /**
     * @generated from protobuf field: repeated storage.BarDataReply Datas = 1 [json_name = "Datas"];
     */
    datas: BarDataReply[];
}
/**
 * @generated from protobuf message storage.BarDataReply
 */
export interface BarDataReply {
    /**
     * @generated from protobuf field: float Start = 1 [json_name = "Start"];
     */
    start: number;
    /**
     * @generated from protobuf field: float End = 2 [json_name = "End"];
     */
    end: number;
    /**
     * @generated from protobuf field: float Value = 3 [json_name = "Value"];
     */
    value: number;
}
/**
 * @generated from protobuf message storage.TrendDatasReply
 */
export interface TrendDatasReply {
    /**
     * @generated from protobuf field: repeated storage.TrendDataReply Datas = 1 [json_name = "Datas"];
     */
    datas: TrendDataReply[];
    /**
     * @generated from protobuf field: int32 Index = 2 [json_name = "Index"];
     */
    index: number;
}
/**
 * @generated from protobuf message storage.TrendDataReply
 */
export interface TrendDataReply {
    /**
     * @generated from protobuf field: double X = 1 [json_name = "X"];
     */
    x: number;
    /**
     * @generated from protobuf field: float Y = 2 [json_name = "Y"];
     */
    y: number;
}
/**
 * @generated from protobuf message storage.RainFlowDatasReply
 */
export interface RainFlowDatasReply {
    /**
     * @generated from protobuf field: repeated storage.BarDataReply Means = 1 [json_name = "Means"];
     */
    means: BarDataReply[];
    /**
     * @generated from protobuf field: int32 RangeCount = 2 [json_name = "RangeCount"];
     */
    rangeCount: number;
    /**
     * @generated from protobuf field: repeated storage.BarDataReply Ranges = 3 [json_name = "Ranges"];
     */
    ranges: BarDataReply[];
    /**
     * @generated from protobuf field: repeated storage.TwoDimensionDataReply TwoDimension = 4 [json_name = "TwoDimension"];
     */
    twoDimension: TwoDimensionDataReply[];
}
/**
 * @generated from protobuf message storage.TwoDimensionDataReply
 */
export interface TwoDimensionDataReply {
    /**
     * @generated from protobuf field: storage.BarDataReply Mean = 1 [json_name = "Mean"];
     */
    mean?: BarDataReply;
    /**
     * @generated from protobuf field: storage.BarDataReply Range = 2 [json_name = "Range"];
     */
    range?: BarDataReply;
}
/**
 * @generated from protobuf message storage.XYDatasReply
 */
export interface XYDatasReply {
    /**
     * @generated from protobuf field: repeated float Datas = 1 [json_name = "Datas"];
     */
    datas: number[];
}
/**
 * @generated from protobuf message storage.ExtensionEigenDatasReply
 */
export interface ExtensionEigenDatasReply {
    /**
     * @generated from protobuf field: repeated storage.ExtensionEigenDataReply Datas = 1 [json_name = "Datas"];
     */
    datas: ExtensionEigenDataReply[];
}
/**
 * @generated from protobuf message storage.ExtensionEigenDataReply
 */
export interface ExtensionEigenDataReply {
    /**
     * @generated from protobuf field: int64 X = 1 [json_name = "X"];
     */
    x: bigint;
    /**
     * @generated from protobuf field: float Y = 2 [json_name = "Y"];
     */
    y: number;
}
/**
 * @generated from protobuf message storage.DspParamReply
 */
export interface DspParamReply {
    /**
     * @generated from protobuf oneof: Inner
     */
    inner: {
        oneofKind: "fFTDspParam";
        /**
         * @generated from protobuf field: storage.FFTDspParamReply FFTDspParam = 1 [json_name = "FFTDspParam"];
         */
        fFTDspParam: FFTDspParamReply;
    } | {
        oneofKind: "orderAnalysisDspParam";
        /**
         * @generated from protobuf field: storage.OrderAnalysisDspParamReply OrderAnalysisDspParam = 2 [json_name = "OrderAnalysisDspParam"];
         */
        orderAnalysisDspParam: OrderAnalysisDspParamReply;
    } | {
        oneofKind: "ampAnalysisDspParam";
        /**
         * @generated from protobuf field: storage.AmpAnalysisDspParamReply AmpAnalysisDspParam = 3 [json_name = "AmpAnalysisDspParam"];
         */
        ampAnalysisDspParam: AmpAnalysisDspParamReply;
    } | {
        oneofKind: "cableDspParam";
        /**
         * @generated from protobuf field: storage.CableDspParamReply CableDspParam = 4 [json_name = "CableDspParam"];
         */
        cableDspParam: CableDspParamReply;
    } | {
        oneofKind: "cepstrumDspParam";
        /**
         * @generated from protobuf field: storage.CepstrumDspParamReply CepstrumDspParam = 5 [json_name = "CepstrumDspParam"];
         */
        cepstrumDspParam: CepstrumDspParamReply;
    } | {
        oneofKind: "crossPowerSpecDensDspParam";
        /**
         * @generated from protobuf field: storage.CrossPowerSpecDensDspParamReply CrossPowerSpecDensDspParam = 6 [json_name = "CrossPowerSpecDensDspParam"];
         */
        crossPowerSpecDensDspParam: CrossPowerSpecDensDspParamReply;
    } | {
        oneofKind: "rainFlowDspParam";
        /**
         * @generated from protobuf field: storage.RainFlowDspParamReply RainFlowDspParam = 7 [json_name = "RainFlowDspParam"];
         */
        rainFlowDspParam: RainFlowDspParamReply;
    } | {
        oneofKind: "virtualComputeParam";
        /**
         * @generated from protobuf field: storage.VirtualComputeParamReply VirtualComputeParam = 8 [json_name = "VirtualComputeParam"];
         */
        virtualComputeParam: VirtualComputeParamReply;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message storage.FFTDspParamReply
 */
export interface FFTDspParamReply {
    /**
     * @generated from protobuf field: int32 AnalysisCount = 1 [json_name = "AnalysisCount"];
     */
    analysisCount: number;
    /**
     * @generated from protobuf field: int32 FreqUnit = 2 [json_name = "FreqUnit"];
     */
    freqUnit: number;
    /**
     * @generated from protobuf field: bool UseSpeed = 3 [json_name = "UseSpeed"];
     */
    useSpeed: boolean;
    /**
     * @generated from protobuf field: double RoteSpeed = 4 [json_name = "RoteSpeed"];
     */
    roteSpeed: number;
}
/**
 * @generated from protobuf message storage.OrderAnalysisDspParamReply
 */
export interface OrderAnalysisDspParamReply {
    /**
     * @generated from protobuf field: int32 AnalysisCount = 1 [json_name = "AnalysisCount"];
     */
    analysisCount: number;
    /**
     * @generated from protobuf field: int32 FreqUnit = 2 [json_name = "FreqUnit"];
     */
    freqUnit: number;
    /**
     * @generated from protobuf field: bool UseSpeed = 3 [json_name = "UseSpeed"];
     */
    useSpeed: boolean;
    /**
     * @generated from protobuf field: double RoteSpeed = 4 [json_name = "RoteSpeed"];
     */
    roteSpeed: number;
}
/**
 * @generated from protobuf message storage.AmpAnalysisDspParamReply
 */
export interface AmpAnalysisDspParamReply {
    /**
     * @generated from protobuf field: int32 AnalysisCount = 1 [json_name = "AnalysisCount"];
     */
    analysisCount: number;
}
/**
 * @generated from protobuf message storage.CableDspParamReply
 */
export interface CableDspParamReply {
    /**
     * @generated from protobuf field: int32 AnalysisCount = 1 [json_name = "AnalysisCount"];
     */
    analysisCount: number;
    /**
     * @generated from protobuf field: int32 FreqUnit = 2 [json_name = "FreqUnit"];
     */
    freqUnit: number;
    /**
     * @generated from protobuf field: bool UseSpeed = 3 [json_name = "UseSpeed"];
     */
    useSpeed: boolean;
    /**
     * @generated from protobuf field: double RoteSpeed = 4 [json_name = "RoteSpeed"];
     */
    roteSpeed: number;
}
/**
 * @generated from protobuf message storage.CepstrumDspParamReply
 */
export interface CepstrumDspParamReply {
    /**
     * @generated from protobuf field: int32 AnalysisCount = 1 [json_name = "AnalysisCount"];
     */
    analysisCount: number;
}
/**
 * @generated from protobuf message storage.CrossPowerSpecDensDspParamReply
 */
export interface CrossPowerSpecDensDspParamReply {
    /**
     * @generated from protobuf field: int32 AnalysisPointCount = 1 [json_name = "AnalysisPointCount"];
     */
    analysisPointCount: number;
}
/**
 * @generated from protobuf message storage.RainFlowDspParamReply
 */
export interface RainFlowDspParamReply {
    /**
     * @generated from protobuf field: int32 MeanRangeNum = 1 [json_name = "MeanRangeNum"];
     */
    meanRangeNum: number;
    /**
     * @generated from protobuf field: int32 AmpRangeNum = 2 [json_name = "AmpRangeNum"];
     */
    ampRangeNum: number;
}
/**
 * @generated from protobuf message storage.VirtualComputeParamReply
 */
export interface VirtualComputeParamReply {
    /**
     * @generated from protobuf field: int32 CalculateType = 1 [json_name = "CalculateType"];
     */
    calculateType: number;
    /**
     * @generated from protobuf field: int32 DomainType = 2 [json_name = "DomainType"];
     */
    domainType: number;
}
/**
 * @generated from protobuf message storage.PointParamReply
 */
export interface PointParamReply {
    /**
     * @generated from protobuf oneof: Inner
     */
    inner: {
        oneofKind: "cycleSamplePointParam";
        /**
         * @generated from protobuf field: storage.CycleSamplePointParamReply CycleSamplePointParam = 1 [json_name = "CycleSamplePointParam"];
         */
        cycleSamplePointParam: CycleSamplePointParamReply;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message storage.CycleSamplePointParamReply
 */
export interface CycleSamplePointParamReply {
    /**
     * @generated from protobuf field: float PointNumberPerCycle = 1 [json_name = "PointNumberPerCycle"];
     */
    pointNumberPerCycle: number;
    /**
     * @generated from protobuf field: float CycleNumber = 2 [json_name = "CycleNumber"];
     */
    cycleNumber: number;
}
// @generated message type with reflection information, may provide speed optimized methods
class EigenInfoReply$Type extends MessageType<EigenInfoReply> {
    constructor() {
        super("storage.EigenInfoReply", [
            { no: 1, name: "Key", kind: "scalar", jsonName: "Key", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "Value", kind: "scalar", jsonName: "Value", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "Unit", kind: "scalar", jsonName: "Unit", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<EigenInfoReply>): EigenInfoReply {
        const message = { key: 0, value: "", unit: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EigenInfoReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EigenInfoReply): EigenInfoReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 Key = 1 [json_name = "Key"];*/ 1:
                    message.key = reader.int32();
                    break;
                case /* string Value = 2 [json_name = "Value"];*/ 2:
                    message.value = reader.string();
                    break;
                case /* string Unit = 3 [json_name = "Unit"];*/ 3:
                    message.unit = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EigenInfoReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 Key = 1 [json_name = "Key"]; */
        if (message.key !== 0)
            writer.tag(1, WireType.Varint).int32(message.key);
        /* string Value = 2 [json_name = "Value"]; */
        if (message.value !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.value);
        /* string Unit = 3 [json_name = "Unit"]; */
        if (message.unit !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.unit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message storage.EigenInfoReply
 */
export const EigenInfoReply = new EigenInfoReply$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DataParamReply$Type extends MessageType<DataParamReply> {
    constructor() {
        super("storage.DataParamReply", [
            { no: 1, name: "Name", kind: "scalar", jsonName: "Name", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "FullValue", kind: "scalar", jsonName: "FullValue", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 3, name: "Unit", kind: "scalar", jsonName: "Unit", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "IsObserver", kind: "scalar", jsonName: "IsObserver", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "Extensions", kind: "scalar", jsonName: "Extensions", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "AlarmRuler", kind: "message", jsonName: "AlarmRuler", T: () => AlarmRulerReply }
        ]);
    }
    create(value?: PartialMessage<DataParamReply>): DataParamReply {
        const message = { name: "", fullValue: 0, unit: "", isObserver: false, extensions: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DataParamReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DataParamReply): DataParamReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string Name = 1 [json_name = "Name"];*/ 1:
                    message.name = reader.string();
                    break;
                case /* double FullValue = 2 [json_name = "FullValue"];*/ 2:
                    message.fullValue = reader.double();
                    break;
                case /* string Unit = 3 [json_name = "Unit"];*/ 3:
                    message.unit = reader.string();
                    break;
                case /* bool IsObserver = 4 [json_name = "IsObserver"];*/ 4:
                    message.isObserver = reader.bool();
                    break;
                case /* string Extensions = 5 [json_name = "Extensions"];*/ 5:
                    message.extensions = reader.string();
                    break;
                case /* storage.AlarmRulerReply AlarmRuler = 6 [json_name = "AlarmRuler"];*/ 6:
                    message.alarmRuler = AlarmRulerReply.internalBinaryRead(reader, reader.uint32(), options, message.alarmRuler);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DataParamReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string Name = 1 [json_name = "Name"]; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* double FullValue = 2 [json_name = "FullValue"]; */
        if (message.fullValue !== 0)
            writer.tag(2, WireType.Bit64).double(message.fullValue);
        /* string Unit = 3 [json_name = "Unit"]; */
        if (message.unit !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.unit);
        /* bool IsObserver = 4 [json_name = "IsObserver"]; */
        if (message.isObserver !== false)
            writer.tag(4, WireType.Varint).bool(message.isObserver);
        /* string Extensions = 5 [json_name = "Extensions"]; */
        if (message.extensions !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.extensions);
        /* storage.AlarmRulerReply AlarmRuler = 6 [json_name = "AlarmRuler"]; */
        if (message.alarmRuler)
            AlarmRulerReply.internalBinaryWrite(message.alarmRuler, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message storage.DataParamReply
 */
export const DataParamReply = new DataParamReply$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AlarmRulerReply$Type extends MessageType<AlarmRulerReply> {
    constructor() {
        super("storage.AlarmRulerReply", [
            { no: 1, name: "Id", kind: "scalar", jsonName: "Id", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "HasSendToDc", kind: "scalar", jsonName: "HasSendToDc", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "IsHistory", kind: "scalar", jsonName: "IsHistory", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "EntityId", kind: "scalar", jsonName: "EntityId", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "Type", kind: "scalar", jsonName: "Type", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "AmplitudeAlarmRuler", kind: "message", jsonName: "AmplitudeAlarmRuler", oneof: "inner", T: () => AmplitudeAlarmRulerReply },
            { no: 7, name: "BandEnergyAlarmRuler", kind: "message", jsonName: "BandEnergyAlarmRuler", oneof: "inner", T: () => BandEnergyAlarmRulerReply },
            { no: 8, name: "DispersedAlarmRuler", kind: "message", jsonName: "DispersedAlarmRuler", oneof: "inner", T: () => DispersedAlarmRulerReply },
            { no: 9, name: "MutationAlarmRuler", kind: "message", jsonName: "MutationAlarmRuler", oneof: "inner", T: () => MutationAlarmRulerReply },
            { no: 10, name: "SelfLearningAlarmRuler", kind: "message", jsonName: "SelfLearningAlarmRuler", oneof: "inner", T: () => SelfLearningAlarmRulerReply }
        ]);
    }
    create(value?: PartialMessage<AlarmRulerReply>): AlarmRulerReply {
        const message = { id: "", hasSendToDc: false, isHistory: false, entityId: 0, type: 0, inner: { oneofKind: undefined } };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AlarmRulerReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AlarmRulerReply): AlarmRulerReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string Id = 1 [json_name = "Id"];*/ 1:
                    message.id = reader.string();
                    break;
                case /* bool HasSendToDc = 2 [json_name = "HasSendToDc"];*/ 2:
                    message.hasSendToDc = reader.bool();
                    break;
                case /* bool IsHistory = 3 [json_name = "IsHistory"];*/ 3:
                    message.isHistory = reader.bool();
                    break;
                case /* int32 EntityId = 4 [json_name = "EntityId"];*/ 4:
                    message.entityId = reader.int32();
                    break;
                case /* int32 Type = 5 [json_name = "Type"];*/ 5:
                    message.type = reader.int32();
                    break;
                case /* storage.AmplitudeAlarmRulerReply AmplitudeAlarmRuler = 6 [json_name = "AmplitudeAlarmRuler"];*/ 6:
                    message.inner = {
                        oneofKind: "amplitudeAlarmRuler",
                        amplitudeAlarmRuler: AmplitudeAlarmRulerReply.internalBinaryRead(reader, reader.uint32(), options, (message.inner as any).amplitudeAlarmRuler)
                    };
                    break;
                case /* storage.BandEnergyAlarmRulerReply BandEnergyAlarmRuler = 7 [json_name = "BandEnergyAlarmRuler"];*/ 7:
                    message.inner = {
                        oneofKind: "bandEnergyAlarmRuler",
                        bandEnergyAlarmRuler: BandEnergyAlarmRulerReply.internalBinaryRead(reader, reader.uint32(), options, (message.inner as any).bandEnergyAlarmRuler)
                    };
                    break;
                case /* storage.DispersedAlarmRulerReply DispersedAlarmRuler = 8 [json_name = "DispersedAlarmRuler"];*/ 8:
                    message.inner = {
                        oneofKind: "dispersedAlarmRuler",
                        dispersedAlarmRuler: DispersedAlarmRulerReply.internalBinaryRead(reader, reader.uint32(), options, (message.inner as any).dispersedAlarmRuler)
                    };
                    break;
                case /* storage.MutationAlarmRulerReply MutationAlarmRuler = 9 [json_name = "MutationAlarmRuler"];*/ 9:
                    message.inner = {
                        oneofKind: "mutationAlarmRuler",
                        mutationAlarmRuler: MutationAlarmRulerReply.internalBinaryRead(reader, reader.uint32(), options, (message.inner as any).mutationAlarmRuler)
                    };
                    break;
                case /* storage.SelfLearningAlarmRulerReply SelfLearningAlarmRuler = 10 [json_name = "SelfLearningAlarmRuler"];*/ 10:
                    message.inner = {
                        oneofKind: "selfLearningAlarmRuler",
                        selfLearningAlarmRuler: SelfLearningAlarmRulerReply.internalBinaryRead(reader, reader.uint32(), options, (message.inner as any).selfLearningAlarmRuler)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AlarmRulerReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string Id = 1 [json_name = "Id"]; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* bool HasSendToDc = 2 [json_name = "HasSendToDc"]; */
        if (message.hasSendToDc !== false)
            writer.tag(2, WireType.Varint).bool(message.hasSendToDc);
        /* bool IsHistory = 3 [json_name = "IsHistory"]; */
        if (message.isHistory !== false)
            writer.tag(3, WireType.Varint).bool(message.isHistory);
        /* int32 EntityId = 4 [json_name = "EntityId"]; */
        if (message.entityId !== 0)
            writer.tag(4, WireType.Varint).int32(message.entityId);
        /* int32 Type = 5 [json_name = "Type"]; */
        if (message.type !== 0)
            writer.tag(5, WireType.Varint).int32(message.type);
        /* storage.AmplitudeAlarmRulerReply AmplitudeAlarmRuler = 6 [json_name = "AmplitudeAlarmRuler"]; */
        if (message.inner.oneofKind === "amplitudeAlarmRuler")
            AmplitudeAlarmRulerReply.internalBinaryWrite(message.inner.amplitudeAlarmRuler, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* storage.BandEnergyAlarmRulerReply BandEnergyAlarmRuler = 7 [json_name = "BandEnergyAlarmRuler"]; */
        if (message.inner.oneofKind === "bandEnergyAlarmRuler")
            BandEnergyAlarmRulerReply.internalBinaryWrite(message.inner.bandEnergyAlarmRuler, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* storage.DispersedAlarmRulerReply DispersedAlarmRuler = 8 [json_name = "DispersedAlarmRuler"]; */
        if (message.inner.oneofKind === "dispersedAlarmRuler")
            DispersedAlarmRulerReply.internalBinaryWrite(message.inner.dispersedAlarmRuler, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* storage.MutationAlarmRulerReply MutationAlarmRuler = 9 [json_name = "MutationAlarmRuler"]; */
        if (message.inner.oneofKind === "mutationAlarmRuler")
            MutationAlarmRulerReply.internalBinaryWrite(message.inner.mutationAlarmRuler, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* storage.SelfLearningAlarmRulerReply SelfLearningAlarmRuler = 10 [json_name = "SelfLearningAlarmRuler"]; */
        if (message.inner.oneofKind === "selfLearningAlarmRuler")
            SelfLearningAlarmRulerReply.internalBinaryWrite(message.inner.selfLearningAlarmRuler, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message storage.AlarmRulerReply
 */
export const AlarmRulerReply = new AlarmRulerReply$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AmplitudeAlarmRulerReply$Type extends MessageType<AmplitudeAlarmRulerReply> {
    constructor() {
        super("storage.AmplitudeAlarmRulerReply", [
            { no: 1, name: "EigenType", kind: "scalar", jsonName: "EigenType", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "AmplitudeAlarmType", kind: "scalar", jsonName: "AmplitudeAlarmType", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "Flag", kind: "scalar", jsonName: "Flag", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "Warning1", kind: "scalar", jsonName: "Warning1", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 5, name: "Warning2", kind: "scalar", jsonName: "Warning2", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 6, name: "Danger1", kind: "scalar", jsonName: "Danger1", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 7, name: "Danger2", kind: "scalar", jsonName: "Danger2", T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value?: PartialMessage<AmplitudeAlarmRulerReply>): AmplitudeAlarmRulerReply {
        const message = { eigenType: 0, amplitudeAlarmType: 0, flag: 0, warning1: 0, warning2: 0, danger1: 0, danger2: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AmplitudeAlarmRulerReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AmplitudeAlarmRulerReply): AmplitudeAlarmRulerReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 EigenType = 1 [json_name = "EigenType"];*/ 1:
                    message.eigenType = reader.int32();
                    break;
                case /* int32 AmplitudeAlarmType = 2 [json_name = "AmplitudeAlarmType"];*/ 2:
                    message.amplitudeAlarmType = reader.int32();
                    break;
                case /* int32 Flag = 3 [json_name = "Flag"];*/ 3:
                    message.flag = reader.int32();
                    break;
                case /* double Warning1 = 4 [json_name = "Warning1"];*/ 4:
                    message.warning1 = reader.double();
                    break;
                case /* double Warning2 = 5 [json_name = "Warning2"];*/ 5:
                    message.warning2 = reader.double();
                    break;
                case /* double Danger1 = 6 [json_name = "Danger1"];*/ 6:
                    message.danger1 = reader.double();
                    break;
                case /* double Danger2 = 7 [json_name = "Danger2"];*/ 7:
                    message.danger2 = reader.double();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AmplitudeAlarmRulerReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 EigenType = 1 [json_name = "EigenType"]; */
        if (message.eigenType !== 0)
            writer.tag(1, WireType.Varint).int32(message.eigenType);
        /* int32 AmplitudeAlarmType = 2 [json_name = "AmplitudeAlarmType"]; */
        if (message.amplitudeAlarmType !== 0)
            writer.tag(2, WireType.Varint).int32(message.amplitudeAlarmType);
        /* int32 Flag = 3 [json_name = "Flag"]; */
        if (message.flag !== 0)
            writer.tag(3, WireType.Varint).int32(message.flag);
        /* double Warning1 = 4 [json_name = "Warning1"]; */
        if (message.warning1 !== 0)
            writer.tag(4, WireType.Bit64).double(message.warning1);
        /* double Warning2 = 5 [json_name = "Warning2"]; */
        if (message.warning2 !== 0)
            writer.tag(5, WireType.Bit64).double(message.warning2);
        /* double Danger1 = 6 [json_name = "Danger1"]; */
        if (message.danger1 !== 0)
            writer.tag(6, WireType.Bit64).double(message.danger1);
        /* double Danger2 = 7 [json_name = "Danger2"]; */
        if (message.danger2 !== 0)
            writer.tag(7, WireType.Bit64).double(message.danger2);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message storage.AmplitudeAlarmRulerReply
 */
export const AmplitudeAlarmRulerReply = new AmplitudeAlarmRulerReply$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BandEnergyAlarmRulerReply$Type extends MessageType<BandEnergyAlarmRulerReply> {
    constructor() {
        super("storage.BandEnergyAlarmRulerReply", [
            { no: 1, name: "Details", kind: "scalar", jsonName: "Details", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "OperateType", kind: "scalar", jsonName: "OperateType", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "ParamType", kind: "scalar", jsonName: "ParamType", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "SpectrumParam", kind: "scalar", jsonName: "SpectrumParam", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<BandEnergyAlarmRulerReply>): BandEnergyAlarmRulerReply {
        const message = { details: "", operateType: 0, paramType: 0, spectrumParam: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BandEnergyAlarmRulerReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BandEnergyAlarmRulerReply): BandEnergyAlarmRulerReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string Details = 1 [json_name = "Details"];*/ 1:
                    message.details = reader.string();
                    break;
                case /* int32 OperateType = 2 [json_name = "OperateType"];*/ 2:
                    message.operateType = reader.int32();
                    break;
                case /* int32 ParamType = 3 [json_name = "ParamType"];*/ 3:
                    message.paramType = reader.int32();
                    break;
                case /* string SpectrumParam = 4 [json_name = "SpectrumParam"];*/ 4:
                    message.spectrumParam = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BandEnergyAlarmRulerReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string Details = 1 [json_name = "Details"]; */
        if (message.details !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.details);
        /* int32 OperateType = 2 [json_name = "OperateType"]; */
        if (message.operateType !== 0)
            writer.tag(2, WireType.Varint).int32(message.operateType);
        /* int32 ParamType = 3 [json_name = "ParamType"]; */
        if (message.paramType !== 0)
            writer.tag(3, WireType.Varint).int32(message.paramType);
        /* string SpectrumParam = 4 [json_name = "SpectrumParam"]; */
        if (message.spectrumParam !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.spectrumParam);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message storage.BandEnergyAlarmRulerReply
 */
export const BandEnergyAlarmRulerReply = new BandEnergyAlarmRulerReply$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DispersedAlarmRulerReply$Type extends MessageType<DispersedAlarmRulerReply> {
    constructor() {
        super("storage.DispersedAlarmRulerReply", [
            { no: 1, name: "CategoryName", kind: "scalar", jsonName: "CategoryName", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "DispersedItems", kind: "scalar", jsonName: "DispersedItems", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DispersedAlarmRulerReply>): DispersedAlarmRulerReply {
        const message = { categoryName: "", dispersedItems: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DispersedAlarmRulerReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DispersedAlarmRulerReply): DispersedAlarmRulerReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string CategoryName = 1 [json_name = "CategoryName"];*/ 1:
                    message.categoryName = reader.string();
                    break;
                case /* string DispersedItems = 2 [json_name = "DispersedItems"];*/ 2:
                    message.dispersedItems = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DispersedAlarmRulerReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string CategoryName = 1 [json_name = "CategoryName"]; */
        if (message.categoryName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.categoryName);
        /* string DispersedItems = 2 [json_name = "DispersedItems"]; */
        if (message.dispersedItems !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.dispersedItems);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message storage.DispersedAlarmRulerReply
 */
export const DispersedAlarmRulerReply = new DispersedAlarmRulerReply$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MutationAlarmRulerReply$Type extends MessageType<MutationAlarmRulerReply> {
    constructor() {
        super("storage.MutationAlarmRulerReply", [
            { no: 1, name: "EigenType", kind: "scalar", jsonName: "EigenType", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "Flag", kind: "scalar", jsonName: "Flag", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "Alarm", kind: "scalar", jsonName: "Alarm", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 4, name: "Danger", kind: "scalar", jsonName: "Danger", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 5, name: "MutationTrigger", kind: "scalar", jsonName: "MutationTrigger", T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value?: PartialMessage<MutationAlarmRulerReply>): MutationAlarmRulerReply {
        const message = { eigenType: 0, flag: 0, alarm: 0, danger: 0, mutationTrigger: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<MutationAlarmRulerReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MutationAlarmRulerReply): MutationAlarmRulerReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 EigenType = 1 [json_name = "EigenType"];*/ 1:
                    message.eigenType = reader.int32();
                    break;
                case /* int32 Flag = 2 [json_name = "Flag"];*/ 2:
                    message.flag = reader.int32();
                    break;
                case /* double Alarm = 3 [json_name = "Alarm"];*/ 3:
                    message.alarm = reader.double();
                    break;
                case /* double Danger = 4 [json_name = "Danger"];*/ 4:
                    message.danger = reader.double();
                    break;
                case /* double MutationTrigger = 5 [json_name = "MutationTrigger"];*/ 5:
                    message.mutationTrigger = reader.double();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MutationAlarmRulerReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 EigenType = 1 [json_name = "EigenType"]; */
        if (message.eigenType !== 0)
            writer.tag(1, WireType.Varint).int32(message.eigenType);
        /* int32 Flag = 2 [json_name = "Flag"]; */
        if (message.flag !== 0)
            writer.tag(2, WireType.Varint).int32(message.flag);
        /* double Alarm = 3 [json_name = "Alarm"]; */
        if (message.alarm !== 0)
            writer.tag(3, WireType.Bit64).double(message.alarm);
        /* double Danger = 4 [json_name = "Danger"]; */
        if (message.danger !== 0)
            writer.tag(4, WireType.Bit64).double(message.danger);
        /* double MutationTrigger = 5 [json_name = "MutationTrigger"]; */
        if (message.mutationTrigger !== 0)
            writer.tag(5, WireType.Bit64).double(message.mutationTrigger);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message storage.MutationAlarmRulerReply
 */
export const MutationAlarmRulerReply = new MutationAlarmRulerReply$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SelfLearningAlarmRulerReply$Type extends MessageType<SelfLearningAlarmRulerReply> {
    constructor() {
        super("storage.SelfLearningAlarmRulerReply", [
            { no: 1, name: "RelateId", kind: "scalar", jsonName: "RelateId", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "StartTime", kind: "scalar", jsonName: "StartTime", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "EndTime", kind: "scalar", jsonName: "EndTime", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "Sigma", kind: "scalar", jsonName: "Sigma", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 5, name: "WeightLimit", kind: "scalar", jsonName: "WeightLimit", T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value?: PartialMessage<SelfLearningAlarmRulerReply>): SelfLearningAlarmRulerReply {
        const message = { relateId: "", startTime: "", endTime: "", sigma: 0, weightLimit: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SelfLearningAlarmRulerReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SelfLearningAlarmRulerReply): SelfLearningAlarmRulerReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string RelateId = 1 [json_name = "RelateId"];*/ 1:
                    message.relateId = reader.string();
                    break;
                case /* string StartTime = 2 [json_name = "StartTime"];*/ 2:
                    message.startTime = reader.string();
                    break;
                case /* string EndTime = 3 [json_name = "EndTime"];*/ 3:
                    message.endTime = reader.string();
                    break;
                case /* double Sigma = 4 [json_name = "Sigma"];*/ 4:
                    message.sigma = reader.double();
                    break;
                case /* double WeightLimit = 5 [json_name = "WeightLimit"];*/ 5:
                    message.weightLimit = reader.double();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SelfLearningAlarmRulerReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string RelateId = 1 [json_name = "RelateId"]; */
        if (message.relateId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.relateId);
        /* string StartTime = 2 [json_name = "StartTime"]; */
        if (message.startTime !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.startTime);
        /* string EndTime = 3 [json_name = "EndTime"]; */
        if (message.endTime !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.endTime);
        /* double Sigma = 4 [json_name = "Sigma"]; */
        if (message.sigma !== 0)
            writer.tag(4, WireType.Bit64).double(message.sigma);
        /* double WeightLimit = 5 [json_name = "WeightLimit"]; */
        if (message.weightLimit !== 0)
            writer.tag(5, WireType.Bit64).double(message.weightLimit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message storage.SelfLearningAlarmRulerReply
 */
export const SelfLearningAlarmRulerReply = new SelfLearningAlarmRulerReply$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CursorInfoReply$Type extends MessageType<CursorInfoReply> {
    constructor() {
        super("storage.CursorInfoReply", [
            { no: 1, name: "BlockNumber", kind: "scalar", jsonName: "BlockNumber", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "FirstNumber", kind: "scalar", jsonName: "FirstNumber", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "LastNumber", kind: "scalar", jsonName: "LastNumber", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "BlockSize", kind: "scalar", jsonName: "BlockSize", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<CursorInfoReply>): CursorInfoReply {
        const message = { blockNumber: 0, firstNumber: 0, lastNumber: 0, blockSize: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CursorInfoReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CursorInfoReply): CursorInfoReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 BlockNumber = 1 [json_name = "BlockNumber"];*/ 1:
                    message.blockNumber = reader.int32();
                    break;
                case /* int32 FirstNumber = 2 [json_name = "FirstNumber"];*/ 2:
                    message.firstNumber = reader.int32();
                    break;
                case /* int32 LastNumber = 3 [json_name = "LastNumber"];*/ 3:
                    message.lastNumber = reader.int32();
                    break;
                case /* int32 BlockSize = 4 [json_name = "BlockSize"];*/ 4:
                    message.blockSize = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CursorInfoReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 BlockNumber = 1 [json_name = "BlockNumber"]; */
        if (message.blockNumber !== 0)
            writer.tag(1, WireType.Varint).int32(message.blockNumber);
        /* int32 FirstNumber = 2 [json_name = "FirstNumber"]; */
        if (message.firstNumber !== 0)
            writer.tag(2, WireType.Varint).int32(message.firstNumber);
        /* int32 LastNumber = 3 [json_name = "LastNumber"]; */
        if (message.lastNumber !== 0)
            writer.tag(3, WireType.Varint).int32(message.lastNumber);
        /* int32 BlockSize = 4 [json_name = "BlockSize"]; */
        if (message.blockSize !== 0)
            writer.tag(4, WireType.Varint).int32(message.blockSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message storage.CursorInfoReply
 */
export const CursorInfoReply = new CursorInfoReply$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HistoryDataReply$Type extends MessageType<HistoryDataReply> {
    constructor() {
        super("storage.HistoryDataReply", [
            { no: 1, name: "SampleTime", kind: "scalar", jsonName: "SampleTime", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "Eigens", kind: "message", jsonName: "Eigens", repeat: 1 /*RepeatType.PACKED*/, T: () => EigenDataReply },
            { no: 3, name: "Datas", kind: "message", jsonName: "Datas", T: () => DatasReply }
        ]);
    }
    create(value?: PartialMessage<HistoryDataReply>): HistoryDataReply {
        const message = { sampleTime: 0n, eigens: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<HistoryDataReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: HistoryDataReply): HistoryDataReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 SampleTime = 1 [json_name = "SampleTime"];*/ 1:
                    message.sampleTime = reader.int64().toBigInt();
                    break;
                case /* repeated storage.EigenDataReply Eigens = 2 [json_name = "Eigens"];*/ 2:
                    message.eigens.push(EigenDataReply.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* storage.DatasReply Datas = 3 [json_name = "Datas"];*/ 3:
                    message.datas = DatasReply.internalBinaryRead(reader, reader.uint32(), options, message.datas);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: HistoryDataReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 SampleTime = 1 [json_name = "SampleTime"]; */
        if (message.sampleTime !== 0n)
            writer.tag(1, WireType.Varint).int64(message.sampleTime);
        /* repeated storage.EigenDataReply Eigens = 2 [json_name = "Eigens"]; */
        for (let i = 0; i < message.eigens.length; i++)
            EigenDataReply.internalBinaryWrite(message.eigens[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* storage.DatasReply Datas = 3 [json_name = "Datas"]; */
        if (message.datas)
            DatasReply.internalBinaryWrite(message.datas, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message storage.HistoryDataReply
 */
export const HistoryDataReply = new HistoryDataReply$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EigenDataReply$Type extends MessageType<EigenDataReply> {
    constructor() {
        super("storage.EigenDataReply", [
            { no: 1, name: "EigenType", kind: "scalar", jsonName: "EigenType", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "Value", kind: "scalar", jsonName: "Value", T: 2 /*ScalarType.FLOAT*/ }
        ]);
    }
    create(value?: PartialMessage<EigenDataReply>): EigenDataReply {
        const message = { eigenType: 0, value: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EigenDataReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EigenDataReply): EigenDataReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 EigenType = 1 [json_name = "EigenType"];*/ 1:
                    message.eigenType = reader.int32();
                    break;
                case /* float Value = 2 [json_name = "Value"];*/ 2:
                    message.value = reader.float();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EigenDataReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 EigenType = 1 [json_name = "EigenType"]; */
        if (message.eigenType !== 0)
            writer.tag(1, WireType.Varint).int32(message.eigenType);
        /* float Value = 2 [json_name = "Value"]; */
        if (message.value !== 0)
            writer.tag(2, WireType.Bit32).float(message.value);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message storage.EigenDataReply
 */
export const EigenDataReply = new EigenDataReply$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchPointWaveDataReply$Type extends MessageType<BatchPointWaveDataReply> {
    constructor() {
        super("storage.BatchPointWaveDataReply", [
            { no: 1, name: "PointId", kind: "scalar", jsonName: "PointId", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "SampleTime", kind: "message", jsonName: "SampleTime", T: () => Timestamp },
            { no: 3, name: "RotateSpeed", kind: "message", jsonName: "RotateSpeed", T: () => DoubleValue },
            { no: 4, name: "WaveCount", kind: "scalar", jsonName: "WaveCount", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "WaveData", kind: "scalar", jsonName: "WaveData", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<BatchPointWaveDataReply>): BatchPointWaveDataReply {
        const message = { pointId: 0, waveCount: 0, waveData: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BatchPointWaveDataReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchPointWaveDataReply): BatchPointWaveDataReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 PointId = 1 [json_name = "PointId"];*/ 1:
                    message.pointId = reader.int32();
                    break;
                case /* google.protobuf.Timestamp SampleTime = 2 [json_name = "SampleTime"];*/ 2:
                    message.sampleTime = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.sampleTime);
                    break;
                case /* google.protobuf.DoubleValue RotateSpeed = 3 [json_name = "RotateSpeed"];*/ 3:
                    message.rotateSpeed = DoubleValue.internalBinaryRead(reader, reader.uint32(), options, message.rotateSpeed);
                    break;
                case /* int32 WaveCount = 4 [json_name = "WaveCount"];*/ 4:
                    message.waveCount = reader.int32();
                    break;
                case /* bytes WaveData = 5 [json_name = "WaveData"];*/ 5:
                    message.waveData = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BatchPointWaveDataReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 PointId = 1 [json_name = "PointId"]; */
        if (message.pointId !== 0)
            writer.tag(1, WireType.Varint).int32(message.pointId);
        /* google.protobuf.Timestamp SampleTime = 2 [json_name = "SampleTime"]; */
        if (message.sampleTime)
            Timestamp.internalBinaryWrite(message.sampleTime, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.DoubleValue RotateSpeed = 3 [json_name = "RotateSpeed"]; */
        if (message.rotateSpeed)
            DoubleValue.internalBinaryWrite(message.rotateSpeed, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* int32 WaveCount = 4 [json_name = "WaveCount"]; */
        if (message.waveCount !== 0)
            writer.tag(4, WireType.Varint).int32(message.waveCount);
        /* bytes WaveData = 5 [json_name = "WaveData"]; */
        if (message.waveData.length)
            writer.tag(5, WireType.LengthDelimited).bytes(message.waveData);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message storage.BatchPointWaveDataReply
 */
export const BatchPointWaveDataReply = new BatchPointWaveDataReply$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DatasReply$Type extends MessageType<DatasReply> {
    constructor() {
        super("storage.DatasReply", [
            { no: 1, name: "DataCount", kind: "scalar", jsonName: "DataCount", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "YDatas", kind: "message", jsonName: "YDatas", oneof: "inner", T: () => YDatasReply },
            { no: 3, name: "BarDatas", kind: "message", jsonName: "BarDatas", oneof: "inner", T: () => BarDatasReply },
            { no: 4, name: "TrendDatas", kind: "message", jsonName: "TrendDatas", oneof: "inner", T: () => TrendDatasReply },
            { no: 5, name: "RainFlowDatas", kind: "message", jsonName: "RainFlowDatas", oneof: "inner", T: () => RainFlowDatasReply },
            { no: 6, name: "XYDatas", kind: "message", jsonName: "XYDatas", oneof: "inner", T: () => XYDatasReply },
            { no: 7, name: "ExtensionEigenDatas", kind: "message", jsonName: "ExtensionEigenDatas", oneof: "inner", T: () => ExtensionEigenDatasReply },
            { no: 8, name: "DiluteDatas", kind: "message", jsonName: "DiluteDatas", oneof: "inner", T: () => DiluteDatasReply }
        ]);
    }
    create(value?: PartialMessage<DatasReply>): DatasReply {
        const message = { dataCount: 0, inner: { oneofKind: undefined } };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DatasReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DatasReply): DatasReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 DataCount = 1 [json_name = "DataCount"];*/ 1:
                    message.dataCount = reader.int32();
                    break;
                case /* storage.YDatasReply YDatas = 2 [json_name = "YDatas"];*/ 2:
                    message.inner = {
                        oneofKind: "yDatas",
                        yDatas: YDatasReply.internalBinaryRead(reader, reader.uint32(), options, (message.inner as any).yDatas)
                    };
                    break;
                case /* storage.BarDatasReply BarDatas = 3 [json_name = "BarDatas"];*/ 3:
                    message.inner = {
                        oneofKind: "barDatas",
                        barDatas: BarDatasReply.internalBinaryRead(reader, reader.uint32(), options, (message.inner as any).barDatas)
                    };
                    break;
                case /* storage.TrendDatasReply TrendDatas = 4 [json_name = "TrendDatas"];*/ 4:
                    message.inner = {
                        oneofKind: "trendDatas",
                        trendDatas: TrendDatasReply.internalBinaryRead(reader, reader.uint32(), options, (message.inner as any).trendDatas)
                    };
                    break;
                case /* storage.RainFlowDatasReply RainFlowDatas = 5 [json_name = "RainFlowDatas"];*/ 5:
                    message.inner = {
                        oneofKind: "rainFlowDatas",
                        rainFlowDatas: RainFlowDatasReply.internalBinaryRead(reader, reader.uint32(), options, (message.inner as any).rainFlowDatas)
                    };
                    break;
                case /* storage.XYDatasReply XYDatas = 6 [json_name = "XYDatas"];*/ 6:
                    message.inner = {
                        oneofKind: "xYDatas",
                        xYDatas: XYDatasReply.internalBinaryRead(reader, reader.uint32(), options, (message.inner as any).xYDatas)
                    };
                    break;
                case /* storage.ExtensionEigenDatasReply ExtensionEigenDatas = 7 [json_name = "ExtensionEigenDatas"];*/ 7:
                    message.inner = {
                        oneofKind: "extensionEigenDatas",
                        extensionEigenDatas: ExtensionEigenDatasReply.internalBinaryRead(reader, reader.uint32(), options, (message.inner as any).extensionEigenDatas)
                    };
                    break;
                case /* storage.DiluteDatasReply DiluteDatas = 8 [json_name = "DiluteDatas"];*/ 8:
                    message.inner = {
                        oneofKind: "diluteDatas",
                        diluteDatas: DiluteDatasReply.internalBinaryRead(reader, reader.uint32(), options, (message.inner as any).diluteDatas)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DatasReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 DataCount = 1 [json_name = "DataCount"]; */
        if (message.dataCount !== 0)
            writer.tag(1, WireType.Varint).int32(message.dataCount);
        /* storage.YDatasReply YDatas = 2 [json_name = "YDatas"]; */
        if (message.inner.oneofKind === "yDatas")
            YDatasReply.internalBinaryWrite(message.inner.yDatas, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* storage.BarDatasReply BarDatas = 3 [json_name = "BarDatas"]; */
        if (message.inner.oneofKind === "barDatas")
            BarDatasReply.internalBinaryWrite(message.inner.barDatas, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* storage.TrendDatasReply TrendDatas = 4 [json_name = "TrendDatas"]; */
        if (message.inner.oneofKind === "trendDatas")
            TrendDatasReply.internalBinaryWrite(message.inner.trendDatas, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* storage.RainFlowDatasReply RainFlowDatas = 5 [json_name = "RainFlowDatas"]; */
        if (message.inner.oneofKind === "rainFlowDatas")
            RainFlowDatasReply.internalBinaryWrite(message.inner.rainFlowDatas, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* storage.XYDatasReply XYDatas = 6 [json_name = "XYDatas"]; */
        if (message.inner.oneofKind === "xYDatas")
            XYDatasReply.internalBinaryWrite(message.inner.xYDatas, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* storage.ExtensionEigenDatasReply ExtensionEigenDatas = 7 [json_name = "ExtensionEigenDatas"]; */
        if (message.inner.oneofKind === "extensionEigenDatas")
            ExtensionEigenDatasReply.internalBinaryWrite(message.inner.extensionEigenDatas, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* storage.DiluteDatasReply DiluteDatas = 8 [json_name = "DiluteDatas"]; */
        if (message.inner.oneofKind === "diluteDatas")
            DiluteDatasReply.internalBinaryWrite(message.inner.diluteDatas, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message storage.DatasReply
 */
export const DatasReply = new DatasReply$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DiluteDatasReply$Type extends MessageType<DiluteDatasReply> {
    constructor() {
        super("storage.DiluteDatasReply", [
            { no: 1, name: "Datas", kind: "message", jsonName: "Datas", repeat: 1 /*RepeatType.PACKED*/, T: () => DiluteDataReply }
        ]);
    }
    create(value?: PartialMessage<DiluteDatasReply>): DiluteDatasReply {
        const message = { datas: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DiluteDatasReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DiluteDatasReply): DiluteDatasReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated storage.DiluteDataReply Datas = 1 [json_name = "Datas"];*/ 1:
                    message.datas.push(DiluteDataReply.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DiluteDatasReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated storage.DiluteDataReply Datas = 1 [json_name = "Datas"]; */
        for (let i = 0; i < message.datas.length; i++)
            DiluteDataReply.internalBinaryWrite(message.datas[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message storage.DiluteDatasReply
 */
export const DiluteDatasReply = new DiluteDatasReply$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DiluteDataReply$Type extends MessageType<DiluteDataReply> {
    constructor() {
        super("storage.DiluteDataReply", [
            { no: 1, name: "Time", kind: "scalar", jsonName: "Time", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "MinValue", kind: "scalar", jsonName: "MinValue", T: 2 /*ScalarType.FLOAT*/ },
            { no: 3, name: "MaxValue", kind: "scalar", jsonName: "MaxValue", T: 2 /*ScalarType.FLOAT*/ }
        ]);
    }
    create(value?: PartialMessage<DiluteDataReply>): DiluteDataReply {
        const message = { time: 0n, minValue: 0, maxValue: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DiluteDataReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DiluteDataReply): DiluteDataReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 Time = 1 [json_name = "Time"];*/ 1:
                    message.time = reader.int64().toBigInt();
                    break;
                case /* float MinValue = 2 [json_name = "MinValue"];*/ 2:
                    message.minValue = reader.float();
                    break;
                case /* float MaxValue = 3 [json_name = "MaxValue"];*/ 3:
                    message.maxValue = reader.float();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DiluteDataReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 Time = 1 [json_name = "Time"]; */
        if (message.time !== 0n)
            writer.tag(1, WireType.Varint).int64(message.time);
        /* float MinValue = 2 [json_name = "MinValue"]; */
        if (message.minValue !== 0)
            writer.tag(2, WireType.Bit32).float(message.minValue);
        /* float MaxValue = 3 [json_name = "MaxValue"]; */
        if (message.maxValue !== 0)
            writer.tag(3, WireType.Bit32).float(message.maxValue);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message storage.DiluteDataReply
 */
export const DiluteDataReply = new DiluteDataReply$Type();
// @generated message type with reflection information, may provide speed optimized methods
class YDatasReply$Type extends MessageType<YDatasReply> {
    constructor() {
        super("storage.YDatasReply", [
            { no: 1, name: "Datas", kind: "scalar", jsonName: "Datas", repeat: 1 /*RepeatType.PACKED*/, T: 2 /*ScalarType.FLOAT*/ }
        ]);
    }
    create(value?: PartialMessage<YDatasReply>): YDatasReply {
        const message = { datas: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<YDatasReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: YDatasReply): YDatasReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated float Datas = 1 [json_name = "Datas"];*/ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.datas.push(reader.float());
                    else
                        message.datas.push(reader.float());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: YDatasReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated float Datas = 1 [json_name = "Datas"]; */
        if (message.datas.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.datas.length; i++)
                writer.float(message.datas[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message storage.YDatasReply
 */
export const YDatasReply = new YDatasReply$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BarDatasReply$Type extends MessageType<BarDatasReply> {
    constructor() {
        super("storage.BarDatasReply", [
            { no: 1, name: "Datas", kind: "message", jsonName: "Datas", repeat: 1 /*RepeatType.PACKED*/, T: () => BarDataReply }
        ]);
    }
    create(value?: PartialMessage<BarDatasReply>): BarDatasReply {
        const message = { datas: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BarDatasReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BarDatasReply): BarDatasReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated storage.BarDataReply Datas = 1 [json_name = "Datas"];*/ 1:
                    message.datas.push(BarDataReply.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BarDatasReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated storage.BarDataReply Datas = 1 [json_name = "Datas"]; */
        for (let i = 0; i < message.datas.length; i++)
            BarDataReply.internalBinaryWrite(message.datas[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message storage.BarDatasReply
 */
export const BarDatasReply = new BarDatasReply$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BarDataReply$Type extends MessageType<BarDataReply> {
    constructor() {
        super("storage.BarDataReply", [
            { no: 1, name: "Start", kind: "scalar", jsonName: "Start", T: 2 /*ScalarType.FLOAT*/ },
            { no: 2, name: "End", kind: "scalar", jsonName: "End", T: 2 /*ScalarType.FLOAT*/ },
            { no: 3, name: "Value", kind: "scalar", jsonName: "Value", T: 2 /*ScalarType.FLOAT*/ }
        ]);
    }
    create(value?: PartialMessage<BarDataReply>): BarDataReply {
        const message = { start: 0, end: 0, value: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BarDataReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BarDataReply): BarDataReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* float Start = 1 [json_name = "Start"];*/ 1:
                    message.start = reader.float();
                    break;
                case /* float End = 2 [json_name = "End"];*/ 2:
                    message.end = reader.float();
                    break;
                case /* float Value = 3 [json_name = "Value"];*/ 3:
                    message.value = reader.float();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BarDataReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* float Start = 1 [json_name = "Start"]; */
        if (message.start !== 0)
            writer.tag(1, WireType.Bit32).float(message.start);
        /* float End = 2 [json_name = "End"]; */
        if (message.end !== 0)
            writer.tag(2, WireType.Bit32).float(message.end);
        /* float Value = 3 [json_name = "Value"]; */
        if (message.value !== 0)
            writer.tag(3, WireType.Bit32).float(message.value);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message storage.BarDataReply
 */
export const BarDataReply = new BarDataReply$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TrendDatasReply$Type extends MessageType<TrendDatasReply> {
    constructor() {
        super("storage.TrendDatasReply", [
            { no: 1, name: "Datas", kind: "message", jsonName: "Datas", repeat: 1 /*RepeatType.PACKED*/, T: () => TrendDataReply },
            { no: 2, name: "Index", kind: "scalar", jsonName: "Index", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<TrendDatasReply>): TrendDatasReply {
        const message = { datas: [], index: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TrendDatasReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TrendDatasReply): TrendDatasReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated storage.TrendDataReply Datas = 1 [json_name = "Datas"];*/ 1:
                    message.datas.push(TrendDataReply.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 Index = 2 [json_name = "Index"];*/ 2:
                    message.index = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TrendDatasReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated storage.TrendDataReply Datas = 1 [json_name = "Datas"]; */
        for (let i = 0; i < message.datas.length; i++)
            TrendDataReply.internalBinaryWrite(message.datas[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 Index = 2 [json_name = "Index"]; */
        if (message.index !== 0)
            writer.tag(2, WireType.Varint).int32(message.index);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message storage.TrendDatasReply
 */
export const TrendDatasReply = new TrendDatasReply$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TrendDataReply$Type extends MessageType<TrendDataReply> {
    constructor() {
        super("storage.TrendDataReply", [
            { no: 1, name: "X", kind: "scalar", jsonName: "X", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 2, name: "Y", kind: "scalar", jsonName: "Y", T: 2 /*ScalarType.FLOAT*/ }
        ]);
    }
    create(value?: PartialMessage<TrendDataReply>): TrendDataReply {
        const message = { x: 0, y: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TrendDataReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TrendDataReply): TrendDataReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* double X = 1 [json_name = "X"];*/ 1:
                    message.x = reader.double();
                    break;
                case /* float Y = 2 [json_name = "Y"];*/ 2:
                    message.y = reader.float();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TrendDataReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* double X = 1 [json_name = "X"]; */
        if (message.x !== 0)
            writer.tag(1, WireType.Bit64).double(message.x);
        /* float Y = 2 [json_name = "Y"]; */
        if (message.y !== 0)
            writer.tag(2, WireType.Bit32).float(message.y);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message storage.TrendDataReply
 */
export const TrendDataReply = new TrendDataReply$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RainFlowDatasReply$Type extends MessageType<RainFlowDatasReply> {
    constructor() {
        super("storage.RainFlowDatasReply", [
            { no: 1, name: "Means", kind: "message", jsonName: "Means", repeat: 1 /*RepeatType.PACKED*/, T: () => BarDataReply },
            { no: 2, name: "RangeCount", kind: "scalar", jsonName: "RangeCount", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "Ranges", kind: "message", jsonName: "Ranges", repeat: 1 /*RepeatType.PACKED*/, T: () => BarDataReply },
            { no: 4, name: "TwoDimension", kind: "message", jsonName: "TwoDimension", repeat: 1 /*RepeatType.PACKED*/, T: () => TwoDimensionDataReply }
        ]);
    }
    create(value?: PartialMessage<RainFlowDatasReply>): RainFlowDatasReply {
        const message = { means: [], rangeCount: 0, ranges: [], twoDimension: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RainFlowDatasReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RainFlowDatasReply): RainFlowDatasReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated storage.BarDataReply Means = 1 [json_name = "Means"];*/ 1:
                    message.means.push(BarDataReply.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 RangeCount = 2 [json_name = "RangeCount"];*/ 2:
                    message.rangeCount = reader.int32();
                    break;
                case /* repeated storage.BarDataReply Ranges = 3 [json_name = "Ranges"];*/ 3:
                    message.ranges.push(BarDataReply.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated storage.TwoDimensionDataReply TwoDimension = 4 [json_name = "TwoDimension"];*/ 4:
                    message.twoDimension.push(TwoDimensionDataReply.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RainFlowDatasReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated storage.BarDataReply Means = 1 [json_name = "Means"]; */
        for (let i = 0; i < message.means.length; i++)
            BarDataReply.internalBinaryWrite(message.means[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 RangeCount = 2 [json_name = "RangeCount"]; */
        if (message.rangeCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.rangeCount);
        /* repeated storage.BarDataReply Ranges = 3 [json_name = "Ranges"]; */
        for (let i = 0; i < message.ranges.length; i++)
            BarDataReply.internalBinaryWrite(message.ranges[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated storage.TwoDimensionDataReply TwoDimension = 4 [json_name = "TwoDimension"]; */
        for (let i = 0; i < message.twoDimension.length; i++)
            TwoDimensionDataReply.internalBinaryWrite(message.twoDimension[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message storage.RainFlowDatasReply
 */
export const RainFlowDatasReply = new RainFlowDatasReply$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TwoDimensionDataReply$Type extends MessageType<TwoDimensionDataReply> {
    constructor() {
        super("storage.TwoDimensionDataReply", [
            { no: 1, name: "Mean", kind: "message", jsonName: "Mean", T: () => BarDataReply },
            { no: 2, name: "Range", kind: "message", jsonName: "Range", T: () => BarDataReply }
        ]);
    }
    create(value?: PartialMessage<TwoDimensionDataReply>): TwoDimensionDataReply {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TwoDimensionDataReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TwoDimensionDataReply): TwoDimensionDataReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* storage.BarDataReply Mean = 1 [json_name = "Mean"];*/ 1:
                    message.mean = BarDataReply.internalBinaryRead(reader, reader.uint32(), options, message.mean);
                    break;
                case /* storage.BarDataReply Range = 2 [json_name = "Range"];*/ 2:
                    message.range = BarDataReply.internalBinaryRead(reader, reader.uint32(), options, message.range);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TwoDimensionDataReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* storage.BarDataReply Mean = 1 [json_name = "Mean"]; */
        if (message.mean)
            BarDataReply.internalBinaryWrite(message.mean, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* storage.BarDataReply Range = 2 [json_name = "Range"]; */
        if (message.range)
            BarDataReply.internalBinaryWrite(message.range, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message storage.TwoDimensionDataReply
 */
export const TwoDimensionDataReply = new TwoDimensionDataReply$Type();
// @generated message type with reflection information, may provide speed optimized methods
class XYDatasReply$Type extends MessageType<XYDatasReply> {
    constructor() {
        super("storage.XYDatasReply", [
            { no: 1, name: "Datas", kind: "scalar", jsonName: "Datas", repeat: 1 /*RepeatType.PACKED*/, T: 2 /*ScalarType.FLOAT*/ }
        ]);
    }
    create(value?: PartialMessage<XYDatasReply>): XYDatasReply {
        const message = { datas: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<XYDatasReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: XYDatasReply): XYDatasReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated float Datas = 1 [json_name = "Datas"];*/ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.datas.push(reader.float());
                    else
                        message.datas.push(reader.float());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: XYDatasReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated float Datas = 1 [json_name = "Datas"]; */
        if (message.datas.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.datas.length; i++)
                writer.float(message.datas[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message storage.XYDatasReply
 */
export const XYDatasReply = new XYDatasReply$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ExtensionEigenDatasReply$Type extends MessageType<ExtensionEigenDatasReply> {
    constructor() {
        super("storage.ExtensionEigenDatasReply", [
            { no: 1, name: "Datas", kind: "message", jsonName: "Datas", repeat: 1 /*RepeatType.PACKED*/, T: () => ExtensionEigenDataReply }
        ]);
    }
    create(value?: PartialMessage<ExtensionEigenDatasReply>): ExtensionEigenDatasReply {
        const message = { datas: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ExtensionEigenDatasReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ExtensionEigenDatasReply): ExtensionEigenDatasReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated storage.ExtensionEigenDataReply Datas = 1 [json_name = "Datas"];*/ 1:
                    message.datas.push(ExtensionEigenDataReply.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ExtensionEigenDatasReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated storage.ExtensionEigenDataReply Datas = 1 [json_name = "Datas"]; */
        for (let i = 0; i < message.datas.length; i++)
            ExtensionEigenDataReply.internalBinaryWrite(message.datas[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message storage.ExtensionEigenDatasReply
 */
export const ExtensionEigenDatasReply = new ExtensionEigenDatasReply$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ExtensionEigenDataReply$Type extends MessageType<ExtensionEigenDataReply> {
    constructor() {
        super("storage.ExtensionEigenDataReply", [
            { no: 1, name: "X", kind: "scalar", jsonName: "X", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "Y", kind: "scalar", jsonName: "Y", T: 2 /*ScalarType.FLOAT*/ }
        ]);
    }
    create(value?: PartialMessage<ExtensionEigenDataReply>): ExtensionEigenDataReply {
        const message = { x: 0n, y: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ExtensionEigenDataReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ExtensionEigenDataReply): ExtensionEigenDataReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 X = 1 [json_name = "X"];*/ 1:
                    message.x = reader.int64().toBigInt();
                    break;
                case /* float Y = 2 [json_name = "Y"];*/ 2:
                    message.y = reader.float();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ExtensionEigenDataReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 X = 1 [json_name = "X"]; */
        if (message.x !== 0n)
            writer.tag(1, WireType.Varint).int64(message.x);
        /* float Y = 2 [json_name = "Y"]; */
        if (message.y !== 0)
            writer.tag(2, WireType.Bit32).float(message.y);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message storage.ExtensionEigenDataReply
 */
export const ExtensionEigenDataReply = new ExtensionEigenDataReply$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DspParamReply$Type extends MessageType<DspParamReply> {
    constructor() {
        super("storage.DspParamReply", [
            { no: 1, name: "FFTDspParam", kind: "message", jsonName: "FFTDspParam", oneof: "inner", T: () => FFTDspParamReply },
            { no: 2, name: "OrderAnalysisDspParam", kind: "message", jsonName: "OrderAnalysisDspParam", oneof: "inner", T: () => OrderAnalysisDspParamReply },
            { no: 3, name: "AmpAnalysisDspParam", kind: "message", jsonName: "AmpAnalysisDspParam", oneof: "inner", T: () => AmpAnalysisDspParamReply },
            { no: 4, name: "CableDspParam", kind: "message", jsonName: "CableDspParam", oneof: "inner", T: () => CableDspParamReply },
            { no: 5, name: "CepstrumDspParam", kind: "message", jsonName: "CepstrumDspParam", oneof: "inner", T: () => CepstrumDspParamReply },
            { no: 6, name: "CrossPowerSpecDensDspParam", kind: "message", jsonName: "CrossPowerSpecDensDspParam", oneof: "inner", T: () => CrossPowerSpecDensDspParamReply },
            { no: 7, name: "RainFlowDspParam", kind: "message", jsonName: "RainFlowDspParam", oneof: "inner", T: () => RainFlowDspParamReply },
            { no: 8, name: "VirtualComputeParam", kind: "message", jsonName: "VirtualComputeParam", oneof: "inner", T: () => VirtualComputeParamReply }
        ]);
    }
    create(value?: PartialMessage<DspParamReply>): DspParamReply {
        const message = { inner: { oneofKind: undefined } };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DspParamReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DspParamReply): DspParamReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* storage.FFTDspParamReply FFTDspParam = 1 [json_name = "FFTDspParam"];*/ 1:
                    message.inner = {
                        oneofKind: "fFTDspParam",
                        fFTDspParam: FFTDspParamReply.internalBinaryRead(reader, reader.uint32(), options, (message.inner as any).fFTDspParam)
                    };
                    break;
                case /* storage.OrderAnalysisDspParamReply OrderAnalysisDspParam = 2 [json_name = "OrderAnalysisDspParam"];*/ 2:
                    message.inner = {
                        oneofKind: "orderAnalysisDspParam",
                        orderAnalysisDspParam: OrderAnalysisDspParamReply.internalBinaryRead(reader, reader.uint32(), options, (message.inner as any).orderAnalysisDspParam)
                    };
                    break;
                case /* storage.AmpAnalysisDspParamReply AmpAnalysisDspParam = 3 [json_name = "AmpAnalysisDspParam"];*/ 3:
                    message.inner = {
                        oneofKind: "ampAnalysisDspParam",
                        ampAnalysisDspParam: AmpAnalysisDspParamReply.internalBinaryRead(reader, reader.uint32(), options, (message.inner as any).ampAnalysisDspParam)
                    };
                    break;
                case /* storage.CableDspParamReply CableDspParam = 4 [json_name = "CableDspParam"];*/ 4:
                    message.inner = {
                        oneofKind: "cableDspParam",
                        cableDspParam: CableDspParamReply.internalBinaryRead(reader, reader.uint32(), options, (message.inner as any).cableDspParam)
                    };
                    break;
                case /* storage.CepstrumDspParamReply CepstrumDspParam = 5 [json_name = "CepstrumDspParam"];*/ 5:
                    message.inner = {
                        oneofKind: "cepstrumDspParam",
                        cepstrumDspParam: CepstrumDspParamReply.internalBinaryRead(reader, reader.uint32(), options, (message.inner as any).cepstrumDspParam)
                    };
                    break;
                case /* storage.CrossPowerSpecDensDspParamReply CrossPowerSpecDensDspParam = 6 [json_name = "CrossPowerSpecDensDspParam"];*/ 6:
                    message.inner = {
                        oneofKind: "crossPowerSpecDensDspParam",
                        crossPowerSpecDensDspParam: CrossPowerSpecDensDspParamReply.internalBinaryRead(reader, reader.uint32(), options, (message.inner as any).crossPowerSpecDensDspParam)
                    };
                    break;
                case /* storage.RainFlowDspParamReply RainFlowDspParam = 7 [json_name = "RainFlowDspParam"];*/ 7:
                    message.inner = {
                        oneofKind: "rainFlowDspParam",
                        rainFlowDspParam: RainFlowDspParamReply.internalBinaryRead(reader, reader.uint32(), options, (message.inner as any).rainFlowDspParam)
                    };
                    break;
                case /* storage.VirtualComputeParamReply VirtualComputeParam = 8 [json_name = "VirtualComputeParam"];*/ 8:
                    message.inner = {
                        oneofKind: "virtualComputeParam",
                        virtualComputeParam: VirtualComputeParamReply.internalBinaryRead(reader, reader.uint32(), options, (message.inner as any).virtualComputeParam)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DspParamReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* storage.FFTDspParamReply FFTDspParam = 1 [json_name = "FFTDspParam"]; */
        if (message.inner.oneofKind === "fFTDspParam")
            FFTDspParamReply.internalBinaryWrite(message.inner.fFTDspParam, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* storage.OrderAnalysisDspParamReply OrderAnalysisDspParam = 2 [json_name = "OrderAnalysisDspParam"]; */
        if (message.inner.oneofKind === "orderAnalysisDspParam")
            OrderAnalysisDspParamReply.internalBinaryWrite(message.inner.orderAnalysisDspParam, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* storage.AmpAnalysisDspParamReply AmpAnalysisDspParam = 3 [json_name = "AmpAnalysisDspParam"]; */
        if (message.inner.oneofKind === "ampAnalysisDspParam")
            AmpAnalysisDspParamReply.internalBinaryWrite(message.inner.ampAnalysisDspParam, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* storage.CableDspParamReply CableDspParam = 4 [json_name = "CableDspParam"]; */
        if (message.inner.oneofKind === "cableDspParam")
            CableDspParamReply.internalBinaryWrite(message.inner.cableDspParam, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* storage.CepstrumDspParamReply CepstrumDspParam = 5 [json_name = "CepstrumDspParam"]; */
        if (message.inner.oneofKind === "cepstrumDspParam")
            CepstrumDspParamReply.internalBinaryWrite(message.inner.cepstrumDspParam, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* storage.CrossPowerSpecDensDspParamReply CrossPowerSpecDensDspParam = 6 [json_name = "CrossPowerSpecDensDspParam"]; */
        if (message.inner.oneofKind === "crossPowerSpecDensDspParam")
            CrossPowerSpecDensDspParamReply.internalBinaryWrite(message.inner.crossPowerSpecDensDspParam, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* storage.RainFlowDspParamReply RainFlowDspParam = 7 [json_name = "RainFlowDspParam"]; */
        if (message.inner.oneofKind === "rainFlowDspParam")
            RainFlowDspParamReply.internalBinaryWrite(message.inner.rainFlowDspParam, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* storage.VirtualComputeParamReply VirtualComputeParam = 8 [json_name = "VirtualComputeParam"]; */
        if (message.inner.oneofKind === "virtualComputeParam")
            VirtualComputeParamReply.internalBinaryWrite(message.inner.virtualComputeParam, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message storage.DspParamReply
 */
export const DspParamReply = new DspParamReply$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FFTDspParamReply$Type extends MessageType<FFTDspParamReply> {
    constructor() {
        super("storage.FFTDspParamReply", [
            { no: 1, name: "AnalysisCount", kind: "scalar", jsonName: "AnalysisCount", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "FreqUnit", kind: "scalar", jsonName: "FreqUnit", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "UseSpeed", kind: "scalar", jsonName: "UseSpeed", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "RoteSpeed", kind: "scalar", jsonName: "RoteSpeed", T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value?: PartialMessage<FFTDspParamReply>): FFTDspParamReply {
        const message = { analysisCount: 0, freqUnit: 0, useSpeed: false, roteSpeed: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FFTDspParamReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FFTDspParamReply): FFTDspParamReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 AnalysisCount = 1 [json_name = "AnalysisCount"];*/ 1:
                    message.analysisCount = reader.int32();
                    break;
                case /* int32 FreqUnit = 2 [json_name = "FreqUnit"];*/ 2:
                    message.freqUnit = reader.int32();
                    break;
                case /* bool UseSpeed = 3 [json_name = "UseSpeed"];*/ 3:
                    message.useSpeed = reader.bool();
                    break;
                case /* double RoteSpeed = 4 [json_name = "RoteSpeed"];*/ 4:
                    message.roteSpeed = reader.double();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FFTDspParamReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 AnalysisCount = 1 [json_name = "AnalysisCount"]; */
        if (message.analysisCount !== 0)
            writer.tag(1, WireType.Varint).int32(message.analysisCount);
        /* int32 FreqUnit = 2 [json_name = "FreqUnit"]; */
        if (message.freqUnit !== 0)
            writer.tag(2, WireType.Varint).int32(message.freqUnit);
        /* bool UseSpeed = 3 [json_name = "UseSpeed"]; */
        if (message.useSpeed !== false)
            writer.tag(3, WireType.Varint).bool(message.useSpeed);
        /* double RoteSpeed = 4 [json_name = "RoteSpeed"]; */
        if (message.roteSpeed !== 0)
            writer.tag(4, WireType.Bit64).double(message.roteSpeed);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message storage.FFTDspParamReply
 */
export const FFTDspParamReply = new FFTDspParamReply$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OrderAnalysisDspParamReply$Type extends MessageType<OrderAnalysisDspParamReply> {
    constructor() {
        super("storage.OrderAnalysisDspParamReply", [
            { no: 1, name: "AnalysisCount", kind: "scalar", jsonName: "AnalysisCount", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "FreqUnit", kind: "scalar", jsonName: "FreqUnit", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "UseSpeed", kind: "scalar", jsonName: "UseSpeed", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "RoteSpeed", kind: "scalar", jsonName: "RoteSpeed", T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value?: PartialMessage<OrderAnalysisDspParamReply>): OrderAnalysisDspParamReply {
        const message = { analysisCount: 0, freqUnit: 0, useSpeed: false, roteSpeed: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<OrderAnalysisDspParamReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OrderAnalysisDspParamReply): OrderAnalysisDspParamReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 AnalysisCount = 1 [json_name = "AnalysisCount"];*/ 1:
                    message.analysisCount = reader.int32();
                    break;
                case /* int32 FreqUnit = 2 [json_name = "FreqUnit"];*/ 2:
                    message.freqUnit = reader.int32();
                    break;
                case /* bool UseSpeed = 3 [json_name = "UseSpeed"];*/ 3:
                    message.useSpeed = reader.bool();
                    break;
                case /* double RoteSpeed = 4 [json_name = "RoteSpeed"];*/ 4:
                    message.roteSpeed = reader.double();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OrderAnalysisDspParamReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 AnalysisCount = 1 [json_name = "AnalysisCount"]; */
        if (message.analysisCount !== 0)
            writer.tag(1, WireType.Varint).int32(message.analysisCount);
        /* int32 FreqUnit = 2 [json_name = "FreqUnit"]; */
        if (message.freqUnit !== 0)
            writer.tag(2, WireType.Varint).int32(message.freqUnit);
        /* bool UseSpeed = 3 [json_name = "UseSpeed"]; */
        if (message.useSpeed !== false)
            writer.tag(3, WireType.Varint).bool(message.useSpeed);
        /* double RoteSpeed = 4 [json_name = "RoteSpeed"]; */
        if (message.roteSpeed !== 0)
            writer.tag(4, WireType.Bit64).double(message.roteSpeed);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message storage.OrderAnalysisDspParamReply
 */
export const OrderAnalysisDspParamReply = new OrderAnalysisDspParamReply$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AmpAnalysisDspParamReply$Type extends MessageType<AmpAnalysisDspParamReply> {
    constructor() {
        super("storage.AmpAnalysisDspParamReply", [
            { no: 1, name: "AnalysisCount", kind: "scalar", jsonName: "AnalysisCount", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<AmpAnalysisDspParamReply>): AmpAnalysisDspParamReply {
        const message = { analysisCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AmpAnalysisDspParamReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AmpAnalysisDspParamReply): AmpAnalysisDspParamReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 AnalysisCount = 1 [json_name = "AnalysisCount"];*/ 1:
                    message.analysisCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AmpAnalysisDspParamReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 AnalysisCount = 1 [json_name = "AnalysisCount"]; */
        if (message.analysisCount !== 0)
            writer.tag(1, WireType.Varint).int32(message.analysisCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message storage.AmpAnalysisDspParamReply
 */
export const AmpAnalysisDspParamReply = new AmpAnalysisDspParamReply$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CableDspParamReply$Type extends MessageType<CableDspParamReply> {
    constructor() {
        super("storage.CableDspParamReply", [
            { no: 1, name: "AnalysisCount", kind: "scalar", jsonName: "AnalysisCount", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "FreqUnit", kind: "scalar", jsonName: "FreqUnit", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "UseSpeed", kind: "scalar", jsonName: "UseSpeed", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "RoteSpeed", kind: "scalar", jsonName: "RoteSpeed", T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value?: PartialMessage<CableDspParamReply>): CableDspParamReply {
        const message = { analysisCount: 0, freqUnit: 0, useSpeed: false, roteSpeed: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CableDspParamReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CableDspParamReply): CableDspParamReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 AnalysisCount = 1 [json_name = "AnalysisCount"];*/ 1:
                    message.analysisCount = reader.int32();
                    break;
                case /* int32 FreqUnit = 2 [json_name = "FreqUnit"];*/ 2:
                    message.freqUnit = reader.int32();
                    break;
                case /* bool UseSpeed = 3 [json_name = "UseSpeed"];*/ 3:
                    message.useSpeed = reader.bool();
                    break;
                case /* double RoteSpeed = 4 [json_name = "RoteSpeed"];*/ 4:
                    message.roteSpeed = reader.double();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CableDspParamReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 AnalysisCount = 1 [json_name = "AnalysisCount"]; */
        if (message.analysisCount !== 0)
            writer.tag(1, WireType.Varint).int32(message.analysisCount);
        /* int32 FreqUnit = 2 [json_name = "FreqUnit"]; */
        if (message.freqUnit !== 0)
            writer.tag(2, WireType.Varint).int32(message.freqUnit);
        /* bool UseSpeed = 3 [json_name = "UseSpeed"]; */
        if (message.useSpeed !== false)
            writer.tag(3, WireType.Varint).bool(message.useSpeed);
        /* double RoteSpeed = 4 [json_name = "RoteSpeed"]; */
        if (message.roteSpeed !== 0)
            writer.tag(4, WireType.Bit64).double(message.roteSpeed);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message storage.CableDspParamReply
 */
export const CableDspParamReply = new CableDspParamReply$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CepstrumDspParamReply$Type extends MessageType<CepstrumDspParamReply> {
    constructor() {
        super("storage.CepstrumDspParamReply", [
            { no: 1, name: "AnalysisCount", kind: "scalar", jsonName: "AnalysisCount", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<CepstrumDspParamReply>): CepstrumDspParamReply {
        const message = { analysisCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CepstrumDspParamReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CepstrumDspParamReply): CepstrumDspParamReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 AnalysisCount = 1 [json_name = "AnalysisCount"];*/ 1:
                    message.analysisCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CepstrumDspParamReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 AnalysisCount = 1 [json_name = "AnalysisCount"]; */
        if (message.analysisCount !== 0)
            writer.tag(1, WireType.Varint).int32(message.analysisCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message storage.CepstrumDspParamReply
 */
export const CepstrumDspParamReply = new CepstrumDspParamReply$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CrossPowerSpecDensDspParamReply$Type extends MessageType<CrossPowerSpecDensDspParamReply> {
    constructor() {
        super("storage.CrossPowerSpecDensDspParamReply", [
            { no: 1, name: "AnalysisPointCount", kind: "scalar", jsonName: "AnalysisPointCount", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<CrossPowerSpecDensDspParamReply>): CrossPowerSpecDensDspParamReply {
        const message = { analysisPointCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CrossPowerSpecDensDspParamReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CrossPowerSpecDensDspParamReply): CrossPowerSpecDensDspParamReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 AnalysisPointCount = 1 [json_name = "AnalysisPointCount"];*/ 1:
                    message.analysisPointCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CrossPowerSpecDensDspParamReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 AnalysisPointCount = 1 [json_name = "AnalysisPointCount"]; */
        if (message.analysisPointCount !== 0)
            writer.tag(1, WireType.Varint).int32(message.analysisPointCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message storage.CrossPowerSpecDensDspParamReply
 */
export const CrossPowerSpecDensDspParamReply = new CrossPowerSpecDensDspParamReply$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RainFlowDspParamReply$Type extends MessageType<RainFlowDspParamReply> {
    constructor() {
        super("storage.RainFlowDspParamReply", [
            { no: 1, name: "MeanRangeNum", kind: "scalar", jsonName: "MeanRangeNum", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "AmpRangeNum", kind: "scalar", jsonName: "AmpRangeNum", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<RainFlowDspParamReply>): RainFlowDspParamReply {
        const message = { meanRangeNum: 0, ampRangeNum: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RainFlowDspParamReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RainFlowDspParamReply): RainFlowDspParamReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 MeanRangeNum = 1 [json_name = "MeanRangeNum"];*/ 1:
                    message.meanRangeNum = reader.int32();
                    break;
                case /* int32 AmpRangeNum = 2 [json_name = "AmpRangeNum"];*/ 2:
                    message.ampRangeNum = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RainFlowDspParamReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 MeanRangeNum = 1 [json_name = "MeanRangeNum"]; */
        if (message.meanRangeNum !== 0)
            writer.tag(1, WireType.Varint).int32(message.meanRangeNum);
        /* int32 AmpRangeNum = 2 [json_name = "AmpRangeNum"]; */
        if (message.ampRangeNum !== 0)
            writer.tag(2, WireType.Varint).int32(message.ampRangeNum);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message storage.RainFlowDspParamReply
 */
export const RainFlowDspParamReply = new RainFlowDspParamReply$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VirtualComputeParamReply$Type extends MessageType<VirtualComputeParamReply> {
    constructor() {
        super("storage.VirtualComputeParamReply", [
            { no: 1, name: "CalculateType", kind: "scalar", jsonName: "CalculateType", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "DomainType", kind: "scalar", jsonName: "DomainType", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<VirtualComputeParamReply>): VirtualComputeParamReply {
        const message = { calculateType: 0, domainType: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<VirtualComputeParamReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: VirtualComputeParamReply): VirtualComputeParamReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 CalculateType = 1 [json_name = "CalculateType"];*/ 1:
                    message.calculateType = reader.int32();
                    break;
                case /* int32 DomainType = 2 [json_name = "DomainType"];*/ 2:
                    message.domainType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: VirtualComputeParamReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 CalculateType = 1 [json_name = "CalculateType"]; */
        if (message.calculateType !== 0)
            writer.tag(1, WireType.Varint).int32(message.calculateType);
        /* int32 DomainType = 2 [json_name = "DomainType"]; */
        if (message.domainType !== 0)
            writer.tag(2, WireType.Varint).int32(message.domainType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message storage.VirtualComputeParamReply
 */
export const VirtualComputeParamReply = new VirtualComputeParamReply$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PointParamReply$Type extends MessageType<PointParamReply> {
    constructor() {
        super("storage.PointParamReply", [
            { no: 1, name: "CycleSamplePointParam", kind: "message", jsonName: "CycleSamplePointParam", oneof: "inner", T: () => CycleSamplePointParamReply }
        ]);
    }
    create(value?: PartialMessage<PointParamReply>): PointParamReply {
        const message = { inner: { oneofKind: undefined } };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PointParamReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PointParamReply): PointParamReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* storage.CycleSamplePointParamReply CycleSamplePointParam = 1 [json_name = "CycleSamplePointParam"];*/ 1:
                    message.inner = {
                        oneofKind: "cycleSamplePointParam",
                        cycleSamplePointParam: CycleSamplePointParamReply.internalBinaryRead(reader, reader.uint32(), options, (message.inner as any).cycleSamplePointParam)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PointParamReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* storage.CycleSamplePointParamReply CycleSamplePointParam = 1 [json_name = "CycleSamplePointParam"]; */
        if (message.inner.oneofKind === "cycleSamplePointParam")
            CycleSamplePointParamReply.internalBinaryWrite(message.inner.cycleSamplePointParam, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message storage.PointParamReply
 */
export const PointParamReply = new PointParamReply$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CycleSamplePointParamReply$Type extends MessageType<CycleSamplePointParamReply> {
    constructor() {
        super("storage.CycleSamplePointParamReply", [
            { no: 1, name: "PointNumberPerCycle", kind: "scalar", jsonName: "PointNumberPerCycle", T: 2 /*ScalarType.FLOAT*/ },
            { no: 2, name: "CycleNumber", kind: "scalar", jsonName: "CycleNumber", T: 2 /*ScalarType.FLOAT*/ }
        ]);
    }
    create(value?: PartialMessage<CycleSamplePointParamReply>): CycleSamplePointParamReply {
        const message = { pointNumberPerCycle: 0, cycleNumber: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CycleSamplePointParamReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CycleSamplePointParamReply): CycleSamplePointParamReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* float PointNumberPerCycle = 1 [json_name = "PointNumberPerCycle"];*/ 1:
                    message.pointNumberPerCycle = reader.float();
                    break;
                case /* float CycleNumber = 2 [json_name = "CycleNumber"];*/ 2:
                    message.cycleNumber = reader.float();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CycleSamplePointParamReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* float PointNumberPerCycle = 1 [json_name = "PointNumberPerCycle"]; */
        if (message.pointNumberPerCycle !== 0)
            writer.tag(1, WireType.Bit32).float(message.pointNumberPerCycle);
        /* float CycleNumber = 2 [json_name = "CycleNumber"]; */
        if (message.cycleNumber !== 0)
            writer.tag(2, WireType.Bit32).float(message.cycleNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message storage.CycleSamplePointParamReply
 */
export const CycleSamplePointParamReply = new CycleSamplePointParamReply$Type();
