// @generated by protobuf-ts 2.9.1
// @generated from protobuf file "protos/calculatewavedata.proto" (package "storage", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { HistoryPointDataReply } from "./historypointdatareply";
/**
 * @generated from protobuf message storage.CalculateWaveDataRequest
 */
export interface CalculateWaveDataRequest {
    /**
     * @generated from protobuf field: string BeginTime = 1 [json_name = "BeginTime"];
     */
    beginTime: string;
    /**
     * @generated from protobuf field: string EndTime = 2 [json_name = "EndTime"];
     */
    endTime: string;
    /**
     * @generated from protobuf field: int32 Limit = 3 [json_name = "Limit"];
     */
    limit: number;
    /**
     * @generated from protobuf field: repeated storage.PointAlgorithmParamRequest Points = 4 [json_name = "Points"];
     */
    points: PointAlgorithmParamRequest[];
    /**
     * @generated from protobuf field: bool IsExternal = 5 [json_name = "IsExternal"];
     */
    isExternal: boolean;
    /**
     * @generated from protobuf field: bool IncludeAlarm = 6 [json_name = "IncludeAlarm"];
     */
    includeAlarm: boolean;
}
/**
 * @generated from protobuf message storage.PointAlgorithmParamRequest
 */
export interface PointAlgorithmParamRequest {
    /**
     * @generated from protobuf field: int32 PointId = 1 [json_name = "PointId"];
     */
    pointId: number;
    /**
     * @generated from protobuf field: string AlgorithmParam = 2 [json_name = "AlgorithmParam"];
     */
    algorithmParam: string;
    /**
     * @generated from protobuf field: int32 AlgorithmType = 3 [json_name = "AlgorithmType"];
     */
    algorithmType: number;
}
/**
 * @generated from protobuf message storage.CalculateWaveDataReply
 */
export interface CalculateWaveDataReply {
    /**
     * @generated from protobuf field: repeated storage.HistoryPointDataReply Points = 1 [json_name = "Points"];
     */
    points: HistoryPointDataReply[];
}
// @generated message type with reflection information, may provide speed optimized methods
class CalculateWaveDataRequest$Type extends MessageType<CalculateWaveDataRequest> {
    constructor() {
        super("storage.CalculateWaveDataRequest", [
            { no: 1, name: "BeginTime", kind: "scalar", jsonName: "BeginTime", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "EndTime", kind: "scalar", jsonName: "EndTime", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "Limit", kind: "scalar", jsonName: "Limit", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "Points", kind: "message", jsonName: "Points", repeat: 1 /*RepeatType.PACKED*/, T: () => PointAlgorithmParamRequest },
            { no: 5, name: "IsExternal", kind: "scalar", jsonName: "IsExternal", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "IncludeAlarm", kind: "scalar", jsonName: "IncludeAlarm", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<CalculateWaveDataRequest>): CalculateWaveDataRequest {
        const message = { beginTime: "", endTime: "", limit: 0, points: [], isExternal: false, includeAlarm: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CalculateWaveDataRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CalculateWaveDataRequest): CalculateWaveDataRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string BeginTime = 1 [json_name = "BeginTime"];*/ 1:
                    message.beginTime = reader.string();
                    break;
                case /* string EndTime = 2 [json_name = "EndTime"];*/ 2:
                    message.endTime = reader.string();
                    break;
                case /* int32 Limit = 3 [json_name = "Limit"];*/ 3:
                    message.limit = reader.int32();
                    break;
                case /* repeated storage.PointAlgorithmParamRequest Points = 4 [json_name = "Points"];*/ 4:
                    message.points.push(PointAlgorithmParamRequest.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bool IsExternal = 5 [json_name = "IsExternal"];*/ 5:
                    message.isExternal = reader.bool();
                    break;
                case /* bool IncludeAlarm = 6 [json_name = "IncludeAlarm"];*/ 6:
                    message.includeAlarm = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CalculateWaveDataRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string BeginTime = 1 [json_name = "BeginTime"]; */
        if (message.beginTime !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.beginTime);
        /* string EndTime = 2 [json_name = "EndTime"]; */
        if (message.endTime !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.endTime);
        /* int32 Limit = 3 [json_name = "Limit"]; */
        if (message.limit !== 0)
            writer.tag(3, WireType.Varint).int32(message.limit);
        /* repeated storage.PointAlgorithmParamRequest Points = 4 [json_name = "Points"]; */
        for (let i = 0; i < message.points.length; i++)
            PointAlgorithmParamRequest.internalBinaryWrite(message.points[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* bool IsExternal = 5 [json_name = "IsExternal"]; */
        if (message.isExternal !== false)
            writer.tag(5, WireType.Varint).bool(message.isExternal);
        /* bool IncludeAlarm = 6 [json_name = "IncludeAlarm"]; */
        if (message.includeAlarm !== false)
            writer.tag(6, WireType.Varint).bool(message.includeAlarm);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message storage.CalculateWaveDataRequest
 */
export const CalculateWaveDataRequest = new CalculateWaveDataRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PointAlgorithmParamRequest$Type extends MessageType<PointAlgorithmParamRequest> {
    constructor() {
        super("storage.PointAlgorithmParamRequest", [
            { no: 1, name: "PointId", kind: "scalar", jsonName: "PointId", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "AlgorithmParam", kind: "scalar", jsonName: "AlgorithmParam", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "AlgorithmType", kind: "scalar", jsonName: "AlgorithmType", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<PointAlgorithmParamRequest>): PointAlgorithmParamRequest {
        const message = { pointId: 0, algorithmParam: "", algorithmType: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PointAlgorithmParamRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PointAlgorithmParamRequest): PointAlgorithmParamRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 PointId = 1 [json_name = "PointId"];*/ 1:
                    message.pointId = reader.int32();
                    break;
                case /* string AlgorithmParam = 2 [json_name = "AlgorithmParam"];*/ 2:
                    message.algorithmParam = reader.string();
                    break;
                case /* int32 AlgorithmType = 3 [json_name = "AlgorithmType"];*/ 3:
                    message.algorithmType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PointAlgorithmParamRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 PointId = 1 [json_name = "PointId"]; */
        if (message.pointId !== 0)
            writer.tag(1, WireType.Varint).int32(message.pointId);
        /* string AlgorithmParam = 2 [json_name = "AlgorithmParam"]; */
        if (message.algorithmParam !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.algorithmParam);
        /* int32 AlgorithmType = 3 [json_name = "AlgorithmType"]; */
        if (message.algorithmType !== 0)
            writer.tag(3, WireType.Varint).int32(message.algorithmType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message storage.PointAlgorithmParamRequest
 */
export const PointAlgorithmParamRequest = new PointAlgorithmParamRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CalculateWaveDataReply$Type extends MessageType<CalculateWaveDataReply> {
    constructor() {
        super("storage.CalculateWaveDataReply", [
            { no: 1, name: "Points", kind: "message", jsonName: "Points", repeat: 1 /*RepeatType.PACKED*/, T: () => HistoryPointDataReply }
        ]);
    }
    create(value?: PartialMessage<CalculateWaveDataReply>): CalculateWaveDataReply {
        const message = { points: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CalculateWaveDataReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CalculateWaveDataReply): CalculateWaveDataReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated storage.HistoryPointDataReply Points = 1 [json_name = "Points"];*/ 1:
                    message.points.push(HistoryPointDataReply.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CalculateWaveDataReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated storage.HistoryPointDataReply Points = 1 [json_name = "Points"]; */
        for (let i = 0; i < message.points.length; i++)
            HistoryPointDataReply.internalBinaryWrite(message.points[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message storage.CalculateWaveDataReply
 */
export const CalculateWaveDataReply = new CalculateWaveDataReply$Type();
