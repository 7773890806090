// @generated by protobuf-ts 2.9.1
// @generated from protobuf file "protos/frgraphdata.proto" (package "component", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { GraphDataReply } from "./graphdatareply";
/**
 * @generated from protobuf message component.GetFrGraphDataRequest
 */
export interface GetFrGraphDataRequest {
    /**
     * @generated from protobuf field: int32 PointId = 1 [json_name = "PointId"];
     */
    pointId: number;
    /**
     * @generated from protobuf field: float SampleFreq = 2 [json_name = "SampleFreq"];
     */
    sampleFreq: number;
    /**
     * @generated from protobuf field: component.IIRParamRequest GraphParam = 3 [json_name = "GraphParam"];
     */
    graphParam?: IIRParamRequest;
}
/**
 * @generated from protobuf message component.IIRParamRequest
 */
export interface IIRParamRequest {
    /**
     * @generated from protobuf field: int32 FilterMode = 1 [json_name = "FilterMode"];
     */
    filterMode: number;
    /**
     * @generated from protobuf field: int32 FilterType = 2 [json_name = "FilterType"];
     */
    filterType: number;
    /**
     * @generated from protobuf field: double PassbandFreq = 3 [json_name = "PassbandFreq"];
     */
    passbandFreq: number;
    /**
     * @generated from protobuf field: double StopbandFreq = 4 [json_name = "StopbandFreq"];
     */
    stopbandFreq: number;
    /**
     * @generated from protobuf field: double PassbandLowerFreq = 5 [json_name = "PassbandLowerFreq"];
     */
    passbandLowerFreq: number;
    /**
     * @generated from protobuf field: double PassbandUpperFreq = 6 [json_name = "PassbandUpperFreq"];
     */
    passbandUpperFreq: number;
    /**
     * @generated from protobuf field: double StopbandLowerFreq = 7 [json_name = "StopbandLowerFreq"];
     */
    stopbandLowerFreq: number;
    /**
     * @generated from protobuf field: double StopbandUpperFreq = 8 [json_name = "StopbandUpperFreq"];
     */
    stopbandUpperFreq: number;
    /**
     * @generated from protobuf field: double PassbandFlat = 9 [json_name = "PassbandFlat"];
     */
    passbandFlat: number;
    /**
     * @generated from protobuf field: double StopbandDecay = 10 [json_name = "StopbandDecay"];
     */
    stopbandDecay: number;
}
/**
 * @generated from protobuf message component.GetFrGraphDataReply
 */
export interface GetFrGraphDataReply {
    /**
     * @generated from protobuf field: repeated component.GraphDataReply Datas = 1 [json_name = "Datas"];
     */
    datas: GraphDataReply[];
    /**
     * @generated from protobuf field: int32 MinOrder = 2 [json_name = "MinOrder"];
     */
    minOrder: number;
}
// @generated message type with reflection information, may provide speed optimized methods
class GetFrGraphDataRequest$Type extends MessageType<GetFrGraphDataRequest> {
    constructor() {
        super("component.GetFrGraphDataRequest", [
            { no: 1, name: "PointId", kind: "scalar", jsonName: "PointId", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "SampleFreq", kind: "scalar", jsonName: "SampleFreq", T: 2 /*ScalarType.FLOAT*/ },
            { no: 3, name: "GraphParam", kind: "message", jsonName: "GraphParam", T: () => IIRParamRequest }
        ]);
    }
    create(value?: PartialMessage<GetFrGraphDataRequest>): GetFrGraphDataRequest {
        const message = { pointId: 0, sampleFreq: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetFrGraphDataRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetFrGraphDataRequest): GetFrGraphDataRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 PointId = 1 [json_name = "PointId"];*/ 1:
                    message.pointId = reader.int32();
                    break;
                case /* float SampleFreq = 2 [json_name = "SampleFreq"];*/ 2:
                    message.sampleFreq = reader.float();
                    break;
                case /* component.IIRParamRequest GraphParam = 3 [json_name = "GraphParam"];*/ 3:
                    message.graphParam = IIRParamRequest.internalBinaryRead(reader, reader.uint32(), options, message.graphParam);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetFrGraphDataRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 PointId = 1 [json_name = "PointId"]; */
        if (message.pointId !== 0)
            writer.tag(1, WireType.Varint).int32(message.pointId);
        /* float SampleFreq = 2 [json_name = "SampleFreq"]; */
        if (message.sampleFreq !== 0)
            writer.tag(2, WireType.Bit32).float(message.sampleFreq);
        /* component.IIRParamRequest GraphParam = 3 [json_name = "GraphParam"]; */
        if (message.graphParam)
            IIRParamRequest.internalBinaryWrite(message.graphParam, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message component.GetFrGraphDataRequest
 */
export const GetFrGraphDataRequest = new GetFrGraphDataRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IIRParamRequest$Type extends MessageType<IIRParamRequest> {
    constructor() {
        super("component.IIRParamRequest", [
            { no: 1, name: "FilterMode", kind: "scalar", jsonName: "FilterMode", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "FilterType", kind: "scalar", jsonName: "FilterType", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "PassbandFreq", kind: "scalar", jsonName: "PassbandFreq", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 4, name: "StopbandFreq", kind: "scalar", jsonName: "StopbandFreq", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 5, name: "PassbandLowerFreq", kind: "scalar", jsonName: "PassbandLowerFreq", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 6, name: "PassbandUpperFreq", kind: "scalar", jsonName: "PassbandUpperFreq", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 7, name: "StopbandLowerFreq", kind: "scalar", jsonName: "StopbandLowerFreq", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 8, name: "StopbandUpperFreq", kind: "scalar", jsonName: "StopbandUpperFreq", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 9, name: "PassbandFlat", kind: "scalar", jsonName: "PassbandFlat", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 10, name: "StopbandDecay", kind: "scalar", jsonName: "StopbandDecay", T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value?: PartialMessage<IIRParamRequest>): IIRParamRequest {
        const message = { filterMode: 0, filterType: 0, passbandFreq: 0, stopbandFreq: 0, passbandLowerFreq: 0, passbandUpperFreq: 0, stopbandLowerFreq: 0, stopbandUpperFreq: 0, passbandFlat: 0, stopbandDecay: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<IIRParamRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: IIRParamRequest): IIRParamRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 FilterMode = 1 [json_name = "FilterMode"];*/ 1:
                    message.filterMode = reader.int32();
                    break;
                case /* int32 FilterType = 2 [json_name = "FilterType"];*/ 2:
                    message.filterType = reader.int32();
                    break;
                case /* double PassbandFreq = 3 [json_name = "PassbandFreq"];*/ 3:
                    message.passbandFreq = reader.double();
                    break;
                case /* double StopbandFreq = 4 [json_name = "StopbandFreq"];*/ 4:
                    message.stopbandFreq = reader.double();
                    break;
                case /* double PassbandLowerFreq = 5 [json_name = "PassbandLowerFreq"];*/ 5:
                    message.passbandLowerFreq = reader.double();
                    break;
                case /* double PassbandUpperFreq = 6 [json_name = "PassbandUpperFreq"];*/ 6:
                    message.passbandUpperFreq = reader.double();
                    break;
                case /* double StopbandLowerFreq = 7 [json_name = "StopbandLowerFreq"];*/ 7:
                    message.stopbandLowerFreq = reader.double();
                    break;
                case /* double StopbandUpperFreq = 8 [json_name = "StopbandUpperFreq"];*/ 8:
                    message.stopbandUpperFreq = reader.double();
                    break;
                case /* double PassbandFlat = 9 [json_name = "PassbandFlat"];*/ 9:
                    message.passbandFlat = reader.double();
                    break;
                case /* double StopbandDecay = 10 [json_name = "StopbandDecay"];*/ 10:
                    message.stopbandDecay = reader.double();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: IIRParamRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 FilterMode = 1 [json_name = "FilterMode"]; */
        if (message.filterMode !== 0)
            writer.tag(1, WireType.Varint).int32(message.filterMode);
        /* int32 FilterType = 2 [json_name = "FilterType"]; */
        if (message.filterType !== 0)
            writer.tag(2, WireType.Varint).int32(message.filterType);
        /* double PassbandFreq = 3 [json_name = "PassbandFreq"]; */
        if (message.passbandFreq !== 0)
            writer.tag(3, WireType.Bit64).double(message.passbandFreq);
        /* double StopbandFreq = 4 [json_name = "StopbandFreq"]; */
        if (message.stopbandFreq !== 0)
            writer.tag(4, WireType.Bit64).double(message.stopbandFreq);
        /* double PassbandLowerFreq = 5 [json_name = "PassbandLowerFreq"]; */
        if (message.passbandLowerFreq !== 0)
            writer.tag(5, WireType.Bit64).double(message.passbandLowerFreq);
        /* double PassbandUpperFreq = 6 [json_name = "PassbandUpperFreq"]; */
        if (message.passbandUpperFreq !== 0)
            writer.tag(6, WireType.Bit64).double(message.passbandUpperFreq);
        /* double StopbandLowerFreq = 7 [json_name = "StopbandLowerFreq"]; */
        if (message.stopbandLowerFreq !== 0)
            writer.tag(7, WireType.Bit64).double(message.stopbandLowerFreq);
        /* double StopbandUpperFreq = 8 [json_name = "StopbandUpperFreq"]; */
        if (message.stopbandUpperFreq !== 0)
            writer.tag(8, WireType.Bit64).double(message.stopbandUpperFreq);
        /* double PassbandFlat = 9 [json_name = "PassbandFlat"]; */
        if (message.passbandFlat !== 0)
            writer.tag(9, WireType.Bit64).double(message.passbandFlat);
        /* double StopbandDecay = 10 [json_name = "StopbandDecay"]; */
        if (message.stopbandDecay !== 0)
            writer.tag(10, WireType.Bit64).double(message.stopbandDecay);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message component.IIRParamRequest
 */
export const IIRParamRequest = new IIRParamRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetFrGraphDataReply$Type extends MessageType<GetFrGraphDataReply> {
    constructor() {
        super("component.GetFrGraphDataReply", [
            { no: 1, name: "Datas", kind: "message", jsonName: "Datas", repeat: 1 /*RepeatType.PACKED*/, T: () => GraphDataReply },
            { no: 2, name: "MinOrder", kind: "scalar", jsonName: "MinOrder", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<GetFrGraphDataReply>): GetFrGraphDataReply {
        const message = { datas: [], minOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetFrGraphDataReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetFrGraphDataReply): GetFrGraphDataReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated component.GraphDataReply Datas = 1 [json_name = "Datas"];*/ 1:
                    message.datas.push(GraphDataReply.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 MinOrder = 2 [json_name = "MinOrder"];*/ 2:
                    message.minOrder = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetFrGraphDataReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated component.GraphDataReply Datas = 1 [json_name = "Datas"]; */
        for (let i = 0; i < message.datas.length; i++)
            GraphDataReply.internalBinaryWrite(message.datas[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 MinOrder = 2 [json_name = "MinOrder"]; */
        if (message.minOrder !== 0)
            writer.tag(2, WireType.Varint).int32(message.minOrder);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message component.GetFrGraphDataReply
 */
export const GetFrGraphDataReply = new GetFrGraphDataReply$Type();
