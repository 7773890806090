// @generated by protobuf-ts 2.9.1
// @generated from protobuf file "protos/alarmpointsrangewavedatas.proto" (package "alarm", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { HistoryPointDatasReply } from "./historypointdatasreply";
import { Int32Value } from "../google/protobuf/wrappers";
/**
 * @generated from protobuf message alarm.QueryAlarmPointsRangeWaveDatasRequest
 */
export interface QueryAlarmPointsRangeWaveDatasRequest {
    /**
     * @generated from protobuf field: string StartTime = 1 [json_name = "StartTime"];
     */
    startTime: string;
    /**
     * @generated from protobuf field: string EndTime = 2 [json_name = "EndTime"];
     */
    endTime: string;
    /**
     * @generated from protobuf field: string EventId = 3 [json_name = "EventId"];
     */
    eventId: string;
    /**
     * @generated from protobuf field: google.protobuf.Int32Value Limit = 4 [json_name = "Limit"];
     */
    limit?: Int32Value;
    /**
     * @generated from protobuf field: repeated int32 PointIds = 5 [json_name = "PointIds"];
     */
    pointIds: number[];
}
/**
 * @generated from protobuf message alarm.QueryAlarmPointsRangeWaveDatasReply
 */
export interface QueryAlarmPointsRangeWaveDatasReply {
    /**
     * @generated from protobuf field: repeated storage.HistoryPointDatasReply Points = 1 [json_name = "Points"];
     */
    points: HistoryPointDatasReply[];
}
// @generated message type with reflection information, may provide speed optimized methods
class QueryAlarmPointsRangeWaveDatasRequest$Type extends MessageType<QueryAlarmPointsRangeWaveDatasRequest> {
    constructor() {
        super("alarm.QueryAlarmPointsRangeWaveDatasRequest", [
            { no: 1, name: "StartTime", kind: "scalar", jsonName: "StartTime", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "EndTime", kind: "scalar", jsonName: "EndTime", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "EventId", kind: "scalar", jsonName: "EventId", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "Limit", kind: "message", jsonName: "Limit", T: () => Int32Value },
            { no: 5, name: "PointIds", kind: "scalar", jsonName: "PointIds", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<QueryAlarmPointsRangeWaveDatasRequest>): QueryAlarmPointsRangeWaveDatasRequest {
        const message = { startTime: "", endTime: "", eventId: "", pointIds: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<QueryAlarmPointsRangeWaveDatasRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: QueryAlarmPointsRangeWaveDatasRequest): QueryAlarmPointsRangeWaveDatasRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string StartTime = 1 [json_name = "StartTime"];*/ 1:
                    message.startTime = reader.string();
                    break;
                case /* string EndTime = 2 [json_name = "EndTime"];*/ 2:
                    message.endTime = reader.string();
                    break;
                case /* string EventId = 3 [json_name = "EventId"];*/ 3:
                    message.eventId = reader.string();
                    break;
                case /* google.protobuf.Int32Value Limit = 4 [json_name = "Limit"];*/ 4:
                    message.limit = Int32Value.internalBinaryRead(reader, reader.uint32(), options, message.limit);
                    break;
                case /* repeated int32 PointIds = 5 [json_name = "PointIds"];*/ 5:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.pointIds.push(reader.int32());
                    else
                        message.pointIds.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: QueryAlarmPointsRangeWaveDatasRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string StartTime = 1 [json_name = "StartTime"]; */
        if (message.startTime !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.startTime);
        /* string EndTime = 2 [json_name = "EndTime"]; */
        if (message.endTime !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.endTime);
        /* string EventId = 3 [json_name = "EventId"]; */
        if (message.eventId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.eventId);
        /* google.protobuf.Int32Value Limit = 4 [json_name = "Limit"]; */
        if (message.limit)
            Int32Value.internalBinaryWrite(message.limit, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* repeated int32 PointIds = 5 [json_name = "PointIds"]; */
        if (message.pointIds.length) {
            writer.tag(5, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.pointIds.length; i++)
                writer.int32(message.pointIds[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message alarm.QueryAlarmPointsRangeWaveDatasRequest
 */
export const QueryAlarmPointsRangeWaveDatasRequest = new QueryAlarmPointsRangeWaveDatasRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class QueryAlarmPointsRangeWaveDatasReply$Type extends MessageType<QueryAlarmPointsRangeWaveDatasReply> {
    constructor() {
        super("alarm.QueryAlarmPointsRangeWaveDatasReply", [
            { no: 1, name: "Points", kind: "message", jsonName: "Points", repeat: 1 /*RepeatType.PACKED*/, T: () => HistoryPointDatasReply }
        ]);
    }
    create(value?: PartialMessage<QueryAlarmPointsRangeWaveDatasReply>): QueryAlarmPointsRangeWaveDatasReply {
        const message = { points: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<QueryAlarmPointsRangeWaveDatasReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: QueryAlarmPointsRangeWaveDatasReply): QueryAlarmPointsRangeWaveDatasReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated storage.HistoryPointDatasReply Points = 1 [json_name = "Points"];*/ 1:
                    message.points.push(HistoryPointDatasReply.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: QueryAlarmPointsRangeWaveDatasReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated storage.HistoryPointDatasReply Points = 1 [json_name = "Points"]; */
        for (let i = 0; i < message.points.length; i++)
            HistoryPointDatasReply.internalBinaryWrite(message.points[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message alarm.QueryAlarmPointsRangeWaveDatasReply
 */
export const QueryAlarmPointsRangeWaveDatasReply = new QueryAlarmPointsRangeWaveDatasReply$Type();
