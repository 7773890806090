import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import axios from '@common/js/axios'
import { setOrderDepartment, setOrgs } from '@common/js/utils'
import { ParentGuid } from './enum'

// 创建一个新的 store 实例
const store = createStore({
	plugins: [
		createPersistedState({
			storage: window.sessionStorage,
			key: 'phm',
		}),
	],
	state() {
		let shrink = Config.isShrink || false
		return {
			browseType: 'pc',
			userInfo: {},
			currentDep: {},
			appDep: {},
			appDevice: {},
			appFaultDevice: {},
			appFault: {},
			appPoint: {},
			licenseInfo: {},
			lastDep: null,
			depTree: {},
			graphSetting: {},
			currentTime: '',
			enableCalendar: false,
			currentPoint: {},
			showPlatForm: false,
			historyTimeChange: false,
			isCollapse: false,
			allNode: [],
			userAuth: {},
			exportDataParam: {},
			eigenTypeList: [],
			warningCount: 0,
			eventCount: 0,
			warningStatus: false,
			deviceOnlineStatus: false,
			alarmOpenStatus: false,
			uiMode: 0, 
			primaryColor: '#409eff',
			theme: 'normal',
			lang: 'CN',
			shrink: shrink,
			soundAlarmSrc: '',
			alarmInfo: [],
			originTree: [],
			treeNodes: [],
			platformConfig: {},
			alarmConfig: {},
			userAlarmPathConfig: null,
			alarmRelateInfo: {},
			customColor: {
				background: '#F9FAFC',
				card: '#fff',
				border: '#eee',
				title: '#333',
				mainText: '#666',
				tip: '#ccc',
			},
			copyedNodeInfo: {},
			departTypes: [],
			deviceLevels: [],
			departAndDeviceConfig: {},
			isDeviceOfflineStatus: false
		}
	},
	mutations: {
		reset(state, payload) {
			for (const key in state) {
				const type = Object.prototype.toString.call(state[key])
				switch (type) {
					case '[object Boolean]':
						if (key !== 'historyTimeChange' && key !== 'warningStatus' && key !== 'alarmOpenStatus' && key !== 'deviceOnlineStatus') {
							state[key] = false
						}
						break
					case '[object Object]':
						if (key !== 'customColor' && key !== 'graphSetting' && key !== 'licenseInfo') {
							state[key] = {}
						}
						break
					case '[object String]':
						if (key !== 'primaryColor' && key !== 'theme' && key !== 'lang' && key !== 'browseType') {
							state[key] = ''
						}
						break
					case '[object Array]':
						if (key !== 'alarmInfo') {
							state[key] = []
						}
						break
					case '[object Number]':
						state[key] = 0
						break
					default:
						state[key] = {}
						break
				}
			}
		},
		setBrowseType(state, payload) {
			state.browseType = payload.browseType
		},
		setAlarmSrc(state, payload) {
			state.soundAlarmSrc = payload.soundAlarmSrc
		},
		setLang(state, payload) {
			state.lang = payload.lang
		},
		setLicenseInfo(state, payload) {
			state.licenseInfo = payload.licenseInfo
		},
		setExportDataParam(state, payload) {
			state.exportDataParam = payload.exportDataParam
		},
		setShrink(state, payload) {
			state.shrink = payload.shrink
		},
		setGraphSetting(state, payload) {
			if (JSON.stringify(payload) != "{}") {
				state.graphSetting = payload.graphSetting;
			}
		},
		updateGraphSetting(state, payload) {
			if (state.graphSetting[payload.property]) {
				state.graphSetting[payload.property] = payload.value;
			}
		},
		setWarningStatus(state, payload) {
			state.warningStatus = payload.warningStatus
		},
		setDeviceOnlineStatus(state, payload) {
			state.deviceOnlineStatus = payload.deviceOnlineStatus
		},
		setAlarmOpenStatus(state, payload) {
			state.alarmOpenStatus = payload.alarmOpenStatus
		},
		setUiMode(state, payload) {
			state.uiMode = payload.uiMode
		},
		setWarningCount(state, payload) {
			state.warningCount = payload.warningCount
		},
		setEventCount(state, payload) {
			state.eventCount = payload.eventCount
		},
		setPrimaryColor(state, payload) {
			state.primaryColor = payload.primaryColor
		},
		setTheme(state, payload) {
			state.theme = payload.theme
		},
		setThemeColor(state, payload) {
			state.customColor = payload.theme
		},
		setUserInfo(state, payload) {
			state.userInfo = payload.userInfo
		},
		updateUserInfo(state, payload) {
			for (let key in payload) {
				state.userInfo[key] = payload[key];
			}
		},
		setCurrentDep(state, payload) {
			state.currentDep = payload.currentDep
		},
		setAppDep(state, payload) {
			state.appDep = payload.appDep
		},
		setAppDevice(state, payload) {
			state.appDevice = payload.appDevice
		},
		setAppFault(state, payload) {
			state.appFault = payload.appFault
		},
		setAppPoint(state, payload) {
			state.appPoint = payload.appPoint
		},
		setAppFaultDevice(state, payload) {
			state.appFaultDevice = payload.appFaultDevice
		},
		setLastDep(state, payload) {
			state.lastDep = payload.lastDep
		},
		setCalendar(state, payload) {
			state.enableCalendar = payload.enableCalendar
		},
		setHistoryTimeChange(state, payload) {
			state.historyTimeChange = payload.historyTimeChange
		},
		setCurrentTime(state, payload) {
			state.currentTime = payload.currentTime
		},
		setCurrentPoint(state, payload) {
			state.currentPoint = payload.currentPoint
		},
		setDepTree(state, payload) {
			state.depTree = payload.depTree
		},
		setUserAuth(state, payload) {
			state.userAuth = payload.userAuth
		},
		appendDepTree(state, payload) {
			const { parentPath, data } = payload
			const findTree = (path) => {
				for (let index = 0; index < state.depTree.length; index++) {
					const element = state.depTree[index]
					if (element.path === path) {
						element.children.push(data)
						return state.depTree
					}
					if (element.children && element.children.length > 0) {
						findTree(element.path)
					}
				}
			}
			const tree = findTree(parentPath)
		},
		setShowPlatForm(state, payload) {
			state.showPlatForm = payload.showPlatForm
		},
		setAllNode(state, payload) {
			state.allNode = payload.allNode
		},
		setEigens(state, payload) {
			state.eigenTypeList = payload.eigenTypeList
		},
		setCollapse(state, payload) {
			state.isCollapse = payload.isCollapse
		},
		setAlarmInfo(state, payload) {
			state.alarmInfo = payload.alarmInfo;
		},
		updateAlarmConfig(state, payload) {
			for (let key in payload) {
				state.alarmConfig[key] = payload[key];
			}
		},
		setAlarmConfig(state, payload) {
			state.alarmConfig = payload.alarmConfig;
		},
		setUserAlarmPathConfig(state, payload) {
			state.userAlarmPathConfig = payload.userAlarmPathConfig
		},
		setAlarmRelateInfo(state, payload) {
			state.alarmRelateInfo = payload.alarmRelateInfo;
		},
		setCopyedNodeInfo(state, payload) {
			state.copyedNodeInfo = payload.copyedNodeInfo;
		},
		setDepartTypes(state, payload) {
			state.departTypes = payload.departTypes
		},
		setOriginTree(state, payload) {
			state.originTree = payload.originTree
		},
		setTreeNodes(state, payload) {
			state.treeNodes = payload.treeNodes
		},
		updateOriginTree(state, payload) {
			if (payload.type === 'add') {
				payload.data.IsVisible = true;
				payload.data.ParentId = payload.data.ParentId || payload.data.DepartmentId;
				state.originTree.push(payload.data);
			}
			if (payload.type === 'remove') {
				const index = state.originTree.findIndex(t => t.Id === payload.data.Id);
				if (index >= 0) {
					state.originTree.splice(index, 1);
				}
			}
			if (payload.type === 'update') {
				const index = state.originTree.findIndex(t => t.Id === payload.data.Id);
				if (index >= 0) {
					state.originTree[index].Name = payload.data.Name;
					if (payload.data.DepartmentType != null) {
						state.originTree[index].DepartmentType = payload.data.DepartmentType;
					}
					if (payload.data.DeviceGrade != null) {
						state.originTree[index].DeviceGrade = payload.data.DeviceGrade;
					}
				}
			}
			state.treeNodes = setOrderDepartment(state.originTree.filter(item => item.IsVisible).sort((a, b) => a.DisplayIndex - b.DisplayIndex), ParentGuid);
		},
		setPlatformConfig(state, payload) {
			state.platformConfig = payload.platformConfig
		},
		setDeviceLevels(state, payload) {
			state.deviceLevels = payload.deviceLevels
		},
		setDepartAndDeviceConfig(state, payload) {
			state.departAndDeviceConfig = payload.departAndDeviceConfig
		},
		setIsDeviceOfflineStatus(state, payload) {
			state.isDeviceOfflineStatus = payload.isDeviceOfflineStatus
		}
	},
	actions: {
		getOrgs({ dispatch, commit }, current) {
			return axios({
				method: 'post',
				url: '/Tree/GetAll',
				data: {
					'parentId': '0001',
					'nodeType': 0,
					'name': '',
					'displayIndex': 0,
				},
			}).then((res) => {
				res.Result[0].ParentId = 0
				commit('setAllNode', { allNode: res.Result })
				res.Result = res.Result.filter((item) => item.category !== 9)
				const data = setOrgs(res.Result, 0)
				return data
			})
		},
		getEigens() {
			// no permisson control	
			axios({
				method: 'get',
				url: 'Eigen/all/1',
			}).then((res) => {
				if (res.IsSuccess) {
					this.commit('setEigens', { eigenTypeList: res.Result })
				}
			})
		},
		async getAllDepartmentType() {
			// no permisson control	
			return axios({
				method: 'get',
				url: '/DepartmentType/all',
			}).then((res) => {
				if (res.IsSuccess) {
					this.commit('setDepartTypes', { departTypes: res.Result })
				}
			})
		},
		async getAllDeviceLevels() {
			return axios({
				method: 'get',
				url: '/DeviceGrade/all',
			}).then((res) => {
				if (res.IsSuccess) {
					this.commit('setDeviceLevels', { deviceLevels: res.Result })
				}
			})
		},
		getTree() {
			axios({
				method: 'get',
				url: '/Tree/tree',
			}).then((res) => {
				if (res.IsSuccess) {
					res.Result.forEach(item => {
						item.Disabled = !item.IsVisible
					})
					this.commit('setOriginTree', { originTree: res.Result });
					this.commit('setTreeNodes', { treeNodes: setOrderDepartment(res.Result.sort((a, b) => a.DisplayIndex - b.DisplayIndex), ParentGuid) })
				}
			})
		},
		getDepartDeviceConfig() {
			axios({
				method: 'get',
				url: '/DepartmentDeviceConfig',
			}).then((res) => {
				if (res.IsSuccess) {
					this.commit('setDepartAndDeviceConfig', { departAndDeviceConfig: res.Result });
				}
			})
		}
	},
})

export default store
