// @generated by protobuf-ts 2.9.1
// @generated from protobuf file "protos/data.proto" (package "storage", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { Data } from "./data";
import type { QueryNavDiluteDatasRequest } from "./dilutedatas";
import type { QueryHistoryDiluteDatasRequest } from "./dilutedatas";
import type { GetNearestRotateSpeedRequest } from "./nearestrotatespeed";
import type { CalculateWaveDataRequest } from "./calculatewavedata";
import type { GetFirstWaveDataRequest } from "./firstwavedata";
import type { QueryLatestWaveDataRequest } from "./latestwavedata";
import type { QueryLatestEigenDatasRequest } from "./latesteigendatas";
import type { GetDatasRequest } from "./getdatas";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { Reply } from "./reply";
import type { GetRangeWaveDatasRequest } from "./rangewavedatas";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * The greeting service definition.
 *
 * @generated from protobuf service storage.Data
 */
export interface IDataClient {
    /**
     * Sends a greeting
     *
     * @generated from protobuf rpc: GetRangeWaveDatas(storage.GetRangeWaveDatasRequest) returns (storage.Reply);
     */
    getRangeWaveDatas(input: GetRangeWaveDatasRequest, options?: RpcOptions): UnaryCall<GetRangeWaveDatasRequest, Reply>;
    /**
     * @generated from protobuf rpc: GetDatas(storage.GetDatasRequest) returns (storage.Reply);
     */
    getDatas(input: GetDatasRequest, options?: RpcOptions): UnaryCall<GetDatasRequest, Reply>;
    /**
     * @generated from protobuf rpc: GetEigenDatas(storage.GetDatasRequest) returns (storage.Reply);
     */
    getEigenDatas(input: GetDatasRequest, options?: RpcOptions): UnaryCall<GetDatasRequest, Reply>;
    /**
     * @generated from protobuf rpc: GetEigenDatasByLowerlimit(storage.GetDatasRequest) returns (storage.Reply);
     */
    getEigenDatasByLowerlimit(input: GetDatasRequest, options?: RpcOptions): UnaryCall<GetDatasRequest, Reply>;
    /**
     * @generated from protobuf rpc: QueryLatestEigenDatas(storage.QueryLatestEigenDatasRequest) returns (storage.Reply);
     */
    queryLatestEigenDatas(input: QueryLatestEigenDatasRequest, options?: RpcOptions): UnaryCall<QueryLatestEigenDatasRequest, Reply>;
    /**
     * @generated from protobuf rpc: QueryLatestWaveData(storage.QueryLatestWaveDataRequest) returns (storage.Reply);
     */
    queryLatestWaveData(input: QueryLatestWaveDataRequest, options?: RpcOptions): UnaryCall<QueryLatestWaveDataRequest, Reply>;
    /**
     * @generated from protobuf rpc: GetFirstWaveData(storage.GetFirstWaveDataRequest) returns (storage.Reply);
     */
    getFirstWaveData(input: GetFirstWaveDataRequest, options?: RpcOptions): UnaryCall<GetFirstWaveDataRequest, Reply>;
    /**
     * @generated from protobuf rpc: GetLastWaveData(storage.GetFirstWaveDataRequest) returns (storage.Reply);
     */
    getLastWaveData(input: GetFirstWaveDataRequest, options?: RpcOptions): UnaryCall<GetFirstWaveDataRequest, Reply>;
    /**
     * @generated from protobuf rpc: CalculateWaveData(storage.CalculateWaveDataRequest) returns (storage.Reply);
     */
    calculateWaveData(input: CalculateWaveDataRequest, options?: RpcOptions): UnaryCall<CalculateWaveDataRequest, Reply>;
    /**
     * @generated from protobuf rpc: QueryDatasWithLimit(storage.CalculateWaveDataRequest) returns (storage.Reply);
     */
    queryDatasWithLimit(input: CalculateWaveDataRequest, options?: RpcOptions): UnaryCall<CalculateWaveDataRequest, Reply>;
    /**
     * @generated from protobuf rpc: GetNearestEigenData(storage.GetDatasRequest) returns (storage.Reply);
     */
    getNearestEigenData(input: GetDatasRequest, options?: RpcOptions): UnaryCall<GetDatasRequest, Reply>;
    /**
     * @generated from protobuf rpc: QueryNearestEigenData(storage.GetDatasRequest) returns (storage.Reply);
     */
    queryNearestEigenData(input: GetDatasRequest, options?: RpcOptions): UnaryCall<GetDatasRequest, Reply>;
    /**
     * @generated from protobuf rpc: GetNearestRotateSpeed(storage.GetNearestRotateSpeedRequest) returns (storage.Reply);
     */
    getNearestRotateSpeed(input: GetNearestRotateSpeedRequest, options?: RpcOptions): UnaryCall<GetNearestRotateSpeedRequest, Reply>;
    /**
     * @generated from protobuf rpc: QueryHistoryDiluteDatas(storage.QueryHistoryDiluteDatasRequest) returns (storage.Reply);
     */
    queryHistoryDiluteDatas(input: QueryHistoryDiluteDatasRequest, options?: RpcOptions): UnaryCall<QueryHistoryDiluteDatasRequest, Reply>;
    /**
     * @generated from protobuf rpc: QueryNavDiluteDatas(storage.QueryNavDiluteDatasRequest) returns (storage.Reply);
     */
    queryNavDiluteDatas(input: QueryNavDiluteDatasRequest, options?: RpcOptions): UnaryCall<QueryNavDiluteDatasRequest, Reply>;
}
/**
 * The greeting service definition.
 *
 * @generated from protobuf service storage.Data
 */
export class DataClient implements IDataClient, ServiceInfo {
    typeName = Data.typeName;
    methods = Data.methods;
    options = Data.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * Sends a greeting
     *
     * @generated from protobuf rpc: GetRangeWaveDatas(storage.GetRangeWaveDatasRequest) returns (storage.Reply);
     */
    getRangeWaveDatas(input: GetRangeWaveDatasRequest, options?: RpcOptions): UnaryCall<GetRangeWaveDatasRequest, Reply> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetRangeWaveDatasRequest, Reply>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetDatas(storage.GetDatasRequest) returns (storage.Reply);
     */
    getDatas(input: GetDatasRequest, options?: RpcOptions): UnaryCall<GetDatasRequest, Reply> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetDatasRequest, Reply>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetEigenDatas(storage.GetDatasRequest) returns (storage.Reply);
     */
    getEigenDatas(input: GetDatasRequest, options?: RpcOptions): UnaryCall<GetDatasRequest, Reply> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetDatasRequest, Reply>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetEigenDatasByLowerlimit(storage.GetDatasRequest) returns (storage.Reply);
     */
    getEigenDatasByLowerlimit(input: GetDatasRequest, options?: RpcOptions): UnaryCall<GetDatasRequest, Reply> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetDatasRequest, Reply>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: QueryLatestEigenDatas(storage.QueryLatestEigenDatasRequest) returns (storage.Reply);
     */
    queryLatestEigenDatas(input: QueryLatestEigenDatasRequest, options?: RpcOptions): UnaryCall<QueryLatestEigenDatasRequest, Reply> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<QueryLatestEigenDatasRequest, Reply>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: QueryLatestWaveData(storage.QueryLatestWaveDataRequest) returns (storage.Reply);
     */
    queryLatestWaveData(input: QueryLatestWaveDataRequest, options?: RpcOptions): UnaryCall<QueryLatestWaveDataRequest, Reply> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<QueryLatestWaveDataRequest, Reply>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetFirstWaveData(storage.GetFirstWaveDataRequest) returns (storage.Reply);
     */
    getFirstWaveData(input: GetFirstWaveDataRequest, options?: RpcOptions): UnaryCall<GetFirstWaveDataRequest, Reply> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetFirstWaveDataRequest, Reply>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetLastWaveData(storage.GetFirstWaveDataRequest) returns (storage.Reply);
     */
    getLastWaveData(input: GetFirstWaveDataRequest, options?: RpcOptions): UnaryCall<GetFirstWaveDataRequest, Reply> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetFirstWaveDataRequest, Reply>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CalculateWaveData(storage.CalculateWaveDataRequest) returns (storage.Reply);
     */
    calculateWaveData(input: CalculateWaveDataRequest, options?: RpcOptions): UnaryCall<CalculateWaveDataRequest, Reply> {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept<CalculateWaveDataRequest, Reply>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: QueryDatasWithLimit(storage.CalculateWaveDataRequest) returns (storage.Reply);
     */
    queryDatasWithLimit(input: CalculateWaveDataRequest, options?: RpcOptions): UnaryCall<CalculateWaveDataRequest, Reply> {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept<CalculateWaveDataRequest, Reply>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetNearestEigenData(storage.GetDatasRequest) returns (storage.Reply);
     */
    getNearestEigenData(input: GetDatasRequest, options?: RpcOptions): UnaryCall<GetDatasRequest, Reply> {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetDatasRequest, Reply>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: QueryNearestEigenData(storage.GetDatasRequest) returns (storage.Reply);
     */
    queryNearestEigenData(input: GetDatasRequest, options?: RpcOptions): UnaryCall<GetDatasRequest, Reply> {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetDatasRequest, Reply>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetNearestRotateSpeed(storage.GetNearestRotateSpeedRequest) returns (storage.Reply);
     */
    getNearestRotateSpeed(input: GetNearestRotateSpeedRequest, options?: RpcOptions): UnaryCall<GetNearestRotateSpeedRequest, Reply> {
        const method = this.methods[12], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetNearestRotateSpeedRequest, Reply>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: QueryHistoryDiluteDatas(storage.QueryHistoryDiluteDatasRequest) returns (storage.Reply);
     */
    queryHistoryDiluteDatas(input: QueryHistoryDiluteDatasRequest, options?: RpcOptions): UnaryCall<QueryHistoryDiluteDatasRequest, Reply> {
        const method = this.methods[13], opt = this._transport.mergeOptions(options);
        return stackIntercept<QueryHistoryDiluteDatasRequest, Reply>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: QueryNavDiluteDatas(storage.QueryNavDiluteDatasRequest) returns (storage.Reply);
     */
    queryNavDiluteDatas(input: QueryNavDiluteDatasRequest, options?: RpcOptions): UnaryCall<QueryNavDiluteDatasRequest, Reply> {
        const method = this.methods[14], opt = this._transport.mergeOptions(options);
        return stackIntercept<QueryNavDiluteDatasRequest, Reply>("unary", this._transport, method, opt, input);
    }
}
