import { PluginMode } from "@core/graphs/webcharts/Dh-webchart-models";
import * as pluginList from "./Dh-plugin-library";

export class PluginManager {
	constructor(owner) {
		this.Owner = owner;
		this.Plugins = new Map();
		this.PluginEntities = null;
	}
	Init(plugins) {
		if (plugins == undefined) return;
		this.PluginEntities = plugins;
	}
	getPluginName(type) {
		for (let key of Object.keys(PluginMode)) {
			if (PluginMode[key] == type) {
				return key;
			}
		}
		return "";
	}
	OnRender() {
		for (const p of this.Plugins.values()) {
			p.OnRender();
		}
	}
	Clear() {
		for (const p of this.Plugins.values()) {
			p.Close();
		}
		this.Plugins.clear();
	}
	OnSizeChanged() {
		for (const p of this.Plugins.values()) {
			p.OnSizeChanged();
		}
	}
	GetPlugin(type) {
		const result = this.Plugins.getValues().find((p) => p.Entity.Type == type);
		return result;
	}
	// 将统计信息plugin置于第一位，保证统计信息先于光标信息生成
	SortStatisticsPlugin(plugins) {
		const plugin = [PluginMode.Statistics, PluginMode.HistogramStatistics];
		const array = [];
		plugin.forEach(item => {
			const index = plugins.findIndex(c => c === item);
			if(index >= 0) {
				array.push(item);
			}
		})
		plugins = array.concat(plugins);
		return plugins;
	} 
	OpenPlugin(ptypes) {
		let result = null;
		if (!this.PluginEntities) return result;
		ptypes = this.SortStatisticsPlugin(ptypes);
		for (const type of ptypes) {
			if (!this.Plugins.has(type)) {
				for (const entity of this.PluginEntities) {
					if (entity.Type == type) {
						let pluginKey = this.getPluginName(type) + "Plugin";
						const obj = new pluginList[pluginKey]();
						obj.IsEnabled = this.Owner.IsEnabled;
						obj.Init(this.Owner, entity);
						obj.CreatePlugin();
						this.Plugins.set(type, obj);
						result = obj;
					}
				}
			}
		}
		this.OnSizeChanged();
		this.OnRender();
		return result;
	}
	ClosePlugin(ptypes) {
		for (const type of ptypes) {
			if (this.Plugins.has(type)) {
				this.Plugins.get(type).Close();
				this.Plugins.delete(type);
			}
		}
	}
	GetRadioPlugin() {
		const key = [...this.Plugins.keys()].find((p) => p < 100);
		if (key) return this.Plugins.get(key);
	}
	ClearData() {
		for (const p of this.Plugins.values()) {
			p.ClearData();
		}
	}
}
