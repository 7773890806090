import { AlgorithmTypeEnum } from "@components/js/utils/model";

export const DataProtolEnum = {
    YDatas: 0,
    XYDatas: 1,
    ComplexDatas: 2,
    BarDatas: 3,
    TrendDatas: 4,
    RainFlowDatas: 5,
    PatrolDatas: 6,
}

export const EnumXDataType = {
    Time: 0,
    Number: 1,
}

class DataParser {
    constructor() { }
    GetDatas(data, paramDto) { }
    GetDspParam(paramDto) {
        if (paramDto.DspParam) return paramDto.DspParam.length > 0 ? paramDto.DspParam[1] : paramDto.DspParam;
        return null;
    }
    GetDivision(data, paramDto) {
        if (paramDto.SampleType == 1) {
            return 60;
        }
        if (paramDto.DspParam) {
            const dspParam = this.GetDspParam(paramDto);
            const result = dspParam.FreqUnit == 1 ? (dspParam.UseSpeed ? paramDto.RotateSpeed ?? dspParam.RoteSpeed : 60) : 60;
            if (result == 0) {
                // 转速为0情况
                return 1;
            }
            return result;
        }
        return 60;

    }
    GetAnalysisPointCount(paramDto) {
        if (paramDto.DspParam) {
            const dspParam = this.GetDspParam(paramDto);
            return dspParam.AnalysisCount > 64 ? dspParam.AnalysisCount : 64 * parseInt(Math.pow(2, dspParam.AnalysisCount));
        }
        return 1000;
    }
}

class YDatasParser extends DataParser {
    constructor() {
        super();
    }
    GetDatas(data, paramDto) {
        switch (paramDto.DspType) {
            case AlgorithmTypeEnum.SpectrumAnalysis:
            case AlgorithmTypeEnum.OrderAnalysis:
            case AlgorithmTypeEnum.CableAnalysis:
                return this.GetFFTDatas(data, paramDto);
            case AlgorithmTypeEnum.CrossPowerSpectrumDensity:
                return this.GetCrossPowerSpectrumDatas(data, paramDto);
            case AlgorithmTypeEnum.Cepstrum:
                if (paramDto.SampleType == 1) {
                    return this.GetCycleOrginDatas(data, paramDto);
                }
                return this.GetCepstrumDatas(data, paramDto);
            case AlgorithmTypeEnum.EnvelopeAnalysis:
                return this.GetEnvelopData(data, paramDto);
            case AlgorithmTypeEnum.EnvelopeSpectrum:
                return this.GetEnvelopSpectrumData(data, paramDto);
            case AlgorithmTypeEnum.CorrelationAnalysis:
                return this.GetCorrelationData(data, paramDto);
            case AlgorithmTypeEnum.VirtualCompute:
                const param = paramDto.DspParam[1];
                if (param.DomainType > 0) {
                    param.AnalysisCount = (data.DataCount - 1) * 2.56;
                    return this.GetFFTDatas(data, paramDto);
                }
            default:
                break;
        }
        if (paramDto.SampleType == 1) {
            return this.GetCycleOrginDatas(data, paramDto);
        }
        return this.GetOrginDatas(data, paramDto);
    }
    GetCycleOrginDatas(data, paramDto) {
        const pointParam = paramDto.PointParam?.length > 0 ? paramDto.PointParam[1] : paramDto.PointParam;
        const diff = 1 / pointParam.PointNumberPerCycle;
        const cycleNumber = pointParam.CycleNumber || 1;
        const start = 0 + diff * (paramDto.DataOffset ?? 0);
        const dataRet = {
            XDataType: EnumXDataType.Number,
            DataCount: data.DataCount,
            Points: [],
            XMax: cycleNumber
        }
        paramDto.BlockSize = cycleNumber * pointParam.PointNumberPerCycle;
        dataRet.Points = new Array(data.DataCount);
        for (let y = 0; y < data.DataCount; y++) {
            const x = start + diff * y;
            dataRet.Points[y] = {
                X: x,
                Y: data.Datas[y]
            }
        }
        return dataRet;
    }
    GetOrginDatas(data, paramDto) {
        const isTorv = paramDto.SampleType == 11;
        const diff = isTorv ? (360 / paramDto.BlockSize) : (1E7 / paramDto.SampleFreq / 10000);
        const start = (isTorv ? 0 : (paramDto.SampleTime / 10000)) + diff * (paramDto.DataOffset ?? 0);
        const first = isTorv ? 0 : (paramDto.SampleTime / 10000);
        const dataRet = {
            XDataType: isTorv ? EnumXDataType.Number : EnumXDataType.Time,
            DataCount: data.DataCount,
            Points: [],
            XMax: first + diff * paramDto.BlockSize
        }
        dataRet.Points = new Array(data.DataCount);
        for (let y = 0; y < data.DataCount; y++) {
            const x = start + diff * y;
            dataRet.Points[y] = {
                X: x,
                Y: data.Datas[y]
            }
        }
        return dataRet;
    }
    GetCorrelationData(data, paramDto) {
        const dataRet = {
            XDataType: EnumXDataType.Time,
            DataCount: data.DataCount,
            Points: [],
        }
        const length = parseInt((data.DataCount - 1) / 2);
        const diff = 1E6 / paramDto.SampleFreq;
        const start = -length * diff;
        dataRet.Points = new Array(data.DataCount);
        for (let y = 0; y < data.DataCount; y++) {
            const x = start + diff * y;
            dataRet.Points[y] = {
                X: x / 10000,
                Y: data.Datas[y]
            }
        }
        return dataRet;
    }
    GetFFTDatas(data, paramDto) {
        const analysisPoint = this.GetAnalysisPointCount(paramDto);
        let xDiff = 1;
        if (paramDto.SampleType == 1) {
            const pointParam = paramDto.PointParam?.length > 0 ? paramDto.PointParam[1] : paramDto.PointParam;
            xDiff = pointParam.PointNumberPerCycle / analysisPoint;
        } else {
            xDiff = paramDto.SampleFreq / analysisPoint;
        }
        const div = this.GetDivision(data, paramDto);
        const maxIndex = parseInt(analysisPoint / 2.56) + 1;
        const dataRet = {
            XDataType: EnumXDataType.Number,
            DataCount: maxIndex,
            Points: [],
        }
        const start = 0;
        const diff = xDiff / div * 60;;
        dataRet.Points = new Array(maxIndex);
        for (let y = 0; y < maxIndex; y++) {
            const x = start + diff * y;
            dataRet.Points[y] = {
                X: x,
                Y: data.Datas[y]
            }
        }
        return dataRet;
    }
    GetCrossPowerSpectrumDatas(data, paramDto) {
        const dataRet = {
            XDataType: EnumXDataType.Number,
            DataCount: data.DataCount,
            Points: [],
        }
        const dspParam = this.GetDspParam(paramDto) || { AnalysisPointCount: 1 };
        const analysisPointCount = dspParam.AnalysisPointCount > 64 ? dspParam.AnalysisPointCount : 64 * parseInt(Math.pow(2, dspParam.AnalysisPointCount));
        let diff = 1;
        if (paramDto.SampleType == 1) {
            const pointParam = paramDto.PointParam?.length > 0 ? paramDto.PointParam[1] : paramDto.PointParam;
            diff = pointParam.PointNumberPerCycle / analysisPointCount;
        } else {
            diff = paramDto.SampleFreq / analysisPointCount;
        }
        const start = 0;
        dataRet.Points = new Array(data.DataCount);
        for (let y = 0; y < data.DataCount; y++) {
            const x = start + diff * y;
            dataRet.Points[y] = {
                X: x,
                Y: data.Datas[y]
            }
        }
        return dataRet;
    }
    GetCepstrumDatas(data, paramDto) {
        const diff = 1E7 / paramDto.SampleFreq / 1E4 / 1E3;
        const start = 0;
        const dataRet = {
            XDataType: EnumXDataType.Number,
            DataCount: data.DataCount,
            Points: [],
            XMax: diff * data.DataCount
        }
        dataRet.Points = new Array(data.DataCount);
        for (let y = 0; y < data.DataCount; y++) {
            const x = start + diff * y;
            dataRet.Points[y] = {
                X: x,
                Y: data.Datas[y]
            }
        }
        return dataRet;
    }
    GetEnvelopData(data, paramDto) {
        const diff = 1E7 / paramDto.SampleFreq / 10000;
        const count = data.DataCount / 2;
        const start = paramDto.SampleTime / 10000 + diff * (paramDto.DataOffset ?? 0);
        const first = paramDto.SampleTime / 10000;
        const point = {
            XDataType: EnumXDataType.Time,
            DataCount: data.DataCount,
            Points: [],
            XMax: first + count * diff
        };
        paramDto.DataOffset = paramDto.DataOffset >> 1;
        point.Points = new Array(data.DataCount);
        for (let y = 0; y < count; y++) {
            const x = start + diff * y;
            point.Points[y] = {
                X: x,
                Y: data.Datas[y]
            }
            point.Points[y + count] = {
                X: x,
                Y: data.Datas[y + count]
            }
        }
        return point;
    }
    GetEnvelopSpectrumData(data, paramDto) {
        const point = {
            XDataType: EnumXDataType.Time,
            DataCount: data.DataCount,
            Points: [],
        };
        point.Points = new Array(data.DataCount);
        const count = data.DataCount / 2;
        const analysisPointCount = (data.DataCount / 2 - 1) * 2.56;
        let diff = 1;
        if (paramDto.SampleType == 1) {
            const pointParam = paramDto.PointParam?.length > 0 ? paramDto.PointParam[1] : paramDto.PointParam;
            diff = pointParam.PointNumberPerCycle / analysisPointCount;
        } else {
            diff = paramDto.SampleFreq / analysisPointCount;
        }
        const start = 0;
        for (let y = 0; y < count; y++) {
            const x = start + diff * y;
            point.Points[y] = {
                X: x,
                Y: data.Datas[y]
            }
            point.Points[y + count] = {
                X: x,
                Y: data.Datas[y + count]
            }
        }
        return point;
    }
}

class XYDatasParser extends DataParser {
    constructor() {
        super();
    }
    GetDatas(data) {
        const dataRet = {
            XDataType: EnumXDataType.Number,
            DataCount: data.DataCount / 2,
            Points: [],
        }
        dataRet.Points = new Array(dataRet.DataCount);
        for (let y = 0; y < data.Datas.length; y += 2) {
            dataRet.Points[y >> 1] = {
                X: data.Datas[y],
                Y: data.Datas[y + 1]
            }
        }
        return dataRet;
    }
}

class ComplexDatasParser extends DataParser {
    constructor() {
        super();
    }
    GetDatas(data, paramDto) {
        const start = 0;
        const analysisPoint = this.GetAnalysisPointCount(paramDto);
        const division = this.GetDivision(data, paramDto);
        const xDiff = paramDto.SampleFreq / analysisPoint;
        const diff = xDiff / division * 60;
        const maxIndex = parseInt(analysisPoint / 2.56) + 1;
        const dataRet = {
            XDataType: EnumXDataType.Number,
            DataCount: maxIndex,
            Points: [],
        }
        dataRet.Points = new Array(maxIndex);
        for (let y = 0; y < maxIndex; y++) {
            const x = start + diff * y;
            dataRet.Points[y] = {
                X: x,
                Y: data.Datas[y].Magnitude
            }
        }
        return dataRet;
    }
}

class BarDatasParser extends DataParser {
    constructor() {
        super();
    }
    GetDatas(data, paramDto) {
        const diff = 1E7 / paramDto.SampleFreq;
        const dspParam = this.GetDspParam(paramDto) || { AnalysisPointCount: 1 };
        const dataRet = {
            Datas: data.Datas,
            DataCount: data.DataCount,
            StartTime: paramDto.SampleTime,
            EndTime: paramDto.SampleTime + (dspParam.AnalysisPointCount - 1) * diff,
            XDataType: EnumXDataType.Time,
        }
        return dataRet;
    }
}

class TrendDatasParser extends DataParser {
    constructor() {
        super();
    }
    GetDatas(data) {
        const dataRet = {
            XDataType: EnumXDataType.Time,
            DataCount: data.DataCount,
            Points: [],
        }
        for (const d of data.Datas) {
            d.X = d.X / 10000;
        }
        dataRet.Points = data.Datas;
        dataRet.Index = data.Index;
        return dataRet;
    }
}

class RainFlowDatasParser extends DataParser {
    constructor() {
        super();
    }
    GetDatas(data, paramDto) {
        data.StartTime = paramDto.SampleTime;
        data.EndTime = paramDto.SampleTime;
        return data;
    }
}

class PatrolDatasParser extends DataParser {
    constructor() {
        super();
    }
}

export class DataParserContext {
    static _instance = null;
    static GetInstance() {
        if (DataParserContext._instance == null) {
            DataParserContext.initial();
        }
        return DataParserContext._instance;
    }
    static initial() {
        const handler = new Map();
        handler.set(DataProtolEnum.YDatas, new YDatasParser());
        handler.set(DataProtolEnum.XYDatas, new XYDatasParser());
        handler.set(DataProtolEnum.ComplexDatas, new ComplexDatasParser());
        handler.set(DataProtolEnum.BarDatas, new BarDatasParser());
        handler.set(DataProtolEnum.TrendDatas, new TrendDatasParser());
        handler.set(DataProtolEnum.RainFlowDatas, new RainFlowDatasParser());
        handler.set(DataProtolEnum.PatrolDatas, new PatrolDatasParser());
        DataParserContext._instance = handler;
    }
}