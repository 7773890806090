// @generated by protobuf-ts 2.9.1
// @generated from protobuf file "protos/latestwavedata.proto" (package "storage", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message storage.QueryLatestWaveDataRequest
 */
export interface QueryLatestWaveDataRequest {
    /**
     * @generated from protobuf field: repeated int32 PointIds = 1 [json_name = "PointIds"];
     */
    pointIds: number[];
}
// @generated message type with reflection information, may provide speed optimized methods
class QueryLatestWaveDataRequest$Type extends MessageType<QueryLatestWaveDataRequest> {
    constructor() {
        super("storage.QueryLatestWaveDataRequest", [
            { no: 1, name: "PointIds", kind: "scalar", jsonName: "PointIds", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<QueryLatestWaveDataRequest>): QueryLatestWaveDataRequest {
        const message = { pointIds: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<QueryLatestWaveDataRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: QueryLatestWaveDataRequest): QueryLatestWaveDataRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated int32 PointIds = 1 [json_name = "PointIds"];*/ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.pointIds.push(reader.int32());
                    else
                        message.pointIds.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: QueryLatestWaveDataRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated int32 PointIds = 1 [json_name = "PointIds"]; */
        if (message.pointIds.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.pointIds.length; i++)
                writer.int32(message.pointIds[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message storage.QueryLatestWaveDataRequest
 */
export const QueryLatestWaveDataRequest = new QueryLatestWaveDataRequest$Type();
