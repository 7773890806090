import { i18nGlobal } from "@common/js/utils";

export const ComponentTypeEnum = {
	None: 0,
	TrendGraph: 1, //趋势图
	WaveGraph: 2, //波形图
	SpectrumGraph: 3, //频谱图
	WaterfallGraph: 4, //瀑布图
	AxisPositionGraph: 5, //轴心位置图
	BarGraph: 6, //棒图
	BodePlotsGraph: 7, //伯德图
	DashBoard: 8, //仪表盘
	NavigateGraph: 9, //导航图谱 已弃用
	NumberTable: 10, //数字表
	CascadeGraph: 11, //级联图
	PolarGraph: 12, //极坐标
	Calendar: 13, //日历
	NavigateBar: 14, //导航条
	MachineTree: 15, //设备树
	Image: 16, //图片
	ThreeD: 17, //结构模型
	EditBox: 18, //自定义编辑框
	Map: 19, //地图
	Video: 20, //视频
	DeviceBrowse: 21, //设备浏览 已弃用
	IIRAlgorithmGraph: 22, //IIR数字滤波
	OnceIntegrayAlgorithmGraph: 23, //一次积分
	OnceDifferentialAlgorithmGraph: 24, //一次微分
	RosetteAlgorithmGraph: 25, //应变花
	AmplitudeAnalysisAlgorithmGraph: 26, //幅值分析
	Report: 27,
	TwiceIntegrayAlgorithmGraph: 28, //二次积分
	TwiceDifferentialAlgorithmGraph: 29, //二次微分
	AlarmCalendar: 30,
	BarStatistics: 31,
	PointStateStatistics: 32,
	AlarmReport: 33,
	FaultGraph: 35,
	GraphisDisplay: 36,
	RecorderGraph: 37, //记录仪
	XYRecordGraph: 38, //XY记录仪
	Document: 39, //文档
	VirtualComputingAlgorithmGraph: 40, //虚拟计算
	RainflowAlgorithmGraph: 41, //雨流分析
	FreqIntegralAlgorithmGraph: 42, //频域积分
	MultiSampleAlgorithmGraph: 43, //重采样
	EnvelopeAlgorithmGraph: 44, //包络波形
	OrderAnalysisGraph: 45, //阶次分析
	OctaveAlgorithmGraph: 46, //倍频程
	SoundIntensityAlgorithmGraph: 47, //声强分析
	SoundPowerAlgorithmGraph: 48, //声功率分析
	TorsionAlgorithmGraph: 49, //扭矩分析
	ShaftPowerAlgorithmGraph: 50, //轴功率分析
	CableForceAlgorithmGraph: 51, //索力分析
	StaticsAnalysisAlgorithmGraph: 52, //统计分析
	Title: 53, //状态显示标题控件
	FIRAlgorithmGraph: 54, //FIR数字滤波
	PowerSpectrumDensityAlgorithmGraph: 55, //自功率谱密度
	CorrelationAlgorithmGraph: 56, //相关分析
	CovarianceAlgorithmGraph: 57, //协方差分析
	SelfPowerSpectrumAlgorithmGraph: 58, //自功率谱
	CrossPowerSpectrumDensityAlgorithmGraph: 59, //互功率谱密度
	CepstrumAlgorithmGraph: 60, //倒谱分析
	WholePeriodWaveGraph: 61, //整周期波形图
	VibrationListAlgorithmGraph: 62, //振动列表
	EnvelopeSpectrumAlgorithmGraph: 63, //包络谱
	SoundPressureAlgorithmGraph: 64, //声压分析
	TimeDomainODS: 65, //时域ODS
	FreqDomainODS: 66, //频域ODS
	TorsionalVibrationAlgorithmGraph: 67, //扭振分析
	WaveletAlgorithmGraph: 68, //小波分析
	AxisTrailGraph: 69, //轴心轨迹图
	AlarmInfo: 70, //实时报警信息
	FaultAlarmReport: 71, //故障报警报表
	FFTAlgorithmGraph: 72, //频谱分析
	StudySelfGraph: 73, //自学习图谱
	HealthStatus: 74, //健康状态,
	EnvelopeAlgorithmGraphEx: 75, //工作状态中的包洛波形
	DeviceStatus: 76, //设备健康状态
	EnergyEvalution: 77, //能量评估
	BendingAlgorithmGraph: 78, //弯矩分析
	ResidualStress: 81, // 残余应力
	TrendForecast: 90, // 趋势预测
	PointValue: 200, //厦门环寂定制组件
}

export const TaskTypeEnum = {
	HealthStatus: 1 // 健康度
}

export const MachineTreeNodeTypeEnum = {
	/**单位 */
	Area: 1,
	/**部门 */
	Depart: 2,
	/**设备 */
	Device: 3,
	/**场景 */
	Scene: 4,
}

export const AreaTypeEnum = {
	/**单位 */
	Unit: 1,
	/**部门 */
	Depart: 2,
}

export const depColorEnum = {
	/**默认色 */
	defaultColor: '#606266',
	/**高亮色 */
	activeColor: '#0755c2',
}

export const ColorEnum = {
	defaultColor: '#606266', // 默认色
	activeColor: '#0755c2', // 高亮色
	PrimaryColor: '#409eff', // 主题色
}

export const OrgEnum = {
	Unit: 'unit',
	Depart: 'depart',
	Device: 'device',
	Scence: 'scence',
}

export const PeriodModeEnum = {
	Day: 0,
	Week: 1,
	Month: 2,
	Year: 3,
}

export const PeriodTypeEnum = {
	EveryDayMode: 0,
	EveryWeekMode: 1,
	EveryMonthMode: 2,
	CustomMode: 3,
}

export const PatrolModeEnum = {
	None: 0,
	Holiday: 1,
	Duty: 3,
}

export const SaveModeEnum = {
	Continuous: 1,
	Time: 2,
	Trigger: 3,
	Dilute: 4
}

export const LoginSuccessEnum = {
	None: 0,
	Success: 1,
	UserNotExist: 2,
	UserPasswordError: 3,
	OverTime: 4,
	KeepTimeout: 5,
	Other: 6,
	NeedChangePassword: 14,
}

export const DepartmentTypeEnum = {
	Unit: 1,
	Depart: 2,
}

export const DeviceStatusEnum = {
	onLine: 1,
	offLine: 0,
}

export const PlotTypeEnum = {
	PointData: 0,
	StatusData: 1,
	Graphics: 2,
	ThreeD: 3,
	FaultMode: 4,
	Analysis: 5
}

export const AlarmTypeEnum = {
	None: 0,
	LessLimit: 2,
	MoreLimit: 1,
	InnerWindow: 3,
	OuterWindow: 4,
}

export const AlarmTypeModelEnum = {
	Amplitude: 0, //幅值报警
	DispersedAlarm: 1, //离散报警
	SelftLearning: 2, //自学习,
	Mutation: 3, //突变报警
	TrendForecast: 4, //趋势预测
	Bandwidth: 4 // 频带能量
}

export const AlarmTypeNameEnum = {
	[AlarmTypeModelEnum.Amplitude]: i18nGlobal('common.amplitudeAlarm'),
	[AlarmTypeModelEnum.Mutation]: i18nGlobal('common.mutaionAlarm'),
	[AlarmTypeModelEnum.SelftLearning]: i18nGlobal('common.selfLearningAlarm'),
	[AlarmTypeModelEnum.TrendForecast]: i18nGlobal('common.trendPredictionAlarm'),
	[AlarmTypeModelEnum.Bandwidth]: i18nGlobal('common.bandEnergyAlarm'),
}

export const ContractTypes = {
	Login: 1001, //客户端验证（客户端->服务器）
	LoginResult: 2001, //服务器验证消息
	TreeAlarms: 2101, //设备树报警信息（服务器->客户端）
	TreeUpdate: 2102, //设备树更新信息（服务器->客户端）
	DeviceStatus: 2103, // 设备树离线在线推送
	NodeData: 1121, //客户端请求测点实时数据（客户端->服务器）
	NodeDataResult: 2121, //服务器返回测点实时数据（服务器->客户端）
	Graph: 3001, // 图谱实时数据
	Timing: 3201, // 计时实时数据
	RefreshOnLineDevice: 4001, //采集站管理设备变更通知
	PointAlarmCountChanged: 4003, //右上角报警变更通知(实时、历史报警数量放在一起推送)
	ThirdPartyTreeUpdate: 4004, //OPC设备变更通知
	ModbusTreeUpdate: 4005, //Modbus设备变更通知
	FaultAlarmCountChanged: 4006,
	AlarmInfoUpdate: 4008, //实时报警信息变更通知
	StartStopUpdate: 4009, //启停机信息变更通知
	ComponentLayoutChanged: 4010,
	DeviceTreeUpdate: 5001, //仪器树变更
	SystemEventInformation: 7001, // 系统事件推送
	SystemCheck: 7002, //系统自检
	MachineDataChanged: 10001, ////故障设置测点数据推送消息类型
	ExportTaskNumChanged: 8002, //// 导出任务数量
	ExportTaskProgressChanged: 8001, ////导出任务进度
	InstrumentSample: 11001, // 开始采样和停止采样
	InstrumentName: 11002, // 修改名字
	BatteryChanged: 12001,//电池电量更新
	DataCenterConnected: 13001,//数据中心上线
	DH5531: 13002, // 靖江定制项目需求
	MessagePush: 13003,
	Eigen: 14001,//特征值数据
	Block: 15001,//块数据
	Orgin: 16001,//原始数据
	Latest: 17001,//最新值
	Dsp: 18001,//信号处理
	BlockEigen: 19001,
	RecorderCachedData: 20000,//记录仪缓存原始数据
	RecorderCachedDilution: 21000,//记录仪缓存抽点数据
}

export const NodeStateEnum = {
	None: 0,
	Add: 1,
	Delete: 2,
	Update: 3,
}

export const AlarmStatusColorEnum = {
	Normal: '',
	High: '#f59435',
	Highest: '#ff0010',
}

export const ShowPathEnum = {
	Unit: 1,
	Depart: 2,
	Device: 3,
	Point: 4,
}

export const SpeedEnum = {
	Fast: 'fast',
	Normal: 'normal',
	Slow: 'slow',
}

export const ExportStatusEnum = {
	NoExport: -1,
	Exporting: 0,
	Exported: 1,
	Failed: 2,
}

export const TreeOperateEnum = {
	Add: 0,
	Edit: 1,
	Delete: 2,
}

export const SpectrumTypeEnum = {
	/**实时谱 */
	Realtime: 0,
	/**平均谱 */
	Average: 1,
}

export const AmplitudeTypeEnum = {
	/**幅值谱 */
	Amplitude: 0,
	/**有效值谱 */
	RMS: 1,
	/**功率谱 */
	Power: 2,
	/**有效值功率谱 */
	RMSPower: 3,
	/**功率谱密度 */
	PowerDensity: 4,
	/**有效值功率谱密度 */
	RMSPowerDensity: 5,
	/**根号功率谱密度 */
	SqrtPowerDensity: 6,
}

export const WinTypeEnum = {
	/**矩形窗 */
	Rectangle: 0,
	/**汉窗 */
	Hanning: 1,
	/**哈明 */
	Hamming: 2,
	/**平顶窗 */
	Flattop: 3,
	/**三角窗 */
	Triangle: 4,
	/**布莱克曼窗 */
	Blackman: 5,
}

export const ModelButtonType = {
	Remove: 1,
	Add: 2,
}

/** 声音报警等级 */
export const SoundAlarmLevelEnum = {
	Safe: 1, //正常
	Warnning: 8, //高保
	Danger: 16, //高高报
}

export const MeasureModeEnum = {
	None: -1,
	Measurement: 0,
	Observation: 1,
	HandCopying: 2,
}

export const ParentGuid = '00000000-0000-0000-0000-000000000000'

export const AdminId = "b942b1d8-2946-4ac7-8ae8-5ba51d1c175b";

export const AdminWorkGroupId = "c750eeae-97d7-4e3f-a420-fa2f55a6f5d4";

export const FaultModeEnum = {
	Scheme: 0,
	Advice: 1,
}

export const TreeMap = {
	[MachineTreeNodeTypeEnum.Area]: "treeUnit",
	[MachineTreeNodeTypeEnum.Depart]: "treeDepart",
	[MachineTreeNodeTypeEnum.Device]: "treeDevice",
	[MachineTreeNodeTypeEnum.Scene]: "treeScene",
};
/** 测点标签报警类别 */
export const LabelAlarmTypeEnum = {
	Value: 0, //数值报警
	Status: 1, //状态报警
}

export const DataRegisterTypeEnum = {
	Block: 0,
	Origin: 1
}
export const NormalLevel = 1;

export const NormalLevelItem = [{
	AudioId: null,
	Color: '#67c23a',
	Level: 1,
	Name: i18nGlobal('common.normal')
}]

/** 测点ID超过131072的为信号处理测点 */
export const SIGNAL_DISPOSE_FLAG = 131072;

export const ReportTemplateEnum = {
	Template1: 0,
	Template2: 1, //普拉斯定制报告模板
	Template3: 2,
	Template4: 3,
}

export const ReportTemplateNameMap = {
	[ReportTemplateEnum.Template1]: i18nGlobal('mainLayout.report.template1'),
	[ReportTemplateEnum.Template2]: i18nGlobal('mainLayout.report.template2'),
	[ReportTemplateEnum.Template3]: i18nGlobal('mainLayout.report.template3'),
	[ReportTemplateEnum.Template4]: i18nGlobal('mainLayout.report.template4'),
}

export const TimingComponentUnitEnum = {
	S: 0,
	M: 1,
	H: 2,
	D: 3,
	MS: 4,
	HMS: 5,
	HM: 6,
	DHMS: 7,
	DHM: 8,
	DH: 9,
}

export const DisplayContentEnum = {
	Unit: 0,
	Department: 1,
	DeviceAndScene: 2,
	Point: 3,
	Fault: 4,
	Type: 5,
	Level: 6,
	Time: 7,
};

export const TimeUnitEnum = {
	Second: 0,
	Minute: 1,
	Hour: 2,
	Day: 3
}

export const AlignEnum = {
    Left: 0,
    HorizontallyCentered: 1,
    Right: 2,
    HorizontallyDistribution: 3,
    Top: 4,
	VerticallyCentered: 5,
    Bottom: 6,
    VerticallyDistribution: 7
}

export const MatchingEnum = {
	Width: 0,
	Height: 1,
	WidthAndHeight: 2
}

export const ComponentPositionEnum = {
	Width: 0,
	Height: 1,
	Left: 2,
	Top: 3
}

export const SortOperateEnum = {
	Top: 0,
	Up: 1,
	Down: 2,
	Bottom: 3
}

export const ChartColors = ['#ffbf25','#aa3ef2','#02c1f2','#fb3978','#2ac096','#f46e3b','#da0d18','#0ad9e1','#503ff5','#42ff89'];
export const LinearChartColors = [
	['#f9be31','#e08025'],
	['#c950e2','#73058a'],
	['#fb3978','#9d0636'],
	['#02c1f2','#047c9a'],
	['#34d4a7','#028460'],
	['#ef9242','#9a2d04'],
	['#f85059','#a9040c'],
	['#14e9f1','#04858a'],
	['#6b5cf5','#072579'],
	['#42ff89','#07a242']
];

export const UiModeEnum = {
	EditStatus: 0,
	ShowStatus: 1
}

export const PermissionStatusEnum = {
	Normal: 0,
	IsDefaultCheck: 1 // 默认选中
}
export const ChartColorTypeEnum = {
    Custom: 0,
    OnlineOffline: 1,
    AlarmLevel: 2
}

export const ArrangementEnum = {
	Vertical: 0,
	Horizontal: 1
};
