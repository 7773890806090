import { nextTick, ref } from "vue";
import { Debounce, guid } from "@common/js/utils";
import * as componentList from "@components/js/utils/componentLibrary";
import { AlgorithmTypeEnum, EnumComponentTheme } from "@components/js/utils/model";
import { PluginMode } from "@core/graphs/webcharts/Dh-webchart-models";
import dayjs from 'dayjs'
import { AlarmTypeModelEnum } from "@common/js/enum";
import { GrpcApiContext } from "@grpc/grpcApi";
import store from "@common/js/store";
import { PointCacheContext } from "@core/js/clientRealContext";

const EnumAlarmMode = {
    Point: 0,
    Fault: 1
}

export class AlarmGraphDetailComponentLayoutOperator {
    constructor(state) {
        this.state = state;
        this.alarmInfo = null;
        this.alarmData = null;
        this.alarmIndex = 0;
        this.startIndex = 0;
        this.endIndex = 0;
        this.dataDebounce = new Debounce();
        this.alarmGraphMode = EnumAlarmMode.Point;
        this.tooltipChanged = null;
        this.dataClient = this.createDataClient();
        this.state.isRealMode = false;
    }
    createDataClient() {
        return GrpcApiContext.GetInstance();
    }
    setData(alarmInfo, alarmData) {
        this.alarmInfo = alarmInfo;
        this.alarmData = alarmData;
        this.alarmIndex = 0;
        this.startIndex = 0;
        this.endIndex = 0;
        this.alarmGraphMode = (alarmInfo.AlarmModel == 1 || alarmData.FaultParam == null) ? EnumAlarmMode.Point : EnumAlarmMode.Fault;
    }
    async createComponent(name, param) {
        try {
            if (name == "" || componentList[name] == null) return;
            if (param == null) {
                param = {};
            }
            let option = {};
            if (param.Component) {
                option = param.Component;
            }
            if (window.componentLibarys[name] == null) {
                window.componentLibarys[name] = await componentList[name]();
            }
            const item = {
                chartRef: ref(null),
                componentCategory: name,
                key: guid(),
                width: 950,
                height: 300,
                componentOption: {
                    id: param.Id,
                    height: 300,
                    width: 950,
                    option: option,
                    isHistory: true,
                    showToolbar: false,
                    showSave: false,
                    showAlarm: false,
                    showDataInput: false,
                    setDefaultPoint: false,
                    showStorage: false,
                    showAlgorithm: false,
                    owner: this,
                    showBatch: !this.isEigenGraph(name) && this.alarmGraphMode == EnumAlarmMode.Fault
                },
            }
            await this.customComponent(item);
            this.state.graphData.push(item);
            nextTick(() => {
                this.registerEvents(item);
            });
        } catch { }
    }
    isEigenGraph(name) {
        return name == "trendGraphComponent"
            || name == "navigateGraphComponent"
            || name == "statisticAnalysisComponent"
            || name == "polarGraphComponent"
            || name == "bodePlotsGraphComponent"
            || name == "trendForecastComponent"
            || name == "eigenFilterAlgorithmComponent"
            || name == "axisPositionGraphComponent"
    }
    registerEvents(item) {
        const component = item.chartRef.value.component;
        if (item.componentCategory == "trendGraphComponent") {
            component.onCoordinateChangeHandler = (e) => {
                const coord = e.Owner.Information.CoordinateInfo;
                if (coord.XMax > coord.OriginXMax) coord.XMax = coord.OriginXMax;
                if (coord.XMin < coord.OriginXMin) coord.XMin = coord.OriginXMin;
                e.Owner.OnMeasure();
                e.Owner.OnRender();
            };
            component.onComponentCreated = () => {
                if (this.alarmGraphMode == EnumAlarmMode.Point) {
                    this.registerTrendEvents(component);
                    setTimeout(() => {
                        this.queryAlarmEigenData(component);
                    }, 500)
                } else {
                    this.queryFaultAlarmEigenData(component, () => {
                        setTimeout(() => {
                            this.registerTrendEvents(component);
                        }, 500);
                    });
                }
            }
            return;
        }
        if (item.componentCategory == "navigateGraphComponent") {
            component.onSingleCursorPositionChanged = (time, index) => {
                for (const gd of this.state.graphData) {
                    gd.chartRef.component?.queryFaultAlarmData?.({
                        EventId: this.alarmData.AlarmEvents[0].Id,
                        Time: time,
                        Index: index
                    })
                }
            }
            component.onDoubleCursorPositionChanged = (start, end, action) => {
                for (const gd of this.state.graphData) {
                    gd.chartRef.component?.queryFaultAlarmRangeData?.({
                        Start: start,
                        End: end,
                        EventId: this.alarmData.AlarmEvents[0].Id,
                        action: action
                    })
                }
            }
            this.queryAlarmNavigateDatas(component);
            return;
        }
        component.onNextBatchClick = () => {
            component?.queryFaultAlarmBatchData?.({
                Type: 1,
                EventId: this.alarmData.AlarmEvents[0].Id
            })
        }
        component.onLastBatchClick = () => {
            component?.queryFaultAlarmBatchData?.({
                Type: -1,
                EventId: this.alarmData.AlarmEvents[0].Id
            })
        }
        if (item.componentCategory == "recordGraphComponent") {
            component.onCoordinateChangeHandler = (e) => {
                const coord = e.Owner.Information.CoordinateInfo;
                if (coord.XMax > coord.OriginXMax) coord.XMax = coord.OriginXMax;
                if (coord.XMin < coord.OriginXMin) coord.XMin = coord.OriginXMin;
                e.Owner.OnMeasure();
                e.Owner.OnRender();
            };
        }
    }
    registerTrendEvents(component) {
        component.onNavigateChanged = (time) => {
            this.dataDebounce.run(() => {
                for (const gd of this.state.graphData) {
                    if (this.alarmGraphMode == EnumAlarmMode.Point) {
                        gd.chartRef.component?.queryPointAlarmData?.({
                            Time: time,
                            EventId: this.alarmData.AlarmEvents[0].Id,
                            PointIds: this.alarmData.AlarmEvents[0].PointIdEigenTypePairs.map((t) => t.PointId)
                        })
                    } else {
                        gd.chartRef.component?.queryFaultAlarmData?.({
                            EventId: this.alarmData.AlarmEvents[0].Id,
                            Time: time
                        })
                    }
                }
            }, 500);
        };
        component.onRangeNavigateChanged = (start, end, action) => {
            this.dataDebounce.run(() => {
                for (const gd of this.state.graphData) {
                    if (this.alarmGraphMode == EnumAlarmMode.Point) {
                        gd.chartRef.component?.queryPointAlarmRangeWaveData?.({
                            Start: start,
                            End: end,
                            PointIds: this.alarmData.AlarmEvents[0].PointIdEigenTypePairs.map((t) => t.PointId),
                            EventId: this.alarmData.AlarmEvents[0].Id,
                            action: action
                        })
                    } else {
                        gd.chartRef.component?.queryFaultAlarmRangeData?.({
                            Start: start,
                            End: end,
                            EventId: this.alarmData.AlarmEvents[0].Id,
                            action: action
                        })
                    }
                }
            }, 500);
        };
        component.onRectCussorChanged = (arr) => {
            if (this.alarmGraphMode == EnumAlarmMode.Point && this.alarmInfo.AlarmType == 6 && this.alarmIndex !== null) {
                const name = this.getSpecialComponent();
                if (this.alarmData.SignalType == 101 || this.alarmData.SignalType == 105) {
                    const fftComponent = this.getComponent(name);
                    fftComponent?.queryPointAlarmData?.({
                        Time: arr[0].Time,
                        EventId: this.alarmData.AlarmEvents[0].Id,
                        PointIds: this.alarmData.AlarmEvents[0].PointIdEigenTypePairs.map((t) => t.PointId)
                    })
                } else {
                    const waveComponent = this.getComponent(name);
                    const spectrumComponent = this.getComponent("spectrumGraphComponent");
                    this.queryAlarmBandEnergyData(arr, waveComponent, spectrumComponent);
                }
            }
        };
    }
    async customComponent(item) {
        item.componentOption.option.showTimeSelector = false;
        item.componentOption.option.HideAlarmLine = true;
        item.componentOption.option.Theme = EnumComponentTheme.Light;
        item.componentOption.option.startIndex = null;
        item.componentOption.option.endIndex = null;
        if (item.componentCategory == "trendGraphComponent") {
            item.componentOption.option.CursorType = PluginMode.NavigateCursor;
            const signalType = this.alarmData.SignalType;
            if (this.alarmInfo.AlarmType == 6 && signalType != AlgorithmTypeEnum.SpectrumAnalysis && signalType != AlgorithmTypeEnum.OrderAnalysis) {
                item.componentOption.option.CursorType = PluginMode.RectCursor;
                item.componentOption.option.showRectCurssor = true;
            }
            item.componentOption.showDoubleNavCursor = true;
        }
        if (item.componentCategory == "navigateGraphComponent") {
            const height = store.state.browseType === 'app' ? 30 : 40;
            item.height = height;
            item.componentOption.height = height;
        }
    }
    async createPointAlarmComponents() {
        await this.createComponent("trendGraphComponent", this.getPointAlarmTrendParam());
        const component = this.getSpecialComponent();
        if (this.alarmInfo.AlarmType == 6) {
            if (this.alarmData.SignalType == 101 || this.alarmData.SignalType == 105) {
                await this.createComponent(component, this.getPointAlarmParam());
            } else {
                await this.createComponent(component, this.getPointAlarmParam());
                await this.createComponent("spectrumGraphComponent", this.getPointAlarmParam());
            }
        } else {
            await this.createComponent(component, this.getPointAlarmParam());
        }
    }
    async createFaultAlarmComponents() {
        const datas = JSON.parse(this.alarmData.FaultParam);
        const naviPoints = await this.getNavigatePoints(datas);
        await this.createComponent("navigateGraphComponent", naviPoints);
        for (const data of datas.filter(r => r.ComponentCategory != 3)) {
            await this.createComponent(data.ComponentName, data);
        }
    }
    getSpecialComponent() {
        switch (this.alarmData.SignalType) {
            case 0:
                return "waveGraphComponent";
            case AlgorithmTypeEnum.IIRFilte:
                return "iirAlgorithmComponent";
            case AlgorithmTypeEnum.IntegralOnce:
                return "integrayAlgorithmComponent";
            case AlgorithmTypeEnum.IntegralTwice:
                return "twiceIntegrayAlgorithmComponent";
            case AlgorithmTypeEnum.DifferentialOnce:
                return "onceDifferentialAlgorithmGraphComponent";
            case AlgorithmTypeEnum.DifferentialTwice:
                return "twiceDifferentialAlgorithmGraphComponent";
            case AlgorithmTypeEnum.StrainRosetteAnalysis:
                return "rosetteAlgorithmGraphComponent";
            case AlgorithmTypeEnum.CableAnalysis:
                return "fftAlgorithmComponent";
            case AlgorithmTypeEnum.EnvelopeAnalysis:
                return "envelopeAlgorithmComponent";
            case AlgorithmTypeEnum.FIRFilte:
                return "firAlgorithmComponent";
            case AlgorithmTypeEnum.CorrelationAnalysis:
                return "correlationAlgorithmComponent";
            case AlgorithmTypeEnum.VirtualCompute:
                return "virtualComputeAlgorithmComponent";
            case AlgorithmTypeEnum.EnvelopeSpectrum:
                return "envelopeSpectrumAlgorithmComponent";
            case AlgorithmTypeEnum.CrossPowerSpectrumDensity:
                return "crossPowerSpectrumDensAlgorithmComponent";
            case AlgorithmTypeEnum.PowerSpectrumDensity:
                return "crossPowerSpectrumDensAlgorithmComponent";
            case AlgorithmTypeEnum.Cepstrum:
                return "cepstrumAlgorithmComponent";
            case AlgorithmTypeEnum.FreqIntegral:
                return "freqIntegralAlgorithmComponent";
            case AlgorithmTypeEnum.TrendForecast:
                return "trendForecastComponent";
            case AlgorithmTypeEnum.SpectrumAnalysis:
                return "fftAlgorithmComponent";
            case AlgorithmTypeEnum.OrderAnalysis:
                return "orderAlgorithmComponent";
            case AlgorithmTypeEnum.TorqueAnalysis:
                return "torqueAlgorithmComponent";
            case AlgorithmTypeEnum.ShaftPowerAnalysis:
                return "shaftPowerAlgorithmComponent";
            case AlgorithmTypeEnum.ResidualStress:
                return "residualStressAlgorithmComponent";
        }
        return null;
    }
    async getNavigatePoints(datas) {
        const pointMap = new Map();
        for (const data of datas.filter(r => r.ComponentCategory != 3)) {
            const points = data.Component.RegisterPoints;
            for (const point of points) {
                if (!pointMap.has(point.PointId)) {
                    const pointTemp = await PointCacheContext.GetInstance().get(point.PointId);
                    pointMap.set(point.PointId, {
                        PointId: point.PointId,
                        PointName: pointTemp.Name,
                        ValueList: []
                    });
                }
                const pTemp = pointMap.get(point.PointId);
                for (const eigen of JSON.parse(point.ValueList)) {
                    if (!pTemp.ValueList.find(r => r.Id == eigen.Id)) {
                        pTemp.ValueList.push(eigen);
                    }
                }
            }
        }
        return {
            Component: {
                RegisterPoints: pointMap.getValues(),
                AlarmPointId: this.alarmData.PointId,
                EventId: this.alarmData.AlarmEvents[0].Id,
            }
        }
    }
    getComponent(name) {
        const item = this.state.graphData.find(r => r.componentCategory == name);
        return item?.chartRef.component;
    }
    getPointAlarmTrendParam() {
        if (this.alarmData.AlarmEvents.length > 0) {
            const registerPoints = this.alarmData.AlarmEvents[0].PointIdEigenTypePairs.map((item) => {
                return {
                    PointId: item.PointId,
                    ValueList: JSON.stringify([
                        {
                            Id: item.EigenType,
                            Name: "",
                        },
                    ]),
                };
            });
            return {
                Component: {
                    RegisterPoints: registerPoints,
                    showHidingCursor: true
                }
            }
        }
        return null;
    }
    getPointAlarmParam() {
        let pointId = this.alarmData.PointId;
        if (this.alarmData.AlarmEvents.length > 0 && this.alarmData.AlarmEvents[0].PointIdEigenTypePairs.length > 0) {
            pointId = this.alarmData.AlarmEvents[0].PointIdEigenTypePairs[0].PointId;
        }
        const param = {
            Component: {
                RegisterPoints: [
                    {
                        PointId: pointId,
                    },
                ],
                Alarm: [],
            }
        }
        if (this.alarmData.AlarmEvents.length > 0 && this.alarmData.AlarmEvents[0].AlarmRulerInfo) {
            const alarmRuler = this.alarmData.AlarmEvents[0].AlarmRulerInfo;
            alarmRuler.RegisterPoints = [];
            alarmRuler.IsEnabled = true;
            switch (this.alarmData.AlarmType) {
                case 1:
                    param.Component.Alarm.push({
                        AlarmCategory: AlarmTypeModelEnum.Amplitude,
                        ...alarmRuler
                    });
                    break;
                case 6:
                    param.Component.Alarm.push({
                        AlarmCategory: AlarmTypeModelEnum.Bandwidth,
                        ...alarmRuler
                    });
                    break;
            }
        }
        return param;
    }
    queryAlarmEigenData(component) {
        if (this.alarmData.AlarmEvents.length == 0) return;
        component?.queryPointAlarmEigenData?.({
            PointId: this.alarmData.PointId,
            EigenTypes: [this.alarmData.AlarmEvents[0].EigenType],
            EventId: this.alarmData.AlarmEvents[0].Id,
            action: (datas) => {
                this.setBandAlarmData(datas, component);
            }
        })
    }
    queryAlarmBandEnergyData(arr, waveComponent, speComponent) {
        if (this.alarmData.AlarmEvents.length == 0) return;
        const { BlockNumber, BlockSize, FirstNumber, LastNumber } = JSON.parse(this.alarmData.AlarmEvents[0].EventParam);
        const firstIndex = arr[0].XIndex;
        let direction = (firstIndex - this.startIndex) / (this.endIndex - this.startIndex);
        let firstNumber = FirstNumber;
        let lastNumber = LastNumber;
        let totalNumber = BlockSize * BlockNumber - (BlockSize - FirstNumber) - (BlockSize - LastNumber);
        if (FirstNumber === BlockSize && LastNumber === BlockSize) {
            firstNumber = FirstNumber;
            lastNumber = LastNumber;
        } else {
            if (direction < 0) {
                while (direction < 0) {
                    lastNumber = BlockSize - firstNumber;
                    const leftBlockNumber = Math.floor((totalNumber - lastNumber) / BlockSize);
                    firstNumber = totalNumber - leftBlockNumber * BlockSize - lastNumber;
                    direction++;
                }
            } else {
                while (direction > 0) {
                    firstNumber = BlockSize - lastNumber;
                    const leftBlockNumber = Math.floor((totalNumber - lastNumber) / BlockSize);
                    lastNumber = totalNumber - leftBlockNumber * BlockSize - firstNumber;
                    direction--;
                }
            }
        }

        const condition = {
            beginTime: arr[0].Time,
            endTime: arr[1].Time,
            firstNumber: firstNumber,
            lastNumber: lastNumber,
            pointIds: [this.alarmData.PointId],
            eventId: this.alarmData.AlarmEvents[0].Id,
        };
        this.dataClient.queryBandEnergyWaveData(condition).then((res) => {
            if (res?.length > 0) {
                waveComponent.setDataContout(res);
                const spe = [];
                // 设置转速
                let speed = 60;
                try {
                    speed = JSON.parse(this.alarmData.AlarmEvents[0].EventParam).Details[0].Detail.RotateSpeed;
                } catch {
                }
                res.forEach((item) => {
                    spe.push({
                        ...item,
                        Datas: item.FFTDatas,
                        RotateSpeed: speed
                    });
                });
                speComponent.queryCondition = {
                    Time: arr[0].Time,
                    PointIds: [this.alarmData.PointId]
                };
                speComponent.setData(spe);
            } else {
                waveComponent.clearData();
                speComponent.clearData();
            }
        });
    }
    setBandAlarmData(datas, component) {
        if (this.alarmInfo.AlarmType == 6 && this.alarmGraphMode == EnumAlarmMode.Point) {
            this.alarmIndex = datas.findIndex((item) => item.AlarmLevel);
            this.endIndex = this.alarmIndex;
            const { BlockNumber } = JSON.parse(this.alarmData.AlarmEvents[0].EventParam);
            this.startIndex = this.endIndex - BlockNumber + 1;
            const rectCursor = component.graph.GetPluginManager()?.GetPlugin(PluginMode.RectCursor);
            rectCursor?.SetPosition([this.startIndex, this.endIndex]);
            rectCursor?.SetBlock(this.startIndex, this.alarmIndex);
            component.option.startIndex = this.startIndex;
            component.option.endIndex = this.endIndex;
            const array = [
                {
                    XIndex: this.startIndex,
                    Time: dayjs(datas[this.startIndex].X).format("YYYY-MM-DD HH:mm:ss.SSS"),
                },
                {
                    XIndex: this.endIndex,
                    Time: dayjs(datas[this.endIndex].X).format("YYYY-MM-DD HH:mm:ss.SSS"),
                },
            ];
            if (this.state.graphData.length >= 2) {
                const name = this.getSpecialComponent();
                if (this.alarmData.SignalType == 101 || this.alarmData.SignalType == 105) {
                    const fftComponent = this.getComponent(name);
                    fftComponent?.queryPointAlarmData?.({
                        Time: array[0].Time,
                        EventId: this.alarmData.AlarmEvents[0].Id,
                        PointIds: this.alarmData.AlarmEvents[0].PointIdEigenTypePairs.map((t) => t.PointId)
                    })
                } else {
                    const spectrumComponent = this.getComponent("spectrumGraphComponent");
                    const waveComponent = this.getComponent(name);
                    this.queryAlarmBandEnergyData(array, waveComponent, spectrumComponent);
                }

            }
        }
    }
    queryAlarmNavigateDatas(component) {
        if (this.alarmData.AlarmEvents.length == 0) return;
        component?.queryFaultAlarmDatas?.({
            PointId: this.alarmData.PointId,
            EigenTypes: [this.alarmData.AlarmEvents[0].EigenType],
            EventId: this.alarmData.AlarmEvents[0].Id,
        })
    }
    queryFaultAlarmEigenData(component, callback) {
        if (this.alarmData.AlarmEvents.length == 0) return;
        component?.queryFaultAlarmEigenData?.({
            EventId: this.alarmData.AlarmEvents[0].Id,
            action: () => {
                callback?.();
            }
        })
    }
    OnSizeChanged() {
        for (const gd of this.state.graphData) {
            gd.chartRef.component?.graph?.OnSizeChanged?.();
        }
    }
    close() {
        this.state.graphData = [];
    }
}