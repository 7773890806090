// @generated by protobuf-ts 2.9.1
// @generated from protobuf file "protos/axispositionhistorydatareply.proto" (package "component", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { DataParamReply } from "./basereply";
import { HistoryPointDataReply } from "./historypointdatareply";
import { DatasReply } from "./basereply";
/**
 * @generated from protobuf message component.AxisPositionHistoryDataReply
 */
export interface AxisPositionHistoryDataReply {
    /**
     * @generated from protobuf field: int32 PointId = 1 [json_name = "PointId"];
     */
    pointId: number;
    /**
     * @generated from protobuf field: int64 SampleTime = 2 [json_name = "SampleTime"];
     */
    sampleTime: bigint;
    /**
     * @generated from protobuf field: component.AxisTrailGraphPointReply Param = 3 [json_name = "Param"];
     */
    param?: AxisTrailGraphPointReply;
    /**
     * @generated from protobuf field: storage.DatasReply Datas = 4 [json_name = "Datas"];
     */
    datas?: DatasReply;
}
/**
 * @generated from protobuf message component.AxisTrailHistoryDataReply
 */
export interface AxisTrailHistoryDataReply {
    /**
     * @generated from protobuf field: int32 PointId = 1 [json_name = "PointId"];
     */
    pointId: number;
    /**
     * @generated from protobuf field: component.AxisTrailGraphPointReply Param = 2 [json_name = "Param"];
     */
    param?: AxisTrailGraphPointReply;
    /**
     * @generated from protobuf field: storage.DatasReply TDatas = 3 [json_name = "TDatas"];
     */
    tDatas?: DatasReply;
    /**
     * @generated from protobuf field: storage.HistoryPointDataReply XDatas = 4 [json_name = "XDatas"];
     */
    xDatas?: HistoryPointDataReply;
    /**
     * @generated from protobuf field: storage.HistoryPointDataReply YDatas = 5 [json_name = "YDatas"];
     */
    yDatas?: HistoryPointDataReply;
    /**
     * @generated from protobuf field: int64 SampleTime = 6 [json_name = "SampleTime"];
     */
    sampleTime: bigint;
}
/**
 * @generated from protobuf message component.GetAxisDatasReply
 */
export interface GetAxisDatasReply {
    /**
     * @generated from protobuf field: repeated component.AxisTrailHistoryDataReply Datas = 1 [json_name = "Datas"];
     */
    datas: AxisTrailHistoryDataReply[];
}
/**
 * @generated from protobuf message component.GetAxisPositionDatasReply
 */
export interface GetAxisPositionDatasReply {
    /**
     * @generated from protobuf field: repeated component.AxisPositionHistoryDataReply Datas = 1 [json_name = "Datas"];
     */
    datas: AxisPositionHistoryDataReply[];
}
/**
 * @generated from protobuf message component.PolarHistoryDataReply
 */
export interface PolarHistoryDataReply {
    /**
     * @generated from protobuf field: int32 PointId = 1 [json_name = "PointId"];
     */
    pointId: number;
    /**
     * @generated from protobuf field: repeated component.PolarPointReply Datas = 2 [json_name = "Datas"];
     */
    datas: PolarPointReply[];
    /**
     * @generated from protobuf field: storage.DataParamReply Param = 3 [json_name = "Param"];
     */
    param?: DataParamReply;
}
/**
 * @generated from protobuf message component.PolarPointReply
 */
export interface PolarPointReply {
    /**
     * @generated from protobuf field: double Amplitude = 1 [json_name = "Amplitude"];
     */
    amplitude: number;
    /**
     * @generated from protobuf field: double Phase = 2 [json_name = "Phase"];
     */
    phase: number;
    /**
     * @generated from protobuf field: double Real = 3 [json_name = "Real"];
     */
    real: number;
    /**
     * @generated from protobuf field: double Imaginary = 4 [json_name = "Imaginary"];
     */
    imaginary: number;
    /**
     * @generated from protobuf field: double Speed = 5 [json_name = "Speed"];
     */
    speed: number;
}
/**
 * @generated from protobuf message component.GetPolarDatasReply
 */
export interface GetPolarDatasReply {
    /**
     * @generated from protobuf field: repeated component.PolarHistoryDataReply Datas = 1 [json_name = "Datas"];
     */
    datas: PolarHistoryDataReply[];
}
/**
 * @generated from protobuf message component.BodePlotHistoryDataReply
 */
export interface BodePlotHistoryDataReply {
    /**
     * @generated from protobuf field: int32 PointId = 1 [json_name = "PointId"];
     */
    pointId: number;
    /**
     * @generated from protobuf field: storage.DatasReply AmpDatas = 2 [json_name = "AmpDatas"];
     */
    ampDatas?: DatasReply;
    /**
     * @generated from protobuf field: storage.DatasReply PhaseDatas = 3 [json_name = "PhaseDatas"];
     */
    phaseDatas?: DatasReply;
    /**
     * @generated from protobuf field: storage.DataParamReply Param = 4 [json_name = "Param"];
     */
    param?: DataParamReply;
}
/**
 * @generated from protobuf message component.GetBodePlotDatasReply
 */
export interface GetBodePlotDatasReply {
    /**
     * @generated from protobuf field: repeated component.BodePlotHistoryDataReply Datas = 1 [json_name = "Datas"];
     */
    datas: BodePlotHistoryDataReply[];
}
/**
 * @generated from protobuf message component.DcComponentDatasReply
 */
export interface DcComponentDatasReply {
    /**
     * @generated from protobuf field: string ComponentId = 1 [json_name = "ComponentId"];
     */
    componentId: string;
    /**
     * @generated from protobuf field: repeated int32 Source = 2 [json_name = "Source"];
     */
    source: number[];
}
/**
 * @generated from protobuf message component.AxisTrailGraphPointReply
 */
export interface AxisTrailGraphPointReply {
    /**
     * @generated from protobuf field: string Name = 1 [json_name = "Name"];
     */
    name: string;
    /**
     * @generated from protobuf field: int32 PointId = 2 [json_name = "PointId"];
     */
    pointId: number;
    /**
     * @generated from protobuf field: string Unit = 3 [json_name = "Unit"];
     */
    unit: string;
    /**
     * @generated from protobuf field: double FullValue = 4 [json_name = "FullValue"];
     */
    fullValue: number;
    /**
     * @generated from protobuf field: double XFullValue = 5 [json_name = "XFullValue"];
     */
    xFullValue: number;
    /**
     * @generated from protobuf field: double YFullValue = 6 [json_name = "YFullValue"];
     */
    yFullValue: number;
    /**
     * @generated from protobuf field: string XUnit = 7 [json_name = "XUnit"];
     */
    xUnit: string;
    /**
     * @generated from protobuf field: string YUnit = 8 [json_name = "YUnit"];
     */
    yUnit: string;
    /**
     * @generated from protobuf field: string XName = 9 [json_name = "XName"];
     */
    xName: string;
    /**
     * @generated from protobuf field: string YName = 10 [json_name = "YName"];
     */
    yName: string;
    /**
     * @generated from protobuf field: int32 XPointId = 11 [json_name = "XPointId"];
     */
    xPointId: number;
    /**
     * @generated from protobuf field: int32 YPointId = 12 [json_name = "YPointId"];
     */
    yPointId: number;
}
// @generated message type with reflection information, may provide speed optimized methods
class AxisPositionHistoryDataReply$Type extends MessageType<AxisPositionHistoryDataReply> {
    constructor() {
        super("component.AxisPositionHistoryDataReply", [
            { no: 1, name: "PointId", kind: "scalar", jsonName: "PointId", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "SampleTime", kind: "scalar", jsonName: "SampleTime", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 3, name: "Param", kind: "message", jsonName: "Param", T: () => AxisTrailGraphPointReply },
            { no: 4, name: "Datas", kind: "message", jsonName: "Datas", T: () => DatasReply }
        ]);
    }
    create(value?: PartialMessage<AxisPositionHistoryDataReply>): AxisPositionHistoryDataReply {
        const message = { pointId: 0, sampleTime: 0n };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AxisPositionHistoryDataReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AxisPositionHistoryDataReply): AxisPositionHistoryDataReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 PointId = 1 [json_name = "PointId"];*/ 1:
                    message.pointId = reader.int32();
                    break;
                case /* int64 SampleTime = 2 [json_name = "SampleTime"];*/ 2:
                    message.sampleTime = reader.int64().toBigInt();
                    break;
                case /* component.AxisTrailGraphPointReply Param = 3 [json_name = "Param"];*/ 3:
                    message.param = AxisTrailGraphPointReply.internalBinaryRead(reader, reader.uint32(), options, message.param);
                    break;
                case /* storage.DatasReply Datas = 4 [json_name = "Datas"];*/ 4:
                    message.datas = DatasReply.internalBinaryRead(reader, reader.uint32(), options, message.datas);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AxisPositionHistoryDataReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 PointId = 1 [json_name = "PointId"]; */
        if (message.pointId !== 0)
            writer.tag(1, WireType.Varint).int32(message.pointId);
        /* int64 SampleTime = 2 [json_name = "SampleTime"]; */
        if (message.sampleTime !== 0n)
            writer.tag(2, WireType.Varint).int64(message.sampleTime);
        /* component.AxisTrailGraphPointReply Param = 3 [json_name = "Param"]; */
        if (message.param)
            AxisTrailGraphPointReply.internalBinaryWrite(message.param, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* storage.DatasReply Datas = 4 [json_name = "Datas"]; */
        if (message.datas)
            DatasReply.internalBinaryWrite(message.datas, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message component.AxisPositionHistoryDataReply
 */
export const AxisPositionHistoryDataReply = new AxisPositionHistoryDataReply$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AxisTrailHistoryDataReply$Type extends MessageType<AxisTrailHistoryDataReply> {
    constructor() {
        super("component.AxisTrailHistoryDataReply", [
            { no: 1, name: "PointId", kind: "scalar", jsonName: "PointId", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "Param", kind: "message", jsonName: "Param", T: () => AxisTrailGraphPointReply },
            { no: 3, name: "TDatas", kind: "message", jsonName: "TDatas", T: () => DatasReply },
            { no: 4, name: "XDatas", kind: "message", jsonName: "XDatas", T: () => HistoryPointDataReply },
            { no: 5, name: "YDatas", kind: "message", jsonName: "YDatas", T: () => HistoryPointDataReply },
            { no: 6, name: "SampleTime", kind: "scalar", jsonName: "SampleTime", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<AxisTrailHistoryDataReply>): AxisTrailHistoryDataReply {
        const message = { pointId: 0, sampleTime: 0n };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AxisTrailHistoryDataReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AxisTrailHistoryDataReply): AxisTrailHistoryDataReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 PointId = 1 [json_name = "PointId"];*/ 1:
                    message.pointId = reader.int32();
                    break;
                case /* component.AxisTrailGraphPointReply Param = 2 [json_name = "Param"];*/ 2:
                    message.param = AxisTrailGraphPointReply.internalBinaryRead(reader, reader.uint32(), options, message.param);
                    break;
                case /* storage.DatasReply TDatas = 3 [json_name = "TDatas"];*/ 3:
                    message.tDatas = DatasReply.internalBinaryRead(reader, reader.uint32(), options, message.tDatas);
                    break;
                case /* storage.HistoryPointDataReply XDatas = 4 [json_name = "XDatas"];*/ 4:
                    message.xDatas = HistoryPointDataReply.internalBinaryRead(reader, reader.uint32(), options, message.xDatas);
                    break;
                case /* storage.HistoryPointDataReply YDatas = 5 [json_name = "YDatas"];*/ 5:
                    message.yDatas = HistoryPointDataReply.internalBinaryRead(reader, reader.uint32(), options, message.yDatas);
                    break;
                case /* int64 SampleTime = 6 [json_name = "SampleTime"];*/ 6:
                    message.sampleTime = reader.int64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AxisTrailHistoryDataReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 PointId = 1 [json_name = "PointId"]; */
        if (message.pointId !== 0)
            writer.tag(1, WireType.Varint).int32(message.pointId);
        /* component.AxisTrailGraphPointReply Param = 2 [json_name = "Param"]; */
        if (message.param)
            AxisTrailGraphPointReply.internalBinaryWrite(message.param, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* storage.DatasReply TDatas = 3 [json_name = "TDatas"]; */
        if (message.tDatas)
            DatasReply.internalBinaryWrite(message.tDatas, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* storage.HistoryPointDataReply XDatas = 4 [json_name = "XDatas"]; */
        if (message.xDatas)
            HistoryPointDataReply.internalBinaryWrite(message.xDatas, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* storage.HistoryPointDataReply YDatas = 5 [json_name = "YDatas"]; */
        if (message.yDatas)
            HistoryPointDataReply.internalBinaryWrite(message.yDatas, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* int64 SampleTime = 6 [json_name = "SampleTime"]; */
        if (message.sampleTime !== 0n)
            writer.tag(6, WireType.Varint).int64(message.sampleTime);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message component.AxisTrailHistoryDataReply
 */
export const AxisTrailHistoryDataReply = new AxisTrailHistoryDataReply$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAxisDatasReply$Type extends MessageType<GetAxisDatasReply> {
    constructor() {
        super("component.GetAxisDatasReply", [
            { no: 1, name: "Datas", kind: "message", jsonName: "Datas", repeat: 1 /*RepeatType.PACKED*/, T: () => AxisTrailHistoryDataReply }
        ]);
    }
    create(value?: PartialMessage<GetAxisDatasReply>): GetAxisDatasReply {
        const message = { datas: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAxisDatasReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAxisDatasReply): GetAxisDatasReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated component.AxisTrailHistoryDataReply Datas = 1 [json_name = "Datas"];*/ 1:
                    message.datas.push(AxisTrailHistoryDataReply.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAxisDatasReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated component.AxisTrailHistoryDataReply Datas = 1 [json_name = "Datas"]; */
        for (let i = 0; i < message.datas.length; i++)
            AxisTrailHistoryDataReply.internalBinaryWrite(message.datas[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message component.GetAxisDatasReply
 */
export const GetAxisDatasReply = new GetAxisDatasReply$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAxisPositionDatasReply$Type extends MessageType<GetAxisPositionDatasReply> {
    constructor() {
        super("component.GetAxisPositionDatasReply", [
            { no: 1, name: "Datas", kind: "message", jsonName: "Datas", repeat: 1 /*RepeatType.PACKED*/, T: () => AxisPositionHistoryDataReply }
        ]);
    }
    create(value?: PartialMessage<GetAxisPositionDatasReply>): GetAxisPositionDatasReply {
        const message = { datas: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAxisPositionDatasReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAxisPositionDatasReply): GetAxisPositionDatasReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated component.AxisPositionHistoryDataReply Datas = 1 [json_name = "Datas"];*/ 1:
                    message.datas.push(AxisPositionHistoryDataReply.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAxisPositionDatasReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated component.AxisPositionHistoryDataReply Datas = 1 [json_name = "Datas"]; */
        for (let i = 0; i < message.datas.length; i++)
            AxisPositionHistoryDataReply.internalBinaryWrite(message.datas[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message component.GetAxisPositionDatasReply
 */
export const GetAxisPositionDatasReply = new GetAxisPositionDatasReply$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PolarHistoryDataReply$Type extends MessageType<PolarHistoryDataReply> {
    constructor() {
        super("component.PolarHistoryDataReply", [
            { no: 1, name: "PointId", kind: "scalar", jsonName: "PointId", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "Datas", kind: "message", jsonName: "Datas", repeat: 1 /*RepeatType.PACKED*/, T: () => PolarPointReply },
            { no: 3, name: "Param", kind: "message", jsonName: "Param", T: () => DataParamReply }
        ]);
    }
    create(value?: PartialMessage<PolarHistoryDataReply>): PolarHistoryDataReply {
        const message = { pointId: 0, datas: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PolarHistoryDataReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PolarHistoryDataReply): PolarHistoryDataReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 PointId = 1 [json_name = "PointId"];*/ 1:
                    message.pointId = reader.int32();
                    break;
                case /* repeated component.PolarPointReply Datas = 2 [json_name = "Datas"];*/ 2:
                    message.datas.push(PolarPointReply.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* storage.DataParamReply Param = 3 [json_name = "Param"];*/ 3:
                    message.param = DataParamReply.internalBinaryRead(reader, reader.uint32(), options, message.param);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PolarHistoryDataReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 PointId = 1 [json_name = "PointId"]; */
        if (message.pointId !== 0)
            writer.tag(1, WireType.Varint).int32(message.pointId);
        /* repeated component.PolarPointReply Datas = 2 [json_name = "Datas"]; */
        for (let i = 0; i < message.datas.length; i++)
            PolarPointReply.internalBinaryWrite(message.datas[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* storage.DataParamReply Param = 3 [json_name = "Param"]; */
        if (message.param)
            DataParamReply.internalBinaryWrite(message.param, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message component.PolarHistoryDataReply
 */
export const PolarHistoryDataReply = new PolarHistoryDataReply$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PolarPointReply$Type extends MessageType<PolarPointReply> {
    constructor() {
        super("component.PolarPointReply", [
            { no: 1, name: "Amplitude", kind: "scalar", jsonName: "Amplitude", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 2, name: "Phase", kind: "scalar", jsonName: "Phase", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 3, name: "Real", kind: "scalar", jsonName: "Real", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 4, name: "Imaginary", kind: "scalar", jsonName: "Imaginary", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 5, name: "Speed", kind: "scalar", jsonName: "Speed", T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value?: PartialMessage<PolarPointReply>): PolarPointReply {
        const message = { amplitude: 0, phase: 0, real: 0, imaginary: 0, speed: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PolarPointReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PolarPointReply): PolarPointReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* double Amplitude = 1 [json_name = "Amplitude"];*/ 1:
                    message.amplitude = reader.double();
                    break;
                case /* double Phase = 2 [json_name = "Phase"];*/ 2:
                    message.phase = reader.double();
                    break;
                case /* double Real = 3 [json_name = "Real"];*/ 3:
                    message.real = reader.double();
                    break;
                case /* double Imaginary = 4 [json_name = "Imaginary"];*/ 4:
                    message.imaginary = reader.double();
                    break;
                case /* double Speed = 5 [json_name = "Speed"];*/ 5:
                    message.speed = reader.double();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PolarPointReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* double Amplitude = 1 [json_name = "Amplitude"]; */
        if (message.amplitude !== 0)
            writer.tag(1, WireType.Bit64).double(message.amplitude);
        /* double Phase = 2 [json_name = "Phase"]; */
        if (message.phase !== 0)
            writer.tag(2, WireType.Bit64).double(message.phase);
        /* double Real = 3 [json_name = "Real"]; */
        if (message.real !== 0)
            writer.tag(3, WireType.Bit64).double(message.real);
        /* double Imaginary = 4 [json_name = "Imaginary"]; */
        if (message.imaginary !== 0)
            writer.tag(4, WireType.Bit64).double(message.imaginary);
        /* double Speed = 5 [json_name = "Speed"]; */
        if (message.speed !== 0)
            writer.tag(5, WireType.Bit64).double(message.speed);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message component.PolarPointReply
 */
export const PolarPointReply = new PolarPointReply$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPolarDatasReply$Type extends MessageType<GetPolarDatasReply> {
    constructor() {
        super("component.GetPolarDatasReply", [
            { no: 1, name: "Datas", kind: "message", jsonName: "Datas", repeat: 1 /*RepeatType.PACKED*/, T: () => PolarHistoryDataReply }
        ]);
    }
    create(value?: PartialMessage<GetPolarDatasReply>): GetPolarDatasReply {
        const message = { datas: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPolarDatasReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPolarDatasReply): GetPolarDatasReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated component.PolarHistoryDataReply Datas = 1 [json_name = "Datas"];*/ 1:
                    message.datas.push(PolarHistoryDataReply.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPolarDatasReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated component.PolarHistoryDataReply Datas = 1 [json_name = "Datas"]; */
        for (let i = 0; i < message.datas.length; i++)
            PolarHistoryDataReply.internalBinaryWrite(message.datas[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message component.GetPolarDatasReply
 */
export const GetPolarDatasReply = new GetPolarDatasReply$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BodePlotHistoryDataReply$Type extends MessageType<BodePlotHistoryDataReply> {
    constructor() {
        super("component.BodePlotHistoryDataReply", [
            { no: 1, name: "PointId", kind: "scalar", jsonName: "PointId", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "AmpDatas", kind: "message", jsonName: "AmpDatas", T: () => DatasReply },
            { no: 3, name: "PhaseDatas", kind: "message", jsonName: "PhaseDatas", T: () => DatasReply },
            { no: 4, name: "Param", kind: "message", jsonName: "Param", T: () => DataParamReply }
        ]);
    }
    create(value?: PartialMessage<BodePlotHistoryDataReply>): BodePlotHistoryDataReply {
        const message = { pointId: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BodePlotHistoryDataReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BodePlotHistoryDataReply): BodePlotHistoryDataReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 PointId = 1 [json_name = "PointId"];*/ 1:
                    message.pointId = reader.int32();
                    break;
                case /* storage.DatasReply AmpDatas = 2 [json_name = "AmpDatas"];*/ 2:
                    message.ampDatas = DatasReply.internalBinaryRead(reader, reader.uint32(), options, message.ampDatas);
                    break;
                case /* storage.DatasReply PhaseDatas = 3 [json_name = "PhaseDatas"];*/ 3:
                    message.phaseDatas = DatasReply.internalBinaryRead(reader, reader.uint32(), options, message.phaseDatas);
                    break;
                case /* storage.DataParamReply Param = 4 [json_name = "Param"];*/ 4:
                    message.param = DataParamReply.internalBinaryRead(reader, reader.uint32(), options, message.param);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BodePlotHistoryDataReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 PointId = 1 [json_name = "PointId"]; */
        if (message.pointId !== 0)
            writer.tag(1, WireType.Varint).int32(message.pointId);
        /* storage.DatasReply AmpDatas = 2 [json_name = "AmpDatas"]; */
        if (message.ampDatas)
            DatasReply.internalBinaryWrite(message.ampDatas, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* storage.DatasReply PhaseDatas = 3 [json_name = "PhaseDatas"]; */
        if (message.phaseDatas)
            DatasReply.internalBinaryWrite(message.phaseDatas, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* storage.DataParamReply Param = 4 [json_name = "Param"]; */
        if (message.param)
            DataParamReply.internalBinaryWrite(message.param, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message component.BodePlotHistoryDataReply
 */
export const BodePlotHistoryDataReply = new BodePlotHistoryDataReply$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetBodePlotDatasReply$Type extends MessageType<GetBodePlotDatasReply> {
    constructor() {
        super("component.GetBodePlotDatasReply", [
            { no: 1, name: "Datas", kind: "message", jsonName: "Datas", repeat: 1 /*RepeatType.PACKED*/, T: () => BodePlotHistoryDataReply }
        ]);
    }
    create(value?: PartialMessage<GetBodePlotDatasReply>): GetBodePlotDatasReply {
        const message = { datas: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetBodePlotDatasReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetBodePlotDatasReply): GetBodePlotDatasReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated component.BodePlotHistoryDataReply Datas = 1 [json_name = "Datas"];*/ 1:
                    message.datas.push(BodePlotHistoryDataReply.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetBodePlotDatasReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated component.BodePlotHistoryDataReply Datas = 1 [json_name = "Datas"]; */
        for (let i = 0; i < message.datas.length; i++)
            BodePlotHistoryDataReply.internalBinaryWrite(message.datas[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message component.GetBodePlotDatasReply
 */
export const GetBodePlotDatasReply = new GetBodePlotDatasReply$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DcComponentDatasReply$Type extends MessageType<DcComponentDatasReply> {
    constructor() {
        super("component.DcComponentDatasReply", [
            { no: 1, name: "ComponentId", kind: "scalar", jsonName: "ComponentId", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "Source", kind: "scalar", jsonName: "Source", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<DcComponentDatasReply>): DcComponentDatasReply {
        const message = { componentId: "", source: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DcComponentDatasReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DcComponentDatasReply): DcComponentDatasReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string ComponentId = 1 [json_name = "ComponentId"];*/ 1:
                    message.componentId = reader.string();
                    break;
                case /* repeated int32 Source = 2 [json_name = "Source"];*/ 2:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.source.push(reader.int32());
                    else
                        message.source.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DcComponentDatasReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string ComponentId = 1 [json_name = "ComponentId"]; */
        if (message.componentId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.componentId);
        /* repeated int32 Source = 2 [json_name = "Source"]; */
        if (message.source.length) {
            writer.tag(2, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.source.length; i++)
                writer.int32(message.source[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message component.DcComponentDatasReply
 */
export const DcComponentDatasReply = new DcComponentDatasReply$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AxisTrailGraphPointReply$Type extends MessageType<AxisTrailGraphPointReply> {
    constructor() {
        super("component.AxisTrailGraphPointReply", [
            { no: 1, name: "Name", kind: "scalar", jsonName: "Name", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "PointId", kind: "scalar", jsonName: "PointId", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "Unit", kind: "scalar", jsonName: "Unit", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "FullValue", kind: "scalar", jsonName: "FullValue", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 5, name: "XFullValue", kind: "scalar", jsonName: "XFullValue", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 6, name: "YFullValue", kind: "scalar", jsonName: "YFullValue", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 7, name: "XUnit", kind: "scalar", jsonName: "XUnit", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "YUnit", kind: "scalar", jsonName: "YUnit", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "XName", kind: "scalar", jsonName: "XName", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "YName", kind: "scalar", jsonName: "YName", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "XPointId", kind: "scalar", jsonName: "XPointId", T: 5 /*ScalarType.INT32*/ },
            { no: 12, name: "YPointId", kind: "scalar", jsonName: "YPointId", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<AxisTrailGraphPointReply>): AxisTrailGraphPointReply {
        const message = { name: "", pointId: 0, unit: "", fullValue: 0, xFullValue: 0, yFullValue: 0, xUnit: "", yUnit: "", xName: "", yName: "", xPointId: 0, yPointId: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AxisTrailGraphPointReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AxisTrailGraphPointReply): AxisTrailGraphPointReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string Name = 1 [json_name = "Name"];*/ 1:
                    message.name = reader.string();
                    break;
                case /* int32 PointId = 2 [json_name = "PointId"];*/ 2:
                    message.pointId = reader.int32();
                    break;
                case /* string Unit = 3 [json_name = "Unit"];*/ 3:
                    message.unit = reader.string();
                    break;
                case /* double FullValue = 4 [json_name = "FullValue"];*/ 4:
                    message.fullValue = reader.double();
                    break;
                case /* double XFullValue = 5 [json_name = "XFullValue"];*/ 5:
                    message.xFullValue = reader.double();
                    break;
                case /* double YFullValue = 6 [json_name = "YFullValue"];*/ 6:
                    message.yFullValue = reader.double();
                    break;
                case /* string XUnit = 7 [json_name = "XUnit"];*/ 7:
                    message.xUnit = reader.string();
                    break;
                case /* string YUnit = 8 [json_name = "YUnit"];*/ 8:
                    message.yUnit = reader.string();
                    break;
                case /* string XName = 9 [json_name = "XName"];*/ 9:
                    message.xName = reader.string();
                    break;
                case /* string YName = 10 [json_name = "YName"];*/ 10:
                    message.yName = reader.string();
                    break;
                case /* int32 XPointId = 11 [json_name = "XPointId"];*/ 11:
                    message.xPointId = reader.int32();
                    break;
                case /* int32 YPointId = 12 [json_name = "YPointId"];*/ 12:
                    message.yPointId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AxisTrailGraphPointReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string Name = 1 [json_name = "Name"]; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* int32 PointId = 2 [json_name = "PointId"]; */
        if (message.pointId !== 0)
            writer.tag(2, WireType.Varint).int32(message.pointId);
        /* string Unit = 3 [json_name = "Unit"]; */
        if (message.unit !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.unit);
        /* double FullValue = 4 [json_name = "FullValue"]; */
        if (message.fullValue !== 0)
            writer.tag(4, WireType.Bit64).double(message.fullValue);
        /* double XFullValue = 5 [json_name = "XFullValue"]; */
        if (message.xFullValue !== 0)
            writer.tag(5, WireType.Bit64).double(message.xFullValue);
        /* double YFullValue = 6 [json_name = "YFullValue"]; */
        if (message.yFullValue !== 0)
            writer.tag(6, WireType.Bit64).double(message.yFullValue);
        /* string XUnit = 7 [json_name = "XUnit"]; */
        if (message.xUnit !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.xUnit);
        /* string YUnit = 8 [json_name = "YUnit"]; */
        if (message.yUnit !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.yUnit);
        /* string XName = 9 [json_name = "XName"]; */
        if (message.xName !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.xName);
        /* string YName = 10 [json_name = "YName"]; */
        if (message.yName !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.yName);
        /* int32 XPointId = 11 [json_name = "XPointId"]; */
        if (message.xPointId !== 0)
            writer.tag(11, WireType.Varint).int32(message.xPointId);
        /* int32 YPointId = 12 [json_name = "YPointId"]; */
        if (message.yPointId !== 0)
            writer.tag(12, WireType.Varint).int32(message.yPointId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message component.AxisTrailGraphPointReply
 */
export const AxisTrailGraphPointReply = new AxisTrailGraphPointReply$Type();
