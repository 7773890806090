import { ISignalRHandler, SignalRInstance } from "@common/js/signalRClient";
import { PointCacheContext } from "./clientRealContext";
import { DataParserContext } from "./dataParserContext";

export class DataRealContext extends ISignalRHandler {
    constructor() {
        super();
        this.SupportContentType = [this.getContractType()];
        /**图谱注册的数据回调委托 */
        this.dataReceiveds = new Map();
        this.pointCacheContext = PointCacheContext.GetInstance();
        this.dataParser = DataParserContext.GetInstance();
    }
    getContractType() { }
    OnReconnected() {
        //发送注册实时数据
        this.sendRegisterPointsToClinet();
    }
    OnConnected() {
        this.sendRegisterPointsToClinet();
    }
    ReceiveData(data) {
        try {
            if (data.Content == null && data.Content.length == 0) {
                return;
            }
            this.onDataReceived(data.Content);
        } catch (e) {
            debugger;
            console.log(e.message);
        }
    }
    onDataReceived() { }
    Clear() {
        this.sendUnRegisterPointsToClient();
        this.dataReceiveds.clear();
    }
    IsSupport(contentType) {
        return this.SupportContentType.findIndex((r) => r == contentType) >= 0;
    }
    registerDataCalback(requestId, callback) {
        this.dataReceiveds.set(requestId, callback);
    }
    removeDataCalback(requestId) {
        this.dataReceiveds.delete(requestId);
        this.unRegisterPoints(requestId);
    }
    async registerPoints() { }
    unRegisterPoints() { }
    sendRegisterPointsToClinet() { }
    sendUnRegisterPointsToClient() { }
    getConnectionIds() {
        const signalR = SignalRInstance.GetInstance();
        if (!signalR) return [];
        return signalR.GetConnectorIds();
    }
}
