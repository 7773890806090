import $ from "jquery";
import { PluginBase } from "./DH-plugin-base";
import { i18nGlobal } from "@common/js/utils";

export class SignalProcessPlugin extends PluginBase {
    constructor() {
        super();
        this.SignalProcessWnd = null;
        this.$select = null;
        this.SelectedItem = null;
        this.Left = 0;
        this.Top = null;
        this.SignalProcessList = [
            {
                name: i18nGlobal('component.title.integralOnce'),
                type: 1,
            },
            {
                name: i18nGlobal('core.secondOrderClustering'),
                type: 2,
            },
            {
                name: i18nGlobal('core.KMeanClustering'),
                type: 3,
            },
            {
                name: i18nGlobal('core.systemClustering'),
                type: 4,
            },
            {
                name: i18nGlobal('core.decisionTree'),
                type: 5,
            },
            {
                name: i18nGlobal('core.discriminant'),
                type: 6,
            },
            {
                name: i18nGlobal('core.RocCurve'),
                type: 7,
            },
            {
                name: i18nGlobal('core.RocAnalysis'),
                type: 8,
            },
            {
                name: i18nGlobal('core.bivariate'),
                type: 9,
            },
            {
                name: i18nGlobal('core.partialCorrelation'),
                type: 10,
            },
            {
                name: i18nGlobal('core.distance'),
                type: 11,
            },
            {
                name: i18nGlobal('core.multilayerPerceptron'),
                type: 12,
            },
            {
                name: i18nGlobal('core.radialBasisFunction'),
                type: 13,
            },
            {
                name: i18nGlobal('core.factor'),
                type: 14,
            },
            {
                name: i18nGlobal('core.correspondenceAnalysis'),
                type: 15,
            },
            {
                name: i18nGlobal('core.lifeTable'),
                type: 16,
            },
            {
                name: "Kaplan-Meier",
                type: 17,
            },
            {
                name: i18nGlobal('core.CoxRegression'),
                type: 18,
            },
            {
                name: i18nGlobal('core.sequenceDiagram'),
                type: 19,
            },
            {
                name: i18nGlobal('core.autocorrelation'),
                type: 20,
            },
            {
                name: i18nGlobal('core.crossCorrelation'),
                type: 21,
            },
        ];
    }
    CreatePlugin() {
        var rect = this.Owner.Information.ShapeContainerRect;
        this.Left = 0;
        this.Top = 0;
        var div =
            '<div class="dropdown open" style="position:absolute;left:' +
            this.Left +
            "px;top:" +
            this.Top +
            `;px">\
	                    <a style="display: inline-block;border: 1px solid #ccc;border-radius: 3px;cursor:pointer;padding: 5px;" href="#" role="button" id="SignalProcessdropdownMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">\
		                    ${i18nGlobal('core.signalDispose')}\
	                    </a>\
	                    <ul class="dropdown-menu" aria-labelledby="SignalProcessdropdownMenu" style="height:150px; overflow-y:auto;padding-left:10px;">\
	                    </ul>\
	                </div>`;
        var select = '<select id="SignalSelcet" style="width:110px;position:absolute;left:' + this.Left + "px;top:" + this.Top + ';px" ctrl="select" prop="SignalSelcet"></select>';
        this.$select = $(select);
        this.SignalProcessWnd = $(div);
        var $dropdownMenu = this.SignalProcessWnd.find(".dropdown-menu");

        $.each(this.SignalProcessList, function (i, data) {
            $dropdownMenu.append(
                '<li class="dropdown-item" data-type="' +
                data.type +
                '" >\
                <label style="vertical-align: text-top; text-align: center;">\
                    <input id="IsSelectItem' +
                i +
                '" type="checkbox" class="ace" name="IsSelectItem" value="' +
                data.type +
                '" data-name="' +
                data.name +
                '">\
                    <span class="lbl"></span>\
                </label>\
                <span style="margin-left: 10px;font-size: 12px; cursor:default;" class="lbl" for="IsSelectItem' +
                i +
                '">' +
                data.name +
                "</span></li>"
            );
        });
        $(this.Owner.ChartContainer).append(this.SignalProcessWnd);
        $(this.Owner.ChartContainer).append(this.$select);
        $(this.Owner.ChartContainer).find("#SignalProcessdropdownMenu").dropdown("toggle");
    }
    OnSizeChanged() {
        var rect = this.Owner.Information.ShapeContainerRect;
        var left = rect.Width / 2 + 60;
        var top = this.Top;
        if (left < 0) left = 0;
        if (top < 0) top = 0;
        this.SignalProcessWnd.css("left", left);
        this.SignalProcessWnd.css("top", top);
        this.$select.css({
            left: left + 70,
            top: top,
        });
        this.InitSvgEvent();
    }
    OnRender() {
        const sender = this;
        this.SignalProcessWnd.find("input").on("click", function (e) {
            if ($(this).is(":checked")) {
                if (sender.$select.find("option[value='" + e.target.value + "']").length === 0) {
                    sender.$select.append('<option value="' + e.target.value + '">' + e.target.dataset.name + "</option>");
                    sender.$select.val(e.target.value);
                }
            } else {
                if (sender.$select.find("option[value='" + e.target.value + "']").length !== 0) {
                    sender.$select.find("option[value='" + e.target.value + "']").remove();
                }
            }
        });
    }
    InitSvgEvent() { }
    Close() {
        this.SignalProcessWnd.remove();
        this.SignalProcessWnd = null;
    }
}
