// @generated by protobuf-ts 2.9.1
// @generated from protobuf file "protos/alarm.proto" (package "alarm", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { Alarm } from "./alarm";
import type { CalculateAlarmNearestWaveDataRequest } from "./calculatealarmnearestwavedata";
import type { QueryAlarmDatasWithLimitRequest } from "./alarmdataswithlimit";
import type { QueryNearestPointsEigenDatasRequest } from "./nearestpointseigendatas";
import type { QueryNearestPointsWaveDatasRequest } from "./nearestpointswavedatas";
import type { QueryBandEnergyAlarmDatasRequest } from "./bandenergyalarmdatas";
import type { QueryAlarmPointsRangeWaveDatasRequest } from "./alarmpointsrangewavedatas";
import type { QueryAlarmPointsWaveDatasRequest } from "./alarmpointswavedatas";
import type { QueryAlarmPointsDatasRequest } from "./alarmpointsdatas";
import type { QueryAlarmPointsEigenDatasRequest } from "./alarmpointseigendatas";
import type { QueryAlarmPointsSpeedsRequest } from "./alarmpointsspeeds";
import type { QueryAlarmPointsEigensDatasRequest } from "./alarmpointseigensdatas";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { Reply } from "./reply";
import type { GetSelfLearningFeatureDatasRequest } from "./selflearningfeaturedatas";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service alarm.Alarm
 */
export interface IAlarmClient {
    /**
     * @generated from protobuf rpc: GetSelfLearningFeatureDatas(alarm.GetSelfLearningFeatureDatasRequest) returns (storage.Reply);
     */
    getSelfLearningFeatureDatas(input: GetSelfLearningFeatureDatasRequest, options?: RpcOptions): UnaryCall<GetSelfLearningFeatureDatasRequest, Reply>;
    /**
     * @generated from protobuf rpc: QueryAlarmPointsEigensDatasAsync(alarm.QueryAlarmPointsEigensDatasRequest) returns (storage.Reply);
     */
    queryAlarmPointsEigensDatasAsync(input: QueryAlarmPointsEigensDatasRequest, options?: RpcOptions): UnaryCall<QueryAlarmPointsEigensDatasRequest, Reply>;
    /**
     * @generated from protobuf rpc: QueryAlarmEigensDatasAsync(alarm.QueryAlarmPointsEigensDatasRequest) returns (storage.Reply);
     */
    queryAlarmEigensDatasAsync(input: QueryAlarmPointsEigensDatasRequest, options?: RpcOptions): UnaryCall<QueryAlarmPointsEigensDatasRequest, Reply>;
    /**
     * @generated from protobuf rpc: QueryAlarmSpeedsDatasAsync(alarm.QueryAlarmPointsSpeedsRequest) returns (storage.Reply);
     */
    queryAlarmSpeedsDatasAsync(input: QueryAlarmPointsSpeedsRequest, options?: RpcOptions): UnaryCall<QueryAlarmPointsSpeedsRequest, Reply>;
    /**
     * @generated from protobuf rpc: QueryFirstAlarmEigenDatas(alarm.QueryAlarmPointsEigenDatasRequest) returns (storage.Reply);
     */
    queryFirstAlarmEigenDatas(input: QueryAlarmPointsEigenDatasRequest, options?: RpcOptions): UnaryCall<QueryAlarmPointsEigenDatasRequest, Reply>;
    /**
     * @generated from protobuf rpc: QueryAlarmPointsDatasAsync(alarm.QueryAlarmPointsDatasRequest) returns (storage.Reply);
     */
    queryAlarmPointsDatasAsync(input: QueryAlarmPointsDatasRequest, options?: RpcOptions): UnaryCall<QueryAlarmPointsDatasRequest, Reply>;
    /**
     * @generated from protobuf rpc: QueryAlarmPointsWaveDatasAsync(alarm.QueryAlarmPointsWaveDatasRequest) returns (storage.Reply);
     */
    queryAlarmPointsWaveDatasAsync(input: QueryAlarmPointsWaveDatasRequest, options?: RpcOptions): UnaryCall<QueryAlarmPointsWaveDatasRequest, Reply>;
    /**
     * @generated from protobuf rpc: QueryAlarmPointsRangeWaveDatasAsync(alarm.QueryAlarmPointsRangeWaveDatasRequest) returns (storage.Reply);
     */
    queryAlarmPointsRangeWaveDatasAsync(input: QueryAlarmPointsRangeWaveDatasRequest, options?: RpcOptions): UnaryCall<QueryAlarmPointsRangeWaveDatasRequest, Reply>;
    /**
     * @generated from protobuf rpc: QueryBandEnergyWaveDataAsync(alarm.QueryBandEnergyAlarmDatasRequest) returns (storage.Reply);
     */
    queryBandEnergyWaveDataAsync(input: QueryBandEnergyAlarmDatasRequest, options?: RpcOptions): UnaryCall<QueryBandEnergyAlarmDatasRequest, Reply>;
    /**
     * @generated from protobuf rpc: QueryNearestPointsWaveDatas(alarm.QueryNearestPointsWaveDatasRequest) returns (storage.Reply);
     */
    queryNearestPointsWaveDatas(input: QueryNearestPointsWaveDatasRequest, options?: RpcOptions): UnaryCall<QueryNearestPointsWaveDatasRequest, Reply>;
    /**
     * @generated from protobuf rpc: QueryNextPointsWaveDatas(alarm.QueryNearestPointsWaveDatasRequest) returns (storage.Reply);
     */
    queryNextPointsWaveDatas(input: QueryNearestPointsWaveDatasRequest, options?: RpcOptions): UnaryCall<QueryNearestPointsWaveDatasRequest, Reply>;
    /**
     * @generated from protobuf rpc: QueryPrePointsWaveDatas(alarm.QueryNearestPointsWaveDatasRequest) returns (storage.Reply);
     */
    queryPrePointsWaveDatas(input: QueryNearestPointsWaveDatasRequest, options?: RpcOptions): UnaryCall<QueryNearestPointsWaveDatasRequest, Reply>;
    /**
     * @generated from protobuf rpc: QueryNearestPointsEigenDatas(alarm.QueryNearestPointsEigenDatasRequest) returns (storage.Reply);
     */
    queryNearestPointsEigenDatas(input: QueryNearestPointsEigenDatasRequest, options?: RpcOptions): UnaryCall<QueryNearestPointsEigenDatasRequest, Reply>;
    /**
     * @generated from protobuf rpc: QueryNextPointsEigenDatas(alarm.QueryNearestPointsEigenDatasRequest) returns (storage.Reply);
     */
    queryNextPointsEigenDatas(input: QueryNearestPointsEigenDatasRequest, options?: RpcOptions): UnaryCall<QueryNearestPointsEigenDatasRequest, Reply>;
    /**
     * @generated from protobuf rpc: QueryPrePointsEigenDatas(alarm.QueryNearestPointsEigenDatasRequest) returns (storage.Reply);
     */
    queryPrePointsEigenDatas(input: QueryNearestPointsEigenDatasRequest, options?: RpcOptions): UnaryCall<QueryNearestPointsEigenDatasRequest, Reply>;
    /**
     * @generated from protobuf rpc: QueryNearestAlarmDataWithLimit(alarm.QueryAlarmDatasWithLimitRequest) returns (storage.Reply);
     */
    queryNearestAlarmDataWithLimit(input: QueryAlarmDatasWithLimitRequest, options?: RpcOptions): UnaryCall<QueryAlarmDatasWithLimitRequest, Reply>;
    /**
     * @generated from protobuf rpc: QueryNextAlarmDataWithLimit(alarm.QueryAlarmDatasWithLimitRequest) returns (storage.Reply);
     */
    queryNextAlarmDataWithLimit(input: QueryAlarmDatasWithLimitRequest, options?: RpcOptions): UnaryCall<QueryAlarmDatasWithLimitRequest, Reply>;
    /**
     * @generated from protobuf rpc: QueryPreAlarmDataWithLimit(alarm.QueryAlarmDatasWithLimitRequest) returns (storage.Reply);
     */
    queryPreAlarmDataWithLimit(input: QueryAlarmDatasWithLimitRequest, options?: RpcOptions): UnaryCall<QueryAlarmDatasWithLimitRequest, Reply>;
    /**
     * @generated from protobuf rpc: CalculateAlarmNearestWaveData(alarm.CalculateAlarmNearestWaveDataRequest) returns (storage.Reply);
     */
    calculateAlarmNearestWaveData(input: CalculateAlarmNearestWaveDataRequest, options?: RpcOptions): UnaryCall<CalculateAlarmNearestWaveDataRequest, Reply>;
    /**
     * @generated from protobuf rpc: CalculateAlarmNextWaveData(alarm.CalculateAlarmNearestWaveDataRequest) returns (storage.Reply);
     */
    calculateAlarmNextWaveData(input: CalculateAlarmNearestWaveDataRequest, options?: RpcOptions): UnaryCall<CalculateAlarmNearestWaveDataRequest, Reply>;
    /**
     * @generated from protobuf rpc: CalculateAlarmPreWaveData(alarm.CalculateAlarmNearestWaveDataRequest) returns (storage.Reply);
     */
    calculateAlarmPreWaveData(input: CalculateAlarmNearestWaveDataRequest, options?: RpcOptions): UnaryCall<CalculateAlarmNearestWaveDataRequest, Reply>;
}
/**
 * @generated from protobuf service alarm.Alarm
 */
export class AlarmClient implements IAlarmClient, ServiceInfo {
    typeName = Alarm.typeName;
    methods = Alarm.methods;
    options = Alarm.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: GetSelfLearningFeatureDatas(alarm.GetSelfLearningFeatureDatasRequest) returns (storage.Reply);
     */
    getSelfLearningFeatureDatas(input: GetSelfLearningFeatureDatasRequest, options?: RpcOptions): UnaryCall<GetSelfLearningFeatureDatasRequest, Reply> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSelfLearningFeatureDatasRequest, Reply>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: QueryAlarmPointsEigensDatasAsync(alarm.QueryAlarmPointsEigensDatasRequest) returns (storage.Reply);
     */
    queryAlarmPointsEigensDatasAsync(input: QueryAlarmPointsEigensDatasRequest, options?: RpcOptions): UnaryCall<QueryAlarmPointsEigensDatasRequest, Reply> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<QueryAlarmPointsEigensDatasRequest, Reply>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: QueryAlarmEigensDatasAsync(alarm.QueryAlarmPointsEigensDatasRequest) returns (storage.Reply);
     */
    queryAlarmEigensDatasAsync(input: QueryAlarmPointsEigensDatasRequest, options?: RpcOptions): UnaryCall<QueryAlarmPointsEigensDatasRequest, Reply> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<QueryAlarmPointsEigensDatasRequest, Reply>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: QueryAlarmSpeedsDatasAsync(alarm.QueryAlarmPointsSpeedsRequest) returns (storage.Reply);
     */
    queryAlarmSpeedsDatasAsync(input: QueryAlarmPointsSpeedsRequest, options?: RpcOptions): UnaryCall<QueryAlarmPointsSpeedsRequest, Reply> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<QueryAlarmPointsSpeedsRequest, Reply>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: QueryFirstAlarmEigenDatas(alarm.QueryAlarmPointsEigenDatasRequest) returns (storage.Reply);
     */
    queryFirstAlarmEigenDatas(input: QueryAlarmPointsEigenDatasRequest, options?: RpcOptions): UnaryCall<QueryAlarmPointsEigenDatasRequest, Reply> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<QueryAlarmPointsEigenDatasRequest, Reply>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: QueryAlarmPointsDatasAsync(alarm.QueryAlarmPointsDatasRequest) returns (storage.Reply);
     */
    queryAlarmPointsDatasAsync(input: QueryAlarmPointsDatasRequest, options?: RpcOptions): UnaryCall<QueryAlarmPointsDatasRequest, Reply> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<QueryAlarmPointsDatasRequest, Reply>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: QueryAlarmPointsWaveDatasAsync(alarm.QueryAlarmPointsWaveDatasRequest) returns (storage.Reply);
     */
    queryAlarmPointsWaveDatasAsync(input: QueryAlarmPointsWaveDatasRequest, options?: RpcOptions): UnaryCall<QueryAlarmPointsWaveDatasRequest, Reply> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<QueryAlarmPointsWaveDatasRequest, Reply>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: QueryAlarmPointsRangeWaveDatasAsync(alarm.QueryAlarmPointsRangeWaveDatasRequest) returns (storage.Reply);
     */
    queryAlarmPointsRangeWaveDatasAsync(input: QueryAlarmPointsRangeWaveDatasRequest, options?: RpcOptions): UnaryCall<QueryAlarmPointsRangeWaveDatasRequest, Reply> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<QueryAlarmPointsRangeWaveDatasRequest, Reply>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: QueryBandEnergyWaveDataAsync(alarm.QueryBandEnergyAlarmDatasRequest) returns (storage.Reply);
     */
    queryBandEnergyWaveDataAsync(input: QueryBandEnergyAlarmDatasRequest, options?: RpcOptions): UnaryCall<QueryBandEnergyAlarmDatasRequest, Reply> {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept<QueryBandEnergyAlarmDatasRequest, Reply>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: QueryNearestPointsWaveDatas(alarm.QueryNearestPointsWaveDatasRequest) returns (storage.Reply);
     */
    queryNearestPointsWaveDatas(input: QueryNearestPointsWaveDatasRequest, options?: RpcOptions): UnaryCall<QueryNearestPointsWaveDatasRequest, Reply> {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept<QueryNearestPointsWaveDatasRequest, Reply>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: QueryNextPointsWaveDatas(alarm.QueryNearestPointsWaveDatasRequest) returns (storage.Reply);
     */
    queryNextPointsWaveDatas(input: QueryNearestPointsWaveDatasRequest, options?: RpcOptions): UnaryCall<QueryNearestPointsWaveDatasRequest, Reply> {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept<QueryNearestPointsWaveDatasRequest, Reply>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: QueryPrePointsWaveDatas(alarm.QueryNearestPointsWaveDatasRequest) returns (storage.Reply);
     */
    queryPrePointsWaveDatas(input: QueryNearestPointsWaveDatasRequest, options?: RpcOptions): UnaryCall<QueryNearestPointsWaveDatasRequest, Reply> {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return stackIntercept<QueryNearestPointsWaveDatasRequest, Reply>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: QueryNearestPointsEigenDatas(alarm.QueryNearestPointsEigenDatasRequest) returns (storage.Reply);
     */
    queryNearestPointsEigenDatas(input: QueryNearestPointsEigenDatasRequest, options?: RpcOptions): UnaryCall<QueryNearestPointsEigenDatasRequest, Reply> {
        const method = this.methods[12], opt = this._transport.mergeOptions(options);
        return stackIntercept<QueryNearestPointsEigenDatasRequest, Reply>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: QueryNextPointsEigenDatas(alarm.QueryNearestPointsEigenDatasRequest) returns (storage.Reply);
     */
    queryNextPointsEigenDatas(input: QueryNearestPointsEigenDatasRequest, options?: RpcOptions): UnaryCall<QueryNearestPointsEigenDatasRequest, Reply> {
        const method = this.methods[13], opt = this._transport.mergeOptions(options);
        return stackIntercept<QueryNearestPointsEigenDatasRequest, Reply>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: QueryPrePointsEigenDatas(alarm.QueryNearestPointsEigenDatasRequest) returns (storage.Reply);
     */
    queryPrePointsEigenDatas(input: QueryNearestPointsEigenDatasRequest, options?: RpcOptions): UnaryCall<QueryNearestPointsEigenDatasRequest, Reply> {
        const method = this.methods[14], opt = this._transport.mergeOptions(options);
        return stackIntercept<QueryNearestPointsEigenDatasRequest, Reply>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: QueryNearestAlarmDataWithLimit(alarm.QueryAlarmDatasWithLimitRequest) returns (storage.Reply);
     */
    queryNearestAlarmDataWithLimit(input: QueryAlarmDatasWithLimitRequest, options?: RpcOptions): UnaryCall<QueryAlarmDatasWithLimitRequest, Reply> {
        const method = this.methods[15], opt = this._transport.mergeOptions(options);
        return stackIntercept<QueryAlarmDatasWithLimitRequest, Reply>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: QueryNextAlarmDataWithLimit(alarm.QueryAlarmDatasWithLimitRequest) returns (storage.Reply);
     */
    queryNextAlarmDataWithLimit(input: QueryAlarmDatasWithLimitRequest, options?: RpcOptions): UnaryCall<QueryAlarmDatasWithLimitRequest, Reply> {
        const method = this.methods[16], opt = this._transport.mergeOptions(options);
        return stackIntercept<QueryAlarmDatasWithLimitRequest, Reply>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: QueryPreAlarmDataWithLimit(alarm.QueryAlarmDatasWithLimitRequest) returns (storage.Reply);
     */
    queryPreAlarmDataWithLimit(input: QueryAlarmDatasWithLimitRequest, options?: RpcOptions): UnaryCall<QueryAlarmDatasWithLimitRequest, Reply> {
        const method = this.methods[17], opt = this._transport.mergeOptions(options);
        return stackIntercept<QueryAlarmDatasWithLimitRequest, Reply>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CalculateAlarmNearestWaveData(alarm.CalculateAlarmNearestWaveDataRequest) returns (storage.Reply);
     */
    calculateAlarmNearestWaveData(input: CalculateAlarmNearestWaveDataRequest, options?: RpcOptions): UnaryCall<CalculateAlarmNearestWaveDataRequest, Reply> {
        const method = this.methods[18], opt = this._transport.mergeOptions(options);
        return stackIntercept<CalculateAlarmNearestWaveDataRequest, Reply>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CalculateAlarmNextWaveData(alarm.CalculateAlarmNearestWaveDataRequest) returns (storage.Reply);
     */
    calculateAlarmNextWaveData(input: CalculateAlarmNearestWaveDataRequest, options?: RpcOptions): UnaryCall<CalculateAlarmNearestWaveDataRequest, Reply> {
        const method = this.methods[19], opt = this._transport.mergeOptions(options);
        return stackIntercept<CalculateAlarmNearestWaveDataRequest, Reply>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CalculateAlarmPreWaveData(alarm.CalculateAlarmNearestWaveDataRequest) returns (storage.Reply);
     */
    calculateAlarmPreWaveData(input: CalculateAlarmNearestWaveDataRequest, options?: RpcOptions): UnaryCall<CalculateAlarmNearestWaveDataRequest, Reply> {
        const method = this.methods[20], opt = this._transport.mergeOptions(options);
        return stackIntercept<CalculateAlarmNearestWaveDataRequest, Reply>("unary", this._transport, method, opt, input);
    }
}
