const EN = {
    "graph": {
        "trend": "Trend",
        "wave": "Wave",
        "spectrogram": "Spectrogram",
        "orderGraph": "Order graph",
        "orderAnalysisGraph": "Order analysis graph"
    },
    "commonJs": {
        "code400": "Request error",
        "code403": "No permission",
        "code404": "Request address error",
        "code405": "Requset method error",
        "code406": "No permissions",
        "code408": "Rquest timeout",
        "code500": "Internal server error",
        "code501": "Service not implemented",
        "code502": "Bad gateway",
        "code503": "Service unavailable",
        "code504": "Gateway timeout",
        "code505": "HTTP version not supported",
        "codeDefault": "Request was aborted",
        "tokenLoseTip": "Invalid login information, please login again",
        "integerTip": "Please enter a positive integer",
        "progress": "Progress",
        "warningValue": 'Warning Value',
        "dangerValue": 'Danger Value',
        "leaveTip": 'Are you sure close Phm?'
    },
    "app": {
        "licenseTip": 'The current system has not obtained the authorization, please go to the web terminal to import the license file！',
        "instrumentStatus": "Instrument status",
        "alarmStatistics": "Alarm statistics",
        "deviceNum": "Device number",
        "acquisitionInstruments": "Acquisition instruments",
        "channelStatus": "Channel status",
        "chooseDepart": "Choose department",
        "chooseDevice": "Choose device",
        "homePage": "Home page",
        "message": "Message",
        "mine": "Mine",
        "enter": "Enter",
        "back": "Back",
        "backToHome": "Back to home",
        "noContent": "No content",
        "confirmType": "Confirm type",
        "noMore": "No more",
        "messageList": "Message list",
        "info": "information",
        "aboutUs": "About us",
        "signOut": "Sign out",
        "chooseTime": "Choose time",
        "chooseDate": "Choose date"
    },
    "headerSetting": {
        "changePwd": {
            "title": "Change Password",
            "oldPwd": "Old password",
            "newPwd": "New password",
            "retypePwd": "Retype new password",
            "oldPwdPlaceholder": "Please enter your old password",
            "newPwdPlaceholder": "Please enter your new password",
            "retypePwdPlaceholder": "Please enter new password again",
            "samePwdToolTip": "Same as old password",
            "differentPwdToolTip": "Two different passwords",
            "notContainCnToolTip": "The password cannot contain Chinese characters, the length is 6-20"
        },
        "exportTask": {
            "title": "System Tasks",
            "subTitle": "Export task",
            "exportTime": "Export time",
            "retry": "Retry",
            "exportContent": "Export content",
            "exportStatus": "Export status",
            "exportProgress": "Export progress",
            "obtainingFiles": "Obtaining files...",
            "unexecuted": "Unexecuted",
            "completed": "Completed",
            "toBeContinued": 'To Be Continued',
            "exportFailed": "Export failed",
            "noRetryTask": "There are no tasks that need to be retried",
            "backupContent": "Backup content",
            "backupStatus": "Backup status",
            "backupTask": "Backup task",
            "backupTime": "Backup time",
            "backuping": "Backuping",
            "backupFailed": "Backup Failed",
            "backupProgress": "Backup Progress"
        },
        "warning": {
            "title": "Alarm Information",
            "path": "Path",
            "alarmType": "Alarm type",
            "alarmClass": "Alarm class",
            "alarmLevel": "Alarm level",
            "alarmTime": 'Alarm Time',
            "alarmReason": 'Alarm Reason',
            "alarmClassify": "Alarm classify",
            "allAlarm": "All alarm",
            "unmute": "Unmute",
            "muteAll": "Mute all",
            "devicePath": "Device path",
            "real": "Real",
            "empty": "Empty",
            "changeUnmute": "Switch mute",
            "alarmDynamics": "Alarm Dynamics",
            "graphDisplay": "Graph display",
            "alarmDetail": "Alarm detail",
            "amplitudeAlarmType": "Amplitude alarm type",
            "viewDetail": "View details",
            "confirmInfo": "Confirm information",
            "severity": "Severity",
            "confirmDetail": "Confirm detail",
            "confirmTip": 'Failed to acknowledge an unfinished alarm',
            "searchDeviceTip": 'The device could not be found'
        },
        "systemEvent": {
            "eventType": "Event type",
            "eventLevel": "Event level",
            "eventContent": "Event content",
            "title": "System Event",
            "time": "Time"
        },
        "systemStatus": {
            "title": "System Status",
            "batteryTitle": 'Battery Status',
            "instrumentName": 'Instrument Name',
            "instrumentCode": 'Instrument Code',
            "sampleCard": 'Capture card',
            "battery": 'Battery Level',
            "full": 'Enough power',
            "low": 'Low power, replace the battery！',
        }
    },
    "login": {
        "welcome": "Welcome to login",
        "sign": "Sign",
        "account": "Account",
        "password": "Password",
        "remeberPwd": "Remember password for one month",
        "accountPlaceHolder": "Please enter an account",
        "lightTheme": "Light theme",
        "darkTheme": "Dark theme",
        "unauthorized": "Software unauthorized",
        "tips": "Tip",
        "unauthorizedTip": "The user does not have permission,please contact the administrator to obtain permissions",
        "systemName": "Based on fault diagnosis and health management\nintelligent device maintenance management platform",
        "stockCode": "Stock code"
    },
    "errorPage": {
        "noPermission": "No permission temporarily",
        "noPermissionTip": "You do not have permission to access this page,you can click the button below to return to the homepage or previous page",
        "goHome": "Go back to the homepage",
        "goBack": "Return to previous page",
        "noPage": "Page loss",
        "noPageTip": "The page you visited does not exist,you can click the button below to return to the homepage or previous page"
    },
    "license": {
        "authDocument": "Authorization documents",
        "importAuth": "Import authorization",
        "activation": "Activation",
        "authInformation": "Authorization information",
        "authStatus": "Authorization status",
        "authVersion": "Authorization version",
        "authTime": "Authorization time",
        "surplus": "Surplus",
        "day": "Day",
        "numUnit": "Piece",
        "infinite": "Infinite",
        "unlimited": "Unlimited",
        "onlinePointNum": "Online measurement points",
        "inspectionPointNum": "Number of inspection points",
        "funList": "Function list",
        "copy": "Copy",
        "activationCode": "Activation code",
        "registrationCode": "Registration code",
        "activated": "Activated",
        "notActive": "Not active",
        "timeRunOut": "Time has run out",
        "dongleCheckFailed": 'Failed to activate',
        "testCheckFailed": 'Failed to activate',
        "registrationCodeToolTip": "Please enter the registration code",
        "activationCodeToolTip": "Please enter the activation code",
        "activateSuccess": "Activation successful",
        "checkFileToolTip": "Please check if the imported file meets the requirements"
    },
    "fileLibrary": {
        "addClassification": "Add classification",
        "editClassification": "Edit classification",
        "deleteClassification": "Delete classification",
        "sort": "Sort",
        "fileName": "File name",
        "fileSize": "File size",
        "lastModificationTime": "Last modification time",
        "obtainingFiles": "Obtaining files...",
        "classificationToolTip": "Please enter a classification",
        "specialCharacterToolTip": "The name cannot contain special characters",
        "classSpecialCharacterToolTip": "The name cannot contain special characters",
        "emptyClassToolTip": "The name cannot be empty",
        "moreThanToolTip": "The category cannot exceed 20 characters",
        "confirmDeleteToolTip": "Please confirm whether to delete the category and its files?",
        "deleteToolTip": "Files cannot be restored after deletion, please confirm whether to delete it?",
        "deleteFile": "Delete file",
        "editFile": "Edit file",
        "selectClassification": "Select classification",
        "sortPlaceholder": "Please enter the serial number",
        "descriptionPlaceholder": "Please enter descriptive information",
        "classificationPlaceholder": "Please select a classification",
        "noChangeToolTip": "No changes have been made yet",
        "emptyFileNameToolTip": "The file name cannot be empty",
        "fileNamePlaceHolder": "Please enter a file name",
        "fileSelectToolTip": "Please select a file",
        "selectFile": "Select file",
        "uploadFile": "Upload file",
        "directory": 'Directory',
        "fileType": 'File type',
        "directoryBelong": 'Directory belong',
        "document": 'Document',
        "picture": 'Picture',
        "audio": 'Audio',
        "other": 'Other',
        "deleteDirectoryTip": 'Deleting this directory deletes both its subdirectories and the files in the directory, so check whether to delete them?',
        "removeDirectory": 'Remove directory',
        "addDirectory": 'Add directory',
        "editDirectory": 'Edit directory',
        "directorySelectTip": 'If not selected, it defaults to the top-level directory',
        "directoryName": 'Directory name',
        "directoryNamePlaceHolder": 'Please enter the directory name',
        "exeTip": 'Cannot upload exe file',
        "nameTip": `Filename can not contain \\ \/ : * ? " ' < > |`
    },
    "faultSetting": {
        "addFault": "Add",
        "editFault": "Edit",
        "deleteFault": "Delete",
        "faultContent": "Content",
        "sortFault": "Sort",
        "addText": "Add text",
        "modelSetting": "Model setting",
        "disableAlarm": "Disable alarm",
        "enableAlarm": "Enable alarm",
        "historyData": "History",
        "realTimeData": "Realtime",
        "exportData": "Export",
        "refresh": 'Refresh',
        "saveModel": "Save",
        "noFaultModel": "Temporarily No Fault Model",
        "faultName": "Fault name",
        "faultModel": "Fault model",
        "specialCharacterToolTip": "The fault name cannot contain special characters",
        "healthToolTip": "Health must be a number of 0-100",
        "moreThanToolTip": "The fault name cannot exceed 20 characters",
        "enterFaultPlaceholder": "Please enter the fault name",
        "deleteFaultToolTip": "Please confirm whether to delete the fault?",
        "models": "Models",
        "horizontal": "Horizontal",
        "vertically": "Vertically",
        "nameEmptyToolTip": "The name cannot be empty",
        "nameMoreThanToolTip": "The name cannot exceed 20 characters",
        "deleteConfirm": "Are you sure you want to delete",
        "topping": "Topping",
        "bottoming": "Bottoming",
        "moveUp": "MoveUp",
        "moveDown": "MoveDown",
        "formula": "Formula",
        "faultErrorMsg": 'Modification is prohibited during fault activation, so please disable it if you need to modify it',

    },
    "depState": {
        "title": "Status Display"
    },
    "dataProcessUnit": {
        "models": "Models",
        "graphType": "Graph type",
        "outputPoint": "Output point",
        "expand": "Expand",
        "collspan": "Collspan"
    },
    "mainLayout": {
        "aside": {
            "sort": "Sort",
            "unitSort": "Sort unit",
            "topping": "Topping",
            "bottoming": "Bottoming",
            "moveUp": "MoveUp",
            "moveDown": "MoveDown",
            "deleteToolTip": "Deleting the subdepartments, devices, and scenes in the directory will be deleted at the same time"
        },
        "header": {
            "alarmInfo": "Alarm information",
            "systemEvent": "System event",
            "systemTask": "System task",
            "platformSetting": "Platform setting",
            "changePwd": "Change password",
            "signOut": "Sign out",
            "systemName": "Based on fault diagnosis and health management intelligent device maintenance management platform",
            "alarmUpdateTip": "The alarm content has been updated, please review?",
            "loadTheme": "Loading theme",
            "hello": "Hello",
            "systemStatus": "System status",
            "showStatus": 'Show',
            "editStatus": 'Edit'
        },
        "mainContent": {
            "analysisReport": "Analysis report",
            "otherReport": "Other report",
            "importFualtLib": "Import fault library",
            "manufacturer": "Manufacturer",
            "equipmentModel": "Equipment model",
            "equipmentType": "Equipment type",
            "faultDescription": "Fault description",
            "noPermission": "No permission temporarily",
            "noPermissionTip": "You do not have permission to access the node,please contact the administrator",
            "summary": "Summary",
            "statusDisplay": "Status Display",
            "fileLib": "Knowledge Base",
            "pointSetting": "Point Settings",
            "pointData": "Point Data",
            "workStatus": "Work Status",
            "spotInspection": "Spot Inspection",
            "addUnit": "Add unit",
            "addDepart": "Add depart",
            "addDevice": "Add device",
            "addScence": "Add scence",
            "importLibrary": "Fault library import",
            "saveFaultLib": "Save fault library",
            "exportReport": "Export report",
            "syncSuccess": "Sync successful",
            "chooseFaultLibToolTip": "Please select the fault library to import",
            "syncFaultLibToolTip": "Do you want to synchronize this device to the fault library?"
        },
        "report": {
            "exportReport": "Export report",
            "baseInfo": "Base information",
            "devicePoint": "Device point",
            "reportName": "Report name",
            "deviceList": "Device list",
            "pointList": "Point list",
            "previousStep": "Previous step",
            "nextStep": "Next step",
            "monitorReport": "Monitoring data analysis report",
            "timeDomain": "Time-domain graph",
            "fftTitle": "Frequency domain graph(FFT transformation)：",
            "FailedToSaveMap": "Failed to save graph",
            "reportTemplate": 'Report template',
            "nameTip": 'The report name cannot be empty',
            "vibration": 'Vibration trend graph',
            "temperature": "Temperature trend graph",
            "template1": 'Device data analysis report',
            "template2": 'Device status analysis report',
            "template3": 'Device status monitoring report',
            "template4": 'Device alarm status monitoring report',
        }
    },
    "patrol": {
        "plan": {
            "title": "Inspection Plan",
            "inspectionCycle": "Inspection cycle",
            "inspectionCycleSelect": "Please select the inspection cycle",
            "noCycle": "No cycle",
            "holiday": "Holiday",
            "enableStatus": "Enable status",
            "planName": "Plan name",
            "planStartTime": "Plan startTime",
            "executor": "Executor",
            "enableOrNot": "Enable or not",
            "cycleToolTip": "Please enter the cycle interval",
            "cycleIntgerToolTip": "Cycle interval must be an integer",
            "cycleRangeToolTip": "The cycle interval must be greater than zero",
            "lessThan": "Less than",
            "enterCorrect": "Please enter the correct",
            "sampleTimeToolTip": "Please select the collection time",
            "intgerToolTip": "Must be an integer",
            "nameMoreThanToolTip": "The first name cannot exceed 50 characters",
            "descriptionMoreThanToolTip": "Confirmation details cannot exceed 1000 characters",
            "planStartTimeToolTip": "Please select the start time of the plan",
            "executorToolTip": "Please select the executor",
            "sampleTime": "Sample time",
            "earlyCollection": "Early collection",
            "delayedRecycling": "Delayed recycling",
            "deletePlanToolTip": "Are you sure you want to delete this plan?",
            "checkBaseInfo": "Please check if the basic information is filled in correctly",
            "checkTimeSetting": "Please check if the time setting is filled in correctly",
            "inspectionPointToolTip": "Please select a patrol inspection point",
            "executorSameToolTip": "The starting point of the executor cannot be the same",
            "cycle": "Cycle",
            "mark": "Mark",
            "markPlaceHolder": "Please enter a mark",
            "periodicMode": "Periodic mode",
            "onceADay": "Once a day",
            "onceAWeek": "Once a week",
            "onceAMonth": "Once a month",
            "allowEarlyCollection": "Allow early collection",
            "allowDelayedRecycling": "Allow delayed recycling",
            "cycleInterval": "Cycle interval",
            "collectionTimeSelection": "Collection time selection",
            "inspectionPoint": "Inspection point",
            "pointName": "Point name",
            "pointType": "Point type",
            "up": "Up",
            "down": "Down",
            "positiveNumber": "Please enter a positive number",
            "addExecutor": "Add executor",
            "executorSelected": "Executor has been selected",
            "addPlan": "Add inspection plan",
            "viewPlan": "View inspection plan"
        },
        "task": {
            "inspectionTask": "Inspection task",
            "inspectionStatus": "Inspection status",
            "inspectionPlan": "Inspection plan",
            "pathName": "Path name",
            "affiliatedPlan": "Affiliated plan",
            "executor": "Executor",
            "unrecycled": "Unrecycled",
            "warningNums": "Warning number",
            "dangerNums": "Danger number",
            "status": "Status",
            "unrecycledNum": "Unrecycled number",
            "pointData": "Point data",
            "inspectionData": "Inspection data",
            "alarmLevel": "Alarm level",
            "unit": "Unit",
            "inspectionTime": "Time",
            "enclosure": "Enclosure",
            "no": "None",
            "errorTip": 'If there is any abnormality, please describe it',
            "h5Tip": "Your browser does not support HTML5 video tag。",
            "noEnclosureTip": "There are currently no attachments uploaded",
            "recordInfo": "Record information",
            "noRecordToolTip": "There is currently no recorded information",
            "preview": "Preview",
            "unexpired": "Unexpired",
            "underExecution": "Under execution",
            "recyclingCompleted": "Recycling completed",
            "notCollected": "Not collected",
            "partialRecycling": "Partial recycling",
            "expiredNotCollected": "Expired not collected",
            "partialTimeoutRecycling": "Partial timeout recycling",
            "expiredRecyclingCompleted": "Expired recycling completed",
            "observationQuantity": "Observation",
            "measurementQuantity": "Measurement",
            "handCopyingQuantity": "HandCopying",
            "notFilledIn": "Not filled in",
            "viewDetail": "View",
            "exportReport": "Export",
            "recordSheetTitle": 'Construction Daily Report Record Sheet',
            "examinationTitle": 'Examination records of key areas',
            "patrolPath": 'Patrol path',
            "patrolContent": 'Patrol content',
            "isNormal": 'Is normal',
            "patrolPhotos": 'Patrol photos',
            "uploadPhotos": 'Upload photos',
            "exceedTip": 'A maximum of 9 images can be uploaded at one measurement point',
            "repeat": 'repeat'
        }
    },
    "hooks": {
        "internalDeleteTip": "The selected item contains system built-in features, unable to delete",
        "cantDeleteTip": "Cannot delete system built-in items"
    },
    "core": {
        "noData": "No data",
        "order": "Order",
        "overLimit": "Over limit",
        "belowLimit": "Below limit",
        "inWindow": "In window",
        "outWindow": "Out window",
        "curveInfo": "Curve information",
        "doubleCursor": "Double cursor",
        "flagCursor": "Flag cursor",
        "threeCursor": "Three cursor",
        "fourCursor": "Four cursor",
        "navigateCursor": "Navigate cursor",
        "navigateTrendDoubleCursor": "NavigateTrendDouble cursor",
        "peakValueCursor": "PeakValue cursor",
        "valleyValueCursor": "ValleyValue cursor",
        "rectCursor": "Rect cursor",
        "sideFrequencyCursor": "SideFrequency cursor",
        "harmonicCursor": 'Harmonic cursor',
        "frequencyMultiplicationCursor": 'Harmonic(Frequency multiplication) cursor',
        "resonanceCursor": 'Resonance cursor',
        "torvibSpeed": 'Torsional resonance speed',
        "torvibFreq": 'Torsional resonance frequency',
        "sideFrequencyCursorNum": 'SideFrequency cursor count',
        "harmonicCursorNum": 'Harmonic cursor count',
        "harmonicDistortionDegree": 'Harmonic distortion degree',
        "singleCursor": "Single cursor",
        "amplitude": "Amplitude",
        "phase": "Phase",
        "rotateSpeed": "Rotate speed",
        "max": "Max",
        "min": "Min",
        "avage": "Avage",
        "RMS": "Root mean square",
        "skewness": "Skewness",
        "kurtosis": "Kurtosis",
        "SD": "Standard deviation",
        "PTP": "Peak-to-peak",
        "slope": "Slope",
        "deviation": "Deviation",
        "APTP": "Adjacent peak-to-peak",
        "peakList": "Peak list",
        "signalDispose": "Signal dispose",
        "secondOrderClustering": "Second order clustering",
        "KMeanClustering": "K-mean clustering",
        "systemClustering": "System clustering",
        "decisionTree": "Decision tree",
        "discriminant": "Discriminant",
        "RocCurve": "Roc curve",
        "RocAnalysis": "Roc analysis",
        "bivariate": "Bivariate",
        "partialCorrelation": "Partial correlation",
        "distance": "Distance",
        "multilayerPerceptron": "Multilayer perceptron",
        "radialBasisFunction": "Radial basis function",
        "factor": "Factor",
        "correspondenceAnalysis": "Correspondence analysis",
        "lifeTable": "Life table",
        "CoxRegression": "Cox regression",
        "sequenceDiagram": "Sequence diagram",
        "autocorrelation": "Autocorrelation",
        "crossCorrelation": "Cross correlation"
    },
    "pointData": {
        "index": {
            "realTime": "Real Time",
            "history": "History",
            "horizontally": "Horizontally",
            "vertically": "Vertically",
            "models": "Model Base",
            "timeDomainAnalysis": "Time Domain",
            "ampAnalysis": "Amplitude",
            "freqAnalysis": "Frequency Domain",
            "orderAnalysis": "Order",
            "envelopeAnalysis": "Envelope",
            "acousticAnalysis": "Acoustic",
            "modeAnalysis": "Modal",
            "engineeringApplication": "Engineering",
            "refreshLayout": "Refresh",
            "clearLayout": "Clear",
            "saveLayout": "Save",
            "faultLibrary": "Fault Library",
            "fullScreen": "Fullscreen",
            "addBillboards": "Add Dashboard",
            "removeBillboard": "Remove Dashboard",
            "lock": "Lock",
            "unlock": "Unlock",
            "lockTip": "The current display panel is locked, please unlock it first",
            "billboardName": "Billboard name",
            "nameLimitTip": "The length of the billboard name cannot exceed 10 characters",
            "nameRepeatTip": "The length of the billboard name cannot be repeated",
            "bearingCaculateTip": "Please calculate the bearing failure factor",
            "checkFreqTip": "Please select the fault characteristic frequency"
        },
        "menu": {
            "trendChart": "Trend Chart",
            "waveChart": "Wave Chart",
            "spectrumChart": "Spectrum Chart",
            "barChart": "Bar Chart",
            "digitalMeter": "Digital Meter",
            "dashboard": "Dashboard",
            "picture": "Picture"
        }
    },
    "workState": {
        "historyData": "History Data",
        "realTimeData": "Real Time Data",
        "exportData": "Export Data",
        "noWorkingStatus": "No Working Status At Present",
        "faultName": "Fault Name",
        "exporting": "Exporting"
    },
    "summary": {
        "openFile": "Open file",
        "theme": "Theme",
        "component": "Component",
        "alarm": "Alarm",
        "statistics": "Statistics",
        "addContent": "Add content",
        "show": "Show",
        "unitName": "Unit name",
        "departName": "Depart name",
        "deviceName": "Device name",
        "sceneName": "Scene name",
        "unitInfo": "Unit information",
        "departInfo": "Depart information",
        "deviceInfo": "Device information",
        "sceneInfo": "Scene information",
        "nameTip": "The first name cannot exceed 20 characters",
        "copyContent": "Copy content",
        "pasteContent": "Paste content",
        "pasteAlert": "Content already exists, Confirm to overwrite it?",
        "browseItem": {
            "picture": "Picture",
            "threeDModel": "3D Model",
            "map": "Map",
            "video": "Video",
            "numericalStatistics": "Numerical Statistics",
            "lineChart": "Line Chart",
            "pieChart": "Pie Chart",
            "histogram": "Histogram",
            "table": 'Table',
        },
        "browseList": {
            "component": "Component"
        }
    },
    "pointSetting": {
        ratedSpeed: 'Rate speed',
        rateSpeedTip: 'Please enter the rate speed',
        "bindMachineAndChannelFirst": "Please bind the machine number and channel first",
        "timeDomainAnalysis": "Time domain analysis",
        "custom": "Custom",
        "orderAnalysis": "Order analysis",
        "frequencyAnalysis": "Frequency Analysis",
        "speedFrequency": "Speed frequency",
        "enterSpeedFrequency": "Please enter the speed frequency",
        "powerFrequency": "Power frequency",
        "enterPowerFrequency": "Please input the power frequency",
        "abnormalFrequency": "Abnormal frequency",
        "enterabnormalFrequency": "Please enter the abnormal frequency",
        "startFreVibInt": "Starting frequency of vibration intensity",
        "enterStartFreVibInt": "Please input the starting frequency of vibration intensity",
        "vibIntCutFre": "Vibration intensity cutoff frequency",
        "enterVibIntCutFre": "Please input the vibration intensity cutoff frequency",
        "vibIntUnit": "Vibration intensity unit",
        "enterVibIntUnit": "Please enter the vibration intensity unit",
        "conCoeOfVibIntUnit": "Conversion coefficient of vibration intensity unit",
        "enterConCoeOfVibIntUnit": "Please enter the vibration intensity unit conversion coefficient",
        "coiPasFrequency": "Coil passing frequency",
        "enterCoiPasFrequency": "Please input the coil passing frequency",
        "speedUppLimFrequency": "Speed upper limit frequency",
        "enterSpeedUppLimFrequency": "Please enter the upper limit frequency of the speed",
        "lowerLimFreOfSpeed": "Lower limit frequency of speed",
        "enterLowerLimFreOfSpeed": "Please enter the lower limit frequency of the rotational speed",
        "envelopeAnalysis": "Envelope analysis",
        "envelopeFreBandRange": "Envelope frequency band range",
        "selectEnvelopeFreBandRange": "Please select the envelope frequency band range",
        "slowVariableAnalysis": "Slow variable analysis",
        "waveformAnalysis": "Waveform analysis",
        "isSampleWave": "Whether to collect waveform",
        "instrumentIsCurSampling": "The instrument is currently sampling",
        "thAreCurNoMeaPoiAvailable": "There are currently no measurement points available",
        "meaPointName": "Measurement point name",
        "enterMeaPointName": "Please enter the name of the measuring point",
        "associationChannel": "Association Channel",
        "signalType": "Signal Type",
        "engineeringUnit": "Engineering Unit",
        "application": "Application",
        "delete": "Delete",
        "noAccessViewPointForTheMoment": "I currently do not have permission to view measurement points",
        "youNotHavePermissionEdit": "You do not have permission to edit",
        "theMeaPoiNamCanContain": "The measurement point name cannot contain \\",
        "dupMeaPoiName": "Duplicate measurement point name",
        "noUnit": "No Unit",
        "confirmChaBindChannel": "Please confirm to change bind channel",
        "chaBindChannel": "Change bind channel",
        "confirmUnbindChannel": "Please confirm to unbind channel",
        "unbindChannel": "Unbind Channel",
        "newMeaPoints": "Add point",
        "bindingChannel": "Binding Channel",
        "pleaseWait": "Please Wait",
        "unbindingChannel": "Unbinding Channel",
        "deletingChannel": "Deleting Channel",
        "selectPointsToDelete": "Please select points to delete",
        "confirmWhetherToDelete": "Please confirm whether to delete it",
        "deletePoints": "Delete point",
        "selectPoint": "Please select a measuring point",
        "batchSetSigType": "Batch set signal type",
        "creatingPoint": "Creating point",
        "batchSetEngineeringUnit": "Batch set engineering unit, please wait",
        "thereAreabsm": "There are also bit-save modifications, whether to save",
        "isSave": "Is save",
        "yes": "Yes",
        "no": "No",
        "customFeatureValues": "Custom feature values",
        "unitSetting": "Unit Setting",
        "optionName": "Option Name",
        "enterOptionName": "Please enter an option name",
        "optionFrequency": "Option frequency",
        "enterOptionFrequency": "Please enter the option frequency",
        "featureValNamCannotDuplicate": "The feature value name cannot be duplicate",
        "pointInformation": "Measurement point information",
        "samplingSettings": "Sampling Settings",
        "realTimeAnalysis": "Real-time Analysis",
        "saveSetting": "Save setting",
        "selectPointObtainContent": "Please select a measuring point to obtain the content",
        "measurementType": "Measurement type",
        "enterMeasurementType": "Please select a measurement type",
        "selectEngineeringUnit": "Please select an engineering unit",
        "fullScaleValue": "Full scale value",
        "enterFullScaleValue": "Please enter a full scale value",
        "samplingFrequency": "Sampling frequency",
        "selectSamplingFrequency": "Please select a sampling frequency",
        "bearingModel": "Bearing model",
        "enterBearingModel": "Please enter the bearing model",
        "professionalType": "Professional type",
        "selectProfessionalType": "Please select a major type",
        "startStopAssociation": "Start stop association",
        "selectStartStopAssociation": "Please select start stop association",
        "observationType": "Observation type",
        "selectObservationType": "Please select the observation type",
        "pointGroup": "Measurement point group",
        "enterPointGroup": "Please enter the measurement point group",
        "PointDetails": "Measurement point details",
        "selectTypeEngUnit": "Please select the type of engineering unit",
        "enterRoleName": "Please enter a role name",
        "online": "On-line",
        "inspection": "Inspection",
        "addPoints": "Add measuring points",
        "noInstrumentsAvailable": "There are currently no instruments available",
        "selectAction": "Select the action you want to perform",
        "channelOperations": "Channel operations",
        "unbind": "Unbind",
        "selectSamplingMethod": "Please select a sampling method",
        "numSamPoiEntCycle": "Number of sampling points for the entire cycle",
        "selectNumSamPoiEntCycle": "Please select the number of sampling points for the entire cycle",
        "numSamplingCycles": "Number of sampling cycles",
        "selectNumSamplingCycles": "Number of sampling cycles",
        "samplingTime": "Sampling time",
        "sensorType": "Sensor type",
        "selectSensorType": "Please select a sensor type",
        "sensorUnit": "Sensor unit",
        "selectSensorUnit": "Please select sensor unit",
        "sensorNumber": "Sensor number",
        "associatedSpeedPoints": "Associated speed measurement points",
        "selectAssociatedSpeedPoints": "Please select the associated speed measurement point",
        "inputMethod": "Input method",
        "selectInputMethod": "Please select the input method",
        "measurementRange": "Measurement range",
        "selectMeasurementRange": "Please select the measurement range",
        "speedRatio": "Speed ratio",
        "enterSpeedRatio": "Please enter the speed ratio",
        "speedPuPerSecond": "Speed pulses per second",
        "enterSpeedPuPerSecond": "Please enter the number of pulses per second at the rotational speed",
        "resistanceValue": "Resistance value",
        "enterResistanceValue": "Please enter the resistance value",
        "hardwarePoiType": "Hardware points type",
        "selectHardwarePoiType": "Please select a hardware point type",
        "hardwareSamInterval": "Hardware sampling interval",
        "enterHardwareSamInterval": "Please enter the hardware sampling interval",
        "compensationChannel": "Compensation channel",
        "selectCompensationChannel": "Please select a compensation channel",
        "thermocoupleType": "Thermocouple type",
        "selecThermocoupleType": "Please select thermocouple type",
        "coldEndTemperature": "Cold end temperature",
        "enterColdEndTemperature": "Please input the cold end temperature",
        "contactType": "Contact type",
        "selectContactType": "Please select the contact type",
        "valueType": "Value type",
        "selectValueType": "Please select a numerical type",
        "typeOfOutput": "Type of output",
        "selectTypeOfOutput": "Please select an output type",
        "signalParameters": "Signal parameters",
        "selectSignalParameters": "Please select the signal parameter type",
        "sensorSensitivity": "Sensor sensitivity",
        "selectSensorSensitivity": "Please select sensor sensitivity",
        "waveformOptions": "Waveform Options",
        "selectWaveformOptions": "Please select waveform option",
        "platinumResistanceType": "Platinum resistance type",
        "selectPlatinumResistanceType": "Please select the type of platinum resistor",
        "plaResWirMethod": "Platinum resistor wiring method",
        "selectWirMethod": "Please select the wiring method",
        "plaResWirResistance": "Platinum resistance wire resistance",
        "enterPlaResWirResistance": "Please input the platinum resistance wire resistance",
        "senCoeTorStrGauge": "Sensitivity coefficient of torque strain gauge",
        "enterTheCoefficient": "Please enter the coefficient",
        "upperLimTorRange": "Upper limit of torque range",
        "enterUpperLimTorRange": "Please enter the upper limit of torque range",
        "lowerLimTorRange": "Lower limit of torque range",
        "enterLowerLimTorRange": "Please enter the lower limit of torque range",
        "linearCoeTorque": "Linear coefficient of torque",
        "enterLinearCoeTorque": "Please enter the torque linearity coefficient",
        "typeTorVibMeaSensor": "Type of torsional vibration measurement sensor",
        "selectTypeTorVibMeaSensor": "Please select the type of torsional vibration measurement sensor",
        "pluseSensorVol": "Supply voltage",
        "selectPluseSensorVol": "Please select the power supply voltage",
        "inputSignal": "Input signal",
        "selectInputSignal": "Please select an input signal",
        "speedPulPerRevolution": "Speed pulses per revolution",
        "enterSpeedPulPerRevolution": "Please input the number of pulses per revolution",
        "selectBridgePressure": "Please select bridge pressure",
        "sensorSamplingFrequency": "Sensor sampling frequency",
        "baudRate": "Baud rate",
        "selectBaudRate": "Please select the baud rate",
        "parityBit": "Parity bit",
        "selectParityBit": "Please select the verification type",
        "stopBit": "Stop bit",
        "selectStopBit": "Please select the number of stop digits",
        "installationAngle": "Installation angle",
        "selectInstallationAngle": "Please select the installation angle",
        "associatedPoints": "Associated measuring points",
        "selectAssociatedPoints": "Please select the associated measurement point",
        "initialGapVoltage": "Initial gap voltage",
        "enterInitialGapVoltage": "Please input the initial gap voltage",
        "elasticModulus": "Elastic modulus",
        "enterElasticModulus": "Please enter the elastic modulus",
        "incentivePeriod": "Incentive period",
        "enterIncentivePeriod": "Please enter the incentive period",
        "coefficient": "Coefficient",
        "initialFrequencyValue": "Initial frequency value",
        "enterInitialFrequencyValue": "Please enter the initial frequency value",
        "iniTemValue": "Initial temperature value",
        "enterIniTemValue": "Please enter the initial temperature value",
        "linExpCoeOfSteString": "Linear expansion coefficient of steel string",
        "enterLinExpCoeOfSteString": "Please enter the linear expansion coefficient of the steel string",
        "linExpCoeOfStrBody": "Linear expansion coefficient of structural body",
        "enterLinExpCoeOfStrBody": "Please enter the linear expansion coefficient of the structure",
        "ifTemCompensation": "Temperature compensation or not",
        "temperatureCompensation": "Temperature compensation",
        "value": "Value",
        "enterTemCompensation": "Please enter temperature compensation",
        "strGauResValue": "Strain gauge resistance value",
        "enterStrGauResValue": "Please input the resistance value of the strain gauge",
        "intPreResistor": "Internal precision resistor",
        "selectIntPreResistor": "Please select an internal precision resistor",
        "wireResistance": "Wire resistance",
        "enterWireResistance": "Please input the wire resistance",
        "senCoeOfStrGauge": "Sensitivity coefficient of strain gauge",
        "enterSenCoeOfStrGauge": "Please enter the sensitivity coefficient of strain gauge",
        "poissonRatio": "Poisson's ratio",
        "enterPoissonRatio": "Please enter Poisson's ratio",
        "appMeaPoint": "Application measurement point",
        "deactivate": "Deactivate",
        "threshold": "Threshold",
        "enterThreshold": "Please enter a threshold",
        "calibrationCoefficient": "Calibration coefficient",
        "sensitivityCoefficient": "Sensitivity coefficient",
        "function": "Function",
        "twoPoints": "Two points",
        "physicalQuantity": "Physical quantity",
        "electricalQuantity": "Electrical quantity",
        "proportionalCoefficient": "Proportional coefficient",
        "enterProportionalCoefficient": "Please enter the scale factor",
        "zeroCorrection": "Zero correction",
        "enterZeroCorrection": "Please enter zero correction",
        "numCalibrationPoints": "Number of calibration points",
        "formulaResults": "Formula results",
        "calculationError": "Calculation error",
        "checkTheParameters": "Please check the parameters",
        "enterSenCoefficient": "Please enter sensitivity coefficient",
        "enteraNumber": "Please enter a number",
        "firstCalibrationPoint": "First calibration point",
        "secondCalibrationPoint": "Second calibration point",
        "senCoeCannotBe": "Sensitivity coefficient cannot be 0",
        "fullScaValMustBeGreThan": "The full scale value must be greater than 0",
        "ElasticModulusMoreThan0": "The elastic modulus value must be greater than 0",
        "twoPhyAndEleQuaMusBeNum": "Two physical and electrical quantities must be numbers",
        "storeWaveForms": "Store Datas",
        "storageMode": "Storage mode",
        "continuousStorage": "Continuous storage",
        "scheduledStorage": "Scheduled storage",
        "intervalStorage": "Interval Storage",
        "triggerStorage": "Trigger storage",
        "storageDays": "Storage Days",
        "storageDaysPlaceholder": "Please input storage days",
        "startingConditionType": "Starting condition type",
        "singleTrigger": "Single trigger",
        "multipleTriggers": "Multiple triggers",
        "cycleTrigger": "Cycle trigger",
        "startTime": "Start time",
        "datePickerPlaceholder": "Please select a time",
        "storageCycle": "Storage cycle",
        "storageCyclePlaceholder": "Please enter the storage cycle",
        "storageTime": "Storage Time",
        "storageTimes": "Storage Times",
        "times": "Times",
        "absoluteTime": "Absolute time",
        "relativeTime": "Relative time",
        "stopTime": "Stop time",
        "stopTimePlaceholder": "Please enter the stop time",
        "triggerConditionType": "Trigger condition type",
        "triggerCount": "Trigger count",
        "triggerCountPlaceholder": "0 Represents unlimited number of times",
        "triggerInterval": "Trigger interval",
        "delayMethod": "Delay method",
        "delayPoints": "Delay points",
        "block": "Block",
        "illustration": "Illustration",
        "noDelay": "No delay",
        "positiveDelay": "Positive delay",
        "negativeDelay": "Negative delay",
        "startingConditions": "Starting conditions",
        "stopConditions": "Stop time type",
        "second": "Second",
        "minute": "Minute",
        "hour": "Hour",
        "day": "Day",
        "triggerPointName": "Trigger measuring point",
        "triggerType": "Trigger method",
        "triggerThreshold": "Trigger threshold",
        "triggerBlocks": "Number of persistent blocks",
        "pointParamPropertyOption": 'Additional signal characteristic value units',

        "hardwareSamplingFreq": 'Hardware sampling frequency',
        "hardwareSamplingFreqPlaceholder": 'Please enter hardware sampling frequency',
        "torvibEqualtimeThreshold": 'Equal time torsional vibration Angle threshold',
        "torvibEqualtimeThresholdPlaceholder": 'Please enter equal time torsional vibration Angle threshold',
        "tachoCounterSensorType": 'Tacho counter sensor type',
        "tachoCounterSensorTypePlaceholder": 'Please select tacho counter sensor type',
        "tachoCounterPulse": 'Pulses per second',
        "tachoCounterPulsePlaceholder": 'Please enter pulses per second',
        "tachoCounterSpeedRatio": 'Speed ratio',
        "tachoCounterSpeedRatioPlaceholder": 'Please enter speed ratio',
        "tachoCounterExchange": 'Direction switching',
        "tachoCounterExchangePlaceholder": 'Please select direction switching',
        "tachoCounterSignalMode": 'Signal pattern',
        "tachoCounterSignalModePlaceholder": 'Please select signal pattern',
        "tachoCounterXMode": 'Frequency doubling mode',
        "tachoCounterXModePlaceholder": 'Please select frequency doubling mode',
        "tachoCounterResetMode": 'Reset mode',
        "tachoCounterResetModePlaceholder": 'Please select reset mode',
        "tachoCounterMaxPos": 'Location counter threshold',
        "tachoCounterMaxPosPlaceholder": 'Please enter location counter threshold',
        "tachoCounterTimerLoad": 'Speed timing time(ms)',
        "tachoCounterTimerLoadPlaceholder": 'Please enter speed timing time',
        "tachoCounterKeyPhase": 'Key phase',
        "tachoCounterKeyPhasePlaceholder": 'Please select Key phase',
        "tachoCounterStartReset": 'Reset when starting measurement',
        "patrolClearance": 'Patrol Clearance',
        "loop": 'loop',
        "waveType": 'Wave type',
        "dataRefreshRate": "Data refresh rate"
    },
    "browse": {
        "rotationSpeed": "Rotation speed",
        "fast": "Fast",
        "slowSpeed": "Slow speed",
        "mediumSpeed": "Medium speed",
        "noRotation": "No rotation",
        "numberOfRotations": "Number of rotations",
        "pleaseEnterTheNumberOfStreamers": "Please enter the number of streamers",
        "maximumNumberOfEntries": "Maximum number of entries",
        "displayMethod": "Display method",
        "displayContent": "Display content",
        "carousel": "Carousel",
        "statisticalRange": "Statistical range",
        "byPath": "By Path",
        "byTable": "By Table",
        "showColumns": "Show Columns",
        "company": "Company",
        "department": "Department",
        "equipment": "Equipment",
        "scene": "Scene",
        "measuringPoint": "Measuring point",
        "fault": "Fault",
        "type": "Type",
        "grade": "Grade",
        "time": "Time",
        "pageFlippingTime": "Page flipping time",
        "second": "Second",
        "pleaseEnterThePageFlippingTime": "Please enter the page flipping time",
        "componentType": "Component Type",
        "measurementPointAlarm": "Measurement point alarm",
        "alarmEditInfo": "Disable Eidt Alarm Information",
        "equipmentAlarm": "Equipment alarm",
        "displayPath": "Display Path",
        "statisticalType": "Statistical type",
        "numberOfAlarms": "Number of alarms",
        "numberOfCollectors": "Number of collectors",
        "numberOfOnlineCollectors": "Number of online collectors",
        "numberOfOfflineCollectors": "Number of offline collectors",
        "dayAccruedTitle": "The number of rings of the construction progress of the day",
        "monthAccruedTitle": "The number of construction progress rings in the past month",
        "allAccruedTitle": "Cumulative number of construction progress rings",
        "statisticalList": "Statistical List",
        "parameterSettings": "Parameter settings",
        "appearanceSettings": "Appearance Settings",
        "componentTitle": "Component Title",
        "zoomSettings": "Zoom Settings",
        "selfAdaption": "Self-adaption",
        "manualSettings": "Manual settings",
        "contentFontSize": "Content font size",
        "numericFontSize": "Numeric font size",
        "fixedNumberOfColumns": "Fixed number of columns",
        "numberOfViewColumns": "Number of View Columns",
        "statisticalScope": "Statistical scope",
        "nearlyAnHour": "Nearly an hour",
        "lastDay": "Last day",
        "inThePastHalfMonth": "In the past half month",
        "lastMonth": "Last month",
        "inThePastThreeMonths": "In the past three months",
        "inThePastSixMonths": "In the past six months",
        "lastYear": "Last year",
        "inThePastThreeYears": "In the past three years",
        "numericalStatistics": "Numerical statistics",
        "componentName": "Component Name",
        "highAlarmThreshold": "Warning threshold",
        "highHighAlarmThreshold": "Critical threshold",
        "weightRatio": "Weight ratio",
        "sceneWeight": "Scene Weight",
        "faultWeight": "Fault weight",
        "theHighAlarmThresholdMustBeGreaterThanTheHighAlarmThreshold": "The high alarm threshold must be greater than the high alarm threshold",
        "theSumOfFaultWeightsIsNotEqualTo": "The sum of fault weights is not equal to",
        "theSumOfSceneWeightsIsNotEqualTo": "The sum of scene weights is not equal to",
        "alarmSignalStatistics": "Alarm signal statistics",
        "constructionStatistics": "Construction situation ring number statistics",
        "pictureLayoutL": "Picture Layout",
        "freeScaling": "Free scaling",
        "proportionalScaling": "Proportional scaling",
        "doNotScale": "Do not scale",
        "selectFile": "Select File",
        "dragAndDropFilesHereOr": "Drag and drop files here or",
        "clickToUpload": "Click to upload",
        "numberOfAxisSegments": "Number of axis segments",
        "faultFrequencyStatistics": "Fault frequency statistics",
        "patrolStatistics": "Statistics on the number of patrol alarms",
        "alarmLevelStatistics": "Alarm level statistics",
        "statisticsOfTheNumberOfOfflineMeasuringPointsAndEquipment": "Statistics of the number of offline measuring points and equipment",
        "modelName": "Model Name",
        "deviceSerial": "Device Serial",
        "encrypted": "Encrypted",
        "secretKey": "Secret Key",
        "yes": "Yes",
        "no": 'No',
        "axisSettings": " Axis Settings",
        "displayByTime": "Time",
        "displayByDateAndTime": "Date and Time",
        "displayOnTimeMinutesAndSeconds": "time:minutes:seconds",
        "displayInSeconds": "seconds",
        "axisDecimalPlaces": " Axis Decimal Places",
        "numberOfAxisGrids": " Number of Axis Grids",
        "cursorType": "Cursor Type",
        "showMode": "Show Mode",
        "showTimeLength": "Time Length",
        "statisticalDuration": "Statistical duration",
        "refreshInterval": "Refresh interval",
        "displayItems": "Display items",
        "curveInformation": "Curve information",
        "coordinatePoints": "Coordinate points",
        "cursorFollow": "Cursor Follow",
        "statisticalInformation": "Statistical information",
        "maximumValue": "Maximum value",
        "minimumValue": "Minimum value",
        "rootMeanSquare": "Root mean square",
        "averageValue": "Average value",
        "skewness": "Skewness",
        "kurtosis": "Kurtosis",
        "standardSeviation": "Standard deviation",
        "peakToPeak": "Peak to Peak",
        "absoluteTime": "Absolute time",
        "relativeTime": "Relative time",
        "noCursor": "No cursor",
        "singleCursor": "Single cursor",
        "doubleCursor": "Double cursors",
        "threeLightMarkers": "Triple cursors",
        "fourCursors": "Quadruple cursors",
        "navigationCursor": "Navigation cursor",
        "peakCursor": "Peak cursor",
        "valleyCursor": "Valley cursor",
        "areaCursor": "Area Cursor",
        "lately": "Lately",
        "hour": "Hour",
        "day": "Day",
        "month": "Month",
        "year": "Year",
        "videoList": "Video List",
        "addChannel": "Add Channel",
        "yhereAreCurrentlyNoVideosAvailable": "There are currently no videos available",
        "optionSettings": "Option settings",
        "cameraName": "Camera name",
        "passageway": "Passageway",
        "address": "Address",
        "userName": "User Name",
        "password": "Password",
        "pleaseEnterTheNameOfYourCamera": "Please enter the name of your camera",
        "formatReference": "Format reference",
        "videoType": "Vdeo Type",
        "video": "Video",
        "channelNumber": "Channel number",
        "serialNumber": "Serial number",
        "platformSelection": "Platform Selection",
        "videoResources": "Video resources",
        "jingjiangWaterConservancyBureau": "Jingjiang Water Conservancy Bureau",
        "lastHour": "Last hour",
        "camera": "Camera",
        "fontSize": "Font size",
        "RTSPAddress": "Channel RTSP Address",
        "RTSPTip": "Please enter the RTSP address of your camera, like rtsp://username:password{'@'}IP:554/h264/ch1/main/av_stream",
        "RTSPplaceHolder": "Rtsp://username:password{'@'}IP:554/h264/ch1/main/av_stream",
        "normalName": 'Healthy state name',
        "abnormalName": 'Exception status name',
        "playMode": 'Play mode',
        "pageFlipping": 'Page flipping',
        "byDay": 'By Day',
        "byWeek": 'By Week',
        "byMonth": 'By Month',
        "arrangement": 'Arrangement',
        "vertical": 'Vertical',
        "horizontal": 'Horizontal',
        "characteristicFrequencyCursor": "Characteristic frequency cursor",
        "characFreqSetting": "Characteristic frequency setting",
        "charactFreqOfFaults": "Characteristic frequency of faults",
        "searchBearing": 'Search bearing',
        "chooseBearing": 'Choose bearing',
        "pointByDevice": 'Point(by device)',
        "synchronous": 'synchronous',
        "sendInterval": 'The interval between channel status sending',
        "latestInterval": 'The interval of latest value',
        "sendIntervalTip": 'The channel status must be sent at a positive integer',
        "latestFrequencyTip": 'The latest value must be sent frequently as a positive integer',
        "times": 'times/s',
        "sendIntervalLimitTip": 'The maximum channel status transmission interval is 100000000',
        "latestFrequencyLimitTip": 'The latest value transmission interval is 10',
        "displayDepartmentType": 'Display department type',
        "onlineDeviceStatistics": "Online device statistics",
        "byDeviceLevel": "By device class",
        "byAlarmLevel": "By alarm level",
        "statisticsInterval": "Statistical interval",
        "cussorPointName": "The name of the cursor measurement point",
        "healthHistoryStatistics": "Historical health statistics",
        "correlateHealth": "Correlate health",
        "healthRepeat": "There can be no duplicates of associated health",
        "deviceLevelAlarmStatistics": "Statistics on the number of alarms at the device level",
        "displayDepartmentTypeStatics": 'Categorical statistics',
        "displayPath": 'Display path',
    },
    "alarmPro": {
        "startLearn": "Start Learn",
        "weightLimitParameters": "Weight limit parameters of support vectors",
        "gaussian": "Gaussian kernel tolerance",
        "sampleData": "Sample data selection",
        "samplePoint": "Sample point",
        "sampleInput": "Sample input",
        "learning": "Learning",
        "learnSuccess": "Successfully learned",
        "learnFailed": "Learn failed",
        "alarmParam": "Alarm parameters",
        "addParam": "Add param",
        "fromPoint": "From point",
        "custom": "Custom",
        "selectStopTime": "Please select the stop time",
        "changeMode": "Change mode",
        "batchChange": 'Batch change',
        "saveChange": 'Save change',
        "change": "Change",
        "unitNode": "Unit node",
        "departNode": "Depart node",
        "numberOfStrainFlowers": "Number of strain flowers",
        "batchNum": "The number of batch additions cannot exceed 20",
        "numberOfFilters": "Number of filters",
        "smooth": "Smooth",
        "repeatedly": "Repeatedly",
        "alarmStatusDetail": 'Alarm status detail',
        "repairScheme": 'Repair scheme',
        "reportTask": 'Report Task',
        "cycleExecutionTime": 'Cycle Execution Time',
        "reportTemplate": 'Report Template',
        "alarmRule": 'Alarm Rule',
        "alarmSound": 'Alarm Sound',
        "alarmControl": 'Alarm Control',
        "whetherToGroup": 'Whether To Group',
        "deviceReport": 'Device status detection report',
        "alarmReport": 'Alarm status monitoring report',
        "resolveSuggestions": 'Resolve suggestions',
        "relevancy": 'relevancy',
        "exportSwitch": 'Export switch'
    },
    "platformSetting": {
        "baseInfo": {
            "faultLibary": {
                "title": "Fault Library List",
                "deviceModel": "Device model",
                "deviceType": "Device type",
                "faultDetails": "Fault details",
                "faultLib": "Fault library"
            },
            "patrol": {
                "observerCategory": {
                    "observationName": "Observation name",
                    "addObservationType": "Add observation type",
                    "editObservationType": "Edit observation type",
                    "observationNamePlaceholder": "Please input the observation name",
                },
                "observerItem": {
                    "alarmLevel": "Alarm level",
                    "alarmLevelPlaceholder": "Please select the alarm level",
                    "categoryRequired": "Please select the category",
                    "observationsName": "Observations name",
                    "observationsNameTip": "Please enter the observations name",
                    "addObservation": "Add observation",
                    "editObservation": "Edit observation"
                },
                "taskRecord": {
                    "recordName": "Record name",
                    "default": "Default content",
                    "title": "Record Title",
                    "recordTitlePlaceholder": "Please input the record title",
                    "defaultInfo": "Default information",
                    "defaultInfoPlaceholder": "Please input the default information",
                    "addTaskRecord": "Add task record",
                    "editTaskRecord": "Edit task record"
                },
                "workingSchedule": {
                    "previousYear": "Previous year",
                    "previousMonth": "Previous month",
                    "today": "Today",
                    "nextMonth": "Next month",
                    "nextYear": "Next year",
                    "weekArrangement": "Week arrangment",
                    "holidaySetting": "Vacation settings",
                    "restAbbreviation": "Rest",
                    "workingAbbreviation": "Work",
                    "lieuDaysConflict": "At least one lieu day is conflict to the new week arrangment, please edit lieu days first",
                    "holidayName": "Vacation name",
                    "holidayDay": "Vacation day",
                    "holidayStart": "Vacation starts",
                    "holidayEnd": "Vacation ends",
                    "holidayDuration": "Duration",
                    "LieuDays": "Total days in lieu",
                    "selectWorkingDay": "Please select the working day(s) in the week",
                    "holidayRequired": "Please select the vacation range",
                    "needsHolidayDate": "The vacation day is not defined, please select it first",
                    "dateInHoliday": "The vacation range should contains the vacation day",
                    "dateInOtherHoliday": "At least one day in the range is also in another vacation",
                    "lieuDaysNotInHoliday": "The lieu days should not in any vacation",
                    "lieuDaysNotInWorkingDay": "The lieu days should only in rest days",
                    "dateRequired": "Please input the date",
                    "dateExistedInHoliday": "This date is already existed in another vacation",
                    "addHoliday": "Add holiday",
                    "editHoliday": "Edit holiday"
                }
            },
            "sinalSetting": {
                "unitName": "Unit name",
                "unitNamePlaceHolder": "Please input the unit name",
                "addUnit": "Add unit",
                "editUnit": "Edit unit",
                "conversionCoeffcientPlaceHolder": "Please input the conversion coeffcient",
                "reserveDecimalFractionTip": "Please input the length of decimal part",
                "isDefaultUnit": "Is it default unit",
                "addSensorType": "Add sensor type",
                "editSensorType": "Edit a sensor type",
                "sensorName": "Sensor name",
                "sensorNameTooltip": "Please input the sensor name",
                "defaultEigen": "Default eigen",
                "defaultEigenPlaceholder": "Please select the default eigen",
                "signalName": "Signal name",
                "singalNamePlaceholder": "Please input the signal name",
                "defaultEigenType": "Default eigen type",
                "defaultEigenTypePlaceholder": "Please select the default eigen type",
                "addSignalType": "Add signal type",
                "editSignalType": "Edit a signal type",
                "defaultUnit": "Default unit",
                "conversionCoeffcient": "Conversion coeffcient",
                "reserveDecimalFraction": "Reserve decimal"
            }
        },
        "bearing": {
            "bearing": "Bearing",
            "title": "Bearing List",
            "rollerCount": "Roller count",
            "holdingShelfFailFactor": "Holding shelf fault factor",
            "rollerFailFactor": "Roller fault factor",
            "outerRingFailFactor": "Outer ring fault factor",
            "innerRingFailFactor": "Inner ring fault factor",
            "addBearing": "Add bearing",
            "editBearing": "Edit a bearing",
            "bearingVendor": "Vendor",
            "bearingVendorPlaceholder": "Please input the vendor",
            "bearingModel": "Model",
            "bearingModelPlaceholder": "Please input the model",
            "bearingDetails": "Features",
            "contactAngle": "Contact angle",
            "axleDiameter": "Axle diameter",
            "rollerDiameter": "Roller diameter",
            "caculate": "Calculate",
            "bearingFaultNum": "Bearing fault factor",
            "contactAngleTip": "The contact angle should not be empty",
            "contactAngleNumTip": "The angle should be an integer ranged from 0 to 180",
            "axleDiameterTip": "The axle diameter should not be empty",
            "axleDiameterNumTip": "The axle diameter should be ranged from 0 to 1000, and only has one decimal place",
            "rollerDiameterTip": "The roller diameter should not be empty",
            "rollerDiameterNumTip": "The roller diameter should be ranged from 0 to 100, and only has one decimal place",
            "rollerCountTip": "The roller count can not be empty",
            "rollerCountNumTip": "The roller count should be an integer ranged from 0 to 100",
            "axleDiameterBiggerThanRollerTip": "The axle diameter should be bigger than the roller's",
            "caculateTip": "Please calculate the bearing fault factor",
            "chooseBearingTip": 'Please choose one bearing'
        },
        "dataManage": {
            "exportTitle": "Export data",
            "exportPath": "Export path",
            "importTitle": "Import data",
            "select": "Select",
            "open": "Open"
        },
        "instrument": {
            "rotateSpeedSetting": "Rotate speed setting",
            "rotateSpeedSettingPlaceholder": "Please input the rotate speed",
            "instrumentNumber": "Instrument number",
            "captureCard": "Capture card",
            "channelType": "Channel type",
            "relatedPath": "Related path",
            "channelSetting": "Channel settings",
            "manuallyCheck": "Manually check",
            "balance": "Balance",
            "zero": "Clear",
            "exportData": "Export the result",
            "importData": "Import the result",
            "countReset": "Count reset",
            "wireResistanceMeasurement": "Wire Resistance Measurement",
            "wireTitle": "Wire Resistance Measurement Result",
            "wireValue": "Wire Resistance Measurement Value",
            "selfCheckResult": "Self Check Result",
            "channelNumber": "Channel number",
            "channelName": "Channel name",
            "instrumentName": "Instrument name",
            "instrumentSerial": "Instrument serial number",
            "timeoutOffline": "Offline timeout",
            "positiveIntegerRequired": "Please input a positive integer",
            "selectFile": "Select a file",
            "selectFileTooltip": "Please click the blank or drag a file to upload",
            "batteryLevel": "Battery level",
            "channels": "Channels",
            "editInstrument": "Edit instrument",
            "editInstrumentPlaceholder": "Please input the instrument name",
            "sampleMode": "Sample mode",
            "sampleModePlaceholder": "Please select the sample mode",
            "paramDownloadType": "Parameters pushing type",
            "paramDownloadTypePlaceholder": "Please select the parameters pushing type",
            "sampleInterval": "Sample interval",
            "sampleIntervalPlaceholder": "Please select the sample interval",
            "showDivision": "Show division",
            "showDivisionPlaceholder": "Please select the division",
            "workMode": "Work mode",
            "sampleGate": "Sample gate",
            "sampleGatePlaceholder": "Please input the sample gate",
            "offlineDiv": "Break point uploading interval",
            "rotateSpeed": "Rotate speed",
            "rotateSpeedPlaceholder": "Please input the rotate speed",
            "instrumentSerialPlaceholder": "Please input the instrument serial number",
            "title": "Instrument Tree",
            "refresh": "Refresh",
            "instrumentList": "Instrument List",
            "captureCardList": "Capture Card List",
            "channelList": "Channel List",
            "instrumentType": "Instrument type",
            "instrumentTypePlaceholder": "Please input the instrument type",
            "startSampling": "Start Sampling",
            "stopSampling": "Stop Sampling",
            "NTPSetting": "NTP settings",
            "noInstrument": "No instrument yet",
            "NTPList": "NTP list",
            "deleteInstrument": "Delete instrument",
            "startingSampling": "Start sampling",
            "stopingSampling": "Stop sampling",
            "lowBatteryAlarm": "Low battery power alarm",
            "powerOn": "Power on",
            "powerOff": "Power off",
            "absoluteClearZero": "Absolute clearzero",
            "relativeClearZero": "Relative clearzero"
        },
        "permission": {
            "depart": {
                "addUnit": "Add unit",
                "addDepartment": "Add department",
                "superiorDepartment": "Superior department",
                "nodeRequired": "Please select a node",
                "noAuthToDeleteNode": "No authorization to delete",
                "nodeNeededWhenDelete": "Please select the node(s) you'd like to delete",
                "confirmDeleteOperation": "Delete current unit/department will also delete its sub-department(s), continue?",
                "confirmTwice": "Are you sure to delete selected node?",
                "unitDeleteTip": "You cannot delete a unique unit"
            },
            "role": {
                "title": "Role",
                "roleName": "Role name",
                "roleDiscription": "Role discription",
                "deleteConfirmMessage": "Current role is linked to at least one user, deleting this role will cause the user to lose the role and authorization, continue?",
                "deleteConfirmTitle": "Confirm delete",
                "addRole": "Add role",
                "editRole": "Edit a role",
                "roleNamePlaceholder": "Please input the role name",
                "module": "Module",
                "operationAuth": "Operation authorization",
                "instrumentTreeSetting": "Instrument tree settings"
            },
            "user": {
                "workGroup": "Work group",
                "columnSetting": "Display item settings",
                "addUser": "Add user",
                "displayName": "Name",
                "mobile": "Mobile",
                "email": "Email",
                "loginType": "Login method",
                "loginCategory": "Login type",
                "createTime": "Time",
                "createBy": "By",
                "ExpiredTime": "Available",
                "ExpiredTimeWithUnit": "Available(day)",
                "disabledSucceed": "Successfully disabled",
                "enabledSucceed": "Successfully enabled",
                "addUser2": "Add User",
                "editUser": "Edit user",
                "departmentRelated": "Related department",
                "userRelated": "Related user",
                "workGroupRelated": "Related work group",
                "accountPlaceholder": "Please input the account",
                "namePlaceholder": "Please input the name",
                "passwordTwicePlaceholder": "Please confirm the password",
                "mobilePlaceholder": "Please input the mobile",
                "emailPlaceholder": "Please input the email",
                "loginTypePlaceholder": "Please input the login method",
                "expireTimePlaceholder": "Please input the available time",
                "organizationStructure": "Organization structure",
                "workGroupList": "Work group list",
                "workGroupDiscription": "Work group discription",
                "chooseEnable": "Please select whether it should be enable",
                "workGroupTip": "You must have the workgroup browsing permission, otherwise you cannot view and modify the display path of the alarm message"
            },
            "workGroup": {
                "title": "Work Group List",
                "workGroupName": "Work group name",
                "workGroupDiscription": "Work group discription",
                "addWorkGroup": "Add work group",
                "editWorkGroup": "Edit work group",
                "workGroupNameRequired": "Please input the work group name",
                "browse": "View(search)",
                "createWorkGroup": "Add Workgroup",
            }
        },
        "productManage": {
            "nodeSetting": {
                "title": "Node Configuration",
                "number": "Number",
                "numberPlaceholder": "Please input the number",
                "illegalChar": "Illegal character",
                "alertMessage": "Change number may cause problem to existed task(s), continue?"
            },
            "theme": {
                "title": "Theme Setting",
                "mainColor": "Main color",
                "cardColor": "Card color",
                "backgroundColor": "Background color",
                "deviderColor": "Devider color",
                "tipColor": "Tip color",
                "bodyColor": "Body color",
                "titleColor": "Title color",
                "customTheme": "Custom theme",
                "createTheme": "New custom theme",
                "defaultLight": "Default light theme",
                "defaultDark": "Default dark theme",
                "deleteConfirm": "Are you sure to delete this theme?",
                "applySucceed": "Theme applied",
                "saveMode": "save UI mode"
            },
            "language": {
                "chooseLanguage": "Select language:"
            },
            "safe": {
                "title": 'Security settings',
                "passwordLock": 'Whether the lock password is incorrect',
                "numberOfErrors": 'The number of times you entered an error',
                "lockTime": 'The length of lock'
            },
            "operationLog": {
                'title': "Operation Log",
                "operateBy": "User",
                "operateTime": "Operate time",
                "search": "Search",
                "operateContent": 'Content',
                "action": 'Action',
                "operands": 'Operands'
            }
        },
        "runStatusManage": {
            "title": "Start and stop setting",
            "start": "Start",
            "stop": "Stop",
            "saveStatus": "Save status change",
            "queryStatus": "Query status change",
            "currentStatus": "Current status",
            "stopReason": "Reason",
            "viewStatus": "View status",
            "details": "Details",
            "toNow": "Till now",
            "reason": "Reason",
            "operatebBy": "Operator",
            "bySignal": "By signal",
            "byRotateSpeed": "By rotateSpeed",
            "startUpConditions": "Start up conditions",
            "switch": "Switch",
            "addCondtions": 'Add condtions',
            "editConditions": 'Edit conditions'
        },
        "selfCheck": {
            "selfCheckParam": "Self-check Parameters",
            "selfCheckPeriod": "Self-check Period",
            "automaticCheck": "Automatically self-check",
            "otherSelfCheckType": "Other self-check",
            "iepeSelfCheck": 'IEPE self-check',
            "vibrateSelfCheck": 'Vibrate self-check',
            "sensorSelfCheck": 'Sensor self-check',
            "strainSelfCheck": "Strain self-check",
            "inSituSelfCheck": "In situ self-check",
            "underInstrument": "Instrument",
            "underCaptureCard": "Capture card",
            "underInstrumentPlaceholder": "Please select the instrument",
            "underCaptureCardPlaceholder": "Please select the capture card",
            "excitateFrequency": "Excitate frequency",
            "sampleFrequency": "Sample frequency",
            "sampleDepth": "Sample depth",
            "excitateFrequencyPlaceholder": "Please select the excitate frequency",
            "sampleFrequencyPlaceholder": "Please select the sample frequency",
            "sampleDepthPlaceholder": "Please select the sample depth",
            "baseVolt": "Base voltage",
            "channelName": "Channel name",
            "selfCheckSwitch": "Self-check switch",
            "reuseItemRequired": "Please select at least one item to reuse",
            "baseVoltMoreThanZeroRule": "The voltage should higher than 0",
            "range": "Range",
            "rangeValue": "Range value",
            "rangePlaceholder": "Please select the range",
            "bridgeWay": "Bridge circuit",
            "bridgeWayPlaceholder": "Please select the bridge circuit",
            "bridgeVolt": "Bridge voltage",
            "bridgeVoltPlaceholder": "Please select the bridge voltage",
            "wireResistance": "Wire resistance",
            "strainGaugeResistance": "Strain gauge resistance",
            "resistanceMoreThanZeroRule": "The resistance should be higher than 0",
            "wireResistanceMoreThanZeroRule": "The wire resistance should be higher than 0",
            "gaugeResistanceMoreThanZeroRule": "The gauge resistance should be higher thant 0",
            "groupByIns": "Grouped by instrument"
        },
        "settingCenter": {
            "agreement": {
                "agreementType": "Agreement type",
                "deviceList": "Device list",
                "deviceID": "Device ID",
                "deviceIDPlaceholder": "Please input the device ID",
                "modbus": {
                    "unit": "Unit",
                    "setByPoint": "Set by point",
                    "setByDevice": "Set by instrument",
                    "deviceGroupList": "Instrument group list",
                    "addDeviceGroup": "Add instrument group",
                    "editDeviceGroup": "Edit instrument group",
                    "transformParams": "Transform parameters",
                    "addDevice": "Add instrument",
                    "deleteDevice": "Delete instrument",
                    "deleteModbus": "Delete modbus",
                    "deviceGroupName": "Instrument group name",
                    "deviceGroupNamePlaceholder": "Please input the instrument name",
                    "interval": "Interval(second)",
                    "intervalWithoutUnit": "Interval",
                    "intervalPlaceholder": "Please input the interval",
                    "startAddress": "Start address",
                    "startAddressPlaceholder": "Please input start address",
                    "sendInstrumentStatus": "Push instrument status",
                    "sendContent": "Content",
                    "deviceIdAndStatus": "Instrument ID and status",
                    "deviceIdAndAlarm": "Instrument ID and alarm",
                    "deviceStatusCode": "Instrument status code",
                    "alarmStatusCode": "Alarm status code",
                    "deviceStatus": "Instrument status",
                    "alarmStatus": "Alarm status",
                    "normal": "Normal",
                    "highAlarm": "Warning alarm",
                    "doubleHighAlarm": "Critical alarm",
                    "deviceIdIntegerRule": "The instrument ID should be an integer",
                    "deviceIdMaxRule": "The instrment ID should not bigger than 255",
                    "deviceStatusCodeNormal": "Instrument status code(normal)",
                    "deviceStatusCodeWarning": "Instrument status code(warning)",
                    "deviceStatusCodeDanger": "Instrument status code(critical)",
                    "alarmStatusCodeNormal": "Alarm status code(normal)",
                    "alarmStatusCodeWarning": "Alarm status code(warning)",
                    "alarmStatusCodeDangerous": "Alarm status code(critical)",
                    "normalCodeRule": "Normal code should be a natural integer",
                    "warningCodeRule": "Warning code should be a natural integer",
                    "dangerousCodeRule": "Critical code should be a natural integer",
                    "deviceRequired": "Please select the instrument first",
                    "dataType": "Data type",
                    "dataTypePlaceholder": "Please select data type",
                    "functionCode": "Function code",
                    "functionCodePlaceholder": "Please select function code",
                    "boundSlave": "Bind slave",
                    "boundSlavePlaceholder": "Please select the slave node",
                    "increment": "Increment",
                    "allIncrement": "Set increment to all",
                    "variableAddress": "Variable address",
                    "eigenType": "Eigen type",
                    "eigenTypePlaceholder": "Please select the eigen type",
                    "boolean": "Booean(bool)",
                    "wireRegister": "Wire register",
                    "holdingRegister": "Holding register",
                    "addressIntegerRule": "The address should be a natural integer",
                    "pointRequired": "Please select point",
                    "faultRequired": "Please select fault",
                    "deleteConfigSucceed": "Delete succeed",
                    "deleteConfigConfirm": "Are you sure to delete ",
                    "slaveDeviceInfo": "Slave device information",
                    "addSlave": "Add slave",
                    "slaveName": "Slave name",
                    "slaveId": "Slave ID",
                    "editSlave": "Edit slave",
                    "deleteSlave": "Delete slave",
                    "slaveNameRequired": "Please input slave name",
                    "method": "Method",
                    "rightIpRequired": "Illegal IP, please input another one",
                    "startSucceed": "Start succeed",
                    "startFailed": "Start failed",
                    "endSucceed": "Stop succeed",
                    "deleteSlaveConfirm": "Are you sure to delete selected slave node(s)?",
                    "statusCode": "Status code",
                    "applyAll": 'Apply All',
                    "master": 'Master',
                    "addMaster": 'Add Master',
                    "siteType": 'Site type',
                    "slave": 'Slave',
                    "deviceMsg": 'Device encodings cannot have the same entry',
                    "statusMsg": 'The status code cannot have the same item',
                    "addSite": 'Add site',
                    "editSite": 'Edit site',
                    "siteName": 'Site name',
                    "boundSite": 'Bound site',
                    "siteInfo": 'Site information',
                    "deleteSite": 'Delete site',
                    "deleteSiteConfirm": "Are you sure to delete selected site?",
                    "format": 'Data format',
                    'nodeFiltering': 'Node filtering',
                    "codeRule": "Status code should be a natural integer",
                    "codeLimitRule": "The code must be increased in order of alarm level",
                },
                "mqtt": {
                    "pointConfig": "Point configuration",
                    "clientAuthConfig": "Client authorization configuration",
                    "addClient": "Add client",
                    "editClient": "Edit client",
                    "deleteClient": "Delete client",
                    "clientId": "Client ID",
                    "clientIdPlaceholder": "Please input the client ID",
                    "deleteClientConfirm": "Are you sure to delete selected client(s)?",
                    "faultFilter": "Fault filter",
                    "faultName": "Fault name",
                    "faultAlarm": "Fault alarm",
                    "serviceAddress": "Service address",
                    "waveform": "Waveform",
                    "enableWaveform": "Enable waveform",
                    "editAddressSucceed": "Edit succeed",
                    "externalCodeRequired": "Please input external code",
                    "themePlaceHolder": "Please input theme",
                    "groupList": "Group list",
                    "groupDetail": "Group detao;",
                    "monitoringType": "Monitor type",
                    "monitoringPointNumber": "Monitor point number",
                    "keysPlaceHolder": "Please enter the key, up to 256 characters",
                    "themeSymbolTip": "Themes cannot be contained",
                    "key": "key",
                    "removeGroupTip": "Are you sure you want to delete the selected group？",
                    "removePointTip": "Are you sure you want to delete the selected measurement point?？",
                    "sensorIdTip": "The monitoring number only supports positive integer",
                    "sensorLimitTip": "The monitoring number must be between 1 and 9007199254740992",
                    "acousticDeviceNum": "Acoustic emission broken wires device number",
                    "acousticChannelNum": "Acoustic emission broken wires channel number",
                    "pushName": "Push name",
                },
                "opc": {
                    "connectParam": "Connection parameters",
                    "eigenTypePlaceholder": "Please select the eigen type",
                    "applySucceed": "Apply succeed"
                },
                "phm": {
                    "terminalList": "Terminal list",
                    "terminalParam": "Terminal parameters",
                    "thirdParty": "Third-party",
                    "connectionStatus": "Connection status",
                    "lastConnectionLasts": "Last connection",
                    "alarmPushConfig": "Alarm pushing configuration",
                    "needsConnection": "Connected",
                    "pushWaveformOption": "Push waveform data",
                    "pushFalutAlarmOption": "Push fault alarm",
                    "mqttSeverRequired": "Please set Mqtt service address first",
                    "exportItemRequired": "Please select export item(s)",
                    "exporting": "Export in progress",
                    "addTerminal": "Add terminal",
                    "editTerminal": "Edit terminal",
                    "connected": "Connected",
                    "disconnected": "Disconnected",
                    "needsNumber": "Please set a number first in point settings",
                    "adding": "Adding",
                    'editing': "Editing",
                    "added": "Add succeed",
                    "edited": "Edit succeed",
                    "clearSucceed": "Clear succeed",
                    "tcpIpNet": 'IP address/domain',
                    "tcpIpNetPlaceholder": 'Please enter an IP address or domain name',
                    "settingList": 'Setting List',
                    "faultPath": 'Fault Path',
                    "faultData": 'Fault Data',
                    "faultEvent": 'Fault Event',
                    "oneClickSelfIncrement": 'One-click self-increment',
                    "exportAll": 'Export All',
                    "alarmEvent": 'Alarm Event',
                    "externalCodeTip": 'The external encoding can be up to 16 characters',
                    "externalCodeTip48": 'The external encoding can be up to 48 characters',
                    "externalCodeLimit": 'The external encoding cannot contain Chinese',
                    "limitTip": 'Up to 16 characters',
                    "limitTip48": 'Up to 48 characters',
                    "choosePointTip": 'Please choose point',
                    "chooseFaultTip": 'Please choose fault',
                    "externalCodeLimit2": 'The external encoding cannot contain ,',
                    "channelStatus": 'Channel status'
                }
            },
            "alarm": {
                "groupType": "Group Type",
                "onePointGroup": "A group of single measurement points",
                "allPointGroup": "One set of all measurement points",
                "alarmSaveTime": "Alarm storage timeout",
                "alarmConfig": "Alarm Configuration",
                "alarmParam": "Alarm parameters",
                "observationCategory": "Observation category",
                "observationCategoryPlaceholder": "Please select the observation category",
                "observationName": "Observation name",
                "alarmLevel": "Alarm level",
                "normal": "Normal",
                "high": "Warning",
                "doubleHigh": "Critical",
                "highThreshold": "Warning threshold",
                "doubleHighThreshold": "Critical threshold",
                "alarmCategory": "Alarm category",
                "overLimit": "Over limit",
                "belowLimit": "Below limit",
                "inWindow": "In window",
                "outWindow": "Out window",
                "equal": "Equal",
                "none": "None",
                "switch": "Switch",
                "lowLimit": "Min",
                "highLimit": "Max",
                "referenceValue": "Reference value",
                "sampleDiagram": "Sample diagram",
                "pointNameRequired": "Please input point name",
                "unitTypeRequired": "Please select unit type",
                "alarmThresholdRule": "The threshold is ranged from -1000000000 to 1000000000",
                "overLimitRule": "The critical value should higher than the warning value of the over limit alarm for amplitude",
                "belowLimitRule": "The warning value should higher than the critical value of the below limit alarm for amplitude",
                "inWindowHighAlarmRule": "The upper limit of the amplitude alarm in the window must be greater than the lower limit of the high alarm",
                "inWindowDoubleHighAlarmRule": "The upper limit of high high alarm for amplitude alarm within the window must be greater than the lower limit of high high alarm",
                "inWindowOrderRule": "Please assign values according to the rules in the window(warning-max -> critical-max -> critical-min -> warning-min)",
                "outWindowHighAlarmRule": "The upper limit of the amplitude alarm outside the window must be greater than the lower limit of the high alarm",
                "outWindowDoubleHighAlarmRule": "The upper limit of the high high alarm for the amplitude alarm outside the window must be greater than the lower limit of the high high alarm",
                "outWindowOrderRule": "Please assign values according to the rules outside the window(critical-max -> warning-max -> warning-min -> critical-min)",
                "alarmFilterInWindowRule": "The upper limit in the filter type window must be greater than the lower limit",
                "alarmFilterOutWindowRule": "The upper limit outside the window must be greater than the lower limit",
                "noEditAuth": "You need edit permission for the measurement point settings",
                "alarmInterval": "Alarm interval",
                "maxCountLimit": "Max counts",
                "counts": "Count",
                "amplitudeAlarmThresholdCount": "Number of trigger points for amplitude alarm:",
                "alarmVoice": "Alarm voice:",
                "systemDefault": "System default",
                "customVoice": "Custom",
                "select": "Select",
                "preview": "Preview",
                "alarmIntervalRequired": "Please input the alarm interval",
                "maxCountLimitRequired": "Please enter the count limit",
                "amplitudeAlarmThresholdCountRequired": "Please input the threshold",
                "voiceChanged": "Change voice succeed",
                "alarmSaving": "Save alarm",
                "needsSaving": "Save",
                "forward": "Forward",
                "backward": "Backward",
                "storageUnit": "Storage Unit",
                "blockSizePlaceholder": "Please input an integer ranged from 0 to 400",
                "blockSizeWarning": "Both forward and backward block size should be an integer ranged from 0 to 400",
                "secondSizePlaceHolder": "Please input an integer ranged from 0 to 1800",
                "secondSizeWarning": "Both forward and backward storage time should be an integer ranged from 0 to 1800",
                "alarmSetting": "Alarm settings",
                "alarmFilter": "Alarm filter",
                "filterType": "Filter Type",
                "alarmPoint": "Alarm Point",
                "amplitudeMutationTrigger": "Amplitude trigger",
                "amplitudeMutationTriggerPlaceholder": "Please input the trigger amplitude",
                "amplitudeMutationTriggerTitle": "Please input a number ranged from 0.001 to 100000000",
                "parametersType": "Parameter type",
                "byFrequencyBand": "By frequency band",
                "byCenterFrequency": "By center frequency",
                "byRotateSpeed": "By rotate speed",
                "bandParameter": "Spectrum parameters",
                "change": "Change",
                "bandParameterRule": "Please input a number ranged from 0 to 100000000",
                "centerFrequency": "Center frequency",
                "bandMax": "Upper limit",
                "bandWidth": "Bandwidth",
                "bandMix": "Lower limit",
                "changeBandParameter": "Modify Spectrum Parameters",
                "diagramType": "Graph type",
                "diagramTypePlaceholder": "Please select the graph type",
                "isolateType": "Isolate type",
                "isolateTypePlaceholder": "Please select the isolate type",
                "windowType": "Window type",
                "windowTypePlaceholder": "Please select the window type",
                "analysisCount": "Block Size",
                "analysisCountPlaceholder": "Please select block size",
                "averageTime": "Average times",
                "averageType": "Average method",
                "averageTypePlaceholder": "Please select the average method",
                "needsSimulateRotateSpeed": "Simulate speed or not",
                "simulateRotateSpeed": "Simulate",
                "noSimulation": "Not",
                "bandFrequencyMinWithUnit": "Lower limit of band frequency(Hz)",
                "centerFrequencyWithUnit": "Center frequency(Hz)",
                "bandFrequencyMinWithX": "Lower limit of band frequency(x)",
                "bandFrequencyMaxWithUnit": "Upper limit of band frequency(Hz)",
                "bandWidthWithUnit": "Bandwidth(Hz)",
                "bandFrequencyMaxWithX": "Upper limit of band frequency(x)",
                "forwardBlock": "Forward block size",
                "forwardBlockRequired": "Please input the forward block size",
                "backwardBlock": "Backward block size",
                "backwardBlockRequired": "Please input the backward block size",
                "peak": "Peak",
                "averageSqrt": "Root mean square value",
                "amplitudeAlarm": "Amplitude alarm",
                "mutationAlarm": "Mutation alarm",
                "bandFrequencyAlarm": "Band energy alarm",
                "saveSucceedAlert": "Successfully saved alarm storage",
                "amplitudeHighAlarmRule": "The high high alarm value of the over limit amplitude alarm must be greater than the high alarm value",
                "amplitudeLowAlarmRule": "The low limit amplitude alarm high alarm value must be greater than the high high alarm value",
                "ampEigenTypeRequired": "Please select the type of amplitude characteristic value",
                "mutationThresholdRule": "The alarm threshold should be a number ranged from 0.001 to 100000000",
                "mutationHighAlarmRule": "Mutation alarm high high alarm value must be greater than high alarm value",
                "mutationEigenTypePlaceholder": "Please select the type of mutation characteristic value",
                "mutationTriggerPlaceholder": "Please input the trigger amplitude",
                "alarmParamRequired": "Please add alarm parameters",
                "changeAmpAlarmSucceed": "Successfully modified amplitude alarm",
                "changeMutAlarmSucceed": "Successfully modified mutation alarm",
                "changeBandEnergyAlarmSucceed": "Successfully modified frequency band energy alarm",
                "removeSucceed": "Successfully removed",
                "highThresholdError": "The over-report threshold must be less than the over-reporting threshold",
                "highThresholdError2": "The low-level overreport threshold must be greater than the high-high reporting threshold",
                "bandRangeError": "The upper limit of the frequency band must be greater than the lower limit",
                "onlinePoint": "Online",
                "patrolPoint": "Patrol",
                "paramSetting": "Parameter settings",
                "requesting": "Requesting",
                "noBrowseAuth": "Currently you do not have permission to view measurement points",
                "chooseAlarm": "Please set the alarm first",
                "alarmSettingSuccess": 'The alarm settings are modified',
                "bandCoincideMsg": "There can be no overlap in the band intervals",
                "detailSetting": "Detail settings",
                "ampAlarmTriggerControl": "Amplitude alarm trigger",
                "ampAlarmTriggerNums": "Amplitude trigger number",
            },
            "platformMachineTree": {
                "instrumentTreeSetting": "Device Tree Configuration",
                "otherSetting": "Other Configuration",
                "syncInstrumentTree": "Synchronize Device Tree",
                "unlinking": "Disassociate",
                "orginazaionRelated": "Associated organizational structure",
                "linkToOrganization": "Related organizations",
                "syncWarning": "Synchronizing the device tree will synchronously add the device tree and overwrite it in the department management list. Besides, the device and scenario will create a virtual department with the same name to the department management list, continue?",
                "syncDeviceOrScene": "Sync device / scene",
                "editOrganizationRelated": "Edit Associated Organizational Structure",
                "instrumentTreeName": "Device Tree Name",
                "relateTreeStruct": "Association Tree Structure",
                "test": "Test",
                "organizationRequired": "Please select an organizational structure",
                "departmentExistedError": "Department existed, can not sync",
                "syncSucceed": "Sync successful",
                "linkSucceed": "Successfully associated",
                "pointNeeded": "Please select a node",
                "cancelSucceed": "Canceled successfully",
                "alertMessage": "Are you sure you want to cancel the association?"
            },
            "save": {
                "storageRule": "Storage Rules",
                "storageMode": "Storage mode",
                "storageType": "Storage type",
                "dataBackup": "Data backup",
                "underPath": "Under path",
                "storageTypeCell": "Storage Rule Cell",
                "switchRule": "Switch Rules",
                "all": "All",
                "switchStorageRuleWarning": "Data with different storage rules cannot be continuously queried, switch to a new rule?",
                "switchSucceed": "Switching successful",
                "bindPoint": "Bind point",
                "ruleName": "Rule name",
                "ruleNamePlaceholder": "Please input the rule name",
                "diluteRatio": "Dilution ratio",
                "saveDay": "Storage days",
                "saveInterval": "Save Interval(s)",
                "saveInterval1": "Save Interval",
                "saveDayLimitTip": "The storage period cannot exceed 366 days",
                "saveDayMustLteLast": "The storage interval must be increased at one time",
                "firstLvelsaveDayMustBeZero": "the first row must be zero",
                "saveRadioMustLteLast": "The storage interval not valid",
                "diluteStrategy": "Dilution Stragegy(Proportional)",
                "isDisplay": "Please choose whether to display",
                "diluteRatioIncreaseRule": "The time must increase sequentially",
                "diluteRatioMaxRule": "The dilution ratio cannot exceed 65532",
                "storageRuleCellName": "Storage Rule Cell Name",
                "dilutionRulesName": "Dilution rules unit name",
                "storageRuleCellNamePlaceholder": "Please input the storage rule cell name",
                "storageRuleRadioName": "Storage rule Name",
                "storageRuleRadioNamePlaceholder": "Please input the storage rule name",
                "saveDayWithLimit": "Storage days(natural integer)",
                "naturalNumberPlaceholder": "Please enter a natural integer",
                "storageOverMaxError": "Maximum number of storage items is 10",
                "saveDayExcceed": "Storage days cannot exceed 65532",
                "saveDayNeedsIncrease": "The storage days must increase sequentially",
                "saveDayPlaceholder": "Please fill in the storage days",
                "continousStore": "Continuous storage",
                "fixedTimeStore": "Scheduled storage",
                "diluteStore": "Dilute Storage",
                "triggerStore": "Trigger Storage",
                "negativeDelayPoint": "Negative delay points",
                "delayPointPlaceholder": "Please enter the number of delay points",
                "startTrigger": "Starting conditions",
                "triggerOnce": "Single",
                "triggerMulti": "Multiple",
                "triggerTimes": "Trigger times",
                "triggerInterval": "Trigger interval",
                "triggerPeriodPlaceholder": "Please enter the trigger period",
                "triggerTimePlaceholder": "Please enter the number of triggers",
                "zeroEqualsToInfinity": "0 means unlimited",
                "startDayPlaceholder": "Please select a start date",
                "stopTrigger": "Stop condition",
                "absoluteTime": "Absolute time",
                "relativeTime": "Relative time",
                "trigger": "Trigger conditions",
                "triggerPoint": "Trigger measuring point",
                "triggerType": "Trigger Condition Type",
                "triggerBySignal": "Signal triggering",
                "triggerMethod": "Trigger method",
                "triggerThreshold": "Trigger Threshold",
                "continousPoint": "Duration points",
                "noStorageRuleCell": "No storage rule units cell",
                "addStorageRule": "Add Storage Rule",
                "editStorageRule": "Edit Storage Rule",
                "pointLinkedToRuleWarning": "This rule already has been bound to test points",
                "addStorageRuleCell": "Add Storage Rule Cell",
                "editStorageRuleCell": "Edit Storage Rule Cell",
                "deleteConfirm": "Please confirm whether to delete it",
                "deleteConfirmTitle": "Delete unit",
                "automaticallyBackup": "Automatic backup",
                "backupPeriod": "Backup cycle",
                "backupData": "Backup data",
                "allData": "All data",
                "eigenAndAlarm": "Eigen value + Alarm",
                "backupTime": "Backup time",
                "backupManually": "Manual backup",
                "backupNow": "Immediate backup",
                "endTimeShouldLargerThanStartTime": "The start time must be less than the end time",
                "backupInProgress": "Backup in progress",
                "addStorageRule": "Add storage rule",
                "deleteStorageRule": "Delete storage rule",
                "storageRuleName": "Storage rule name",
                "editDilutionRule": "Edit dilution rule unit",
                "addDilutionRule": "Add dilution rule unit",
                "addStorageInterval": "Add storage rule",
                "editStorageInterval": "Edit storage rule",
                "startTriggerType": "Starting condition type",
                "startTime": "Start time",
                "storagePeriod": "Storage cycle",
                "storageTimes": "Storage Times",
                "stopTriggerType": "Stop condition type",
                "stopTime": "Stop time",
                "stopTimeType": "Stop time type",
                "stopTimePlaceholder": "Please input the stop time",
                "stopTimeRequired": "Please select the stop time",
                "periodTrigger": "Periodic trigger",
                "storageTypeBondByPoint": "A storage mode is bound to the point in the selected item!",
                "storageTypeAlreadyBondByPoint": "The current storage mode has been bound to the point!",
                "timePlaceholder": "Please select a time",
                "stopTimeBiggerThanTriggerTime": "The stop time must be greater than the trigger time",
                "stopTimeSmallerThanStoragePeriod": "The stop time must be less than the storage cycle",
                "fixedTimeTypeNameRequired": "Please enter the name of the scheduled storage type",
                "rulerNameRequired": "Name Required",
                "startTimeRequired": "Please select the start time",
                "storagePeriodRequired": "Please enter the storage period",
                "storageTimesRequired": "Please enter the number of storage times",
                "addFixedTimeStorageRule": "Add fixed time storage rule",
                "editFixedTimeStorageRule": "Edit fixed time storage rule",
                "lowElectricalLevel": "Low electrical level",
                "highElectricalLevel": "High electrical level",
                "continousBlock": "Number of persistent blocks",
                "triggerType2": 'Trigger Type',
                "byThreshold": 'By Threshold',
                "byTime": 'By Time',
                "continousTime": "Continous Time",
                "triggerThresholdRequired": "Please enter the trigger threshold!",
                "triggerThresholdMustBeNumber": "The trigger threshold can only be a number!",
                "continousBlockRequired": "Please enter the number of persistent blocks!",
                "continousBlockMustBeNaturalNumber": "The number of persistent blocks can only be a positive integer!",
                "triggerTimeRequired": "Trigger Time Required",
                "rulerGroupRequired": "Ruler Group Required",
                "duilteRulerRequired": "Dilute Ruler Required",
                "triggerTimeMustBeNaturalNumber": "Trigger Time Must Be Natural Number",
                "delayType": "Delay method",
                "delayPoints": "Delay points(block)",
                "delayPointsWithoutUnit": "Delay Points",
                "noDelay": "No delay",
                "positiveDelay": "Positive delay",
                "negativeDelay": "Negative delay",
                "addTriggerStorageType": "Add trigger storage mode",
                "editTriggerStorageType": "Edit trigger storage mode",
                "addTriggerStorageRule": "Add trigger storage rule",
                "editTriggerStorageRule": "Edit trigger storage rule",
                "triggerParameter": "Trigger parameters",
                "storageTypePlaceholder": "Please enter a storage mode name",
                "triggerStorageTypeNameRequired": "Please enter the trigger storage type name",
                "triggerTimeRequired": "Please enter the number of triggers",
                "triggerIntervalRequired": "Please enter the trigger interval",
                "delayPointRequired": "Please enter the number of delay points",
                "deleteTip": 'If the storage period is shorter, the system will delete the excess data, please check whether to modify it again?',
                "confrimTip": 'If the storage period is shortened, the system will delete the excess data, so please check whether to modify it?',
                "storeUpdateTip": 'the dilution unit corresponding to storage rule "[0]" is associated with dilution rule [1]. Please modify it in the storage configuration',
                "startConditionsRequired": "Please add start condition",
                "dilutionRules": "Dilution rules",
                "dilutionRulesUnit": "Dilution rules Unit",
                "rules1": 'Within 30 days: 1 data/minute',
                "rules2": '30-90 days: 1 data/10 minutes',
                "rules3": '90-180 days: 1 data/1 hour',
                "rules4": 'More than 180 days: 1 data/12 hours',
                "startConditionPoint": 'Please choose start condition point',
                "stopConditionPoint": 'Please choose stop condition point',
                "confirmTip": "Modifying the storage rules will cause the collected data to be lost, so it is recommended to contact the engineer to confirm whether to continue?"
            },
            "scheduledExport": {
                "title": "Scheduled export task",
                "exportParam": "Export parameters",
                "exportFormat": "Export Format",
                "exportPeriod": "Export cycle",
                "actionTime": "Export Execution Time",
                "xDecimal": "X Axis Decimal Places",
                "yDecimal": "Y Axis Decimal Places",
                "timePlaceholder": "Please select a time",
                "expiredAfter": "Expired After",
                "separatorType": "Separator type",
                "customSeparator": "Custom Separator",
                "exportData": "Export data",
                "alarmExportFormat": "Alarm data format",
                "exportItem": "Export item",
                "storageTypeForDHDAS": "Storage type",
                "exportGroup": "Export group",
                "exportPoints": "Export points",
                "eachDay": "Each day",
                "twoDays": "Two days",
                "threeDays": "Three days",
                "fourDays": "Four days",
                "fiveDays": "Five days",
                "sixDays": "Six days",
                "eachWeek": "Each week",
                "oneMonth": "One month",
                "threeMonths": "Three months",
                "sixMonths": "Six months",
                "nineMonths": "Nine months",
                "oneYear": "One year",
                "oneYearAndHalf": "One year and a half",
                "twoYears": "Two years",
                "threeYears": "Three years",
                "notCleanUp": "Backups are not cleaned up",
                "semicolonChar": "Semicolon",
                "commaChar": "Comma",
                "blankChar": "Black",
                "tableChar": "Table",
                "otherChar": "Other",
                "waveformData": "Waveform data",
                "eigenData": "Eigen data",
                "alarmData": "Alarm data",
                "startTime": "Start time",
                "saveEachChannel": "Save each channel",
                "saveEachGroup": "Save each group",
                "actionTimeRequired": "Please select the export task execution time",
                "separatorRequired": "Please input the separator",
                "exportDataAtLeastOne": "Export data requires at least one item",
                "formatRequired": "Please select the format of alarm data",
                "editGroup": "Edit group",
                "addGroup": "Add group",
                "samplePlaceholder": "Please select the sample frequency",
                "fileNameTip": "Please enter,failure to enter will be the default",
                "blockPlacehoder": "Please select the data block size",
                "groupNameRequired": "Please input a group name",
                "pointAtLeastOne": "Please select at least one measuring point",
                "deleteGroup": "Delete group",
                "apply": "Apply",
                "applyToAll": "Apply to all",
                "applyToOriginal": "Apply to original points",
                "applyToSignal": "Applied to signal processing points",
                "groupName": "Group name",
                "fileName": "File Name",
                "sampleFrequency": "Sample frequency",
                "blockSize": "Block size",
                "groupedPoints": "Grouped measurement points",
                "previewExport": "Preview export",
                "dataInterval": 'Data interval',
                "waveDataInterval": 'Wave data interval',
                "eigenDataInterval": 'Eigen data interval',
                "reportTaskList": 'Report task list',
                "reportTaskSetting": 'Report task setting',
                "exportDevices": 'Export devices',
                "blankContent": 'Blank complement strategy',
                "noContent": 'No Content',
                "latestValue": 'Latest Value'
            },
            "device": {
                "deviceSetting": "Devices setting up"
            },
            "graph": {
                "graphConfig": "Graph Configuration",
                "labelConfig": "Label Configuration",
                "fixedScale": "Fixed scale",
                "millisecond": "Millisecond",
                "selfAdaption": "Self-adaption",
                "axisScaleSetting": "Axis scale setting",
                "displayCoordinatePointsOrNot": "Display coordinate points or not",
                "historicalPoint": 'Historical data is inserted into alarm points',
                "historyInsertAlarmPoints": "History Data Insert Alarm Points",
                "minimumPushInterval": "Minimum push interval",
                "maximumNumberOfCachePointsForWaveform": "Maximum number of cache points for waveform",
                "graphGonfiguration": "Graph Configuration",
                "labelGonfiguration": "Label Configuration",
                "decimalPlaces": "Decimal Places",
                "fontSize": "Font size",
                "showLabelLines": "Show label lines",
                "showLabelPoints": "Show label points",
                "displayFeatureValueNames": "Show feature value name",
                "useRecorder": "Use recorder",
                "recorderTip": "After the recorder is used, it will consume a lot of performance and cause the operation speed to slow down, whether to continue to use the recorder?",
                "recorderSureAgain": "After the recorder is used, it will consume a lot of performance and cause the operation speed to slow down, please sure again？",
                "recorderCloseTip": "Turning off the recorder mode will affect other users. Confirm whether to turn it off?"
            },
            "msgSend": {
                "configurationInformation": "Configuration information",
                "mailboxServer": "Mailbox server",
                "pleaseEnterTheEmailServer": "Please enter the email server",
                "emailServerSendPort": "Email server send port",
                "pleaseEnterTheSendPort": "Please enter the send port",
                "emailAccount": "Email account",
                "pleaseEnterYourEmailAccount": "Please enter your email account",
                "authorizationPassword": "Authorization Password",
                "pleaseEnterTheAuthorizationPassword": "Please enter the authorization password",
                "domainName": "Domain name",
                "pleaseEnterTheDomainName": "Please enter the domain name",
                "emailSender": "Email sender",
                "pleaseEnterTheEmailSender": "Please enter the email sender",
                "limitOnTheNumberOfUsersForMassSending": "Limit on the number of users for mass sending",
                "pleaseEnterTheMaximumLimitNumber": "Please enter the maximum limit number",
                "sendEmailSubject": "Send email subject",
                "pleaseEnterTheEmailSubject": "Please enter the email subject",
                "companyLinkAddress": "Company Link Address",
                "pleaseEnterTheCompanyLinkAddress": "Please enter the company link address",
                "startStopControl": "Start stop control",
                "startUp": "Start",
                "stopIt": "Stop",
                "systemEventPushLevel": "System event push level",
                "pleaseSelectTheSystemEventPushLevel": "Please select the system event push level",
                "systemAlarmPushLevel": "System alarm push level",
                "pleaseSetTheAlarmPushLevel": "Please select the system alarm push level",
                "gaobao": "Warning",
                "highHighAlarm": "High high alarm",
                "enableOrNot": "Enable or not",
                "recipientList": "Recipient List",
                "eMailAddress": "E-mail address",
                "test": "Test",
                "noEditingPermissionsCurrentlyAvailable": "No editing permissions currently available",
                "pleaseEnterTheCorrectEmailAddress": "Please enter the correct email address",
                "confirmWhetherToDelete": "Confirm whether to delete",
                "deleteEmail": "Delete email",
                "pleaseFillInTheConfigurationInformationCompletely": "Please fill in the configuration information completely",
                "pleaseAddRecipient": "Please add recipient",
                "testSuccessful": "Test successful",
                "mailConfiguration": "Mail Configuration",
                "SMSConfiguration": "SMS configuration",
                "SMSAlarmSendingMechanism": "SMS alarm sending mechanism",
                "HuaweiCloudSMS": "Huawei Cloud SMS",
                "systemEventAlarmLevel": "System event alarm level",
                "systemEventPush": "System event push",
                "systemEventPushLevel": "System event push level",
                "pleaseSetTheEventAlarmLevel": "Delete contact phone number",
                "warning": "Warning",
                "danger": "Danger",
                "sistributionLists": "Distribution lists",
                "thereIsCurrentlyNoAataAvailable": "There is currently no data available",
                "contactPhoneNumber": "Contact phone number",
                "email": "Email",
                "pleaseEnterTheCorrectEmailFormat": "Please enter the correct email format",
                "pleaseEnterATheme": "Please enter a theme",
                "pleaseEnterTheCompanyAddress": "Please enter the company address",
                "pleaseSelectStartStopControl": "Please select start stop control",
                "pleaseSelectTheSystemAlarmPushLevel": "Please select the system alarm push level",
                "pleaseSelectWhetherToEnableOrNot": "Please select whether to enable or not",
                "pleaseSelectTheSendingMechanism": "Please select the sending mechanism",
                "pleaseSelectTheSystemEventAlarmLevel": "Please select the system event alarmLevel",
                "noPermissionToEditTemporarily": "No permission to edit temporarily",
                "pleaseEnterTheCorrectPhoneNumber": "Please enter the correct phone number",
                "deleteContactPhoneNumber": "Delete contact phone number",
                "pleaseAddARecipient": "Please add a recipient",
                "eventSmsSwitch": 'Event Sms Switch',
                "systemAlarmPush": "System alarm push",
                "associateUsers": "Associate users"
            }
        },
        "tools": {
            "title": "The maximum allowable torsional vibration angle is calculated",
            "batchTitle": "Batch Tool",
            "format": "Format",
            "exportInstrumentTree": "Export device tree",
            "importInstrumentTree": "Import device tree",
            "import": "Import",
            "exportNodeRequired": "Please select export nodes",
            "exporting": "Export in progress",
            "errorTip": "Error",
            "importFailed": "Import failed",
            "importSucceed": "Import succeed",
            "importing": "Import in progress",
            "progress": "Progress",
            "batchTip": "Importing requires the instrument to stop sampling. Importing may cause existing measurement points to be modified or deleted",
            "outerAxleDiameter": "Outer diameter of shaft(mm)",
            "innerAxleDiameter": "Inner diameter of shaft(mm)",
            "axleLength": "Axle length(mm)",
            "E": "Young's modulus of materials(Mpa)",
            "yieldStrength": "Yield limit stress of materials(MPa)",
            "possonRatio": "Poisson's ratio of materials",
            "ns": "Safety factor",
            "maxTwistVibrateAngle": "Maximum allowable torsional vibration angle",
            "paramIllegalError": "Please check if the parameters match the actual situation",
            "calculated": "Calculate succeed",
            "currentPosition": "Current position",
            "instrumentManagement": "Instrument",
            "instrumentSetting": "Instrument Settings",
            "selfCheckSetting": "Self-Check Settings",
            "signalSetting": "Signal Settings",
            "bearingSetting": "Searing Settings",
            "faultLibrarySetting": "Fault Library Settings",
            "patrolSetting": "Patrol Settings",
            "authorizationManagement": "Authorization",
            "userRoleManagement": "Role Management",
            "workGroupManagement": "Work Group Management",
            "dataPushing": "Data Pushing",
            "messagePushing": "Message Pushing",
            "storageSetting": "Storage Configuration",
            "componentSetting": "Component Configuration",
            "taskSetting": "Task Settings",
            "toolManagement": "Tools",
            "calculatingTool": "Calculation",
            "batchingTool": "Batch",
            "systemSetting": "System Setting",
            "languageSetting": "Language Setting",
            "analysisTool": "Analysis tool",
            "dataSource": "Data source",
            "dataSources": "Data sources",
            "notOpen": "Not Open",
            "turnedOn": "Opened",
            "opening": "Opening",
            "database": "Database",
            "file": "file",
            "dataAdress": 'Data path',
            "dataName": 'Database name',
            "deviceTree": "Device tree",
            "forExample": "For example",
            "activeNodeTip": "Please select the device node",
            "enableTip": "Please enable the data source first",
            "exportAnalysisTree": "Export analysis device tree"
        }
    },
    "component": {
        "title": {
            "custom": "Custom",
            "recordGraph": 'Recorder Chart',
            "trendGraph": "Trend Chart",
            "waveGraph": "Waveform",
            "spectrumGraph": "Spectrogram",
            "waterfallGraph": "Waterfall Plot",
            "barGraph": "Bar Chart",
            "numberTable": "Digital Meter",
            "dashBoard": "Dashboard",
            "wholePeriodGraph": "Whole Cycle Waveform",
            "axisPosition": "Axis Position Diagram",
            "axisTrail": "Axis Trajectory Diagram",
            "polarGraph": "Polar Plot",
            "bodePlotsGraph": "Bode Diagram",
            "cascadeGraph": "Cascading Diagram",
            "torvibMonitoring": "Torsional Vibration Monitoring",
            "torvibRotate": "Torsional Vibration Rotate Speed Analysis",

            "iirGraph": "IIR Digital Filtering",
            "firGraph": "FIR Digital Filtering",
            "integralOnce": "One Time Integration",
            "integralTwice": "Quadratic Integral",
            "differentialOnce": "First-order Differentiation",
            "differentialTwice": "Quadratic Differentiation",
            "correlation": "Correlation Analysis",
            "analysisOfCovariance": "Analysis of Covariance",
            "virtualCompute": "Virtual Computing",
            "trendForecast": "Trend Prediction",
            "statisticAnalysis": "Statistical Analysis",
            "ampAnalysis": "Amplitude Analysis",
            "rainflowAnalysis": "Rain Flow Analysis",
            "eigenFilter": "Eigen Wave Filtering",
            "fftGraph": "Spectrum Analysis",
            "crossPowerSpectrumDens": "Cross Power Spectral Density",
            "cepstrumAnalysis": "Cepstrum Analysis",
            "freqIntegral": "Frequency Domain Integration",
            "orderAlgorithm": "Order Spectrum",
            "envelopeWave": "Envelope Waveform",
            "envelopeSpectrum": "Envelope Spectrum",
            "soundPressureAnalysis": "Sound Pressure Analysis",
            "soundIntensityAnalysis": "Sound Intensity Analysis",
            "acousticPowerAnalysis": "Sound Power Analysis",
            "timeDomainODS": "Time Domain ODS",
            "frequencyDomainODS": "Frequency Domain ODS",
            "rosetteAlgorithm": "Strain Flower Calculation",
            "torqueAnalysis": "Torque Analysis",
            "shaftPowerAnalysis": "Shaft Power Analysis",
            "torsionalVibrationAnalysis": "Torsional Vibration Analysis",
            "cableForceAlgorithm": "Cable Force Analysis",
            "waveletAnalysis": "Wavelet Analysis",
            "bucklingAnalysis": "Buckling Analysis"
        },
        "cursorSettings": "Cursor Settings",
        "statisticalInformation": "Statistical Information",
        "componentProperties": "Component Properties",
        "cursorType": {
            "noCursor": "No Cursor",
            "singleCursor": "Single Cursor"
        },
        "statistics": {
            "maximumValue": "Maximum Value",
            "minimumValue": "Minimum Value",
            "rootMeanSquare": "Root Mean Square",
            "averageValue": "Average Value",
            "skewness": "Skewness",
            "kurtosis": "Kurtosis",
            "standardDeviation": "Standard Deviation",
            "peakToPeak": "Peak To Peak"
        },
        "message": {
            "paramFailed": "Parameter Acquisition Failed",
            "maxPoint32": "Add at most 32 measuring points",
            "maxEigen32": "Add at most 32 characteristic values"
        },
        "algorithm": {
            "parameterSetting": "Parameter Setting",
            "colorSetting": 'Color Setting',
            "preview": "Preview",
            "analysisPoints": "Analysis Points",
            "manualExport": 'Manual Export',
            "exportRange": 'Export Range',

            "intervalNum": "Interval Numbers",
            "statisticalType": "Statistical Type",
            "AutomaticValue": "Automatic Value",
            "statisticalLowerLimit": "Statistical Lower Limit",
            "statisticalUpperLimit": "Statistical Upper Limit",
            "intervalNumIntMsg": "The number of intervals must be an integer",
            "intervalNumRangeMsg": "The range of intervals is",
            "lowerIntMsg": "The statistical lower limit must be a number",
            "lowerRangeMsg": "The statistical lower limit range is",
            "upperIntMsg": "The statistical upper limit must be a number",
            "upperRangeMsg": "The statistical upper limit range is",
            "statisticalRule": "The statistical lower limit must be less than the statistical upper limit",
            "cableType": "Cable Type",
            "cableLength": "Cable Length",
            "cableDiameter": "Cable Diameter",
            "cableWeight": "Cable Weight",
            "elasticModulus": "Elastic Modulus",
            "fundamentalFrequencyLowerLimit": "Fundamental Frequency Lower Limit",
            "fundamentalFrequencyUpperLimit": "Fundamental Frequency Upper Limit",
            "graphType": "Graph Type",
            "magnitudeType": "Magnitude Type",
            "windowType": "Window Type",
            "septumRectum": "Septum Rectum",
            "frequencyResolution": "Frequency Resolution",
            "averageTimes": "Average Times",
            "averageMethod": "Average Method",
            "overlap": "overlap",
            "exponentialWeighting": "Exponential Weighting",
            "simulateSpeedOrNot": "Simulate Speed or not",
            "simulatedSpeed": "Simulated Speed",
            "unitSwitching": "Unit Switching",
            "cableRule1": "The cable length must be greater than 0 less than or equal to 100000000",
            "cableRule2": "The cable diameter must be greater than 0 less than or equal to 100000000",
            "cableRule3": "The weight of the cable must be greater than 0 less than or equal to 100000000",
            "cableRule4": "The elastic modulus must be greater than 0 less than or equal to 100000000",
            "cableRule5": "The lower limit of the fundamental frequency must be less than or equal to 100000000",
            "cableRule6": "The upper limit of the fundamental frequency must be greater than the lower limit",
            "cableRule7": 'The lower fundamental frequency limit of cable force analysis is out of range',
            "cableRule8": 'The upper limit of the fundamental frequency of the cable analysis is out of range',
            "estimationMethod": "Estimation Method",
            "unitConversionCoefficient": "Unit Conversion Coefficient",
            "highestSpectralLineFrequency": "Highest Spectral Line Frequency",
            "highestSpectralLineAmplitude": "Highest Spectral Line Amplitude",
            "highestSpectralLinePhase": "Highest Spectral Line Phase",
            "lowestSpectralLineFrequency": "Lowest Spectral Line Frequency",
            "lowestSpectralLineAmplitude": "Lowest Spectral Line Amplitude",
            "lowestSpectralLinePhase": "Lowest Spectral Line Phase",
            "fundamentalAmplitude": "Fundamental Amplitude",
            "fundamentalFrequency": "Fundamental Frequency",
            "bandpassUpperLimitFrequency": "Bandpass Upper Limit Frequency",
            "bandpassLowerLimitFrequency": "Bandpass Lower Limit Frequency",
            "order": "Order",
            "filterAmplitudePhaseFrequencyCharacteristics": "Filter Amplitude Phase Frequency Characteristics",
            "filteringMethod": "Filtering Method",
            "filterType": "Filter Type",
            "windowFunctionMethod": "Window Function Method",
            "upperBoundaryFrequencyOfPassband": "Upper Boundary Frequency of Passband",
            "lowerBoundaryFrequencyOfPassband": "Lower Boundary Frequency of Passband",
            "stopBandUpperBoundaryFrequency": "Stop Band Upper Boundary Frequency",
            "stopBandLowerBoundaryFrequency": "Stop Band Lower Boundary Frequency",
            "passbandCutoffFrequency": "Passband Cutoff Frequency",
            "passbandBoundaryFrequencyTip": "The passband boundary frequency is too high or too low",
            "stopBandBoundaryFrequencyTip": "Stop band boundary frequency too high or too low",
            "stopLessThanPassTip": "The stopband boundary frequency cannot be less than the passband boundary frequency",
            "stopLargerThanPassTip": "The stopband boundary frequency cannot be greater than the passband boundary frequency",
            "passTopLessThanPassBottom": "The upper boundary frequency of the passband cannot be less than the lower boundary frequency of the passband",
            "stopTopLessThanStopBottom": "The upper boundary frequency of the stopband cannot be less than the lower boundary frequency of the stopband",
            "passBottomLessThanStopBottom": "The frequency of the lower boundary of the passband cannot be less than the frequency of the lower boundary of the stopband",
            "passTopLargerThanStopTop": "The upper boundary frequency of the passband cannot be greater than the upper boundary frequency of the stopband",
            "stopBottomLargerThanPassBottom": "The lower boundary frequency of the stopband must be greater than the lower boundary frequency of the passband",
            "stopTopLargerThanPassTop": "The upper boundary frequency of the stopband must be less than the upper boundary frequency of the passband",
            "lowerBoundaryFrequencyOfPassbandTip": "The frequency of the lower boundary of the passband is too high or too low",
            "upperBoundaryFrequencyOfPassbandTip": "The upper boundary frequency of the passband is too high or too low",
            "bandpassTip": "The bandpass lower limit frequency must be less than the bandpass upper limit frequency",
            "bandpassUpperLimitFrequencyTip": "Bandpass upper limit frequency too high or too low",
            "bandpassLowerLimitFrequencyTip": "Bandpass lower limit frequency too high or too low",
            "stopBandBottomTip": "Stopper band lower boundary frequency too high or too low",
            "stopBandTopTip": "The upper boundary frequency of the stopband is too high or too low",
            "lowPass": "Low-pass",
            "highPass": "High-pass",
            "bandpass": "Bandpass",
            "bandstop": "Bandstop",
            "rectangularWindow": "Rectangular Window",
            "hanning": "Hanning",
            "haimingWindow": "Haiming Window",
            "flatTopWindow": "Flat Top Window",
            "triangularWindow": "Triangular Window",
            "blackmanWindow": "Blackman Window",
            "filterType2": "Filter Type",
            "passbandBoundaryFrequency": "Passband Boundary Frequency",
            "stopBandBoundaryFrequency": "Stop Band Boundary Frequency",
            "flatnessOfPassband": "Flatness of Passband",
            "stopbandAttenuation": "Stopband Attenuation",
            "minimumOrder": "Minimum Order",
            "butter": "Butterworth",
            "cheby": "Chebyshev",
            "highPassDCFilter": "DC and High-pass Filters",
            "lowPassFilter": "Low-pass Filter",
            "statisticalSettings": "Statistical Settings",
            "statisticalMethods": "Statistical Methods",
            "amplitudeRange": "Amplitude Range",
            "meanRange": "MeanRange",
            "minimumValue": "Minimum Value",
            "maximumValue": "Maximum Value",
            "inputMeasurementPoint": "Input Measurement Point",
            "parameterImport": "Parameter Import",
            "parameterExport": "Parameter Export",
            "groupNumber": "Group Number",
            "order": "Order",
            "strainFlowerName": "Name",
            "strainFlowerType": "Type",
            "material": "Material",
            "poissonRatio": "Poisson Ratio",
            "outputMeasurementPoint": "Output Measurement Point",
            "characteristicValue": "Characteristic Value",
            "statisticalValues": 'Statistical Values',
            "illustration": "Illustration",
            "strainFlower": "Strain Flower",
            "byWaveform": "By Waveform",
            "byCharacteristicValue": "By Characteristic Value",
            "byFrequencyDomain": 'By Frequency Domain',
            "switchConfirmMsg": "Switching will delete the original output measurement points, sure to switch?",
            "fittingData": "Fitting Data",
            "predictionDuration": "Prediction duration",
            "predictionInterval": "Prediction interval",
            "fittingMethod": "Fitting method",
            "polynomial": "Polynomial",
            "index": "Index",
            "tenMinutes": "Ten Minutes",
            "thirtyMinutes": "Thirty Minutes",
            "oneHour": "One Hour",
            "twelveHours": "Twelve Hours",
            "oneDay": "One Day",
            "threeDays": "Three Days",
            "oneWeek": "One Week",
            "oneMonth": "One Month",
            "threeMonths": "Three Months",
            "sixMonths": "Six Months",
            "oneYear": "One Year",
            "formula": "Formula",
            "customFunctions": "Custom Functions",
            "chooseFunction": "Choose function",
            "addFunction": "Add Function",
            "editFunction": "Edit Function",
            "deleteFunction": "Delete Function",
            "essentialInformation": "Essential Information",
            "runningFunctionName": "Running Function Name",
            "functionDescription": "Function Description",
            "functionContent": "Function Content",
            "compile": "Compile",
            "formulaPlaceholder": "Please enter the formula",
            "formulaErrMsg": "Formula parsing error",
            "parsing": "Parsing",
            "editing": "Editing",
            "compileSuccess": "Compile Success",
            "savePointMsg": "Please select the measurement point to save",
            "fixedTime": 'Fixed time',

            "torvibPoint": 'Tor-vib Point',
            "torvibPointRule": 'Please check points with the same sampling frequency',

            "shaftInnerDiameter": 'Shaft inner diameter',
            "shaftOuterdiameter": 'Shaft outer diameter',
            "outputUnit": 'Output unit',

            "speedChannel": "speed channel",
            "speedChannelTip": "Please select the speed channel",
            "residualStressName": "Residual stress name",
            "residualStressNumber": "Residual stress number",
            "plasticStrain": "Plastic strain",
            "strainDistance": "The distance between the hole center and the center of the strain gauge",
            "releaseFactor": "Release factor",
            "holeRadius": "Hole radius",
            "strainGaugesCloseRange": "Strain gauges close range",
            "strainGaugesLongRange": "Strain gauges long range",
            "strainGageGridHalfWidth": "Strain gage grid half width",
            "strainGageGridHalfLength": "Strain gage grid half length",

            "correct": "Correct",
            "eccentricCoordinates": "Eccentric coordinates",
            "known": "Known",
            "unknown": "Unknown",
            "automaticScale": "Automatic Scale"
        },
        "template": {
            "attributeSettings": " Attribute Settings",
            "graphParam": "Graph Parameters",
            "algorithmParam": "Algorithm Parameters",
            "alarmParam": "Alarm Parameters",
            "saveConfig": "Storage Settings",
            "unSaveTitle": 'List of incomplete inspection tasks',
            "moveBoard": 'Move board'
        },
        "save": {
            "isStoreWaveform": "Whether to store waveforms",
            "isStoreAlarm": "Whether to store alarms"
        },
        "templateBrowse": {
            "alarmDetails": "Alarm Details",
            "alarmDevice": 'Alarm Device',
            "devicePath": "Device Path",
            "alarmClassification": "Alarm Classification",
            "severity": "Severity",
            "offlineDetails": "Offline Details",
            "number": "Number",
            "abnormalDataDetails": "Abnormal Data Details",
            "barChartSettings": "Bar Chart Settings",
            "lineChartSettings": "Line Chart Settings",
            "unlock": "Unlock",
            "lock": "Lock",
            "setBackground": "Set as Background",
            "setComponent": "Set as Component",
            "setMark": "Set Mark",
            "setTitle": "Set Title",
            "reportProperty": "Report Property Settings",
            "markEnter": "Please enter a mark name",
            "markEdit": "Modify Mark Name",
            "markLimit": "The mark name cannot exceed 20 Characters",
            "titleEnter": "Please enter a title name",
            "titleEdit": "Modify Title Name",
            "titleLimit": "The title name cannot exceed 20 Characters",
            "pieChartSettings": "Pie Chart Settings",
            "offlineDeviceDetails": "Offline Device Details",
            "samplingTime": "Sampling Time",
            "videoParam": "Video Parameters",
            "videoEdit": "Video Editing"
        },
        "vue": {
            "maximize": "Maximize",
            "reduction": "Reduction",
            "close": "Close",
            "move": 'Move',
            "billboard": "Dashboard",
            "deleteAllGraphTip": "Confirm the deletion of all graphs under the current dashboard？",
            "removeTip": "Deleting the current dashboard will also delete all the maps under the current dashboard, are you sure?",
            "limitBillboardTip": 'Up to 10 dashboards exist',
            "expandNavigation": "Expand Navigation",
            "nextBatch": 'Next Batch',
            "preBatch": 'Last Batch',
            "collapseNavigation": "Collapse Navigation",
            "deviceList": "Device List",
            "pointList": "List of Measuring Points",
            "eigenList": "Characteristic List",
            "decimalPlaces": "Decimal Places",
            "fontSize": "Font Size",
            "dataExport": "Data Export",
            "exportMeasurementPoints": "Export measurement points",
            "exportFormat": "Export Format",
            "storageMethod": "Storage Method",
            "exportScope": "Export Scope",
            "current": "Current",
            "absoluteTime": "Absolute Time",
            "outputTime": "Output Time",
            "outputFrequency": 'Output Frequency',
            "startTime": "Start Time",
            "xAxisDecimalPlaces": "X Axis Decimal Places",
            "yAxisDecimalPlaces": "Y Axis Decimal Places",
            "dataSegmentSeparator": "Data Segment Separator",
            "otherDelimiter": "Other Delimiter",
            "exportItems": "Export Items",
            "pointPath": 'Point Path',
            "selectPointMsg": "Please select a measuring point",
            "noPointMsg": "No measurement point selected or selected measurement point with no eigenvalues",
            "enterSeparatorMsg": "Please enter a separator character",
            "separatorLimitMsg": "The length of the separator cannot exceed 10 characters",
            "selectTimeMsg": "Please select a time",
            "timeLimitMsg": "The start time must be less than the end time",
            "pointLabel": "Measurement Point Label",
            "videoLabel": "Video Label",
            "navigationLabel": "Navigation Label",
            "multiPointLabel": "Multiple Measurement Point Label",
            "trendLabel": "Trend Chart Label",
            "narrow": "Narrow",
            "enlarge": "Enlarge",
            "navigationSettings": "Navigation Settings",
            "navigationGraphPropertySettings": "Navigation Graph Property Settings",
            "dataInput": "Data Input",
            "timeSettings": "Time Settings",
            "endTimeType": "End Time Type",
            "endTime": "End Time",
            "singleNavigationCursor": "Single",
            "dualNavigationCursor": "Dual",
            "timeRangeTooLarge": "Time span too large",
            "timeRangeGreaterZero": "The time length needs to be greater than 0",
            "pointLabelList": "Point Label List",
            "noLabel": "No labels currently available",
            "pointChoose": "Measurement Point Selection",
            "showEigen": "Display Characteristic Values",
            "sigleEigen": "Single Eigenvalue",
            "multiEigen": "Multiple Eigenvalues",
            "deviceName": "Device Name",
            "pointName": "Measurement point name",
            "displayParamName": "Display Parameter Name",
            "eigenType": "Eigen Type",
            "unit": "Unit",
            "newLabel": "New Label",
            "videoLabelList": "Video Label List",
            "optionSettings": "Option Settings",
            "cameraName": "Camera Name",
            "jumpLink": "Jump Link",
            "displayPoint": "Display measuring point",
            "displayParameterName": "Extra Name",
            "localAmplification": "Local Amplification",
            "addPoint": "Add measuring points",
            "addComponent": "Add Component",
            "view": "View",
            "hideGrid": "Hide Grid",
            "showGrid": "Show Grid",
            "threeDView": "3D View",
            "fourViews": "Four Views",
            "bottomView": "Bottom View",
            "verticalView": "Vertical View",
            "rearView": "Rear View",
            "rightView": "Right View",
            "frontView": "Front View",
            "leftView": "Left View",
            "point": "Measure Point",
            "registerType": 'Data Register Type',
            "useOrgin": 'Wave Data',
            "useBlock": 'Eigen Data',
            "refreshFreq": 'Refresh Frequency(Hz)'
        }
    },
    "dataInput": {
        "modelName": "Model Name",
        "scaleOnXAxis": "X-axis decimal places",
        "scaleOnYAxis": "Y-axis decimal places",
        "numberOfXAxisGrids": "Number of X-axis grids",
        "numberOfYAxisGrids": "Number of Y-axis grids",
        "cursor": "Cursor",
        "type": "Type",
        "displayItems": "Display items",
        "curveinfo": "Curve infomation",
        "alarmLineStatistics": "Alarm line statistics",
        "maximumValue": "MaximumValue",
        "minimumValue": "MinimumValue",
        "rootMeanSquare": "Root mean square",
        "averageValue": "Average value",
        "totalRmsValue": 'Total Rms Value',
        "skewness": "Skewness",
        "kurtosis": "Kurtosis",
        "standardDeviation": "Standard deviation",
        "peakToPeak": "Peak to peak",
        "numberOfPoints": "Number of points",
        "xAxisSettings": "X-axis settings",
        "displayMethod": "Display method",
        "displayByTime": "Display by time",
        "displayByDate": "Display by date",
        "displayOnTimeMinutesAndSeconds": "Display on time,minutes and seconds",
        "displayBySeconds": "Display by seconds",
        "trajectoriesChart": "Trajectories chart",
        "waveformChart": "Wave form chart",
        "rawSampling": "Raw sampling",
        "synthesisRate": "Synthesis rate",
        "statisticInfo": "Statistic infomation",
        "absoluteTime": "Absolute time",
        "relativeTime": "Relative time",
        "noCursor": "No cursor",
        "singleCursor": "Single cursor",
        "doubleCursor": "Double cursors",
        "threeLightMarkers": "Triple cursors",
        "fourCursors": "Quadruple cursors",
        "peakCursor": "Peak cursor",
        "valleyCursor": "Valley cursor",
        "edgeFrequencyCursor": "Edge frequency cursor",
        "areaCursor": "Area cursor",
        "navigationCursor": "Navigation cursor",
        "dualNavigationCursor": "Dual navigation cursor",
        "markCursor": "Mark cursor",
        "barChartType": "Bar chart type",
        "barChart": "Bar chart",
        "fixedViewColumns": "Fixed view columns",
        "numberOfViewColumns": "Number of view columns",
        "scaleType": "Scale type",
        "byRange": "By range",
        "custom": "Custom",
        "scaleRange": "Scale range",
        "offlineCannotExceedOnline": "Offline cannot exceed online",
        "coordinatePoints": "Coordinate points",
        "waveformCursor": "Wave form cursor",
        "trajectoryCursor": "Trajectory cursor",
        "cursorFollow": "Cursor follow",
        "characteristicValueList": "Characteristic value list",
        "theMeasurementPointNameCannotContain": "The measurement point name cannot contain",
        "differentSamplingRatesCheck": "The sampling frequency of the current measurement point is different from the selected measurement point. Clicking OK will delete the selected measurement point. Are you sure to check it?",
        "differentSamplingRatesCheckSameSamplingPoints": "If the sampling frequency of the measuring points is inconsistent, selecting all will automatically check the same sampling frequency and measuring points with the same sampling frequency. Are you sure to check?",
        "fundamentalFrequency": "Fundamental frequency",
        "objectIsSame": "To determine if the objects are the same",
        "output": "Output",
        "move": "Move",
        "realCepstrum": "Real cepstrum",
        "complexCepstrum": "Complex cepstrum",
        "dataInput": "Data input",
        "pointData": "Measurement point data",
        "externalData": "External data",
        "uploadMsg": "Drag and drop the file here, or ",
        "uploadClick": "Click on upload",
        "decimalPlaces": "Decimal places",
        "numberOfSegmentedSegments": "Number of segmented segments",
        "fontSize": "Font size",
        "dashboard": "Dashboard",
        "displayType": "Display type",
        "oneQuarter": "One quarter",
        "half": "A half",
        "threeQuarter": "Three quarter",
        "circular": "Circular",
        "deviceSelection": "Device selection",
        "pointSelection": "Point selection",
        "algorithmParameters": "Algorithm parameters",
        "fixedDisplayDigits": "Fixed display digits",
        "displayNumberOfDigits": "Display number of digits",
        "showSubtitles": "Show subtitles",
        "point": "Measure point",
        "eigenvalue": "Eigen value",
        "unit": "Unit",
        "numberOfCircles": "Number of circles",
        "rainFlowType": "Rain flow type",
        "oneDimensionalRainFlowCounting": "One dimensional rain flow counting",
        "twoDimensionalRainFlowCounting": "Two dimensional rain flow counting",
        "alarmLine": "Alarm line",
        "cepstrumAnalysisAndCorrelationAnalysisOfSpectralParameters": "Cepstrum analysis and correlation analysis of spectral parameters. The X-axis represents time, but the configuration of spectral parameters is presented in digital form",
        "xAxisType": "X-axis type",
        "yAxisType": "Y-axis type",
        "referenceValueType": "Reference value type",
        "referenceValue": "Reference value",
        "linearCoordinates": "Linear coordinates",
        "logarithmicCoordinates": "Logarithmic coordinates",
        "coordinates": "Coordinates",
        "soundPressure": "Sound pressure",
        "acceleration": "Acceleration",
        "speed": "Speed",
        "displacement": "Displacement",
        "power": "Power",
        "theReferenceValueMustBeGreaterThan": "The reference value must be greater than",
        "inputPoint": "Input measurement point",
        "selectTheStatisticalDurationAndCharacteristicValues": "Please select the statistical duration and characteristic values",
        "statisticalDuration": "Statistical duration",
        "minute": "Minute",
        "hour": "Hour",
        "date": "Date",
        "cumulativeValue": "Cumulative value",
        "theNumberOfIntervalsMustBe": "The number of intervals must be",
        "positiveInteger": "Positive integer",
        "groupNumber": "Group number",
        "unchecked": "Unchecked",
        "give": "Give",
        "validValue": "Valid value",
        "variance": "Variance",
        "kurtosisCoefficient": "Kurtosis coefficient",
        "marginCoefficient": "Margin coefficient",
        "pulseFactor": "Pulse factor",
        "crestFactor": "Crest factor",
        "waveformFactor": "Wave form factor",
        "absolutePeak": "Absolute peak",
        "equivalentPeakValue": "Equivalent peak value",
        "latestValue": "Latest value",
        "checkTheInputMeasurementPoint": "Please check the input measurement point",
        "checkFeatureValues": "Please check the characteristic value",
        "formulaIsEmptyOrUnresolved": "Formula is empty or unresolved",
        "noMeasurementPointsEntered": "No measurement points entered",
        "selectCharacteristicValue": "Please select a characteristic value",
        "selectTheInputMeasurementPoint": "Please select the input measurement point",
        "waveform": "Wave form",
        "frequencyCapping": 'Frequency Capping',
        "lowerFrequency": 'Lower Frequency'
    },
    "calendar": {
        "Sun": "Sun",
        "Mon": "Mon",
        "Tue": "Tue",
        "Wed": "Wed",
        "Tur": "Tur",
        "Fri": "Fri",
        "Sat": "Sat",
        "JAN": "JAN.",
        "FEB": "FEB.",
        "MAR": "MAR.",
        "APR": "APR.",
        "MAY": "MAY.",
        "JUN": "JUN.",
        "JUL": "JUL.",
        "AUG": "AUG.",
        "SEP": "SEP.",
        "OCT": "OCT.",
        "NOV": "NOV.",
        "DEC": "DEC.",
        "year": "-",
        "month": "-",
        "day": " ",
        "week": "",
        "title": "Alarm Details",
        "path": "Device Path",
        "alarmLevleStr": "Alarm level",
        "alarmTypeStr": "Alarm type",
        "alarmModelStr": "Alarm classification",
        "confirmDegreeStr": "Severity",
        "openTitle": 'Alarm switch status'
    },
    "copyRight": {
        "title": "Copyright Information",
        "edition": "Software edition：",
        "authorizedVersion": "Authorized Version: ",
        "authorizationTime": "Authorization Time: ",
        "day": "Day(s)",
        "unlimited": "Unlimited",
        "availableDuration": "Available Duration: ",
        "timeRange": "©2019-Now",
        "companyName": "Jiangsu Donghua Testing Technology Co., Ltd",
        "allRightsReserved": "All rights reserved",
        "address": "Adress: Xingang Avenue 208, Jingjiang City, Jiangsu Province, China(214500)",
        "tel": "Telephone: ",
        "email": "Email: ",
        "website": "Website: ",
        "waringmsg1": "Warning: This computer program is protected by copyright law and international treaties, Unauthorized reproduction or dissemination of this program(or any part thereof), will face severe civil and criminal sanctions,",
        "waringmsg2": "And will be prosecuted to the maximum extent permitted by law.",
        "driver": "The version number of the driver："
    },
    "editBox": {
        "emptyAlert": "Please enter a name first",
        "nameLimit": "The name cannot exceed 100 characters",
        "nameSpaceTip": 'Name cannot be empty',
        "contentLimit": "The content must not exceed 10000 characters",
        "commaTip": 'Name cannot contain ,'
    },
    "modeBase": {
        "graphType": "Graph Type:",
        "outputMeasurementPoints": "Output Points:",
        "noModel": "There is currently no model available",
        "delMsg": "Are you sure to delete the model",
        "delPoint": 'Are you sure to delete the point'
    },
    "pointChoose": {
        "newPoint": "New Measuring Points",
        "deviceList": "Device List",
        "organization": "Organization Structure",
        "pointList": "Measuring Points List",
        "patrolPoint": "Patrol Inspection Points",
        "noPoint": "There are currently no measurement points available"
    },
    "warningLicense": {
        "text": "The current system has not been authorized yet, please try again or",
        "link": "Go"
    },
    "ampAnalysis": {
        "probabilityStatistics": "Probability Statistics",
        "probabilityDensity": "Probability Density",
        "cumulativeProbability": "Cumulative Probability"
    },
    "cableForceAlgorithm": {
        "fundamentalFrequency": "Fundamental Frequency",
        "cableForce": "Cable Force",
        "trend": "Trend",
        "spectrum": "Spectrum",
        "establishPointMsg": "Please establish a measuring point",
        "archBridge": "Arch Bridge",
        "cableStayedBridge": "Cable-stayed Bridge",
        "suspensionBridgeMain": "Main Cable of Suspension Bridge",
        "suspensionBridgeSling": "Suspension Bridge Sling"
    },
    "correlation": {
        "noCorrection": "No Correction",
        "biasedEstimation": "Biased Estimation",
        "unbiasedEstimation": "Unbiased Estimation",
        "normalization": "Normalization"
    },
    "envelopeJS": {
        "upperEnvelope": "UpperEnvelope",
        "lowerEnvelope": "LowerEnvelope"
    },
    "fftGraph": {
        "realtimeSpectrum": "Realtime Spectrum",
        "averageSpectrum": "Average Spectrum",
        "amplitudeSpectrum": "Amplitude Spectrum",
        "effectiveValueSpectrum": "Effective Value Spectrum",
        "powerSpectrum": "Power Spectrum",
        "RMSPowerSpectrum": "RMS Power Spectrum",
        "powerSpectralDensity": "Power Spectral Density",
        "RMSPowerSpectralDensity": "RMS Power Spectral Density",
        "radicalPowerSpectralDensity": "Radical Power Spectral Density",
        "undividedRectum": "Undivided Rectum",
        "linearAverage": "Linear Average",
        "peakHold": "Peak Hold",
        "exponentialAverage": "Exponential Average"
    },
    "orderAlgorithm": {
        "oreder": "Order"
    },
    "rainflowAnalysis": {
        "conventionalCount": "Conventional Rain Flow Count"
    },
    "trendForecast": {
        "establishPointMsg": "Please establish a measuring point"
    },
    "waveAnalysisJS": {
        "title": "Waveform Analysis"
    },
    "alarmCalendarJS": {
        "title": "Alarm Calendar"
    },
    "alarmRecordJS": {
        "title": "Alarm Record"
    },
    "alarmStatusJS": {
        "title": "Alarm Status"
    },
    "countStaticsJS": {
        "title": "Quantity Statistics",
        "abnormalData": "Abnormal Data",
        "pointNum": "Number of Measuring Points",
        "onlineNum": "Online Quantity",
        "offlineNum": "Offline Quantity",
        "dailyAccrual": 'Daily Accrual',
        "monthlyAccrual": 'Monthly Accrual',
        "total": 'Ttotal total',
    },
    "healthStatusJS": {
        "title": "Health Level",
        "healthy": "Healthy",
        "fault": "Fault"
    },
    "histogramJS": {
        "title": "Histogram"
    },
    "imageComponentJS": {
        "title": "Picture"
    },
    "lineChartJS": {
        "title": "Line Chart",
        "seriesName": "Fault Statistics",
        "seriesName2": 'Inspection alarm statistics'
    },
    "mapComponentJS": {
        "title": "Beijing",
        "componentTitle": "Map",
        "addressMsg": "Please enter the address"
    },
    "pieChartComponentJS": {
        "title": "Alarm Level Statistics",
        "levelHealthy": "Healthy",
        "levelNormal": "Normal",
        "levelWarning": "Warning",
        "levelDanger": "Danger",
        "offline": "Offline",
        "online": "Online",
        "seriesName": "Measurement Point Equipment Statistics",
        "total": "total"
    },
    "reportComponentJS": {
        "title": "Report Form"
    },
    "runHealthJS": {
        "title": "Health Status"
    },
    "statusStatisticsJS": {
        "title": "Health Status"
    },
    "threeDComponentJS": {
        "title": "3DModel",
        "noMultipleModelsMsg": "Cannot select multiple models",
        "requireModelMsg": "Model file must be selected",
        "loading": "Parsing Model ..."
    },
    "titleComponentJS": {
        "title": "Intelligent Equipment Maintenance Management Platform Based on Fault Diagnosis and Health Management"
    },
    "trendStatisJS": {
        "title": "Trend Statistics"
    },
    "videoComponentJS": {
        "title": "Video Surveillance",
        "accessTokenError": 'Get AccessToken Failed！',
        "ezopenError": "ezopen get error！",
        "deviceSerialCannotBeNull": "The Device Serial Cannot be Empty!",
        "appKeyCannotBeNull": "AppKey Cannot be Empty！",
        "appSecretCannotBeNull": "AppSecret Cannot be Empty！",
        "channelNoCannotBeNull": "The Channel Number Cannot be Empty！",
        "secretKeyCannotBeNull": "The SecretKey Cannot be Empty！",
    },
    "axisPosition": {
        "title": "Axis Position Diagram",
        "horizontal": "Horizontal Direction",
        "vertical": "Vertical Direction"
    },
    "axisTrail": {
        "title": "Axis Trajectory Diagram",
        "waveGraphTitle": "Waveform"
    },
    "barGraph": {
        "title": "Bar Chart"
    },
    "bodePlotsGraph": {
        "title": "Bode Diagram",
        "amplitude": "Amplitude",
        "phase": "Phase"
    },
    "cascadeGraph": {
        "title": "Cascading Diagram"
    },
    "dashBoard": {
        "title": "Dashboard"
    },
    "numberTable": {
        "title": "Digital Meter"
    },
    "panelGraph": {
        "title": 'Panel',
        "componentBackgroundColor": 'Component background color',
        "numBackgroundColor": 'Numeric background color',
        "numColor": 'Numeric color',
        "textColor": 'Text color',
        "textSize": 'Text size',
        "numSize": 'Number size',
        "followTheme": "Follow the theme settings",
        "deviceFilter": "Device filtering",
        "showMode": 'Show mode',
        "upDown": 'Up and down mode',
        "leftRight": 'Left and right mode'
    },
    "polarGraph": {
        "title": "Polar Plot"
    },
    "spectrumGraph": {
        "title": "Spectrogram"
    },
    "torvib": {
        "title": 'Torsional Vibration Monitoring',
        "torsionalVibrationMode": 'Torsional vibration mode of direct connection shafting',
        "frequencyValue": 'Frequency Value',
    },
    "trendGraph": {
        "title": "Trend Chart",
        "establishPointMsg": "Please establish a measuring point",
        "trendData": "Trend Chart Data"
    },
    "waterfallGraph": {
        "title": "Waterfall Plot"
    },
    "waveGraph": {
        "title": "Waveform",
        "upperEnvelope": "UpperEnvelope",
        "lowerEnvelope": "LowerEnvelope",
        "noQueryData": "Unable to query data"
    },
    "recordGraph": {
        "title": 'Recorder Chart',
    },
    "wholePeriodGraph": {
        "title": "Whole Cycle Waveform",
        "circle": "Circles"
    },
    "labels": {
        "maxGraphNumMsg": "Add at most 32 Graphs"
    },
    "svgOperator": {
        "newPoint": "New Measuring Point"
    },
    "appearance": {
        "showTitle": "Displays the component title",
        "titleAlign": "Heading alignment",
        "showBorder": "Displays the component border",
        "left": "left",
        "center": "center",
        "showLine": "Displays the label wire",
        "showDot": "Show label points",
        "useUserLayout": "Component Layout",
        "shared": "Shared",
        "user": "By User",
        "changeLayoutMsg": "Change component layout will result in current layout changes, sure to switch?",
        "cursorPixels": "Cursor calibration number",
        "pixels": "pixels",
        "alarmConfig": "Alarm setting",
        "valueAlarm": "By value",
        "statusAlarm": "By status",
    },
    "udp165": {
        "serviceSetting": "Service configuration",
        "sending": "Frequency of sending",
        "systemCode": "System code",
        "unit": "times/s",
        "latestValue": "Latest value",
        "systemCodeTip": "The system code only supports numbers",
        "systemCodeLimitTip": "The maximum system code is 2147483647",
    },
    "systemMode": {
        "title": 'System mode',
        "userMode": 'User mode',
        "allMode": 'Global mode',
    },
    "faultCaseLibrary": {
        "title": 'Fault case library',
        "caseLibrary": 'Case library',
        "joinCase": 'Are you sure you want to join the case library？',
        "reportTaskName": 'Report task name',
    },
    "timing": {
        "title": 'timing',
        "fontSize": 'Text font size',
        "numSize": 'Numeric font size',
        "timingSetting": 'Timing settings',
        "autonomousTiming": 'Autonomous timing',
        "notTimed": 'Not timed',
        "timingStartValue": 'Timing start value',
        "selectPointTip": 'Please select a measurement point',
        "selectEigenTip": 'Please select a feature value',
        "showName": 'Displays the timing name',
        "cumulative": 'cumulative timing',
        "reset": 'reset',
        "S": 'second',
        "M": 'minute',
        "H": 'hour',
        "D": 'day',
        "MS": 'minute:second',
        "HMS": 'hour:minute:second',
        "HM": 'hour:minute',
        "DHMS": 'day hour:minute:second',
        "DHM": 'day hour:minute',
        "DH": 'day hour',
    },
    "titleGraph": {
        "title": 'title',
        "titleText": 'Title text',
        "backgroundColor": 'Background color',
        "textColor": 'Text color',
        "fontSize": 'Text font size'
    },
    "valveStatus": {
        "title": 'Valve status',
        "name": 'Valve name',
        "fontSize": 'Text font size',
        "controlMode": 'Control mode',
        "singleChannel": 'Single-channel',
        "dualChannel": 'Dual-channel',
        "controlParameters": 'Control parameters',
        "channel1": 'Channel 1',
        "channel2": 'Channel 2',
        "selectPointTip": 'Please select a channel and a feature value',
        "pointDefferent": 'Please select a different associated measurement point',
        "panelValve": 'Valve status panel'
    },
    "valvePanelStatus": {
        "title": 'Valve status panel',
        "list": "List of valves",
        "param": "Valve parameters",
        "colorSet": "set the color individually",
        "name": "Valve",
        "repeatTip": "There must be no duplicate valve names"
    },
    "graphicalDisplay": {
        "title": 'Graphical display'
    },
    "tableGraph": {
        "rowNums": 'Row count',
        "colNums": 'Column count',
        "alignType": 'alignment',
    },
    "alarmValid": {
        "mutationAlarmTip": 'The reference value must be increased in order of alarm level',
        "ampMoreTip": 'The reference value of the overrun must be increased according to the alarm level',
        "ampLessTip": 'The low reference value must be reduced in order according to the alarm level',
        "inWindowTip": 'The amplitude alarm parameters in the window should be satisfied',
        "outWindowTip": 'The alarm parameters of the amplitude outside the window should be satisfied',
        "overLimit": 'overLimit',
        "belowLimit": 'belowLimit'
    },
    "alarmLevelConfig": {
        "addLevel": "Add Level",
        "levelTip": "Some features require a page refresh to update the alarm color",
        "severity": "Severity",
        "severityTip": "The larger the value, the more serious it is",
        "levelName": "Level Name",
        "alarmColor": "Alarm Color",
        "soundFile": "Sound File",
        "preview": "test"
    },
    "departSetting": {
        "title": "Device tree settings",
        "departType": "Depart type",
        "addDeartType": "Add department type",
        "editDepartType": "Edit department type",
        "association": "Type",
        "departTip": "All the selected nodes already exist"
    },
    "monitoring": {
        "title": 'Scope of monitoring',
        "statisticsNodes": "Statistics nodes",
        "textBackground": "Text background color",
        "associateNodes": "Associate nodes",
        "showName": "Show name",
        "nodeRepeatTip": "There are duplicate associated nodes",
        "nameRepeatTip": "There are duplicate display names",
        "nodeBlankTip": "Please select the associated measurement point",
        "nameBlankTip": "Please fill in the display name",
        "unitBlankTip": "Please fill in the unit",
        "limitTip": "A maximum of 100 statistics nodes can be added",
        "inputLimitTip": "Up to 10 characters",
        "statisticalItems": "Statistical items",
        "selectStatisticalTip": "Please select a statistic item",
        "pointNum": "The total number of points",
        "deviceSuffix": "class device"
    },
    "deviceLevel": {
        "title": "Device rating",
        "addDeviceLevel": "Add a device level",
        "editDeviceLevel": "Edit the device level",
    },
    "deviceDepartSetting": {
        "title": "Depart/Device config",
        "departLable": "The department information displays the department type",
        "deviceLable": "The device information shows the device rating"
    },
    "layout": {
        "align": "Align",
        "leftAligned": "Left-aligned",
        "horizontallyCentered": "Horizontally centered",
        "rightAligned": "Right-aligned",
        "horizontallyDistribution": "Horizontally distribution",
        "topAlignment": "Top-aligned",
        "verticallyCentered": "Vertically centered",
        "bottomAlignment": "Bottom-aligned",
        "verticallyDistribution": "Vertically distribution",
        "matching": "matching",
        "widthMatching": "Width matching",
        "heightMatching": "Height matching",
        "widthHeightMatching": "Width and height matching",
        "layer": "layer",
        "layout": "layout"
    }
}

export default EN