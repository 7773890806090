import { guid } from "@common/js/utils";
import WebChart from "@core/graphs/webcharts/Dh-webchart-charts";
import { CoordinateInfo, ChartInformation } from "@core/graphs/webcharts/Dh-webchart-coordinates";
import { ScaleType } from "@core/graphs/webcharts/Dh-webchart-models";
import { TableCoordinateSystem } from "@core/graphs/coords/Dh-coordsystem-base";
import { LineShapeOperator } from "../shapes/Dh-shape-line";

export class AnalysisChart {
    constructor() {
        this.WebChart = null;
        this.Option = null;
    }
    Init(option, plugins) {
        this.Option = option;
        let coorSys = this.GetCoordinateSystem();
        let information = this.GetInformation();
        let shape = this.GetShapeOperator();
        this.WebChart = new WebChart(coorSys, shape, information);
        this.WebChart.Init(option.ChartId);
        this.WebChart.SetOption(option);
        this.WebChart.setPlugin(plugins);
        this.OnChartChanged();
        this.InitInformation(information);
    }
    InitInformation(information) {
        if (this.Option.CoordInfo && information.CoordinateInfo) {
            information.CoordinateInfo.IsEnlarge = this.Option.CoordInfo.IsEnlarge;
            information.CoordinateInfo.XMinRate = this.Option.CoordInfo.XMinRate;
            information.CoordinateInfo.XMaxRate = this.Option.CoordInfo.XMaxRate;
            information.CoordinateInfo.YMax = this.Option.CoordInfo.YMax;
            information.CoordinateInfo.YMin = this.Option.CoordInfo.YMin;
            information.CoordinateInfo.YRate = this.Option.CoordInfo.YAxisRate == 0 ? 1 : this.Option.CoordInfo.YAxisRate;
        }
    }
    GetCoordinateSystem() {
        return new TableCoordinateSystem(guid());
    }
    GetInformation() {
        const info = new ChartInformation();
        info.CoordinateInfo = new CoordinateInfo(100, 0, 1000, -1000, info);
        return info;
    }
    GetShapeOperator() {
        return new LineShapeOperator();
    }
    SetData(datas) {
        if (datas.length === 0) {
            this.WebChart.Information.UnitTexts.XText = "";
            this.WebChart.SetData(datas);
        } else {
            const range = this.getCoordinateRange(datas);
            if (range.xmax === range.xmin) range.xmax += 100;
            let coord = null;
            let hasPrex = datas.find(r => this.CheckData(r.PointId)) != null;
            if (hasPrex) {
                coord = this.UpdateDataCoords(range.ymax, range.ymin);
            } else {
                coord = this.UpdateCoords(range.ymax, range.ymin, datas[0].FullValue);
            }
            this.WebChart.Information.UpdateCoordinateInfo(range.xmax, range.xmin, coord.ymax, coord.ymin);
            this.WebChart.Information.UnitTexts.XText = datas[0].XUnit;
            this.WebChart.SetData(datas);
        }
    }
    getCoordinateRange(datas) {
        const range = {
            xmax: null,
            xmin: null,
            ymax: null,
            ymin: null
        };
        for (const data of datas) {
            if (range.xmax == null || range.xmax <= data.XMax) range.xmax = data.XMax;
            if (range.xmin == null || range.xmin >= data.XMin) {
                if (data.XMin != null) range.xmin = data.XMin;
            }
            if (data.YMax != null && (range.ymax == null || range.ymax <= data.YMax)) range.ymax = data.YMax;
            if (data.YMin != null && (range.ymin == null || range.ymin >= data.YMin)) range.ymin = data.YMin;
        }
        if (range.ymax == null || range.ymin == null || range.xmax == null || range.xmin == null) {
            this.ClearData();
            const coordInfo = this.WebChart.Information.CoordinateInfo;
            range.ymax = range.ymax || coordInfo.YMax;
            range.ymin = range.ymin || coordInfo.YMin;
            range.xmax = range.xmax || coordInfo.XMax;
            range.xmin = range.xmin || coordInfo.XMin;
        }
        return range;
    }
    CheckData(pointId) {
        if (typeof (pointId) == "string") return true;
        let id = parseInt(pointId);
        let hex = id.toString(16).toUpperCase();
        if (hex.length < 6) return pointId > 131072;
        let target = hex.substring(hex.length - 6);
        return parseInt(target, 16) > 131072;
    }
    UpdateCoords(ymax, ymin, fullValue) {
        if (this.WebChart.Option.Axis.AxisScaleType == ScaleType.Auto) {
            const result = this.GetCoords(ymax, ymin, fullValue);
            return { ymax: result.max, ymin: result.min };
        }
        if (ymax === ymin) ymax += 100;
        return { ymax, ymin };
    }
    UpdateDataCoords(ymax, ymin) {
        if (this.WebChart.Option.Axis.AxisScaleType == ScaleType.Auto) {
            let step = Math.abs(ymax - ymin) * 0.1;
            step = step == 0 ? 100 : step;
            ymax += step;
            ymin -= step;
        }
        if (ymax === ymin) ymax += 100;
        return { ymax, ymin };
    }
    GetCoords(max, min, fullValue) {
        let deta = Math.abs(max - min);
        let spacing = deta * 0.1;
        if (deta < 1e-10) {
            if (Math.abs(max) < 1e-10) {
                spacing = 0.1;
            } else {
                spacing = Math.abs(max) * 0.1;
            }
        }
        let dbMiddle = (max + min) / 2;
        let dbRatio = (max - min) / 2;
        let dbCoief = 0.0078125;
        let dbCheckMin = Math.abs(fullValue * dbCoief);
        if (dbCoief > 1e-10 && dbCheckMin > 1e-10) {
            if (Math.abs(dbRatio) < dbCheckMin) {
                max = dbMiddle + dbCheckMin;
                min = dbMiddle - dbCheckMin;
            }
            else {
                max += spacing;
                min -= spacing;
            }
        }
        else {
            max += spacing;
            min -= spacing;
        }
        const maxMin = this.CheckCoordMinMax(min, max);
        return this.GetAutoMaxMinScale(maxMin.min, maxMin.max);
    }
    CheckCoordMinMax(min, max) {
        if (max < min) {
            let dbTmp = max;
            max = min;
            min = dbTmp;
        }
        let dbUpper = Math.pow(10.0, 15);
        //	数据下限
        let dbLower = 0.0 - dbUpper;
        //	数据精度
        let dbExponent = 1e-3;

        //	判断是否相等
        if (min == max) {
            max = (max == 0) ? (1.0) : (min + Math.abs(min) * 0.1);
        }
        //	判断最大最小值是否超出上下限，并确保两个数据的差值不小于数据精度值
        if (min < dbLower) {
            min = dbLower;
            max = (max - min < dbExponent) ? (min + dbExponent * 10.0) : (max);
        }
        else if (max < dbLower) {
            min = dbLower;
            max = min + dbExponent * 10.0;
        }
        else if (max > dbUpper) {
            max = dbUpper;
            min = (max - min < dbExponent) ? (max - dbExponent * 10.0) : (min);
        }
        else if (min > dbUpper) {
            max = dbUpper;
            min = max - dbExponent * 10.0;
        }
        return { min, max };
    }
    GetAutoMaxMinScale(min, max) {
        let minTemp = min;
        let maxTemp = max;
        //	最大最小值求差
        let delta = maxTemp - minTemp;
        if (maxTemp < minTemp) {
            let dbTmp = maxTemp;
            maxTemp = minTemp;
            minTemp = dbTmp;
        }
        let dbTenth = 0.0;
        if (delta > 1e-7) {
            dbTenth = Math.pow(10.0, this.GetScaleEntries(delta));//	量级下边界

            //	确保网格线数在10 - 15之间
            dbTenth = this.ModifyPrecision(delta, dbTenth);

            let nCount = 0;
            let dbCount = 0;
            //	判断是否修改最小刻度值
            dbCount = minTemp / dbTenth;
            nCount = dbCount;
            if (dbCount != nCount) {
                nCount = (nCount < dbCount) ? (nCount) : (nCount - 1);
                min = dbTenth * nCount;
            }

            //	判断是否修改最大刻度值
            dbCount = maxTemp / dbTenth;
            nCount = dbCount;
            if (dbCount != nCount) {
                nCount = (nCount > dbCount) ? (nCount) : (nCount + 1);
                max = dbTenth * nCount;
            }
        }
        return { max, min };
    }
    GetScaleEntries(dbData) {
        if (dbData < Math.pow(10.0, -10))
            return -10;
        let iExponent = -10;
        while (dbData < Math.pow(10.0, iExponent) || dbData >= Math.pow(10.0, iExponent + 1)) {
            iExponent += 1;
            if (iExponent > 100) {
                break;
            }
        }
        return iExponent;
    }
    ModifyPrecision(dbDelta, dbTenth) {
        let nCount = parseInt((dbDelta / dbTenth));

        if ((nCount < 1) || (nCount > 10))
            return dbTenth;

        switch (nCount) {
            case 1:
                dbTenth /= 10.0;	//	10倍精度
                break;
            case 2:
                dbTenth /= 5.0;	//	5倍精度
                break;
            case 3:
                dbTenth /= 2.5;	//	2.5倍精度
                break;
            case 4:
            case 5:
                dbTenth /= 2.0;	//	2倍精度
                break;
        }
        return dbTenth;
    }
    UpdateXCoordinate(start, end) {
        const coord = this.WebChart.Information.CoordinateInfo;
        if (start == null) start = coord.OriginXMin;
        if (end == null) end = coord.OriginXMax;
        if (end < 1e6) end = start + 1000 * 60;
        this.WebChart.Information.UpdateCoordinateInfo(end, start, coord.OriginYMax, coord.OriginYMin);
        this.WebChart.SetData([]);
    }
    ClearData() {
        this.WebChart.ClearData();
    }
    OnChartChanged() {
        this.WebChart.OnChartChanged();
    }
    Dispose() {
        this.WebChart?.Dispose();
        for (let p in this) {
            this[p] = null;
        }
    }
    Update(option, refresh = true) {
        this.Option = option;
        this.WebChart.Update(option, refresh);
    }
    GetPluginManager() {
        return this.WebChart.PluginManager;
    }
    UseDataRefresh(state) {
        this.WebChart.UseDataRefresh(state);
    }
}
