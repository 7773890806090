import $ from "jquery";
import { i18nGlobal } from "@common/js/utils";
import { CursorElement } from "@core/graphs/plugins/Dh-plugin-cursor-base";
import { SingleCursorPlugin } from "./Dh-plugin-single-cursor";

export class SideFrequencyCursorPlugin extends SingleCursorPlugin {
    constructor(mode) {
        super(mode);
        this.Title = i18nGlobal('core.sideFrequencyCursor');
        this.SideFrequencyCursorNum = 1;
        this.SideFrequencyCursorMap = new Map();
        this.SideFrequencyCursorDataMap = new Map();
        this.CursorDiff = 2; //两侧光标与主光标的距离，单位为XIndex
    }
    SetSideFrequencyCursorDiff(val) {
        this.CursorDiff = val;
        this.OnSizeChanged();
    }
    SetSideFrequencyCursorNum(val) {
        this.SideFrequencyCursorNum = val;
        this.OnSizeChanged();
    }
    OnSizeChanged() {
        const rect = this.Owner.Information.ShapeContainerRect;
        this.SideFrequencyCursorMap.forEach(cursor => {
            cursor.Remove();
        });
        this.SideFrequencyCursorMap.clear();
        this.SideFrequencyCursorDataMap.clear();
        for (let i = 0; i < this.SideFrequencyCursorNum; i++) {
            const lCursor = new CursorElement(this, rect.Height, this.Owner.CoordinateSystem.AxisColor, "1", { X: rect.Left, Y: rect.Top }, `L${i + 1}`);
            const rCursor = new CursorElement(this, rect.Height, this.Owner.CoordinateSystem.AxisColor, "1", { X: rect.Left, Y: rect.Top }, `R${i + 1}`)
            this.SideFrequencyCursorMap.set(`L${i + 1}`, lCursor);
            this.SideFrequencyCursorMap.set(`R${i + 1}`, rCursor);
            $(this.Owner.PluginContainer).append(lCursor.DOM);
            $(this.Owner.PluginContainer).append(rCursor.DOM);
        }
        this.Cursor?.Remove();
        this.Cursor = new CursorElement(this, rect.Height, this.Owner.CoordinateSystem.AxisColor, "2", { X: rect.Left, Y: rect.Top }, "I");
        $(this.Owner.PluginContainer).append(this.Cursor.DOM);
    }
    OnMouseUp(e) {
        let plugins = $("div .cursor-window");
        $.each(plugins, function () {
            $(this).css("pointer-events", "auto");
        });
        if (!this.IsEnabled) return false;
        if (window.event.ctrlKey) return false;
        if (e.which != 1 && !e.touches) return false;
        $('body').off('mouseup', this.onMouseUpHandler);
        $('body').off('touchend', this.onMouseUpHandler);
        if (this.Cursor && this.Cursor.IsMouseDown) {
            let offsetx = this.getMousePostion(e);
            this.Cursor.IsMouseDown = false;
            this.IsNotify = true;
            let data = this.Owner.CoordinateSystem.GetPositionData(offsetx);
            if (data) this.UpdatePosition(data);
        }
        this.IsMouseDown = false;
    }
    OnMouseMove(e) {
        if (!super.OnMouseMove(e)) return false;
        this.SideFrequencyCursorMap.forEach((cursor, key) => {
            if (cursor.IsMouseDown && e.which == 1) {
                this.IsNotify = false;
                const offsetx = this.getMousePostion(e);
                const data = this.Owner.CoordinateSystem.GetPositionData(offsetx);
                if (data) {
                    const currentCursorData = this.SideFrequencyCursorDataMap.get(key);
                    const magnification = Number.parseInt(key.slice(1));
                    const changedXIndex = Number.parseInt((currentCursorData.XIndex - data.XIndex) / magnification);
                    if (key.startsWith("L")) {
                        this.CursorDiff = Math.max(1, this.CursorDiff + changedXIndex);
                    } else {
                        this.CursorDiff = Math.max(1, this.CursorDiff - changedXIndex);
                    }
                    this.UpdateSideCursorPosition(this.CursorData.XIndex);
                }
            }
        })
    }
    GetCursorIndexByName(mainXIndex, cursorName) {
        if (cursorName.startsWith("L")) {
            return mainXIndex - Number.parseInt(cursorName.slice(1)) * this.CursorDiff;
        } else {
            return mainXIndex + Number.parseInt(cursorName.slice(1)) * this.CursorDiff;
        }
    }
    UpdateSideCursorPosition(xIndex) {
        let rect = this.Owner.Information.ShapeContainerRect;
        this.SideFrequencyCursorMap.forEach((cursor, key) => {
            const currentXIndex = this.GetCursorIndexByName(xIndex, key);
            const currentCursorData = this.Owner.CoordinateSystem.GetPositionByIndex(currentXIndex);
            cursor.ClearPoints();
            if (currentCursorData && currentCursorData != null) {
                this.SideFrequencyCursorDataMap.set(key, currentCursorData);
                Array.isArray(currentCursorData.Items) && currentCursorData.Items.forEach(dataItem => cursor.DrawPoint(dataItem.OffsetY + rect.Top));
                if (!isNaN(currentCursorData.OffsetX) && isFinite(currentCursorData.OffsetX)) {
                    if (currentCursorData.OffsetX >= 0 && Math.floor(currentCursorData.OffsetX) <= rect.Width) {
                        cursor.DOM.removeClass("hide");
                        cursor.DOM.attr("transform", "translate(" + (currentCursorData.OffsetX - 6) + ",0)");
                    } else {
                        cursor.DOM.addClass("hide");
                    }
                }
            } else {
                cursor.DOM.attr("transform", "translate(-6,0)");
            }
        })
    }
    UpdatePosition(data) {
        let rect = this.Owner.Information.ShapeContainerRect;
        if (this.Cursor == null) return;
        this.Cursor.ClearPoints();
        let container = this.LegendWnd.find(".cursor-container");

        const LeftFrequencyCursorKeys = this.SideFrequencyCursorDataMap.getKeys()
            .filter(e => e.startsWith('L'))
            .sort((a, b) => Number(b.slice(1)) - Number(a.slice(1)));

        const RightFrequencyCursorKeys = this.SideFrequencyCursorDataMap.getKeys()
            .filter(e => e.startsWith('R'))
            .sort((a, b) => Number(a.slice(1)) - Number(b.slice(1)));

        if (data && data != null) {
            this.UpdateSideCursorPosition(data.XIndex);

            container.empty();
            let headContent = '';
            LeftFrequencyCursorKeys.forEach(key => {
                headContent += `&emsp;X${key}:${this.SideFrequencyCursorDataMap.get(key).XText}`;
            })
            headContent += `&emsp;X:${data.XText}`;
            RightFrequencyCursorKeys.forEach(key => {
                headContent += `&emsp;X${key}:${this.SideFrequencyCursorDataMap.get(key).XText}`;
            })
            container.append('<span class="cursor-row">' + headContent + "</span>");

            Array.isArray(data.Items) && data.Items.forEach((dataItem, index) => {
                let rowContent = '';
                LeftFrequencyCursorKeys.forEach(key => {
                    rowContent += `&emsp;X${key}:${this.SideFrequencyCursorDataMap.get(key).Items[index]?.Y}`;
                })
                rowContent += `&emsp;Y:${dataItem.Y}`;
                RightFrequencyCursorKeys.forEach(key => {
                    rowContent += `&emsp;X${key}:${this.SideFrequencyCursorDataMap.get(key).Items[index]?.Y}`;
                })
                container.append('<span class="cursor-row" style="color:' + dataItem.Color + '">' + rowContent + "</span>");
                this.Cursor.DrawPoint(dataItem.OffsetY + rect.Top);
            })
            this.CursorData = data;
            this.NotifyPositionChanged();
            if (!isNaN(this.CursorData.OffsetX) && isFinite(this.CursorData.OffsetX)) {
                if (this.CursorData.OffsetX >= 0 && Math.floor(this.CursorData.OffsetX) <= rect.Width) {
                    this.Cursor.DOM.removeClass("hide");
                    this.Cursor.DOM.attr("transform", "translate(" + (this.CursorData.OffsetX - 6) + ",0)");
                } else {
                    this.Cursor.DOM.addClass("hide");
                }
            }
        } else {
            container.empty();
            this.Cursor.DOM.attr("transform", "translate(-6,0)");
        }
    }
    Close() {
        super.Close();
        if (this.Cursor != null) {
            this.Cursor.Remove();
            this.Cursor = null;
        }
        this.SideFrequencyCursorMap.forEach(cursor => cursor.Remove());
        this.SideFrequencyCursorMap = null;
        this.SideFrequencyCursorDataMap = null;
        if (this.LegendWnd != null) {
            this.LegendWnd.remove();
        }
    }
}