// @generated by protobuf-ts 2.9.1
// @generated from protobuf file "protos/getdatas.proto" (package "storage", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { HistoryPointDataReply } from "./historypointdatareply";
/**
 * @generated from protobuf message storage.GetDatasRequest
 */
export interface GetDatasRequest {
    /**
     * @generated from protobuf field: int32 DataType = 1 [json_name = "DataType"];
     */
    dataType: number;
    /**
     * @generated from protobuf field: string StartTime = 2 [json_name = "StartTime"];
     */
    startTime: string;
    /**
     * @generated from protobuf field: string EndTime = 3 [json_name = "EndTime"];
     */
    endTime: string;
    /**
     * @generated from protobuf field: repeated storage.GetPointDataRequest Points = 4 [json_name = "Points"];
     */
    points: GetPointDataRequest[];
    /**
     * @generated from protobuf field: bool IsExternal = 5 [json_name = "IsExternal"];
     */
    isExternal: boolean;
    /**
     * @generated from protobuf field: bool IncludeAlarm = 6 [json_name = "IncludeAlarm"];
     */
    includeAlarm: boolean;
}
/**
 * @generated from protobuf message storage.GetPointDataRequest
 */
export interface GetPointDataRequest {
    /**
     * @generated from protobuf field: int32 PointId = 1 [json_name = "PointId"];
     */
    pointId: number;
    /**
     * @generated from protobuf field: repeated int32 EigenTypes = 2 [json_name = "EigenTypes"];
     */
    eigenTypes: number[];
    /**
     * @generated from protobuf field: bool IsPatrol = 3 [json_name = "IsPatrol"];
     */
    isPatrol: boolean;
}
/**
 * @generated from protobuf message storage.GetDatasReply
 */
export interface GetDatasReply {
    /**
     * @generated from protobuf field: repeated storage.HistoryPointDataReply Points = 1 [json_name = "Points"];
     */
    points: HistoryPointDataReply[];
}
// @generated message type with reflection information, may provide speed optimized methods
class GetDatasRequest$Type extends MessageType<GetDatasRequest> {
    constructor() {
        super("storage.GetDatasRequest", [
            { no: 1, name: "DataType", kind: "scalar", jsonName: "DataType", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "StartTime", kind: "scalar", jsonName: "StartTime", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "EndTime", kind: "scalar", jsonName: "EndTime", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "Points", kind: "message", jsonName: "Points", repeat: 1 /*RepeatType.PACKED*/, T: () => GetPointDataRequest },
            { no: 5, name: "IsExternal", kind: "scalar", jsonName: "IsExternal", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "IncludeAlarm", kind: "scalar", jsonName: "IncludeAlarm", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<GetDatasRequest>): GetDatasRequest {
        const message = { dataType: 0, startTime: "", endTime: "", points: [], isExternal: false, includeAlarm: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetDatasRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetDatasRequest): GetDatasRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 DataType = 1 [json_name = "DataType"];*/ 1:
                    message.dataType = reader.int32();
                    break;
                case /* string StartTime = 2 [json_name = "StartTime"];*/ 2:
                    message.startTime = reader.string();
                    break;
                case /* string EndTime = 3 [json_name = "EndTime"];*/ 3:
                    message.endTime = reader.string();
                    break;
                case /* repeated storage.GetPointDataRequest Points = 4 [json_name = "Points"];*/ 4:
                    message.points.push(GetPointDataRequest.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bool IsExternal = 5 [json_name = "IsExternal"];*/ 5:
                    message.isExternal = reader.bool();
                    break;
                case /* bool IncludeAlarm = 6 [json_name = "IncludeAlarm"];*/ 6:
                    message.includeAlarm = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetDatasRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 DataType = 1 [json_name = "DataType"]; */
        if (message.dataType !== 0)
            writer.tag(1, WireType.Varint).int32(message.dataType);
        /* string StartTime = 2 [json_name = "StartTime"]; */
        if (message.startTime !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.startTime);
        /* string EndTime = 3 [json_name = "EndTime"]; */
        if (message.endTime !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.endTime);
        /* repeated storage.GetPointDataRequest Points = 4 [json_name = "Points"]; */
        for (let i = 0; i < message.points.length; i++)
            GetPointDataRequest.internalBinaryWrite(message.points[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* bool IsExternal = 5 [json_name = "IsExternal"]; */
        if (message.isExternal !== false)
            writer.tag(5, WireType.Varint).bool(message.isExternal);
        /* bool IncludeAlarm = 6 [json_name = "IncludeAlarm"]; */
        if (message.includeAlarm !== false)
            writer.tag(6, WireType.Varint).bool(message.includeAlarm);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message storage.GetDatasRequest
 */
export const GetDatasRequest = new GetDatasRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPointDataRequest$Type extends MessageType<GetPointDataRequest> {
    constructor() {
        super("storage.GetPointDataRequest", [
            { no: 1, name: "PointId", kind: "scalar", jsonName: "PointId", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "EigenTypes", kind: "scalar", jsonName: "EigenTypes", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "IsPatrol", kind: "scalar", jsonName: "IsPatrol", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<GetPointDataRequest>): GetPointDataRequest {
        const message = { pointId: 0, eigenTypes: [], isPatrol: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPointDataRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPointDataRequest): GetPointDataRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 PointId = 1 [json_name = "PointId"];*/ 1:
                    message.pointId = reader.int32();
                    break;
                case /* repeated int32 EigenTypes = 2 [json_name = "EigenTypes"];*/ 2:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.eigenTypes.push(reader.int32());
                    else
                        message.eigenTypes.push(reader.int32());
                    break;
                case /* bool IsPatrol = 3 [json_name = "IsPatrol"];*/ 3:
                    message.isPatrol = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPointDataRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 PointId = 1 [json_name = "PointId"]; */
        if (message.pointId !== 0)
            writer.tag(1, WireType.Varint).int32(message.pointId);
        /* repeated int32 EigenTypes = 2 [json_name = "EigenTypes"]; */
        if (message.eigenTypes.length) {
            writer.tag(2, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.eigenTypes.length; i++)
                writer.int32(message.eigenTypes[i]);
            writer.join();
        }
        /* bool IsPatrol = 3 [json_name = "IsPatrol"]; */
        if (message.isPatrol !== false)
            writer.tag(3, WireType.Varint).bool(message.isPatrol);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message storage.GetPointDataRequest
 */
export const GetPointDataRequest = new GetPointDataRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetDatasReply$Type extends MessageType<GetDatasReply> {
    constructor() {
        super("storage.GetDatasReply", [
            { no: 1, name: "Points", kind: "message", jsonName: "Points", repeat: 1 /*RepeatType.PACKED*/, T: () => HistoryPointDataReply }
        ]);
    }
    create(value?: PartialMessage<GetDatasReply>): GetDatasReply {
        const message = { points: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetDatasReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetDatasReply): GetDatasReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated storage.HistoryPointDataReply Points = 1 [json_name = "Points"];*/ 1:
                    message.points.push(HistoryPointDataReply.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetDatasReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated storage.HistoryPointDataReply Points = 1 [json_name = "Points"]; */
        for (let i = 0; i < message.points.length; i++)
            HistoryPointDataReply.internalBinaryWrite(message.points[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message storage.GetDatasReply
 */
export const GetDatasReply = new GetDatasReply$Type();
