// @generated by protobuf-ts 2.9.1
// @generated from protobuf file "protos/historyhealthdatas.proto" (package "component", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "../google/protobuf/timestamp";
/**
 * @generated from protobuf message component.HistoryHealthDatasRequest
 */
export interface HistoryHealthDatasRequest {
    /**
     * @generated from protobuf field: int32 Range = 1 [json_name = "Range"];
     */
    range: number;
    /**
     * @generated from protobuf field: repeated string ComponentIds = 2 [json_name = "ComponentIds"];
     */
    componentIds: string[];
}
/**
 * @generated from protobuf message component.HistoryHealthReply
 */
export interface HistoryHealthReply {
    /**
     * @generated from protobuf field: repeated component.HistoryHealthComponentReply DatasReply = 1 [json_name = "DatasReply"];
     */
    datasReply: HistoryHealthComponentReply[];
}
/**
 * @generated from protobuf message component.HistoryHealthComponentReply
 */
export interface HistoryHealthComponentReply {
    /**
     * @generated from protobuf field: string ComponentId = 1 [json_name = "ComponentId"];
     */
    componentId: string;
    /**
     * @generated from protobuf field: repeated component.HistoryHealthDatasReply HealthDatas = 2 [json_name = "HealthDatas"];
     */
    healthDatas: HistoryHealthDatasReply[];
}
/**
 * @generated from protobuf message component.HistoryHealthDatasReply
 */
export interface HistoryHealthDatasReply {
    /**
     * @generated from protobuf field: google.protobuf.Timestamp SampleTime = 1 [json_name = "SampleTime"];
     */
    sampleTime?: Timestamp;
    /**
     * @generated from protobuf field: int32 Value = 2 [json_name = "Value"];
     */
    value: number;
}
// @generated message type with reflection information, may provide speed optimized methods
class HistoryHealthDatasRequest$Type extends MessageType<HistoryHealthDatasRequest> {
    constructor() {
        super("component.HistoryHealthDatasRequest", [
            { no: 1, name: "Range", kind: "scalar", jsonName: "Range", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "ComponentIds", kind: "scalar", jsonName: "ComponentIds", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<HistoryHealthDatasRequest>): HistoryHealthDatasRequest {
        const message = { range: 0, componentIds: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<HistoryHealthDatasRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: HistoryHealthDatasRequest): HistoryHealthDatasRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 Range = 1 [json_name = "Range"];*/ 1:
                    message.range = reader.int32();
                    break;
                case /* repeated string ComponentIds = 2 [json_name = "ComponentIds"];*/ 2:
                    message.componentIds.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: HistoryHealthDatasRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 Range = 1 [json_name = "Range"]; */
        if (message.range !== 0)
            writer.tag(1, WireType.Varint).int32(message.range);
        /* repeated string ComponentIds = 2 [json_name = "ComponentIds"]; */
        for (let i = 0; i < message.componentIds.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.componentIds[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message component.HistoryHealthDatasRequest
 */
export const HistoryHealthDatasRequest = new HistoryHealthDatasRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HistoryHealthReply$Type extends MessageType<HistoryHealthReply> {
    constructor() {
        super("component.HistoryHealthReply", [
            { no: 1, name: "DatasReply", kind: "message", jsonName: "DatasReply", repeat: 1 /*RepeatType.PACKED*/, T: () => HistoryHealthComponentReply }
        ]);
    }
    create(value?: PartialMessage<HistoryHealthReply>): HistoryHealthReply {
        const message = { datasReply: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<HistoryHealthReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: HistoryHealthReply): HistoryHealthReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated component.HistoryHealthComponentReply DatasReply = 1 [json_name = "DatasReply"];*/ 1:
                    message.datasReply.push(HistoryHealthComponentReply.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: HistoryHealthReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated component.HistoryHealthComponentReply DatasReply = 1 [json_name = "DatasReply"]; */
        for (let i = 0; i < message.datasReply.length; i++)
            HistoryHealthComponentReply.internalBinaryWrite(message.datasReply[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message component.HistoryHealthReply
 */
export const HistoryHealthReply = new HistoryHealthReply$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HistoryHealthComponentReply$Type extends MessageType<HistoryHealthComponentReply> {
    constructor() {
        super("component.HistoryHealthComponentReply", [
            { no: 1, name: "ComponentId", kind: "scalar", jsonName: "ComponentId", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "HealthDatas", kind: "message", jsonName: "HealthDatas", repeat: 1 /*RepeatType.PACKED*/, T: () => HistoryHealthDatasReply }
        ]);
    }
    create(value?: PartialMessage<HistoryHealthComponentReply>): HistoryHealthComponentReply {
        const message = { componentId: "", healthDatas: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<HistoryHealthComponentReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: HistoryHealthComponentReply): HistoryHealthComponentReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string ComponentId = 1 [json_name = "ComponentId"];*/ 1:
                    message.componentId = reader.string();
                    break;
                case /* repeated component.HistoryHealthDatasReply HealthDatas = 2 [json_name = "HealthDatas"];*/ 2:
                    message.healthDatas.push(HistoryHealthDatasReply.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: HistoryHealthComponentReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string ComponentId = 1 [json_name = "ComponentId"]; */
        if (message.componentId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.componentId);
        /* repeated component.HistoryHealthDatasReply HealthDatas = 2 [json_name = "HealthDatas"]; */
        for (let i = 0; i < message.healthDatas.length; i++)
            HistoryHealthDatasReply.internalBinaryWrite(message.healthDatas[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message component.HistoryHealthComponentReply
 */
export const HistoryHealthComponentReply = new HistoryHealthComponentReply$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HistoryHealthDatasReply$Type extends MessageType<HistoryHealthDatasReply> {
    constructor() {
        super("component.HistoryHealthDatasReply", [
            { no: 1, name: "SampleTime", kind: "message", jsonName: "SampleTime", T: () => Timestamp },
            { no: 2, name: "Value", kind: "scalar", jsonName: "Value", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<HistoryHealthDatasReply>): HistoryHealthDatasReply {
        const message = { value: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<HistoryHealthDatasReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: HistoryHealthDatasReply): HistoryHealthDatasReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.Timestamp SampleTime = 1 [json_name = "SampleTime"];*/ 1:
                    message.sampleTime = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.sampleTime);
                    break;
                case /* int32 Value = 2 [json_name = "Value"];*/ 2:
                    message.value = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: HistoryHealthDatasReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.Timestamp SampleTime = 1 [json_name = "SampleTime"]; */
        if (message.sampleTime)
            Timestamp.internalBinaryWrite(message.sampleTime, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 Value = 2 [json_name = "Value"]; */
        if (message.value !== 0)
            writer.tag(2, WireType.Varint).int32(message.value);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message component.HistoryHealthDatasReply
 */
export const HistoryHealthDatasReply = new HistoryHealthDatasReply$Type();
