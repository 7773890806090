const platformInstrument = () => import("@views/platformSetting/instrument/index.vue");
const platformSignal = () => import("@views/platformSetting/baseInfo/sigalSetting/index.vue");
const platformBearingLibary = () => import("@views/platformSetting/bearing/index.vue");
const platformUser = () => import("@views/platformSetting/permission/user/index.vue");
const platformRole = () => import("@views/platformSetting/permission/role/index.vue");
const platformWorkGroup = () => import("@views/platformSetting/permission/workGroup/index.vue");
const runStatusManage = () => import("@views/platformSetting/runStatusManage/index.vue");
const platformSave = () => import("@views/platformSetting/settingsCenter/save/index.vue");
const platformAgree = () => import("@views/platformSetting/settingsCenter/agreement/index.vue");
const platformVersion = () => import("@views/platformSetting/productManage/platformVersion/index.vue");
const platformDepart = () => import("@views/platformSetting/permission/depart/index.vue");
const platformPatrol = () => import("@views/platformSetting/baseInfo/patrol/index.vue");
const platformCaculateTool = () => import("@views/platformSetting/tools/caculate/index.vue");
const platformBatchTool = () => import("@views/platformSetting/tools/batch/index.vue");
const platformAnalysisTool = () => import("@views/platformSetting/tools/analysis/index.vue");
const platformExportTool = () => import("@views/platformSetting/settingsCenter/taskSetting/index.vue");
const platformTheme = () => import("@views/platformSetting/productManage/theme/index.vue");
const platformLanguage = () => import("@views/platformSetting/productManage/language/index.vue");
const platformMachineTree = () => import("@views/platformSetting/settingsCenter/platformMachineTree/index.vue");
const platformMsg = () => import("@views/platformSetting/settingsCenter/msgSend/index.vue");
const platformSelfCheck = () => import("@views/platformSetting/selfCheck/index.vue");
const platformGraph = () => import("@views/platformSetting/settingsCenter/graph/index.vue");
const platformDevice = () => import("@views/platformSetting/settingsCenter/device/index.vue");
const platformAlarm = () => import("@views/platformSetting/settingsCenter/alarm/index.vue");
const faultLibary = () => import("@views/platformSetting/baseInfo/faultLibary/index.vue");
const dataExport = () => import("@views/platformSetting/dataManage/dataExport.vue");
const dataImport = () => import("@views/platformSetting/dataManage/dataImport.vue");
const platformNodeSetting = () => import("@views/platformSetting/productManage/nodeSetting/index.vue");
const platformSafe = () => import("@views/platformSetting/productManage/safe/index.vue");
const startAndStop = () => import("@views/platformSetting/settingsCenter/startAndStop/index.vue");
const operationLog = () => import("@views/platformSetting/productManage/operationLog/index.vue");
const platformOther = () => import("@views/platformSetting/productManage/other/otherSetting.vue");
const platformFaultCaseLibrary = () => import("@views/platformSetting/baseInfo/faultCaseLibrary/faultCaseLibrary.vue");
const platformDeviceTreeSetting = () => import("@views/platformSetting/baseInfo/deviceTree/index.vue");
const deviceDepartSetting = () => import("@views/platformSetting/settingsCenter/deviceDepartSetting/index.vue");

export {
    dataImport,
    dataExport,
    platformInstrument,
    platformSignal,
    platformBearingLibary,
    platformFaultCaseLibrary,
    platformUser,
    platformRole,
    platformWorkGroup,
    runStatusManage,
    platformSave,
    platformAgree,
    platformVersion,
    platformDepart,
    platformPatrol,
    platformCaculateTool,
    platformAnalysisTool,
    platformBatchTool,
    platformTheme,
    platformLanguage,
    platformAlarm,
    platformMachineTree,
    platformMsg,
    platformSelfCheck,
    platformGraph,
    platformDevice,
    platformNodeSetting,
    faultLibary,
    platformExportTool,
    platformSafe,
    startAndStop,
    operationLog,
    platformOther,
    platformDeviceTreeSetting,
    deviceDepartSetting
};
