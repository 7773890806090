<template>
    <el-pagination class="pagination" v-model:currentPage="pageInfo.PageIndex" v-model:page-size="pageInfo.PageSize"
        :page-sizes="pageInfo.PageSizes || [10, 20, 50, 100]" :background="true"
        layout="total, sizes, prev, pager, next, jumper" :total="pageInfo.total" @size-change="handleSizeChange"
        @current-change="handleCurrentChange" :small="props.small" />
</template>

<script setup>
const props = defineProps({
    pageInfo: {
        default: () => {
            return {
            }
        },
        type: Object
    },
    small: {
        type: Boolean,
        default: false
    }
})

const emit = defineEmits(['changePage']);
const handleSizeChange = (PageSize) => {
    emit('changePage', { ...props.pageInfo, PageSize: PageSize, PageIndex: 1 })
}

const handleCurrentChange = (PageIndex) => {
    emit('changePage', { ...props.pageInfo, PageIndex: PageIndex })
}
</script>

<style scoped lang="less">
.pagination {
    margin-top: 15px;
    display: flex;
    justify-content: center;

    :deep(.el-select .el-input) {
        width: 100px;
    }
}
</style>
