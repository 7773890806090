import { alertConfirm, alertErrorMsg, i18nString } from "@common/js/utils";
import axios from "@common/js/axios";
import { onMounted, reactive, ref, watch } from "vue";
import { alertSuccessMsg, i18nGlobal } from "@common/js/utils";
import { ruleCheck } from "@common/js/rules";
import { axiosAuth } from "@common/js/auth";

// 平台设置表格操作
const useTableOperation = (state, emit, params) => {
	const { type } = params;

	const deleteData = (Id) => {
		const { getTableData } = params;
		const Data = Array.isArray(Id) ? Id : state.selection.map((item) => item.Id);
		if (state.selection.find((item) => item.IsInternal)) {
			return alertErrorMsg(i18nGlobal('hooks.internalDeleteTip'));
		}
		const handleDelete = () => {
			axios({
				method: "delete",
				url: `${type}/Delete`,
				data: {
					ids: Data,
				},
			}).then((res) => {
				if (res.IsSuccess) {
					getTableData(true);
				}
			});
		};
		alertConfirm(handleDelete, { msg: i18nString({ CN: `确实要删除 ${params.msg}`, EN: `Are you sure to delete ${params.msg}` }) });
	};

	const removeTableData = (data) => {
		const { getTableData } = params;
		if (data.find((item) => item.IsInternal)) {
			return alertErrorMsg(i18nGlobal('hooks.cantDeleteTip'));
		}
		const ids = data.map((item) => item.Id);
		const handleDelete = () => {
			axios({
				method: "delete",
				url: `${type}/${type}`,
				data: {
					ids: ids,
				},
			}).then((res) => {
				if (res.IsSuccess) {
					alertSuccessMsg(i18nGlobal('common.deleteSuccess'));
					getTableData(true);
				} else if(type === 'StorageMode') {
					getTableData()
				}
			});
		};
		if (params.checkPermision) {
			axios({
				method: "post",
				url: params.checkPermision,
				data: ids,
			}).then((res) => {
				if (res.IsSuccess) {
					if (res.Result) {
						alertConfirm(handleDelete, {
							msg: i18nString({
								CN: `确定要删除${data.length > 1 ? "所选" : "该"}${params.msg}?`,
								EN: `Are you sure to delete ${data.length > 1 ? "selected" : "this"}${params.msg}?`
							})
						});
					} else {
						alertConfirm(() => handleDelete(), {
							msg: i18nString({
								CN: `当前${params.msg}已有用户关联，删除该${params.msg}会使其解除关联并失去当前${params.msg}权限，是否继续删除？`,
								EN: `current ${params.msg} is linked to user, deleting ${params.msg} will unlink it to all user and making each user lose authorization, continue?`
							}),
							title: i18nGlobal('common.deleteConfirm'),
						});
					}
				}
			});
		} else if (params.checkDelete) {
			axios({
				method: "post",
				url: params.checkDelete,
				data: {
					ids,
				},
			}).then((res) => {
				if (res.IsSuccess) {
					if (res.Result) {
						alertConfirm(handleDelete, {
							msg: i18nString({
								CN: `确定要删除${data.length > 1 ? "所选" : "该"}${params.msg}?`,
								EN: `Are you sure to delete ${data.length > 1 ? "selected" : "this"}${params.msg}?`
							})
						});
					} else {
					}
				}
			});
		} else {
			alertConfirm(handleDelete, {
				msg: i18nString({
					CN: `确定要删除${data.length > 1 ? "所选" : "该"}${params.msg}?`,
					EN: `Are you sure to delete ${data.length > 1 ? "selected" : "this"}${params.msg}?`
				})
			});
		}
	};

	const changePage = (pageInfo) => {
		const { getTableData } = params;
		state.pageInfo = pageInfo;
		getTableData();
	};

	const handleSelectionChange = (selection, name = "selection") => {
		state[name] = selection;
	};

	const submitForm = (isFirst) => {
		const { getTableData } = params;
		state.currentRow = {};
		getTableData(isFirst);
	};

	const cancelDialog = (dialog = "innerDialog") => {
		state[dialog] = false;
		state.currentRow = {};
	};

	const beforeAdd = (dialog = "innerDialog") => {
		state.currentRow = {};
		state[dialog] = true;
	};

	const beforeEdit = (row, dialog = "innerDialog") => {
		state.currentRow = row;
		state[dialog] = true;
	};

	return {
		deleteData,
		changePage,
		handleSelectionChange,
		submitForm,
		cancelDialog,
		removeTableData,
		beforeAdd,
		beforeEdit,
	};
};

const useEditRow = (type, props, state, needRelated = true, auth) => {
	const getCurrentData = () => {
		return axiosAuth({
			method: "get",
			url: `${type}/${type}/${props.row.Id}`,
			auth
		}).then((res) => {
			if (res.IsSuccess) {
				return res.Result;
			}
		});
	};

	const getRelatedList = () => {
		return axiosAuth({
			method: "get",
			url: `${type}/${type}Related`,
			auth
		}).then((res) => {
			if (res.IsSuccess) {
				return res.Result;
			}
		});
	};

	const setCurrentForm = async (callback, beforeFun) => {
		let isAdd = null;
		if (props.depInfo) {
			isAdd = props.depInfo.operateType === "add";
		} else {
			isAdd = props.row.Id === undefined;
		}
		// 编辑
		if (!isAdd) {
			const currentRow = await getCurrentData();
			// needRelated 是否请求GetRelated接口 beforeFun 回显表单前操作 callback 回显表单后操作
			if (!needRelated) {
				beforeFun && beforeFun(currentRow);
				for (let key in state.form) {
					state.form[key] = currentRow[key] === undefined ? "" : currentRow[key];
				}
				callback && callback(currentRow);
			} else {
				const res = await getRelatedList();
				beforeFun && beforeFun(currentRow);
				for (let key in state.form) {
					state.form[key] = currentRow[key] === undefined ? "" : currentRow[key];
				}
				callback && callback(res, currentRow);
			}
		} else {
			// 新增
			if (!needRelated) {
				callback && callback();
			} else {
				const res = await getRelatedList();
				callback && callback(res);
			}
		}
	};

	return {
		getCurrentData,
		getRelatedList,
		setCurrentForm,
	};
};

const useDialog = (formRef, emit) => {
	const showDialog = ref(false);

	onMounted(() => {
		showDialog.value = true;
	});

	const closeDialog = (callback) => {
		if (formRef && formRef.value) {
			formRef.value.resetFields();
		}
		if (callback && typeof callback === "function") {
			callback();
		}
		cancel(callback);
	};

	const cancel = (data) => {
		showDialog.value = false;
		emit("cancel", data);
	};

	return {
		showDialog,
		closeDialog,
		cancel,
	};
};

const useTabelSettings = () => {
	const formatterText = (row, column) => {
		const props = column.property;
		const text = row[props];
		if (Array.isArray(text)) {
			if (text.length === 0) {
				return "-";
			} else {
				return text.toString();
			}
		}
		if (ruleCheck.IsNullOrSpaceStr(text)) {
			return "-";
		}
		return text;
	};

	const selectable = (row) => {
		if (row.CanDelete !== undefined && row.CanDelete === false) {
			return false;
		}
		if (row.IsInternal !== undefined && (row.IsInternal === 1 || row.IsInternal === true)) {
			return false;
		}
		if (row.State !== undefined && row.State === 10) {
			return false;
		}
		return true;
	};

	const getRowClassName = ({ row }) => {
		if (row.State === 10) {
			return "emptyTr";
		}
		return "";
	};

	return {
		formatterText,
		selectable,
		getRowClassName,
	};
};

export { useTableOperation, useEditRow, useDialog, useTabelSettings };
