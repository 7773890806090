// @generated by protobuf-ts 2.9.1
// @generated from protobuf file "protos/onceIntegralgraphdata.proto" (package "component", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { GraphDataReply } from "./graphdatareply";
/**
 * @generated from protobuf message component.GetOnceIntegralGraphDataRequest
 */
export interface GetOnceIntegralGraphDataRequest {
    /**
     * @generated from protobuf field: int32 PointId = 1 [json_name = "PointId"];
     */
    pointId: number;
    /**
     * @generated from protobuf field: float SampleFreq = 2 [json_name = "SampleFreq"];
     */
    sampleFreq: number;
    /**
     * @generated from protobuf field: component.OnceIntegralParamRequest GraphParam = 3 [json_name = "GraphParam"];
     */
    graphParam?: OnceIntegralParamRequest;
}
/**
 * @generated from protobuf message component.OnceIntegralParamRequest
 */
export interface OnceIntegralParamRequest {
    /**
     * @generated from protobuf field: bool FilterSwitch = 1 [json_name = "FilterSwitch"];
     */
    filterSwitch: boolean;
    /**
     * @generated from protobuf field: float Coefficient = 2 [json_name = "Coefficient"];
     */
    coefficient: number;
    /**
     * @generated from protobuf field: int32 Order = 3 [json_name = "Order"];
     */
    order: number;
    /**
     * @generated from protobuf field: double CutoffFreq = 4 [json_name = "CutoffFreq"];
     */
    cutoffFreq: number;
}
/**
 * @generated from protobuf message component.GetOnceIntegralGraphDataReply
 */
export interface GetOnceIntegralGraphDataReply {
    /**
     * @generated from protobuf field: string ComponentId = 1 [json_name = "ComponentId"];
     */
    componentId: string;
    /**
     * @generated from protobuf field: repeated component.GraphDataReply Datas = 2 [json_name = "Datas"];
     */
    datas: GraphDataReply[];
}
// @generated message type with reflection information, may provide speed optimized methods
class GetOnceIntegralGraphDataRequest$Type extends MessageType<GetOnceIntegralGraphDataRequest> {
    constructor() {
        super("component.GetOnceIntegralGraphDataRequest", [
            { no: 1, name: "PointId", kind: "scalar", jsonName: "PointId", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "SampleFreq", kind: "scalar", jsonName: "SampleFreq", T: 2 /*ScalarType.FLOAT*/ },
            { no: 3, name: "GraphParam", kind: "message", jsonName: "GraphParam", T: () => OnceIntegralParamRequest }
        ]);
    }
    create(value?: PartialMessage<GetOnceIntegralGraphDataRequest>): GetOnceIntegralGraphDataRequest {
        const message = { pointId: 0, sampleFreq: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetOnceIntegralGraphDataRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetOnceIntegralGraphDataRequest): GetOnceIntegralGraphDataRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 PointId = 1 [json_name = "PointId"];*/ 1:
                    message.pointId = reader.int32();
                    break;
                case /* float SampleFreq = 2 [json_name = "SampleFreq"];*/ 2:
                    message.sampleFreq = reader.float();
                    break;
                case /* component.OnceIntegralParamRequest GraphParam = 3 [json_name = "GraphParam"];*/ 3:
                    message.graphParam = OnceIntegralParamRequest.internalBinaryRead(reader, reader.uint32(), options, message.graphParam);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetOnceIntegralGraphDataRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 PointId = 1 [json_name = "PointId"]; */
        if (message.pointId !== 0)
            writer.tag(1, WireType.Varint).int32(message.pointId);
        /* float SampleFreq = 2 [json_name = "SampleFreq"]; */
        if (message.sampleFreq !== 0)
            writer.tag(2, WireType.Bit32).float(message.sampleFreq);
        /* component.OnceIntegralParamRequest GraphParam = 3 [json_name = "GraphParam"]; */
        if (message.graphParam)
            OnceIntegralParamRequest.internalBinaryWrite(message.graphParam, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message component.GetOnceIntegralGraphDataRequest
 */
export const GetOnceIntegralGraphDataRequest = new GetOnceIntegralGraphDataRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OnceIntegralParamRequest$Type extends MessageType<OnceIntegralParamRequest> {
    constructor() {
        super("component.OnceIntegralParamRequest", [
            { no: 1, name: "FilterSwitch", kind: "scalar", jsonName: "FilterSwitch", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "Coefficient", kind: "scalar", jsonName: "Coefficient", T: 2 /*ScalarType.FLOAT*/ },
            { no: 3, name: "Order", kind: "scalar", jsonName: "Order", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "CutoffFreq", kind: "scalar", jsonName: "CutoffFreq", T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value?: PartialMessage<OnceIntegralParamRequest>): OnceIntegralParamRequest {
        const message = { filterSwitch: false, coefficient: 0, order: 0, cutoffFreq: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<OnceIntegralParamRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OnceIntegralParamRequest): OnceIntegralParamRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool FilterSwitch = 1 [json_name = "FilterSwitch"];*/ 1:
                    message.filterSwitch = reader.bool();
                    break;
                case /* float Coefficient = 2 [json_name = "Coefficient"];*/ 2:
                    message.coefficient = reader.float();
                    break;
                case /* int32 Order = 3 [json_name = "Order"];*/ 3:
                    message.order = reader.int32();
                    break;
                case /* double CutoffFreq = 4 [json_name = "CutoffFreq"];*/ 4:
                    message.cutoffFreq = reader.double();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OnceIntegralParamRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool FilterSwitch = 1 [json_name = "FilterSwitch"]; */
        if (message.filterSwitch !== false)
            writer.tag(1, WireType.Varint).bool(message.filterSwitch);
        /* float Coefficient = 2 [json_name = "Coefficient"]; */
        if (message.coefficient !== 0)
            writer.tag(2, WireType.Bit32).float(message.coefficient);
        /* int32 Order = 3 [json_name = "Order"]; */
        if (message.order !== 0)
            writer.tag(3, WireType.Varint).int32(message.order);
        /* double CutoffFreq = 4 [json_name = "CutoffFreq"]; */
        if (message.cutoffFreq !== 0)
            writer.tag(4, WireType.Bit64).double(message.cutoffFreq);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message component.OnceIntegralParamRequest
 */
export const OnceIntegralParamRequest = new OnceIntegralParamRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetOnceIntegralGraphDataReply$Type extends MessageType<GetOnceIntegralGraphDataReply> {
    constructor() {
        super("component.GetOnceIntegralGraphDataReply", [
            { no: 1, name: "ComponentId", kind: "scalar", jsonName: "ComponentId", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "Datas", kind: "message", jsonName: "Datas", repeat: 1 /*RepeatType.PACKED*/, T: () => GraphDataReply }
        ]);
    }
    create(value?: PartialMessage<GetOnceIntegralGraphDataReply>): GetOnceIntegralGraphDataReply {
        const message = { componentId: "", datas: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetOnceIntegralGraphDataReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetOnceIntegralGraphDataReply): GetOnceIntegralGraphDataReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string ComponentId = 1 [json_name = "ComponentId"];*/ 1:
                    message.componentId = reader.string();
                    break;
                case /* repeated component.GraphDataReply Datas = 2 [json_name = "Datas"];*/ 2:
                    message.datas.push(GraphDataReply.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetOnceIntegralGraphDataReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string ComponentId = 1 [json_name = "ComponentId"]; */
        if (message.componentId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.componentId);
        /* repeated component.GraphDataReply Datas = 2 [json_name = "Datas"]; */
        for (let i = 0; i < message.datas.length; i++)
            GraphDataReply.internalBinaryWrite(message.datas[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message component.GetOnceIntegralGraphDataReply
 */
export const GetOnceIntegralGraphDataReply = new GetOnceIntegralGraphDataReply$Type();
