<script setup>
import { ElConfigProvider } from "element-plus"
import zhCn from "element-plus/lib/locale/lang/zh-cn"
import en from 'element-plus/lib/locale/lang/en'
import { reactive, ref, computed } from "vue";
import { useStore } from "vuex";

const store = useStore();
const local = computed(() => {
    const lang = store.state.lang;
    const map = {
        'CN': zhCn,
        'EN': en
    }
    return map[lang];
})
</script>

<template>
    <el-config-provider :locale="local">
        <router-view />
    </el-config-provider>
</template>

<style lang="less">
html,
body {
    width: 100%;
    height: 100%;
    background-color: #fff;
    // overflow: hidden;
    #app {
        height: 100%;
        // max-height: 100%;
        // min-width: 1100px;
        // height: auto;
        // padding-bottom: 1px;
        overflow: auto;
    }
}
</style>
