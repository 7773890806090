import { createApp } from 'vue'
import pcRouter from '@router'
import appRouter from '@router/app'
import store from '@common/js/store'
import ElementPlus from 'element-plus'
import Vant from 'vant'
import App from './App.vue'
import i18n from '@common/js/i18n'
import '@styles/reset.css'
import 'amfe-flexible'
import 'element-plus/dist/index.css'
import '@styles/common.less'
import '@core/graphs/css/graph.less'
import '@components/css/component.less'
import '@common/js/common'
import '@common/js/storage'
import 'vant/lib/index.css';

// svg插件
import 'virtual:svg-icons-register'
import 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
import '@styles/reset.less'
import { isMobile } from '@common/js/utils'
import { PhmWebStorageContext } from '@components/js/utils/indexDb'
// import 'default-passive-events'
window.componentLibarys = Object();
const app = createApp(App)

const router = isMobile() ? appRouter : pcRouter
store.commit('setBrowseType', {
	browseType: isMobile() ? 'app' : 'pc'
})
app.use(router).use(store).use(ElementPlus, { size: 'default' }).use(VXETable).use(i18n).use(Vant)
PhmWebStorageContext.GetInstance();
app.directive('auth', (el, binding) => {
	if (store.state.userAuth.ResourceAuth && !store.state.userAuth.ResourceAuth.includes(binding.value)) {
		el.style.display = 'none'
	}
})

app.directive('debounce', (el, binding) => {
	el.addEventListener('click', (e) => {
		el.classList.add('is-disabled')
		el.disabled = true
		setTimeout(() => {
			el.disabled = false
			el.classList.remove('is-disabled')
		}, 1000)
	})
})

app.directive('auths', (el, binding) => {
	if (store.state.userAuth.ResourceAuth == null) return
	let auths = binding.value.split(',')
	let hasAuth = false
	for (let auth of auths) {
		if (store.state.userAuth.ResourceAuth.includes(auth)) {
			hasAuth = true
		}
	}
	if (!hasAuth) {
		el.style.display = 'none'
	}
})

app.directive('authas', (el, binding) => {
	if (store.state.userAuth.ResourceAuth == null) return
	let auths = binding.value.split(',')
	let hasAuth = true
	for (let auth of auths) {
		if (!store.state.userAuth.ResourceAuth.includes(auth)) {
			hasAuth = false
		}
	}
	if (!hasAuth) {
		el.style.display = 'none'
	}
})
app.directive('title', (el, binding) => {
	el.addEventListener('mouseover', () => {
		el.title = el.scrollWidth > el.offsetWidth ? binding.value : ''
	})
})

app.directive('treeAuth', (el, binding) => {
	const { role, tree } = binding.value
	if (store.state.userAuth.ResourceAuth && !store.state.userAuth.ResourceAuth.includes(role)) {
		el.style.display = 'none'
	} else {
		const MachineTreeAuth = store.state.userAuth.MachineTreeAuth
		const currentDep = store.state.currentDep
		if (MachineTreeAuth) {
			const hasAuth = MachineTreeAuth[currentDep.Id]
			if (!hasAuth || hasAuth[tree] === 0) {
				el.style.display = 'none'
			}
		}
	}
})

app.mount('#app')
