// @generated by protobuf-ts 2.9.1
// @generated from protobuf file "protos/graphfirstdatas.proto" (package "component", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { HistoryPointDataReply } from "./historypointdatareply";
import { GraphParamRequest } from "./graphparamrequest";
import { GetFirstWaveDataRequest } from "./graphparamrequest";
/**
 * @generated from protobuf message component.GetGraphFirstDatasRequest
 */
export interface GetGraphFirstDatasRequest {
    /**
     * @generated from protobuf field: component.GetFirstWaveDataRequest Condition = 1 [json_name = "Condition"];
     */
    condition?: GetFirstWaveDataRequest;
    /**
     * @generated from protobuf field: component.GraphParamRequest GraphParam = 2 [json_name = "GraphParam"];
     */
    graphParam?: GraphParamRequest;
    /**
     * @generated from protobuf field: bool IncludeAlarm = 3 [json_name = "IncludeAlarm"];
     */
    includeAlarm: boolean;
}
/**
 * @generated from protobuf message component.GetGraphFirstDatasReply
 */
export interface GetGraphFirstDatasReply {
    /**
     * @generated from protobuf field: repeated storage.HistoryPointDataReply Points = 1 [json_name = "Points"];
     */
    points: HistoryPointDataReply[];
}
// @generated message type with reflection information, may provide speed optimized methods
class GetGraphFirstDatasRequest$Type extends MessageType<GetGraphFirstDatasRequest> {
    constructor() {
        super("component.GetGraphFirstDatasRequest", [
            { no: 1, name: "Condition", kind: "message", jsonName: "Condition", T: () => GetFirstWaveDataRequest },
            { no: 2, name: "GraphParam", kind: "message", jsonName: "GraphParam", T: () => GraphParamRequest },
            { no: 3, name: "IncludeAlarm", kind: "scalar", jsonName: "IncludeAlarm", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<GetGraphFirstDatasRequest>): GetGraphFirstDatasRequest {
        const message = { includeAlarm: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetGraphFirstDatasRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetGraphFirstDatasRequest): GetGraphFirstDatasRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* component.GetFirstWaveDataRequest Condition = 1 [json_name = "Condition"];*/ 1:
                    message.condition = GetFirstWaveDataRequest.internalBinaryRead(reader, reader.uint32(), options, message.condition);
                    break;
                case /* component.GraphParamRequest GraphParam = 2 [json_name = "GraphParam"];*/ 2:
                    message.graphParam = GraphParamRequest.internalBinaryRead(reader, reader.uint32(), options, message.graphParam);
                    break;
                case /* bool IncludeAlarm = 3 [json_name = "IncludeAlarm"];*/ 3:
                    message.includeAlarm = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetGraphFirstDatasRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* component.GetFirstWaveDataRequest Condition = 1 [json_name = "Condition"]; */
        if (message.condition)
            GetFirstWaveDataRequest.internalBinaryWrite(message.condition, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* component.GraphParamRequest GraphParam = 2 [json_name = "GraphParam"]; */
        if (message.graphParam)
            GraphParamRequest.internalBinaryWrite(message.graphParam, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* bool IncludeAlarm = 3 [json_name = "IncludeAlarm"]; */
        if (message.includeAlarm !== false)
            writer.tag(3, WireType.Varint).bool(message.includeAlarm);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message component.GetGraphFirstDatasRequest
 */
export const GetGraphFirstDatasRequest = new GetGraphFirstDatasRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetGraphFirstDatasReply$Type extends MessageType<GetGraphFirstDatasReply> {
    constructor() {
        super("component.GetGraphFirstDatasReply", [
            { no: 1, name: "Points", kind: "message", jsonName: "Points", repeat: 1 /*RepeatType.PACKED*/, T: () => HistoryPointDataReply }
        ]);
    }
    create(value?: PartialMessage<GetGraphFirstDatasReply>): GetGraphFirstDatasReply {
        const message = { points: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetGraphFirstDatasReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetGraphFirstDatasReply): GetGraphFirstDatasReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated storage.HistoryPointDataReply Points = 1 [json_name = "Points"];*/ 1:
                    message.points.push(HistoryPointDataReply.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetGraphFirstDatasReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated storage.HistoryPointDataReply Points = 1 [json_name = "Points"]; */
        for (let i = 0; i < message.points.length; i++)
            HistoryPointDataReply.internalBinaryWrite(message.points[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message component.GetGraphFirstDatasReply
 */
export const GetGraphFirstDatasReply = new GetGraphFirstDatasReply$Type();
