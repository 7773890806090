import $ from "jquery";
import { guid } from "@common/js/utils";

export class KeyListenManager {
	static getListenManager() {
		if (window.KeyManager == undefined || window.KeyManager == null) {
			window.KeyManager = new KeyListenManager();
		}
		return window.KeyManager;
	}
	constructor() {
		this.CurrentElement = null; //当前选中的元素(plugin)
		$(document).keydown((e) => {
			//向左
			if (this.CurrentElement == null) return;
			if (this.CurrentElement.Owner != undefined) {
				if ($(this.CurrentElement.Owner.ChartContainer).parent().is(":visible") == false) return;
			}
			if (e.keyCode == 37) {
				this.CurrentElement.KeyLeftPress?.();
			}
			//向上
			else if (e.keyCode == 38) {
				this.CurrentElement.KeyUpPress?.();
			}
			//向右
			else if (e.keyCode == 39) {
				this.CurrentElement.KeyRightPress?.();
			}
			//向下
			else if (e.keyCode == 40) {
				this.CurrentElement.KeyDownPress?.();
			}
		});
		$(document).keyup((e) => {
			if (e.keyCode == 37 || e.keyCode == 39) {
				if (this.CurrentElement != null) {
					this.CurrentElement.RefreshRelatedData?.();
				}
			}
		});
	}
}

export class ElementBase {
	constructor() {
		this.Id = guid();
	}
	AddRect(x, y, width, height, fill) {
		width = width < 0 ? 0 : width;
		height = height < 0 ? 0 : height;
		var rect = document.createElementNS("http://www.w3.org/2000/svg", "rect");
		$(rect).attr("x", x).attr("y", y).attr("width", width).attr("height", height);
		if (fill) {
			$(rect).css("fill", fill);
		}
		return rect;
	}
	AddCross(x, y, width, height, stroke) {
		width = width < 0 ? 0 : width;
		height = height < 0 ? 0 : height;
		var line1 = document.createElementNS("http://www.w3.org/2000/svg", "line");
		var line2 = document.createElementNS("http://www.w3.org/2000/svg", "line");
		$(line1).attr("x1", x - width / 2).attr("y1", y - height / 2).attr("x2", x + width / 2).attr("y2", y + height / 2);
		$(line2).attr("x1", x + width / 2).attr("y1", y - height / 2).attr("x2", x - width / 2).attr("y2", y + height / 2);

		if (stroke) {
			$(line1).css("stroke", stroke);
			$(line2).css("stroke", stroke);
		}
		return { line1, line2 };
	}
	AddRegion() {
		var rect = document.createElementNS("http://www.w3.org/2000/svg", "rect");
		let width = this.Width < 0 ? 0 : this.Width;
		let height = this.Height < 0 ? 0 : this.Height;
		$(rect).attr("x", 0).attr("y", 0).attr("width", width).attr("height", height).css("fill", "transparent");
		return rect;
	}
	AddPath(d, width, transform) {
		var path = document.createElementNS("http://www.w3.org/2000/svg", "path");
		width = width < 0 ? 0 : width;
		$(path).attr("d", d).attr("stroke-width", width);
		if (transform) {
			$(path).attr("transform", transform);
		}
		return path;
	}
}

export class PluginBase extends ElementBase {
	constructor() {
		super();
		this.Entity = null;
		this.Owner = null;
		this.IsEnabled = true;
	}
	Init(owner, entity) {
		this.Owner = owner;
		this.Entity = entity;
	}
	CreatePlugin() { }
	OnRender() { }
	OnSizeChanged() { }
	Close() { }
	ClearData() { }
}

export class SvgButtonBase extends PluginBase {
	constructor() {
		super();
		this.DOM = null;
		this.Width = 16;
		this.Height = 16;
		this.Left = 0;
		this.Top = 0;
		this.Padding = 3;
		this.Translate = "";
	}
	CreatePlugin() {
		this.Dom = this.GetSvgIcon();
		this.Dom.append(this.AddRegion());
		$(this.Owner.PluginContainer).append(this.Dom);
		this.Dom.attr("id", this.Id);
		this.Dom.click((e) => {
			e.preventDefault();
			e.stopPropagation();
			this.OnClick();
		});
		this.Dom.on("dblclick", (e) => {
			this.OnDblClick();
			e.preventDefault();
			e.stopPropagation();
		});
		let isMouseDown = false;
		this.Dom.mousedown((e) => {
			e.preventDefault();
			e.stopPropagation();
			isMouseDown = true;
			this.Dom.attr("transform", this.Translate + " scale(1.1)");
		}).mouseup((e) => {
			if (isMouseDown) {
				e.preventDefault();
				e.stopPropagation();
				isMouseDown = false;
			}
			this.Dom.attr("transform", this.Translate);
		});
		this.Dom.addClass("svg-button");
	}
	GetSvgIcon() { }
	OnDblClick() {
		if (!this.IsEnabled) return;
		if (this.Entity.OnDblClick) {
			this.Entity.OnDblClick();
		}
	}
	OnClick() {
		if (!this.IsEnabled) return;
		if (this.Entity.onClick) {
			this.Entity.onClick();
		}
	}
	Close() {
		this.Dom.remove();
		this.Dom = null;
	}
}
