import axios from "@common/js/axios";
import { ContractTypes } from "@common/js/enum";
import { getDeepClone } from "@common/js/utils";
import { BlockDataRealContext } from "./blockDataRealContext";
import store from "@common/js/store";

export class LatestWaveDataRealContext extends BlockDataRealContext {
    constructor() {
        super();
    }
    getContractType() {
        return ContractTypes.Latest;
    }
    sendRegisterPointsToClinet() {
        if (this.getConnectionIds().length == 0) return;
        this.debounce.run(() => {
            const registerGraphPoints = this.updateHistoryChangeGraphs();
            const connectionIds = this.getConnectionIds();
            if (connectionIds.length == 0) return;
            const refreshFreq = store.state.graphSetting.RefreshFreq ?? 5;
            axios({
                method: "post",
                url: "datamanagement/register-latest-data",
                data: {
                    ConnectionIds: connectionIds,
                    Points: [...this.registerPointCollection.values()].map(p => {
                        return {
                            PointId: p.PointId,
                            Interval: parseInt(1000 / refreshFreq),
                        }
                    }),
                },
            }).then((res) => {
                if (res.IsSuccess) {
                    this.processRegisterData([...this.registerPointCollection.values()], registerGraphPoints);
                }
            });
        }, 100);
    }
    async processRegisterData(pointInfos, registerGraphPoints) {
        const latestDatas = await this.getLatestData(pointInfos);
        let datas = pointInfos.map(pointInfo => {
            const point = {
                PointId: pointInfo.PointId,
                AlgorithmType: pointInfo.AlgorithmType,
                Param: getDeepClone(pointInfo),
                Eigens: [{
                    EigenType: 13,
                    Value: latestDatas.find(d => d.PointId == pointInfo.PointId)?.Data || 0,
                    Unit: pointInfo.Unit,
                    EigenName: ''
                }],
                SampleTime: Number(new Date())
            }
            return point;
        });
        if (datas.length > 0) {
            this.processRegisterDataReceived(datas, registerGraphPoints);
        }
        datas = null;
    }
    async getLatestData(pointInfos) {
        const res = await axios({
            method: "post",
            url: "datamanagement/query-latest-data",
            data: {
                PointIds: pointInfos.map(p => p.PointId)
            }
        });
        if (res.IsSuccess && res.Result?.length > 0) {
            return res.Result;
        }
        return [];
    }
    processWaveData(datas) {
        for (const data of datas) {
            if (data.Eigens == null) {
                data.Eigens = [{
                    EigenType: 13,
                    Value: data.Data,
                }];
            }
        }
    }
    getCloneData(data) {
        const dataTemp = {
            PointId: data.PointId,
            SampleTime: data.SampleTime,
            Param: null,
            Eigens: data.Eigens,
        };
        if (data.Param) {
            dataTemp.Param = getDeepClone(data.Param);
        }
        return dataTemp;
    }
}