<template>
    <div id="virtualMsgList" v-bind="containerProps">
        <div v-bind="wrapperProps">
            <div class="listItem" v-for="{ data, index } in list" :key="index">
                <el-icon class="first" :class="data.type">
                    <WarningFilled v-if="data.type === 'warning'" />
                    <SuccessFilled v-if="data.type === 'success'" />
                    <CircleCloseFilled v-if="data.type === 'danger'" />
                    <InfoFilled v-if="data.type === 'info'" />
                </el-icon>
                <span class="name" :class="data.type" v-title="data.text">{{ data.text }}</span>
                <el-icon @click.stop="remove(data)">
                    <Close />
                </el-icon>
            </div>
        </div>
    </div>
</template>
<script setup>
import { Close, SuccessFilled, WarningFilled, CircleCloseFilled, InfoFilled } from '@element-plus/icons-vue'
import { onMounted } from "vue";
import { draggable } from "@core/graphs/webcharts/Dh-webchart-draggable";
import $ from "jquery";
import { useVirtualList } from '@vueuse/core'

const emit = defineEmits(['remove']);
const props = defineProps({
    data: {
        default: [],
        type: Array
    },
})

const { list, containerProps, wrapperProps, scrollTo } = useVirtualList(
    props.data,
    {
        itemHeight: 48,
        overscan: 10,
    },
)


const remove = (data) => {
    emit('remove', data)
}

onMounted(() => {
    new draggable({
        element: $('#virtualMsgList'),
        containment: "parent",
    });
})
</script>

<style scoped lang="less">
#virtualMsgList {
    background-color: transparent;
    width: 330px;
    top: 20px;
    max-height: calc(100% - 60px);
    overflow-y: auto;
    position: absolute;
    right: 0;
    padding: 10px;
    z-index: 999;
    pointer-events: none;

    &::-webkit-scrollbar {
        display: none;
    }

    .listItem {
        pointer-events: all;
        border-radius: 4px;
        width: 98%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        color: var(--phm-theme-title);
        // color: var(--phm-primary-color);
        background-color: var(--phm-theme-card);
        box-shadow: 2px 4px 8px 1px var(--phm-primary-light-color);
        align-items: center;
        font-size: 14px;
        border: 1px solid var(--phm-theme-border);
        height: 40px;
        padding: 8px;
        margin-bottom: 8px;

        .name {
            width: 250px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;

            &.warning {
                color: #f19b18;
            }

            &.danger {
                color: #f56c6c;
            }

            &.success {
                color: #67c23a;
            }

            &.info {
                color: var(--phm-primary-color);
            }
        }

        .el-icon {
            cursor: pointer;
            color: var(--phm-theme-tip);

            &.first {
                margin-right: 4px;
                color: var(--phm-primary-color);

                &.warning {
                    color: #f19b18;
                }

                &.danger {
                    color: #f56c6c;
                }

                &.success {
                    color: #67c23a;
                }

                &.info {
                    color: var(--phm-primary-color);
                }
            }
        }
    }
}
</style>
