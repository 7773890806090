import { AlgorithmTypeEnum } from "@components/js/utils/model";
import { DataPoint, PolarDataPoint } from "@core/graphs/webcharts/Dh-webchart-models";
import { getEnvelopData } from "@core/js/clientRealContext";
import { EnumXDataType } from "@core/js/dataParserContext";
import { getPointTimeInterval } from "@core/js/recordDataHistoryContext";
import { jsonToUpperCase } from "./grpcApi";

export const parseHistoryPointDataReply = (pointDatas) => {
    if (!pointDatas?.length > 0) return;
    const dataRet = [];
    pointDatas.forEach(pointData => {
        if (!pointData.datas) return;
        const data = {
            PointId: pointData.pointId,
            AlgorithmType: pointData.dspType,
            RotateSpeed: getRoateSpeed(pointData),
            SampleTime: Number(pointData.sampleTime),
            CursorInfo: jsonToUpperCase(pointData.cursorInfo),
            Eigens: [],
            AlarmParams: pointData.alarmParams,
            BlockSize: pointData.blockSize,
            SampleFreq: pointData.freq,
            DataOffset: 0
        }
        if (pointData.eigenType) {
            data.Eigens.push({
                EigenType: pointData.eigenType.key,
                EigenName: pointData.eigenType.value,
                Unit: pointData.eigenType.unit
            });
            data.EigenType = jsonToUpperCase(pointData.eigenType);
        }
        if (pointData.param) {
            data.Param = {
                FullValue: pointData.param.fullValue,
                IsObserver: pointData.param.isObserver,
                Name: pointData.param.name,
                Unit: pointData.param.unit,
                Extensions: pointData.param.extensions,
                SampleType: pointData.sampleType
            }
            if (pointData.param.xFullValue) {
                data.Param.XFullValue = pointData.param.xFullValue;
            }
            if (pointData.param.yFullValue) {
                data.Param.YFullValue = pointData.param.yFullValue;
            }
            if (pointData.param.xUnit) {
                data.Param.XUnit = pointData.param.xUnit;
            }
            if (pointData.param.yUnit) {
                data.Param.YUnit = pointData.param.yUnit;
            }
        }
        data.Datas = parseDatas(pointData);
        dataRet.push(data);
    });
    if (dataRet.find(r => r.AlgorithmType === 14 || r.AlgorithmType === 106)) {
        return getEnvelopData(dataRet);
    }
    return dataRet;
}

const createHistoryPointDatasReply = (pointData) => {
    const data = {
        PointId: pointData.pointId,
        AlgorithmType: pointData.dspType,
        RotateSpeed: pointData.rotateSpeed?.value,
        CursorInfo: jsonToUpperCase(pointData.cursorInfo),
        SampleFreq: pointData.freq,
        Param: {
            FullValue: pointData.param.fullValue,
            IsObserver: pointData.param.isObserver,
            Name: pointData.param.name,
            Unit: pointData.param.unit
        },
        Datas: {
            XDataType: EnumXDataType.Time,
            Points: [],
            DataCount: 0
        },
    }
    if (pointData.fFTDatas) {
        data.FFTDatas = parseDatas(pointData.fFTDatas);
    }
    return data;
}

export const processHistoryPointDatasReply = (pointData) => {
    const data = createHistoryPointDatasReply(pointData);
    pointData.pointDatas.forEach(point => {
        point.dspType = pointData.dspType;
        point.freq = pointData.freq;
        point.dspParam = pointData.dspParam;
        point.pointParam = pointData.pointParam;
        point.rotateSpeed = pointData.rotateSpeed;
        point.sampleType = pointData.sampleType;
        const temp = parseDatas(point);
        temp.Points.forEach(d => data.Datas.Points.push(d));
        data.Datas.XDataType = temp.XDataType;
    });
    data.Datas.DataCount = data.Datas.Points.length;
    return [data];
}

export const processEnvelopPointDatasReply = (pointData) => {
    const data = createHistoryPointDatasReply(pointData);
    const lowerData = [];
    const uperData = [];
    pointData.pointDatas.forEach(point => {
        point.dspType = pointData.dspType;
        point.freq = pointData.freq;
        point.dspParam = pointData.dspParam;
        point.pointParam = pointData.pointParam;
        point.rotateSpeed = pointData.rotateSpeed;
        point.sampleType = pointData.sampleType;
        const temp = getSegmentEnvelopData(point);
        temp[0].forEach(d => lowerData.push(d));
        temp[1].forEach(d => uperData.push(d));
    });
    lowerData.forEach(d => {
        data.Datas.Points.push(d);
    });
    uperData.forEach(d => {
        data.Datas.Points.push(d);
    });
    data.Datas.DataCount = data.Datas.Points.length;
    return getEnvelopData([data]);
}

export const processBodeDataReply = (response) => {
    if (!response?.datas?.length > 0) return;

    const processedDataList = [];

    for (const pointData of response.datas) {
        const processedData = {
            PointId: pointData.pointId,
            SampleTime: Number(new Date()),
            Param: {
                FullValue: pointData.param.fullValue,
                IsObserver: pointData.param.isObserver,
                Name: pointData.param.name,
                Unit: pointData.param.unit,
                Extensions: pointData.param.extensions,
            },
            Datas: {
                XDataType: EnumXDataType.Number,
                DataCount: 0,
                AmpDatas: {
                    PointId: pointData.pointId,
                    Datas: getXYDataPoints(pointData.ampDatas)
                },
                PhaseDatas: {
                    PointId: pointData.pointId,
                    Datas: getXYDataPoints(pointData.phaseDatas)
                },
            }
        };

        processedDataList.push(processedData);
    }

    return processedDataList;
}

export const processAxisTrailDataReply = (response) => {
    if (!response?.datas?.length > 0) return;
    let processedDataList = [];
    for (const pointData of response.datas) {
        const processedData = {
            PointId: pointData.pointId,
            SampleTime: Number(pointData.sampleTime),
            Param: {
                FullValue: pointData.param.fullValue,
                Name: pointData.param.name,
                XUnit: pointData.param.xUnit,
                YUnit: pointData.param.yUnit,
                PointId: pointData.param.pointId,
                XFullValue: pointData.param.xFullValue,
                XName: pointData.param.xName,
                XPointId: pointData.param.xPointId,
                YFullValue: pointData.param.yFullValue,
                YName: pointData.param.yName,
                YPointId: pointData.param.yPointId,
            },
            Datas: {
                XDataType: EnumXDataType.Number,
                DataCount: 0,
                TDatas: [],
                XDatas: parseHistoryPointDataReply([pointData.xDatas])[0],
                YDatas: parseHistoryPointDataReply([pointData.yDatas])[0],
            }
        };

        processedData.Datas.TDatas = getXYDataPoints(pointData.tDatas).Points;

        processedDataList.push(processedData);
    }

    return processedDataList;
}

export const processPolarDataReply = (response) => {
    if (!response?.datas?.length > 0) return;

    let processedDataList = [];

    for (const pointData of response.datas) {
        if (!pointData.datas) continue;
        const processedData = {
            PointId: pointData.pointId,
            Param: {
                FullValue: pointData.param.fullValue,
                IsObserver: pointData.param.isObserver,
                Name: pointData.param.name,
                Unit: pointData.param.unit,
            },
            Datas: {
                XDataType: EnumXDataType.Number,
                DataCount: 0,
                Points: [],
            }
        };

        processedData.Datas.Points = new Array();
        for (let y = 0; y < pointData.datas.length; y++) {
            const upperCaseData = jsonToUpperCase(pointData.datas[y]);
            processedData.Datas.Points[y] = new PolarDataPoint(upperCaseData.Real, upperCaseData.Imaginary, upperCaseData);
        }

        processedDataList.push(processedData);
    }


    return processedDataList;
}

export const processTorvibHistoryDataReplay = (response) => {
    if (!response?.datas?.length > 0) return;

    const processedDataList = [];

    for (const pointData of response.datas) {
        pointData.datas.freq = pointData.datas.sampleFreq;
        pointData.fFTDatas.freq = pointData.datas.sampleFreq;
        const processedData = {
            PointId: pointData.pointId,
            ODSFrequency: pointData.oDSFrequency,
            ODSValue: pointData.oDSValue,
            Param: {
                Extensions: pointData.param.extensions,
                FullValue: pointData.param.fullValue,
                IsObserver: pointData.param.isObserver,
                Name: pointData.param.name,
                Unit: pointData.param.unit,
            },
            Datas: {
                threshold: pointData.datas.threshold,
                Datas: parseDatas(pointData.datas),
            },
            FFTDatas: {
                Datas: parseDatas(pointData.fFTDatas),
            }
        };
        processedDataList.push(processedData);
    }

    return processedDataList;
}

export const parseHistoryDilutionDataReply = (pointDatas) => {
    if (!pointDatas?.length > 0) return;
    const dataRet = [];
    pointDatas.forEach(pointData => {
        const data = {
            PointId: pointData.pointId,
            StartTime: Number(pointData.startTime) / 10000,
            Datas: {
                XDataType: EnumXDataType.Time,
                DataCount: 0,
                Points: [],
                Level: pointData.diluteLevel
            },
            DiluteDatas: new Map(),
        }
        if (pointData.dataParam) {
            data.Param = {
                FullValue: pointData.dataParam.fullValue,
                IsObserver: pointData.dataParam.isObserver,
                Name: pointData.dataParam.name,
                Unit: pointData.dataParam.unit,
                SampleType: 0
            }
        }
        if (pointData.diluteLevel > 0) {
            data.DiluteDatas.set(pointData.diluteLevel, {
                XDataType: EnumXDataType.Time,
                DataCount: 0,
                Points: [],
                Level: pointData.diluteLevel
            });
        }
        if (pointData.diluteDataSegments?.length > 0) {
            pointData.diluteDataSegments.forEach(segment => {
                segment.dspType = pointData.dspType;
                segment.dspParam = pointData.dspParam;
                segment.freq = segment.sampleFreq;
                segment.pointParam = pointData.pointParam;
                segment.rotateSpeed = 60;
                segment.sampleType = 0;
                const waveData = parseDatas(segment);
                waveData.Level = pointData.diluteLevel;
                if (segment.isContinous == false && waveData.DataCount > 0) waveData.Points[0].Break = true;
                if (data.Datas.Level > 0) {
                    if (data.DiluteDatas.has(data.Datas.Level)) {
                        const diluteData = data.DiluteDatas.get(data.Datas.Level);
                        diluteData.Points.push(...waveData.Points);
                        diluteData.DataCount += waveData.DataCount;
                        diluteData.XDataType = waveData.XDataType;
                    } else {
                        data.DiluteDatas.set(data.Datas.Level, waveData);
                    }
                } else {
                    data.Datas.Points.push(...waveData.Points);
                    data.Datas.DataCount += waveData.DataCount;
                    data.Datas.XDataType = waveData.XDataType;
                }
                data.SampleFreq = segment.sampleFreq;
            });
        }
        dataRet.push(data);
    });
    return dataRet;
}

const parseDatas = (pointData) => {
    switch (pointData.datas.inner.oneofKind) {
        case "yDatas":
            return getYDatas(pointData);
        case "barDatas":
            return getBarDatas(pointData);
        case "trendDatas":
            return getTrendDatas(pointData);
        case "rainFlowDatas":
            return getRainFlowDatas(pointData);
        case "xYDatas":
            return getXYData(pointData);
        case "extensionEigenDatas":
            return getExtensionEigenDatas(pointData);
        case "diluteDatas":
            return getDiluteDatas(pointData);
    }
}

const getYDatas = (pointData) => {
    switch (pointData.dspType) {
        case AlgorithmTypeEnum.SpectrumAnalysis:
        case AlgorithmTypeEnum.OrderAnalysis:
        case AlgorithmTypeEnum.CableAnalysis:
            return getFFTDatas(pointData);
        case AlgorithmTypeEnum.CrossPowerSpectrumDensity:
            return getCrossPowerSpectrumDatas(pointData);
        case AlgorithmTypeEnum.Cepstrum:
            if (pointData.sampleType == 1) {
                return getCycleOrginDatas(pointData);
            }
            return getCepstrumDatas(pointData);
        case AlgorithmTypeEnum.EnvelopeAnalysis:
            return getEnvelopDataEx(pointData);
        case AlgorithmTypeEnum.EnvelopeSpectrum:
            return getEnvelopSpectrumData(pointData);
        case AlgorithmTypeEnum.CorrelationAnalysis:
            return getCorrelationData(pointData);
        case AlgorithmTypeEnum.VirtualCompute:
            const dsp = getDspParam(pointData);
            if (dsp.domainType > 0) {
                dsp.analysisCount = (pointData.datas.dataCount - 1) * 2.56;
                return getFFTDatas(pointData);
            }
        default:
            break;
    }
    if (pointData.sampleType == 1) {
        return getCycleOrginDatas(pointData);
    }
    return getOrginDatas(pointData);
}

const getDspParam = (pointData) => {
    return pointData.dspParam.inner[pointData.dspParam.inner.oneofKind];
}

const getDataEntity = (pointData) => {
    return pointData.datas.inner[pointData.datas.inner.oneofKind];
}

const getAnalysisPointCount = (pointData) => {
    const dspParam = getDspParam(pointData);
    if (dspParam) {
        return dspParam.analysisCount > 64 ? dspParam.analysisCount : 64 * parseInt(Math.pow(2, dspParam.analysisCount));
    }
    return 1000;
}

const getDivision = (pointData) => {
    if (pointData.sampleType == 1) {
        return 60;
    }
    const dspParam = getDspParam(pointData);
    if (dspParam) {
        const result = dspParam.freqUnit == 1 ? (dspParam.useSpeed ? pointData.rotateSpeed?.value ?? dspParam.roteSpeed : 60) : 60;
        if (result == 0) {
            // 转速为0情况
            return 1;
        }
        return result;
    }
    return 60;

}

const getRoateSpeed = (pointData) => {
    if (pointData.rotateSpeed?.value) return pointData.rotateSpeed.value;
    if (pointData.dspParam) {
        const dspParam = getDspParam(pointData);
        const result = dspParam.useSpeed ? dspParam.roteSpeed : 0;
        if (result == 0) {
            // 转速为0情况
            return 1;
        }
        return result;
    }
    return 0;
}

const getOrginDatas = (pointData) => {
    const isTorv = pointData.sampleType == 11;
    const dataRet = {
        XDataType: isTorv ? EnumXDataType.Number : EnumXDataType.Time,
        DataCount: pointData.datas.dataCount,
        Points: new Array(pointData.datas.dataCount),
    }
    const dataEntity = getDataEntity(pointData);
    const diff = isTorv ? (360 / pointData.blockSize) : (1E7 / pointData.freq / 10000);
    const start = isTorv ? 0 : Number(pointData.sampleTime) / 10000;
    for (let y = 0; y < dataRet.DataCount; y++) {
        const x = start + diff * y;
        dataRet.Points[y] = {
            X: x,
            Y: dataEntity.datas[y]
        }
    }
    return dataRet;
}

const getCycleOrginDatas = (pointData) => {
    const pointParam = pointData.pointParam.inner[pointData.pointParam.inner.oneofKind];
    const cycleNumber = pointParam.cycleNumber || 1;
    const diff = 1 / pointParam.pointNumberPerCycle;
    const start = 0;
    const dataRet = {
        XDataType: EnumXDataType.Number,
        DataCount: pointData.datas.dataCount,
        Points: new Array(pointData.datas.dataCount),
        XMax: cycleNumber
    }
    const dataEntity = getDataEntity(pointData);
    dataRet.Points = new Array(pointData.datas.dataCount);
    for (let y = 0; y < pointData.datas.dataCount; y++) {
        const x = start + diff * y;
        dataRet.Points[y] = {
            X: x,
            Y: dataEntity.datas[y]
        }
    }
    return dataRet;
}

const getFFTDatas = (pointData) => {
    const analysisPoint = getAnalysisPointCount(pointData);
    let xDiff = 1;

    if (pointData.sampleType == 1) {
        const pointParam = pointData.pointParam.inner[pointData.pointParam.inner.oneofKind];
        xDiff = pointParam.pointNumberPerCycle / analysisPoint;
    } else {
        xDiff = pointData.freq / analysisPoint;
    }
    const div = getDivision(pointData);
    const maxIndex = parseInt(analysisPoint / 2.56) + 1;
    const dataRet = {
        XDataType: EnumXDataType.Number,
        DataCount: maxIndex,
        Points: new Array(maxIndex),
    }
    const start = 0;
    const diff = xDiff / div * 60;
    const dataEntity = getDataEntity(pointData);
    for (let y = 0; y < maxIndex; y++) {
        const x = start + diff * y;
        dataRet.Points[y] = {
            X: x,
            Y: dataEntity.datas[y]
        }
    }
    return dataRet;
}

const getCrossPowerSpectrumDatas = (pointData) => {
    const dataRet = {
        XDataType: EnumXDataType.Number,
        DataCount: pointData.datas.dataCount,
        Points: new Array(pointData.datas.dataCount),
    }
    const dspParam = getDspParam(pointData) || { analysisPointCount: 128 };
    const analysisPointCount = dspParam.analysisPointCount > 64 ? dspParam.analysisPointCount : 64 * parseInt(Math.pow(2, dspParam.analysisPointCount));
    const dataEntity = getDataEntity(pointData);
    let diff = 1;
    if (pointData.sampleType == 1) {
        const pointParam = pointData.pointParam.inner[pointData.pointParam.inner.oneofKind];
        diff = pointParam.pointNumberPerCycle / analysisPointCount;
    } else {
        diff = pointData.freq / analysisPointCount;
    }
    const start = 0;
    for (let y = 0; y < dataRet.DataCount; y++) {
        const x = start + diff * y;
        dataRet.Points[y] = {
            X: x,
            Y: dataEntity.datas[y]
        }
    }
    return dataRet;
}

const getCepstrumDatas = (pointData) => {
    const diff = 1E7 / pointData.freq / 1E4 / 1E3;
    const start = 0;
    const dataRet = {
        XDataType: EnumXDataType.Number,
        DataCount: pointData.datas.dataCount,
        Points: new Array(pointData.datas.dataCount),
    }
    const dataEntity = getDataEntity(pointData);
    for (let y = 0; y < dataRet.DataCount; y++) {
        const x = start + diff * y;
        dataRet.Points[y] = {
            X: x,
            Y: dataEntity.datas[y]
        }
    }
    return dataRet;
}

const getEnvelopDataEx = (pointData) => {
    const point = {
        XDataType: EnumXDataType.Time,
        DataCount: pointData.datas.dataCount,
        Points: new Array(pointData.datas.dataCount),
    };
    const count = pointData.datas.dataCount / 2;
    const diff = 1E7 / pointData.freq / 10000;
    const start = Number(pointData.sampleTime) / 10000;
    const dataEntity = getDataEntity(pointData);
    for (let y = 0; y < count; y++) {
        const x = start + diff * y;
        point.Points[y] = {
            X: x,
            Y: dataEntity.datas[y]
        }
        point.Points[y + count] = {
            X: x,
            Y: dataEntity.datas[y + count]
        }
    }
    return point;
}

const getSegmentEnvelopData = (pointData) => {
    const count = pointData.datas.dataCount / 2;
    const diff = 1E7 / pointData.freq / 10000;
    const start = Number(pointData.sampleTime) / 10000;
    const dataEntity = getDataEntity(pointData);
    const lowerData = [];
    const uperData = [];
    for (let y = 0; y < count; y++) {
        const x = start + diff * y;
        lowerData.push({
            X: x,
            Y: dataEntity.datas[y]
        });
        uperData.push({
            X: x,
            Y: dataEntity.datas[y + count]
        });
    }
    return [lowerData, uperData];
}

const getEnvelopSpectrumData = (pointData) => {
    const point = {
        XDataType: EnumXDataType.Time,
        DataCount: pointData.datas.dataCount,
        Points: new Array(pointData.datas.dataCount),
    };
    const dataEntity = getDataEntity(pointData);
    const count = pointData.datas.dataCount / 2;
    const analysisPointCount = (count - 1) * 2.56;
    let diff = 1;
    if (pointData.sampleType == 1) {
        const pointParam = pointData.pointParam.inner[pointData.pointParam.inner.oneofKind];
        diff = pointParam.pointNumberPerCycle / analysisPointCount;
    } else {
        diff = pointData.freq / analysisPointCount;
    }
    const start = 0;
    for (let y = 0; y < count; y++) {
        const x = start + diff * y;
        point.Points[y] = {
            X: x,
            Y: dataEntity.datas[y]
        }
        point.Points[y + count] = {
            X: x,
            Y: dataEntity.datas[y + count]
        }
    }
    return point;
}

const getCorrelationData = (pointData) => {
    const dataRet = {
        XDataType: EnumXDataType.Time,
        DataCount: pointData.datas.dataCount,
        Points: new Array(pointData.datas.dataCount),
    }
    const length = parseInt((pointData.datas.dataCount - 1) / 2);
    const diff = 1E6 / pointData.freq;
    const start = -length * diff;
    dataRet.Points = new Array(pointData.datas.dataCount);
    const dataEntity = getDataEntity(pointData);
    for (let y = 0; y < pointData.datas.dataCount; y++) {
        const x = start + diff * y;
        dataRet.Points[y] = {
            X: x / 10000,
            Y: dataEntity.datas[y]
        }
    }
    return dataRet;
}

const getExtensionEigenDatas = (pointData) => {
    const xcofe = 10000;
    const dataEntity = getDataEntity(pointData);
    const dataRet = {
        XDataType: EnumXDataType.Time,
        DataCount: pointData.datas.dataCount,
        Points: new Array(pointData.datas.dataCount),
    }
    for (let y = 0; y < dataRet.DataCount; y++) {
        dataRet.Points[y] = new DataPoint(Number(dataEntity.datas[y].x) / xcofe, dataEntity.datas[y].y);
    }
    return dataRet;
}

const getXYData = (pointData) => {
    const count = pointData.datas.dataCount / 2;
    const dataRet = {
        XDataType: EnumXDataType.Number,
        DataCount: count,
        Points: new Array(count),
    }
    const dataEntity = getDataEntity(pointData);
    for (let y = 0; y < pointData.datas.dataCount; y += 2) {
        dataRet.Points[y >> 1] = {
            X: dataEntity.datas[y],
            Y: dataEntity.datas[y + 1]
        }
    }
    return dataRet;
}

const getDiluteDatas = (pointData) => {
    const dataRet = {
        XDataType: EnumXDataType.Time,
        DataCount: 0,
        Points: new Array(),
    }
    const dataEntity = getDataEntity(pointData);
    dataEntity.datas?.forEach(data => {
        const x = Number(data.time) / 10000;
        dataRet.Points.push({ X: x, Y: data.minValue });
        dataRet.Points.push({ X: x, Y: data.maxValue });
    });
    dataRet.DataCount = dataRet.Points.length;
    return dataRet;
}

const getBarDatas = (pointData) => {
    const diff = 1E7 / pointData.freq;
    const dspParam = getDspParam(pointData) || { analysisPointCount: 1 };
    const dataRet = {
        Datas: jsonToUpperCase(getDataEntity(pointData)).Datas,
        DataCount: pointData.datas.dataCount,
        StartTime: Number(pointData.sampleTime),
        EndTime: Number(pointData.sampleTime) + (dspParam.analysisPointCount - 1) * diff,
        XDataType: EnumXDataType.Time,
    }
    return dataRet;
}

const getTrendDatas = (pointData) => {
    const xcofe = 10000;
    const dataEntity = getDataEntity(pointData);
    const dataRet = {
        XDataType: EnumXDataType.Time,
        DataCount: pointData.datas.dataCount,
        Points: new Array(pointData.datas.dataCount),
    }
    for (let y = 0; y < dataRet.DataCount; y++) {
        dataRet.Points[y] = new DataPoint(Number(dataEntity.datas[y].x) / xcofe, dataEntity.datas[y].y);
    }
    dataRet.Index = dataEntity.index;
    return dataRet;
}

const getRainFlowDatas = () => {
    return {
        XDataType: EnumXDataType.Number,
        DataCount: 0,
        Points: [],
    }
}

const getXYDataPoints = (data) => {
    const count = data.dataCount / 2;
    const dataRet = {
        XDataType: EnumXDataType.Number,
        DataCount: count,
        Points: new Array(count),
    }
    const dataEntity = data.inner[data.inner.oneofKind];
    for (let y = 0; y < data.dataCount; y += 2) {
        dataRet.Points[y >> 1] = {
            X: dataEntity.datas[y],
            Y: dataEntity.datas[y + 1]
        }
    }
    return dataRet;
}