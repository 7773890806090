import { Point, Rect, XAxisShowType, XAxisType, YAxisType } from "@core/graphs/webcharts/Dh-webchart-models";
import { EnumXDataType } from "@core/js/dataParserContext";
import dayjs from "dayjs";
import { bignumber, subtract, divide, round } from "mathjs";
import { TableCoordinateSystem } from "./Dh-coordsystem-base";

export class TimeXCoordinateSystem extends TableCoordinateSystem {
    constructor(id) {
        super(id);
        this.XAxisTextStyle = " normal 11px Arial";
        this.XLeftOffset = 10;
        this.XRightOffset = -15;
        this.XAxisShowType = XAxisShowType.ShowDate;
        this.XAxisType = XAxisType.Absolute;
        this.StartTime = null;
    }
    OnMeasure() {
        this.EnsureContainerCreated();
        this.MeasureGrid();
        this.MeasureRows();
        this.MeasureColumns();
    }
    //获取测量时间尺寸
    MeasureTime(date) {
        if (this.XAxisType == XAxisType.Absolute && this.XAxisShowType == XAxisShowType.ShowDate) {
            if (date == null || typeof date != "string") {
                return { Width: 0, Height: 0 };
            }
            let dateStrArray = date.split(" ");
            let sizeDate = this.drawApi.MeasureText(dateStrArray[0], this.FontStyle, this.FontStyle);
            let sizeTime = this.drawApi.MeasureText(dateStrArray[1], this.FontStyle, this.FontStyle);
            let wd = sizeDate.Width > sizeTime.Width ? sizeDate.Width : sizeTime.Width;
            let ht = sizeDate.Height * 2;
            return { Width: wd, Height: ht };
        } else {
            let sizeTime = this.drawApi.MeasureText(date, this.FontStyle, this.FontStyle);
            let wd = sizeTime.Width;
            let ht = sizeTime.Height;
            return { Width: wd, Height: ht + 5 };
        }
    }
    //格式化X方向文字
    FormatXText(text) {
        if (this.XAxisType == XAxisType.Absolute) {
            let date = dayjs(parseInt(text));
            let str = "";
            if (this.XAxisShowType == XAxisShowType.ShowDate) {
                str = date.format("YYYY-MM-DD HH:mm:ss.SSS");
            } else {
                str = date.format("HH:mm:ss.SSS");
            }
            let decimalPre = parseFloat(this.XDecimalPrecision);
            if (decimalPre >= 3) decimalPre = 4;
            if (decimalPre < 3 && decimalPre > 0) decimalPre = decimalPre + 1;
            if (decimalPre < 0) decimalPre = 0;
            str = str.substring(0, str.indexOf(".") + decimalPre);
            return str;
        } else {
            let info = this.Owner.Information;
            let xmin = info.CoordinateInfo.OriginXMin;
            if (this.StartTime) xmin = this.StartTime;
            let milliseconds = subtract(bignumber(text), bignumber(xmin));
            if (this.XAxisShowType == XAxisShowType.ShowS) {
                return round(divide(milliseconds, 1000), this.XDecimalPrecision);
            } else {
                let dateStr = round(divide(milliseconds, 1000), this.XDecimalPrecision);
                let dateStrArray = dateStr.toString().split(".");
                let day = dayjs("2000-12-12 00:00:00").add(Number(dateStrArray[0]), "second").format("HH:mm:ss");
                return dateStrArray.length < 2 ? day : `${day}.${dateStrArray[1]}`;
            }
        }
    }
    FormatYText(text) {
        let info = this.Owner.Information;
        if (info.YAxisType == YAxisType.Text) {
            for (const item of info.YAxisItems.values()) {
                for (const t of item) {
                    if (t.Value == text) return t.Name;
                }
            }
            return text;
        } else {
            return super.FormatYText(text);
        }
    }
    //获取X方向文字尺寸
    GetXTextSize(text) {
        return this.MeasureTime(text);
    }
    //绘制X方向坐标
    DrawXText(s, x, y, fontColor, fontStyle) {
        if (this.XAxisType == XAxisType.Absolute && this.XAxisShowType == XAxisShowType.ShowDate) {
            if (s == null || typeof s != "string") {
                return;
            }
            let dateStrArray = s.split(" ");
            let sizeDate = this.drawApi.MeasureText(dateStrArray[0], fontStyle, fontStyle);
            let sizeTime = this.drawApi.MeasureText(dateStrArray[1], fontStyle, fontStyle);
            let w = sizeDate.Width - sizeTime.Width;
            this.drawApi.FillText(dateStrArray[0], x, y, fontColor, fontStyle);
            this.drawApi.FillText(dateStrArray[1], x + w / 2, y + sizeDate.Height + 2, fontColor, fontStyle);
        } else {
            this.drawApi.FillText(s, x, y + 5, fontColor, fontStyle);
        }
    }
    GetXOffsetColumn(c, column) {
        if (c == 0) return this.XLeftOffset;
        if (c == column) return this.XRightOffset;
        return 0;
    }
    GetXDemoSize() {
        if (this.ShowXText) {
            if (this.XAxisType == XAxisType.Absolute) {
                return this.GetXTextSize("1770-12-1 12:12:12", this.XAxisTextStyle);
            } else {
                return this.GetXTextSize("10000", this.XAxisTextStyle);
            }
        } else {
            return { Width: 0, Height: 0 };
        }
    }
    FormatXSpanText(timeSpan) {
        if (this.XAxisType == XAxisType.Relative && this.XAxisShowType == XAxisShowType.ShowS) {
            let time = timeSpan / 1000;
            return time.toFixed(this.XDecimalPrecision);
        } else {
            var str = this.getTimeStr(timeSpan);
            let decimalPre = parseFloat(this.XDecimalPrecision);
            if (decimalPre >= 3) decimalPre = 4;
            if (decimalPre < 3 && decimalPre > 0) decimalPre = decimalPre + 1;
            if (decimalPre < 0) decimalPre = 0;
            str = str.substring(0, str.indexOf(".") + decimalPre);
            return str;
        }
    }
    getTimeStr(times) {
        if (typeof times === "number") {
            if (times <= 0) {
                return "00:00:00.000";
            } else {
                let hour = times < 3600 * 1000 ? 0 : parseInt(times / 3600 / 1000);
                let left = times - hour * 3600 * 1000;
                let minute = left < 60 * 1000 ? 0 : parseInt(left / 60 / 1000);
                left = left - minute * 60 * 1000;
                let second = left < 1000 ? 0 : parseInt(left / 1000);
                let minsecond = left - second * 1000;
                return `${hour < 10 ? "0" + hour : hour}:${minute < 10 ? "0" + minute : minute}:${second < 10 ? "0" + second : second}.${this.getMiniSecondStr(minsecond).replace(".", "")}`;
            }
        }
        return "00:00:00.000";
    }
    getMiniSecondStr(ms) {
        if (ms > 100) return ms + "";
        if (ms > 10) return "0" + ms;
        if (ms > 0) return "00" + ms;
        return "000";
    }
    MeasureRows() {
        let info = this.Owner.Information;
        if (info.YAxisType == YAxisType.Text) {
            const yText = [];
            const items = new Map();
            for (const item of info.YAxisItems.values()) {
                for (const t of item) {
                    if (!items.has(t.Value)) {
                        items.set(t.Value, t);
                    }
                }
            }
            const keys = items.getKeys().orderByDesc(p => p);
            for (let key of keys) {
                yText.push(items.get(key).Name);
            }
            this.YTexts = yText;
            this.Rows = this.YTexts.length - 1;
            info.YUnit = (info.CoordinateInfo.YMax - info.CoordinateInfo.YMin) / info.ShapeContainerRect.Height;
        } else {
            super.MeasureRows();
        }
    }
}


export class CustomCoordinateSystem extends TimeXCoordinateSystem {
    constructor(id) {
        super(id);
        this.XDataType = null;
    }
    GetXTextSize(text) {
        if (this.XDataType == EnumXDataType.Time) {
            return super.GetXTextSize(text);
        } else {
            return this.GetTextSize(text, this.XAxisTextStyle);
        }
    }
    FormatXText(text) {
        if (this.XDataType == EnumXDataType.Time) {
            return super.FormatXText(text);
        } else {
            return round(text, this.XDecimalPrecision);
        }
    }
    DrawXText(s, x, y, fontColor, fontStyle) {
        if (this.XDataType == EnumXDataType.Time) {
            super.DrawXText(s, x, y, fontColor, fontStyle);
        } else {
            this.drawApi.FillText(s, x, y + 4, fontColor, fontStyle);
        }
    }
    GetXOffsetColumn(c, column) {
        if (this.XDataType == EnumXDataType.Time) {
            return super.GetXOffsetColumn(c, column);
        } else {
            return 0;
        }
    }
    GetXDemoSize() {
        if (this.XDataType == EnumXDataType.Time) {
            return super.GetXDemoSize();
        } else {
            if (this.ShowXText) {
                return this.GetTextSize("Test", this.XAxisTextStyle);
            } else {
                return { Width: 0, Height: 0 };
            }
        }
    }
    GetSceenPoints(points) {
        let info = this.Owner.Information;
        let shapeRect = info.ShapeContainerRect;
        if (points?.length > 0) {
            let result = new Array();
            for (let i = 0; i < points.length; i++) {
                let d = points[i];
                let x = shapeRect.Left + (d.X - info.CoordinateInfo.XMin) / info.XUnit;
                let y = shapeRect.Top + shapeRect.Height - (d.Y - info.CoordinateInfo.YMin) / info.YUnit;
                result.push(new Point(x, y));
            }
            return result;
        }
    }
}


export class NavigateCoordinateSystem extends TimeXCoordinateSystem {
    constructor(id) {
        super(id);
        this.ShowEndPoint = true;
    }
    MeasureGrid() {
        let info = this.Owner.Information;
        info.ShapeContainerRect = new Rect();
        info.ShapeContainerRect.Left = info.Padding.Left;
        info.ShapeContainerRect.Top = info.Padding.Top;
        info.ShapeContainerRect.Width = info.ChartContainerRect.Width - info.ShapeContainerRect.Left - info.Padding.Right;
        info.ShapeContainerRect.Height = info.ChartContainerRect.Height - info.Padding.Top - info.Padding.Bottom;
    }
    MeasureRows() {
        let info = this.Owner.Information;
        info.YUnit = (info.CoordinateInfo.YMax - info.CoordinateInfo.YMin) / info.ShapeContainerRect.Height;
    }
    MeasureColumns() {
        let info = this.Owner.Information;
        info.XUnit = (info.CoordinateInfo.XMax - info.CoordinateInfo.XMin) / info.ShapeContainerRect.Width;
    }
    OnRender() {
        this.EnsureContainerCreated();
        this.DrawAxis();
        this.DrawOuterLines();
    }
    DrawAxis() {
        let info = this.Owner.Information;
        let rect = info.ShapeContainerRect;
        this.drawApi.BeginPath();
        this.drawApi.HorizontalBottomMoveTo(rect.Left, rect.Top + rect.Height);
        this.drawApi.HorizontalBottomLineTo(rect.Left + rect.Width, rect.Top + rect.Height);
        this.drawApi.Stroke(this.VerCrossColor, 1);
    }
    DrawOuterLines() {
        let info = this.Owner.Information;
        let rect = info.ShapeContainerRect;
        this.drawApi.BeginPath();
        this.drawApi.HorizontalTopMoveTo(rect.Left, rect.Top);
        this.drawApi.HorizontalTopLineTo(rect.Left + rect.Width, rect.Top);
        this.drawApi.Stroke(this.HorCrossColor, 1);
    }
    GetSceenPoints(points) {
        let info = this.Owner.Information;
        let shapeRect = info.ShapeContainerRect;
        if (points?.length > 0) {
            let result = new Array();
            for (let i = 0; i < points.length; i++) {
                let d = points[i];
                let x = shapeRect.Left + (d.X - info.CoordinateInfo.XMin) / info.XUnit;
                let y = shapeRect.Top + shapeRect.Height - (d.Y - info.CoordinateInfo.YMin) / info.YUnit;
                result.push(new Point(x, y));
            }
            return result;
        }
    }
}

export class RecorderNavigateCoordinateSystem extends NavigateCoordinateSystem {
    constructor(id) {
        super(id);
    }
    GetSceenPoints(points) {
        let info = this.Owner.Information;
        let shapeRect = info.ShapeContainerRect;
        if (points?.length > 0) {
            const result = new Array();
            for (let i = 0; i < points.length; i++) {
                const d = points[i];
                let x = shapeRect.Left + (d.X - info.CoordinateInfo.XMin) / info.XUnit;
                let y = shapeRect.Top + shapeRect.Height - (d.Y - info.CoordinateInfo.YMin) / info.YUnit;
                const p = new Point(x, y);
                if (d.Break) p.Break = d.Break;
                result.push(p);
            }
            return result;
        }
    }
}


export class TimeTableCoordinateSystem extends TableCoordinateSystem {
    constructor(id, xzangle, yzproportion) {
        super(id);
        this.XAxisShowType = XAxisShowType.ShowTime;
        this.XZAngle = 30; //Z轴与X轴夹角
        if (xzangle != null) {
            this.XZAngle = xzangle;
        }
        this.YZProportion = 1 / 3; //Y:Z
        if (yzproportion != null) {
            this.YZProportion = yzproportion;
        }
        this.Rows = 0;
        this.Columns = 0;
        this.XTexts = null;
        this.YTexts = null;
    }
    OnRender() {
        this.EnsureContainerCreated();
        this.DrawTitle();

        this.DrawAxis();
        this.DrawFrameLines();
        this.DrawRule();
        this.DrawAxisText();
        this.DrawCrossLines();
        this.DrawTimeText();
        this.DrawUnit();
    }
    MeasureRows() {
        let info = this.Owner.Information;
        info.CurrentIndex = 0;
        let data = this.Owner.ShapeOperator.Data;

        let xscaleSize = this.GetXDemoSize();
        let xunitSize = this.GetTextSize("test", this.UnitTextStyle);
        let top = info.Padding.Top;
        let right = info.Padding.Right;
        let bottom = info.Padding.Bottom + xunitSize.Height + (this.ShowXRule ? this.RuleLength + xscaleSize.Height : 0);
        let left = info.Padding.Left;
        if (data != null && data.length > 0) {
            let rightTextSize = this.GetTimeTextSize();
            right = rightTextSize.Width;
        }

        let total_W = info.ChartContainerRect.Width;
        let total_H = info.ChartContainerRect.Height;

        let sinXZangle = Math.sin((this.XZAngle * Math.PI) / 180);
        let cosXZangle = Math.cos((this.XZAngle * Math.PI) / 180);
        let depth = (total_H - top - bottom) / (sinXZangle + this.YZProportion); //计算立方体深度
        let height = depth * this.YZProportion;
        let temH = depth * sinXZangle;
        let temW = depth * cosXZangle;

        //计算Y方向所有坐标并记录下值
        let ytext;
        if (this.GridSelfAdaption) {
            let rows = Math.floor(info.ShapeContainerRect.Height / this.DesignHeight);
            ytext = this.GetYScaleText(info.CoordinateInfo.YMax, info.CoordinateInfo.YMin, rows);
        } else {
            ytext = this.GetYScaleText(info.CoordinateInfo.YMax, info.CoordinateInfo.YMin, this.YGridNum);
        }
        this.YTexts = ytext.YTexts;
        if (this.YTexts != null && this.YTexts.length > 0) {
            this.Rows = this.YTexts.length - 1;
        } else {
            this.Rows = 1;
        }

        let maxlen = 0;
        for (let i = 0; i < this.YTexts.length; i++) {
            let temlen = this.drawApi.MeasureText(this.YTexts[i], this.FontStyle, this.FontStyle);
            if (maxlen < temlen.Height) {
                maxlen = temlen.Height;
            }
        }
        left = info.Padding.Left + (this.ShowYRule ? maxlen + this.RuleLength : 0);
        let width = total_W - left - right - temW;

        //坐标顶点信息
        info.VertexInfo.FrontLeftBottom.X = left;
        info.VertexInfo.FrontLeftBottom.Y = total_H - bottom;
        info.VertexInfo.FrontLeftTop.X = left;
        info.VertexInfo.FrontLeftTop.Y = top + temH;
        info.VertexInfo.FrontRightBottom.X = left + width;
        info.VertexInfo.FrontRightBottom.Y = total_H - bottom;
        info.VertexInfo.BackLeftBottom.X = left + temW;
        info.VertexInfo.BackLeftBottom.Y = top + height;
        info.VertexInfo.BackLeftTop.X = left + temW;
        info.VertexInfo.BackLeftTop.Y = top;
        info.VertexInfo.BackRightBottom.X = total_W - right;
        info.VertexInfo.BackRightBottom.Y = top + height;
        info.VertexInfo.BackRightTop.X = total_W - right;
        info.VertexInfo.BackRightTop.Y = top;
        info.VertexInfo.Width = width;
        info.VertexInfo.Height = height;
        info.VertexInfo.Depth = depth;
        info.VertexInfo.XZAngle = this.XZAngle;
        info.VertexInfo.YZProportion = this.YZProportion;
        //标尺
        info.ShapeContainerRect = new Rect(info.VertexInfo.FrontLeftTop.X, info.VertexInfo.FrontLeftTop.Y, width, height);
        info.YUnit = (info.CoordinateInfo.YMax - info.CoordinateInfo.YMin) / info.ShapeContainerRect.Height;
        info.XUnit = (info.CoordinateInfo.XMax - info.CoordinateInfo.XMin) / info.ShapeContainerRect.Width;
    }
    //绘制X Y轴坐标横线
    DrawFrontLines(rect) {
        this.drawApi.BeginPath();
        this.drawApi.VerticalLeftMoveTo(rect.Left, rect.Top);
        this.drawApi.VerticalLeftLineTo(rect.Left, rect.Top + rect.Height);
        this.drawApi.Stroke(this.AxisColor, 1);

        this.drawApi.BeginPath();
        this.drawApi.HorizontalBottomMoveTo(rect.Left, rect.Top + rect.Height);
        this.drawApi.HorizontalBottomLineTo(rect.Left + rect.Width, rect.Top + rect.Height);
        this.drawApi.Stroke(this.AxisColor, 1);
    }
    //画框架
    DrawFrameLines() {
        //param类型：VertexInfoClass
        let info = this.Owner.Information;
        let param = info.VertexInfo;
        this.drawApi.BeginPath();
        this.drawApi.MoveTo(param.FrontLeftTop.X, param.FrontLeftTop.Y); //正面 左上角
        this.drawApi.LineTo(param.BackLeftTop.X, param.BackLeftTop.Y); //背面 左上角
        this.drawApi.LineTo(param.BackRightTop.X, param.BackRightTop.Y); //背面 右上角
        this.drawApi.LineTo(param.BackRightBottom.X, param.BackRightBottom.Y); //背面 右下角
        this.drawApi.LineTo(param.FrontRightBottom.X, param.FrontRightBottom.Y); //正面 右下角
        this.drawApi.Stroke(this.HorCrossColor, 0.5);

        this.drawApi.BeginPath();
        this.drawApi.MoveTo(param.FrontLeftBottom.X, param.FrontLeftBottom.Y); //正面左下角
        this.drawApi.LineTo(param.BackLeftBottom.X, param.BackLeftBottom.Y); //背面 左下角
        this.drawApi.LineTo(param.BackLeftTop.X, param.BackLeftTop.Y); //背面 左上角

        this.drawApi.MoveTo(param.BackLeftBottom.X, param.BackLeftBottom.Y); //背面 左下角
        this.drawApi.LineTo(param.BackRightBottom.X, param.BackRightBottom.Y); //背面 右下角
        this.drawApi.Stroke(this.VerCrossColor, 1);
    }
    DrawCrossLines() {
        let info = this.Owner.Information;
        let param = info.VertexInfo;
        let rect = info.ShapeContainerRect;
        //画标尺上的虚线
        this.drawApi.BeginPath();
        let eachH = rect.Height / (this.YTexts.length - 1);
        for (let i = 1; i < this.YTexts.length - 1; i++) {
            let startX = param.FrontLeftBottom.X;
            let startY = param.FrontLeftBottom.Y - eachH * i;
            let centerX = param.BackLeftBottom.X;
            let centerY = param.BackLeftBottom.Y - eachH * i;
            let endX = param.BackRightBottom.X;
            let endY = param.BackLeftBottom.Y - eachH * i;
            this.drawApi.MoveTo(startX, startY);
            this.drawApi.LineTo(centerX, centerY);
            this.drawApi.LineTo(endX, endY);
        }
        this.drawApi.Stroke(this.HorCrossColor, 0.5);
    }
    DrawTimeText() {
        let data = this.Owner.ShapeOperator.Data;
        if (data == null || data.length == 0) return;
        let info = this.Owner.Information;
        let demoSize = this.GetTimeTextSize();
        let count = data.length;
        let zuint = info.VertexInfo.Depth / count;
        let sinXZangle = Math.sin((info.VertexInfo.XZAngle * Math.PI) / 180);
        let cosXZangle = Math.cos((info.VertexInfo.XZAngle * Math.PI) / 180);
        for (let i = 0; i < count; i++) {
            let y = info.VertexInfo.FrontRightBottom.Y - sinXZangle * zuint * i + demoSize.Height / 2;
            let x = info.VertexInfo.FrontRightBottom.X + cosXZangle * zuint * i + 5;
            let color = data[i].LineColor;
            let text = this.getXText(data[i].Time);
            this.drawApi.FillText(text, x, y, color, "");
        }
    }
    getXText(text) {
        if (this.XAxisShowType == XAxisShowType.ShowDate) {
            return text;
        } else if (this.XAxisShowType == XAxisShowType.ShowTime) {
            let dateStrArray = text.split(" ");
            return dateStrArray[1];
        } else {
            let dateStrArray = text.split(" ");
            let time = dateStrArray[1];
            return time.substring(time.indexOf(":") + 1);
        }
    }
    CalculatorZUint() {
        let info = this.Owner.Information;
        let totalcount = this.Owner.ShapeOperator.Data.length;
        info.VertexInfo.ZUint = info.VertexInfo.Depth / totalcount;
    }
    GetPositionByIndex(index) {
        const data = this.Owner.ShapeOperator.Data;
        const info = this.Owner.Information;
        const shapeRect = info.ShapeContainerRect;
        if (data == null || data.length == undefined || data.length == 0) return null;
        const pointId = this.GetSelectItem();
        const seletedPoint = data.find((r) => pointId != null && r.PointId == pointId);
        if (seletedPoint?.Points?.length > 0) {
            if (index >= seletedPoint.Points.length) index = seletedPoint.Points.length - 1;
            if (index < 0) index = 0;
            const pointOfSelect = seletedPoint.Points[index];
            const result = {
                Items: new Array(),
                OffsetX: info.CoordinateInfo.XMin ? (pointOfSelect.X - info.CoordinateInfo.XMin) / info.XUnit : (pointOfSelect.X - info.CoordinateInfo.XMin) / info.XUnit,
                X: pointOfSelect.X,
                XText: this.FormatXText(pointOfSelect.X),
                XIndex: index,
            };
            this.CalculatorZUint();
            for (let i = 0; i < data.length; i++) {
                let lineData = data[i];
                if (index < lineData.Points.length) {
                    let point = lineData.Points[index];
                    let xvalue = point.X;
                    let yvalue = point.Y;
                    let x = info.CoordinateInfo.XMin ? shapeRect.Left + (xvalue - info.CoordinateInfo.XMin) / info.XUnit : shapeRect.Left + (xvalue - info.CoordinateInfo.XMin) / info.XUnit;
                    let y = shapeRect.Top + shapeRect.Height - (yvalue - info.CoordinateInfo.YMin) / info.YUnit;
                    let sinXZangle = Math.sin((info.VertexInfo.XZAngle * Math.PI) / 180);
                    let cosXZangle = Math.cos((info.VertexInfo.XZAngle * Math.PI) / 180);
                    x += cosXZangle * info.VertexInfo.ZUint * i;
                    y -= sinXZangle * info.VertexInfo.ZUint * i;
                    result.Items.push({
                        OffsetY: y,
                        OffsetX: x,
                        PointId: lineData.PointId,
                        Text: lineData.Legend,
                        Tag: { Point: point, Source: lineData.Tag },
                        Y: this.GetYDescription(point),
                        Z: lineData.Time,
                    });
                }
            }
            return result;
        }
    }
    //获取离X最近的
    GetPositionData(offsetx) {
        const info = this.Owner.Information;
        const shapeRect = info.ShapeContainerRect;
        const data = this.Owner.ShapeOperator.Data;
        if (data?.length > 0 && offsetx >= 0 && offsetx <= shapeRect.Width) {
            const pointId = this.GetSelectItem();
            const point = data.find((r) => pointId != null && r.PointId == pointId);
            if (point?.Points.length > 0) {
                let index = this.BinarySearch(point.Points, offsetx, function (p) {
                    return (p.X - info.CoordinateInfo.XMin) / info.XUnit;
                });
                if (index >= point.Points.length) {
                    index = point.Points.length - 1;
                }
                if (index < 0) {
                    index = 0;
                }
                return this.GetPositionByIndex(index);
            }
        }
        return null;
    }
    GetPoints(series) {
        let info = this.Owner.Information;
        let shapeRect = info.ShapeContainerRect;
        if (series?.Points != null) {
            this.CalculatorZUint();
            let result = new Array();
            let sinXZangle = Math.sin((info.VertexInfo.XZAngle * Math.PI) / 180);
            let cosXZangle = Math.cos((info.VertexInfo.XZAngle * Math.PI) / 180);
            for (const d of series.Points) {
                let x = shapeRect.Left + (d.X - info.CoordinateInfo.XMin) / info.XUnit;
                let y = shapeRect.Top + shapeRect.Height - (d.Y - info.CoordinateInfo.YMin) / info.YUnit;
                x += cosXZangle * info.VertexInfo.ZUint * info.CurrentIndex;
                y -= sinXZangle * info.VertexInfo.ZUint * info.CurrentIndex;
                let p = new Point(x, y);
                result.push(p);
            }
            let left = shapeRect.Left + cosXZangle * info.VertexInfo.ZUint * info.CurrentIndex;
            let top = shapeRect.Top - sinXZangle * info.VertexInfo.ZUint * info.CurrentIndex;
            info.CurrentRect = new Rect(left - 2, top + 2, shapeRect.Width + 2, shapeRect.Height + 2);
            info.CurrentIndex++;
            return result;
        }
    }
    GetXDemoSize() {
        if (this.ShowXText) {
            return this.GetTimeTextSize();
        } else {
            return { Width: 0, Height: 0 };
        }
    }
    GetTimeTextSize() {
        if (this.XAxisShowType == XAxisShowType.ShowDate) {
            return this.GetTextSize("0000-00-00 00:00:00.000", this.XAxisTextStyle);
        } else if (this.XAxisShowType == XAxisShowType.ShowTime) {
            return this.GetTextSize("00:00:00.000", this.XAxisTextStyle);
        } else {
            return this.GetTextSize("00:00.000", this.XAxisTextStyle);
        }
    }
    GetXTextSize(text) {
        return this.GetTextSize(text, this.XAxisTextStyle);
    }
}
