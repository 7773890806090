import { KeyListenManager, ElementBase, PluginBase } from "@core/graphs/plugins/DH-plugin-base";
import $ from "jquery";
import { ruleCheck } from "@common/js/rules";
import { draggable } from "@core/graphs/webcharts/Dh-webchart-draggable";
import { ThemeResource } from "@components/js/utils/model";

export class CursorBase extends ElementBase {
    constructor(owner, width, height, linecolor, stroke, startPos, name) {
        super();
        this.parent = owner;
        this.IsMouseDown = false;
        this.Width = width;
        this.Height = height;
        this.StartPos = startPos;
        this.Name = name;
        this.DOM = null;
        this.Color = linecolor !== undefined ? linecolor : "black";
        this.Stroke = stroke != undefined ? stroke : 1;
        this.StopPropagation = true;
        this.mouseUpHandler = (e) => {
            $("body").off("mouseup", this.mouseUpHandler);
            this.parent.OnMouseUp?.(e);
            this.OnMouseUp(e);
        };
    }
    Init() { }
    OnMouseUp(e) {
        let plugins = $("div .cursor-window");
        $.each(plugins, function (i, item) {
            $(item).css("pointer-events", "auto");
        });
        this.IsMouseDown = false;
    }
    OnMouseDown(e) {
        if (e.which != 1) return;
        if (!this.IsSelected()) {
            this.Select();
        }
        if (this.StopPropagation) {
            e.stopPropagation();
        }
        this.IsMouseDown = true;

        KeyListenManager.getListenManager();
        window.KeyManager.CurrentElement = this.parent;
        let plugins = $("div .cursor-window"); //禁止光标和曲线信息插件的鼠标事件
        $.each(plugins, function (i, item) {
            $(item).css("pointer-events", "none");
        });
        $("body").off("mouseup", this.mouseUpHandler);
        $("body").on("mouseup", this.mouseUpHandler);
    }
    Select() {
        this.UnSelect();
        this.DOM.attr("isSelected", "true");
        this.DOM.children("text").css("fill", "#4991f5");
    }
    UnSelect() {
        let groups = $(this.DOM[0].parentNode).children("g");
        $.each(groups, function (i, g) {
            if ($(g).attr("isSelected")) {
                $(g).attr("isSelected", "false");
                $(g).children("text").css("fill", $(g).attr("cursor-color"));
            }
        });
    }
    Remove() {
        $(this.DOM).off("mousedown");
        //$(this.DOM).off('mouseup')
        this.DOM?.remove();
        $("body").off("mouseup", this.mouseUpHandler);
    }
    RegisterEvent() {
        let sender = this;
        if (this.parent.IsEnabled) {
            $(this.DOM).off("mousedown");
            $(this.DOM).on("mousedown", function (e) {
                sender.OnMouseDown(e);
            });
            $(this.DOM).on("touchstart", function (e) {
                sender.OnMouseDown(e);
            });
            //$(this.DOM).off('mouseup')
            // $(this.DOM).on('mouseup', function (e) {
            //     sender.OnMouseUp(e)
            // })
        }
    }
    IsSelected() {
        return this.DOM.attr("isSelected") == "true";
    }
}

//光标
export class CursorElement extends CursorBase {
    constructor(owner, height, linecolor, stroke, startPos, name) {
        super(owner, 11, height, linecolor, stroke, startPos, name);
        this.Height = height - 9;
        this.Points = new Array();
        this.LineStartPos = { X: this.StartPos.X, Y: this.StartPos.Y + 9 };
        this.StartPos.X = parseInt(this.StartPos.X) + 0.5 + 2;
        this.LineStartPos.X = parseInt(this.LineStartPos.X) + 1.5;
        this.Init();
    }
    Init() {
        let g = document.createElementNS("http://www.w3.org/2000/svg", "g");
        let line = document.createElementNS("http://www.w3.org/2000/svg", "line");
        let path = document.createElementNS("http://www.w3.org/2000/svg", "path");
        $(g)
            .attr("transform", "translate(1,0)")
            .attr("candrag", "true")
            .css("width", "9px")
            .css("height", "100%")
            .css("cursor", "e-resize")
            .attr("isSelected", "false")
            .attr("cursor-color", this.Color)
            .attr("aria-title", "");
        $(path)
            .attr("fill", "rgba(0,0,0,0)")
            .attr(
                "d",
                "M " +
                this.LineStartPos.X +
                " " +
                this.LineStartPos.Y +
                " L " +
                (this.LineStartPos.X + this.Width) +
                " " +
                this.LineStartPos.Y +
                " L " +
                (this.LineStartPos.X + this.Width) +
                " " +
                (this.LineStartPos.Y + this.Height) +
                " L " +
                this.LineStartPos.X +
                " " +
                (this.LineStartPos.Y + this.Height) +
                " L " +
                this.LineStartPos.X +
                " " +
                this.LineStartPos.Y +
                ""
            )
            .attr("cursor-rect", "true");
        $(line)
            .attr("stroke", this.Color)
            .attr("stroke-width", this.Stroke)
            .attr("x1", this.LineStartPos.X + 5)
            .attr("y1", this.LineStartPos.Y)
            .attr("x2", this.LineStartPos.X + 5)
            .attr("y2", this.Height + this.LineStartPos.Y);
        if (!ruleCheck.IsNullOrSpaceStr(this.Name)) {
            let name = document.createElementNS("http://www.w3.org/2000/svg", "text");
            $(name).text(this.Name);
            $(name)
                .attr("x", this.LineStartPos.X + 10)
                .attr("y", this.StartPos.Y + 12)
                .addClass("none-select")
                .css("font-family", "monospace");
            $(g).append(name);
        }
        $(g).append(line).append(path);
        $(g).append(this.AddRect(this.StartPos.X, this.StartPos.Y, 7, 7, this.Color));
        $(g).append(this.AddRect(this.StartPos.X + 1, this.StartPos.Y + 7, 5, 1, this.Color));
        $(g).append(this.AddRect(this.StartPos.X + 2, this.StartPos.Y + 8, 3, 1, this.Color));
        $(g).append(this.AddRect(this.StartPos.X + 3, this.StartPos.Y + 9, 1, 1, this.Color));

        this.DOM = $(g);
        this.RegisterEvent();
    }
    Remove() {
        this.ClearPoints();
        super.Remove();
    }
    DrawPoint(y) {
        let rect = this.parent.Owner.Information.ShapeContainerRect;
        let theme = this.parent.Owner.Option.Axis.Theme;
        const coordSystem = this.parent.Owner.CoordinateSystem;
        if (y - 3 > rect.Top && y - 3 <= rect.Height + rect.Top && coordSystem.DrawPoint) {
            let point = this.AddRect(this.LineStartPos.X + 2, y - 3, 6, 6, ThemeResource[theme].DotColor);
            this.Points.push(point);
            this.DOM.append(point);
        }
    }
    ClearPoints() {
        if (this.Points.length > 0) {
            $.each(this.Points, function (i, p) {
                p.remove();
            });
        }
        this.Points = new Array();
    }
}

export class CursorPluginBase extends PluginBase {
    constructor(mode) {
        super(mode);
        this.IsMouseDown = false;
        this.IsNotify = true;
        this.Title = null;
        this.LegendWnd = null;
        this.IsCursorChanged = false;
        this.onMouseUpHandler = (e) => {
            this.OnMouseUp(e);
        };
    }
    WacthCursorChanged(action) {
        this.IsCursorChanged = true;
        action?.();
        this.IsCursorChanged = false;
    }
    CreatePlugin() {
        let padding = this.Owner.Information.Padding;
        let rect = this.Owner.Information.ShapeContainerRect;
        let table =
            `<div class="cursor-window none-select" style="pointer-events:auto;position: absolute;left:${(rect.Left + 15)}px;top:${padding.Top}px">
                <table>
                    <tbody>
                        <tr>
                            <th style="white-space: nowrap;">
                                <div class="cursor-array"></div>
                                <span class="cursor-title">${this.Title}</span>
                            </th>
                        </tr>
                        <tr>
                            <td>
                                <div class="cursor-container"></div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>`;
        this.LegendWnd = $(table);
        $(this.Owner.ChartContainer).append(this.LegendWnd);
        if (this.IsEnabled) {
            new draggable({
                element: this.LegendWnd,
                containment: "parent",
            });
        }
        this.InitSvgEvent();
    }
    InitSvgEvent() {
        let sender = this;
        this.Owner.OnMouseDown.delete(this.Id);
        //this.Owner.OnMouseUp.delete(this.Id)
        this.Owner.OnMouseMove.delete(this.Id);
        this.Owner.OnTouchStart.delete(this.Id);
        this.Owner.OnTouchMove.delete(this.Id);

        this.Owner.OnMouseDown.set(this.Id, function (e) {
            sender.OnMouseDown(e);
        });
        //this.Owner.OnMouseUp.set(this.Id, function (e) {
        //sender.OnMouseUp(e)
        //})
        this.Owner.OnMouseMove.set(this.Id, function (e) {
            sender.OnMouseMove(e);
        });

        this.Owner.OnTouchStart.set(this.Id, function (e) {
            sender.OnTouchStart(e);
        });
        this.Owner.OnTouchMove.set(this.Id, function (e) {
            sender.OnTouchMove(e);
        });
    }
    OnSizeChanged() { }
    OnTouchStart(e) { }
    OnTouchMove(e) { }
    OnMouseDown(e) {
        if (e.which != 1) return false;
        if (window.event.ctrlKey) return false;
        if (!this.IsEnabled) return false;
        KeyListenManager.getListenManager();
        window.KeyManager.CurrentElement = this;
        let rect = this.Owner.Information.ShapeContainerRect;
        if (e.offsetY < rect.Top || e.offsetY > rect.Top + rect.Height) return false;
        if (e.offsetX < rect.Left - 4 || e.offsetX > rect.Left + rect.Width + 4) return false;
        $('body').off('mouseup', this.onMouseUpHandler);
        $('body').on('mouseup', this.onMouseUpHandler);
        return true;
    }
    OnMouseUp(e) {
        let plugins = $("div .cursor-window");
        $.each(plugins, function () {
            $(this).css("pointer-events", "auto");
        });
        if (!this.IsEnabled) return false;
        if (window.event.ctrlKey) return false;
        if (e.which != 1 && !e.touches) return false;
        $('body').off('mouseup', this.onMouseUpHandler);
        $('body').off('touchend', this.onMouseUpHandler);
        return true;
    }
    OnMouseMove(e) {
        if (!this.IsEnabled) return false;
        if (window.event.ctrlKey) return false;
        return true;
    }
    GetPosition() {
        return 0;
    }
    SetPosition(pos) { }
    GetCursorPosition() {
        return 0;
    }
    GetZoomPosition() {
        let info = this.Owner.Information;
        return (info.CoordinateInfo.XMin + info.CoordinateInfo.XMax) / 2;
    }
    getMousePostion(e) {
        let rect = this.Owner.Information.ShapeContainerRect;
        let c_x = e.offsetX;
        let offsetx = c_x - rect.Left;
        if (offsetx + 4 > rect.Width) {
            offsetx = rect.Width;
        }
        if (offsetx < 0) {
            offsetx = 0;
        }
        return offsetx;
    }
    Close() {
        super.Close();
        KeyListenManager.getListenManager();
        window.KeyManager.CurrentElement = null;
        this.Owner.OnMouseDown.delete(this.Id);
        //this.Owner.OnMouseUp.delete(this.Id);
        this.Owner.OnMouseMove.delete(this.Id);
        this.Owner.OnTouchStart.delete(this.Id);
        this.Owner.OnTouchMove.delete(this.Id);
        $('body').off('mouseup', this.onMouseUpHandler);
        $('body').off('touchend', this.onMouseUpHandler);
    }
    SetPositionByX() {
    }
}
