// @generated by protobuf-ts 2.9.1
// @generated from protobuf file "protos/alarmpointseigendatas.proto" (package "alarm", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { HistoryPointDataReply } from "./historypointdatareply";
import { AlarmPointEigensRequest } from "./alarmpointeigens";
/**
 * @generated from protobuf message alarm.QueryAlarmPointsEigenDatasRequest
 */
export interface QueryAlarmPointsEigenDatasRequest {
    /**
     * @generated from protobuf field: repeated alarm.AlarmPointEigensRequest PointEigens = 1 [json_name = "PointEigens"];
     */
    pointEigens: AlarmPointEigensRequest[];
    /**
     * @generated from protobuf field: string Time = 2 [json_name = "Time"];
     */
    time: string;
    /**
     * @generated from protobuf field: bool IsExternal = 3 [json_name = "IsExternal"];
     */
    isExternal: boolean;
}
/**
 * @generated from protobuf message alarm.QueryAlarmPointsEigenDatasReply
 */
export interface QueryAlarmPointsEigenDatasReply {
    /**
     * @generated from protobuf field: repeated storage.HistoryPointDataReply Points = 1 [json_name = "Points"];
     */
    points: HistoryPointDataReply[];
}
// @generated message type with reflection information, may provide speed optimized methods
class QueryAlarmPointsEigenDatasRequest$Type extends MessageType<QueryAlarmPointsEigenDatasRequest> {
    constructor() {
        super("alarm.QueryAlarmPointsEigenDatasRequest", [
            { no: 1, name: "PointEigens", kind: "message", jsonName: "PointEigens", repeat: 1 /*RepeatType.PACKED*/, T: () => AlarmPointEigensRequest },
            { no: 2, name: "Time", kind: "scalar", jsonName: "Time", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "IsExternal", kind: "scalar", jsonName: "IsExternal", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<QueryAlarmPointsEigenDatasRequest>): QueryAlarmPointsEigenDatasRequest {
        const message = { pointEigens: [], time: "", isExternal: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<QueryAlarmPointsEigenDatasRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: QueryAlarmPointsEigenDatasRequest): QueryAlarmPointsEigenDatasRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated alarm.AlarmPointEigensRequest PointEigens = 1 [json_name = "PointEigens"];*/ 1:
                    message.pointEigens.push(AlarmPointEigensRequest.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string Time = 2 [json_name = "Time"];*/ 2:
                    message.time = reader.string();
                    break;
                case /* bool IsExternal = 3 [json_name = "IsExternal"];*/ 3:
                    message.isExternal = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: QueryAlarmPointsEigenDatasRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated alarm.AlarmPointEigensRequest PointEigens = 1 [json_name = "PointEigens"]; */
        for (let i = 0; i < message.pointEigens.length; i++)
            AlarmPointEigensRequest.internalBinaryWrite(message.pointEigens[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string Time = 2 [json_name = "Time"]; */
        if (message.time !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.time);
        /* bool IsExternal = 3 [json_name = "IsExternal"]; */
        if (message.isExternal !== false)
            writer.tag(3, WireType.Varint).bool(message.isExternal);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message alarm.QueryAlarmPointsEigenDatasRequest
 */
export const QueryAlarmPointsEigenDatasRequest = new QueryAlarmPointsEigenDatasRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class QueryAlarmPointsEigenDatasReply$Type extends MessageType<QueryAlarmPointsEigenDatasReply> {
    constructor() {
        super("alarm.QueryAlarmPointsEigenDatasReply", [
            { no: 1, name: "Points", kind: "message", jsonName: "Points", repeat: 1 /*RepeatType.PACKED*/, T: () => HistoryPointDataReply }
        ]);
    }
    create(value?: PartialMessage<QueryAlarmPointsEigenDatasReply>): QueryAlarmPointsEigenDatasReply {
        const message = { points: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<QueryAlarmPointsEigenDatasReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: QueryAlarmPointsEigenDatasReply): QueryAlarmPointsEigenDatasReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated storage.HistoryPointDataReply Points = 1 [json_name = "Points"];*/ 1:
                    message.points.push(HistoryPointDataReply.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: QueryAlarmPointsEigenDatasReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated storage.HistoryPointDataReply Points = 1 [json_name = "Points"]; */
        for (let i = 0; i < message.points.length; i++)
            HistoryPointDataReply.internalBinaryWrite(message.points[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message alarm.QueryAlarmPointsEigenDatasReply
 */
export const QueryAlarmPointsEigenDatasReply = new QueryAlarmPointsEigenDatasReply$Type();
