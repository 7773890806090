// @generated by protobuf-ts 2.9.1
// @generated from protobuf file "protos/alarm.proto" (package "alarm", syntax proto3)
// tslint:disable
import { CalculateAlarmNearestWaveDataRequest } from "./calculatealarmnearestwavedata";
import { QueryAlarmDatasWithLimitRequest } from "./alarmdataswithlimit";
import { QueryNearestPointsEigenDatasRequest } from "./nearestpointseigendatas";
import { QueryNearestPointsWaveDatasRequest } from "./nearestpointswavedatas";
import { QueryBandEnergyAlarmDatasRequest } from "./bandenergyalarmdatas";
import { QueryAlarmPointsRangeWaveDatasRequest } from "./alarmpointsrangewavedatas";
import { QueryAlarmPointsWaveDatasRequest } from "./alarmpointswavedatas";
import { QueryAlarmPointsDatasRequest } from "./alarmpointsdatas";
import { QueryAlarmPointsEigenDatasRequest } from "./alarmpointseigendatas";
import { QueryAlarmPointsSpeedsRequest } from "./alarmpointsspeeds";
import { QueryAlarmPointsEigensDatasRequest } from "./alarmpointseigensdatas";
import { Reply } from "./reply";
import { GetSelfLearningFeatureDatasRequest } from "./selflearningfeaturedatas";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
/**
 * @generated ServiceType for protobuf service alarm.Alarm
 */
export const Alarm = new ServiceType("alarm.Alarm", [
    { name: "GetSelfLearningFeatureDatas", options: {}, I: GetSelfLearningFeatureDatasRequest, O: Reply },
    { name: "QueryAlarmPointsEigensDatasAsync", options: {}, I: QueryAlarmPointsEigensDatasRequest, O: Reply },
    { name: "QueryAlarmEigensDatasAsync", options: {}, I: QueryAlarmPointsEigensDatasRequest, O: Reply },
    { name: "QueryAlarmSpeedsDatasAsync", options: {}, I: QueryAlarmPointsSpeedsRequest, O: Reply },
    { name: "QueryFirstAlarmEigenDatas", options: {}, I: QueryAlarmPointsEigenDatasRequest, O: Reply },
    { name: "QueryAlarmPointsDatasAsync", options: {}, I: QueryAlarmPointsDatasRequest, O: Reply },
    { name: "QueryAlarmPointsWaveDatasAsync", options: {}, I: QueryAlarmPointsWaveDatasRequest, O: Reply },
    { name: "QueryAlarmPointsRangeWaveDatasAsync", options: {}, I: QueryAlarmPointsRangeWaveDatasRequest, O: Reply },
    { name: "QueryBandEnergyWaveDataAsync", options: {}, I: QueryBandEnergyAlarmDatasRequest, O: Reply },
    { name: "QueryNearestPointsWaveDatas", options: {}, I: QueryNearestPointsWaveDatasRequest, O: Reply },
    { name: "QueryNextPointsWaveDatas", options: {}, I: QueryNearestPointsWaveDatasRequest, O: Reply },
    { name: "QueryPrePointsWaveDatas", options: {}, I: QueryNearestPointsWaveDatasRequest, O: Reply },
    { name: "QueryNearestPointsEigenDatas", options: {}, I: QueryNearestPointsEigenDatasRequest, O: Reply },
    { name: "QueryNextPointsEigenDatas", options: {}, I: QueryNearestPointsEigenDatasRequest, O: Reply },
    { name: "QueryPrePointsEigenDatas", options: {}, I: QueryNearestPointsEigenDatasRequest, O: Reply },
    { name: "QueryNearestAlarmDataWithLimit", options: {}, I: QueryAlarmDatasWithLimitRequest, O: Reply },
    { name: "QueryNextAlarmDataWithLimit", options: {}, I: QueryAlarmDatasWithLimitRequest, O: Reply },
    { name: "QueryPreAlarmDataWithLimit", options: {}, I: QueryAlarmDatasWithLimitRequest, O: Reply },
    { name: "CalculateAlarmNearestWaveData", options: {}, I: CalculateAlarmNearestWaveDataRequest, O: Reply },
    { name: "CalculateAlarmNextWaveData", options: {}, I: CalculateAlarmNearestWaveDataRequest, O: Reply },
    { name: "CalculateAlarmPreWaveData", options: {}, I: CalculateAlarmNearestWaveDataRequest, O: Reply }
]);
