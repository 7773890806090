<template>
	<div class="platformContainer">
		<el-menu :active-text-color="primaryColor" ref="menuRef" :default-active="state.defaultActive"
			:collapse="isCollapse" :background-color="store.state.customColor.card"
			:text-color="store.state.customColor.mainText">
			<div class="menuItem" v-for="item in state.menuList">
				<el-sub-menu :index="item.name" v-if="item.children">
					<template #title>
						<div class="nocollapse">
							<svgIcon :name="item.icon" :hover="false"
								:color="item.children.find((item) => item.icon === state.activeMenu) && isCollapse ? primaryColor : textColor">
							</svgIcon>
							<span v-if="!isCollapse">{{ item.name }}</span>
						</div>
					</template>
					<el-menu-item v-for="child in item.children" :index="child.name"
						@click="handleClickMenuItem(item, child)">
						<span>{{ child.name }}</span>
					</el-menu-item>
				</el-sub-menu>
				<el-menu-item v-else :index="item.name" @click="handleClickMenuItem(item)">
					<div class="nocollapse">
						<svgIcon :color="state.activeMenu === item.icon ? primaryColor : textColor" :name="item.icon"
							:hover="false"> </svgIcon>
						<span v-if="!isCollapse">{{ item.name }}</span>
					</div>
				</el-menu-item>
			</div>
		</el-menu>
		<div class="main">
			<div class="head">
				<svgIcon width="16px" height="16px" :name="isCollapse ? 'expandPlatform' : 'collapsePlatform'"
					@click="setCollapse"> </svgIcon>

				<svgIcon name="platformBack" @click="goBack"></svgIcon>
				<span class="text">{{ $t("platformSetting.tools.currentPosition") }}:</span>
				<svgIcon name="platformHome" @click="goHome"></svgIcon>

				<el-breadcrumb separator="/">
					<el-breadcrumb-item>{{ $t("common.platformSetting") }}</el-breadcrumb-item>
					<el-breadcrumb-item v-for="item in state.activeName">{{ item }}</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<div class="content">
				<component :is="componentLibarys[state.activeMenu]?.default"></component>
			</div>
		</div>
	</div>
</template>

<script setup>
import { computed, onMounted, onUnmounted, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import svgIcon from "@common/components/svgIcon.vue";
import * as componentList from "./component";
import { i18nGlobal } from "@common/js/utils";

const componentLibarys = {};
const emit = defineEmits(["setTab"]);
const store = useStore();
const router = useRouter();
const primaryColor = computed(() => store.state.primaryColor);
const textColor = computed(() => store.state.customColor.TextColor);
const isCollapse = computed(() => store.state.isCollapse)
const menuRef = ref(null);

const state = reactive({
	defaultActive: "",
	activeName: [],
	activeMenu: "",
	isCollapse: false,
	menuList: [
		{
			name: i18nGlobal("platformSetting.tools.instrumentManagement"),
			icon: "platformInstrument",
			auth: ["Instrument_Browse", "SelfCheck_Browse"],
			children: [
				{
					name: i18nGlobal("platformSetting.tools.instrumentSetting"),
					icon: "platformInstrument",
					auth: ["Instrument_Browse"],
				},
				{
					name: i18nGlobal("platformSetting.tools.selfCheckSetting"),
					icon: "platformSelfCheck",
					auth: ["SelfCheck_Browse"],
				},
			],
		},
		{
			name: i18nGlobal("common.baseInfo"),
			icon: "platformBaseInfo",
			children: [
				{
					name: i18nGlobal("platformSetting.tools.signalSetting"),
					icon: "platformSignal",
					auth: ["Signal_Browse", "Sensor_Browse", "Unit_Browse"],
				},
				{
					name: i18nGlobal("platformSetting.tools.bearingSetting"),
					icon: "platformBearingLibary",
					auth: ["BearingLibary_Browse"],
				},
				{
					name: i18nGlobal("platformSetting.tools.faultLibrarySetting"),
					icon: "faultLibary",
					auth: ["FaultLib_Browse"],
				},
				{
					name: i18nGlobal("platformSetting.tools.patrolSetting"),
					icon: ["platformPatrol"],
					auth: ["ObserverCategory_Browse", "ObserverItem_Browse", "TaskRecord_Browse", "Holiday_Browse"],
				},
				{
					name: i18nGlobal("faultCaseLibrary.title"),
					icon: "platformFaultCaseLibrary",
					auth: ["Fault_Case_Browse"],
				},
				{
					name: i18nGlobal("departSetting.title"),
					icon: "platformDeviceTreeSetting",
					auth: ["DepartmentType_Browse", "DeviceGrade_Browse"],
				},
			],
			// {
			//     name: '消息发送设置'i18nGlobal('platformSetting.tools.possonRatio'),
			//     icon: 'platformMsg'浏览（查找）
			// }, {
			//     name: '倒班设置'i18nGlobal('platformSetting.tools.possonRatio'),
			//     icon: 'dutyTemplate'
			// }]
		},
		{
			name: i18nGlobal("platformSetting.tools.authorizationManagement"),
			icon: "platformAuth",
			children: [
				{
					name: i18nGlobal("common.departmentManagement"),
					icon: "platformDepart",
					auth: ["Department_Browse"],
				},
				{
					name: i18nGlobal("platformSetting.tools.userRoleManagement"),
					icon: "platformRole",
					auth: ["Role_Browse"],
				},
				{
					name: i18nGlobal("platformSetting.tools.workGroupManagement"),
					icon: "platformWorkGroup",
					auth: ["WorkGroup_Browse"],
				},
				{
					name: i18nGlobal("common.userManagement"),
					icon: "platformUser",
					auth: ["User_Browse"],
				},
			],
		},
		// {
		//     name: '运维设置'i18nGlobal('platformSetting.tools.possonRatio'),
		//     icon: 'runStatusManage'
		// },
		{
			name: i18nGlobal("common.settingConfig"),
			icon: "platformSetting",
			children: [
				//     {
				//     name: '设备树配置'i18nGlobal('platformSetting.tools.possonRatio'),
				//     icon: 'platformMachineTree'
				// },
				{
					name: i18nGlobal("platformSetting.tools.dataPushing"),
					icon: "platformAgree",
					auth: ["Modbus_Browse", "Mqtt_Browse", "TCP_Browse", "Opc_Browse"],
				},
				{
					name: i18nGlobal("platformSetting.tools.messagePushing"),
					icon: "platformMsg",
					auth: ["Email_Browse", "Message_Browse"],
				},
				{
					name: i18nGlobal("platformSetting.tools.storageSetting"),
					icon: "platformSave",
					auth: ["DiluteRule_Browse", "StorageMode_Browse", "Backup_Browse"],
				},
				// {
				//     name: '设备配置'i18nGlobal('platformSetting.tools.possonRatio'),
				//     icon: 'platformDevice',
				//     // auth: ['SaveNode']
				// },
				{
					name: i18nGlobal("platformSetting.tools.componentSetting"),
					icon: "platformGraph",
					auth: ["Component_Set_Browse"],
				},
				{
					name: i18nGlobal("platformSetting.settingCenter.alarm.alarmConfig"),
					icon: "platformAlarm",
					auth: ["AlarmConfigSett_Browse", "AlarmParam_Browse"],
				},
				{
					name: i18nGlobal("platformSetting.tools.taskSetting"),
					icon: "platformExportTool",
					auth: ["ScheduledExport_Browse", "ReportTask_Browse"],
				},
				{
					name: i18nGlobal('platformSetting.runStatusManage.title'),
					icon: 'startAndStop',
					auth: ['StartStopMachine_Browse']
				},
				{
					name: i18nGlobal('deviceDepartSetting.title'),
					icon: 'deviceDepartSetting',
					auth: ['Department_Device_Config_Browse']
				}
			],
		},
		// {
		//     name: '数据管理'i18nGlobal('platformSetting.tools.possonRatio'),
		//     icon: 'dataManage',
		//     children: [{
		//         name: '数据导出'i18nGlobal('platformSetting.tools.possonRatio'),
		//         icon: 'dataExport',
		//         auth: ['DataManage_Export']
		//     }, {
		//         name: '数据导入'i18nGlobal('platformSetting.tools.possonRatio'),
		//         icon: 'dataImport',
		//         auth: ['DataManage_Import']
		//     }]
		// },
		{
			name: i18nGlobal("platformSetting.tools.toolManagement"),
			icon: "platformTool",
			children: [
				{
					name: i18nGlobal("platformSetting.tools.calculatingTool"),
					icon: "platformCaculateTool",
					auth: ["CalTool_Browse"],
				},
				{
					name: i18nGlobal("platformSetting.tools.batchingTool"),
					icon: "platformBatchTool",
					auth: ["Batch_Browse"],
				},
				{
					name: i18nGlobal('platformSetting.tools.analysisTool'),
					icon: "platformAnalysisTool",
					auth: ["AnalysisTool_Browse"]
				},
			],
		},
		{
			name: i18nGlobal("platformSetting.tools.systemSetting"),
			icon: "platFormSystemSetting",
			children: [
				{
					name: i18nGlobal("platformSetting.productManage.nodeSetting.title"),
					icon: "platformNodeSetting",
					auth: ["NodeConfig_Browse"],
				},
				{
					name: i18nGlobal("platformSetting.productManage.safe.title"),
					icon: "platformSafe",
					auth: ["Security_Browse"],
				},
				{
					name: i18nGlobal("common.themeSetting"),
					icon: "platformTheme",
					auth: ["Theme_Browse"],
				},
				{
					name: i18nGlobal("platformSetting.tools.languageSetting"),
					icon: "platformLanguage",
					auth: ["Language_Browse"],
				},
				// {
				// 	name: i18nGlobal("platformSetting.settingCenter.platformMachineTree.otherSetting"),
				// 	icon: "platformOther",
				// 	auth: ["Other_Browse"]
				// },
				{
					name: i18nGlobal("common.copyrightInfo"),
					icon: "platformVersion",
					auth: ["Copyright_Browse"],
				},
				{
					name: i18nGlobal('platformSetting.productManage.operationLog.title'),
					icon: "operationLog",
					auth: ["OperationLog_Browse"],
				},
			],
		},
	],
});

const setCollapse = () => {

	store.commit("setCollapse", { isCollapse: !store.state.isCollapse });
}

const goBack = () => {
	store.commit("setShowPlatForm", { showPlatForm: false });
};

const goHome = () => {
	store.commit("setShowPlatForm", { showPlatForm: false });
	emit("setTab");
	router.push({ path: "/summary" });
	// window.location.reload()
};

const setAuth = () => {
	const authArr = store.state.userAuth.ResourceAuth;
	for (let i = 0; i < state.menuList.length; i++) {
		const element = state.menuList[i];
		if (element.auth && element.auth.every((item) => !authArr.includes(item))) {
			state.menuList.splice(i, 1);
			i--;
		}
		if (element.children) {
			for (let j = 0; j < element.children.length; j++) {
				const item = element.children[j];
				if (item.auth && item.auth.every((child) => !authArr.includes(child))) {
					const index = element.children.findIndex((t) => t.auth === item.auth);
					element.children.splice(index, 1);
					j--;
				}
			}
		}
	}

	// 多级菜单下全部没有权限即隐藏
	for (let i = 0; i < state.menuList.length; i++) {
		const element = state.menuList[i];
		if (element.children && element.children.length === 0) {
			state.menuList.splice(i, 1);
			i--;
		}
	}
};

const handleClickMenuItem = async (item, child) => {
	let activeMenu = child ? child.icon : item.icon;
	let activeName = child ? [item.name, child.name] : [item.name];
	await checkLibary(activeMenu);
	state.activeName = activeName;
	state.activeMenu = activeMenu;
};

watch(
	() => store.state.showPlatForm,
	(val) => {
		if (val) {
			// 记住
			state.menuList.forEach((item) => {
				if (item.icon === state.activeMenu) {
					handleClickMenuItem(item);
				}
				if (item.children) {
					item.children.forEach((child) => {
						if (child.icon === state.activeMenu) {
							handleClickMenuItem(child);
						}
					});
				}
			});
		}
	}
);

onMounted(async () => {
	setAuth();
	if (state.menuList.length > 0) {
		let activeMenu = "";
		if (state.menuList[0].children) {
			menuRef.value?.open(state.menuList[0].name);
			state.defaultActive = state.menuList[0].children[0].name;
			activeMenu = state.menuList[0].children[0].icon;
		} else {
			state.defaultActive = state.menuList[0].name;
			activeMenu = state.menuList[0].icon;
		}
		await checkLibary(activeMenu);
		state.activeMenu = activeMenu;
	} else {
		state.defaultActive = "";
		state.activeMenu = "";
	}
});

const checkLibary = async (name) => {
	if (name != "" && componentLibarys[name] == null) {
		componentLibarys[name] = await componentList[name]();
	}
};

onUnmounted(() => {
	for (let p in componentLibarys) {
		componentLibarys[p] = null;
	}
});
</script>

<style scoped lang="less">
@import "@styles/variable.less";

.platformContainer {
	width: 100%;
	display: flex;
	background-color: var(--phm-theme-background);

	.el-menu {
		&:not(.el-menu--collapse) {
			width: 240px;
			min-width: 240px;

			.el-menu-item {
				svg {
					margin-right: 12px;
					margin-bottom: 60x;
				}
			}

			:deep(.el-icon.el-sub-menu__icon-arrow) {
				display: block;
			}
		}

		padding-top: 10px;
		border-right: none;
		overflow: auto;

		:deep(.el-icon.el-sub-menu__icon-arrow) {
			display: none;
		}

		.nocollapse {
			display: flex;
			align-items: center;
		}

		.el-menu-item {
			&:hover {
				background-color: var(--phm-primary-light-color);
			}

			&.is-active {
				background-color: var(--phm-primary-light-color);
			}

			&.active {
				background-color: var(--phm-primary-light-color);
			}

			// svg {
			//     margin-right: 12px;
			//     margin-bottom: 60x;
			// }
		}

		.el-sub-menu {
			.el-menu-item {
				&:hover {
					background-color: var(--phm-primary-light-color);
				}

				&.is-active {
					background-color: var(--phm-primary-light-color);
				}

				span {
					margin-left: 12px;
				}
			}

			.el-sub-menu__title {
				span {
					margin-left: 12px;
					color: var(--phm-theme-mainText);
				}
			}
		}
	}

	.main {
		padding-top: 12px;
		margin-left: 10px;
		flex: 1;
		overflow: hidden;
		display: flex;
		flex-direction: column;

		.head {
			display: flex;
			align-items: center;

			svg {
				margin-right: 20px;
				cursor: pointer;
			}

			.text {
				font-size: 14px;
				color: var(--phm-theme-title);
				margin-right: 4px;
				margin-top: 2px;
			}

			.el-breadcrumb {
				margin-top: 4px;
			}
		}

		.content {
			padding: 10px;
			padding-left: 0;
			box-sizing: border-box;
			padding-bottom: 10px;
			flex: 1;
			overflow: hidden;
		}
	}
}
</style>
