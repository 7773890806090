import $ from "jquery";
import { i18nGlobal } from "@common/js/utils";
import { PluginBase } from "./DH-plugin-base";

export class InputButtonPlugin extends PluginBase {
	constructor() {
		super();
		this.IDom = null;
		this.LastValue = null;
		this.Option = {
			DefaultValue: "",
			LabelName: "",
			InputType: "text",
			Position: "right: 30px;top: 0px;"
		}
	}
	InitOption() {
		if (this.Entity.Option) {
			for (const key in this.Option) {
				if (Object.hasOwnProperty.call(this.Entity.Option, key)) {
					this.Option[key] = this.Entity.Option[key];
				}
			}
		}
	}
	CreatePlugin() {
		this.InitOption();
		this.CreateInput();
	}
	CreateInput() {
		let html = `<div style="position: absolute;${this.Option.Position}overflow: hidden;z-index:100;font-size:13px">
						<span style="height:24px;color: var(--phm-theme-mainText)">${this.Option.LabelName}</span>
                        <input class="el-input__inner" type="${this.Option.InputType}" value="${this.Option.DefaultValue}" title="" style="width:100px;height:22px;border: 1px solid;">
                        <button id="sure" type="button" class="el-button el-button--small el-button--primary el-button--default" style="margin-left: 5px;vertical-align: baseline;">${i18nGlobal('common.confirm')}</button>
                    </div>`;
		this.IDom = $(html);
		$(this.Owner.ChartContainer).append(this.IDom);
		this.LastValue = this.Option.DefaultValue;
		let sender = this;
		this.IDom.find("input").on("click mousedown", function (e) {
			e.stopPropagation();
		});
		this.IDom.find("input")
			.on("change keyup paste", function (e) {
				let value = $(this).val();
				sender.LastValue = value;
			})
		this.IDom.find("#sure").on("click", function () {
			if (typeof sender.Entity.onSure === 'function') {
				let value = sender.IDom.find("input").val();
				sender.Entity.onSure(value);
			}
		});
	}
	SetInputVal(val) {
		this.LastValue = val;
		this.IDom.find("input").val(val);
	}
	HideButton() {
		this.IDom.find("#sure").css("display", "none");
	}
	ShowButton() {
		this.IDom.find("#sure").css("display", "");
	}
	Close() {
		this.IDom.find("input").off('click mousedown change keyup paste');
		this.IDom.find("#sure").off('click');
		this.IDom.remove();
		this.IDom = null;
	}
}
