import axios from "@common/js/axios";
import store from "@common/js/store";
import { NormalLevelItem } from "./enum";

export const isCookieEnable = () => {
	try {
		var _ = window.top.Window;
		return true;
	} catch (e) {
		return false;
	}
};

export const setTokens = (userName, config) => {
	store.commit("updateUserInfo", {
		...config,
		UserName: userName
	});
};

export const getTokens = () => {
	const userInfo = store.state.userInfo;
	return {
		refreshToken: userInfo?.RefreshToken,
		accessToken: userInfo?.AccessToken,
		token: userInfo?.Token,
	}
};

export const getUserAuth = async (userName) => {
	const res = await axios({
		method: "post",
		url: "Authority/GetUserAuth",
		data: {
			Name: userName,
		},
	});
	if (res.IsSuccess) {
		store.commit("setUserAuth", { userAuth: res.Result });
		return res.Result;
	}
};

export const getUiMode = async () => {
	try {
		const res = await axios({
			method: "get",
			url: "Theme/ui-mode",
		})
		if (res.IsSuccess) {
			store.commit('setUiMode', {
				uiMode: res.Result.UiMode
			});
			return res.Result;
		}
	}
	catch { }
};


export const getGraphSetting = async () => {
	// no permisson control
	const res = await axios({
		method: "get",
		url: "/AllConfiguration",
	}).catch(() => { });
	if (res?.IsSuccess) {
		store.commit("setGraphSetting", {
			graphSetting: {
				...res.Result.ComponentDefaultDto,
				UseRecorder: res.Result.DiluteConfigDto.Enabled
			},
		});
		store.commit("setDepartAndDeviceConfig", {
			departAndDeviceConfig: res.Result.DepartmentDeviceConfigDto,
		});

		store.commit("setIsDeviceOfflineStatus", {
			isDeviceOfflineStatus: res.Result.DeviceStatusConfigDto.IsDeviceOfflineStatus
		})
		return res.Result;
	}

};

export const axiosAuth = (config) => {
	if (config.auth) {
		const auth = config.auth;
		delete config.auth;
		if (Array.isArray(auth)) {
			if (auth.every(item => store.state.userAuth.ResourceAuth.includes(item))) {
				return axios(config);
			} else {
				console.log(config.url + '没有权限')
				return new Promise((resolve) => {
					resolve({
						IsSuccess: false
					})
				})
			}
		} else {
			if (store.state.userAuth.ResourceAuth.includes(auth)) {
				return axios(config);
			} else {
				console.log(config.url + '没有权限')
				return new Promise((resolve) => {
					resolve({
						IsSuccess: false
					})
				})
			}
		}

	} else {
		return axios(config);
	}
}

export const getAlarmLevel = async () => {
	const res = await axios({
		method: "get",
		url: "AlarmConfig/AlarmLevels",
	})
	if (res.IsSuccess) {
		store.commit('setAlarmInfo', { alarmInfo: res.Result.concat(NormalLevelItem).sort((a, b) => a.Level - b.Level) })
		return res.Result;
	}
}