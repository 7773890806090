<template>
	<svg aria-hidden="true" :width="width" title="fff" :height="height" @mouseenter="changeColor(state.hoverColor)"
		@mouseleave="changeColor(props.color)">
		<title v-if="props.title">{{ props.title }}</title>
		<use :xlink:href="symbolId" :fill="fillColor" />
	</svg>
</template>

<script setup>
import { computed, reactive, watch, ref, onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'
import { ColorEnum } from '@common/js/enum'
const store = useStore()
const props = defineProps({
	prefix: {
		type: String,
		default: 'icon',
	},
	name: {
		type: String,
		required: true,
	},
	color: {
		type: String,
		default: '',
	},
	hoverColor: {
		type: String,
		default: ColorEnum.PrimaryColor,
	},
	hover: {
		type: Boolean,
		default: true,
	},
	width: {
		type: String,
		default: '20px',
	},
	height: {
		type: String,
		default: '20px',
	},
	title: {
		type: String,
		default: '',
	},
	disabled: {
		type: Boolean,
		default: false
	}
})

const symbolId = computed(() => `#${props.prefix}-${props.name}`)

const state = reactive({
	hoverColor: '',
})

const fillColor = ref(props.color ? props.color : store.state.customColor.TextColor)

const changeColor = (color) => {
	if (props.hover && !props.disabled) {
		fillColor.value = color || store.state.customColor.TextColor
	}
}

const colorWatcher1 = watch(
	() => props.color,
	(color) => {
		fillColor.value = color
	}
)

const colorWatcher2 = watch(
	() => store.state.primaryColor,
	(color) => {
		state.hoverColor = color
	},
	{
		immediate: true,
	}
)

const colorWatcher3 = watch(
	() => store.state.customColor.TextColor,
	(color) => {
		if (!props.color) {
			fillColor.value = color
		}
	},
	{
		immediate: true,
	}
)

onBeforeUnmount(() => {
	colorWatcher1()
	colorWatcher2()
	colorWatcher3()
})
</script>
