import axios from "@common/js/axios";
import { ContractTypes } from "@common/js/enum";
import { BlockDataRealContext } from "./blockDataRealContext";
import { EnumXDataType } from "./dataParserContext";

export class OriginDataRealContext extends BlockDataRealContext {
    constructor() {
        super();
    }
    getContractType() {
        return ContractTypes.Orgin;
    }
    sendRegisterPointsToClinet() {
        if (this.getConnectionIds().length == 0) return;
        this.debounce.run(() => {
            const registerGraphPoints = this.updateHistoryChangeGraphs();
            const connectionIds = this.getConnectionIds();
            if (connectionIds.length == 0) return;
            axios({
                method: "post",
                url: "datamanagement/register-origin-data",
                data: {
                    ConnectionIds: connectionIds,
                    PointIds: [...this.registerPointCollection.keys()],
                },
            }).then((res) => {
                if (res.IsSuccess) {
                    this.processRegisterData([...this.registerPointCollection.values()], registerGraphPoints);
                }
            });
        }, 100);
    }
    processWaveData(datas) {
        datas.forEach(data => {
            if (data.Datas?.length == 2) {
                data.Datas = this.dataParser.get(data.Datas[0]).GetDatas(data.Datas[1], data);
                data.AlgorithmType = data.DspType;
                data.DiluteDatas = new Map();
                data.StartTime = data.StartSampleTime / 1E4;
                for (const key in data.DilutionPoints) {
                    data.DiluteDatas.set(Number(key), getCachedDilutionPointList(data.DilutionPoints[key], key))
                }
                delete data.DilutionPoints;
            }
        });
    }
    getCloneData(data) {
        const dataTemp = super.getCloneData(data);
        dataTemp.DiluteDatas = data.DiluteDatas;
        dataTemp.StartTime = data.StartTime;
        return dataTemp;
    }
}

export const getCachedDilutionPointList = (datas, level) => {
    const point = {
        XDataType: EnumXDataType.Time,
        DataCount: 0,
        Points: [],
        Level: Number(level)
    };
    point.Points = new Array();
    for (const data of datas) {
        const x = data.Time / 10000;
        point.Points.push({ X: x, Y: data.MinValue })
        point.Points.push({ X: x, Y: data.MaxValue })
    }
    return point;
}