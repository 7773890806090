import $ from "jquery";
import { ruleCheck } from "@common/js/rules";
import { ThemeResource } from "@components/js/utils/model";
import { CursorBase, CursorElement } from "@core/graphs/plugins/Dh-plugin-cursor-base";
import { DoubleCursorPlugin } from "./Dh-plugin-double-cursor";
import { SingleCursorPlugin, TrendSingleCursorPlugin } from "./Dh-plugin-single-cursor";
import { i18nGlobal } from "@common/js/utils";

export class NavigateCursorElement extends CursorBase {
    constructor(owner, width, height, linecolor, stroke, startPos, name, mode) {
        super(owner, width, height, linecolor, stroke, startPos, name);
        this.Height = height - 9;
        this.Points = new Array();
        this.LineStartPos = { X: this.StartPos.X, Y: this.StartPos.Y + 9 };
        this.StartPos.X = parseInt(this.StartPos.X) + 0.5 + 2;
        this.LineStartPos.X = parseInt(this.LineStartPos.X) + 1.5;
        this.Mode = mode ?? 0;
        this.Init();
    }
    Init() {
        this.Height = this.Height + 3;
        this.LineStartPos.Y -= 3;
        this.LineStartPos.X += 0.5;
        let g = document.createElementNS("http://www.w3.org/2000/svg", "g");
        let line = document.createElementNS("http://www.w3.org/2000/svg", "line");
        let path = document.createElementNS("http://www.w3.org/2000/svg", "path");
        $(g)
            .attr("transform", "translate(1,0)")
            .attr("candrag", "true")
            .css("width", "9px")
            .css("height", "100%")
            .css("cursor", "e-resize")
            .attr("isSelected", "false")
            .attr("cursor-color", this.Color)
            .attr("aria-title", "");
        if (this.Mode == 0) {
            $(path)
                .attr("fill", "rgba(0,0,0,0)")
                .attr(
                    "d",
                    "M " +
                    this.LineStartPos.X +
                    " " +
                    this.LineStartPos.Y +
                    " L " +
                    (this.LineStartPos.X + this.Width) +
                    " " +
                    this.LineStartPos.Y +
                    " L " +
                    (this.LineStartPos.X + this.Width) +
                    " " +
                    (this.LineStartPos.Y + this.Height) +
                    " L " +
                    this.LineStartPos.X +
                    " " +
                    (this.LineStartPos.Y + this.Height) +
                    " L " +
                    this.LineStartPos.X +
                    " " +
                    this.LineStartPos.Y +
                    ""
                )
                .attr("cursor-rect", "true");
        } else {
            $(path)
                .attr("fill", "rgba(0,0,0,0)")
                .attr(
                    "d",
                    "M " +
                    (this.LineStartPos.X + 5) +
                    " " +
                    this.LineStartPos.Y +
                    " L " +
                    (this.LineStartPos.X + this.Width + 5) +
                    " " +
                    this.LineStartPos.Y +
                    " L " +
                    (this.LineStartPos.X + this.Width + 5) +
                    " " +
                    (this.LineStartPos.Y + this.Height) +
                    " L " +
                    (this.LineStartPos.X + 5) +
                    " " +
                    (this.LineStartPos.Y + this.Height) +
                    " L " +
                    (this.LineStartPos.X + 5) +
                    " " +
                    this.LineStartPos.Y +
                    ""
                )
                .attr("cursor-rect", "true");
        }
        $(line)
            .attr("stroke", this.Color)
            .attr("stroke-width", this.Stroke)
            .attr("x1", this.LineStartPos.X + 5)
            .attr("y1", this.LineStartPos.Y)
            .attr("x2", this.LineStartPos.X + 5)
            .attr("y2", this.Height + this.LineStartPos.Y);
        if (!ruleCheck.IsNullOrSpaceStr(this.Name)) {
            let name = document.createElementNS("http://www.w3.org/2000/svg", "text");
            $(name).text(this.Name);
            $(name)
                .attr("x", this.LineStartPos.X + 10)
                .attr("y", this.StartPos.Y + 12)
                .addClass("none-select")
                .css("font-family", "monospace")
                .css("pointer-events", "none");
            $(g).append(name);
        }
        $(g).append(line).append(path);
        $(g).append(this.AddRect(this.StartPos.X + 2, this.StartPos.Y, 5, 5, this.Color));
        $(g).append(this.AddRect(this.StartPos.X + 3, this.StartPos.Y + 5, 3, 1, this.Color));
        $(g).append(this.AddRect(this.StartPos.X + 4, this.StartPos.Y + 6, 2, 1, this.Color));

        this.DOM = $(g);
        this.RegisterEvent();
    }
    OnMouseUp(e) {
        let plugins = $("div .cursor-window");
        $.each(plugins, function (i, item) {
            $(item).css("pointer-events", "auto");
        });
    }
    Remove() {
        this.ClearPoints();
        super.Remove();
    }
    DrawPoint(y) {
        let rect = this.parent.Owner.Information.ShapeContainerRect;
        let theme = this.parent.Owner.Option.Axis.Theme;
        const coordSystem = this.parent.Owner.CoordinateSystem;
        if (y - 3 > rect.Top && y - 3 <= rect.Height + rect.Top && coordSystem.DrawPoint) {
            let point = this.AddRect(this.LineStartPos.X + 2, y - 3, 6, 6, ThemeResource[theme].DotColor);
            this.Points.push(point);
            this.DOM.append(point);
        }
    }
    ClearPoints() {
        if (this.Points.length > 0) {
            $.each(this.Points, function (i, p) {
                p.remove();
            });
        }
        this.Points = new Array();
    }
}

export class NavigateTrendDoubleCursorElement extends CursorElement {
    constructor(owner, height, linecolor, stroke, startPos, name) {
        super(owner, height, linecolor, stroke, startPos, name);
    }
    OnMouseUp(e) {
        let plugins = $("div .cursor-window");
        $.each(plugins, function (i, item) {
            $(item).css("pointer-events", "auto");
        });
    }
}

export class NavigateSingleCursorPlugin extends SingleCursorPlugin {
    constructor(mode) {
        super(mode);
    }
    OnRender() {
        if (this.CursorData != null) {
            let x = this.Owner.CoordinateSystem.GetPositionByX(this.CursorData.X);
            this.CursorData = x;
            this.UpdatePosition(this.CursorData);
        } else {
            this.SetPosition(0);
        }
    }
    CreatePlugin() {
        this.InitSvgEvent();
    }
    OnSizeChanged() {
        if (this.Cursor != null) {
            this.Cursor.Remove();
        }
        let rect = this.Owner.Information.ShapeContainerRect;
        this.Cursor = new NavigateCursorElement(this, 11, rect.Height, this.Owner.CoordinateSystem.AxisColor, "1", { X: rect.Left, Y: rect.Top });
        $(this.Owner.PluginContainer).append(this.Cursor.DOM);
    }
    UpdatePosition(data) {
        if (data && data != null) {
            this.CursorData = data;
            this.NotifyPositionChanged();
            if (!isNaN(this.CursorData.OffsetX) && isFinite(this.CursorData.OffsetX)) {
                this.Cursor.DOM.attr("transform", "translate(" + (this.CursorData.OffsetX - 6) + ",0)");
                this.Cursor.DOM.attr("aria-title", data.XText);
            }
        } else {
            this.Cursor.DOM.attr("transform", "translate(-6,0)");
            this.NotifyPositionChanged();
        }
    }
    NotifyPositionChanged() {
        super.NotifyPositionChanged();
        this.Entity.onDataChange?.(this.CursorData);
    }
}

export class NavigateDoubleCursorPlugin extends DoubleCursorPlugin {
    constructor(mode) {
        super(mode);
        this.dragRect = null;
    }
    OnRender() {
        this.IsNotify = false;
        let pos = new Array();
        if (this.CursorDatas.length > 0) {
            for (let i = 0; i < this.CursorDatas.length; i++) {
                if (this.CursorDatas[i].X) {
                    let x = this.Owner.CoordinateSystem.GetPositionByX(this.CursorDatas[i].X);
                    if (x) {
                        pos.push(x.XIndex);
                    } else {
                        pos.push(0);
                    }
                } else {
                    pos.push(this.CursorDatas[i].XIndex);
                }
            }
        } else {
            for (let i = 0; i < this.CursorCount; i++) {
                pos.push(0 + i * 2);
            }
        }
        this.SetPosition(pos);
        this.IsNotify = true;
    }
    CreatePlugin() {
        this.InitSvgEvent();
        let g = document.createElementNS("http://www.w3.org/2000/svg", "g");
        var rect = document.createElementNS("http://www.w3.org/2000/svg", "rect");
        this.dragRect = $(rect);
        this.dragRect.attr("x", 0).attr("y", 0).attr("width", 0).attr("height", 0).css("fill", "#89c1f333");
        $(g).append(this.dragRect);
        this.dragRect.parent().addClass("hide");
        $(this.Owner.PluginContainer).append($(g));
    }
    OnSizeChanged() {
        let rect = this.Owner.Information.ShapeContainerRect;
        for (let cursor of this.Cursors) {
            cursor.Remove();
        }
        this.Cursors = new Array();
        this.Cursors.push(new NavigateCursorElement(this, 5, rect.Height, this.Owner.CoordinateSystem.AxisColor, "1", { X: rect.Left, Y: rect.Top }, "I1", 0));
        this.Cursors.push(new NavigateCursorElement(this, 5, rect.Height, this.Owner.CoordinateSystem.AxisColor, "1", { X: rect.Left, Y: rect.Top }, "I2", 1));
        for (let cursor of this.Cursors) {
            $(this.Owner.PluginContainer).append(cursor.DOM);
        }
        this.dragRect.attr("height", rect.Height);
        this.OnRender();
    }
    ProcessCursorMove(e, i) {
        let offsetx = this.getMousePostion(e);
        let data = this.getOffsetData(offsetx);
        if (data == null) return;
        if (i > 0) {
            let last = this.CursorDatas[i - 1];
            if (data.XIndex - last.XIndex < 0) data = last;
            if (data.XIndex - last.XIndex > this.MaxEndDiff) {
                data = this.Owner.CoordinateSystem.GetPositionByIndex(last.XIndex + this.MaxEndDiff);
            }
        }
        if (i < this.CursorDatas.length - 1) {
            let next = this.CursorDatas[i + 1];
            if (next.XIndex - data.XIndex < 0) data = next;
            if (next.XIndex - data.XIndex > this.MaxEndDiff) {
                data = this.Owner.CoordinateSystem.GetPositionByIndex(next.XIndex - this.MaxEndDiff);
            }
        }
        this.CursorDatas[i] = data;
        this.UpdatePosition(this.CursorDatas);
    }
    GetProcessIndex(i, index) {
        let result = index;
        if (i > 0) {
            let last = this.CursorDatas[i - 1];
            if (index - last.XIndex < 0) result = last.XIndex;
            if (index - last.XIndex > this.MaxEndDiff) result = last.XIndex + this.MaxEndDiff;
        }
        if (i < this.CursorDatas.length - 1) {
            let next = this.CursorDatas[i + 1];
            if (next.XIndex - index < 0) result = next.XIndex;
            if (next.XIndex - index > this.MaxEndDiff) result = next.XIndex - this.MaxEndDiff;
        }
        return result;
    }
    UpdatePosition(data) {
        if (data?.length > 0) {
            this.CursorDatas = data;
            this.NotifyPositionChanged();
            const rect = this.Owner.Information.ShapeContainerRect;
            for (let i = 0; i < this.Cursors.length; i++) {
                const cursor = this.Cursors[i];
                const data = this.CursorDatas[i];
                if (!isNaN(data.OffsetX) && isFinite(data.OffsetX)) {
                    if (data.OffsetX >= 0 && Math.floor(data.OffsetX) <= rect.Width) {
                        cursor.DOM.removeClass("hide");
                        cursor.DOM.attr("transform", "translate(" + (data.OffsetX - 6) + ",0)");
                    } else {
                        cursor.DOM.addClass("hide");
                    }
                }
            }
            let width = Math.abs(data[0].OffsetX - data[1].OffsetX);
            if (data[1].OffsetX > rect.Width) {
                data[1].OffsetX = rect.Width;
            }
            this.dragRect
                .attr("x", data[0].OffsetX + rect.Left)
                .attr("y", 0)
                .attr("width", width);
            this.dragRect.parent().removeClass("hide");
        } else {
            for (let cursor of this.Cursors) {
                cursor.DOM.attr("transform", "translate(-6,0)");
            }
            this.dragRect.parent().addClass("hide");
            this.NotifyPositionChanged();
        }
    }
    NotifyPositionChanged() {
        super.NotifyPositionChanged();
        this.Entity.onDataChange?.(this.CursorDatas);
    }
    Close() {
        super.Close();
        this.dragRect.parent().remove();
        this.dragRect = null;
    }
}

export class NavigateHarmonicCursorPlugin extends NavigateDoubleCursorPlugin {
    constructor(mode) {
        super(mode);
        this.MaxEndDiff = 15;
    }
    OnSizeChanged() {
        let rect = this.Owner.Information.ShapeContainerRect;
        this.LegendWnd.css("left", rect.Left + rect.Width - this.LegendWnd.width());
        this.LegendWnd.css("top", rect.Top);
        $.each(this.Cursors, function (i, cursor) {
            cursor.Remove();
        }); this.Cursors = new Array();
        this.Cursors.push(new CursorElement(this, rect.Height, this.Owner.CoordinateSystem.AxisColor, "1", { X: rect.Left, Y: rect.Top }, ""));
        this.Cursors.push(new CursorElement(this, rect.Height, this.Owner.CoordinateSystem.AxisColor, "1", { X: rect.Left, Y: rect.Top }, ""));
        let sender = this;
        $.each(this.Cursors, function (i, cursor) {
            cursor.StopPropagation = false;
            $(sender.Owner.PluginContainer).append(cursor.DOM);
        });
        this.OnRender();
    }
    NotifyPositionChanged() {
        if (this.Entity.onPositionChanged && this.IsNotify == true && this.CursorDatas.length > 0) {
            let data = this.Owner.CoordinateSystem.GetHarmonicCursorData(this.CursorDatas[0].OffsetX, this.CursorDatas[1].XIndex - this.CursorDatas[0].XIndex);
            let e = {
                Cursors: new Array(),
                Datas: data.TimesFrequencyData,
            };
            $.each(this.CursorDatas, function (i, cd) {
                e.Cursors.push(cd);
            });
            this.Entity.onPositionChanged(e);
        }
    }
}

export class NavigateCursorPlugin extends TrendSingleCursorPlugin {
    constructor(mode) {
        super(mode);
        this.Title = i18nGlobal('core.navigateCursor');
    }
}

export class NavigateTrendDoubleCursorPlugin extends DoubleCursorPlugin {
    constructor(mode) {
        super(mode);
        this.Title = i18nGlobal('core.navigateTrendDoubleCursor');
    }
    OnRender() {
        this.IsNotify = false;
        let pos = new Array();
        if (this.CursorDatas.length > 0) {
            for (let i = 0; i < this.CursorDatas.length; i++) {
                if (this.CursorDatas[i].X) {
                    let x = this.Owner.CoordinateSystem.GetPositionByX(this.CursorDatas[i].X);
                    if (x) {
                        pos.push(x.XIndex);
                        if (this.CursorDatas[i].X != x.X) this.IsNotify = true;
                    } else {
                        pos.push(0);
                    }
                } else {
                    pos.push(this.CursorDatas[i].XIndex);
                    this.IsNotify = true;
                }
            }
        } else {
            for (let i = 0; i < this.CursorCount; i++) {
                pos.push(0 + i * 2);
            }
            this.IsNotify = true;
        }
        this.SetPosition(pos);
        this.IsNotify = true;
    }
    OnSizeChanged() {
        super.OnSizeChanged();
        $.each(this.Cursors, function (i, cursor) {
            cursor.Remove();
        });
        let rect = this.Owner.Information.ShapeContainerRect;
        this.Cursors = new Array();
        for (let i = 0; i < this.CursorCount; i++) {
            this.Cursors.push(new NavigateTrendDoubleCursorElement(this, rect.Height, this.Owner.CoordinateSystem.AxisColor, "1", { X: rect.Left, Y: rect.Top }, "I" + (i + 1)));
        }
        let sender = this;
        $.each(this.Cursors, function (i, cursor) {
            $(sender.Owner.PluginContainer).append(cursor.DOM);
        });
        this.OnRender();
    }
}

export class NavigateRecorderCursorPlugin extends NavigateDoubleCursorPlugin {
    constructor(mode) {
        super(mode);
    }
    OnRender() {
        this.IsNotify = false;
        const pos = new Array();
        const info = this.Owner.Information;
        if (this.CursorDatas.length > 0) {
            if (this.CursorDatas[0].X < info.CoordinateInfo.XMin || this.CursorDatas[0].X > info.CoordinateInfo.XMax) {
                this.IsNotify = true;
                pos.push(info.CoordinateInfo.XMin);
            } else {
                pos.push(this.CursorDatas[0].X);
            }
            if (this.CursorDatas[1].X < info.CoordinateInfo.XMin || this.CursorDatas[1].X > info.CoordinateInfo.XMax) {
                this.IsNotify = true;
                pos.push(info.CoordinateInfo.XMax);
            } else {
                pos.push(this.CursorDatas[1].X);
            }
        } else {
            this.IsNotify = true;
            pos.push(info.CoordinateInfo.XMin);
            pos.push(info.CoordinateInfo.XMax);
        }
        this.SetPosition(pos);
        this.IsNotify = true;
    }
    getOffsetData(offsetx) {
        const x = this.Owner.CoordinateSystem.GetXValue(offsetx);
        return {
            X: x,
            OffsetX: offsetx,
            XText: this.Owner.CoordinateSystem.FormatXText(x)
        }
    }
    ProcessCursorMove(e, i) {
        const offsetx = this.getMousePostion(e);
        let data = this.getOffsetData(offsetx);
        if (data == null) return;
        if (i > 0) {
            let last = this.CursorDatas[i - 1];
            if (data.X - last.X < 0) data = last;
        }
        if (i < this.CursorDatas.length - 1) {
            let next = this.CursorDatas[i + 1];
            if (next.X - data.X < 0) data = next;
        }
        this.CursorDatas[i] = data;
        this.UpdatePosition(this.CursorDatas);
    }
    KeyLeftPress() { }
    KeyRightPress() { }
    SetPosition(pos) {
        this.CursorDatas = new Array();
        const rect = this.Owner.Information.ShapeContainerRect;
        const info = this.Owner.Information;
        if (pos.length == 2) {
            if (pos[0] < info.CoordinateInfo.XMin || pos[0] > info.CoordinateInfo.XMax) {
                pos[0] = info.CoordinateInfo.XMin;
            }
            if (pos[1] < info.CoordinateInfo.XMin || pos[1] > info.CoordinateInfo.XMax) {
                pos[1] = info.CoordinateInfo.XMax;
            }
        }
        for (const p of pos) {
            const data = this.Owner.CoordinateSystem.GetXOffset(p);
            this.CursorDatas.push({
                X: p,
                OffsetX: data - rect.Left,
                XText: this.Owner.CoordinateSystem.FormatXText(p)
            });
        }
        this.UpdatePosition(this.CursorDatas);
    }
    SetPositionByX() { }
    GetCursorPosition() {

    }
    GetZoomPosition() {
        const info = this.Owner.Information;
        return (info.CoordinateInfo.XMin + info.CoordinateInfo.XMax) / 2;
    }
}
