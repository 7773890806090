import $ from "jquery";
import { numberRound } from "@common/js/rules";
import { PluginMode, FreqType } from "@core/graphs/webcharts/Dh-webchart-models";
import { CursorElement, CursorPluginBase } from "@core/graphs/plugins/Dh-plugin-cursor-base";
import { i18nGlobal } from "@common/js/utils";
import { PeakValueCursorPlugin } from "./Dh-plugin-peak-valley-cursor";

export class FourCursorPlugin extends CursorPluginBase {
    constructor() {
        super();
        this.Title = i18nGlobal('core.fourCursor');
        this.CursorCount = 4;
        this.CursorGroups = new Array();
        let group1 = {
            Cursors: new Array(),
            IsMouseDown: false,
            StartDatas: new Array(),
            StartPos: null,
            CursorDatas: new Array(),
        };
        let group2 = {
            Cursors: new Array(),
            IsMouseDown: false,
            StartDatas: new Array(),
            StartPos: null,
            CursorDatas: new Array(),
        };
        this.CursorGroups.push(group1);
        this.CursorGroups.push(group2);
    }
    OnSizeChanged() {
        super.OnSizeChanged();
        $.each(this.CursorGroups, function (i, group) {
            $.each(group.Cursors, function (j, cursor) {
                cursor.Remove();
            });
            group.Cursors = new Array();
        });
        let rect = this.Owner.Information.ShapeContainerRect;
        this.CursorGroups[0].Cursors.push(new CursorElement(this, rect.Height, this.Owner.CoordinateSystem.AxisColor, "1", { X: rect.Left, Y: rect.Top }, "IL"));
        this.CursorGroups[0].Cursors.push(new CursorElement(this, rect.Height, this.Owner.CoordinateSystem.AxisColor, "1", { X: rect.Left, Y: rect.Top }, "IR"));
        this.CursorGroups[1].Cursors.push(new CursorElement(this, rect.Height, this.Owner.CoordinateSystem.AxisColor, "1", { X: rect.Left, Y: rect.Top }, "IIL"));
        this.CursorGroups[1].Cursors.push(new CursorElement(this, rect.Height, this.Owner.CoordinateSystem.AxisColor, "1", { X: rect.Left, Y: rect.Top }, "IIR"));
        let sender = this;
        $.each(this.CursorGroups, function (i, group) {
            $.each(group.Cursors, function (j, cursor) {
                $(sender.Owner.PluginContainer).append(cursor.DOM);
            });
        });
        this.OnRender();
    }
    OnRender() {
        let pos = new Array();
        $.each(this.CursorGroups, function (i, group) {
            if (group.CursorDatas.length > 0) {
                for (let j = 0; j < group.CursorDatas.length; j++) {
                    if (this.CursorDatas[j]?.XIndex != null) {
                        pos.push(this.CursorDatas[j].XIndex);
                    } else {
                        pos.push(0);
                    }
                }
            } else {
                for (let j = 0; j < 2; j++) {
                    pos.push(0);
                }
            }
        });
        this.SetPosition(pos);
    }
    OnMouseDown(e) {
        if (!super.OnMouseDown(e)) return false;
        let rect = this.Owner.Information.ShapeContainerRect;
        if (this.CursorGroups.length == 0) return false;

        $.each(this.CursorGroups, function (i, group) {
            if (group.CursorDatas?.length > 0) {
                let start = group.CursorDatas[0].OffsetX;
                let end = group.CursorDatas[group.Cursors.length - 1].OffsetX;
                for (let j = 0; j < group.Cursors.length; j++) {
                    if (start > group.CursorDatas[j].OffsetX) start = group.CursorDatas[j].OffsetX;
                    if (end < group.CursorDatas[j].OffsetX) end = group.CursorDatas[j].OffsetX;
                }
                let xc = e.offsetX - rect.Left;
                if (xc >= start - 5 && xc <= end + 5) {
                    group.IsMouseDown = true;
                    group.StartPos = xc;
                    group.StartDatas = new Array();
                    for (let k = 0; k < group.CursorDatas.length; k++) {
                        group.StartDatas.push(group.CursorDatas[k].OffsetX);
                    }
                }
            }
        });
        //e.stopPropagation();
        return true;
    }
    OnMouseMove(e) {
        if (!super.OnMouseMove(e)) return false;
        if (e.which !== 1) return false;
        if (this.CursorGroups.length == 0) return false;

        let sender = this;
        let rect = this.Owner.Information.ShapeContainerRect;
        this.IsNotify = false;
        $.each(this.CursorGroups, function (i, group) {
            if (group.IsMouseDown) {
                //区域拖动
                if (group.StartDatas.length == 0) return;
                let delta = e.offsetX - rect.Left - this.StartPos;
                $.each(group.StartDatas, function (i, sd) {
                    let offsetx = sd + delta;
                    if (offsetx < 0) delta = -sd;
                    if (offsetx > rect.Width) delta = rect.Width - sd;
                });
                group.CursorDatas = new Array();
                $.each(group.StartDatas, function (i, sd) {
                    let offsetx = sd + delta;
                    offsetx = offsetx < 0 ? 0 : offsetx;
                    offsetx = offsetx > rect.Width ? rect.Width : offsetx;
                    let data = sender.Owner.CoordinateSystem.GetPositionData(offsetx);
                    if (data) group.CursorDatas.push(data);
                });
            } else {
                $.each(group.Cursors, function (j, cursor) {
                    if (cursor.IsMouseDown) {
                        let offsetx = sender.getMousePostion(e);
                        let data = sender.Owner.CoordinateSystem.GetPositionData(offsetx);
                        if (data) group.CursorDatas[j] = data;
                    }
                });
            }
        });
        sender.UpdatePosition(this.CursorGroups);
        return true;
    }
    OnMouseUp(e) {
        if (!super.OnMouseUp(e)) return false;
        if (e.which !== 1) return false;
        let sender = this;
        $.each(this.CursorGroups, function (i, group) {
            if (group.IsMouseDown) {
                group.IsMouseDown = false;
                sender.RefreshRelatedData();
            } else {
                $.each(group.Cursors, function (j, cursor) {
                    if (cursor.IsMouseDown) {
                        cursor.IsMouseDown = false;
                        sender.RefreshRelatedData();
                    }
                });
            }
        });
        return true;
    }
    KeyLeftPress() {
        if (!this.IsEnabled) return;
        let pos = new Array();
        this.CheckForCusor();
        for (let group of this.CursorGroups) {
            if (group.CursorDatas.length > 0) {
                this.IsNotify = false;
                for (let i = 0; i < group.Cursors.length; i++) {
                    const cursor = group.Cursors[i];
                    let data = group.CursorDatas[i];
                    if (cursor.IsSelected()) {
                        data.XIndex = data.XIndex > 0 ? data.XIndex - 1 : data.XIndex;
                        data.XIndex = this.GetProcessIndex(i, data.XIndex);
                    }
                    pos.push(data.XIndex);
                }
            } else {
                pos.push(0);
                pos.push(0);
            }
        }
        this.SetPosition(pos);
    }
    KeyRightPress() {
        if (!this.IsEnabled) return;
        let pos = new Array();
        this.CheckForCusor();
        for (let group of this.CursorGroups) {
            if (group.CursorDatas.length > 0) {
                this.IsNotify = false;
                for (let i = 0; i < group.Cursors.length; i++) {
                    const cursor = group.Cursors[i];
                    let data = group.CursorDatas[i];
                    if (cursor.IsSelected()) {
                        data.XIndex += 1;
                        data.XIndex = this.GetProcessIndex(i, data.XIndex);
                    }
                    pos.push(data.XIndex);
                }
            } else {
                pos.push(0);
                pos.push(0);
            }
        }
        this.SetPosition(pos);
    }
    CheckForCusor() {
        let isSelected = false;
        for (let group of this.CursorGroups) {
            for (let cursor of group.Cursors) {
                if (cursor.IsSelected()) {
                    isSelected = true;
                }
            }
        }
        if (!isSelected) {
            this.CursorGroups.Cursors[0].Select();
        }
    }
    GetProcessIndex(i, index) {
        return index;
    }
    RefreshRelatedData() {
        this.IsNotify = true;
        this.NotifyPositionChanged();
    }
    UpdatePosition(data) {
        let sender = this;
        $.each(this.CursorGroups, function (i, group) {
            $.each(group.Cursors, function (j, cursor) {
                cursor.ClearPoints();
            });
        });
        let container = this.LegendWnd.find(".cursor-container");
        var rect = this.Owner.Information.ShapeContainerRect;
        container.empty();
        if (data?.length > 0) {
            let serieses = sender.Owner.ShapeOperator.Data;
            let legend = sender.Owner.PluginManager.GetPlugin(PluginMode.Statistics);
            let seriesData = null;
            $.each(serieses, function (i, series) {
                if (series.PointId == legend.SelectedItem) {
                    seriesData = series;
                }
            });
            if (seriesData == null) return;
            $.each(data, function (i, group) {
                if (group.CursorDatas.length == 2) {
                    let x1 = group.CursorDatas[0],
                        x2 = group.CursorDatas[1];
                    if (group.CursorDatas[0].X > group.CursorDatas[1].X) {
                        x1 = group.CursorDatas[1];
                        x2 = group.CursorDatas[0];
                    }
                    let sum = 0;
                    let count = 0;
                    for (let k = 0; k < seriesData.Points.length; k++) {
                        if (seriesData.Points[k].X >= x1.X && seriesData.Points[k].X <= x2.X) {
                            sum += seriesData.Points[k].Y;
                            if (seriesData.Points[k].Y1) sum += seriesData.Points[k].Y1;
                            count++;
                        }
                    }
                    if (count == 0) count = 1;
                    let deltat = sender.Owner.CoordinateSystem.FormatXSpanText(Math.abs(x1.X - x2.X));
                    let avg = numberRound(sum / count, sender.Owner.CoordinateSystem.YDecimalPrecision);
                    let rowContent = "X" + (i + 1) + ":[" + x1.XText + "," + x2.XText + "] Δt" + (i + 1) + ":" + deltat + " Ave(Y" + (i + 1) + "):" + avg;
                    container.append('<span class="cursor-row" style="color:' + seriesData.LineColor + '">' + rowContent + "</span>");
                }
            });
            this.CursorGroups = data;
            this.NotifyPositionChanged();
            $.each(this.CursorGroups, function (i, group) {
                $.each(group.Cursors, function (j, cursor) {
                    if (group.CursorDatas.length == 2) {
                        let data = group.CursorDatas[j];
                        if (!isNaN(data.OffsetX) && isFinite(data.OffsetX)) {
                            if (data.OffsetX >= 0 && Math.floor(data.OffsetX) <= rect.Width) {
                                cursor.DOM.removeClass("hide");
                                cursor.DOM.attr("transform", "translate(" + (data.OffsetX - 6) + ",0)");
                            } else {
                                cursor.DOM.addClass("hide");
                            }
                            if (data.Items.length > 0) {
                                const targetItem = data.Items.find(e => e.Tag.Source.PointId == legend.SelectedItem) ?? data.Items[0];
                                cursor.DrawPoint(targetItem.OffsetY + rect.Top);
                            }
                        }
                    }
                });
            });
        } else {
            $.each(this.CursorGroups, function (i, group) {
                $.each(group.Cursors, function (j, cursor) {
                    cursor.DOM.attr("transform", "translate(-6,0)");
                });
            });
        }
    }
    NotifyPositionChanged() { }
    Close() {
        super.Close();
        $.each(this.CursorGroups, function (i, group) {
            $.each(group.Cursors, function (j, cursor) {
                cursor.Remove();
            });
        });
        if (this.LegendWnd != null) {
            this.LegendWnd.remove();
        }
    }
    GetPosition() {
        let sender = this;
        let result = {
            PluginType: sender.Entity.Type,
            Position: new Array(),
        };
        if (this.CursorGroups.length > 0) {
            for (let group of this.CursorGroups) {
                for (let d of group.CursorDatas) {
                    result.Position.push(d);
                }
            }
        }
        return result;
    }
    GetCursorPosition() {
        let array = [];
        if (this.CursorGroups.length > 0) {
            $.each(this.CursorGroups, function (i, group) {
                $.each(group.CursorDatas, function (j, d) {
                    array.push(d.XIndex);
                });
            });
        }
        return array;
    }
    SetPosition(pos) {
        if (pos.length !== 4) return;
        let group = [
            [pos[0], pos[1]],
            [pos[2], pos[3]],
        ];
        let data = this.Owner.ShapeOperator.Data;
        if (data.length > 0) {
            let i = 0;
            for (let g of group) {
                let group = this.CursorGroups[i];
                group.CursorDatas = new Array();
                for (let p of g) {
                    let data = this.Owner.CoordinateSystem.GetPositionByIndex(p);
                    if (data) {
                        group.CursorDatas.push(data);
                    }
                }
                i++;
            }
            this.UpdatePosition(this.CursorGroups);
        } else {
            let i = 0;
            for (let g of group) {
                let group = this.CursorGroups[i];
                group.CursorDatas = new Array();
                for (let p of g) {
                    group.CursorDatas.push({
                        XIndex: p,
                    });
                }
                i++;
            }
            this.UpdatePosition(null);
        }
    }
    SetPositionByX(pos) {
        this.IsNotify = false;
        if (pos.length !== 4) return;
        let group = [
            [pos[0], pos[1]],
            [pos[2], pos[3]],
        ];
        let data = this.Owner.ShapeOperator.Data;
        if (data.length > 0) {
            let i = 0;
            for (let g of group) {
                let group = this.CursorGroups[i];
                group.CursorDatas = new Array();
                for (let p of g) {
                    let data = this.Owner.CoordinateSystem.GetPositionByX(p);
                    if (data) {
                        group.CursorDatas.push(data);
                    }
                }
                i++;
            }
            this.UpdatePosition(this.CursorGroups);
        }
        this.IsNotify = true;
    }
    GetZoomPosition() {
        if (this.CursorGroups.length > 0) {
            let datas = new Array();
            $.each(this.CursorGroups, function (i, group) {
                $.each(group.CursorDatas, function (j, data) {
                    datas.push(data);
                });
            });
            datas = datas.orderBy((p) => p.X);
            return datas[1].X;
        } else {
            let info = this.Owner.Information;
            return (info.CoordinateInfo.XMin + info.CoordinateInfo.XMax) / 2;
        }
    }
    ClearData() {
        // $.each(this.CursorGroups, function (i, group) {
        //     $.each(group.Cursors, function (j, cursor) {
        //         cursor.CursorDatas = new Array();
        //     });
        // });
    }
}