import { nextTick, onMounted, onBeforeUnmount, reactive, ref, watch } from "vue";
import { Debounce } from "@common/js/utils";
import axios from "@common/js/axios";
import _ from "lodash";
import { ChartRealType, ClientRealContext, PointCacheContext } from "@core/js/clientRealContext";
import { PluginMode, ScaleType, CoordInfo, XAxisShowType, XAxisType } from "@core/graphs/webcharts/Dh-webchart-models";
import { EnumQueryType, EnumComponentTheme, ThemeResource, AmplitudeAlarm, MutationAlarm, AlarmFilter, TitleAlign, AlgroithmGroups, AlgorithmTypeEnum } from "./model";
import store from "@common/js/store";
import dayjs from "dayjs";
import { alertErrorMsg, alertSuccessMsg, elFullScreenLoading, guid, i18nGlobal, alertWarningMsg } from "@common/js/utils";
import * as componentList from "./componentLibrary";
import { ruleCheck } from "@common/js/rules";
import { AlarmTypeModelEnum, NormalLevel, PlotTypeEnum, ParentGuid } from "@common/js/enum";
import { GrpcApiContext } from "@grpc/grpcApi";
import { NavigateOperator } from "./navigateOperator";
export class ComponentBase {
	constructor() {
		this.id = null;
		this.theme = EnumComponentTheme.Light;
		this.containerId = guid();
		this.option = this.createOption();
		this.componentCategory = null;
		this.showProperty = true;
		this.name = null;
		this.appearance = reactive({
			TitleAlignment: TitleAlign.Center,
			ShowBorder: true
		})
		this.toolbarOption = reactive({
			showBar: true,
			showToolbar: true,
			showExport: false,
			showNavigate: false,
			showSave: true,
			showMove: false,
			showClose: true,
			showRestore: true,
			navigateExpand: false,
			isMaxsized: false,
			showModelBase: false,
			showBatch: false,
			hasWorkState: true,
			ShowComponentTitle: false,
			showBiliboard: true,
			TitleAlignment: TitleAlign.Center,
			ShowBorder: true,
			showStatusSwitch: false,
			currentBillboard: 1,
			showBoard: true,
			batchEnable: true
		});
		this.setDefaultPoint = true;
		this.onComponentClose = null;
		this.onComponentMaximize = null;
		this.onComponentRestore = null;
		this.onComponentExport = null;
		this.onComponentSave = null;
		this.onComponentChangeNav = null;
		this.onSaveLayoutHanlder = null;
		this.onRemoveUnitHandler = null;
		this.onSaveToHandler = null;
		this.onSaveToFaultHandler = null;
		this.onNextBatchClick = null;
		this.onLastBatchClick = null;
		this.onClickHandler = null;
		this.layoutOperator = null;
		this.debounce = new Debounce();
		this.onComponentSizeChanged = new Map();
		this.onComponentCreated = null;
		this.watcher = [];
		this.hasAlarm = true;
		this.navigateOperator = this.createNavigateOperor();
		this.initEvents();
	}
	createNavigateOperor() {
		return new NavigateOperator(this);
	}
	getLayoutItem() {
		return this.layoutOperator?.state?.graphData.find((r) => r.chartRef.component == this);
	}
	createOption() {
		return {};
	}
	initEvents() { }
	closeComponent() {
		this.onComponentClose?.();
	}
	createComponent(props, immediate = true) {
		const option = props.componentOption;
		this.layoutOperator = option?.owner;
		this.option.Id = this.containerId + "_container";
		this.id = option?.option?.Id ?? option.componentId;
		this.toolbarOption.ShowBorder = option?.option?.ShowBorder ?? store.state.graphSetting.ShowBorder ?? true;
		this.toolbarOption.ShowComponentTitle = option?.option?.ShowComponentTitle ?? store.state.graphSetting.ShowComponentTitle ?? true;
		this.toolbarOption.TitleAlignment = option?.option?.TitleAlignment ?? store.state.graphSetting.TitleAlignment ?? TitleAlign.Center;
		this.toolbarOption.showToolbar = option?.showToolbar ?? this.toolbarOption.showToolbar;
		this.toolbarOption.showBatch = option?.showBatch ?? this.toolbarOption.showBatch;
		this.showProperty = option?.showProperty ?? true;
		this.toolbarOption.showExport = option?.showExport ?? this.toolbarOption.showExport;
		this.toolbarOption.showSave = option?.showSave ?? this.toolbarOption.showSave;
		this.toolbarOption.showMove = option?.showMove ?? this.toolbarOption.showMove;
		this.toolbarOption.showBoard = option?.showBoard ?? this.toolbarOption.showBoard;
		this.toolbarOption.showClose = option?.showClose ?? this.toolbarOption.showClose;
		this.toolbarOption.showRestore = option?.showRestore ?? this.toolbarOption.showRestore;
		if (this.layoutOperator?.billboard) {
			this.toolbarOption.currentBillboard = option?.billboardId ?? this.layoutOperator.billboard.currentBillboard ?? ParentGuid;
		}
		this.toolbarOption.isMaxsized = option?.isMaxsized ?? this.toolbarOption.isMaxsized;
		this.setDefaultPoint = option?.setDefaultPoint ?? this.setDefaultPoint;
		if (option?.option && immediate) {
			this.setTheme(option.option.Theme);
		}
	}
	close() {
		this.watcher.forEach(t => t());
		for (let p in this) {
			this[p] = null;
		}
	}
	setTheme(theme) {
		this.theme = theme;
		this.option.Axis.Theme = theme;
	}
	updateSize(rect) {
		this.onComponentSizeChanged.forEach((v) => {
			v(rect);
		});
	}
	setParam(param) { }
	getParam() {
		return "{}";
	}
	getAppearance() {
		return {
			ShowComponentTitle: this.toolbarOption.ShowComponentTitle,
			TitleAlignment: this.toolbarOption.TitleAlignment,
			ShowBorder: this.toolbarOption.ShowBorder
		};
	}
	setAppearance(param) {
		this.toolbarOption.ShowBorder = param?.option?.ShowBorder ?? param?.ShowBorder ?? store.state.graphSetting.ShowBorder ?? true;
		this.toolbarOption.ShowComponentTitle = param?.option?.ShowComponentTitle ?? param?.ShowComponentTitle ?? store.state.graphSetting.ShowComponentTitle ?? true;
		this.toolbarOption.TitleAlignment = param?.option?.TitleAlignment ?? param?.TitleAlignment ?? store.state.graphSetting.TitleAlignment ?? TitleAlign.Center;
		if (this.layoutOperator?.routeType === 'summary') {
			if (this.option.Axis.ShowTitle !== undefined) {
				this.option.Axis.ShowTitle = !this.toolbarOption.ShowComponentTitle;
				nextTick(() => {
					this.layoutOperator?.sizeChangeHandler()
				});
			}
		}
	}
	getAlarm() {
		return null;
	}
	registerDeleteHandler(map) {

	}
	useDataRefresh() { }
	getDefaultPointIndex(count) {
		if (this.layoutOperator) {
			let pointCycleIndex = this.layoutOperator.pointCycleIndex;
			if (pointCycleIndex > count) pointCycleIndex = pointCycleIndex % count;
			this.layoutOperator.pointCycleIndex = (this.layoutOperator.pointCycleIndex + 1) % count;
			return pointCycleIndex;
		}
		return 0;
	}
	async setDefaultRegisterPoint() { }
	async getPoints(node) {
		let res = [];
		if (this.layoutOperator && this.layoutOperator.plotType === PlotTypeEnum.Analysis) {
			res = await getAnalysisPoints(this.layoutOperator);
		} else {
			res = await getSimplePoints(node);
		}
		return res;
	}
	async getDefaultRegisterPoints(node) {
		let res = [];
		if (this.layoutOperator && this.layoutOperator.plotType === PlotTypeEnum.Analysis) {
			res = await getAnalysisPoints(this.layoutOperator);
		} else {
			res = await getDefaultRegisterPoints(node);
		}
		return res;
	}
	async getNavPoints(node) {
		const points = await this.getPoints(node);
		return points.filter(p => p.EigenTypes.length > 0
			&& p.SignalType != AlgorithmTypeEnum.CableAnalysis
			&& p.SignalType != AlgorithmTypeEnum.RainflowAnalysis
			&& p.SignalType != AlgorithmTypeEnum.AmpAnalysis)
	}
	registerWatchObject(watchObj, action, config = {}) {
		this.watcher.push(
			watch(
				watchObj,
				(newValue, oldValue) => action?.(newValue, oldValue),
				config
			)
		);
	}
	graphChanged() {

	}
	clearData() {

	}
}
export class GraphComponentBase extends ComponentBase {
	constructor() {
		super();
		this.isHistory = ref(false);
		this.createPlugin();
		this.graph = null;
		this.client = this.createClient();
		this.dataClient = this.createDataClient();
		this.needUnRegister = false;
		this.requestId = guid();
		this.onGraphChanged = null;
		this.showDataInput = ref(true);
		this.showAlarm = ref(true);
		this.showAlgorithm = ref(true);
		this.disableAlarm = ref(false);
		this.disableAlarmParam = ref(false);
		this.showStorage = ref(true);
		this.startTime = null;
		this.endTime = null;
		this.isApp = store.state.browseType === 'app';
		this.dataCallback = (data) => {
			this.appendData(data);
		};
		this.inputPointCount = 1;
		this.hasEigen = false;
	}
	createClient() { }
	createDataClient() {
		return GrpcApiContext.GetInstance();
	}
	createPlugin() { }
	createComponent(props) {
		const option = props.componentOption;
		this.layoutOperator = option?.owner;
		this.option.Id = this.containerId + "_container";
		this.id = option?.option?.Id ?? option.componentId;
		this.toolbarOption.ShowBorder = option?.option?.ShowBorder ?? store.state.graphSetting.ShowBorder ?? true;
		this.toolbarOption.ShowComponentTitle = option?.option?.ShowComponentTitle ?? store.state.graphSetting.ShowComponentTitle ?? true;
		this.toolbarOption.TitleAlignment = option?.option?.TitleAlignment ?? store.state.graphSetting.TitleAlignment ?? TitleAlign.Center;
		this.isHistory.value = option?.isHistory;
		this.toolbarOption.showToolbar = option?.showToolbar ?? this.toolbarOption.showToolbar;
		this.toolbarOption.showBatch = option?.showBatch ?? this.toolbarOption.showBatch;
		this.showProperty = option?.showProperty ?? true;
		this.showDataInput.value = option?.showDataInput ?? true;
		this.showAlarm.value = option?.showAlarm ?? true;
		this.showAlgorithm.value = option?.showAlgorithm ?? true;
		this.disableAlarm.value = option?.disableAlarm ?? false;
		this.disableAlarmParam.value = option?.disableAlarmParam ?? false;
		this.showStorage.value = option?.showStorage ?? true;
		this.toolbarOption.showExport = option?.showExport ?? this.toolbarOption.showExport;
		this.toolbarOption.showSave = option?.showSave ?? this.toolbarOption.showSave;
		this.toolbarOption.showMove = option?.showMove ?? this.toolbarOption.showMove;
		this.toolbarOption.showClose = option?.showClose ?? this.toolbarOption.showClose;
		this.toolbarOption.showRestore = option?.showRestore ?? this.toolbarOption.showRestore;
		this.toolbarOption.showBoard = option?.showBoard ?? this.toolbarOption.showBoard;
		if (this.layoutOperator?.billboard) {
			this.toolbarOption.currentBillboard = option?.billboardId ?? this.layoutOperator.billboard.currentBillboard ?? ParentGuid;
		}
		this.toolbarOption.isMaxsized = option?.isMaxsized ?? this.toolbarOption.isMaxsized;
		this.setDefaultPoint = option?.setDefaultPoint ?? this.setDefaultPoint;
		this.checkShowStorage(option?.showStorage);
		if (option?.option) {
			this.setTheme(option.option.Theme);
			this.setAlarm(option.option.Alarm);
			this.setAlarmFilter(option.option.AlarmFilter);
		}
	}
	setAlarmFilter() {

	}
	setAlarm(alarm) {
		const alarmInfos = [];
		let amplitudeAlarm = new AmplitudeAlarm();
		let mutationAlarm = new MutationAlarm();
		store.state.alarmInfo.filter(item => item.Level !== NormalLevel).forEach(item => {
			amplitudeAlarm.Levels.push({
				Level: item.Level,
				Threshold: 0,
				Enable: false,
				LowerLimit: 0,
				UpperLimit: 0,
				Color: item.Color,
				Name: item.Name
			})
			mutationAlarm.Levels.push({
				Level: item.Level,
				Threshold: 0,
				Enable: false,
				Color: item.Color,
				Name: item.Name
			})
		})
		alarmInfos.push(this.getOrCreateAlarmModel(AlarmTypeModelEnum.Amplitude, alarm, amplitudeAlarm));
		alarmInfos.push(this.getOrCreateAlarmModel(AlarmTypeModelEnum.Mutation, alarm, mutationAlarm));
		this.option.AlarmInfo = alarmInfos;
	}
	getOrCreateAlarmModel(type, alarm, model) {
		if (alarm) {
			const result = alarm?.find((item) => item.AlarmCategory === type);
			if (result) {
				if (result.Levels) {
					model.Levels.forEach(child => {
						const current = result.Levels.find(t => t.Level === child.Level);
						const alarmCurrent = store.state.alarmInfo.find(t => t.Level === child.Level);
						child.Name = alarmCurrent?.Name;
						child.Color = alarmCurrent?.Color;
						child.Threshold = current?.Threshold ?? 0;
						child.Enable = current?.Enable ?? false;
						if (type === AlarmTypeModelEnum.Amplitude) {
							child.LowerLimit = current?.LowerLimit ?? 0;
							child.UpperLimit = current?.UpperLimit ?? 0;
						}
					})
					result.Levels = model.Levels;
				}
				return result
			}
			return this.createAlarmModel(type, model);
		} else {
			return this.createAlarmModel(type, model);
		}
		// return alarm?.find((item) => item.AlarmCategory === type) ?? this.createAlarmModel(type, model);
	}
	createAlarmModel(type, model) {
		return {
			AlarmCategory: type,
			...model,
		};
	}
	createGraph() { }
	graphChanged(isHistory) {
		this.unRegisterAction();
		this.isHistory.value = isHistory;
		this.registerAction();

		if (!this.isHistory.value) this.beginQuery();
		this.onGraphChanged?.();
		if (isHistory == false) {
			this.startTime = null;
		}
	}
	beginQuery(changeData) {
		if (this.option.Axis.DataSource == 2) {
			this.client?.registerPoints(this.requestId, []);
			this.getClassicData();
		} else {
			this.beforeBeginQuery?.();
			if (this.option?.RegisterPoints) {
				if (this.isHistory.value) {
					let { startTime, endTime } = this.getQueryTime();
					if (startTime) startTime = dayjs(startTime).format("YYYY-MM-DD HH:mm:ss.SSS");
					if (endTime) endTime = dayjs(endTime).format("YYYY-MM-DD HH:mm:ss.SSS");
					this.getHistoryData(startTime, endTime, EnumQueryType.Fuzzy);
				} else {
					if (this.option.RegisterPoints.length == 0) this.graph.ClearData();
					this.client?.registerPoints(this.requestId, this.option.RegisterPoints, changeData);
					this.needUnRegister = true;
				}
			}
		}
	}
	getClassicData() {
		if (ruleCheck.IsNullOrSpaceStr(this.option.Axis.DataSouceId)) return;
		this.dataClient.getTypicalData({ id: this.option.Axis.DataSouceId })
			.then((res) => res && this.graph?.SetData(res));
	}
	getHistoryData() { }
	getQueryTime() {
		return { startTime: dayjs().format("YYYY-MM-DD HH:mm:ss.SSS"), endTime: null };
	}
	addRegisterPoints(registerPoints) {
		if (this.option?.RegisterPoints) {
			this.option.RegisterPoints = registerPoints;
		}
	}
	registerAction() {
		if (!this.isHistory.value) {
			this.client?.registerDataCalback(this.requestId, this.dataCallback);
		}
	}
	unRegisterAction() {
		if (!this.isHistory.value && this.needUnRegister) {
			this.needUnRegister = false;
			this.client?.removeDataCalback(this.requestId);
		}
	}
	setData(datas) {
		this.setTime(datas);
		this.graph?.SetData(datas);
	}
	appendData(datas) {
		this.setTime(datas);
		this.graph?.AppendData(this.processData(datas));
	}
	setTime(datas) {
		if (datas?.length > 0 && datas[0].SampleTime) {
			let priex = datas[0].SampleTime.toString().length > 13 ? 10000 : 1;
			this.startTime = dayjs(new Date(datas[0].SampleTime / priex)).format("YYYY-MM-DD HH:mm:ss.SSS");
		}
	}
	processData(datas) {
		return datas;
	}
	clearData(startTime, endTime) {
		this.graph?.ClearData(startTime, endTime);
	}
	updateSize(rect) {
		super.updateSize(rect);
		this.graph?.EnsureGraphSizes(rect);
		this.graph?.OnSizeChanged();
	}
	dataInputFilter(p) {
		return true;
	}
	algirthmFilter(p) {
		return p;
	}
	checkShowStorage(state) {
		this.showStorage.value = state && Config.showStorage;
	}
	async setDefaultRegisterPoint() {
		if (this.option.RegisterPoints.length == 0 && this.setDefaultPoint) {
			const points = await (await this.getDefaultRegisterPoints(store.state.currentDep)).filter((p) => this.dataInputFilter(p));
			if (points && points.length > 0) {
				for (let i = 0; i < this.inputPointCount; i++) {
					const idx = this.getDefaultPointIndex(points.length);
					const point = points[idx];
					if (this.hasEigen) {
						if (point.EigenTypes.length > 0) {
							this.option.RegisterPoints.push({
								PointId: point.Id,
								DeviceId: point.DeviceId,
								ValueList: this.getEigenList(point),
								Name: point.Name,
								SignalType: point.SignalType,
							});
						}
					} else {
						this.option.RegisterPoints.push({
							PointId: point.Id,
							DeviceId: point.DeviceId,
							ValueList: JSON.stringify([]),
							Name: point.Name,
							SignalType: point.SignalType,
						});
					}
				}
				this.beginQuery();
			}
		}
	}
	getEigenList(point) {
		const eigen = point.EigenTypes[0];
		return JSON.stringify([
			{
				Id: eigen.Key,
				Name: eigen.Value,
			},
		])
	}
	close() {
		this.graph?.Clear();
		this.unRegisterAction();
		super.close();
		this.navigateOperator?.close();
	}
}
export class TimeDomainGraphBase extends GraphComponentBase {
	constructor() {
		super();
		this.onCursorChanged = null;
		this.freshDebounce = new Debounce();
		this.showXUnit = false;
	}
	createClient() {
		return ClientRealContext.GetInstance(ChartRealType.Block);
	}
	createOption() {
		return reactive({
			Id: "",
			UnitId: null,
			Key: guid(),
			Axis: {
				Title: "",
				ShowTitle: true,
				XAxisShowType: XAxisShowType.ShowTime,
				XDecimalPrecision: 3,
				YDecimalPrecision: 3,
				ShowUnit: true,
				ShowXUnit: false,
				GridSelfAdaption: false,
				XGridNum: 4,
				YGridNum: 4,
				ShowAlarmLine: true,
				HideAlarmLine: false,
				ShowLegend: true,
				AxisScaleType: store.state.graphSetting.ScaleType,
				ShowDot: store.state.graphSetting.ShowDot,
				XAxisType: 0,
				Theme: this.theme,
				DataSource: 1,
				DataSouceName: "",
				DataSouceId: null,
				ShowMode: 0,
				TimeLength: 10 * 60 * 1000,
				ShowCursorPointName: false
			},
			CoordInfo: new CoordInfo(),
			CursorType: PluginMode.None,
			Statistics: [],
			RegisterPoints: [],
			CursorPosition: null,
			AlarmInfo: [],
			AlarmFilter: new AlarmFilter()
		});
	}
	createPlugin() {
		this.Plugins = new Map();
		this.Plugins.set(PluginMode.YAxisZoomOut, { Type: PluginMode.YAxisZoomOut });
		this.Plugins.set(PluginMode.YAxisZoomIn, { Type: PluginMode.YAxisZoomIn });
		this.Plugins.set(PluginMode.XAxisZoomOut, { Type: PluginMode.XAxisZoomOut });
		this.Plugins.set(PluginMode.XAxisZoomIn, { Type: PluginMode.XAxisZoomIn });
		this.Plugins.set(PluginMode.DoubleCursor, { Type: PluginMode.DoubleCursor });
		this.Plugins.set(PluginMode.ThreeCursor, { Type: PluginMode.ThreeCursor });
		this.Plugins.set(PluginMode.FourCursor, { Type: PluginMode.FourCursor });
		this.Plugins.set(PluginMode.PeakValueCursor, { Type: PluginMode.PeakValueCursor });
		this.Plugins.set(PluginMode.ValleyValueCursor, { Type: PluginMode.ValleyValueCursor });
		this.Plugins.set(PluginMode.FlagCursor, { Type: PluginMode.FlagCursor });
		this.Plugins.set(PluginMode.YAxisRange, { Type: PluginMode.YAxisRange });
		this.Plugins.set(PluginMode.NavigateCursor, { Type: PluginMode.NavigateCursor });
		this.Plugins.set(PluginMode.Enlarge, { Type: PluginMode.Enlarge });
		this.Plugins.set(PluginMode.Statistics, { Type: PluginMode.Statistics });
		this.Plugins.set(PluginMode.AlarmCursor, { Type: PluginMode.AlarmCursor });
		this.Plugins.set(PluginMode.AutoScale, {
			Type: PluginMode.AutoScale,
			onClick: () => {
				this.option.Axis.AxisScaleType = this.option.Axis.AxisScaleType == ScaleType.Auto ? ScaleType.FullValue : ScaleType.Auto;
				this.graph?.UpdateScaleType();
			},
		});
	}
	createComponent(props) {
		super.createComponent(props);
		nextTick(() => {
			if (props.componentOption.option) {
				this.setParam(props.componentOption.option);
				this.setTheme(this.option.Axis.Theme);
			}
			this.createOwnGraph(props);
		});
	}
	async createOwnGraph(props) {
		this.graph = this.createGraph();
		if (this.graph != null) {
			this.graph.Init(this.option, this.Plugins.getValues());
			this.openPlugin();
			this.graphChanged(this.isHistory.value);
			this.setDefaultRegisterPoint(false);
			nextTick(() => {
				this.graph.EnsureGraphSizes();
				this.graph.OnSizeChanged();
				this.onComponentCreated?.();
			});
		}
	}
	openPlugin() {
		const plugins = this.Plugins.getValues()
			.filter((r) => r.Type >= 100)
			.map((p) => p.Type);
		this.graph.GetPluginManager().OpenPlugin(plugins);
	}
	initEvents() {
		super.initEvents();
		this.watchOption();
		this.wacthCursor();
		this.watchStatistics();
	}
	watchOption() {
		this.registerWatchObject(() => this.option, (p) => {
			if (this.showXUnit == false) {
				p.Axis.ShowXUnit = p.Axis.XAxisType == XAxisType.Relative;
				if (this.option.RegisterPoints.length > 0) {
					const point = this.option.RegisterPoints[0];
					if (point.SignalType && AlgroithmGroups[point.SignalType] == 90) {
						p.Axis.ShowXUnit = false;
					}
				}
			}
			this.updateOption(p);
		}, { deep: true, immediate: true });
	}
	wacthCursor() {
		this.registerWatchObject(() => this.option.CursorType, (newValue, oldValue) => {
			this.changeCursor(newValue, oldValue);
		});
	}
	watchStatistics() {
		this.registerWatchObject(() => this.option.Statistics, (newValue) => {
			if (this.graph) {
				const pluginManager = this.graph.GetPluginManager();
				const statics = pluginManager.GetPlugin(PluginMode.Statistics);
				if (statics && newValue) {
					statics.ValueType = newValue;
					statics.OnRender();
				}
			}
		});
	}
	changeCursor(newValue, oldValue) {
		if (this.graph) {
			const pluginManager = this.graph.GetPluginManager();
			pluginManager.ClosePlugin([oldValue]);
			pluginManager.OpenPlugin([newValue]);
			this.onCursorChanged?.(newValue);
		}
	}
	setParam(param) {
		if (param.YAxisLogType == 0) param.YAxisLogType = 1;
		if (param.XAxisLogType == 0) param.XAxisLogType = 1;
		for (let p in param) {
			if (this.option.Axis.hasOwnProperty(p)) {
				this.option.Axis[p] = param[p];
			}
			if (this.option.CoordInfo.hasOwnProperty(p)) {
				this.option.CoordInfo[p] = param[p];
			}
		}
		if (param.Statistics) this.option.Statistics = JSON.parse(param.Statistics);
		if (param.CursorType != null) this.option.CursorType = param.CursorType;
		if (param.RegisterPoints) this.option.RegisterPoints = param.RegisterPoints;
		if (param.UnitId) this.option.UnitId = param.UnitId;
		if (param.Key) this.option.Key = param.Key;
		if (!ruleCheck.IsNullOrSpaceStr(param.CursorPosition)) this.option.CursorPosition = JSON.parse(param.CursorPosition);
		if (param.Alarm != null) {
			this.updateAlarmInfo();
		}
		this.setAppearance(param);
		this.navigateOperator?.setParam(param);
	}
	updateAlarmInfo() {
		this.graph?.GetPluginManager()?.GetPlugin(PluginMode.AlarmCursor)?.SetAlarmInfo(this.option.AlarmInfo);
	}
	getParam() {
		const graphInfo = {
			Id: this.id,
			ComponentType: this.componentCategory,
			CursorType: this.option.CursorType,
			Statistics: JSON.stringify(this.option.Statistics),
			RegisterPoints: this.option.RegisterPoints,
			Key: this.option.Key,
			UnitId: this.option.UnitId,
			...this.getAppearance()
		};
		Object.assign(graphInfo, this.option.Axis);
		Object.assign(graphInfo, this.getCoordinateInfo());
		let radioPlugin = this.graph.GetPluginManager().GetRadioPlugin();
		if (radioPlugin) {
			graphInfo.CursorPosition = JSON.stringify(radioPlugin.GetCursorPosition());
		}
		return JSON.stringify(graphInfo);
	}
	getAlarm() {
		return {
			RelatedId: this.option.Key,
			Alarm: this.option.AlarmInfo.map((item) => {
				return {
					AlarmCategory: item.AlarmCategory,
					Alarm: JSON.stringify(item),
				};
			}),
		};
	}
	getAlarmFilter() {
		if (this.option?.AlarmFilter) {
			const { Data, FilterType } = this.option.AlarmFilter;
			let data;
			switch (FilterType) {
				case 1:
					data = Data.overrun[0]
					return {
						IsEnable: data.Switch,
						Value1: data.ReferenceValue,
						AmpFilterType: 1
					}
				case 2:
					data = Data.lowLimit[0]
					return {
						IsEnable: data.Switch,
						Value1: data.ReferenceValue,
						AmpFilterType: 2
					}
				case 3:
					data = Data.inWindow[0]
					return {
						IsEnable: data.Switch,
						Value1: data.Low,
						Value2: data.High,
						AmpFilterType: 3
					}
				case 4:
					data = Data.outWindow[0]
					return {
						IsEnable: data.Switch,
						Value1: data.Low,
						Value2: data.High,
						AmpFilterType: 4
					}
				case 5:
					data = Data.equal[0]
					return {
						IsEnable: data.Switch,
						Value1: data.ReferenceValue,
						AmpFilterType: 5
					}
			}
		} else {
			return {
				IsEnable: false,
				Value1: 0,
				AmpFilterType: 1
			}
		}
	}
	setAlarmFilter(data) {
		if (data) {
			const { AmpFilterType, IsEnable, Value1, Value2 } = data;
			let Data = this.option.AlarmFilter.Data;
			this.option.AlarmFilter.FilterType = AmpFilterType;
			switch (AmpFilterType) {
				case 1:
					Data.overrun[0].Switch = IsEnable;
					Data.overrun[0].ReferenceValue = Value1;
					break;
				case 2:
					Data.lowLimit[0].Switch = IsEnable;
					Data.lowLimit[0].ReferenceValue = Value1;
					break;
				case 3:
					Data.inWindow[0].Switch = IsEnable;
					Data.inWindow[0].Low = Value1;
					Data.inWindow[0].High = Value2;
					break;
				case 4:
					Data.outWindow[0].Switch = IsEnable;
					Data.outWindow[0].Low = Value1;
					Data.outWindow[0].High = Value2;
					break;
				case 5:
					Data.equal[0].Switch = IsEnable;
					Data.equal[0].ReferenceValue = Value1;
					break;
				default:
					break;
			}
		}
	}
	getCoordinateInfo() {
		const coordInfo = new CoordInfo();
		const coords = this.graph.GetCoordinate();
		if (coords) {
			coordInfo.IsEnlarge = coords.IsEnlarge || coords.AutoChangeYAxis == false;
			coordInfo.XMinRate = coords.XMinRate;
			coordInfo.XMaxRate = coords.XMaxRate;
			coordInfo.YAxisRate = coords.YRate;
			coordInfo.YMax = coords.YMax;
			coordInfo.YMin = coords.YMin;
			coordInfo.TimeLength = this.option.Axis.TimeLength;
		}
		return coordInfo;
	}
	getQueryTime() {
		const coords = this.graph.GetCoordinate();
		if (coords) {
			return { startTime: coords.XMin, endTime: coords.XMax };
		}
		return super.getQueryTime();
	}
	initCursor() {
		const pluginManager = this.graph.GetPluginManager();
		const cursor = pluginManager.OpenPlugin([this.option.CursorType]);
		if (this.option.CursorPosition && cursor) {
			cursor.SetPosition(this.option.CursorPosition);
		}
	}
	graphChanged(isHistory) {
		super.graphChanged(isHistory);
		if (!this.graph) return;
		const pluginManager = this.graph.GetPluginManager();
		this.initCursor();
		const statics = pluginManager.GetPlugin(PluginMode.Statistics);
		if (statics && this.option.Statistics) {
			statics.ValueType = this.option.Statistics;
			statics.OnRender();
		}
		this.updateAlarmInfo();
	}
	setTheme(theme) {
		if (theme == null) theme = EnumComponentTheme.Light;
		super.setTheme(theme);
		this.option.Axis.XAxisTextColor = ThemeResource[theme].AxisTextColor();
		this.option.Axis.YAxisTextColor = ThemeResource[theme].AxisTextColor();
		this.option.Axis.RuleColor = ThemeResource[theme].LineColor();
		this.option.Axis.AxisColor = ThemeResource[theme].LineColor();
		this.option.Axis.HorCrossColor = ThemeResource[theme].CrossColor();
		this.option.Axis.VerCrossColor = ThemeResource[theme].CrossColor();
		this.option.Axis.VerCrossStyle = ThemeResource[theme].CrossStyle();
		this.option.Axis.HorCrossStyle = ThemeResource[theme].CrossStyle();
		this.option.Axis.TitleColor = ThemeResource[theme].TitleColor();
		this.option.Axis.UnitTextColor = ThemeResource[theme].LineColor();
		this.option.Axis.HeaderColor = ThemeResource[theme].TitleColor();
		this.option.Axis.XTitleTextColor = ThemeResource[theme].TitleColor();
		this.option.Axis.YTitleTextColor = ThemeResource[theme].TitleColor();
		this.graph?.Update(this.option);
		this.navigateOperator?.setTheme(theme);
	}
	useDataRefresh(state) {
		this.graph?.UseDataRefresh(state);
	}
	updateOption(option) {
		this.graph?.Update(option);
	}
}

export class MultipleGraphComponentBase extends TimeDomainGraphBase {
	constructor() {
		super();
		this.graph = null;
		this.multipleGraph = this.createGraph();
		this.initializeGraph();
	}
	createOption() {
		const option = super.createOption();
		option.Param = {
			CoordInfos: [],
			ScaleType: [],
		};
		return option;
	}
	createPlugin() { }
	initializeGraph() {
		this.multipleGraph.initEvents();
		this.multipleGraph.createPlugin();
	}
	async createOwnGraph(props) {
		const { width, height } = props.componentOption;
		this.multipleGraph.createContainer(width, height);
		this.multipleGraph.initialize();
		this.updateSize({ width, height });
		this.graphChanged(this.isHistory.value);
		this.setDefaultRegisterPoint(false);
		nextTick(() => {
			this.onComponentCreated?.();
		});
	}
	initEvents() { }
	graphChanged(isHistory) {
		if (this.showNavigate) {
			this.toolbarOption.showNavigate = isHistory;
			this.toolbarOption.navigateExpand = false;
		}
		this.unRegisterAction();
		this.isHistory.value = isHistory;
		this.registerAction();
		if (!this.isHistory.value) this.beginQuery();
		this.onGraphChanged?.();
		if (!isHistory) this.navigateOperator?.close();
		this.multipleGraph.graphChanged();
	}
	useDataRefresh(state) {
		this.multipleGraph.useDataRefresh(state);
	}
	updateSize(rect) {
		this.onComponentSizeChanged.forEach(event => event(rect));
		this.multipleGraph.updateSize(rect);
	}
	setTheme(theme) {
		if (theme == null) theme = EnumComponentTheme.Light;
		this.theme = theme;
		this.option.Axis.Theme = theme;
		this.option.Axis.XAxisTextColor = ThemeResource[theme].AxisTextColor();
		this.option.Axis.YAxisTextColor = ThemeResource[theme].AxisTextColor();
		this.option.Axis.RuleColor = ThemeResource[theme].LineColor();
		this.option.Axis.AxisColor = ThemeResource[theme].LineColor();
		this.option.Axis.HorCrossColor = ThemeResource[theme].CrossColor();
		this.option.Axis.VerCrossColor = ThemeResource[theme].CrossColor();
		this.option.Axis.VerCrossStyle = ThemeResource[theme].CrossStyle();
		this.option.Axis.HorCrossStyle = ThemeResource[theme].CrossStyle();
		this.option.Axis.TitleColor = ThemeResource[theme].TitleColor();
		this.option.Axis.UnitTextColor = ThemeResource[theme].LineColor();
		this.option.Axis.HeaderColor = ThemeResource[theme].TitleColor();
		this.option.Axis.XTitleTextColor = ThemeResource[theme].TitleColor();
		this.option.Axis.YTitleTextColor = ThemeResource[theme].TitleColor();
		this.multipleGraph.refresh();
		this.navigateOperator?.setTheme(theme);
	}
	getQueryTime() {
		if (this.startTime) {
			return { startTime: this.startTime, endTime: this.endTime };
		}
		return { startTime: dayjs().format("YYYY-MM-DD HH:mm:ss.SSS"), endTime: null };
	}
	dataInputFilter(p) {
		return p.HasWave == true && (p.SignalType < 100 || p.SignalType === AlgorithmTypeEnum.FreqIntegral || p.SignalType == AlgorithmTypeEnum.BlockSample || (p.SignalType > 500 && p.SignalType < 600)) && !p.IsPatrol;
	}
	appendData(datas) {
		this.multipleGraph.appendData(datas);
	}
	setData(datas) {
		this.multipleGraph.setData(datas);
	}
	clearData(time) {
		this.multipleGraph.clearData(time);
	}
	updateOption() {
		this.multipleGraph?.refresh();
	}
	setParam(param) {
		super.setParam(param);
		if (!ruleCheck.IsNullOrSpaceStr(param.Param)) this.option.Param = JSON.parse(param.Param);
	}
	getParam() {
		this.option.Param.CoordInfos = this.multipleGraph.getCoordinateInfo();
		this.option.Param.ScaleType = this.multipleGraph.getScaleType();
		const graphInfo = {
			Id: this.id,
			ComponentType: this.componentCategory,
			CursorType: this.option.CursorType,
			Statistics: JSON.stringify(this.option.Statistics),
			RegisterPoints: this.option.RegisterPoints,
			Key: this.option.Key,
			UnitId: this.option.UnitId,
			Param: JSON.stringify(this.option.Param),
		};

		Object.assign(graphInfo, this.option.Axis);
		graphInfo.CursorPosition = JSON.stringify(this.multipleGraph.getCusorPosition());
		return JSON.stringify(graphInfo);
	}
	setAlarmFilter() { }
	updateAlarmInfo() { }
	close() {
		this.multipleGraph.close();
		this.unRegisterAction();
		this.navigateOperator.close();
		this.watcher.forEach(watch => watch());
		for (let p in this) {
			this[p] = null;
		}
	}
}

export class PreviceGraphBase {
	constructor() {
		this.graphId = guid();
		this.menuOption = this.createMenus();
		this.currentCursor = PluginMode.SingleCursor;
		this.staticsArray = [];
		this.graph = null;
		this.dataClient = this.createDataClient();
	}
	createDataClient() {
		return GrpcApiContext.GetInstance();
	}
	createMenus() {
		let menuOption = reactive({
			isOpen: false,
			eventVal: {},
			menus: [
				{
					label: i18nGlobal('component.cursorSettings'),
					divided: true,
					children: [],
				},
				{
					label: i18nGlobal('component.statisticalInformation'),
					divided: true,
					children: [],
				},
			],
		});

		const cursors = [
			{ value: 0, label: i18nGlobal('component.cursorType.noCursor') },
			{ value: 1, label: i18nGlobal('component.cursorType.singleCursor') },
		];

		const statistics = [
			{ value: 1, label: i18nGlobal('component.statistics.maximumValue') },
			{ value: 2, label: i18nGlobal('component.statistics.minimumValue') },
			{ value: 4, label: i18nGlobal('component.statistics.rootMeanSquare') },
			{ value: 3, label: i18nGlobal('component.statistics.averageValue') },
			{ value: 5, label: i18nGlobal('component.statistics.skewness') },
			{ value: 6, label: i18nGlobal('component.statistics.kurtosis') },
			{ value: 7, label: i18nGlobal('component.statistics.standardDeviation') },
			{ value: 8, label: i18nGlobal('component.statistics.peakToPeak') },
		];

		for (let i = 0; i < cursors.length; i++) {
			menuOption.menus[0].children.push({
				label: cursors[i].label,
				icon: "",
				isChecked: false,
				click: () => {
					this.openCursor(cursors[i].value, i);
				},
			});
		}

		for (let i = 0; i < statistics.length; i++) {
			menuOption.menus[1].children.push({
				label: statistics[i].label,
				icon: "",
				isChecked: false,
				click: () => {
					this.openStatics(statistics[i].value, i);
				},
			});
		}
		return menuOption;
	}
	openCursor(val, idx) {
		for (const menu of this.menuOption.menus[0].children) {
			menu.isChecked = false;
			menu.icon = "";
		}
		this.menuOption.menus[0].children[idx].isChecked = !this.menuOption.menus[0].children[idx].isChecked;
		if (this.menuOption.menus[0].children[idx].isChecked) {
			this.menuOption.menus[0].children[idx].icon = "√";
		}
		const pluginManager = this.graph.GetPluginManager();
		pluginManager.ClosePlugin([this.currentCursor]);
		pluginManager.OpenPlugin([val]);
		this.currentCursor = val;
	}
	openStatics(val, idx) {
		this.menuOption.menus[1].children[idx].isChecked = !this.menuOption.menus[1].children[idx].isChecked;
		if (this.menuOption.menus[1].children[idx].isChecked) {
			this.menuOption.menus[1].children[idx].icon = "√";
			this.staticsArray.push(val);
		} else {
			this.menuOption.menus[1].children[idx].icon = "";
			this.staticsArray.splice(
				this.staticsArray.findIndex((t) => t === val),
				1
			);
		}
		const pluginManager = this.graph.GetPluginManager();
		const statics = pluginManager.GetPlugin(PluginMode.Statistics);
		if (statics) {
			statics.ValueType = this.staticsArray;
			statics.OnRender();
		}
	}
	createGraph() { }
	openMenu(e) {
		this.menuOption.isOpen = false;
		nextTick(() => {
			this.menuOption.eventVal = e;
			this.menuOption.isOpen = true;
		});
	}
	onSizeChanged() {
		this.graph.EnsureGraphSizes();
		this.graph.OnSizeChanged();
	}
	setTheme(option, theme) {
		if (theme == null) theme = EnumComponentTheme.Light;
		option.Axis.XAxisTextColor = ThemeResource[theme].AxisTextColor();
		option.Axis.YAxisTextColor = ThemeResource[theme].AxisTextColor();
		option.Axis.RuleColor = ThemeResource[theme].LineColor();
		option.Axis.AxisColor = ThemeResource[theme].LineColor();
		option.Axis.HorCrossColor = ThemeResource[theme].CrossColor();
		option.Axis.VerCrossColor = ThemeResource[theme].CrossColor();
		option.Axis.VerCrossStyle = ThemeResource[theme].CrossStyle();
		option.Axis.HorCrossStyle = ThemeResource[theme].CrossStyle();
		option.Axis.TitleColor = ThemeResource[theme].TitleColor();
		option.Axis.UnitTextColor = ThemeResource[theme].LineColor();
		this.graph?.Update(option);
	}
	close() {
		this.graph?.Clear();
	}
}

export const useComponentBase = (component, props, ref) => {
	const operator = new GeneralComponentOperator(component, props, ref);
	const state = operator.state;
	onMounted(() => {
		operator.onMounted();
	});
	onBeforeUnmount(() => {
		operator.onBeforeUnmount();
	});
	const updateSize = (rect) => {
		operator.updateSize(rect);
	};

	return {
		state,
		updateSize,
		operator,
	};
};

export class ComponentOperator {
	constructor(component, props, refs) {
		this.refs = refs;
		this.component = component;
		this.props = props;
		this.state = this.createState();
		this.initialize();
		this.titleHeight = 40
	}
	initialize() {
		this.component.navigateOperator?.initializeParam(this);
	}
	createState() {
		let dep;
		if (this.component.layoutOperator && this.component.layoutOperator.plotType === PlotTypeEnum.Analysis) {
			dep = this.component.layoutOperator.activeNode;
		} else {
			dep = store.state.currentDep;
		}
		return reactive({
			showProperty: false,
			hasNode: dep.NodeType < 3,
		});
	}
	onMounted() {
		this.component.navigateOperator?.onComponentMounted(this, this.props);
		this.component.createComponent(this.props);
	}
	onBeforeUnmount() {
		this.component.close();
		for (let p in this) {
			this[p] = null;
		}
	}
	updateSize(rect) {
		this.component.navigateOperator?.updateComponentSize(rect, this);
	}
	async showGraphProperty() { }
	saveGraphParam() { }
	closeProperty() {
		this.state.showProperty = false;
	}
	onTabChange(name) {
		for (let p in this.refs) {
			if (this.refs[p].value && this.refs[p].value.update) {
				this.refs[p].value.update(name, this.refs)
			}
		}
	}
	validate() {
		let flag = true;
		for (let p in this.refs) {
			if (this.refs[p].value && this.refs[p].value.validate) {
				if (!this.refs[p].value.validate()) {
					flag = false;
				}
			}
		}
		return flag;
	}
}

export class GeneralComponentOperator extends ComponentOperator {
	constructor(component, props, refs) {
		super(component, props, refs)
	}
	showGraphProperty() {
		if (store.state.browseType === 'app') return;
		if (this.component.layoutOperator.islock) return alertWarningMsg(i18nGlobal('pointData.index.lockTip'));
		this.state.showProperty = true;
		nextTick(() => {
			this.refs.paramRef.value.setData();
		});
	}
	saveData() {
		this.refs.paramRef.value.saveData()
	}
}

export const checkGraphRegiserHasChange = (source, target, outNewPoints) => {
	const newPoints = new Map();
	const targetTemp = [...target];
	let hasChanged = false;
	if (source.length != target.length) hasChanged = true;
	while (targetTemp.length > 0) {
		const point = source.find((r) => r.PointId == targetTemp[0].PointId);
		if (point == null) {
			newPoints.set(targetTemp[0].PointId, targetTemp[0]);
			hasChanged = true;
		} else {
			const sourceEigens = JSON.parse(point.ValueList);
			const targetEigens = JSON.parse(targetTemp[0].ValueList);
			const newEigens = [];
			if (sourceEigens.length != targetEigens.length) hasChanged = true;
			for (let targetEigen of targetEigens) {
				if (!sourceEigens.find((r) => r.Id == targetEigen.Id)) {
					newEigens.push(targetEigen);
					hasChanged = true;
				}
			}
			if (newEigens.length > 0) {
				let temp = {};
				Object.assign(temp, point);
				temp.ValueList = JSON.stringify(newEigens);
				newPoints.set(point.PointId, temp);
			}
		}
		targetTemp.splice(0, 1);
	}
	if (outNewPoints) {
		for (let p of newPoints.values()) {
			outNewPoints.push(p);
		}
	}
	return targetTemp.length > 0 || hasChanged;
};

export const checkGraphPointRegisterHasChange = (source, target, outNewPoints, outDeletePoints) => {
	let hasChanged = false;
	for (const point of source) {
		if (target.findIndex(p => p.PointId == point.PointId) < 0) {
			if (outDeletePoints) outDeletePoints.push(point);
			hasChanged = true;
		}
	}
	for (const point of target) {
		if (source.findIndex(p => p.PointId == point.PointId) < 0) {
			if (outNewPoints) outNewPoints.push(point);
			hasChanged = true;
		}
	}
	return hasChanged;
}

const getSignalType = (point) => {
	let signalType = point.SignalType || AlgorithmTypeEnum.None;
	if (signalType == 0) {
		if (point.SampleMode == 1) {
			signalType = AlgorithmTypeEnum.WholePeriodSample;
		} else if (point.SampleMode == 3) {
			signalType = AlgorithmTypeEnum.BlockSample;
		} else if (point.SampleMode == 11) {
			signalType = AlgorithmTypeEnum.CycleTorsionslVibration;
		}
	}
	if (signalType == AlgorithmTypeEnum.VirtualCompute) {
		if (point.DataType == 1) {
			signalType = AlgorithmTypeEnum.VirtualComputeEigen;
		} else if (point.DomainType == 1) {
			signalType = AlgorithmTypeEnum.VirtualComputeFreq;
		}
	}

	return signalType;
}

export const mapPoint = (point) => {
	return {
		Id: point.Id,
		Name: point.Name,
		EigenTypes: point.EigenTypes,
		DeviceId: point.DeviceId,
		SampleFreq: point.SampleFreq || 1024,
		DomainType: point.DomainType,
		SampleMode: point.SampleMode || 0,
		SignalType: getSignalType(point),
		UnitStr: point.UnitStr,
		UnitId: point.UnitId,
		FullValue: point.FullValue,
		HasWave: point.HasWave,
		MeatureMode: point.MeatureMode,
		IsPatrol: point.IsPatrol || false,
		BlockSize: point.BlockSize,
		BindingChannelType: point.BindingChannelType,
		SourcePointIds: point.SourcePointIds || [],
		DataType: point.DataType,
		UseNavigateEigen: point.UseNavigateEigen || false
	}
}

const mapSimplePoint = (point) => {
	return {
		Id: point.Id,
		Name: point.Name,
		EigenTypes: point.EigenTypes,
		DeviceId: point.DeviceId,
		SampleFreq: point.SampleFreq || 1024,
		DomainType: point.DomainType,
		SampleMode: point.SampleMode || 0,
		SignalType: getSignalType(point),
		UnitStr: point.UnitStr,
		FullValue: point.FullValue,
		HasWave: point.HasWave,
		IsPatrol: point.IsPatrol || false,
		DataType: point.DataType,
		UseNavigateEigen: point.UseNavigateEigen || false
	}
}

export const getPoints = async (node) => {
	if (node == null) return [];
	let res = await axios.get(`Component/points/${node.Path}`, {});
	if (res.IsSuccess && res?.Result?.length > 0) {
		const array = res.Result.map((point) => mapPoint(point));
		PointCacheContext.GetInstance().update(array);
		return array;
	}
	return [];
};

export const getSimplePoints = async (node) => {
	if (node == null) return [];
	let res = await axios.get(`Component/component-simple-points/${node.Path}`, {});
	if (res.IsSuccess && res?.Result?.length > 0) {
		const array = res.Result.map((point) => mapSimplePoint(point));
		PointCacheContext.GetInstance().update(array);
		return array;
	}
	return [];
};

export const getDefaultRegisterPoints = async (node) => {
	if (node == null) return [];
	let res = await axios.get(`Component/first-device-points/${node.Path}`, {});
	if (res.IsSuccess && res?.Result?.length > 0) {
		const array = res.Result.map((point) => mapPoint(point));
		return array;
	}
	return [];
};

export const getPoint = async (pointId) => {
	if (pointId == null) return null;
	let res = await axios.get(`Component/point/${pointId}`, {});
	if (res.IsSuccess && res?.Result) {
		const point = res.Result;
		return mapPoint(point);
	}
	return null;
}

export const getPointsByIds = async (pointIds) => {
	if (!pointIds.length > 0) return [];
	const res = await axios({
		method: "post",
		url: `Component/points/true`,
		data: {
			Ids: pointIds
		},
	});
	if (res.IsSuccess && res?.Result?.length > 0) {
		const array = res.Result.map((point) => mapPoint(point));
		PointCacheContext.GetInstance().update(array);
		return array;
	}
	return [];
}

export const getAnalysisPoints = async (layoutOperator) => {
	const node = layoutOperator.activeNode;
	if (!node) return [];
	const deviceId = node.Id;
	const isImportTree = layoutOperator.isImportTree;
	let res = await axios.get(`AnalysisTool/ExternalPoints/${deviceId}/${isImportTree}`, {});
	if (res.IsSuccess && res.Result) {
		const array = JSON.parse(res.Result).map((point) => mapPoint(point));
		return array;
	}
	return [];
};

export const getSingalPoints = async (unitId) => {
	if (unitId == null) return [];
	const res = await axios.get(`Component/dsppoints/${unitId}`, {});
	if (res.IsSuccess && res?.Result?.length > 0) {
		const array = res.Result.map((point) => mapPoint(point));
		PointCacheContext.GetInstance().update(array);
		return array;
	}
	return [];
};

export const getTree = async (currentNode) => {
	const res = await axios.get(`Tree/${currentNode.Path}/type/3,4`);
	if (res.IsSuccess) {
		return res.Result.map((node) => {
			node.isSelect = false;
			node.isActive = false;
			return node;
		});
	}
	return [];
};

export const saveAlarm = (id, alarmInfo) => {
	axios({
		method: "post",
		url: "Component/update-alarm",
		data: {
			RelatedId: id,
			Alarm: this.option.AlarmInfo.map((item) => {
				return {
					AlarmCategory: item.AlarmCategory,
					Alarm: JSON.stringify(item),
				};
			}),
			// Alarm: {
			//     AlarmCategory: alarmInfo.AlarmCategory,
			//     Alarm: JSON.stringify(alarmInfo.alarms[alarmInfo.AlarmCategory])
			// }
		},
	});
};

export const isTheSameGroup = (sourceType, targetType, filter = true) => {
	if (filter) {
		return AlgroithmGroups[sourceType] == AlgroithmGroups[targetType];
	}
	return true;
}