const CN = {
    graph: {
        trend: '趋势图',
        wave: '波形图',
        fft: '频谱图'
    },
    commonJs: {
        code400: '请求错误',
        code403: '没有权限',
        code404: '请求地址出错',
        code405: '请求方法出错',
        code406: '没有权限',
        code408: '请求超时',
        code500: '服务器内部错误',
        code501: '服务未实现',
        code502: '网关错误',
        code503: '服务不可用',
        code504: '网关超时',
        code505: 'HTTP版本不受支持',
        codeDefault: '请求失败',
        tokenLoseTip: '登录信息失效,请重新登录',
        integerTip: '请输入正整数',
        progress: '进度',
        warningValue: '高报值',
        dangerValue: '高高报值',
        leaveTip: '确定要关闭Phm吗?'
    },
    app: {
        licenseTip: '当前系统尚未获取授权,请前往网页端导入license文件！',
        instrumentStatus: "仪器状态",
        alarmStatistics: "报警统计",
        deviceNum: "设备数量",
        acquisitionInstruments: "采集仪器",
        channelStatus: "通道状态",
        chooseDepart: "选择部门",
        chooseDevice: "选择设备",
        homePage: "首页",
        message: "消息",
        mine: "我的",
        enter: "进入",
        back: "返回",
        backToHome: "返回首页",
        noContent: "找不到内容",
        confirmType: "确认类型",
        noMore: "没有更多了",
        messageList: "消息列表",
        info: "信息",
        aboutUs: "关于我们",
        signOut: "退出登录",
        chooseTime: "选择时间",
        chooseDate: "选择日期"
    },
    headerSetting: {
        changePwd: {
            title: '修改密码',
            oldPwd: '旧密码',
            newPwd: '新密码',
            retypePwd: '重输新密码',
            oldPwdPlaceholder: '请输入旧密码',
            newPwdPlaceholder: '请输入新密码',
            retypePwdPlaceholder: '请再次输入新密码',
            samePwdToolTip: '和旧密码相同',
            differentPwdToolTip: '两次密码不同',
            notContainCnToolTip: '密码不能包含中文，长度为6-20个字符'
        },
        exportTask: {
            title: '系统任务',
            subTitle: '导出任务',
            exportTime: '导出时间',
            retry: '重试',
            exportContent: '导出内容',
            exportStatus: '导出状态',
            exportProgress: '导出进度',
            obtainingFiles: '获取文件中...',
            unexecuted: '未执行',
            completed: '已完成',
            toBeContinued: '未完成',
            exportFailed: '导出失败',
            noRetryTask: '没有需要重试的任务',
            backupContent: "备份内容",
            backupStatus: "备份状态",
            backupTask: "备份任务",
            backupTime: "备份时间",
            backuping: "备份中",
            backupFailed: "备份失败",
            backupProgress: "备份进度"
        },
        warning: {
            title: '报警信息',
            path: '所属路径',
            alarmType: '报警类型',
            alarmClass: '报警种类',
            alarmLevel: '报警等级',
            alarmTime: '报警时间',
            alarmReason: '报警原因',
            alarmClassify: '报警分类',
            allAlarm: '全部报警',
            unmute: '取消静音',
            muteAll: '全部静音',
            devicePath: '设备路径',
            real: '实',
            empty: '虚',
            changeUnmute: '切换静音',
            alarmDynamics: '报警动态',
            graphDisplay: '图谱展示',
            alarmDetail: '报警详情',
            amplitudeAlarmType: '幅值报警类型',
            viewDetail: '查看详情',
            confirmInfo: '确认信息',
            severity: '严重程度',
            confirmDetail: '确认详情',
            confirmTip: '不能确认未结束或已确认的报警',
            searchDeviceTip: '找不到该设备'
        },
        systemEvent: {
            eventType: '事件类型',
            eventLevel: '事件等级',
            eventContent: '事件内容',
            title: '系统事件',
            time: '时间'
        },
        systemStatus: {
            title: '系统状态',
            batteryTitle: '电量状态',
            instrumentName: '仪器名称',
            instrumentCode: '仪器编码',
            sampleCard: '采集卡',
            battery: '电池电量',
            full: '电量足',
            low: '电量低,请更换电池！',
        }
    },
    login: {
        welcome: '欢迎登录',
        sign: '登录',
        account: '账号',
        password: '密码',
        remeberPwd: '记住密码一个月',
        accountPlaceHolder: '请输入用户名',
        lightTheme: '浅色主题',
        darkTheme: '深色主题',
        unauthorized: '软件未授权',
        tips: '提示',
        unauthorizedTip: '该用户没有权限,请与管理员联系以获得权限',
        systemName: '基于故障诊断和健康管理的\n智能设备维保管理平台',
        stockCode: '股票代码'
    },
    errorPage: {
        noPermission: '暂无权限',
        noPermissionTip: '您没有权限访问该页面,您可以点击下方按钮返回首页或上一页',
        goHome: '回到首页',
        goBack: '返回上一页',
        noPage: '页面丢失',
        noPageTip: '您访问的页面不存在,您可以点击下方按钮返回首页或上一页',
    },
    license: {
        authDocument: '授权文件',
        importAuth: '导入授权',
        activation: '激活',
        authInformation: '授权信息',
        authStatus: '授权状态',
        authVersion: '授权版本',
        authTime: '授权时间',
        surplus: '剩余',
        day: '天',
        numUnit: '个',
        infinite: '无限',
        unlimited: '无限制',
        onlinePointNum: '在线测点数',
        inspectionPointNum: '巡检测点数',
        funList: '功能列表',
        copy: '复制',
        activationCode: '激活码',
        registrationCode: '注册码',
        activated: '已激活',
        notActive: '未激活',
        timeRunOut: '时间已用完',
        dongleCheckFailed: '加密狗激活失败',
        testCheckFailed: '内测版激活失败',
        registrationCodeToolTip: '请输入注册码',
        activationCodeToolTip: '请输入激活码',
        activateSuccess: '激活成功',
        checkFileToolTip: '请检查导入文件是否符合要求'
    },
    fileLibrary: {
        addClassification: '添加分类',
        editClassification: '编辑分类',
        deleteClassification: '删除分类',
        sort: '序号',
        fileName: '名称',
        fileSize: '文件大小',
        lastModificationTime: '最后修改时间',
        obtainingFiles: '获取文件中...',
        classificationToolTip: '请输入分类',
        specialCharacterToolTip: '名称不可出现特殊字符',
        classSpecialCharacterToolTip: '分类名称不可出现特殊字符',
        emptyClassToolTip: '分类名称不能为空',
        moreThanToolTip: '分类名称不可超过20个字符',
        confirmDeleteToolTip: '请确认是否删除该分类及其下的文件',
        deleteToolTip: '文件删除后不可恢复，请确认是否删除？',
        deleteFile: '删除文件',
        editFile: '编辑文件',
        selectClassification: '选择分类',
        sortPlaceholder: '请输入序号',
        descriptionPlaceholder: '请输入描述信息',
        classificationPlaceholder: '请选择分类',
        noChangeToolTip: '尚未有改动',
        emptyFileNameToolTip: '文件名不能为空',
        fileNamePlaceHolder: '请输入文件名称',
        fileSelectToolTip: '请选择文件',
        selectFile: '选择文件',
        uploadFile: '上传文件',
        directory: '目录',
        fileType: '文件类型',
        directoryBelong: '所属目录',
        document: '文档',
        picture: '图片',
        audio: '音频',
        other: '其他',
        deleteDirectoryTip: '删除该目录会同时删除其子目录和目录下文件，请确认是否删除？',
        removeDirectory: '删除目录',
        addDirectory: '添加目录',
        editDirectory: '编辑目录',
        directorySelectTip: '不选择将默认为根目录',
        directoryName: '目录名称',
        directoryNamePlaceHolder: '请输入目录名称',
        exeTip: '不能上传exe文件',
        nameTip: `文件名不能包含\\ \/ : * ? " ' < > |等特殊符号`
    },
    faultSetting: {
        addFault: '添加',
        editFaaddFault: '编辑故障',
        deleteFault: '删除',
        faultContent: '内容',
        sortFault: '排序',
        addText: '添加文字',
        modelSetting: '模型设置',
        disableAlarm: '禁用报警',
        enableAlarm: '启用报警',
        historyData: '历史',
        realTimeData: '实时',
        exportData: '导出',
        refresh: '刷新',
        saveModel: "保存",
        noFaultModel: '暂无故障模型',
        faultName: '故障名称',
        faultModel: '故障模型',
        specialCharacterToolTip: '故障名称不可出现特殊字符',
        healthToolTip: '健康度必须为0-100的数字',
        moreThanToolTip: '故障名称不可超过20个字符',
        enterFaultPlaceholder: '请输入故障名称',
        deleteFaultToolTip: '请确认是否删除该故障？',
        models: '模型库',
        horizontal: '横向平铺',
        vertically: '纵向平铺',
        nameEmptyToolTip: '名字不能为空',
        nameMoreThanToolTip: '名字不得超过20个字符',
        deleteConfirm: '确定要删除',
        topping: '置顶',
        bottoming: '置底',
        moveUp: '上移',
        moveDown: '下移',
        formula: '公式',
        faultErrorMsg: '故障启用中禁止修改，如需修改请先停用',
    },
    depState: {
        title: '状态显示'
    },
    dataProcessUnit: {
        models: '模型库',
        graphType: '图谱类型',
        outputPoint: '输出测点',
        expand: '展开',
        collspan: '收起'
    },
    mainLayout: {
        aside: {
            sort: '排序',
            unitSort: '单位排序',
            topping: '置顶',
            bottoming: '置底',
            moveUp: '上移',
            moveDown: '下移',
            deleteToolTip: '删除当前会同时删除该目录下的子部门、设备和场景，是否继续删除',
            deleteConfirm: '删除确认'
        },
        header: {
            alarmInfo: '报警信息',
            systemEvent: '系统事件',
            systemTask: '系统任务',
            platformSetting: '平台设置',
            changePwd: '修改密码',
            signOut: '退出',
            systemName: '基于故障诊断和健康管理的智能设备维保管理平台',
            alarmUpdateTip: '报警内容已更新，请查看？',
            loadTheme: '加载主题',
            hello: '你好',
            systemStatus: '系统状态',
            showStatus: '展示状态',
            editStatus: '编辑状态'
        },
        mainContent: {
            analysisReport: '分析报告',
            otherReport: '其他报告',
            importFualtLib: '导入故障库',
            manufacturer: '生产厂家',
            equipmentModel: '设备型号',
            equipmentType: '设备类型',
            faultDescription: '故障列表',
            noPermission: '暂无权限',
            noPermissionTip: '您没有权限访问节点,请联系管理员',
            summary: '概述',
            statusDisplay: '状态显示',
            fileLib: '知识库',
            pointSetting: '测点设置',
            pointData: '测点数据',
            workStatus: '工作状态',
            spotInspection: '点巡检',
            addUnit: '添加单位',
            addDepart: '添加部门',
            addDevice: '添加设备',
            addScence: '添加场景',
            importLibrary: '故障库导入',
            saveFaultLib: '保存故障库',
            exportReport: '导出报告',
            syncSuccess: '同步成功',
            chooseFaultLibToolTip: '请选择要导入的故障库',
            syncFaultLibToolTip: '是否将该设备同步到故障库?'
        },
        report: {
            exportReport: '导出报告',
            baseInfo: '基本信息',
            devicePoint: '设备测点',
            reportName: '报告名称',
            deviceList: '设备列表',
            pointList: '测点列表',
            previousStep: '上一步',
            nextStep: '下一步',
            monitorReport: '监测数据分析报告',
            timeDomain: "时域图谱",
            fftTitle: '频域图谱（FFT变换）：',
            FailedToSaveMap: '保存图谱失败',
            reportTemplate: '导出模板',
            nameTip: '报告名称不能为空',
            vibration: '振动趋势图',
            temperature: "温度趋势图",
            template1: '设备数据分析报告',
            template2: '设备状态分析报告',
            template3: '设备状态监测报告',
            template4: '设备报警状态监测报告',
        }
    },
    patrol: {
        plan: {
            title: '巡检计划',
            inspectionCycle: '巡检周期',
            inspectionCycleSelect: '请选择巡检周期',
            noCycle: '无周期',
            holiday: '节假日',
            enableStatus: '启用状态',
            planName: '计划名称',
            planStartTime: '计划起始时间',
            executor: '执行人',
            enableOrNot: '是否启用',
            cycleToolTip: '请输入周期间隔',
            cycleIntgerToolTip: '周期间隔必须为整数',
            cycleRangeToolTip: '周期间隔必须大于0',
            lessThan: '小于',
            enterCorrect: '请输入正确的',
            sampleTimeToolTip: '请选择采集时间',
            intgerToolTip: '必须为整数',
            nameMoreThanToolTip: '名字不能超过50个字符',
            descriptionMoreThanToolTip: '确认详情不能超过1000个字符',
            planStartTimeToolTip: '请选择计划起始时间',
            executorToolTip: '请选择执行人',
            sampleTime: '采集时间',
            earlyCollection: '提前采集',
            delayedRecycling: '延迟回收',
            deletePlanToolTip: '确认要删除该计划吗？',
            checkBaseInfo: '请检查基本信息填写是否正确',
            checkTimeSetting: '请检查时间设置填写是否正确',
            inspectionPointToolTip: '请选择巡检测点',
            executorSameToolTip: '不能有相同执行人',
            cycle: '周期',
            mark: '标记',
            markPlaceHolder: '请输入标记',
            periodicMode: '周期模式',
            onceADay: '每天一次',
            onceAWeek: '每周一次',
            onceAMonth: '每月一次',
            allowEarlyCollection: '允许提前采集',
            allowDelayedRecycling: '允许延迟回收',
            cycleInterval: '周期间隔',
            collectionTimeSelection: '采集时间选定',
            inspectionPoint: '巡检测点',
            pointName: '测点名称',
            pointType: '测点类型',
            up: '上升',
            down: '下降',
            positiveNumber: '请输入正数',
            addExecutor: '添加执行人',
            executorSelected: '执行人已选完',
            addPlan: '新增巡检计划',
            viewPlan: '查看巡检计划'
        },
        task: {
            inspectionTask: '巡检任务',
            inspectionStatus: '巡检状态',
            inspectionPlan: '巡检计划',
            pathName: '路径名称',
            affiliatedPlan: '所属计划',
            executor: '执行人',
            unrecycled: '未回收',
            warningNums: '高报数',
            dangerNums: '高高报数',
            status: '状态',
            unrecycledNum: '未回收数',
            pointData: '测点数据',
            inspectionData: '巡检数据',
            alarmLevel: '报警等级',
            unit: '工程单位',
            inspectionTime: '巡检时间',
            enclosure: '附件',
            no: '无',
            errorTip: '异常情况描述',
            h5Tip: '您的浏览器不支持 HTML5 video 标签。',
            noEnclosureTip: '暂无附件上传',
            recordInfo: '记录信息',
            noRecordToolTip: '暂无记录信息',
            preview: '预览',
            unexpired: '未到期',
            underExecution: '执行中',
            recyclingCompleted: '回收完成',
            notCollected: '未回收',
            partialRecycling: '部分回收',
            expiredNotCollected: '过期未回收',
            partialTimeoutRecycling: '部分超时回收',
            expiredRecyclingCompleted: '过期回收完成',
            observationQuantity: '观察量',
            measurementQuantity: '测量量',
            handCopyingQuantity: '手抄量',
            notFilledIn: '未填写',
            viewDetail: '查看详情',
            exportReport: '导出报告',
            recordSheetTitle: '施工日报记录表',
            examinationTitle: '重点部位检查记录',
            patrolPath: '巡检路径',
            patrolContent: '巡检内容',
            isNormal: '是否正常',
            patrolPhotos: '巡检照片',
            uploadPhotos: '上传照片',
            exceedTip: '一个测点最多可上传9张图片',
            repeat: '重复'
        }
    },
    hooks: {
        internalDeleteTip: '所选项含有系统内置，无法删除',
        cantDeleteTip: '不能删除系统内置项',
    },
    core: {
        noData: '无数据',
        order: '阶次',
        overLimit: "幅值超限",
        belowLimit: "幅值低限",
        inWindow: "幅值窗内",
        outWindow: "幅值窗外",
        curveInfo: '曲线信息',
        doubleCursor: '双光标',
        flagCursor: '标记光标',
        threeCursor: '三光标',
        fourCursor: '四光标',
        navigateCursor: '导航光标',
        navigateTrendDoubleCursor: '双导航光标',
        peakValueCursor: '峰值光标',
        valleyValueCursor: '谷值光标',
        rectCursor: '区域光标',
        sideFrequencyCursor: '边频光标',
        harmonicCursor: '谐光标',
        frequencyMultiplicationCursor: '谐(倍频)光标',
        resonanceCursor: '共振光标',
        torvibSpeed: '扭振共振转速',
        torvibFreq: '扭振共振频率',
        sideFrequencyCursorNum: '边频光标数',
        harmonicCursorNum: '谐光标数',
        harmonicDistortionDegree: '谐波失真度',
        singleCursor: '单光标',
        amplitude: '幅值',
        phase: '相位',
        rotateSpeed: '转速',
        max: '最大值',
        min: '最小值',
        avage: '平均值',
        RMS: '均方根',
        skewness: '偏度',
        kurtosis: '峰度',
        SD: '标准差',
        PTP: '峰峰值',
        slope: '斜率',
        deviation: '偏差值',
        APTP: '相邻峰峰值',
        peakList: '峰值列表',
        signalDispose: '信号处理',
        secondOrderClustering: '二阶聚类',
        KMeanClustering: 'K-均值聚类',
        systemClustering: '系统聚类',
        decisionTree: '决策树',
        discriminant: '判别式',
        RocCurve: 'ROC曲线',
        RocAnalysis: 'ROC分析',
        bivariate: '双变量',
        partialCorrelation: '偏相关',
        distance: '距离',
        multilayerPerceptron: '多层感知器',
        radialBasisFunction: '径向基函数',
        factor: '因子',
        correspondenceAnalysis: '对应分析',
        lifeTable: '寿命表',
        CoxRegression: 'Cox回归',
        sequenceDiagram: '序列图',
        autocorrelation: '自相关',
        crossCorrelation: '交叉相关性',
    },
    pointData: {
        index: {
            realTime: "实时",
            history: "历史",
            horizontally: '横向平铺',
            vertically: '纵向平铺',
            models: '模型库',
            timeDomainAnalysis: "时域分析",
            ampAnalysis: "幅值域分析",
            freqAnalysis: "频域分析",
            orderAnalysis: "阶次分析",
            envelopeAnalysis: "包络分析",
            acousticAnalysis: "声学分析",
            modeAnalysis: "模态分析",
            engineeringApplication: "工程应用",
            refreshLayout: "刷新布局",
            clearLayout: "清空布局",
            saveLayout: "保存布局",
            faultLibrary: "故障库",
            fullScreen: '全屏',
            addBillboards: "添加看板",
            editBillboard: "编辑看板",
            removeBillboard: "删除看板",
            lock: "锁定",
            unlock: "解锁",
            lockTip: "当前展示面板已被锁定,请先解锁",
            billboardName: "看板名称",
            nameLimitTip: "看板名称长度不能超过10个字符",
            nameRepeatTip: "看板名称不能重复",
            bearingCaculateTip: "请算出轴承故障系数",
            checkFreqTip: "请选择故障特征频率"
        },
        menu: {
            trendChart: "趋势图",
            waveChart: "波形图",
            spectrumChart: "频谱图",
            barChart: "棒图",
            digitalMeter: "数字表",
            dashboard: "仪表盘",
            picture: "图片"
        }
    },
    workState: {
        historyData: '历史数据',
        realTimeData: '实时数据',
        exportData: '导出数据',
        noWorkingStatus: "暂无工作状态",
        faultName: "故障名称",
        exporting: "导出中",
    },
    summary: {
        openFile: '打开文件',
        theme: '主题',
        component: '组件',
        alarm: '报警',
        statistics: '统计',
        addContent: '添加内容',
        show: '展示',
        unitName: '单位名称',
        departName: '部门名称',
        deviceName: '设备名称',
        sceneName: '场景名称',
        unitInfo: '单位信息',
        departInfo: '部门信息',
        deviceInfo: '设备信息',
        sceneInfo: '场景信息',
        nameTip: '名字不能超过20个字符',
        copyContent: '复制内容',
        pasteContent: '粘贴内容',
        pasteAlert: '是否覆盖当前信息？',
        browseItem: {
            picture: "图片",
            threeDModel: "3D模型",
            map: "地图",
            video: "视频",
            numericalStatistics: '数值统计',
            lineChart: '折线图',
            pieChart: '饼图',
            histogram: '柱状图',
            table: '表格',
        },
        browseList: {
            component: "组件",
        }
    },
    pointSetting: {
        ratedSpeed: '额定转速',
        rateSpeedTip: '请输入额定转速',
        bindMachineAndChannelFirst: "请先绑定机号与通道",
        timeDomainAnalysis: "时域分析",
        custom: "自定义",
        orderAnalysis: "阶次分析",
        frequencyAnalysis: '频域分析',
        speedFrequency: "转速频率",
        enterSpeedFrequency: "请输入转速频率",
        powerFrequency: "电源频率",
        enterPowerFrequency: "请输入电源频率",
        abnormalFrequency: "异常频率",
        enterabnormalFrequency: "请输入异常频率",
        startFreVibInt: "振动烈度起始频率",
        enterStartFreVibInt: "请输入振动烈度起始频率",
        vibIntCutFre: "振动烈度截止频率",
        enterVibIntCutFre: "请输入振动烈度截止频率",
        vibIntUnit: "振动烈度单位",
        enterVibIntUnit: "请输入振动烈度单位",
        conCoeOfVibIntUnit: "振动烈度单位转换系数",
        enterConCoeOfVibIntUnit: "请输入振动烈度单位转换系数",
        coiPasFrequency: "线圈通过频率",
        enterCoiPasFrequency: "请输入线圈通过频率",
        speedUppLimFrequency: "转速上限频率",
        enterSpeedUppLimFrequency: "请输入转速上限频率",
        lowerLimFreOfSpeed: "转速下限频率",
        enterLowerLimFreOfSpeed: "请输入转速下限频率",
        envelopeAnalysis: "包络分析",
        envelopeFreBandRange: "包络频带范围",
        selectEnvelopeFreBandRange: "请选择包络频带范围",
        slowVariableAnalysis: "缓变量分析",
        waveformAnalysis: "波形分析",
        isSampleWave: "是否采集波形",
        instrumentIsCurSampling: "仪器正在采样",
        thAreCurNoMeaPoiAvailable: "暂无测点",
        meaPointName: "测点名称",
        enterMeaPointName: "请输入测点名称",
        associationChannel: "关联通道",
        signalType: "信号类型",
        engineeringUnit: "工程单位",
        application: "应用",
        delete: "删除",
        noAccessViewPointForTheMoment: "暂无权限查看测点",
        youNotHavePermissionEdit: "您没有权限编辑",
        theMeaPoiNamCanContain: "测点名称不能包含\\",
        dupMeaPoiName: "测点名称重复",
        noUnit: "无单位",
        confirmChaBindChannel: "请确认是否更换绑定通道",
        chaBindChannel: "更换通道绑定",
        confirmUnbindChannel: "请确认是否取消通道绑定",
        unbindChannel: "取消通道绑定",
        newMeaPoints: "新增测点",
        bindingChannel: "通道绑定中",
        pleaseWait: "请稍后",
        unbindingChannel: "通道解绑中",
        deletingChannel: "正在删除测点",
        selectPointsToDelete: "请选择要删除的测点",
        confirmWhetherToDelete: "请确认是否删除",
        deletePoints: "删除测点",
        selectPoint: "请选择测点",
        batchSetSigType: "批量设置信号类型",
        creatingPoint: "测点创建中",
        batchSetEngineeringUnit: "批量设置工程单位,请稍后",
        thereAreabsm: "还有未保存的修改,是否保存",
        isSave: "是否保存",
        yes: "是",
        no: "否",
        customFeatureValues: "自定义特征值",
        unitSetting: "单位设置",
        optionName: "选项名称",
        enterOptionName: "请输入选项名称",
        optionFrequency: "选项频率",
        enterOptionFrequency: "请输入选项频率",
        featureValNamCannotDuplicate: "特征值名称不能重复",
        pointInformation: "测点信息",
        samplingSettings: "采样设置",
        realTimeAnalysis: "实时分析",
        saveSetting: "存储设置",
        selectPointObtainContent: "请选择测点获取内容",
        measurementType: "测量类型",
        enterMeasurementType: "请选择测量类型",
        selectEngineeringUnit: "请选择工程单位",
        fullScaleValue: "满度值",
        enterFullScaleValue: "请输入满度值",
        samplingFrequency: "采样频率",
        selectSamplingFrequency: "请选择采样频率",
        bearingModel: "轴承型号",
        enterBearingModel: "请输入轴承型号",
        professionalType: "专业类型",
        selectProfessionalType: "请选择专业类型",
        startStopAssociation: "启停关联",
        selectStartStopAssociation: "请选择启停关联",
        observationType: "观察类型",
        selectObservationType: "请选择观察类型",
        pointGroup: "测点组",
        enterPointGroup: "请输入测点组",
        PointDetails: "测点详情",
        selectTypeEngUnit: "请选择工程单位类型",
        enterRoleName: "请输入角色名",
        online: "在线",
        inspection: "巡检",
        addPoints: "添加测点",
        noInstrumentsAvailable: "暂无仪器",
        selectAction: "请选择要执行的操作",
        channelOperations: "通道操作",
        unbind: "取消绑定",
        selectSamplingMethod: "请选择采样方式",
        numSamPoiEntCycle: "整周期采样点数",
        selectNumSamPoiEntCycle: "请选择整周期采样点数",
        numSamplingCycles: "采样周期数",
        selectNumSamplingCycles: "请选择采样周期数",
        samplingTime: "采样时间",
        sensorType: "传感器类型",
        selectSensorType: "请选择传感器类型",
        sensorUnit: "传感器单位",
        selectSensorUnit: "请选择传感器单位",
        sensorNumber: "传感器编号",
        associatedSpeedPoints: "关联转速测点",
        selectAssociatedSpeedPoints: "请选择关联转速测点",
        inputMethod: "输入方式",
        selectInputMethod: "请选择输入方式",
        measurementRange: "测量量程",
        selectMeasurementRange: "请选择测量量程",
        speedRatio: "转速比",
        enterSpeedRatio: "请输入转速比",
        speedPuPerSecond: "转速每秒脉冲数",
        enterSpeedPuPerSecond: "请输入转速每秒脉冲数",
        resistanceValue: "电阻值",
        enterResistanceValue: "请输入电阻值",
        hardwarePoiType: "硬件积分类型",
        selectHardwarePoiType: "请选择硬件积分类型",
        hardwareSamInterval: "硬件采样间隔",
        enterHardwareSamInterval: "请输入硬件采样间隔",
        compensationChannel: "补偿通道",
        selectCompensationChannel: "请选择补偿通道",
        thermocoupleType: "热电偶类型",
        selecThermocoupleType: "请选择热电偶类型",
        coldEndTemperature: "冷端温度",
        enterColdEndTemperature: "请输入冷端温度",
        contactType: "接点类型",
        selectContactType: "请选择接点类型",
        valueType: "数值类型",
        selectValueType: "请选择数值类型",
        typeOfOutput: "输出类型",
        selectTypeOfOutput: "请选择输出类型",
        signalParameters: "信号参数",
        selectSignalParameters: "请选择信号参数类型",
        sensorSensitivity: "传感器灵敏度",
        selectSensorSensitivity: "请选择传感器灵敏度",
        waveformOptions: "波形选项",
        selectWaveformOptions: "请选择波形选项",
        platinumResistanceType: "铂电阻类型",
        selectPlatinumResistanceType: "请选择铂电阻类型",
        plaResWirMethod: "铂电阻接线方式",
        selectWirMethod: "请选择接线方式",
        plaResWirResistance: "铂电阻导线电阻",
        enterPlaResWirResistance: "请输入铂电阻导线电阻",
        senCoeTorStrGauge: "扭矩应变计灵敏度系数",
        enterTheCoefficient: "请输入系数",
        upperLimTorRange: "扭矩量程上限",
        enterUpperLimTorRange: "请输入扭矩量程上限",
        lowerLimTorRange: "扭矩量程下限",
        enterLowerLimTorRange: "请输入扭矩量程下限",
        linearCoeTorque: "扭矩线性系数",
        enterLinearCoeTorque: "请输入扭矩线性系数",
        typeTorVibMeaSensor: "扭振测量传感器类型",
        selectTypeTorVibMeaSensor: "请选择扭振测量传感器类型",
        pluseSensorVol: "供电电压",
        selectPluseSensorVol: "请选择供电电压",
        inputSignal: "输入信号",
        selectInputSignal: "请选择输入信号",
        speedPulPerRevolution: "转速每转脉冲数",
        enterSpeedPulPerRevolution: "请输入转速每转脉冲数",
        selectBridgePressure: "请选择桥压",
        sensorSamplingFrequency: "传感器采样频率",
        baudRate: "波特率",
        selectBaudRate: "请选择波特率",
        parityBit: "校验类型",
        selectParityBit: "请选择校验类型",
        stopBit: "停止位数",
        selectStopBit: "请选择停止位数",
        installationAngle: "安装角度",
        selectInstallationAngle: "请选择安装角度",
        associatedPoints: "关联测点",
        selectAssociatedPoints: "请选择关联测点",
        initialGapVoltage: "初始间隙电压",
        enterInitialGapVoltage: "请输入初始间隙电压",
        elasticModulus: "弹性模量",
        enterElasticModulus: "请输入弹性模量",
        incentivePeriod: "激励周期",
        enterIncentivePeriod: "请输入激励周期",
        coefficient: "系数",
        initialFrequencyValue: "初始频率值",
        enterInitialFrequencyValue: "请输入初始频率值",
        iniTemValue: "初始温度值",
        enterIniTemValue: "请输入初始温度值",
        linExpCoeOfSteString: "钢弦线性膨胀系数",
        enterLinExpCoeOfSteString: "请输入钢弦线性膨胀系数",
        linExpCoeOfStrBody: "结构体线性膨胀系数",
        enterLinExpCoeOfStrBody: "请输入结构体线性膨胀系数",
        ifTemCompensation: "是否温度补偿",
        temperatureCompensation: "温度补偿",
        value: "值",
        enterTemCompensation: "请输入温度补偿",
        strGauResValue: "应变计阻值",
        enterStrGauResValue: "请输入应变计阻值",
        intPreResistor: "内部精密电阻",
        selectIntPreResistor: "请选择内部精密电阻",
        wireResistance: "导线电阻",
        enterWireResistance: "请输入导线电阻",
        senCoeOfStrGauge: "应变片灵敏度系数",
        enterSenCoeOfStrGauge: "请输入应变片灵敏度系数",
        poissonRatio: "泊松比",
        enterPoissonRatio: "请输入泊松比",
        appMeaPoint: "应用测点",
        deactivate: "停用",
        threshold: "阈值",
        enterThreshold: "请输入阈值",
        calibrationCoefficient: "标定系数",
        sensitivityCoefficient: "灵敏度系数",
        function: "函数",
        twoPoints: "两点",
        physicalQuantity: "物理量",
        electricalQuantity: "电学量",
        proportionalCoefficient: "比例系数",
        enterProportionalCoefficient: "请输入比例系数",
        zeroCorrection: "零位修正",
        enterZeroCorrection: "请输入零位修正",
        numCalibrationPoints: "标定点序号",
        formulaResults: "公式结果",
        calculationError: "计算错误",
        checkTheParameters: "请检查参数",
        enterSenCoefficient: "请输入灵敏度系数",
        enteraNumber: "请输入数字",
        firstCalibrationPoint: "第一标定点",
        secondCalibrationPoint: "第二标定点",
        senCoeCannotBe: "灵敏度系数不能为",
        fullScaValMustBeGreThan: "满度值必须大于",
        ElasticModulusMoreThan0: "弹性模量必须大于0",
        twoPhyAndEleQuaMusBeNum: "两点物理量和电学量必须为数字",
        storeWaveForms: "存储数据",
        storageMode: "存储模式",
        continuousStorage: "连续存储",
        scheduledStorage: "定时存储",
        intervalStorage: "间隔存储",
        triggerStorage: "触发存储",
        storageDays: "存储天数",
        storageDaysPlaceholder: "请输入天数",
        startingConditionType: "启动条件类型",
        singleTrigger: "单次触发",
        multipleTriggers: "多次触发",
        cycleTrigger: "周期触发",
        startTime: "启动时间",
        datePickerPlaceholder: "请选择时间",
        storageCycle: "存储周期",
        storageCyclePlaceholder: "请输入存储周期",
        storageTimes: "存储次数",
        times: "次",
        absoluteTime: "绝对时间",
        relativeTime: "相对时间",
        stopTime: "停止时间",
        stopTimePlaceholder: "请输入停止时间",
        triggerConditionType: "触发条件类型",
        triggerCount: "触发次数",
        triggerCountPlaceholder: "0代表不限次数",
        triggerInterval: "触发间隔",
        delayMethod: "延迟方式",
        delayPoints: "延迟点数",
        block: "块",
        illustration: "图示",
        noDelay: "无延迟",
        positiveDelay: "正延迟",
        negativeDelay: "负延迟",
        startingConditions: "启动条件",
        stopConditions: "停止时间类型",
        second: "秒",
        minute: "分钟",
        hour: "小时",
        day: "天",
        triggerPointName: "触发测点",
        triggerType: "触发方式",
        triggerThreshold: "触发阈值",
        triggerBlocks: "持续块数",
        pointParamPropertyOption: '附加信号特征值单位',

        hardwareSamplingFreq: '硬件采样频率',
        hardwareSamplingFreqPlaceholder: '请输入硬件采样频率',
        torvibEqualtimeThreshold: '等时间扭振角度阈值',
        torvibEqualtimeThresholdPlaceholder: '请输入等时间扭振角度阈值',
        tachoCounterSensorType: '转速计数器传感器类型',
        tachoCounterSensorTypePlaceholder: '请选择转速计数器传感器类型',
        tachoCounterPulse: '每秒脉冲数',
        tachoCounterPulsePlaceholder: '请输入每秒脉冲数',
        tachoCounterSpeedRatio: '转速比',
        tachoCounterSpeedRatioPlaceholder: '请输入转速比',
        tachoCounterExchange: '方向交换',
        tachoCounterExchangePlaceholder: '请选择方向交换',
        tachoCounterSignalMode: '信号模式',
        tachoCounterSignalModePlaceholder: '请选择信号模式',
        tachoCounterXMode: '倍频方式',
        tachoCounterXModePlaceholder: '请选择倍频方式',
        tachoCounterResetMode: '复位方式',
        tachoCounterResetModePlaceholder: '请选择复位方式',
        tachoCounterMaxPos: '位置计数器阈值',
        tachoCounterMaxPosPlaceholder: '请输入位置计数器阈值',
        tachoCounterTimerLoad: '速度定时时间(ms)',
        tachoCounterTimerLoadPlaceholder: '请输入速度定时时间',
        tachoCounterKeyPhase: '键相通道',
        tachoCounterKeyPhasePlaceholder: '请选择键相通道',
        tachoCounterStartReset: '是否开始测量时复位',
        patrolClearance: '巡检情况',
        loop: '环',
        waveType: '波形类型',
        dataRefreshRate: "数据刷新率"
    },
    browse: {
        rotationSpeed: "轮播速度",
        fast: "快速",
        slowSpeed: "慢速",
        mediumSpeed: "中速",
        noRotation: "不轮播",
        numberOfRotations: "轮播条数",
        pleaseEnterTheNumberOfStreamers: "请输入轮播条数",
        maximumNumberOfEntries: "最大条数",
        displayMethod: "显示方式",
        displayContent: "显示内容",
        carousel: "轮播",
        statisticalRange: "统计范围",
        byPath: "按路径",
        byTable: "按表格",
        showColumns: "显示列",
        company: "单位",
        department: "部门",
        equipment: "设备",
        scene: "场景",
        measuringPoint: "测点",
        fault: "故障",
        type: "类型",
        grade: "等级",
        time: "时间",
        pageFlippingTime: "翻页时长",
        second: "秒",
        pleaseEnterThePageFlippingTime: "请输入翻页时长",
        componentType: "组件类型",
        measurementPointAlarm: "测点报警",
        alarmEditInfo: "不能编辑报警参数",
        equipmentAlarm: "设备报警",
        displayPath: "显示路径",
        statisticalType: "统计类型",
        numberOfAlarms: "报警数量",
        numberOfCollectors: "采集器数量",
        numberOfOnlineCollectors: "在线采集器数量",
        numberOfOfflineCollectors: "离线采集器数量",
        dayAccruedTitle: "当日施工进度环数",
        monthAccruedTitle: "近一个月施工进度环数",
        allAccruedTitle: "累计施工进度环数",
        statisticalList: "统计列表",
        parameterSettings: "参数设置",
        appearanceSettings: "外观设置",
        componentTitle: "组件标题",
        zoomSettings: "缩放设置",
        selfAdaption: "自适应",
        manualSettings: "手动设置",
        contentFontSize: "内容字号",
        numericFontSize: "数值字号",
        fixedNumberOfColumns: "固定列数",
        numberOfViewColumns: "视图列数",
        statisticalScope: "统计范围",
        nearlyAnHour: "近一小时",
        lastDay: "近一天",
        inThePastHalfMonth: "近半个月",
        lastMonth: "近一个月",
        inThePastThreeMonths: "近三个月",
        inThePastSixMonths: "近六个月",
        lastYear: "近一年",
        inThePastThreeYears: "近三年",
        inThePastHalfHour: "近半个小时",
        lastHour: "近一个小时",
        inThePastSixHours: "近六个小时",
        lastDay: "近一天",
        numericalStatistics: "数值统计",
        componentName: "组件名称",
        highAlarmThreshold: "高报阈值",
        highHighAlarmThreshold: "高高报阈值",
        weightRatio: "权重比例",
        sceneWeight: "场景权重",
        faultWeight: "故障权重",
        theHighAlarmThresholdMustBeGreaterThanTheHighAlarmThreshold: "高报阈值必须大于高高报阈值",
        theSumOfFaultWeightsIsNotEqualTo: "故障权重之和不等于",
        theSumOfSceneWeightsIsNotEqualTo: "场景权重之和不等于",
        alarmSignalStatistics: "报警信号统计",
        constructionStatistics: "施工情况环数统计",
        pictureLayoutL: "图片布局",
        freeScaling: "自由缩放",
        proportionalScaling: "等比缩放",
        doNotScale: "不缩放",
        selectFile: "选择文件",
        dragAndDropFilesHereOr: "将文件拖拽至此处,或",
        clickToUpload: "点击上传",
        numberOfAxisSegments: "轴分段数量",
        faultFrequencyStatistics: "故障次数统计",
        patrolStatistics: "巡检报警次数统计",
        alarmLevelStatistics: "报警等级统计",
        statisticsOfTheNumberOfOfflineMeasuringPointsAndEquipment: "掉线测点设备数统计",
        modelName: "模型名称",
        deviceSerial: "设备序列号",
        encrypted: "加密",
        secretKey: "秘钥",
        yes: "是",
        no: '否',
        axisSettings: "轴设置",
        displayByTime: "按时间显示",
        displayByDateAndTime: "按日期时间显示",
        displayOnTimeMinutesAndSeconds: "按时分秒显示",
        displayInSeconds: "按秒显示",
        axisDecimalPlaces: "轴小数位数",
        numberOfAxisGrids: "轴网格数量",
        cursorType: "光标类型",
        showMode: "显示模式",
        showTimeLength: "显示时长",
        statisticalDuration: "统计时长",
        refreshInterval: "刷新间隔",
        displayItems: "显示项",
        curveInformation: "曲线信息",
        coordinatePoints: "坐标点",
        cursorFollow: "光标跟随",
        statisticalInformation: "统计信息",
        maximumValue: "最大值",
        minimumValue: "最小值",
        rootMeanSquare: "均方根",
        averageValue: "平均值",
        skewness: "偏度",
        kurtosis: "峰度",
        standardSeviation: "标准差",
        peakToPeak: "峰峰值",
        absoluteTime: "绝对时间",
        relativeTime: "相对时间",
        noCursor: "无光标",
        singleCursor: "单光标",
        doubleCursor: "双光标",
        threeLightMarkers: "三光标",
        fourCursors: "四光标",
        navigationCursor: "导航光标",
        peakCursor: "峰值光标",
        valleyCursor: "谷值光标",
        areaCursor: "区域光标",
        lately: "最近",
        hour: "小时",
        day: "天",
        month: "月",
        year: "年",
        videoList: "视频列表",
        addChannel: "添加通道",
        yhereAreCurrentlyNoVideosAvailable: "暂无视频",
        optionSettings: "选项设置",
        cameraName: "摄像头名称",
        passageway: "通道",
        address: "地址",
        userName: "用户名",
        password: "密码",
        pleaseEnterTheNameOfYourCamera: "请输入您的摄像头的",
        formatReference: "格式参考",
        videoType: "视频类型",
        video: "视频",
        channelNumber: "通道号",
        serialNumber: "序列号",
        platformSelection: "平台选择",
        videoResources: "视频资源",
        jingjiangWaterConservancyBureau: "靖江水利局",
        lastHour: '最近一小时',
        lastDay: '最近一天',
        lastMonth: '最近一月',
        lastYear: '最近一年',
        camera: "摄像头",
        fontSize: "字号大小",
        RTSPAddress: '通道RTSP地址',
        RTSPTip: "请输入您的摄像头的RTSP地址，格式参考rtsp://用户名:密码{'@'}IP:554/h264/ch1/main/av_stream",
        RTSPplaceHolder: "rtsp://用户名:密码{'@'}IP:554/h264/ch1/main/av_stream",
        normalName: '正常状态名称',
        abnormalName: '异常状态名称',
        playMode: '播放模式',
        pageFlipping: '翻页',
        byDay: '按日',
        byWeek: '按周',
        byMonth: '按月',
        arrangement: '排列方式',
        vertical: '纵向',
        horizontal: '横向',
        characteristicFrequencyCursor: "特征频率光标",
        characFreqSetting: "特征频率设置",
        charactFreqOfFaults: "故障特征频率",
        searchBearing: '查找轴承',
        chooseBearing: '选择轴承',
        pointByDevice: '测点(按设备)',
        synchronous: '同步',
        sendInterval: '通道状态发送间隔',
        latestInterval: '最新值发送间隔',
        sendIntervalTip: '通道状态发送间隔必须为正整数',
        latestFrequencyTip: '最新值发送频率必须为正整数或0',
        times: '次/s',
        sendIntervalLimitTip: '通道状态发送间隔最大为100000000',
        latestFrequencyLimitTip: '最新值发送频率最大为10',
        displayDepartmentType: '显示部门类型',
        onlineDeviceStatistics: "在线设备统计",
        byDeviceLevel: "按设备等级",
        byAlarmLevel: "按报警等级",
        statisticsInterval: "统计间隔",
        cussorPointName: "光标测点名称",
        healthHistoryStatistics: "历史健康度统计",
        correlateHealth: "关联健康度",
        healthRepeat: "关联健康度不能有重复",
        deviceLevelAlarmStatistics: "设备等级报警数量统计",
        displayDepartmentTypeStatics: '分类统计',
        displayPath: '显示路径',
        style: '风格',
        style1: '风格一',
        style2: '风格二',
        tagBackground: '标签背景颜色'
    },
    alarmPro: {
        startLearn: '开始学习',
        weightLimitParameters: '支持向量的权重限制参数',
        gaussian: '高斯核容差',
        sampleData: '样本数据选取',
        samplePoint: '样本测点',
        sampleInput: '样本输入',
        learning: '学习中',
        learnSuccess: '自学习成功',
        learnFailed: '自学习失败',
        alarmParam: "报警参数",
        addParam: '添加参数',
        fromPoint: '来自于测点',
        custom: '自定义',
        selectStopTime: '请选择停止时间',
        changeMode: '切换模式',
        batchChange: '批量切换',
        saveChange: '存储切换',
        change: '切换',
        unitNode: '单位节点',
        departNode: '部门节点',
        numberOfStrainFlowers: '应变花数量',
        batchNum: '批量添加个数不能超过20',
        numberOfFilters: '滤波次数',
        smooth: '平滑',
        repeatedly: '多次',
        alarmStatusDetail: '报警状态详情',
        repairScheme: '维修方案',
        reportTask: '报告任务',
        cycleExecutionTime: '周期执行时间',
        reportTemplate: '报告模板',
        alarmRule: '报警规则',
        alarmSound: '报警声音',
        alarmControl: '报警控制',
        whetherToGroup: '是否分组',
        deviceReport: '设备状态检测报告',
        alarmReport: '报警状态监测报告',
        resolveSuggestions: '解决建议',
        relevancy: '关联',
        exportSwitch: '导出开关',
        onceTip: '一个故障只能有一个字段关联维修方案',
    },
    platformSetting: {
        baseInfo: {
            faultLibary: {
                title: "故障库列表",
                deviceModel: "设备型号",
                deviceType: "设备类型",
                faultDetails: "故障列表",
                faultLib: "故障库",
            },
            patrol: {
                observerCategory: {
                    observationName: "观察类别名称",
                    addObservationType: "新增观察类别",
                    editObservationType: "编辑观察类别",
                    observationNamePlaceholder: "请输入观察类别名称",
                },
                observerItem: {
                    alarmLevel: "报警等级",
                    alarmLevelPlaceholder: "请选择报警等级",
                    categoryRequired: "请选择观察类别",
                    observationsName: '观察项名称',
                    observationsNameTip: '请输入观察项名称',
                    addObservation: '新增观察项',
                    editObservation: '编辑观察项',
                },
                taskRecord: {
                    recordName: "记录项名称",
                    default: "默认内容",
                    title: "任务记录标题",
                    recordTitlePlaceholder: "请输入任务记录标题",
                    defaultInfo: "默认信息",
                    defaultInfoPlaceholder: "请输入默认信息",
                    addTaskRecord: '添加任务记录',
                    editTaskRecord: '编辑任务记录',
                    defaultInfoPlaceholder: "请输入默认信息",
                },
                workingSchedule: {
                    previousYear: "上一年",
                    previousMonth: "上一月",
                    today: "今天",
                    nextMonth: "下一月",
                    nextYear: "下一年",
                    weekArrangement: "排班设置",
                    holidaySetting: "节假日设置",
                    restAbbreviation: "休",
                    workingAbbreviation: "班",
                    lieuDaysConflict: "至少有一个补班日期与待提交的工作排期存在冲突，请先修改补班日期",
                    holidayName: "节假日名称",
                    holidayDay: "节假日日期",
                    holidayStart: "放假起始时间",
                    holidayEnd: "放假结束时间",
                    holidayDuration: "放假天数",
                    LieuDays: "补班天数",
                    selectWorkingDay: "请选择出勤时间",
                    holidayRequired: "请选择放假日期",
                    needsHolidayDate: "未选择节假日，请先选择",
                    dateInHoliday: "假期当天应包含在放假日期内",
                    dateInOtherHoliday: "放假日期包含了已存在于其他假期的数据",
                    lieuDaysNotInHoliday: "调班应不在放假日期内",
                    lieuDaysNotInWorkingDay: "调班应不在正常上班期间内",
                    dateRequired: "请输入日期",
                    dateExistedInHoliday: "该日期已被其他节假日占用",
                    addHoliday: '添加节假日',
                    editHoliday: '编辑节假日'
                },
            },
            sinalSetting: {
                unitName: '单位名称',
                unitNamePlaceHolder: '请输入单位名称',
                addUnit: '新增工程单位',
                editUnit: '编辑工程单位',
                conversionCoeffcientPlaceHolder: '请输入转换系数',
                reserveDecimalFractionTip: '请输入保留小数位',
                isDefaultUnit: '是否为默认单位',
                addSensorType: "新增传感器类型",
                editSensorType: "编辑传感器类型",
                sensorName: "传感器名称",
                sensorNameTooltip: "请输入传感器名称",
                defaultEigen: "默认特征值",
                defaultEigenPlaceholder: "请选择默认特征值",
                signalName: "信号名称",
                singalNamePlaceholder: "请输入信号名称",
                defaultEigenType: "默认特征值类型",
                defaultEigenTypePlaceholder: "请选择默认特征值类型",
                addSignalType: "新增信号类型",
                editSignalType: "编辑信号类型",
                defaultUnit: "默认单位",
                conversionCoeffcient: "转换系数",
                reserveDecimalFraction: "保留小数位数",
            }
        },
        bearing: {
            brearing: '轴承库',
            title: "轴承库列表",
            rollerCount: "滚子数",
            holdingShelfFailFactor: "保持架故障频率系数",
            rollerFailFactor: "滚动体故障频率系数",
            outerRingFailFactor: "外圈故障频率系数",
            innerRingFailFactor: "内圈故障频率系数",
            addBearing: "新增轴承",
            editBearing: "编辑轴承",
            bearingVendor: "轴承厂商",
            bearingVendorPlaceholder: "请输入轴承厂商",
            bearingModel: "型号",
            bearingModelPlaceholder: "请输入轴承型号",
            bearingDetails: "轴承参数",
            contactAngle: "接触角",
            axleDiameter: "轴承节径",
            rollerDiameter: "滚动体直径",
            caculate: '计算',
            bearingFaultNum: '轴承故障系数',
            contactAngleTip: '接触角不能为空',
            contactAngleNumTip: '接触角必须是0-180之间的整数',
            axleDiameterTip: '轴承节径不能为空',
            axleDiameterNumTip: '轴承节径必须在0-1000之间，且只能有一位小数',
            rollerDiameterTip: '滚动体直径不能为空',
            rollerDiameterNumTip: '滚动体直径必须在0-100之间，且只能有一位小数',
            rollerCountTip: '滚子数量不能为空',
            rollerCountNumTip: '滚子数量必须为1-100之间的整数',
            axleDiameterTip: '轴承节径必须大于滚动体直径',
            caculateTip: '请计算轴承故障系数',
            contactAngleTip: '接触角不能为空',
            chooseBearingTip: '请先选择轴承'
        },
        dataManage: {
            exportTitle: "数据导出",
            exportPath: "导出路径",
            importTitle: "数据导入",
            select: '选择',
            open: '打开'
        },
        instrument: {
            rotateSpeedSetting: "转速值设置",
            rotateSpeedSettingPlaceholder: "请输入转速值",
            instrumentNumber: "机号",
            captureCard: "采集卡",
            channelType: "通道类型",
            relatedPath: "关联路径",
            channelSetting: "通道设置",
            manuallyCheck: "手动自检",
            balance: "平衡",
            zero: "清零",
            exportData: "结果导出",
            importData: "结果导入",
            countReset: "计数复位",
            wireResistanceMeasurement: "导线电阻测量",
            wireTitle: "导线电阻测量结果",
            wireValue: "导线电阻测量值",
            selfCheckResult: "自检结果",
            channelNumber: "通道号",
            channelName: "通道名称",
            instrumentName: "仪器名称",
            instrumentSerial: "仪器编码",
            timeoutOffline: "超时离线",
            positiveIntegerRequired: "请输入正整数",
            selectFile: "选择文件",
            selectFileTooltip: "将文件拖拽至此处或点击上传",
            batteryLevel: "电池电量",
            channels: "通道数",
            editInstrument: "编辑仪器",
            editInstrumentPlaceholder: "请输入仪器名称",
            sampleMode: "采样方式",
            sampleModePlaceholder: "请选择采样方式",
            paramDownloadType: "下发参数方式",
            paramDownloadTypePlaceholder: "请选择下发参数方式",
            sampleInterval: "采样间隔",
            sampleIntervalPlaceholder: "请选择采样间隔",
            showDivision: "显示分度",
            showDivisionPlaceholder: "请选择显示分度",
            workMode: "工作模式",
            sampleGate: "采样门限",
            sampleGatePlaceholder: "请输入采样门限",
            offlineDiv: "断点数据上传时间间隔",
            // offlineDivPlaceholder: ""
            rotateSpeed: "转速值",
            rotateSpeedPlaceholder: "请输入转速值",
            instrumentSerialPlaceholder: "请输入仪器编码",
            title: "仪器树",
            refresh: "刷新",
            instrumentList: "仪器列表",
            captureCardList: "采集卡列表",
            channelList: "通道列表",
            instrumentType: "仪器类型",
            instrumentTypePlaceholder: "请输入仪器类型",
            startSampling: "开始采样",
            stopSampling: "停止采样",
            NTPSetting: "NTP设置",
            noInstrument: "暂无仪器",
            NTPList: "NTP列表",
            deleteInstrument: "删除仪器",
            startingSampling: "开始采样中",
            stopingSampling: "停止采样中",
            lowBatteryAlarm: "低电量报警",
            powerOn: "电源上电",
            powerOff: "电源断电",
            absoluteClearZero: "绝对清零",
            relativeClearZero: "相对清零"
        },
        permission: {
            depart: {
                addUnit: "添加单位",
                addDepartment: "添加部门",
                superiorDepartment: "上级部门",
                nodeRequired: "请选择节点",
                noAuthToDeleteNode: "没有删除该节点的权限",
                nodeNeededWhenDelete: "请选择要删除的节点",
                confirmDeleteOperation: "删除当前单位/部门会同时删除该目录下的子部门，是否继续删除？",
                confirmTwice: "确定要删除所选节点吗？",
                unitDeleteTip: "不能删除唯一一个单位"
            },
            role: {
                title: "角色列表",
                roleName: "角色名称",
                roleDiscription: "角色描述",
                deleteConfirmMessage: "当前角色已有用户关联，删除该角色会使其解除关联并失去当前角色权限，是否继续删除？",
                deleteConfirmTitle: "删除确认",
                addRole: "新增角色",
                editRole: "编辑角色",
                roleNamePlaceholder: "请输入角色名称",
                module: "功能模块",
                operationAuth: "操作权限",
                instrumentTreeSetting: "设备树配置",
            },
            user: {
                workGroup: "工作组",
                columnSetting: "显示项设置",
                addUser: "添加用户",
                displayName: "昵称",
                mobile: "绑定手机号",
                email: "绑定邮箱",
                loginType: "登录方式",
                loginCategory: "登录类型",
                createTime: "创建时间",
                createBy: "创建人",
                ExpiredTime: "使用期限",
                ExpiredTimeWithUnit: "使用期限（天）",
                disabledSucceed: "禁用成功",
                enabledSucceed: "启用成功",
                addUser2: "新增用户",
                editUser: "编辑用户",
                departmentRelated: "关联部门",
                userRelated: "关联角色",
                workGroupRelated: "关联工作组",
                accountPlaceholder: "请输入账号",
                namePlaceholder: "请输入昵称",
                passwordTwicePlaceholder: "请输入确认密码",
                mobilePlaceholder: "请输入手机号",
                emailPlaceholder: "请输入邮箱",
                loginTypePlaceholder: "请选择登录类型",
                expireTimePlaceholder: "请输入使用期限",
                organizationStructure: "组织结构",
                workGroupList: "工作组列表",
                workGroupDiscription: "工作组描述",
                chooseEnable: "请选择是否启用",
                workGroupTip: "您需要有工组组浏览权限，否则无法查看与修改报警信息显示路径"
            },
            workGroup: {
                title: "工作组列表",
                workGroupName: "工作组名称",
                workGroupDiscription: "工作组描述",
                addWorkGroup: "新增工作组",
                editWorkGroup: "编辑工作组",
                workGroupNameRequired: "请输入工作组名称",
                browse: "浏览（查找）",
                createWorkGroup: "添加工作组",
            }
        },
        productManage: {
            nodeSetting: {
                title: "节点设置",
                number: "编号",
                numberPlaceholder: "请输入编号",
                illegalChar: "非法字符",
                alertMessage: "修改编号可能会导致已经存在的业务异常，请确认是否修改？"
            },
            theme: {
                title: "主题设置",
                mainColor: "主题色",
                cardColor: "卡片颜色",
                backgroundColor: "背景色",
                deviderColor: "分割线颜色",
                tipColor: "提示颜色",
                bodyColor: "正文颜色",
                titleColor: "标题颜色",
                customTheme: "自定义主题",
                createTheme: "创建自定义主题",
                defaultLight: "浅色主题",
                defaultDark: "深色主题",
                deleteConfirm: "确认删除主题吗？",
                applySucceed: "应用成功",
                saveMode: "保存界面模式"
            },
            language: {
                chooseLanguage: "选择语言：",
            },
            safe: {
                title: '安全设置',
                passwordLock: '密码错误锁定',
                numberOfErrors: '输入错误次数',
                lockTime: '锁定时长',
                noPermissionEdit: '暂无权限修改'
            },
            operationLog: {
                title: '操作日志',
                operateBy: "操作人",
                operateTime: "操作时间",
                search: "搜索",
                operateContent: '操作内容',
                action: '动作',
                operands: '操作对象'
            }
        },
        runStatusManage: {
            title: "启停机配置",
            start: "启动",
            stop: "停机",
            saveStatus: "保存状态变更",
            queryStatus: "查询变更详情",
            currentStatus: "当前状态",
            stopReason: "停机原因",
            viewStatus: "查看变更详情",
            details: "详情",
            toNow: "至今",
            reason: "原因",
            operatebBy: "操作人",
            bySignal: "按信号",
            byRotateSpeed: "按转速",
            startUpConditions: "启机条件",
            switch: "启停机开关",
            addCondtions: '添加条件',
            editConditions: '编辑条件'
        },
        selfCheck: {
            selfCheckParam: "自检参数",
            selfCheckPeriod: "自检周期",
            automaticCheck: "自动自检",
            otherSelfCheckType: "其他自检",
            iepeSelfCheck: 'IEPE自检',
            vibrateSelfCheck: '振弦自检',
            sensorSelfCheck: '传感器自检',
            strainSelfCheck: "应变自检",
            inSituSelfCheck: "原位校准",
            underInstrument: "所属仪器",
            underCaptureCard: "所属采集卡",
            underInstrumentPlaceholder: "请选择所属仪器",
            underCaptureCardPlaceholder: "请选择所属采集卡",
            excitateFrequency: "激励频率值",
            sampleFrequency: "采样频率值",
            sampleDepth: "采样点数",
            excitateFrequencyPlaceholder: "请选择激励频率值",
            sampleFrequencyPlaceholder: "请选择采样频率值",
            sampleDepthPlaceholder: "请选择采样点数",
            baseVolt: "基准电压值",
            channelName: "通道名称",
            selfCheckSwitch: "自检开关",
            reuseItemRequired: "请至少选择一项进行复用",
            baseVoltMoreThanZeroRule: "电压值必须大于0",
            range: "量程",
            rangeValue: "量程值",
            rangePlaceholder: "请选择量程",
            bridgeWay: "桥路方式",
            bridgeWayPlaceholder: "请选择桥路方式",
            bridgeVolt: "桥压",
            bridgeVoltPlaceholder: "请选择桥压",
            wireResistance: "导线电阻",
            strainGaugeResistance: "应变计电阻",
            resistanceMoreThanZeroRule: "电阻必须大于0",
            wireResistanceMoreThanZeroRule: "导线电阻必须大于0",
            gaugeResistanceMoreThanZeroRule: "应变计电阻必须大于0",
            groupByIns: "按仪器分组"
        },
        settingCenter: {
            agreement: {
                agreementType: "协议类型",
                deviceList: "设备列表",
                deviceID: "设备ID",
                deviceIDPlaceholder: "请输入设备ID",
                modbus: {
                    unit: "工程单位",
                    setByPoint: "按测点配置",
                    setByDevice: "按设备配置",
                    deviceGroupList: "设备组列表",
                    addDeviceGroup: "添加设备组",
                    editDeviceGroup: "编辑设备组",
                    transformParams: "传输参数",
                    addDevice: "添加设备",
                    deleteDevice: "移除设备",
                    deleteModbus: "移除",
                    deviceGroupName: "设备组名称",
                    deviceGroupNamePlaceholder: "请输入设备组名称",
                    interval: "发送间隔（秒）",
                    intervalWithoutUnit: "发送间隔",
                    intervalPlaceholder: "请输入发送间隔",
                    startAddress: "起始地址",
                    startAddressPlaceholder: "请输入起始地址",
                    sendInstrumentStatus: "发送仪器状态",
                    sendContent: "发送内容",
                    deviceIdAndStatus: "设备ID、设备状态",
                    deviceIdAndAlarm: "设备ID、报警状态",
                    deviceStatusCode: "设备状态编码",
                    alarmStatusCode: "报警状态编码",
                    deviceStatus: "设备状态",
                    alarmStatus: "报警状态",
                    normal: "正常",
                    highAlarm: "高报",
                    doubleHighAlarm: "高高报",
                    deviceIdIntegerRule: "设备ID必须为非负整数",
                    deviceIdMaxRule: "设备ID最大为255",
                    deviceStatusCodeNormal: "设备状态编码（正常）",
                    deviceStatusCodeWarning: "设备状态编码（高报）",
                    deviceStatusCodeDanger: "设备状态编码（高高报）",
                    alarmStatusCodeNormal: "报警状态编码（正常）",
                    alarmStatusCodeWarning: "报警状态编码（高报）",
                    alarmStatusCodeDangerous: "报警状态编码（高高报）",
                    normalCodeRule: "正常编码必须为非负的整数",
                    warningCodeRule: "高报编码必须为非负的整数",
                    dangerousCodeRule: "高高报编码必须为非负的整数",
                    deviceRequired: "请先选择设备",
                    dataType: "数据类型",
                    dataTypePlaceholder: "请选择数据类型",
                    functionCode: "功能码",
                    functionCodePlaceholder: "请选择功能码",
                    boundSlave: "绑定从站",
                    boundSlavePlaceholder: "请选择绑定从站",
                    increment: "自增",
                    allIncrement: "全部自增",
                    variableAddress: "变量地址",
                    eigenType: "特征值类型",
                    eigenTypePlaceholder: "请选择特征值类型",
                    boolean: "布尔型（bool）",
                    wireRegister: "线圈寄存器",
                    holdingRegister: "保持寄存器",
                    addressIntegerRule: "变量地址必须为正整数",
                    pointRequired: "请选择测点",
                    faultRequired: "请选择故障",
                    deleteConfigSucceed: "清除配置成功",
                    deleteConfigConfirm: "请确认是否清除所选测点配置？",
                    slaveDeviceInfo: "从站设备信息",
                    addSlave: "添加从站",
                    slaveName: "从站名称",
                    slaveId: "从属ID",
                    editSlave: "编辑从站",
                    deleteSlave: "删除从站",
                    slaveNameRequired: "请输入从站名称",
                    method: "方式",
                    rightIpRequired: "请输入正确IP",
                    startSucceed: "启动成功",
                    startFailed: "启动失败",
                    endSucceed: "停止成功",
                    deleteSlaveConfirm: "请确认是否删除所选从站",
                    statusCode: "状态编码",
                    applyAll: '应用全部',
                    master: '主站',
                    addMaster: '添加主站',
                    siteType: '站点类型',
                    slave: '从站',
                    deviceMsg: '设备编码不能有相同项',
                    statusMsg: '状态编码不能有相同项',
                    addSite: '添加站点',
                    editSite: '编辑站点',
                    siteName: '站点名称',
                    boundSite: '绑定站点',
                    siteInfo: '站点信息',
                    deleteSite: '删除站点',
                    deleteSiteConfirm: "请确认是否删除所选站点",
                    format: '数据格式',
                    nodeFiltering: '节点筛选',
                    codeRule: "状态编码必须为非负的整数",
                    codeLimitRule: "编码必须按报警等级依次增加",
                },
                mqtt: {
                    pointConfig: "测点配置",
                    clientAuthConfig: "客户端授权配置",
                    addClient: "添加客户端",
                    editClient: "编辑客户端",
                    deleteClient: "删除客户端",
                    clientId: "客户端ID",
                    clientIdPlaceholder: "请输入客户端ID",
                    deleteClientConfirm: "确认要删除所选客户端吗？",
                    faultFilter: "故障筛选",
                    faultName: "故障名称",
                    faultAlarm: "故障报警",
                    serviceAddress: "服务地址",
                    waveform: "波形",
                    enableWaveform: "启用波形",
                    editAddressSucceed: "修改服务地址成功",
                    externalCodeRequired: "请输入外部编码！",
                    themePlaceHolder: "请输入主题",
                    groupList: "分组列表",
                    groupDetail: "分组详情",
                    monitoringType: "监测类型",
                    monitoringPointNumber: "监测点编号",
                    keysPlaceHolder: "请输入密钥,最多256个字符",
                    themeSymbolTip: "主题不能包含",
                    key: "密钥",
                    removeGroupTip: "确定要删除选中分组吗？",
                    removePointTip: "确定要删除选中测点吗？",
                    sensorIdTip: "监测编号只支持正整数",
                    sensorLimitTip: "监测编号必须在1到9007199254740992之间",
                    acousticDeviceNum: "声发射断丝设备编号",
                    acousticChannelNum: "声发射断丝通道编号",
                    pushName: "推送名称",
                },
                opc: {
                    connectParam: "连接参数",
                    eigenTypePlaceholder: "请选择特征值类型",
                    applySucceed: "应用成功",
                },
                phm: {
                    terminalList: "终端列表",
                    terminalParam: "终端参数",
                    thirdParty: "第三方",
                    connectionStatus: "连接状态",
                    lastConnectionLasts: "上次连接时间",
                    alarmPushConfig: "报警推送配置",
                    needsConnection: "是否连接",
                    pushWaveformOption: "是否推送波形",
                    pushFalutAlarmOption: "是否推送故障报警",
                    mqttSeverRequired: "请先配置Mqtt服务地址",
                    exportItemRequired: "请选择导出项",
                    exporting: "导出中",
                    addTerminal: "新增终端",
                    editTerminal: "编辑终端",
                    connected: "连接成功",
                    disconnected: "断连成功",
                    needsNumber: "请先在节点配页置设置节点",
                    adding: "新增中",
                    editing: "编辑中",
                    added: "新增成功",
                    edited: "编辑成功",
                    clearSucceed: "清除成功",
                    tcpIpNet: 'IP地址/域名',
                    tcpIpNetPlaceholder: '请输入IP地址或域名',
                    settingList: '配置列表',
                    faultPath: '故障路径',
                    faultData: '故障数据',
                    faultEvent: '故障事件',
                    oneClickSelfIncrement: '一键自增',
                    exportAll: '全部导出',
                    alarmEvent: '报警事件',
                    externalCodeTip: '外部编码最多16个字符',
                    externalCodeTip48: '外部编码最多48个字符',
                    externalCodeLimit: '外部编码不能包含中文',
                    limitTip: '最多16个字符',
                    limitTip48: '最多48个字符',
                    choosePointTip: '请选择测点',
                    chooseFaultTip: '请选择故障',
                    externalCodeLimit2: '外部编码不能包含,',
                    channelStatus: '通道状态'
                },
            },
            alarm: {
                groupType: "分组类型",
                onePointGroup: "单个测点一组",
                allPointGroup: "所有测点一组",
                alarmSaveTime: "报警存储超时时间",
                alarmConfig: "报警配置",
                alarmParam: "报警参数",
                observationCategory: "观察类型",
                observationCategoryPlaceholder: "请选择观察类型",
                observationName: "观察项名称",
                alarmLevel: "报警等级",
                normal: "正常",
                high: "高报",
                highThreshold: "高报阈值",
                doubleHigh: "高高报",
                doubleHighThreshold: "高高报阈值",
                alarmCategory: "报警类型",
                overLimit: "超限",
                belowLimit: "低限",
                inWindow: "窗内",
                outWindow: "窗外",
                equal: "等于",
                none: "无",
                switch: "开关",
                lowLimit: "下限",
                highLimit: "上限",
                referenceValue: "参考值",
                sampleDiagram: "图示",
                pointNameRequired: "请输入测点名称",
                unitTypeRequired: "请选择工程单位类型",
                alarmThresholdRule: "报警阈值应输入-1000000000~1000000000间的数字",
                overLimitRule: "超限幅值报警高高报值必须大于高报值",
                belowLimitRule: "低限幅值报警高报值必须大于高高报值",
                inWindowHighAlarmRule: "窗内幅值报警高报上限必须大于高报下限",
                inWindowDoubleHighAlarmRule: "窗内幅值报警高高报上限必须大于高高报下限",
                inWindowOrderRule: "请按窗内规则赋值（高报上限>高高报上限>高高报下限>高报下限）",
                outWindowHighAlarmRule: "窗外幅值报警高报上限必须大于高报下限",
                outWindowDoubleHighAlarmRule: "窗外幅值报警高高报上限必须大于高高报下限",
                outWindowOrderRule: "请按窗外规则赋值（高高报上限>高报上限>高报下限>高高报下限）",
                alarmFilterInWindowRule: "过滤类型窗内上限必须大于下限",
                alarmFilterOutWindowRule: "过滤类型窗外上限必须大于下限",
                noEditAuth: "您需要测点设置的编辑权限",
                alarmInterval: "报警间隔",
                maxCountLimit: "次数限制",
                counts: "次",
                amplitudeAlarmThresholdCount: "幅值报警触发点数：",
                alarmVoice: "报警声音：",
                systemDefault: "系统默认",
                customVoice: "自定义方案",
                select: "选择",
                preview: "试听",
                alarmIntervalRequired: "请输入报警间隔",
                maxCountLimitRequired: "请输入次数限制",
                amplitudeAlarmThresholdCountRequired: "请输入点数",
                voiceChanged: "切换报警声音成功",
                alarmSaving: "报警存储",
                needsSaving: "是否存储",
                forward: "向前",
                backward: "向后",
                storageUnit: "存储单位",
                blockSizePlaceholder: "请输入0-400之间的整数",
                blockSizeWarning: "向前向后数据块必须为0-400之间的整数",
                secondSizePlaceHolder: "请输入0-1800之间的整数",
                secondSizeWarning: "向前向后存储时间必须为0-1800之间的整数",
                alarmSetting: "报警设置",
                alarmFilter: "报警过滤",
                filterType: "过滤类型",
                alarmPoint: "报警测点",
                amplitudeMutationTrigger: "触发幅值",
                amplitudeMutationTriggerPlaceholder: "请输入触发幅值",
                amplitudeMutationTriggerTitle: "请输入0.001到100000000之间的数字",
                parametersType: "参数类型",
                byFrequencyBand: "按频带",
                byCenterFrequency: "按中心频率",
                byRotateSpeed: "按转速",
                bandParameter: "频谱参数",
                change: "修改",
                bandParameterRule: "请输入0到100000000之间的数字",
                centerFrequency: "中心频率",
                bandMax: "频带上限",
                bandWidth: "带宽",
                bandMix: "频带下限",
                changeBandParameter: "修改频谱参数",
                diagramType: "图谱类型",
                diagramTypePlaceholder: "请选择图谱类型",
                isolateType: "隔直",
                isolateTypePlaceholder: "请选择隔直",
                windowType: "窗类型",
                windowTypePlaceholder: "请选择窗类型",
                analysisCount: "块大小",
                analysisCountPlaceholder: "请选择块大小",
                averageTime: "平均次数",
                averageType: "平均方式",
                averageTypePlaceholder: "请选择平均方式",
                needsSimulateRotateSpeed: "是否模拟转速",
                simulateRotateSpeed: "模拟转速",
                noSimulation: "不模拟",
                bandFrequencyMinWithUnit: "频带下限（Hz）",
                centerFrequencyWithUnit: "中心频率（Hz）",
                bandFrequencyMinWithX: "频带下限（x）",
                bandFrequencyMaxWithUnit: "频带上限（Hz）",
                bandWidthWithUnit: "带宽（Hz）",
                bandFrequencyMaxWithX: "频带上限（x）",
                forwardBlock: "向前数据块",
                forwardBlockRequired: "请输入向前存储的数据块数",
                backwardBlock: "向后数据块",
                backwardBlockRequired: "请输入向后存储的数据块数",
                peak: "峰值",
                averageSqrt: "均方根值",
                amplitudeAlarm: "幅值报警",
                mutationAlarm: "突变报警",
                bandFrequencyAlarm: "频带能量报警",
                saveSucceedAlert: "保存报警存储成功",
                amplitudeHighAlarmRule: "超限幅值报警高高报值必须大于高报值",
                amplitudeLowAlarmRule: "低限幅值报警高报值必须大于高高报值",
                inWindowHighAlarmRule: "窗内幅值报警高报上限必须大于高报下限",
                inWindowDoubleHighAlarmRule: "窗内幅值报警高高报上限必须大于高高报下限",
                outWindowHighAlarmRule: "窗外幅值报警高报上限必须大于高报下限",
                outWindowDoubleHighAlarmRule: "窗外幅值报警高高报上限必须大于高高报下限",
                ampEigenTypeRequired: "请选择幅值特征值类型",
                mutationThresholdRule: "报警阈值应输入0.001~100000000间的数字",
                mutationHighAlarmRule: "突变报警高高报值必须大于高报值",
                mutationEigenTypePlaceholder: "请选择突变特征值类型",
                mutationTriggerPlaceholder: "请输入触发幅值",
                alarmParamRequired: "请添加报警参数",
                changeAmpAlarmSucceed: "修改幅值报警成功",
                changeMutAlarmSucceed: "修改突变报警成功",
                changeBandEnergyAlarmSucceed: "修改频带能量报警成功",
                removeSucceed: "移除成功",
                highThresholdError: "超限高报阈值必须小于高高报阈值",
                highThresholdError2: "低限高报阈值必须大于高高报阈值",
                bandRangeError: "频带上限必须大于下限",
                onlinePoint: "在线测点",
                patrolPoint: "巡检测点",
                paramSetting: "参数设置",
                requesting: "请求中",
                noBrowseAuth: "暂无权限查看测点",
                chooseAlarm: "请先设置报警",
                alarmSettingSuccess: '报警设置修改成功',
                bandCoincideMsg: "频带区间不能有重合",
                detailSetting: "详细设置",
                ampAlarmTriggerControl: "幅值报警触发控制",
                ampAlarmTriggerNums: "幅值报警触发点数",
            },
            platformMachineTree: {
                instrumentTreeSetting: "设备树配置",
                otherSetting: "其他设置",
                syncInstrumentTree: "同步设备树",
                unlinking: "取消关联",
                orginazaionRelated: "关联组织结构",
                linkToOrganization: "关联组织",
                syncWarning: "同步设备树会将设备树同步添加并覆盖到部门管理列表，设备和场景将会创建同名虚拟部门至部门管理列表，是否继续？",
                syncDeviceOrScene: "同步设备/场景",
                editOrganizationRelated: "编辑关联组织结构",
                instrumentTreeName: "设备树名称",
                relateTreeStruct: "关联树结构",
                test: "测试",
                organizationRequired: "请选择组织结构",
                departmentExistedError: "已存在部门，无法进行同步",
                syncSucceed: "同步成功",
                linkSucceed: "关联成功",
                pointNeeded: "请选择节点",
                cancelSucceed: "取消成功",
                alertMessage: "确实要取消关联吗？"
            },
            save: {
                storageRule: "存储规则",
                storageMode: "存储模式",
                storageType: "存储类型",
                dataBackup: "数据备份",
                underPath: "所属路径",
                storageTypeCell: "存储规则单元",
                switchRule: "切换规则",
                all: "全部",
                switchStorageRuleWarning: "不同存储规则的数据不能连续查询,请确认是否切换？",
                switchSucceed: "切换成功",
                bindPoint: "绑定测点",
                ruleName: "规则名称",
                ruleNamePlaceholder: "请输入规则名称",
                diluteRatio: "稀释比例",
                saveDay: "存储天数",
                saveInterval: "存储间隔(s)",
                saveInterval1: "存储间隔",
                saveDayLimitTip: "存储天数不能超过366天",
                saveDayMustLteLast: "存储间隔必须依次增加",
                firstLvelsaveDayMustBeZero: "第一级只能是0",
                saveRadioMustLteLast: "存储间隔下一级必须大于上一级间隔",
                diluteStrategy: "稀释策略（比例稀释）",
                isDisplay: "请选择是否显示",
                diluteRatioIncreaseRule: "时间必须依次增加",
                diluteRatioMaxRule: "稀释比例不能超过65532",
                storageRuleCellName: "存储规则单元名称",
                dilutionRulesName: "稀释规则单元名称",
                storageRuleCellNamePlaceholder: "请输入存储规则单元名称",
                storageRuleRadioName: "稀释规则名称",
                storageRuleRadioNamePlaceholder: "请输入稀释规则名称",
                saveDayWithLimit: "存储天数(正整数)",
                naturalNumberPlaceholder: "请输入正整数",
                storageOverMaxError: "存储条数最多10条",
                saveDayExcceed: "存储天数不能超过65532",
                saveDayNeedsIncrease: "存储天数必须依次增加",
                saveDayPlaceholder: "请填写存储天数",
                continousStore: "连续存储",
                fixedTimeStore: "定时存储",
                diluteStore: "稀释存储",
                triggerStore: "触发存储",
                negativeDelayPoint: "负延迟点数",
                delayPointPlaceholder: "请输入延迟点数",
                startTrigger: "启动条件",
                triggerOnce: "单次触发",
                triggerMulti: "多次触发",
                triggerPeriodPlaceholder: "请输入触发周期",
                triggerTimes: "触发次数",
                triggerInterval: "触发间隔",
                triggerTimePlaceholder: "请输入触发次数",
                zeroEqualsToInfinity: "0代表不限次数",
                startDayPlaceholder: "请选择开始日期",
                stopTrigger: "停止条件",
                absoluteTime: "绝对时间",
                relativeTime: "相对时间",
                trigger: "触发条件",
                triggerPoint: "触发测点",
                triggerType: "触发模式",
                triggerBySignal: "信号触发",
                triggerMethod: "触发方式",
                triggerThreshold: "触发阈值",
                continousPoint: "持续点数",
                noStorageRuleCell: "暂无存储规则单元",
                addStorageRule: "添加存储规则",
                editStorageRule: "编辑存储规则",
                pointLinkedToRuleWarning: "该规则已经有绑定测点",
                addStorageRuleCell: "添加存储规则单元",
                editStorageRuleCell: "编辑存储规则单元",
                deleteConfirm: "确认是否删除",
                deleteConfirmTitle: "删除单元",
                automaticallyBackup: "自动备份",
                backupPeriod: "备份周期",
                backupData: "备份数据",
                allData: "全部数据",
                eigenAndAlarm: "特征值+报警",
                backupTime: "备份时间",
                backupManually: "手动备份",
                backupNow: "立即备份",
                endTimeShouldLargerThanStartTime: "开始时间必须小于结束时间",
                backupInProgress: "备份中",
                addStorageRule: "添加存储规则",
                deleteStorageRule: "删除存储规则",
                storageRuleName: "存储规则名称",
                editDilutionRule: "编辑稀释规则单元",
                addDilutionRule: "添加稀释规则单元",
                addStorageInterval: "添加稀释规则",
                editStorageInterval: "编辑稀释规则",
                startTriggerType: "启动条件类型",
                startTime: "启动时间",
                storagePeriod: "存储周期",
                storageTime: "存储时间",
                storageTimes: "存储次数",
                stopTriggerType: "停止条件类型",
                stopTime: "停止时间",
                stopTimeType: "停止时间类型",
                stopTimePlaceholder: "请输入停止时间",
                stopTimeRequired: "请输入停止时间",
                periodTrigger: "周期触发",
                storageTypeBondByPoint: "选中项中存在被测点绑定的存储规则！",
                storageTypeAlreadyBondByPoint: "当前存储规则已被测点绑定！",
                timePlaceholder: "请选择时间",
                stopTimeBiggerThanTriggerTime: "停止时间必须大于触发时间",
                stopTimeSmallerThanStoragePeriod: "停止时间必须小于存储周期",
                fixedTimeTypeNameRequired: "请输入定时存储类型名称",
                startTimeRequired: "请选择启动时间",
                storagePeriodRequired: "请输入存储周期",
                storageTimesRequired: "请输入存储次数",
                addFixedTimeStorageRule: "添加定时存储规则",
                editFixedTimeStorageRule: "编辑定时存储规则",
                lowElectricalLevel: "低电平",
                highElectricalLevel: "高电平",
                continousBlock: "持续块数",
                triggerType2: '触发类型',
                byThreshold: '按阈值',
                byTime: '按时间',
                continousTime: "持续时间",
                triggerThresholdRequired: "请输入触发阈值！",
                triggerThresholdMustBeNumber: "触发阈值只能是数字！",
                continousBlockRequired: "请输入持续块数！",
                continousBlockMustBeNaturalNumber: "持续块数只能是正整数！",
                triggerTimeRequired: "请输入持续时间",
                rulerNameRequired: "请选输入连续存储规则名称",
                rulerGroupRequired: "请选择稀释规则单元",
                duilteRulerRequired: "请选择稀释规则",
                triggerTimeMustBeNaturalNumber: "持续时间只能是正整数",
                triggerType: "触发条件类型",
                delayType: "延迟方式",
                delayPoints: "延迟点数（块）",
                delayPointsWithoutUnit: "延迟点数",
                noDelay: "无延迟",
                positiveDelay: "正延迟",
                negativeDelay: "负延迟",
                addTriggerStorageType: "添加触发存储规则",
                editTriggerStorageType: "编辑触发存储规则",
                addTriggerStorageRule: "添加触发存储规则",
                editTriggerStorageRule: "编辑触发存储规则",
                triggerParameter: "触发参数",
                storageTypePlaceholder: "请输入存储规则名称",
                triggerStorageTypeNameRequired: "请输入触发存储类型名称",
                triggerTimeRequired: "请输入触发次数",
                triggerIntervalRequired: "请输入触发间隔",
                delayPointRequired: "请输入延迟点数",
                deleteTip: '存储天数变短系统会删除多余数据,请再次确认是否修改?',
                confrimTip: '存储天数变短系统会删除多余数据,请确认是否修改?',
                storeUpdateTip: '存储规则"[0]"对应的稀释单元已经关联了稀释规则[1]，请在存储配置中修改',
                startConditionsRequired: "请添加启动条件",
                dilutionRules: "稀释规则",
                dilutionRulesUnit: "稀释规则单元",
                rules1: '30天内: 1条数据/1分钟',
                rules2: '30-90天:1条数据/10分钟',
                rules3: '90-180天:1条数据/1小时',
                rules4: '大于180天:1条数据/12小时',
                startConditionPoint: '启动条件请选择触发测点',
                stopConditionPoint: '停止条件请选择触发测点',
                confirmTip: "修改存储规则会导致已经采集的数据丢失,建议联系工程师确认,请问是否继续?"
            },
            scheduledExport: {
                title: "定时导出任务",
                exportParam: "导出参数",
                exportFormat: "导出格式",
                exportPeriod: "导出周期",
                actionTime: "导出执行时间",
                xDecimal: "X轴小数位数",
                yDecimal: "Y轴小数位数",
                timePlaceholder: "请选择时间",
                expiredAfter: "过期清理时长",
                separatorType: "数据段分隔符",
                customSeparator: "其他分隔符",
                exportData: "导出数据",
                alarmExportFormat: "报警数据格式",
                exportItem: "导出项",
                storageTypeForDHDAS: "存储方式",
                exportGroup: "导出分组",
                exportPoints: "导出测点",
                eachDay: "每一天",
                twoDays: "每两天",
                threeDays: "每三天",
                fourDays: "每四天",
                fiveDays: "每五天",
                sixDays: "每六天",
                eachWeek: "每一周",
                oneMonth: "一个月",
                threeMonths: "三个月",
                sixMonths: "六个月",
                nineMonths: "九个月",
                oneYear: "一年",
                oneYearAndHalf: "一年半",
                twoYears: "两年",
                threeYears: "三年",
                notCleanUp: "不清理备份",
                semicolonChar: "分号",
                commaChar: "逗号",
                blankChar: "空格",
                tableChar: "制表符",
                otherChar: "其他",
                waveformData: "波形数据",
                eigenData: "特征数据",
                alarmData: "报警数据",
                startTime: "起始时间",
                saveEachChannel: "各通道分别保存",
                saveEachGroup: "通道组分别保存",
                actionTimeRequired: "请选择导出任务执行时间",
                separatorRequired: "请输入自定义分隔符",
                exportDataAtLeastOne: "导出数据需要至少一项",
                formatRequired: "请选择导出报警数据文件类型",
                editGroup: "编辑分组",
                addGroup: "添加分组",
                samplePlaceholder: "请选择采样频率",
                fileNameTip: "请输入,不填写将会是默认名称",
                blockPlacehoder: "请选择数据块大小",
                groupNameRequired: "请输入分组名称",
                pointAtLeastOne: "请至少选择一个测点",
                deleteGroup: "删除分组",
                apply: "应用",
                applyToAll: "应用到全部",
                applyToOriginal: "应用到原始测点",
                applyToSignal: "应用到信号处理测点",
                groupName: "分组名称",
                fileName: "文件名称",
                sampleFrequency: "采样频率",
                blockSize: "数据块大小",
                groupedPoints: "分组测点",
                previewExport: '预览导出',
                dataInterval: '数据间隔',
                waveDataInterval: '波形数据间隔',
                eigenDataInterval: '特征值数据间隔',
                reportTaskList: '报告任务列表',
                reportTaskSetting: '报告任务设置',
                exportDevices: '导出设备',
                blankContent: '空行补数策略',
                noContent: '不补',
                latestValue: '最近值'
            },
            device: {
                deviceSetting: "设备设置",
            },
            graph: {
                graphConfig: "图谱配置",
                labelConfig: "标签配置",
                fixedScale: '固定刻度',
                millisecond: '毫秒',
                selfAdaption: '自适应',
                axisScaleSetting: "轴刻度设置",
                displayCoordinatePointsOrNot: "是否显示坐标点",
                historyInsertAlarmPoints: "历史数据插入报警点",
                historicalPoint: '历史数据插入报警点',
                historyInsertAlarmPoints: "历史数据插入报警点",
                minimumPushInterval: "最小推送间隔",
                maximumNumberOfCachePointsForWaveform: "波形图最大缓存点数",
                graphGonfiguration: "图谱配置",
                labelGonfiguration: "标签配置",
                decimalPlaces: "小数位数",
                fontSize: "字号大小",
                showLabelLines: "是否显示标签线",
                showLabelPoints: "是否显示标签点",
                displayFeatureValueNames: "是否显示特征值名",
                useRecorder: "使用记录仪",
                recorderTip: "使用记录仪将会导致一定程度的性能损耗，请确认是否使用记录仪？",
                recorderSureAgain: "使用记录仪将会导致一定程度的性能损耗，请再次确认是否使用记录仪？",
                recorderCloseTip: "关闭记录仪模式将会影响其他用户，是否确认关闭记录仪？",
            },
            msgSend: {
                configurationInformation: "配置信息",
                mailboxServer: "邮箱服务器",
                pleaseEnterTheEmailServer: "请输入邮箱服务器",
                emailServerSendPort: "邮箱服务器发送端口",
                pleaseEnterTheSendPort: "请设置发送端口",
                emailAccount: "邮箱账号",
                pleaseEnterYourEmailAccount: "请输入邮箱账号",
                authorizationPassword: "授权密码",
                pleaseEnterTheAuthorizationPassword: "请输入授权密码",
                domainName: "域名",
                pleaseEnterTheDomainName: "请输入域名",
                emailSender: "邮件发送方",
                pleaseEnterTheEmailSender: "请输入邮件发送方",
                limitOnTheNumberOfUsersForMassSending: "群发发送用户数量限制",
                pleaseEnterTheMaximumLimitNumber: "请设置最大限制数",
                sendEmailSubject: "发送邮件主题",
                pleaseEnterTheEmailSubject: "请输入邮件主题",
                companyLinkAddress: "公司链接地址",
                pleaseEnterTheCompanyLinkAddress: "请输入公司链接地址",
                startStopControl: "启停控制",
                startUp: "启动",
                stopIt: "停止",
                systemEventPushLevel: "系统事件推送等级",
                pleaseSelectTheSystemEventPushLevel: "请设置事件推送等级",
                systemAlarmPushLevel: "系统报警推送等级",
                pleaseSetTheAlarmPushLevel: "请设置报警推送等级",
                gaobao: "高报",
                highHighAlarm: "高高报",
                enableOrNot: "是否启用",
                recipientList: "收件人列表",
                eMailAddress: "邮箱地址",
                test: "测试",
                noEditingPermissionsCurrentlyAvailable: "暂无编辑权限",
                pleaseEnterTheCorrectEmailAddress: "请输入正确邮箱",
                confirmWhetherToDelete: "确认是否删除",
                deleteEmail: "删除邮箱",
                pleaseFillInTheConfigurationInformationCompletely: "请将配置信息填写完整",
                pleaseAddRecipient: "请添加收件人",
                testSuccessful: "测试成功",
                eailConfiguration: "邮件配置",
                SMSConfiguration: "短信配置",
                SMSAlarmSendingMechanism: "短信报警发送机制",
                HuaweiCloudSMS: "华为云短信",
                systemEventAlarmLevel: "系统报警推送等级",
                systemEventPush: "系统事件推送",
                systemEventPushLevel: "系统事件推送等级",
                pleaseSetTheEventAlarmLevel: "请设置事件报警等级",
                warning: "警告",
                danger: "危险",
                sistributionLists: "收信人列表",
                thereIsCurrentlyNoAataAvailable: "暂无数据",
                contactPhoneNumber: "联系人电话",
                pleaseEnterTheEmailServer: "请输入邮箱服务器",
                pleaseEnterTheSendPort: "请输入发送端口",
                email: "邮箱",
                pleaseEnterTheCorrectEmailFormat: "请输入正确邮箱格式",
                pleaseEnterTheMaximumLimitNumber: "请输入最大限制数",
                pleaseEnterATheme: "请输入主题",
                pleaseEnterTheCompanyAddress: "请输入公司地址",
                pleaseSelectStartStopControl: "请选择启停控制",
                pleaseSelectTheSystemEventPushLevel: "请选择系统事件推送等级",
                pleaseSelectTheSystemAlarmPushLevel: "请选择系统报警推送等级",
                pleaseSelectWhetherToEnableOrNot: "请选择是否启用",
                pleaseSelectTheSendingMechanism: "请选择发送机制",
                pleaseSelectTheSystemEventAlarmLevel: "请选择系统事件报警等级",
                noPermissionToEditTemporarily: "暂无权限编辑",
                pleaseEnterTheCorrectPhoneNumber: "请输入正确手机号",
                confirmWhetherToDelete: "确认是否删除",
                deleteContactPhoneNumber: "删除联系人电话",
                pleaseFillInTheConfigurationInformationCompletely: "请将配置信息填写完整",
                pleaseAddARecipient: "请添加收信人",
                eventSmsSwitch: '消息推送事件开关',
                systemAlarmPush: "系统报警推送",
                associateUsers: "关联用户"
            },
        },
        tools: {
            title: '最大许用扭振角度计算',
            batchTitle: "批处理工具",
            format: "格式:",
            exportInstrumentTree: "导出设备树:",
            importInstrumentTree: "导入设备树:",
            import: "导入",
            exportNodeRequired: "请选择导出节点",
            exporting: "导出中",
            errorTip: "错误提示",
            importFailed: "导入失败",
            importSucceed: "导入成功",
            importing: "导入中",
            progress: "进度",
            batchTip: "导入需要仪器停止采样，导入可能导致已有测点被修改或删除",
            outerAxleDiameter: "轴外直径（mm）",
            innerAxleDiameter: "轴内直径（mm）",
            axleLength: "轴长度（mm）",
            E: "材料的杨氏模量（Mpa）",
            yieldStrength: "材料的屈服极限应力（MPa）",
            possonRatio: "材料泊松比",
            ns: "安全系数",
            maxTwistVibrateAngle: "最大许用扭振角度",
            paramIllegalError: "请检查参数是否符合实际情况",
            calculated: "计算成功",
            currentPosition: "当前位置",
            instrumentManagement: "仪器管理",
            instrumentSetting: "仪器设置",
            selfCheckSetting: "自检设置",
            signalSetting: "信号设置",
            bearingSetting: "轴承库设置",
            faultLibrarySetting: "故障库设置",
            patrolSetting: "巡检设置",
            authorizationManagement: "权限管理",
            userRoleManagement: "角色管理",
            workGroupManagement: "工作组管理",
            dataPushing: "数据推送",
            messagePushing: "消息推送",
            storageSetting: "存储配置",
            componentSetting: "组件配置",
            taskSetting: "任务配置",
            toolManagement: "工具管理",
            calculatingTool: "计算工具",
            batchingTool: "批处理工具",
            systemSetting: "系统设置",
            languageSetting: "语言设置",
            analysisTool: "分析工具",
            dataSource: "数据源",
            dataSources: "数据来源",
            notOpen: "未打开",
            turnedOn: "已打开",
            opening: "打开中",
            database: "数据库",
            file: "文件",
            dataAdress: '数据路径',
            dataName: '数据库名称',
            deviceTree: "设备树",
            forExample: "例如",
            activeNodeTip: "请选择设备节点",
            enableTip: "请先启用数据源",
            exportAnalysisTree: "导出分析工具设备树"
        },
    },
    component: {
        title: {
            custom: '自定义',

            recordGraph: '2D图谱',
            trendGraph: '趋势图',
            waveGraph: '波形图',
            spectrumGraph: '频谱图',
            waterfallGraph: '瀑布图',
            barGraph: '棒图',
            numberTable: '数字表',
            dashBoard: '仪表盘',
            wholePeriodGraph: '整周期波形图',
            axisPosition: '轴心位置图',
            axisTrail: '轴心轨迹图',
            polarGraph: '极坐标图',
            bodePlotsGraph: '伯德图',
            cascadeGraph: '级联图',
            torvibMonitoring: '扭振监测',
            torvibRotate: '扭振转速分析',
            panelGraph: '面板',

            iirGraph: 'IIR数字滤波',
            firGraph: 'FIR数字滤波',
            integralOnce: '一次积分',
            integralTwice: '二次积分',
            differentialOnce: '一次微分',
            differentialTwice: '二次微分',
            correlation: '相关分析',
            analysisOfCovariance: '协方差分析',
            virtualCompute: '虚拟计算',
            trendForecast: '趋势预测',

            statisticAnalysis: '统计分析',
            ampAnalysis: '幅值分析',
            rainflowAnalysis: '雨流分析',
            eigenFilter: '特征值滤波',

            fftGraph: '频谱分析',
            crossPowerSpectrumDens: '互功率谱密度',
            cepstrumAnalysis: '倒谱分析',
            freqIntegral: '频域积分',

            orderAlgorithm: '阶次谱',

            envelopeWave: '包络波形',
            envelopeSpectrum: '包络谱',

            soundPressureAnalysis: '声压分析',
            soundIntensityAnalysis: '声强分析',
            acousticPowerAnalysis: '声功率分析',

            timeDomainODS: '时域ODS',
            frequencyDomainODS: '频域ODS',

            rosetteAlgorithm: '应变花计算',
            torqueAnalysis: '扭矩分析',
            shaftPowerAnalysis: '轴功率分析',
            torsionalVibrationAnalysis: '扭振分析',
            cableForceAlgorithm: '索力分析',
            waveletAnalysis: '小波分析',
            bucklingAnalysis: '弯矩分析',
            residualStressAnalysis: '残余应力'
        },

        cursorSettings: '光标设置',
        statisticalInformation: '统计信息',
        componentProperties: '组件属性',

        cursorType: {
            noCursor: '无光标',
            singleCursor: '单光标',
        },

        statistics: {
            maximumValue: '最大值',
            minimumValue: '最小值',
            rootMeanSquare: '均方根',
            averageValue: '平均值',
            skewness: '偏度',
            kurtosis: '峰度',
            standardDeviation: '标准差',
            peakToPeak: '峰峰值',
        },

        message: {
            paramFailed: '参数获取失败',
            maxPoint32: '最多添加32个测点',
            maxEigen32: '最多添加32个特征值',
        },

        algorithm: {
            parameterSetting: '参数设置',
            colorSetting: '颜色设置',
            preview: '预览',
            analysisPoints: '分析点数',
            manualExport: '手动导出',
            exportRange: '导出范围',

            intervalNum: '区间个数',
            statisticalType: '统计类型',
            AutomaticValue: '自动取值',
            statisticalLowerLimit: '统计下限',
            statisticalUpperLimit: '统计上限',
            intervalNumIntMsg: '区间个数必须是整数',
            intervalNumRangeMsg: '区间个数范围为',
            lowerIntMsg: '统计下限必须是数字',
            lowerRangeMsg: '统计下限范围为',
            upperIntMsg: '统计上限必须是数字',
            upperRangeMsg: '统计上限范围为',
            statisticalRule: '统计下限必须小于统计上限',

            cableType: '索类型',
            cableLength: '索长',
            cableDiameter: '索直径',
            cableWeight: '索重量',
            elasticModulus: '弹性模量',
            fundamentalFrequencyLowerLimit: '基频下限',
            fundamentalFrequencyUpperLimit: '基频上限',
            graphType: '图谱类型',
            magnitudeType: '幅值类型',
            windowType: '窗类型',
            septumRectum: '隔直',
            frequencyResolution: '频率分辨率',
            averageTimes: '平均次数',
            averageMethod: '平均方式',
            overlap: '重叠率',
            exponentialWeighting: '指数加权',
            simulateSpeedOrNot: '是否模拟转速',
            simulatedSpeed: '模拟转速',
            unitSwitching: '单位切换',
            cableRule1: '索长必须大于0小于等于100000000',
            cableRule2: '索直径必须大于0小于等于100000000',
            cableRule3: '索重量必须大于0小于等于100000000',
            cableRule4: '弹性模量必须大于0小于等于100000000',
            cableRule5: '基频下限必须小于等于100000000',
            cableRule6: '基频上限必须大于下限',
            cableRule7: '索力分析基频下限超过范围',
            cableRule8: '索力分析基频上限超过范围',

            estimationMethod: '估计方式',

            unitConversionCoefficient: '单位转换系数',

            highestSpectralLineFrequency: '最高谱线频率',
            highestSpectralLineAmplitude: '最高谱线幅值',
            highestSpectralLinePhase: '最高谱线相位',
            lowestSpectralLineFrequency: '最低谱线频率',
            lowestSpectralLineAmplitude: '最低谱线幅值',
            lowestSpectralLinePhase: '最低谱线相位',
            fundamentalAmplitude: '基频幅值',
            fundamentalFrequency: '基频频率',

            bandpassUpperLimitFrequency: '带通上限频率',
            bandpassLowerLimitFrequency: '带通下限频率',
            order: '阶数',
            filterAmplitudePhaseFrequencyCharacteristics: '滤波器幅相频特性',

            filteringMethod: '滤波方式',
            filterType: '滤波器类型',
            windowFunctionMethod: '窗函数法',
            upperBoundaryFrequencyOfPassband: '通带上边界频率',
            lowerBoundaryFrequencyOfPassband: '通带下边界频率',
            stopBandUpperBoundaryFrequency: '阻带上边界频率',
            stopBandLowerBoundaryFrequency: '阻带下边界频率',
            passbandCutoffFrequency: '通带截止频率',

            passbandBoundaryFrequencyTip: '通带边界频率太大或太小',
            stopBandBoundaryFrequencyTip: '阻带边界频率太大或太小',
            stopLessThanPassTip: '阻带边界频率不能小于通带边界频率',
            stopLargerThanPassTip: '阻带边界频率不能大于通带边界频率',
            passTopLessThanPassBottom: '通带上边界频率不能小于通带下边界频率',
            stopTopLessThanStopBottom: '阻带上边界频率不能小于阻带下边界频率',
            passBottomLessThanStopBottom: '通带下边界频率不能小于阻带下边界频率',
            passTopLargerThanStopTop: '通带上边界频率不能大于阻带上边界频率',
            stopBottomLargerThanPassBottom: '阻带下边界频率必须大于通带下边界频率',
            stopTopLargerThanPassTop: '阻带上边界频率必须小于通带上边界频率',

            lowerBoundaryFrequencyOfPassbandTip: '通带下边界频率太大或太小',
            upperBoundaryFrequencyOfPassbandTip: '通带上边界频率太大或太小',
            bandpassTip: '带通下限频率必须小于带通上限频率',
            bandpassUpperLimitFrequencyTip: '带通上限频率太大或太小',
            bandpassLowerLimitFrequencyTip: '带通下限频率太大或太小',
            stopBandBottomTip: '阻带下边界频率太大或太小',
            stopBandTopTip: '阻带上边界频率太大或太小',

            lowPass: '低通',
            highPass: '高通',
            bandpass: '带通',
            bandstop: '带阻',

            rectangularWindow: '矩形窗',
            hanning: '汉宁窗',
            haimingWindow: '海明窗',
            flatTopWindow: '平顶窗',
            triangularWindow: '三角窗',
            blackmanWindow: '布莱克曼窗',

            filterType2: '滤波类型',
            passbandBoundaryFrequency: '通带边界频率',
            stopBandBoundaryFrequency: '阻带边界频率',
            flatnessOfPassband: '通带平坦度',
            stopbandAttenuation: '阻带衰减',
            minimumOrder: '最小阶数',
            butter: '巴特沃兹',
            cheby: '切比雪夫',
            highPassDCFilter: '直流和高通滤波器',
            lowPassFilter: '低通滤波器',

            statisticalSettings: '统计设置',
            statisticalMethods: '统计方式',
            amplitudeRange: '幅值范围',
            meanRange: '均值范围',
            minimumValue: '最小值',
            maximumValue: '最大值',

            inputMeasurementPoint: '输入测点',
            parameterImport: '参数导入',
            parameterExport: '参数导出',
            groupNumber: '组号',
            // order: '组号',
            strainFlowerName: '应变花名称',
            strainFlowerType: '应变花类型',
            material: '材料',
            elasticModulus: '弹性模量',
            poissonRatio: '泊松比',
            outputMeasurementPoint: '输出测点',
            characteristicValue: '特征值',
            statisticalValues: '统计值',
            illustration: '图示',
            strainFlower: '应变花',

            byWaveform: '按波形',
            byCharacteristicValue: '按特征值',
            byFrequencyDomain: '按频域',
            switchConfirmMsg: '切换会删除原有输出测点,确认切换?',

            fittingData: '拟合数据',
            predictionDuration: '预测时长',
            predictionInterval: '预测间隔',
            fittingMethod: '拟合方式',
            polynomial: '多项式',
            index: '指数',

            tenMinutes: "十分钟",
            thirtyMinutes: "三十分钟",
            oneHour: "一小时",
            twelveHours: "十二小时",
            oneDay: "一天",
            threeDays: "三天",
            oneWeek: "一周",
            oneMonth: "一个月",
            threeMonths: "三个月",
            sixMonths: "半年",
            oneYear: "一年",

            formula: "公式",
            customFunctions: "自定义函数",
            chooseFunction: "选择函数：",
            addFunction: "添加函数",
            editFunction: "编辑函数",
            deleteFunction: "删除函数",
            essentialInformation: "基本信息",
            runningFunctionName: "运行函数名称",
            functionDescription: "函数描述",
            functionContent: "函数内容",
            compile: "编译",
            formulaPlaceholder: "请输入公式",
            formulaErrMsg: "公式解析错误",
            parsing: "解析中",
            editing: '修改中',
            compileSuccess: "编译成功",
            savePointMsg: '请选择要保存的测点',
            fixedTime: '严格等时间',

            torvibPoint: '扭振测点',
            torvibPointRule: '请勾选相同采样频率的测点',

            shaftInnerDiameter: '轴内径',
            shaftOuterdiameter: '轴外径',
            outputUnit: '输出单位',

            speedChannel: "转速通道",
            speedChannelTip: "请选择转速通道",
            residualStressName: "残余应力名称",
            residualStressNumber: "残余应力数量",
            plasticStrain: "塑性应变",
            strainDistance: "孔心与应变片中心距离",
            releaseFactor: "释放系数",
            holeRadius: "孔半径",
            strainGaugesCloseRange: "应变片近距",
            strainGaugesLongRange: "应变片远距",
            strainGageGridHalfWidth: "应变计丝栅半宽度",
            strainGageGridHalfLength: "应变计丝栅半长度",

            correct: "修正",
            eccentricCoordinates: "偏心坐标",
            known: "已知",
            unknown: "未知",
            automaticScale: "自动刻度"
        },

        template: {
            attributeSettings: '属性设置',
            graphParam: '图谱参数',
            algorithmParam: '算法参数',
            alarmParam: '报警参数',
            saveConfig: '存储设置',
            unSaveTitle: '未完成巡检任务列表',
            moveBoard: '移动看板'
        },

        save: {
            isStoreWaveform: '是否存储波形',
            isStoreAlarm: '是否存储报警',
        },

        templateBrowse: {
            alarmDetails: '报警详情',
            alarmDevice: '报警设备',
            devicePath: '设备路径',
            alarmClassification: '报警分类',
            severity: '严重程度',

            offlineDetails: '离线详情',
            number: '编号',
            abnormalDataDetails: '异常数据详情',
            barChartSettings: '柱状图设置',
            lineChartSettings: '折线图设置',

            unlock: '解锁',
            lock: '锁定',
            setBackground: '设为背景',
            setComponent: '设为组件',
            setMark: '设置标记',
            setTitle: '设置标题',
            reportProperty: '报表属性设置',
            markEnter: '请输入标记名称',
            markEdit: '修改标记名称',
            markLimit: '标记名称不可超过20个字符',
            titleEnter: '请输入标题',
            titleEdit: '修改标题',
            titleLimit: '标题不可超过20个字符',

            pieChartSettings: '饼图设置',
            offlineDeviceDetails: '离线设备详情',
            samplingTime: '采样时间',
            videoParam: '视频参数',
            videoEdit: '视频编辑',
        },

        vue: {
            maximize: '最大化',
            reduction: '还原',
            close: '关闭',
            move: '移动',
            billboard: "看板",
            deleteAllGraphTip: "确认删除当前看板下的所有图谱吗？",
            removeTip: "删除当前看板同时会删除当前看板下所有图谱，是否确定？",
            limitBillboardTip: '最多存在10个看板',
            expandNavigation: '展开导航',
            collapseNavigation: '收起导航',
            deviceList: '设备列表',
            pointList: '测点列表',
            eigenList: '特征值列表',
            decimalPlaces: '小数位数',
            fontSize: '字号大小',
            nextBatch: '下一批',
            preBatch: '上一批',

            dataExport: '数据导出',
            exportMeasurementPoints: '导出测点',
            exportFormat: '导出格式',
            storageMethod: '存储方式',
            exportScope: '导出范围',
            current: '当前',
            absoluteTime: '绝对时间',
            outputTime: '输出时间',
            outputFrequency: '输出频率',
            startTime: '起始时间',
            xAxisDecimalPlaces: 'X轴小数位数',
            yAxisDecimalPlaces: 'Y轴小数位数',
            dataSegmentSeparator: '数据段分隔符',
            otherDelimiter: '其他分隔符',
            exportItems: '导出项',
            pointPath: '测点路径',
            selectPointMsg: '请选择测点',
            noPointMsg: '没有选择测点 或 选择测点无特征值',
            enterSeparatorMsg: '请输入分隔符',
            separatorLimitMsg: '分隔符长度不能超过10个字符',
            selectTimeMsg: '请选择时间',
            timeLimitMsg: '起始时间必须小于结束时间',

            pointLabel: '测点标签',
            videoLabel: '视频标签',
            navigationLabel: '导航标签',
            multiPointLabel: '多测点标签',
            trendLabel: '趋势图标签',

            narrow: '缩小',
            enlarge: '放大',
            navigationSettings: '导航设置',
            navigationGraphPropertySettings: '导航图属性设置',
            dataInput: '数据输入',
            timeSettings: '时间设置',
            endTimeType: '结束时间类型',
            endTime: '结束时间',
            singleNavigationCursor: '单导航光标',
            dualNavigationCursor: '双导航光标',
            timeRangeTooLarge: '时间跨度太大',
            timeRangeGreaterZero: '时间长度需要大于0',

            pointLabelList: '测点标签列表',
            noLabel: '暂无标签',
            pointChoose: '测点选择',
            showEigen: '显示特征值',
            sigleEigen: '单特征值',
            multiEigen: '多特征值',
            pointList: '测点列表',
            deviceName: '设备名称',
            pointName: '测点名称',
            displayParamName: '显示参数名称',
            eigenType: '特征值类型',
            unit: '单位',
            newLabel: '新建标签',

            videoLabelList: '视频标签列表',
            optionSettings: '选项设置',
            cameraName: '摄像头名称',
            jumpLink: '跳转链接',
            displayPoint: '显示测点',
            deviceName: '设备名称',
            displayParameterName: '显示参数名称',
            videoLabel: '视频标签',

            localAmplification: '局部放大',
            addPoint: '添加测点',
            addComponent: '添加组件',
            view: '视图',
            hideGrid: '隐藏网格',
            showGrid: '显示网格',
            threeDView: '3D视图',
            fourViews: '四视图',
            bottomView: '仰视图',
            verticalView: '俯视图',
            rearView: '后视图',
            rightView: '右视图',
            frontView: '前视图',
            leftView: '左视图',
            point: '测点',
            registerType: '数据显示方式',
            useOrgin: '波形',
            useBlock: '特征值',
            refreshFreq: '刷新频率(Hz)'
        },
    },

    dataInput: {
        modelName: '模型名称',
        scaleOnXAxis: 'X轴小数位数',
        scaleOnYAxis: 'Y轴小数位数',
        numberOfXAxisGrids: 'X轴网格数量',
        numberOfYAxisGrids: 'Y轴网格数量',
        cursor: '光标',
        type: '类型',
        displayItems: '显示项',
        curveinfo: '曲线信息',
        alarmLineStatistics: '报警线统计信息',
        maximumValue: '最大值',
        minimumValue: '最小值',
        rootMeanSquare: '均方根',
        averageValue: '平均值',
        totalRmsValue: '总均方根值',
        skewness: '偏度',
        kurtosis: '峰度',
        standardDeviation: '标准差',
        peakToPeak: '峰峰值',
        numberOfPoints: '显示点数',
        xAxisSettings: 'X轴设置',
        displayMethod: '显示方式',
        displayByTime: '按时间显示',
        displayByDate: '按日期时间显示',
        displayOnTimeMinutesAndSeconds: '按时分秒显示',
        displayBySeconds: '按秒显示',
        trajectoriesChart: '轨迹图',
        waveformChart: '波形图',
        rawSampling: '原始采样',
        synthesisRate: '合成率',
        statisticInfo: '统计信息',
        absoluteTime: '绝对时间',
        relativeTime: '相对时间',
        noCursor: '无光标',
        singleCursor: '单光标',
        doubleCursor: '双光标',
        threeLightMarkers: '三光标',
        fourCursors: '四光标',
        peakCursor: '峰值光标',
        valleyCursor: '谷值光标',
        edgeFrequencyCursor: '边频光标',
        areaCursor: '区域光标',
        navigationCursor: '导航光标',
        dualNavigationCursor: '双导航光标',
        markCursor: '标记光标',
        barChartType: '棒图类型',
        barChart: '棒图',
        fixedViewColumns: '固定视图列数',
        numberOfViewColumns: '视图列数',
        scaleType: '刻度类型',
        byRange: '按量程',
        custom: '自定义',
        scaleRange: '刻度范围',
        offlineCannotExceedOnline: '下限不能超过上限',
        coordinatePoints: '坐标点',
        waveformCursor: '波形图光标',
        trajectoryCursor: '轨迹图光标',
        cursorFollow: '光标跟随',
        characteristicValueList: '特征值列表',
        theMeasurementPointNameCannotContain: '测点名称中不能包含',
        differentSamplingRatesCheck: '当前测点采样频率和已经勾选的测点不相同，点击确认将会删掉勾选的测点，确认勾选吗？',
        differentSamplingRatesCheckSameSamplingPoints: '测点采样频率不一致，全选会自动勾选相同采样频率的测点，确认勾选吗？',
        fundamentalFrequency: '基频',
        objectIsSame: '为了判断对象是否相同',
        output: '输出',
        move: '移除',
        realCepstrum: '实倒谱',
        complexCepstrum: '复倒谱',
        dataInput: '数据输入',
        pointData: '测点数据',
        externalData: '外部数据',
        uploadMsg: '将文件拖拽至此处，或',
        uploadClick: '点击上传',
        decimalPlaces: '小数位数',
        numberOfSegmentedSegments: '分割段数',
        fontSize: '字体大小',
        dashboard: '仪表盘',
        displayType: '显示类型',
        oneQuarter: '四分之一',
        half: '二分之一',
        threeQuarter: '四分之三',
        circular: '圆形',
        deviceSelection: '设备选择',
        pointSelection: '测点选择',
        algorithmParameters: '算法参数',
        fixedDisplayDigits: '固定显示位数',
        displayNumberOfDigits: '显示位数',
        showSubtitles: '显示子标题',
        point: '测点',
        eigenvalue: '特征值',
        unit: '单位',
        numberOfCircles: '圆圈数量',
        rainFlowType: '雨流类型',
        oneDimensionalRainFlowCounting: '一维雨流计数',
        twoDimensionalRainFlowCounting: '二维雨流计数',
        alarmLine: '报警线',
        cepstrumAnalysisAndCorrelationAnalysisOfSpectralParameters: '倒谱分析和相关分析图谱参数，X轴为时间，但是以数字形式展示的图谱参数配置',
        xAxisType: 'X轴类型',
        yAxisType: 'Y轴类型',
        referenceValueType: '参考值类型',
        referenceValue: '参考值',
        linearCoordinates: '线性坐标',
        logarithmicCoordinates: '对数坐标',
        coordinates: '坐标',
        soundPressure: '声压',
        acceleration: '加速度',
        speed: '速度',
        displacement: '位移',
        power: '力',
        theReferenceValueMustBeGreaterThan: '参考值必须大于',
        inputPoint: '输入测点',
        selectTheStatisticalDurationAndCharacteristicValues: '请选择统计时长与特征值',
        statisticalDuration: '统计时长',
        minute: '分钟',
        hour: '小时',
        date: '天',
        cumulativeValue: '累计值',
        theNumberOfIntervalsMustBe: '区间个数必须是',
        positiveInteger: '正整数',
        groupNumber: '组号',
        unchecked: '未勾选',
        give: '与',
        validValue: '有效值',
        variance: '方差',
        kurtosisCoefficient: '峭度系数K',
        marginCoefficient: '裕度系数L',
        pulseFactor: '脉冲因数I',
        crestFactor: '波峰因数CF',
        waveformFactor: '波形因数S',
        absolutePeak: '绝对峰值',
        equivalentPeakValue: '等效峰值',
        latestValue: '最新值',
        skewness: '歪度',
        checkTheInputMeasurementPoint: '请勾选输入测点',
        checkFeatureValues: '请勾选特征值',
        formulaIsEmptyOrUnresolved: '公式为空或未解析',
        noMeasurementPointsEntered: '没有输入测点',
        selectCharacteristicValue: '请选择特征值',
        selectTheInputMeasurementPoint: '请选择输入测点',
        waveform: '波形',
        frequencyCapping: '频率上限',
        lowerFrequency: '频率下限'
    },

    calendar: {
        Sun: '日',
        Mon: '一',
        Tue: '二',
        Wed: '三',
        Tur: '四',
        Fri: '五',
        Sat: '六',

        JAN: '一月',
        FEB: '二月',
        MAR: '三月',
        APR: '四月',
        MAY: '五月',
        JUN: '六月',
        JUL: '七月',
        AUG: '八月',
        SEP: '九月',
        OCT: '十月',
        NOV: '十一月',
        DEC: '十二月',
        year: '年',
        month: '月',
        day: '日',
        week: '星期',

        title: '报警详情',
        path: '设备路径',
        alarmLevleStr: '报警等级',
        alarmTypeStr: '报警类型',
        alarmModelStr: '报警分类',
        confirmDegreeStr: '严重程度',
        openTitle: '报警开关状态'
    },
    copyRight: {
        title: '版权信息',
        edition: '软件版本：',
        authorizedVersion: '授权版本：',
        authorizationTime: '授权时间：',
        day: '天',
        unlimited: '无限制',
        availableDuration: '可用时长：',
        timeRange: '©2019-现在',
        companyName: '江苏东华测试技术股份有限公司',
        allRightsReserved: '保留所有权利',
        address: '地址：中国江苏省靖江市新港大道208号（214500）',
        tel: '电话：',
        email: '邮箱：',
        website: '网址：',
        waringmsg1: '警告：本计算机程序受著作权法和国际条约保护。如未经授权而擅自复制或传播本程序（或其中任何部分），将受到严厉的民事和刑事制裁，',
        waringmsg2: '并将在法律许可的最大限度内受到起诉。',
        driver: "驱动版本："
    },
    editBox: {
        emptyAlert: '请先输入名称',
        nameLimit: '名字不得超过100个字符',
        nameSpaceTip: '名字不能为空',
        contentLimit: '内容不得超过10000个字符',
        commaTip: '名字不能包括,'
    },
    modeBase: {
        graphType: '图谱类型：',
        outputMeasurementPoints: '输出测点：',
        noModel: '暂无模型',
        delMsg: '确认删除该模型吗',
        delPoint: '确认删除该测点吗'
    },
    pointChoose: {
        newPoint: '新增测点',
        deviceList: '设备列表',
        organization: '组织结构',
        pointList: '测点列表',
        patrolPoint: '巡检测点',
        noPoint: '暂无测点',
    },
    warningLicense: {
        text: '当前系统尚未获取授权，请重试或',
        link: '立即前往',
    },
    ampAnalysis: {
        probabilityStatistics: '概率统计',
        probabilityDensity: '概率密度',
        cumulativeProbability: '累计概率',
    },
    cableForceAlgorithm: {
        fundamentalFrequency: '基频',
        cableForce: '索力',
        trend: '趋势',
        spectrum: '频谱',
        establishPointMsg: '请建立测点',
        archBridge: '拱桥',
        cableStayedBridge: '斜拉桥',
        suspensionBridgeMain: '悬索桥主索',
        suspensionBridgeSling: '悬索桥吊索',
    },
    correlation: {
        noCorrection: '不修正',
        biasedEstimation: '有偏估计',
        unbiasedEstimation: '无偏估计',
        normalization: '归一化',
    },
    envelopeJS: {
        upperEnvelope: '上包络',
        lowerEnvelope: '下包络',
    },
    fftGraph: {
        realtimeSpectrum: '实时谱',
        averageSpectrum: '平均谱',
        amplitudeSpectrum: '幅值谱',
        effectiveValueSpectrum: '有效值谱',
        powerSpectrum: '功率谱',
        RMSPowerSpectrum: '有效值功率谱',
        powerSpectralDensity: '功率谱密度',
        RMSPowerSpectralDensity: '有效值功率谱密度',
        radicalPowerSpectralDensity: '根号功率谱密度',
        undividedRectum: '无隔直',
        linearAverage: '线性平均',
        peakHold: '峰值保持',
        exponentialAverage: '指数平均',
    },
    orderAlgorithm: {
        oreder: '阶次',
    },
    rainflowAnalysis: {
        conventionalCount: '常规雨流计数',
    },
    trendForecast: {
        establishPointMsg: '请建立测点',
    },
    waveAnalysisJS: {
        title: '波形分析',
    },
    alarmCalendarJS: {
        title: '报警日历',
    },
    alarmRecordJS: {
        title: '报警记录',
    },
    alarmStatusJS: {
        title: '报警状态',
    },
    countStaticsJS: {
        title: '数量统计',
        abnormalData: '异常数据',
        pointNum: '测点数量',
        onlineNum: '在线数量',
        offlineNum: '离线数量',
        dailyAccrual: '日累计',
        monthlyAccrual: '月累计',
        total: '总累计',
    },
    healthStatusJS: {
        title: '健康度',
        healthy: '健康',
        fault: '故障',
    },
    histogramJS: {
        title: '柱状图',
    },
    imageComponentJS: {
        title: '图片',
    },
    lineChartJS: {
        title: '折线图',
        seriesName: '故障统计',
        seriesName2: '巡检报警统计'
    },
    mapComponentJS: {
        title: '北京',
        componentTitle: '地图',
        addressMsg: '请输入地址',
    },
    pieChartComponentJS: {
        title: '报警等级统计',
        levelHealthy: '健康',
        levelNormal: '正常',
        levelWarning: '高报',
        levelDanger: '高高报',
        offline: '离线',
        online: '在线',
        seriesName: '测点设备统计',
        total: "总计"
    },
    reportComponentJS: {
        title: '报表',
    },
    runHealthJS: {
        title: '运行状况',
    },
    statusStatisticsJS: {
        title: '健康状态',
    },
    threeDComponentJS: {
        title: '3D模型',
        noMultipleModelsMsg: '不能选择多个模型',
        requireModelMsg: '必须选择模型文件',
        loading: "模型解析中..."
    },
    titleComponentJS: {
        title: '基于故障诊断和健康管理的智能设备维保管理平台',
    },
    trendStatisJS: {
        title: '趋势统计',
    },
    videoComponentJS: {
        title: '视频监控',
        accessTokenError: '获取accessToken失败！',
        ezopenError: "萤石云接口调用失败，请检查网络！",
        deviceSerialCannotBeNull: "设备序列号不能为空！",
        appKeyCannotBeNull: "AppKey不能为空！",
        appSecretCannotBeNull: "AppSecret不能为空！",
        channelNoCannotBeNull: "通道号不能为空！",
        secretKeyCannotBeNull: "加密状态下密钥不能为空！",
    },
    axisPosition: {
        title: '轴心位置图',
        horizontal: '水平方向',
        vertical: '垂直方向',
    },
    axisTrail: {
        title: '轴心轨迹图',
        waveGraphTitle: '波形图',
    },
    barGraph: {
        title: '棒图',
    },
    bodePlotsGraph: {
        title: '伯德图',
        amplitude: '幅值',
        phase: '相位'
    },
    cascadeGraph: {
        title: '级联图',
    },
    dashBoard: {
        title: '仪表盘',
    },
    numberTable: {
        title: '数字表',
    },
    panelGraph: {
        title: '面板',
        componentBackgroundColor: '组件背景颜色',
        numBackgroundColor: '数值背景颜色',
        numColor: '数值颜色',
        textColor: '文本颜色',
        textSize: '文本字号大小',
        numSize: '数值字号大小',
        followTheme: "跟随主题设置",
        deviceFilter: "设备筛选",
        showMode: '显示模式',
        upDown: '上下模式',
        leftRight: '左右模式'
    },
    polarGraph: {
        title: '极坐标图',
    },
    spectrumGraph: {
        title: '频谱图',
    },
    torvib: {
        title: '扭振监测',
        torsionalVibrationMode: '直联轴系扭振振型',
        frequencyValue: '频率值',
    },
    trendGraph: {
        title: '趋势图',
        establishPointMsg: '请建立测点',
        trendData: '趋势图数据',
    },
    waterfallGraph: {
        title: '瀑布图',
    },
    waveGraph: {
        title: '波形图',
        upperEnvelope: '上包络',
        lowerEnvelope: '下包络',
        noQueryData: '查询不到数据',
    },
    recordGraph: {
        title: '2D图谱',
    },
    wholePeriodGraph: {
        title: '整周期波形图',
        circle: '圈',
    },
    labels: {
        maxGraphNumMsg: '最多添加32个图谱',
    },
    svgOperator: {
        newPoint: '新增测点',
    },
    appearance: {
        showTitle: "显示组件标题栏",
        titleAlign: "标题对齐方式",
        showBorder: "显示组件边框",
        left: "居左",
        center: "居中",
        showLine: "显示标签连线",
        showDot: "显示标签点",
        useUserLayout: "组件布局",
        shared: "共享",
        user: "按用户",
        changeLayoutMsg: "改变组件布局会导致现有布局变化，是否确认切换？",
        cursorPixels: "光标校准点数",
        pixels: "像素",
        alarmConfig: "报警设置",
        valueAlarm: "数值报警",
        statusAlarm: "状态报警(手动结束)",
    },
    udp165: {
        serviceSetting: "服务配置",
        sending: "发送频率",
        systemCode: "系统编码",
        unit: "次/s",
        latestValue: "最新值",
        systemCodeTip: "系统编码只支持整数",
        systemCodeLimitTip: "系统编码最大为2147483647",
    },
    systemMode: {
        title: '系统模式',
        userMode: '用户模式',
        allMode: '全局模式',
    },
    faultCaseLibrary: {
        title: '故障案例库',
        caseLibrary: '案例库',
        joinCase: '确定要加入案例库吗？',
        reportTaskName: '报告任务名称',
    },
    timing: {
        title: '计时',
        fontSize: '文字字号大小',
        numSize: '数值字号大小',
        timingSetting: '计时设置',
        autonomousTiming: '自主计时',
        notTimed: '不计时',
        timingStartValue: '计时起始值',
        selectPointTip: '请选择测点',
        selectEigenTip: '请选择特征值',
        showName: '显示计时名称',
        cumulative: '是否累计',
        reset: '重置',
        S: '秒',
        M: '分',
        H: '时',
        D: '天',
        MS: '分:秒',
        HMS: '时:分:秒',
        HM: '时:分',
        DHMS: '天 时:分:秒',
        DHM: '天 时:分',
        DH: '天 时',
    },
    titleGraph: {
        title: '标题',
        titleText: '标题文本',
        backgroundColor: '背景颜色',
        textColor: '文字颜色',
        fontSize: '文字字号大小'
    },
    valveStatus: {
        title: '阀门状态',
        name: '阀门名称',
        fontSize: '文字字号大小',
        controlMode: '控制模式',
        singleChannel: '单通道',
        dualChannel: '双通道',
        controlParameters: '控制参数',
        channel1: '通道一',
        channel2: '通道二',
        selectPointTip: '请选择通道测点和特征值',
        pointDefferent: '请选择不同的关联测点',
        panelValve: '阀门面板'
    },
    valvePanelStatus: {
        title: '阀门状态面板',
        list: "阀门列表",
        param: "阀门参数",
        colorSet: "是否单独设置颜色",
        name: "阀门",
        repeatTip: "阀门名称不能有重复"
    },
    graphicalDisplay: {
        title: '图形显示'
    },
    tableGraph: {
        rowNums: '表格行数',
        colNums: '表格列数',
        alignType: '对齐方式',
    },
    alarmValid: {
        mutationAlarmTip: '参考值必须按照报警等级依次增加',
        ampMoreTip: '超限参考值必须按照报警等级依次增加',
        ampLessTip: '低限参考值必须按照报警等级依次减少',
        inWindowTip: '窗内幅值报警参数应满足',
        outWindowTip: '窗外幅值报警参数应满足',
        overLimit: '上限',
        belowLimit: '下限'
    },
    alarmLevelConfig: {
        addLevel: "添加等级",
        levelTip: "部分功能需要刷新页面才能更新报警颜色",
        severity: "严重程度",
        severityTip: "数值越大严重程度越高",
        levelName: "等级名称",
        alarmColor: "报警颜色",
        soundFile: "声音文件",
        preview: "试听",
    },
    departSetting: {
        title: "设备树设置",
        departType: "部门类型",
        addDeartType: "新增部门类型",
        editDepartType: "编辑部门类型",
        association: "关联类型",
        departTip: "所选节点已全部存在"
    },
    monitoring: {
        title: '监测范围',
        statisticsNodes: "统计节点",
        textBackground: "文本背景颜色",
        associateNodes: "关联节点",
        showName: "显示名称",
        nodeRepeatTip: "关联节点有重复",
        nameRepeatTip: "显示名称有重复",
        nodeBlankTip: "请选择关联节点",
        nameBlankTip: "请填写显示名称",
        unitBlankTip: "请填写单位",
        limitTip: "统计节点最多添加100个",
        inputLimitTip: "最多10个字符",
        statisticalItems: "统计项",
        selectStatisticalTip: "请选择统计项",
        pointNum: "测点总数",
        deviceSuffix: "类设备"
    },
    deviceLevel: {
        title: "设备等级",
        addDeviceLevel: "添加设备等级",
        editDeviceLevel: "编辑设备等级",
    },
    deviceDepartSetting: {
        title: "部门/设备配置",
        departLable: "部门信息显示部门类型",
        deviceLable: "设备信息显示设备等级",
        association: "关联类型"
    },
    layout: {
        align: "对齐",
        leftAligned: "左对齐",
        horizontallyCentered: "水平居中",
        rightAligned: "右对齐",
        horizontallyDistribution: "水平分布",
        topAlignment: "顶对齐",
        verticallyCentered: "垂直居中",
        bottomAlignment: "底对齐",
        verticallyDistribution: "垂直分布",
        matching: "匹配",
        widthMatching: "宽度匹配",
        heightMatching: "高度匹配",
        widthHeightMatching: "宽高匹配",
        layer: "图层",
        change: "变换",
        layout: "布局"
    }
}

export default CN