import { GrpcWebFetchTransport } from "@protobuf-ts/grpcweb-transport";
import { alertErrorMsg } from "@common/js/utils";
import { DataClient } from "./ts/protos/data.client";
import { AlarmClient } from "./ts/protos/alarm.client";
import { ComponentClient } from "./ts/protos/component.client";
import { GrpcFaultClient as FaultClient } from "./ts/protos/fault.client";
import { GetRangeWaveDatasRequest, RangeWaveDatasReply } from "./ts/protos/rangewavedatas";
import { GetDatasRequest, GetDatasReply } from "./ts/protos/getdatas";
import { GetFirstWaveDataRequest, GetFirstWaveDataReply } from "./ts/protos/firstwavedata";
import { QueryLatestWaveDataRequest } from "./ts/protos/latestwavedata";
import { QueryLatestEigenDatasRequest } from "./ts/protos/latesteigendatas";
import { CalculateWaveDataRequest, CalculateWaveDataReply } from "./ts/protos/calculatewavedata";
import { GetNearestRotateSpeedRequest, GetNearestRotateSpeedReply } from "./ts/protos/nearestrotatespeed";
import { QueryAlarmPointsEigensDatasRequest, QueryAlarmPointsEigensDatasReply } from "./ts/protos/alarmpointseigensdatas";
import { QueryAlarmPointsEigenDatasRequest, QueryAlarmPointsEigenDatasReply } from "./ts/protos/alarmpointseigendatas";
import { QueryAlarmPointsWaveDatasRequest, QueryAlarmPointsWaveDatasReply } from "./ts/protos/alarmpointswavedatas";
import { QueryAlarmPointsRangeWaveDatasRequest, QueryAlarmPointsRangeWaveDatasReply } from "./ts/protos/alarmpointsrangewavedatas";
import { QueryBandEnergyAlarmDatasRequest, QueryBandEnergyAlarmDatasReply } from "./ts/protos/bandenergyalarmdatas";
import { QueryNearestPointsWaveDatasRequest, QueryNearestPointsWaveDatasReply } from "./ts/protos/nearestpointswavedatas";
import { QueryNearestPointsEigenDatasRequest, QueryNearestPointsEigenDatasReply } from "./ts/protos/nearestpointseigendatas";
import { QueryAlarmDatasWithLimitRequest, QueryAlarmDatasWithLimitReply } from "./ts/protos/alarmdataswithlimit";
import { CalculateAlarmNearestWaveDataRequest, CalculateAlarmNearestWaveDataReply } from "./ts/protos/calculatealarmnearestwavedata";
import { GetFrGraphDataRequest, GetFrGraphDataReply } from "./ts/protos/frgraphdata";
import { GetFIRGraphDataRequest, GetFIRGraphDataReply } from "./ts/protos/firgraphdata";
import { GetOnceIntegralGraphDataRequest, GetOnceIntegralGraphDataReply } from "./ts/protos/onceIntegralgraphdata";
import { GetTwiceIntegralGraphDataRequest, GetTwiceIntegralGraphDataReply } from "./ts/protos/twiceintegralgraphdata";
import { GetOnceDifferentialGraphDataRequest, GetOnceDifferentialGraphDataReply } from "./ts/protos/oncedifferentialgraphdata";
import { GetEnvelopGraphDataRequest, GetEnvelopGraphDataReply } from "./ts/protos/envelopgraphdata";
import { GetEnvelopSpecGraphDataRequest, GetEnvelopSpecGraphDataReply } from "./ts/protos/envelopspecgraphdata";
import { GetTwiceDifferentialGraphDataRequest, GetTwiceDifferentialGraphDataReply } from "./ts/protos/twicedifferentialgraphdata";
import { GetGraphDatasRequest, GetGraphDatasReply } from "./ts/protos/graphdatas";
import { GetGraphNearestDatasRequest, GetGraphNearestDatasReply } from "./ts/protos/graphnearestdatas";
import { GetGraphFirstDatasRequest, GetGraphFirstDatasReply } from "./ts/protos/graphfirstdatas";
import { GetParamTwoPointDatasRequest, GetParamTwoPointDatasReply } from "./ts/protos/paramtwopointdatas";
import { GetParamTwoPointNearestDatasRequest, GetParamTwoPointNearestDatasReply } from "./ts/protos/paramtwopointnearestdatas";
import { GetParamTwoPointFirstDatasRequest } from "./ts/protos/paramtwopointfirstdatas";
import { QueryHistoryDiluteDatasRequest, QueryHistoryDiluteDatasReply, QueryNavDiluteDatasRequest, QueryNavDiluteDatasReply } from "./ts/protos/dilutedatas";
import { GetTypicalDataRequest } from "./ts/protos/typicaldata";
import { GetBodePlotDatasReply, GetPolarDatasReply, GetAxisDatasReply } from "./ts/protos/axispositionhistorydatareply";
import { GetTorvibGraphDatasRequest, GetTorvibDatasReply } from "./ts/protos/torvibgraphdatas";
import { HistoryHealthDatasRequest, HistoryHealthReply } from "./ts/protos/historyhealthdatas";
import { parseHistoryDilutionDataReply, parseHistoryPointDataReply, processAxisTrailDataReply, processBodeDataReply, processEnvelopPointDatasReply, processHistoryPointDatasReply, processPolarDataReply, processTorvibHistoryDataReplay } from "./grpcDataParserContext";
import store from "@common/js/store";
import { AlgorithmTypeEnum } from "@components/js/utils/model";

/** 将JSON的全部键值转为首字母大写 */
export const jsonToUpperCase = (obj) => {
    const upper = (inputObj) => {
        if (typeof inputObj !== 'object' || !inputObj) {
            return inputObj;
        }

        const outputObj = {};

        if (Array.isArray(inputObj)) {
            return inputObj.map(e => upper(e));
        } else {
            Object.keys(inputObj).forEach((key) => {
                const upperKey = key.replace(key[0], key[0].toUpperCase());
                if (typeof inputObj[key] === 'object') {
                    outputObj[upperKey] = upper(inputObj[key])
                } else {
                    outputObj[upperKey] = inputObj[key];
                }
            })
        }

        return outputObj;
    }
    return upper(obj);
}

export const withGrpcRequest = (param, rpcOption = {}) => {
    rpcOption.meta = {
        'Accept-Language': store.state.lang === 'CN' ? 'zh-Hans' : 'EN'
    }
    return (requestEntity, replyEntity, requestFunc, processResultFunc = undefined) => {
        const requestParam = requestEntity.fromJson(param);
        return new Promise((resolve) => {
            requestFunc(requestParam, rpcOption)
                .then((res) => {
                    const { response } = res;
                    if (response.isSuccess) {
                        resolve(processResultFunc ? processResultFunc(replyEntity.fromBinary(response.content.value)) : replyEntity.fromBinary(response.content.value));
                    } else {
                        alertErrorMsg(response.errorMessage);
                        console.error("grpc_interface|error:", response.errorMessage);
                        resolve([]);
                    }
                })
                .catch((err) => {
                    alertErrorMsg(err);
                    console.error("grpc_client|error:", err);
                    resolve([]);
                });
        });
    }
}

const processRangeWaveResult = (response) => {
    const datas = [];
    response.points.forEach(pointData => {
        if (pointData.dspType == AlgorithmTypeEnum.EnvelopeAnalysis) {
            datas.push(...processEnvelopPointDatasReply(pointData));
        } else {
            datas.push(...processHistoryPointDatasReply(pointData));
        }
    });
    return datas;
}

const processHistoryPointDataResult = (response) => {
    return parseHistoryPointDataReply(response?.points);
}

const processHistoryDilutionDataResult = (response) => {
    return parseHistoryDilutionDataReply(response?.points);
}

const processPreviewWaveDataResult = (response) => {
    return parseHistoryPointDataReply(response?.datas);
}

const processIIRPreviewWaveDataResult = (response) => {
    if (!response) return;
    const { datas, ...rest } = response;
    return {
        Datas: parseHistoryPointDataReply(response.datas),
        ...jsonToUpperCase(rest),
    }
}

const processHistoryHealthDataResult = (response) => {
    return response;
}

export class GrpcApiService {
    constructor() {
        this.dataClient = new DataClient(
            new GrpcWebFetchTransport({
                baseUrl: Config.grpc,
            })
        );
        this.alarmClient = new AlarmClient(
            new GrpcWebFetchTransport({
                baseUrl: Config.grpc,
            })
        );
        this.componentClient = new ComponentClient(
            new GrpcWebFetchTransport({
                baseUrl: Config.grpc,
            })
        );
        this.faultClient = new FaultClient(
            new GrpcWebFetchTransport({
                baseUrl: Config.grpc,
            })
        );
    }

    /** DataManage模块接口 */
    async getDatas(param) {
        return withGrpcRequest(param)(
            GetDatasRequest,
            GetDatasReply,
            (...args) => this.dataClient.getDatas.apply(this.dataClient, args),
            processHistoryPointDataResult
        );
    }
    async getEigenDatasByLowerlimit(param) {
        return withGrpcRequest(param)(
            GetDatasRequest,
            GetDatasReply,
            (...args) => this.dataClient.getEigenDatasByLowerlimit.apply(this.dataClient, args),
            processHistoryPointDataResult
        );
    }
    async queryLatestEigenDatas(param) {
        return withGrpcRequest(param)(
            QueryLatestEigenDatasRequest,
            GetDatasReply,
            (...args) => this.dataClient.queryLatestEigenDatas.apply(this.dataClient, args),
            processHistoryPointDataResult
        );
    }
    async getFirstWaveData(param) {
        return withGrpcRequest(param)(
            GetFirstWaveDataRequest,
            GetFirstWaveDataReply,
            (...args) => this.dataClient.getFirstWaveData.apply(this.dataClient, args),
            processHistoryPointDataResult
        );
    }
    async queryLatestWaveData(param) {
        return withGrpcRequest(param)(
            QueryLatestWaveDataRequest,
            GetFirstWaveDataReply,
            (...args) => this.dataClient.queryLatestWaveData.apply(this.dataClient, args),
            processHistoryPointDataResult
        );
    }
    async getLastWaveData(param) {
        return withGrpcRequest(param)(
            GetFirstWaveDataRequest,
            GetFirstWaveDataReply,
            (...args) => this.dataClient.getLastWaveData.apply(this.dataClient, args),
            processHistoryPointDataResult
        );
    }
    async calculateWaveData(param) {
        return withGrpcRequest(param)(
            CalculateWaveDataRequest,
            CalculateWaveDataReply,
            (...args) => this.dataClient.calculateWaveData.apply(this.dataClient, args),
            processHistoryPointDataResult
        );
    }
    async getNearestRotateSpeed(param) {
        return withGrpcRequest(param)(
            GetNearestRotateSpeedRequest,
            GetNearestRotateSpeedReply,
            (...args) => this.dataClient.getNearestRotateSpeed.apply(this.dataClient, args),
            jsonToUpperCase
        );
    }
    async queryDatasWithLimit(param) {
        return withGrpcRequest(param)(
            CalculateWaveDataRequest,
            CalculateWaveDataReply,
            (...args) => this.dataClient.queryDatasWithLimit.apply(this.dataClient, args),
            processHistoryPointDataResult
        );
    }
    async getNearestEigenData(param) {
        return withGrpcRequest(param)(
            GetDatasRequest,
            GetDatasReply,
            (...args) => this.dataClient.getNearestEigenData.apply(this.dataClient, args),
            processHistoryPointDataResult
        );
    }
    async getRangeWaveDatas(param) {
        return withGrpcRequest(param)(
            GetRangeWaveDatasRequest,
            RangeWaveDatasReply,
            (...args) => this.dataClient.getRangeWaveDatas.apply(this.dataClient, args),
            processRangeWaveResult
        );
    }
    async queryHistoryDiluteDatas(param) {
        return withGrpcRequest(param)(
            QueryHistoryDiluteDatasRequest,
            QueryHistoryDiluteDatasReply,
            (...args) => this.dataClient.queryHistoryDiluteDatas.apply(this.dataClient, args),
            processHistoryDilutionDataResult,
        );
    }
    async queryNavDiluteDatas(param) {
        return withGrpcRequest(param)(
            QueryNavDiluteDatasRequest,
            QueryNavDiluteDatasReply,
            (...args) => this.dataClient.queryNavDiluteDatas.apply(this.dataClient, args),
            processHistoryDilutionDataResult,
        );
    }

    /** Alarm模块接口 */
    async queryAlarmPointsEigensDatas(param) {
        return withGrpcRequest(param)(
            QueryAlarmPointsEigensDatasRequest,
            QueryAlarmPointsEigensDatasReply,
            (...args) => this.alarmClient.queryAlarmPointsEigensDatasAsync.apply(this.alarmClient, args),
            processHistoryPointDataResult,
        );
    }
    async queryAlarmEigensDatas(param) {
        return withGrpcRequest(param)(
            QueryAlarmPointsEigensDatasRequest,
            QueryAlarmPointsEigensDatasReply,
            (...args) => this.alarmClient.queryAlarmEigensDatasAsync.apply(this.alarmClient, args),
            processHistoryPointDataResult,
        );
    }
    async queryFirstAlarmEigenDatas(param) {
        return withGrpcRequest(param)(
            QueryAlarmPointsEigenDatasRequest,
            QueryAlarmPointsEigenDatasReply,
            (...args) => this.alarmClient.queryFirstAlarmEigenDatas.apply(this.alarmClient, args),
            processHistoryPointDataResult,
        );
    }
    async queryAlarmPointsWaveDatas(param) {
        return withGrpcRequest(param)(
            QueryAlarmPointsWaveDatasRequest,
            QueryAlarmPointsWaveDatasReply,
            (...args) => this.alarmClient.queryAlarmPointsWaveDatasAsync.apply(this.alarmClient, args),
            processHistoryPointDataResult,
        );
    }
    async queryAlarmPointsRangeWaveDatas(param) {
        return withGrpcRequest(param)(
            QueryAlarmPointsRangeWaveDatasRequest,
            QueryAlarmPointsRangeWaveDatasReply,
            (...args) => this.alarmClient.queryAlarmPointsRangeWaveDatasAsync.apply(this.alarmClient, args),
            processRangeWaveResult,
        );
    }
    async queryBandEnergyWaveData(param) {
        return withGrpcRequest(param)(
            QueryBandEnergyAlarmDatasRequest,
            QueryBandEnergyAlarmDatasReply,
            (...args) => this.alarmClient.queryBandEnergyWaveDataAsync.apply(this.alarmClient, args),
            processRangeWaveResult,
        );
    }
    async queryNearestPointsWaveDatas(param) {
        return withGrpcRequest(param)(
            QueryNearestPointsWaveDatasRequest,
            QueryNearestPointsWaveDatasReply,
            (...args) => this.alarmClient.queryNearestPointsWaveDatas.apply(this.alarmClient, args),
            processHistoryPointDataResult,
        );
    }
    async queryNextPointsWaveDatas(param) {
        return withGrpcRequest(param)(
            QueryNearestPointsWaveDatasRequest,
            QueryNearestPointsWaveDatasReply,
            (...args) => this.alarmClient.queryNextPointsWaveDatas.apply(this.alarmClient, args),
            processHistoryPointDataResult,
        );
    }
    async queryPrePointsWaveDatas(param) {
        return withGrpcRequest(param)(
            QueryNearestPointsWaveDatasRequest,
            QueryNearestPointsWaveDatasReply,
            (...args) => this.alarmClient.queryPrePointsWaveDatas.apply(this.alarmClient, args),
            processHistoryPointDataResult,
        );
    }
    async queryNearestPointsEigenDatas(param) {
        return withGrpcRequest(param)(
            QueryNearestPointsEigenDatasRequest,
            QueryNearestPointsEigenDatasReply,
            (...args) => this.alarmClient.queryNearestPointsEigenDatas.apply(this.alarmClient, args),
            processHistoryPointDataResult,
        );
    }
    async queryNextPointsEigenDatas(param) {
        return withGrpcRequest(param)(
            QueryNearestPointsEigenDatasRequest,
            QueryNearestPointsEigenDatasReply,
            (...args) => this.alarmClient.queryNextPointsEigenDatas.apply(this.alarmClient, args),
            processHistoryPointDataResult,
        );
    }
    async queryPrePointsEigenDatas(param) {
        return withGrpcRequest(param)(
            QueryNearestPointsEigenDatasRequest,
            QueryNearestPointsEigenDatasReply,
            (...args) => this.alarmClient.queryPrePointsEigenDatas.apply(this.alarmClient, args),
            processHistoryPointDataResult,
        );
    }
    async queryNearestAlarmDataWithLimit(param) {
        return withGrpcRequest(param)(
            QueryAlarmDatasWithLimitRequest,
            QueryAlarmDatasWithLimitReply,
            (...args) => this.alarmClient.queryNearestAlarmDataWithLimit.apply(this.alarmClient, args),
            processHistoryPointDataResult,
        );
    }
    async queryNextAlarmDataWithLimit(param) {
        return withGrpcRequest(param)(
            QueryAlarmDatasWithLimitRequest,
            QueryAlarmDatasWithLimitReply,
            (...args) => this.alarmClient.queryNextAlarmDataWithLimit.apply(this.alarmClient, args),
            processHistoryPointDataResult,
        );
    }
    async queryPreAlarmDataWithLimit(param) {
        return withGrpcRequest(param)(
            QueryAlarmDatasWithLimitRequest,
            QueryAlarmDatasWithLimitReply,
            (...args) => this.alarmClient.queryPreAlarmDataWithLimit.apply(this.alarmClient, args),
            processHistoryPointDataResult,
        );
    }
    async calculateAlarmNearestWaveData(param) {
        return withGrpcRequest(param)(
            CalculateAlarmNearestWaveDataRequest,
            CalculateAlarmNearestWaveDataReply,
            (...args) => this.alarmClient.calculateAlarmNearestWaveData.apply(this.alarmClient, args),
            processHistoryPointDataResult,
        );
    }
    async calculateAlarmNextWaveData(param) {
        return withGrpcRequest(param)(
            CalculateAlarmNearestWaveDataRequest,
            CalculateAlarmNearestWaveDataReply,
            (...args) => this.alarmClient.calculateAlarmNextWaveData.apply(this.alarmClient, args),
            processHistoryPointDataResult,
        );
    }
    async calculateAlarmPreWaveData(param) {
        return withGrpcRequest(param)(
            CalculateAlarmNearestWaveDataRequest,
            CalculateAlarmNearestWaveDataReply,
            (...args) => this.alarmClient.calculateAlarmPreWaveData.apply(this.alarmClient, args),
            processHistoryPointDataResult,
        );
    }

    /** Graphs模块接口 */
    async getFrGraphData(param) {
        return withGrpcRequest(param)(
            GetFrGraphDataRequest,
            GetFrGraphDataReply,
            (...args) => this.componentClient.getFrGraphData.apply(this.componentClient, args),
            processIIRPreviewWaveDataResult
        );
    }
    async getFIRGraphData(param) {
        return withGrpcRequest(param)(
            GetFIRGraphDataRequest,
            GetFIRGraphDataReply,
            (...args) => this.componentClient.getFIRGraphData.apply(this.componentClient, args),
            processPreviewWaveDataResult
        );
    }
    async getOnceIntegralGraphData(param) {
        return withGrpcRequest(param)(
            GetOnceIntegralGraphDataRequest,
            GetOnceIntegralGraphDataReply,
            (...args) => this.componentClient.getOnceIntegralGraphData.apply(this.componentClient, args),
            processPreviewWaveDataResult
        );
    }
    async getTwiceIntegralGraphData(param) {
        return withGrpcRequest(param)(
            GetTwiceIntegralGraphDataRequest,
            GetTwiceIntegralGraphDataReply,
            (...args) => this.componentClient.getTwiceIntegralGraphData.apply(this.componentClient, args),
            processPreviewWaveDataResult
        );
    }
    async getOnceDifferentialGraphData(param) {
        return withGrpcRequest(param)(
            GetOnceDifferentialGraphDataRequest,
            GetOnceDifferentialGraphDataReply,
            (...args) => this.componentClient.getOnceDifferentialGraphData.apply(this.componentClient, args),
            processPreviewWaveDataResult
        );
    }
    async getEnvelopGraphData(param) {
        return withGrpcRequest(param)(
            GetEnvelopGraphDataRequest,
            GetEnvelopGraphDataReply,
            (...args) => this.componentClient.getEnvelopGraphData.apply(this.componentClient, args),
            processPreviewWaveDataResult
        );
    }
    async getEnvelopSpecGraphData(param) {
        return withGrpcRequest(param)(
            GetEnvelopSpecGraphDataRequest,
            GetEnvelopSpecGraphDataReply,
            (...args) => this.componentClient.getEnvelopSpecGraphData.apply(this.componentClient, args),
            processPreviewWaveDataResult
        );
    }
    async getTwiceDifferentialGraphData(param) {
        return withGrpcRequest(param)(
            GetTwiceDifferentialGraphDataRequest,
            GetTwiceDifferentialGraphDataReply,
            (...args) => this.componentClient.getTwiceDifferentialGraphData.apply(this.componentClient, args),
            processPreviewWaveDataResult
        );
    }

    async queryWholePeriodDatas(param) {
        return withGrpcRequest(param)(
            GetGraphDatasRequest,
            GetGraphDatasReply,
            (...args) => this.componentClient.queryWholePeriodDatas.apply(this.componentClient, args),
            processHistoryPointDataResult
        );
    }

    async queryLatestSpectralDatas(param) {
        return withGrpcRequest(param)(
            GetGraphDatasRequest,
            GetGraphDatasReply,
            (...args) => this.componentClient.queryLatestSpectralDatas.apply(this.componentClient, args),
            processHistoryPointDataResult
        );
    }

    async queryBodePlotsDatas(param) {
        return withGrpcRequest(param)(
            GetGraphDatasRequest,
            GetBodePlotDatasReply,
            (...args) => this.componentClient.queryBodePlotsDatas.apply(this.componentClient, args),
            processBodeDataReply
        );
    }
    async queryPolarDatas(param) {
        return withGrpcRequest(param)(
            GetGraphDatasRequest,
            GetPolarDatasReply,
            (...args) => this.componentClient.queryPolarDatas.apply(this.componentClient, args),
            processPolarDataReply
        );
    }
    //
    async queryWholePeriodAlarmNearestDatas(param) {
        return withGrpcRequest(param)(
            GetGraphNearestDatasRequest,
            GetGraphNearestDatasReply,
            (...args) => this.componentClient.queryWholePeriodAlarmNearestDatas.apply(this.componentClient, args),
            processHistoryPointDataResult
        );
    }
    async queryWholePeriodAlarmPreDatas(param) {
        return withGrpcRequest(param)(
            GetGraphNearestDatasRequest,
            GetGraphNearestDatasReply,
            (...args) => this.componentClient.queryWholePeriodAlarmPreDatas.apply(this.componentClient, args),
            processHistoryPointDataResult
        );
    }
    async queryWholePeriodAlarmNextDatas(param) {
        return withGrpcRequest(param)(
            GetGraphNearestDatasRequest,
            GetGraphNearestDatasReply,
            (...args) => this.componentClient.queryWholePeriodAlarmNextDatas.apply(this.componentClient, args),
            processHistoryPointDataResult
        );
    }
    async queryBodeAlarmDatas(param) {
        return withGrpcRequest(param)(
            GetGraphNearestDatasRequest,
            GetBodePlotDatasReply,
            (...args) => this.componentClient.queryBodeAlarmDatas.apply(this.componentClient, args),
            processBodeDataReply
        );
    }
    async queryPolarAlarmDatas(param) {
        return withGrpcRequest(param)(
            GetGraphNearestDatasRequest,
            GetPolarDatasReply,
            (...args) => this.componentClient.queryPolarAlarmDatas.apply(this.componentClient, args),
            processPolarDataReply
        );
    }
    async queryWholePeriodFirstData(param) {
        return withGrpcRequest(param)(
            GetGraphFirstDatasRequest,
            GetGraphFirstDatasReply,
            (...args) => this.componentClient.queryWholePeriodFirstData.apply(this.componentClient, args),
            processHistoryPointDataResult
        );
    }

    async queryAxisPositionDatas(param) {
        return withGrpcRequest(param)(
            GetParamTwoPointDatasRequest,
            GetParamTwoPointDatasReply,
            (...args) => this.componentClient.queryAxisPositionDatas.apply(this.componentClient, args),
            processHistoryPointDataResult
        );
    }
    async queryAxisPosAlarmDatas(param) {
        return withGrpcRequest(param)(
            GetParamTwoPointNearestDatasRequest,
            GetParamTwoPointNearestDatasReply,
            (...args) => this.componentClient.queryAxisPosAlarmDatas.apply(this.componentClient, args),
            processHistoryPointDataResult
        );
    }
    async queryAxisTrailAlarmNearestDatas(param) {
        return withGrpcRequest(param)(
            GetParamTwoPointNearestDatasRequest,
            GetAxisDatasReply,
            (...args) => this.componentClient.queryAxisTrailAlarmNearestDatas.apply(this.componentClient, args),
            processAxisTrailDataReply
        );
    }
    async queryAxisTrailAlarmPreDatas(param) {
        return withGrpcRequest(param)(
            GetParamTwoPointNearestDatasRequest,
            GetAxisDatasReply,
            (...args) => this.componentClient.queryAxisTrailAlarmPreDatas.apply(this.componentClient, args),
            processAxisTrailDataReply
        );
    }
    async queryAxisTrailAlarmNextDatas(param) {
        return withGrpcRequest(param)(
            GetParamTwoPointNearestDatasRequest,
            GetAxisDatasReply,
            (...args) => this.componentClient.queryAxisTrailAlarmNextDatas.apply(this.componentClient, args),
            processAxisTrailDataReply
        );
    }
    async queryAxisTrailFirstData(param) {
        return withGrpcRequest(param)(
            GetParamTwoPointFirstDatasRequest,
            GetAxisDatasReply,
            (...args) => this.componentClient.queryAxisTrailFirstData.apply(this.componentClient, args),
            processAxisTrailDataReply
        );
    }
    async queryTorvibDatas(param) {
        return withGrpcRequest(param)(
            GetTorvibGraphDatasRequest,
            GetTorvibDatasReply,
            (...args) => this.componentClient.queryTorvibDatas.apply(this.componentClient, args),
            processTorvibHistoryDataReplay
        );
    }

    /** Fault模块接口 */
    async getTypicalData(param) {
        return withGrpcRequest(param)(
            GetTypicalDataRequest,
            GetDatasReply,
            (...args) => this.faultClient.getTypicalData.apply(this.faultClient, args),
            processHistoryPointDataResult
        );
    }

    /** 健康度历史模块接口 */
    async queryHistoryHealthDatasAsync(param) {
        return withGrpcRequest(param)(
            HistoryHealthDatasRequest,
            HistoryHealthReply,
            (...args) => this.componentClient.queryHistoryHealthDatasAsync.apply(this.componentClient, args),
            processHistoryHealthDataResult
        );
    }
}

export class GrpcApiContext {
    static _instance = null;
    static GetInstance() {
        if (GrpcApiContext._instance == null) {
            GrpcApiContext._instance = new GrpcApiService();
        }
        return GrpcApiContext._instance;
    }
}
