export class DotImageResourceContext {
    static _instance = null;
    static GetInstance() {
        if (DotImageResourceContext._instance == null) {
            DotImageResourceContext._instance = new DotImageResource();
        }
        return DotImageResourceContext._instance;
    }
    static Clear() {
        DotImageResourceContext._instance?.clear();
    }
}

export class DotImageResource {
    constructor() {
        this.canvas = document.createElement('canvas');
        this.canvas.width = 20;
        this.canvas.height = 20;
        this.context = this.canvas.getContext('2d');
        this.images = new Map();
    }
    async get(color) {
        return new Promise((resove) => {
            if (this.images.has(color)) resove(this.images.get(color));
            else {
                this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
                this.context.fillStyle = color;
                this.context.strokeStyle = color;
                this.context.beginPath();
                this.context.arc(10, 10, 8, 0, Math.PI * 2, false);
                this.context.fill();
                this.context.stroke();
                const image = new Image();
                image.src = this.canvas.toDataURL("image/png");
                this.images.set(color, image);
                image.onload = () => {
                    resove(image);
                }
            }
        });
    }
    clear() {
        this.context = null;
        this.canvas = null;
        this.images.clear();
    }
}