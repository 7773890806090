import $ from "jquery";
import { PluginMode, FreqType } from "@core/graphs/webcharts/Dh-webchart-models";
import { CursorElement } from "@core/graphs/plugins/Dh-plugin-cursor-base";
import { i18nGlobal } from "@common/js/utils";
import { DoubleCursorPlugin } from "./Dh-plugin-double-cursor";
import { KeyListenManager } from "@core/graphs/plugins/DH-plugin-base";

export class CharacteristicFrequencyCursorPlugin extends DoubleCursorPlugin {
    constructor(mode) {
        super(mode);
        this.Title = i18nGlobal('browse.characteristicFrequencyCursor');
        this.CursorCount = 4;
        this.Cursors = [];
        this.StartPos = null; //鼠标按下位置
        this.StartDatas = new Array(); //鼠标按下时数据信息
        this.CursorDatas = new Array();
        this.firstCaculate = false;
    }
    OnSizeChanged() {
        super.OnSizeChanged();
        $.each(this.Cursors, function (i, cursor) {
            cursor.Remove();
        });
        this.Cursors = [];
        let rect = this.Owner.Information.ShapeContainerRect;
        this.Cursors.push(new CursorElement(this, rect.Height, this.Owner.CoordinateSystem.AxisColor, "1", { X: rect.Left, Y: rect.Top }, "fb"));
        this.Cursors.push(new CursorElement(this, rect.Height, this.Owner.CoordinateSystem.AxisColor, "1", { X: rect.Left, Y: rect.Top }, "fco"));
        this.Cursors.push(new CursorElement(this, rect.Height, this.Owner.CoordinateSystem.AxisColor, "1", { X: rect.Left, Y: rect.Top }, "fi"));
        this.Cursors.push(new CursorElement(this, rect.Height, this.Owner.CoordinateSystem.AxisColor, "1", { X: rect.Left, Y: rect.Top }, "fo"));
        let sender = this;
        $.each(this.Cursors, function (i, cursor) {
            $(sender.Owner.PluginContainer).append(cursor.DOM);
        });
        this.OnRender();
    }
    GetRotateSpeed(option, id) {
        if (option.Signal) return option.Signal;
        if (option.Signals) {
            const res = option.Signals.find(t => t.SignalPoints.map(c => c.Id).includes(id));
            if (res) {
                return res;
            } else {
                return null;
            }
        }
        if (option.RegisterPoints?.length > 0) {
            let Signal = option.RegisterPoints.find(t => t.PointId === id);
            if (Signal && Signal.Param) {
                return JSON.parse(Signal.Param)
            } else {
                return null
            }
        }
        return null
    }
    OnRender() {
        let serieses = this.Owner.ShapeOperator.Data;
        let legend = this.Owner.PluginManager.GetPlugin(PluginMode.Statistics);
        let BearingInfo = this.Owner.Option.BearingInfo;
        let Signal = this.GetRotateSpeed(this.Owner.Option, legend.SelectedItem);
        let seriesData = null;
        if (!BearingInfo || !Signal) {
            this.Cursors.forEach(cursor => {
                cursor.DOM.addClass("hide");
            })
            return;
        }
        $.each(serieses, function (i, series) {
            if (series.PointId == legend.SelectedItem) {
                seriesData = series;
            }
        });
        if (seriesData == null || seriesData.Points.length == 0) {
            let container = this.LegendWnd.find(".cursor-container");
            container.empty();
            this.Cursors.forEach(cursor => {
                cursor.DOM.addClass("hide");
            })
            return;
        }

        const IsUseSpeed = this.Owner.Option.IsUseSpeed;
        let RotateSpeed = this.GetSpeed(legend.SelectedItem);
        
        if(IsUseSpeed) {
            let { RoteSpeed } = Signal;
            if (this.firstCaculate) {
                this.SetOutputData({
                    seriesData,
                    BearingInfo,
                    RoteSpeed
                })
                this.firstCaculate = false;
            } else {
                let pos = new Array();
                if (this.CursorDatas.length > 0) {
                    for (let i = 0; i < this.CursorDatas.length; i++) {
                        if (this.CursorDatas[i]) {
                            if (this.CursorDatas[i]?.XIndex != null) {
                                pos.push(this.CursorDatas[i].XIndex);
                            } else {
                                pos.push(0);
                            }
                        } else {
                            pos.push(null);
                        }
                    }
                } else {
                    for (let i = 0; i < this.CursorCount; i++) {
                        pos.push(0);
                    }
                }
                this.SetPosition(pos);
            }
        } else {
            if(RotateSpeed === null) {
                this.SetPosition([null,null,null,null]);
            } else {
                this.SetOutputData({
                    seriesData,
                    BearingInfo,
                    RoteSpeed: RotateSpeed
                })
            }
        }
    }
    SetOutputData({ seriesData, BearingInfo, RoteSpeed }) {
        let { RollerFreq, HoldingShelf, InnerRing, OuterRing } = BearingInfo;
        let outputData = new Array(4), data = [], Points = seriesData.Points;
        outputData[0] = this.GetPointByValue(RollerFreq * RoteSpeed / 60, Points);
        outputData[1] = this.GetPointByValue(HoldingShelf * RoteSpeed / 60, Points);
        outputData[2] = this.GetPointByValue(InnerRing * RoteSpeed / 60, Points);
        outputData[3] = this.GetPointByValue(OuterRing * RoteSpeed / 60, Points);
        let info = this.Owner.Information;
        outputData.forEach(item => {
            if (item) {
                let offsetx = this.Owner.CoordinateSystem.GetXOffset(item.X) - info.ShapeContainerRect.Left;
                let res = this.Owner.CoordinateSystem.GetPositionData(offsetx);
                data.push(res)
            } else {
                data.push(null)
            }
        })
        this.UpdatePosition(data);
    }
    GetSpeed(id) {
        if (this.Owner.Option.Speeds) {
            return this.Owner.Option.Speeds.find(t => t.pointId === id)?.data;
        } else {
            return this.Owner.Option.RotateSpeedMap[id] ? this.Owner.Option.RotateSpeedMap[id] : null;
        }
    }
    GetPointByValue(value, data) {
        for (let index = 0; index < data.length; index++) {
            const item = data[index];
            if (item.X === value) {
                return item;
            }
            if (index >= 1 && index < data.length - 1) {
                if (data[index - 1].X < value && data[index].X > value) {
                    if (value - data[index - 1].X > data[index].X - value) {
                        return data[index]

                    } else {
                        return data[index - 1]
                    }
                }
            }
        }
        return null;
    }
    UpdatePosition(data) {
        let sender = this;
        let rect = this.Owner.Information.ShapeContainerRect;
        if (this.Cursors.length === 0) return;
        for (let cursor of this.Cursors) {
            cursor.ClearPoints();
        }
        let container = this.LegendWnd.find(".cursor-container");
        let selectedPoint = this.Owner.PluginManager.GetPlugin(PluginMode.Statistics)?.SelectedItem;
        container.empty();
        const map = ['fb', 'fco', 'fi', 'fo']
        if (data?.length > 0) {
            const freqs = sender.Owner.Option.Axis.FrequencyLines.split(',').map(t => Number(t));
            for (let i = 0; i < data.length; i++) {
                let item = null;
                if (data[i] !== null) {
                    item = data[i].Items.find(t => t.Tag?.Source?.PointId === selectedPoint);
                }
                if (item) {
                    sender.Cursors[i].DrawPoint(item.OffsetY + rect.Top);
                    let rowContent = "";
                    if (freqs.includes(i + 1)) {
                        rowContent += `<span>${map[i]}</span> <span>X: ${data[i].XText}</span>  <span> Y: ${item.Y}</span>`
                        container.append(`<span class="freq-cursor-row" style="color:${item.Color}">${rowContent}</span>`);
                    }
                } else {
                    this.Cursors[i].DOM.addClass("hide");
                }

            }
            $.each(this.Cursors, function (i, cursor) {
                let cussorData = data[i];
                const freqs = sender.Owner.Option.Axis.FrequencyLines.split(',').map(t => Number(t));
                if (cursor)
                    if (!cussorData) {
                        cursor.DOM.addClass("hide");
                    } else {
                        if (!isNaN(cussorData?.OffsetX) && isFinite(cussorData?.OffsetX)) {
                            if (cussorData.OffsetX >= 0 && Math.floor(cussorData.OffsetX) <= rect.Width) {
                                cursor.DOM.removeClass("hide");
                                cursor.DOM.attr("transform", "translate(" + (cussorData.OffsetX - 6) + ",0)");
                            } else {
                                cursor.DOM.addClass("hide");
                            }
                        }
                    }

                if (!freqs.includes(FreqType.fb) && cursor.Name === 'fb') {
                    cursor.DOM.addClass("hide");
                }
                if (!freqs.includes(FreqType.fco) && cursor.Name === 'fco') {
                    cursor.DOM.addClass("hide");
                }
                if (!freqs.includes(FreqType.fi) && cursor.Name === 'fi') {
                    cursor.DOM.addClass("hide");
                }
                if (!freqs.includes(FreqType.fo) && cursor.Name === 'fo') {
                    cursor.DOM.addClass("hide");
                }
            });
        } else {
            container.empty();
            $.each(this.Cursors, function (i, cursor) {
                cursor.DOM.attr("transform", "translate(-6,0)");
            });
        }
        if (data) {
            this.CursorDatas = data;
        }
    }
    Close() {
        super.Close();
        $.each(this.Cursors, function (i, cd) {
            cd.Remove();
        });
        if (this.LegendWnd != null) {
            this.LegendWnd.remove();
        }
    }
    GetPosition() {
        let sender = this;
        let result = {
            PluginType: sender.Entity.Type,
            Position: new Array(),
        };
        if (this.CursorDatas.length > 0) {
            $.each(this.CursorDatas, function (i, d) {
                result.Position.push(d);
            });
        }
        return result;
    }
    GetCursorPosition() {
        let array = [];
        if (this.CursorDatas.length > 0) {
            $.each(this.CursorDatas, function (i, d) {
                array.push(d?.XIndex || 0);
            });
        }
        return array;
    }
    RenderGeneral(pos) {
        this.CursorDatas = new Array();
        let data = this.Owner.ShapeOperator.Data;
        if (data.length > 0) {
            for (let p of pos) {
                if (p !== null && p !== undefined) {
                    let data = this.Owner.CoordinateSystem.GetPositionByIndex(p);
                    if (data) {
                        this.CursorDatas.push(data);
                    }
                } else {
                    this.CursorDatas.push(null);
                }
            }
            this.UpdatePosition(this.CursorDatas);
        } else {
            for (let p of pos) {
                this.CursorDatas.push({
                    XIndex: p,
                });
            }
            this.UpdatePosition(null);
        }
    }
    SetPosition(pos) {
        let legend = this.Owner.PluginManager.GetPlugin(PluginMode.Statistics);
        let RotateSpeed = this.GetSpeed(legend.SelectedItem);
        this.RenderGeneral(pos)

        // if (RotateSpeed === null) {
        //     this.RenderGeneral(pos)
        // } else {
        //     this.OnRender()
        // }
    }
    GetZoomIndex() {
        let datas = new Array();
        $.each(this.CursorDatas, function (i, data) {
            datas.push(data);
        });
        datas = datas.orderBy((p) => p?.X);
        let info = this.Owner.Information;
        return datas[1]?.X || (info.CoordinateInfo.XMin + info.CoordinateInfo.XMax) / 2;;
    }
    GetZoomPosition() {
        if (this.CursorDatas.length > 0) {
            let selectIndex = null;
            this.Cursors.forEach((item, index) => {
                if (item.IsSelected()) {
                    selectIndex = index;
                }
            })
            if (selectIndex !== null) {
                if (this.CursorDatas[selectIndex]) {
                    return this.CursorDatas[selectIndex].X
                } else {
                    return this.GetZoomIndex();
                }
            } else {
                return this.GetZoomIndex();
            }
        } else {
            let info = this.Owner.Information;
            return (info.CoordinateInfo.XMin + info.CoordinateInfo.XMax) / 2;
        }
    }
    KeyLeftPress() {
        let legend = this.Owner.PluginManager.GetPlugin(PluginMode.Statistics);
        let Signal = this.GetRotateSpeed(this.Owner.Option, legend.SelectedItem);
        if (!Signal || !Signal.UseSpeed) return;
        if (!this.IsEnabled) return;
        if (this.CursorDatas.length > 0) {
            this.IsNotify = false;
            let pos = new Array();
            this.CheckForCusor();
            for (let i = 0; i < this.Cursors.length; i++) {
                const cursor = this.Cursors[i];
                let data = this.CursorDatas[i];
                if (cursor.IsSelected()) {
                    data.XIndex = data.XIndex > 0 ? data.XIndex - 1 : data.XIndex;
                    data.XIndex = this.GetProcessIndex(i, data.XIndex);
                }
                pos.push(data.XIndex);
            }
            this.SetPosition(pos);
        }
    }
    KeyRightPress() {
        let legend = this.Owner.PluginManager.GetPlugin(PluginMode.Statistics);
        let Signal = this.GetRotateSpeed(this.Owner.Option, legend.SelectedItem);
        if (!Signal || !Signal.UseSpeed) return;
        if (!this.IsEnabled) return;
        if (this.CursorDatas.length > 0) {
            this.IsNotify = false;
            let pos = new Array();
            this.CheckForCusor();
            for (let i = 0; i < this.Cursors.length; i++) {
                const cursor = this.Cursors[i];
                let data = this.CursorDatas[i];
                if (cursor.IsSelected()) {
                    data.XIndex += 1;
                    data.XIndex = this.GetProcessIndex(i, data.XIndex);
                }
                pos.push(data.XIndex);
            }
            this.SetPosition(pos);
        }
    }
    GetProcessIndex(i, index) {
        return index;
    }
    OnMouseUp(e) {
        let legend = this.Owner.PluginManager.GetPlugin(PluginMode.Statistics);
        let Signal = this.GetRotateSpeed(this.Owner.Option, legend.SelectedItem);
        if (!Signal || !Signal.UseSpeed) return false;
        if (!super.OnMouseUp(e)) return false;
        if (e.which != 1) return false;
        let sender = this;
        if (this.IsMouseDown) {
            this.IsMouseDown = false;
            this.RefreshRelatedData();
        } else {
            $.each(this.Cursors, function (i, cursor) {
                if (cursor.IsMouseDown) {
                    cursor.IsMouseDown = false;
                    sender.RefreshRelatedData();
                }
            });
        }
        return true;
    }
    OnMouseMove(e) {
        let legend = this.Owner.PluginManager.GetPlugin(PluginMode.Statistics);
        let Signal = this.GetRotateSpeed(this.Owner.Option, legend.SelectedItem);
        if (!Signal || !Signal.UseSpeed) return false;
        if (!super.OnMouseMove(e)) return false;
        if (e.which !== 1) return false;
        if (this.Cursors.length == 0) return false;
        if (this.CursorDatas.length == 0) return false;
        this.IsNotify = false;
        for (let i = 0; i < this.Cursors.length; i++) {
            const cursor = this.Cursors[i];
            if (cursor.IsMouseDown) {
                this.ProcessCursorMove(e, i);
            }
        }
        return true;
    }
    OnMouseDown(e) {
        if (e.which != 1) return false;
        if (window.event.ctrlKey) return false;
        if (!this.IsEnabled) return false;
        KeyListenManager.getListenManager();
        window.KeyManager.CurrentElement = this;
        let rect = this.Owner.Information.ShapeContainerRect;
        if (e.offsetY < rect.Top || e.offsetY > rect.Top + rect.Height) return false;
        if (e.offsetX < rect.Left - 4 || e.offsetX > rect.Left + rect.Width + 4) return false;
        $('body').off('mouseup', this.onMouseUpHandler);
        $('body').on('mouseup', this.onMouseUpHandler);
        if (this.Cursors.length == 0) return false;
        if (this.CursorDatas.length == 0) return false;
        if (this.CursorDatas.every(t => t === null)) return false;
        let startIndex = this.CursorDatas.findIndex(item => item);
        let endtIndex = this.CursorDatas.findLastIndex(item => item);
        let start = this.CursorDatas[startIndex].OffsetX;
        let end = this.CursorDatas[endtIndex].OffsetX;
        for (let i = 0; i < this.Cursors.length; i++) {
            if (this.CursorDatas[i]) {
                if (start > this.CursorDatas[i].OffsetX) start = this.CursorDatas[i].OffsetX;
                if (end < this.CursorDatas[i].OffsetX) end = this.CursorDatas[i].OffsetX;
            }
        }
        let xc = e.offsetX - rect.Left;
        if (xc >= start - 5 && xc <= end + 5) {
            this.IsMouseDown = true;
            this.StartPos = xc;
            this.StartDatas = new Array();
            for (let j = 0; j < this.CursorDatas.length; j++) {
                if (this.CursorDatas[j]) {
                    this.StartDatas.push(this.CursorDatas[j].OffsetX);
                }
            }
        }
        //e.stopPropagation();
        return true;
    }
    RefreshRelatedData() {
        this.IsNotify = true;
        this.NotifyPositionChanged();
    }
    NotifyPositionChanged() {
        if (this.Entity.onPositionChanged && this.IsNotify == true && this.CursorDatas.length > 0) {
            let e = {
                Cursors: new Array(),
            };
            $.each(this.CursorDatas, function (i, cd) {
                e.Cursors.push(cd);
            });
            this.Entity.onPositionChanged(e);
        }
    }
    CheckForCusor() {
        let isSelected = false;
        $.each(this.Cursors, function (i, cursor) {
            if (cursor.IsSelected()) {
                isSelected = true;
            }
        });
        if (!isSelected) {
            this.Cursors[0].Select();
        }
    }
    ClearData() {
        // $.each(this.CursorGroups, function (i, group) {
        //     $.each(group.Cursors, function (j, cursor) {
        //         cursor.CursorDatas = new Array();
        //     });
        // });
    }
}