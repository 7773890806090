import $ from "jquery";
import { ruleCheck } from "@common/js/rules";
import { DataPointShape, DataSeries, ScaleType, YAxisType, ChartMode } from "@core/graphs/webcharts/Dh-webchart-models";
import { ThemeResource } from "@components/js/utils/model";
import store from "@common/js/store";
import { Debounce } from "@common/js/utils";
import _ from "lodash";
import dayjs from "dayjs";

export class GraphBase {
    constructor() {
        this.Graph = null;
        this.ContainerId = null;
        this.Option = null;
        this.Plugins = null;
        this.GraphType = null;
        this.PointType = DataPointShape.None;
        this.Datas = new Map();
        this.PointSize = 2;
        this.debounce = new Debounce();
        this.colorIndex = 0;
        this.xcofe = 10000;
        this.xUnit = "s";
    }
    Init(option, plugins) {
        this.Clear();
        this.ContainerId = option.Id;
        option.ChartId = this.ContainerId + "_" + this.GraphType;
        this.Option = option;
        this.Plugins = plugins;
        $("#" + this.ContainerId).html(this.CreateGraphContainer());
        this.EnsureGraphSizes();
        this.CreateGraph();
    }
    CreateGraphContainer() {
        return `<div id="${this.Option.ChartId}" data-minwidth="40" data-minheight="40" style="position:absolute;overflow: hidden;"></div>`;
    }
    CreateGraph() { }
    EnsureGraphSizes(rect) {
        const container = $("#" + this.ContainerId);
        const graphDom = $("#" + this.Option.ChartId);
        let height = container.height();
        let width = container.width();
        if (height < graphDom.attr("data-minheight")) {
            height = graphDom.attr("data-minheight");
        }
        if (width < graphDom.attr("data-minwidth")) {
            width = graphDom.attr("data-minwidth");
        }
        $("#" + this.Option.ChartId).css("height", rect ? rect.height : height);
        $("#" + this.Option.ChartId).css("width", rect ? rect.width : width);
    }
    GetMaxMinValue(data) {
        let xmax = null,
            xmin = null,
            ymax = null,
            ymin = null;
        for (const d of data) {
            if (ymax == null || d.Y > ymax) ymax = d.Y;
            if (ymin == null || d.Y < ymin) ymin = d.Y;
            if (xmax == null || d.X > xmax) xmax = d.X;
            if (xmin == null || d.X < xmin) xmin = d.X;
        }
        return {
            XMax: xmax,
            XMin: xmin,
            YMax: ymax,
            YMin: ymin,
        };
    }
    UpdateDataSeries(datas, forceUpdate = true) {
        const dataMaps = new Map();
        for (const p of datas) {
            if (p.Param) {
                let ds = new DataSeries();
                if (!forceUpdate && this.Datas.has(p.PointId)) {
                    ds = this.Datas.get(p.PointId);
                }
                ds.PointId = p.PointId;
                ds.PointType = this.PointType;
                ds.XUnit = this.xUnit;
                ds.YUnit = p.Param.Unit;
                ds.Tag = p.Param;
                ds.Legend = p.Param.Name;
                ds.FullValue = p.Param.FullValue;
                ds.Points = new Array();
                dataMaps.set(p.PointId, ds);
            }
        }
        if (dataMaps.size > 0 || forceUpdate) this.Datas = dataMaps;
    }
    AppendData(datas) {
        this.UpdateDataSeries(datas, false);
        for (const dt of datas) {
            if (dt.Datas?.Points?.length > 0) {
                if (!this.Datas.has(dt.PointId)) {
                    continue;
                }
                const series = this.Datas.get(dt.PointId);
                this.ProcessData(series, dt);
                series.FullValue = ruleCheck.IsNullOrSpaceStr(series.FullValue) ? 1000 : parseFloat(series.FullValue);
                this.ProcessYData(series, series.FullValue);
            }
        }
        this.SetIntervalRefresh();
    }
    SetData(datas) {
        this.UpdateDataSeries(datas);
        for (const dt of datas) {
            if (dt.Datas?.Points?.length > 0) {
                if (!this.Datas.has(dt.PointId)) {
                    continue;
                }
                let series = this.Datas.get(dt.PointId);
                this.ProcessData(series, dt);
                series.FullValue = ruleCheck.IsNullOrSpaceStr(series.FullValue) ? 1000 : parseFloat(series.FullValue);
                this.ProcessYData(series, series.FullValue);
            }
        }
        const serieses = this.Datas.getValues();
        let temp = this.getOrderDataSeries(serieses);
        this.SetLineColor(temp);
        this.Graph?.SetData(temp);
        temp = null;
    }
    ProcessData(series, data) {
        series.Points = data.Datas.Points;
        series.ChartMode = series.Points.length > 3000 ? ChartMode.Performance : ChartMode.Normal;
        series.XMin = series.Points[0].X;
        if (data.Datas.XMax) {
            series.XMax = data.Datas.XMax;
        } else {
            series.XMax = series.Points[series.Points.length - 1].X;
        }
    }
    ProcessYData(series, fullValue) {
        if (this.Option.Axis.AxisScaleType == ScaleType.Auto && series.Points.length > 0) {
            const maxmin = this.GetMaxMinValue(series.Points);
            series.YMax = maxmin.YMax;
            series.YMin = maxmin.YMin;
        } else {
            series.YMax = fullValue;
            series.YMin = -fullValue;
        }
        series.FullValue = fullValue;
    }
    SetIntervalRefresh(timeOut) {
        if (timeOut == null) timeOut = store.state.graphSetting.DataIntervalMillsec;
        this.debounce.run(() => {
            let serieses = [];
            for (const data of this.Datas.values()) {
                let temp = {};
                Object.assign(temp, data);
                serieses.push(temp);
            }
            let temp = this.getOrderDataSeries(serieses);
            this.SetLineColor(temp);
            this.Graph?.SetData(temp);
            temp = null;
            serieses = null;
        }, timeOut);
    }
    getOrderDataSeries(serieses) {
        let registerPoints = this.Option.RegisterPoints;
        let result = [];
        if (registerPoints?.length > 0) {
            for (let rp of registerPoints) {
                let series = serieses.find((r) => r.PointId == rp.PointId);
                if (series) {
                    result.push(series);
                }
            }
            return result;
        } else {
            return serieses;
        }
    }
    getMaxValue(datas) {
        let idx = 0;
        for (const data of datas.values()) {
            if (data.YAxisType == YAxisType.Text) {
                for (let item of data.YAxisItems) {
                    if (item.Value > idx) idx = item.Value;
                }
            }
        }
        return idx;
    }
    SetLineColor(series, startIndex = 0) {
        let i = startIndex;
        const theme = this.Option?.Axis?.Theme || 1;
        let colors = ThemeResource[theme].Colors();
        for (const s of series) {
            let idx = i % colors.length;
            s.LineColor = colors[idx];
            i++;
        }
    }
    ClearData(start, end) {
        this.Datas.clear();
        this.Graph?.ClearData();
        if (start) {
            start = start ? Number(dayjs(start)) : null;
            end = end ? Number(dayjs(end)) : null;
            this.UpdateXCoordinate(start, end);
        }
    }
    ClearZoom() {
        const info = this.Graph.WebChart.Information.CoordinateInfo;
        info.YRate = 1;
        info.XMinRate = 0;
        info.XMaxRate = 1;
        info.IsEnlarge = false;
    }
    UpdateXCoordinate(start, end) {
        this.Graph?.UpdateXCoordinate(start, end);
    }
    OnSizeChanged() {
        this.Graph.OnChartChanged();
    }
    Update(option) {
        if (this.Graph == null) return;
        let datas = this.Datas.getValues();
        let temp = this.getOrderDataSeries(datas);
        this.SetLineColor(temp);
        if (this.Option) {
            option.ChartId = this.Option.ChartId;
        }
        this.Option = option;
        this.Graph.Update(option);
    }
    UpdateScaleType() {
        let coord = this.Graph.WebChart.Information.CoordinateInfo;
        coord.YRate = 1;
        coord.XRate = 1;
        coord.IsEnlarge = false;
        this.RefreshData();
    }
    RefreshData() {
        let datas = this.Datas.getValues();
        for (const data of datas) {
            this.ProcessYData(data, data.FullValue);
        }
        if (this.Graph != null) {
            let temp = this.getOrderDataSeries(datas);
            this.SetLineColor(temp);
            this.Graph.SetData(temp);
            temp = null;
        }
        datas = null;
    }
    GetPluginManager() {
        return this.Graph.GetPluginManager();
    }
    UseDataRefresh(state) {
        this.Graph?.UseDataRefresh(state);
    }
    GetCoordinate() {
        return this.Graph.WebChart.Information.CoordinateInfo;
    }
    GetInformation() {
        return this.Graph.WebChart.Information;
    }
    GetShapeOpeator() {
        return this.Graph.WebChart.ShapeOperator;
    }
    Clear() {
        this.ClearData();
        if (this.Graph != null) {
            this.Graph.Dispose();
            this.Graph = null;
            $("#" + this.ContainerId).empty();
        }
        this.ContainerId = null;
        this.Option = null;
        this.Plugins = null;
        this.GraphType = null;
    }
}