import $ from "jquery";
import { PluginBase } from "./DH-plugin-base";
import { i18nGlobal } from "@common/js/utils";

export class TimeRangePlugin extends PluginBase {
    constructor() {
        super();
        this.Dom = null;
        this.Time = new Map();
    }
    CreatePlugin() {
        let rect = this.Owner.Information.ChartContainerRect;
        let html =
            '<div class="time-range" style="position: absolute;float:left;display:flex;left:0px;top:30px;width:' +
            rect.Width +
            'px;height:30px;overflow: hidden;">' +
            `<span class="time-label">${i18nGlobal('common.startTime')}:</span>` +
            '<input id="start" class="actived" type="text" value="">' +
            `<span class="time-label">${i18nGlobal('common.endTime')}:</span>` +
            '<input id="end" type="text" value="">' +
            `<button id="sure" type="button" class="btn btn-primary btn-xs btn-dialog-submit" style="margin-left:10px">${i18nGlobal('common.confirm')}</button>` +
            "</div>";
        this.Dom = $(html);
        $(this.Owner.ChartContainer).append(this.Dom);
        this.Dom.on("dblclick", function (e) {
            e.preventDefault();
            e.stopPropagation();
        });
        let sender = this;
        this.Dom.find("#sure").on("click", function () {
            if (sender.Entity.onSure) {
                let startTime = sender.Dom.find("#start").val();
                let endTime = sender.Dom.find("#end").val();
                if (!sender.ValidateTimeFormat(startTime)) {
                    startTime = sender.Time.get("start");
                    sender.Dom.find("#start").val(startTime);
                }
                if (!sender.ValidateTimeFormat(endTime)) {
                    endTime = sender.Time.get("end");
                    sender.Dom.find("#end").val(endTime);
                }

                let obj = {
                    StartTime: startTime,
                    EndTime: endTime,
                };
                sender.Entity.onSure(obj);
            }
        });

        this.Dom.find("input")
            .on("click", function () {
                sender.Dom.find("input").removeClass("actived");
                $(this).addClass("actived");
                //let calTool = SystemTest.GetTopWindow().CalendarTool;
                //calTool.SetTime($(this).val());
            })
            .on("change keyup paste", function (e) {
                let value = $(this).val();
                if (sender.ValidateTimeFormat(value)) {
                    sender.Time.set(this.id, value);
                    return;
                }
                if (!sender.ContinueInputFormat(value)) {
                    $(this).val(sender.Time.get(this.id));
                }
            })
            .on("blur", function () {
                let value = $(this).val();
                if (!sender.ValidateTimeFormat(value)) {
                    $(this).val(sender.Time.get(this.id));
                }
            });
    }
    OnSizeChanged() {
        let rect = this.Owner.Information.ChartContainerRect;
        this.Dom.css("width", rect.Width);
    }
    ValidateTimeFormat(value) {
        let reg =
            /^(?:19|20)\d\d-(?:(?:\d{1,2}))-(?:(?:\d{1,2})) (?:(?:\d{1,2})):\d{1,2}:\d{1,2}$/;
        if (value.test(reg) == false) {
            return false;
        }
        let p = value.split(" ");
        let year = parseInt(p[0].split("-")[0]);
        let month = parseInt(p[0].split("-")[1]);
        let day = parseInt(p[0].split("-")[2]);
        if (year < 1970 || year > 2100) {
            return false;
        }
        if (month < 1 || month > 12) {
            return false;
        }
        if (day < 1 || day > 31) {
            return false;
        }
        if ((month == 4 || month == 6 || month == 9 || month == 11) && day == 31) {
            return false;
        }
        if (month == 2) {
            let isleap = year % 4 == 0 && (year % 100 != 0 || year % 400 == 0);
            if (day > 29 || (day == 29 && !isleap)) {
                return false;
            }
        }

        let hour = parseInt(p[1].split(":")[0]);
        let minitue = parseInt(p[1].split(":")[1]);
        let second = parseInt(p[1].split(":")[2]);
        if (hour > 23) {
            return false;
        }
        if (minitue > 59) {
            return false;
        }
        if (second > 59) {
            return false;
        }
        return true;
    }
    ContinueInputForma(value) {
        let reg =
            /^(?:19|20)\d\d-(?:(?:\d{0,2}))-(?:(?:\d{0,2})) (?:(?:\d{0,2})):\d{0,2}:\d{0,2}$/;
        if (value.test(reg) == false) {
            return false;
        }
        let p = value.split(" ");
        let year = parseInt(p[0].split("-")[0]);
        let month = parseInt(p[0].split("-")[1]);
        let day = parseInt(p[0].split("-")[2]);
        if (year < 1970 || year > 2100) {
            return false;
        }
        if (!isNaN(month) && month > 12) {
            return false;
        }
        if (!isNaN(day) && day > 31) {
            return false;
        }
        if ((month == 4 || month == 6 || month == 9 || month == 11) && day == 31) {
            return false;
        }
        if (month == 2) {
            let isleap = year % 4 == 0 && (year % 100 != 0 || year % 400 == 0);
            if (day > 29 || (day == 29 && !isleap)) {
                return false;
            }
        }

        let hour = parseInt(p[1].split(":")[0]);
        let minitue = parseInt(p[1].split(":")[1]);
        let second = parseInt(p[1].split(":")[2]);
        if (!isNaN(hour) && hour > 23) {
            return false;
        }
        if (!isNaN(minitue) && minitue > 59) {
            return false;
        }
        if (!isNaN(second) && second > 59) {
            return false;
        }
        return true;
    }
    SetCondition(condition) {
        if (condition.EndTime == null) {
            condition.EndTime = SystemTest.GetTopWindow().CalendarTool.GetEndTime();
        }
        this.Dom.find("#end").val(condition.EndTime);
        this.Dom.find("#start").val(condition.StartTime);
        this.Time.set("end", condition.EndTime);
        this.Time.set("start", condition.StartTime);
    }
    SetTime(time) {
        this.Dom.find(".actived").val(time);
    }
    setStartTime(time) {
        this.Dom.find("#start").val(time);
    }
    Close() {
        this.Dom.remove();
    }
    SwitchTheme(theme) {
        if (theme == 2) {
            this.Dom.find(".time-label").css("color", "white");
        } else {
            this.Dom.find(".time-label").css("color", "black");
        }
    }
}
