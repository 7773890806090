// @generated by protobuf-ts 2.9.1
// @generated from protobuf file "protos/alarmpointeigens.proto" (package "alarm", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message alarm.AlarmPointEigensRequest
 */
export interface AlarmPointEigensRequest {
    /**
     * @generated from protobuf field: int32 PointId = 1 [json_name = "PointId"];
     */
    pointId: number;
    /**
     * @generated from protobuf field: bool IsPatrol = 2 [json_name = "IsPatrol"];
     */
    isPatrol: boolean;
    /**
     * @generated from protobuf field: repeated int32 EigenTypes = 3 [json_name = "EigenTypes"];
     */
    eigenTypes: number[];
}
// @generated message type with reflection information, may provide speed optimized methods
class AlarmPointEigensRequest$Type extends MessageType<AlarmPointEigensRequest> {
    constructor() {
        super("alarm.AlarmPointEigensRequest", [
            { no: 1, name: "PointId", kind: "scalar", jsonName: "PointId", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "IsPatrol", kind: "scalar", jsonName: "IsPatrol", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "EigenTypes", kind: "scalar", jsonName: "EigenTypes", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<AlarmPointEigensRequest>): AlarmPointEigensRequest {
        const message = { pointId: 0, isPatrol: false, eigenTypes: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AlarmPointEigensRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AlarmPointEigensRequest): AlarmPointEigensRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 PointId = 1 [json_name = "PointId"];*/ 1:
                    message.pointId = reader.int32();
                    break;
                case /* bool IsPatrol = 2 [json_name = "IsPatrol"];*/ 2:
                    message.isPatrol = reader.bool();
                    break;
                case /* repeated int32 EigenTypes = 3 [json_name = "EigenTypes"];*/ 3:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.eigenTypes.push(reader.int32());
                    else
                        message.eigenTypes.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AlarmPointEigensRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 PointId = 1 [json_name = "PointId"]; */
        if (message.pointId !== 0)
            writer.tag(1, WireType.Varint).int32(message.pointId);
        /* bool IsPatrol = 2 [json_name = "IsPatrol"]; */
        if (message.isPatrol !== false)
            writer.tag(2, WireType.Varint).bool(message.isPatrol);
        /* repeated int32 EigenTypes = 3 [json_name = "EigenTypes"]; */
        if (message.eigenTypes.length) {
            writer.tag(3, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.eigenTypes.length; i++)
                writer.int32(message.eigenTypes[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message alarm.AlarmPointEigensRequest
 */
export const AlarmPointEigensRequest = new AlarmPointEigensRequest$Type();
