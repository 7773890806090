import $ from "jquery";
import { PluginBase } from "./Dh-plugin-base";
import { guid } from "@common/js/utils";
import DrawApi from "@core/graphs/webcharts/Dh-webchart-drawApis";

export class AlarmLinePlugin extends PluginBase {
	constructor() {
		super();
		this.HighValue1 = null;
		this.HighValue2 = null;
		this.DangerValue1 = null;
		this.DangerValue2 = null;
		this.AlarmType = 0;
		this.Id = guid();
		this.drawApi = new DrawApi();
	}
	EnsureContainerCreated() {
		var container = this.Owner.PluginContainer;
		var element = document.getElementById(this.Id);
		if (element == null) {
			element = document.createElement("canvas");
			element.setAttribute("id", this.Id);
			element.setAttribute("style", "position:absolute;left:0;");
			container.before(element);
		}
		element.width = $("#" + this.Owner.ChartContainer.id).width();
		element.height = $("#" + this.Owner.ChartContainer.id).height();
		this.EnsureContainerCliped();
		return element;
	}
	//确认容器已经剪裁
	EnsureContainerCliped() {
		var rect = this.Owner.Information.ShapeContainerRect;
		this.drawApi.SetContext(this.Id);
		this.drawApi.ClipRect(rect.Left, rect.Top, rect.Width, rect.Height);
	}
	CreatePlugin() { }
	OnSizeChanged() { }
	OnRender() {
		this.EnsureContainerCreated();
		var coordinate = this.Owner.Information.CoordinateInfo;
		if (this.Owner.Option && this.Owner.Option.Axis.ShowAlarmLine == false) {
			return;
		}
		var highLine1 = { Points: new Array() };
		highLine1.Points.push({ X: coordinate.XMin, Y: this.HighValue1 });
		highLine1.Points.push({ X: coordinate.XMax, Y: this.HighValue1 });
		var highLine2 = { Points: new Array() };
		highLine2.Points.push({ X: coordinate.XMin, Y: this.HighValue2 });
		highLine2.Points.push({ X: coordinate.XMax, Y: this.HighValue2 });
		var dangerLine1 = { Points: new Array() };
		dangerLine1.Points.push({ X: coordinate.XMin, Y: this.DangerValue1 });
		dangerLine1.Points.push({ X: coordinate.XMax, Y: this.DangerValue1 });
		var dangerLine2 = { Points: new Array() };
		dangerLine2.Points.push({ X: coordinate.XMin, Y: this.DangerValue2 });
		dangerLine2.Points.push({ X: coordinate.XMax, Y: this.DangerValue2 });
		var datas = new Array();
		if (this.HighValue1 != null) {
			datas.push({
				Data: highLine1,
				Color: "#FFCC33",
			});
		}
		if (this.HighValue2 != null) {
			datas.push({
				Data: highLine2,
				Color: "#FFCC33",
			});
		}
		if (this.DangerValue1 != null) {
			datas.push({
				Data: dangerLine1,
				Color: "red",
			});
		}
		if (this.DangerValue2 != null) {
			datas.push({
				Data: dangerLine2,
				Color: "red",
			});
		}
		this.drawApi.SetContext(this.Id);
		this.drawApi.ClearAll();
		for (const series of datas) {
			let ptArray = this.Owner.CoordinateSystem.GetPoints(series.Data);
			if (ptArray != null && ptArray.length > 0) {
				this.Draw(ptArray, series.Color);
			}
		}
	}
	//绘制
	Draw(ptArray, color) {
		this.drawApi.BeginPath();
		var first = ptArray[0];
		this.drawApi.MoveTo(first.X, first.Y);
		for (const d of ptArray) {
			this.drawApi.LineTo(d.X, d.Y);
		}
		this.drawApi.Stroke(color, 1);
	}
	SetPosition(alarmType, pos) {
		if (pos.length != 4) return;
		this.HighValue1 = pos[0];
		this.HighValue2 = pos[1];
		this.DangerValue1 = pos[2];
		this.DangerValue2 = pos[3];
		this.AlarmType = alarmType;
		this.Owner.Information.UpdateCoordinateInfo();
		this.Owner.OnChartChanged();
	}
	Close() {
		if (this.drawApi.DrawContext) this.drawApi.Dispose();
		this.Owner.Information.SetYMaxMin(null);
		this.Owner.Information.SetYMinMax(null);
		this.Owner.Information.UpdateCoordinateInfo();
	}
}
