import { AmplitudeTypeEnum, SpectrumTypeEnum, WinTypeEnum } from '@common/js/enum'

/** 近似相等，用于忽略编程语言处理小数时带来的精度误差 */
const isApproximateEqual = (a, b) => {
    return Math.abs(a - b) < 1e-10;
}

/** 获取谐波失真度 */
export const GetTHD = (baseValue, count, datas) => {
    let result = {};
    const Algorithm = {}
    for (const pointData of datas) {
        const powerCorrectValues = GetSpectrumPowerCorrect(
            pointData.Points.map(p => p.Y),
            Algorithm.SpectrumType,
            Algorithm.WindowType,
            Algorithm.AmplitudeType,
            pointData.Points.length
        );
        const pds = new Array(powerCorrectValues.Length);
        powerCorrectValues.forEach((val, i) => {
            pds[i] = { X: pointData.Points[i].X, Y: powerCorrectValues[i] }
        })
        let y = pointData.Points.find(e => isApproximateEqual(e.X, baseValue))?.Y;
        y = GetSpectrumPowerCorrect(
            [y],
            Algorithm.SpectrumType,
            Algorithm.WindowType,
            Algorithm.AmplitudeType,
            pointData.Points.length
        )[0];
        let factor = pds.filter(p => p.X >= baseValue && p.X <= baseValue * count && (baseValue == 0 || isApproximateEqual(p.X % baseValue, 0))).reduce((pre, cur) => pre + cur.Y * cur.Y, 0) - y * y;
        if (y != 0) {
            const res = Math.sqrt(factor / (y * y));
            result[pointData.PointId] = Number.isNaN(res) ? 0 : res;
        } else {
            result[pointData.PointId] = 3
        }
    }
    return result;
}

/** 频谱数据能量修正 */
const GetSpectrumPowerCorrect = (datas, spectType, winType, amplType, length) => {
    let coef = 1.0;
    let results = datas;

    if (spectType != SpectrumTypeEnum.Realtime || (amplType != AmplitudeTypeEnum.Amplitude && amplType != AmplitudeTypeEnum.RMS)) {
        coef /= GetWindowFactor(winType, length, 1);
    }
    coef *= GetWindowFactor(winType, length, 3);

    if (datas.length > 0)
        results = datas.map(data => data * coef);

    return datas;
}

/** 获取加窗修正系数 */
const GetWindowFactor = (winType, length, wflag) => {
    const winData = GetWindowDatas(length, winType, 0, length);
    let result = 0;
    switch (wflag) {
        case 1:
        case 2:
            result = winData.reduce((p, c) => p + c) / length;
            result = 1 / result;
            break;
        case 3:
            result = Math.sqrt(winData.reduce((p, c) => p + c * c) / length);
            result = 1 / result;
            break;
    }
    return result;
}

/**
 * 获取加窗数据
 * @param {int} length 数组长度
 * @param {WinTypeEnum} winType 窗类型
 * @param {int} startIndex （力窗/指数窗）起始坐标
 * @param {double} winWidth （力窗/指数窗）窗宽[0-100]
 * @returns {double[]}
 */
const GetWindowDatas = (length, winType, startIndex, winWidth) => {
    let result = new Array(length)
    const Len2 = (length + 1) / 2;
    switch (winType) {
        case WinTypeEnum.Hanning:
            let hntmp = 2.0 * Math.PI / (length + 1);
            for (let k = 0; k < Len2; ++k) {
                result[k] = 0.5 * (1.0 - Math.cos((k + 1) * hntmp));
                result[length - k - 1] = result[k];
            }
            break;
        case WinTypeEnum.Hamming:
            let hmtmp = 2.0 * Math.PI / (length - 1);
            for (let k = 0; k < Len2; ++k) {
                result[k] = 0.54 - 0.46 * Math.cos(hmtmp * k);
                result[length - k - 1] = result[k];
            }
            break;
        case WinTypeEnum.Flattop:
            let fttmp = 2.0 * Math.PI / (length - 1);
            for (let k = 0; k < Len2; ++k) {
                result[k] = 0.21557895 - 0.41663158 * Math.cos(fttmp * k) + 0.277263158 * Math.cos(2 * fttmp * k)
                    - 0.083578947 * Math.cos(3 * fttmp * k) + 0.006947368 * Math.cos(4 * fttmp * k);
                result[length - k - 1] = result[k];
            }
            break;
        case WinTypeEnum.Triangle:
            let odd = length % 2;
            for (let k = 0; k < Len2; ++k) {
                result[k] = (2 * (k + 1) + odd - 1) / (length + odd);
                result[length - k - 1] = result[k];
            }
            break;
        case WinTypeEnum.Blackman:
            let bmtmp = 2.0 * Math.PI / (length - 1);
            for (let k = 0; k < Len2; ++k) {
                result[k] = 0.42 - 0.5 * Math.cos(bmtmp * k) + 0.08 * Math.cos(2 * bmtmp * k);
                result[length - k - 1] = result[k];
            }
            result[0] = 0.0;
            result[length - 1] = 0.0;
            break;
        case WinTypeEnum.Weight:
            let endIndex = Math.min(length, startIndex + winWidth * length / 100);
            for (let i = startIndex; i < endIndex; ++i) {
                result[i] = Math.exp((i - startIndex) * Math.log(0.001) / (endIndex - startIndex));
            }
            break;
        case WinTypeEnum.Power:
            let pend = Math.min(length, startIndex + length * winWidth / 100);
            for (let i = startIndex; i < pend; ++i) {
                result[i] = 1;
            }
            break;
        case WinTypeEnum.Rectangle:
        default:
            for (let i = 0; i < length; ++i) {
                result[i] = 1;
            }
            break;
    }
    return result;
}