import { ruleCheck } from "@common/js/rules";
import { DataPointShape, DataPoint, GraphType, ChartMode, DataSeries } from "@core/graphs/webcharts/Dh-webchart-models";
import { CustomAnalysisChart } from "@core/graphs/charts/Dh-analysischart-custom";
import _ from "lodash";
import { GraphBase } from "./Dh-graph-base";
import { EnumXDataType } from "@core/js/dataParserContext";

export class CustomGraph extends GraphBase {
    constructor() {
        super();
        this.GraphType = GraphType.CustomGraph;
        this.LastEndPoint = null;
        this.XMin = null;
        this.XMax = null;
        this.TimeLength = 60 * 10; //s
        this.fullBlock = false;
    }
    AppendData(datas) {
        this.UpdateDataSeries(datas, false);
        const waveDatas = datas.filter((r) => r.Datas?.Points?.length > 0);
        const eigenDatas = datas.filter((r) => r.Eigens?.length > 0);
        if (waveDatas.length > 0) {
            this.ProcessWaveData(waveDatas);
            this.UpdateWaveDataX();
        }
        if (eigenDatas.length > 0) {
            this.ProcessEigenData(eigenDatas);
        }
        this.SetIntervalRefresh(this.fullBlock ? 0 : null);
    }
    SetData(datas) {
        this.UpdateDataSeries(datas);
        this.ProcessWaveData(datas, true);
        const eigenDatas = datas.filter((r) => r.Eigens?.length > 0);
        if (eigenDatas.length > 0) {
            for (const data of eigenDatas) {
                const series = this.Datas.get(data.PointId);
                series.PointType = DataPointShape.Arc;
                series.XDataType = EnumXDataType.Time;
            }
        }
        const serieses = this.Datas.getValues();
        let temp = this.getOrderDataSeries(serieses);
        this.SetLineColor(temp);
        this.Graph?.SetData(temp);
        temp = null;
    }
    SetDataContour(datas) {
        this.UpdateDataSeries(datas);
        this.ProcessContourData(datas);
        const serieses = this.Datas.getValues();
        for (const series of serieses) {
            series.ChartMode = ChartMode.Performance;
        }
        let temp = this.getOrderDataSeries(serieses);
        this.SetLineColor(temp);
        this.Graph?.SetData(temp);
        temp = null;
    }
    ProcessContourData(datas) {
        for (const point of datas) {
            if (!this.Datas.has(point.PointId) || point.Datas.DataCount == 0) {
                continue;
            }
            const series = this.Datas.get(point.PointId);
            series.XDataType = point.Datas.XDataType;
            series.Points = point.Datas.Points;
            series.XMin = series.Points[0].X;
            series.XMax = series.Points[series.Points.length - 1].X;
            series.FullValue = ruleCheck.IsNullOrSpaceStr(series.FullValue) ? 1000 : parseFloat(series.FullValue);
            this.ProcessYData(series, series.FullValue);
        }
    }
    ProcessWaveData(datas, isHistory = false) {
        this.fullBlock = false;
        for (const data of datas) {
            if (!this.Datas.has(data.PointId) || !data.Datas || data.Datas.Points.length == 0) {
                continue;
            }
            const xcofe = data.Datas.XDataType == EnumXDataType.Time ? 10000 : 1;
            const series = this.Datas.get(data.PointId);
            series.XDataType = data.Datas.XDataType;
            series.FullValue = ruleCheck.IsNullOrSpaceStr(series.FullValue) ? 1000 : parseFloat(series.FullValue);
            if (data.SampleFreq > 200 || isHistory) {
                series.Points = [...data.Datas.Points];
                series.XMin = series.Points[0].X;
                series.XMax = series.Points[series.Points.length - 1].X;
                if (data.Datas.XMax) {
                    series.XMax = data.Datas.XMax;
                }
            } else {
                series.LowFrequency = true;
                if (data.DataOffset == 0) {
                    series.Points = [...data.Datas.Points];
                } else {
                    series.Points.push(...data.Datas.Points);
                }
                if (data.BlockSize == data.Datas.Points.length + data.DataOffset) {
                    this.fullBlock = true;
                }
                series.XMin = series.Points[0].X;
                if (data.Datas.XMax) {
                    series.XMax = data.Datas.XMax;
                } else {
                    series.XMax = series.XMin + 1E7 / data.SampleFreq * data.BlockSize / xcofe;
                }
                series.XLength = series.XMax - series.XMin;
            }
            series.ChartMode = series.Points.length > 3000 ? ChartMode.Performance : ChartMode.Normal;
            this.ProcessYData(series, series.FullValue);
        }
    }
    UpdateWaveDataX() {
        const temp = [...this.Datas.values()].filter(d => d.LowFrequency == true);
        if (temp.length == 0) return;
        let target = null;
        for (const data of temp) {
            if (target == null || target.XLength < data.XLength) {
                target = data;
            }
        }
        for (const data of temp) {
            data.XMax = target.XMax;
            data.XMin = target.XMin;
        }
    }
    ProcessEigenData(datas) {
        let xmax = null,
            xmin = null;
        let dataMap = [];
        for (const data of datas) {
            if (!this.Datas.has(data.PointId)) {
                continue;
            }
            let series = this.Datas.get(data.PointId);
            series.PointType = DataPointShape.Arc;
            series.XDataType = EnumXDataType.Time;
            if (data.SampleTime) series.Points.push(new DataPoint(data.SampleTime / this.xcofe, data.Eigens[0].Value));
            dataMap.push(series);
        }

        for (let series of dataMap) {
            if (series.Points.length > 0) {
                let min = series.Points[0].X;
                let max = series.Points[series.Points.length - 1].X;
                if (xmin == null || xmin > min) xmin = min;
                if (xmax == null || xmax < max) xmax = max;
            }
        }

        if (this.XMin == null) this.XMin = xmin;
        this.XMax = xmax;
        if (this.XMax - this.XMin >= this.TimeLength * 1000) this.XMin = this.XMax - this.TimeLength * 1000;
        else {
            this.XMax = this.XMin + this.TimeLength * 1000;
        }

        for (const series of dataMap) {
            series.XMin = this.XMin;
            series.XMax = this.XMax;
            let points = new Array();
            let lastPoint = null;
            for (let y = 0; y < series.Points.length; y++) {
                let p = series.Points[y];
                if (p.X >= series.XMin) {
                    points.push(p);
                } else {
                    lastPoint = p;
                }
            }
            if (lastPoint != null) {
                points.unshift(lastPoint);
            }
            series.Points = null;
            series.Points = points;
            points = null;
            this.ProcessYData(series, series.FullValue);
        }
        dataMap = null;
    }
    CreateGraph() {
        this.Graph = new CustomAnalysisChart();
        this.Graph.Init(this.Option, this.Plugins);
        this.Graph.OnChartChanged();
    }
    getOrderDataSeries(serieses) {
        const registerPoints = this.Option.RegisterPoints;
        const result = [];
        if (registerPoints?.length > 0) {
            for (const rp of registerPoints) {
                const series = serieses.filter((r) => r.PointId.toString().startsWith(rp.PointId + "_") || r.PointId == rp.PointId);
                if (series.length > 0) {
                    result.push(...series);
                }
            }
            return result;
        } else {
            return serieses;
        }
    }
}
