import store from "@common/js/store";
import dayjs from "dayjs";
import { LineShapeOperator } from "./Dh-shape-line";

export class NavigateLineShapeOperator extends LineShapeOperator {
    constructor() {
        super();
        this.diluteCount = 3000;
    }
    EnsureContainerCliped() {
        const rect = this.ChartContext.Information.ShapeContainerRect;
        this.drawApi.SetContext(this.Id);
        this.drawApi.ClipRect((rect.Left - 2), rect.Top, (rect.Width + 4), rect.Height);
    }
    OnRender() {
        super.OnRender();
        this.DrawEndPoint();
    }
    DrawSeries(series) {
        if (series.DrawShape == true) {
            this.DrawCountourSeries(series);
        } else {
            super.DrawSeries(series);
        }
    }
    DrawCountourSeries(series) {
        let temp = this.GetDilutePoints(series.Points, series);
        const coordSystem = this.ChartContext.CoordinateSystem;
        let ptArray = coordSystem.GetSceenPoints(temp.Points);
        const orginBreaks = series.Breaks;
        series.Breaks = temp.Breaks;
        this.DrawContour(ptArray, series);
        series.Breaks = orginBreaks;
        temp = null;
    }
    DrawContour(ptArray, data) {
        if (this.drawApi == null || ptArray.length < 3 || data == null) return;
        if (data.Breaks?.length > 0) {
            let index = 0;
            let breakIndex = 0;
            while (index < ptArray.length) {
                let end = data.Breaks.length > breakIndex ? data.Breaks[breakIndex] : ptArray.length;
                this.drawApi.Save();
                this.drawApi.BeginPath();

                for (let i = index; i < end - 1; i++) {
                    this.drawApi.MoveTo(ptArray[i].X, ptArray[i].Y);
                    this.drawApi.LineTo(ptArray[i + 1].X, ptArray[i + 1].Y);
                    if (i + 2 < end) this.drawApi.LineTo(ptArray[i + 2].X, ptArray[i + 2].Y);
                }
                this.drawApi.Fill(data.LineColor, data.LineColor);
                this.drawApi.Restore();
                index = end;
                breakIndex++;
            }
        }
        else {
            this.drawApi.Save();
            this.drawApi.BeginPath();
            for (let i = 0; i < ptArray.length - 1; i++) {
                this.drawApi.MoveTo(ptArray[i].X, ptArray[i].Y);
                this.drawApi.LineTo(ptArray[i + 1].X, ptArray[i + 1].Y);
                if (i + 2 < ptArray.length) this.drawApi.LineTo(ptArray[i + 2].X, ptArray[i + 2].Y);
            }
            this.drawApi.Fill(data.LineColor, data.LineColor);
            this.drawApi.Restore();
        }
    }
    GetDilutePoints(points, series) {
        const result = {
            Points: [],
            Breaks: []
        }
        if (series.Breaks == null) series.Breaks = [];
        let { start, end, length } = this.getVisiableRange(points);
        if (start == -1 || end == -1 || length <= this.diluteCount) {
            if (start == -1) start = 0;
            if (end == -1) end = points.length - 1;
            result.Points = points.slice(start, end + 1);
            result.Breaks = series.Breaks.filter(b => b >= start && b <= end).map(b => b - start);
            return result;
        }
        const diluteRate = (Math.floor((length - 1) / this.diluteCount) + 1) * 2;
        for (let i = start; i <= end; i = i + diluteRate) {
            let maxIndex = i,
                minIndex = i;
            for (let j = 1; j < diluteRate; j++) {
                if (i + j <= end) {
                    if (points[i + j].Y > points[maxIndex].Y) {
                        maxIndex = i + j;
                    }
                    if (points[i + j].Y < points[minIndex].Y) {
                        minIndex = i + j;
                    }
                }
            }
            if (maxIndex > minIndex) {
                result.Points.push(points[minIndex]);
                result.Points.push(points[maxIndex]);
            } else {
                result.Points.push(points[maxIndex]);
                result.Points.push(points[minIndex]);
            }
        }
        const breaks = series.Breaks.filter(b => b >= start && b <= end);
        this.getContourBreaks(points, breaks, result);
        return result;
    }
    getVisiableRange(points) {
        const coordInfo = this.ChartContext.Information.CoordinateInfo;
        let start = -1, end = -1;
        for (let i = 0; i < points.length; i++) {
            if (coordInfo.XMin <= points[i].X && start == -1) start = i;
            if (coordInfo.XMax >= points[i].X) end = i;
        }
        let length = end - start + 1;
        if (start > 0) start = start - 1;
        if (end > 0 && end < points.length - 1) end = end + 1;
        return { start, end, length };
    }
    getContourBreaks(points, breaks, result) {
        if (breaks.length > 0 && result.Points.length > 0) {
            let range = this.getBreakRange(0, points, breaks, result.Points[0]);
            for (let i = 0; i < result.Points.length - 1; i++) {
                const p = result.Points[i];
                const pNext = result.Points[i + 1];
                if (range == null) break;
                if (p.X > range.x1) {
                    range = this.getBreakRange(range.index + 1, points, breaks, p);
                }
                if (p.X == range.x1 || (p.X < range.x1 && pNext.X > range.x2)) {
                    result.Breaks.push(i + 1);
                    range = this.getBreakRange(range.index + 1, points, breaks, p);
                } else if (pNext.X == range.x2) {
                    result.Breaks.push(i);
                    range = this.getBreakRange(range.index + 1, points, breaks, p);
                }
            }
        }
    }
    getBreakRange(index, points, breaks, currPoint) {
        for (let i = index; i < breaks.length; i++) {
            if (points[breaks[i]].X > currPoint.X) {
                return {
                    x1: breaks[i] == 0 ? points[breaks[i]].X : points[breaks[i] - 1].X,
                    x2: points[breaks[i]].X,
                    index: i
                }
            }
        }
        return null;
    }
    DrawEndPoint() {
        const fontStyle = ` normal ${14}px Arial`;
        const info = this.ChartContext.Information;
        const coordinateSystem = this.ChartContext.CoordinateSystem;
        if (coordinateSystem.ShowEndPoint == false || store.state.browseType === 'app') return;
        const rect = info.ShapeContainerRect;
        const xminText = dayjs(info.CoordinateInfo.XMin).format("YYYY-MM-DD HH:mm:ss.SSS");
        const xminSize = this.drawApi.MeasureText(xminText, fontStyle, fontStyle);
        this.drawApi.FillText(xminText, rect.Left, (rect.Top + rect.Height) - xminSize.Height / 2, coordinateSystem.XAxisTextColor, fontStyle);
        const xmaxText = dayjs(info.CoordinateInfo.XMax).format("YYYY-MM-DD HH:mm:ss.SSS");
        const xmaxSize = this.drawApi.MeasureText(xmaxText, fontStyle, fontStyle);
        this.drawApi.FillText(
            xmaxText,
            (rect.Left + rect.Width) - xmaxSize.Width,
            (rect.Top + rect.Height) - xmaxSize.Height / 2,
            coordinateSystem.XAxisTextColor,
            fontStyle
        );
    }
}

export class RecorderNavigateLineShapeOperator extends NavigateLineShapeOperator {
    constructor() {
        super();
    }
    DrawCountourSeries(series) {
        const coordSystem = this.ChartContext.CoordinateSystem;
        let ptArray = coordSystem.GetSceenPoints(series.Points);
        this.DrawContour(ptArray, series);
    }
    DrawContour(ptArray, data) {
        if (this.drawApi == null || ptArray.length < 3 || data == null) return;
        let index = 0;
        let breakIndex = 0;
        while (index < ptArray.length) {
            let end = ptArray.findIndex((p, i) => p.Break && i > breakIndex);
            if (end < 0) end = ptArray.length;
            this.drawApi.Save();
            this.drawApi.BeginPath();

            for (let i = index; i < end - 1; i++) {
                this.drawApi.MoveTo(ptArray[i].X, ptArray[i].Y);
                this.drawApi.LineTo(ptArray[i + 1].X, ptArray[i + 1].Y);
                if (i + 2 < end) this.drawApi.LineTo(ptArray[i + 2].X, ptArray[i + 2].Y);
            }
            this.drawApi.Fill(data.LineColor, data.LineColor);
            this.drawApi.Restore();
            index = end;
            breakIndex++;
        }
    }
}