import { nextTick } from "vue";
import { AlarmTypeEnum } from "@common/js/enum";
import { getHexOpacityColor, i18nGlobal } from "@common/js/utils";
import { LineMode } from "@core/graphs/webcharts/Dh-webchart-models";
import store from "@common/js/store";
import dayjs from "dayjs";

export const ComponentType = {
	None: 0,
	TrendGraph: 1, //趋势图
	WaveGraph: 2, //波形图
	SpectrumGraph: 3, //频谱图
	WaterfallGraph: 4, //瀑布图
	AxisPositionGraph: 5, //轴心位置图
	BarGraph: 6, //棒图
	BodePlotsGraph: 7, //伯德图
	DashBoard: 8, //仪表盘
	NavigateGraph: 9, //导航图谱 已弃用
	NumberTable: 10, //数字表
	CascadeGraph: 11, //级联图
	PolarGraph: 12, //极坐标
	Calendar: 13, //日历
	NavigateBar: 14, //导航条
	MachineTree: 15, //设备树
	Image: 16, //图片
	ThreeD: 17, //结构模型
	EditBox: 18, //自定义编辑框
	Map: 19, //地图
	Video: 20, //视频
	DeviceBrowse: 21, //设备浏览 已弃用
	IIRAlgorithmGraph: 22, //IIR数字滤波
	OnceIntegrayAlgorithmGraph: 23, //一次积分
	OnceDifferentialAlgorithmGraph: 24, //一次微分
	RosetteAlgorithmGraph: 25, //应变花
	AmplitudeAnalysisAlgorithmGraph: 26, //幅值分析
	Report: 27,
	TwiceIntegrayAlgorithmGraph: 28, //二次积分
	TwiceDifferentialAlgorithmGraph: 29, //二次微分
	AlarmCalendar: 30,
	BarStatistics: 31,
	PointStateStatistics: 32,
	AlarmReport: 33,
	FaultGraph: 35,
	GraphisDisplay: 36,
	RecorderGraph: 37, //记录仪
	XYRecordGraph: 38, //XY记录仪
	Document: 39, //文档
	VirtualComputingAlgorithmGraph: 40, //虚拟计算
	RainflowAlgorithmGraph: 41, //雨流分析
	FreqIntegralAlgorithmGraph: 42, //频域积分
	MultiSampleAlgorithmGraph: 43, //重采样
	EnvelopeAlgorithmGraph: 44, //包络波形
	OrderAnalysisGraph: 45, //阶次分析
	OctaveAlgorithmGraph: 46, //倍频程
	SoundIntensityAlgorithmGraph: 47, //声强分析
	SoundPowerAlgorithmGraph: 48, //声功率分析
	TorsionAlgorithmGraph: 49, //扭矩分析
	ShaftPowerAlgorithmGraph: 50, //轴功率分析
	CableForceAlgorithmGraph: 51, //索力分析
	StaticsAnalysisAlgorithmGraph: 52, //统计分析
	Title: 53, //状态显示标题控件
	FIRAlgorithmGraph: 54, //FIR数字滤波
	PowerSpectrumDensityAlgorithmGraph: 55, //自功率谱密度
	CorrelationAlgorithmGraph: 56, //相关分析
	CovarianceAlgorithmGraph: 57, //协方差分析
	SelfPowerSpectrumAlgorithmGraph: 58, //自功率谱
	CrossPowerSpectrumAlgorithmGraph: 59, //互功率谱密度
	CepstrumAlgorithmGraph: 60, //倒谱分析
	WholePeriodWaveGraph: 61, //整周期波形图
	VibrationListAlgorithmGraph: 62, //振动列表
	EnvelopeSpectrumAlgorithmGraph: 63, //包络谱
	SoundPressureAlgorithmGraph: 64, //声压分析
	TimeDomainODS: 65, //时域ODS
	FreqDomainODS: 66, //频域ODS
	TorsionalVibrationAlgorithmGraph: 67, //扭振分析
	WaveletAlgorithmGraph: 68, //小波分析
	AxisTrailGraph: 69, //轴心轨迹图
	AlarmInfo: 70, //实时报警信息
	FaultAlarmReport: 71, //故障报警报表
	FFTAlgorithmGraph: 72, //频谱分析
	StudySelfGraph: 73, //自学习图谱
	HealthStatus: 74, //健康状态,
	EnvelopeAlgorithmGraphEx: 75, //工作状态中的包洛波形
	DeviceStatus: 76, //设备健康状态
	EnergyEvalution: 77, //能量评估
	PointValue: 200, //厦门环寂定制组件
};

export const GraphDataTypeEnum = {
	Point: 1, //测点数据
	File: 2, //文件数据
};

export const LabelType = {
	Video: 1,
	Navigate: 2,
	MultiplePoint: 3,
	PointEigen: 4,
	Graph: 5,
};

export const EnumQueryDataType = {
	Eigen: 0,
	Wave: 1,
};

export const EnumQueryType = {
	/**模糊 */
	Fuzzy: 0,
	/**精确 */
	Precise: 1,
};

//算法类型
export const AlgorithmTypeEnum = {
	None: 0,
	IIRFilte: 1, // IIR滤波
	IntegralOnce: 2, // 一次积分
	IntegralTwice: 3, // 二次积分
	DifferentialOnce: 4, // 一次微分
	DifferentialTwice: 5, // 二次微分
	StatisticAnalysis: 6, // 统计分析
	SoundIntensityAnalysis: 8, // 声强分析
	SoundPowerAnalysis: 9, // 声功率分析
	StrainRosetteAnalysis: 10, // 应变花计算
	TorqueAnalysis: 11, // 扭矩分析
	ShaftPowerAnalysis: 12, // 轴功率分析
	CableAnalysis: 13, // 索力分析
	EnvelopeAnalysis: 14, //包络分析
	FIRFilte: 15, // FIR滤波
	CorrelationAnalysis: 16, // 相关分析
	BendingAnalysis: 17, // 弯矩分析
	HealthCalculate: 18, // 健康计算
	HealthCalcMachine: 19, //设备健康
	VirtualCompute: 20, //虚拟计算
	ResidualStress: 22, // 残余应力
	FreqDomainAnalysis: 100, //频域分析开始
	SpectrumAnalysis: 101, //频谱分析
	Octave: 103, //倍频程分析
	OrderAnalysis: 105, //阶次分析
	EnvelopeSpectrum: 106, //包络谱
	SelfPowerSpectrum: 107, //自功率谱
	CrossPowerSpectrumDensity: 108, //互功率谱密度
	PowerSpectrumDensity: 109, //功率谱密度
	Cepstrum: 110, //倒谱
	FreqIntegral: 111, //频域积分
	VirtualComputeFreq: 120,//虚拟计算频域
	AmplitudeDomainAnalysis: 200, //幅值域分析开始
	RainflowAnalysis: 201, // 雨流分析
	AmpAnalysis: 202, // 幅值分析
	EnergyEvalution: 203, //能量评估
	TrendForecast: 204, // 趋势预测
	EigenFiltering: 205, // 特征值滤波
	RoateDomain: 500,//转频域
	WholePeriodSample: 501,//整周期采样
	CycleTorsionslVibration: 502,//扭振角度
	BlockSample: 601,//块采样
	VirtualComputeEigen: 1001,//虚拟计算特征值
};

export const AlgroithmGroups = {
	0: 0,
	1: 0, // IIR滤波
	2: 0, // 一次积分
	3: 0, // 二次积分
	4: 0, // 一次微分
	5: 0, // 二次微分
	10: 0, // 应变花计算
	11: 0, // 扭矩分析
	12: 0, // 轴功率分析
	14: 14, //包络分析
	15: 0, // FIR滤波
	16: 16, // 相关分析
	20: 0, //虚拟计算
	22: 0, // 残余应力
	90: 90,//整周期采样
	101: 100, //频谱分析
	105: 105, //阶次分析
	106: 100, //包络谱
	107: 100, //自功率谱
	108: 100, //互功率谱密度
	109: 109, //功率谱密度
	110: 110, //倒谱
	111: 0, //频域积分
	200: 200, //幅值域分析开始
	201: 201, // 雨流分析
	202: 202, // 幅值分析
	204: 204, // 趋势预测
	501: 501,//整周期采样
	502: 502,//扭振角度
	601: 601,//块采样
}

export class SignalBase {
	constructor() {
		this.SignalPoints = [];
		this.Id = null;
		this.AlgorithmType = null;
	}
}


export class AlarmFilter {
	constructor() {
		this.FilterType = 1;
		this.Data = {
			overrun: [{
				Switch: false,
				ReferenceValue: 0,
				Name: i18nGlobal('platformSetting.settingCenter.alarm.overLimit')
			}],
			lowLimit: [{
				Switch: false,
				ReferenceValue: 0,
				Name: i18nGlobal('platformSetting.settingCenter.alarm.belowLimit')
			}],
			inWindow: [{
				Switch: false,
				Low: 0,
				High: 0,
				Name: i18nGlobal('platformSetting.settingCenter.alarm.inWindow')
			}],
			outWindow: [{
				Switch: false,
				Low: 0,
				High: 0,
				Name: i18nGlobal('platformSetting.settingCenter.alarm.outWindow')
			}],
			equal: [{
				Switch: false,
				ReferenceValue: 0,
				Name: i18nGlobal('platformSetting.settingCenter.alarm.equal')
			}]
		}
	}
}

export class AlarmBase {
	constructor() {
		this.Guid = null;
		// this.AlarmCategory = AlarmTypeModelEnum.Amplitude;
		this.RegisterPoints = [];
		this.IsEnabled = false;
	}
}

export class AmplitudeAlarm extends AlarmBase {
	constructor() {
		super();
		this.Flag = 0;
		this.AmplitudeAlarmType = AlarmTypeEnum.MoreLimit;
		this.Warning1 = 0;
		this.Danger1 = 0;
		this.Warning2 = 0;
		this.Danger2 = 0;
		this.IsLessLimitEnable = false;
		this.LessLimit = 1;
		this.Levels = [];
	}
}

export class MutationAlarm extends AlarmBase {
	constructor() {
		super();
		this.Flag = 0;
		this.Alarm = 0;
		this.Danger = 0;
		this.MutationTrigger = 0.01;
		this.Levels = [];
	}
}

export class BandwidthAlarm extends AlarmBase {
	constructor() {
		super();
		this.ParamType = 0;
		this.OperateType = 0;
		this.Details = "[]";
	}
}

export class SelfLearningAlarm extends AlarmBase {
	constructor() {
		super();
		this.StartTime = dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss");
		this.EndTime = dayjs(new Date()).add(1, "minute").format("YYYY-MM-DD HH:mm:ss");
		this.Sigma = 0;
		this.WeightLimit = 0;
	}
}

export class AlarmStorage {
	constructor() {
		this.IsSave = true;
		this.Before = 10;
		this.After = 10;
	}
}

export class DataProcessUnit {
	constructor() {
		this.Id = null;
		this.Name = null;
		this.DeviceId = null;
		this.IsShowWave = true;
		this.IsSaveWaveData = true;
		this.AlarmStorage = new AlarmStorage();
		this.ModelCategory = null;
		this.ComponentName = null;
		this.Description = null;
		this.ComponentType = null;
		this.ComponentTitle = null;
		this.ComponentId = null;
		this.IsSaveAlarm = false;
		this.SignalParam = {
			AlgorithmType: null,
			SignalParam: null,
		};
	}
}

export const EnumAlarmFlag = {
	None: 0,
	WarnOnly: 1,
	DangerOnly: 2,
	WarnAndDanger: 3,
};

export const EnumModelCategory = {
	/** 趋势分析*/
	Trend: 0,
	/** 波形分析*/
	Wave: 1,
	/** IIR数字滤波*/
	IIR: 2,
	/** 频谱分析*/
	FFT: 3,
	/** 一次积分*/
	OnceIntegray: 4,
	/** 一次微分*/
	OnceDifferential: 5,
	/** 应变花*/
	Rosette: 6,
	/** 幅值分析*/
	AmplitudeAnalysis: 7,
	/** 二次积分*/
	TwiceIntegray: 8,
	/** 二次微分*/
	TwiceDifferential: 9,
	/** 虚拟计算*/
	VirtualComputing: 10,
	/** 雨流分析*/
	Rainflow: 11,
	/** 频域积分*/
	FreqIntegral: 12,
	/** 重采样*/
	MultiSample: 13,
	/** 包络波形*/
	Envelope: 14,
	/** 阶次分析*/
	OrderAnalysis: 15,
	/** 倍频程*/
	Octave: 16,
	/** 声强分析*/
	SoundIntensity: 17,
	/** 声功率分析*/
	SoundPower: 18,
	/** 扭矩分析*/
	Torsion: 19,
	/** 轴功率分析*/
	ShaftPower: 20,
	/** 索力分析*/
	CableForce: 21,
	/** 统计分析*/
	StaticsAnalysis: 22,
	/** FIR数字滤波*/
	FIR: 23,
	/** 自功率谱密度*/
	PowerSpectrumDensity: 24,
	/** 相关分析*/
	Correlation: 25,
	/** 协方差分析*/
	Covariance: 26,
	/** 自功率谱*/
	SelfPowerSpectrum: 27,
	/** 互功率谱密度*/
	CrossPowerSpectrum: 28,
	/** 倒谱分析*/
	Cepstrum: 29,
	/** 振动列表*/
	VibrationList: 30,
	/** 包络谱*/
	EnvelopeSpectrum: 31,
	/** 声压分析*/
	SoundPressure: 32,
	/** 时域ODS*/
	TimeDomainODS: 33,
	/** 频域ODS*/
	FreqDomainODS: 34,
	/** 扭振分析*/
	TorsionalVibration: 35,
	/** 小波分析*/
	Wavelet: 36,
	/** 能量评估*/
	EnergyEvalution: 37,
	/** 残余应力*/
	ResidualStress: 38
};

export const EnumComponentTheme = {
	Light: 1,
	Blue: 2,
	Dark: 3,
	Shadow: 4
};

export const EnumVideoType = {
	Direct: 1,
	SunPlatform: 2,
	EZOpen: 3
};

export class Line {
	constructor(x1, y1, x2, y2, thickness, color) {
		this.X1 = x1 + 0.5;
		this.Y1 = y1 + 0.5;
		this.X2 = x2 + 0.5;
		this.Y2 = y2 + 0.5;
		this.Thickness = thickness;
		this.Color = color;
	}
	DrawLine(ctx) {
		ctx.beginPath();
		ctx.moveTo(this.X1, this.Y1);
		ctx.lineTo(this.X2, this.Y2);
		ctx.lineWidth = this.Thickness;
		ctx.strokeStyle = this.Color;
		ctx.globalAlpha = 0.3;
		ctx.closePath();
		ctx.stroke();
	}
}

export class GradientStop {
	constructor(stop, color) {
		this.Stop = stop;
		this.Color = color;
	}
}

export class GradientLine extends Line {
	constructor(x1, y1, x2, y2, thickness, color) {
		super(x1, y1, x2, y2, thickness, color);
	}
	DrawLine(ctx) {
		ctx.beginPath();
		ctx.moveTo(this.X1, this.Y1);
		ctx.lineTo(this.X2, this.Y2);
		var gnt = ctx.createLinearGradient(this.X1, this.Y1, this.X2, this.Y2);
		for (var i = 0; i < this.Color.length; i++) {
			gnt.addColorStop(this.Color[i].Stop, this.Color[i].Color);
		}
		ctx.lineWidth = this.Thickness;
		ctx.strokeStyle = gnt;
		ctx.closePath();
		ctx.stroke();
	}
}

export class RadialGradientLine extends Line {
	constructor(x1, y1, r1, x2, y2, r2, startX, startY, width, height, color) {
		super(x1, y1, x2, y2, 0, color);
		this.R1 = r1;
		this.R2 = r2;
		this.StartX = startX;
		this.StartY = startY;
		this.Width = width;
		this.Height = height;
	}
	DrawLine(ctx) {
		ctx.beginPath();
		var gnt = ctx.createRadialGradient(this.X1, this.Y1, this.R1, this.X2, this.Y2, this.R2);
		for (var i = 0; i < this.Color.length; i++) {
			gnt.addColorStop(this.Color[i].Stop, this.Color[i].Color);
		}
		ctx.fillStyle = gnt;
		ctx.ellipse(this.X1, this.Y1, this.Width / 2, this.Height / 2, 0, 0, Math.PI * 2);
		ctx.closePath();
		ctx.fill();
	}
}

export const ThemeResource = {
	1: {
		Colors: () => {
			if (store.state.customColor.GraphTheme) {
				return ["#07c3fd", "#50D017", "#F1B531", "#C9490B", "#05A5B7", "#F131A9", "#05DCEB", "#EE6569", "#05B6A3", "#6E08A9", "#1A8EF1", "#FD8251", "#92CC76", "#A13F53"];
			} else {
				return ["#3358CE", "#F1B531", "#50D017", "#AA3EF2", "#C9490B", "#05A5B7", "#F131A9", "#05DCEB", "#EE6569", "#05B6A3", "#6E08A9", "#1A8EF1", "#FD8251", "#92CC76", "#A13F53"];
			}
		},
		AxisTextColor: () => {
			return store.state.customColor.TextColor;
		},
		LineColor: () => {
			return store.state.customColor.TextColor;
		},
		CrossColor: () => {
			return getHexOpacityColor(store.state.customColor.TooltipColor, 0.3);
		},
		CrossStyle: () => {
			return LineMode.Solid;
		},
		TitleColor: () => {
			return store.state.customColor.TitleColor;
		},
		DotColor: () => {
			return store.state.customColor.TitleColor;
		},
	},
	2: {
		Colors: () => {
			return [
				"#04b6ff",
				"#FFC1C1",
				"#d8c54a",
				"#32CD32",
				"#b76d27",
				"#FF6EB4",
				"#3358CE",
				"#F1B531",
				"#50D017",
				"#AA3EF2",
				"#C9490B",
				"#05A5B7",
				"#F131A9",
				"#05DCEB",
				"#EE6569",
				"#05B6A3",
				"#6E08A9",
				"#1A8EF1",
				"#FD8251",
				"#92CC76",
				"#A13F53",
			];
		},
		AxisTextColor: () => {
			return "#ffffff";
		},
		LineColor: () => {
			return "#3772c5";
		},
		CrossColor: () => {
			return "#3772c580";
		},
		CrossStyle: () => {
			return LineMode.Dash;
		},
		TitleColor: () => {
			return "white";
		},
		DotColor: () => {
			return "red";
		},
	},
	3: {
		Colors: () => {
			return [
				"#04b6ff",
				"#FFC1C1",
				"#d8c54a",
				"#32CD32",
				"#b76d27",
				"#FF6EB4",
				"#3358CE",
				"#F1B531",
				"#50D017",
				"#AA3EF2",
				"#C9490B",
				"#05A5B7",
				"#F131A9",
				"#05DCEB",
				"#EE6569",
				"#05B6A3",
				"#6E08A9",
				"#1A8EF1",
				"#FD8251",
				"#92CC76",
				"#A13F53",
			];
		},
		AxisTextColor: () => {
			return "#ffffff";
		},
		LineColor: () => {
			return "#3772c5";
		},
		CrossColor: () => {
			return "#3772c580";
		},
		CrossStyle: () => {
			return LineMode.Dash;
		},
		TitleColor: () => {
			return "white";
		},
		DotColor: () => {
			return "red";
		},
	},
	4: {
		Colors: () => {
			return [
				"#04b6ff",
				"#FFC1C1",
				"#d8c54a",
				"#32CD32",
				"#b76d27",
				"#FF6EB4",
				"#3358CE",
				"#F1B531",
				"#50D017",
				"#AA3EF2",
				"#C9490B",
				"#05A5B7",
				"#F131A9",
				"#05DCEB",
				"#EE6569",
				"#05B6A3",
				"#6E08A9",
				"#1A8EF1",
				"#FD8251",
				"#92CC76",
				"#A13F53",
			];
		},
		AxisTextColor: () => {
			return "#ffffff";
		},
		LineColor: () => {
			return "#3772c5";
		},
		CrossColor: () => {
			return "#3772c580";
		},
		CrossStyle: () => {
			return LineMode.Dash;
		},
		TitleColor: () => {
			return "white";
		},
		DotColor: () => {
			return "red";
		},
	},
};

export const updatePropertyLayout = (container, config) => {
	nextTick(() => {
		let blocks = container.querySelectorAll(`.param-container .property-col`);
		let index = 0;
		for (let block of blocks) {
			if (index % 3 == 0) block.setAttribute("style", `margin:0px ${config ? config.marginRight : "20px"} 5px 0px`);
			if (index % 3 == 1) block.setAttribute("style", `margin:0px ${config ? config.marginRight : "20px"} 5px 0px`);
			if (index % 3 == 2) block.setAttribute("style", "margin:0px 0px 5px 0px");
			index++;
		}
	});
};

export const LabelAlarmLevel = {
	Normal: 1,
	Warning: 2,
	Danger: 3,
};

export const Orientation = {
	Horizontal: 0,
	Vertical: 1,
};

export class Tuple {
	constructor(t1, t2) {
		this.Item1 = t1;
		this.Item2 = t2;
	}
}

export const EigenFrom = {
	SourcePoint: 0,
	CheckedPoint: 1
}

export const TitleAlign = {
	Left: 0,
	Center: 1,
};

