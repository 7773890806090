import { SignalRInstance } from "@common/js/signalRClient";
import { i18nGlobal } from "@common/js/utils";
import _ from "lodash";
import { BlockDataRealContext } from "./blockDataRealContext";
import { EigenDataRealContext, TrendEigenDataRealContext } from "./eigenDataRealContext";
import { OriginDataRealContext } from "./orginDataRealContext";
import { LatestWaveDataRealContext } from "./latestDataContext";
import * as DspContext from "./dspDataRealContext";
import * as Recorder from "./recordDataHistoryContext";
import { getPoint } from "@components/js/utils/componentBase";
import { BlockEigenDataRealContext } from "./blockEigenDataRealContext";

export const ChartRealType = {
	Block: 0, //块数据
	Eigen: 1, //特征值数据
	Orgin: 2, //原始数据
	LatestData: 3,//波形最新值
	Spectral: 4, //频域图谱推送类型
	FreqIntegral: 5, // 频域积分
	CrossPowerSpecDens: 6, // 互功率谱密度
	AxisPosition: 7, //轴心位置
	AxisTrail: 8, //轴心轨迹
	BodePlots: 9, //伯德图,
	WholePeriod: 10, //整周期
	Polar: 11, //极坐标
	AmplitudeAnalysis: 12, //幅值分析
	CablePre: 113,
	CepstrumAnalysis: 14, //倒谱分析
	CorrelationAnalysis: 15, //相关分析
	TorvibMonitoring: 16, //扭振监测
	Torque: 17, // 扭矩分析
	ShaftPower: 18, // 轴功率
	TrendEigen: 20,//趋势特征值
	BlockEigen: 21,
	Timing: 22,//定时器
	RecorderCached: 23,
	RecorderDilution: 24
};

export const getEnvelopData = (datas) => {
	const result = [];
	for (const data of datas) {
		if (data.AlgorithmType === 14 || data.AlgorithmType === 106) {
			const waveData = data.Datas;
			const lowerData = createEnvelopData(data, waveData, 1, i18nGlobal('envelopeJS.upperEnvelope'));
			const upperData = createEnvelopData(data, waveData, 2, i18nGlobal('envelopeJS.lowerEnvelope'));
			result.push(lowerData);
			result.push(upperData);
			if (waveData?.DataCount > 0) {
				for (let m = 0; m < waveData.DataCount; m++) {
					const n = waveData.Points[m];
					if (m >= waveData.DataCount / 2) {
						upperData.Datas.Points.push(n);
					} else {
						lowerData.Datas.Points.push(n);
					}
				}
			}
		} else {
			result.push(data)
		}
	}
	return result;
}

const createEnvelopData = (data, waveData, flex, name) => {
	data.Datas = null;
	const tempData = _.cloneDeep(data);
	tempData.PointId += "_" + flex;
	if (tempData.Param) {
		tempData.Param.Name += "_" + name;
		tempData.Param.PointId += "_" + flex;
	}
	if (waveData?.DataCount > 0) {
		tempData.Datas = {
			DataCount: waveData.DataCount / 2,
			XDataType: waveData.XDataType,
			Points: [],
		};
	}
	return tempData;
}

export class ClientRealContext {
	static _instance = null;
	static GetInstance(chartRealType) {
		if (ClientRealContext._instance == null) {
			ClientRealContext.initial();
		}
		return ClientRealContext._instance.get(chartRealType);
	}
	static initial() {
		const signalRinstance = SignalRInstance.GetInstance();
		ClientRealContext._instance = new Map();
		ClientRealContext._instance.set(ChartRealType.Block, new BlockDataRealContext());
		ClientRealContext._instance.set(ChartRealType.Eigen, new EigenDataRealContext());
		ClientRealContext._instance.set(ChartRealType.Orgin, new OriginDataRealContext());
		ClientRealContext._instance.set(ChartRealType.LatestData, new LatestWaveDataRealContext());
		ClientRealContext._instance.set(ChartRealType.Spectral, new DspContext.SpectrumDspDataRealContext());
		ClientRealContext._instance.set(ChartRealType.FreqIntegral, new DspContext.FreqIntegralDspDataRealContext());
		ClientRealContext._instance.set(ChartRealType.CrossPowerSpecDens, new DspContext.CrossPowerSpectrumDensDspDataRealContext());
		ClientRealContext._instance.set(ChartRealType.AxisPosition, new DspContext.AxisPositionDspDataRealContext());
		ClientRealContext._instance.set(ChartRealType.AxisTrail, new DspContext.AxisTrailDspDataRealContext());
		ClientRealContext._instance.set(ChartRealType.BodePlots, new DspContext.BodePlotsDspDataRealContext());
		ClientRealContext._instance.set(ChartRealType.WholePeriod, new DspContext.WholePeriodDspDataRealContext());
		ClientRealContext._instance.set(ChartRealType.Polar, new DspContext.PolarDspDataRealContext());
		ClientRealContext._instance.set(ChartRealType.AmplitudeAnalysis, new DspContext.AmplitudeAnalysisDspDataRealContext());
		ClientRealContext._instance.set(ChartRealType.CablePre, new DspContext.CablePreDspDataRealContext());
		ClientRealContext._instance.set(ChartRealType.CepstrumAnalysis, new DspContext.CepstrumAnalysisDspDataRealContext());
		ClientRealContext._instance.set(ChartRealType.CorrelationAnalysis, new DspContext.CorrelationAnalysisDspDataRealContext());
		ClientRealContext._instance.set(ChartRealType.TorvibMonitoring, new DspContext.TorvibMonitoringDspDataRealContext());
		ClientRealContext._instance.set(ChartRealType.Torque, new DspContext.TorqueDspDataRealContext());
		ClientRealContext._instance.set(ChartRealType.ShaftPower, new DspContext.ShaftPowerDspDataRealContext());
		ClientRealContext._instance.set(ChartRealType.RecorderCached, new Recorder.RecorderCachedDataContext());
		ClientRealContext._instance.set(ChartRealType.RecorderDilution, new Recorder.RecorderCachedDilutionDataContext());
		ClientRealContext._instance.set(ChartRealType.TrendEigen, new TrendEigenDataRealContext());
		ClientRealContext._instance.set(ChartRealType.BlockEigen, new BlockEigenDataRealContext());
		ClientRealContext._instance.set(ChartRealType.Timing, new DspContext.TimingDspDataRealContext());
		ClientRealContext._instance.forEach(h => {
			signalRinstance.Add(h);
		});
	}
	static Clear() {
		ClientRealContext._instance.forEach(h => h.Clear());
		ClientRealContext._instance.clear();
		ClientRealContext._instance = null;
	}
}


class PointCacheContent {
	constructor() {
		this.pointsMap = new Map();
	}
	update(points) {
		points.forEach(point => {
			this.pointsMap.set(point.Id, point);
		})
	}
	async get(pointId) {
		if (this.pointsMap.has(pointId)) {
			return this.pointsMap.get(pointId);
		}
		else {
			const p = await getPoint(pointId);
			if (p) {
				this.pointsMap.set(p.Id, p);
			}
			return p;
		}
	}
	getPoint(pointId) {
		if (this.pointsMap.has(pointId)) {
			return this.pointsMap.get(pointId);
		}
		return null;
	}
}

export class PointCacheContext {
	static _instance = null;
	static GetInstance() {
		if (PointCacheContext._instance == null) {
			PointCacheContext._instance = new PointCacheContent();
		}
		return PointCacheContext._instance;
	}
}
