import $ from "jquery";
import { numberRound } from "@common/js/rules";
import { PluginMode } from "@core/graphs/webcharts/Dh-webchart-models";
import { DoubleCursorPlugin } from "./Dh-plugin-double-cursor";
import { i18nGlobal } from "@common/js/utils";

export class ThreeCursorPlugin extends DoubleCursorPlugin {
    constructor(mode) {
        super(mode);
        this.CursorCount = 3;
        this.Title = i18nGlobal('core.threeCursor');
    }
    UpdatePosition(data) {
        let sender = this;
        let rect = this.Owner.Information.ShapeContainerRect;
        var legend = this.Owner.PluginManager.GetPlugin(PluginMode.Statistics);
        for (let cursor of this.Cursors) {
            cursor.ClearPoints();
        }
        let container = this.LegendWnd.find(".cursor-container");
        if (data?.length > 0) {
            container.empty();
            let headContent = "";
            $.each(data, function (i, d) {
                if (d.XText !== undefined) {
                    headContent += "X" + (i + 1) + ":" + d.XText + "&emsp;";
                }
            });
            if (headContent) {
                container.append('<span class="cursor-row">' + headContent + "</span>");
            }

            const dataMap = new Map();
            data.forEach((cursorData, cursorIndex) => {
                if (cursorData.Items?.length > 0) {
                    cursorData.Items.forEach(dataItem => {
                        if (dataItem.Text) {
                            dataItem.CursorIndex = cursorIndex;
                            dataMap.has(dataItem.Text) ? dataMap.get(dataItem.Text).push(dataItem) : dataMap.set(dataItem.Text, [dataItem]);
                        }
                    })
                }
            })
            dataMap.forEach((dataItemList) => {
                let rowContent = "";
                dataItemList.forEach((dataItem) => {
                    rowContent += "Y" + (dataItem.CursorIndex + 1) + ":" + dataItem.Y + "&emsp;";
                    sender.Cursors[dataItem.CursorIndex].DrawPoint(dataItem.OffsetY + rect.Top);
                })
                container.append('<span class="cursor-row" style="color:' + dataItemList[0].Color + '">' + rowContent + "</span>");
            })

            this.CursorDatas = data;
            this.NotifyPositionChanged();
            $.each(this.Cursors, function (i, cursor) {
                let data = sender.CursorDatas[i];
                if (!isNaN(data.OffsetX) && isFinite(data.OffsetX)) {
                    if (data.OffsetX >= 0 && Math.floor(data.OffsetX) <= rect.Width) {
                        cursor.DOM.removeClass("hide");
                        cursor.DOM.attr("transform", "translate(" + (data.OffsetX - 6) + ",0)");
                    } else {
                        cursor.DOM.addClass("hide");
                    }
                }
            });
        } else {
            container.empty();
            $.each(this.Cursors, function (i, cursor) {
                cursor.DOM.attr("transform", "translate(-6,0)");
            });
        }
        legend?.OnRender();

        if (data?.length > 0) {
            let serieses = sender.Owner.ShapeOperator.Data;
            let legend = sender.Owner.PluginManager.GetPlugin(PluginMode.Statistics);
            let seriesData = null;
            $.each(serieses, function (i, series) {
                if (series.PointId == legend.SelectedItem) {
                    seriesData = series;
                }
            });
            if (seriesData == null) return;
            for (let i = 0; i < sender.CursorCount; i++) {
                for (let j = i + 1; j < sender.CursorCount; j++) {
                    let start = data[i].X;
                    let end = data[j].X;
                    if (start > end) {
                        start = data[j].X;
                        end = data[i].X;
                    }
                    let sum = 0;
                    let count = 0;
                    for (let k = 0; k < seriesData.Points.length; k++) {
                        if (seriesData.Points[k].X >= start && seriesData.Points[k].X <= end) {
                            sum += seriesData.Points[k].Y;
                            if (seriesData.Points[k].Y1) sum += seriesData.Points[k].Y1;
                            count++;
                        }
                    }
                    if (count == 0) count = 1;
                    let rowContent =
                        "X" +
                        (i + 1) +
                        ("X" + (j + 1)) +
                        " ΔT:" +
                        this.Owner.CoordinateSystem.FormatXSpanText(Math.abs(end - start)) +
                        " Ave(Y):" +
                        numberRound(sum / count, sender.Owner.CoordinateSystem.YDecimalPrecision);
                    container.append('<span class="cursor-row" style="color:' + seriesData.LineColor + '">' + rowContent + "</span>");
                }
            }
        }
    }
    GetZoomPosition() {
        if (this.CursorDatas.length > 0) {
            let datas = [...this.CursorDatas].orderBy((p) => p.X);
            return datas[1].X;
        } else {
            let info = this.Owner.Information;
            return (info.CoordinateInfo.XMin + info.CoordinateInfo.XMax) / 2;
        }
    }
}
