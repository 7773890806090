import dayjs from "dayjs";
import { guid } from "@common/js/utils";
import { CoordinateInfo, ChartInformation } from "@core/graphs/webcharts/Dh-webchart-coordinates";
import { ScaleType } from "@core/graphs/webcharts/Dh-webchart-models";
import { NavigateCoordinateSystem, RecorderNavigateCoordinateSystem } from "@core/graphs/coords/Dh-coordsystem-time";
import { TrendAnalysisChart } from "./Dh-analysischart-trend";
import { NavigateLineShapeOperator, RecorderNavigateLineShapeOperator } from "../shapes/Dh-shape-navigate";

export class NavigateAnalysisChart extends TrendAnalysisChart {
    constructor() {
        super();
    }
    GetCoordinateSystem() {
        return new NavigateCoordinateSystem(guid());
    }
    GetInformation() {
        let info = new ChartInformation();
        let xmax = Number(dayjs());
        let xmin = Number(dayjs().add(-1, "hour"));
        info.CoordinateInfo = new CoordinateInfo(xmax, xmin, 1000, 0, info);
        info.Padding = {
            Left: 60,
            Top: 0,
            Right: 90,
            Bottom: 0,
        };
        if (this.Option.Axis.PaddingRight) {
            info.Padding.Right = this.Option.Axis.PaddingRight;
        }
        return info;
    }
    GetShapeOperator() {
        return new NavigateLineShapeOperator();
    }
    UpdateCoords(ymax, ymin) {
        if (this.WebChart.Option.Axis.AxisScaleType == ScaleType.Auto) {
            let step = Math.abs(ymax - ymin) * 0.3;
            step = step == 0 ? 100 : step;
            ymax += step;
            ymin -= step;
        }
        if (ymax === ymin) ymax += 100;
        return { ymax, ymin };
    }
}

export class RecorderNavigateAnalysisChart extends NavigateAnalysisChart {
    constructor() {
        super();
    }
    GetShapeOperator() {
        return new RecorderNavigateLineShapeOperator();
    }
    GetCoordinateSystem() {
        return new RecorderNavigateCoordinateSystem(guid());
    }
}