// @generated by protobuf-ts 2.9.1
// @generated from protobuf file "protos/rangewavedatas.proto" (package "storage", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { HistoryPointDatasReply } from "./historypointdatasreply";
/**
 * The request message containing the user's name.
 *
 * @generated from protobuf message storage.GetRangeWaveDatasRequest
 */
export interface GetRangeWaveDatasRequest {
    /**
     * @generated from protobuf field: string StartTime = 1 [json_name = "StartTime"];
     */
    startTime: string;
    /**
     * @generated from protobuf field: string EndTime = 2 [json_name = "EndTime"];
     */
    endTime: string;
    /**
     * @generated from protobuf field: int32 Limit = 3 [json_name = "Limit"];
     */
    limit: number;
    /**
     * @generated from protobuf field: repeated int32 Points = 4 [json_name = "Points"];
     */
    points: number[];
    /**
     * @generated from protobuf field: bool IsExternal = 5 [json_name = "IsExternal"];
     */
    isExternal: boolean;
}
/**
 * @generated from protobuf message storage.RangeWaveDatasReply
 */
export interface RangeWaveDatasReply {
    /**
     * @generated from protobuf field: repeated storage.HistoryPointDatasReply Points = 1 [json_name = "Points"];
     */
    points: HistoryPointDatasReply[];
}
// @generated message type with reflection information, may provide speed optimized methods
class GetRangeWaveDatasRequest$Type extends MessageType<GetRangeWaveDatasRequest> {
    constructor() {
        super("storage.GetRangeWaveDatasRequest", [
            { no: 1, name: "StartTime", kind: "scalar", jsonName: "StartTime", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "EndTime", kind: "scalar", jsonName: "EndTime", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "Limit", kind: "scalar", jsonName: "Limit", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "Points", kind: "scalar", jsonName: "Points", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "IsExternal", kind: "scalar", jsonName: "IsExternal", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<GetRangeWaveDatasRequest>): GetRangeWaveDatasRequest {
        const message = { startTime: "", endTime: "", limit: 0, points: [], isExternal: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetRangeWaveDatasRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetRangeWaveDatasRequest): GetRangeWaveDatasRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string StartTime = 1 [json_name = "StartTime"];*/ 1:
                    message.startTime = reader.string();
                    break;
                case /* string EndTime = 2 [json_name = "EndTime"];*/ 2:
                    message.endTime = reader.string();
                    break;
                case /* int32 Limit = 3 [json_name = "Limit"];*/ 3:
                    message.limit = reader.int32();
                    break;
                case /* repeated int32 Points = 4 [json_name = "Points"];*/ 4:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.points.push(reader.int32());
                    else
                        message.points.push(reader.int32());
                    break;
                case /* bool IsExternal = 5 [json_name = "IsExternal"];*/ 5:
                    message.isExternal = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetRangeWaveDatasRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string StartTime = 1 [json_name = "StartTime"]; */
        if (message.startTime !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.startTime);
        /* string EndTime = 2 [json_name = "EndTime"]; */
        if (message.endTime !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.endTime);
        /* int32 Limit = 3 [json_name = "Limit"]; */
        if (message.limit !== 0)
            writer.tag(3, WireType.Varint).int32(message.limit);
        /* repeated int32 Points = 4 [json_name = "Points"]; */
        if (message.points.length) {
            writer.tag(4, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.points.length; i++)
                writer.int32(message.points[i]);
            writer.join();
        }
        /* bool IsExternal = 5 [json_name = "IsExternal"]; */
        if (message.isExternal !== false)
            writer.tag(5, WireType.Varint).bool(message.isExternal);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message storage.GetRangeWaveDatasRequest
 */
export const GetRangeWaveDatasRequest = new GetRangeWaveDatasRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RangeWaveDatasReply$Type extends MessageType<RangeWaveDatasReply> {
    constructor() {
        super("storage.RangeWaveDatasReply", [
            { no: 1, name: "Points", kind: "message", jsonName: "Points", repeat: 1 /*RepeatType.PACKED*/, T: () => HistoryPointDatasReply }
        ]);
    }
    create(value?: PartialMessage<RangeWaveDatasReply>): RangeWaveDatasReply {
        const message = { points: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RangeWaveDatasReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RangeWaveDatasReply): RangeWaveDatasReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated storage.HistoryPointDatasReply Points = 1 [json_name = "Points"];*/ 1:
                    message.points.push(HistoryPointDatasReply.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RangeWaveDatasReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated storage.HistoryPointDatasReply Points = 1 [json_name = "Points"]; */
        for (let i = 0; i < message.points.length; i++)
            HistoryPointDatasReply.internalBinaryWrite(message.points[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message storage.RangeWaveDatasReply
 */
export const RangeWaveDatasReply = new RangeWaveDatasReply$Type();
