<template>
	<el-dialog v-model="state.showProperty" :top="state.top" :draggable="props.draggable" :class="props.customClass"
		@close="closeExcute" @open="openedExcute" :title="props.title" :width="props.width" append-to-body class="component_dialog"
		:destroy-on-close="props.destroyOnClose">
		<div class="property-container" ref="containerRef" :style="{ height: props.height }">
			<slot></slot>
		</div>
		<template #footer>
			<span class="dialog-footer">
				<el-button @click="cancelExcute">{{ $t('common.cancel') }}</el-button>
				<el-button type="primary" @click="sureExcute" v-debounce>{{ $t('common.ok') }}</el-button>
			</span>
		</template>
	</el-dialog>
</template>

<script setup>
import { reactive, ref, watch, nextTick, onBeforeUnmount } from "vue";
import { i18nGlobal } from "@common/js/utils";

const containerRef = ref(null);
const emit = defineEmits(["cancel", "sure", "close", "open", "before"]);
const props = defineProps({
	showProperty: {
		default: false,
		type: Boolean,
	},
	draggable: {
		default: true,
		type: Boolean,
	},
	height: {
		default: "auto",
		type: String,
	},
	width: {
		default: "1000px",
		type: String,
	},
	title: {
		default: i18nGlobal('component.componentProperties'),
		type: String,
	},
	customClass: {
		default: "",
		type: String,
	},
	top: {
		default: "",
		type: String,
	},
	operator: {
		default: null,
		type: Object,
	},
	destroyOnClose: {
		default: false,
		type: Boolean,
	},
});

const state = reactive({
	showProperty: false,
	top: "",
});

const propertyWathcer = watch(
	() => props.showProperty,
	(p) => {
		state.showProperty = p;
	},
	{
		deep: true,
	}
);

const cancelExcute = () => {
	state.showProperty = false;
	emit("cancel");
};

const openedExcute = () => {
	if (props.top) {
		state.top = props.top;
	} else {
		nextTick(() => {
			if (state.top == "") {
				let vhUnit = document.body.clientHeight / 100;
				let componentHeight = containerRef.value.clientHeight + 120;
				let top = (document.body.clientHeight - componentHeight) / 2 / vhUnit;
				if (top < 0) top = 0;
				state.top = top + "vh";
			}

			nextTick(() => {
				let dialog = containerRef.value.parentElement.parentElement;
				let transform = dialog.style.transform;
				if (transform != null && transform != "" && transform.indexOf("translate") >= 0) {
					let offsetTop = dialog.offsetTop;
					let spilts = transform.split(",");
					let translateTop = spilts[1].substring(0, spilts[1].indexOf("px"));
					if (offsetTop + Number(translateTop.trim()) < 0) {
						dialog.style.transform = `${spilts[0]}, ${-offsetTop}px)`;
					}
				}
			});
		});
	}

	emit("open");
};

const sureExcute = () => {
	if (props.operator) {
		let isValidate = props.operator.validate();
		if (!isValidate) return;
	}
	state.showProperty = false;
	emit("sure");
};

const closeExcute = () => {
	emit("close");
};

onBeforeUnmount(() => {
	propertyWathcer();
});
</script>

<style>
.el-dialog__body {
	padding: 10px 20px 10px 20px !important;
}
</style>
