import { CommonShape, ShapeElement } from "../webcharts/Dh-webchart-shapes";
import { ChartMode, DataPointShape, FSHADER_SOURCE, PluginMode, VSHADER_SOURCE } from "../webcharts/Dh-webchart-models";
import $ from "jquery";
import performanceWorker from "@core/graphs/worker/dh-performance-worker.js?worker";
import { DotImageResourceContext } from "./Dh-shape-dots";
import { getAlarmColorByLevel, isIterable } from "@common/js/utils";
import { NormalLevel } from "@common/js/enum";

export class LineShapeOperator extends CommonShape {
    constructor() {
        super();
        this.dotResource = DotImageResourceContext.GetInstance();
    }
    SetData(data) {
        this.Data = null;
        this.Data = data;
    }
    OnRender() {
        this.EnsureContainerCreated();
        this.drawApi.ClearAll();
        const data = this.Data;
        const shapeRect = this.ChartContext.Information.ShapeContainerRect;
        if (shapeRect.Width < 1) return;
        if (!isIterable(data)) return;
        let selectSeries = null;
        let legend = this.ChartContext.PluginManager.GetPlugin(PluginMode.Statistics);
        for (const series of data) {
            if (series.Points && series.Points != null) {
                if (legend?.SelectedItem == series.PointId) {
                    selectSeries = series;
                    continue;
                }
                this.DrawSeries(series);
            }
        }
        if (selectSeries != null) {
            this.DrawSeries(selectSeries);
        }
    }
    DrawSeries(series) {
        let ptArray = this.GetPoints(series);
        if (ptArray != null && ptArray.length > 0) {
            this.Draw(ptArray, series);
            ptArray = null;
        }
    }
    /**绘制 */
    Draw(ptArray, series) {
        this.DrawLines(ptArray, series);
        this.DrawDots(ptArray, series);
    }
    /**绘制线 */
    DrawLines(ptArray, data) {
        if (this.drawApi == null || ptArray == null || ptArray.length == 0 || data == null) return;
        let first = ptArray[0];
        this.drawApi.BeginPath();
        this.drawApi.MoveTo(first.X, first.Y);
        for (const d of ptArray) {
            this.drawApi.LineTo(d.X, d.Y);
        }
        this.drawApi.Stroke(data.LineColor, data.LineWidth);
    }
    DrawDots(ptArray, data) {
        if (!this.ChartContext?.Option?.Axis?.ShowDot) return;
        const color = data.LineColor;
        let dotArrays = {
            normal: [],
            alarm: {},
        };
        const shapeRect = this.ChartContext.Information.ShapeContainerRect;
        const left = shapeRect.Left - 0.001;
        const right = shapeRect.Left + shapeRect.Width + 0.001;
        const top = shapeRect.Top - 0.001;
        const bottom = shapeRect.Top + shapeRect.Height + 0.001;
        for (let i = 0; i < ptArray.length; i++) {
            const d = ptArray[i];
            if (d.X < left || d.X > right || d.Y < top || d.Y > bottom) continue;
            if (d.hasOwnProperty('AlarmLevel') && d.AlarmLevel != NormalLevel) {
                Array.isArray(dotArrays.alarm[d.AlarmLevel]) ? (dotArrays.alarm[d.AlarmLevel].push(d)) : (dotArrays.alarm[d.AlarmLevel] = [d]);
            } else {
                dotArrays.normal.push(d);
            }
        }
        this.DrawDot(dotArrays.normal, data, color);
        Object.entries(dotArrays.alarm).forEach(([level, currentLevelPoints]) => {
            const dotColor = getAlarmColorByLevel(level);
            dotColor && this.DrawDot(currentLevelPoints, data, dotColor, 1.5);
        });
        dotArrays = null;
    }
    /**绘制点 */
    async DrawDot(ptArray, data, color, size = 1) {
        if (this.drawApi == null || ptArray.length == 0 || data == null) return;
        const length = data.PointSize * size;
        if (data.PointType !== DataPointShape.None) {
            this.drawApi.Save();
            const image = await this.dotResource.get(color);
            for (const d of ptArray) {
                this.drawApi.DrawImage(image, d.X - length / 2, d.Y - length / 2, length, length)
            }
            this.drawApi.Restore();
        }
    }
    /**获取显示数据 */
    GetPoints(resultData) {
        let calculator = this.ChartContext.CoordinateSystem;
        return calculator.GetPoints(resultData);
    }
    ClearData() {
        this.Data = new Array();
    }
}

export class HLineShapeOperator extends ShapeElement {
    constructor() {
        super();
        this.worker = null;
        this.ZoomFactor = window.devicePixelRatio;
        this.Data = [];
        this.gl = null;
    }
    EnsureContainerCreated() {
        if (this.isDisposed) return;
        const container = this.ChartContext.PluginContainer;
        let element = document.getElementById(this.Id);
        if (element == null && this.gl == null) {
            element = document.createElement("canvas");
            element.setAttribute("id", this.Id);
            element.setAttribute("style", "position:absolute;left:" + this.ChartContext.CoordinateSystem.CanvasLeft + "px;pointer-events:none;");
            container.before(element);
            this.gl = this.getWebGLContext(element);
            this.initShaders(VSHADER_SOURCE, FSHADER_SOURCE);
        }
        if (element) {
            const { width, height } = this.GetContainerRect();
            element.style.width = width + "px";
            element.style.height = height + "px";
            element.width = width * this.ZoomFactor;
            element.height = height * this.ZoomFactor;
        }
        return element;
    }
    getWebGLContext(canvas) {
        let gl = null;
        try {
            // 如果没有webgl上下文，尝试实验版的webgl
            gl = canvas.getContext("webgl") || canvas.getContext("exprimental-wegl");
        } catch { }
        return gl;
    }
    initShaders(vshader, fshader) {
        const gl = this.gl;
        // 分别加载顶点着色器和片段着色器
        const vertexShader = this.loadShader(gl.VERTEX_SHADER, vshader);
        const fragmentShader = this.loadShader(gl.FRAGMENT_SHADER, fshader);

        // 4.创建程序对象
        const program = gl.createProgram();

        // 5.编译过的着色器附加到程序对象中
        gl.attachShader(program, vertexShader);
        gl.attachShader(program, fragmentShader);

        // 6.链接程序对象
        gl.linkProgram(program);

        // 7.调用程序对象
        gl.useProgram(program);
        gl.program = program;
    }
    loadShader(type, source) {
        const gl = this.gl;
        const shader = gl.createShader(type);

        // 2.把着色器代码载入到着色器对象
        gl.shaderSource(shader, source);

        // 3.编译着色器
        gl.compileShader(shader);

        // getShaderParameter 检查编译状态
        const compiled = gl.getShaderParameter(shader, gl.COMPILE_STATUS);
        if (!compiled) {
            const error = gl.getShaderInfoLog(shader);
            console.log("Failed to compile shader: " + error);
            gl.deleteShader(shader);
            return null;
        }

        return shader;
    }
    OnRender() {
        const element = this.EnsureContainerCreated();
        if (element) {
            const info = this.ChartContext.Information;
            const shapeRect = info.ShapeContainerRect;
            const chartRect = info.ChartContainerRect;
            if (shapeRect.Width < 1) return;
            const legend = this.ChartContext.PluginManager.GetPlugin(PluginMode.Statistics);
            const gl = this.gl;
            this.setRate();
            gl.viewport(
                shapeRect.Left * this.ZoomFactor,
                (chartRect.Height - shapeRect.Top - shapeRect.Height) * this.ZoomFactor,
                shapeRect.Width * this.ZoomFactor,
                shapeRect.Height * this.ZoomFactor);
            gl.clearColor(0, 0, 0, 0);
            gl.clear(gl.COLOR_BUFFER_BIT);

            let selectSeries = null;
            for (const data of this.getDrawData()) {
                if (legend?.SelectedItem == data.PointId) {
                    selectSeries = data;
                    continue;
                }
                this.drawSeries(data);
            }
            if (selectSeries != null) {
                this.drawSeries(selectSeries);
            }
        }
    }
    getDrawData() {
        return this.Data;
    }
    drawSeries(data) {
        if (data.Points.length == 0 || data.LineColor == null) return;
        const info = this.ChartContext.Information;
        const coordInfo = info.CoordinateInfo;
        const temp = [];
        for (const point of data.Points) {
            temp.push(point.X - coordInfo.XMin);
            temp.push(point.Y - coordInfo.YMin);
        }
        const vertices = new Float32Array(temp);
        const gl = this.gl;
        // 创建buffer对象
        const vertexBuffer = gl.createBuffer();
        // 绑定buffer到缓冲对象上
        gl.bindBuffer(gl.ARRAY_BUFFER, vertexBuffer);
        // 向缓冲对象写入数据
        gl.bufferData(gl.ARRAY_BUFFER, vertices, gl.DYNAMIC_DRAW);
        // 从程序对象中获取相应属性
        const a_Position = gl.getAttribLocation(gl.program, "a_Position");
        // 向顶点写入缓冲数据
        gl.vertexAttribPointer(a_Position, 2, gl.FLOAT, false, 0, 0);
        // 使用缓冲数据建立程序代码到着色器代码的联系
        gl.enableVertexAttribArray(a_Position);

        this.setColor(this.hexToRgba(data.LineColor));
        this.setUniformValue("type", 0);
        if (data.DrawType == 1) {
            gl.drawArrays(gl.TRIANGLE_STRIP, 0, temp.length / 2);
        }
        gl.drawArrays(gl.LINE_STRIP, 0, temp.length / 2);
        if (data.PointType > 0 && this.ChartContext?.Option?.Axis?.ShowDot) {
            this.setUniformValue("type", data.PointType);
            this.setUniformValue("pointSize", 6.0);
            gl.drawArrays(gl.POINTS, 0, temp.length / 2);

            const alarmPointMap = new Map();
            //绘制报警点
            const red = [];
            const yellow = [];
            for (const point of data.Points) {
                if (point.hasOwnProperty('AlarmLevel') && point.AlarmLevel != NormalLevel) {
                    if (alarmPointMap.has(point.AlarmLevel)) {
                        alarmPointMap.get(point.AlarmLevel).push(point.X - coordInfo.XMin, point.Y - coordInfo.YMin);
                    } else {
                        alarmPointMap.set(point.AlarmLevel, [point.X - coordInfo.XMin, point.Y - coordInfo.YMin]);
                    }
                }
            }
            alarmPointMap.forEach((value, key) => {
                this.drawDots(value, getAlarmColorByLevel(key));
            })
        }
    }
    drawDots(points, color) {
        this.setUniformValue("pointSize", 10.0);
        const gl = this.gl;
        const vertices = new Float32Array(points);
        // 创建buffer对象
        const vertexBuffer = gl.createBuffer();
        // 绑定buffer到缓冲对象上
        gl.bindBuffer(gl.ARRAY_BUFFER, vertexBuffer);
        // 向缓冲对象写入数据
        gl.bufferData(gl.ARRAY_BUFFER, vertices, gl.DYNAMIC_DRAW);
        // 从程序对象中获取相应属性
        const a_Position = gl.getAttribLocation(gl.program, "a_Position");
        // 向顶点写入缓冲数据
        gl.vertexAttribPointer(a_Position, 2, gl.FLOAT, false, 0, 0);
        // 使用缓冲数据建立程序代码到着色器代码的联系
        gl.enableVertexAttribArray(a_Position);

        this.setColor(this.hexToRgba(color));
        gl.drawArrays(gl.POINTS, 0, points.length / 2);
    }
    setColor(color) {
        const gl = this.gl;
        const u_FragColor = gl.getUniformLocation(gl.program, "u_FragColor");
        gl.uniform4f(u_FragColor, ...color);
    }
    setRate() {
        const info = this.ChartContext.Information;
        const coordInfo = info.CoordinateInfo;
        this.setUniformValue("xunit", 1 / (coordInfo.XMax - coordInfo.XMin));
        this.setUniformValue("yunit", 1 / (coordInfo.YMax - coordInfo.YMin));
    }
    setUniformValue(key, value) {
        const gl = this.gl;
        const temp = gl.getUniformLocation(gl.program, key);
        gl.uniform1f(temp, value);
    }
    hexToRgba(hex, opacity = 1) {
        if (hex.length == 9) {
            opacity = parseInt("0x" + hex.slice(7, 9)) / 255;
        }
        return [parseInt("0x" + hex.slice(1, 3)) / 255, parseInt("0x" + hex.slice(3, 5)) / 255, parseInt("0x" + hex.slice(5, 7)) / 255, opacity]
    }
    SetData(data) {
        this.Data = new Array();
        for (const d of data) {
            this.Data.push(d);
        }
    }
    OnClear() {
        this.Data = [];
        if (this.gl) this.gl.getExtension('WEBGL_lose_context').loseContext();
        this.gl = null;
    }
    ClearData() {
        this.Data = [];
    }
    GetContainerRect() {
        const container = $("#" + this.ChartContext.ChartContainer.id);
        return {
            width: container.width(),
            height: container.height()
        }
    }
}

export class HLineShapeWorkerOperator extends HLineShapeOperator {
    constructor() {
        super();
        this.worker = null;
        this.ZoomFactor = window.devicePixelRatio;
        this.Data = [];

    }
    EnsureContainerCreated() {
        const container = this.ChartContext.PluginContainer;
        let element = document.getElementById(this.Id);
        if (element == null) {
            element = document.createElement("canvas");
            element.setAttribute("id", this.Id);
            element.setAttribute("style", "position:absolute;left:" + this.ChartContext.CoordinateSystem.CanvasLeft + "px;z-index:1;pointer-events:none;");
            container.before(element);
            this.worker = new performanceWorker();
            const offscreen = element.transferControlToOffscreen();
            this.worker.postMessage({
                msg: "init",
                canvas: offscreen,
                zoomFactor: this.ZoomFactor
            }, [offscreen]);
        }
        const { width, height } = this.GetContainerRect();
        element.style.width = width + "px";
        element.style.height = height + "px";
        this.worker.postMessage({
            msg: "update",
            width: width * this.ZoomFactor,
            height: height * this.ZoomFactor,
        });
        return element;
    }
    OnRender() {
        this.EnsureContainerCreated();
        const info = this.ChartContext.Information;
        const shapeRect = info.ShapeContainerRect;
        const coordInfo = info.CoordinateInfo;

        if (shapeRect.Width < 1) return;
        const legend = this.ChartContext.PluginManager.GetPlugin(PluginMode.Statistics);
        this.worker.postMessage({
            msg: "render",
            shapeRect: shapeRect,
            xmax: coordInfo.XMax,
            xmin: coordInfo.XMin,
            ymax: coordInfo.YMax,
            ymin: coordInfo.YMin,
            showDot: this.ChartContext?.Option?.Axis?.ShowDot || false,
            selectId: legend?.SelectedItem
        });
    }
    SetData(data) {
        this.Data = new Array();
        for (const d of data) {
            this.Data.push(d);
        }
        const info = this.ChartContext.Information;
        const coordInfo = info.CoordinateInfo;
        this.worker.postMessage({
            msg: "setdata",
            datas: this.Data,
            xmax: coordInfo.XMax,
            xmin: coordInfo.XMin,
        });
    }
    OnClear() {
        this.Data = [];
        this.worker?.terminate();
        this.worker = null;
    }
    ClearData() {
        this.Data = [];
    }
    GetContainerRect() {
        const container = $("#" + this.ChartContext.ChartContainer.id);
        return {
            width: container.width(),
            height: container.height()
        }
    }
}

export class CustomShapeOperator extends LineShapeOperator {
    constructor() {
        super();
        this.diluteCount = 2000;
    }
    DrawSeries(series) {
        switch (series.ChartMode) {
            case ChartMode.Performance:
                this.DrawPerformance(series);
                break;
            default:
                this.Draw(this.GetPoints(series), series);
                break;
        }
    }
    Draw(ptArray, series) {
        if (ptArray == null || ptArray.length == 0) return;
        switch (series.ChartMode) {
            case ChartMode.Performance:
                this.DrawPerformance(ptArray, series);
                break;
            default:
                this.DrawLines(ptArray, series);
                this.DrawDots(ptArray, series);
                break;
        }
    }
    DrawPerformance(series) {
        const temp = this.GetDilutePoints(series.Points);
        const coordSystem = this.ChartContext.CoordinateSystem;
        const ptArray = coordSystem.GetSceenPoints(temp.Points);
        this.DrawLines(ptArray, series);
        this.DrawDots(ptArray, series);
    }
    GetDilutePoints(points) {
        const result = {
            isDilute: false,
            Points: []
        }
        const coordInfo = this.ChartContext.Information.CoordinateInfo;
        let start = -1, end = -1;
        for (let i = 0; i < points.length; i++) {
            if (coordInfo.XMin <= points[i].X && start == -1) {
                start = i;
                break;
            }
        }
        for (let i = points.length - 1; i >= 0; i--) {
            if (coordInfo.XMax >= points[i].X) {
                end = i;
                break;
            }
        }
        let length = end - start + 1;
        if (start > 0) start = start - 1;
        if (end > 0 && end < points.length - 1) end = end + 1;
        if (start == -1 || end == -1) {
            if (start == -1) start = 0;
            if (end == -1) end = points.length - 1;
            result.Points = points.slice(start, end + 1);
            return result;
        }
        let diluteRate = this.GetDuliteRate({ count: length - 1, points });
        result.isDilute = diluteRate > 2;
        for (let i = start; i <= end; i = i + diluteRate) {
            let maxIndex = i,
                minIndex = i;
            for (let j = 1; j < diluteRate; j++) {
                if (i + j <= end) {
                    if (points[i + j].Y > points[maxIndex].Y) {
                        maxIndex = i + j;
                    }
                    if (points[i + j].Y < points[minIndex].Y) {
                        minIndex = i + j;
                    }
                }
            }
            if (maxIndex > minIndex) {
                result.Points.push(points[minIndex]);
                result.Points.push(points[maxIndex]);
            } else {
                result.Points.push(points[maxIndex]);
                result.Points.push(points[minIndex]);
            }
        }
        return result;
    }
    GetDuliteRate(param) {
        return (Math.floor(param.count / this.diluteCount) + 1) * 2;
    }
}