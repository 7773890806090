import { i18nGlobal } from "@common/js/utils";
import StepTemplateOne from "../vueTemplate/stepTemplate_1.vue";
import StepTemplateDevice from "../vueTemplate/stepTemplateDevice.vue";
import { ReportTemplateEnum } from "@common/js/enum";

const ReportInfo = {
    [ReportTemplateEnum.Template1]: {
        stepNameList: [i18nGlobal('mainLayout.report.baseInfo'), i18nGlobal('mainLayout.report.devicePoint')],
        stepComponent: StepTemplateOne,
    },
    [ReportTemplateEnum.Template2]: {
        stepNameList: [i18nGlobal('mainLayout.report.baseInfo'), i18nGlobal('mainLayout.report.devicePoint')],
        stepComponent: StepTemplateOne,
    },
    [ReportTemplateEnum.Template3]: {
        stepNameList: [i18nGlobal('mainLayout.report.baseInfo'), i18nGlobal('app.chooseDevice')],
        stepComponent: StepTemplateDevice,
    },
    [ReportTemplateEnum.Template4]: {
        stepNameList: [i18nGlobal('mainLayout.report.baseInfo'), i18nGlobal('app.chooseDevice')],
        stepComponent: StepTemplateDevice,
    },
}

export {
    ReportInfo,
}