import { AlarmTypeModelEnum, NormalLevel } from "@common/js/enum";
import store from "@common/js/store";
import { guid, i18nGlobal } from "@common/js/utils";
import DrawApi from "@core/graphs/webcharts/Dh-webchart-drawApis";
import { BandwidthPoint, PluginMode } from "@core/graphs/webcharts/Dh-webchart-models";
import $ from "jquery";
import { PluginBase } from "./DH-plugin-base";
import _ from 'lodash';
import { EnumAlarmFlag } from "@components/js/utils/model";

export class FreqBandAlarmPlugin extends PluginBase {
    constructor() {
        super();
        this.Id = guid();
        this.drawApi = new DrawApi();
        this.datas = [];
        this.alarmInfo = null;
        this.ptArray = [];
    }
    CreatePlugin() {

    }
    OnSizeChanged() {

    }
    OnRender() {
        this.EnsureContainerCreated();
        this.drawApi.ClearAll();
        if (this.Owner.Option?.Axis.ShowAlarmLine != true) return;
        // 单位为Hz/RPM时不画图
        if (this.Owner.Option.Signal && this.Owner.Option.Signal.FreqUnit === 1) {
            return;
        }
        if (this.Entity.UnitCheck && this.alarmInfo && this.Owner.Information.UnitTexts.XText) {
            if (this.Owner.Information.UnitTexts.XText == "Hz" && this.alarmInfo.ParamType == 2) {
                return;
            }
            if (this.Owner.Information.UnitTexts.XText == i18nGlobal('core.order') && this.alarmInfo.ParamType !== 2) {
                return;
            }
        }
        if (this.Owner.Option && this.Owner.Option.Axis.ShowAlarmLine == true) {
            const rect = this.Owner.Information.ShapeContainerRect;
            this.ptArray = this.GetPoints();
            this.drawApi.Save();
            this.drawApi.BeginPath();
            this.drawApi.ClipRect(rect.Left + 2, rect.Top, rect.Width, rect.Height);
            this.DrawRectangle(this.ptArray, this.ptArray);
            this.DrawGraphText(this.ptArray, this.ptArray);
            this.DrawDashLine(this.ptArray, this.ptArray);
            this.drawApi.Restore();
        }
    }
    SetAlarmInfo(arr) {
        this.datas = [];
        const bandWidth = arr.find((item) => item.AlarmCategory === AlarmTypeModelEnum.Bandwidth && item.IsEnabled)
        if (bandWidth) {
            this.alarmInfo = bandWidth;
            let details = '';
            try {
                details = JSON.parse(bandWidth.Details);
            } catch {
                details = bandWidth.Details
            }
            let arr = [];

            details.forEach((item, index) => {
                const { High, Low, AlarmType, ParameterType } = item;
                arr.push([]);
                const Id = guid();
                item.Levels = item.Levels.filter(t => store.state.alarmInfo.find(c => c.Level === t.Level))
                item.Levels.forEach(child => {
                    const { Threshold, Level, Enable } = child;
                    const Color = store.state.alarmInfo.find(t => t.Level === Level)?.Color
                    const row = {
                        Id,
                        High,
                        Low,
                        Enable,
                        Threshold,
                        Color,
                        Level,
                        AlarmType,
                        Dash: item.Dash,
                        ParameterType
                    }
                    arr[index].push(row)
                })
            })

            if (bandWidth.ParamType === 1) {
                arr.forEach(child => {
                    child.forEach(item => {
                        const { Low, High } = item;
                        item.Dash = Low;
                        item.Low = this.subtract(Low, High);
                        if (item.Low < 0) {
                            item.Low = 0;
                        };
                        item.High = this.accAdd(Low, High);
                    })

                });
            }
            this.datas = arr;
        }
    }
    accAdd(num1, num2) {
        let r1, r2, m;
        try { r1 = num1.toString().split(".")[1].length } catch (e) { r1 = 0 }
        try { r2 = num2.toString().split(".")[1].length } catch (e) { r2 = 0 }
        m = Math.pow(10, Math.max(r1, r2))
        return Number((num1 * m + num2 * m) / m)
    }
    subtract(num1, num2) {
        let r1, r2, m, n;
        try { r1 = num1.toString().split(".")[1].length } catch (e) { r1 = 0 }
        try { r2 = num2.toString().split(".")[1].length } catch (e) { r2 = 0 }
        m = Math.pow(10, Math.max(r1, r2));
        n = (r1 >= r2) ? r1 : r2;
        return Number(((num1 * m - num2 * m) / m).toFixed(n));
    }
    setRotateSpeed(data) {
        let speed = 0
        const selectItem = this.Owner?.PluginManager?.Plugins.get(PluginMode.Statistics)?.SelectedItem;
        if (selectItem) {
            // 实时转速
            const selectSpeed = this.Owner.Option.RotateSpeedMap[selectItem];

            // 查询历史转速
            const speeds = this.Owner.Option.Speeds;

            if (selectSpeed) {
                speed = Number(selectSpeed);
            } else {
                if (speeds && speeds.length > 0) {
                    speed = speeds.find(t => t.pointId === selectItem)?.data ?? 60;
                } else {
                    // 无转速的测点按转速60计算
                    speed = 60
                }
            }
        }
        speed = speed / 60;

        const item = _.cloneDeep(data);

        item.forEach((child, index) => {
            const { Low, High } = child;
            child.Low = this.subtract(Low * speed, High);
            if (child.Low < 0) {
                child.Low = 0;
            };
            child.High = this.accAdd(Low * speed, High);


            // high = child.High;
            // low = child.Low;
            child.Dash = Low * speed;
            data[index].Dash = child.Dash;
            if (speed === 0) {
                child.Low = 0;
                child.High = 0;
                child.Dash = undefined;
            };
        })
        return item;
    }
    GetPoints() {
        if (this.datas.length === 0) return [];
        const chart = this.Owner.Information;
        const rect = chart.ShapeContainerRect;
        const result = [];
        for (let data of this.datas) {
            if (data && data[0]) {
                if (this.alarmInfo && this.alarmInfo.ParamType === 3) {
                    data = this.setRotateSpeed(data);
                }
                const AlarmType = data[0].AlarmType;
                let yRate = rect.Height / (chart.CoordinateInfo.YMax - chart.CoordinateInfo.YMin);
                let xRate = rect.Width / (chart.CoordinateInfo.XMax - chart.CoordinateInfo.XMin);
                let x1 = xRate * (data[0].Low - chart.CoordinateInfo.XMin);
                let x2 = xRate * (data[0].High - chart.CoordinateInfo.XMin);
                let xdash = x1 + (x2 - x1) / 2 + rect.Left;
                const normalColor = store.state.alarmInfo.find(t => t.Level === NormalLevel)?.Color;
                if (AlarmType === 0) { // 超限
                    let lastHeight = 0;
                    const reverseData = _.cloneDeep(data).reverse();
                    for (let i = 0; i < reverseData.length; i++) {
                        const item = reverseData[i];
                        if (item.Enable) {
                            let itemHeight = yRate * (chart.CoordinateInfo.YMax - item.Threshold) - lastHeight;
                            if(itemHeight < 0) {                              
                                continue;
                            } else {
                                let p = new BandwidthPoint(rect.Left + x1, rect.Top + lastHeight, x2 - x1, itemHeight, item.Color, item.Level, item.Id, xdash);
                                lastHeight += itemHeight
                                result.push(p)
                            }
                          
                        }
                    }
                    if (reverseData.find(t => t.Enable)) {
                        const hasOther = result.find(t => t.Id === data[0].Id)
                        let normalY = hasOther ? result[result.length - 1].Y + result[result.length - 1].Height : 0;
                        let height = hasOther ?  yRate * (chart.CoordinateInfo.YMax) : rect.Height + rect.Top;
                        let normal = new BandwidthPoint(rect.Left + x1, normalY, x2 - x1, height, normalColor, NormalLevel, data[0].Id, xdash);
                        result.push(normal)
                    }
                } else {
                    let lastHeight = 0;
                    const switchData = data.filter(t => t.Enable);
                    if (switchData.length > 0) {
                        let normal = new BandwidthPoint(rect.Left + x1, rect.Top, x2 - x1, yRate * (chart.CoordinateInfo.YMax - switchData[0].Threshold), normalColor, NormalLevel, data[0].Id, xdash);
                        result.push(normal)
                        lastHeight += yRate * (chart.CoordinateInfo.YMax - switchData[0].Threshold);
                    }
                    for (let i = 0; i < data.length; i++) {
                        const item = data[i];
                        if (item.Enable) {
                            let Threshold = this.GetNextThreshold(i, data);
                            let p = new BandwidthPoint(rect.Left + x1, rect.Top + lastHeight, x2 - x1, yRate * (chart.CoordinateInfo.YMax - Threshold) - lastHeight, item.Color, item.Level, item.Id, xdash);
                            lastHeight += (yRate * (chart.CoordinateInfo.YMax - Threshold) - lastHeight);
                            result.push(p)
                        }
                    }
                }
            }

        }
        return result;
    }

    GetNextThreshold(i, data) {
        const res = data.find((item, index) => item.Enable && index > i);
        return res?.Threshold || 0;
    }
    GetDangerSwitch(data) {
        return data.Flag == EnumAlarmFlag.DangerOnly || data.Flag == EnumAlarmFlag.WarnAndDanger || data.DangerSwitch;
    }
    GetWarningSwitch(data) {
        return data.Flag == EnumAlarmFlag.WarnOnly || data.Flag == EnumAlarmFlag.WarnAndDanger || data.WarningSwitch;
    }
    SetBandAlarmInfo(datas) {
        this.datas = datas;
    }
    EnsureContainerCreated() {
        var container = this.Owner.PluginContainer
        var element = document.getElementById(this.Id)
        if (element == null) {
            element = document.createElement('canvas')
            element.setAttribute('id', this.Id)
            element.setAttribute('style', `position:absolute;left:0;pointer-events:none;`);
            container.before(element)
        }
        element.width = $('#' + this.Owner.ChartContainer.id).width()
        element.height = $('#' + this.Owner.ChartContainer.id).height()
        this.EnsureContainerCliped()
        return element
    }
    //确认容器已经剪裁
    EnsureContainerCliped() {
        var rect = this.Owner.Information.ShapeContainerRect
        this.drawApi.SetContext(this.Id)
        this.drawApi.ClipRect(rect.Left, rect.Top, rect.Width, rect.Height)
    }
    DrawRectangle(ptArray) {
        for (const d of ptArray) {
            this.drawApi.BeginPath();
            this.drawApi.MoveTo(d.X, d.Y);
            this.drawApi.ShadowRectangle(d.X, d.Y, d.Width, d.Height, d.color);
        }
    }
    DrawDashLine(ptArray) {
        const rect = this.Owner.Information.ShapeContainerRect;
        this.datas.forEach((item) => {
            if (item[0] && item[0].Dash) {
                const current = ptArray.filter((t) => t.Id === item[0].Id).orderBy(p => p.Y);
                if (current.length > 0) {
                    const min = current[0].Y;
                    const X = current[0].X;
                    const dash = current[0].dash;
                    this.drawApi.BeginPath();
                    this.drawApi.MoveTo(dash, min);
                    this.drawApi.LineTo(dash, rect.Height + rect.Top);
                    this.drawApi.SetLineDash([5]);
                    this.drawApi.Stroke("#fff", 2);
                }
            }
        });
    }
    DrawGraphText(ptArray) {
        const color = store.state.customColor.TextColor;
        for (const data of this.datas) {
            const AlarmType = data[0].AlarmType;
            if (AlarmType === 0) {
                for (let i = data.length - 1; i >= 0; i--) {
                    const item = data[i];
                    const info = ptArray.find(t => t.Level === item.Level && t.Id === item.Id);
                    if (info) {
                        this.drawApi.FillText(item.Threshold, (info.X + info.Width) + 10, (info.Y + info.Height) + 5, color, "normal 10px Arial");
                    }
                }
            } else {
                for (let i = 0; i < data.length; i++) {
                    const item = data[i];
                    const info = ptArray.find(t => t.Level === item.Level && t.Id === item.Id);
                    if (info) {
                        this.drawApi.FillText(item.Threshold, (info.X + info.Width) + 10, info.Y + 5, color, "normal 10px Arial");
                    }
                }
            }

        }
    }
    Close() {
        if (this.drawApi.DrawContext) this.drawApi.Dispose();
    }
}