import { get0dBCoef, guid } from "@common/js/utils";
import { ChartInformation, SpectrumCoordinateInfo } from "@core/graphs/webcharts/Dh-webchart-coordinates";
import { SpectrumTableCoordinateSystem } from "@core/graphs/coords/dh-coordsystem-spectrum";
import { AnalysisChart } from "./Dh-analysischart-base";
import { ScaleType } from "../webcharts/Dh-webchart-models";

export class SpectralAnalysisChart extends AnalysisChart {
    constructor() {
        super();
    }
    GetInformation() {
        let info = new ChartInformation();
        info.UnitTexts.XText = "Hz";
        info.CoordinateInfo = new SpectrumCoordinateInfo(390, 0, 1000, 0, info);
        return info;
    }
    InitInformation(information) {
        super.InitInformation(information);
        if (this.Option.CoordInfo && information.CoordinateInfo) {
            information.CoordinateInfo.XAxisLogType = this.Option.Axis.XAxisLogType;
            information.CoordinateInfo.YAxisLogType = this.Option.Axis.YAxisLogType;
        }
    }
    GetCoordinateSystem() {
        return new SpectrumTableCoordinateSystem(guid());
    }
    SetData(datas) {
        if (datas.length === 0) {
            this.WebChart.Information.UnitTexts.XText = "";
            this.WebChart.SetData(datas);
        } else {
            let xmax = null,
                xmin = null,
                ymin = null,
                ymax = null;
            for (const data of datas) {
                if (xmax == null || xmax <= data.XMax) xmax = data.XMax;
                if (xmin == null || (data.XMin && xmin >= data.XMin)) xmin = data.XMin;
                if (ymin == null || ymin >= data.YMin) ymin = data.YMin;
                if (ymax == null || ymax <= data.YMax) ymax = data.YMax;
            }
            if (ymin > 0) ymin = 0;
            let coord = this.UpdateDataCoords(ymax, 0);
            if (ymax == null || xmax == null || xmin == null) {
                this.WebChart.Information.UnitTexts.XText = datas[0].XUnit;
                this.ClearData();
                const coordInfo = this.WebChart.Information.CoordinateInfo;
                ymax = coordInfo.YMax;
                ymin = coordInfo.YMin;
                xmax = coordInfo.XMax;
                xmin = coordInfo.XMin;
            }
            this.WebChart.Information.UpdateCoordinateInfo(xmax, xmin, coord.ymax, ymin);
            this.WebChart.Information.UnitTexts.XText = datas[0].XUnit;
            this.WebChart.SetData(datas);
        }
    }
    UpdateDataCoords(ymax, ymin) {
        if (this.WebChart.Option.Axis.AxisScaleType == ScaleType.Auto) {
            let rate = this.Option.Axis.YAxisLogType > 0 ? 1 : 0.1;
            let step = Math.abs(ymax - ymin) * rate;
            step = step == 0 ? 100 : step;
            ymax += step;
            ymin -= step;
        }
        if (ymax === ymin) ymax += 100;
        return { ymax, ymin };
    }
    ProcessData(datas) {
        if (this.WebChart.CoordinateSystem.YAxisLogType == 3) {
            let yReferenceValue = this.WebChart.CoordinateSystem.YReferenceValue;
            let coef = get0dBCoef();
            for (var data in datas) {
                for (var point in data.Points) {
                    if (point.Y > 0) {
                        point.Y = coef * Math.Log10(point.Y / yReferenceValue);
                    }
                }
            }
        }
    }
    UpdateXCoordinate() {
    }
}