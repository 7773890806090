import axios from "@common/js/axios";
import { ContractTypes } from "@common/js/enum";
import { i18nGlobal } from "@common/js/utils";
import { PolarDataPoint } from "@core/graphs/webcharts/Dh-webchart-models";
import { ChartRealType } from "./clientRealContext";
import { DataProtolEnum, EnumXDataType } from "./dataParserContext";
import { DataRealContext } from "./dataRealContext";
import { EnumQueryDataType } from "@components/js/utils/model";
import { GrpcApiContext } from "@grpc/grpcApi";
import dayjs from "dayjs";

export class DspDataRealContext extends DataRealContext {
    constructor() {
        super();
        this.actionName = null;
        this.chartRealType = null;
        this.graphRegisterPoints = new Map();
    }
    getContractType() {
        return ContractTypes.Dsp;
    }
    registerPoints(requestId, registerPoints) {
        this.graphRegisterPoints.set(requestId, registerPoints);
        setTimeout(() => {
            this.sendComponentRegiterPointsToClient(requestId);
        }, 0);
    }
    unRegisterPoints(requestId) {
        this.sendComponentUnRegisterPointsToClient(requestId);
        this.graphRegisterPoints.delete(requestId);
    }
    sendComponentRegiterPointsToClient(requestId) {
        const connectionIds = this.getConnectionIds();
        if (connectionIds.length == 0) return;
        const registerPoints = this.graphRegisterPoints.get(requestId);
        const postData = {};
        Object.assign(postData, registerPoints);
        postData.ComponentId = requestId;
        postData.ConnectionId = connectionIds;
        postData.Source = this.chartRealType;
        axios({
            method: "post",
            url: `Graphs/${this.actionName}`,
            data: postData,
        }).then((res) => {
            if (res.IsSuccess) {
                this.processRegisterData(requestId, registerPoints);
            }
        });
    }
    async processRegisterData(requestId, pointInfos) {
        if (pointInfos == null) return;
        const datas = [];
        for (const pointInfo of pointInfos.Points) {
            const point = await this.pointCacheContext.get(pointInfo.PointId) || pointInfo;
            datas.push({
                PointId: pointInfo.PointId,
                Param: {
                    PointId: pointInfo.PointId,
                    Name: point.Name,
                    Unit: point.UnitStr,
                    FullValue: point.FullValue,
                    SampleType: point.SampleMode
                },
                ComponentId: requestId,
            });
        }
        if (datas.length > 0) {
            this.onDataReceived(datas);
        }
    }
    ReceiveData(data) {
        try {
            if (data.Content == null && data.Content.length == 0) {
                return;
            }
            let graphDatas = data.Content.map(d => d.Data[1]);
            this.onDataReceived(graphDatas.filter(data => data.Source != null && data.Source.includes(this.chartRealType)));
            graphDatas = null;
        } catch (e) {
            debugger;
            console.log(e.message);
        }
    }
    onDataReceived(datas) {
        if (datas.length == 0) return;
        this.dataReceiveds.forEach((handler, requestId) => {
            let graphDatas = datas.filter((r) => r.ComponentId == requestId);
            if (graphDatas.length > 0) {
                for (const data of graphDatas) {
                    this.processData(data);
                }
                handler(graphDatas);
            }
            graphDatas = null;
        });
    }
    processData(data) {
        if (data.Datas?.length == 2) {
            data.Datas = this.dataParser.get(data.Datas[0]).GetDatas(data.Datas[1], data);
        }
    }
    sendComponentUnRegisterPointsToClient(requestId) {
        axios({
            method: "delete",
            url: `Graphs/${this.actionName}?id=${requestId}`,
            data: {},
        });
    }
    sendRegisterPointsToClinet() {
        for (const key of this.graphRegisterPoints.keys()) {
            this.sendComponentRegiterPointsToClient(key);
        }
    }
}

export class SpectrumDspDataRealContext extends DspDataRealContext {
    constructor() {
        super();
        this.actionName = "spectrum";
        this.chartRealType = ChartRealType.Spectral;
        this.client = GrpcApiContext.GetInstance();
    }
    async processRegisterData(requestId, pointInfos) {
       const points = pointInfos.Points;
       await super.processRegisterData(requestId, pointInfos);
       this.queryLatestSpectralDatas(points, requestId);
    }
    queryLatestSpectralDatas(registerPoints, componentId) {
        if(registerPoints.length === 0) return;
        let conditon = {
            Conndition: {
                StartTime: dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                Points: registerPoints.map(item => {
                    return { PointId: item.PointId }
                }),
            },
            GraphParam: {
                Points: registerPoints.map(point => {
                    return {
                        PointId: point.PointId,
                        AlgorithmParam: JSON.stringify({
                            RoteSpeed: point.AlgorithmParam.RoteSpeed,
                            UseSpeed: point.AlgorithmParam.UseSpeed,
                        }),
                    }
                }),
            },
        };
        this.client.queryLatestSpectralDatas(conditon)
            .then((res) => {
                if(res) {
                    res.forEach(item => item.ComponentId = componentId)
                    this.onDataReceived(res)
                }
            });
    }
    processData(data) {
        super.processData(data);
        if (data.RotateSpeed == null && data?.DspParam?.length == 2) {
            const dspParam = data.DspParam[1];
            data.RotateSpeed = dspParam.UseSpeed ? dspParam.RoteSpeed : 0;
            // 使用的模拟转速做标记，特征频率光标需要使用
            if (dspParam.UseSpeed) data.IsUseSpeed = true;
        }
    }
}

export class FreqIntegralDspDataRealContext extends DspDataRealContext {
    constructor() {
        super();
        this.actionName = "FreqIntegral";
        this.chartRealType = ChartRealType.FreqIntegral;
    }
}

export class TorqueDspDataRealContext extends DspDataRealContext {
    constructor() {
        super();
        this.actionName = "torque";
        this.chartRealType = ChartRealType.Torque;
    }
}

export class ShaftPowerDspDataRealContext extends DspDataRealContext {
    constructor() {
        super();
        this.actionName = "shaft-power";
        this.chartRealType = ChartRealType.ShaftPower;
    }
}

export class CrossPowerSpectrumDensDspDataRealContext extends DspDataRealContext {
    constructor() {
        super();
        this.actionName = "cpsd";
        this.chartRealType = ChartRealType.CrossPowerSpecDens;
    }
    async processRegisterData(requestId, pointInfos) {
        const datas = [];
        for (const pointInfo of pointInfos.Points) {
            const pointId = pointInfo.PointIds[0];
            const point = await this.pointCacheContext.get(pointId);
            datas.push({
                PointId: pointId,
                Param: {
                    PointId: pointId,
                    Name: point.Name,
                    Unit: point.UnitStr,
                    FullValue: point.FullValue,
                    SampleType: point.SampleMode
                },
                ComponentId: requestId,
            });
        }
        if (datas.length > 0) {
            this.onDataReceived(datas);
        }
    }
}

export class AxisPositionDspDataRealContext extends DspDataRealContext {
    constructor() {
        super();
        this.actionName = "axisPos";
        this.chartRealType = ChartRealType.AxisPosition;
    }
    async processRegisterData(requestId, registerDto) {
        if (registerDto == null || registerDto.X == null || registerDto.Y == null) return;
        const xp = await this.pointCacheContext.get(registerDto.X.PointId);
        const yp = await this.pointCacheContext.get(registerDto.Y.PointId);
        const datas = new Array();
        const data = {
            PointId: 0,
            Param: {
                PointId: 0,
                Name: '轴心位置',
                XUnit: xp.UnitStr,
                YUnit: yp.UnitStr,
                XFullValue: xp.FullValue,
                YFullValue: yp.FullValue,
            },
            ComponentId: requestId,
        };
        datas.push(data);
        if (datas.length > 0) {
            this.onDataReceived(datas);
        }
    }
}

export class AxisTrailDspDataRealContext extends DspDataRealContext {
    constructor() {
        super();
        this.actionName = "axisTrail";
        this.chartRealType = ChartRealType.AxisTrail;
    }
    async processRegisterData(requestId, registerDto) {
        if (registerDto == null || registerDto.X == null || registerDto.Y == null) return;
        const xp = await this.pointCacheContext.get(registerDto.X.PointId);
        const yp = await this.pointCacheContext.get(registerDto.Y.PointId);
        const datas = new Array();
        datas.push(this.createXYParam(requestId, xp, yp));
        datas.push(this.createParam(requestId, xp));
        datas.push(this.createParam(requestId, yp));
        this.dataReceiveds.forEach((handler, key) => {
            let arrays = datas.filter((r) => r.ComponentId == key);
            if (arrays.length > 0) {
                handler(arrays);
            }
            arrays = null;
        });
    }
    createXYParam(requestId, xp, yp) {
        return {
            PointId: 0,
            Param: {
                PointId: 0,
                Name: '轴心轨迹',
                XUnit: xp.UnitStr,
                YUnit: yp.UnitStr,
                XFullValue: xp.FullValue,
                YFullValue: yp.FullValue,
            },
            ComponentId: requestId,
        }
    }
    createParam(requestId, pointInfo) {
        return {
            PointId: pointInfo.Id,
            Param: {
                PointId: pointInfo.Id,
                Name: pointInfo.Name,
                Unit: pointInfo.UnitStr,
                FullValue: pointInfo.FullValue,
            },
            ComponentId: requestId,
        }
    }
    onDataReceived(datas) {
        if (datas.length == 0) return;
        this.dataReceiveds.forEach((handler, requestId) => {
            const temp = datas.filter((r) => r.ComponentId == requestId);
            const arrays = [];
            for (const data of temp) {
                arrays.push({
                    PointId: 0,
                    ComponentId: data.ComponentId,
                    SampleFreq: data.XDatas.SampleFreq,
                    SampleTime: data.XDatas.SampleTime,
                    Datas: this.dataParser.get(DataProtolEnum.XYDatas).GetDatas(data.TDatas),
                });
                if (data.XDatas.Datas?.length == 2) {
                    data.XDatas.Datas = this.dataParser.get(data.XDatas.Datas[0]).GetDatas(data.XDatas.Datas[1], data.XDatas);
                }
                if (data.YDatas.Datas?.length == 2) {
                    data.YDatas.Datas = this.dataParser.get(data.YDatas.Datas[0]).GetDatas(data.YDatas.Datas[1], data.YDatas);
                }
                arrays.push(data.XDatas);
                arrays.push(data.YDatas);
            }
            if (arrays.length > 0) {
                handler(arrays);
            }
        });
    }
}

export class CablePreDspDataRealContext extends DspDataRealContext {
    constructor() {
        super();
        this.actionName = "cable";
        this.chartRealType = ChartRealType.CablePre;
    }
}

export class BodePlotsDspDataRealContext extends DspDataRealContext {
    constructor() {
        super();
        this.actionName = "bode";
        this.chartRealType = ChartRealType.BodePlots;
    }
    async processRegisterData(requestId, registerDto) {
        const datas = new Array();
        for (const pointInfo of registerDto.Points) {
            const point = await this.pointCacheContext.get(pointInfo.PointId);
            datas.push(this.createData(point, requestId, 1, i18nGlobal('core.amplitude')));
            datas.push(this.createData(point, requestId, 2, i18nGlobal('core.phase')));
        }
        if (datas.length > 0) {
            this.dataReceiveds.forEach((handler, key) => {
                let arrays = datas.filter((r) => r.ComponentId == key);
                if (arrays.length > 0) {
                    handler(arrays);
                }
                arrays = null;
            });
        }
    }
    createData(point, componentId, idx, name) {
        return {
            PointId: point.Id + "_" + idx,
            Param: {
                PointId: point.Id + "_" + idx,
                Name: point.Name + "_" + name,
                Unit: point.UnitStr,
                FullValue: point.FullValue,
            },
            ComponentId: componentId,
        };
    }
    onDataReceived(datas) {
        this.dataReceiveds.forEach((handler, key) => {
            const temp = datas.filter((r) => r.ComponentId == key);
            let arrays = [];
            for (const data of temp) {
                data.AmpDatas.PointId += "_1";
                data.PhaseDatas.PointId += "_2";
                if (data.AmpDatas.Datas?.length == 2) {
                    data.AmpDatas.Datas = this.dataParser.get(data.AmpDatas.Datas[0]).GetDatas(data.AmpDatas.Datas[1]);
                }
                if (data.PhaseDatas.Datas?.length == 2) {
                    data.PhaseDatas.Datas = this.dataParser.get(data.PhaseDatas.Datas[0]).GetDatas(data.PhaseDatas.Datas[1]);
                }
                data.AmpDatas.SampleTime = data.SampleTime;
                data.PhaseDatas.SampleTime = data.SampleTime;
                arrays.push(data.AmpDatas);
                arrays.push(data.PhaseDatas);
            }
            if (arrays.length > 0) {
                handler(arrays);
            }
            arrays = null;
        });
    }
}

export class WholePeriodDspDataRealContext extends DspDataRealContext {
    constructor() {
        super();
        this.actionName = "wp";
        this.chartRealType = ChartRealType.WholePeriod;
    }
}

export class PolarDspDataRealContext extends DspDataRealContext {
    constructor() {
        super();
        this.actionName = "polar";
        this.chartRealType = ChartRealType.Polar;
    }
    processData(data) {
        if (data.Datas?.length > 0) {
            const datas = {
                XDataType: EnumXDataType.Number,
                DataCount: data.Datas.length,
                Points: [],
            }
            for (const polarData of data.Datas) {
                datas.Points.push(new PolarDataPoint(polarData.Real, polarData.Imaginary, polarData));
            }
            data.Datas = datas;
        }
    }
}

export class CepstrumAnalysisDspDataRealContext extends DspDataRealContext {
    constructor() {
        super();
        this.actionName = "cepstrum";
        this.chartRealType = ChartRealType.CepstrumAnalysis;
    }
}

export class CorrelationAnalysisDspDataRealContext extends CrossPowerSpectrumDensDspDataRealContext {
    constructor() {
        super();
        this.actionName = "correlation";
        this.chartRealType = ChartRealType.CorrelationAnalysis;
    }
}

export class AmplitudeAnalysisDspDataRealContext extends DspDataRealContext {
    constructor() {
        super();
        this.actionName = "ampanalysis";
        this.chartRealType = ChartRealType.AmplitudeAnalysis;
    }
}

export class TorvibMonitoringDspDataRealContext extends DspDataRealContext {
    constructor() {
        super();
        this.actionName = "torvib";
        this.chartRealType = ChartRealType.TorvibMonitoring;
    }
    async processRegisterData(requestId, registerDto) {
        if (registerDto == null || registerDto.Points?.length == 0) return;

        const datas = new Array();
        for (const rehisterPoint of registerDto.Points) {
            const point = await this.pointCacheContext.get(rehisterPoint.PointId);
            datas.push({
                PointId: point.Id,
                Param: {
                    PointId: point.Id,
                    Name: point.Name,
                    Unit: point.UnitStr,
                    FullValue: point.FullValue,
                    SampleType: point.SampleMode
                },
                ComponentId: requestId,
            });
        }
        if (datas.length > 0) {
            this.onDataReceived(datas);
        }
    }
    processData(data) {
        if (data.Datas?.Datas.length == 2) {
            data.Datas.Datas = this.dataParser.get(data.Datas.Datas[0]).GetDatas(data.Datas.Datas[1], data.Datas);
        }
        if (data.FFTDatas?.Datas.length == 2) {
            data.FFTDatas.SampleFreq = data.Datas.SampleFreq;
            data.FFTDatas.Datas = this.dataParser.get(data.FFTDatas.Datas[0]).GetDatas(data.FFTDatas.Datas[1], data.FFTDatas);
        }
    }
}

export class TimingDspDataRealContext extends DspDataRealContext {
    constructor() {
        super();
        this.actionName = "timer";
        this.chartRealType = ChartRealType.Timing;
    }
    sendComponentRegiterPointsToClient(requestId) {
        const connectionIds = this.getConnectionIds();
        if (connectionIds.length == 0) return;
        const registerPoints = this.graphRegisterPoints.get(requestId);
        const postData = {};
        postData.ComponentId = requestId;
        postData.ConnectionId = connectionIds;
        postData.Source = this.chartRealType;
        postData.Points = registerPoints;
        axios({
            method: "post",
            url: `Graphs/${this.actionName}`,
            data: postData,
        }).then((res) => {
            if (res.IsSuccess) {
                this.processRegisterData(requestId, registerPoints);
            }
        });
    }
    sendComponentUnRegisterPointsToClient(requestId) {
        axios({
            method: "post",
            url: `Graphs/unregister-component`,
            data: {
                componentId: requestId,
                ConnectionIds: this.getConnectionIds()
            },
        });
    }
    async processRegisterData(requestId) {
        const datas = await this.getCurrentTime(requestId);
        if (datas.length > 0) {
            this.onDataReceived(datas);
        }
    }
    processData(data) {
        return {
            ElapsedMilliseconds: data.ElapsedMilliseconds,
            IsRunning: data.IsRunning,
            ComponentId: data.ComponentId
        }
    }
    async getCurrentTime(requestId) {
        // component_use
        const res = await axios({
            method: "get",
            url: `/Graphs/get-timer-status/${requestId}`,
        });
        if (res.IsSuccess) {
            const { ElapsedMilliseconds, IsRunning } = res.Result;
            return [{
                ElapsedMilliseconds,
                IsRunning,
                ComponentId: requestId
            }];
        }
        return [];
    }
}

