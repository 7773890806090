<template>
	<el-dialog v-model="props.showDialog" :close-on-click-modal="state.clickModel" :draggable="true" @close="closeExcute"
		@open="openedExcute" :title="$t('mainLayout.report.exportReport')" width="800px" append-to-body>
		<el-steps :active="state.currentStep" align-center>
			<el-step v-for="item in stepList" :title="item"></el-step>
		</el-steps>
		<div v-if="state.currentStep == 0" class="param-container">
			<div class="property-col1">
				<label class="name">{{ $t("mainLayout.report.reportName") }}:</label>
				<el-input class="value" maxlength="20" v-model="state.title" show-word-limit />
			</div>
			<div class="property-col">
				<label class="name">{{ $t("mainLayout.report.reportTemplate") }}:</label>
				<el-select class="value" v-model="state.templateType" @change="changeTemplate">
					<el-option v-for="item in ReportTemplateEnum" :label="ReportTemplateNameMap[item]" :value="item"></el-option>
				</el-select>
			</div>
			<div class="property-col">
				<label class="name">{{ $t('component.vue.exportFormat') }}:</label>
				<el-select class="value" v-model="state.formatType">
					<el-option v-for="item in FormatType" :key="item.value" :value="item.value" :label="item.label" />
				</el-select>
			</div>
			<div class="property-col">
				<label class="name">{{ $t("common.startTime") }}:</label>
				<el-date-picker class="value" type="datetime" :placeholder="$t('common.selectStartTimePlaceholder')"
					:teleported="false" v-model="state.startTime" format="YYYY-MM-DD HH:mm:ss"
					value-format="YYYY-MM-DD HH:mm:ss" />
			</div>
			<div class="property-col">
				<label class="name">{{ $t("common.endTime") }}:</label>
				<el-date-picker class="value" type="datetime" :placeholder="$t('common.selectEndTimePlaceholder')"
					:teleported="false" v-model="state.endTime" format="YYYY-MM-DD HH:mm:ss"
					value-format="YYYY-MM-DD HH:mm:ss" />
			</div>
		</div>

		<component :is="ReportInfo[state.templateType].stepComponent" ref="stepRef" :step="state.currentStep" :datas="state.datas"></component>
		<template #footer>
			<span class="dialog-footer">
				<el-button v-if="state.currentStep >= 1" @click="previousStep">{{
					$t("mainLayout.report.previousStep") }}</el-button>
				<el-button type="primary" v-if="state.currentStep < stepList.length - 1" @click="nextStep">{{
					$t("mainLayout.report.nextStep") }}</el-button>
				<el-button type="primary" v-if="state.currentStep == stepList.length - 1" @click="sureExcute">{{ $t("common.export")
				}}</el-button>
				<el-button @click="cancelExcute">{{ $t("common.cancel") }}</el-button>
			</span>
		</template>
	</el-dialog>
</template>

<script setup>
import { nextTick, reactive, computed, onMounted, ref, watch } from "vue";
import axios from "@common/js/axios";
import store from "@common/js/store";
import dayjs from "dayjs";
import { alertErrorMsg, alertSuccessMsg, guid, i18nGlobal } from "@common/js/utils";
import { ReportInfo } from "./reportLib";
import { ReportTemplateEnum, ReportTemplateNameMap } from "@common/js/enum";

const FormatType = [
	{
		label: "word",
		value: 1,
	}
];

const props = defineProps({
	showDialog: {
		type: Boolean,
		default: false,
	},
});

const stepRef = ref(null);
const state = reactive({
	currentStep: 0,
	clickModel: true,
	reportDialog: false,
	datas: [],
	startTime: null,
	endTime: null,
	enitiyId: null,
	title: ReportTemplateNameMap[ReportTemplateEnum.Template1],
	templateType: ReportTemplateEnum.Template1,
	formatType: 1,
});

const emit = defineEmits(["close"]);
const stepList = computed(() => {
	return ReportInfo[state.templateType].stepNameList;
})

const closeExcute = () => {
	emit("close");
};

const openedExcute = async () => {
	const currentTime = new Date()
	state.currentStep = 0;
	// await getHistoryData();
	state.reportDialog = true;
	state.clickModel = true;
	state.graphId = guid();
	state.startTime =  dayjs(currentTime).subtract(1, 'hour').format("YYYY-MM-DD HH:mm:ss");
	state.endTime =  dayjs(currentTime).format("YYYY-MM-DD HH:mm:ss");
	stepRef.value?.init(state);
};

const sureExcute = () => {
	switch (state.templateType) {
		default:
		case ReportTemplateEnum.Template1:
		case ReportTemplateEnum.Template2:
			stepRef.value?.getReportOperator()?.sureExcute();
			break;
		case ReportTemplateEnum.Template3:
		case ReportTemplateEnum.Template4:
			exportReport34();
			break;
	}
};

/** 导出模板三和模板四 */
const exportReport34 = () => {
	if(!baseVlidate()) return;
		const deviceIds = stepRef.value?.getCheckedDeviceIds();
		axios({
			method: "post",
			url: "Report/export-report",
			data: {
				// Path: store.state.currentDep.Path,
				DeviceIds: deviceIds,
				ReportName: state.title,
				FormatType: state.formatType,
				TemplateType: state.templateType - 1, //合并之后兼容老接口字段，模板3、4对应接口字段TemplateType的1、2
				StartTime: dayjs(state.startTime).format("YYYY-MM-DD HH:mm:ss"),
				EndTime: dayjs(state.endTime).format("YYYY-MM-DD HH:mm:ss")
			},
		}).then((res) => {
			if (res.IsSuccess) {
				alertSuccessMsg(i18nGlobal('common.exportSuccess'));
			}
		});
		emit('close')
		window.Header.showExportTasks();
}

const changeTemplate = (val) => {
	state.title = ReportTemplateNameMap[val];
	state.datas = [];
	nextTick(() => stepRef.value?.init(state));
}

const baseVlidate = () => {
	if(state.title.trim() === "") {
		alertErrorMsg(i18nGlobal("mainLayout.report.nameTip"));
		return false;
	}

	let flag = true;
	const arr = ['\\','\/',':','*','?','<','>','|'];
	arr.forEach(item => {
		if(state.title.includes(item)) flag = false;
	})
	if(!flag){
		alertErrorMsg(i18nGlobal('fileLibrary.nameTip'));
		return false;
	}

	if (state.startTime && state.endTime) {
		const isBefore = dayjs(state.startTime).isBefore(dayjs(state.endTime));
		if (!isBefore) {
			alertErrorMsg(i18nGlobal("common.startTimeLessThanEndTime"));
			return false;
		}
	}
	return true;
}

const cancelExcute = () => {
	if (state.clickModel == false) return;
	emit("close");
};

const previousStep = () => {
	if(state.currentStep > 0) {
		state.currentStep--;
	}
} 

const nextStep = () => {
	if(state.currentStep === 0) {
		if(!baseVlidate()) return; 
	} else {
		if(stepRef.value?.validate(state.currentStep)) return; 
	}
	if(state.currentStep < stepList.value.length - 1) {
		state.currentStep++;
	}
}

const getHistoryData = async () => {
	const node = store.state.currentDep;
	state.startTime = dayjs().add(-1, "hour").format("YYYY-MM-DD HH:mm:ss.SSS");
	state.endTime = dayjs().format("YYYY-MM-DD HH:mm:ss.SSS");
	let res = await axios.get(`report/dataAnalysis/${node.Id}/type/1`);
	if (res.Result) {
		state.datas = res.Result.ReportPoints;
		state.title = res.Result.Name;
		state.enitiyId = res.Result.Id;
		if (res.Result.TemplateType != null) state.templateType = res.Result.TemplateType;
	} else {
		state.datas = [];
		state.templateType = ReportTemplateEnum.Template1;
		state.title = ReportTemplateNameMap[ReportTemplateEnum.Template1];
	}
};

watch(() => state.reportDialog, (val) => {
	if(!val) {
		closeExcute();
	}
})

onMounted(() => {
})
</script>

<style scoped lang="less">
:deep(.el-step.is-center .el-step__line) {
	left: 0 !important;
	right: -50% !important;
}

:deep(.el-step__head.is-process),
:deep(.el-step__head.is-finish) {
	color: var(--phm-theme-background);
	border-color: var(--phm-primary-color);

	.el-step__icon {
		background-color: var(--phm-primary-color);
	}
}

:deep(.el-step__head.is-wait) {
	color: var(--phm-theme-mainText);
	border-color: var(--phm-theme-tip);

	.el-step__icon {
		background-color: var(--phm-theme-border);
	}
}

:deep(.is-finish .el-step__line) {
	background-color: var(--phm-primary-color) !important;
}

:deep(.is-wait .el-step__line) {
	background-color: var(--phm-theme-tip) !important;
}

:deep(.is-process .el-step__line) {
	background-color: var(--phm-primary-color) !important;
}

:deep(.el-step:last-of-type .el-step__line) {
	display: block;
	right: 0% !important;
}

:deep(.el-step__title.is-process) {
	color: var(--phm-primary-color) !important;
}

.param-container {
	margin-top: 10px;
	display: flow-root;

	.property-col {
		width: 365px;
		margin-right: 15px;
		margin-bottom: 10px;

		.name {
			margin-bottom: 5px;
			display: block;
		}
	}

	.property-col1 {
		width: 98%;
		margin-bottom: 10px;

		.name {
			margin-bottom: 5px;
			display: block;
			color: var(--phm-theme-mainText);
		}

		.value {
			margin-top: 8px;
		}
	}
}
</style>
