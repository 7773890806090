// @generated by protobuf-ts 2.9.1
// @generated from protobuf file "protos/paramtwopointnearestdatas.proto" (package "component", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { AxisPositionHistoryDataReply } from "./axispositionhistorydatareply";
import { GraphParamTwoPointRequest } from "./graphparamrequest";
/**
 * @generated from protobuf message component.GetParamTwoPointNearestDatasRequest
 */
export interface GetParamTwoPointNearestDatasRequest {
    /**
     * @generated from protobuf field: string DateTime = 1 [json_name = "DateTime"];
     */
    dateTime: string;
    /**
     * @generated from protobuf field: string EventId = 2 [json_name = "EventId"];
     */
    eventId: string;
    /**
     * @generated from protobuf field: repeated int32 Points = 3 [json_name = "Points"];
     */
    points: number[];
    /**
     * @generated from protobuf field: component.GraphParamTwoPointRequest GraphParam = 4 [json_name = "GraphParam"];
     */
    graphParam?: GraphParamTwoPointRequest;
}
/**
 * @generated from protobuf message component.GetParamTwoPointNearestDatasReply
 */
export interface GetParamTwoPointNearestDatasReply {
    /**
     * @generated from protobuf field: repeated component.AxisPositionHistoryDataReply Points = 1 [json_name = "Points"];
     */
    points: AxisPositionHistoryDataReply[];
}
// @generated message type with reflection information, may provide speed optimized methods
class GetParamTwoPointNearestDatasRequest$Type extends MessageType<GetParamTwoPointNearestDatasRequest> {
    constructor() {
        super("component.GetParamTwoPointNearestDatasRequest", [
            { no: 1, name: "DateTime", kind: "scalar", jsonName: "DateTime", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "EventId", kind: "scalar", jsonName: "EventId", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "Points", kind: "scalar", jsonName: "Points", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "GraphParam", kind: "message", jsonName: "GraphParam", T: () => GraphParamTwoPointRequest }
        ]);
    }
    create(value?: PartialMessage<GetParamTwoPointNearestDatasRequest>): GetParamTwoPointNearestDatasRequest {
        const message = { dateTime: "", eventId: "", points: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetParamTwoPointNearestDatasRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetParamTwoPointNearestDatasRequest): GetParamTwoPointNearestDatasRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string DateTime = 1 [json_name = "DateTime"];*/ 1:
                    message.dateTime = reader.string();
                    break;
                case /* string EventId = 2 [json_name = "EventId"];*/ 2:
                    message.eventId = reader.string();
                    break;
                case /* repeated int32 Points = 3 [json_name = "Points"];*/ 3:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.points.push(reader.int32());
                    else
                        message.points.push(reader.int32());
                    break;
                case /* component.GraphParamTwoPointRequest GraphParam = 4 [json_name = "GraphParam"];*/ 4:
                    message.graphParam = GraphParamTwoPointRequest.internalBinaryRead(reader, reader.uint32(), options, message.graphParam);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetParamTwoPointNearestDatasRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string DateTime = 1 [json_name = "DateTime"]; */
        if (message.dateTime !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.dateTime);
        /* string EventId = 2 [json_name = "EventId"]; */
        if (message.eventId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.eventId);
        /* repeated int32 Points = 3 [json_name = "Points"]; */
        if (message.points.length) {
            writer.tag(3, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.points.length; i++)
                writer.int32(message.points[i]);
            writer.join();
        }
        /* component.GraphParamTwoPointRequest GraphParam = 4 [json_name = "GraphParam"]; */
        if (message.graphParam)
            GraphParamTwoPointRequest.internalBinaryWrite(message.graphParam, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message component.GetParamTwoPointNearestDatasRequest
 */
export const GetParamTwoPointNearestDatasRequest = new GetParamTwoPointNearestDatasRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetParamTwoPointNearestDatasReply$Type extends MessageType<GetParamTwoPointNearestDatasReply> {
    constructor() {
        super("component.GetParamTwoPointNearestDatasReply", [
            { no: 1, name: "Points", kind: "message", jsonName: "Points", repeat: 1 /*RepeatType.PACKED*/, T: () => AxisPositionHistoryDataReply }
        ]);
    }
    create(value?: PartialMessage<GetParamTwoPointNearestDatasReply>): GetParamTwoPointNearestDatasReply {
        const message = { points: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetParamTwoPointNearestDatasReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetParamTwoPointNearestDatasReply): GetParamTwoPointNearestDatasReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated component.AxisPositionHistoryDataReply Points = 1 [json_name = "Points"];*/ 1:
                    message.points.push(AxisPositionHistoryDataReply.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetParamTwoPointNearestDatasReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated component.AxisPositionHistoryDataReply Points = 1 [json_name = "Points"]; */
        for (let i = 0; i < message.points.length; i++)
            AxisPositionHistoryDataReply.internalBinaryWrite(message.points[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message component.GetParamTwoPointNearestDatasReply
 */
export const GetParamTwoPointNearestDatasReply = new GetParamTwoPointNearestDatasReply$Type();
