// @generated by protobuf-ts 2.9.1
// @generated from protobuf file "protos/graphparamrequest.proto" (package "component", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message component.GraphParamRequest
 */
export interface GraphParamRequest {
    /**
     * @generated from protobuf field: string ConnectionId = 1 [json_name = "ConnectionId"];
     */
    connectionId: string;
    /**
     * @generated from protobuf field: int32 Source = 2 [json_name = "Source"];
     */
    source: number;
    /**
     * @generated from protobuf field: repeated component.GraphPointParamRequest Points = 3 [json_name = "Points"];
     */
    points: GraphPointParamRequest[];
    /**
     * @generated from protobuf field: string ComponentId = 4 [json_name = "ComponentId"];
     */
    componentId: string;
}
/**
 * @generated from protobuf message component.GraphPointParamRequest
 */
export interface GraphPointParamRequest {
    /**
     * @generated from protobuf field: int32 PointId = 1 [json_name = "PointId"];
     */
    pointId: number;
    /**
     * @generated from protobuf field: string AlgorithmParam = 2 [json_name = "AlgorithmParam"];
     */
    algorithmParam: string; // JObject
}
/**
 * @generated from protobuf message component.GraphParamTwoPointRequest
 */
export interface GraphParamTwoPointRequest {
    /**
     * @generated from protobuf field: string ConnectionId = 1 [json_name = "ConnectionId"];
     */
    connectionId: string;
    /**
     * @generated from protobuf field: int32 Source = 2 [json_name = "Source"];
     */
    source: number;
    /**
     * @generated from protobuf field: repeated component.GraphPointParamRequest Points = 3 [json_name = "Points"];
     */
    points: GraphPointParamRequest[];
    /**
     * @generated from protobuf field: string ComponentId = 4 [json_name = "ComponentId"];
     */
    componentId: string;
    /**
     * @generated from protobuf field: component.GraphPointParamRequest X = 5 [json_name = "X"];
     */
    x?: GraphPointParamRequest;
    /**
     * @generated from protobuf field: component.GraphPointParamRequest Y = 6 [json_name = "Y"];
     */
    y?: GraphPointParamRequest;
    /**
     * @generated from protobuf field: string AlgorithmParam = 7 [json_name = "AlgorithmParam"];
     */
    algorithmParam: string;
}
/**
 * @generated from protobuf message component.GetFirstWaveDataRequest
 */
export interface GetFirstWaveDataRequest {
    /**
     * @generated from protobuf field: string StartTime = 1 [json_name = "StartTime"];
     */
    startTime: string;
    /**
     * @generated from protobuf field: string EndTime = 2 [json_name = "EndTime"];
     */
    endTime: string;
    /**
     * @generated from protobuf field: repeated int32 Points = 3 [json_name = "Points"];
     */
    points: number[];
    /**
     * @generated from protobuf field: bool IsExternal = 4 [json_name = "IsExternal"];
     */
    isExternal: boolean;
    /**
     * @generated from protobuf field: bool IsFirst = 5 [json_name = "IsFirst"];
     */
    isFirst: boolean;
}
// @generated message type with reflection information, may provide speed optimized methods
class GraphParamRequest$Type extends MessageType<GraphParamRequest> {
    constructor() {
        super("component.GraphParamRequest", [
            { no: 1, name: "ConnectionId", kind: "scalar", jsonName: "ConnectionId", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "Source", kind: "scalar", jsonName: "Source", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "Points", kind: "message", jsonName: "Points", repeat: 1 /*RepeatType.PACKED*/, T: () => GraphPointParamRequest },
            { no: 4, name: "ComponentId", kind: "scalar", jsonName: "ComponentId", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GraphParamRequest>): GraphParamRequest {
        const message = { connectionId: "", source: 0, points: [], componentId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GraphParamRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GraphParamRequest): GraphParamRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string ConnectionId = 1 [json_name = "ConnectionId"];*/ 1:
                    message.connectionId = reader.string();
                    break;
                case /* int32 Source = 2 [json_name = "Source"];*/ 2:
                    message.source = reader.int32();
                    break;
                case /* repeated component.GraphPointParamRequest Points = 3 [json_name = "Points"];*/ 3:
                    message.points.push(GraphPointParamRequest.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string ComponentId = 4 [json_name = "ComponentId"];*/ 4:
                    message.componentId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GraphParamRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string ConnectionId = 1 [json_name = "ConnectionId"]; */
        if (message.connectionId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.connectionId);
        /* int32 Source = 2 [json_name = "Source"]; */
        if (message.source !== 0)
            writer.tag(2, WireType.Varint).int32(message.source);
        /* repeated component.GraphPointParamRequest Points = 3 [json_name = "Points"]; */
        for (let i = 0; i < message.points.length; i++)
            GraphPointParamRequest.internalBinaryWrite(message.points[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string ComponentId = 4 [json_name = "ComponentId"]; */
        if (message.componentId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.componentId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message component.GraphParamRequest
 */
export const GraphParamRequest = new GraphParamRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GraphPointParamRequest$Type extends MessageType<GraphPointParamRequest> {
    constructor() {
        super("component.GraphPointParamRequest", [
            { no: 1, name: "PointId", kind: "scalar", jsonName: "PointId", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "AlgorithmParam", kind: "scalar", jsonName: "AlgorithmParam", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GraphPointParamRequest>): GraphPointParamRequest {
        const message = { pointId: 0, algorithmParam: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GraphPointParamRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GraphPointParamRequest): GraphPointParamRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 PointId = 1 [json_name = "PointId"];*/ 1:
                    message.pointId = reader.int32();
                    break;
                case /* string AlgorithmParam = 2 [json_name = "AlgorithmParam"];*/ 2:
                    message.algorithmParam = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GraphPointParamRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 PointId = 1 [json_name = "PointId"]; */
        if (message.pointId !== 0)
            writer.tag(1, WireType.Varint).int32(message.pointId);
        /* string AlgorithmParam = 2 [json_name = "AlgorithmParam"]; */
        if (message.algorithmParam !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.algorithmParam);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message component.GraphPointParamRequest
 */
export const GraphPointParamRequest = new GraphPointParamRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GraphParamTwoPointRequest$Type extends MessageType<GraphParamTwoPointRequest> {
    constructor() {
        super("component.GraphParamTwoPointRequest", [
            { no: 1, name: "ConnectionId", kind: "scalar", jsonName: "ConnectionId", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "Source", kind: "scalar", jsonName: "Source", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "Points", kind: "message", jsonName: "Points", repeat: 1 /*RepeatType.PACKED*/, T: () => GraphPointParamRequest },
            { no: 4, name: "ComponentId", kind: "scalar", jsonName: "ComponentId", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "X", kind: "message", jsonName: "X", T: () => GraphPointParamRequest },
            { no: 6, name: "Y", kind: "message", jsonName: "Y", T: () => GraphPointParamRequest },
            { no: 7, name: "AlgorithmParam", kind: "scalar", jsonName: "AlgorithmParam", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GraphParamTwoPointRequest>): GraphParamTwoPointRequest {
        const message = { connectionId: "", source: 0, points: [], componentId: "", algorithmParam: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GraphParamTwoPointRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GraphParamTwoPointRequest): GraphParamTwoPointRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string ConnectionId = 1 [json_name = "ConnectionId"];*/ 1:
                    message.connectionId = reader.string();
                    break;
                case /* int32 Source = 2 [json_name = "Source"];*/ 2:
                    message.source = reader.int32();
                    break;
                case /* repeated component.GraphPointParamRequest Points = 3 [json_name = "Points"];*/ 3:
                    message.points.push(GraphPointParamRequest.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string ComponentId = 4 [json_name = "ComponentId"];*/ 4:
                    message.componentId = reader.string();
                    break;
                case /* component.GraphPointParamRequest X = 5 [json_name = "X"];*/ 5:
                    message.x = GraphPointParamRequest.internalBinaryRead(reader, reader.uint32(), options, message.x);
                    break;
                case /* component.GraphPointParamRequest Y = 6 [json_name = "Y"];*/ 6:
                    message.y = GraphPointParamRequest.internalBinaryRead(reader, reader.uint32(), options, message.y);
                    break;
                case /* string AlgorithmParam = 7 [json_name = "AlgorithmParam"];*/ 7:
                    message.algorithmParam = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GraphParamTwoPointRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string ConnectionId = 1 [json_name = "ConnectionId"]; */
        if (message.connectionId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.connectionId);
        /* int32 Source = 2 [json_name = "Source"]; */
        if (message.source !== 0)
            writer.tag(2, WireType.Varint).int32(message.source);
        /* repeated component.GraphPointParamRequest Points = 3 [json_name = "Points"]; */
        for (let i = 0; i < message.points.length; i++)
            GraphPointParamRequest.internalBinaryWrite(message.points[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string ComponentId = 4 [json_name = "ComponentId"]; */
        if (message.componentId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.componentId);
        /* component.GraphPointParamRequest X = 5 [json_name = "X"]; */
        if (message.x)
            GraphPointParamRequest.internalBinaryWrite(message.x, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* component.GraphPointParamRequest Y = 6 [json_name = "Y"]; */
        if (message.y)
            GraphPointParamRequest.internalBinaryWrite(message.y, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* string AlgorithmParam = 7 [json_name = "AlgorithmParam"]; */
        if (message.algorithmParam !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.algorithmParam);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message component.GraphParamTwoPointRequest
 */
export const GraphParamTwoPointRequest = new GraphParamTwoPointRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetFirstWaveDataRequest$Type extends MessageType<GetFirstWaveDataRequest> {
    constructor() {
        super("component.GetFirstWaveDataRequest", [
            { no: 1, name: "StartTime", kind: "scalar", jsonName: "StartTime", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "EndTime", kind: "scalar", jsonName: "EndTime", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "Points", kind: "scalar", jsonName: "Points", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "IsExternal", kind: "scalar", jsonName: "IsExternal", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "IsFirst", kind: "scalar", jsonName: "IsFirst", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<GetFirstWaveDataRequest>): GetFirstWaveDataRequest {
        const message = { startTime: "", endTime: "", points: [], isExternal: false, isFirst: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetFirstWaveDataRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetFirstWaveDataRequest): GetFirstWaveDataRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string StartTime = 1 [json_name = "StartTime"];*/ 1:
                    message.startTime = reader.string();
                    break;
                case /* string EndTime = 2 [json_name = "EndTime"];*/ 2:
                    message.endTime = reader.string();
                    break;
                case /* repeated int32 Points = 3 [json_name = "Points"];*/ 3:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.points.push(reader.int32());
                    else
                        message.points.push(reader.int32());
                    break;
                case /* bool IsExternal = 4 [json_name = "IsExternal"];*/ 4:
                    message.isExternal = reader.bool();
                    break;
                case /* bool IsFirst = 5 [json_name = "IsFirst"];*/ 5:
                    message.isFirst = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetFirstWaveDataRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string StartTime = 1 [json_name = "StartTime"]; */
        if (message.startTime !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.startTime);
        /* string EndTime = 2 [json_name = "EndTime"]; */
        if (message.endTime !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.endTime);
        /* repeated int32 Points = 3 [json_name = "Points"]; */
        if (message.points.length) {
            writer.tag(3, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.points.length; i++)
                writer.int32(message.points[i]);
            writer.join();
        }
        /* bool IsExternal = 4 [json_name = "IsExternal"]; */
        if (message.isExternal !== false)
            writer.tag(4, WireType.Varint).bool(message.isExternal);
        /* bool IsFirst = 5 [json_name = "IsFirst"]; */
        if (message.isFirst !== false)
            writer.tag(5, WireType.Varint).bool(message.isFirst);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message component.GetFirstWaveDataRequest
 */
export const GetFirstWaveDataRequest = new GetFirstWaveDataRequest$Type();
