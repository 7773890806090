import $ from "jquery";
import { PluginMode } from "@core/graphs/webcharts/Dh-webchart-models";
import { CursorElement, CursorPluginBase } from "@core/graphs/plugins/Dh-plugin-cursor-base";
import { i18nGlobal } from "@common/js/utils";

export class DoubleCursorPlugin extends CursorPluginBase {
    constructor(mode) {
        super(mode);
        this.CursorDatas = new Array();
        this.MaxEndDiff = 1000000;
        this.StartPos = null; //鼠标按下位置
        this.StartDatas = new Array(); //鼠标按下时数据信息
        this.CursorCount = 2;
        this.Cursors = new Array();
        this.Title = i18nGlobal('core.doubleCursor');
    }
    OnSizeChanged() {
        super.OnSizeChanged();
        $.each(this.Cursors, function (i, cursor) {
            cursor.Remove();
        });
        let rect = this.Owner.Information.ShapeContainerRect;
        this.Cursors = new Array();
        for (let i = 0; i < this.CursorCount; i++) {
            this.Cursors.push(new CursorElement(this, rect.Height, this.Owner.CoordinateSystem.AxisColor, "1", { X: rect.Left, Y: rect.Top }, "I" + (i + 1)));
        }
        let sender = this;
        $.each(this.Cursors, function (i, cursor) {
            $(sender.Owner.PluginContainer).append(cursor.DOM);
        });
        this.OnRender();
    }
    OnRender() {
        let pos = new Array();
        if (this.CursorDatas.length > 0) {
            for (let i = 0; i < this.CursorDatas.length; i++) {
                if (i > 0) {
                    let last = this.CursorDatas[i - 1];
                    let now = this.CursorDatas[i];
                    if (last.XIndex - now.XIndex > this.MaxEndDiff) {
                        now.XIndex = last.XIndex + this.MaxEndDiff;
                    }
                }
                pos.push(this.CursorDatas[i].XIndex);
            }
        } else {
            for (let i = 0; i < this.CursorCount; i++) {
                pos.push(0 + i * this.MaxEndDiff);
            }
        }
        this.SetPosition(pos);
    }
    OnMouseDown(e) {
        if (!super.OnMouseDown(e)) return false;
        let rect = this.Owner.Information.ShapeContainerRect;
        if (this.Cursors.length == 0) return false;
        if (!this.CursorDatas || this.CursorDatas.length == 0) return false;
        let start = this.CursorDatas[0].OffsetX;
        let end = this.CursorDatas[this.Cursors.length - 1].OffsetX;
        for (let i = 0; i < this.Cursors.length; i++) {
            if (start > this.CursorDatas[i].OffsetX) start = this.CursorDatas[i].OffsetX;
            if (end < this.CursorDatas[i].OffsetX) end = this.CursorDatas[i].OffsetX;
        }
        let xc = e.offsetX - rect.Left;
        if (xc >= start - 5 && xc <= end + 5) {
            this.IsMouseDown = true;
            this.StartPos = xc;
            this.StartDatas = new Array();
            for (let j = 0; j < this.CursorDatas.length; j++) {
                this.StartDatas.push(this.CursorDatas[j].OffsetX);
            }
        }
        return true;
    }
    OnMouseMove(e) {
        if (!super.OnMouseMove(e)) return false;
        if (e.which !== 1) return false;
        if (this.Cursors.length == 0) return false;
        if (this.CursorDatas.length == 0) return false;

        let sender = this;
        let rect = this.Owner.Information.ShapeContainerRect;
        this.IsNotify = false;
        if (this.IsMouseDown) {
            //区域拖动
            if (this.StartDatas.length == 0) return false;
            let delta = e.offsetX - rect.Left - this.StartPos;
            $.each(this.StartDatas, function (i, sd) {
                let offsetx = sd + delta;
                if (offsetx < 0) delta = -sd;
                if (offsetx > rect.Width) delta = rect.Width - sd;
            });

            $.each(this.StartDatas, function (i, sd) {
                let offsetx = sd + delta;
                offsetx = offsetx < 0 ? 0 : offsetx;
                offsetx = offsetx > rect.Width ? rect.Width : offsetx;
                let data = sender.getOffsetData(offsetx);
                if (data) sender.CursorDatas[i] = data;
            });
            this.WacthCursorChanged(() => { this.UpdatePosition(sender.CursorDatas); })
        } else {
            for (let i = 0; i < this.Cursors.length; i++) {
                const cursor = this.Cursors[i];
                if (cursor.IsMouseDown) {
                    this.ProcessCursorMove(e, i);
                }
            }
        }
        return true;
    }
    getOffsetData(offsetx) {
        return this.Owner.CoordinateSystem.GetPositionData(offsetx);
    }
    ProcessCursorMove(e, i) {
        const offsetx = this.getMousePostion(e);
        let data = this.getOffsetData(offsetx);
        if (data) this.CursorDatas[i] = data;
        this.WacthCursorChanged(() => { this.UpdatePosition(this.CursorDatas); })
    }
    OnMouseUp(e) {
        if (!super.OnMouseUp(e)) return false;
        if (e.which != 1 && !e.touches) return false;
        let sender = this;
        if (this.IsMouseDown) {
            this.IsMouseDown = false;
            this.WacthCursorChanged(() => { this.RefreshRelatedData(); })
        } else {
            this.WacthCursorChanged(() => {
                $.each(this.Cursors, function (i, cursor) {
                    if (cursor.IsMouseDown) {
                        cursor.IsMouseDown = false;
                        sender.RefreshRelatedData();
                    }
                });
            })
        }
        return true;
    }
    KeyLeftPress() {
        if (!this.IsEnabled) return;
        if (this.CursorDatas.length > 0) {
            this.IsNotify = false;
            let pos = new Array();
            this.CheckForCusor();
            for (let i = 0; i < this.Cursors.length; i++) {
                const cursor = this.Cursors[i];
                let data = this.CursorDatas[i];
                if (cursor.IsSelected()) {
                    data.XIndex = data.XIndex > 0 ? data.XIndex - 1 : data.XIndex;
                    data.XIndex = this.GetProcessIndex(i, data.XIndex);
                }
                pos.push(data.XIndex);
            }
            this.WacthCursorChanged(() => { this.SetPosition(pos); })
        }
    }
    KeyRightPress() {
        if (!this.IsEnabled) return;
        if (this.CursorDatas.length > 0) {
            this.IsNotify = false;
            let pos = new Array();
            this.CheckForCusor();
            for (let i = 0; i < this.Cursors.length; i++) {
                const cursor = this.Cursors[i];
                let data = this.CursorDatas[i];
                if (cursor.IsSelected()) {
                    data.XIndex += 1;
                    data.XIndex = this.GetProcessIndex(i, data.XIndex);
                }
                pos.push(data.XIndex);
            }
            this.WacthCursorChanged(() => { this.SetPosition(pos); })
        }
    }
    CheckForCusor() {
        let isSelected = false;
        $.each(this.Cursors, function (i, cursor) {
            if (cursor.IsSelected()) {
                isSelected = true;
            }
        });
        if (!isSelected) {
            this.Cursors[0].Select();
        }
    }
    GetProcessIndex(i, index) {
        return index;
    }
    RefreshRelatedData() {
        this.IsNotify = true;
        this.NotifyPositionChanged();
    }
    UpdatePosition(data) {
        let sender = this;
        let rect = this.Owner.Information.ShapeContainerRect;
        var legend = this.Owner.PluginManager.GetPlugin(PluginMode.Statistics);
        for (let cursor of this.Cursors) {
            cursor.ClearPoints();
        }
        let container = this.LegendWnd.find(".cursor-container");
        if (data?.length > 0) {
            container.empty();
            let headContent = "";
            $.each(data, function (i, d) {
                if (d.XText !== undefined) {
                    headContent += "X" + (i + 1) + ":" + d.XText + "&emsp;";
                }
            });
            if (data[0].X) {
                let delatT = data[data.length - 1].X - data[0].X;
                headContent += "dX:" + (delatT < 0 ? "-" : "") + this.Owner.CoordinateSystem.FormatXSpanText(Math.abs(delatT)) + "&emsp;";
            }
            if (headContent) {
                container.append('<span class="cursor-row" >' + headContent + "</span>");
            }
            const dataMap = new Map();
            data.forEach((cursorData, cursorIndex) => {
                if (cursorData.Items?.length > 0) {
                    cursorData.Items.forEach(dataItem => {
                        if (dataItem.Text) {
                            dataItem.CursorIndex = cursorIndex;
                            dataMap.has(dataItem.Text) ? dataMap.get(dataItem.Text).push(dataItem) : dataMap.set(dataItem.Text, [dataItem]);
                        }
                    })
                }
            })
            dataMap.forEach((dataItemList) => {
                let rowContent = "";
                dataItemList.forEach((dataItem) => {
                    rowContent += "Y" + (dataItem.CursorIndex + 1) + ":" + dataItem.Y + "&emsp;";
                    sender.Cursors[dataItem.CursorIndex].DrawPoint(dataItem.OffsetY + rect.Top);
                })
                if (dataItemList.length == data.length) {
                    const dy = dataItemList[data.length - 1].Y - dataItemList[0].Y;
                    if (!isNaN(dy)) rowContent += "dY:" + dy.toFixed(sender.Owner.CoordinateSystem.YDecimalPrecision) + "&emsp;";
                    container.append('<span class="cursor-row" style="color:' + dataItemList[0].Color + '">' + rowContent + "</span>");
                }
            })

            this.CursorDatas = data;
            this.NotifyPositionChanged();
            $.each(this.Cursors, function (i, cursor) {
                let data = sender.CursorDatas[i];
                if (!isNaN(data.OffsetX) && isFinite(data.OffsetX)) {
                    if (data.OffsetX >= 0 && Math.floor(data.OffsetX) <= rect.Width) {
                        cursor.DOM.removeClass("hide");
                        cursor.DOM.attr("transform", "translate(" + (data.OffsetX - 6) + ",0)");
                    } else {
                        cursor.DOM.addClass("hide");
                    }
                }
            });
        } else {
            container.empty();
            $.each(this.Cursors, function (i, cursor) {
                cursor.DOM.attr("transform", "translate(-6,0)");
            });
        }
        legend?.OnRender();
        typeof this.Entity.onUpdatePosition === 'function' && this.Entity.onUpdatePosition()
    }
    NotifyPositionChanged() {
        if (this.Entity.onPositionChanged && this.IsNotify == true && this.CursorDatas.length > 0) {
            const e = {
                Cursors: new Array(),
            };
            $.each(this.CursorDatas, function (i, cd) {
                e.Cursors.push(cd);
            });
            this.Entity.onPositionChanged(e);
        }
        if (this.Entity.onCursorPosChanged && this.CursorDatas.length > 0 && this.IsCursorChanged) {
            const e = this.CursorDatas.map(c => c.XIndex);
            this.Entity.onCursorPosChanged(e);
        }
    }
    GetEventData() {

    }
    Close() {
        super.Close();
        $.each(this.Cursors, function (i, cd) {
            cd.Remove();
        });
        if (this.LegendWnd != null) {
            this.LegendWnd.remove();
        }
    }
    GetPosition() {
        let sender = this;
        let result = {
            PluginType: sender.Entity.Type,
            Position: new Array(),
        };
        if (this.CursorDatas.length > 0) {
            $.each(this.CursorDatas, function (i, d) {
                result.Position.push(d);
            });
        }
        return result;
    }
    GetCursorPosition() {
        let array = [];
        if (this.CursorDatas.length > 0) {
            $.each(this.CursorDatas, function (i, d) {
                array.push(d.XIndex);
            });
        }
        return array;
    }
    GetZoomPosition() {
        if (this.CursorDatas.length > 0) {
            return (this.CursorDatas[0].X + this.CursorDatas[1].X) / 2;
        } else {
            let info = this.Owner.Information;
            return (info.CoordinateInfo.XMin + info.CoordinateInfo.XMax) / 2;
        }
    }
    SetPosition(pos) {
        this.CursorDatas = new Array();
        let data = this.Owner.ShapeOperator.Data;
        if (data.length > 0) {
            for (let p of pos) {
                let data = this.Owner.CoordinateSystem.GetPositionByIndex(p);
                if (data) {
                    this.CursorDatas.push(data);
                }
            }
            this.UpdatePosition(this.CursorDatas);
        } else {
            for (let p of pos) {
                this.CursorDatas.push({
                    XIndex: p,
                });
            }
            this.UpdatePosition(null);
        }
    }
    SetPositionByX(pos) {
        this.IsNotify = false;
        let data = this.Owner.ShapeOperator.Data;
        if (pos.length > 0 && data.length > 0) {
            this.CursorDatas = new Array();
            for (let p of pos) {
                let data = this.Owner.CoordinateSystem.GetPositionByX(p);
                if (data) {
                    this.CursorDatas.push(data);
                }
            }
            this.UpdatePosition(this.CursorDatas);
        }
        this.IsNotify = true;
    }
    ClearData() {
        //this.CursorDatas = new Array();
    }
}
