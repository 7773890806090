import { Debounce } from "@common/js/utils";
import { DspDataRealContext } from "./dspDataRealContext";
import _ from "lodash";
import { ContractTypes } from "@common/js/enum";
import { EnumXDataType } from "./dataParserContext";
import axios from "@common/js/axios";
import dayjs from "dayjs";

export class RecorderCachedDataContext extends DspDataRealContext {
    constructor() {
        super();
        this.debounce = new Debounce();
    }
    getContractType() {
        return ContractTypes.RecorderCachedData;
    }
    unRegisterPoints(requestId) {
        this.graphRegisterPoints.delete(requestId);
    }
    ReceiveData(data) {
        try {
            if (data.Content == null && data.Content.length == 0) {
                return;
            }
            let graphDatas = data.Content;
            this.onDataReceived(graphDatas);
            graphDatas = null;
        } catch (e) {
            console.log(e.message);
        }
    }
    onDataReceived(datas) {
        if (datas.length == 0) return;
        this.dataReceiveds.forEach((handler, requestId) => {
            const arrays = [];
            const input = this.graphRegisterPoints.get(requestId);
            datas.filter((r) => r.Tag == requestId).forEach(data => {
                data.Datas.forEach(signal => {
                    const p = input.Points.find(c => c.PointId == signal.Id);
                    const point = this.pointCacheContext.getPoint(signal.Id);
                    if (p && point) {
                        point.Level = p.Level;
                        arrays.push({
                            PointId: point.Id,
                            Name: point.Name,
                            Unit: point.Unit,
                            FullValue: point.FullValue,
                            SampleFreq: point.SampleFreq,
                            Datas: this.getCachedPointList(signal, point),
                            StartTime: data.StartTime / 10000,
                        });
                    }
                });
            })
            if (arrays.length > 0) {
                handler(arrays);
            }
        });
    }
    getCachedPointList(data, point) {
        const datas = getCachedPointList(data.BlockSignals, point);
        delete data.BlockSignals
        return datas;
    }
    sendComponentRegiterPointsToClient(requestId) {
        const connectionIds = this.getConnectionIds();
        if (connectionIds.length == 0) return;
        const input = this.graphRegisterPoints.get(requestId);
        const postData = {
            ClientIds: connectionIds,
            Datas: {
                StartTime: input.StartTime.format("YYYY-MM-DD HH:mm:ss.SSS"),
                Datas: input.Points.map(c => {
                    return {
                        PointId: c.PointId,
                        DilutionLevel: c.Level,
                    }
                }),
                Tag: requestId
            }
        };
        axios({
            method: "post",
            url: 'datamanagement/search-cached-data',
            data: postData,
        });
    }
    sendComponentUnRegisterPointsToClient() { }
    sendRegisterPointsToClinet() { }
}

export class RecorderCachedDilutionDataContext extends RecorderCachedDataContext {
    constructor() {
        super();
    }
    getContractType() {
        return ContractTypes.RecorderCachedDilution;
    }
    sendComponentRegiterPointsToClient(requestId) {
        const connectionIds = this.getConnectionIds();
        if (connectionIds.length == 0) return;
        const input = this.graphRegisterPoints.get(requestId);
        const postData = {
            ClientIds: connectionIds,
            Datas: {
                StartTime: input.StartTime.format("YYYY-MM-DD HH:mm:ss.SSS"),
                Datas: input.Points.map(c => {
                    return {
                        PointId: c.PointId,
                        DilutionLevel: c.Level,
                    }
                }),
                Tag: requestId
            }
        };
        axios({
            method: "post",
            url: 'datamanagement/search-cached-dilution-data',
            data: postData,
        });
    }
    getCachedPointList(data, point) {
        const datas = getCachedDilutionPointList(data.Datas, point);
        delete data.Datas;
        if (point.Level > 0) {
            const temp = {
                XDataType: EnumXDataType.Time,
                DataCount: 0,
                Points: [],
                DiluteDatas: new Map(),
                Level: point.Level
            };
            temp.DiluteDatas.set(point.Level, datas);
            return temp;
        }
        return datas;
    }
}

export const getCachedPointList = (datas, point) => {
    const pointRet = {
        XDataType: EnumXDataType.Time,
        DataCount: 0,
        Points: [],
        DiluteDatas: new Map(),
        Level: 0
    };
    datas.forEach(item => pointRet.DataCount += item.Datas.length);
    const points = new Array(pointRet.DataCount);
    let prex = 0;
    for (const segment of datas) {
        const diff = 1E7 / point.SampleFreq;
        const start = segment.StartTime;
        for (let y = 0; y < segment.Datas.length; y++) {
            const x = start + diff * y;
            points[y + prex] = {
                X: x / 10000,
                Y: segment.Datas[y]
            }
        }
        if (points.length > 0) points[prex].Break = true;
        prex += segment.Datas.length;
    }
    if (points.length > 0 && window.showLog) {
        console.log(`原始返回start:${dayjs(points[0].X).format("YYYY-MM-DD HH:mm:ss.SSS")} end:${dayjs(points[pointRet.DataCount - 1].X).format("YYYY-MM-DD HH:mm:ss.SSS")} 当前时间:${dayjs().format("YYYY-MM-DD HH:mm:ss.SSS")} count:${pointRet.DataCount} 段数:${datas.length}`);
    }
    pointRet.Points = points;
    return pointRet;
}

export const getCachedDilutionPointList = (datas, point) => {
    const pointRet = {
        XDataType: EnumXDataType.Time,
        DataCount: 0,
        Points: [],
        Level: point.Level
    };
    pointRet.Points = new Array();
    const interval = getPointTimeInterval(point.SampleFreq, point.Level);
    let last = 0;
    for (const data of datas) {
        const x = data.Time / 10000;
        if ((data.Time - last) / interval > 1.1) {
            pointRet.Points.push({ X: x, Y: data.MinValue, Break: true });
        } else {
            pointRet.Points.push({ X: x, Y: data.MinValue });
        }
        last = data.Time;
        pointRet.Points.push({ X: x, Y: data.MaxValue });
    }
    pointRet.DataCount = pointRet.Points.length;
    if (window.showLog) {
        if (pointRet.DataCount > 0) {
            console.log(`抽点返回start:${new dayjs(pointRet.Points[0].X).format("YYYY-MM-DD HH:mm:ss.SSS")} end:${new dayjs(pointRet.Points[pointRet.DataCount - 1].X).format("YYYY-MM-DD HH:mm:ss.SSS")} count:${pointRet.DataCount} 段数:${pointRet.Points.filter(p => p.Break).length}`);
        } else {
            console.log(`抽点返回数据为空`);
        }
    }
    return pointRet;
}

export const getDulitionPointCount = (freq, level) => {
    if (freq < 2000) {
        return 1000 * parseInt(Math.pow(10, level - 1));
    }
    return parseInt(freq / 1000) * 1000 * parseInt(Math.pow(10, level - 1));
}

export const getPointTimeInterval = (freq, level) => {
    if (level == 0) return 1E7 / freq;
    const dulitionCount = getDulitionPointCount(freq, level);
    return (1E7 / freq) * dulitionCount;
}

export const getDilutionLevel = (start, end, freq) => {
    const time = Number(end) - Number(start);
    const count = time * freq / 1000;
    if ((freq < 1E5 && count <= 1E5) || (freq >= 1E5 && count < 2E5)) return 0;
    if (freq <= 1E5) {
        for (let i = 1; i < 7; i++) {
            const timeLevel = 1000 * getDulitionPointCount(freq, i) * (1000 / freq);
            if (time < timeLevel) return i;
        }
    } else {
        for (let i = 1; i < 7; i++) {
            const timeLevel = 2000 * getDulitionPointCount(freq, i) * (1000 / freq);
            if (time < timeLevel) return i;
        }
    }
    return 6;
}

export const showDataTimeLog = (points, msg) => {
    if (window.showLog && points.length > 0) {
        console.log(`${msg} start:${dayjs(points[0].X).format("YYYY-MM-DD HH:mm:ss.SSS")} end:${dayjs(points[points.length - 1].X).format("YYYY-MM-DD HH:mm:ss.SSS")}`);
    }
}