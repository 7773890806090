<template>
	<div class="graphContainer">
		<div class="item" v-for="item in state.graphData" :key="item.key">
			<component :ref="(el) => (item.chartRef = el)" :is="componentLibarys[item.componentCategory].default"
				:componentOption="item.componentOption"> </component>
		</div>
	</div>
</template>

<script setup>
import { nextTick, onMounted, reactive } from "vue";
import { AlarmGraphDetailComponentLayoutOperator } from "./alarmGraphLayoutOperator";

const componentLibarys = window.componentLibarys;
const props = defineProps({
	row: {},
});

const state = reactive({
	graphData: [],
});

const layoutOperator = new AlarmGraphDetailComponentLayoutOperator(state);
const setData = (data) => {
	state.graphData = [];
	state.currentInfo = data;
	layoutOperator.setData(props.row, data);
	nextTick(() => {
		if (props.row.AlarmModel == 1 || data.FaultParam == null) {
			layoutOperator.createPointAlarmComponents();
		} else if (props.row.AlarmModel == 2) {
			layoutOperator.createFaultAlarmComponents();
		}
	});
};

const update = () => {
	setTimeout(() => {
		layoutOperator.OnSizeChanged();
	}, 100);
};

const close = () => {
	layoutOperator.close();
};

defineExpose({
	setData,
	update,
	close,
});
</script>

<style lang="less" scoped>
.item {
	width: 100%;
	position: relative;
}

.graphContainer {
	min-height: 620px;
	max-height: 960px;
	overflow-y: auto;
	position: relative;
}
</style>
