<template>
	<el-dialog v-model="state.showDialog" :draggable="false" @close="closeDialog" top="5vh" :width="1000"
		:title="$t('headerSetting.warning.alarmDetail')" append-to-body>
		<div class="header" style="display: flex; justify-content: space-between; align-items: center">
			<el-radio-group v-model="state.currentRadio">
				<el-radio-button :label="$t('headerSetting.warning.alarmDynamics')" />
				<el-radio-button :label="$t('headerSetting.warning.graphDisplay')" @click="updateSize"
					v-if="state.detailRow.AlarmType != 5" />
				<el-radio-button v-if="state.showRepair && state.repairInfo.length > 0"
					:label="$t('alarmPro.repairScheme')" />
			</el-radio-group>
		</div>
		<div class="dynamic" v-if="state.currentRadio === $t('headerSetting.warning.alarmDynamics')">
			<ul>
				<li v-for="item in state.AlarmEvents">
					<div class="time">
						<div class="text">{{ item.AlarmTime }}</div>
					</div>
					<div class="detail">
						<div class="node"></div>
						<div v-if="state.detailRow.AlarmModel !== 2">
							<div class="title">{{ item.AlarmEventTypeStr }}</div>
							<div class="item">{{ $t("headerSetting.warning.alarmLevel") }}:{{ item.AlarmLevelStr }}
							</div>
							<div class="item" v-if="item.AlarmTypeStr">{{
								$t("headerSetting.warning.amplitudeAlarmType") }}:{{ item.AlarmTypeStr }}</div>
							<div class="item faultDetail" v-for="child in item.AlarmRulerStr?.split(';')">{{ child
							}}</div>
							<div class="item">{{ item.ValueStr }}</div>
						</div>

						<div v-if="state.detailRow.AlarmModel === 2">
							<div class="title">{{ item.AlarmEventTypeStr }}</div>
							<div class="item faultDetail">{{ item.Detail }}</div>
						</div>
					</div>
				</li>
			</ul>
		</div>
		<div class="chartWrapper theme1" v-show="state.currentRadio === $t('headerSetting.warning.graphDisplay')">
			<alarmGraphDetail ref="alarmGraphRef" :row="state.detailRow"></alarmGraphDetail>
		</div>
		<div class="repair" v-if="state.currentRadio === $t('alarmPro.repairScheme')">
			<div class="item" v-for="item in state.repairInfo">
				<div class="name">{{ item.name }}:</div>
				<div class="value" v-html="item.value"></div>
			</div>
			<div class="nocontent" v-if="state.repairInfo.length === 0">{{ $t("common.noContent") }}</div>
		</div>
	</el-dialog>
</template>

<script setup>
import { reactive, ref, nextTick  } from "vue";
import axios from "@common/js/axios";
import { i18nGlobal } from "@common/js/utils";
import alarmGraphDetail from "./alarmGraphDetail.vue";
import { axiosAuth } from "@common/js/auth";

const emit = defineEmits(["cancel", "change"]);
const alarmGraphRef = ref(null);
const props = defineProps({
	currentRadio: {
		default: '',
		type: String,
	},
});

const state = reactive({
	currentRadio: '',
	showRepair: false,
	showDialog: false,
	AlarmEvents: [],
	repairInfo: [],
	detailRow: {}
});

const updateSize = () => {
	alarmGraphRef?.value?.update();
};

const getFaults = (id) => {
	axiosAuth({
		method: "get",
		url: `Faults/${id}`,
		auth: "Fault_Browse"
	}).then((res) => {
		if (res.IsSuccess) {
			const data = JSON.parse(res.Result.Param);
			state.repairInfo = data.filter((t) => t.scheme);
		}
	});
};

const getDetail = (row, url, auth) => {
	state.currentRadio = props.currentRadio;
	state.showRepair = !!row.FaultId;
	if (state.showRepair) getFaults(row.FaultId);
	state.detailRow = row;
	if (state.currentRadio === i18nGlobal("alarmPro.repairScheme") && !state.showRepair) {
		state.currentRadio = i18nGlobal("headerSetting.warning.alarmDynamics");
	}
	if(state.currentRadio === i18nGlobal('headerSetting.warning.graphDisplay') && state.detailRow.AlarmType == 5) {
		state.currentRadio = i18nGlobal("headerSetting.warning.alarmDynamics");
	}
	axiosAuth({
		method: "get",
		url: url,
		auth,
		params: {
			infoId: row.Id,
		},
	}).then((res) => {
		if (res.IsSuccess) {
			let result = typeof res.Result === 'string' ? JSON.parse(res.Result) : res.Result;
			state.AlarmEvents = result.AlarmEvents;
			if (state.detailRow.AlarmType != 5) {
				alarmGraphRef?.value?.setData(result);
			}
		}
	});
};

const openDialog = (row, url) => {
	state.showDialog = true;
	nextTick(() => {
		getDetail(row, url)
	})
}

const closeDialog = () => {
	alarmGraphRef?.value?.close();
	emit('change', { currentRadio: state.currentRadio })
};

defineExpose({
	openDialog
})
</script>

<style scoped lang="less">
.dynamic {
	margin-top: 20px;
	display: flex;
	justify-content: center;
	padding-bottom: 20px;
	overflow: auto;

	ul {
		width: 70%;
		max-height: 500px;
		// overflow: auto;

		li {
			display: flex;
			align-items: center;

			// justify-content: space-between;
			.time {
				height: 100%;
				margin-right: 20px;
				width: 170px;
				color: var(--phm-theme-mainText);
			}

			.detail {
				border-left: 2px solid #eee;
				position: relative;
				padding: 20px 0;
				padding-left: 20px;
				flex: 1;

				div {
					// margin-bottom: 4px;
				}

				.title {
					color: var(--phm-theme-title);
					font-weight: bold;
					margin-bottom: 4px;
				}

				.item {
					color: var(--phm-theme-mainText);

					&.faultDetail {
						white-space: pre-line;
					}
				}

				.node {
					position: absolute;
					width: 10px;
					height: 10px;
					border-radius: 50%;
					left: -6px;
					top: 50%;
					transform: translateY(-50%);
					background-color: #eee;
				}
			}
		}
	}
}

.repair {
	margin-top: 20px;
	min-height: 100px;
	padding-bottom: 20px;
	.item {
		display: flex;
		color: var(--phm-theme-mainText);
		margin-bottom: 10px;

		.name {
			margin-right: 10px;
			font-size: 14px;
			text-align: left;
		}

		.value {
			font-size: 14px;
			white-space: pre-line;
		}
	}
}

</style>
