// @generated by protobuf-ts 2.9.1
// @generated from protobuf file "protos/bandenergyalarmdatas.proto" (package "alarm", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { HistoryPointDatasReply } from "./historypointdatasreply";
/**
 * @generated from protobuf message alarm.QueryBandEnergyAlarmDatasRequest
 */
export interface QueryBandEnergyAlarmDatasRequest {
    /**
     * @generated from protobuf field: string EventId = 1 [json_name = "EventId"];
     */
    eventId: string;
    /**
     * @generated from protobuf field: string BeginTime = 2 [json_name = "BeginTime"];
     */
    beginTime: string;
    /**
     * @generated from protobuf field: string EndTime = 3 [json_name = "EndTime"];
     */
    endTime: string;
    /**
     * @generated from protobuf field: int32 FirstNumber = 4 [json_name = "FirstNumber"];
     */
    firstNumber: number;
    /**
     * @generated from protobuf field: int32 LastNumber = 5 [json_name = "LastNumber"];
     */
    lastNumber: number;
    /**
     * @generated from protobuf field: repeated int32 PointIds = 6 [json_name = "PointIds"];
     */
    pointIds: number[];
}
/**
 * @generated from protobuf message alarm.QueryBandEnergyAlarmDatasReply
 */
export interface QueryBandEnergyAlarmDatasReply {
    /**
     * @generated from protobuf field: repeated storage.HistoryPointDatasReply Points = 1 [json_name = "Points"];
     */
    points: HistoryPointDatasReply[];
}
// @generated message type with reflection information, may provide speed optimized methods
class QueryBandEnergyAlarmDatasRequest$Type extends MessageType<QueryBandEnergyAlarmDatasRequest> {
    constructor() {
        super("alarm.QueryBandEnergyAlarmDatasRequest", [
            { no: 1, name: "EventId", kind: "scalar", jsonName: "EventId", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "BeginTime", kind: "scalar", jsonName: "BeginTime", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "EndTime", kind: "scalar", jsonName: "EndTime", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "FirstNumber", kind: "scalar", jsonName: "FirstNumber", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "LastNumber", kind: "scalar", jsonName: "LastNumber", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "PointIds", kind: "scalar", jsonName: "PointIds", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<QueryBandEnergyAlarmDatasRequest>): QueryBandEnergyAlarmDatasRequest {
        const message = { eventId: "", beginTime: "", endTime: "", firstNumber: 0, lastNumber: 0, pointIds: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<QueryBandEnergyAlarmDatasRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: QueryBandEnergyAlarmDatasRequest): QueryBandEnergyAlarmDatasRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string EventId = 1 [json_name = "EventId"];*/ 1:
                    message.eventId = reader.string();
                    break;
                case /* string BeginTime = 2 [json_name = "BeginTime"];*/ 2:
                    message.beginTime = reader.string();
                    break;
                case /* string EndTime = 3 [json_name = "EndTime"];*/ 3:
                    message.endTime = reader.string();
                    break;
                case /* int32 FirstNumber = 4 [json_name = "FirstNumber"];*/ 4:
                    message.firstNumber = reader.int32();
                    break;
                case /* int32 LastNumber = 5 [json_name = "LastNumber"];*/ 5:
                    message.lastNumber = reader.int32();
                    break;
                case /* repeated int32 PointIds = 6 [json_name = "PointIds"];*/ 6:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.pointIds.push(reader.int32());
                    else
                        message.pointIds.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: QueryBandEnergyAlarmDatasRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string EventId = 1 [json_name = "EventId"]; */
        if (message.eventId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.eventId);
        /* string BeginTime = 2 [json_name = "BeginTime"]; */
        if (message.beginTime !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.beginTime);
        /* string EndTime = 3 [json_name = "EndTime"]; */
        if (message.endTime !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.endTime);
        /* int32 FirstNumber = 4 [json_name = "FirstNumber"]; */
        if (message.firstNumber !== 0)
            writer.tag(4, WireType.Varint).int32(message.firstNumber);
        /* int32 LastNumber = 5 [json_name = "LastNumber"]; */
        if (message.lastNumber !== 0)
            writer.tag(5, WireType.Varint).int32(message.lastNumber);
        /* repeated int32 PointIds = 6 [json_name = "PointIds"]; */
        if (message.pointIds.length) {
            writer.tag(6, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.pointIds.length; i++)
                writer.int32(message.pointIds[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message alarm.QueryBandEnergyAlarmDatasRequest
 */
export const QueryBandEnergyAlarmDatasRequest = new QueryBandEnergyAlarmDatasRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class QueryBandEnergyAlarmDatasReply$Type extends MessageType<QueryBandEnergyAlarmDatasReply> {
    constructor() {
        super("alarm.QueryBandEnergyAlarmDatasReply", [
            { no: 1, name: "Points", kind: "message", jsonName: "Points", repeat: 1 /*RepeatType.PACKED*/, T: () => HistoryPointDatasReply }
        ]);
    }
    create(value?: PartialMessage<QueryBandEnergyAlarmDatasReply>): QueryBandEnergyAlarmDatasReply {
        const message = { points: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<QueryBandEnergyAlarmDatasReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: QueryBandEnergyAlarmDatasReply): QueryBandEnergyAlarmDatasReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated storage.HistoryPointDatasReply Points = 1 [json_name = "Points"];*/ 1:
                    message.points.push(HistoryPointDatasReply.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: QueryBandEnergyAlarmDatasReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated storage.HistoryPointDatasReply Points = 1 [json_name = "Points"]; */
        for (let i = 0; i < message.points.length; i++)
            HistoryPointDatasReply.internalBinaryWrite(message.points[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message alarm.QueryBandEnergyAlarmDatasReply
 */
export const QueryBandEnergyAlarmDatasReply = new QueryBandEnergyAlarmDatasReply$Type();
