// @generated by protobuf-ts 2.9.1
// @generated from protobuf file "protos/selflearningfeaturedatas.proto" (package "alarm", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { HistoryPointDataReply } from "./historypointdatareply";
/**
 * @generated from protobuf message alarm.GetSelfLearningFeatureDatasRequest
 */
export interface GetSelfLearningFeatureDatasRequest {
    /**
     * @generated from protobuf field: string RelateId = 1 [json_name = "RelateId"];
     */
    relateId: string;
}
/**
 * @generated from protobuf message alarm.GetSelfLearningFeatureDatasReply
 */
export interface GetSelfLearningFeatureDatasReply {
    /**
     * @generated from protobuf field: repeated storage.HistoryPointDataReply Points = 1 [json_name = "Points"];
     */
    points: HistoryPointDataReply[];
}
// @generated message type with reflection information, may provide speed optimized methods
class GetSelfLearningFeatureDatasRequest$Type extends MessageType<GetSelfLearningFeatureDatasRequest> {
    constructor() {
        super("alarm.GetSelfLearningFeatureDatasRequest", [
            { no: 1, name: "RelateId", kind: "scalar", jsonName: "RelateId", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetSelfLearningFeatureDatasRequest>): GetSelfLearningFeatureDatasRequest {
        const message = { relateId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSelfLearningFeatureDatasRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSelfLearningFeatureDatasRequest): GetSelfLearningFeatureDatasRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string RelateId = 1 [json_name = "RelateId"];*/ 1:
                    message.relateId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSelfLearningFeatureDatasRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string RelateId = 1 [json_name = "RelateId"]; */
        if (message.relateId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.relateId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message alarm.GetSelfLearningFeatureDatasRequest
 */
export const GetSelfLearningFeatureDatasRequest = new GetSelfLearningFeatureDatasRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSelfLearningFeatureDatasReply$Type extends MessageType<GetSelfLearningFeatureDatasReply> {
    constructor() {
        super("alarm.GetSelfLearningFeatureDatasReply", [
            { no: 1, name: "Points", kind: "message", jsonName: "Points", repeat: 1 /*RepeatType.PACKED*/, T: () => HistoryPointDataReply }
        ]);
    }
    create(value?: PartialMessage<GetSelfLearningFeatureDatasReply>): GetSelfLearningFeatureDatasReply {
        const message = { points: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSelfLearningFeatureDatasReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSelfLearningFeatureDatasReply): GetSelfLearningFeatureDatasReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated storage.HistoryPointDataReply Points = 1 [json_name = "Points"];*/ 1:
                    message.points.push(HistoryPointDataReply.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSelfLearningFeatureDatasReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated storage.HistoryPointDataReply Points = 1 [json_name = "Points"]; */
        for (let i = 0; i < message.points.length; i++)
            HistoryPointDataReply.internalBinaryWrite(message.points[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message alarm.GetSelfLearningFeatureDatasReply
 */
export const GetSelfLearningFeatureDatasReply = new GetSelfLearningFeatureDatasReply$Type();
