import $ from 'jquery'
import { CursorElement, CursorPluginBase } from "@core/graphs/plugins/Dh-plugin-cursor-base"
import { i18nGlobal } from '@common/js/utils'

export class FlagCursorElement extends CursorElement {
    constructor(owner, height, linecolor, stroke, startPos, name) {
        super(owner, height, linecolor, stroke, startPos, name)
    }
    Update(option) {
        if (option.Name) {
            this.Name = option.Name
            this.DOM.children('text').text(option.Name)
        }
        if (option.Color) {
            this.Color = option.Color
            let groups = $(this.DOM[0].parentNode).children('g')
            $.each(groups, function (i, g) {
                $(g).attr('cursor-color', option.Color)
            })
            this.DOM.children('line').css('stroke', this.Color)
            this.DOM.children('rect').css('fill', this.Color)
        }
    }
    UpdatePosition(x) {
        let rect = this.parent.Owner.Information.ShapeContainerRect
        if (x < 0) x = 0
        if (x > rect.Width) x = rect.Width
        this.DOM.attr('transform', 'translate(' + (x - 6) + ',0)')
    }
}


export class FlagCursorPlugin extends CursorPluginBase {
    constructor(mode) {
        super(mode)
        this.Title = i18nGlobal('core.flagCursor');
        this.Cursors = new Map()
        this.CursorDatas = new Map()
    }
    CreatePlugin() {
        this.InitSvgEvent()
    }
    CreateCursor(option) {
        if (this.Cursors.has(option.Key)) return
        let rect = this.Owner.Information.ShapeContainerRect
        let pos = rect.Left + rect.Width * option.X
        let cursor = new FlagCursorElement(this, rect.Height, option.Color, '1', { X: rect.Left, Y: rect.Top }, 'I' + option.Name)
        $(this.Owner.PluginContainer).append(cursor.DOM)
        this.Cursors.set(option.Key, cursor)
        this.CursorDatas.set(option.Key, option)
        cursor.UpdatePosition(pos)
    }
    RemoveCursor(option) {
        if (this.Cursors.has(option.Key)) {
            let cursor = this.Cursors.get(option.Key)
            cursor.Remove()
            this.Cursors.delete(option.Key)
        }
        this.CursorDatas.delete(option.Key)
    }
    UpdateCursor(option) {
        if (this.Cursors.has(option.Key)) {
            let cursor = this.Cursors.get(option.Key)
            cursor.Update(option)
        }
        this.CursorDatas.set(option.Key, option)
    }
    OnSizeChanged() {
        for (const cursor of this.Cursors.values()) {
            cursor.Remove();
        }
        for (const cursorData of this.CursorDatas.values()) {
            this.RemoveCursor(cursorData)
            this.CreateCursor(cursorData)
        }
    }
    OnMouseMove(e) {
        if (!super.OnMouseMove(e)) return false
        if (e.which !== 1) return false
        if (this.Cursors.size == 0) return false
        if (this.CursorDatas.size == 0) return false
        let rect = this.Owner.Information.ShapeContainerRect
        let pos = e.offsetX - rect.Left
        let sender = this
        $.each(this.Cursors.keys(), function () {
            let cursor = sender.Cursors.get(this)
            let cursorData = sender.CursorDatas.get(this)
            if (cursor.IsMouseDown) {
                cursorData.X = pos / rect.Width
                cursor.UpdatePosition(pos)
            }
        })
        return true
    }
    OnMouseUp(e) {
        if (!super.OnMouseUp(e)) return false;
        if (e.which !== 1) return false;
        if (!this.IsMouseDown) return;
        const rect = this.Owner.Information.ShapeContainerRect;
        const pos = e.offsetX - rect.Left;
        for (const key of this.Cursors.keys()) {
            const cursor = this.Cursors.get(key);
            const cursorData = this.CursorDatas.get(key);
            if (cursor.IsMouseDown) {
                cursorData.X = pos / rect.Width;
                cursor.UpdatePosition(pos);
            }
        }
        return true;
    }
    GetPosition() {
        let result = {
            PluginType: this.Entity.Type,
            Cursors: this.CursorDatas.getValues(),
        }
        return result
    }
    Close() {
        super.Close()
        for (const cursor of this.Cursors.values()) {
            cursor.Remove();
        }
        this.Cursors.clear();
        this.CursorDatas.clear();
    }
}
