import { getTextSize } from "@common/js/utils";
import $ from "jquery";

class DrawApi {
    constructor() {
        this.Canvas = null;
        this.Offset = 0.5;
        this.DrawContext = null;
        this.textHeightMap = {};
    }
    /**设置上下文 */
    SetContext(ctx) {
        if (this.DrawContext == null) {
            this.Canvas = document.getElementById(ctx);
            if (this.Canvas) {
                let cxt = this.Canvas.getContext("2d");
                this.DrawContext = cxt;
            }
        }
    }
    /**设置或返回用于笔触的颜色、渐变或模式 */
    SetStrokeStyle(style) {
        this.DrawContext.strokeStyle = style;
    }
    /**设置绘笔的大小*/
    SetDrawWidth(width) {
        this.DrawContext.lineWidth = width;
    }
    /**开始画线*/
    BeginPath() {
        this.DrawContext.beginPath();
    }
    /**结束画线*/
    ClosePath() {
        this.DrawContext.closePath();
    }
    /**左侧竖直连接线到*/
    VerticalLeftLineTo(x, y) {
        this.DrawContext.lineTo(parseInt(x) + this.Offset, y);
    }
    /**顶部水平连接线到*/
    HorizontalTopLineTo(x, y) {
        this.DrawContext.lineTo(x, parseInt(y) + this.Offset);
    }
    /**右侧竖直连接线到*/
    VerticalRightLineTo(x, y) {
        this.DrawContext.lineTo(parseInt(x) - this.Offset, y);
    }
    /**底部水平连接线到*/
    HorizontalBottomLineTo(x, y) {
        this.DrawContext.lineTo(x, parseInt(y) - this.Offset);
    }
    /**左侧竖直移动线到*/
    VerticalLeftMoveTo(x, y) {
        this.DrawContext.moveTo(parseInt(x) + this.Offset, y);
    }
    /**顶部水平移动线到*/
    HorizontalTopMoveTo(x, y) {
        this.DrawContext.moveTo(x, parseInt(y) + this.Offset);
    }
    /**右侧竖直移动线到*/
    VerticalRightMoveTo(x, y) {
        this.DrawContext.moveTo(parseInt(x) - this.Offset, y);
    }
    /**底部水平移动线到*/
    HorizontalBottomMoveTo(x, y) {
        this.DrawContext.moveTo(x, parseInt(y) - this.Offset);
    }
    /**移动到*/
    MoveTo(x, y) {
        this.DrawContext.moveTo(parseInt(x) + this.Offset, parseInt(y) + this.Offset);
    }
    /**线连接到*/
    LineTo(x, y) {
        this.DrawContext.lineTo(parseInt(x) + this.Offset, parseInt(y) + this.Offset);
    }
    /**绘制矩形*/
    Rectangle(x, y, width, height, style) {
        this.DrawContext.beginPath();
        this.DrawContext.fillStyle = style;
        this.DrawContext.rect(x, y, width, height);
        this.DrawContext.fill();
        this.DrawContext.closePath();
    }
    /**绘制阴影矩形*/
    ShadowRectangle(x, y, width, height, style) {
        this.DrawContext.beginPath();
        this.DrawContext.fillStyle = style;
        this.DrawContext.shadowColor = 'rgba(0,0,0,.12)'
        this.DrawContext.shadowBlur = 6

        this.DrawContext.rect(x, y, width, height);
        this.DrawContext.fill();
        this.DrawContext.closePath();
    }
    /**渲染*/
    Stroke(style, lineWidth) {
        if (lineWidth != this.DrawContext.lineWidth) {
            this.DrawContext.lineWidth = lineWidth;
        }
        this.SetStrokeStyle(style);
        this.DrawContext.stroke();
    }
    // 获取文字高度
    GetTextHeight(fontSize, text, fontStyle) {
        if (this.textHeightMap[fontSize]) {
            return this.textHeightMap[fontSize]
        } else {
            const textSize = getTextSize(fontSize, text, fontStyle);
            if (textSize.height > 0) {
                this.textHeightMap[fontSize] = textSize.height;
                return textSize.height;
            } return 0;
        }
    }
    /**测量文本*/
    MeasureText(s, fontSize, fontStyle, useDom) {
        let reg = /[1-9][0-9]*/g;
        let height = '';
        if (useDom) {
            height = this.GetTextHeight(fontSize, s, fontStyle);;
        } else {
            let numList = fontSize.match(reg);
            height = parseFloat(numList[0]);
        }
        this.DrawContext.font = fontStyle;
        let result = {
            Width: this.DrawContext.measureText(s).width, Height: height
        };
        return result;
    }
    /**测量竖向（文字横向显示）文本*/
    MeasureCrossangeVerticalText(s, fontSize, fontStyle) {
        if (s == null || s === "") {
            return { Width: 0, Height: 0 };
        }

        let reg = /[1-9][0-9]*/g;
        let numList = fontSize.match(reg);
        let height = parseFloat(numList[0]);
        return { Width: numList, Height: s.toString().length * height };
    }
    /**绘制文本*/
    FillText(s, x, y, fontColor, fontStyle) {
        this.DrawContext.font = fontStyle;
        this.DrawContext.fillStyle = fontColor;
        this.DrawContext.fillText(s, x, y);
    }
    /**绘制竖直文本*/
    FillVerticalText(s, x, y, fontColor, fontStyle) {
        let text = s;
        if (s == null) {
            text = "";
        }
        this.DrawContext.save();
        this.DrawContext.translate(x, y);
        this.DrawContext.rotate(270 * Math.PI / 180);
        this.FillText(text, 0, 0, fontColor, fontStyle);
        this.DrawContext.restore();
    }
    /**绘制反向竖直文本*/
    FillReversalVerticalText(s, x, y, fontColor, fontStyle) {
        this.DrawContext.save();
        this.DrawContext.translate(x, y);
        this.DrawContext.rotate(90 * Math.PI / 180);
        this.FillText(s, 0, 0, fontColor, fontStyle);
        this.DrawContext.restore();
    }
    /**绘制渐变色矩形*/
    CreateLinearGradientRectangle(x0, y0, x1, y1, colors) {
        let grd = this.DrawContext.createLinearGradient(0, y0, 0, y1);
        let step = 1 / (colors.length - 1);
        for (let i = 0; i < colors.length; i++) {
            grd.addColorStop(step * i, colors[i]);
        }
        this.DrawContext.fillStyle = grd;
        this.DrawContext.fillRect(x0, y1, x1 - x0, Math.abs(y0 - y1));
    }
    /**绘制横向显示的竖直文本*/
    FillCrossrangeVerticalText(s, x, y, fontColor, fontStyle) {
        if (s == null || s === "") return;
        let reg = /[1-9][0-9]*/g;
        let numList = fontStyle.match(reg);
        let height = parseFloat(numList[0]);

        for (let i = 0; i < s.length; i++) {
            let char = s[i];

            this.DrawContext.save();
            if (char == ")") {
                y -= 10;
            }
            this.DrawContext.translate(x, y + i * height);

            if (char == "(" || char == ")") {
                this.DrawContext.rotate(90 * Math.PI / 180);
                this.FillText(char, 0, 0, fontColor, fontStyle);
                y += 5;
            } else {
                this.FillText(char, 0, 0, fontColor, fontStyle);
            }

            this.DrawContext.restore();
        }
    }
    /**清除所有*/
    ClearAll() {
        this.DrawContext.clearRect(0, 0, this.Canvas.offsetWidth, this.Canvas.offsetHeight);
    }
    Dispose() {
        this.Canvas.width = 0;
        this.Canvas.height = 0;
        this.DrawContext.fillRect(0, 0, 0, 0);
        this.DrawContext = null;
        $(this.Canvas).empty();
        this.Canvas = null;
    }
    /**剪切矩形*/
    ClipRect(x, y, width, height) {
        this.DrawContext.rect(x, y, width, height);
        this.DrawContext.clip();
    }
    ClipPath(points) {
        if (points.length == 0) return;
        this.DrawContext.beginPath();
        this.DrawContext.moveTo(points[0].X, points[0].Y);
        for (let p of points) {
            this.DrawContext.lineTo(p.X, p.Y);
        }
        this.DrawContext.clip();
    }
    Save() {
        this.DrawContext.save();
    }
    Restore() {
        this.DrawContext.restore();
    }
    DrawImage(image, x, y, width, height) {
        this.DrawContext.drawImage(image, x, y, width, height);
    }
    /**填充圆形*/
    FillArc(x, y, r, sAngle, eAngle, counterclockwise, fillStyle, lineStyle) {
        this.DrawContext.save();
        this.DrawContext.beginPath();
        this.DrawContext.arc(x, y, r, sAngle, eAngle, counterclockwise);
        this.DrawContext.fillStyle = fillStyle;
        this.DrawContext.fill();
        this.DrawContext.strokeStyle = lineStyle;
        this.DrawContext.stroke();
        this.DrawContext.closePath();
        this.DrawContext.restore();
    }
    /**填充三角形*/
    FillTriangle(point1, point2, point3, style) {
        //this.DrawContext.fillStyle = style;
        this.DrawContext.save();
        this.DrawContext.beginPath();

        this.DrawContext.moveTo(point1.X, point1.Y);
        this.DrawContext.lineTo(point2.X, point2.Y);
        this.DrawContext.lineTo(point3.X, point3.Y);

        //this.DrawContext.closePath();
        this.DrawContext.fillStyle = style;
        // this.DrawContext.lineWidth = 3;
        //this.DrawContext.stroke();
        this.DrawContext.fill();
        this.DrawContext.restore();
    }
    /**填充正方形*/
    FillSquare(x, y, r, style) {
        this.DrawContext.save();
        let radius = 3.5;
        if (r) {
            radius = r;
        }
        this.DrawContext.fillStyle = style;
        this.DrawContext.fillRect(x - radius, y - radius, radius * 2, radius * 2);
        this.DrawContext.restore();
    }
    /**填充三角形*/
    FillTriangleEx(x, y, r, style) {
        this.DrawContext.save();
        // 根据三角形中心x,y 定位三点位置
        let radius = 2;
        if (r) {
            radius = r;
        }

        this.DrawContext.beginPath();
        this.DrawContext.moveTo(x - radius * 1.2, y + radius);
        this.DrawContext.lineTo(x + radius * 1.2, y + radius);
        this.DrawContext.lineTo(x, y - radius * 3);
        this.DrawContext.closePath();
        this.DrawContext.fillStyle = style;
        this.DrawContext.fill();
        this.DrawContext.restore();
    }
    DottedlineCircle(x, y, r, lineStyle) {
        let circum = 2 * Math.PI * r;
        let increase = 360 / (circum / 6);
        for (let i = 0; i < 360; i += increase) {
            let dx = x + r * Math.cos(2 * Math.PI * i / 360);
            let dy = y + r * Math.sin(2 * Math.PI * i / 360);
            this.FillArc(dx, dy, 0.5, 0, Math.PI * 2, true, lineStyle, lineStyle);
        }
    }
    /**设置虚线间隔*/
    SetLineDash(arry) {
        this.DrawContext.setLineDash(arry);
    }
    /**
     * 绘制圆柱体
     * @author by ttq 2021-04-19
     * @param {any} x        圆柱体上部椭圆圆心x坐标
     * @param {any} y        圆柱体上部椭圆圆心y坐标
     * @param {any} a        圆柱体上部椭圆长轴半轴a
     * @param {any} b        圆柱体上部椭圆短轴半轴b
     * @param {any} h        圆柱体的高 h 
     * @param {any} color    圆柱体横向渐变颜色数组['#337ded', '#27569e', '#27569e']
     */
    DrawCylinder(x, y, a, b, h, color) {
        this.DrawContext.save();
        if (h < 0) {
            h = - h;
            y = y - h;
        }

        this.DrawContext.fillStyle = 'transparent';
        this.DrawContext.beginPath();
        this.DrawBezierArcLow(x, y + h, a, b);
        this.DrawContext.fill();

        this.DrawContext.fillStyle = color[0];
        this.DrawContext.moveTo(x - a, y);
        this.DrawContext.lineTo(x - a, y + h);
        this.DrawContext.lineTo(x + a, y + h);
        this.DrawContext.lineTo(x + a, y);
        this.DrawContext.lineTo(x - a, y);
        this.DrawContext.fill();
        this.DrawBezierEllipse(x, y, a, b, color);
        this.DrawContext.restore();
    }
    DrawCylinderEx(x, y, a, b, h, color) {
        this.DrawContext.save();
        if (h < 0) {
            h = - h;
            y = y - h;
        }

        this.DrawContext.fillStyle = 'transparent';
        this.DrawContext.beginPath();
        this.DrawBezierArcLow(x, y + h, a, b);
        this.DrawContext.fill();

        this.DrawContext.fillStyle = color[0];
        this.DrawContext.moveTo(x - a, y);
        this.DrawContext.lineTo(x - a, y + h);
        this.DrawContext.lineTo(x + a, y + h);
        this.DrawContext.lineTo(x + a, y);
        this.DrawContext.lineTo(x - a, y);
        this.DrawContext.fill();
        this.DrawBezierEllipseEx(x, y, a, b, color);
        this.DrawContext.restore();
    }
    /**
     * 绘制三次贝塞尔下圆弧
     * @author by ttq 2021-04-19
     * @param {any} x   圆弧圆心x
     * @param {any} y   圆弧圆心y
     * @param {any} a   圆弧长轴半轴a
     * @param {any} b   圆弧短轴半轴b
     */
    DrawBezierArcLow(x, y, a, b) {
        this.DrawContext.save();
        let k = .5522848;
        let ox = a * k; // 水平控制点偏移量
        let oy = b * k; // 垂直控制点偏移量</p> <p> ctx.beginPath();
        this.DrawContext.moveTo(x - a, y);
        this.DrawContext.bezierCurveTo(x - a, y + oy, x - ox, y + b, x, y + b);
        this.DrawContext.bezierCurveTo(x + ox, y + b, x + a, y + oy, x + a, y);
        this.DrawContext.closePath();
        this.DrawContext.fill();
        this.DrawContext.restore();
    }
    /**
     * 绘制三次贝塞尔椭圆
     * @author by ttq 2021-04-19
     * @param {any} x
     * @param {any} y
     * @param {any} a
     * @param {any} b
     */
    DrawBezierEllipse(x, y, a, b, color) {
        this.DrawContext.beginPath();
        this.DrawContext.fillStyle = color[0];
        this.DrawContext.ellipse(x, y, a, b, 0, 0, Math.PI);
        this.DrawContext.closePath();
        this.DrawContext.fill();
        this.DrawContext.restore();

        this.DrawContext.beginPath();
        this.DrawContext.fillStyle = color[1];
        this.DrawContext.ellipse(x, y, a, b, 0, Math.PI, 2 * Math.PI);
        this.DrawContext.closePath();
        this.DrawContext.fill();
        this.DrawContext.restore();
    }
    DrawBezierEllipseEx(x, y, a, b, color) {
        this.DrawContext.beginPath();
        this.DrawContext.fillStyle = color[1];
        this.DrawContext.ellipse(x, y, a, b, 0, 0, 2 * Math.PI);
        this.DrawContext.closePath();
        this.DrawContext.fill();
        this.DrawContext.restore();
    }
    /**
     * 绘制七段数码管
     * @author by ttq 2021-04-21
     * @param {any} x                 //绘制起点x
     * @param {any} y                 //绘制起点y
     * @param {any} color             //数码管颜色颜色
     * @param {any} lineWidth         //数码管的宽度
     * @param {any} digitalWidth      //整个数字的宽度
     * @param {any} offset            //数码管边角点的偏移量
     * @param {any} alpha             //数码管的透明度数组比如[1, 1, 1, 1, 0.1, 1, 1]
     */
    DrawDigital(x, y, color, lineWidth, digitalWidth, offset, alpha) {
        this.DrawContext.save();
        this.DrawContext.fillStyle = color;
        this.DrawContext.beginPath();
        this.DrawContext.globalAlpha = alpha[0];
        this.DrawContext.moveTo(x, y + 0.5 * lineWidth);
        this.DrawContext.lineTo(x + offset, y);
        this.DrawContext.lineTo(x + digitalWidth - offset, y);
        this.DrawContext.lineTo(x + digitalWidth, y + 0.5 * lineWidth);
        this.DrawContext.lineTo(x + digitalWidth - offset, y + lineWidth);
        this.DrawContext.lineTo(x + offset, y + lineWidth);
        this.DrawContext.lineTo(x, y + 0.5 * lineWidth);
        this.DrawContext.fill();
        //七段数码管第二段
        this.DrawContext.beginPath();
        this.DrawContext.globalAlpha = alpha[1];
        this.DrawContext.moveTo(x, y + 0.5 * lineWidth + 2);
        this.DrawContext.lineTo(x, y + 1.5 * lineWidth + 2 + (digitalWidth - (offset * 2)));
        this.DrawContext.lineTo(x + offset, y + lineWidth + 2 + (digitalWidth - (offset * 2)));
        this.DrawContext.lineTo(x + offset, y + lineWidth + 2);
        this.DrawContext.lineTo(x, y + 0.5 * lineWidth + 2);
        this.DrawContext.fill();
        //七段数码管第三段
        this.DrawContext.beginPath();
        this.DrawContext.globalAlpha = alpha[2];
        this.DrawContext.moveTo(x, y + 1.5 * lineWidth + 2 * 2 + (digitalWidth - (offset * 2)));
        this.DrawContext.lineTo(x + offset, y + lineWidth + 2 * 2 + (digitalWidth - (offset * 2)));
        this.DrawContext.lineTo(x + digitalWidth - offset, y + lineWidth + 2 * 2 + (digitalWidth - (offset * 2)));
        this.DrawContext.lineTo(x + digitalWidth, y + 1.5 * lineWidth + 2 * 2 + (digitalWidth - (offset * 2)));
        this.DrawContext.lineTo(x + digitalWidth - offset, y + 2 * lineWidth + 2 * 2 + (digitalWidth - (offset * 2)));
        this.DrawContext.lineTo(x + offset, y + 2 * lineWidth + 2 * 2 + (digitalWidth - (offset * 2)));
        this.DrawContext.lineTo(x, y + 1.5 * lineWidth + 2 * 2 + (digitalWidth - (offset * 2)));
        this.DrawContext.fill();
        //七段数码管第四段
        this.DrawContext.beginPath();
        this.DrawContext.globalAlpha = alpha[3];
        this.DrawContext.moveTo(x + digitalWidth, y + 0.5 * lineWidth + 2);
        this.DrawContext.lineTo(x + digitalWidth, y + 1.5 * lineWidth + 2 + (digitalWidth - (offset * 2)));
        this.DrawContext.lineTo(x + digitalWidth - offset, y + lineWidth + 2 + (digitalWidth - (offset * 2)));
        this.DrawContext.lineTo(x + digitalWidth - offset, y + lineWidth + 2);
        this.DrawContext.lineTo(x + digitalWidth, y + 0.5 * lineWidth + 2);
        this.DrawContext.fill();
        //七段数码管第五段
        this.DrawContext.beginPath();
        this.DrawContext.globalAlpha = alpha[4];
        this.DrawContext.moveTo(x, y + 1.5 * lineWidth + 2 * 3 + (digitalWidth - (offset * 2)));
        this.DrawContext.lineTo(x, y + 2.5 * lineWidth + 2 * 3 + (digitalWidth - (offset * 2)) * 2);
        this.DrawContext.lineTo(x + offset, y + 2 * lineWidth + 2 * 3 + (digitalWidth - (offset * 2)) * 2);
        this.DrawContext.lineTo(x + offset, y + 2 * lineWidth + 2 * 3 + (digitalWidth - (offset * 2)));
        this.DrawContext.lineTo(x, y + 1.5 * lineWidth + 2 * 3 + (digitalWidth - (offset * 2)));
        this.DrawContext.fill();
        //七段数码管第六段
        this.DrawContext.beginPath();
        this.DrawContext.globalAlpha = alpha[5];
        this.DrawContext.moveTo(x, y + 2.5 * lineWidth + 2 * 4 + (digitalWidth - (offset * 2)) * 2);
        this.DrawContext.lineTo(x + offset, y + 3 * lineWidth + 2 * 4 + (digitalWidth - (offset * 2)) * 2);
        this.DrawContext.lineTo(x + digitalWidth - offset, y + 3 * lineWidth + 2 * 4 + (digitalWidth - (offset * 2)) * 2);
        this.DrawContext.lineTo(x + digitalWidth, y + 2.5 * lineWidth + 2 * 4 + (digitalWidth - (offset * 2)) * 2);
        this.DrawContext.lineTo(x + digitalWidth - offset, y + 2 * lineWidth + 2 * 4 + (digitalWidth - (offset * 2)) * 2);
        this.DrawContext.lineTo(x + offset, y + 2 * lineWidth + 2 * 4 + (digitalWidth - (offset * 2)) * 2);
        this.DrawContext.lineTo(x, y + 2.5 * lineWidth + 2 * 4 + (digitalWidth - (offset * 2)) * 2);
        this.DrawContext.fill();
        //七段数码管第七段
        this.DrawContext.beginPath();
        this.DrawContext.globalAlpha = alpha[6];
        this.DrawContext.moveTo(x + digitalWidth, y + 1.5 * lineWidth + 2 * 3 + (digitalWidth - (offset * 2)));
        this.DrawContext.lineTo(x + digitalWidth, y + 2.5 * lineWidth + 2 * 3 + (digitalWidth - (offset * 2)) * 2);
        this.DrawContext.lineTo(x + digitalWidth - offset, y + 2 * lineWidth + 2 * 3 + (digitalWidth - (offset * 2)) * 2);
        this.DrawContext.lineTo(x + digitalWidth - offset, y + 2 * lineWidth + 2 * 3 + (digitalWidth - (offset * 2)));
        this.DrawContext.lineTo(x + digitalWidth, y + 1.5 * lineWidth + 2 * 3 + (digitalWidth - (offset * 2)));
        this.DrawContext.fill();
        this.DrawContext.globalAlpha = 1;
        this.DrawContext.restore();
    }
    /**
     * 绘制正方形
     * @author by ttq 2021-04-21
     * @param {any} x        //起始位置x
     * @param {any} y        //起始位置y
     * @param {any} width    //边长
     * @param {any} color    //填充颜色
     */
    DrawSquare(x, y, width, color) {
        this.DrawContext.save();
        this.DrawContext.fillStyle = color;
        this.DrawContext.beginPath();
        this.DrawContext.moveTo(x, y);
        this.DrawContext.lineTo(x, y + width);
        this.DrawContext.lineTo(x + width, y + width);
        this.DrawContext.lineTo(x + width, y);
        this.DrawContext.lineTo(x, y);
        this.DrawContext.fill();
        this.DrawContext.restore();
    }
    Fill(fillStyle, strokeStyle) {
        this.DrawContext.fillStyle = fillStyle;
        this.DrawContext.strokeStyle = strokeStyle;
        this.DrawContext.fill();
        this.DrawContext.stroke();
    }
    Arc(x, y, r, sAngle, eAngle, counterclockwise) {
        this.DrawContext.arc(x, y, r, sAngle, eAngle, counterclockwise);
    }
}

export default DrawApi;