import { AmplitudeTypeEnum, SpectrumTypeEnum, WinTypeEnum, MachineTreeNodeTypeEnum, AlarmTypeEnum, NormalLevel, DisplayContentEnum, ParentGuid, TimeUnitEnum } from '@common/js/enum'
import { ElLoading, ElMessage, ElMessageBox } from 'element-plus'
import $ from 'jquery'
import dayjs from 'dayjs'
import axios from "@common/js/axios";
import store from './store';
import i18n from "@common/js/i18n";
import { showSuccessToast, showFailToast } from 'vant';
import { ruleCheck } from './rules';

// 错误信息提示
export const alertErrorMsg = (msg, isHtml) => {
	if (store.state.browseType === 'app') {
		showFailToast(msg)
	} else {
		ElMessage({
			message: `${msg}`,
			type: 'error',
			showClose: true,
			dangerouslyUseHTMLString: isHtml,
		})
	}

}

// 成功信息提示
export const alertSuccessMsg = (msg, isHtml) => {
	if (store.state.browseType === 'app') {
		showSuccessToast(msg)
	} else {
		ElMessage({
			message: `${msg}`,
			type: 'success',
			showClose: true,
			dangerouslyUseHTMLString: isHtml,
		})
	}
}


// 警告信息提示
export const alertWarningMsg = (msg) =>
	ElMessage({
		message: `${msg}`,
		type: 'warning',
		showClose: true,
	})

// 警告信息提示
export const alertInfoMsg = (msg, isHtml) =>
	ElMessage({
		message: `${msg}`,
		type: 'info',
		showClose: true,
		dangerouslyUseHTMLString: isHtml,
	})

// 确认
export const alertConfirm = (callback, args, cancelCallback, closeCallback) => ElMessageBox.confirm(
	`${args && args.msg ? args.msg : i18nGlobal('common.confirmTips')}`,
	`${args && args.title ? args.title : i18nGlobal('common.tip')}`,
	{
		distinguishCancelAndClose: true,
		confirmButtonText: args?.sureText ?? i18nGlobal('common.confirm'),
		cancelButtonText: args?.cancelText ?? i18nGlobal('common.cancel'),
		type: 'warning'
	}
).then(() => {
	callback()
}).catch((action) => {
	if (closeCallback) {
		if (action == "cancel") cancelCallback?.();
		else closeCallback?.();
	} else {
		cancelCallback?.();
	}
})

export const alertCancelConfirm = (sureCallback, cancelCallback, args) =>
	ElMessageBox.confirm(`${args && args.msg ? args.msg : i18nGlobal('common.confirmTips')}`, `${args && args.title ? args.title : i18nGlobal('common.tip')}`, {
		confirmButtonText: i18nGlobal('common.confirm'),
		cancelButtonText: i18nGlobal('common.cancel'),
		type: 'warning',
	}).then(() => {
		sureCallback()
	}).catch((err) => {
		if (err == 'cancel') {
			cancelCallback()
		}
	})

// 整屏加载
export const fullScreenLoading = (text, config = { needEllipsis: true, totalCount: 0 }) => {
	const id = guid()
	let txt = text ? text : i18nGlobal('common.loading')
	txt += config.needEllipsis ? '...' : ''
	const loading = $(`
        <div id="${id}" class='fullScreenLoadingWrapper ${store.state.browseType}'>
            <div class="loadingIcons">
                <div class="item"></div>
                <div class="item"></div>
                <div class="item"></div>
                <div class="item"></div>
                <div class="item"></div>
            </div>
            <div class="fullScreenLoadingText">${txt}
                ${config.totalCount ? `<span class='sampleCount'>${i18nGlobal('commonJs.progress')}：0/${config.totalCount}</span>` : `<span class='sampleCount'></span>`}
            </div>
        </div>`)
	$('body').append(loading)

	return {
		close: function () {
			$(`.fullScreenLoadingWrapper`).remove()
		},
	}
}

// 整屏加载
export const elFullScreenLoading = (text) => {
	const loading = ElLoading.service({
		lock: true,
		text: text || i18nGlobal('common.loading'),
		background: 'rgba(0, 0, 0, 0.1)',
	})
	return loading
}

export const elFullScreenDelayLoading = (text, time = 500) => {
	let loading = null;
	let isDispose = false;
	const ret = {
		close: () => {
			isDispose = true;
			loading?.close();
		}
	}
	setTimeout(() => {
		if (isDispose) return;
		loading = ElLoading.service({
			lock: true,
			text: text || i18nGlobal('common.loading'),
			background: 'rgba(0, 0, 0, 0.1)',
		});
	}, time);
	return ret;
}

export const removeElLoading = () => {
	$('.is-fullscreen').remove()
}

// 判断为null或空字符
export const isNullOrSpaceStr = (str) => {
	return str === null || str.trim() === ''
}

// 判读为null或undefined
export const isNullOrUndefined = (str) => {
	return str === null || str === undefined
}

// 对象属性转驼峰
export const objToLowerCase = (obj) => {
	let newObj = {}
	for (let key in obj) {
		let newKey = key[0].toLowerCase() + key.slice(1)
		newObj[newKey] = obj[key]
	}
	return newObj
}

export const objToUpperCase = (obj) => {
	let newObj = {}
	for (let key in obj) {
		let newKey = key[0].toUpperCase() + key.slice(1)
		newObj[newKey] = obj[key]
	}
	return newObj
}

export const setOrgs = (data, Id) => {
	let tree = []
	let temp
	for (let i = 0; i < data.length; i++) {
		if (data[i].ParentId == Id) {
			let obj = data[i]
			temp = setOrgs(data, data[i].Id)
			if (temp.length > 0) {
				obj.children = temp
			}
			tree.push(obj)
		}
	}
	return tree
}

export const setDepartment = (data, Id) => {
	let tree = []
	let temp
	for (let i = 0; i < data.length; i++) {
		if (data[i].ParentId === Id) {
			let obj = data[i]
			temp = setDepartment(data, data[i].Id)
			if (temp.length > 0) {
				obj.children = temp
			}
			tree.push(obj)
		}
	}
	return tree
}

export const setOrderDepartment = (data, Id) => {
	let tree = []
	let temp
	for (let i = 0; i < data.length; i++) {
		if (data[i].ParentId === Id) {
			let obj = data[i]
			temp = setOrderDepartment(data, data[i].Id)
			if (temp.length > 0) {
				obj.children = temp
			}
			tree.push(obj)
		}
	}
	return tree.orderBy(p => p.DisplayIndex);
}

export const setFilterDepartment = (data, Id) => {
	let tree = []
	let temp
	for (let i = 0; i < data.length; i++) {
		let obj = data[i]
		if (obj.ParentId === Id) {
			if (obj.IsVisible) {
				temp = setFilterDepartment(data, obj.Id)
				if (temp.length > 0) {
					obj.children = temp
				}
				tree.push(obj)
			} else {
				temp = setFilterDepartment(data, obj.Id)
				for (let o of temp) {
					tree.push(o)
				}
			}
		}
	}
	return tree
}

const setVisible = (data, item) => {
	const parent = data.find(t => t.Id === item.ParentId);
	if (parent) {
		if (item.IsVisible) {
			parent.IsVisible = item.IsVisible;
		}
		setVisible(data, parent)
	}
}

export const setAuthTree = (data) => {
	data.forEach(item => {
		if (item.IsLeaf) {
			setVisible(data, item)
		}
	})
	return data.filter(item => item.IsVisible);
}

export const setDeviceTree = (data, Id) => {
	let tree = []
	let temp
	for (let i = 0; i < data.length; i++) {
		if (data[i].ParentId === Id) {
			let obj = data[i]
			obj.disabled = (obj.NodeType !== MachineTreeNodeTypeEnum.Device && obj.NodeType !== MachineTreeNodeTypeEnum.Scene);
			temp = setDepartment(data, data[i].Id)
			if (temp.length > 0) {
				obj.children = temp
			}
			tree.push(obj)
		}
	}
	return tree
}

export const getChildNodesByPath = (id, data) => {
	const dep = data.find(item => item.Id === id);
	if (!dep) {
		return {
			originTree: [],
			treeNodes: []
		}
	} 
	let path = dep.Path
	let originTree = data.filter(item => item.Path.slice(0, path.length).includes(path) || path === item.Path);
	let treeNodes = [dep];
	treeNodes[0].children = [];
	treeNodes[0].children = setOrderDepartment(originTree.sort((a, b) => a.DisplayIndex - b.DisplayIndex), id);
	return {
		originTree,
		treeNodes
	}
}

// 随机生成id
export const guid = () => {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		var r = (Math.random() * 16) | 0,
			v = c == 'x' ? r : (r & 0x3) | 0x8
		return v.toString(16)
	})
}

// 清除表单
export const clearForm = (form) => {
	for (const key in form) {
		switch (Object.prototype.toString.call(form[key])) {
			case '[object String]':
				form[key] = ''
				break
			case '[object Array]':
				form[key] = []
				break
			case '[object Boolean]':
				form[key] = false
				break
			case '[object Object]':
				form[key] = {}
				break
			default:
				form[key] = ''
				break
		}
	}
}

export const mixColor = (color1, color2, weight) => {
	weight = Math.max(Math.min(Number(weight), 1), 0)
	let r1 = parseInt(color1.substring(1, 3), 16)
	let g1 = parseInt(color1.substring(3, 5), 16)
	let b1 = parseInt(color1.substring(5, 7), 16)
	let r2 = parseInt(color2.substring(1, 3), 16)
	let g2 = parseInt(color2.substring(3, 5), 16)
	let b2 = parseInt(color2.substring(5, 7), 16)
	let r = Math.round(r1 * (1 - weight) + r2 * weight)
	let g = Math.round(g1 * (1 - weight) + g2 * weight)
	let b = Math.round(b1 * (1 - weight) + b2 * weight)
	r = ('0' + (r || 0).toString(16)).slice(-2)
	g = ('0' + (g || 0).toString(16)).slice(-2)
	b = ('0' + (b || 0).toString(16)).slice(-2)
	return '#' + r + g + b
}

export const getHexOpacityColor = (color = '#000', opacity = 0.5) => {
	opacity = Math.max(opacity, 0)
	opacity = Math.min(opacity, 1)
	color = color.replace(/\#/g, '').toUpperCase()
	if (color.length === 3) {
		let arr = color.split('')
		color = ''
		for (let i = 0; i < arr.length; i++) {
			color += arr[i] + arr[i] //将简写的3位字符补全到6位字符
		}
	}
	let num = Math.round(255 * opacity) //四舍五入
	let str = ''
	let arrHex = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F'] //十六进制数组
	while (num > 0) {
		let mod = num % 16
		num = (num - mod) / 16
		str = arrHex[mod] + str
	}
	if (str.length == 1) str = '0' + str
	if (str.length == 0) str = '00'
	return `#${color + str}`
}

export const setPhmTheme = (theme, store) => {
	const data = { ...theme }
	if (theme.GraphTheme === false) {
		document.body.setAttribute('id', 'lightBody')
	} else if (theme.GraphTheme === true) {
		document.body.setAttribute('id', 'darkBody')
	}
	delete data.primary

	const { BackgroundColor, CardColor, SeparatorColor, TitleColor, TextColor, TooltipColor } = data
	const map = {
		'--phm-theme-background': BackgroundColor,
		'--phm-theme-card': CardColor,
		'--phm-theme-title': TitleColor,
		'--phm-theme-mainText': TextColor,
		'--phm-theme-tip': TooltipColor,
		'--phm-theme-border': SeparatorColor,
		'--phm-theme-card-5': getHexOpacityColor(CardColor, 0.5),
		'--phm-theme-mainText-15': getHexOpacityColor(TextColor, 0.15),
		'--phm-theme-mainText-70': getHexOpacityColor(TextColor, 0.7),
		'--phm-theme-mainText-50': getHexOpacityColor(TextColor, 0.5),
		'--phm-theme-background-9': getHexOpacityColor(BackgroundColor, 0.9),
		'--phm-theme-background-7': getHexOpacityColor(BackgroundColor, 0.7),
	}

	const node = document.documentElement

	for (const key in map) {
		node.style.setProperty(key, map[key])
	}

	store.commit('setThemeColor', { theme: data })
}

export const setPhmPrimaryColor = (color, store) => {
	const pre = '--el-color-primary'
	const customPre = '--phm-primary-color'
	const customLightPre = '--phm-primary-light-color'

	// 白色混合色
	const mixWhite = '#ffffff'
	// 黑色混合色
	const mixBlack = '#000000'
	const node = document.documentElement

	const CustomColor = getHexOpacityColor(color, 0.15)

	node.style.setProperty(pre, color)
	node.style.setProperty(customPre, color)
	node.style.setProperty(customLightPre, CustomColor)

	for (let i = 1; i < 10; i += 1) {
		node.style.setProperty(`--phm-light-${i}`, getHexOpacityColor(color, i / 10))
	}
	for (let i = 1; i < 10; i += 1) {
		node.style.setProperty(`${pre}-light-${i}`, mixColor(color, mixWhite, i * 0.1))
	}
	for (let i = 1; i < 3; i += 1) {
		node.style.setProperty(`--el-color-primary-dark-${i}`, mixColor(color, mixBlack, i * 0.1))
	}
	store.commit('setPrimaryColor', { primaryColor: color })
}

export const getCssVar = (text) => {
	const arr = document.documentElement.style.cssText.split(';')
	const primary = arr.find((item) => item.includes(text)).split(':')[1]
	return primary
}

export const isEmptyValue = (val) => {
	if (typeof val === 'string') {
		return val.trim() === ''
	} else {
		return val === undefined || val === null
	}
}

export const getTextSize = (fontSize, text, family) => {
	let span = document.createElement('span')
	let result = {
		width: 0,
		height: 0,
	}
	span.style.visibility = 'hidden'
	span.style.fontSize = ruleCheck.IsNum(fontSize) ? fontSize + 'px' : fontSize //文字大小
	span.style.fontFamily = family || 'SYHT' //字体
	span.style.display = 'inline-block'
	document.body.appendChild(span)
	if (typeof span.textContent != 'undefined') {
		span.textContent = text
	} else {
		span.innerText = text
	}
	//使用window.getComputedStyle方法获取KDS
	result.width = parseFloat(window.getComputedStyle(span).width)
	result.height = parseFloat(window.getComputedStyle(span).height) - 10
	$(span).remove()
	return result
}

export const getOrgDevices = (arr, devices = []) => {
	if (arr && arr.length > 0) {
		arr.forEach((item) => {
			if (item.NodeType === MachineTreeNodeTypeEnum.Device) {
				devices.push(item.Path)
			}
			if (item.children && item.children.length > 0) {
				getOrgDevices(item.children, devices)
			}
		})
	}
	return devices
}

export const watchTime = (name, func) => {
	console.time(name)
	func()
	console.timeEnd(name)
}

export const isObjectValueEqual = (a, b) => {
	let aProps = Object.getOwnPropertyNames(a)
	let bProps = Object.getOwnPropertyNames(b)
	if (aProps.length != bProps.length) return false
	for (let i = 0; i < aProps.length; i++) {
		let propName = aProps[i]
		let propA = a[propName]
		let propB = b[propName]
		if (!b.hasOwnProperty(propName)) return false
		if (propA instanceof Object) {
			if (!isObjectValueEqual(propA, propB)) {
				return false
			}
		} else if (propA !== propB) {
			return false
		}
	}
	return true
}

export const getDeepClone = (a) => {
	if (a === undefined) return ''
	let str = JSON.stringify(a)
	return JSON.parse(str)
}

export const getOffsetByBody = (el) => {
	let offsetTop = 0
	let offsetLeft = 0
	while (el && el.tagName != 'BODY') {
		offsetTop += el.offsetTop
		offsetLeft += el.offsetLeft
		el = el.offsetParent
	}
	return {
		offsetLeft,
		offsetTop,
	}
}

export const removeUnit = (unitId) => {
	for (let handler of window.onUnitRemoved.values()) {
		handler(unitId)
	}
}

export const getSourceUrl = () => {
	let address = ''
	if (Config.url.endsWith('/api/')) {
		address = Config.url.replace('/api/', '')
	}
	if (Config.url.endsWith('/api')) {
		address = Config.url.replace('/api', '')
	}
	return address
}

export const getSpectrumAmplCorrectCoef = (spectType, winType, amplType, length) => {
	if (spectType == SpectrumTypeEnum.Realtime && (amplType == AmplitudeTypeEnum.Amplitude || amplType == AmplitudeTypeEnum.RMS)) {
		return getWindowFactor(winType, length, 1)
	} else {
		return 1
	}
}

export const getWindowFactor = (winType, length, wflag) => {
	let winData = getWindowDatas(length, winType, 0, length)
	let result = 0
	switch (wflag) {
		case 1:
		case 2:
			result =
				sum(winData, (d) => {
					return d
				}) / length
			result = 1 / result
			break
		case 3:
			result = Math.sqrt(
				sum(winData, (d) => {
					d * d
				}) / length
			)
			result = 1 / result
			break
	}
	return result
}

export const getWindowDatas = (length, winType, startIndex, winWidth) => {
	let result = new Array(length)
	let len2 = (length + 1) / 2
	switch (winType) {
		case WinTypeEnum.Hanning:
			let hntmp = (2 * Math.PI) / (length + 1)
			for (let k = 0; k < len2; k++) {
				result[k] = 0.5 * (1 - Math.cos((k + 1) * hntmp))
				result[length - k - 1] = result[k]
			}
			break
		case WinTypeEnum.Hamming:
			let hmtmp = (2.0 * Math.PI) / (length - 1)
			for (let k = 0; k < len2; ++k) {
				result[k] = 0.54 - 0.46 * Math.Cos(hmtmp * k)
				result[length - k - 1] = result[k]
			}
			break
		case WinTypeEnum.Flattop:
			let fttmp = (2.0 * Math.PI) / (length - 1)
			for (let k = 0; k < len2; ++k) {
				result[k] = 0.21557895 - 0.41663158 * Math.Cos(fttmp * k) + 0.277263158 * Math.Cos(2 * fttmp * k) - 0.083578947 * Math.Cos(3 * fttmp * k) + 0.006947368 * Math.Cos(4 * fttmp * k)
				result[length - k - 1] = result[k]
			}
			break
		case WinTypeEnum.Triangle:
			let odd = length % 2
			for (let k = 0; k < len2; ++k) {
				result[k] = (2 * (k + 1) + odd - 1) / (length + odd)
				result[length - k - 1] = result[k]
			}
			break
		case WinTypeEnum.Blackman:
			let bmtmp = (2.0 * Math.PI) / (length - 1)
			for (let k = 0; k < len2; ++k) {
				result[k] = 0.42 - 0.5 * Math.Cos(bmtmp * k) + 0.08 * Math.Cos(2 * bmtmp * k)
				result[length - k - 1] = result[k]
			}
			result[0] = 0.0
			result[length - 1] = 0.0
			break
		case WinTypeEnum.Rectangle:
		default:
			for (let i = 0; i < length; ++i) {
				result[i] = 1
			}
			break
	}
	return result
}

export const get0dBCoef = (ampliType) => {
	var coef = 20
	switch (ampliType) {
		case AmplitudeTypeEnum.PowerDensity:
		case AmplitudeTypeEnum.Power:
		case AmplitudeTypeEnum.RMSPowerDensity:
		case AmplitudeTypeEnum.RMSPower:
		case AmplitudeTypeEnum.SqrtPowerDensity:
			coef = 10
			break
	}
	return coef
}

export const getPointCount = (analysisPoint) => {
	return 64 * Math.pow(2, analysisPoint)
}

export const sum = (arr, func) => {
	var s = 0
	arr.forEach(function (val, idx, arr) {
		s += func(val)
	}, 0)
	return s
}

export const getFloatFixedNum = (num) => {
	let x = String(num).indexOf('.') + 1 // 小数点的位置
	let y = String(num).length - x // 小数的位数
	return y
}

export const getRemainTime = () => {
	const now = +new Date()
	const tomorrow = +new Date(dayjs().add(1, 'day').format('YYYY-MM-DD hh:mm:ss'))
	// return 5000

	return tomorrow - now
}

export const judgeTreeAuth = (treeAuth, path, authType) => {
	if (treeAuth[path]) {
		return treeAuth[path][authType] == 1
	} else {
		let tempPath = path,
			hasAuth = false
		while (tempPath.length > 0) {
			for (const key in treeAuth) {
				const element = treeAuth[key]
				if (element.Path === tempPath) {
					hasAuth = element[authType] == 1
					break
				}
			}
			tempPath = tempPath.slice(0, tempPath.length - 4)
		}
		return hasAuth
	}
}

export const setNewStr = (str, i, word) => {
	if (i === 0) return word + str.slice(1)
	if (i === str.length - 1) return str.slice(0, str.length - 1) + word
	return str.slice(0, i) + word + str.slice(i + 1, str.length)
}

export const isIterable = (obj) => {
	return obj != null && typeof obj[Symbol.iterator] === 'function'
}

export const binarySearch = (points, length, target, func) => {
	let left = 0;
	let right = length - 1;
	if (target == null) {
		return 0;
	}
	while (left <= right) {
		let mid = Math.floor((right + left) / 2);
		let value = func(points[mid]);
		if (isNaN(value)) return 0;
		if (value == target) {
			return mid;
		} else if (value < target) {
			left = mid + 1;
		} else if (value > target) {
			right = mid - 1;
		}
	}
	return right < 0 ? 0 : right;
}
export class Debounce {
	constructor() {
		this.timeout = null;
		this.func = null;
		this.action = () => {
			this.timeout = null;
			this.func = null;
		}
	}
	run(func, wait) {
		this.func = func;
		if (wait == 0) {
			this.func.apply();
			if (this.timeout) {
				clearTimeout(this, this.timeout);
				this.timeout = null;
			}
			return;
		}
		if (this.timeout) return;
		this.timeout = setTimeout(() => {
			try {
				this.func.apply();
			} catch (e) {

			} finally {
				this.timeout = null;
			}
		}, wait);
	}
	runAwait(func, wait) {
		this.func = func;
		if (this.timeout) return;
		this.timeout = setTimeout(() => {
			try {
				this.func?.(this.action);
			} catch (e) {

			}
		}, wait);
	}
}

export const getPointInfoById = (data) => {
	const ids = data.map(t => t.PointId)
	return axios({
		method: 'post',
		url: '/Component/points/false',
		data: {
			ids: ids
		}
	}).then((res) => {
		if (res.IsSuccess) {
			return res.Result;
		}
	})
}

export const i18nGlobal = (t) => {
	return i18n.global.t(t)
}

export const i18nString = (obj) => {
	return obj[store.state.lang];
}

export const htmlEncode = (str) => {
	if (str == null) return str;
	str = str.replace(/&/g, "&amp;");
	str = str.replace(/</g, "&lt;");
	str = str.replace(/>/g, "&gt;");
	str = str.replace(/"/g, "&quot;");
	str = str.replace(/'/g, "&#39;");
	return str;
}

export const rexFilter = (str) => {
	return $("<div/>").html(str).text();
}

export const expandNode = (node) => {
	if (!node.isLeaf) {
		if (!node.expanded) {
			node.expand();
		} else {
			node.collapse();
		}
	}
};

export const isMobile = () => {
	let flag = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
	return flag;
}

export const standardStringFormat = (value) => {
	if (value && typeof value === "string") {
		return value.trim().replace(/[\r\n\t，,]/g, "");
	}
	return value;
}

/** 实时数据特征值图谱，按原始数据时过滤掉无波形的测点 */
export const originDataInputFilter = (p) => {
	const extralAlgorithms = [6, 13, 16]; //索力、相关、统计分析单独排除
	return p.HasWave == true && (p.SignalType < 100 || p.SignalType === 111 || p.SignalType == 601 || (p.SignalType > 500 && p.SignalType < 600)) && !extralAlgorithms.includes(p.SignalType);
}

/** 幅值报警：根据报警规则判断当前值的报警等级 */
export const getAlarmLevelByRule = (value, alarmRule, eigenType) => {
	if (!alarmRule || alarmRule.AmplitudeAlarmType == AlarmTypeEnum.None) return NormalLevel;
	if (alarmRule.EigenType != null && alarmRule.EigenType != eigenType) return NormalLevel;
	const enabledLevels = alarmRule.Levels.filter(level => level.Enable).sort((a, b) => b.Level - a.Level);
	switch (alarmRule.AmplitudeAlarmType) {
		default:
			return false;
		case AlarmTypeEnum.MoreLimit:
			for (let i = 0; i < enabledLevels.length; i++)
				if (value >= enabledLevels[i].Threshold) return enabledLevels[i].Level;
			break;
		case AlarmTypeEnum.LessLimit:
			for (let i = 0; i < enabledLevels.length; i++)
				if (value <= enabledLevels[i].Threshold) return enabledLevels[i].Level;
			break;
		case AlarmTypeEnum.InnerWindow:
			for (let i = 0; i < enabledLevels.length; i++)
				if (value <= enabledLevels[i].UpperLimit && value >= enabledLevels[i].LowerLimit) return enabledLevels[i].Level;
			break;
		case AlarmTypeEnum.OuterWindow:
			for (let i = 0; i < enabledLevels.length; i++)
				if (value >= enabledLevels[i].UpperLimit || value <= enabledLevels[i].LowerLimit) return enabledLevels[i].Level;
			break;
	}
	return NormalLevel;
}

/** 将Flag等字段转换为AlarmLevels，兼容巡检报警使用 */
export const getAlarmLevelsByFlag = (alarmInfo) => {
	return [
		{
			"AlarmRulerId": "",
			"Level": 8,
			"Enable": alarmInfo.Flag == 1 || alarmInfo.Flag == 3,
			"Threshold": alarmInfo.Warning1,
			"LowerLimit": alarmInfo.Warning2,
			"UpperLimit": alarmInfo.Warning1
		},
		{
			"AlarmRulerId": "",
			"Level": 16,
			"Enable": alarmInfo.Flag == 2 || alarmInfo.Flag == 3,
			"Threshold": alarmInfo.Danger1,
			"LowerLimit": alarmInfo.Danger2,
			"UpperLimit": alarmInfo.Danger1
		}
	]
}

export const getAlarmColorByLevel = (alarmLevel) => {
	if (store.state.alarmInfo?.length > 0) {
		const target = store.state.alarmInfo.find(e => e.Level == alarmLevel);
		if (target) {
			return target.Color;
		} else {
			const normalColor = store.state.alarmInfo.find(e => e.Level == NormalLevel).Color;
			return normalColor;
		}
	} else
		return false

}

export const checkHasAllPermission = (machineTreeAuth) => {
	if (machineTreeAuth['0001']) {
		const rootAuth = machineTreeAuth['0001'];
		return rootAuth.WorkgroupId === 'c750eeae-97d7-4e3f-a420-fa2f55a6f5d4';
	}
	return false;
}

const UTCBASETIMETICKS = "621356256000000000";

/**
 * 获取时间戳
 * @param {*} ticks 
 */
export const getTimesTamp = (ticks) => {
	if (ticks > UTCBASETIMETICKS) {
		return ticks - UTCBASETIMETICKS;
	}
	return Number(new Date())
}

export const concatAlarmPath = (item, displayContents, displayDepartmentTypes) => {
	const { AlarmTypes, ConfirmDegree, ConfirmTypes, AlarmModels } = store.state.alarmRelateInfo;
	translateAlarmStr(item, { AlarmTypes, AlarmModels, ConfirmDegree, ConfirmTypes });
	let name = '';
	if (displayContents.includes(DisplayContentEnum.Unit) || item.CompanyStr === undefined) name += item.CompanyStr;
	if (displayContents.includes(DisplayContentEnum.Department)) {
		if (displayDepartmentTypes.includes(0)) {
			name += item.DepartmentStr || "";
		} else {
			item.DepartmentTypeNames.forEach(e => {
				if (displayDepartmentTypes.includes(e.DepartmentType)) {
					name += e.Name + '\\';
				}
			})
		}
	}
	if (displayContents.includes(DisplayContentEnum.DeviceAndScene) || item.DeviceStr === undefined) name += item.DeviceStr || "";
	if (displayContents.includes(DisplayContentEnum.Point) || item.PointName === undefined) name += item.PointName || "";
	if (name[name.length - 1] === "\\") name = name.slice(0, -1);
	return name;
}

export const getTimeLength = (timeLength, timeLengthUnit) => {
	if (timeLengthUnit === TimeUnitEnum.Second) {
		return timeLength * 1000;
	} else if (timeLengthUnit === TimeUnitEnum.Minute) {
		return timeLength * 60 * 1000;
	} else if (timeLengthUnit === TimeUnitEnum.Hour) {
		return timeLength * 60 * 60 * 1000;
	} else {
		return timeLength * 24 * 60 * 60 * 1000;
	}
}

export const fixedThemes = [
	{
		Id: "00000000-0000-0000-0000-000000000000",
		Name: i18nGlobal('platformSetting.productManage.theme.defaultLight'),
		active: false,
		fixed: true,
		GraphTheme: false,
		CardColor: "#FFFFFF",
		BackgroundColor: "#F9FAFC",
		SeparatorColor: "#EEEEEE",
		TextColor: "#666666",
		ThemeColor: "#409eff",
		TitleColor: "#333333",
		TooltipColor: "#CCCCCC",
	},
	{
		Id: "00000000-0000-0000-0000-000000000001",
		Name: i18nGlobal('platformSetting.productManage.theme.defaultDark'),
		active: false,
		choose: false,
		fixed: true,
		GraphTheme: true,
		CardColor: "#020c34",
		BackgroundColor: "#00092c",
		SeparatorColor: "#10223A",
		TextColor: "#b4cbdb",
		ThemeColor: "#409eff",
		TitleColor: "#f9fafc",
		TooltipColor: "#195d9b",
	},
]

export const translateAlarmStr = (item, { AlarmModels, AlarmTypes, ConfirmTypes, ConfirmDegree }) => {
	item.AlarmLevelStr = store.state.alarmInfo.find(t => t.Level === item.AlarmLevel)?.Name;
	item.AlarmModelStr = AlarmModels.find(t => t.Key === item.AlarmModel)?.Value;
	item.AlarmTypeStr = AlarmTypes.find(t => t.Key === item.AlarmType)?.Value;
	item.ConfirmTypeStr = ConfirmTypes.find(t => t.Key === item.ConfirmType)?.Value;
	item.ConfirmDegreeStr = ConfirmDegree.find(t => t.Key === item.ConfirmDegree)?.Value;
	item.DepartmentStr = item.DepartmentTypeNames.length > 0 ? item.DepartmentTypeNames.map(t => t.Name).join('\\') + '\\' : '';
}
