import $ from "jquery";
import { alertErrorMsg, i18nGlobal, i18nString } from "@common/js/utils";
import { getTextSize, isMobile } from "@common/js/utils";
import { PluginBase, SvgButtonBase } from "./DH-plugin-base";

export class AutoScalePlugin extends SvgButtonBase {
	constructor() {
		super();
	}
	GetSvgIcon() {
		var size = getTextSize(this.Owner.CoordinateSystem.FontSize, "1000", "Microsoft YaHei UI");
		this.Width = size.height + 5;
		this.Height = this.Owner.Information.ShapeContainerRect.Height - 100;
		var g = document.createElementNS("http://www.w3.org/2000/svg", "g");
		$(g).css("width", this.Width).css("height", this.Height);
		return $(g);
	}
	OnSizeChanged() {
		var info = this.Owner.Information;
		var rect = info.ShapeContainerRect;
		let leftPadding = this.Entity.PaddingLeft != null ? this.Entity.PaddingLeft : 0;
		this.Left = rect.Left - this.Width - 5 + leftPadding;
		if (rect.Height > 100) {
			this.Top = rect.Top + 50;
			this.Height = rect.Height - 100;
		} else {
			this.Top = rect.Top + rect.Height / 3;
			this.Height = rect.Height / 2;
		}

		this.Translate = "translate(" + this.Left + "," + this.Top + ")";
		this.Dom.attr("transform", this.Translate);
		this.Dom.css("cursor", "n-resize");
		this.Dom.css("height", this.Height);
		this.Dom.css("width", this.Width);
		this.Dom.find("rect").height(this.Height);
	}
	OnClick() {
		let coord = this.Owner.Information.CoordinateInfo;
		coord.AutoChangeYAxis = true;
		super.OnClick();
	}
	GetYMaxMinValue(data) {
		var result = {
			Max: -1000000,
			Min: 100000000,
		};
		$.each(data, function (j, point) {
			if (point.Y > result.Max) result.Max = point.Y;
			if (point.Y < result.Min) result.Min = point.Y;
		});
		if (result.Max == result.Min) {
			result.Max += 10;
			result.Min -= 10;
		}
		return result;
	}
}

export class MultiCoordAutoScalePlugin extends SvgButtonBase {
	constructor() {
		super();
		this.DOM = [];
		this.Width = 16;
		this.Height = 16;
		this.Left = 0;
		this.Top = 0;
		this.Padding = 3;
	}
	GetSvgIcon(shapeContainerRect) {
		var size = getTextSize(this.Owner.CoordinateSystem.FontSize, "1000", "Microsoft YaHei UI");
		var Width = size.height + 5;
		var Height = shapeContainerRect == null ? 50 : shapeContainerRect.Height - 100;
		var g = document.createElementNS("http://www.w3.org/2000/svg", "g");
		$(g).css("width", Width).css("height", Height);
		return $(g);
	}
	CreatePlugin() {
		this.OnSizeChanged();
	}
	OnSizeChanged() {
		const info = this.Owner.Information;
		const keys = [...info.ChartList.keys()];
		this.DOM.forEach((item) => item.dom.remove());
		this.DOM = [];
		for (let i = 0; i < keys.length; i++) {
			this.DOM.push({
				id: isNaN(Number(keys[i])) ? keys[i] : Number(keys[i]),
				info: info.ChartList.get(keys[i]),
				dom: this.GetSvgIcon(info.ChartList.get(keys[i]).ShapeContainerRect),
			});
		}
		this.DOM.forEach((item) => {
			var rect = item.info.ShapeContainerRect;
			if (rect == null) return;
			var Left = rect.Left - this.Width - 5;
			var Top = rect.Top + rect.Height / 10;
			var Translate = "translate(" + Left + "," + Top + ")";
			var Height = rect.Height - rect.Height / 5;
			item.dom.append(this.AddRegion());
			item.dom.attr("id", item.id);
			$(this.Owner.PluginContainer).append(item.dom);
			item.dom.click((e) => {
				e.preventDefault();
				e.stopPropagation();
				this.Entity.onClick(item.id);
			});
			item.dom.on("dblclick", (e) => {
				this.OnDblClick();
				e.preventDefault();
				e.stopPropagation();
			});
			item.dom
				.mousedown((e) => {
					e.preventDefault();
					e.stopPropagation();
					item.dom.attr("transform", Translate + " scale(1.1)");
				})
				.mouseup((e) => {
					e.preventDefault();
					e.stopPropagation();
					item.dom.attr("transform", Translate);
				});
			item.dom.addClass("svg-button");
			item.dom.attr("transform", Translate);
			item.dom.css("cursor", "n-resize");
			item.dom.css("height", Height);
			item.dom.css("width", this.Width);
			item.dom.find("rect").height(Height);
		});
	}
	Close() {
		this.DOM.forEach((item) => item.dom.remove());
		this.DOM = [];
	}
}

export class YAxisRangePlugin extends PluginBase {
	constructor() {
		super();
		this.IDom = null;
		this.LastValue = null;
		this.Funcs = null;
	}
	CreatePlugin() {
		this.Owner.OnDblClick.delete(this.Id);
		this.Owner.OnDblClick.set(this.Id, (e) => {
			this.OnDblClick(e);
		});
	}
	OnDblClick(e) {
		if (this.IDom || isMobile()) return;
		let info = this.Owner.Information;
		let shape = info.ShapeContainerRect;
		if (e.offsetX > shape.Left) return false;
		let coordSys = this.Owner.CoordinateSystem;
		let ymaxText = coordSys.YTexts[0];
		let yminText = coordSys.YTexts[coordSys.YTexts.length - 1];

		let ymaxTextSize = this.MeasureSize(ymaxText);
		let yminTextSize = this.MeasureSize(yminText);
		if (e.offsetY <= shape.Top + ymaxTextSize.Width / 2 && e.offsetY >= shape.Top - ymaxTextSize.Width / 2) {
			//最大值
			this.Funcs = (value, sender) => {
				let coord = sender.Owner.Information.CoordinateInfo;
				coord.YMax = value < coord.YMin ? coord.YMin : value;
				coord.AutoChangeYAxis = false;
				sender.Owner.OnMeasure();
				sender.Owner.OnRender();
			};
			this.createInput(info.CoordinateInfo.YMax, shape.Top);
		}
		if (e.offsetY <= shape.Top + shape.Height && e.offsetY >= shape.Top + shape.Height - yminTextSize.Width) {
			//最小值
			this.Funcs = (value, sender) => {
				let coord = sender.Owner.Information.CoordinateInfo;
				coord.YMin = value > coord.YMax ? coord.YMax : value;
				coord.AutoChangeYAxis = false;
				sender.Owner.OnMeasure();
				sender.Owner.OnRender();
			};
			this.createInput(info.CoordinateInfo.YMin, shape.Top + shape.Height - 30);
		}
	}
	createInput(defaultValue, top) {
		let shape = this.Owner.Information.ShapeContainerRect;
		let html = `<div class="rangeDialog" style="position: absolute;float:left;display:flex;left:${shape.Left + 5}px;top:${top}px;overflow: hidden;z-index:100;">
                        <input class="el-input__inner" type="text" value="${defaultValue}" style="width:100px;height:24px;">
                        <button id="sure" type="button" class="el-button el-button--small el-button--primary el-button--default" style="margin-left: 5px;height: 24px;">${i18nGlobal('common.confirm')}</button>
                    </div>`;
		this.IDom = $(html);
		$(this.Owner.ChartContainer).append(this.IDom);
		this.LastValue = defaultValue;
		this.IDom.on("dblclick", function (e) {
			e.preventDefault();
			e.stopPropagation();
		});
		const that = this;
		this.IDom.find("input").on("click mousedown", function (e) {
			e.stopPropagation();
		});
		this.IDom.find("input")
			.on("change keyup paste", function (e) {
				let value = $(this).val();
				if (value == "" || value == "-") return;
				if (parseFloat(value).toString() == "NaN") {
					$(this).val(that.LastValue);
					return;
				} else {
					that.LastValue = parseFloat(value);
				}
			})
			.on("blur", function () {
				let value = $(this).val();
				if (value == "" || value == "-") return;
				if (parseFloat(value).toString() == "NaN") {
					$(this).val(that.LastValue);
					return;
				} else {
					that.LastValue = parseFloat(value);
				}
			});
		this.IDom.find("#sure").on("click", function () {
			let value = that.IDom.find("input").val();
			let dvalue = parseFloat(value);
			if (Math.abs(dvalue) > 1E7) {
				alertErrorMsg(i18nString({
					CN: `输入的坐标轴刻度必须在-10000000-10000000之间`,
					EN: `the number is between -10000000 and 10000000`
				}));
				return;
			}
			if (dvalue.toString() != "NaN" && that.Funcs) {
				that.Funcs(dvalue, that);
			}
			$(that.Owner.ChartContainer).find(".rangeDialog").remove();
			that.IDom = null;
		});
	}
	MeasureSize(text) {
		let coordSys = this.Owner.CoordinateSystem;
		let size = coordSys.GetYTextSize(text);
		if (size.Width < 20) size.Width = 20;
		return size;
	}
	Close() {
		if (this.IDom) {
			this.IDom.off('dblclick');
			this.IDom.find("input").off('click mousedown change keyup paste blur');
			this.IDom.find("#sure").off('click');
			this.IDom.remove();
			this.IDom = null;
		}
	}
}
