﻿import $ from "jquery";
import { PluginManager } from "@core/graphs/plugins/Dh-plugin-manage";
import { Rect } from "./Dh-webchart-models";
// 坐标轴信息
class WebChart {
	constructor(coordinate, shape, information) {
		this.CoordinateSystem = coordinate; //坐标轴信息
		this.ShapeOperator = shape;
		this.ChartContainer = null;
		this.PluginContainer = null;
		this.Information = information;
		this.PluginManager = new PluginManager(this);
		this.ShapeOperator.ChartContext = this;
		this.CoordinateSystem.Owner = this;
		this.Information.Owner = this;
		this.IsEnabled = true; //禁用图谱 目前只能在初始化的时候设置才能生效 后续看需求是否需要扩展
		this.IsRenderLock = false;
		this.Events = ['MouseDown', 'MouseUp', 'MouseMove', 'TouchStart', 'TouchMove', 'TouchEnd', 'DblClick'];
		this.Option = null;
		this.renderChanged = null;
	}
	Init(chartId) {
		this.ChartContainer = document.getElementById(chartId);
	}
	Dispose() {
		this.ShapeOperator.OnClear();
		this.PluginManager.Clear();
		this.CoordinateSystem.Clear();
		this.Events.forEach(e => this.PluginContainer.off(e));
		this.PluginContainer.empty();
		for (let p in this) {
			this[p] = null;
		}
	}
	SetOption(option) {
		if (option.IsEnabled != undefined) {
			this.IsEnabled = option.IsEnabled;
		}
		this.Option = option;
		this.createPluginContainer();
		this.CoordinateSystem.SetOption(option.Axis);
		this.ShapeOperator.SetOption(option.Axis);
	}
	setPlugin(plugin) {
		this.PluginManager.Init(plugin);
	}
	Update(option, refresh = true) {
		this.Option = option;
		this.CoordinateSystem.SetOption(option.Axis);
		this.ShapeOperator.SetOption(option.Axis);
		if (refresh) this.OnChartChanged();
	}
	SetData(data) {
		this.ShapeOperator.SetData(data);
		this.OnMeasure();
		this.OnRender();
	}
	AppendData(data) {
		this.ShapeOperator.AppendData(data);
		this.OnMeasure();
		this.OnRender();
	}
	ClearData() {
		this.ShapeOperator.ClearData();
		this.PluginManager.ClearData();
		this.OnMeasure();
		this.OnRender();
	}
	OnRender() {
		if (this.IsRenderLock) return;
		//let start = performance.now();
		this.PluginManager.OnRender();
		this.CoordinateSystem.OnRender();
		this.ShapeOperator.OnRender();
		//let end = performance.now();
		//console.log('OnRender', `${end - start}ms`)

		typeof this.renderChanged === 'function' && this.renderChanged();
	}
	OnMeasure() {
		if (this.IsRenderLock) return;
		this.CoordinateSystem.OnMeasure();
		this.ShapeOperator.OnMeasure();
	}
	OnChartChanged() {
		try {
			const width = $("#" + this.ChartContainer.id).width();
			const height = $("#" + this.ChartContainer.id).height();
			this.PluginContainer.height(height);
			this.PluginContainer.width(width);
			if (!this.Information.ChartContainerRect) {
				this.Information.ChartContainerRect = new Rect(0, 0, width, height);
			} else {
				this.Information.ChartContainerRect.update(0, 0, width, height);
			}
			if (width > 0 && height > 0) {
				this.OnMeasure();
				this.PluginManager.OnSizeChanged();
				this.OnRender();
			}
		} catch (e) {
			console.error(e);
		}
	}
	createPluginContainer() {
		const chartContainer = $("#" + this.ChartContainer.id);
		this.PluginContainer = $(
			`<svg width="${chartContainer.width()}" height="${chartContainer.height()}" style="position:relative; left:0;top:0;z-index:2;" class="svg-chart" version="1.1"  xmlns="http://www.w3.org/2000/svg"></svg>`
		);
		chartContainer.append(this.PluginContainer);
		this.Events.forEach(event => this.createEventHandler(event.toLocaleLowerCase(), `On${event}`));
	}
	createEventHandler(eventName, actionName) {
		this[actionName] = new Map();
		this.PluginContainer.on(eventName, (e) => {
			this[actionName].getValues().forEach(func => func(e));
		});
	}
	UseDataRefresh(state) {
		this.IsRenderLock = !state;
	}
}

export default WebChart;
