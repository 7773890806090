<template>
	<div class="event" id="showSystemEvent" v-loading="state.loading">
		<div class="title">{{ $t('headerSetting.systemEvent.title')}}</div>
		<div class="content">
			<el-form class="flex platSearchForm" :inline="true">
				<el-form-item :label="$t('headerSetting.systemEvent.eventType')">
					<el-select v-model="state.searchForm.Type" :placeholder="$t('common.selectToolTip')" @change="getTableData(true)"
						:teleported="false">
						<el-option v-for="item in state.EventTypes" :key="item.Key" :label="item.Value" :value="item.Key">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item :label="$t('headerSetting.systemEvent.eventLevel')">
					<el-select v-model="state.searchForm.Level" :placeholder="$t('common.selectToolTip')" @change="getTableData(true)"
						:teleported="false">
						<el-option v-for="item in state.EventLevels" :key="item.Key" :label="item.Value" :value="item.Key">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item :label="$t('headerSetting.systemEvent.time')">
					<el-date-picker v-model="state.searchForm.StartTime" type="datetime" class="marRight10"
						:placeholder="$t('common.selectStartTimePlaceholder')" :teleported="false" format="YYYY-MM-DD HH:mm:ss"
						value-format="YYYY-MM-DD HH:mm:ss" :clearable="false" />
					<el-date-picker v-model="state.searchForm.EndTime" type="datetime" class="marRight10"
						:placeholder="$t('common.selectEndTimePlaceholder')" :teleported="false" format="YYYY-MM-DD HH:mm:ss"
						value-format="YYYY-MM-DD HH:mm:ss" :clearable="false" />
				</el-form-item>
				<el-form-item>
					<el-input v-model="state.searchForm.SearchText" :placeholder="$t('common.searchContentPlaceholder')" class="searchInput"
						@keyup.enter.prevent="getTableData(true)"> </el-input>
					<el-button type="primary" @click="getTableData(true)">{{$t('common.query')}}</el-button>
					<el-button type="primary" @click="reset">{{$t('common.reset')}}</el-button>
					<el-button type="primary" :disabled="state.selection.length === 0" @click="confirm(state.selection)"
						v-auth="'SystemEvent_Edit'">{{$t('common.confirm')}}</el-button>
				</el-form-item>
			</el-form>

			<el-table :data="state.tableData" style="width: 100%" :empty-text="$t('common.noContent')" 
				max-height="600" @selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55" />
				<el-table-column prop="TypeStr" :label="$t('headerSetting.systemEvent.eventType')" width="180" />
				<el-table-column prop="LevelStr" :label="$t('headerSetting.systemEvent.eventLevel')" width="180" />
				<el-table-column prop="Time" :label="$t('headerSetting.systemEvent.time')" width="240" />
				<el-table-column prop="Content" :label="$t('headerSetting.systemEvent.eventContent')" width="450" show-overflow-tooltip />
				<el-table-column :label="$t('common.operation')">
					<template #default="scope">
						<el-button type="primary" link @click="confirm([scope.row])" v-auth="'SystemEvent_Edit'">{{$t('common.confirm')}}
						</el-button>
					</template>
				</el-table-column>
			</el-table>
			<paginationVue :pageInfo="state.pageInfo" @changePage="changePage"></paginationVue>
		</div>
	</div>
</template>

<script setup>
import { reactive } from "vue";
import paginationVue from "@common/components/pagination.vue";
import axios from "@common/js/axios";
import { alertErrorMsg, alertSuccessMsg, i18nGlobal } from "@common/js/utils";
import dayjs from "dayjs";
import { axiosAuth } from "@common/js/auth";

const emit = defineEmits(["setCount"]);
const state = reactive({
	selection: [],
	tableData: [],
	pageInfo: {
		total: 0,
		PageIndex: 1,
		PageSize: 10,
	},
	EventLevels: [],
	EventTypes: [],
	searchForm: {
		Type: "",
		Level: "",
		StartTime: "",
		EndTime: "",
		SearchText: "",
	},
	loading: true,
});

const getOptions = () => {
	state.loading = true;
	axiosAuth({
		method: "get",
		url: "Event/relate",
		auth: "SystemEvent_Browse",
	}).then((res) => {
		if (res.IsSuccess) {
			state.EventLevels = res.Result.EventLevels;
			state.EventTypes = res.Result.EventTypes;
			state.searchForm.Type = state.EventTypes[0]?.Key;
			state.searchForm.Level = state.EventLevels[0]?.Key;
			getTableData(true);
		}
	});
};

const clearData = () => {
	state.pageInfo.PageIndex = 1;
	state.pageInfo.total = 0;
	state.tableData = [];
};

const getTableData = (isFirst) => {
	state.loading = true;
	if (state.searchForm.StartTime && state.searchForm.EndTime) {
		const isBefore = dayjs(state.searchForm.StartTime).isBefore(dayjs(state.searchForm.EndTime));
		if (!isBefore) {
			state.loading = false;
			return alertErrorMsg(i18nGlobal('common.startTimeLessThanEndTime'));
		}
	}
	if (isFirst) {
		state.pageInfo.PageIndex = 1;
	}
	axiosAuth({
		method: "get",
		url: "Event/search",
		auth: "SystemEvent_Browse",
		params: {
			...state.searchForm,
			PageSize: state.pageInfo.PageSize,
			PageIndex: state.pageInfo.PageIndex,
		},
	}).then((res) => {
		if (res.IsSuccess) {
			res.Result.Data.forEach((item) => {
				item.LevelStr = state.EventLevels.find((child) => child.Key === item.Level)?.Value;
				item.TypeStr = state.EventTypes.find((child) => child.Key === item.Type)?.Value;
			});
			state.tableData = res.Result.Data;
			state.pageInfo.total = res.Result.TotalRows;
		}
	}).finally(() => {
		state.loading = false;
	});
};

const changePage = (pageInfo) => {
	state.pageInfo = pageInfo;
	getTableData();
};
const handleSelectionChange = (selection) => {
	state.selection = selection;
};

const reset = () => {
	state.searchForm.StartTime = "";
	state.searchForm.EndTime = "";
	state.searchForm.Type = state.EventTypes[0].Key;
	state.searchForm.Level = state.EventLevels[0].Key;
	getTableData(true);
};

const confirm = (data, type) => {
	axios({
		method: "delete",
		url: "Event",
		data: {
			Ids: data.map((item) => item.Id),
		},
	}).then((res) => {
		if (res.IsSuccess) {
			emit("setCount");
			getTableData(true);
			alertSuccessMsg(i18nGlobal('common.confirmSuccess'));
		}
	});
};

defineExpose({
	getOptions,
	clearData,
});
</script>

<style scoped lang="less">
.event {
	.title {
		color: var(--phm-theme-title);
		font-weight: bold;
		height: 40px;
		line-height: 40px;
		border-bottom: 1px solid var(--phm-theme-border);
		padding-left: 20px;
	}

	.content {
		padding: 20px;
	}
}
</style>
