// @generated by protobuf-ts 2.9.1
// @generated from protobuf file "protos/typicaldata.proto" (package "device", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { HistoryPointDataReply } from "./historypointdatareply";
/**
 * @generated from protobuf message device.GetTypicalDataRequest
 */
export interface GetTypicalDataRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
}
/**
 * @generated from protobuf message device.GetTypicalDataReply
 */
export interface GetTypicalDataReply {
    /**
     * @generated from protobuf field: repeated storage.HistoryPointDataReply Points = 1 [json_name = "Points"];
     */
    points: HistoryPointDataReply[];
}
// @generated message type with reflection information, may provide speed optimized methods
class GetTypicalDataRequest$Type extends MessageType<GetTypicalDataRequest> {
    constructor() {
        super("device.GetTypicalDataRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetTypicalDataRequest>): GetTypicalDataRequest {
        const message = { id: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetTypicalDataRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTypicalDataRequest): GetTypicalDataRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTypicalDataRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message device.GetTypicalDataRequest
 */
export const GetTypicalDataRequest = new GetTypicalDataRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTypicalDataReply$Type extends MessageType<GetTypicalDataReply> {
    constructor() {
        super("device.GetTypicalDataReply", [
            { no: 1, name: "Points", kind: "message", jsonName: "Points", repeat: 1 /*RepeatType.PACKED*/, T: () => HistoryPointDataReply }
        ]);
    }
    create(value?: PartialMessage<GetTypicalDataReply>): GetTypicalDataReply {
        const message = { points: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetTypicalDataReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTypicalDataReply): GetTypicalDataReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated storage.HistoryPointDataReply Points = 1 [json_name = "Points"];*/ 1:
                    message.points.push(HistoryPointDataReply.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTypicalDataReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated storage.HistoryPointDataReply Points = 1 [json_name = "Points"]; */
        for (let i = 0; i < message.points.length; i++)
            HistoryPointDataReply.internalBinaryWrite(message.points[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message device.GetTypicalDataReply
 */
export const GetTypicalDataReply = new GetTypicalDataReply$Type();
